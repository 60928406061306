import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-metaverse-banner',
  templateUrl: './metaverse-banner.component.html',
  styleUrls: ['./metaverse-banner.component.scss']
})
export class MetaverseBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
