<div *ngIf="isTeacher">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>
<div *ngIf="!isTeacher">
    <app-header-style-one></app-header-style-one>
</div>
<div class="containerBg">
    <div class="container">
        <!-- <div class="row mt-5">
		  <div class="col-md-12">
			 <div class="serviceTitle">
				<h3 class="col-lg-12" labelStyle>
					<span>
						<i class="fas fa-film"></i>
						<span class="ml-2">{{ this.editData._id ? 'Update' : 'Create'}} Video</span> 
					</span>
					<a type="button" class="default-btn mb-2  ml-2 float-right p-2" (click)="goBack()"><i class="fas fa-long-arrow-alt-left"></i>&nbsp;Back</a>
				</h3>				
			 </div>
			 <hr>
		  </div>
	   </div> -->
       <div class="go" style="border-bottom: 1px solid rgba(0, 0, 0, 0.1);">
		<h4>Create Evalution Test</h4>
		<div style="width: 10%;"><button type="button" class="default-btn float-right " (click)="goBack()"><i class="fas fa-long-arrow-alt-left kin"></i >&nbsp;Back</button>
		</div></div>
	   <div class="container" style="margin-top: 2%;">
            <form [formGroup]="createForm">
                <div class="row">
                    <!-- <div class="col-lg-6 description">
						<div class="form-group mb-3">
                <label labelStyle>Video Name</label>
						   <input type="text" formControlName="videoname" placeholder="Video Name"  class="form-control" maxlength="50" [ngClass]="{ 'is-invalid': submitted && f.videoname.errors}" />
						   	<div *ngIf="submitted && f.videoname.errors" class="invalid-feedback">
								<div *ngIf="f.videoname.errors.required">
									Video Name is required
								</div>
						 	</div>
						</div>
                    </div>					 -->

                    <div class="col-md-6">
                        <div class="form-group mb-3">
                            <label labelStyle>Test Name</label>
                            <input type="text" formControlName="testname" placeholder="Test Name" class="form-control"
                                maxlength="50" [ngClass]="{ 'is-invalid': submitted && f.testname.errors}" />
                            <div *ngIf="submitted && f.testname.errors" class="invalid-feedback">
                                <div *ngIf="f.testname.errors.required">
                                    Test Name is required
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-md-6 description" *ngIf="coursecategorylist.length !=0">
                        <div class="form-group">
                            <label labelStyle>Category</label>
                            <select class="form-control col-md-12" (change)="selectcategoryChange($event)"
                                formControlName="courseCategory">
                                <option value="" disabled>Select Category</option>
                                <option *ngFor="let obj of coursecategorylist" [ngValue]="obj._id">{{obj.categoryName}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-6 description" *ngIf="modulelist.length !=0">
                        <div class="form-group">
                            <label labelStyle>Module</label>
                            <select class="form-control col-md-12" (change)="selectmoduleChange($event)"
                                formControlName="courseModule">
                                <option value="" disabled>Select Module</option>
                                <option *ngFor="let obj of modulelist" [ngValue]="obj.moduleID">{{obj.moduleName}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-6 description" *ngIf="templatelist.length !=0">
                        <div class="form-group">
                            <label labelStyle>Template</label>
                            <select class="form-control col-md-12" (change)="selecttemplateChange($event)"
                                formControlName="courseTemplate">
                                <option value="" disabled>Select Template</option>
                                <option *ngFor="let obj of templatelist" [ngValue]="obj._id">{{obj.courseName}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-6 description" *ngIf="topicapi.length !=0">
                        <div class="form-group">
                            <label labelStyle>Topic</label>
                            <select class="form-control col-md-12" (change)="selecttopicChange($event)"
                                formControlName="courseTopic">
                                <option value="" disabled>Select Topic</option>
                                <option *ngFor="let obj of topicapi" [ngValue]="obj.topicID._id">
                                    {{obj.topicID.topicName}}</option>
                            </select>
                        </div>
                    </div>

                    <!-- <div class="col-md-6 description" *ngIf="gradeapi.length !=0">
                        <div class="form-group">
                            <label labelStyle>Grade</label>
                          <select class="form-control col-md-12" (change)="selectgradeChange($event)" formControlName="courseGrade" >
                            <option value="" disabled>Select Grade</option>
                            <option *ngFor="let obj of gradeapi" [ngValue]="obj._id">{{obj.gradeName}}</option>
                          </select>
						
                        </div>
                      </div> -->


                    <div class="col-md-6 description" *ngIf="courseboardlist.length !=0">
                        <div class="form-group">
                            <label labelStyle>Board</label>
                            <select class="form-control col-md-12" (change)="selectboardChange($event)"
                                formControlName="courseBoard">
                                <option value="" disabled>Select Board</option>
                                <option *ngFor="let obj of courseboardlist" [ngValue]="obj._id">{{obj.boardName}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <!-- <div class="col-md-6 description" *ngIf="coursesubjectlist.length !=0">
                        <div class="form-group">
                            <label labelStyle>Subject</label>
                          <select class="form-control col-md-12" (change)="selectsubjectChange($event)" formControlName="courseSubject" >
                            <option value="" disabled>Select Subject</option>
                            <option *ngFor="let obj of coursesubjectlist" [ngValue]="obj._id">{{obj.subjectName}}</option>
                          </select>

                        </div>
					  </div> -->
                    <div class="col-md-6 description" *ngIf="languageapi.length !=0">
                        <div class="form-group">
                            <label labelStyle>Language</label>
                            <select class="form-control col-md-12" (change)="selectlanguageChange($event)"
                                formControlName="courseLanguage">
                                <option value="" disabled>Select Language</option>
                                <option *ngFor="let obj of languageapi" [ngValue]="obj._id">{{obj.languageName}}
                                </option>
                            </select>
                        </div>
                    </div>



                    <div class="col-md-6">
                        <div class="form-group mb-3">
                            <label labelStyle>Privacy</label>
                            <select class="form-control p-0 col-md-12" (change)="privacytype($event)" required
                                formControlName="privacy">
                                <option [value]="" disabled>Select Privacy Type</option>
                                <option [value]="'Private'">Private</option>
                                <option [value]="'Institution'">Institution</option>
                                <option [value]="'Public'">buddi</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group col-md-6">
                        <label labelStyle>Level</label>
                        <select id="levelid" class="form-control" formControlName="level" (change)="levelChange($event)">
                            <option value="" disabled>Select Level</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                        </select>
                    </div>
                     <div class="col-md-6 description">
                        <div class="form-group mb-3">
                            <label labelStyle>Price</label>
                            <textarea
                                type="text"
                                maxlength="5"
                                formControlName="price"
                                class="form-control"
                            ></textarea>
                            <!--<label labelStyle>Price in Buddi Coin: {{price}}</label>-->
                        </div>
                    </div>
                    <div class="col-md-6" *ngIf="f.providerBy.value != 'You Tube'">
                        <div class="form-group">
                            <label labelStyle>Choose Question File(s)</label>
                            <div class="input-group">
                                <div class="custom-file">
                                    <input type="hidden" formControlName="fileupload" name="" value="">
                                    <input type="file" class="custom-file-input" multiple=""  (change)="onFileChange($event)"
                                        id="inputGroupFile01" aria-describedby="inputGroupFileAddon01"
                                        [ngClass]="{ 'is-invalid': submitted && f.fileupload.errors}">
                                    <label class="custom-file-label" for="inputGroupFile01">{{selectedFileName}}</label>                                 
                                </div>
                            </div>
                            <div *ngIf="submitted && f.fileupload.errors" class="invalid-error-span">
                                <div *ngIf="f.fileupload.errors.required" style="font-size: 80%;color: #dc3545;">
                                    Document is required</div>
                            </div>
                            <!-- <p><small>Holding down the Ctrl key, click each of the each video(s) files for multiple select upload</small></p> -->
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label labelStyle>Choose Test Thumbnail</label>
                            <div class="input-group">
                                <div class="custom-file">
                                    <input type="hidden" formControlName="thumbnail" name="" value="">
                                    <input type="file" accept="image/*" class="custom-file-input"
                                        [ngClass]="{ 'is-invalid': submitted && f.thumbnail.errors}"
                                        (change)="onSelectFile($event)" id="inputGroupFile01"
                                        aria-describedby="inputGroupFileAddon01">
                                    <label class="custom-file-label" style="word-break: break-all;overflow: hidden;"
                                        for="inputGroupFile01">{{this.thumbnailPlace =='' ? 'Choose File' : this.thumbnailPlace}}</label>
                                </div>
                            </div>
                            <div *ngIf="submitted && f.thumbnail.errors" class="invalid-error-span">
                                <div *ngIf="f.thumbnail.errors.required" style="font-size: 80%;color: #dc3545;">
                                    Thumbnail Image is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                            <div class="form-group mb-3">
                                <label labelStyle>Number Of Questions</label>
                                <input type="number" formControlName="noofquestions" placeholder="Number Of Question" class="form-control"
                                    maxlength="3" [ngClass]="{ 'is-invalid': submitted && f.noofquestions.errors}" />
                                <div *ngIf="submitted && f.noofquestions.errors" class="invalid-feedback">
                                    <div *ngIf="f.noofquestions.errors.required">
                                        No. Of Questions is required
                                    </div>
                                </div>
                            </div>
                        </div>

                    <div class="col-md-6">
                            <div class="form-group mb-3">
                                <label labelStyle>Total Marks</label>
                                <input type="number" formControlName="totalmarks" placeholder="Total Marks" class="form-control"
                                    maxlength="3" [ngClass]="{ 'is-invalid': submitted && f.totalmarks.errors}" />
                                <div *ngIf="submitted && f.totalmarks.errors" class="invalid-feedback">
                                    <div *ngIf="f.totalmarks.errors.required">
                                        Total Marks is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                                <div class="form-group mb-3">
                                    <label labelStyle>Total Time (Minutes)</label>
                                    <input type="number" formControlName="totaltime" placeholder="Total Time" class="form-control"
                                        maxlength="3" [ngClass]="{ 'is-invalid': submitted && f.totaltime.errors}" />
                                    <div *ngIf="submitted && f.totaltime.errors" class="invalid-feedback">
                                        <div *ngIf="f.totaltime.errors.required">
                                            Total Minutes is required
                                        </div>
                                    </div>
                                </div>
                            </div>

                    <div class="col-md-6">
                        <div class="form-group mb-3">
                            <label labelStyle>Description</label>
                            <textarea type="text" maxlength="120" formControlName="description"
                                class="form-control"></textarea>
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-12 description">
                        <div class="text-center mt-3">
                            <button type="button" (click)="onSubmit('create')" class="default-btn">
                                <i class='bx bx-save icon-arrow before'></i>
                                <span class="label">{{this.editData.length!=0? 'Update' : 'Add'}} </span>
                                <i class="bx bx-save icon-arrow after"></i>
                            </button>
                        </div>
                    </div>
                </div>

            </form>
            <br>
            <div class="row">

            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <img src="assets/img/spinner-default.gif" alt="">
</ngx-spinner>