<div *ngIf="isstudent">
    <app-header-style-one></app-header-style-one>
</div>
<div *ngIf="isteacher">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>
<section class="menuPage ptb-50">
<div class="container">
	<div class="banner-wrapper-content" >
			<h5 style="color: #000000; padding-top:25px;padding-bottom:25px; cursor: pointer" class="col-lg-12">
					Courses
				</h5>
	<!---<p>95% of people learning for professional development report career benefits like getting a promotion, a raise, or starting a new career.</p>
		<form class="menuSearch">
			<input type="text" placeholder="What do you want to learn?" class="input-search">
			<button type="button">Search</button>
		</form>-->
	</div> 
	<!-- <div class="menuCard">
		<h2 class="menuLevel1" *ngFor = "let categorymenu of coursecategory">{{categorymenu.categoryName}}
		<div class="row">
		 <div class="col-md-3">
			<ul>
				 <h3 class="title" *ngIf="categorymenu.moduleList.length > 0">	</h3> 
				 <li class="menuLevel2" *ngFor="let submenu of categorymenu.moduleList" (click)="selectedmenu(submenu.categoryID,submenu.moduleID)">
					<span class="hover">{{submenu.moduleName}}</span><li>
			
			</ul>
		  </div>
		  
		  </div>
		</h2>
	
		</div> -->
	<div class="menuCard">
		<ul>
			<li *ngFor = "let categorymenu of coursecategory"><h2 class="menuLevel1"  >{{categorymenu.categoryName}}</h2>
		<!-- <h2 class="menuLevel1" ></h2> -->
		<ul>
			<li class="" *ngIf="categorymenu.moduleList.length > 0"></li>

			<li class="columnList" *ngFor="let submenu of categorymenu.moduleList" (click)="selectedmenu(submenu.categoryID,submenu.moduleID)">
				<h3 class="title">{{submenu.moduleName}}</h3>
		</li>
	</ul>

		  </li>
	</ul>
	</div>
	
</div>
</section>
<!-- <div *ngIf="isteacher">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div> -->
