<app-header-style-one-teacher></app-header-style-one-teacher>

<div class="page-title-area page-title-style-three item-bg4 jarallax" data-jarallax='{"speed": 0.3}'>
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a>Home</a></li>
        <li>Request</li>
      </ul>
      <h2>View Our Request</h2>
    </div>
  </div>
</div>
<div class="container  mt-3">
  <h3 style="color: var(--mainColor);">
    Requested One-on-One Class
</h3>
  <div class="container p-0">
    <form [formGroup]="exploreForm">
      <div class="row">

        <div class="col-lg-12 row">
          <div class="col-md-2 description" *ngIf="coursecategorylist.length !=0">
            <div class="form-group">
              <label>Category Name</label>
              <select class="form-control" (change)="selectChange($event)" formControlName="categoryID">
                <option value="all">All</option>
                <option *ngFor="let obj of coursecategorylist" [ngValue]="obj._id">{{obj.categoryName}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-2 description" *ngIf="gradeapi.length !=0">
            <div class="form-group">
              <label>Grade</label>
              <select class="form-control" (change)="selectChange($event)" formControlName="gradeID">
                <option value="all">All</option>
                <option *ngFor="let obj of gradeapi" [ngValue]="obj._id">{{obj.gradeName}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-2 description" *ngIf="courseboardlist.length !=0">
            <div class="form-group">
              <label>Board</label>
              <select class="form-control" (change)="selectChange($event)" formControlName="boardID">
                <option value="all">All</option>
                <option *ngFor="let obj of courseboardlist" [ngValue]="obj._id">{{obj.boardName}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-2 description" *ngIf="coursesubjectlist.length !=0">
            <div class="form-group">
              <label>Subject</label>
              <select class="form-control" (change)="selectChange($event)" formControlName="subjectID">
                <option value="all">All</option>
                <option *ngFor="let obj of coursesubjectlist" [ngValue]="obj._id">{{obj.subjectName}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-2 description" *ngIf="languageapi.length !=0">
            <div class="form-group">
              <label>Language</label>
              <select class="form-control" (change)="selectChange($event)" formControlName="languageID">
                <option value="all">All</option>
                <option *ngFor="let obj of languageapi" [ngValue]="obj._id">{{obj.languageName}}</option>
              </select>
            </div>
          </div>

       
        </div>
      </div>

    </form>

  </div>

    <div class="row">
        <div class="col-lg-3 col-md-6" *ngFor="let slide of requestedDataList | paginate: { id: 'pagination1',itemsPerPage: count1, currentPage: p1 }" >
            <div class="single-courses-box without-box-shadow mb-30">
                <div class="courses-image">
                    <a  (click)="detailsRequest(slide,'Livesession')" class="d-block"><img style="width: 250px;height: 175px;" [src]="slide.image =='' ? 'assets/img/courses/courses1.jpg':slide.image" alt="image"></a>
                    <!-- <div class="courses-tag">
                        <a (click)="detailsRequest(slide,'Livesession')" class="d-block">{{slide.categoryID ? slide.categoryID.categoryName : '' }} ({{slide.gradeID ? slide.gradeID.gradeName : '' }})</a>
                    </div> -->
                </div>
                <div class="courses-content">
                    <div class="course-author d-flex align-items-center">
                        <img [src]="slide.studentID!=undefined && slide.studentID.userPhoto!='' ? slide.studentID.userPhoto  :'assets/img/user1.jpg'" class="rounded-circle mr-2" alt="image">
                        <span *ngIf="slide.studentID!=undefined">{{slide.studentID.firstName}}  
                        </span>
                    </div>
                    <div class="course-author d-flex align-items-center">            
                        <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image">
                        {{slide.ScheduleDate |  date :"MMM dd,yyyy, hh:mm aaa"}}
                    </div> 
                    <h3>
                      <a (click)="detailsRequest(slide,'Livesession')" class="d-inline-block"><span class="text-break">{{slide.name}}</span></a>
                    
                      </h3>
               
                </div>
                <div>
                    {{slide.categoryID ? slide.categoryID.categoryName : '' }}, {{slide.gradeID ? slide.gradeID.gradeName : '' }}, {{slide.boardID ? slide.boardID.boardName : '' }}
                  </div>
                  <div class="courses-box-footer">
                    {{slide.subjectID ? slide.subjectID.subjectName : '' }}, {{slide.languageID ? slide.languageID.languageName : '' }}
                  </div> 
            </div>
        </div>
          
        <div *ngIf="requestedDataList.length==0">
            Currently no Request available
        </div>
    </div>  
    <div style="float: right;" *ngIf="requestedDataList.length!=0">
            <pagination-controls (pageChange)="p1 = $event" id="pagination1"></pagination-controls>
          </div>

 
  
  <div class="container" style="padding-top: 5%;">
  </div>
</div>