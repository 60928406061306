import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Options } from 'ng5-slider';
import { ApiService } from '../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { Location } from '@angular/common';
declare var $: any;
import * as moment from 'moment';

@Component({
  selector: 'app-student-test-details',
  templateUrl: './student-test-details.component.html',
  styleUrls: ['./student-test-details.component.scss']
})

export class StudentTestDetailsComponent implements OnInit {

  courseSideID: any;  //course id of the course on the course.buddiforlife.com side
  testID: any;
  groupname1: string;
  formdata;
  testDetails: any;
  startDate: string;
  formData;
  typeuser: string;
  lastupdateddate: string;
  endDate: string;
  public pagename: any = '';
  isUserLoggedIn: string;
  isenroll: string;
  islogin: string;
  public isstudent = false;
  public isteacher = false;
  public ispublish = false;
  public courseURL = environment.COURSE_URL;
  public iscreatedby = false;

  constructor(private location: Location, private my_router: ActivatedRoute, public toastr: ToastrManager, private formBuilder: FormBuilder, private rest: ApiService, private router: Router, private SpinnerService: NgxSpinnerService, private sanitizer: DomSanitizer, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.testID = this.my_router.snapshot.params['id'];
    const userTypep = localStorage.getItem('userType');      
    this.isUserLoggedIn = localStorage.getItem('isLoggedin');
    if(this.isUserLoggedIn=="true") {
      if(userTypep.toLowerCase()=="teacher") {
        this.isteacher = true;
      }
      else {
        this.isstudent = true;
      }
    }
    else {
      this.isstudent = true;
    }
    var usertype = localStorage.getItem('profileType');
    this.typeuser = usertype.toLowerCase();
    const userType = localStorage.getItem('userType');
    var testformData = {};
    testformData["id"] = this.testID;
    const webToken = localStorage.getItem('webtoken');
    this.SpinnerService.show();

    this.rest.getsinglestudentresultdetails(webToken, testformData).subscribe((result) => {
        this.testDetails = result.data;
    });
    this.isUserLoggedIn = localStorage.getItem('isLoggedin');

  }

  courseJoin(courseid, startdate) {
   
  }

  back() {
    this.router.navigate(['/student/home']);
  }

  viewtest(id) {
    this.router.navigate(['/student-test-result/' + id]);
  }

}



