import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Options } from 'ng5-slider';
import { MediaService } from '../../../../shared/media.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ApiService } from '../../../../shared/api.service';
import { NgxSpinnerService } from "ngx-spinner";
import {NgSelectModule, NgOption} from '@ng-select/ng-select';

@Component({
  selector: 'app-class-course-list',
  templateUrl: './class-course-list.component.html',
  styleUrls: ['./class-course-list.component.scss']
})
export class ClassCourseListComponent implements OnInit {

  public coursecategorylist : any = [];
  public paramData:any = [];
  categoryselectedValue = "";
  public modulelist : any = [];
  public isTeacher:boolean = false;
  public isStudent:boolean = false;
  moduleselectedValue = "";
  public templatelist : any = [];
  public categoryname = "";
  public modulename= "";
  public id = "";
  p111: number = 1;
  count111: number = 4;

  constructor(private activatedRoute :ActivatedRoute,public toastr: ToastrManager,private SpinnerService: NgxSpinnerService,private rest : ApiService,private location:Location,private api: MediaService,private formBuilder: FormBuilder,private router: Router) { 
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
  };
  }

  
  ngOnInit(): void {
    // this.getcoursecategory();
    this.isTeacher = localStorage.getItem('profileType').toLowerCase() =='teacher';
    this.isStudent = localStorage.getItem('profileType').toLowerCase() =='student'  
    this.id = this.activatedRoute.snapshot.params.id;

    var req = {
      moduleID : this.id
    }
    this.rest.getClassCourseTemplatefromModule(req).subscribe((result) => {
      if(result.status){
        this.coursecategorylist = result.data.templateList;
        this.categoryname = result.data.categoryID.categoryName;
        this.modulename = result.data.moduleName;
      }
    });
  }

  getcoursecategory() {
    this.rest.getClassCourseCategory().subscribe((result) => {
      this.coursecategorylist = result.data;
      if(Object.keys(this.paramData).length == 0){
       // this.createForm.patchValue({ courseCategory: "" })
      }
    }, (err) => {
    console.log(err);
    });
  }

  selectcategoryChange (event: any) {
    this.categoryselectedValue = event.target.options[event.target.selectedIndex].value.substring(event.target.options[event.target.selectedIndex].value.indexOf(":") + 1); 
    alert(this.categoryselectedValue);
    this.coursecategorylist.forEach(element => {
      if(element._id.replace(/\s/g, "") == this.categoryselectedValue.replace(/\s/g, "")) {
        this.modulelist = element.moduleList;
         if(Object.keys(this.paramData).length == 0){
        //  this.createForm.patchValue({ courseModule: element.moduleList[0]._id })
         }
      }     
    });   
  }

  selectmoduleChange (event: any) {
    this.moduleselectedValue = event.target.options[event.target.selectedIndex].value.substring(event.target.options[event.target.selectedIndex].value.indexOf(":") + 1);  
    var req= {
      moduleID:this.moduleselectedValue.replace(/\s/g, "")
    }
    console.log("modulechange", req);
    this.rest.getClassCourseTemplatefromModule(req).subscribe((result) => {
      if(result.status) {
        alert("success");
        this.templatelist = result.data.templateList;
        this.categoryname = result.data.categoryID.categoryName
        this.modulename = result.data.moduleName;
        this.id = result.data._id;
        alert(this.templatelist.length);
        if(Object.keys(this.paramData).length == 0){
         // this.createForm.patchValue({ courseCategory: "" })
        }
      }
      else {
        alert("coming in");
        this.templatelist = [];
      }      
    }, (err) => {
    console.log(err);
    });
     
  }

  coursedetails(data) {
    if(this.isTeacher){
      this.router.navigate(['/ClassCourseDetails/' + data]);
    }
    else{
      this.router.navigate(['/StudentCourseDetails/' + data]);
    }
    
  }
}


