<div *ngIf="isstudent">
    <app-header-style-one></app-header-style-one>
</div>
<div *ngIf="isteacher">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>


<div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a>Home</a></li>
                <li>dashboard</li>
                <li>{{testDetails.testID ? testDetails.testID.testName : ''}}</li>
            </ul>
            <h2>Tests</h2>
        </div>
    </div>
</div>

<section class="courses-details-area pt-100 pb-70">
    <div class="container">
 <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-lg-8">
                    <div class="courses-title">
                        <h2 style="overflow-wrap: break-word;">{{testDetails.testID ? testDetails.testID.testName : ''}}</h2>
                        <!-- <p>{{courseDetails.description}}</p> -->
                    </div>

                    <div class="courses-meta">
                        <ul>
                            <li>
                                <i class='bx bx-folder-open'></i>
                                <span>Category</span>
                                <a >{{testDetails.category ? testDetails.category.categoryName : ''}}</a>
                            </li>
                            <!-- <li>
                                <i class='bx bx-group'></i>
                                <span>Teacher Name</span>
                                <a *ngIf="isstudent" data-toggle="modal" data-target="#myModal1">{{courseDetails.teacherDetails ? courseDetails.teacherDetails.teacherFirstName : courseDetails.createdTeacherFirstName}}</a>
                                <a *ngIf="isteacher">{{courseDetails.createdTeacherFirstName}}</a>
                              
                            </li>                          -->
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4" style="padding-left: 0px;padding-right:0px;">
                    <div class="courses-price">
                        <a  type="submit" class="button" style=" margin-right: 10px;" (click)="back()" data-toggle="modal" data-target="#myModal2"><span class="label" style="font-weight: 600;">Back</span></a>

                        <a  type="submit" class="button"  (click)="viewtest(testDetails._id)" style=" margin-right: 10px;margin-top: 5%;"><span class="label" style="font-weight: 600;">View</span></a>
                        <!-- <a  type="submit" class="button"  style=" margin-right: 10px;margin-top: 5%;"><span class="label" style="font-weight: 600;">View</span></a> -->
                      
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-8">
                <div class="courses-details-image text-center">
                   <img src='assets/img/courses/courses1.jpg'
                        style="width: 250px;height: 175px;" alt="image">
                </div>

            </div>

            <div class="col-lg-4">
                <div class="courses-sidebar-information">
                    <ul>
                        <li>
                            <span><i class='bx bxs-graduation'></i> Total Marks:</span>
                            {{testDetails.totalMarks}}/{{testDetails.testID ? testDetails.testID.totalMarks : ''}}
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> Category:</span>
                            {{testDetails.category ? testDetails.category.categoryName : ''}}
                        </li>
                        <li>
                            <span><i class='bx bxs-badge-check'></i> Course:</span>
                            {{testDetails.courseID ? testDetails.courseID.courseName : 'Not Applicable' }}
                        </li>   
                        <!-- <li>
                            <span><i class='bx bxs-badge-check'></i> Language:</span>
                            {{testDetails.language ? testDetails.language.languageName : 'Not Applicable' }}
                        </li>    -->
                        <li>
                            <span><i class='bx bxs-badge-check'></i> Date:</span>
                            {{testDetails.dateTaken |  date :"MMM dd,yyyy, hh:mm aaa"}}
                        </li>   
                    </ul>
                </div>
            </div>
        </div>       
    </div>
</section>