<div *ngIf="!isStudent">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>
<div *ngIf="isStudent">
    <app-header-style-one></app-header-style-one>
</div>

<div class="container" style="margin-bottom: 2%;" *ngIf="cls">
    <div class="dos mb-3">
      <h4 style="text-align: center;"><strong style="font-size: 14px;">Welcome {{userprofdata.firstName}}!</strong><br><p style="font-size: 18px;margin: 0;">Do you want to create an institution?</p></h4>
      <!-- <button type="button" class="close" (click)="close()" >&times;</button> -->
    </div>
    <div class="body">
      <!-- <p>One fine body&hellip;</p> -->
      <h1 style="font-size: 20px; ">Benefits for Joining the Subscription Model</h1>
       <p>1. Global Listing</p>
       <p>2. Substantial Discount Per Class</p>
       <p>3. Maintain Private group of Tutors and Learners</p>
       <p>4. Free Marketing to Increased Revenue through our Promotions</p>
       <p>5. Consolidated Billing for your Institutions</p>
  <div class="container">
  <div class="pricing-table table-responsive">
   <table class="table">
       <thead>
           <tr>
               <th scope="col">Basic<br>
                   (Connect with learners Easily)
               </th>
           
               <th scope="col">Professional<br>
                   (Take your business to next level)
               </th>
                         
           </tr>
       </thead>
       <tbody>
           <tr>
            <td><strong><i class="fa fa-rupee"></i>2,999/Year</strong>
            </td>
            <td><strong><i class="fa fa-rupee"></i>5,999/Year</strong>
            </td>
            
           </tr>
           <tr>
              <td> <a (click)="rout(999)" href="javascript:void(0)" class="btn buy">Buy Now</a></td>
              <td> <a (click)="rout(17999)" href="javascript:void(0)" class="btn buy">Buy Now</a></td>
             
           </tr>
       </tbody>
       </table>
       </div>
      </div>
    </div>
    <div style="display: flex;justify-content: space-between;">
        <a href="/Pricing-plans" style="text-decoration: underline;color: var(--mainColor);">Pricing-plans Details</a>
      <a (click)="close()" style="color: var(--mainColor);text-decoration: underline;cursor: pointer;">Choose Later</a>
    </div>
</div>