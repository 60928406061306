import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-asset',
  templateUrl: './asset.component.html',
  styleUrls: ['./asset.component.scss']
})
export class AssetComponent implements OnInit {
  public isStudent = false;
  public isTeacher = false;
  constructor() { }

  ngOnInit(): void {
  }

}
