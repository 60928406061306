import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-upcomming',
  templateUrl: './upcomming.component.html',
  styleUrls: ['./upcomming.component.scss']
})
export class UpcommingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
