
  
  
   
<!--     
    <div class=" col-md-12 banner">
        <div class="container">
           
            <div class="row">
             
    <div class="head">
    <h1>Welcome to buddi 
        Metaverse
        </h1>
        <p>Explore and learn through immersive<br>
             experience from tutors all over the globe.</p>
             <a routerLink="/getstarted" ><button class="btn">Get Started</button></a>
         </div>
<video id="myVideo" poster="/images/w3schools_green.jpg" autoplay muted loop controls>
    <source src="../../../../assets/img/metaverse vedio.mp4" type="video/mp4">
    
    Your browser does not support the video tag.
 </video>
</div>
</div>
</div> -->














<video  id="myVideo" autoplay muted loop controls style="margin-bottom:-8px">
    <source src="../../../../assets/img/Metaverse_v1.mp4" alt="Metaverse_v1."   type="video/mp4">
  </video>

  <div class="content">
   
      
      
      <div class=" col-md-12 banner">
          <div class="container">
              <div class="row">
      <div class="head">
      <h1>Welcome to buddi 
          Metaverse
          </h1>
          <p>Explore and learn through immersive<br>
               experience from tutors all over the globe.</p>
               <a routerLink="/getstarted" ><button class="btn">Get Started</button></a>
  </div>
      
  
  </div>
  </div>
  </div>
  
  </div>
