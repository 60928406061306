<section class="login-area pagebackground bodyHeight">
    <div class="row m-0" >
        <div class="col-lg-4 col-md-4 p-0">
            <!-- <div class="login-image">
                <img src="../../../../../assets/img/buddi-logo.png" alt="image">
            </div> -->
        </div>
        <div class="col-lg-4 col-md-4 p-0">
            <section class="my-dashboard-area" style="padding-top: 50px;padding-bottom: 50px;">
                <div class="container">
                    <div class="myDashboard-content">
                        <form class="edit-account" [formGroup]="schoolregisterform"
                            (ngSubmit)="onSubmit(schoolregisterform)">
                            <div class="row"
                                style="padding: 10px;box-shadow: 0px 0px 20px 0px;background-color: white;">

                                <div class="row">
                                    <div class="col-lg-12 col-md-12" style="text-align: center;margin-bottom: 5%;">
                                        <img src="../../../../../assets/img/black-logo.png" alt="image">
                                    </div>
                                    <div class="col-lg-12 col-md-12" style="text-align: center;">
                                        <h4 style="margin-bottom: 5%;">Welcome to buddi </h4>
                                    </div>
                                    <div class="col-lg-12 col-md-12" style="text-align: center;">
                                        <h5 style="margin-bottom: 5%;"> AI Powered Adaptive Learning Platform </h5>
                                    </div>

                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label>Student Name <span class="required"
                                                style="color: red;">*</span></label>
                                        <input type="text" maxlength="100" class="form-control" value=""
                                            formControlName="name" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                            <div *ngIf="f.name.errors?.required">Student Name required</div>
                                        </div>
                                        <!-- <div *ngIf="iscoursenameexists==true" >
                                            <p style="color: red;">Course Full Name Already Exists</p>
                                        </div> -->
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label>Parent Name <span class="required"
                                                style="color: red;">*</span></label>
                                        <input type="text" maxlength="50" class="form-control" value=""
                                            formControlName="parentname" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.parentname.errors }">
                                        <div *ngIf="submitted && f.parentname.errors" class="invalid-feedback">
                                            <div *ngIf="f.parentname.errors?.required">Parent Name required</div>
                                        </div>
                                        <!-- <div *ngIf="iscoursenameexists==true" >
                                            <p style="color: red;">Course Full Name Already Exists</p>
                                        </div> -->
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="row">
                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                            <label>Code <span class="required" style="color: red;">*</span></label>
                                            <select class="form-control col-md-12">
                                                <option value="+91">+91</option>
                                            </select>
                                        </div>

                                        <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                                            <div class="form-group">
                                                <label>Parent Phone Number <span class="required"
                                                        style="color: red;">*</span></label>
                                                <input type="number" maxlength="10" class="form-control" value=""
                                                    formControlName="phonenumber" class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }">
                                                <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                                                    <div *ngIf="f.phonenumber.errors?.required">Parent Phone Number required
                                                    </div>
                                                </div>
                                                <!-- <div *ngIf="iscoursenameexists==true" >
                                                    <p style="color: red;">Course Full Name Already Exists</p>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label>Parent Email <span class="required" style="color: red;">*</span></label>
                                        <input type="text" formControlName="email" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                            <div *ngIf="f.email.errors.required">Email is required</div>
                                            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label>Grade <span class="required" style="color: red;">*</span></label>
                                        <select class="form-control col-md-12" class="form-control" formControlName="courseGrade">
                                            <option value="" disabled>Select Grade</option>
                                            <option *ngFor="let obj of gradeapi" [value]="obj.gradeName">{{obj.gradeName}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-12 mt-2" style="margin-bottom: 5%;"> 
                                    <div class="form-group">                    
                                        <label>Category <span class="required" style="color: red;">*</span></label>
                                        <br>
                                    <input type="radio" name="category"  value="K-12"
                                        formControlName="classType"> K-12
                                    <input type="radio" name="category" value="NEET"
                                        formControlName="classType"> NEET  
                                        <input type="radio" name="category"  value="JEE"
                                        formControlName="classType"> JEE
                                        <input type="radio" name="category"  value="Coder"
                                        formControlName="classType"> Coding      
                                        </div>            
                            </div>

                                <!-- <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label>Number of Students (optional)</label>
                                        <input type="number" maxlength="4" class="form-control" value=""
                                            formControlName="studentnumber">

                                        <div *ngIf="iscoursenameexists==true" >
                                            <p style="color: red;">Course Full Name Already Exists</p>
                                        </div>
                                    </div>
                                </div> -->

                              
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <ul>
                                        <li>
                                            <label style="word-wrap:break-word"
                                                style="text-decoration: underline;color: blue;">
                                                <input id="checkid" type="checkbox" value="test" /> <a
                                                    style="text-decoration: underline;color: blue;"
                                                    href="https://buddi.life/buddi-privacy" target="_blank"> I Agree to
                                                    the Terms and Conditions </a>
                                            </label>
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn">
                                        <i class='bx bx-save icon-arrow before'></i>
                                        <span class="label">Register here</span>
                                        <i class="bx bx-save icon-arrow after"></i>
                                    </button>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </div>

        <div class="col-lg-4 col-md-4 p-0">
            <!-- <div class="login-image">
                <img src="../../../../../assets/img/buddi-logo.png" alt="image">
            </div> -->
        </div>
    </div>
</section>