<app-header-style-one-teacher></app-header-style-one-teacher>

<div class="container  mt-3">
  <h3 style="color: var(--mainColor);">
    Teacher Pricing
</h3>

<hr>
  <div class="container">
    <form [formGroup]="exploreForm">

        <h5>Personal Class Price </h5>
      <div class="row">
            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                    <label >Level 1</label>
                    <input type="number" formControlName="personallevel1" class="form-control" maxlength="5"
                    class="form-control"  />
            </div>

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                    <label >Level 2</label>
                    <input type="number" formControlName="personallevel2" class="form-control" maxlength="5"
                    class="form-control"  />
            </div>

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                    <label >Level 3</label>
                    <input type="number" formControlName="personallevel3" class="form-control" maxlength="5"
                    class="form-control" />
            </div>

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                    <label >Level 4</label>
                    <input type="number" formControlName="personallevel4" class="form-control" maxlength="5"
                    class="form-control"  />
            </div>
      
      </div>

      <hr>

      <!--<h5>Monitored Test Price </h5>
      <div class="row">
            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                    <label >Level 1</label>
                    <input type="number" formControlName="monitoredlevel1" class="form-control" maxlength="5"
                    class="form-control" />
            </div>

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                    <label >Level 2</label>
                    <input type="number" formControlName="monitoredlevel2" class="form-control" maxlength="5"
                    class="form-control" />
            </div>

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                    <label >Level 3</label>
                    <input type="number" formControlName="monitoredlevel3" class="form-control" maxlength="5"
                    class="form-control" />
            </div>

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                    <label >Level 4</label>
                    <input type="number" formControlName="monitoredlevel4" class="form-control" maxlength="5"
                    class="form-control"  />
            </div>
      
      </div>





      <hr>

      -->


      <h5>Video Class Price </h5>
      <div class="row">
            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                    <label >Level 1</label>
                    <input type="number" formControlName="videolevel1" class="form-control" maxlength="5"
                    class="form-control"/>
            </div>

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                    <label >Level 2</label>
                    <input type="number" formControlName="videolevel2" class="form-control" maxlength="5"
                    class="form-control" />
            </div>

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                    <label >Level 3</label>
                    <input type="number" formControlName="videolevel3" class="form-control" maxlength="5"
                    class="form-control"  />
            </div>

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                    <label >Level 4</label>
                    <input type="number" formControlName="videolevel4" class="form-control" maxlength="5"
                    class="form-control" />
            </div>
      
      </div>



      <hr>



      <h5>Test Price </h5>
      <div class="row">
            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                    <label >Level 1</label>
                    <input type="number" formControlName="testlevel1" class="form-control" maxlength="5"
                    class="form-control"  />
            </div>

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                    <label >Level 2</label>
                    <input type="number" formControlName="testlevel2" class="form-control" maxlength="5"
                    class="form-control" />
            </div>

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                    <label >Level 3</label>
                    <input type="number" formControlName="testlevel3" class="form-control" maxlength="5"
                    class="form-control"  />
            </div>

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                    <label >Level 4</label>
                    <input type="number" formControlName="testlevel4" class="form-control" maxlength="5"
                    class="form-control" />
            </div>
      
      </div>

<hr>

      <h5>Smart Material Price </h5>
      <div class="row">
            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                    <label >Level 1</label>
                    <input type="number" formControlName="materiallevel1" class="form-control" maxlength="5"
                    class="form-control"  />
            </div>

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                    <label >Level 2</label>
                    <input type="number" formControlName="materiallevel2" class="form-control" maxlength="5"
                    class="form-control" />
            </div>

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                    <label >Level 3</label>
                    <input type="number" formControlName="materiallevel3" class="form-control" maxlength="5"
                    class="form-control"  />
            </div>

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                    <label >Level 4</label>
                    <input type="number" formControlName="materiallevel4" class="form-control" maxlength="5"
                    class="form-control" />
            </div>
      
      </div>

      <hr>




      <h5>Group Class Price </h5>
      <div class="row">
            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                    <label >Level 1</label>
                    <input type="number" formControlName="grouplevel1" class="form-control" maxlength="5"
                    class="form-control"  />
            </div>

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                    <label >Level 2</label>
                    <input type="number" formControlName="grouplevel2" class="form-control" maxlength="5"
                    class="form-control" />
            </div>

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                    <label >Level 3</label>
                    <input type="number" formControlName="grouplevel3" class="form-control" maxlength="5"
                    class="form-control"  />
            </div>

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                    <label >Level 4</label>
                    <input type="number" formControlName="grouplevel4" class="form-control" maxlength="5"
                    class="form-control" />
            </div>
      
      </div>

        <hr>

      <h5>Study Hall Price </h5>
      <div class="row">
            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                    <label >Level 1</label>
                    <input type="number" formControlName="studyHallLevel1" class="form-control" maxlength="5"
                    class="form-control"  />
            </div>

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                    <label >Level 2</label>
                    <input type="number" formControlName="studyHallLevel2" class="form-control" maxlength="5"
                    class="form-control" />
            </div>

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                    <label >Level 3</label>
                    <input type="number" formControlName="studyHallLevel3" class="form-control" maxlength="5"
                    class="form-control"  />
            </div>

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                    <label >Level 4</label>
                    <input type="number" formControlName="studyHallLevel4" class="form-control" maxlength="5"
                    class="form-control" />
            </div>
      
      </div>
      <hr>

      <h5>Round Table Price </h5>
      <div class="row">
            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                    <label >Level 1</label>
                    <input type="number" formControlName="roundTableLevel1" class="form-control" maxlength="5"
                    class="form-control"  />
            </div>

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                    <label >Level 2</label>
                    <input type="number" formControlName="roundTableLevel2" class="form-control" maxlength="5"
                    class="form-control" />
            </div>

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                    <label >Level 3</label>
                    <input type="number" formControlName="roundTableLevel3" class="form-control" maxlength="5"
                    class="form-control"  />
            </div>

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                    <label >Level 4</label>
                    <input type="number" formControlName="roundTableLevel4" class="form-control" maxlength="5"
                    class="form-control" />
            </div>
      
      </div>

    </form>

  </div>

  <div class="container" style="padding-top: 5%;padding-bottom: 5%;">
        <button  class="col-lg-2 float-right" (click)="submit()">Save Pricing  &nbsp;<i
            class="fas fa-long-arrow-alt-right"></i></button>
  </div>
</div>