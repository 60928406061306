import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.scss']
})
export class TransactionHistoryComponent implements OnInit {
  public isStudent = false;
  public isTeacher = false;
  constructor() { }

  ngOnInit(): void {
    this.isTeacher =
    localStorage.getItem("profileType").toLowerCase() == "teacher";
this.isStudent =
    localStorage.getItem("profileType").toLowerCase() == "student";
  }

}
