import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../../shared/api.service";
import { Router, ActivatedRoute } from "@angular/router";
import { DatePipe } from '@angular/common';
const datepipe: DatePipe = new DatePipe('en-US')
@Component({
  selector: 'app-completed-classes',
  templateUrl: './completed-classes.component.html',
  styleUrls: ['./completed-classes.component.scss']
})
export class CompletedClassesComponent implements OnInit {

  public isStudent = false;
  public isTeacher = false;
  public classList = [];
  public studyHallList = [];
  public roundTableList = [];
  constructor(private rest: ApiService, private router: Router) { }

  ngOnInit(): void {
    
    this.isTeacher =
        localStorage.getItem("profileType").toLowerCase() == "teacher";
    this.isStudent =
        localStorage.getItem("profileType").toLowerCase() == "student";
    var reqData = {
      "notFetchUpcoming": true
    }
    this.fetchCompletedLiveClasses(reqData);
    this.fetchCompletedRoundTables(reqData);
    this.fetchCompletedStudyHalls(reqData);
  }

  fetchCompletedLiveClasses(reqData) {
    const token = localStorage.getItem("webtoken");
    if (this.isTeacher) {
      this.rest
          .getUserPersonalClassRequestsTeacher(token, reqData)
          .subscribe((result) => {
              console.log(result, "result===");
              if (result.status) {
                  this.classList = result.data.data.completed;
                  console.log("after respose", this.classList);
                  for(var item of this.classList){
                    if(item.startDateTime){
                      item.startDateTime = datepipe.transform(item.startDateTime, 'MMM d, y, h:mm a')
                    }
                  }
              }
          });
  } else {
      this.rest
      .getUserPersonalClassRequestsStudent(token, reqData)
      .subscribe((result) => {
          console.log(result, "result");
          if (result.status) {
              this.classList = result.data.data.completed;
              console.log("after respose", this.classList);
              for(var item of this.classList){
                if(item.startDateTime){
                  item.startDateTime = datepipe.transform(item.startDateTime, 'MMM d, y, h:mm a')
                }
              }
          }
      });
    }
  }

  fetchCompletedRoundTables(reqData) {
    const token = localStorage.getItem("webtoken");
    if (this.isTeacher) {
      this.rest
          .getUserRoundClassRequestsTeacher(token, reqData)
          .subscribe((result) => {
              console.log(result, "result===");
              if (result.status) {
                  this.roundTableList = result.data.data.completed;
                  console.log("round class", this.roundTableList);
                  for(var item of this.roundTableList){
                    if(item.startDateTime){
                      item.startDateTime = datepipe.transform(item.startDateTime, 'MMM d, y, h:mm a')
                    }
                  }
              }
          });
  } else {
      this.rest
      .getUserRoundClassesStudent(token, reqData)
      .subscribe((result) => {
          console.log(result, "result");
          if (result.status) {
              this.roundTableList = result.data.data.completed;
              console.log("round class", this.roundTableList);
              for(var item of this.roundTableList){
                if(item.startDateTime){
                  item.startDateTime = datepipe.transform(item.startDateTime, 'MMM d, y, h:mm a')
                }
              }
          }
      });
    }
  }

  fetchCompletedStudyHalls(reqData) {
    const token = localStorage.getItem("webtoken");
    if (this.isTeacher) {
      this.rest
          .getUserStudyClassRequestsTeacher(token, reqData)
          .subscribe((result) => {
              console.log(result, "result===");
              if (result.status) {
                  this.studyHallList = result.data.data.completed;
                  console.log("study hall", this.studyHallList);
                  for(var item of this.studyHallList){
                    if(item.startDateTime){
                      item.startDateTime = datepipe.transform(item.startDateTime, 'MMM d, y, h:mm a')
                    }
                  }
              }
          });
  } else {
      this.rest
      .getUserStudyClassRequestsStudent(token, reqData)
      .subscribe((result) => {
          console.log(result, "result");
          if (result.status) {
              this.studyHallList = result.data.data.completed;
              console.log("study hall", this.studyHallList);
              for(var item of this.studyHallList){
                if(item.startDateTime){
                  item.startDateTime = datepipe.transform(item.startDateTime, 'MMM d, y, h:mm a')
                }
              }
          }
      });
    }
  }
}
