import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../shared/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ToastrManager, Toastr } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-students-register',
  templateUrl: './students-register.component.html',
  styleUrls: ['./students-register.component.scss']
})
export class StudentsRegisterComponent implements OnInit {
  schoolregisterform: FormGroup;
  submitted = false;
  formData;
  public gradeapi: any = [];
  public grade = "Select Grade";
  public paramData: any = [];

  constructor(private formBuilder: FormBuilder, private router: Router, private rest: ApiService, private toastr: ToastrManager) { }

  ngOnInit(): void {
    this.schoolregisterform = this.formBuilder.group({
      name: ['', Validators.required],
      parentname: ['', Validators.required],
      phonenumber: ['', Validators.required],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      courseGrade: ['', Validators.required],
      classType:['']
    });
    this.getgrade();
  }

  // convenience getter for easy access to form fields
  get f() { return this.schoolregisterform.controls; }

  onSubmit(FormGroup) {
    this.submitted = true;

    if (this.schoolregisterform.invalid) {
      console.log("Invalid Fields");
      this.toastr.errorToastr("Invalid Fields");
      const data = this.schoolregisterform.value;
      //To check the invalid field
      // const invalid = [];
      // const controls = this.schoolregisterform.controls;
      // for (const name in controls) {
      //     if (controls[name].invalid) {
      //         invalid.push(name);
      //         alert(invalid);
      //     }
      // }
      return;
    }

    const data = this.schoolregisterform.value;

    if (data.classType === undefined || data.classType === "") {
      this.toastr.errorToastr("Please select a category");
    }
    else {
      var element = <HTMLInputElement> document.getElementById("checkid");
      var isChecked = element.checked;
      if(isChecked == true) {
        var userData = {};
        userData["parentEmail"] = data.email;
        userData["studentName"] = data.name;
        userData["parentName"] = data.parentname;
        userData["parentPhoneNumber"] = "+91" + data.phonenumber;
        userData["category"] = data.classType;
        userData["grade"] = data.courseGrade;
  
        this.rest.studentsregister(userData).subscribe((result) => {
          console.log(result);
          if (result.status) {
            this.toastr.successToastr(result.message);
            this.router.navigate(['/thanks-student-registration']);
          }
          else {
            this.toastr.errorToastr(result.message);
          }
        }, (err) => {
          console.log(err);
          this.toastr.errorToastr(err);
        });
      }
      else {
        this.toastr.errorToastr("Please check Terms and Conditions");     
      }
      
    }
  }

  getgrade() {
    this.rest.gradefield().subscribe((result) => {
      this.gradeapi = result.data;
      if (Object.keys(this.paramData).length == 0) {
        this.schoolregisterform.patchValue({ courseGrade: this.gradeapi[0].gradeName })
      }
    }, (err) => {
      console.log(err);
    });
  }
}
