<app-header-style-one-teacher></app-header-style-one-teacher>
<div class="container" style="padding-top: 25px;">
    <div class="row">
        <div *ngIf="userCreated" class="col-md-12">
            <div class="text-center noti">
                <h1>All 6 Users Created Successfully</h1>
            </div>
        </div>       
        <button  class="default-btn py-2 px-4" (click)="backto()">Back</button>
        <div *ngIf="div1" class="col-md-12" style="display: flex;justify-content: center;">
            <form [formGroup]="form1">
            <table>
                <tr>
                    <th style="padding-top: 20px;font-size: 18px;">
                        <label style="color: #fd660f;">Teacher 1</label>
                    </th>&nbsp;&nbsp;
                    <th>
                        <div class="form-group">
                            <label>First Name</label>
                            <input type="text" 
                            class="form-control" 
                            formControlName="firstName"
                            [ngClass]="{'is-invalid': form1Submitted && f.firstName.errors}"
                            >
                            <div *ngIf="form1Submitted && f.firstName.errors  " class="invalid-feedback">
                                <div *ngIf="f.firstName.errors?.required" >First Name is required</div>                                
                            </div>
                        </div>                        
                    </th>&nbsp;
                    <th>
                        <div class="form-group">
                            <label>Last Name</label>
                            <input type="text" 
                            class="form-control" 
                            formControlName="lastName"
                            [ngClass]="{'is-invalid': form1Submitted && f.lastName.errors}">
                            <div *ngIf="form1Submitted && f.lastName.errors  " class="invalid-feedback">
                                <div *ngIf="f.lastName.errors?.required" >Last Name is required</div>                                
                            </div>
                        </div>
                    </th>&nbsp;
                    <th>
                        <div class="form-group">
                            <label>Email</label>
                          <input
                            type="email"
                            id="registerFormEmail"
                            class="form-control"
                            pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$"
                            formControlName="email"
                            [ngClass]="{'is-invalid': form1Submitted && f.email.errors}"                    
                          />
                          <div *ngIf="form1Submitted && f.email.errors  " class="invalid-feedback">
                            <div *ngIf="f.email.errors?.required" >Email is required</div>
                            <div *ngIf="f.email.errors?.pattern">Email must be a valid email</div>
                          </div>
                        </div>
                    </th>&nbsp;
                    <th>
                        <div class="form-group">
                            <label>Mobile Number</label>
                            <input type="text" 
                            formControlName="phoneNumber"
                            class="form-control" 
                            [ngClass]="{'is-invalid': form1Submitted && f.phoneNumber.errors}"/>
                            <div *ngIf="form1Submitted && f.phoneNumber.errors" class="invalid-feedback">
                            <div *ngIf="f.phoneNumber.errors.required">Mobile No is Required</div>
                            <div *ngIf="f.phoneNumber.errors.pattern">Enter only number</div>
                            <div *ngIf="f.phoneNumber.errors.minlength">Mobile No must have 10 digits</div>
                            <div *ngIf="f.phoneNumber.errors.maxlength">Mobile No cannot have more than 12 digits</div>
                        </div>
                        </div>
                    </th>&nbsp;
                    <!-- <th>
                        <div class="form-group">
                            <label>Password</label>
                            <input type="password"
                            id="registerFormPassword"
                            name="password"
                            autocomplete="new-password"
                            class="form-control"
                            formControlName="password"
                               [ngClass]="{'is-invalid': submitted && f.password.errors}">
                        
                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                              <div *ngIf="f.password.errors.required" >Password is Required</div>
                              <div *ngIf="f.password.errors?.minlength">Must be atleast 8 characters</div>
                              <div *ngIf="f.password.errors.pattern">
                                  must contain one number<br>
                                  must contain atleast 1 letter in capital case<br>
                                  must contain atleast 1 letter in small case<br>
                                  must contain atleast one special character<br>
                              </div>
                           </div>
                        </div>
                    </th>&nbsp;&nbsp; -->
                    <th style="padding-top: 10px;">
                        <button type="submit" (click)="onSubmit()" class="default-btn py-2 px-4">Register</button>
                    </th>                                       
                </tr>                              
            </table>
        </form>        
        </div>
        <div *ngIf="div2" class="col-md-12" style="display: flex;justify-content: center;">
            <form [formGroup]="form2">
            <table>
                <tr>
                    <th style="padding-top: 20px;font-size: 18px;">
                        <label style="color: #fd660f;">Teacher 2</label>
                    </th>&nbsp;&nbsp;
                    <th>
                        <div class="form-group">
                            <label>First Name</label>
                            <input type="text" 
                            class="form-control" 
                            formControlName="firstName2"
                            [ngClass]="{'is-invalid': form2Submitted && f2.firstName2.errors}">
                            <div *ngIf="form2Submitted && f2.firstName2.errors  " class="invalid-feedback">
                                <div *ngIf="f2.firstName2.errors?.required" >First Name is required</div>                                
                            </div>
                        </div>                        
                    </th>&nbsp;
                    <th>
                        <div class="form-group">
                            <label>Last Name</label>
                            <input type="text" 
                            class="form-control" 
                            formControlName="lastName2"
                            [ngClass]="{'is-invalid': form2Submitted && f2.lastName2.errors}">
                            <div *ngIf="form2Submitted && f2.lastName2.errors  " class="invalid-feedback">
                                <div *ngIf="f2.lastName2.errors?.required" >Last Name is required</div>                                
                            </div>
                        </div>
                    </th>&nbsp;
                    <th>
                        <div class="form-group">
                            <label>Email</label>
                          <input
                            type="email"
                            id="registerFormEmail"
                            class="form-control"
                            pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$"
                            formControlName="email2"
                            [ngClass]="{'is-invalid': form2Submitted && f2.email2.errors}"                    
                          />
                          <div *ngIf="form2Submitted && f2.email2.errors  " class="invalid-feedback">
                            <div *ngIf="f2.email2.errors?.required" >Email is required</div>
                            <div *ngIf="f2.email2.errors?.pattern">Email must be a valid email</div>
                          </div>
                        </div>
                    </th>&nbsp;
                    <th>
                        <div class="form-group">
                            <label>Mobile Number</label>
                            <input type="text" 
                            formControlName="phoneNumber2"
                            class="form-control" 
                            [ngClass]="{'is-invalid': form2Submitted && f2.phoneNumber2.errors}"/>
                            <div *ngIf="form2Submitted && f2.phoneNumber2.errors" class="invalid-feedback">
                            <div *ngIf="f2.phoneNumber2.errors.required">Mobile No is Required</div>
                            <div *ngIf="f2.phoneNumber2.errors.pattern">Enter only number</div>
                            <div *ngIf="f2.phoneNumber2.errors.minlength">Mobile No must have 10 digits</div>
                            <div *ngIf="f2.phoneNumber2.errors.maxlength">Mobile No cannot have more than 12 digits</div>
                        </div>
                        </div>
                    </th>&nbsp;
                    <!-- <th>
                        <div class="form-group">
                            <label>Password</label>
                            <input type="password"
                            id="registerFormPassword"
                            name="password"
                            autocomplete="new-password"
                            class="form-control"
                            formControlName="password"
                               [ngClass]="{'is-invalid': submitted && f2.password.errors}">
                        
                            <div *ngIf="submitted && f2.password.errors" class="invalid-feedback">
                              <div *ngIf="f2.password.errors.required" >Password is Required</div>
                              <div *ngIf="f2.password.errors?.minlength">Must be atleast 8 characters</div>
                              <div *ngIf="f2.password.errors.pattern">
                                  must contain one number<br>
                                  must contain atleast 1 letter in capital case<br>
                                  must contain atleast 1 letter in small case<br>
                                  must contain atleast one special character<br>
                              </div>
                           </div>
                        </div>
                    </th>&nbsp;&nbsp; -->
                    <th style="padding-top: 10px;">
                        <button type="submit" (click)="onSubmit2()" class="default-btn py-2 px-4">Register</button>
                    </th>                                       
                </tr>                              
            </table>
        </form>        
        </div>
        <div *ngIf="div3" class="col-md-12" style="display: flex;justify-content: center;">
            <form [formGroup]="form3">
            <table>
                <tr>
                    <th style="padding-top: 20px;font-size: 18px;">
                        <label style="color: #fd660f;">Teacher 3</label>
                    </th>&nbsp;&nbsp;
                    <th>
                        <div class="form-group">
                            <label>First Name</label>
                            <input type="text" 
                            class="form-control" 
                            formControlName="firstName3"
                            [ngClass]="{'is-invalid': form3Submitted && f3.firstName3.errors}">
                            <div *ngIf="form3Submitted && f3.firstName3.errors  " class="invalid-feedback">
                                <div *ngIf="f3.firstName3.errors?.required" >First Name is required</div>                                
                            </div>
                        </div>                        
                    </th>&nbsp;
                    <th>
                        <div class="form-group">
                            <label>Last Name</label>
                            <input type="text" 
                            class="form-control" 
                            formControlName="lastName3"
                            [ngClass]="{'is-invalid': form3Submitted && f3.lastName3.errors}">
                            <div *ngIf="form3Submitted && f3.lastName3.errors  " class="invalid-feedback">
                                <div *ngIf="f3.lastName3.errors?.required" >Last Name is required</div>                                
                            </div>
                        </div>
                    </th>&nbsp;
                    <th>
                        <div class="form-group">
                            <label>Email</label>
                          <input
                            type="email"
                            id="registerFormEmail"
                            class="form-control"
                            pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$"
                            formControlName="email3"
                            [ngClass]="{'is-invalid': form3Submitted && f3.email3.errors}"                    
                          />
                          <div *ngIf="form3Submitted && f3.email3.errors  " class="invalid-feedback">
                            <div *ngIf="f3.email3.errors?.required" >Email is required</div>
                            <div *ngIf="f3.email3.errors?.pattern">Email must be a valid email</div>
                          </div>
                        </div>
                    </th>&nbsp;
                    <th>
                        <div class="form-group">
                            <label>Mobile Number</label>
                            <input type="text" 
                            formControlName="phoneNumber3"
                            class="form-control" 
                            [ngClass]="{'is-invalid': form3Submitted && f3.phoneNumber3.errors}"/>
                            <div *ngIf="form3Submitted && f3.phoneNumber3.errors" class="invalid-feedback">
                            <div *ngIf="f3.phoneNumber3.errors.required">Mobile No is Required</div>
                            <div *ngIf="f3.phoneNumber3.errors.pattern">Enter only number</div>
                            <div *ngIf="f3.phoneNumber3.errors.minlength">Mobile No must have 10 digits</div>
                            <div *ngIf="f3.phoneNumber3.errors.maxlength">Mobile No cannot have more than 12 digits</div>
                        </div>
                        </div>
                    </th>&nbsp;
                    <!-- <th>
                        <div class="form-group">
                            <label>Password</label>
                            <input type="password"
                            id="registerFormPassword"
                            name="password"
                            autocomplete="new-password"
                            class="form-control"
                            formControlName="password"
                               [ngClass]="{'is-invalid': submitted && f3.password.errors}">
                        
                            <div *ngIf="submitted && f3.password.errors" class="invalid-feedback">
                              <div *ngIf="f3.password.errors.required" >Password is Required</div>
                              <div *ngIf="f3.password.errors?.minlength">Must be atleast 8 characters</div>
                              <div *ngIf="f3.password.errors.pattern">
                                  must contain one number<br>
                                  must contain atleast 1 letter in capital case<br>
                                  must contain atleast 1 letter in small case<br>
                                  must contain atleast one special character<br>
                              </div>
                           </div>
                        </div>
                    </th>&nbsp;&nbsp; -->
                    <th style="padding-top: 10px;">
                        <button type="submit" (click)="onSubmit3()" class="default-btn py-2 px-4">Register</button>
                    </th>                                       
                </tr>                              
            </table>
        </form>        
        </div>
        <div *ngIf="div4" class="col-md-12" style="display: flex;justify-content: center;">
            <form [formGroup]="form4">
            <table>
                <tr>
                    <th style="padding-top: 20px;font-size: 18px;">
                        <label style="color: #fd660f;">Teacher 4</label>
                    </th>&nbsp;&nbsp;
                    <th>
                        <div class="form-group">
                            <label>First Name</label>
                            <input type="text" 
                            class="form-control" 
                            formControlName="firstName4"
                            [ngClass]="{'is-invalid': form4Submitted && f4.firstName4.errors}">
                            <div *ngIf="form4Submitted && f4.firstName4.errors  " class="invalid-feedback">
                                <div *ngIf="f4.firstName4.errors?.required" >First Name is required</div>                                
                            </div>
                        </div>                        
                    </th>&nbsp;
                    <th>
                        <div class="form-group">
                            <label>Last Name</label>
                            <input type="text" 
                            class="form-control" 
                            formControlName="lastName4"
                            [ngClass]="{'is-invalid': form4Submitted && f4.lastName4.errors}">
                            <div *ngIf="form4Submitted && f4.lastName4.errors  " class="invalid-feedback">
                                <div *ngIf="f4.lastName4.errors?.required" >Last Name is required</div>                                
                            </div>
                        </div>
                    </th>&nbsp;
                    <th>
                        <div class="form-group">
                            <label>Email</label>
                          <input
                            type="email"
                            id="registerFormEmail"
                            class="form-control"
                            pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$"
                            formControlName="email4"
                            [ngClass]="{'is-invalid': form4Submitted && f4.email4.errors}"                    
                          />
                          <div *ngIf="form4Submitted && f4.email4.errors  " class="invalid-feedback">
                            <div *ngIf="f4.email4.errors?.required" >Email is required</div>
                            <div *ngIf="f4.email4.errors?.pattern">Email must be a valid email</div>
                          </div>
                        </div>
                    </th>&nbsp;
                    <th>
                        <div class="form-group">
                            <label>Mobile Number</label>
                            <input type="text" 
                            formControlName="phoneNumber4"
                            class="form-control" 
                            [ngClass]="{'is-invalid': form4Submitted && f4.phoneNumber4.errors}"/>
                            <div *ngIf="form4Submitted && f4.phoneNumber4.errors" class="invalid-feedback">
                            <div *ngIf="f4.phoneNumber4.errors.required">Mobile No is Required</div>
                            <div *ngIf="f4.phoneNumber4.errors.pattern">Enter only number</div>
                            <div *ngIf="f4.phoneNumber4.errors.minlength">Mobile No must have 10 digits</div>
                            <div *ngIf="f4.phoneNumber4.errors.maxlength">Mobile No cannot have more than 12 digits</div>
                        </div>
                        </div>
                    </th>&nbsp;
                    <!-- <th>
                        <div class="form-group">
                            <label>Password</label>
                            <input type="password"
                            id="registerFormPassword"
                            name="password"
                            autocomplete="new-password"
                            class="form-control"
                            formControlName="password"
                               [ngClass]="{'is-invalid': submitted && f4.password.errors}">
                        
                            <div *ngIf="submitted && f4.password.errors" class="invalid-feedback">
                              <div *ngIf="f4.password.errors.required" >Password is Required</div>
                              <div *ngIf="f4.password.errors?.minlength">Must be atleast 8 characters</div>
                              <div *ngIf="f4.password.errors.pattern">
                                  must contain one number<br>
                                  must contain atleast 1 letter in capital case<br>
                                  must contain atleast 1 letter in small case<br>
                                  must contain atleast one special character<br>
                              </div>
                           </div>
                        </div>
                    </th>&nbsp;&nbsp; -->
                    <th style="padding-top: 10px;">
                        <button type="submit" (click)="onSubmit4()" class="default-btn py-2 px-4">Register</button>
                    </th>                                       
                </tr>                              
            </table>
        </form>        
        </div>
        <div *ngIf="div5" class="col-md-12" style="display: flex;justify-content: center;">
            <form [formGroup]="form5">
            <table>
                <tr>
                    <th style="padding-top: 20px;font-size: 18px;">
                        <label style="color: #fd660f;">Teacher 5</label>
                    </th>&nbsp;&nbsp;
                    <th>
                        <div class="form-group">
                            <label>First Name</label>
                            <input type="text" 
                            class="form-control" 
                            formControlName="firstName5"
                            [ngClass]="{'is-invalid': form5Submitted && f5.firstName5.errors}">
                            <div *ngIf="form5Submitted && f5.firstName5.errors  " class="invalid-feedback">
                                <div *ngIf="f5.firstName5.errors?.required" >First Name is required</div>                                
                            </div>
                        </div>                        
                    </th>&nbsp;
                    <th>
                        <div class="form-group">
                            <label>Last Name</label>
                            <input type="text" 
                            class="form-control" 
                            formControlName="lastName5"
                            [ngClass]="{'is-invalid': form5Submitted && f5.lastName5.errors}">
                            <div *ngIf="form5Submitted && f5.lastName5.errors  " class="invalid-feedback">
                                <div *ngIf="f5.lastName5.errors?.required" >Last Name is required</div>                                
                            </div>
                        </div>
                    </th>&nbsp;
                    <th>
                        <div class="form-group">
                            <label>Email</label>
                          <input
                            type="email"
                            id="registerFormEmail"
                            class="form-control"
                            pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$"
                            formControlName="email5"
                            [ngClass]="{'is-invalid': form5Submitted && f5.email5.errors}"                    
                          />
                          <div *ngIf="form5Submitted && f5.email5.errors  " class="invalid-feedback">
                            <div *ngIf="f5.email5.errors?.required" >Email is required</div>
                            <div *ngIf="f5.email5.errors?.pattern">Email must be a valid email</div>
                          </div>
                        </div>
                    </th>&nbsp;
                    <th>
                        <div class="form-group">
                            <label>Mobile Number</label>
                            <input type="text" 
                            formControlName="phoneNumber5"
                            class="form-control" 
                            [ngClass]="{'is-invalid': form5Submitted && f5.phoneNumber5.errors}"/>
                            <div *ngIf="form5Submitted && f5.phoneNumber5.errors" class="invalid-feedback">
                            <div *ngIf="f5.phoneNumber5.errors.required">Mobile No is Required</div>
                            <div *ngIf="f5.phoneNumber5.errors.pattern">Enter only number</div>
                            <div *ngIf="f5.phoneNumber5.errors.minlength">Mobile No must have 10 digits</div>
                            <div *ngIf="f5.phoneNumber5.errors.maxlength">Mobile No cannot have more than 12 digits</div>
                        </div>
                        </div>
                    </th>&nbsp;
                    <!-- <th>
                        <div class="form-group">
                            <label>Password</label>
                            <input type="password"
                            id="registerFormPassword"
                            name="password"
                            autocomplete="new-password"
                            class="form-control"
                            formControlName="password"
                               [ngClass]="{'is-invalid': submitted && f5.password.errors}">
                        
                            <div *ngIf="submitted && f5.password.errors" class="invalid-feedback">
                              <div *ngIf="f5.password.errors.required" >Password is Required</div>
                              <div *ngIf="f5.password.errors?.minlength">Must be atleast 8 characters</div>
                              <div *ngIf="f5.password.errors.pattern">
                                  must contain one number<br>
                                  must contain atleast 1 letter in capital case<br>
                                  must contain atleast 1 letter in small case<br>
                                  must contain atleast one special character<br>
                              </div>
                           </div>
                        </div>
                    </th>&nbsp;&nbsp; -->
                    <th style="padding-top: 10px;">
                        <button type="submit" (click)="onSubmit5()" class="default-btn py-2 px-4">Register</button>
                    </th>                                       
                </tr>                              
            </table>
        </form>        
        </div>
        <div *ngIf="div6" class="col-md-12" style="display: flex;justify-content: center;">
            <form [formGroup]="form6">
            <table>
                <tr>
                    <th style="padding-top: 20px;font-size: 18px;">
                        <label style="color: #fd660f;">Teacher 6</label>
                    </th>&nbsp;&nbsp;
                    <th>
                        <div class="form-group">
                            <label>First Name</label>
                            <input type="text" 
                            class="form-control" 
                            formControlName="firstName6"
                            [ngClass]="{'is-invalid': form6Submitted && f6.firstName6.errors}">
                            <div *ngIf="form6Submitted && f6.firstName6.errors  " class="invalid-feedback">
                                <div *ngIf="f6.firstName6.errors?.required" >First Name is required</div>                                
                            </div>
                        </div>                        
                    </th>&nbsp;
                    <th>
                        <div class="form-group">
                            <label>Last Name</label>
                            <input type="text" 
                            class="form-control" 
                            formControlName="lastName6"
                            [ngClass]="{'is-invalid': form6Submitted && f6.lastName6.errors}">
                            <div *ngIf="form6Submitted && f6.lastName6.errors  " class="invalid-feedback">
                                <div *ngIf="f6.lastName6.errors?.required" >Last Name is required</div>                                
                            </div>
                        </div>
                    </th>&nbsp;
                    <th>
                        <div class="form-group">
                            <label>Email</label>
                          <input
                            type="email"
                            id="registerFormEmail"
                            class="form-control"
                            pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$"
                            formControlName="email6"
                            [ngClass]="{'is-invalid': form6Submitted && f6.email6.errors}"                    
                          />
                          <div *ngIf="form6Submitted && f6.email6.errors  " class="invalid-feedback">
                            <div *ngIf="f6.email6.errors?.required" >Email is required</div>
                            <div *ngIf="f6.email6.errors?.pattern">Email must be a valid email</div>
                          </div>
                        </div>
                    </th>&nbsp;
                    <th>
                        <div class="form-group">
                            <label>Mobile Number</label>
                            <input type="text" 
                            formControlName="phoneNumber6"
                            class="form-control" 
                            [ngClass]="{'is-invalid': form6Submitted && f6.phoneNumber6.errors}"/>
                            <div *ngIf="form6Submitted && f6.phoneNumber6.errors" class="invalid-feedback">
                            <div *ngIf="f6.phoneNumber6.errors.required">Mobile No is Required</div>
                            <div *ngIf="f6.phoneNumber6.errors.pattern">Enter only number</div>
                            <div *ngIf="f6.phoneNumber6.errors.minlength">Mobile No must have 10 digits</div>
                            <div *ngIf="f6.phoneNumber6.errors.maxlength">Mobile No cannot have more than 12 digits</div>
                        </div>
                        </div>
                    </th>&nbsp;
                    <!-- <th>
                        <div class="form-group">
                            <label>Password</label>
                            <input type="password"
                            id="registerFormPassword"
                            name="password"
                            autocomplete="new-password"
                            class="form-control"
                            formControlName="password"
                               [ngClass]="{'is-invalid': submitted && f6.password.errors}">
                        
                            <div *ngIf="submitted && f6.password.errors" class="invalid-feedback">
                              <div *ngIf="f6.password.errors.required" >Password is Required</div>
                              <div *ngIf="f6.password.errors?.minlength">Must be atleast 8 characters</div>
                              <div *ngIf="f6.password.errors.pattern">
                                  must contain one number<br>
                                  must contain atleast 1 letter in capital case<br>
                                  must contain atleast 1 letter in small case<br>
                                  must contain atleast one special character<br>
                              </div>
                           </div>
                        </div>
                    </th>&nbsp;&nbsp; -->
                    <th style="padding-top: 10px;">
                        <button type="submit" (click)="onSubmit6()" class="default-btn py-2 px-4">Register</button>
                    </th>                                       
                </tr>                              
            </table>
        </form>        
        </div>
    </div>
</div>
