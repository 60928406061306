<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <img src="assets/img/spinner-default.gif" alt="">
</ngx-spinner>
<app-header-style-one-teacher></app-header-style-one-teacher>
<div class="container">

    <div class="page-title-content">
        <div class="row">
            <div class="col-lg-10 mt-4">
                <h3 style="color: black;">Welcome to buddi.life ! Complete the KYC Process below for the following</h3>
            </div>
        </div>
    </div>
</div>
<section class="login-area pagebackground">
    <div class="m-0">       
        <div class="">
            <section class="my-dashboard-area" style="padding-top: 50px;padding-bottom: 50px;">
                <div class="container">
                    <h6 class="text-center mb-3" *ngIf="isRejected && !kycStatus && newKyc"><span class=" text-danger">Your KYC Request Rejected. Please re-do your KYC</span></h6> <br>
                    <div class="myDashboard-content" *ngIf="newKyc">
                        <form [formGroup]="kycForm">

                            <div class="row">
                                <div class="col-sm-6">
                                    <label>Gender</label>
                                    <div class="form-group">
                                        <select [ngClass]="{ 'is-invalid': isSubmit && f.gender.errors}" class="browser-default custom-select" formControlName="gender">
                                            <option value="" selected>Select Gender</option>
                                            <option>Male</option>
                                            <option>Female</option>
                                            <option>Rather not say</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <label>Your Location</label>
                                    <input type="text" [ngClass]="{ 'is-invalid': isSubmit && f.location.errors}" placeholder="Your Location"formControlName="location" class="meeting-des form-control" >
                                </div>
                                <div class="col-sm-6">
                                    <label>Grade you would Comfortable in Teaching</label>
                                    <div class="form-group">
                                        <ng-multiselect-dropdown [ngClass]="{ 'is-invalid': isSubmit && selectedgradelist.length==0}" [placeholder]="'Select Grades'"   [settings]="dropdowngradeSettings" [data]="listgrade"  [(ngModel)]="selectedgradelist" [ngModelOptions]="{standalone: true}"   (onSelect)="onItemgradeSelect($event)" (onSelectAll)="onItemgradeallSelect($event)" (onDeSelectAll)="onItemgradeallDeSelect($event)" (onDeSelect)="onItemgradeDeSelect($event)" >
                                        </ng-multiselect-dropdown> 
                                        <span class="text-danger" *ngIf="isSubmit && selectedgradelist.length==0">
                                            Please Select GradeList
                                        </span>
                                    </div>
                                    
                                </div>
                                <div class="col-sm-6">
                                    <label>Boards you can handle</label>
                                    <div class="form-group">
                                        <ng-multiselect-dropdown  [ngClass]="{ 'is-invalid': isSubmit && selectedboardlist.length==0}" [placeholder]="'Select Board'" [settings]="dropdownboardSettings" [data]="listboard"  [(ngModel)]="selectedboardlist" [ngModelOptions]="{standalone: true}"  (onSelect)="onItemboardSelect($event)" (onSelectAll)="onItemboardallSelect($event)" (onDeSelectAll)="onItemboardallDeSelect($event)" (onDeSelect)="onItemboardDeSelect($event)" >
                                        </ng-multiselect-dropdown>
                                        <span class="text-danger" *ngIf="isSubmit && selectedboardlist.length==0">
                                            Please Select BoardList
                                        </span>
                                    </div>
                                 
                                </div>
                                <div class="col-sm-6">
                                    <label>Primary subject of teaching</label>
                                    <div class="form-group">
                                        <ng-multiselect-dropdown  [ngClass]="{ 'is-invalid': isSubmit && selectedsubjectlist.length==0}" [placeholder]="'Select Subject'" [settings]="dropdownsubjectSettings" [data]="listsubjects"  [(ngModel)]="selectedsubjectlist" [ngModelOptions]="{standalone: true}"  (onSelect)="onItemsubjectSelect($event)" (onSelectAll)="onItemsubjectallSelect($event)" (onDeSelectAll)="onItemsubjectallDeSelect($event)" (onDeSelect)="onItemsubjectDeSelect($event)" >
                                        </ng-multiselect-dropdown>
                                        <span class="text-danger" *ngIf="isSubmit && selectedsubjectlist.length==0">
                                            Please Select SubjectList
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <label>Total year of Teaching Experience </label>
                                    <input type="number" [ngClass]="{ 'is-invalid': isSubmit && f.yearofexperience.errors}" placeholder="Total year of Teaching Experience "  formControlName="yearofexperience" class="meeting-des form-control" >
                                </div>
                                <div class="col-lg-12">
                                    <label>Available Timing or Hrs available per day for teaching with buddi.life</label>
                                    <span class="text-danger" *ngIf="isSubmit && mergeTwoField.length==0">
                                        Please Select Available Time
                                    </span>
                                    <div class="row">
                                        <div class="col-lg-6">
                                             <label>India Timings</label>
                                            <div class=" form-group" *ngFor="let slide of getAvailableTimesList.other">
                                                <input type="checkbox" (click)="getSelection(slide,$event)"  formControlName="getAvailableTimesUS" class="mr-1">{{slide.time}}
                                        </div>
                                        </div>
                                        
                                        <div class="col-lg-6">
                                            <label>US Timings</label>
                                            <div class="form-group" *ngFor="let slide of getAvailableTimesList.US">
                                                <input type="checkbox" (click)="getSelection(slide,$event)"  formControlName="getAvailableTimes" class="mr-1">{{slide.time}}
                                            </div>
                                        </div>
                                        
                                    </div>                                   
                                </div>  
                                <div class="col-lg-12">
                                    <label>Current Occupation</label>
                                    <span class="text-danger" *ngIf="isSubmit && currentOccupationList.length==0">
                                        Please Select Occupation
                                    </span>
                                    <div class="row">
                                        <div class="col-lg-3 form-group" *ngFor="let slide of currentOccupation">
                                            <input type="checkbox" [checked]="slide.selected" name="currentOccupationChecked" (click)="getSelection(slide,$event)" formControlName="currentOccupation" class="mr-1 currentOccupationChecked" [id]="slide._id"><span>{{slide.occupationName}}</span>
                                        </div>
                                    </div>                                   
                                </div>
                                    
                                 

                                <div class="col-lg-12">
                                    <label>Mode of Engagement</label>
                                    <span class="text-danger" *ngIf="isSubmit && modeofEngagementArray.length==0">
                                        Please select Mode of Engagement
                                    </span>
                                    <div class="row">
                                        <div class="col-lg-3 form-group" *ngFor="let slide of engagementList">
                                            <input [ngClass]="{ 'is-invalid': isSubmit && f.modeofEngagement.errors}" (click)="getSelection(slide,$event)" type="checkbox" formControlName="modeofEngagement" class="mr-1">{{slide.employmentType}}
                                        </div>
                                    </div>                                   
                                </div> 
                                <div class="col-lg-12">
                                    <label>buddi Packages Interested</label>
                                       <span class="text-danger" *ngIf="isSubmit && packageInterestArray.length==0">
                                        Please select buddi Packages Interested
                                    </span>
                                    <div class="row">
                                        <div class="col-lg-3 form-group" *ngFor="let slide of packageInterestList">
                                            <input type="checkbox" [ngClass]="{ 'is-invalid': isSubmit && f.packageInterest.errors}" (click)="getSelection(slide,$event)" formControlName="packageInterest" class="mr-1">{{slide.selectPackageName}}
                                        </div>
                                    </div>                                   
                                </div>   
                                <div class="col-lg-12">
                                    <label>Types of internet Connection</label>
                                     <span class="text-danger" *ngIf="isSubmit && internetConnectionArray.length==0">
                                        Please select Types of internet Connection
                                    </span>
                                    <div class="row">
                                        <div class="col-lg-3 form-group" *ngFor="let slide of internetConnectionList">
                                            <input type="checkbox" [ngClass]="{ 'is-invalid': isSubmit && f.internetConnection.errors}" (click)="getSelection(slide,$event)" formControlName="internetConnection" class="mr-1">{{slide.NetworkConnection}}
                                        </div>
                                    </div>                                   
                                </div>  
                                
                                <div class="col-lg-12">
                                    <label>Available Device</label>
                                     <span class="text-danger" *ngIf="isSubmit && availableDeviceArray.length==0">
                                        Please select Available Device
                                    </span>
                                    <div class="row">
                                        <div class="col-lg-3 form-group" *ngFor="let slide of availableDeviceList">
                                            <input type="checkbox" [ngClass]="{ 'is-invalid': isSubmit && f.availableDevice.errors}" (click)="getSelection(slide,$event)" formControlName="availableDevice" class="mr-1">{{slide.deviceName}}
                                        </div>
                                    </div>                                   
                                </div>  
                                <div class="col-lg-12">
                                    <label>Head phone with MIC</label>
                                    <div class="row">
                                        <div class="col-lg-1 form-group" >
                                            <input type="radio" name="phonemic"  value="Yes" formControlName="phonemic">Yes
                                        </div>
                                        <div class="col-lg-1 form-group" >
                                           <input type="radio" name="phonemic" 
                                            value="No"formControlName="phonemic">No
                                        </div>
                                    </div>                                   
                                </div>  
                                <div class="col-lg-6 mb-4">
                                    <div>
                                        <h6>Resume</h6>
                                    </div>
                                </div>
                                <div class="col-lg-6 input-group">
                                  <div class="custom-file">
                                    <input type="hidden" formControlName="resume"  name="" value="">
                                    <input type="file" [ngClass]="{ 'is-invalid': isSubmit && f.resume.errors}"  class="custom-file-input" accept="application/pdf" (change)="fileChange($event)" id="resume"
                                      aria-describedby="inputGroupFileAddon01">
                                    <label class="custom-file-label" for="resume">{{f.resumeFilename.value}}</label>
                                    </div>
                                </div> 
                            	<div class="col-lg-6 mb-4">
                            		<div>
                            			<h6>Upload Passport Photo(recently taken)</h6>
										( file should be jpg, png 1 MB max)
									</div>
                            	</div>
                                <div class="col-lg-6 input-group">
                                  <div class="custom-file">
                                    <input type="hidden" formControlName="passportPhoto"  name="" value="">
                                    <input type="file" [ngClass]="{ 'is-invalid': isSubmit && f.passportPhoto.errors}"  class="custom-file-input" accept="image/*,application/pdf" (change)="fileChange($event)" id="passportPhoto"
                                      aria-describedby="inputGroupFileAddon01">
                                    <label class="custom-file-label" for="passportPhoto">{{f.passportPhotoFilename.value}}</label>
                               
									</div>
                                </div>                 
                             	<div class="col-lg-6 mb-4">
                            		<div>
                            			<h6>Upload Identity and Address Proof<br> 
										(Aadhaar card – <b>Front Side</b>)</h6>
										( file should be jpg, png, pdf 2MB max)
									</div>
                            	</div>
                                <div class="col-lg-6 input-group">
                                  <div class="custom-file">
                                    <input type="hidden" formControlName="addressProofFront"  name="" value="">
                                    <input type="file" [ngClass]="{ 'is-invalid': isSubmit && f.addressProofFront.errors}"  class="custom-file-input" accept="image/*,application/pdf" (change)="fileChange($event)" id="addressProofFront"
                                      aria-describedby="inputGroupFileAddon01">
                                    <label class="custom-file-label" for="addressProofFront">{{f.addressProofFrontFilename.value}}</label>
                                  </div>
                                </div>   
                                <div class="col-lg-6 mb-4">
                            		<div>
                            			<h6>Upload Identity and Address Proof<br> 
										(Aadhaar card – <b>Back Side</b>)</h6>
										( file should be jpg, png, pdf 2MB max)
									</div>
                            	</div>
                                <div class="col-lg-6 input-group">
                                  <div class="custom-file">
                                    <input type="hidden"   formControlName="addressProofBack"  name="" value="">
                                    <input type="file"  [ngClass]="{ 'is-invalid': isSubmit && f.addressProofBack.errors}" class="custom-file-input" accept="image/*,application/pdf" (change)="fileChange($event)" id="addressProofBack"
                                      aria-describedby="inputGroupFileAddon01">
                                    <label class="custom-file-label" for="addressProofBack">{{f.addressProofBackFilename.value}}</label>
                                  </div>
                                </div> 
                                <div class="col-lg-6 mb-4">
                            		<div>
                            			<h6>Upload PAN</h6>
										( file should be jpg, png, pdf 2MB max)
									</div>
                            	</div>
                                <div class="col-lg-6 input-group">
                                  <div class="custom-file">
                                    <input type="hidden"   formControlName="panCard"  name="" value="">
                                    <input type="file" [ngClass]="{ 'is-invalid': isSubmit && f.panCard.errors}" class="custom-file-input" accept="image/*,application/pdf" (change)="fileChange($event)" id="panCard"
                                      aria-describedby="inputGroupFileAddon01">
                                    <label class="custom-file-label" for="panCard">{{f.panCardFilename.value}}</label>
                                  </div>
                                </div> 
                                <div class="col-lg-6 mb-4">
                            		<div>
                            			<h6>Degree Certificates ( Graduates)</h6>
										( file should be jpg, png, pdf 2MB max)
									</div>
                            	</div>
                                <div class="col-lg-6 input-group">
                                  <div class="custom-file">
                                    <input type="hidden" formControlName="underGraduates"  name="" value="">
                                    <input type="file" [ngClass]="{ 'is-invalid': isSubmit && f.underGraduates.errors}"   class="custom-file-input" accept="image/*,application/pdf" (change)="fileChange($event)" id="underGraduates"
                                      aria-describedby="inputGroupFileAddon01">
                                    <label class="custom-file-label" for="underGraduates">{{f.underGraduatesFilename.value}}</label>
                                  </div>
                                </div> 
                                <div class="col-lg-6 mb-4">
                            		<div>
                            			<h6>Degree Certificates ( Post Graduates)</h6>
										( file should be jpg, png, pdf 2MB max)
									</div>
                            	</div>
                                <div class="col-lg-6 input-group">
                                  <div class="custom-file">
                                    <input type="hidden"   formControlName="postGraduates"  name="" value="">
                                    <input type="file" [ngClass]="{ 'is-invalid': isSubmit && f.postGraduates.errors}" class="custom-file-input" accept="image/*,application/pdf" (change)="fileChange($event)" id="postGraduates"
                                      aria-describedby="inputGroupFileAddon01">
                                    <label class="custom-file-label" for="postGraduates">{{f.postGraduatesFilename.value}}</label>
                                  </div>
                                </div> 
                               
                                <div class="col-lg-6 col-md-6">
                                	 <input type="checkbox"  formControlName="agree" id="agree">
                                	 <label  [ngClass]="{ 'is-invalid': isSubmit && (f.agree.errors==null) || !isTermsAnd }"  for="agree" style="margin-left: 5px;"> I agreed to the <span  [ngClass]="{'text-success': isTermsAnd,'text-danger':!isTermsAnd}" (click)="termsAndCondition()">Terms and Conditions and Privacy Policy</span> of buddi.life</label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <button type="submit" (click)="submitProof()" class="default-btn">
                                        <i class='bx bx-save icon-arrow before'></i>
                                        <span class="label">Submit</span>
                                        <i class="bx bx-save icon-arrow after"></i>
                                    </button>
                                </div>

                            </div>
                        </form>
                    </div>
                    <div *ngIf="!kycStatus && !newKyc && !isRejected">
                    	<div class="container text-center">
                    		<h5>Your KYC Status Pending</h5>
                    	</div>                    	
                    </div>
                    <div *ngIf="!kycStatus && !newKyc && isRejected">
                        <div class="container text-center">
                            <h5>Your KYC Form resubmitted. Status Pending</h5>
                        </div>    
                    </div>
                </div>
            </section>
        </div>
    </div>
</section>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <img src="assets/img/spinner-default.gif" alt="">
</ngx-spinner>
