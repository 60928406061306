<div *ngIf="!isStudent">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>
<div *ngIf="isStudent">
    <app-header-style-one></app-header-style-one>
</div>
<div class="containerBg">
    <div class="container">
        <div class="row mt-5">
            <div class="col-md-12">
                <div class="serviceTitle">
                    <h3 class="col-lg-12" labelStyle>
                            <span class="ml-2" style="color: #000000;">Request a Live Demo </span>
                        <button
                            type="button"
                            class="default-btn float-right "
                            (click)="goBack()"
                            ><i class="fas fa-long-arrow-alt-left kin"></i
                            >&nbsp;Back</button>
                    </h3>
                </div>
                <hr />
            </div>
        </div>
        <div class="container">
            <form [formGroup]="createForm" >
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group mb-3">
                            <label labelStyle>First Name</label>
                            <div class="not">
                            <input  
                                type="text"
                                maxlength="500"
                                formControlName="fname"
                                class="form-control mr-2"
                            >
                            <i class='fas fa-exclamation-circle' style="color: green;" data-toggle="tooltip" data-placement="right" title="Enter your first name"></i>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group mb-3">
                            <label labelStyle>Last Name</label>
                            <div class="not">
                            <input  
                                type="text"
                                maxlength="500"
                                formControlName="lname"
                                class="form-control mr-2"
                            >
                            <i class='fas fa-exclamation-circle' style="color: green;" data-toggle="tooltip" data-placement="right" title="Enter your last name"></i>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group mb-3">
                            <label labelStyle>Company</label>
                            <div class="not">
                            <input  
                                type="text"
                                maxlength="500"
                                formControlName="Company"
                                class="form-control mr-2"
                            >
                            <i class='fas fa-exclamation-circle' style="color: green;" data-toggle="tooltip" data-placement="right" title="Enter your company name"></i>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group mb-3">
                            <label labelStyle>Work Email</label>
                            <div class="not">
                            <input  
                                type="mail"
                                maxlength="500"
                                formControlName="mail"
                                class="form-control mr-2"
                            >
                            <i class='fas fa-exclamation-circle' style="color: green;" data-toggle="tooltip" data-placement="right" title="Enter your mail"></i>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group mb-3">
                            <label labelStyle>Phone</label>
                            <div class="not">
                            <input  
                                type="text"
                                maxlength="500"
                                formControlName="phone"
                                class="form-control mr-2"
                            >
                            <i class='fas fa-exclamation-circle' style="color: green;" data-toggle="tooltip" data-placement="right" title="Enter your phone number"></i>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 description">
                        <div class="text-center mt-3">
                            <button
                                type="button"
                                (click)="onSubmit()"
                                class="default-btn mr-2"
                                id="addMaterialButton"
                            >Submit
                            </button>
                            <button
                            type="button"
                            (click)="goBack()"
                            class="default-btn"
                            id="addMaterialButton"
                        >Close
                        </button>
                        </div>
                    </div>
                </div>
            </form>
            <br />
            <div class="row"></div>
        </div>
    </div>
</div>

<!-- <div class="container">
    <h1>FormSubmit Demo</h1>
    <form target="_blank" action="https://formsubmit.co/ggokulbsc44@email.com" method="POST">
      <div class="form-group">
        <div class="form-row">
          <div class="col">
            <input type="text" name="name" class="form-control" placeholder="Full Name" required>.
            
          </div>
          <div class="col">
            <input type="email" name="email" class="form-control" placeholder="Email Address" required>
          </div>
        </div>
      </div>
      <div class="form-group">
        <textarea placeholder="Your Message" class="form-control" name="message" rows="10" required></textarea>
      </div>
      <button type="submit" class="btn btn-lg btn-dark btn-block">Submit Form</button>
    </form>
  </div> -->