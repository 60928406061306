<div class="container" >
    <!-------------------------------------------- Teacher Video Upload List------------------------------------------------------ -->

    <h3 style="color: var(--mainColor)" >Videos Uploaded</h3>
    <a routerLink="/teacher-video-upload" class="default-btn mb-2 float-right p-2">Add Video</a>

    <div class="container-fluid row p-0" style="cursor: pointer" *ngIf="teacherVideouploadlist.length !=0">
<div class="col-lg-3 col-md-6"
    *ngFor="let slide of teacherVideouploadlist | paginate: { id: 'paginationvideoupload',itemsPerPage: videouploadcount, currentPage: pvideoupload }">
    <div class="single-courses-box without-box-shadow mb-30">
        <div class="courses-image">
            <img style="width:100%" (click)="uploadedvideodetails(slide)"
                style="width: 250px;height: 175px;"
                [src]="slide.thumbnail ? slide.thumbnail : 'assets/img/videocat-thumb-1.jpg'" alt=""
                width="100%">                   
        </div>
        <div class="courses-content">
            <div class="course-author d-flex align-items-center">         
                <span style="word-break: break-all">{{slide.videoName}}
                </span>   
            </div>
            <h3>
                <a (click)="uploadedvideodetails(slide)"
                    class="d-inline-block">
                    <span class="text-break">
                        {{slide.topicID.topicName}}
                    </span>
                </a>      
            </h3>
            <span  *ngIf="slide.status=='Draft'" style="background-color: #e86f19;border-radius: 7px;padding: 0px 3px 0px 3px;color: white;">Draft</span>
        </div>       
          <!-- <div class="courses-box-footer">
            <div>
                Type: {{slide.securitytype =='Group' ? 'School' : slide.securitytype}}
            </div>
        </div>             -->
        <!-- <list5 [slide]="slide"></list5> -->
        <div class="courses-box-footer">
            {{slide.categoryID ? slide.categoryID.categoryName : '' }},
            <!-- {{slide.topicID ? slide.topicID.gradeID.gradeName : '' }} -->
            {{slide.boardID ? slide.boardID.boardName : '' }}
        </div>
        <div class="courses-box-footer">
                <!-- {{slide.topicID ? slide.topicID.subjectID.subjectName : '' }} -->
            {{slide.languageID ? slide.languageID.languageName : '' }}
        </div>
      
    </div>
</div>
</div>
<div style="float: right;" *ngIf="teacherVideouploadlist.length!=0">
<pagination-controls (pageChange)="pvideoupload" id="paginationvideoupload"></pagination-controls>
</div>
<div *ngIf="teacherVideouploadlist.length ==0">
No Uploaded Video(s) Available.
</div>
<div *ngIf="teacherVideouploadlist.length!=0">
<br>
<hr>
</div>

<!-------------------------------------------- End Of Teacher Video Upload List------------------------------------------------------ -->

   <!-------------------------------------------- Teacher SmartMaterial Upload List------------------------------------------------------ -->

   <h3 style="color: var(--mainColor)" >Smart Materials Uploaded</h3>
   <a routerLink="/teacher-smartmaterial-upload" class="default-btn mb-2 float-right p-2">Add Material</a>

   <div class="container-fluid row p-0" style="cursor: pointer" *ngIf="teachersmartmaterialuploadlist.length !=0">
<div class="col-lg-3 col-md-6"
   *ngFor="let slide of teachersmartmaterialuploadlist | paginate: { id: 'paginationsmartupload',itemsPerPage: smartuploadcount, currentPage: psmartupload }">
   <div class="single-courses-box without-box-shadow mb-30">
       <div class="courses-image">
           <img style="width:100%" (click)="smartmaterialdetails(slide)"
               style="width: 250px;height: 175px;"
               [src]="slide.thumbnail ? slide.thumbnail : 'assets/img/videocat-thumb-1.jpg'" alt=""
               width="100%">                   
       </div>
       <div class="courses-content">
           <div class="course-author d-flex align-items-center">
               
               <span style="word-break: break-all">{{slide.materialName}}
               </span>   
           </div>
           <h3>
               <a (click)="smartmaterialdetails(slide)"
                   class="d-inline-block">
                   <span class="text-break">
                       {{slide.topicID.topicName}}
                   </span>
               </a>      
           </h3>
           <span  *ngIf="slide.status=='Draft'" style="background-color: #e86f19;border-radius: 7px;padding: 0px 3px 0px 3px;color: white;">Draft</span>
       </div>       
         <!-- <div class="courses-box-footer">
           <div>
               Type: {{slide.securitytype =='Group' ? 'School' : slide.securitytype}}
           </div>
       </div>             -->
       <!-- <list5 [slide]="slide"></list5> -->
       <div class="courses-box-footer">
           {{slide.categoryID ? slide.categoryID.categoryName : '' }},
           <!-- {{slide.topicID ? slide.topicID.gradeID.gradeName : '' }} -->
           {{slide.boardID ? slide.boardID.boardName : '' }}
       </div>
       <div class="courses-box-footer">
               <!-- {{slide.topicID ? slide.topicID.subjectID.subjectName : '' }} -->
           {{slide.languageID ? slide.languageID.languageName : '' }}
       </div>
     
   </div>
</div>
</div>
<div style="float: right;" *ngIf="teachersmartmaterialuploadlist.length!=0">
<pagination-controls (pageChange)="psmartupload" id="paginationsmartupload"></pagination-controls>
</div>
<div *ngIf="teachersmartmaterialuploadlist.length ==0">
No Uploaded Smart Material(s) Available.
</div>
<div *ngIf="teachersmartmaterialuploadlist.length!=0">
<br>
<hr>
</div>

<!-------------------------------------------- End Of Teacher SmartMaterial Upload List------------------------------------------------------ -->

 <!-------------------------------------------- Teacher Test Upload List------------------------------------------------------ -->

 <h3 style="color: var(--mainColor)" >Tests Uploaded</h3>
 <a routerLink="/teacher-test-upload" class="default-btn mb-2 float-right p-2">Add Test</a>

 <div class="container-fluid row p-0" style="cursor: pointer" *ngIf="teachertestuploadlist.length !=0">
<div class="col-lg-3 col-md-6"
 *ngFor="let slide of teachertestuploadlist | paginate: { id: 'paginationtestupload',itemsPerPage: testuploadcount, currentPage: ptestupload }">
 <div class="single-courses-box without-box-shadow mb-30">
     <div class="courses-image">
         <img style="width:100%" (click)="testdetails(slide)"
             style="width: 250px;height: 175px;"
             [src]="slide.thumbnail ? slide.thumbnail : 'assets/img/videocat-thumb-1.jpg'" alt=""
             width="100%">                   
     </div>
     <div class="courses-content">
         <div class="course-author d-flex align-items-center">
             
             <span style="word-break: break-all">{{slide.testName}}
             </span>   
         </div>
         <h3>
             <a (click)="testdetails(slide)"
                 class="d-inline-block">
                 <span class="text-break">
                     {{slide.topicID.topicName}}
                 </span>
             </a>      
         </h3>
         <span  *ngIf="slide.status=='Draft'" style="background-color: #e86f19;border-radius: 7px;padding: 0px 3px 0px 3px;color: white;">Draft</span>
     </div>       
       <!-- <div class="courses-box-footer">
         <div>
             Type: {{slide.securitytype =='Group' ? 'School' : slide.securitytype}}
         </div>
     </div>             -->
     <!-- <list5 [slide]="slide"></list5> -->
     <div class="courses-box-footer">
         {{slide.categoryID ? slide.categoryID.categoryName : '' }},
         <!-- {{slide.topicID ? slide.topicID.gradeID.gradeName : '' }} -->
         {{slide.boardID ? slide.boardID.boardName : '' }}
     </div>
     <div class="courses-box-footer">
             <!-- {{slide.topicID ? slide.topicID.subjectID.subjectName : '' }} -->
         {{slide.languageID ? slide.languageID.languageName : '' }}
     </div>
   
 </div>
</div>
</div>
<div style="float: right;" *ngIf="teachertestuploadlist.length!=0">
<pagination-controls (pageChange)="ptestupload" id="paginationtestupload"></pagination-controls>
</div>
<div *ngIf="teachertestuploadlist.length ==0">
No Uploaded Test(s) Available.
</div>
<div *ngIf="teachertestuploadlist.length!=0">
<br>
<hr>
</div>

<!-------------------------------------------- End Of Teacher Test Upload List------------------------------------------------------ -->

 <!--------------------------------------------Teacher Test Correction List------------------------------------------------------ -->

 <h3 style="color: var(--mainColor)" >Test(s) to be Corrected</h3>

 <div class="container-fluid row p-0" style="cursor: pointer" *ngIf="teachertestcorrectionlist && teachertestcorrectionlist.length !=0">
<div class="col-lg-3 col-md-6"
 *ngFor="let slide of teachertestcorrectionlist | paginate: { id: 'paginationtestcorrect',itemsPerPage: testcorrectcount, currentPage: ptestcorrect }">
 <div class="single-courses-box without-box-shadow mb-30">
     <div class="courses-image">
         <img style="width:100%" (click)="testcorrectiondetails(slide)"
             style="width: 250px;height: 175px;"
             [src]="slide.testID.thumbnail ? slide.testID.thumbnail : 'assets/img/videocat-thumb-1.jpg'" alt=""
             width="100%">                   
     </div>
     <div class="courses-content">
         <div class="course-author d-flex align-items-center">
             
             <span style="word-break: break-all">
                <!-- {{slide.testID.testName}} -->
             </span>   
         </div>
         <h3>
             <a (click)="testcorrectiondetails(slide)"
                 class="d-inline-block">
                 <span class="text-break">
                     {{slide.testID.testName}}
                 </span>
             </a>      
         </h3>
     </div>       
      
     <div class="courses-box-footer">
         {{slide.dateTaken |  date :"MMM dd,yyyy, hh:mm aaa"}}
     
     </div>
   
   
 </div>
</div>
</div>
<div style="float: right;" *ngIf="teachertestcorrectionlist && teachertestcorrectionlist.length!=0">
<pagination-controls (pageChange)="ptestcorrect" id="paginationtestcorrect"></pagination-controls>
</div>
<div *ngIf="teachertestcorrectionlist && teachertestcorrectionlist.length ==0">
No Test(s) for Correction Available.
</div>
<div *ngIf="teachertestcorrectionlist && teachertestcorrectionlist.length!=0">
<br>
<hr>
</div>

<!-------------------------------------------- End Of Teacher Test Correction List------------------------------------------------------ -->
</div>