

<!-- Header Student or Teacher -->
<div *ngIf="!isTeacher">
  <app-header-style-one></app-header-style-one>
</div>
<div *ngIf="isTeacher">
  <app-header-style-one-teacher></app-header-style-one-teacher>
</div>
<!-- Header Student or Teacher -->


<div class="container  mt-3">
  <div class="container">
    <form [formGroup]="exploreForm">
      <div class="searchFixed">
        <div class="container">
          <div class="row ">
            <div class="col-lg-12 mb-2">          
              <div class="row mt-2"  style="padding-bottom: 2%;">
                <div class="col-lg-5">
                </div>
                <div class="col-lg-1 col-sm-1 col-xs-1 col-md-1 p-0 pt-1">
                  <p class="font12size" *ngIf="studentIsSchool &&  itemNameFromRoute!='buddi'" style="font-weight: 600;color:var(--mainColor);font-size: 18px">
                    <input type="checkbox" (click)="changeRole(true)" formControlName="meetingType">Only schools
                  </p>
                </div>  
                <div class="col-lg-3 pl-0 description">
                  <div class="form-group"> 
                    <div class="ng-autocomplete ml-3">
                      <input class="form-control searchByDrop" type="text" formControlName="searchby" (focus)="onFocused($event)" (keyup)="searchbyName($event)">
                      <div class="scrollable-menu  form-control searchByDrop" *ngIf="!selectItem">
                        <ul class="searchByDrop list-unstyled" *ngFor="let item of dataList" >
                          <li [innerHTML]="item.flimname" class="searchByDrop" (click)="selectItemClick(item)" *ngIf="itemNameFromRoute=='video' || itemNameFromRoute=='audio' || itemNameFromRoute=='ebook' || itemNameFromRoute=='videoyoutube'|| itemNameFromRoute=='solution' || itemNameFromRoute=='question'"></li>
                          <li [innerHTML]="item.name" class="searchByDrop" (click)="selectItemClick(item)"   *ngIf="itemNameFromRoute=='class' || itemNameFromRoute=='buddi'"></li>
                          <li [innerHTML]="item.fullName"  class="searchByDrop" (click)="selectItemClick(item)" *ngIf="itemNameFromRoute=='course'"></li>
                          <li [innerHTML]="item.testName"  class="searchByDrop" (click)="selectItemClick(item)" *ngIf="itemNameFromRoute=='tests'"></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>        
                <div class="col-lg-1 col-sm-2 col-xs-2 col-md-2 description">
                  <a  (click)="backto()" class="default-btn backButton">
                    <span class="">Back</span>
                  </a>
                </div> 
              </div>
            </div>
            <div class="col-lg-12 row">
              <div class="col-md-2 description" *ngIf="coursecategorylist.length !=0">
                <div class="form-group">
                  <label style="color:var(--mainColor)">Category Name</label>
                  <select class="form-control" (change)="selectChange($event)" formControlName="categoryID">
                    <option *ngFor="let obj of coursecategorylist" [ngValue]="obj._id">{{obj.categoryName}}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-2 description" *ngIf="gradeapi.length !=0">
                <div class="form-group">
                  <label style="color:var(--mainColor)">Grade</label>
                  <select class="form-control" (change)="selectChange($event)" formControlName="gradeID">
                    <option value="all">All</option>
                    <option *ngFor="let obj of gradeapi" [ngValue]="obj._id">{{obj.gradeName}}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-2 description" *ngIf="courseboardlist.length !=0">
                <div class="form-group">
                  <label style="color:var(--mainColor)">Board</label>
                  <select class="form-control" (change)="selectChange($event)" formControlName="boardID">
                    <option value="all">All</option>
                    <option *ngFor="let obj of courseboardlist" [ngValue]="obj._id">{{obj.boardName}}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-2 description" *ngIf="coursesubjectlist.length !=0">
                <div class="form-group">
                  <label style="color:var(--mainColor)">Subject</label>
                  <select class="form-control" (change)="selectChange($event)" formControlName="subjectID">
                    <option value="all">All</option>
                    <option *ngFor="let obj of coursesubjectlist" [ngValue]="obj._id">{{obj.subjectName}}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-2 description" *ngIf="languageapi.length !=0">
                <div class="form-group">
                  <label style="color:var(--mainColor)">Language</label>
                  <select class="form-control" (change)="selectChange($event)" formControlName="languageID">
                    <option value="all">All</option>
                    <option *ngFor="let obj of languageapi" [ngValue]="obj._id">{{obj.languageName}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <hr>
  <div class="classSpace"></div>
    <h3 class="col-lg-12 mt-5 p-0" style="color:var(--mainColor)" *ngIf="itemNameFromRoute=='class'">Classes</h3>
    <div class="row container p-0" *ngIf="dataList !=undefined && dataList.length !=0 && itemNameFromRoute=='class'">
    <div class="col-lg-3 col-md-6"
      *ngFor="let slide of dataList | paginate: config">
      <div class="single-courses-box without-box-shadow mb-30">
        <div class="courses-image">
          <a (click)="detailsMeeting(slide,'Livesession')" class="d-block">
            <img
              [src]="slide.image =='' ? 'assets/img/courses/courses1.jpg':slide.image"
              style="width: 250px;height: 175px;" alt="image">
          </a>
        </div>
        <div class="courses-content">
          <div class="course-author d-flex align-items-center">
            <img
              [src]="slide.teacherID!=undefined && slide.teacherID.userPhoto!='' ? slide.teacherID.userPhoto :'assets/img/user1.jpg'"
              class="rounded-circle mr-2" alt="image">
              <span *ngIf="slide.teacherID!=undefined">{{slide.teacherID.firstName}}</span>
          </div>
          <div class="course-author d-flex align-items-center">
            <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image">{{slide.ScheduleDate |  date :"MMM dd,yyyy, hh:mm aaa"}}
          </div>
          <h3>
            <a (click)="detailsMeeting(slide,'Livesession')" class="d-inline-block"><span class="text-break">{{slide.name}}</span></a>
          </h3>
        </div>
        <div>
          {{slide.categoryID ? slide.categoryID.categoryName : '' }}, {{slide.gradeID ? slide.gradeID.gradeName : '' }},
          {{slide.boardID ? slide.boardID.boardName : '' }}
        </div>
        <div class="courses-box-footer">
          {{slide.subjectID ? slide.subjectID.subjectName : '' }},
          {{slide.languageID ? slide.languageID.languageName : '' }}
        </div>
      </div>
    </div>
  </div>
  <pagination-controls class="float-right" [id]="config.id" *ngIf="itemNameFromRoute=='class' && dataList.length!=0" (pageChange)="pageChanged($event)"></pagination-controls>
  <div class="col-lg-12" style="color:var(--mainColor)" *ngIf="dataList ==undefined || dataList.length ==0 && itemNameFromRoute=='class'">
    No Class(es) Available.
  </div>
  <br *ngIf="itemNameFromRoute=='class'">
<hr *ngIf="itemNameFromRoute=='class'">

  <h3 class="col-lg-12 mt-5 p-0" style="color:var(--mainColor)" *ngIf="itemNameFromRoute=='buddi'">buddi Group Classes</h3>
  <div class="row container p-0" *ngIf="dataList.length!=0 && itemNameFromRoute=='buddi'">
    <div class="col-lg-3 col-md-6"
      *ngFor="let slide of dataList  | paginate: config">
      <div class="single-courses-box without-box-shadow mb-30">
        <div class="courses-image">
          <a (click)="buddiGroupRedirect(slide)" class="d-block">
            <img [src]="'assets/img/courses/courses1.jpg'" style="width: 250px;height: 175px;" alt="image"></a>

        </div>
        <div class="courses-content">
          <div class="course-author d-flex align-items-center">
              <img [src]="slide.instructor!=undefined && slide.instructor.userPhoto!='' ? slide.instructor.userPhoto :'assets/img/user1.jpg'"class="rounded-circle mr-2" alt="image">
              <span *ngIf="slide.instructor!=undefined">{{slide.instructor.firstName}}</span>
            </div>
          <div class="course-author d-flex align-items-center">
           
            <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image">
            {{slide.startTime |  date :"MMM dd,yyyy"}} | {{slide.endTime |  date :"MMM dd,yyyy"}}
          </div>
          <h3>
            <a (click)="detailsMeeting(slide,'Livesession')" class="d-inline-block"><span class="text-break">{{slide.name}}</span></a>
          </h3>

        </div>

        <div>
          {{slide.categoryID ? slide.categoryID.categoryName : '' }}, {{slide.gradeID ? slide.gradeID.gradeName : '' }},
          {{slide.boardID ? slide.boardID.boardName : '' }}
        </div>
        <div class="courses-box-footer">
          {{slide.subjectID ? slide.subjectID.subjectName : '' }},
          {{slide.languageID ? slide.languageID.languageName : '' }}
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-12 p-0" style="color:var(--mainColor)" *ngIf="dataList.length ==0 && itemNameFromRoute=='buddi'">
    No buddi Group Class(es) Available.
  </div>
  <pagination-controls class="float-right" [id]="config.id" *ngIf="itemNameFromRoute=='buddi' && dataList.length!=0" (pageChange)="pageChanged($event)"></pagination-controls>
<br *ngIf="itemNameFromRoute=='buddi'">
<hr *ngIf="itemNameFromRoute=='buddi'">


  <h3 class="col-lg-12 mt-5 p-0" style="color:var(--mainColor)" *ngIf="itemNameFromRoute=='course'">Courses</h3>
  <div class="row container" *ngIf="itemNameFromRoute=='course'" style="cursor: pointer;border-bottom: 1px solid #d2d0d0">
    <div class="col-lg-3 col-md-6"
      *ngFor="let slidecp of courseData |filter:f.searchby.value| paginate: { id: 'pagination1',itemsPerPage: count, currentPage: p }">
      <div class="single-courses-box without-box-shadow mb-30">
        <div class="courses-image">
          <a (click)="coursedetails(slidecp)" class="d-block"><img
              [src]="slidecp.courseImage =='' ? 'assets/img/courses/courses1.jpg':slidecp.courseImage"
              style="width: 250px;height: 175px;" alt="image"></a>

        </div>
        <div class="courses-content">
          <div class="course-author d-flex align-items-center">
            <img [src]="slidecp.createdBy!=undefined && slidecp.createdBy.userPhoto!='' ? slidecp.createdBy.userPhoto :'assets/img/user1.jpg'" class="rounded-circle mr-2" alt="image">
            <span>{{slidecp.createdBy ? slidecp.createdBy.firstName : null }}</span>
          </div>
          <div class="course-author d-flex align-items-center">
            <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image"
              style="width: 30px;height: 30px;">
            <span>{{slidecp.startDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</span>
          </div>
          <h3><a (click)="coursedetails(slidecp)" class="d-inline-block">{{slidecp.fullName}}</a></h3>

        </div>
        <div class="courses-box-footer">
          {{slidecp.categoryID ? slidecp.categoryID.categoryName : 'N/A' }} ,
          {{slidecp.gradeID ? slidecp.gradeID.gradeName : 'N/A' }} ,
          {{slidecp.boardID ? slidecp.boardID.boardName : 'N/A' }}
          <br>
          {{slidecp.subjectID ? slidecp.subjectID.subjectName : 'N/A' }} ,
          {{slidecp.languageID ? slidecp.languageID.languageName : 'N/A' }}

        </div>
      </div>
    </div>
    <div class="col-lg-12" *ngIf="(courseData |filter:f.searchby.value).length==0 && itemNameFromRoute=='course'" style="padding-bottom: 3%;color:var(--mainColor)">
      No Course(s) Available.
    </div>
    <div class="col-lg-12 col-md-12" *ngIf="courseData?.length > 0 && itemNameFromRoute=='course'">
      <div style="float: right;">
        <pagination-controls (pageChange)="p = $event" id="pagination1"></pagination-controls>
      </div>
    </div>
  </div>
  <h3 *ngIf="istests" style="padding-top: 5%;color:var(--mainColor)">Tests</h3>
  <div class="row container" *ngIf="istests" style="cursor: pointer">
  <div *ngIf="isevaluation" class="col-lg-12 col-md-12" style="cursor: pointer;border-bottom: 1px solid #d2d0d0" >
    <h5 style="text-align: center;margin-bottom: 2%;color:var(--mainColor)">Evaluation</h5>
    <div class="row">
    <div class="col-lg-3 col-md-6"
    *ngFor="let slidetest1 of testevaluationData |filter:f.searchby.value| paginate: { id: 'paginationtest1',itemsPerPage: testcount1, currentPage: test1 }">
    <div class="single-courses-box without-box-shadow mb-30">
      <div class="courses-image">
        <a (click)="gettestdetails(slidetest1._id)" class="d-block"><img src='assets/img/courses/courses1.jpg'
            style="width: 250px;height: 175px;" alt="image"></a>

      </div>
      <div class="courses-content">
        <div class="course-author d-flex align-items-center">
          <img src="assets/img/usercourse.png" class="rounded-circle mr-2" alt="image">
          <span>{{slidetest1.category ? slidetest1.category.categoryName : 'Not Applicable'}}</span>
        </div>
        <div class="course-author d-flex align-items-center">
          <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image"
            style="width: 30px;height: 30px;">
          <span>{{slidetest1.scheduledDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</span>
        </div>
        <h3><a  class="d-inline-block">{{slidetest1.testName}}</a></h3>

      </div>
      <div class="courses-box-footer" >
        
        {{slidetest1.subjectString}}
      </div>
    </div>
  </div>
</div>
  <div style="color:var(--mainColor)" *ngIf="testevaluationData?.length < 1 && istests">
    No Evaluation Tests Available.
  </div>
  <div class="col-lg-12 col-md-12" *ngIf="testevaluationData?.length > 0 && istests">
    <div style="float: right;">
      <pagination-controls (pageChange)="test1 = $event" id="paginationtest1"></pagination-controls>
    </div>
  </div>  
</div>

<div *ngIf="isbenchmark" class="col-lg-12 col-md-12" style="cursor: pointer;border-bottom: 1px solid #d2d0d0;margin-bottom: 2%;">
  <h5 style="text-align: center;margin-top: 2%;color:var(--mainColor)" >Benchmarking</h5>
  <div class="row">
  <div class="col-lg-3 col-md-6"
  *ngFor="let slidetest2 of testbenchmarkingData|filter:f.searchby.value | paginate: { id: 'paginationtest2',itemsPerPage: testcount2, currentPage: test2 }">
  <div class="single-courses-box without-box-shadow mb-30">
    <div class="courses-image">
      <a  (click)="gettestdetails(slidetest2._id)" class="d-block"><img src='assets/img/courses/courses1.jpg'
          style="width: 250px;height: 175px;" alt="image"></a>

    </div>
    <div class="courses-content">
      <div class="course-author d-flex align-items-center">
        <img src="assets/img/usercourse.png" class="rounded-circle mr-2" alt="image">
        <span>{{slidetest2.category ? slidetest2.category.categoryName : 'Not Applicable'}}</span>
      </div>
      <div class="course-author d-flex align-items-center">
        <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image"
          style="width: 30px;height: 30px;">
        <span>{{slidetest2.scheduledDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</span>
      </div>
      <h3><a  class="d-inline-block">{{slidetest2.testName}}</a></h3>

    </div>
    <div class="courses-box-footer">
      {{slidetest2.subjectString}}       
    </div>
  </div>
</div>
</div>
<div style="color:var(--mainColor)" *ngIf="testbenchmarkingData?.length < 1 && istests">
  No Benchmarking Tests Available.
</div>
<div class="col-lg-12 col-md-12" *ngIf="testbenchmarkingData?.length > 0 && istests">
  <div style="float: right;">
    <pagination-controls (pageChange)="test2 = $event" id="paginationtest2"></pagination-controls>
  </div>
</div>  
</div>

<div *ngIf="ispreviousyear" class="col-lg-12 col-md-12" style="cursor: pointer;border-bottom: 1px solid #d2d0d0;margin-bottom: 2%;">
  <h5 style="text-align: center;margin-top: 2%;color:var(--mainColor)">Previous Year</h5>
  <div class="row">
<div class="col-lg-3 col-md-6"
  *ngFor="let slidetest3 of testpreviousyearData |filter:f.searchby.value| paginate: { id: 'paginationtest3',itemsPerPage: testcount3, currentPage: test3 }">
  <div class="single-courses-box without-box-shadow mb-30">
    <div class="courses-image">
      <a  (click)="gettestdetails(slidetest3._id)" class="d-block"><img src='assets/img/courses/courses1.jpg'
          style="width: 250px;height: 175px;" alt="image"></a>

    </div>
    <div class="courses-content">
      <div class="course-author d-flex align-items-center">
        <img src="assets/img/usercourse.png" class="rounded-circle mr-2" alt="image">
        <span>{{slidetest3.category ? slidetest3.category.categoryName : 'Not Applicable'}}</span>
      </div>
      <div class="course-author d-flex align-items-center">
        <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image"
          style="width: 30px;height: 30px;">
        <span>{{slidetest3.scheduledDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</span>
      </div>
      <h3><a  class="d-inline-block">{{slidetest3.testName}}</a></h3>

    </div>
    <div class="courses-box-footer">
      {{slidetest3.subjectString}}       
    </div>
  </div>
</div>
</div>
<div style="color:var(--mainColor)" *ngIf="testpreviousyearData?.length < 1 && istests">
  No Previous Year Tests Available.
</div>
<div class="col-lg-12 col-md-12" *ngIf="testpreviousyearData?.length > 0 && istests">
  <div style="float: right;">
    <pagination-controls (pageChange)="test3 = $event" id="paginationtest3"></pagination-controls>
  </div>
</div>
</div>

<div *ngIf="isprogressive" class="col-lg-12 col-md-12" style="cursor: pointer;border-bottom: 1px solid #d2d0d0;margin-bottom: 2%;">
  <h5 style="text-align: center;margin-top: 2%;color:var(--mainColor)">Progressive</h5>
  <div class="row">
<div class="col-lg-3 col-md-6"
  *ngFor="let slidetest4 of testprogressiveData |filter:f.searchby.value| paginate: { id: 'paginationtest4',itemsPerPage: testcount4, currentPage: test4 }">
  <div class="single-courses-box without-box-shadow mb-30">
    <div class="courses-image">
      <a (click)="gettestdetails(slidetest4._id)" class="d-block"><img src='assets/img/courses/courses1.jpg'
          style="width: 250px;height: 175px;" alt="image"></a>

    </div>
    <div class="courses-content">
      <div class="course-author d-flex align-items-center">
        <img src="assets/img/usercourse.png" class="rounded-circle mr-2" alt="image">
        <span>{{slidetest4.category ? slidetest4.category.categoryName : 'Not Applicable'}}</span>
      </div>
      <div class="course-author d-flex align-items-center">
        <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image"
          style="width: 30px;height: 30px;">
        <span>{{slidetest4.scheduledDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</span>
      </div>
      <h3><a  class="d-inline-block">{{slidetest4.testName}}</a></h3>

    </div>
    <div class="courses-box-footer">
      {{slidetest4.subjectString}}       
    </div>
  </div>
</div>
</div>
<div style="color:var(--mainColor)" *ngIf="testprogressiveData?.length < 1 && istests">
  No Progressive Data Available.
</div>
<div class="col-lg-12 col-md-12" *ngIf="testprogressiveData?.length > 0 && istests">
  <div style="float: right;">
    <pagination-controls (pageChange)="test4 = $event" id="paginationtest4"></pagination-controls>
  </div>
</div>
</div>
  </div>

  
  <div class="container" style="padding-top: 2%;">

  </div>


  <!-- Ebook Start -->
  <h3 class="col-lg-12 mt-5" style="color:var(--mainColor)" *ngIf="itemNameFromRoute=='ebook'">
    e-Books
  </h3>
  <div class="container-fluid row" style="cursor: pointer" *ngIf="dataList.length !=0 && itemNameFromRoute=='ebook'">

    <div class="col-lg-3 col-md-6" 
      *ngFor="let slide of dataList| paginate: config">
      <div class="single-courses-box without-box-shadow mb-30">
        <div class="courses-image">
          <img style="width:100%" (click)="ebookdetails(slide)"
            [src]="slide.thumbname ? slide.thumbname : 'assets/img/videocat-thumb-1.jpg'" alt="" width="100%">
        </div>
        <div class="courses-content">
          <div class="course-author d-flex align-items-center" *ngIf="slide.studentID !=undefined">
            <img
              [src]="slide.studentID!=undefined && slide.studentID.userPhoto!='' ? slide.studentID.userPhoto  :'assets/img/user1.jpg'"
              class="rounded-circle mr-2" alt="image">
            <span *ngIf="slide.studentID!=undefined">{{slide.studentID.profileName}}
            </span>
          </div>
          <div class="course-author d-flex align-items-center" *ngIf="slide.studentID ==undefined">
            <img
              [src]="slide.teacherID!=undefined && slide.teacherID.userPhoto!='' ? slide.teacherID.userPhoto :'assets/img/user1.jpg'"
              class="rounded-circle mr-2" alt="image">
            <span *ngIf="slide.teacherID!=undefined">{{slide.teacherID.profileName}}
            </span>
          </div>
          <h3>
            <a (click)="ebookdetails(slide)" class="d-inline-block"><span class="text-break">{{slide.flimname}}</span></a>

          </h3>

        </div>
        <div class="courses-box-footer" style="word-break: break-all">
          {{slide.categoryID ? slide.categoryID[0].categoryName : '' }}, {{slide.gradeID ? slide.gradeID[0].gradeName : '' }},
          {{slide.boardID ? slide.boardID.boardName : '' }}
        </div>
        <div class="courses-box-footer" *ngIf="slide.subjectID.length > 0" style="word-break: break-all">
          {{slide.subjectID ? slide.subjectID[0].subjectName : '' }},
          {{slide.languageID ? slide.languageID.languageName : '' }}
        </div>
        <div class="courses-box-footer" *ngIf="slide.subjectID.length == 0" style="word-break: break-all">
          {{'' }},
          {{slide.languageID ? slide.languageID.languageName : '' }}
        </div>
        <div *ngIf="slide.isPartner" class="courses-box-footer" style="word-break: break-all">
          Provided By : {{slide.isbuddi_premium? 'buddi Premium' : slide.partner.partnerName}}
        </div>
            <!-- <list5 [slide]="slide"></list5> -->
      </div>
    </div>

  </div>
   <pagination-controls [id]="config.id" class="float-right" *ngIf="dataList.length !=0 && itemNameFromRoute=='ebook'" (pageChange)="pageChanged($event)"></pagination-controls>
  <div style="color:var(--mainColor)" class="col-lg-12" *ngIf="dataList.length ==0 && itemNameFromRoute=='ebook'">
    No e-Book(s) Available.
  </div>
  <br *ngIf="itemNameFromRoute=='ebook'">
<hr *ngIf="itemNameFromRoute=='ebook'">
  <!-- Ebook End -->


  <!-- 
    Audio Start
 -->
  <h3 style="color:var(--mainColor)" class="col-lg-12" *ngIf="itemNameFromRoute=='audio'">
    Audio Classes
  </h3>
  <div class="container-fluid row" style="cursor: pointer" *ngIf="dataList.length !=0 && itemNameFromRoute=='audio'">

    <div class="col-lg-3 col-md-6"
      *ngFor="let slide of dataList| paginate: config">
      <div class="single-courses-box without-box-shadow mb-30">
        <div class="courses-image">
          <img style="width:100%" (click)="audiodetails(slide)"
            [src]="slide.thumbname ? slide.thumbname : 'assets/img/videocat-thumb-1.jpg'" alt="" width="100%">
        </div>
        <div class="courses-content">
          <div class="course-author d-flex align-items-center" *ngIf="slide.studentID !=undefined">
            <img
              [src]="slide.studentID!=undefined && slide.studentID.userPhoto!='' ? slide.studentID.userPhoto  :'assets/img/user1.jpg'"
              class="rounded-circle mr-2" alt="image">
            <span *ngIf="slide.studentID!=undefined">{{slide.studentID.profileName}}
            </span>
          </div>
          <div class="course-author d-flex align-items-center" *ngIf="slide.studentID ==undefined">
            <img
              [src]="slide.teacherID!=undefined && slide.teacherID.userPhoto!='' ? slide.teacherID.userPhoto  :'assets/img/user1.jpg'"
              class="rounded-circle mr-2" alt="image">
            <span *ngIf="slide.teacherID!=undefined">{{slide.teacherID.profileName}}
            </span>
          </div>
          <h3>
            <a (click)="audiodetails(slide)" class="d-inline-block"><span class="text-break">{{slide.flimname}}</span></a>

          </h3>

        </div>
            <!-- <list5 [slide]="slide" [hideData]=true></list5> -->
        <div class="courses-box-footer" style="word-break: break-all">
          {{slide.categoryID ? slide.categoryID[0].categoryName : '' }}, {{slide.gradeID ? slide.gradeID[0].gradeName : '' }},
          {{slide.boardID ? slide.boardID.boardName : '' }}
        </div>
        <div class="courses-box-footer" *ngIf="slide.subjectID.length > 0" style="word-break: break-all">
          {{slide.subjectID ? slide.subjectID[0].subjectName : '' }},
          {{slide.languageID ? slide.languageID.languageName : '' }}
        </div>
        <div class="courses-box-footer" *ngIf="slide.subjectID.length == 0" style="word-break: break-all">
          {{'' }},
          {{slide.languageID ? slide.languageID.languageName : '' }}
        </div>
        <div *ngIf="slide.isPartner" class="courses-box-footer" style="word-break: break-all">
          Provided By : {{slide.isbuddi_premium? 'buddi Premium' : slide.partner.partnerName}}
        </div>
      </div>
    </div>

  </div>
   <pagination-controls [id]="config.id" class="float-right" *ngIf="dataList.length !=0 && itemNameFromRoute=='audio'" (pageChange)="pageChanged($event)"></pagination-controls> 
  <div style="color:var(--mainColor)" class="col-lg-12" *ngIf="dataList.length ==0 && itemNameFromRoute=='audio'">
    No Audio(s) Available.
  </div>
<br *ngIf="itemNameFromRoute=='audio'">
<hr *ngIf="itemNameFromRoute=='audio'">

  <h3 (click)="redirectSearchBy('video')" style="color:var(--mainColor)" class="col-lg-12" *ngIf="itemNameFromRoute=='video'">
    Video Lessons
  </h3>
  <div class="container-fluid row" style="cursor: pointer" *ngIf="dataList.length !=0 && itemNameFromRoute=='video'">

    <div class="col-lg-3 col-md-6"
      *ngFor="let slide of dataList| paginate: config">
      <div class="single-courses-box without-box-shadow mb-30">
        <div class="courses-image">
          <img style="width:100%" (click)="videodetails(slide)" style="width: 250px;height: 175px;"
            [src]="slide.thumbname ? slide.thumbname : 'assets/img/videocat-thumb-1.jpg'" alt="" width="100%">
        </div>
        <div class="courses-content">
          <div class="course-author d-flex align-items-center" *ngIf="slide.studentID !=undefined">
            <img
              [src]="slide.studentID!=undefined && slide.studentID.userPhoto!='' ? slide.studentID.userPhoto  :'assets/img/user1.jpg'"
              class="rounded-circle mr-2" alt="image">
            <span *ngIf="slide.studentID!=undefined">{{slide.studentID.profileName}}
            </span>
          </div>
          <div class="course-author d-flex align-items-center" *ngIf="slide.studentID ==undefined">
            <img
              [src]="slide.teacherID!=undefined && slide.teacherID.userPhoto!='' ? slide.teacherID.userPhoto  :'assets/img/user1.jpg'"
              class="rounded-circle mr-2" alt="image">
            <span *ngIf="slide.teacherID!=undefined">{{slide.teacherID.profileName}}
            </span>
          </div>
          <h3>
            <a (click)="videodetails(slide)" class="d-inline-block"><span class="text-break">{{slide.flimname}}</span></a>

          </h3>

        </div>
        <!-- <list5 [slide]="slide"></list5> -->
        <div class="courses-box-footer" style="word-break: break-all;">
          {{slide.categoryID ? slide.categoryID[0].categoryName : '' }}, {{slide.gradeID ? slide.gradeID[0].gradeName : '' }},
          {{slide.boardID ? slide.boardID.boardName : '' }}
        </div>
        <div class="courses-box-footer" *ngIf="slide.subjectID.length > 0" style="word-break: break-all;">
          {{slide.subjectID ? slide.subjectID[0].subjectName : '' }},
          {{slide.languageID ? slide.languageID.languageName : '' }}
        </div>
        <div class="courses-box-footer" *ngIf="slide.subjectID.length == 0" style="word-break: break-all;">
          {{'' }},
          {{slide.languageID ? slide.languageID.languageName : '' }}
        </div>
        <div *ngIf="slide.isPartner" class="courses-box-footer" style="word-break: break-all;">
          Provided By : {{slide.partner.partnerName==undefined? slide.providerByName ==undefined? 'buddi Premium' :  slide.providerByName : slide.partner.partnerName}}        </div>
      </div>
    </div>

  </div>
  <pagination-controls [id]="config.id" class="float-right" *ngIf="dataList.length !=0 && itemNameFromRoute=='video'" (pageChange)="pageChanged($event)"></pagination-controls>
  <div style="color:var(--mainColor)" class="col-lg-12" *ngIf="dataList.length ==0 && itemNameFromRoute=='video'">
    No Video(s) Available.
  </div>
  <br *ngIf="itemNameFromRoute=='video'">
<hr *ngIf="itemNameFromRoute=='video'">
 <h3  style="color:var(--mainColor);cursor: pointer" class="col-lg-12"  *ngIf="itemNameFromRoute=='videoyoutube'">
   Curated Content Videos  
  </h3>
  <div class="container-fluid row" style="cursor: pointer" *ngIf="dataList.length !=0 && itemNameFromRoute=='videoyoutube'">

    <div class="col-lg-3 col-md-6" *ngFor="let slide of dataList| paginate: config">
      <div class="single-courses-box without-box-shadow mb-30">
        <div class="courses-image">
          <img style="width:100%" (click)="videodetails(slide)" style="width: 250px;height: 175px;"
            [src]="slide.thumbname ? slide.thumbname : 'assets/img/videocat-thumb-1.jpg'" alt="" width="100%">
        </div>
        <div class="courses-content">
          <div class="course-author d-flex align-items-center" *ngIf="slide.studentID !=undefined">
            <img
              [src]="slide.studentID!=undefined && slide.studentID.userPhoto!='' ? slide.studentID.userPhoto  :'assets/img/user1.jpg'"
              class="rounded-circle mr-2" alt="image">
            <span *ngIf="slide.studentID!=undefined">{{slide.studentID.profileName}}
            </span>
          </div>
          <div class="course-author d-flex align-items-center" *ngIf="slide.studentID ==undefined">
            <img
              [src]="slide.teacherID!=undefined && slide.teacherID.userPhoto!='' ? slide.teacherID.userPhoto  :'assets/img/user1.jpg'"
              class="rounded-circle mr-2" alt="image">
            <span *ngIf="slide.teacherID!=undefined">{{slide.teacherID.profileName}}
            </span>
          </div>
          <h3>
            <a (click)="videodetails(slide)" class="d-inline-block"><span class="text-break">{{slide.flimname}}</span></a>

          </h3>

        </div>
        <div class="courses-box-footer" style="word-break: break-all">
          {{slide.categoryID ? slide.categoryID[0].categoryName : '' }}, {{slide.gradeID ? slide.gradeID[0].gradeName : '' }},
          {{slide.boardID ? slide.boardID.boardName : '' }}
        </div>
        <div class="courses-box-footer" *ngIf="slide.subjectID.length > 0" style="word-break: break-all">
          {{slide.subjectID ? slide.subjectID[0].subjectName : '' }},
          {{slide.languageID ? slide.languageID.languageName : '' }}
        </div>
        <div class="courses-box-footer" *ngIf="slide.subjectID.length == 0" style="word-break: break-all">
          {{'' }},
          {{slide.languageID ? slide.languageID.languageName : '' }}
        </div>
        <div *ngIf="slide.isPartner" class="courses-box-footer" style="word-break: break-all">
          Provided By : {{ slide.providerByName }} </div>
      </div>
        <!-- <list5 [slide]="slide"></list5> -->
    </div>

  </div>
 </div>
    <pagination-controls [id]="config.id" class="float-right" *ngIf="dataList.length !=0 && itemNameFromRoute=='videoyoutube'" (pageChange)="pageChanged($event)"></pagination-controls>
  <div style="color:var(--mainColor)" class="col-lg-12" *ngIf="dataList.length ==0 && itemNameFromRoute=='videoyoutube'">
    No Video(s) Available.
  </div>
  <br *ngIf="itemNameFromRoute=='videoyoutube'">
<hr *ngIf="itemNameFromRoute=='videoyoutube'">

<!-- Solution Start -->
  <h3 style="color: var(--mainColor);padding-left: 20%" *ngIf="itemNameFromRoute=='solution'"> Solutions</h3>
  <reuseList 
   [type]="f.meetingType.value ? 'Group' : 'Public'" 
   *ngIf="dataList.length!=0 && itemNameFromRoute=='solution'" 
   [itemName]="'solution'" 
   [config]="config"
   [dataList]="dataList" 
   [redirectText]="'addTo/solution/'"
   (pageNumber)="receivePageNumber($event)"
  >                    
  </reuseList>
  <div style="color:var(--mainColor)" class="col-lg-12" *ngIf="dataList.length ==0 && itemNameFromRoute=='solution'">
    No Solution(s) Available.
  </div>
  <!-- Solution End -->

  <!-- Question Start -->
  <h3 style="color: var(--mainColor);padding-left: 20%;" *ngIf="itemNameFromRoute=='question'">Question Papers</h3>
  <reuseList 
   [type]="f.meetingType.value ? 'Group' : 'Public'" 
   *ngIf="dataList.length!=0 && itemNameFromRoute=='question'" 
   [itemName]="'question'" 
   [config]="config"
   [dataList]="dataList" 
   [redirectText]="'addTo/question/'"
   (pageNumber)="receivePageNumber($event)"
  >                    
  </reuseList>
  <div style="color:var(--mainColor)" style="text-align:center" class="col-lg-12" *ngIf="dataList.length ==0 && itemNameFromRoute=='question'">
    No Question(s) Available.
  </div>
   <!-- Question End -->
<br>
<br>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <img src="assets/img/spinner-default.gif" alt="">
</ngx-spinner>