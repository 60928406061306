<div class="instructor-slides owl-carousel owl-theme">
    <div class="single-instructor-box mb-30">
        <div class="card data" style="width: 18rem;">
            <img class="card-img-top" src="../../../../assets/img/Sport_Category.jpeg" alt="Sport_Category">
            <div class="card-body">
              <h5 class="card-title">Sports</h5><br>
             
            </div>
          </div>
    </div>

    <div class="single-instructor-box mb-30">
     
        <div class="card data" style="width:18rem;">
            <img class="card-img-top" src="../../../../assets/img/Art_Category.jpeg"alt="Art_Category">
            <div class="card-body">
              <h5 class="card-title">Art</h5><br>
            
            </div>
          </div>


    </div>



    <div class="single-instructor-box mb-30">
        <div class="card data" style="width: 18rem;">
            <img class="card-img-top" src="../../../../assets/img/NFT_Category.jpeg" alt="NFT_Category">
            <div class="card-body">
              <h5 class="card-title">Education</h5><br>
             
            </div>
          </div>
    </div>

    <!-- <div class="single-instructor-box mb-30">
        <div class="image">
            <img src="../../../../assets/img/soniya1.jpg" routerLink="/buddi-teachers-team/Soniya" style="cursor: pointer;" title="click"
                onerror="this.src='../../../../../assets/img/sonia_computerscience.JPEG';" alt="image" /> -->

            <!--   <ul class="social">
                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
            </ul> -->
        <!-- </div>

        <div class="content">
            <h3><a routerLink="/buddi-teachers-team/Soniya" style="cursor: pointer;" title="click">Soniya </a></h3>
            <span>Coding</span>
        </div>
    </div> -->

    <!-- <div class="single-instructor-box mb-30">
        <div class="image">
            <img src="../../../../assets/img/nandhni1.jpeg" routerLink="/buddi-teachers-team/Nandhini" style="cursor: pointer;" title="click"
                onerror="this.src='../../../../../assets/img/nandhini_physics.jpg';" alt="image" /> -->

            <!--   <ul class="social">
                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
            </ul> -->
        <!-- </div>

        <div class="content">
            <h3><a routerLink="/buddi-teachers-team/Nandhini" style="cursor: pointer;" title="click">Nandhini </a></h3>
            <span>Physics</span>
        </div>
    </div>

    <div class="single-instructor-box mb-30">
        <div class="image">
            <img src="../../../../assets/img/priyanka1.jpeg" style="cursor: pointer;" title="click" routerLink="/buddi-teachers-team/Priyanka"
                onerror="this.src='../../../../../assets/img/nandhini_physics.jpg';" alt="image" /> -->

            <!--   <ul class="social">
                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
            </ul> -->
        <!-- </div>

        <div class="content">
            <h3><a routerLink="/buddi-teachers-team/Priyanka" style="cursor: pointer;" title="click">Priyanka </a></h3>
            <span>Chemistry</span>
        </div>
    </div> -->

    <!-- <div class="single-instructor-box mb-30">
        <div class="image">
            <img src="../../../../assets/img/seeraja1.jpeg" style="cursor: pointer;" title="click" routerLink="/buddi-teachers-team/Sreeja"
                onerror="this.src='../../../../../assets/img/nandhini_physics.jpg';" alt="image" /> -->

            <!--   <ul class="social">
                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
            </ul> -->
        <!-- </div>

        <div class="content">
            <h3><a routerLink="/buddi-teachers-team/Sreeja" style="cursor: pointer;" title="click">V.S.Sreeja </a></h3>
            <span>Chemistry</span>
        </div> -->
    <!-- </div>
    <div class="single-instructor-box mb-30">
        <div class="image">
            <img src="../../../../assets/img/reethika1.jpeg" style="cursor: pointer;" title="click" routerLink="/buddi-teachers-team/RethikaRamesh"
                onerror="this.src='../../../../../assets/img/nandhini_physics.jpg';" alt="image" /> -->

            <!--   <ul class="social">
                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
            </ul> -->
        <!-- </div>

        <div class="content">
            <h3><a routerLink="/buddi-teachers-team/RethikaRamesh" style="cursor: pointer;" title="click">Rethika Ramesh </a></h3>
            <span>Biology</span>
        </div>
    </div> -->

    <!-- <div class="single-instructor-box mb-30">
        <div class="image">
            <img src="../../../../assets/img/niveetha1.jpeg" style="cursor: pointer;" title="click" routerLink="/buddi-teachers-team/Nivetha"
                onerror="this.src='../../../../../assets/img/Nivetha.JPG';" alt="image" /> -->

            <!--   <ul class="social">
                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
            </ul> -->
        <!-- </div>

        <div class="content">
            <h3><a routerLink="/buddi-teachers-team/Nivetha" style="cursor: pointer;" title="click"> S. Nivetha </a></h3>
            <span>Maths</span>
        </div>
    </div> -->

    <!-- <div class="single-instructor-box mb-30">
        <div class="image">
            <img src="../../../../assets/img/vignesh1.jpeg" style="cursor: pointer;" title="click" routerLink="/buddi-teachers-team/Vignesh"
                onerror="this.src='../../../../../assets/img/Vignesh.JPG';" alt="image" /> -->

            <!--   <ul class="social">
                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
            </ul> -->
        <!-- </div>

        <div class="content">
            <h3><a routerLink="/buddi-teachers-team/Vignesh" style="cursor: pointer;" title="click"> V. Vignesh </a></h3>
            <span>Computer Science</span>
        </div>
    </div> -->

    <!-- <div class="single-instructor-box mb-30">
        <div class="image">
            <img src="../../../../assets/img/balkees1.jpeg" style="cursor: pointer;" title="click" routerLink="/buddi-teachers-team/BalkeesParveen"
                onerror="this.src='../../../../../assets/img/Balkees-Parveen.JPG';" alt="image" /> -->

            <!--   <ul class="social">
                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
            </ul> -->
        <!-- </div> -->

        <!-- <div class="content">
            <h3><a routerLink="/buddi-teachers-team/BalkeesParveen" style="cursor: pointer; " title="click"> Balkees Parveen</a></h3>
            <span>Physics</span>
        </div>
    </div> -->
    <!-- <div class="single-instructor-box mb-30">
        <div class="image">
            <img src="../../../../assets/img/vineetha1.jpeg" style="cursor: pointer;" title="click" routerLink="/buddi-teachers-team/VineethaAnil"
                onerror="this.src='../../../../../assets/img/Vineetha-Anil.JPG';" alt="image" /> -->

            <!--   <ul class="social">
                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
            </ul> -->
        <!-- </div> -->

        <!-- <div class="content">
            <h3><a routerLink="/buddi-teachers-team/VineethaAnil" style="cursor: pointer;" title="click"> Vineetha Anil</a></h3>
            <span>Soft Skills, Social Science</span>
        </div> -->
    </div>

    
