<section class="how-it-works-area pt-100 ">
    <div class="container">
        <div class="section-title">           
            <h2>How It Works? </h2>
        </div>

        <div class="row work">
            <div class="col-lg-4 col-md-6">
                <div class="single-work-process mb-30">
                    <div class="icon">
                        <a (click)="registerclick()" href="javascript:void(0)"> <i class='bx bx-search-alt'></i> </a>
                    </div>
                    <h3><a (click)="registerclick()" href="javascript:void(0)" style="color: white;">Setup</a></h3>                   
                    <!-- <p>An nec placerat repudiare scripserit, temporibus complectitur at sea, vel ignota fierent rreloquentiam id.</p> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-work-process mb-30">
                    <div class="icon">
                        <a (click)="joinclass()" href="javascript:void(0)" ><i class='bx bx-info-square'></i></a>
                    </div>
                    <h3><a (click)="joinclass()" href="javascript:void(0)" style="color: white;">Run</a></h3>          
                    <!-- <p>An nec placerat repudiare scripserit, temporibus complectitur at sea, vel ignota fierent rreloquentiam id.</p> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-work-process mb-30">
                    <div class="icon">
                        <a (click)="joinclass()" href="javascript:void(0)"> <i class='bx bx-like'></i> </a>
                    </div>
                    <h3><a (click)="joinclass()" href="javascript:void(0)" style="color: white;">Monitor Live Classes</a></h3>          
                    <!-- <p>An nec placerat repudiare scripserit, temporibus complectitur at sea, vel ignota fierent rreloquentiam id.</p> -->
                </div>
            </div>
        </div>
    </div>
</section>