<div *ngIf="!isStudent">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>
<div *ngIf="isStudent">
    <app-header-style-one></app-header-style-one>
</div>
<!-- <div class="container" routerLink="/sidenav"><button style="width: 10%;height:45px;margin-left:89%;margin-top:1%;padding: 9px;"><i class="fas fa-long-arrow-alt-left mr-1"></i>Back</button></div> -->
<div div *ngIf="isStudent == true" class="container mt-3 mb-3">
    <div class="go">
        <h5 style="color: #000000;">Upcoming Study Material</h5>
        <div class="btn-group ml-3" style="margin-left:0rem !important">
        <button type="button" (click)="backto()"><i class="fas fa-long-arrow-alt-left mr-1"></i>Back</button>
    </div>
    </div>
    <div class="orders-table table-responsive mb-3">
        <table class="table">
            <thead>
                <tr>
                    <th>Sl.No</th>
                    <th>Material Name</th>
                    <th>Topic Name</th>
                    <!-- <th>Tutor Name</th> -->
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor='let class of smartMaterialList; let i = index'>
                    <td>{{i + 1}}</td>
                    <td class="height_set">
                        <a routerLink="javascript:void(0)" (click)="detailsSmartMaterial(class)" class="d-inline-block"
                        >{{ class.materialName }}</a>
                    </td>
                    <!-- <td *ngIf="class.teacherID">{{class.teacherID.profileName}} {{class.teacherID.lastName}}</td> -->
                    <td *ngIf="class.topicID">{{class.topicID.topicName}}</td>
                    <!-- <td>{{class.fullName}}</td> -->
                    <td>
                        <div class="Action align">
                            
                                <a class="Action"
                                    *ngIf="(isLoggedin =='true')"
                                    href="{{class.materialPath}}"><i class="fa fa-eye" data-toggle="tooltip" data-placement="bottom" title="See in pdf"></i></a>
                        
                      
                                <a class="Action" href="javascript:void(0)" (click)="smartMaterialFunc('share')">
                                        <i class="fas fa-share-alt-square" data-toggle="tooltip" data-placement="bottom" title="Share"></i>
                                </a>
                      
                        </div>


                    </td>

                    <!-- <a routerLink="/single-courses" class="view-button"
                        >View</a
                    > -->
                </tr>
            </tbody>
        </table>
        <h4
        style="color: #000000; text-align: center"
        class="col-lg-12 mt-3"
        *ngIf="smartMaterialList.length == 0"
    >
        No Study Material Available
    </h4>
        <div class="pagination-area text-center">
            <a
                href="javascript:void(0)"
                (click)="clickLiveClassPageNumber(1)"
                *ngIf="currentLiveClassPageNumber > 2 && totalLiveClassPageCount > 1"
                class="page-numbers"
                >1</a
            >
            <span
                class="page-numbers"
                *ngIf="currentLiveClassPageNumber > 2 && totalLiveClassPageCount > 1"
                aria-current="page"
                >...</span
            >
            <a
                href="javascript:void(0)"
                (click)="clickLiveClassPageNumber(currentLiveClassPageNumber - 1)"
                *ngIf="currentLiveClassPageNumber > 1 && totalLiveClassPageCount > 1"
                class="page-numbers"
                >{{ currentLiveClassPageNumber - 1 }}</a
            >
            <span
                class="page-numbers current"
                *ngIf="totalLiveClassPageCount != 0"
                aria-current="page"
                >{{ currentLiveClassPageNumber }}</span
            >
            <a
                href="javascript:void(0)"
                (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 1)"
                *ngIf="
                    totalLiveClassPageCount > 1 &&
                    currentLiveClassPageNumber + 1 <= totalLiveClassPageCount
                " class="page-numbers">{{ currentLiveClassPageNumber + 1 }}</a>
        <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 2)" *ngIf="
                    totalLiveClassPageCount > 1 &&
                    currentLiveClassPageNumber + 2 <= totalLiveClassPageCount
                " class="page-numbers">{{ currentLiveClassPageNumber + 2 }}</a>
        <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 3)" *ngIf="
                    totalLiveClassPageCount > 1 &&
                    currentLiveClassPageNumber + 3 <= totalLiveClassPageCount
                " class="page-numbers">{{ currentLiveClassPageNumber + 3 }}</a>
        <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 4)" *ngIf="
                    totalLiveClassPageCount > 1 &&
                    currentLiveClassPageNumber + 4 <= totalLiveClassPageCount
                " class="page-numbers">{{ currentLiveClassPageNumber + 4 }}</a>
        <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 1)" *ngIf="
                    totalLiveClassPageCount > 1 &&
                    currentLiveClassPageNumber + 1 <= totalLiveClassPageCount
                " class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
    </div>
    </div>
</div>

<div div *ngIf="isTeacher == true" class="container mt-3">
    <div class="go">
        <h5 style="color: #000000; cursor: pointer;">Upcoming Study Material</h5>
        <div class="btn-group ml-3" style="margin-left:0rem !important">
        <button type="button" routerLink="/sidenav"><i class="fas fa-long-arrow-alt-left mr-1"></i>Back</button>
    </div>
    </div>
    <div class="orders-table table-responsive mb-3">
        <table class="table">
            <thead>
                <tr>
                    <th>Sl.No</th>
                    <th>Material Name</th>
                    <th>Topic Name</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor='let class of smartMaterialList; let i = index'>
                    <td>{{i + 1}}</td>
                    <td class="height_set">
                        <a routerLink="javascript:void(0)" (click)="detailsSmartMaterial(class)" class="d-inline-block"
                        >{{ class.materialName }}</a>
                    </td>
                    <!-- <td *ngIf="class.teacherID">{{class.teacherID.profileName}} {{class.teacherID.lastName}}</td> -->
                    <td *ngIf="class.topicID">{{class.topicID.topicName}}</td>
                    <td>
                        <div class="Action align">
                            
                                <a class="Action"
                                    *ngIf="(isLoggedin =='true' && purchase == true) || (isLoggedin == 'true' && class.teacherID._id == teacherID)"
                                    href="{{class.materialPath}}"><i class="fa fa-eye" data-toggle="tooltip" data-placement="bottom" title="See in pdf"></i></a>
                        
                      
                                <a class="Action" href="javascript:void(0)" (click)="smartMaterialFunc('share')">
                                        <i class="fas fa-share-alt-square" data-toggle="tooltip" data-placement="bottom" title="Share"></i>
                                </a>
                      
                        </div>


                    </td>

                    <!-- <a routerLink="/single-courses" class="view-button"
                        >View</a
                    > -->
                </tr>
            </tbody>
        </table>
        <h4
        style="color: #000000; text-align: center"
        class="col-lg-12 mt-3"
        *ngIf="smartMaterialList.length == 0"
    >
    No Study Material Available
    </h4>
        <div class="pagination-area text-center">
            <a
                href="javascript:void(0)"
                (click)="clickLiveClassPageNumber(1)"
                *ngIf="currentLiveClassPageNumber > 2 && totalLiveClassPageCount > 1"
                class="page-numbers"
                >1</a
            >
            <span
                class="page-numbers"
                *ngIf="currentLiveClassPageNumber > 2 && totalLiveClassPageCount > 1"
                aria-current="page"
                >...</span
            >
            <a
                href="javascript:void(0)"
                (click)="clickLiveClassPageNumber(currentLiveClassPageNumber - 1)"
                *ngIf="currentLiveClassPageNumber > 1 && totalLiveClassPageCount > 1"
                class="page-numbers"
                >{{ currentLiveClassPageNumber - 1 }}</a
            >
            <span
                class="page-numbers current"
                *ngIf="totalLiveClassPageCount != 0"
                aria-current="page"
                >{{ currentLiveClassPageNumber }}</span
            >
            <a
                href="javascript:void(0)"
                (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 1)"
                *ngIf="
                    totalLiveClassPageCount > 1 &&
                    currentLiveClassPageNumber + 1 <= totalLiveClassPageCount
                " class="page-numbers">{{ currentLiveClassPageNumber + 1 }}</a>
        <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 2)" *ngIf="
                    totalLiveClassPageCount > 1 &&
                    currentLiveClassPageNumber + 2 <= totalLiveClassPageCount
                " class="page-numbers">{{ currentLiveClassPageNumber + 2 }}</a>
        <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 3)" *ngIf="
                    totalLiveClassPageCount > 1 &&
                    currentLiveClassPageNumber + 3 <= totalLiveClassPageCount
                " class="page-numbers">{{ currentLiveClassPageNumber + 3 }}</a>
        <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 4)" *ngIf="
                    totalLiveClassPageCount > 1 &&
                    currentLiveClassPageNumber + 4 <= totalLiveClassPageCount
                " class="page-numbers">{{ currentLiveClassPageNumber + 4 }}</a>
        <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 1)" *ngIf="
                    totalLiveClassPageCount > 1 &&
                    currentLiveClassPageNumber + 1 <= totalLiveClassPageCount
                " class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
    </div>
    </div>
</div>