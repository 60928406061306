import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../shared/api.service';

@Component({
  selector: 'app-nft',
  templateUrl: './nft.component.html',
  styleUrls: ['./nft.component.scss']
})
export class NFTComponent implements OnInit {
  public isStudent: boolean = false;
  public isTeacher: boolean = false;
  public isteacher = false;
  constructor() { }

  ngOnInit(): void {
    this.isTeacher =
            localStorage.getItem("profileType").toLowerCase() == "teacher";
        this.isStudent =
            localStorage.getItem("profileType").toLowerCase() == "student";
  }

}