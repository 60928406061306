<app-header-style-one *ngIf="!isTeacher"></app-header-style-one>
    <app-header-style-one-teacher
        *ngIf="isTeacher"
    ></app-header-style-one-teacher>

<section class="register-area">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="register-image">
                <img src="assets/img/register-bg.jpg" alt="image">
            </div>
        </div>

        <div class="col-lg-6 col-md-12 p-0">
            <div class="register-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="register-form">
                            <div class="logo">
                                <a routerLink="/"><img src="assets/img/black-logo.png" alt="image"></a>
                            </div>
                            <h3>Tutor</h3>
                            <h3>Forgot Password</h3>
                            <p>Already signed up? <a routerLink="/teacher/login">Log in</a></p>
                            <div class="forPasswordForm" id="MobileNumberFormID">
                                <!-- <h2>Forgot Password</h2> -->
                                <!-- <h2 *ngIf="isUserLoggedIn =='true'">Change Password</h2> -->
                                <div class="tab-content" id="forPasswordTabContent">
                                  <div class="tab-pane fade show active" id="userforPassword" role="tabpanel" aria-labelledby="userforPassword-tab">
                                    <form class="p-4" [formGroup]="registerForm">
                                        <p>Resetting your password is easy, just tell us the email address you registered with buddi.life.</p>
<!--                                         
                                        <div class="form-group">
                                           
                                            <input
                                                type="text"
                                                id="number"
                                                class="form-control"
                                                formControlName="mobileNumber1"
                                                placeholder="Mobile Number"
                                                minlength="10"
                                                [ngClass]="{ 'is-invalid': submitted && f.mobileNumber1.errors }" />
            
                                             <div *ngIf="submitted && f.mobileNumber1.errors" class="invalid-feedback">
                                                <div *ngIf="f.mobileNumber1.errors.required">Mobile No is Required</div>
                                                <div *ngIf="f.mobileNumber1.errors.pattern">Enter only number</div>
                                                <div *ngIf="f.mobileNumber1.errors.minlength">Mobile No must have 10 digits</div>
                                            </div>
                                        </div> -->
            
            
            
            
            
            
                                        <div class="form-group">
                                            <!-- <label>Email Address</label> -->
            
                                            <input
                                            type="email"
                                            placeholder="me@example.com"
                                            id="forPasswordFormEmail"
                                            class="form-control"
                                            formControlName="email"  minlength="10"  
                                            [ngClass]="{'is-invalid': submitted && user.email.errors}" 
                                            [(ngModel)]="user.email" name="email"                   
                                          />
                                          <div *ngIf="submitted && user.email.errors" class="invalid-feedback">
                                            <div *ngIf="user.email.errors?.required" >Email is required</div>
                                            <div *ngIf="user.email.errors?.email">Email must be a valid email address</div>
                                          <div *ngIf="user.email.errors?.minlength">Enter Minimum 10 characters
                                       </div>
                                      
                                        </div>
                                    </div>
            
            <!-- <div class="form-group">
                <label>Email Address / User ID</label>
                <input
                  type="text"
                  id="LoginFormEmail"
                  class="form-control"
                  placeholder="Email-Id / User ID"
                  [(ngModel)]="loginID.email"
                  name="mailID"
                  #loginEmail = "ngModel"
                  [ngClass]="{ 'is-invalid': userform.submitted && loginEmail.invalid }"
                  required email                   
                />
                <div *ngIf="userform.submitted && loginEmail.invalid" class="invalid-feedback">
                  <div *ngIf="loginEmail.errors.required">Email/User ID Required</div>
                  <div *ngIf="loginEmail.errors.email">Email must be a valid email address</div> 
                </div>                    
              </div> -->
            
            
            
            
            
            
            
            
            
            
            
            
            
            
                                        <button class="btn btnType2 btn-block my-4" type="submit" (click)="submit()">Submit</button>
                                        <!-- <p class="regLink">
                                            <a routerLink="/login">Back to Login</a>
                                          </p> -->
                                    </form>
                                  </div>					  
                                </div>
                            </div>
                            <div class="forPasswordForm"  id="OTPFormID">
            
                                <!-- <h3>Enter OTP</h3> -->
                                <div class="tab-content" id="forPasswordTabContent">
                                  <div
                                    class="tab-pane fade show active"
                                    id="userregister"
                                    role="tabpanel"
                                    aria-labelledby="userregister-tab"
                                  >
                                    <form class="p-4" [formGroup]="registerForm">
                                      <div class="row">
                                        <!-- <div class="col-lg-12">
                                            <p>An OTP has been sent to {{Email}}. If this is not your number please  
                                            <a [ngClass]="{'md-btn md-btn-success': !isChangeNumberButtonDisabled,
                                              'md-btn disabled': isChangeNumberButtonDisabled }" >
                                            <span id = "changeNumberButton" (click)="changePhoneNumber()" style="color:rgb(4, 31, 184);">click here</span>
                                            </a>
                                            </p>
                                          </div> -->
                                        <div class="col-lg-12">
                                          <div class="form-group">
                                            <label>Password <span class="required" style="color: red;">*</span></label>
                                            <show-hide-password size="xs" btnStyle="default" [btnOutline]="false">
                                              <input type="password"
                                                id="registerFormPassword"
                                                name="password"
                                                placeholder="New Password"
                                                autocomplete="new-password"
                                                class="form-control"
                                                formControlName="password"
                                                   [ngClass]="{'is-invalid': !f.password.pristine && f.password.errors}">
                                            
                                                <div *ngIf="!f.password.pristine && f.password.errors" class="invalid-feedback">
                                                  <div *ngIf="f.password.errors.required" >Password is Required</div>
                                                  <div *ngIf="f.password.errors?.minlength">Must be atleast 8 characters</div>
                                                  <div *ngIf="f.password.errors.required">must contain one number</div>
                                                  <div *ngIf="f.password.errors.required">must contain atleast 1 letter in capital case</div>
                                                  <div *ngIf="f.password.errors.required"> must contain atleast 1 letter in small case</div>
                                                  <div *ngIf="f.password.errors.required"> must contain atleast one special character</div>
                                                     
                                                  </div>
                                          
                                                </show-hide-password>
                                          </div>
                                        </div>
                                      </div>
                                      <button class="btn btnType2 btn-block my-4"
                                      id = "otpButton"
                                      (click)="OTPSubmit()">
                                      Submit
                                    </button>
                                    <!-- <p id = "otpResendTimer" >{{timeLeft}} Seconds Left....</p>
                                    <a [ngClass]="{'md-btn md-btn-success': !isButtonDisabled,
                                    'md-btn disabled': isButtonDisabled }" >
                                      <p id = "otpResendButton" (click)="otpResendClicked()">Resend OTP</p>
                                    </a>
                                    <p id = "otpSentSuccess">OTP Sent!</p> -->
                                    </form>
                                  </div>
                                </div>
                              </div>
            
                              <!-- <div class="forPasswordForm"  id="ChangePasswordID">
                                <h2>Change Password</h2>
                                <div class="tab-content" id="forPasswordTabContent">
                                      <div
                                        class="tab-pane fade show active"
                                        id="userregister"
                                        role="tabpanel"
                                        aria-labelledby="userregister-tab"
                                    >
                                        <form class="p-4" [formGroup]="registerForm">
                                            <div class="row">
                                                <div class="col-md-10">
                                                    <div class="form-group">
                                                       
                                                        <show-hide-password size="xs"  btnStyle="default" [btnOutline]="false">
                                                        <input
                                                            type="password"
                                                            id="passWord"
                                                            class="form-control"
                                                            formControlName="passWord"
                                                            placeholder="New Password"
                                                            minlength="10"
                                                            [ngClass]="{'is-invalid': submitted && f.passWord.errors}"
                                                        />
                                                        
                                                        <div *ngIf="submitted && f.passWord.errors" class="invalid-feedback">
                                                            <div *ngIf="f.passWord.errors.required" >Password is Required</div>
                                                            <div *ngIf="f.passWord.errors?.minlength">Must be atleast 8 characters</div>
                                                            <div *ngIf="f.passWord.errors.pattern">
                                                                must contain one number<br>
                                                                   must contain atleast 1 letter in capital case<br>
                                                                 must contain atleast 1 letter in small case<br>
                                                                 must contain atleast one special character<br>
                                                            </div>
                                                        </div>
                                                        </show-hide-password>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-10">
                                                    <div class="form-group">
                                                       
                                                        <show-hide-password size="xs"  btnStyle="default" [btnOutline]="false">
                                                        <input
                                                            type="password"
                                                            id="checkpassWord"
                                                            class="form-control"
                                                            formControlName="checkpassWord"
                                                            placeholder="Re-enter Password"
                                                            minlength="10"
                                                            [ngClass]="{'is-invalid': submitted && f.checkpassWord.errors}"
                                                        />
                                                        
                                                        <div *ngIf="submitted && f.checkpassWord.errors" class="invalid-feedback">
                                                            <div *ngIf="f.checkpassWord.errors?.required" >Confirm Password is Required</div>
                                                        </div>
                                                        </show-hide-password>	
                                                    </div>
                                                </div>
                                            </div>
                                            <button class="btn btnType2 btn-block my-4"
                                                id = "otpButton"
                                                (click)="PasswordSubmit()">
                                                Submit
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>			 -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>