<div class="container" >
    <div class="card-deck">
        <div class="col-lg-4 col-md-6" routerLink="/Currency-wallet" style="cursor:pointer ;">
           <div class="card text-center">
            <img src="../../../../assets/img/Currency-wallet.jpeg" alt="">
            <div class="card-body">
                Currency Wallet
            </div>
           </div>
        </div>
        <div class="col-lg-4 col-md-6" routerLink="/currency-transaction-history" style="cursor:pointer ;">
            <div class="card text-center">
                <img src="../../assets/img/upcoming-live-class.jpeg" alt="">
                <div class="card-body">
                    Currency Transactions
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6" *ngIf="profiletype=='teacher'" routerLink="/sidenav/payment/subscription" style="cursor:pointer ;">
            <div class="card text-center">
                <img src="../../assets/img/upcoming-live-class.jpeg" alt="">
                <div class="card-body">
                    Subscriptions
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6" routerLink="/buddi-coin" style="cursor:pointer ;">   
            <div class="card text-center">
                <img src="../../../../assets/img/buddi-reward.jpeg" alt="">
                <div class="card-body">
                    buddi Coin Wallet
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6" routerLink="/buddi-coin-transaction-history" style="cursor:pointer ;">
            <div class="card text-center">
                <img src="../../../../assets/img/trans-history.jpeg" alt="">
                <div class="card-body">
                    buddi Coin Transactions
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6" routerLink="/buddi-rewards" style="cursor:pointer ;">
            <div class="card text-center">
                <img src="../../../../assets/img/buddi-reward.jpeg" alt="">
                <div class="card-body">
                    buddi Rewards
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6" routerLink="/nft-asset-transaction" style="cursor:pointer ;">
            <div class="card text-center">
                <img src="../../../../assets/img/nft_pay.jpeg" alt="">
                <div class="card-body">
                    NFT's Assets Transactions
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6" routerLink="/invoice" style="cursor:pointer ;">
            <div class="card text-center">
                <img src="../../../../assets/img/Invoice.jpeg" alt="">
                <div class="card-body">
                    Invoices
                </div>
            </div>
        </div>
    </div>
</div>