import { Component, OnInit } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';
import { ApiService } from "../../../shared/api.service";

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss']
})
export class WalletComponent implements OnInit {

  public isStudent = false;
  public isTeacher = false;
  public walletBalance = 0;
  public teacherID;
  isLoggedin = "false"
  constructor(
    private rest: ApiService,
    private router: Router,
    public toastr: ToastrManager
  ) { }

  ngOnInit(): void {
    this.isLoggedin = localStorage.getItem('isLoggedin');
    this.teacherID = localStorage.getItem('teacherID')

    const token = localStorage.getItem("webtoken");
    this.isTeacher =
        localStorage.getItem("profileType").toLowerCase() == "teacher";
    this.isStudent =
        localStorage.getItem("profileType").toLowerCase() == "student";
        var reqData = {
          notFetchCompleted: true,
          limit: 5
      };
    this.getWalletBalance();
  }

  getWalletBalance() {
    const webToken = localStorage.getItem("webtoken");
    this.rest.getWalletBalance(webToken).subscribe((response) => {
      console.log("material", response)
      this.walletBalance = response.data
    })
    
  }

  checkPromoCode(){
    this.router.navigate(['/wallet-recharge'])
  }
}