<div *ngIf="isstudent">
    <app-header-style-one></app-header-style-one>
</div>
<div *ngIf="isteacher">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>

<section class="courses-details-area pt-100 pb-70" style="padding-top: 30px!important" *ngIf="isinstruction">
    <div class="container" style="box-shadow: 0px 0px 1px 0px;padding: 5px 30px 5px 30px;">
        <div class="row" style="display: block;">
            <h4 style="text-align: center;">{{TestData.testName}}</h4>
        </div>

        <div class="row" style="margin-top: 3%;display: block;">
            <h6 style="text-align: center;">Instructions</h6>

        </div>
        <p style="text-align: center;">Read the instruction carefully</p>
        <div class="row" style="margin-top: 3%;display: block;">

            <p style="color: #444;font-size: 17px;font-weight: 400;">1. Total duration of the test is <span
                    style="font-size: 17px;color: black;font-weight: 700;">{{TestData.totalTime}} Minutes</span>.</p>

            <p style="color: #444;font-size: 17px;font-weight: 400;">2. There will be only <span
                    style="font-size: 17px;color: black;font-weight: 700;">{{TestData.numberOfQuestions}}
                    questions</span> in this test.</p>

            <p style="color: #444;font-size: 17px;font-weight: 400;">3. Total marks for this test is <span
                    style="font-size: 17px;color: black;font-weight: 700;">{{TestData.totalMarks}} marks</span>.</p>

            <p *ngIf="subjectlength > 1" style="color: #444;font-size: 17px;font-weight: 400;">4. There are <span
                    style="font-size: 17px;color: black;font-weight: 700;">{{subjectlength}} sections</span> in the
                question paper consisting of {{subjectnameasstring}}.</p>

            <p *ngIf="subjectlength == 1" style="color: #444;font-size: 17px;font-weight: 400;">4. There is <span
                    style="font-size: 17px;color: black;font-weight: 700;">{{subjectlength}} section</span> in the
                question paper consisting of {{subjectnameasstring}}.</p>

            <p style="color: #444;font-size: 17px;font-weight: 400;">5. In all the sections, <span
                    style="font-size: 17px;color: black;font-weight: 700;">No deduction</span> from the total score will
                be made if a question is unanswered.</p>

            <p style="color: #444;font-size: 17px;font-weight: 400;">6. In all the sections,there will be a deduction
                from the total score for each wrong answers in some of the questions.</p>

            <p style="color: #444;font-size: 17px;font-weight: 400;">7. Please use ONE Device,browser and tab while
                taking the test. As an anti-cheating measure,switching between tabs,browsers or devices will result in
                loss of your test data.</p>

            <label>
                <input type="checkbox" value="1" style="margin-right: 10px!important;" (change)="changed($event)"><span
                    style="color: #444;font-size: 17px;font-weight: 400;">I have read and understood the
                    instructions.</span>
            </label>
        </div>

        <div class="row" style="margin-top: 3%;display: block;text-align: center;">
            <button disabled *ngIf="!ischecked" type="submit" class="default-btn"
                style="background-color: grey;border-color: grey;"><i class='bx bx-send icon-arrow before'></i><span
                    class="label">Start Test</span></button>

            <button *ngIf="ischecked" type="submit" class="default-btn" (click)="getquestions()"><i
                    class='bx bx-send icon-arrow before'></i><span class="label">Start Test</span><i
                    class="bx bx-send icon-arrow after"></i></button>
        </div>


    </div>
</section>

<section class="courses-details-area pt-100 pb-70" style="padding-top: 30px!important" *ngIf="isquestion && !uploadimage">
    <div class="container">
        <!-- The Modal to end a test -->
        <div class="modal" id="myModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">

                        <div style="margin-top: 5%;">
                            <p> Are you sure you want to end this test?</p>
                        </div>
                        <div class="row" style="display: block;">
                            <h6 style="text-align: center;">Question summary</h6>
                        </div>
                        <div class="row" style="margin-top: 3%;">
                            <div class="col-lg-4">
                                <p style="font-size: 12px;"><span class="greendot"></span> {{answeredcount}} Answered
                                </p>
                            </div>
                            <div class="col-lg-4">
                                <p style="font-size: 12px;"><span class="orangedot"></span> {{reviewedcount}} Marked</p>
                            </div>
                            <div class="col-lg-4">
                                <p style="font-size: 12px;"><span class="yellowdot"></span> {{unansweredcount}}
                                    Unanswered</p>
                            </div>
                        </div>
                        <div class="row" style="margin-top: 5%;">
                            <div class="col-md-4 col-lg-4 col-sm-4">
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3">
                                <a class="default-btn" (click)="endtest()" style=" margin-right: 10px;"
                                    data-dismiss="modal"><i class='bx bx-paper-plane icon-arrow before'></i><span
                                        class="label">End</span><i class="bx bx-paper-plane icon-arrow after"></i></a>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3">
                                <a class="default-btn" style="margin-left: 15%;" data-dismiss="modal"><i
                                        class='bx bx-paper-plane icon-arrow before'></i><span
                                        class="label">Cancel</span><i
                                        class="bx bx-paper-plane icon-arrow after"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End of The Modal to end a test -->



           <!-- The Modal to question image -->
           <div class="modal" id="myModalimg">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">                        
                      
                        <div *ngFor="let path of answerlist[questioncount].images" >
                            <div *ngIf="path.includes('http')" data-toggle="modal" data-target="#myModalimg">
                                  <img class="img-responsive" src={{path}} style="max-height:250px;">                              
                            </div>
                            <div id="mathdiv" *ngIf="!path.includes('http')" mathjax>
                                {{path}}
                            </div>
                        </div>
                        
                        <div class="row" style="margin-top: 5%;">
                            <div class="col-md-4 col-lg-4 col-sm-4">
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3">
                             
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3">
                                <a class="default-btn" style="margin-left: 15%;" data-dismiss="modal"><i
                                        class='bx bx-paper-plane icon-arrow before'></i><span
                                        class="label">Cancel</span><i
                                        class="bx bx-paper-plane icon-arrow after"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End of The Modal question image -->




        <!-- Exam Timer -->
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="box-shadow: 0px 0px 1px 0px;">
                <div class="row" style="height: 75px;padding-top: 2%;">
                    <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                        <p style="word-break: break-word;font-size: 20px;color:black;font-weight: 700">
                            {{TestData.testName}}</p>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                        <p style="font-size: 18px;font-weight: 700;float: right;color: black;"> <i
                                class='bx bx-time'></i> Time Left: {{display}} </p>
                    </div>
                </div>
            </div>

        </div>

        <!-- The modal to show last question Alert -->
        <div id="myModal1" *ngIf="showlastmodal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body" style="padding-top: 0px;">
                        <div class="row"
                            style="padding: 5px 5px 5px 10px;border-bottom: 1px solid lightgrey;background-color: #00adee;color: white;">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <p style="color: white;font-size:18px;font-weight: 600;"> Alert!
                                </p>
                            </div>

                        </div>
                        <div style="margin-top: 5%;">
                            <p style="font-size: 17px;font-weight: 600"> Moving to the Last Question in this Test</p>
                        </div>


                        <div class="row" style="margin-top: 5%;">
                            <div class="col-md-4 col-lg-4 col-sm-4">
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3">

                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3">
                                <a class="default-btn" style="margin-left: 15%;" (click)="lastquestion()"><i
                                        class='bx bx-paper-plane icon-arrow before'></i><span class="label">Ok</span><i
                                        class="bx bx-paper-plane icon-arrow after"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End of  modal to show last question Alert-->
        <!-- End of Exam Timer -->

        <div class="row" style="margin-top:3%" *ngIf="showquestions">

            <!-- Question with options -->
            <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12" style="box-shadow: 0px 0px 1px 0px">
                <div class="row"
                    style="padding: 5px 5px 5px 10px;border-bottom: 1px solid lightgrey;background-color: #00adee;color: white;">
                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8" style="border-right: 1px solid white;">
                        <p style="color: white;font-weight: 600;"> Question {{questioncount + 1}} : {{choisequestion}}
                        </p>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4" *ngIf="answerlist[questioncount].questionType!=3">
                        <p style="color: white;font-weight: 600;"> Wrong Answer : {{deductionmarks}} marks</p>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4" *ngIf="answerlist[questioncount].questionType==3">
                        <p style="color: white;font-weight: 600;"> Correct Answer : {{correctmarks}} marks</p>
                    </div>
                    
                </div>
                <!-- <div class="row" style="padding: 5px 5px 5px 10px;margin-top: 2%;">
                     <div id="mathdiv" mathjax >
                       
                        {{answerlist[questioncount].questionText}}
                    </div>
                </div>  -->
                <!-- <div id="mathjax" (load)="mathjax.MathJaxTypeset()" mathjax>
                    {{answerlist[questioncount].questionText}}
                </div> -->

                <div *ngIf="answerlist[questioncount].images == undefined" class="row"
                    style="padding: 5px 5px 5px 10px;;margin-top: 2%">
                    <!-- <p>{{currentsubjectname}}</p> -->
                    <div id="mathdiv" mathjax>

                        {{answerlist[questioncount].questionText}}
                    </div>
                </div>

                <div *ngIf="answerlist[questioncount].images != undefined">
                    <div *ngFor="let path of answerlist[questioncount].images" >
                        <div *ngIf="path.includes('http')" data-toggle="modal" data-target="#myModalimg">
                            <img src={{path}} alt="something" style="width:auto;height:auto;" />
                        </div>
                        <div id="mathdiv" *ngIf="!path.includes('http')" mathjax>
                            {{path}}
                        </div>
                    </div>

                </div>



                <form class="edit-account" [formGroup]="selectedoptionform">

                    <!-- Dropdown options with multiselect-->
                    <div class="row" style="padding: 5px 5px 5px 10px;margin-top: 2%;"
                        *ngIf="answerlist[questioncount].correctAnswer.length > 1 && answerlist[questioncount].questionType==1">
                        <div class="col-lg-5" (click)="option1divclicked()"
                            style="padding: 15px 15px 15px 15px;box-shadow: 0px 0px 1px 0px;;margin-top: 5%;">

                            <!-- <span
                                style="margin-left: 3%;font-size: 13px;word-break: break-word;" id="mathone" mathjax>{{answerlist[questioncount].option1}}</span> -->
                            <div *ngIf="answerlist[questioncount].option1Images == undefined" class="row"
                                style="padding: 5px 5px 5px 10px;;margin-top: 2%">
                                <div id="mathone" mathjax>
                                    <div class="row">
                                        <div class="col-lg-4">
                                            <input type="radio" name="isoption1" (click)="option1clicked()"
                                                [(ngModel)]="isoption1" formControlName="option1" [value]="true">
                                        </div>
                                        <div class="col-lg-8">
                                            {{answerlist[questioncount].option1}}
                                        </div>
                                    </div>


                                </div>
                            </div>

                            <div *ngIf="answerlist[questioncount].option1Images != undefined">
                                <div *ngFor="let path of answerlist[questioncount].option1Images">
                                    <div *ngIf="path.includes('http')">
                                        <img src={{path}} alt="something" style="width:auto;height:auto;" />
                                    </div>
                                    <div id="mathone" *ngIf="!path.includes('http')" mathjax>
                                        {{path}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-1">

                        </div>
                        <div class="col-lg-5" (click)="option2divclicked()"
                            style="padding: 15px 15px 15px 15px;box-shadow: 0px 0px 1px 0px;;margin-top: 5%;">
                            <input type="radio" name="isoption2" (click)="option2clicked()" [(ngModel)]="isoption2"
                                formControlName="option2" [value]="true">
                            <!-- <span
                                style="margin-left: 3%;font-size: 13px;word-break: break-word;" id="mathtwo" mathjax>{{answerlist[questioncount].option2}}</span> -->
                            <div *ngIf="answerlist[questioncount].option2Images == undefined" class="row"
                                style="padding: 5px 5px 5px 10px;;margin-top: 2%">
                                <div id="mathtwo" mathjax>

                                    {{answerlist[questioncount].option2}}
                                </div>
                            </div>

                            <div *ngIf="answerlist[questioncount].option2Images != undefined">
                                <div *ngFor="let path of answerlist[questioncount].option2Images">
                                    <div *ngIf="path.includes('http')">
                                        <img src={{path}} alt="something" style="width:auto;height:auto;" />
                                    </div>
                                    <div id="mathtwo" *ngIf="!path.includes('http')" mathjax>
                                        {{path}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-1">

                        </div>
                        <div class="col-lg-5" (click)="option3divclicked()"
                            style="padding: 15px 15px 15px 15px;box-shadow: 0px 0px 1px 0px;;margin-top: 5%;">
                            <input type="radio" (click)="option3clicked()" [(ngModel)]="isoption3"
                                formControlName="option3" [value]="true">
                            <!-- <span
                                style="margin-left: 3%;font-size: 13px;word-break: break-word;" id="maththree" mathjax>{{answerlist[questioncount].option3}}</span> -->
                            <div *ngIf="answerlist[questioncount].option3Images == undefined" class="row"
                                style="padding: 5px 5px 5px 10px;;margin-top: 2%">
                                <div id="maththree" mathjax>

                                    {{answerlist[questioncount].option3}}
                                </div>
                            </div>

                            <div *ngIf="answerlist[questioncount].option3Images != undefined">
                                <div *ngFor="let path of answerlist[questioncount].option3Images">
                                    <div *ngIf="path.includes('http')">
                                        <img src={{path}} alt="something" style="width:auto;height:auto;" />
                                    </div>
                                    <div id="maththree" *ngIf="!path.includes('http')" mathjax>
                                        {{path}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-1">

                        </div>
                        <div class="col-lg-5" (click)="option4divclicked()"
                            style="padding: 15px 15px 15px 15px;box-shadow: 0px 0px 1px 0px;;margin-top: 5%;">
                            <input type="radio" (click)="option4clicked()" name="isoption4" [(ngModel)]="isoption4"
                                formControlName="option4" [value]="true">
                            <!-- <span
                                style="margin-left: 3%;font-size: 13px;word-break: break-word;" id="mathfour" mathjax>{{answerlist[questioncount].option4}}</span> -->
                            <div *ngIf="answerlist[questioncount].option4Images == undefined" class="row"
                                style="padding: 5px 5px 5px 10px;;margin-top: 2%">
                                <div id="mathfour" mathjax>

                                    {{answerlist[questioncount].option4}}
                                </div>
                            </div>

                            <div *ngIf="answerlist[questioncount].option4Images != undefined">
                                <div *ngFor="let path of answerlist[questioncount].option4Images">
                                    <div *ngIf="path.includes('http')">
                                        <img src={{path}} alt="something" style="width:auto;height:auto;" />
                                    </div>
                                    <div id="mathfour" *ngIf="!path.includes('http')" mathjax>
                                        {{path}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End of Dropdown options with multiselect-->
                    <!-- Dropdown options with single select-->
                    <div class="row" style="padding: 5px 5px 5px 10px;margin-top: 2%;"
                        *ngIf="answerlist[questioncount].correctAnswer.length == 1 && answerlist[questioncount].questionType==1">
                        <div class="col-lg-5" (click)="option1divclicked()"
                            style="padding: 15px 15px 15px 15px;box-shadow: 0px 0px 1px 0px;;margin-top: 5%;">

                            <!-- <span
                                style="margin-left: 3%;font-size: 13px;word-break: break-word;" id="mathone" mathjax>{{answerlist[questioncount].option1}}</span> -->
                            <div *ngIf="answerlist[questioncount].option1Images == undefined" class="row"
                                style="padding: 5px 5px 5px 10px;;margin-top: 2%">
                                <div class="row" style="width: 100%;">
                                    <div class="col-lg-1 col-md-1 col-sm-1">
                                        <input type="radio" name="category" formControlName="option1"
                                            [(ngModel)]="isoption1" [value]="true">
                                    </div>
                                    <div class="col-lg-10 col-md-10 col-sm-10"
                                        style="padding-left: 0px;padding-right: 0px;">

                                        <div id="mathone" mathjax style="margin-left: 5%;">

                                            {{answerlist[questioncount].option1}}
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div *ngIf="answerlist[questioncount].option1Images != undefined">
                                <input type="radio" name="category" formControlName="option1" [(ngModel)]="isoption1"
                                    [value]="true">
                                <div *ngFor="let path of answerlist[questioncount].option1Images">
                                    <div *ngIf="path.includes('http')">
                                        <img src={{path}} alt="something" style="width:auto;height:auto;" />
                                    </div>
                                    <div id="mathone" *ngIf="!path.includes('http')" mathjax>
                                        {{path}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-1">

                        </div>
                        <div class="col-lg-5" (click)="option2divclicked()"
                            style="padding: 15px 15px 15px 15px;box-shadow: 0px 0px 1px 0px;;margin-top: 5%;">

                            <!-- <span
                                style="margin-left: 3%;font-size: 13px;word-break: break-word;" id="mathtwo" mathjax>{{answerlist[questioncount].option2}}</span> -->
                            <div *ngIf="answerlist[questioncount].option2Images == undefined" class="row"
                                style="padding: 5px 5px 5px 10px;;margin-top: 2%">
                                <div class="row" style="width: 100%;">
                                    <div class="col-lg-1 col-md-1 col-sm-1">
                                        <input type="radio" name="category" formControlName="option2"
                                            [(ngModel)]="isoption2" [value]="true">
                                    </div>
                                    <div class="col-lg-10 col-md-10 col-sm-10"
                                        style="padding-left: 0px;padding-right: 0px;">

                                        <div id="mathtwo" mathjax style="margin-left: 5%;">

                                            {{answerlist[questioncount].option2}}
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div *ngIf="answerlist[questioncount].option2Images != undefined">
                                <input type="radio" name="category" formControlName="option2" [(ngModel)]="isoption2"
                                    [value]="true">
                                <div *ngFor="let path of answerlist[questioncount].option2Images">
                                    <div *ngIf="path.includes('http')">
                                        <img src={{path}} alt="something" style="width:auto;height:auto;" />
                                    </div>
                                    <div id="mathtwo" *ngIf="!path.includes('http')" mathjax>
                                        {{path}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-1">

                        </div>
                        <div class="col-lg-5" (click)="option3divclicked()"
                            style="padding: 15px 15px 15px 15px;box-shadow: 0px 0px 1px 0px;;margin-top: 5%;">

                            <!-- <span
                                style="margin-left: 3%;font-size: 13px;word-break: break-word;" id="maththree" mathjax>{{answerlist[questioncount].option3}}</span> -->
                            <div *ngIf="answerlist[questioncount].option3Images == undefined" class="row"
                                style="padding: 5px 5px 5px 10px;;margin-top: 2%">
                                <div class="row" style="width: 100%;">
                                    <div class="col-lg-1 col-md-1 col-sm-1">
                                        <input type="radio" name="category" formControlName="option3"
                                            [(ngModel)]="isoption3" [value]="true">
                                    </div>
                                    <div class="col-lg-10 col-md-10 col-sm-10"
                                        style="padding-left: 0px;padding-right: 0px;">

                                        <div id="maththree" mathjax style="margin-left: 5%;">

                                            {{answerlist[questioncount].option3}}
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div *ngIf="answerlist[questioncount].option3Images != undefined">
                                <input type="radio" name="category" formControlName="option3" [(ngModel)]="isoption3"
                                    [value]="true">
                                <div *ngFor="let path of answerlist[questioncount].option3Images">
                                    <div *ngIf="path.includes('http')">
                                        <img src={{path}} alt="something" style="width:auto;height:auto;" />
                                    </div>
                                    <div id="maththree" *ngIf="!path.includes('http')" mathjax>
                                        {{path}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-1">

                        </div>
                        <div class="col-lg-5" (click)="option4divclicked()"
                            style="padding: 15px 15px 15px 15px;box-shadow: 0px 0px 1px 0px;;margin-top: 5%;">

                            <!-- <span
                                style="margin-left: 3%;font-size: 13px;word-break: break-word;" id="mathfour" mathjax>{{answerlist[questioncount].option4}}</span> -->
                            <div *ngIf="answerlist[questioncount].option4Images == undefined" class="row"
                                style="padding: 5px 5px 5px 10px;;margin-top: 2%">
                                <div class="row" style="width: 100%;">
                                    <div class="col-lg-1 col-md-1 col-sm-1">
                                        <input type="radio" name="category" formControlName="option4"
                                            [(ngModel)]="isoption4" [value]="true">
                                    </div>
                                    <div class="col-lg-10 col-md-10 col-sm-10"
                                        style="padding-left: 0px;padding-right: 0px;">

                                        <div id="mathfour" mathjax style="margin-left: 5%;">

                                            {{answerlist[questioncount].option4}}
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div *ngIf="answerlist[questioncount].option4Images != undefined">
                                <input type="radio" name="category" formControlName="option4" [(ngModel)]="isoption4"
                                    [value]="true">
                                <div *ngFor="let path of answerlist[questioncount].option4Images">
                                    <div *ngIf="path.includes('http')">
                                        <img src={{path}} alt="something" style="width:auto;height:auto;" />
                                    </div>
                                    <div id="mathfour" *ngIf="!path.includes('http')" mathjax>
                                        {{path}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End of Dropdown options with single select-->











                           <!-- Dropdown options with multiselect-->
                           <div class="row" style="padding: 5px 5px 5px 10px;margin-top: 2%;"
                           *ngIf="answerlist[questioncount].correctAnswer.length > 1 && answerlist[questioncount].questionType==2">
                           <div class="col-lg-5" (click)="option1divclicked()"
                               style="padding: 15px 15px 15px 15px;box-shadow: 0px 0px 1px 0px;;margin-top: 5%;">
   
                               <!-- <span
                                   style="margin-left: 3%;font-size: 13px;word-break: break-word;" id="mathone" mathjax>{{answerlist[questioncount].option1}}</span> -->
                               <div *ngIf="answerlist[questioncount].option1Images == undefined" class="row"
                                   style="padding: 5px 5px 5px 10px;;margin-top: 2%">
                                   <div id="mathone" mathjax>
                                       <div class="row">
                                           <div class="col-lg-4">
                                               <input type="radio" name="isoption1" (click)="option1clicked()"
                                                   [(ngModel)]="isoption1" formControlName="option1" [value]="true">
                                           </div>
                                           <div class="col-lg-8">
                                               {{answerlist[questioncount].option1}}
                                           </div>
                                       </div>
   
   
                                   </div>
                               </div>
   
                               <div *ngIf="answerlist[questioncount].option1Images != undefined">
                                   <div *ngFor="let path of answerlist[questioncount].option1Images">
                                       <div *ngIf="path.includes('http')">
                                           <img src={{path}} alt="something" style="width:auto;height:auto;" />
                                       </div>
                                       <div id="mathone" *ngIf="!path.includes('http')" mathjax>
                                           {{path}}
                                       </div>
                                   </div>
                               </div>
                           </div>
                           <div class="col-lg-1">
   
                           </div>
                           <div class="col-lg-5" (click)="option2divclicked()"
                               style="padding: 15px 15px 15px 15px;box-shadow: 0px 0px 1px 0px;;margin-top: 5%;">
                               <input type="radio" name="isoption2" (click)="option2clicked()" [(ngModel)]="isoption2"
                                   formControlName="option2" [value]="true">
                               <!-- <span
                                   style="margin-left: 3%;font-size: 13px;word-break: break-word;" id="mathtwo" mathjax>{{answerlist[questioncount].option2}}</span> -->
                               <div *ngIf="answerlist[questioncount].option2Images == undefined" class="row"
                                   style="padding: 5px 5px 5px 10px;;margin-top: 2%">
                                   <div id="mathtwo" mathjax>
   
                                       {{answerlist[questioncount].option2}}
                                   </div>
                               </div>
   
                               <div *ngIf="answerlist[questioncount].option2Images != undefined">
                                   <div *ngFor="let path of answerlist[questioncount].option2Images">
                                       <div *ngIf="path.includes('http')">
                                           <img src={{path}} alt="something" style="width:auto;height:auto;" />
                                       </div>
                                       <div id="mathtwo" *ngIf="!path.includes('http')" mathjax>
                                           {{path}}
                                       </div>
                                   </div>
                               </div>
                           </div>
                           <div class="col-lg-1">
   
                           </div>
                           <div class="col-lg-5" (click)="option3divclicked()"
                               style="padding: 15px 15px 15px 15px;box-shadow: 0px 0px 1px 0px;;margin-top: 5%;">
                               <input type="radio" (click)="option3clicked()" [(ngModel)]="isoption3"
                                   formControlName="option3" [value]="true">
                               <!-- <span
                                   style="margin-left: 3%;font-size: 13px;word-break: break-word;" id="maththree" mathjax>{{answerlist[questioncount].option3}}</span> -->
                               <div *ngIf="answerlist[questioncount].option3Images == undefined" class="row"
                                   style="padding: 5px 5px 5px 10px;;margin-top: 2%">
                                   <div id="maththree" mathjax>
   
                                       {{answerlist[questioncount].option3}}
                                   </div>
                               </div>
   
                               <div *ngIf="answerlist[questioncount].option3Images != undefined">
                                   <div *ngFor="let path of answerlist[questioncount].option3Images">
                                       <div *ngIf="path.includes('http')">
                                           <img src={{path}} alt="something" style="width:auto;height:auto;" />
                                       </div>
                                       <div id="maththree" *ngIf="!path.includes('http')" mathjax>
                                           {{path}}
                                       </div>
                                   </div>
                               </div>
                           </div>
                           <div class="col-lg-1">
   
                           </div>
                           <div class="col-lg-5" (click)="option4divclicked()"
                               style="padding: 15px 15px 15px 15px;box-shadow: 0px 0px 1px 0px;;margin-top: 5%;">
                               <input type="radio" (click)="option4clicked()" name="isoption4" [(ngModel)]="isoption4"
                                   formControlName="option4" [value]="true">
                               <!-- <span
                                   style="margin-left: 3%;font-size: 13px;word-break: break-word;" id="mathfour" mathjax>{{answerlist[questioncount].option4}}</span> -->
                               <div *ngIf="answerlist[questioncount].option4Images == undefined" class="row"
                                   style="padding: 5px 5px 5px 10px;;margin-top: 2%">
                                   <div id="mathfour" mathjax>
   
                                       {{answerlist[questioncount].option4}}
                                   </div>
                               </div>
   
                               <div *ngIf="answerlist[questioncount].option4Images != undefined">
                                   <div *ngFor="let path of answerlist[questioncount].option4Images">
                                       <div *ngIf="path.includes('http')">
                                           <img src={{path}} alt="something" style="width:auto;height:auto;" />
                                       </div>
                                       <div id="mathfour" *ngIf="!path.includes('http')" mathjax>
                                           {{path}}
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                       <!-- End of Dropdown options with multiselect-->
                       <!-- Dropdown options with single select-->
                       <div class="row" style="padding: 5px 5px 5px 10px;margin-top: 2%;"
                           *ngIf="answerlist[questioncount].correctAnswer.length == 1 && answerlist[questioncount].questionType==2">
                           <div class="col-lg-5" (click)="option1divclicked()"
                               style="padding: 15px 15px 15px 15px;box-shadow: 0px 0px 1px 0px;;margin-top: 5%;">
   
                               <!-- <span
                                   style="margin-left: 3%;font-size: 13px;word-break: break-word;" id="mathone" mathjax>{{answerlist[questioncount].option1}}</span> -->
                               <div *ngIf="answerlist[questioncount].option1Images == undefined" class="row"
                                   style="padding: 5px 5px 5px 10px;;margin-top: 2%">
                                   <div class="row" style="width: 100%;">
                                       <div class="col-lg-1 col-md-1 col-sm-1">
                                           <input type="radio" name="category" formControlName="option1"
                                               [(ngModel)]="isoption1" [value]="true">
                                       </div>
                                       <div class="col-lg-10 col-md-10 col-sm-10"
                                           style="padding-left: 0px;padding-right: 0px;">
   
                                           <div id="mathone" mathjax style="margin-left: 5%;">
   
                                               {{answerlist[questioncount].option1}}
                                           </div>
                                       </div>
                                   </div>
   
                               </div>
   
                               <div *ngIf="answerlist[questioncount].option1Images != undefined">
                                   <input type="radio" name="category" formControlName="option1" [(ngModel)]="isoption1"
                                       [value]="true">
                                   <div *ngFor="let path of answerlist[questioncount].option1Images">
                                       <div *ngIf="path.includes('http')">
                                           <img src={{path}} alt="something" style="width:auto;height:auto;" />
                                       </div>
                                       <div id="mathone" *ngIf="!path.includes('http')" mathjax>
                                           {{path}}
                                       </div>
                                   </div>
                               </div>
                           </div>
                           <div class="col-lg-1">
   
                           </div>
                           <div class="col-lg-5" (click)="option2divclicked()"
                               style="padding: 15px 15px 15px 15px;box-shadow: 0px 0px 1px 0px;;margin-top: 5%;">
   
                               <!-- <span
                                   style="margin-left: 3%;font-size: 13px;word-break: break-word;" id="mathtwo" mathjax>{{answerlist[questioncount].option2}}</span> -->
                               <div *ngIf="answerlist[questioncount].option2Images == undefined" class="row"
                                   style="padding: 5px 5px 5px 10px;;margin-top: 2%">
                                   <div class="row" style="width: 100%;">
                                       <div class="col-lg-1 col-md-1 col-sm-1">
                                           <input type="radio" name="category" formControlName="option2"
                                               [(ngModel)]="isoption2" [value]="true">
                                       </div>
                                       <div class="col-lg-10 col-md-10 col-sm-10"
                                           style="padding-left: 0px;padding-right: 0px;">
   
                                           <div id="mathtwo" mathjax style="margin-left: 5%;">
   
                                               {{answerlist[questioncount].option2}}
                                           </div>
                                       </div>
                                   </div>
   
   
                               </div>
   
                               <div *ngIf="answerlist[questioncount].option2Images != undefined">
                                   <input type="radio" name="category" formControlName="option2" [(ngModel)]="isoption2"
                                       [value]="true">
                                   <div *ngFor="let path of answerlist[questioncount].option2Images">
                                       <div *ngIf="path.includes('http')">
                                           <img src={{path}} alt="something" style="width:auto;height:auto;" />
                                       </div>
                                       <div id="mathtwo" *ngIf="!path.includes('http')" mathjax>
                                           {{path}}
                                       </div>
                                   </div>
                               </div>
                           </div>
                           <div class="col-lg-1">
   
                           </div>
                           <div class="col-lg-5" (click)="option3divclicked()"
                               style="padding: 15px 15px 15px 15px;box-shadow: 0px 0px 1px 0px;;margin-top: 5%;">
   
                               <!-- <span
                                   style="margin-left: 3%;font-size: 13px;word-break: break-word;" id="maththree" mathjax>{{answerlist[questioncount].option3}}</span> -->
                               <div *ngIf="answerlist[questioncount].option3Images == undefined" class="row"
                                   style="padding: 5px 5px 5px 10px;;margin-top: 2%">
                                   <div class="row" style="width: 100%;">
                                       <div class="col-lg-1 col-md-1 col-sm-1">
                                           <input type="radio" name="category" formControlName="option3"
                                               [(ngModel)]="isoption3" [value]="true">
                                       </div>
                                       <div class="col-lg-10 col-md-10 col-sm-10"
                                           style="padding-left: 0px;padding-right: 0px;">
   
                                           <div id="maththree" mathjax style="margin-left: 5%;">
   
                                               {{answerlist[questioncount].option3}}
                                           </div>
                                       </div>
                                   </div>
   
   
                               </div>
   
                               <div *ngIf="answerlist[questioncount].option3Images != undefined">
                                   <input type="radio" name="category" formControlName="option3" [(ngModel)]="isoption3"
                                       [value]="true">
                                   <div *ngFor="let path of answerlist[questioncount].option3Images">
                                       <div *ngIf="path.includes('http')">
                                           <img src={{path}} alt="something" style="width:auto;height:auto;" />
                                       </div>
                                       <div id="maththree" *ngIf="!path.includes('http')" mathjax>
                                           {{path}}
                                       </div>
                                   </div>
                               </div>
                           </div>
                           <div class="col-lg-1">
   
                           </div>
                           <div class="col-lg-5" (click)="option4divclicked()"
                               style="padding: 15px 15px 15px 15px;box-shadow: 0px 0px 1px 0px;;margin-top: 5%;">
   
                               <!-- <span
                                   style="margin-left: 3%;font-size: 13px;word-break: break-word;" id="mathfour" mathjax>{{answerlist[questioncount].option4}}</span> -->
                               <div *ngIf="answerlist[questioncount].option4Images == undefined" class="row"
                                   style="padding: 5px 5px 5px 10px;;margin-top: 2%">
                                   <div class="row" style="width: 100%;">
                                       <div class="col-lg-1 col-md-1 col-sm-1">
                                           <input type="radio" name="category" formControlName="option4"
                                               [(ngModel)]="isoption4" [value]="true">
                                       </div>
                                       <div class="col-lg-10 col-md-10 col-sm-10"
                                           style="padding-left: 0px;padding-right: 0px;">
   
                                           <div id="mathfour" mathjax style="margin-left: 5%;">
   
                                               {{answerlist[questioncount].option4}}
                                           </div>
                                       </div>
                                   </div>
   
   
                               </div>
   
                               <div *ngIf="answerlist[questioncount].option4Images != undefined">
                                   <input type="radio" name="category" formControlName="option4" [(ngModel)]="isoption4"
                                       [value]="true">
                                   <div *ngFor="let path of answerlist[questioncount].option4Images">
                                       <div *ngIf="path.includes('http')">
                                           <img src={{path}} alt="something" style="width:auto;height:auto;" />
                                       </div>
                                       <div id="mathfour" *ngIf="!path.includes('http')" mathjax>
                                           {{path}}
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                       <!-- End of Dropdown options with single select-->









                    <!-- start of Questions with textbox and image-->
                    <div style="padding: 5px 5px 5px 10px;margin-top: 2%;"
                        *ngIf="answerlist[questioncount].questionType==3">
                        <div class="row" style="padding: 5px 20px 5px 10px;">

                            <textarea cols="30" rows="5" class="form-control" formControlName="questiontype3text"
                                placeholder="Type your Answer here"></textarea>

                        </div>
                        <div class="row" style="text-align: center;">

                            <p style="text-align: center;"> or </p>

                        </div>
                        <!-- start of Questions with textbox and image-->
                        <div class="row" style="padding: 5px 20px 5px 10px;">

                            <!-- <div class="input-group">
                                <div class="custom-file">
                                    <input type="hidden" formControlName="fileupload" name="" value="">
                                    <input type="file" [ngClass]="{ 'is-invalid': f.fileupload.errors}"
                                        class="custom-file-input" accept="image/*" (change)="fileChange($event)"
                                        id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" accept="image/*">
                                    <label class="custom-file-label" for="inputGroupFile01">{{selectedFileNmae}}</label>
                                </div>
                            </div>  -->
                            <form  [formGroup]="imagecheckboxform" style="width: 100%">
                             <div class="input-group">
                                <input type="checkbox" id="imagecheckbox" name="isimagecheckbox" [(ngModel)]="isimagecheckbox" formControlName="imagecheckbox" (change)="imagecheckboxfunction($event)">
                                <label for="isimagecheckbox" style="padding-left: 1%;">    Upload images at the End</label><br>                              
                            </div>
                            </form>


                        </div>



                    </div>
                    <!-- End of Questions with textbox and image-->
                </form>

                <div class="row" style="margin-top:5%;padding-bottom: 2%">

                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="row">
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <a type="submit" (click)="previous()" class="button"
                                    style=" font-size: 13px;border: solid 1px #c7c1c1;background-color: #b7b5b7;color: black;"><span
                                        class="label" style="font-weight: 600;">Previous</span></a>
                            </div>
                            <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1">

                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">

                                <a type="submit" (click)="reviewlater()" class="button"
                                    style="font-size: 13px; background-color: #fd7649;color: black;"><span
                                        class="label" style="font-weight: 600;">Review Later</span></a>

                            </div>
                            <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1">

                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <a type="submit" class="button"
                                    style="font-size: 13px;background-color: var(--mainColor);color: white;"
                                    (click)="onSubmit(selectedoptionform)"><span class="label"
                                        style="font-weight: 600;">Save &
                                        Next</span></a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <!-- End Of Question with options -->

            <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12">
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12"
                style="box-shadow: 0px 0px 1px 0px;padding: 5px 5px 5px 10px;">
                <div class="instructor-details" style="padding-top: 5%;">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="textheader">
                                {{TestData.testName}}
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <a type="submit" class="button" data-toggle="modal" data-target="#myModal"
                                style=" margin-left: 10px;"><span class="label" style="font-weight: 600;">End
                                    Test</span></a>
                        </div>
                    </div>
                    <div class="row" style="margin-top: 3%;">
                        <div class="col-lg-4">
                            <p style="font-size: 12px;"><span class="greendot"></span> {{answeredcount}} Answered</p>
                        </div>
                        <div class="col-lg-4">
                            <p style="font-size: 12px;"><span class="orangedot"></span> {{reviewedcount}} Marked</p>
                        </div>
                        <div class="col-lg-4">
                            <p style="font-size: 12px;"><span class="yellowdot"></span> {{unansweredcount}} Unanswered
                            </p>
                        </div>
                    </div>
                    <hr>
                    <div class="row" style="margin-top: 3%;" *ngIf="subjectname1!=''">
                        <div class="col-lg-10 col-md-10">
                            <h6 style="margin-left: 1%;margin-bottom: 2%;font-size: 20px;font-weight: 700;">
                                {{subjectname1}}</h6>
                        </div>
                        <div class="col-lg-2 col-md-2" *ngIf="subjectname1arrowdown">
                            <img src="../../../../assets/img/down-arrow.png" (click)="subjectname1arrowdownfunction()"
                                style="height: 20px;width: 20px;" alt="image">
                        </div>
                        <div class="col-lg-2 col-md-2" *ngIf="subjectname1arrowup">
                            <img src="../../../../assets/img/up-arrow.png" (click)="subjectname1arrowupfunction()"
                                style="height: 20px;width: 20px;" alt="image">
                        </div>
                        <ng-container *ngIf="subjectname1arrayquestion">
                            <div style="margin-top: 3%;" *ngFor="let subjects1 of subjectname1array; let i = index">
                                <a type="submit" (click)="questionnumberbutton(subjects1.questionNumber)"
                                    *ngIf="subjects1.status=='Answered'" class="buttontext"
                                    style=" margin-left: 10px;margin-top:2%;background-color: #00adee;color: white;"><span
                                        class="label" style="font-weight: 600;">{{subjects1.questionNumber}}</span></a>
                                <a type="submit" *ngIf="subjects1.status=='Unanswered'" class="buttontext"
                                    style=" margin-left: 10px;margin-top:2%"
                                    (click)="questionnumberbutton(subjects1.questionNumber)"><span class="label"
                                        style="font-weight: 600;">{{subjects1.questionNumber}}</span></a>
                                <a type="submit" (click)="questionnumberbutton(subjects1.questionNumber)"
                                    *ngIf="subjects1.status=='Reviewed'" class="buttontext"
                                    style=" margin-left: 10px;margin-top:2%;background-color: #fd7649;color: white;"><span
                                        class="label" style="font-weight: 600;">{{subjects1.questionNumber}}</span></a>
                            </div>
                        </ng-container>
                    </div>

                    <hr *ngIf="subjectname1!=''">

                    <div class="row" style="margin-top: 3%;" *ngIf="subjectname2!=''">
                        <div class="col-lg-10 col-md-10">
                            <h6 style="margin-left: 1%;margin-bottom: 2%;font-size: 20px;font-weight: 700;">
                                {{subjectname2}}</h6>
                        </div>
                        <div class="col-lg-2 col-md-2" *ngIf="subjectname2arrowdown">
                            <img src="../../../../assets/img/down-arrow.png" (click)="subjectname2arrowdownfunction()"
                                style="height: 20px;width: 20px;" alt="image">
                        </div>
                        <div class="col-lg-2 col-md-2" *ngIf="subjectname2arrowup">
                            <img src="../../../../assets/img/up-arrow.png" (click)="subjectname2arrowupfunction()"
                                style="height: 20px;width: 20px;" alt="image">
                        </div>
                        <ng-container *ngIf="subjectname2arrayquestion">
                            <div style="margin-top: 3%;" *ngFor="let subjects2 of subjectname2array; let i = index">
                                <a type="submit" (click)="questionnumberbutton(subjects2.questionNumber)"
                                    *ngIf="subjects2.status=='Answered'" class="buttontext"
                                    style=" margin-left: 10px;margin-top:2%;background-color: #00adee;color: white;"><span
                                        class="label" style="font-weight: 600;">{{subjects2.questionNumber}}</span></a>
                                <a type="submit" *ngIf="subjects2.status=='Unanswered'" class="buttontext"
                                    style=" margin-left: 10px;margin-top:2%"
                                    (click)="questionnumberbutton(subjects2.questionNumber)"><span class="label"
                                        style="font-weight: 600;">{{subjects2.questionNumber}}</span></a>
                                <a type="submit" (click)="questionnumberbutton(subjects2.questionNumber)"
                                    *ngIf="subjects2.status=='Reviewed'" class="buttontext"
                                    style=" margin-left: 10px;margin-top:2%;background-color: #fd7649;color: white;"><span
                                        class="label" style="font-weight: 600;">{{subjects2.questionNumber}}</span></a>
                            </div>
                        </ng-container>
                    </div>

                    <hr *ngIf="subjectname2!=''">

                    <div class="row" style="margin-top: 3%;" *ngIf="subjectname3!=''">
                        <div class="col-lg-10 col-md-10">
                            <h6 style="margin-left: 1%;margin-bottom: 2%;font-size: 20px;font-weight: 700;">
                                {{subjectname3}}</h6>
                        </div>
                        <div class="col-lg-2 col-md-2" *ngIf="subjectname3arrowdown">
                            <img src="../../../../assets/img/down-arrow.png" (click)="subjectname3arrowdownfunction()"
                                style="height: 20px;width: 20px;" alt="image">
                        </div>
                        <div class="col-lg-2 col-md-2" *ngIf="subjectname3arrowup">
                            <img src="../../../../assets/img/up-arrow.png" (click)="subjectname3arrowupfunction()"
                                style="height: 20px;width: 20px;" alt="image">
                        </div>
                        <ng-container *ngIf="subjectname3arrayquestion">
                            <div style="margin-top: 3%;" *ngFor="let subjects3 of subjectname3array; let i = index">

                                <a type="submit" (click)="questionnumberbutton(subjects3.questionNumber)"
                                    *ngIf="subjects3.status=='Answered'" class="buttontext"
                                    style=" margin-left: 10px;margin-top:2%;background-color: #00adee;color: white;"><span
                                        class="label" style="font-weight: 600;">{{subjects3.questionNumber}}</span></a>
                                <a type="submit" *ngIf="subjects3.status=='Unanswered'" class="buttontext"
                                    style=" margin-left: 10px;margin-top:2%"
                                    (click)="questionnumberbutton(subjects3.questionNumber)"><span class="label"
                                        style="font-weight: 600;">{{subjects3.questionNumber}}</span></a>
                                <a type="submit" (click)="questionnumberbutton(subjects3.questionNumber)"
                                    *ngIf="subjects3.status=='Reviewed'" class="buttontext"
                                    style=" margin-left: 10px;margin-top:2%;background-color: #fd7649;color: white;"><span
                                        class="label" style="font-weight: 600;">{{subjects3.questionNumber}}</span></a>
                            </div>
                        </ng-container>
                    </div>

                    <hr *ngIf="subjectname3!=''">

                    <div class="row" style="margin-top: 3%;" *ngIf="subjectname4!=''">
                        <div class="col-lg-10 col-md-10">
                            <h6 style="margin-left: 1%;margin-bottom: 2%;font-size: 20px;font-weight: 700;">
                                {{subjectname4}}</h6>
                        </div>
                        <div class="col-lg-2 col-md-2" *ngIf="subjectname4arrowdown">
                            <img src="../../../../assets/img/down-arrow.png" (click)="subjectname4arrowdownfunction()"
                                style="height: 20px;width: 20px;" alt="image">
                        </div>
                        <div class="col-lg-2 col-md-2" *ngIf="subjectname4arrowup">
                            <img src="../../../../assets/img/up-arrow.png" (click)="subjectname4arrowupfunction()"
                                style="height: 20px;width: 20px;" alt="image">
                        </div>
                        <ng-container *ngIf="subjectname4arrayquestion">
                            <div style="margin-top: 3%;" *ngFor="let subjects4 of subjectname4array; let i = index">
                                <a type="submit" (click)="questionnumberbutton(subjects4.questionNumber)"
                                    *ngIf="subjects4.status=='Answered'" class="buttontext"
                                    style=" margin-left: 10px;margin-top:2%;background-color: #00adee;color: white;"><span
                                        class="label" style="font-weight: 600;">{{subjects4.questionNumber}}</span></a>
                                <a type="submit" *ngIf="subjects4.status=='Unanswered'" class="buttontext"
                                    style=" margin-left: 10px;margin-top:2%"
                                    (click)="questionnumberbutton(subjects4.questionNumber)"><span class="label"
                                        style="font-weight: 600;">{{subjects4.questionNumber}}</span></a>
                                <a type="submit" (click)="questionnumberbutton(subjects4.questionNumber)"
                                    *ngIf="subjects4.status=='Reviewed'" class="buttontext"
                                    style=" margin-left: 10px;margin-top:2%;background-color: #fd7649;color: white;"><span
                                        class="label" style="font-weight: 600;">{{subjects4.questionNumber}}</span></a>
                            </div>
                        </ng-container>
                    </div>
                    <!-- <div class="row">
                        <div id="ck-button">
                            <label>
                                <input type="checkbox" value="1"><span>1</span>
                            </label>
                        </div>
                        <div id="ck-button">
                            <label>
                                <input type="checkbox" value="1"><span>2</span>
                            </label>
                        </div>
                        <div id="ck-button">
                            <label>
                                <input type="checkbox" value="1"><span>3</span>
                            </label>
                        </div>
                        <div id="ck-button">
                            <label>
                                <input type="checkbox" value="1"><span>4</span>
                            </label>
                        </div>
                        <div id="ck-button">
                            <label>
                                <input type="checkbox" value="1"><span>5</span>
                            </label>
                        </div>
                        <div id="ck-button">
                            <label>
                                <input type="checkbox" value="1"><span>6</span>
                            </label>
                        </div>
                        <div id="ck-button">
                            <label>
                                <input type="checkbox" value="1"><span>7</span>
                            </label>
                        </div>

                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>


<section class="courses-details-area pt-100 pb-70" style="padding-top: 30px!important" *ngIf="isquestion && uploadimage">
    <div class="container">

        <div class="modal" id="myimageModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">
                        <img src={{showimageinmodal}} id="imagepreview" style="width: 425px; height: 425px;" >
                    </div>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="box-shadow: 0px 0px 1px 0px;">
                <div class="row" style="height: 75px;padding-top: 2%;">
                    <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                        <p style="word-break: break-word;font-size: 20px;color:black;font-weight: 700">
                           Upload Images for {{TestData.testName}}</p>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                        <a (click)="uploadimages()" class="default-btn"><i
                            class='bx bx-paper-plane icon-arrow before'></i><span
                            class="label">Upload</span><i
                            class="bx bx-paper-plane icon-arrow after"></i></a>
                    </div>
                </div>
            </div>

        </div>

        <div class="row" style="margin-top:3%">
            <form class="edit-account" [formGroup]="selectedimageform">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding: 5px 5px 5px 10px;border-bottom: 1px solid lightgrey;color: white;">

<!-- 
                <div class="input-group">
                    <div class="custom-file">
                        <input type="hidden" formControlName="fileupload" name="" value="">
                        <input type="file" [ngClass]="{ 'is-invalid': f.fileupload.errors}"
                            class="custom-file-input" accept="image/*" (change)="fileChange($event)"
                            id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" accept="image/*">
                        <label class="custom-file-label" for="inputGroupFile01">{{selectedFileNmae}}</label>
                    </div>
                </div>  -->


                <div class="form-group">

                    <label for="file">File</label>
            
                    <input 
            
                        formControlName="file"
            
                        id="file" 
            
                        type="file" 
            
                        class="form-control"
            
                        multiple="" 
            
                        (change)="onFileChange($event)">
            
                 
            
                </div>
            
               <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngFor='let url of images;let index = index'>                
              
                    <img   [src]="url" height="100" width="100%" (click)="imageselected(url)" style="margin: 3px;padding: 5px 5px 5px 10px;border-bottom: 1px solid lightgrey;color: white;"  data-toggle="modal" data-target="#myimageModal"> <br/>
                    <a class="button" (click)="removeSelectedFile(index)">Remove</a>
               
            </div>
        </div>
               

            </div>
            </form>

        </div>
    </div>
</section>