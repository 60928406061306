import { Component, OnInit,Input,EventEmitter,Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../shared/api.service';
import { NgxSpinnerService } from "ngx-spinner";
import { MediaService } from '../../../shared/media.service';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'listClass',
  templateUrl: './class-list.component.html',
  styleUrls: ['./class-list.component.scss']
})
export class ClassListComponent implements OnInit {

  @Input() data:any = [] ;
  @Output() pageNumberClass =  new EventEmitter<any>();
  @Input() typeClass:String ='';
  @Input() itemNameClass:String = '';
  @Input() configClass:any = [];
  @Input() redirectTextClass:String = 'details/';
  constructor(private router:Router,private toastr:ToastrManager,private api:MediaService,private SpinnerService: NgxSpinnerService,private userApi:ApiService) { }

  	ngOnInit(): void {
    console.log(this.data)
  	}
    ngAfterViewInit() {
        // this.cd.detectChanges();
    }
  	redirectDetails(data){   
  		var isAccept = data.acceptStatus ? 'one2oneAccept' : 'viewRequest';
  		if(data.classGroup){
       		this.router.navigate(['/automation' + "/" + data.meetingID], { state: data });
    	}else{
      		this.router.navigate(['/student/'+isAccept+'/' + "/" + data.meetingID], { state: data });
    	} 
    }
  	pageChanged(event){
  		var data={
  			currentPage:event,
  			list:this.itemNameClass,
  			type:this.typeClass
  		}
  		this.pageNumberClass.emit(data)
  	}
  	fetchrecordvideo(id,type) {
    	var req = {
    	  [type]: id
    	}
    	// this.SpinnerService.show()
    	this.api.fetchrecordvideo(req).subscribe((result) => {
    	  // this.SpinnerService.hide()
    	  if (result.status) {
    	    window.open(result.data[0].recordurl, '_blank');
    	  }
    	}, (err) => {
    	  this.toastr.errorToastr(err.message);
    	});

  	}
}
