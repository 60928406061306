import { Component, OnInit, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Options } from 'ng5-slider';
import { ApiService } from '../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { Location } from '@angular/common';
import * as moment from 'moment';
import { MediaService } from '../../../shared/media.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ViewChild } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-study-material-details',
  templateUrl: './study-material-details.component.html',
  styleUrls: ['./study-material-details.component.scss']
})
export class StudyMaterialDetailsComponent implements OnInit {

  public materialID: any = ''
  constructor(private mediaAPI: MediaService, private location: Location, private activatedRoute: ActivatedRoute, public toastr: ToastrManager, private formBuilder: FormBuilder, private rest: ApiService, private router: Router, private SpinnerService: NgxSpinnerService, private sanitizer: DomSanitizer, private datePipe: DatePipe) { }
  public livedetails: any = [];
  public categoryarrayvalues;
  public subjectarrayvalues;
  public gradearrayvalues;
  isLoggedin = "false";
  public payment;
  public purchase;
  public teacherID;
  formData;
  amount: any;
  materialname: any;
  public isTeacher: any = false;
  isItemAddedToCart: boolean = false;


  ngOnInit(): void {
    const webToken = localStorage.getItem('webtoken');
    this.isLoggedin = localStorage.getItem('isLoggedin');
    this.payment = localStorage.getItem('paymentID')
    this.materialID = this.activatedRoute.snapshot.params.id
    this.teacherID = localStorage.getItem('teacherID');
    this.isTeacher = localStorage.getItem('profileType').toLowerCase() =='teacher';
    this.getstudyDetails();
    this.isMaterialAddedToCart();
  }
  getstudyDetails() {
    const webToken = localStorage.getItem('webtoken');
    this.rest.getStudyDetails(webToken, { materialID: this.materialID }).subscribe((response) => {
      console.log('dfdfdfdf', response)
      if (response.status) {

        this.livedetails = response.data;
        this.amount = response.data.price;
        this.materialname = response.data.materialName;
        this.purchase = response.data.isMaterialPurchased
        console.log('final', this.livedetails)
        let categoryarray: any = [];
        let gradearray: any = [];
        let subjectarray: any = [];
        if (this.livedetails.categoryID.length > 0) {
          for (var i = 0; i < this.livedetails.categoryID.length; i++) {
            categoryarray.push(this.livedetails.categoryID[i].categoryName);
          }
          this.categoryarrayvalues = categoryarray.toString();
        }

        // if (this.livedetails.subjectID.length > 0) {
        //   for (var i = 0; i < this.livedetails.subjectID.length; i++) {
        //     subjectarray.push(this.livedetails.subjectID[i].subjectName);
        //   }
        //   this.subjectarrayvalues = subjectarray.toString();
        // }

        // if (this.livedetails.gradeID.length > 0) {
        //   for (var i = 0; i < this.livedetails.gradeID.length; i++) {
        //     gradearray.push(this.livedetails.gradeID[i].gradeName);
        //   }
        //   this.gradearrayvalues = gradearray.toString();
        // }
        // if(this.livedetails.length!=0 && this.livedetails.tag !=''){
        //   this.tagArray = this.livedetails.tag.split(',')
        // }
        // this.reviewGet(this.livedetails._id);
      }
    });
  }
  requestAccept() {
    alert(this.materialID)
    const webToken = localStorage.getItem('webtoken');
    this.formData = new FormData();
    this.formData.append("paymentID", "60c5fbda392adabe8ed07b7c");
    this.formData.append("itemID", this.materialID);
    this.formData.append("itemType", "Video");
    var req = {
      paymentID: "60c5fbda392adabe8ed07b7c",
      itemID: this.materialID,
      itemType: "SmartMaterial"
    }
    localStorage.setItem('paymentID', "60c5fbda392adabe8ed07b7c");
    this.rest.paymentTest(req, webToken).subscribe((result) => {

      // console.log('result of explore', result)
      // this.SpinnerService.hide();
      if (result.status) {
        alert(result.message)
        // this.videoList = result.data.splice(0,4);
        // console.log('list video',this.videoList)

      } else {
        // this.videoList = [];
      }
    }, (err) => {
      console.log(err);
    });

  }
  login() {
    var data = {
      isredirect: true
    }
    this.router.navigate(['/student/login'], { state: data });
  }
  addcart() {
    var tempcart = [];

    var cart = {

      "itemID": this.materialID,
      "itemName": this.materialname,
      "itemType": "SmartMaterial",
      "price": this.amount

    }
    tempcart.push(cart);
    tempcart = tempcart.concat(JSON.parse(localStorage.getItem('cartItems') || '[]'));
    localStorage.setItem('cartItems', JSON.stringify(tempcart));
    this.isItemAddedToCart = true;

    document.getElementById('cartSize').innerText = "Cart(" + tempcart.length.toString() + ")"
    alert("Material Added To Cart")
  }
  payments() {
    var buyNowItem = {};
    var tempMaterialID = this.materialID;
    buyNowItem = {
      "itemID": this.materialID,
      "itemName": this.materialname,
      "itemType": "Video",
      "price": this.amount
    };
    localStorage.setItem('buyNowItem', JSON.stringify(buyNowItem))
    if(this.isItemAddedToCart){
      let tempCart = JSON.parse(localStorage.getItem('cartItems') || '[]');
      var newCart = tempCart.filter(function(item){
        if(item.itemID == tempMaterialID && item.itemType == "Video"){
          return false;
        }
        else{
          return true;
        }
      })
      localStorage.setItem('cartItems', JSON.stringify(newCart))
    }
    this.router.navigateByUrl('/payment')
  }
  backto() {
    this.location.back();
  }
  toggleVideo(event: any) {
    // var data={ itemId : this.reviewForm.value.itemId}
    //  this.rest.updateviewCount(data,localStorage.getItem('webtoken')).subscribe((response) => {

    //    if(response.status){
    //      this.reviewGet(this.livedetails._id);
    //    }
    //  });
  }

  isMaterialAddedToCart() {
    let tempCart = JSON.parse(localStorage.getItem('cartItems') || '[]');
    for (var item of tempCart) {
      console.log(item);
      if (item.itemID == this.materialID && item.itemType == "SmartMaterial") {
        this.isItemAddedToCart = true;
      }
    }
  }

  addToDashboard(data){
    const webToken = localStorage.getItem('webtoken');
    var request = {};
    request['materialID'] = data._id
    this.SpinnerService.show();
    this.rest.getWalletBalance(webToken).subscribe((response) => {
      console.log("material", response)
      if(response.data < this.livedetails.price){
        this.SpinnerService.hide();
        this.toastr.errorToastr("Insufficient balance. Please recharge your wallet.")
      }
      else{
        this.SpinnerService.hide();
        this.rest.addMaterialToPurchased(webToken, request).subscribe((result) => {
          if(result.status){
            this.toastr.successToastr("Material Added to your dashboard");
            this.router.navigate(['/sidenav']);
          }
          else{
            this.toastr.errorToastr("Something went wrong. Please try again later")
            console.log(result)
          }
        }) 
      }
    })
    
  }
  redirectToEdit(data){
    this.router.navigate(['/teacher-smartmaterial-edit/' + data._id])
  }

  addToCourseCompletion() {
    console.log("Test");
    var reqData = {
      courseID: this.livedetails.courseID._id,
      topicID: this.livedetails.topicID._id,
      methodOfLearning: "studymaterial"
    };
      console.log(reqData);
      this.rest.addCourseCompletion(localStorage.getItem('webtoken'), reqData).subscribe((response) => {
        console.log(response);
       });
    
  }
  deleteSmartMaterial(){
    this.SpinnerService.show()
    var req={
      materialID : this.livedetails._id,
      }
      
    this.mediaAPI.deleteuploadedsmartmaterial(req).subscribe((result) => {
         this.SpinnerService.hide()
      if(result.status){
        this.toastr.successToastr(result.message);          
        this.location.back();
      }else{
        this.toastr.errorToastr(result.message);          
      }
    });
  }

}
