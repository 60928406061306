import { Component, OnInit } from '@angular/core';
import { Router , ActivatedRoute} from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import {User} from '../../model/user';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../../../shared/api.service';
import { createTrue } from 'typescript';
@Component({
  selector: 'app-studentforgetpassword',
  templateUrl: './studentforgetpassword.component.html',
  styleUrls: ['./studentforgetpassword.component.scss']
})
export class StudentforgetpasswordComponent implements OnInit {
  public isStudent: boolean = false;
  public isTeacher: boolean = false;
  public user = new User('', '');
  fb: any;
  registerForm: FormGroup;
  submitted: boolean;
  interval;
  timeLeft: number = 25;
  isButtonDisabled = false;
  Email;
  changeNumberStatus = false;
  isUserLoggedIn: string;
  isChangeNumberButtonDisabled =false;
  constructor(
    private my_router: ActivatedRoute,
      public router: Router,
      private userApi: ApiService,
      public toastr: ToastrManager,
      private formBuilder: FormBuilder

    ) { }

  ngOnInit() {
      
    this.isTeacher =
            localStorage.getItem("profileType").toLowerCase() == "teacher";
        this.isStudent =
            localStorage.getItem("profileType").toLowerCase() == "student";
    let verifytoken = this.my_router.snapshot.paramMap.get('id');
    if (verifytoken != null) {
      document.getElementById('MobileNumberFormID').hidden = true;
      document.getElementById('OTPFormID').hidden = false;
    }
    else {
      document.getElementById('MobileNumberFormID').hidden = false;
      document.getElementById('OTPFormID').hidden = true;
    }
    this.userdetails();
    // document.getElementById('MobileNumberFormID').hidden = false;
    // document.getElementById('OTPFormID').hidden = true;
    // document.getElementById('otpResendButton').hidden = true;
    // document.getElementById('otpSentSuccess').hidden = true;
    // document.getElementById('ChangePasswordID').hidden = true;
    // this.changeNumberStatus = false;
    this.registerForm = this.formBuilder.group({
     
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      otp: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      password:  ['', [ Validators.required,Validators.minLength(8),Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
      checkpassWord: ['', [ Validators.required]],
      profileType:'Student'
      
  });
  this.isUserLoggedIn = localStorage.getItem('isLoggedin');
  }
  get f() {
    return this.registerForm.controls;
    }
    

  submit(){
    this.submitted = true;
    // if (this.registerForm.invalid) {
    //   this.toastr.errorToastr('Invalid Fields');
    //     return;
    // }
    console.log(this.registerForm.value.email);
    this.Email = this.registerForm.value.email;
    if (this.registerForm.value.email === '') {
      this.toastr.errorToastr('You must fill out all the fields');
      return;
    }    
    const data = {"email":this.Email}
    console.log('data',data)
    this.userApi.studentforgotemail(data).subscribe(res => {
      console.log('result', res );
      if(res.status){
        this.router.navigate(['/student/login']);
        // document.getElementById('MobileNumberFormID').hidden = true;
        // document.getElementById('OTPFormID').hidden = false;
        // document.getElementById('otpResendTimer').hidden = false;
        // document.getElementById('otpResendButton').hidden = true;
        this.toastr.successToastr(res.message);
        this.startTimer();
      }else{
        this.toastr.errorToastr(res.message);
      }
    });

    
  }

  // onLoggedin() {
  //   if (this.user.email === '') {
  //       // this.toastr.errorToastr('Please Enter Email');
  //       alert('enter email');
  //   } else {
  //     this.userApi.forgotPage(this.user.email).subscribe((result) => {

  //         console.log('Reg', result);
  //         if (result.status) {
  //             // this.onRegisterin(this.registerForm.value)
  //             this.toastr.successToastr('Your Registration Successfull');

  //             this.router.navigate(['/login']);
  //         } else {
  //             this.toastr.errorToastr(result.message);
  //             // alert(result.message);
  //         }
  //       }, (err) => {
  //           console.log(err);
  //       });
  //   }
  // }

  startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } 
      else {
        document.getElementById('otpResendTimer').hidden = true;
        document.getElementById('otpResendButton').hidden = false;
        
        clearInterval(this.interval);
      }
    },1000)
  }

  otpResendClicked() {
    console.log("Clicked");
    document.getElementById('otpResendButton').hidden = true;
    document.getElementById('otpResendTimer').hidden = false;
    this.timeLeft = 60;
    this.startTimer();
    console.log('checking register',this.registerForm.value)    
    const data = {phoneNumber:this.registerForm.value.mobileNumber1}
    this.userApi.studentforgotsendOTP(data).subscribe(res => {
      if(res.status){
        this.toastr.successToastr("OTP sent");
        //this.router.navigate(['/login']);
        console.log("OTP sent");
      }
      else {
        this.toastr.errorToastr(res.message);
      }
    });

    (<HTMLInputElement> document.getElementById("otpResendButton")).disabled = true;
    this.isButtonDisabled = true;
    document.getElementById('otpResendButton').hidden = true;
    this.toastr.successToastr('Another OTP has been sent!');
    //document.getElementById('otpSentSuccess').hidden = false;
  }

  changePhoneNumber() {
    // this.changeNumberStatus = true;
    document.getElementById('MobileNumberFormID').hidden = false;
    document.getElementById('OTPFormID').hidden = true;
    document.getElementById('otpResendTimer').hidden = true;
    document.getElementById('otpResendButton').hidden = true;
    document.getElementById('otpSentSuccess').hidden = true;
    document.getElementById('ChangePasswordID').hidden = true;
    clearInterval(this.interval);
    this.timeLeft = 25;
  }

  OTPSubmit() {
    console.log('checking register',this.registerForm.value)
  // alert(this.registerForm.value.password);
    if(this.registerForm.value.password !== undefined){
        const passid = this.my_router.snapshot.paramMap.get('id');
        // alert(passid);
        var otpdata = {};
        otpdata["token"]=passid;
        otpdata["password"]=this.registerForm.value.password;
        this.userApi.checkmailforgotStudent(otpdata).subscribe(res => {
          if(res.status){
            this.toastr.successToastr(res.message);
            this.router.navigate(['/student/login']);
          }else{
            this.toastr.errorToastr(res.message);
          }
        });
    
    }
    else {
      this.toastr.errorToastr('Password Required');
    }
  }
  PasswordSubmit() {
    console.log('checking register',this.registerForm.value)   
    if(this.registerForm.value.passWord !== "" && this.registerForm.value.checkpassWord !== "") {
      if(this.registerForm.value.passWord === this.registerForm.value.checkpassWord) {
        var passwordchange = {};
        passwordchange["phoneNumber"]=this.registerForm.value.mobileNumber1;
        passwordchange["password"]=this.registerForm.value.passWord;
          this.userApi.forgotPasswordchangestudent(passwordchange).subscribe(res => {
            if(res.status){
              this.toastr.successToastr(res.message);
              this.router.navigate(['/student/login']);
            }else{
              this.toastr.errorToastr(res.message);
            }
        
        });
      }
      else{
        this.toastr.errorToastr("Password Mismatch");
      }
    }
    else {
      this.toastr.errorToastr("All fields must be filled out");
    }
  
  }

  userdetails() {
    const webToken = localStorage.getItem('webtoken');
    if(webToken != null){
    this.userApi.userName_DisplayStudent(webToken).subscribe((result) => {
      console.log(result.data);
    });
  }
}

}
