import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'productexpired'
})
export class ProductexpiredPipe implements PipeTransform {

  transform(value: any): any {
    if(value){
        return new Date() < new Date(value) ? 'Active' : 'Expired';
    }
    return null;
  }

}
