import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../shared/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as socketio from 'socket.io-client';
import { environment } from '../../../../environments/environment'
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-header-style-one-teacher',
  templateUrl: './header-style-one-teacher.component.html',
  styleUrls: ['./header-style-one-teacher.component.scss']
})
export class HeaderStyleOneTeacherComponent implements OnInit {

  isLogin: any = false;
  pagename: any;
  loginname: any;
  allheader: any = [];
  public teacherData: any = [];
  notidata: any = [];
  cartSize: number;
  socket: any;
  readonly url
  public notification: any = [];
  public notificationLength: any = [];
  public coursecategory: any = [];
  public coursecategoryselected: any = [];
  public iskyc: boolean = false
  public feedbackPending: boolean = false
  public isTeacher: boolean = false;
  constructor(
    private userApi: ApiService,
    private router: Router,
    public toastr: ToastrManager,
    private SpinnerService: NgxSpinnerService,

  ) {
    this.socket = socketio(environment.socket.baseUrl, environment.socket.config);
    this.getmessage().subscribe(data => console.log(data));
  }

  ngOnInit(): void {
    // this.iskyc = localStorage.getItem('kycStatus') == 'true' ? true : false;
    const webToken = localStorage.getItem('webtoken');
    if (localStorage.getItem('profileType') != null) {
      this.isTeacher = localStorage.getItem('profileType').toLowerCase() == 'teacher'
    }
    // this.userApi.userName_DisplayTeacher(webToken).subscribe((response) => {
    //   if (response.status) {
    //     this.teacherData = response.data;
    //     console.log('students', this.teacherData);
    //   }
    // });
    // this.userApi.kycStatus(webToken).subscribe((result) => {
    //   if (result.status && result.data != null && result.data != undefined) {
    //     if (result.data) {
    //       this.iskyc = result.data.data.isApproved;
    //       this.feedbackPending = localStorage.getItem('isPendindTeacher') == 'true' ? true : false
    //       localStorage.setItem('kycStatus', this.iskyc ? 'true' : 'false')
    //     }
    //   }
    // });
    if (localStorage.getItem('cartItems') == null) {
      this.cartSize = 0
    }
    else {
      this.cartSize = JSON.parse(localStorage.getItem('cartItems')).length;
    }
    window.addEventListener('storage', () => {
      console.log("called")
    }, false);
    //   this.userApi.getCourseCategory().subscribe((result) => {
    //     if (result.status) {
    //       this.allheader = result.data;
    //     } else {
    //     }
    // }, (err) => {
    //     console.log(err);
    // });

    // this.userApi.getCourseCategoryModuleHeader().subscribe((result) => {
    //   console.log('========',result)
    //   if (result.status) {
    //     this.coursecategory = result.data.splice(0, 10);
    //   } else {
    //   }
    // }, (err) => {
    //   console.log(err);
    // });

    this.pagename = localStorage.getItem('profileType');
    this.loginname = this.pagename + '/login';
    this.isLogin = localStorage.getItem('isLoggedin');
    // if (this.isLogin == 'true') {
    //   this.getnotificationInterval();
    // }
  }
  // getnotificationInterval() {
  //   var userid = localStorage.getItem('userId');


  //   this.pushData('CREATEROOM', userid);
  //   this.getmessage().subscribe(data => console.log(data))
  //   // const webToken = localStorage.getItem('webtoken');
  //   // setInterval(()=>{
  //   //  this.userApi.getstudentnotification(webToken).subscribe((response) => {
  //   //    if(response.status){
  //   //      this.notification = response.data.notifyData;
  //   //      this.notificationLength = response.data.Totallength
  //   //    }
  //   //   })
  //   // },3000);
  // }

  // clc(){
  //   this.router.navigate(["/nft"]);
  // }
  requestAccept(data) {
    const webToken = localStorage.getItem('webtoken');
    this.userApi.statusChangeNotification(data._id, webToken).subscribe((result) => {
      if (result.status) {
        // this.router.navigate(["/"+data.redirectUrl]);
        // window.location.href = '/'+data.redirectUrl
        window.location.href = data.redirectUrl;
      }
    });
  }
  detailsAccept(data) {
    this.router.navigate(["/myCalendar"]);
  }
  logout() {
    localStorage.setItem('isLoggedin', 'false');
    window.location.href = localStorage.getItem('profileType').toLowerCase() + '/login';
    // this.router.navigate(["/"]);
  }
  getData(): Observable<any> {
    return new Observable((observer) =>
      this.socket.on('CREATEROOM', (userid) => observer.next(userid))
      //this.socket.on('SEND_MESSAGE', (data) => observer.next(data))
    );

  }

  getmessage(): Observable<any> {
    return new Observable((observer) =>
      this.socket.on('message', (data) => {
        this.notidata = JSON.stringify(data);
        this.notification = data.notifyData;
        this.notificationLength = data.Totallength;
      })

    );
  }


  listen(event: string): Observable<any> {
    return new Observable(subscriber => {

      this.socket.on(event, data => {
        subscriber.next(data);
      })
    })
  }

  pushData(event: string, data?: any) {
    this.socket.emit(event, data);

  }

  selectedmenu(categoryid, moduleid) {
    this.SpinnerService.show();
    var req = {
      moduleID : moduleid
    }
    this.userApi.getClassCourseTemplatefromModule(req).subscribe((result) => {
      this.SpinnerService.hide();
      if(result.status){
        if(result.data.templateList.length > 1){
          this.router.navigate(['/class-course-list/' + moduleid]);
        }
        else{
          var templateID = result.data.templateList[0]._id;
          if(this.isTeacher){
            this.router.navigate(['/ClassCourseDetails/' + templateID]);
          }
          else{
            this.router.navigate(['/StudentCourseDetails/' + templateID]);
          }
          
        }
      }
      else{
        this.toastr.errorToastr("There was an issue. Please try again later.")
      }
    });
  }


  isKycStudyhall() {

    var usertype1 = localStorage.getItem('profileType');
    // this.iskyc = localStorage.getItem('kycStatus') == 'true' ? true : false;

    // if (this.iskyc == true) {
    //   // this.toastr.errorToastr('Please Submit The Kyc form');

    //   window.location.href = '/studyhallList';

    // }
    // else if (usertype1.toLowerCase() == "student") {
    //   // this.isstudent = true;

    //   window.location.href = 'studyhallList';
    // }

    // else {
    //   this.toastr.errorToastr('Please Submit The Kyc form');

    //   window.location.href = '/kyc';

    // }

    // commented by Monish on 13/11/2021
    window.location.href = '/studyhallList';
  }

}
