<div class="single-courses-box without-box-shadow mb-30">
    <div class="courses-box-footer" *ngIf="slide.classType!='One-on-One' && !hideData">

        <div *ngIf="!slide.isrecorded && slide.meetingType==undefined">
            Type: {{slide.securitytype =='Group' ? 'School' : slide.securitytype}}
        </div>
        <div *ngIf="slide.isrecorded && !slide.instructor && slide.meetingType!=undefined">
            Type: {{slide.meetingType =='Group' ? 'School' : slide.meetingType}}
        </div>
        <div *ngIf="slide.isrecorded && !slide.instructor && slide.securitytype!=undefined">
            Type: {{slide.securitytype =='Group' ? 'School' : slide.securitytype}}
        </div>
        <div *ngIf="slide.isrecorded && slide.instructor">
            Type: {{slide.meetingType =='Group' ? 'School' : slide.meetingType}}
        </div>
        <div *ngIf="slide.meetingType!=undefined">
            Type: {{slide.meetingType =='Group' ? 'School' : slide.meetingType}}
        </div>

    </div>
    
 	<div class="courses-box-footer" *ngIf="slide.categoryID.length > 0" style="word-break: break-all">
        {{slide.categoryID ? slide.categoryID[0].categoryName : '' }},
        {{slide.gradeID ? slide.gradeID[0].gradeName : '' }},
        {{slide.boardID ? slide.boardID.boardName : '' }}
	</div>
    <div class="courses-box-footer" *ngIf="slide.subjectID.length > 0" style="word-break: break-all">
        {{slide.subjectID ? slide.subjectID[0].subjectName : '' }},
        {{slide.languageID ? slide.languageID.languageName : '' }}
    </div>
    <div *ngIf="slide.isPartner && slide.providerByName!='You Tube'" class="courses-box-footer" >
        Provided By : 
        {{slide.partner ==null || slide.partner.partnerName==undefined? slide.providerByName =='' || slide.providerByName ==undefined? 'buddi Premium' :  slide.providerByName : slide.partner.partnerName}}
    </div>
</div>
