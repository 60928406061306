import { Component, OnInit,Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../shared/api.service';
import { NgxSpinnerService } from "ngx-spinner";
import { MediaService } from '../../../shared/media.service';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'listingS',
  templateUrl: './list5-student.component.html',
  styleUrls: ['./list5-student.component.scss']
})
export class List5StudentComponent implements OnInit {
  @Input() data:any =[] ;
	@Input() totallength:Number = 0;
	@Input() pagelimit:Number = 0;  
  v10: number = 1;
  countv10: number = 4;
  constructor(private router:Router,private toastr:ToastrManager,private api:MediaService,private SpinnerService: NgxSpinnerService,private userApi:ApiService) { }

  ngOnInit(): void {
  }
  videodetails(data) {
    this.router.navigate(['/video-details/' + data._id]);
  }
}
