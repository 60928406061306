import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Options } from 'ng5-slider';
import { ApiService } from '../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-exam-details',
  templateUrl: './exam-details.component.html',
  styleUrls: ['./exam-details.component.scss']
})
export class ExamDetailsComponent implements OnInit {
  examSideID: any;  //course id of the exam on the course.buddiforlife.com side
  examID: any;
  typeuser: string;
  public isstudent = false;
  public isteacher = false;
  isUserLoggedIn: string;
  isenroll: string;
  islogin: string;
  iscreateduser: string;
  examDetails: any;
  public iscreatedby = false;
  public pagename: any = '';
  public courseURL = environment.COURSE_URL;
  public ispublish = false;

  constructor(private my_router: ActivatedRoute, public toastr: ToastrManager, private formBuilder: FormBuilder, private rest: ApiService, private router: Router) { }

  ngOnInit(): void {
    this.examID = this.my_router.snapshot.params['id'];
    var usertype = localStorage.getItem('profileType');
    var userId = localStorage.getItem('userId');
    this.typeuser = usertype.toLowerCase();
    if (usertype.toLowerCase() == "teacher") {
      this.isenroll = "false";
      this.isteacher = true;
    }
    else {
      this.isstudent = true;
    }

    var examformData = {};
    examformData["id"] = this.examID;
    examformData["userType"] = usertype;
    const webToken = localStorage.getItem('webtoken');
    if (webToken == null) {
      this.rest.getExamDetailswithoutauth(webToken, examformData).subscribe((result) => {
        this.examDetails = result.data;
        this.examSideID = this.examDetails.examID;
        this.pagename = "Exam Details"
        if (usertype.toLowerCase() == "teacher") {

          var userid = localStorage.getItem('userId');
          if (result.data.createdBy == userid) {
            this.islogin = "true";
            if (result.data.status == "Draft") {
              this.ispublish = true;
            }
            this.iscreatedby = true;
          }
        }
        else if (usertype.toLowerCase() == "student") {
          if (result.data.studentEnrolledIn == true) {
            this.islogin = "true";
          }
          else {
            this.isenroll = "true";
          }
        }

      });

    }
    else {
      this.rest.getExamDetails(webToken, examformData).subscribe((result) => {
        console.log(result);
        this.examDetails = result.data;
        this.examSideID = this.examDetails.examID;
        this.pagename = "Exam Details"
        if (usertype.toLowerCase() == "teacher") {

          var userid = localStorage.getItem('userId');
          if (result.data.createdBy == userid) {
            this.islogin = "true";
            if (result.data.status == "Draft") {
              this.ispublish = true;
            }
            this.iscreatedby = true;
          }
        }
        else if (usertype.toLowerCase() == "student") {
          if (result.data.studentEnrolledIn == true) {
            this.islogin = "true";
          }
          else {
            this.isenroll = "true";
          }
        }

      });
    }
    this.isUserLoggedIn = localStorage.getItem('isLoggedin');
  }

  courseJoin(courseid, startdate) {
    var userId = localStorage.getItem('userId');
    const userType = localStorage.getItem('userType');
    const startDate1 = new Date(startdate).getTime() / 1000
    const currentdate = new Date().getTime() / 1000
    const startDateshow = moment(startdate).format('DD-MM-YYYY');

    if (startDate1 <= currentdate || userType.toLowerCase() == "teacher") {
      console.log(courseid);
      if (!userId) {
        this.examDetails.
          this.toastr.errorToastr("Please login to access courses");
      }
      else {
        var userData = {};
        userData["userId"] = userId;
        userData["userType"] = userType;
        userData["courseId"] = courseid;
      }
      console.log(userData);
      var redirectURL = this.courseURL + "course/view.php?id=" + this.examSideID;
      this.rest.getLoginURL(userData).subscribe((result) => {
        console.log(result);
        if (result) {
          if (!result["data"]) {
            this.toastr.errorToastr('This user has not registered for courses');
          }
          else {
            if (!result["data"]["loginurl"] || result['exception'] == 'invalid_parameter_exception') {
              this.toastr.errorToastr('This user has not registered for courses');
            }
            else {
              console.log(result);
              console.log(result["data"]["loginurl"]);
              console.log(result["data"]["loginurl"] + "&wantsurl=" + redirectURL)
              window.open(result["data"]["loginurl"] + "&wantsurl=" + redirectURL);
            }
          }
        }
      });
    }
    else {
      this.toastr.errorToastr('Exam has not started yet. Please check for the start date.');
    }

  }

  courseEnroll(examid, startdate) {
    const startDate1 = new Date(startdate).getTime() / 1000
    const currentdate = new Date().getTime() / 1000
    const startDateshow = moment(startdate).format('DD-MM-YYYY');

  
    var userId = localStorage.getItem('userId');
    var userType = localStorage.getItem('userType');
    var userData = {};
    userData["userId"] = userId;
    userData["userType"] = userType;
    userData["examID"] = examid;
    var redirectURL = this.courseURL + "course/view.php?id=" + this.examSideID;
    this.rest.getJoinURLexam(userData).subscribe((result) => {
      console.log(result);
      var globalVar = result;
      if (result) {
        if (!result["data"]) {
          this.toastr.errorToastr('This user has not registered for courses');
        }
        else {
          if (!result["data"]["loginurl"] || result['exception'] == 'invalid_parameter_exception') {
            this.toastr.errorToastr('This user has not registered for courses');
          }
          else {
            console.log(result);
                //Calendar Start
                this.examDetails.isCourse = false;
                const webToken = localStorage.getItem('webtoken');
                this.rest.notificationCourseExam(this.examDetails,webToken).subscribe((notifyResult) => {
 
                });
                this.addToCalendar(this.examDetails, webToken);
 
                //Calendar End
            this.toastr.successToastr('Enrollement Successfull. Enrolled course is added to your Dashboard ');
            if (userType.toLowerCase() == "teacher") {
              this.router.navigate(['/teacher/mydashboard']);
            }
            else {
              console.log(result);
              this.toastr.successToastr('Enrollement Successfull. Enrolled course is added to your Dashboard ');
              if (userType.toLowerCase() == "teacher") {
                this.router.navigate(['/teacher/mydashboard']);
              }
              else {
                this.router.navigate(['/student/mydashboard']);
              }
            }
          }
        }
      };
    });
    
  

  }

  courseDelete(examDetails1) {
    var userId = localStorage.getItem('userId');
    var examuser = examDetails1.createdBy;
    if (userId == examuser) {
      var userData = {};
      userData["_id"] = examDetails1._id;
      const webToken = localStorage.getItem('webtoken');
      this.rest.deleteExambyID(webToken, userData).subscribe((result) => {
        if (result.status) {
          this.router.navigate(['/teacher/mydashboard']);
          this.toastr.successToastr('Exam Deleted Successfully');
        }
        else {
          console.log(result);
          this.toastr.errorToastr(result.message);
        }
      });
    }
    else {
      this.toastr.errorToastr('Cannot be deleted');
    }
  }

  coursedetails(ID, name) {
    this.router.navigateByUrl('edit-exam/' + ID + "/" + name);
  }

  publishcourse(ID, name) {
    const webToken = localStorage.getItem('webtoken');
    const userType = localStorage.getItem('userType');
    var userData = {};
    userData["id"] = ID;
    userData["userType"] = userType;
    this.rest.changeexamurl(webToken, userData).subscribe((response) => {
      if (response.status) {
        this.router.navigate(['/teacher/mydashboard']);
        this.toastr.successToastr('Exam Published Successfully');

      }
      else {
        console.log(response);
        this.toastr.errorToastr(response.message);
      }
    });
    this.router.navigateByUrl('edit-exam/' + ID + "/" + name);
  }

  addToCalendar(data,webToken){
    var req={
      myLifeListDatepick:moment(data.startDate).format('YYYY-MM-DD'),
      myLifeListEventType: "exams",
      myLifeListTimepick:moment(data.startDate).format('HH:mm:ss'),
      setReminder: 0,
      todoEventType: "exams",
      meetingType:data.privacy,
      todoSchedule: data.startDate,
      todoSetReminder: "",
      todosetReminderValue: "",
      sessionName:data.shortName,
      meetingId : data._id,
      categoryID :data.categoryID,
      myLifeListTitle:data.privacy,
      sessionId:data._id,
      profileType:'student'
    };

    this.rest.add_myLife_NewData(req,webToken).subscribe((result) => {
      if(result.status){
        this.toastr.successToastr("Class Added to your Calender");
        //this.location.back();
      }else{
        this.toastr.errorToastr(result.message);
      }

    });
  }

}
