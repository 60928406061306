import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-list5-student-solution',
  templateUrl: './list5-student-solution.component.html',
  styleUrls: ['./list5-student-solution.component.scss']
})
export class List5StudentSolutionComponent implements OnInit {
  @Input() slide:any = {};
  @Input() hideData:any = false;
  constructor() { }

  ngOnInit(): void {
  }

}

