<div *ngIf="isstudent">
    <app-header-style-one></app-header-style-one>
</div>
<div *ngIf="isteacher">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>
<section class="my-dashboard-area ptb-100">
    <div class="container">
        <div class="myDashboard-content">
            <form class="edit-account"  [formGroup]="changepasswordform" (ngSubmit)="onSubmit(changepasswordform)">
                <h3 style="margin-bottom: 5%;">Change Password</h3>
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Old Password <span class="required" style="color: red;">*</span></label>
                            <input type="password" class="form-control" value="" formControlName="oldpassword" class="form-control"  >
                            <div *ngIf="submitted && f.oldpassword.errors" class="invalid-feedback">
                                <div *ngIf="f.oldpassword.errors.required">Old Password Required</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                      
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>New Password <span class="required" style="color: red;">*</span></label>
                            <input type="password" class="form-control" value="" autocomplete="new-password" formControlName="newpassword"  class="form-control"    [ngClass]="{'is-invalid': !f.newpassword.pristine && f.newpassword.errors}" >
                            <div *ngIf="!f.newpassword.pristine && f.newpassword.errors" class="invalid-feedback">
                                <div *ngIf="f.newpassword.errors.required" >Password is Required</div>
                                <div *ngIf="f.newpassword.errors?.minlength">Must be atleast 8 characters</div>
                                <div *ngIf="f.newpassword.errors.pattern">
                                    must contain one number<br>
                                    must contain atleast 1 letter in capital case<br>
                                    must contain atleast 1 letter in small case<br>
                                    must contain atleast one special character<br>
                                </div>
                              </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Confirm Password <span class="required" style="color: red;">*</span></label>
                            <input type="password" class="form-control" value="" formControlName="confirmpassword" class="form-control" >
                            <div *ngIf="submitted && f.confirmpassword.errors" class="invalid-feedback">
                                <div *ngIf="f.confirmpassword.errors.required">Old Password Required</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <button type="submit" class="default-btn" ><i class='bx bx-save icon-arrow before'></i><span class="label">Submit</span><i class="bx bx-save icon-arrow after"></i></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>

