<div class="testimonials-slides owl-carousel owl-theme">
    <div class="single-testimonials-item">
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
        <div class="info">
            <!-- <img src="assets/img/user1.jpg" class="shadow rounded-circle" alt="image"> -->
            <h3>John</h3>
            <span>Student</span>
        </div>
    </div>

    <div class="single-testimonials-item">
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
        <div class="info">
            <!-- <img src="assets/img/user2.jpg" class="shadow rounded-circle" alt="image"> -->
            <h3>Sarah</h3>
            <span>Student</span>
        </div>
    </div>

    <div class="single-testimonials-item">
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
        <div class="info">
            <!-- <img src="assets/img/user3.jpg" class="shadow rounded-circle" alt="image"> -->
            <h3>David</h3>
            <span>Student</span>
        </div>
    </div>

    <div class="single-testimonials-item">
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
        <div class="info">
            <img src="assets/img/user4.jpg" class="shadow rounded-circle" alt="image">
            <h3>James Anderson</h3>
            <span>Student</span>
        </div>
    </div>
</div>