
import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../../shared/api.service";
import { Router, ActivatedRoute } from "@angular/router";
import { DatePipe } from '@angular/common';
import { environment } from "../../../../environments/environment";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrManager } from "ng6-toastr-notifications";
import { CommonTextColorDirective } from '../../custom-directive/common-text-color.directive';
const datepipe: DatePipe = new DatePipe('en-US')


@Component({
  selector: 'app-completed-roundtable-tab',
  templateUrl: './completed-roundtable-tab.component.html',
  styleUrls: ['./completed-roundtable-tab.component.scss']
})
export class CompletedRoundtableTabComponent implements OnInit {

  public isStudent = false;
  public isTeacher = false;
  public roundTableList = [];
  public classList = [];
  public classroomList = [];
  public RoundList = [];
  public videoList = [];
  public testList = [];
  public smartMaterialList = [];
  public currentLiveClassPageNumber = 1;
  public currentLiveClassFilter = 'none';
  public currentStudyClassFilter = 'none';
  public currentRoundClassFilter = 'none';
  public currentPageNumber: number = 1;
  public totalLiveClassPageCount: number = 1;
  public livedetails: any = [];;
  constructor(private rest: ApiService,  public toastr: ToastrManager,private router:Router) { }

  ngOnInit() {
    this.isTeacher =
        localStorage.getItem("profileType").toLowerCase() == "teacher";
    this.isStudent =
        localStorage.getItem("profileType").toLowerCase() == "student";
    var reqData = {
      "notFetchUpcoming": true
    }
    this.fetchCompletedRoundTables(reqData);
  }
  
  RoundtableClassDetails(data) {
    if (this.isTeacher) {
        this.router.navigate(["roundtable-details/" + data._id]);
    } else {
      console.log('fdfdf')
        this.router.navigate(["roundtable-request-details/" + data._id]);
    }
  }

  clickLiveClassPageNumber(pageNumber) {
    this.currentLiveClassPageNumber = pageNumber;
    console.log(this.currentLiveClassPageNumber);
    var reqData = {
        notFetchCompleted: false,
        filter: this.currentLiveClassFilter,
        limit: 5,
        page: pageNumber
    };
    this.fetchCompletedRoundTables(reqData);
  }

  roundClassFilter(filter){
    if(this.currentRoundClassFilter == filter){
  
    }
    else{
        this.currentRoundClassFilter = filter;
        this.currentPageNumber = 1;
        var reqData = {
          notFetchCompleted: false,
            filter: filter,
            limit: 5
        };
        this.fetchRoundClasses(reqData);
        console.log('reqData,=========Roundtable',reqData)
    }
  }
  fetchRoundClasses(request){
    const token = localStorage.getItem("webtoken");
  
    if (this.isTeacher) {
      this.rest
            .getUserRoundClassRequestsTeacher(token,request)
            .subscribe((result) => {
                console.log(result, "result===");
                if (result.status) {
                    this.RoundList =  result.data && result.data.data ? result.data.data.upcoming : [];
                    this.totalLiveClassPageCount = Math.ceil(result.data.totalCount / 5)
                    console.log("after respose", this.RoundList);
                    for(var item of this.RoundList){
                      if(item.startDateTime){
                        item.startDateTime = datepipe.transform(item.startDateTime, 'MMM d, y, h:mm a')
                                            //  (item.startDateTime =  new Date(item.startDateTime,'MMM d, y, h:mm a');
  
                      }
                    }
                }
            });
  
  } else {
    this.rest
    .getUserRoundClassesStudent(token,request)
    .subscribe((result) => {
        console.log(result, "result======Roundtable");
        if (result.status) {
            this.RoundList = result.data && result.data.data ? result.data.data.upcoming : [] ;
            this.totalLiveClassPageCount = Math.ceil(result.data.totalCount / 5)
            console.log("after respose", this.RoundList);
            for(var item of this.RoundList){
              if(item.startDateTime){
                item.startDateTime = datepipe.transform(item.startDateTime, 'MMM d, y, h:mm a')
              }
            }
        }
    });      }
  }
  

  fetchCompletedRoundTables(reqData) {
    const token = localStorage.getItem("webtoken");
    if (this.isTeacher) {
      this.rest
          .getUserRoundClassRequestsTeacher(token, reqData)
          .subscribe((result) => {
              console.log(result, "result===");
              if (result.status) {
                  this.roundTableList = result.data.data.completed;
                  console.log("round class", this.roundTableList);
                  for(var item of this.roundTableList){
                    if(item.startDateTime){
                      item.startDateTime = datepipe.transform(item.startDateTime, 'MMM d, y, h:mm a')
                    }
                  }
              }
          });
  } else {
      this.rest
      .getUserRoundClassesStudent(token, reqData)
      .subscribe((result) => {
          console.log(result, "result");
          if (result.status) {
              this.roundTableList = result.data.data.completed;
              console.log("round class", this.roundTableList);
              for(var item of this.roundTableList){
                if(item.startDateTime){
                  item.startDateTime = datepipe.transform(item.startDateTime, 'MMM d, y, h:mm a')
                }
              }
          }
      });
    }
  }

}


