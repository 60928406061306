import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Options } from 'ng5-slider';
import { MediaService } from '../../../../shared/media.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ApiService } from '../../../../shared/api.service';
import { NgxSpinnerService } from "ngx-spinner";
import {NgSelectModule, NgOption} from '@ng-select/ng-select';

@Component({
  selector: 'app-class-course-details',
  templateUrl: './class-course-details.component.html',
  styleUrls: ['./class-course-details.component.scss']
})
export class ClassCourseDetailsComponent implements OnInit {
  public id;
  public categorydetails : any = [];
  public isteacher: boolean = false;
  public isstudent: boolean = false;
  public categoryid = "";
  public moduleid = "";
  public categoryname = "";
  public modulename = "";


  constructor(private activatedRoute :ActivatedRoute,public toastr: ToastrManager,private SpinnerService: NgxSpinnerService,private rest : ApiService,private location:Location,private api: MediaService,private formBuilder: FormBuilder,private router: Router) { }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.params.id;
    var usertype = localStorage.getItem('profileType');
    if (usertype.toLowerCase() == "teacher") {
      this.isteacher = true;
    }
    else {
      this.isstudent = true;
    }

    var req = {
      courseID : this.id
    }
    this.rest.getClassCoursedetails(req).subscribe((result) => {
      if(result.status){
        this.categorydetails = result.data;
        this.categoryid = result.data.categoryID._id;
        this.moduleid = result.data.moduleID._id;
        this.categoryname = result.data.categoryID.categoryName;
        this.modulename = result.data.moduleID.moduleName;
      }
    });
  }

  toggleAccordian(event, index) {
    const element = event.target;
    element.classList.toggle("active");
    const panel = element.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }

  videoclasses(course,topic) {
this.router.navigate(['/teacher-video-upload/' + this.categoryid + "/" + this.moduleid + "/" + course + "/" + topic]);
  }

  testclasses(course,topic) {
    this.router.navigate(['/teacher-test-upload/' + this.categoryid + "/" + this.moduleid + "/" + course + "/" + topic]);
      }

      studymaterialclasses(course,topic) {
        this.router.navigate(['/teacher-smartmaterial-upload/' + this.categoryid + "/" + this.moduleid + "/" + course + "/" + topic]);
      }
      studyHall() {
        this.router.navigate(['/create-webinar']);
      }
      liveClass(course,topic) {
        this.router.navigate(['/liveClassList/' + this.categoryid + "/" + this.moduleid + "/" + course + "/" + topic]);
      }
}
