import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../shared/api.service';
@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss']
})
export class PaymentHistoryComponent implements OnInit {
  public payments:any = [];
  public isstudent = false;
  public isteacher = false;
  term: string;
  video : any;
  material ; any;
  constructor(private rest: ApiService) { }
  

  ngOnInit(): void {
    const userType = localStorage.getItem('userType');      
    if(userType.toLowerCase()=="teacher") {
      this.isteacher = true;
    }
    else {
      this.isstudent = true;
    }
    // const token = localStorage.getItem('webtoken');
    // console.log('before api')
    // this.rest.getPaymentHistory(token).subscribe((result) => {
    //  console.log('after',result)
    //            if(result.status){
    //          this.payments = result.data;
    //          this.video = result.data.purchasedItemID.videoName;
    //          this.material = result.data.purchasedItemID.materialName;
    //            }
    //          });
  }
  // key: string = 'profilename';
  // reverse : boolean =false;
  // sort(key){
  //   this.key = key;
  //   this.reverse = !this.reverse;
  // }
}
