import { Component, OnInit,Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../shared/api.service';
import { NgxSpinnerService } from "ngx-spinner";
import { MediaService } from '../../../shared/media.service';
import { ToastrManager } from 'ng6-toastr-notifications';
@Component({
  selector: 'listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss']
})
export class ListingComponent implements OnInit {
	@Input() data:any =[] ;
	@Input() totallength:Number = 0;
	@Input() pagelimit:Number = 0;  
  @Input() searchText:any = '';
  v10: number = 1;
  countv10: number = 4;
  constructor(private router:Router,private toastr:ToastrManager,private api:MediaService,private SpinnerService: NgxSpinnerService,private userApi:ApiService) { }

  ngOnInit(): void {
  }
  detailsMeeting(data, pagename) {
    data.pagename = pagename;
    this.router.navigate(['/student/meetingDetails/' + "/" + data.meetingID], { state: data });
  }
  detailsAccept(data, pagename) {
    data.pagename = pagename;
    this.router.navigate(['/student/one2oneAccept/' + "/" + data.meetingID], { state: data });
  }
  detailsRequest(data, pagename) {
    data.pagename = pagename;
    this.router.navigate(['/student/viewRequest/' + "/" + data.meetingID], { state: data });
  }
  fetchrecordvideo(id,type) {
    var req = {
      [type]: id
    }
    this.SpinnerService.show()
    this.api.fetchrecordvideo(req).subscribe((result) => {
      this.SpinnerService.hide()
      if (result.status) {
        window.open(result.data[0].recordurl, '_blank');
      }
    }, (err) => {
      this.toastr.errorToastr(err.message);
    });

  }
  videodetails(data) {
    this.router.navigate(['/video-details/' + data._id]);
  }
  buddiGroupRedirectParent(data) {
    this.router.navigateByUrl('buddigroup/' + data._id);
  }
  buddiGroupRedirect(data) {
    this.router.navigateByUrl('buddigroup/' + data.parentMeetingID._id);
  }
}
