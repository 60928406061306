import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Options } from 'ng5-slider';
import { MediaService } from '../../../shared/media.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ApiService } from '../../../shared/api.service';
import { NgxSpinnerService } from "ngx-spinner";
import {NgSelectModule, NgOption} from '@ng-select/ng-select';

@Component({
  selector: 'app-teacher-video-upload',
  templateUrl: './teacher-video-upload.component.html',
  styleUrls: ['./teacher-video-upload.component.scss']
})
export class TeacherVideoUploadComponent implements OnInit {

  selectedCityId: number;
  videosList = [];
  videos : any =[];
  allfiles : any = [];
  nameList : any = [];
  createForm: FormGroup;
  submitted = false;
  selectedFileName: string = 'Choose File';
  uploaVideoName: string = 'Choose File';
  selectedRouteId:any;
  selectedRouteValue:any;
  videosListget: any;
  meetCategory:any=[];
  selectedValue = "";
  formData;  
  categoryselectedValue = "";
  //boardselectedValue = "";
  languageselectedValue = "";
  gradeselectedValue = "";
  subjectselectedValue = "";
  moduleselectedValue = "";
  templateselectedValue = "";
  topicselectedValue = "";
  alltag:any=[];
  pricingTemplate = {};
  public price: Number;
  public getDatas: any;
  public userId:any = '';
  securityArray:any=[];
  public groupName:any='';
  public meetingCategory = "Select category";
  public meetingType = "Select Session Type";
  public tagtype ="Select Tag";
  public editData:any=[];
  public paramData:any = [];
  public isEnterprise = '';
  public thumbnailFile : any = [];
  public thumbnailPlace:any = '';
  public isTeacher:boolean = false;
  public teacherID:any = '';
  public studentID:any = '';
  public videoname : any = [];
  public coursecategorylist : any = [];
  public modulelist : any = [];
  public templatelist : any = [];
  public courseboardlist : any = [];
  public coursesubjectlist : any = [];
  public languageapi : any = [];
  public gradeapi : any = [];
  public topicapi : any = [];
  public selecttag:any = [];
  public partnerList:any = [];
  public isPartnerUser:any = [];
  public isEdit:Boolean = false;
  public chapterID: Boolean = true;
  public isfromcoursedetails: Boolean = false;

  selectedcategorylist = [];
  selectedgradelist = [];
  selectedsubjectlist = [];
  dropdownSettings: any = {};
   dropdowngradeSettings: any = {};
   dropdownsubjectSettings: any = {};
   selectedtopiclist = [];
  constructor(private activatedRoute :ActivatedRoute,public toastr: ToastrManager,private SpinnerService: NgxSpinnerService,private rest : ApiService,private location:Location,private api: MediaService,private formBuilder: FormBuilder,private router: Router) { }

  ngOnInit() {
    // this.getDatas();
   window.scrollTo(0, 0);
   this.tagfield();
   this.getSecurity();
   this.selectedRouteId = localStorage.getItem('collectionid');
   this.selectedRouteValue = localStorage.getItem('collectionname');
   this.userId = localStorage.getItem('userId');
   const webToken = localStorage.getItem('webtoken');
   this.isPartnerUser = localStorage.getItem('isPartner') =='true'?true:false;
  //  this.getDatas =  window.history.state
  //  console.log( this.getDatas ,'window.history.state');
  var btn = (document.getElementById('addVideoButton') as HTMLButtonElement);
  btn.disabled = false;

   
   this.dropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'categoryName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3
};

  this.dropdowngradeSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'gradeName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3
};

this.dropdownsubjectSettings = {
  singleSelection: false,
  idField: '_id',
  textField: 'subjectName',
  selectAllText: 'Select All',
  unSelectAllText: 'UnSelect All',
  itemsShowLimit: 3
};

   this.getcoursecategory();
   this.getboardfield();
  //  this.getsubjectfield();
   this.getlanguage();
   this.getgrade();
  // this.getPartner();
   this.isTeacher = localStorage.getItem('profileType').toLowerCase() =='teacher' 
   this.createForm = this.formBuilder.group({
      id: null,
      videoname: ['', Validators.required],
      description: ['', Validators.required],
      courseCategory:['', Validators.required],
      courseModule:['', Validators.required],
      courseTemplate:['', Validators.required],
      courseTopic: ['', Validators.required],
      //courseBoard: ['', Validators.required],
      courseLanguage:['', Validators.required],
      privacy: ['', Validators.required], 
      level: ['', Validators.required],
      price: ["", Validators.required],    
      fileupload: ['', Validators.required],
      thumbnail: ['', Validators.required],
     // courseGrade: ['', Validators.required],
      // courseSubject: ['', Validators.required],
      providerBy:['buddi Premium', Validators.required],
    
      upload_name: [null],
    });
    this.createForm.patchValue({
        price: 0
    });
    this.price = 0;
   if(this.isTeacher){
    this.rest.userName_DisplayTeacher(webToken).subscribe((result) => { 
      this.groupName =  result.data !=undefined && result.data.groupName != 'buddi' ? result.data.groupName : ''
      localStorage.setItem('groupname', result.data !=undefined && result.data.groupName != 'buddi' ? result.data.groupName : '');
      this.pricingTemplate = result.data != undefined && result.data.pricingTemplate.videoClassPrice != undefined 
                    ? result.data.pricingTemplate.videoClassPrice : {};
    });
   }else{
    this.rest.userName_DisplayStudent(webToken).subscribe((result) => {
      this.studentID = result.data._id     
    });
   }

   
   if(this.activatedRoute.snapshot.params.topicid !== undefined) {
     this.isfromcoursedetails = true;
    this.categoryselectedValue =this.activatedRoute.snapshot.params.categoryid;
    this.topicselectedValue=this.activatedRoute.snapshot.params.topicid;
    this.moduleselectedValue = this.activatedRoute.snapshot.params.moduleid;
    this.templateselectedValue = this.activatedRoute.snapshot.params.courseid;
  }
  else if(this.activatedRoute.snapshot.params.id !== undefined) {
    var req={
      videoID:this.activatedRoute.snapshot.params.id
    }
    this.api.getUploadedVideosByID(req,webToken).subscribe((result) => {
      if(result.status){
        this.editData =result.data;
        this.price = this.editData.price;
        // this.subjectselectedValue = this.editData.topicID.subjectID._id;
        // this.gradeselectedValue = this.editData.topicID.gradeID._id;
        this.thumbnailPlace = this.editData.thumbnail;
        this.selectedFileName = this.editData.videoName;
        this.categoryselectedValue =this.editData.categoryID._id;
        this.languageselectedValue=this.editData.languageID._id;
        // this.subjectselectedValue=this.editData.topicID.subjectID._id;
        //this.boardselectedValue=this.editData.boardID._id
        // this.gradeselectedValue=this.editData.topicID.gradeID._id;
        this.topicselectedValue=this.editData.topicID._id;
        this.moduleselectedValue = this.editData.moduleID._id;
        this.templateselectedValue = this.editData.courseID._id;
        this.editmodulelist();
        this.edittemplatelist();
       // this.edittopiclist();
       // this.topiclist();
        this.createForm.patchValue({ 
          courseLanguage: this.editData.languageID._id,
          courseCategory: this.editData.categoryID._id,
          //courseBoard: this.editData.boardID._id,
          courseModule:this.editData.moduleID._id,
          courseTemplate:this.editData.courseID._id,
         // courseGrade: this.editData.topicID.gradeID._id,
          // courseSubject: this.editData.topicID.subjectID._id,
          courseTopic: this.editData.topicID._id,
          privacy: this.editData.privacy,
          description: this.editData.description,
          thumbnail: this.editData.thumbnail,
         // upload_name:this.editData.videoPath,
          fileupload: this.editData.videoName,
          level: this.editData.level,
          price: this.editData.price
        })
      }
    });
  }

 
    // var req = this.activatedRoute.snapshot.params;
    // this.api.getVideosByID(req,webToken).subscribe((result) => {
    // if(result.status && result.data.length!=0){
    //   this.editData =result.data;
    //   if(this.isPartnerUser){
    //     this.createForm.patchValue({providerBy:  this.editData[0].providerByName !='' ? this.editData[0].providerByName : this.editData[0].isbuddi_premium ? 'buddi Premium' :'Partner'})
    //     if(this.editData[0].partner!=null){
    //       this.createForm.patchValue({ partner:  this.editData[0].partner._id}); 
    //     }       
    //   }
    //   this.isEdit = this.editData[0]._id ?true:false;
    //   this.createForm.patchValue({ 
    //     caption:this.editData[0].typename,
    //     chapterName:this.editData[0].chapterName,
    //     flimname:this.editData[0].flimname,
    //     type:this.editData[0].type,
    //     benefit:this.editData[0].benefit,
    //     name:this.editData[0].name,
    //     id:  this.editData[0]._id,
    //     description:this.editData[0].description,
    //     securitytype:this.editData[0].securitytype,
    //     additional:this.editData[0].additional,
    //     autor:this.editData[0].autor,
    //     thumbnail:this.editData[0].thumbname,
    //     // courseCategory:this.editData[0].categoryID._id,
    //     // courseGrade:this.editData[0].gradeID._id,
    //     courseBoard:this.editData[0].boardID._id,
    //     // courseSubject:this.editData[0].subjectID._id,
    //     courseLanguage:this.editData[0].languageID._id,
    //     fileupload:this.editData[0].name,
    //   })
    //   this.selectedcategorylist = this.editData[0].categoryID;
    //   this.selectedgradelist = this.editData[0].gradeID;
    //   this.selectedsubjectlist = this.editData[0].subjectID;
    //   this.thumbnailPlace = this.editData[0].thumbfilename
    //   this.uploaVideoName = this.editData[0].originalname;
    //   this.selecttag  = this.editData[0].tag == "" ? '' :this.editData[0].tag.split(",");
    //   this.groupName  = this.editData[0].groupname;
    // }
    // })
 }
  get f() {
    return this.createForm.controls;
  }
  selectChange (event: any) {
    this.selectedValue = event.target.options[event.target.selectedIndex].text;
  }

  levelChange(event: any) {
    var selectedLevel = event.target.options[
        event.target.selectedIndex
    ].value.substring(
        event.target.options[event.target.selectedIndex].value.indexOf(
            ":"
        ) + 1
    );
    this.createForm.patchValue({price: this.pricingTemplate['level' + selectedLevel + 'Price'] ? this.pricingTemplate['level' + selectedLevel + 'Price'] : 0})
    this.price = this.pricingTemplate['level' + selectedLevel + 'Price'] ? this.pricingTemplate['level' + selectedLevel + 'Price'] : 0;
}

  selectcategoryChange (event: any) {
    this.categoryselectedValue = event.target.options[event.target.selectedIndex].value.substring(event.target.options[event.target.selectedIndex].value.indexOf(":") + 1);
    if(this.modulelist.length > 0) {
      this.modulelist = [];
      this.createForm.patchValue({ courseModule: "" })
    }
    if(this.templatelist.length > 0) {
      this.templatelist = [];
      this.createForm.patchValue({ courseTemplate: "" })
    }
    if(this.topicapi.length > 0) {
      this.topicapi = [];
      this.createForm.patchValue({ courseTopic: "" })
    }
    console.log(this.coursecategorylist);
    this.coursecategorylist.forEach(element => {
      if(element._id.replace(/\s/g, "") == this.categoryselectedValue.replace(/\s/g, "")) {
        this.modulelist = element.moduleList;
        console.log(this.modulelist);
         if(Object.keys(this.paramData).length == 0 && !this.isEdit){
        //  this.createForm.patchValue({ courseModule: element.moduleList[0]._id })
         }
      }     
    });   
  }

  isfromcoursedetailsgetmodule() {
    this.coursecategorylist.forEach(element => {
      if(element._id.replace(/\s/g, "") == this.categoryselectedValue.replace(/\s/g, "")) {
        this.modulelist = element.moduleList;
         if(Object.keys(this.paramData).length == 0){
          this.createForm.patchValue({ courseModule: this.moduleselectedValue })
         }
      }     
    });   

    this.isfromcoursedetailsgettemplate();
  }

  isfromcoursedetailsgettemplate() {
    var req= {
      moduleID:this.moduleselectedValue.replace(/\s/g, "")
    }
    this.rest.getClassCourseTemplatefromModule(req).subscribe((result) => {
      this.templatelist = result.data.templateList;
      if(Object.keys(this.paramData).length == 0){
        this.createForm.patchValue({ courseTemplate:  this.templateselectedValue})
      }
      this.isfromcoursedetailsgettopic();
    }, (err) => {
    console.log(err);
    });
   
  }

  isfromcoursedetailsgettopic() {
    console.log("templatelist", this.templatelist);
    this.templatelist.forEach(element => {
      if(element._id.replace(/\s/g, "") == this.templateselectedValue.replace(/\s/g, "")) {
        this.topicapi = element.topicList;
         if(Object.keys(this.paramData).length == 0 && !this.isEdit){
          this.createForm.patchValue({ courseTopic: this.topicselectedValue })
         }
      }     
    });
  }

  editmodulelist() {
    this.coursecategorylist.forEach(element => {
      if(element._id.replace(/\s/g, "") == this.categoryselectedValue.replace(/\s/g, "")) {
        this.modulelist = element.moduleList;
         if(Object.keys(this.paramData).length == 0 && !this.isEdit){
          this.createForm.patchValue({ courseModule: this.moduleselectedValue })
         }
      }     
    });  
  }

  selectmoduleChange (event: any) {
    this.moduleselectedValue = event.target.options[event.target.selectedIndex].value.substring(event.target.options[event.target.selectedIndex].value.indexOf(":") + 1);
   if(this.templatelist.length > 0) {
      this.templatelist = [];
      this.createForm.patchValue({ courseTemplate: "" })
    }
    if(this.topicapi.length > 0) {
      this.topicapi = [];
      this.createForm.patchValue({ courseTopic: "" })
    }
    var req= {
      moduleID:this.moduleselectedValue.replace(/\s/g, "")
    }
    console.log("modulechange", req);
    this.rest.getClassCourseTemplatefromModule(req).subscribe((result) => {
      this.templatelist = result.data.templateList;
      console.log("template",this.templatelist);
      if(Object.keys(this.paramData).length == 0 && !this.isEdit){
       // this.createForm.patchValue({ courseCategory: "" })
      }
    }, (err) => {
    console.log(err);
    });
     
  }

  edittemplatelist() {  
    var req= {
      moduleID:this.moduleselectedValue.replace(/\s/g, "")
    }
    this.rest.getClassCourseTemplatefromModule(req).subscribe((result) => {
      this.templatelist = result.data.templateList;
      console.log(result.data.templateList);
      if(Object.keys(this.paramData).length == 0 && !this.isEdit){
        this.createForm.patchValue({ courseTemplate: this.templateselectedValue })
      }
      this.edittopiclist();
    }, (err) => {
    console.log(err);
    });
  }

  selecttemplateChange (event: any) {
    this.templateselectedValue = event.target.options[event.target.selectedIndex].value.substring(event.target.options[event.target.selectedIndex].value.indexOf(":") + 1);
   if(this.topicapi.length > 0) {
      this.topicapi = [];
      this.createForm.patchValue({ courseTopic: "" })
    }
    this.templatelist.forEach(element => {
      if(element._id.replace(/\s/g, "") == this.templateselectedValue.replace(/\s/g, "")) {
        this.topicapi = element.topicList;
        console.log("topic",this.topicapi);
         if(Object.keys(this.paramData).length == 0 && !this.isEdit){
        //  this.createForm.patchValue({ courseModule: element.moduleList[0]._id })
         }
      }     
    });   
  }

  edittopiclist() {
    // if(this.topicapi.length > 0) {
    //   this.topicapi = [];
    //   this.createForm.patchValue({ courseTopic: "" })
    // }
    this.templatelist.forEach(element => {
      if(element._id.replace(/\s/g, "") == this.templateselectedValue.replace(/\s/g, "")) {
        this.topicapi = element.topicList;
         if(Object.keys(this.paramData).length == 0 && !this.isEdit){
          this.createForm.patchValue({ courseTopic: this.topicselectedValue })
         }
      }     
    }); 
  }

  // selectboardChange (event: any) {
  //   this.boardselectedValue = event.target.options[event.target.selectedIndex].value.substring(event.target.options[event.target.selectedIndex].value.indexOf(":") + 1);
  
  // }

  selectgradeChange (event: any) {
    this.gradeselectedValue = (event.target.options[event.target.selectedIndex].value).substring(event.target.options[event.target.selectedIndex].value.indexOf(":") + 1);
    if(this.subjectselectedValue != "" ) {
      this.topiclist();
    }
  }

  selectlanguageChange (event: any) {
    this.languageselectedValue = event.target.options[event.target.selectedIndex].value.substring(event.target.options[event.target.selectedIndex].value.indexOf(":") + 1);  
  }

  selectsubjectChange (event: any) {
    this.subjectselectedValue = event.target.options[event.target.selectedIndex].value.substring(event.target.options[event.target.selectedIndex].value.indexOf(":") + 1);
   if(this.gradeselectedValue != "" ) {
    this.topiclist();
  }
  }

  selecttopicChange(event: any) {
    this.topicselectedValue = event.target.options[event.target.selectedIndex].value.substring(event.target.options[event.target.selectedIndex].value.indexOf(":") + 1);
  
  }

  goBack() {
    this.location.back();
  }

// getPartner(){
//   this.rest.getAllPartner().subscribe((result) => {
//     if(result.status){
//       this.partnerList = result.data;  
//       if(this.partnerList.length!=0){
//         this.createForm.patchValue({ partner: this.partnerList[0]._id })
//       }
//     }        
//   }, (err) => {
//   });
// }

getcoursecategory() {
  this.rest.getClassCourseCategory().subscribe((result) => {
    this.coursecategorylist = result.data;
    if(this.isfromcoursedetails == true) {
      if(Object.keys(this.paramData).length == 0 && !this.isEdit){
         this.createForm.patchValue({ courseCategory: this.categoryselectedValue })
       }
       this.isfromcoursedetailsgetmodule();
    }
    else {
      if(Object.keys(this.paramData).length == 0 && !this.isEdit){
        // this.createForm.patchValue({ courseCategory: "" })
       }
    }
  
  }, (err) => {
  console.log(err);
  });
}

getboardfield(){
  this.rest.boardfield().subscribe((result) => {
    //this.courseboardlist = result.data;
    // if(Object.keys(this.paramData).length == 0 && !this.isEdit){
    // //  this.createForm.patchValue({ courseBoard: this.courseboardlist[0]._id })
    // }
    // if(this.languageapi.length > 0){
    //   for(var item of this.languageapi){
    //     if(item.languageName == "CBSE"){
    //       this.createForm.patchValue({ courseLanguage: item._id })
    //     }
        
    //   }
    // }
    console.log(this.courseboardlist);
  }, (err) => {
  console.log(err);
  });
}

// getsubjectfield(){
//   this.rest.subjectfield().subscribe((result) => {
//     this.coursesubjectlist = result.data;
//     if(Object.keys(this.paramData).length == 0 && !this.isEdit){
   
//     }
//     console.log(this.coursesubjectlist);
//   }, (err) => {
//   console.log(err);
//   });
// }

getlanguage(){
  this.rest.languagefield().subscribe((result) => {
    this.languageapi = result.data;
  //   if(Object.keys(this.paramData).length == 0 && !this.isEdit){
  //  //   this.createForm.patchValue({ courseLanguage: this.languageapi[0]._id })
  //   }
    // if(this.languageapi.length > 0){
    //   for(var item of this.languageapi){
    //     if(item.languageName == "English"){
    //       this.createForm.patchValue({ courseLanguage: item._id })
    //     }
        
    //   }
    // }
    console.log(this.languageapi);
  }, (err) => {
  console.log(err);
  });
}

getgrade(){
  this.rest.grade().subscribe((result) => {
    this.gradeapi = result.data;
    if(Object.keys(this.paramData).length == 0 && !this.isEdit){
    //  this.createForm.patchValue({ courseGrade: this.gradeapi[0]._id })
    }
  }, (err) => {
  console.log(err);
  });
}

topiclist() {
  var req = {
    subjectID:this.subjectselectedValue.replace(/\s/g, ""),
    gradeID:this.gradeselectedValue.replace(/\s/g, "")
  }
  const webToken = localStorage.getItem('webtoken');
  console.log("topicreq", req);
    this.rest.topiclist(req,webToken).subscribe((result) => {
    this.topicapi = result.data;
    if(Object.keys(this.paramData).length == 0 && !this.isEdit){
     // this.createForm.patchValue({ courseTopic: this.topicapi[0]._id })
    }
  }, (err) => {
  console.log(err);
  });
}


  tagfield(){
   this.rest.tagfield().subscribe((result) => {
     this.alltag = result;
     console.log(this.alltag);
   }, (err) => {
   console.log(err);
   });
  }
  getSecurity(){
   this.rest.security().subscribe((result) => {
     this.securityArray = result;
     console.log(this.securityArray);
   }, (err) => {
   console.log(err);
   });
  }

//  getVideoList() {
//    this.api.getVideoList().subscribe((response) => {
//      this.selectedRouteId = localStorage.getItem('collectionid');
//      this.selectedRouteValue = localStorage.getItem('collectionname');
//      if(response.status){
//        this.videosListget = response.data.reverse();
//        console.log(this.videosListget);
//      }
//    });
//  }

//  deleteItem(item: any) {
//    const index = this.videos.indexOf(item);
//    this.nameList.splice(index,1);
//    if(this.nameList.join(',').length > 29){
//      this.uploaVideoName =  this.nameList.join(',').substring(0,30)+'...';
//    }else{
//      this.uploaVideoName =  this.nameList.join(',');
//    }
//    if(this.uploaVideoName =='...'  || this.nameList.length ==0){
//      this.uploaVideoName = 'Choose File';
//    }
//    this.videos.splice(index,1);
//    this.allfiles.splice(index,1);
//  }
 onSelectFile(event) {
   this.thumbnailFile=[];
   if (event.target.files && event.target.files[0]) {
     var reader = new FileReader();
     reader.readAsDataURL(event.target.files[0]); // read file as data url
     this.thumbnailFile.push(event.target.files[0])
     this.createForm.patchValue({ thumbnail: this.thumbnailFile[0].name });
     this.thumbnailPlace = this.thumbnailFile[0].name;
     reader.onload = (event) => { // called once readAsDataURL is completed
       // this.url = event.target.result;
       
     }
   }
 }


 resetData(){
   this.createForm.reset();
   this.videos =[];
   this.allfiles =[];
   this.videosList = [];
   this.submitted = false;
   this.nameList  = [];
   this.uploaVideoName = 'Choose File';
 }

 
 fileChange(event) {
  let fileList: FileList = event.target.files;
 // const file = event.target.files[0];
 // console.log(fileList);
 console.log(fileList[0]);
  this.createForm.patchValue({ upload_name: fileList[0] });
  this.selectedFileName = fileList[0].name;
  this.createForm.patchValue({ fileupload: this.selectedFileName });
 // this.testForm.get('fileupload').setValue(file);
}


  onSubmit(status) {
    this.SpinnerService.show();
    var btn = (document.getElementById('addVideoButton') as HTMLButtonElement);
    btn.disabled = true;
    this.submitted = true;
    let data = this.createForm.value;
    if (!this.createForm.valid) {
        const invalid = [];
      const controls = this.createForm.controls;
      console.log(controls)
      for (const name in controls) {
        if (controls[name].status == "INVALID") {
          console.log(name)
          btn.disabled = false;
          this.SpinnerService.hide();
          if(name == "courseCategory") {
            this.toastr.errorToastr("Category Required");
            return;
          }
          else if(name == "videoname") {
            this.toastr.errorToastr("Video Name Required");
            return;
          } else if(name == "description") {
            this.toastr.errorToastr("Description Required");
            return;
          }
          else if(name == "courseModule") {
            this.toastr.errorToastr("Module Required");
            return;
          } else 
          if(name == "courseTemplate") {
            this.toastr.errorToastr("Template Required");
            return;
          }
          else if(name == "courseLanguage") {
            this.toastr.errorToastr("Language Required");
            return;
          }
          else if(name == "courseGrade") {
            this.toastr.errorToastr("Grade Required");
            return;
          }  else if(name == "thumbnail") {
            this.toastr.errorToastr("Thumbnail Required");
            return;
          }
          else if(name == "courseSubject") {
            this.toastr.errorToastr("Subject Required");
            return;
          }
          else if(name == "courseTopic") {
            this.toastr.errorToastr("Topic Required");
            return;
          }
          else if(name == "privacy") {
            this.toastr.errorToastr("Privacy Required");
            return;
          }
          else if(name == "fileupload"){
            this.toastr.errorToastr("Video File Required");
            return;
          }
          else if(name == "level"){
            this.toastr.errorToastr("Level Required");
            return;
          }
          else if (name == "price") {
            this.toastr.errorToastr("Price Required");
            return;
          }
          else {
            this.toastr.errorToastr("Invalid Fields");
            return;
          }
        }
      }
    }
 

     this.formData = new FormData();
     if(this.activatedRoute.snapshot.params.id === undefined) {
 this.formData.append("video_files", data.upload_name);
    if(this.thumbnailFile.length !=0){
      this.formData.append("thumbnail_files", this.thumbnailFile[0],this.thumbnailFile[0]['name']);
    }
    this.formData.append("videoName",data.videoname);
    this.formData.append("description", data.description);
    this.formData.append("categoryID", this.categoryselectedValue.replace(/\s/g, ""));
    this.formData.append("languageID", this.languageselectedValue.replace(/\s/g, ""));
   // this.formData.append("subjectID", this.subjectselectedValue.replace(/\s/g, ""));
  //  this.formData.append("gradeID", this.gradeselectedValue.replace(/\s/g, ""));
    this.formData.append("topicID", this.topicselectedValue.replace(/\s/g, ""));
    this.formData.append("moduleID", this.moduleselectedValue.replace(/\s/g, ""));
    this.formData.append("templateID", this.templateselectedValue.replace(/\s/g, ""));
    this.formData.append("privacy", data.privacy);
    this.formData.append("level", data.level);
    this.formData.append("price", data.price);

    for (var pair of this.formData.entries()) {
      console.log(pair[0]+ ', ' + pair[1]); 
  }

     this.api.uploadVideo(this.formData).subscribe((response) => {
       this.SpinnerService.hide();
       
       if(response.status){
        this.resetData();
        this.router.navigate(['/all-video-listing']);
        this.toastr.infoToastr(response.message);
       }
       else {
        btn.disabled = false;
        this.toastr.errorToastr(response.message);
       }
    });  
     }

     else {
       this.formData.append("videoID",this.activatedRoute.snapshot.params.id)
       if(data.upload_name != null) {
        this.formData.append("video_files", data.upload_name);
        this.formData.append("videoName",data.videoName);
       }    
      if(this.thumbnailFile.length !=0){
        this.formData.append("thumbnail_files", this.thumbnailFile[0],this.thumbnailFile[0]['name']);
      }     
      this.formData.append("description", data.description);
      this.formData.append("categoryID", this.categoryselectedValue.replace(/\s/g, ""));
      this.formData.append("languageID", this.languageselectedValue.replace(/\s/g, ""));
    //  this.formData.append("subjectID", this.subjectselectedValue.replace(/\s/g, ""));
      //this.formData.append("boardID", this.boardselectedValue.replace(/\s/g, ""));
     // this.formData.append("gradeID", this.gradeselectedValue.replace(/\s/g, ""));
      this.formData.append("topicID", this.topicselectedValue.replace(/\s/g, ""));
      this.formData.append("moduleID", this.moduleselectedValue.replace(/\s/g, ""));
      this.formData.append("templateID", this.templateselectedValue.replace(/\s/g, ""));
      this.formData.append("privacy", data.privacy);
      this.formData.append("level", data.level);
  
       this.api.EdituploadedVideo(this.formData).subscribe((response) => {
         this.SpinnerService.hide();
         this.resetData();
         if(response.status){
        //  this.router.navigate(['/success/Video']);
          this.toastr.infoToastr(response.message);
          setTimeout(() => {
            window.location.href = '/teacher/mydashboard'
        }, 500);
         
         }
         else {
          this.toastr.errorToastr(response.message);
         }
       });  
     }
     
  }

  privacytype(e){
        this.createForm.patchValue({ privacy:e.target.value});
   
  }
  
  onItemsubjectSelect(item: any) {
     this.selectedsubjectlist.push(item)
     this.selectedsubjectlist = Array.from(this.selectedsubjectlist.reduce((m, t) => m.set(t._id, t), new Map()).values());
    }
    
    onItemsubjectDeSelect(item1: any) {
      this.selectedsubjectlist = this.selectedsubjectlist.filter(item => !item1._id.includes(item))
    }
   
    onItemsubjectallDeSelect(item1: any) {
     for (let i = 0; i < item1.length; i++) {
       this.selectedsubjectlist = this.selectedsubjectlist.filter(item => !item1[i]._id.includes(item))
       };
   }
   onItemsubjectallSelect(item1: any) {
     this.selectedsubjectlist = [];
     for (let i = 0; i < item1.length; i++) {
       this.selectedsubjectlist.push(item1[i])
       };
   }

   onItemcategorySelect(item: any) {
    this.selectedcategorylist.push(item)
    this.selectedcategorylist = Array.from(this.selectedcategorylist.reduce((m, t) => m.set(t._id, t), new Map()).values());
   }
   
   onItemcategoryDeSelect(item1: any) {
     this.selectedcategorylist = this.selectedcategorylist.filter(item => !item1._id.includes(item))
   }
   
   onItemcategoryallDeSelect(item1: any) {
     for (let i = 0; i < item1.length; i++) {
       this.selectedcategorylist = this.selectedcategorylist.filter(item => !item1[i]._id.includes(item))
       };
   }
   onItemcategoryallSelect(item1: any) {
     this.selectedcategorylist = [];
     for (let i = 0; i < item1.length; i++) {
       this.selectedcategorylist.push(item1[i])
       };
   }
   
   onItemgradeSelect(item: any) {
     this.selectedgradelist.push(item)
     this.selectedgradelist = Array.from(this.selectedgradelist.reduce((m, t) => m.set(t._id, t), new Map()).values());
    }
    
    onItemgradeDeSelect(item1: any) {
      this.selectedgradelist = this.selectedgradelist.filter(item => !item1._id.includes(item))
    }
   
    onItemgradeallDeSelect(item1: any) {
     for (let i = 0; i < item1.length; i++) {
       this.selectedgradelist = this.selectedgradelist.filter(item => !item1[i]._id.includes(item))
       };
   }
   onItemgradeallSelect(item1: any) {
     this.selectedgradelist = [];
     for (let i = 0; i < item1.length; i++) {
       this.selectedgradelist.push(item1[i])
       };
   }
 
}