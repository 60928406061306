

<div *ngIf="isTeacher">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>
<div *ngIf="!this.isTeacher">
    <app-header-style-one></app-header-style-one>
</div>
<div  class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a>Home</a></li>
                <!-- <li><a>Classes</a></li> -->
                <li>Payment Success</li>
            </ul>
            <h2></h2>           
        </div>
    </div>
</div>
<div class="container polaroid mt-4 mb-4">
	<div class="mt-4">
		<h4 class="text-center">Thank You!</h4>
		<h6>
			Click Go to your <a class="text-success" routerLink="/student/mydashboard">Dashboard </a>. Your buddi product package has been added to My Accounts Tab and start your a personalized Learning Experience.
		</h6>
		<div class="text-center">
			
			<img style="width: 80px;height: 80px;"
                            [src]="'assets/img/success.jpg'"
                            alt="image">
            <h6>Paid Successfully</h6>
		</div>
	</div>
	<hr>
	<div>
		Payment Id<br>
		   <a class="text-danger" [href]="paymentDetails.invoiceURL" target="_blank">{{paymentDetails.invoiceID}}</a>
		
	</div>
	<br>
	<div>Payment : <b>{{paymentDetails.productDetails.currency}} {{paymentDetails.productPrice}}</b></div><br>

	<div>
		Paid On<br>
		{{paymentDetails.validFrom |  date :"MMM dd,yyyy, hh:mm aaa"}}
	</div>
	<br><br>
	<div>
 		Email : Care@buddi.life<br>
		Contact : +91 9840844987<br>
 	</div>
</div>