<div *ngIf="isstudent">
    <app-header-style-one></app-header-style-one>
</div>
<div *ngIf="isteacher">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>

<!-- <div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Instructor Details</li>
            </ul>
            <h2>Instructor Details</h2>
        </div>
    </div>
</div> -->
  <!-- Below is for Uday Shankar-->
<section  *ngIf="isuday" class="instructor-details-area pt-100 pb-70">
    <div class="container">
        <div class="instructor-details-desc">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <div class="instructor-details-sidebar">
                        <img src="../../../../assets/img/uday.jpg" class="img-thumbnail" alt="image">

                        <!-- <div class="instructor-details-meta">
                            <ul>
                                <li>
                                    <i class='bx bxs-group'></i>
                                    <span>Follower</span>
                                    <a routerLink="/">102</a>
                                </li>
                                <li>
                                    <i class='bx bx-group'></i>
                                    <span>Following</span>
                                    <a routerLink="/">81</a>
                                </li>
                            </ul>
                        </div> -->
                        <!-- <a  class="default-btn" style="margin-top: 5%;"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Follow</span><i class="bx bx-plus-circle icon-arrow after"></i></a> -->
                        <!-- <ul class="social-link" style="margin-top: 5%;">
                            
                            <li><a class="d-block facebook" ><i class='bx bxl-facebook'></i></a></li>
                            <li><a class="d-block twitter" ><i class='bx bxl-twitter'></i></a></li>
                            <li><a class="d-block instagram" ><i class='bx bxl-instagram'></i></a></li>
                            <li><a class="d-block linkedin" ><i class='bx bxl-linkedin'></i></a></li>
                            <li><a class="d-block pinterest" ><i class='bx bxl-pinterest-alt'></i></a></li>
                        </ul> -->
                    </div>
                </div>

                <div class="col-lg-8 col-md-8">
                    <div class="instructor-details">
                        <h3>Uday Shankar</h3>
                        <span class="sub-title">Head -Technology & Strategy
                            </span>
                        <p>Uday is a Technology Entrepreneur with 25 years of experience in software, hardware and IoT. Held senior leadership positions in CSC/ Xchanging, Infosys, Capgemini / Kanbay.An alumnus of the Indian Institute of Science and Indian School of Business. Built startups in the USA and India and successfully exited them.</p>
                      
                        <!-- <div class="instructor-details-info">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <h3>My Education</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Harvard University</span>
                                            Bachelor in Mathematics
                                        </li>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>University of Toronto</span>
                                            Bachelor in English
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <h3>My Experience</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Envato</span>
                                            2000-2010
                                        </li>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>EnvyTheme</span>
                                            2010-2020
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


  <!-- Below is for Mukundan-->
  <section  *ngIf="ismukund" class="instructor-details-area pt-100 pb-70">
    <div class="container">
        <div class="instructor-details-desc">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <div class="instructor-details-sidebar">
                        <img src="../../../../assets/img/mukundan.jpg" class="img-thumbnail" alt="image">

                        <!-- <div class="instructor-details-meta">
                            <ul>
                                <li>
                                    <i class='bx bxs-group'></i>
                                    <span>Follower</span>
                                    <a routerLink="/">102</a>
                                </li>
                                <li>
                                    <i class='bx bx-group'></i>
                                    <span>Following</span>
                                    <a routerLink="/">81</a>
                                </li>
                            </ul>
                        </div> -->
                        <!-- <a class="default-btn"  style="margin-top: 5%;"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Follow</span><i class="bx bx-plus-circle icon-arrow after"></i></a> -->
                        <!-- <ul class="social-link" style="margin-top: 5%;">
                            
                            <li><a  class="d-block facebook" ><i class='bx bxl-facebook'></i></a></li>
                            <li><a  class="d-block twitter" ><i class='bx bxl-twitter'></i></a></li>
                            <li><a  class="d-block instagram" ><i class='bx bxl-instagram'></i></a></li>
                            <li><a  class="d-block linkedin" ><i class='bx bxl-linkedin'></i></a></li>
                            <li><a  class="d-block pinterest" ><i class='bx bxl-pinterest-alt'></i></a></li>
                        </ul> -->
                    </div>
                </div>

                <div class="col-lg-8 col-md-8">
                    <div class="instructor-details">
                        <h3>Mukundan</h3>
                        <span class="sub-title">Head -  Marketing & Operations
                            </span>
                        <p>Mukund is a business executive with 23 years of experience in handling senior executive positions for large corporations like Sulekha, NSF (USA), NIH (USA). He holds dual MBA, from Drexel and Pondicherry, India.</p>
                      
                        <!-- <div class="instructor-details-info">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <h3>My Education</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Harvard University</span>
                                            Bachelor in Mathematics
                                        </li>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>University of Toronto</span>
                                            Bachelor in English
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <h3>My Experience</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Envato</span>
                                            2000-2010
                                        </li>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>EnvyTheme</span>
                                            2010-2020
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


  <!-- Below is for Arif-->
  <section  *ngIf="isarif" class="instructor-details-area pt-100 pb-70">
    <div class="container">
        <div class="instructor-details-desc">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <div class="instructor-details-sidebar">
                        <img src="../../../../assets/img/arif.jpg" class="img-thumbnail" alt="image">

                        <!-- <div class="instructor-details-meta">
                            <ul>
                                <li>
                                    <i class='bx bxs-group'></i>
                                    <span>Follower</span>
                                    <a routerLink="/">102</a>
                                </li>
                                <li>
                                    <i class='bx bx-group'></i>
                                    <span>Following</span>
                                    <a routerLink="/">81</a>
                                </li>
                            </ul>
                        </div> -->
                        <!-- <a class="default-btn"  style="margin-top: 5%;"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Follow</span><i class="bx bx-plus-circle icon-arrow after"></i></a> -->
                        <!-- <ul class="social-link" style="margin-top: 5%;">
                            
                            <li><a  class="d-block facebook" ><i class='bx bxl-facebook'></i></a></li>
                            <li><a class="d-block twitter" ><i class='bx bxl-twitter'></i></a></li>
                            <li><a  class="d-block instagram" ><i class='bx bxl-instagram'></i></a></li>
                            <li><a  class="d-block linkedin" ><i class='bx bxl-linkedin'></i></a></li>
                            <li><a  class="d-block pinterest" ><i class='bx bxl-pinterest-alt'></i></a></li>
                        </ul> -->
                    </div>
                </div>

                <div class="col-lg-8 col-md-8">
                    <div class="instructor-details">
                        <h3>Arif Sheriff</h3>
                        <span class="sub-title">Chief Academic Officer</span>
                        <p>Arif is a Marketing Leader with 16 years of experience in Digital Marketing and Account-Based Marketing (ABM). Held key marketing positions in HCL Technologies, SI UK Education Council, London. Arif has a PG certificate in Business & Management in Intl. Business from the University of Plymouth and a graduate degree in English Literature from the University of Madras.</p>
                      
                        <!-- <div class="instructor-details-info">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <h3>My Education</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Harvard University</span>
                                            Bachelor in Mathematics
                                        </li>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>University of Toronto</span>
                                            Bachelor in English
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <h3>My Experience</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Envato</span>
                                            2000-2010
                                        </li>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>EnvyTheme</span>
                                            2010-2020
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
  <!-- Below is for mugundan-->
  <section  *ngIf="ismugundan" class="instructor-details-area pt-100 pb-70">
        <div class="container">
            <div class="instructor-details-desc">
                <div class="row">
                    <div class="col-lg-4 col-md-4">
                        <div class="instructor-details-sidebar">
                            <img src="../../../../assets/img/mugundan.jpg" class="img-thumbnail" alt="image">
    
                            <!-- <div class="instructor-details-meta">
                                <ul>
                                    <li>
                                        <i class='bx bxs-group'></i>
                                        <span>Follower</span>
                                        <a routerLink="/">102</a>
                                    </li>
                                    <li>
                                        <i class='bx bx-group'></i>
                                        <span>Following</span>
                                        <a routerLink="/">81</a>
                                    </li>
                                </ul>
                            </div> -->
                            <!-- <a class="default-btn"  style="margin-top: 5%;"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Follow</span><i class="bx bx-plus-circle icon-arrow after"></i></a> -->
                            <!-- <ul class="social-link" style="margin-top: 5%;">
                                
                                <li><a  class="d-block facebook" ><i class='bx bxl-facebook'></i></a></li>
                                <li><a  class="d-block twitter" ><i class='bx bxl-twitter'></i></a></li>
                                <li><a  class="d-block instagram" ><i class='bx bxl-instagram'></i></a></li>
                                <li><a  class="d-block linkedin" ><i class='bx bxl-linkedin'></i></a></li>
                                <li><a  class="d-block pinterest" ><i class='bx bxl-pinterest-alt'></i></a></li>
                            </ul> -->
                        </div>
                    </div>
    
                    <div class="col-lg-8 col-md-8">  
                        <div class="instructor-details">
                            <h3>Mugundan</h3>
                            <span class="sub-title">Head - Product Development
                                </span>
                            <p>Mugundan brings over 12 years of experience involved in End-to-End Product lifecycle Development</p>
                                  <p>Manages Product Development and is in charge of buddi.life`s Marketing/PR. Has worked on Various Technologies and led software teams</p>   
                                    
                          
                            <!-- <div class="instructor-details-info">
                                <div class="row">
                                    <div class="col-lg-6 col-md-12">
                                        <h3>My Education</h3>
                                        <ul>
                                            <li>
                                                <i class='bx bxs-graduation'></i>
                                                <span>Harvard University</span>
                                                Bachelor in Mathematics
                                            </li>
                                            <li>
                                                <i class='bx bxs-graduation'></i>
                                                <span>University of Toronto</span>
                                                Bachelor in English
                                            </li>
                                        </ul>
                                    </div>
    
                                    <div class="col-lg-6 col-md-12">
                                        <h3>My Experience</h3>
                                        <ul>
                                            <li>
                                                <i class='bx bx-briefcase'></i>
                                                <span>Envato</span>
                                                2000-2010
                                            </li>
                                            <li>
                                                <i class='bx bx-briefcase'></i>
                                                <span>EnvyTheme</span>
                                                2010-2020
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
     <!-- Below is for sundarrajan-->
  <section  *ngIf="issundarrajan" class="instructor-details-area pt-100 pb-70">
        <div class="container">
            <div class="instructor-details-desc">
                <div class="row">
                    <div class="col-lg-4 col-md-4">
                        <div class="instructor-details-sidebar">
                            <img src="../../../../assets/img/sundarrajan.jpg" class="img-thumbnail" alt="image">
    
                            <!-- <div class="instructor-details-meta">
                                <ul>
                                    <li>
                                        <i class='bx bxs-group'></i>
                                        <span>Follower</span>
                                        <a routerLink="/">102</a>
                                    </li>
                                    <li>
                                        <i class='bx bx-group'></i>
                                        <span>Following</span>
                                        <a routerLink="/">81</a>
                                    </li>
                                </ul>
                            </div> -->
                            <!-- <a class="default-btn"  style="margin-top: 5%;"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Follow</span><i class="bx bx-plus-circle icon-arrow after"></i></a> -->
                            <!-- <ul class="social-link" style="margin-top: 5%;">
                                
                                <li><a  class="d-block facebook" ><i class='bx bxl-facebook'></i></a></li>
                                <li><a  class="d-block twitter" ><i class='bx bxl-twitter'></i></a></li>
                                <li><a  class="d-block instagram" ><i class='bx bxl-instagram'></i></a></li>
                                <li><a  class="d-block linkedin" ><i class='bx bxl-linkedin'></i></a></li>
                                <li><a  class="d-block pinterest" ><i class='bx bxl-pinterest-alt'></i></a></li>
                            </ul> -->
                        </div>
                    </div>
    
                    <div class="col-lg-8 col-md-8">  
                        <div class="instructor-details">
                            <h3>Sundar Rajan Mugundhu
                            </h3>
                            <span class="sub-title">Head - Eco System Growth
                                </span>
                            <p>Sundarrajan has 10+ Years of Experience playing diverse roles spanning technology implementation to understanding Legal frameworks for cross-border transactions</p>
                                                
                            <!-- <div class="instructor-details-info">
                                <div class="row">
                                    <div class="col-lg-6 col-md-12">
                                        <h3>My Education</h3>
                                        <ul>
                                            <li>
                                                <i class='bx bxs-graduation'></i>
                                                <span>Harvard University</span>
                                                Bachelor in Mathematics
                                            </li>
                                            <li>
                                                <i class='bx bxs-graduation'></i>
                                                <span>University of Toronto</span>
                                                Bachelor in English
                                            </li>
                                        </ul>
                                    </div>
    
                                    <div class="col-lg-6 col-md-12">
                                        <h3>My Experience</h3>
                                        <ul>
                                            <li>
                                                <i class='bx bx-briefcase'></i>
                                                <span>Envato</span>
                                                2000-2010
                                            </li>
                                            <li>
                                                <i class='bx bx-briefcase'></i>
                                                <span>EnvyTheme</span>
                                                2010-2020
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
         <!-- Below is for Kabilan-->
  <section  *ngIf="iskabilan" class="instructor-details-area pt-100 pb-70">
    <div class="container">
        <div class="instructor-details-desc">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <div class="instructor-details-sidebar">
                        <img src="../../../../assets/img/kabilan.jpg" class="img-thumbnail" alt="image">

                        <!-- <div class="instructor-details-meta">
                            <ul>
                                <li>
                                    <i class='bx bxs-group'></i>
                                    <span>Follower</span>
                                    <a routerLink="/">102</a>
                                </li>
                                <li>
                                    <i class='bx bx-group'></i>
                                    <span>Following</span>
                                    <a routerLink="/">81</a>
                                </li>
                            </ul>
                        </div> -->
                        <!-- <a class="default-btn"  style="margin-top: 5%;"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Follow</span><i class="bx bx-plus-circle icon-arrow after"></i></a> -->
                        <!-- <ul class="social-link" style="margin-top: 5%;">
                            
                            <li><a  class="d-block facebook" ><i class='bx bxl-facebook'></i></a></li>
                            <li><a  class="d-block twitter" ><i class='bx bxl-twitter'></i></a></li>
                            <li><a  class="d-block instagram" ><i class='bx bxl-instagram'></i></a></li>
                            <li><a  class="d-block linkedin" ><i class='bx bxl-linkedin'></i></a></li>
                            <li><a  class="d-block pinterest" ><i class='bx bxl-pinterest-alt'></i></a></li>
                        </ul> -->
                    </div>
                </div>

                <div class="col-lg-8 col-md-8">  
                    <div class="instructor-details">
                        <h3>Kabilan Mani</h3>
                        <span class="sub-title">Head - Investor Relations
                            </span>
                        <p>With 14+ years of experience, Kabilan leads investor relations services. He also manages the community of investors, Providing them with timely updates and answering queries</p>
                                            
                        <!-- <div class="instructor-details-info">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <h3>My Education</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Harvard University</span>
                                            Bachelor in Mathematics
                                        </li>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>University of Toronto</span>
                                            Bachelor in English
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <h3>My Experience</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Envato</span>
                                            2000-2010
                                        </li>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>EnvyTheme</span>
                                            2010-2020
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
         <!-- Below is for anita-->
         <section  *ngIf="isanita" class="instructor-details-area pt-100 pb-70">
            <div class="container">
                <div class="instructor-details-desc">
                    <div class="row">
                        <div class="col-lg-4 col-md-4">
                            <div class="instructor-details-sidebar">
                                <img src="../../../../assets/img/anita.jpg" class="img-thumbnail" alt="image">
        
                                <!-- <div class="instructor-details-meta">
                                    <ul>
                                        <li>
                                            <i class='bx bxs-group'></i>
                                            <span>Follower</span>
                                            <a routerLink="/">102</a>
                                        </li>
                                        <li>
                                            <i class='bx bx-group'></i>
                                            <span>Following</span>
                                            <a routerLink="/">81</a>
                                        </li>
                                    </ul>
                                </div> -->
                                <!-- <a class="default-btn"  style="margin-top: 5%;"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Follow</span><i class="bx bx-plus-circle icon-arrow after"></i></a> -->
                                <!-- <ul class="social-link" style="margin-top: 5%;">
                                    
                                    <li><a  class="d-block facebook" ><i class='bx bxl-facebook'></i></a></li>
                                    <li><a  class="d-block twitter" ><i class='bx bxl-twitter'></i></a></li>
                                    <li><a  class="d-block instagram" ><i class='bx bxl-instagram'></i></a></li>
                                    <li><a  class="d-block linkedin" ><i class='bx bxl-linkedin'></i></a></li>
                                    <li><a  class="d-block pinterest" ><i class='bx bxl-pinterest-alt'></i></a></li>
                                </ul> -->
                            </div>
                        </div>
        
                        <div class="col-lg-8 col-md-8">  
                            <div class="instructor-details">
                                <h3>Anita Mohan</h3>
                                <span class="sub-title">Head - People Management
                                    </span>
                                    <p>With a doctoral degree, Anita takes care of people related processes improvement at buddi.life. </p>
                                    <p>She is also entrusted with the job of upskilling and training internal stakeholders / employees to take the platform to the next level </p>
                                    
                                                    
                                <!-- <div class="instructor-details-info">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-12">
                                            <h3>My Education</h3>
                                            <ul>
                                                <li>
                                                    <i class='bx bxs-graduation'></i>
                                                    <span>Harvard University</span>
                                                    Bachelor in Mathematics
                                                </li>
                                                <li>
                                                    <i class='bx bxs-graduation'></i>
                                                    <span>University of Toronto</span>
                                                    Bachelor in English
                                                </li>
                                            </ul>
                                        </div>
        
                                        <div class="col-lg-6 col-md-12">
                                            <h3>My Experience</h3>
                                            <ul>
                                                <li>
                                                    <i class='bx bx-briefcase'></i>
                                                    <span>Envato</span>
                                                    2000-2010
                                                </li>
                                                <li>
                                                    <i class='bx bx-briefcase'></i>
                                                    <span>EnvyTheme</span>
                                                    2010-2020
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
                 <!-- Below is for Soniya-->
                 <section  *ngIf="issoniya" class="instructor-details-area pt-100 pb-70">
                    <div class="container">
                        <div class="instructor-details-desc">
                            <div class="row">
                                <div class="col-lg-4 col-md-4">
                                    <div class="instructor-details-sidebar">
                                        <img src="../../../../assets/img/soniya.jpg" class="img-thumbnail" alt="image">
                
                                        <!-- <div class="instructor-details-meta">
                                            <ul>
                                                <li>
                                                    <i class='bx bxs-group'></i>
                                                    <span>Follower</span>
                                                    <a routerLink="/">102</a>
                                                </li>
                                                <li>
                                                    <i class='bx bx-group'></i>
                                                    <span>Following</span>
                                                    <a routerLink="/">81</a>
                                                </li>
                                            </ul>
                                        </div> -->
                                        <!-- <a class="default-btn"  style="margin-top: 5%;"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Follow</span><i class="bx bx-plus-circle icon-arrow after"></i></a> -->
                                        <!-- <ul class="social-link" style="margin-top: 5%;">
                                            
                                            <li><a  class="d-block facebook" ><i class='bx bxl-facebook'></i></a></li>
                                            <li><a  class="d-block twitter" ><i class='bx bxl-twitter'></i></a></li>
                                            <li><a  class="d-block instagram" ><i class='bx bxl-instagram'></i></a></li>
                                            <li><a  class="d-block linkedin" ><i class='bx bxl-linkedin'></i></a></li>
                                            <li><a  class="d-block pinterest" ><i class='bx bxl-pinterest-alt'></i></a></li>
                                        </ul> -->
                                    </div>
                                </div>
                
                                <div class="col-lg-8 col-md-8">  
                                    <div class="instructor-details">
                                        <h3>Soniya Varadhan
                                        </h3>
                                        <span class="sub-title">Head - Content Management

                                            </span>
                                            <p>Having 6+ years of experience in the Education space, Soniya develops and aggregates high quality content in various media formats with an eye for detail she ensures standardization of courses across categories. </p>
                                                                                          
                                        <!-- <div class="instructor-details-info">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-12">
                                                    <h3>My Education</h3>
                                                    <ul>
                                                        <li>
                                                            <i class='bx bxs-graduation'></i>
                                                            <span>Harvard University</span>
                                                            Bachelor in Mathematics
                                                        </li>
                                                        <li>
                                                            <i class='bx bxs-graduation'></i>
                                                            <span>University of Toronto</span>
                                                            Bachelor in English
                                                        </li>
                                                    </ul>
                                                </div>
                
                                                <div class="col-lg-6 col-md-12">
                                                    <h3>My Experience</h3>
                                                    <ul>
                                                        <li>
                                                            <i class='bx bx-briefcase'></i>
                                                            <span>Envato</span>
                                                            2000-2010
                                                        </li>
                                                        <li>
                                                            <i class='bx bx-briefcase'></i>
                                                            <span>EnvyTheme</span>
                                                            2010-2020
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                  <!-- Below is for Sathis-->
                  <section  *ngIf="issathis" class="instructor-details-area pt-100 pb-70">
                    <div class="container">
                        <div class="instructor-details-desc">
                            <div class="row">
                                <div class="col-lg-4 col-md-4">
                                    <div class="instructor-details-sidebar">
                                        <img src="../../../../assets/img/sathis.jpg" class="img-thumbnail" alt="image">
                
                                        <!-- <div class="instructor-details-meta">
                                            <ul>
                                                <li>
                                                    <i class='bx bxs-group'></i>
                                                    <span>Follower</span>
                                                    <a routerLink="/">102</a>
                                                </li>
                                                <li>
                                                    <i class='bx bx-group'></i>
                                                    <span>Following</span>
                                                    <a routerLink="/">81</a>
                                                </li>
                                            </ul>
                                        </div> -->
                                        <!-- <a class="default-btn"  style="margin-top: 5%;"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Follow</span><i class="bx bx-plus-circle icon-arrow after"></i></a> -->
                                        <!-- <ul class="social-link" style="margin-top: 5%;">
                                            
                                            <li><a  class="d-block facebook" ><i class='bx bxl-facebook'></i></a></li>
                                            <li><a  class="d-block twitter" ><i class='bx bxl-twitter'></i></a></li>
                                            <li><a  class="d-block instagram" ><i class='bx bxl-instagram'></i></a></li>
                                            <li><a  class="d-block linkedin" ><i class='bx bxl-linkedin'></i></a></li>
                                            <li><a  class="d-block pinterest" ><i class='bx bxl-pinterest-alt'></i></a></li>
                                        </ul> -->
                                    </div>
                                </div>
                
                                <div class="col-lg-8 col-md-8">  
                                    <div class="instructor-details">
                                        <h3>Sathis Kumar Ganapathi
                                        </h3>
                                        <span class="sub-title">Head - Content Management

                                            </span>
                                            <p>Sathis has 10+ years of Experience in Software Quality Assurance, Performance Auditing and Product Quality Improvement.  </p>
                                          <p>  He is conversant with tools and techniques to ensure product meets accepted standards at buddi.life</p>
                                              
                                                                                          
                                        <!-- <div class="instructor-details-info">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-12">
                                                    <h3>My Education</h3>
                                                    <ul>
                                                        <li>
                                                            <i class='bx bxs-graduation'></i>
                                                            <span>Harvard University</span>
                                                            Bachelor in Mathematics
                                                        </li>
                                                        <li>
                                                            <i class='bx bxs-graduation'></i>
                                                            <span>University of Toronto</span>
                                                            Bachelor in English
                                                        </li>
                                                    </ul>
                                                </div>
                
                                                <div class="col-lg-6 col-md-12">
                                                    <h3>My Experience</h3>
                                                    <ul>
                                                        <li>
                                                            <i class='bx bx-briefcase'></i>
                                                            <span>Envato</span>
                                                            2000-2010
                                                        </li>
                                                        <li>
                                                            <i class='bx bx-briefcase'></i>
                                                            <span>EnvyTheme</span>
                                                            2010-2020
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                          
                             
                             <!-- Below is for Mohan-->
                             <section  *ngIf="ismohan" class="instructor-details-area pt-100 pb-70">
                                <div class="container">
                                    <div class="instructor-details-desc">
                                        <div class="row">
                                            <div class="col-lg-4 col-md-4">
                                                <div class="instructor-details-sidebar">
                                                    <img src="../../../../assets/img/mohan.jpg" class="img-thumbnail" alt="image">
                            
                                                    <!-- <div class="instructor-details-meta">
                                                        <ul>
                                                            <li>
                                                                <i class='bx bxs-group'></i>
                                                                <span>Follower</span>
                                                                <a routerLink="/">102</a>
                                                            </li>
                                                            <li>
                                                                <i class='bx bx-group'></i>
                                                                <span>Following</span>
                                                                <a routerLink="/">81</a>
                                                            </li>
                                                        </ul>
                                                    </div> -->
                                                    <!-- <a class="default-btn"  style="margin-top: 5%;"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Follow</span><i class="bx bx-plus-circle icon-arrow after"></i></a> -->
                                                    <!-- <ul class="social-link" style="margin-top: 5%;">
                                                        
                                                        <li><a  class="d-block facebook" ><i class='bx bxl-facebook'></i></a></li>
                                                        <li><a  class="d-block twitter" ><i class='bx bxl-twitter'></i></a></li>
                                                        <li><a  class="d-block instagram" ><i class='bx bxl-instagram'></i></a></li>
                                                        <li><a  class="d-block linkedin" ><i class='bx bxl-linkedin'></i></a></li>
                                                        <li><a  class="d-block pinterest" ><i class='bx bxl-pinterest-alt'></i></a></li>
                                                    </ul> -->
                                                </div>
                                            </div>
                            
                                            <div class="col-lg-8 col-md-8">  
                                                <div class="instructor-details">
                                                    <h3>Mohana Sundaram  </h3>
                                                    <span class="sub-title">Chief Architect - Blockchain

               </span>
                                                   <p>Blockchain Architect,  Solidity ,Truffle and Chaincode.
                                                </p>
                                                          
                                                                                                      
                                                    <!-- <div class="instructor-details-info">
                                                        <div class="row">
                                                            <div class="col-lg-6 col-md-12">
                                                                <h3>My Education</h3>
                                                                <ul>
                                                                    <li>
                                                                        <i class='bx bxs-graduation'></i>
                                                                        <span>Harvard University</span>
                                                                        Bachelor in Mathematics
                                                                    </li>
                                                                    <li>
                                                                        <i class='bx bxs-graduation'></i>
                                                                        <span>University of Toronto</span>
                                                                        Bachelor in English
                                                                    </li>
                                                                </ul>
                                                            </div>
                            
                                                            <div class="col-lg-6 col-md-12">
                                                                <h3>My Experience</h3>
                                                                <ul>
                                                                    <li>
                                                                        <i class='bx bx-briefcase'></i>
                                                                        <span>Envato</span>
                                                                        2000-2010
                                                                    </li>
                                                                    <li>
                                                                        <i class='bx bx-briefcase'></i>
                                                                        <span>EnvyTheme</span>
                                                                        2010-2020
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                                        <!-- Below is for Thirupathi-->
                                        <section  *ngIf="isthirupathi" class="instructor-details-area pt-100 pb-70">
                                            <div class="container">
                                                <div class="instructor-details-desc">
                                                    <div class="row">
                                                        <div class="col-lg-4 col-md-4">
                                                            <div class="instructor-details-sidebar">
                                                                <img src="../../../../assets/img/thirupathi.jpg" class="img-thumbnail" alt="image">
                                        
                                                                <!-- <div class="instructor-details-meta">
                                                                    <ul>
                                                                        <li>
                                                                            <i class='bx bxs-group'></i>
                                                                            <span>Follower</span>
                                                                            <a routerLink="/">102</a>
                                                                        </li>
                                                                        <li>
                                                                            <i class='bx bx-group'></i>
                                                                            <span>Following</span>
                                                                            <a routerLink="/">81</a>
                                                                        </li>
                                                                    </ul>
                                                                </div> -->
                                                                <!-- <a class="default-btn"  style="margin-top: 5%;"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Follow</span><i class="bx bx-plus-circle icon-arrow after"></i></a> -->
                                                                <!-- <ul class="social-link" style="margin-top: 5%;">
                                                                    
                                                                    <li><a  class="d-block facebook" ><i class='bx bxl-facebook'></i></a></li>
                                                                    <li><a  class="d-block twitter" ><i class='bx bxl-twitter'></i></a></li>
                                                                    <li><a  class="d-block instagram" ><i class='bx bxl-instagram'></i></a></li>
                                                                    <li><a  class="d-block linkedin" ><i class='bx bxl-linkedin'></i></a></li>
                                                                    <li><a  class="d-block pinterest" ><i class='bx bxl-pinterest-alt'></i></a></li>
                                                                </ul> -->
                                                            </div>
                                                        </div>
                                        
                                                        <div class="col-lg-8 col-md-8">  
                                                            <div class="instructor-details">
                                                                <h3>Thirupathi  </h3>
                                                                <span class="sub-title">Manager - Full Stack Development         
                           </span>
                                                               <p>Involved in end-to-end life-cycle of the product, right from understanding the client requirements and mapping it into technical solutions in a time bound environment
                                                            </p>
                                                                                                                                                                        
                                                                <!-- <div class="instructor-details-info">
                                                                    <div class="row">
                                                                        <div class="col-lg-6 col-md-12">
                                                                            <h3>My Education</h3>
                                                                            <ul>
                                                                                <li>
                                                                                    <i class='bx bxs-graduation'></i>
                                                                                    <span>Harvard University</span>
                                                                                    Bachelor in Mathematics
                                                                                </li>
                                                                                <li>
                                                                                    <i class='bx bxs-graduation'></i>
                                                                                    <span>University of Toronto</span>
                                                                                    Bachelor in English
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                        
                                                                        <div class="col-lg-6 col-md-12">
                                                                            <h3>My Experience</h3>
                                                                            <ul>
                                                                                <li>
                                                                                    <i class='bx bx-briefcase'></i>
                                                                                    <span>Envato</span>
                                                                                    2000-2010
                                                                                </li>
                                                                                <li>
                                                                                    <i class='bx bx-briefcase'></i>
                                                                                    <span>EnvyTheme</span>
                                                                                    2010-2020
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>