import { Component, OnInit, Inject, ElementRef, ViewChild, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Options } from 'ng5-slider';
import { ApiService } from '../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from '../../../../environments/environment';
import { MediaService } from '../../../shared/media.service';
import * as moment from 'moment';

@Component({
  selector: 'app-learning-tools',
  templateUrl: './learning-tools.component.html',
  styleUrls: ['./learning-tools.component.scss']
})
export class LearningToolsComponent implements OnInit {
  public meetingsData: any = [];
  public meetingsDataRecord: any = [];
  public meetingsDataListen: any = [];
  public meetingsDataAll: any = [];
  public allmeeting: any = [];
  public inviteUrl = "";
  public frontwebsite = environment.frontUrl;
  public lessonData: any = [];
  public myId: any = ''
  public isLogin: any = 'false';
  public isCancel: any = 'false';
  // public isStudent : any 
  public videoList: any = [];
  public videoListPrivate: any = [];
  public videoListGroup: any = [];
  public teacherVideouploadlist: any = [];
  public videoYoutubePublic: any = [];
  public publicsessionData: any = [];
  public publicsessionDataRecord: any = [];
  public requestedDataList: any = [];
  public requestedDataAccpet: any = [];
  public requestedDataAccpetRecord: any = [];
  public teacherPayoutDetails: any = [];
  public mydashboardData: any = [];
  public isStudent: boolean = false;
  public isTeacher: boolean = false;
  public currentPageNumber : number = 1;
  public totalVideoCount: number;
  public totalPageCount: number;
  public currentVideoCount: number;
  term: string;
  public webinarData: any = [];
  isDesc: any = 1;
  column: string = 'remainingClass';
  p: number = 1;
  count: number = 4;
  // public videoList:any=[];

  constructor(private api: MediaService, private SpinnerService: NgxSpinnerService, private activatedRoute: ActivatedRoute, public location: Location, public toastr: ToastrManager, private formBuilder: FormBuilder, private rest: ApiService, private router: Router) { }

  ngOnInit() {
    this.isLogin = localStorage.getItem('isLoggedin');
    console.log(this.isLogin)
    this.isStudent = localStorage.getItem('profileType').toLowerCase() == 'student'
    this.isTeacher = localStorage.getItem('profileType').toLowerCase() == 'teacher'

    if (this.isLogin == 'true' && this.isTeacher) {
      this.getVideoClassList();
    }
    else if (this.isLogin == 'true' && this.isStudent) {
      this.getVideoClassWithoutLogin();
    }

    else {
      this.getVideoClassWithoutLogin();
    }
  }


  getVideoClassList() {

    const webToken = localStorage.getItem('webtoken');

    this.SpinnerService.show();
    var request = {};
    request['limit'] = environment.listing__itemsPerPage;
    request['page'] = this.currentPageNumber;
    this.rest.NewVideoListing(webToken, request).subscribe((response) => {
      console.log('response', response)
      
      if (response.status && response.data != null && response.data.length != 0) {
        this.videoList = response.data.data;
        console.log(this.videoList, 'this.webinarData')
        this.totalVideoCount = response.data.totalCount;
        this.totalPageCount = Math.ceil(this.totalVideoCount / environment.listing__itemsPerPage)
        this.currentVideoCount = ((this.currentPageNumber - 1) * environment.listing__itemsPerPage)
        for(var roundTable of this.videoList){
          roundTable.startDateTime = (roundTable.startDateTime)
          console.log('roundTable.startDateTime',roundTable.startDateTime);
          if(!roundTable.thumbnail){
            roundTable.thumbnail = 'assets/img/courses/courses1.jpg'
          }
        }
      } else {
        this.mydashboardData = [];
      }
      this.SpinnerService.hide()
    }, (err) => {
      console.log(err);
    });
  }

  getVideoClassWithoutLogin() {
    
    this.isLogin = localStorage.getItem('isLoggedin');
    this.SpinnerService.show();
    var request = {};
    request['limit'] = environment.listing__itemsPerPage;
    request['page'] = this.currentPageNumber;
    this.rest.getLiveclassTeacherWithoutLogin({}, request).subscribe((response) => {
      console.log('response', response)
      
      if (response.status && response.data != null) {
        this.videoList = response.data.data;
        console.log(this.videoList, 'this.webinarData')
        this.totalVideoCount = response.data.totalCount;
        this.totalPageCount = Math.ceil(this.totalVideoCount / environment.listing__itemsPerPage)
        this.currentVideoCount = ((this.currentPageNumber - 1) * environment.listing__itemsPerPage)
        // console.log(this.videoList)
        for(var roundTable of this.videoList){
          roundTable.startDateTime = new Date( roundTable.startDateTime).toLocaleString()
          if(!roundTable.thumbnail){
            roundTable.thumbnail = 'assets/img/courses/courses1.jpg'
          }
        }
      } else {
        this.mydashboardData = [];
      }
      this.SpinnerService.hide()
    }, (err) => {
      console.log(err);
    });
  }
  SortDataPast(data, isOneonone) {
    var sortDate = data.sort(function (a, b) { return new Date(a.ScheduleDate).getTime() - new Date(b.ScheduleDate).getTime() });
    const now = Date.now();
    if (isOneonone) {
      const futureDates = sortDate.filter(date => {
        return (date.ScheduleDate && new Date(date.ScheduleDate).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)) || date.isrecorded;
      });
      return futureDates;
    } else {
      const futureDates = sortDate.filter(date => {
        return (date.ScheduleDate && new Date(date.ScheduleDate).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0));
      });
      return futureDates;
    }

  }
  // indexFilter(filterData){
  //   this.modelData = filterData;
  // }
  sort(property1, property2) {
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property1;
    let direction = this.isDesc ? 1 : -1;
    if (property2) {
      this.teacherPayoutDetails.sort(function (a, b) {
        if (a[property1][property2] < b[property1][property2]) {
          return -1 * direction;
        }
        else if (a[property1][property2] > b[property1][property2]) {
          return 1 * direction;
        }
        else {
          return 0;
        }
      });
    } else {
      this.teacherPayoutDetails.sort(function (a, b) {
        if (a[property1] < b[property1]) {
          return -1 * direction;
        }
        else if (a[property1] > b[property1]) {
          return 1 * direction;
        }
        else {
          return 0;
        }
      });
    }

  };
  redirect() {
    this.router.navigateByUrl('/');
  }
  videodetails(data) {
    this.router.navigateByUrl('/videoDetails/' + data._id);
  }
  detailsMeeting(data, pagename) {
    data.pagename = pagename;
    var jsonStr = JSON.stringify(data)
    localStorage.setItem('livedetails', jsonStr);
    console.log(data)
    if(this.isTeacher){
      this.router.navigate(['/live-class-details/' + data._id], { state: data });
    }
    else{
      this.router.navigate(['/live-class-details/' + data._id], { state: data });
    }
  }
  clickPageNumber(pageNumber) {
    this.currentPageNumber = pageNumber;
    console.log(this.currentPageNumber)
    if (this.isLogin == 'true' && this.isTeacher) {
      this.getVideoClassList();
    }
    else if (this.isLogin == 'true' && this.isStudent) {
      this.getVideoClassWithoutLogin();
    }

    else {
      this.getVideoClassWithoutLogin();
    }
  }

  redirectToCreate(){
    this.router.navigate(['/create-liveclass'])
  }
}
