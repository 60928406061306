<app-logpage></app-logpage>
<section class="login-area bodyHeight">
  <div class="row " style="justify-content: center;margin: 30px 0 30px;">
    <!-- <div class="col-lg-6 col-md-12 p-0">
      <div class="login-image">
        <img src="../../../../../assets/img/buddi-logo.png" alt="image">
      </div>
    </div> -->

    <div class="col-lg-4 col-md-12 p-0 logincol" >
      <!-- <button  routerLink="/student/login" class="float-right col-lg-4 default-btn">Student Login</button> -->
      <div class="login-content">
        <div class="d-table">
          <div class="d-table-cell">
            <div class="login-form">
              <!--  <div class="logo">
                                <a routerLink="/teacher/home"><img src="assets/img/black-logo.png" alt="image"></a>
                            </div> -->
              <!-- <div class="row ml-4">
                <button (click)="teacherLogin()"
                  class=" col-lg-5 col-xs-5 btn btnType2 btn-block my-4 loginButtonselfpage">
                  Teacher
                </button>
                <span class="col-lg-1 col-xs-1">
                </span>
                <button (click)="studentLogin()" class=" col-lg-5 col-xs-5 btn btnType2 btn-block my-4 loginbutton">
                  Student
                </button>
              </div> -->
              <!-- <h3>Tutor</h3>
              <h3>Welcome </h3> -->
              <!-- Tabs navs -->
<!-- <ul class="nav nav-tabs mb-3" id="ex1" role="tablist" >
  <li class="nav-item" role="presentation" >
    <a
      class="nav-link active"
      id="ex1-tab-1"
      data-mdb-toggle="tab"
      role="tab"
      aria-controls="ex1-tabs-1"
      aria-selected="true"
      (click)="teacherLogin()"
      >Tutor</a
    >
  </li>
  <li class="nav-item" role="presentation">
    <a
      class="nav-link"
      id="ex1-tab-2"
      data-mdb-toggle="tab"
      role="tab"
      aria-controls="ex1-tabs-2"
      aria-selected="false"
      (click)="studentLogin()"
      >Learner</a
    >
  </li>
</ul> -->

<!-- Tabs content -->
              
              <form class="p-4">
                <div class="loginForm" id="loginFormIDt">
                  <div class="connect-with-social row">
                    <div class="col-lg-6">
                      <button class="google mb-1" style="background: #00adee;" (click)="teacherLogin()">
                         <span>Tutor</span>
                      </button>
                    </div>
                    <div class="col-lg-6">
                      <button class="facebook" style="background: #fff;color: #222;" (click)="studentLogin()">
                        <span>Learner</span>
                      </button>
                    </div>
                  </div>
               
                  <div class="tab-content" id="registerTabContent">
                    <div class="tab-pane fade show active" id="userregister" role="tabpanel"
                      aria-labelledby="userregister-tab">
                      <form class="p-4 mt-0" [formGroup]="registerform" id="mobilenumber">
                        <div class="form-group">
                                  <ngx-intl-tel-input [cssClass]="'custom'"
                          [enableAutoCountrySelect]="true" [enablePlaceholder]="false" [searchCountryFlag]="true"
                          [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                          [selectedCountryISO]="CountryISO.India" [maxLength]="15"
                          [phoneValidation]="true" [separateDialCode]="true"  class="form-control"
                           formControlName="mobileNumber1"     placeholder="Mobile Number"
                           [ngClass]="{'is-invalid': submitted  &&  !f.mobileNumber1}"
                          >

                        </ngx-intl-tel-input>
                        <div *ngIf="shows">Mobile No is Required</div>
                        <div *ngIf="showslength">Mobile Number is must have 10 Digits</div>

                          <div

                          *ngIf="!f.mobileNumber1.pristine && f.mobileNumber1.errors" class="invalid-feedback">
                            <div *ngIf="submitted && !f.mobileNumber1">Mobile No is Required</div>
                            <!-- <div *ngIf="f.mobileNumber1.errors.pattern">Enter only number</div>
                            <div *ngIf="f.mobileNumber1.errors.minlength">Mobile No must have 10 digits</div>
                            <div *ngIf="f.mobileNumber1.errors.maxlength">Mobile No cannot have more than 20 digits -->
                            </div>
                          </div>
                        <br>
                        <button (click)="loginSubmit()" >Send OTP</button>
                        <br>
                        <p style="cursor: pointer;" (click)="showemail()">Login with Email-Id ?</p>
                        <!-- <p class="regLink mt-3">
                          Already have buddi account? <a routerLink="/teacher/login">Login</a>
                        </p> -->
                       
                        
                      </form>
                      
                      
                      <form class="p-4 mt-0" [formGroup]="emailform" id="email">
                                      
                        <div class="form-group">

                          <input
                          type="text"
                          id="LoginFormEmail"
                          class="form-control"
                          placeholder="Enter Email"
                          name="mailID"
                          formControlName="email"
                          [ngClass]="{ 'is-invalid': submitted && emailf.email.errors }" />
                        
                      
                          <div *ngIf="submitted && emailf.email.errors" class="invalid-feedback">
                            <div *ngIf="emailf.email.errors.required">Email is required</div>
                            <div *ngIf="emailf.email.errors.email">Email must be a valid email address</div>
                        </div>
                         
                          <!-- <input type="text" id="number" class="form-control" formControlName="mobileNumber1"
                            placeholder="Mobile Number"> -->
                          <!-- <div *ngIf="!f.mobileNumber1.pristine && f.mobileNumber1.errors" class="invalid-feedback">
                            <div *ngIf="f.mobileNumber1.errors.required">Email is Required</div>
                            <div *ngIf="f.mobileNumber1.errors.pattern">Enter Email</div>
                          </div> -->
                        </div>
                        <div class="form-group">
                          <pwEye (eyePassword)="receivedData($event)"></pwEye>
                          <input
                            [type]="password"
                            id="LoginFormPassword"
                            class="form-control"
                            formControlName="passWord"
                            placeholder="Password"
                            name="password"
                            [ngClass]="{ 'is-invalid': submitted && emailf.passWord.errors }" />
                        
                  
                          <div *ngIf="submitted && emailf.passWord.errors" class="invalid-feedback">
                            <div *ngIf="emailf.passWord.errors.required">Password is required</div>
                            <div *ngIf="emailf.passWord.errors.passWord">Valid Password is required</div>
                        </div>
                        </div>
                        <br>
                        <button (click)="submitEmail(emailform)" >Login</button>
                        <br>
                        <p style="cursor: pointer;" (click)="showmobile()">Login with OTP ?</p>
                        <!-- <p class="regLink mt-3">
                          Already have buddi account? <a routerLink="/teacher/login">Login</a>
                        </p> -->
                        <!-- <button (click)="submitEmail(emailform)" style="width: 50%;margin-left: 27%;margin-top: 2%;" >Login</button> -->
                       
                      </form>
                    </div>
                  </div>
                  <!-- <div class="form-group" >
                                    <input
                                      type="text"
                                      id="LoginFormEmail"
                                      class="form-control"
                                      placeholder="Enter email/mobile number"
                                      [(ngModel)]="loginID.email"
                                      name="mailID"
                                      #loginEmail = "ngModel"
                                      [ngClass]="{ 'is-invalid': submitted && loginEmail.invalid }"
                                      required email
                                    />
                                    <div *ngIf="submitted && loginEmail.invalid" class="invalid-feedback">
                                      <div *ngIf="loginEmail.errors.required">Email/Mobile No Required</div>
                                    </div>
                                </div> -->

                  <!-- <div class="form-group"> -->
                  <!-- <label>Password</label> -->
                  <!-- <show-hide-password size="xs" btnStyle="default"  [btnOutline]="false"> -->
                  <!-- <span>
                                      <i class="fa fa-eye eyeIcon" (click)="passwordTypeChange()" aria-hidden="true" *ngIf="!show"></i>
                                      <i class="fa fa-eye-slash eyeIcon" (click)="passwordTypeChange()" aria-hidden="true" *ngIf="show"></i> -->
                  <!-- </span> -->
                  <!-- <pwEye (eyePassword)="receivedData($event)"></pwEye>
                                     <input
                                       [type]="this.password"
                                       id="LoginFormPassword"
                                       class="form-control"
                                       placeholder="Password"
                                       [(ngModel)]="loginID.passWord"
                                       name="password"
                                       #loginPassword = "ngModel"
                                       [ngClass]="{ 'is-invalid': submitted && loginPassword.invalid }"
                                       required
                                     />
                                   
                                   
                                     </show-hide-password> -->
                  <!-- <div *ngIf="submitted && loginPassword.invalid" class="invalid-feedback">
                                       <div *ngIf="loginPassword.errors.required">Password Required</div>
                                    </div>
                                </div> -->


                  <!-- <div class="form-group">
                                  <input
                                    type="text"
                                    id="number"
                                    class="form-control"
                                    placeholder="Mobile Number"
                                    #mobileNumber1 = "ngModel"
                                    [ngClass]="{ 'is-invalid': submitted && mobileNumber1.invalid }"
required
                                  />
                                  <div *ngIf="submitted && mobileNumber1.invalid" class="invalid-feedback">
                                    <div *ngIf="mobileNumber1.errors.required">Mobile No is Required</div>
                                    <div *ngIf="mobileNumber1.errors.pattern">Enter only number</div>
                                    <div *ngIf="mobileNumber1.errors.minlength">Mobile No must have 10 digits</div>
                                    <div *ngIf="mobileNumber1.errors.maxlength">Mobile No cannot have more than 20 digits</div>
                                </div>
                                </div> -->


                  <!-- <div class="form-group"> -->
                  <!-- <label>Mobile Number</label> -->
                  <!-- <input
                                    type="text"
                                    id="otp"
                                    class="form-control"
                                    formControlName="otpNo"
                                    placeholder="Mobile Number"
                                    [ngClass]="{'is-invalid': !f.otpNo.pristine && f.otpNo.errors}"
                                  />
                                  <div *ngIf="!f.otpNo.pristine && f.otpNo.errors" class="invalid-feedback">
                                    <div *ngIf="f.otpNo.errors.required">OTP No is Required</div>
                                    <div *ngIf="f.otpNo.errors.pattern">Enter only number</div>
                                    <div *ngIf="f.otpNo.errors.minlength">Mobile No must have 10 digits</div>
                                </div>
                                </div> -->
                  <!-- <button (click)="loginSubmit()">Login</button> -->
                  <div class="forgot-password">
                    <p>New to buddi? <a (click)="teacher()">Sign up</a></p>
                    <a routerLink="/teacher/forgot-password">Forgot Password?</a>
                  </div>
                  <!--           <div class="row">
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-6"> <a href="#" class="btn btn-primary facebook"> <span>Login with Facebook</span> <i class="fa fa-facebook"></i> </a> </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-6"> <a href="#" class="btn btn-primary google-plus"> Login with Google <i class="fa fa-google-plus"></i> </a> </div>
                    </div>    <h2>or</h2>
                    <div class="formsix-pos">
                        <div class="form-group i-email"> <input type="email" class="form-control" required="" id="email2" placeholder="Email Address *"> </div>
                    </div>
                    <div class="formsix-e">
                        <div class="form-group i-password"> <input type="password" class="form-control" required="" id="password2" placeholder="Password *"> </div>
                    </div> -->
                  <!-- <div>
                    (or)
                  </div> -->
                  <div class="connect-with-social row">
                    <div class="col-lg-6">
                      <button class="google mb-1" style="display: flex;justify-content: center; align-items: center;" (click)="signInWithGoogleTeacher()">
                        <img src="../../../../../assets/img/Google__G.png" style="margin-right: 8px;" width="16" height="20" alt=""> 
                        <span>Login</span>
                      </button>
                    </div>
                    <!--   <div class="col-lg-1 p-1 mt-2">
                                    (or)
                                  </div> -->
                    <div class="col-lg-6">
                      <button class="facebook" style="display: flex;justify-content: center; align-items: center;" (click)="signInWithFB()">   
                        <img src="../../../../../assets/img/Facebook.png" style="margin-right: 8px;" width="20" height="20" alt="">
                        <span>Login</span></button>
                    </div>
                  </div>
                </div>
                <!-- <p style="cursor: pointer;" (click)="studentLogin()" routerLink="/student/login">Are you a Learner?</p> -->
                <!-- OTP ADDED -->
                <div class="loginForm" id="OTPFormIDt">

                  <!-- <h3>Enter OTP</h3> -->
                  <div class="tab-content" id="registerTabContent">
                    <div class="tab-pane fade show active" id="userregister" role="tabpanel"
                      aria-labelledby="userregister-tab">
                      <form class="pl-4  pr-4 m-0" [formGroup]="otpForm">
                        <div class="row">
                          <div class="col-lg-12">
                            <p>An OTP has been sent to {{phoneNumber}}. If this is not your number please  
                            <a [ngClass]="{'md-btn md-btn-success': !isChangeNumberButtonDisabled,
                              'md-btn disabled': isChangeNumberButtonDisabled }" >
                            <span id = "changeNumberButton" (click)="changePhoneNumber()" style="color:rgb(4, 31, 184);"><b class="hover">click here</b></span>
                            </a>
                            </p>
                          </div>
                          <div class="col-lg-2">

                          </div>
                          <div class="col-lg-8">
                            <div class="form-group">
                              <input type="text" id="registerFormOtp" class="form-control" placeholder="OTP"
                                formControlName="otp"
                                [ngClass]="{'is-invalid': !otpf.otp.pristine && otpf.otp.errors}" />
                              <div *ngIf="!otpf.otp.pristine && otpf.otp.errors" class="invalid-feedback">
                                <div *ngIf="otpf.otp.errors.required">
                                  OTP is Required
                                </div>
                                <div *ngIf="otpf.otp.errors.maxlength">
                                  OTP Can only hve 6 digits
                                </div>
                                <div *ngIf="otpf.otp.errors.minlength">
                                  OTP must have 6 digits
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-2">

                          </div>
                          
                        </div>
                        <button class="btn btnType2 btn-block my-4" id="otpButton" (click)="OTPSubmit()">
                          Submit
                        </button>
                      <p id = "otpResendTimer" >{{timeLeft}} Seconds Left....</p>
                         <a [ngClass]="{'md-btn md-btn-success': !isOTPButtonDisabled,
      'md-btn disabled': isOTPButtonDisabled }" >
        <p id = "otpResendButton" (click)="otpResendClicked()"><b class="hover">Resend OTP</b></p>
      </a>
      <p id = "otpSentSuccess">OTP Sent!</p> 
                      </form>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>