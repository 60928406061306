import { Component, OnInit } from "@angular/core";
import {
    FormBuilder,
    FormGroup,
    FormArray,
    FormControl,
    Validators,
    ValidatorFn,
} from "@angular/forms";
import { Options } from "ng5-slider";
import { MediaService } from "../../../shared/media.service";
import { ToastrManager } from "ng6-toastr-notifications";
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { ApiService } from "../../../shared/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { NgSelectModule, NgOption } from "@ng-select/ng-select";

@Component({
    selector: "app-create-teacher-liveclass",
    templateUrl: "./create-liveclass.component.html",
    styleUrls: ["./create-liveclass.component.scss"],
})
export class CreateLiveclassComponent implements OnInit {
    selectedCityId: number;
    videosList = [];
    videos: any = [];
    allfiles: any = [];
    nameList: any = [];
    createForm: FormGroup;
    submitted = false;
    selectedFileName: string = "Choose File";
    uploaVideoName: string = "Choose File";
    selectedRouteId: any;
    selectedRouteValue: any;
    videosListget: any;
    meetCategory: any = [];
    selectedValue = "";
    formData;
    categoryselectedValue = "";
    boardselectedValue = "";
    languageselectedValue = "";
    gradeselectedValue = "";
    subjectselectedValue = "";
    moduleselectedValue = "";
    templateselectedValue = "";
    materialidselectedValue = "";
    topicselectedValue = "";
    alltag: any = [];
    pricingTemplate = {};
    public price: Number;
    public getDatas: any;
    public userId: any = "";
    securityArray: any = [];
    public groupName: any = "";
    public meetingCategory = "Select category";
    public meetingType = "Select Session Type";
    public tagtype = "Select Tag";
    public editData: any = [];
    public paramData: any = [];
    public isEnterprise = "";
    public thumbnailFile: any = [];
    public thumbnailPlace: any = "";
    public isTeacher: boolean = false;
    public teacherID: any = "";
    public studentID: any = "";
    public coursecategorylist: any = [];
    public modulelist: any = [];
    public templatelist: any = [];
    public courseboardlist: any = [];
    public coursesubjectlist: any = [];
    public languageapi: any = [];
    public gradeapi: any = [];
    public topicapi: any = [];
    public selecttag: any = [];
    public partnerList: any = [];
    public isPartnerUser: any = [];
    public isEdit: Boolean = false;
    public chapterID: Boolean = true;
    public min: any = new Date();
    public isfromcoursedetails: Boolean = false;
    public isTemplate: Boolean = true;
    public canPayInPerson: Boolean = true;
    public hideClassPrice: Boolean = true;

    selectedcategorylist = [];
    selectedgradelist = [];
    selectedsubjectlist = [];
    selectedtopiclist = [];
    constructor(
        private activatedRoute: ActivatedRoute,
        public toastr: ToastrManager,
        private SpinnerService: NgxSpinnerService,
        private rest: ApiService,
        private location: Location,
        private api: MediaService,
        private formBuilder: FormBuilder,
        private router: Router
    ) {}

    ngOnInit() {
        // this.getDatas();
        
        window.scrollTo(0, 0);
        this.tagfield();
        this.selectedRouteId = localStorage.getItem("collectionid");
        this.selectedRouteValue = localStorage.getItem("collectionname");
        this.userId = localStorage.getItem("userId");
        const webToken = localStorage.getItem("webtoken");
        this.isPartnerUser =
            localStorage.getItem("isPartner") == "true" ? true : false;
        var btn = document.getElementById(
            "addMaterialButton"
        ) as HTMLButtonElement;
        btn.disabled = false;
        //  this.getDatas =  window.history.state
        //  console.log( this.getDatas ,'window.history.state');

        this.getcoursecategory();
        this.getboardfield();
        //  this.getsubjectfield();
        this.getlanguage();
        // this.getPartner();
        this.isTeacher =
            localStorage.getItem("profileType").toLowerCase() == "teacher";
        this.createForm = this.formBuilder.group({
            id: null,
            className: ["", Validators.required],
            description: ["", Validators.required],
            courseCategory: ["", Validators.required],
            courseModule: ["", Validators.required],
            courseTemplate: ["", Validators.required],
            courseTopic: ["", Validators.required],
            courseLanguage: ["", Validators.required],
            privacy: ["", Validators.required],
            level: ["", Validators.required],
            // thumbnail: ["",Validators.required],
            price: ["", Validators.required],
            totalClassTime: ["", Validators.required],
            startDateTime: ["", Validators.required],
            upload_name: [null],
           
        });
        this.createForm.patchValue({
            price: 0
        });
        this.price = 0;
        if (this.isTeacher) {
            this.rest.userName_DisplayTeacher(webToken).subscribe((result) => {
                this.groupName =
                    result.data != undefined && result.data.groupName != "buddi"
                        ? result.data.groupName
                        : "";
                this.pricingTemplate = result.data != undefined && result.data.pricingTemplate.personalClassPrice != undefined 
                    ? result.data.pricingTemplate.personalClassPrice : {};
                localStorage.setItem(
                    "groupname",
                    result.data != undefined && result.data.groupName != "buddi"
                        ? result.data.groupName
                        : ""
                );
            });
        } else {
            this.rest.userName_DisplayStudent(webToken).subscribe((result) => {
                this.studentID = result.data._id;
            });
        }

        if (this.activatedRoute.snapshot.params.topicid !== undefined) {
            this.isfromcoursedetails = true;
            this.categoryselectedValue =
                this.activatedRoute.snapshot.params.categoryid;
            this.topicselectedValue =
                this.activatedRoute.snapshot.params.topicid;
            this.moduleselectedValue =
                this.activatedRoute.snapshot.params.moduleid;
            this.templateselectedValue =
                this.activatedRoute.snapshot.params.courseid;
        } else if (this.activatedRoute.snapshot.params.id !== undefined) {
            var req = {
                materialID: this.activatedRoute.snapshot.params.id,
            };
            this.api.getSmartMaterialByID(req, webToken).subscribe((result) => {
                if (result.status) {
                    this.editData = result.data;
                    this.price = this.editData.price;
                    // this.subjectselectedValue = this.editData.topicID.subjectID._id;
                    // this.gradeselectedValue = this.editData.topicID.gradeID._id;
                    this.thumbnailPlace = this.editData.thumbnail;
                    this.selectedFileName = this.editData.materialName;
                    this.categoryselectedValue = this.editData.categoryID._id;
                    this.languageselectedValue = this.editData.languageID._id;
                    // this.subjectselectedValue=this.editData.topicID.subjectID._id;
                    this.boardselectedValue = this.editData.boardID._id;
                    // this.gradeselectedValue=this.editData.topicID.gradeID._id;
                    this.topicselectedValue = this.editData.topicID._id;
                    this.moduleselectedValue = this.editData.moduleID._id;
                    this.templateselectedValue = this.editData.courseID._id;
                    this.materialidselectedValue =
                        this.editData.materialType._id;
                    this.editmodulelist();
                    this.edittemplatelist();
                    // this.edittopiclist();
                    // this.topiclist();
                    this.createForm.patchValue({
                        courseLanguage: this.editData.languageID._id,
                        courseCategory: this.editData.categoryID._id,
                        courseBoard: this.editData.boardID._id,
                        courseModule: this.editData.moduleID._id,
                        courseTemplate: this.editData.courseID._id,
                        // courseGrade: this.editData.topicID.gradeID._id,
                        // courseSubject: this.editData.topicID.subjectID._id,
                        courseTopic: this.editData.topicID._id,
                        privacy: this.editData.privacy,
                        description: this.editData.description,
                        thumbnail: this.editData.thumbnail,
                        coursematerialid: this.editData.materialType._id,
                        // upload_name:this.editData.videoPath,
                        fileupload: this.editData.materialName,
                        level: this.editData.level,
                        price: this.editData.price
                    });
                }
            });
        }
    }
    get f() {
        return this.createForm.controls;
    }
    selectChange(event: any) {
        this.selectedValue =
            event.target.options[event.target.selectedIndex].text;
    }

    yesRadioButtonChange(event: any) {
        var target = event.target;
        if (target.checked) {
            this.isTemplate = true;
        }
    }

    noRadioButtonChange(event: any) {
        var target = event.target;
        if (target.checked) {
            this.isTemplate = false;
        }
    }

    levelChange(event: any) {
        var selectedLevel = event.target.options[
            event.target.selectedIndex
        ].value.substring(
            event.target.options[event.target.selectedIndex].value.indexOf(
                ":"
            ) + 1
        );
        this.createForm.patchValue({price: this.pricingTemplate['level' + selectedLevel + 'Price'] ? this.pricingTemplate['level' + selectedLevel + 'Price'] : 0})
        this.price = this.pricingTemplate['level' + selectedLevel + 'Price'] ? this.pricingTemplate['level' + selectedLevel + 'Price'] : 0;
    }
    
    selectcategoryChange(event: any) {
        this.categoryselectedValue = event.target.options[
            event.target.selectedIndex
        ].value.substring(
            event.target.options[event.target.selectedIndex].value.indexOf(
                ":"
            ) + 1
        );
        if (this.modulelist.length > 0) {
            this.modulelist = [];
            this.createForm.patchValue({ courseModule: "" });
        }
        if (this.templatelist.length > 0) {
            this.templatelist = [];
            this.createForm.patchValue({ courseTemplate: "" });
        }
        if (this.topicapi.length > 0) {
            this.topicapi = [];
            this.createForm.patchValue({ courseTopic: "" });
        }
        console.log(this.coursecategorylist);
        this.coursecategorylist.forEach((element) => {
            if (
                element._id.replace(/\s/g, "") ==
                this.categoryselectedValue.replace(/\s/g, "")
            ) {
                this.modulelist = element.moduleList;
                console.log(this.modulelist);
                if (Object.keys(this.paramData).length == 0 && !this.isEdit) {
                    //  this.createForm.patchValue({ courseModule: element.moduleList[0]._id })
                }
            }
        });
    }

    isfromcoursedetailsgetmodule() {
        this.coursecategorylist.forEach((element) => {
            if (
                element._id.replace(/\s/g, "") ==
                this.categoryselectedValue.replace(/\s/g, "")
            ) {
                this.modulelist = element.moduleList;
                if (Object.keys(this.paramData).length == 0) {
                    this.createForm.patchValue({
                        courseModule: this.moduleselectedValue,
                    });
                }
            }
        });

        this.isfromcoursedetailsgettemplate();
    }

    isfromcoursedetailsgettemplate() {
        var req = {
            moduleID: this.moduleselectedValue.replace(/\s/g, ""),
        };
        this.rest.getClassCourseTemplatefromModule(req).subscribe(
            (result) => {
                this.templatelist = result.data.templateList;
                if (Object.keys(this.paramData).length == 0) {
                    this.createForm.patchValue({
                        courseTemplate: this.templateselectedValue,
                    });
                }
                this.isfromcoursedetailsgettopic();
            },
            (err) => {
                console.log(err);
            }
        );
    }

    isfromcoursedetailsgettopic() {
        console.log("templatelist", this.templatelist);
        this.templatelist.forEach((element) => {
            if (
                element._id.replace(/\s/g, "") ==
                this.templateselectedValue.replace(/\s/g, "")
            ) {
                this.topicapi = element.topicList;
                if (Object.keys(this.paramData).length == 0 && !this.isEdit) {
                    this.createForm.patchValue({
                        courseTopic: this.topicselectedValue,
                    });
                }
            }
        });
    }

    editmodulelist() {
        this.coursecategorylist.forEach((element) => {
            if (
                element._id.replace(/\s/g, "") ==
                this.categoryselectedValue.replace(/\s/g, "")
            ) {
                this.modulelist = element.moduleList;
                if (Object.keys(this.paramData).length == 0 && !this.isEdit) {
                    this.createForm.patchValue({
                        courseModule: this.moduleselectedValue,
                    });
                }
            }
        });
    }

    selectmoduleChange(event: any) {
        this.moduleselectedValue = event.target.options[
            event.target.selectedIndex
        ].value.substring(
            event.target.options[event.target.selectedIndex].value.indexOf(
                ":"
            ) + 1
        );
        if (this.templatelist.length > 0) {
            this.templatelist = [];
            this.createForm.patchValue({ courseTemplate: "" });
        }
        if (this.topicapi.length > 0) {
            this.topicapi = [];
            this.createForm.patchValue({ courseTopic: "" });
        }
        var req = {
            moduleID: this.moduleselectedValue.replace(/\s/g, ""),
        };
        console.log("modulechange", req);
        this.rest.getClassCourseTemplatefromModule(req).subscribe(
            (result) => {
                this.templatelist = result.data.templateList;
                console.log("template", this.templatelist);
                if (Object.keys(this.paramData).length == 0 && !this.isEdit) {
                    // this.createForm.patchValue({ courseCategory: "" })
                }
            },
            (err) => {
                console.log(err);
            }
        );
    }

    edittemplatelist() {
        var req = {
            moduleID: this.moduleselectedValue.replace(/\s/g, ""),
        };
        this.rest.getClassCourseTemplatefromModule(req).subscribe(
            (result) => {
                this.templatelist = result.data.templateList;
                console.log(result.data.templateList);
                if (Object.keys(this.paramData).length == 0 && !this.isEdit) {
                    this.createForm.patchValue({
                        courseTemplate: this.templateselectedValue,
                    });
                }
                this.edittopiclist();
            },
            (err) => {
                console.log(err);
            }
        );
    }

    selecttemplateChange(event: any) {
        this.templateselectedValue = event.target.options[
            event.target.selectedIndex
        ].value.substring(
            event.target.options[event.target.selectedIndex].value.indexOf(
                ":"
            ) + 1
        );
        if (this.topicapi.length > 0) {
            this.topicapi = [];
            this.createForm.patchValue({ courseTopic: "" });
        }
        this.templatelist.forEach((element) => {
            if (
                element._id.replace(/\s/g, "") ==
                this.templateselectedValue.replace(/\s/g, "")
            ) {
                this.topicapi = element.topicList;
                console.log("topic", this.topicapi);
                if (Object.keys(this.paramData).length == 0 && !this.isEdit) {
                    //  this.createForm.patchValue({ courseModule: element.moduleList[0]._id })
                }
            }
        });
    }

    edittopiclist() {
        // if(this.topicapi.length > 0) {
        //   this.topicapi = [];
        //   this.createForm.patchValue({ courseTopic: "" })
        // }
        this.templatelist.forEach((element) => {
            if (
                element._id.replace(/\s/g, "") ==
                this.templateselectedValue.replace(/\s/g, "")
            ) {
                this.topicapi = element.topicList;
                if (Object.keys(this.paramData).length == 0 && !this.isEdit) {
                    this.createForm.patchValue({
                        courseTopic: this.topicselectedValue,
                    });
                }
            }
        });
    }

    selectlanguageChange(event: any) {
        this.languageselectedValue = event.target.options[
            event.target.selectedIndex
        ].value.substring(
            event.target.options[event.target.selectedIndex].value.indexOf(
                ":"
            ) + 1
        );
    }

    selecttopicChange(event: any) {
        this.topicselectedValue = event.target.options[
            event.target.selectedIndex
        ].value.substring(
            event.target.options[event.target.selectedIndex].value.indexOf(
                ":"
            ) + 1
        );
    }

    goBack() {
        this.location.back();
    }

    getcoursecategory() {
        this.rest.getClassCourseCategory().subscribe(
            (result) => {
                this.coursecategorylist = result.data;
                if (this.isfromcoursedetails == true) {
                    if (
                        Object.keys(this.paramData).length == 0 &&
                        !this.isEdit
                    ) {
                        this.createForm.patchValue({
                            courseCategory: this.categoryselectedValue,
                        });
                    }
                    this.isfromcoursedetailsgetmodule();
                } else {
                    if (
                        Object.keys(this.paramData).length == 0 &&
                        !this.isEdit
                    ) {
                        // this.createForm.patchValue({ courseCategory: "" })
                    }
                }
            },
            (err) => {
                console.log(err);
            }
        );
    }

    getboardfield() {
        this.rest.boardfield().subscribe(
            (result) => {
                this.courseboardlist = result.data;
                if (Object.keys(this.paramData).length == 0 && !this.isEdit) {
                    //  this.createForm.patchValue({ courseBoard: this.courseboardlist[0]._id })
                }
                console.log(this.courseboardlist);
            },
            (err) => {
                console.log(err);
            }
        );
    }

    getlanguage() {
        this.rest.languagefield().subscribe(
            (result) => {
                this.languageapi = result.data;
                if (Object.keys(this.paramData).length == 0 && !this.isEdit) {
                    //   this.createForm.patchValue({ courseLanguage: this.languageapi[0]._id })
                }
                console.log(this.languageapi);
            },
            (err) => {
                console.log(err);
            }
        );
    }

    topiclist() {
        var req = {
            subjectID: this.subjectselectedValue.replace(/\s/g, ""),
            gradeID: this.gradeselectedValue.replace(/\s/g, ""),
        };
        const webToken = localStorage.getItem("webtoken");
        console.log("topicreq", req);
        this.rest.topiclist(req, webToken).subscribe(
            (result) => {
                this.topicapi = result.data;
                if (Object.keys(this.paramData).length == 0 && !this.isEdit) {
                    // this.createForm.patchValue({ courseTopic: this.topicapi[0]._id })
                }
            },
            (err) => {
                console.log(err);
            }
        );
    }

    tagfield() {
        this.rest.tagfield().subscribe(
            (result) => {
                this.alltag = result;
                console.log(this.alltag);
            },
            (err) => {
                console.log(err);
            }
        );
    }
    getSecurity() {
        this.rest.security().subscribe(
            (result) => {
                this.securityArray = result;
                console.log(this.securityArray);
            },
            (err) => {
                console.log(err);
            }
        );
    }

    //  getVideoList() {
    //    this.api.getVideoList().subscribe((response) => {
    //      this.selectedRouteId = localStorage.getItem('collectionid');
    //      this.selectedRouteValue = localStorage.getItem('collectionname');
    //      if(response.status){
    //        this.videosListget = response.data.reverse();
    //        console.log(this.videosListget);
    //      }
    //    });
    //  }

    //  deleteItem(item: any) {
    //    const index = this.videos.indexOf(item);
    //    this.nameList.splice(index,1);
    //    if(this.nameList.join(',').length > 29){
    //      this.uploaVideoName =  this.nameList.join(',').substring(0,30)+'...';
    //    }else{
    //      this.uploaVideoName =  this.nameList.join(',');
    //    }
    //    if(this.uploaVideoName =='...'  || this.nameList.length ==0){
    //      this.uploaVideoName = 'Choose File';
    //    }
    //    this.videos.splice(index,1);
    //    this.allfiles.splice(index,1);
    //  }
    onSelectFile(event) {
        this.thumbnailFile = [];
        if (event.target.files && event.target.files[0]) {
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]); // read file as data url
            this.thumbnailFile.push(event.target.files[0]);
            this.createForm.patchValue({
                thumbnail: this.thumbnailFile[0].name,
            });
            this.thumbnailPlace = this.thumbnailFile[0].name;
            reader.onload = (event) => {
                // called once readAsDataURL is completed
                // this.url = event.target.result;
            };
        }
    }

    resetData() {
        this.createForm.reset();
        this.videos = [];
        this.allfiles = [];
        this.videosList = [];
        this.submitted = false;
        this.nameList = [];
        this.uploaVideoName = "Choose File";
    }

    fileChange(event) {
        let fileList: FileList = event.target.files;
        // const file = event.target.files[0];
        // console.log(fileList);
        console.log(fileList[0]);
        this.createForm.patchValue({ upload_name: fileList[0] });
        this.selectedFileName = fileList[0].name;
        this.createForm.patchValue({ fileupload: this.selectedFileName });
        // this.testForm.get('fileupload').setValue(file);
    }

    onSubmit(status) {
        var btn = document.getElementById(
            "addMaterialButton"
        ) as HTMLButtonElement;
        btn.disabled = true;
        this.submitted = true;
        let data = this.createForm.value;
        if (!this.createForm.valid) {
            const invalid = [];
            const controls = this.createForm.controls;
            for (const name in controls) {
                if ((controls[name].invalid && name == "startDateTime" && !this.isTemplate) || (controls[name].invalid && name != "startDateTime")) {
                    console.log(name);
                    btn.disabled = false;
                    if (name == "description") {
                        this.toastr.errorToastr("Description Required");
                        btn.disabled = false;
                        return;
                    }
                    if (name == "className") {
                        this.toastr.errorToastr("Name Required");
                        btn.disabled = false;
                        return;
                    }
                    else if (name == "courseLanguage") {
                        this.toastr.errorToastr("Language Required");
                        btn.disabled = false;
                        return;
                    } else if (name == "courseTopic") {
                        this.toastr.errorToastr("Topic Required");
                        btn.disabled = false;
                        return;
                    } else if (name == "courseCategory") {
                        this.toastr.errorToastr("Category Required");
                        btn.disabled = false;
                        return;
                    } else if (name == "courseModule") {
                        this.toastr.errorToastr("Module Required");
                        btn.disabled = false;
                        return;
                    } else if (name == "courseTemplate") {
                        this.toastr.errorToastr("Template Required");
                        btn.disabled = false;
                        return;
                    } if (name == "privacy") {
                        this.toastr.errorToastr("Privacy Required");
                        btn.disabled = false;
                        return;
                    } else if (name == "level") {
                        this.toastr.errorToastr("Level Required");
                        btn.disabled = false;
                        return;
                    }else if (name == "thumbnail") {
                        this.toastr.errorToastr("Thumbnail Required");
                        btn.disabled = false;
                        return;
                    } else if (name == "totalClassTime") {
                        this.toastr.errorToastr("Duration Required");
                        btn.disabled = false;
                        return;
                    } else if (name == "startDateTime" && !this.isTemplate) {
                        this.toastr.errorToastr("Start Date Required");
                        btn.disabled = false;
                        return;
                    } else if (name == "price" && !this.isTemplate) {
                        this.toastr.errorToastr("Price Required");
                        btn.disabled = false;
                        return;
                    } else {
                        this.toastr.errorToastr("Invalid Fields");
                        btn.disabled = false;
                        return;
                    }
                }
            }
        }

        this.formData = new FormData();
        if (this.activatedRoute.snapshot.params.id === undefined) {
            if (this.thumbnailFile.length != 0) {
                this.formData.append(
                    "thumbnail_files",
                    this.thumbnailFile[0],
                    this.thumbnailFile[0]["name"]
                );
            }
            this.formData.append("description", data.description);
            this.formData.append("className", data.className);
            this.formData.append(
                "categoryID",
                this.categoryselectedValue.replace(/\s/g, "")
            );
            this.formData.append(
                "languageID",
                this.languageselectedValue.replace(/\s/g, "")
            );
            this.formData.append(
                "topicID",
                this.topicselectedValue.replace(/\s/g, "")
            );
            this.formData.append(
                "moduleID",
                this.moduleselectedValue.replace(/\s/g, "")
            );
            this.formData.append(
                "templateID",
                this.templateselectedValue.replace(/\s/g, "")
            );
            this.formData.append("privacy", data.privacy);
            this.formData.append("level", data.level);
            this.formData.append("totalClassTime", data.totalClassTime);
            this.formData.append("price", data.price);
            if(!this.isTemplate){
                this.formData.append("startDateTime", new Date(data.startDateTime).toUTCString());
            }
            this.formData.append("isTemplate", this.isTemplate);
            for (var pair of this.formData.entries()) {
                console.log(pair[0] + ", " + pair[1]);
            }
            var req = null;
            console.log(this.thumbnailFile)
            if(this.thumbnailFile.length == 0){
              var object = {};
              this.formData.forEach((value, key) => object[key] = value);
              req = object;
            }
            else{
              req = this.formData;
              console.log("2")
            }
            console.log(req)
            this.SpinnerService.show();
            this.api
                .addLiveClass(req)
                .subscribe((response) => {
                    this.SpinnerService.hide();

                    console.log(response)
                    if (response.status) {
                        this.resetData();
                        this.router.navigate([ "/liveClassList"]);
                        this.toastr.infoToastr(response.message);
                    } else {
                        this.toastr.errorToastr(response.message);
                        btn.disabled = false;
                    }
                });
        } else {
            this.formData.append(
                "materialID",
                this.activatedRoute.snapshot.params.id
            );
            if (data.upload_name != null) {
                this.formData.append("material_files", data.upload_name);
                this.formData.append("materialName", data.upload_name.name);
            }
            if (this.thumbnailFile.length != 0) {
                this.formData.append(
                    "thumbnail_files",
                    this.thumbnailFile[0],
                    this.thumbnailFile[0]["name"]
                );
            }
            this.formData.append("description", data.description);
            this.formData.append(
                "categoryID",
                this.categoryselectedValue.replace(/\s/g, "")
            );
            this.formData.append(
                "languageID",
                this.languageselectedValue.replace(/\s/g, "")
            );
            //  this.formData.append("subjectID", this.subjectselectedValue.replace(/\s/g, ""));
            this.formData.append(
                "boardID",
                this.boardselectedValue.replace(/\s/g, "")
            );
            // this.formData.append("gradeID", this.gradeselectedValue.replace(/\s/g, ""));
            this.formData.append(
                "topicID",
                this.topicselectedValue.replace(/\s/g, "")
            );
            this.formData.append(
                "moduleID",
                this.moduleselectedValue.replace(/\s/g, "")
            );
            this.formData.append(
                "templateID",
                this.templateselectedValue.replace(/\s/g, "")
            );
            this.formData.append("privacy", data.privacy);
            this.formData.append("level", data.level);
            this.formData.append(
                "materialType",
                this.materialidselectedValue.replace(/\s/g, "")
            );
            for (var pair of this.formData.entries()) {
                console.log(pair[0] + ", " + pair[1]);
            }
            this.SpinnerService.show();
            this.api.EditSmartMaterial(this.formData).subscribe((response) => {
                this.SpinnerService.hide();
                if (response.status) {
                    //  this.router.navigate(['/success/Video']);
                    this.resetData();
                    this.toastr.infoToastr(response.message);
                    setTimeout(() => {
                        window.location.href = "/teacher/mydashboard";
                    }, 500);
                } else {
                    this.toastr.errorToastr(response.message);
                    btn.disabled = false;
                }
            });
        }
    }

    privacytype(e) {
        this.createForm.patchValue({ privacy: e.target.value });
    }
}
