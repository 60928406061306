import { Component, OnInit } from "@angular/core";
import {
    FormBuilder,
    FormGroup,
    FormArray,
    FormControl,
    Validators,
    ValidatorFn,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "../../../../shared/api.service";
import { ToastrManager } from "ng6-toastr-notifications";
import * as $ from "jquery";
import { Location } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";
@Component({
    selector: "app-feedback-teacher",
    templateUrl: "./feedback-teacher.component.html",
    styleUrls: ["./feedback-teacher.component.scss"],
})
export class FeedbackTeacherComponent implements OnInit {
    OneonOneForm: FormGroup;
    public feedbackData: any = [];
    public id: any;
    // public date: Date = new Date(Date.now());
    constructor(
        public SpinnerService: NgxSpinnerService,
        public toastr: ToastrManager,
        private userApi: ApiService,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit() {
        this.OneonOneForm = new FormGroup({
            section: new FormControl("Good", Validators.required),
            section1: new FormControl("Good", Validators.required),
            section2: new FormControl("Good", Validators.required),
            section3: new FormControl("Good", Validators.required),
        });
        const webtoken = localStorage.getItem("webtoken");
        this.id = this.activatedRoute.snapshot.params.id;
        console.log(this.id);
        if (!this.id) {
            this.feedbackform(webtoken);
        } else {
            this.liveClassFeedbackForm(webtoken);
        }
    }

    get f() {
        return this.OneonOneForm.controls;
    }
    feedbackform(webToken) {
        this.SpinnerService.show();

        this.userApi.getfeedbPendingTeacher(webToken).subscribe(
            (result) => {
                this.SpinnerService.hide();
                if (result.status && result.data.length != 0) {
                    this.feedbackData = result.data;
                    localStorage.setItem("isPendindTeacher", "true");
                    console.log(this.feedbackData, "this.feedbackData");
                } else {
                    localStorage.setItem("isPendindTeacher", "false");
                    window.location.href = "/teacher/mydashboard";
                }
            },
            (err) => {
                console.log(err);
            }
        );
    }
    liveClassFeedbackForm(webToken) {
        this.SpinnerService.show();
        var reqData = {};
        reqData["classID"] = this.id;
        this.userApi
            .getFeedBackDetailsForLiveClass(webToken, reqData)
            .subscribe(
                (result) => {
                    this.SpinnerService.hide();
                    console.log(result);
                    if (result.status && result.data.length != 0) {
                        this.feedbackData = result.data;
                        console.log(this.feedbackData, "this.feedbackData");
                    }
                    // else{
                    // 	window.location.href = '/teacher/mydashboard';
                    // }
                },
                (err) => {
                    console.log(err);
                }
            );
    }
    onSubmit() {
        const webToken = localStorage.getItem("webtoken");

        var data = {};
        if (this.id) {
            data = {
                classId: this.feedbackData._id,
                studentPunctuality: this.OneonOneForm.value.section,
                studentAttentiveness: this.OneonOneForm.value.section1,
                studentAns: this.OneonOneForm.value.section2,
                observedClass: this.OneonOneForm.value.section3,
            };
        } else {
            data = {
                classId: this.feedbackData._id,
                studentPunctuality: this.OneonOneForm.value.section,
                studentAttentiveness: this.OneonOneForm.value.section1,
                studentAns: this.OneonOneForm.value.section2,
                observedClass: this.OneonOneForm.value.section3,
                classRequestType: 'teacherPersonalClassRequests'
            };
        }
        this.SpinnerService.show();
        this.userApi.addFeedBackTeacher(data, webToken).subscribe((result) => {
            this.SpinnerService.hide();
            if (result.status) {
                this.toastr.successToastr(result.message);
                window.location.href = "/sidenav";
            } else {
                this.toastr.errorToastr(result.message);
            }
        });
    }
}
