<div *ngIf="!isStudent">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>
<div *ngIf="isStudent">
    <app-header-style-one></app-header-style-one>
</div>

<div class="container">
    <div class="flexButton">
        <h5 style="color: #000000;">
            Subscription Details
        </h5>
    <div class="btn-group ml-3"  >
       <button type="button" routerLink="/upgrade"><i class="bi bi-upload"></i>Upgrade</button> 
       <button type="button"  routerLink="/sidenav"><i class="fas fa-long-arrow-alt-left mr-1"></i>Back</button>
    </div>
    </div>
</div>
<div class="container mb-3">
        <div class="orders-table table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Institutions</th>
                        <th>Institution Type</th>
                        <th>Package Expiry Date</th>
                        <th>Subscription Type</th>
                        <th>Paid Amount</th>
                    </tr>
                </thead>
                <tbody>
                 <tr>
                    <td>1</td>
                    <td>{{institutionDetails.institutionName}}</td>
                    <td>{{institutionDetails.institutionType}}</td>
                    <td>{{institutionDetails.nextBillingDate}}</td>
                    <td>{{institutionDetails.subscriptionType.institutionTypeName}}</td>
                    <td>{{institutionDetails.subscriptionType.price}}</td>
                </tr>
                </tbody>
            </table>
        </div>
</div>

