

<!-- <app-header-style-one></app-header-style-one>

<app-hometwo-main-banner></app-hometwo-main-banner> -->
<section class="theModal">
  <div class="container">
    <div class="banner-wrapper-content1"  >
      <h1>buddies <span>Ecosystem</span></h1>
      <p>Large Reach - Low Cost - High Volume - Wide Network</p>
      <h3>Join and get benefited in our ecosysem</h3>
    </div>
    <div id="any" class="row">
        <div class="col-md-12"> 
            <div class="main-timeline">
              <div class="row">
                <div class="col-md-3 col-sm-4">
                    <div class="timeline">
                        <div  class="timeline-content">
                            <div class="timeline-icon">
                                <i class="fas fa-user-graduate"></i>
                            </div>
                            <div class="timeline-year">
                                <span>01</span>
                            </div>
                            <div class="inner-content">
                                <h3 class="title">Learners</h3>
                                <p class="description">
                                    Join. Learn. Certified.
                                </p>
                            </div>
                          </div>
                    </div>
                </div>
                <div class="col-md-3 col-sm-4">
                    <div class="timeline blue">
                        <div  class="timeline-content">
                            <div class="timeline-icon">
                                <i class="fas fa-chalkboard-teacher"></i>
                            </div>
                            <div class="timeline-year">
                                <span>02</span>
                            </div>
                            <div class="inner-content">
                                <h3 class="title">Tutors</h3>
                                <p class="description">
                                    Register. Contribute. Evolve.
                                </p>
                            </div>
                          </div>
                    </div>
                </div>
                <div class="col-md-3 col-sm-4">
                    <div class="timeline green">
                        <div  class="timeline-content">
                            <div class="timeline-icon">
                                <i class="fas fa-school"></i>
                            </div>
                            <div class="timeline-year">
                                <span>03</span>
                            </div>
                            <div class="inner-content">
                                <h3 class="title">Institutions</h3>
                                <p class="description">
                                    Onboard. GoLive. Expand.

                                </p>
                            </div>
                          </div>
                    </div>
                </div>
                <div class="col-md-3 col-sm-4">
                    <div class="timeline pink">
                        <div  class="timeline-content">
                            <div class="timeline-icon">
                                <i class="fas fa-university"></i>
                            </div>
                            <div class="timeline-year">
                                <span>04</span>
                            </div>
                            <div class="inner-content">
                                <h3 class="title">Enterprises</h3>
                                <p class="description">
                                    Partner. Upskill. Retain.
                                </p>
                            </div>
                          </div>
                    </div>
                </div>
              </div>
            </div>
        </div>
    </div>
</div>
</section>
<!-- <div class="container  mt-3">
  <h3 (click)="redirect()" style="color:var(--mainColor);cursor: pointer" class="col-lg-12" >
      Video Classes  <span class="more-text" *ngIf="videoList.length!=0">more</span>
    </h3>
    <div class="container-fluid row" style="cursor: pointer" *ngIf="videoList.length !=0">
      <div class="col-lg-3 col-md-6" *ngFor="let slide of videoList | slice:0:4;">
        <div class="single-courses-box without-box-shadow mb-30">
          <div class="courses-image">
            <img style="width:100%" (click)="videodetails(slide)" style="width: 250px;height: 175px;" [src]="slide.thumbnail ? slide.thumbnail : 'assets/img/videocat-thumb-1.jpg'" alt="" width="100%">
          </div>
          <div class="courses-content">
            <div class="course-author d-flex align-items-center" *ngIf="slide.teacherID !=undefined">
              <img
                [src]="slide.teacherID!=undefined ? 'assets/img/user1.jpg' :'assets/img/user1.jpg'"
                class="rounded-circle mr-2" alt="image">
              <span *ngIf="slide.teacherID!=undefined">
                {{slide.teacherID.profileName}}
              </span>
            </div>
            <h3 class="text-center">
              <a (click)="videodetails(slide)" class="d-inline-block">
                <span class="text-break" *ngIf="slide.topicID!=undefined">{{slide.topicID.moduleName}}</span>
                 <span class="text-break" *ngIf="slide.topicID!=undefined">
                  <br>{{slide.topicID.topicName}}</span>
              </a>
            </h3>
          </div>
          <list5 [slide]="slide"></list5> 
          <div *ngIf="slide.categoryID!=undefined ">
            {{slide.categoryID ? slide.categoryID.categoryName : '' }}, {{slide.topicID.gradeID ? slide.topicID.gradeID.gradeName : '' }},
            {{slide.boardID ? slide.boardID.boardName : '' }}
          </div>
          <div class="courses-box-footer" *ngIf="slide.topicID.subjectID!=undefined ">
            {{slide.topicID.subjectID ? slide.topicID.subjectID.subjectName : '' }},
            {{slide.languageID ? slide.languageID.languageName : '' }}
          </div>
        </div>
      </div>
    </div>
    <div style="color:var(--mainColor)" class="col-lg-12" *ngIf="(videoList.length ==0)">
      No Video(s) Available.
    </div>
  
  
    </div> -->
  
    <!-- Smart Material -->
   <!--  <div class="container">
  <p class="my-0"><img src="assets/img/ourTransaction.png" alt="" class="d-block mx-auto img-fluid" /></p>
  </div> -->
    <!-- <div class="container  mt-3">
  
      <h3 (click)="redirects()" style="color:var(--mainColor);cursor: pointer" class="col-lg-12" >
          Study Material  <span class="more-text" *ngIf="smartList.length!=0">more</span>
        </h3>
        <div class="container-fluid row" style="cursor: pointer" *ngIf="smartList.length !=0">
          <div class="col-lg-3 col-md-6" *ngFor="let slide of smartList | slice:0:4;">
            <div class="single-courses-box without-box-shadow mb-30">
              <div class="courses-image">
                <img style="width:100%" (click)="studydetails(slide)" style="width: 250px;height: 175px;" [src]="slide.thumbnail ? slide.thumbnail : 'assets/img/videocat-thumb-1.jpg'" alt="" width="100%">
              </div>
              <div class="courses-content">
                <div class="course-author d-flex align-items-center" *ngIf="slide.teacherID !=undefined">
                  <img
                    [src]="slide.teacherID!=undefined ? 'assets/img/user1.jpg' :'assets/img/user1.jpg'"
                    class="rounded-circle mr-2" alt="image">
                  <span *ngIf="slide.teacherID!=undefined">
                    {{slide.teacherID.profileName}}
                  </span>
                </div>
                <h3 class="text-center">
                  <a (click)="videodetails(slide)" class="d-inline-block">
                    <span class="text-break" *ngIf="slide.moduleID!=undefined">{{slide.moduleID.moduleName}}</span>
                     <span class="text-break" *ngIf="slide.topicID!=undefined">
                      <br>{{slide.topicID.topicName}}</span>
                  </a>
                </h3>
              </div>
               <list5 [slide]="slide"></list5>  
              <div *ngIf="slide.categoryID!=undefined ">
                {{slide.categoryID ? slide.categoryID.categoryName : '' }},
                {{slide.boardID ? slide.boardID.boardName : '' }}
              </div>
              <div class="courses-box-footer" *ngIf="slide.topicID.subjectID!=undefined ">
                {{slide.topicID.subjectID ? slide.topicID.subjectID.subjectName : '' }},
                {{slide.languageID ? slide.languageID.languageName : '' }}
              </div>
            </div>
          </div>
        </div>
        <div style="color:var(--mainColor)" class="col-lg-12" *ngIf="(smartList.length ==0)">
          No Study Material Available.
        </div>
      
      
        </div> -->