import { Component, OnInit } from '@angular/core';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-cbse',
  templateUrl: './cbse-online-tution-content.component.html',
  styleUrls: ['./cbse-online-tution-content.component.scss']
})
export class CBSEComponent implements OnInit {
  public isstudent = false;
  public isteacher = false;
  constructor(private meta: Meta) {
    this.meta.addTags([
      {name: 'description', Content: 'Welcome to Buddi Life! Explore our CBSE Online Tuition services led by an expert panel of teachers. You can attend the classes right from the comforts of your home'},
      {name: 'title' , content: 'CBSE Online Tuition | From Buddi Life | Best E-learning Platform'},
      {name: 'keywords' , content:'Cbse Online Tuition'}
    ]);
  }
 

  ngOnInit(): void {
    const userType = localStorage.getItem('userType');      
    if(userType.toLowerCase()=="teacher") {
      this.isteacher = true;
    }
    else {
      this.isstudent = true;
    }
  }

}
