import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../shared/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ToastrManager, Toastr } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-school-registration',
  templateUrl: './school-registration.component.html',
  styleUrls: ['./school-registration.component.scss']
})
export class SchoolRegistrationComponent implements OnInit {
  schoolregisterform: FormGroup;
  submitted = false;  
  formData;  

  constructor(private formBuilder: FormBuilder,private router: Router ,private rest: ApiService, private toastr: ToastrManager) { }

  ngOnInit(): void {

    this.schoolregisterform = this.formBuilder.group({
      schoolname: ['', Validators.required],
      adminname: ['', Validators.required],
      phonenumber: ['', Validators.required],
      email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      location: ['', Validators.required]
  });


  }

   // convenience getter for easy access to form fields
   get f() { return this.schoolregisterform.controls; }     

  onSubmit(FormGroup) {
    this.submitted = true;

    if (this.schoolregisterform.invalid) {
      console.log("Invalid Fields");
      this.toastr.errorToastr("Invalid Fields");
      const data = this.schoolregisterform.value; 
      //To check the invalid field
      // const invalid = [];
      // const controls = this.schoolregisterform.controls;
      // for (const name in controls) {
      //     if (controls[name].invalid) {
      //         invalid.push(name);
      //         alert(invalid);
      //     }
      // }
        return;
    }         
    
    const data = this.schoolregisterform.value;             
    var element = <HTMLInputElement> document.getElementById("checkid");
    var isChecked = element.checked;
    if(data.schoolname!="" && data.adminname!="" && data.email!="" && data.phonenumber!="" && data.location!="" ) {
      if(isChecked == true) {
        // this.formData = new FormData();
        // this.formData.append("adminEmail", data.email);
        // this.formData.append("schoolName", data.schoolname);
        // this.formData.append("adminName", data.adminname);
        // this.formData.append("adminPhoneNumber", data.phonenumber);
        // this.formData.append("numberOfStudents", data.studentnumber);
        // this.formData.append("location", data.location);

        var userData = {};
        userData["adminEmail"] = data.email;
        userData["schoolName"] = data.schoolname;
        userData["adminName"] = data.adminname;
        userData["adminPhoneNumber"] ="+91" + data.phonenumber;
        userData["location"] = data.location;
        this.rest.schoolregistration(userData).subscribe((result) => {
          console.log(result);
          if(result.status) {
            this.router.navigate(['/thanks-school-registration']);
          } 
          else {            
            this.toastr.errorToastr(result.message); 
         }
        }, (err) => {
        console.log(err);
        this.toastr.errorToastr(err); 
        });   
    
    }
    else {
      this.toastr.errorToastr("Please check Terms and Conditions");     
    }
    }
    else {
      this.toastr.errorToastr("Invalid Fields");
    }
 
  }

}
