import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'list5',
  templateUrl: './list5.component.html',
  styleUrls: ['./list5.component.scss']
})
export class List5Component implements OnInit {
  @Input() slide:any = {};
  @Input() hideData:any = false;
  constructor() { }

  ngOnInit(): void {
  }

}
