import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
	@Input() limit:number = 0;
	@Input() totalLength:any = 0;
  @Input() currentIndex:number = 0;
  constructor() { }

  ngOnInit(): void {
  	this.totalLength = new Array(this.totalLength);
  }
  sendPageNumber(index){
  	console.log(index);
  }
}
