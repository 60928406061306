import { Component, OnInit, Inject, ElementRef, ViewChild, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Options } from 'ng5-slider';
import { ApiService } from '../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from '../../../../environments/environment';
import { MediaService } from '../../../shared/media.service';
import { DatePipe } from '@angular/common';
const datepipe: DatePipe = new DatePipe('en-US')

@Component({
  selector: 'app-liveclass-listing',
  templateUrl: './liveclass-listing.component.html',
  styleUrls: ['./liveclass-listing.component.scss']
})
export class LiveclassListingComponent implements OnInit {
  public meetingsData: any = [];
  public meetingsDataRecord: any = [];
  public meetingsDataListen: any = [];
  public meetingsDataAll: any = [];
  public allmeeting: any = [];
  public inviteUrl = "";
  public frontwebsite = environment.frontUrl;
  public lessonData: any = [];
  public myId: any = ''
  public isLogin: any = 'false';
  public isCancel: any = 'false';
  // public isStudent : any 
  public videoList: any = [];
  public videoListPrivate: any = [];
  public videoListGroup: any = [];
  public teacherVideouploadlist: any = [];
  public videoYoutubePublic: any = [];
  public publicsessionData: any = [];
  public publicsessionDataRecord: any = [];
  public requestedDataList: any = [];
  public requestedDataAccpet: any = [];
  public requestedDataAccpetRecord: any = [];
  public teacherPayoutDetails: any = [];
  public mydashboardData: any = [];
  public isStudent: boolean = false;
  public isTeacher: boolean = false;
  public currentPageNumber : number = 1;
  public totalVideoCount: number;
  public Price: any = [];
  public totalPageCount: number;
  public currentVideoCount: number;
  public categoryID = null;
  public moduleID = null;
  public courseID = null;
  public topicID = null;
  term: string;
  public webinarData: any = [];
  isDesc: any = 1;
  column: string = 'remainingClass';
  p: number = 1;
  count: number = 4;
  teacherDetails = {};
  marked=false;
  currentSearch = null;
  public walletBalance = 0;
  // public videoList:any=[];

  constructor(private api: MediaService, private SpinnerService: NgxSpinnerService, private activatedRoute: ActivatedRoute, public location: Location, public toastr: ToastrManager, private formBuilder: FormBuilder, private rest: ApiService, private router: Router) { }

  ngOnInit() {
    const webToken = localStorage.getItem("webtoken");
    this.rest.getWalletBalance(webToken).subscribe((response) => {
      console.log("material", response)
      this.walletBalance = response.data
    })
    this.isLogin = localStorage.getItem('isLoggedin');
    console.log(this.isLogin)
    this.isStudent = localStorage.getItem('profileType') ? localStorage.getItem('profileType').toLowerCase() == 'student' : false;
    this.isTeacher = localStorage.getItem('profileType') ? localStorage.getItem('profileType').toLowerCase() == 'teacher' : false;
    this.categoryID = this.activatedRoute.snapshot.params['categoryid'];
    this.moduleID = this.activatedRoute.snapshot.params['moduleid'];
    this.courseID = this.activatedRoute.snapshot.params['courseid'];
    this.topicID = this.activatedRoute.snapshot.params['topicid'];
    console.log(this.categoryID, this.moduleID, this.courseID, this.topicID)
    if (this.isLogin == 'true' && this.isTeacher) {
      this.getLiveclassList();
    }
    else if (this.isLogin == 'true' && this.isStudent) {
      this.getLiveclassWithoutlogin();
    }

    else {
      this.getLiveclassWithoutlogin();
    }
  }

  toggleVisibility(e){
    this.marked= e.target.checked; 
    if (this.isLogin == 'true' && this.isTeacher) {
      this.getLiveclassList(null, this.currentSearch, this.marked);
    }
    else {
      this.getLiveclassWithoutlogin(null, this.currentSearch, this.marked);
    }
  }


  getLiveclassList(filter = null, search = null, institution = null) {

    const webToken = localStorage.getItem('webtoken');

    this.SpinnerService.show();
    var request = {};
    if(this.topicID){
      request = {
        topicID: this.topicID,
        moduleID: this.moduleID, 
        courseID: this.courseID,
        categoryID: this.categoryID
      }
    }
    if(filter && filter != 'all'){
      request['filter'] = filter;
    }
    if(search){
      request['search'] = search;
    }
    if(institution)
      request["fetchInstitution"] = true
    request['limit'] = environment.listing__itemsPerPage;
    request['page'] = this.currentPageNumber;
    this.rest.getLiveclassmeetingTeacher(webToken, request).subscribe((response) => {
      console.log('response', response)
      
      if (response.status && response.data != null && response.data.length != 0) {
        this.videoList = response.data.classWebinarrecord;
        console.log(this.videoList, 'this.webinarData')
        this.Price = response.data.price;
        console.log(this.Price, 'this.Price')
        this.totalVideoCount = response.data.totalCount;
        this.totalPageCount = Math.ceil(this.totalVideoCount / environment.listing__itemsPerPage)
        this.currentVideoCount = ((this.currentPageNumber - 1) * environment.listing__itemsPerPage)
        this.teacherDetails = response.data.teacherDetails;
        for(var roundTable of this.videoList){
          roundTable.startDateTime = datepipe.transform(roundTable.startDateTime, 'MMM d, y, h:mm a')
          console.log('roundTable.startDateTime',roundTable.startDateTime);
          if(!roundTable.thumbnail){
            roundTable.thumbnail = 'assets/img/courses/courses1.jpg'
          }
        }
      } else {
        this.mydashboardData = [];
      }
      this.SpinnerService.hide()
    }, (err) => {
      console.log(err);
    });
  }

  getLiveclassWithoutlogin(filter = null, search = null, institution = null) {
    console.log("getLiveclassWithoutlogin")
    this.isLogin = localStorage.getItem('isLoggedin');
    this.SpinnerService.show();
    var request = {};
    if(this.topicID){
      request = {
        topicID: this.topicID,
        moduleID: this.moduleID, 
        courseID: this.courseID,
        categoryID: this.categoryID
      }
    }
    if(filter && filter != 'all'){
      request['filter'] = filter;
    }
    if(search){
      request['search'] = search;
    }
    if(institution)
      request["fetchInstitution"] = true
    request['limit'] = environment.listing__itemsPerPage;
    request['page'] = this.currentPageNumber;
    const webToken = localStorage.getItem('webtoken');
    console.log("Webtoken", webToken)
    console.log("isLogin", this.isLogin)
    this.rest.getLiveclassTeacherWithoutLogin( this.isLogin ? webToken : null, request).subscribe((response) => {
      console.log('response', response)
      
      if (response.status && response.data != null) {
        this.videoList = response.data.data;
        console.log(this.videoList, 'this.webinarData')
        this.totalVideoCount = response.data.totalCount;
        this.totalPageCount = Math.ceil(this.totalVideoCount / environment.listing__itemsPerPage)
        this.currentVideoCount = ((this.currentPageNumber - 1) * environment.listing__itemsPerPage)
        // console.log(this.videoList)
        for(var roundTable of this.videoList){
          roundTable.startDateTime = datepipe.transform(roundTable.startDateTime, 'MMM d, y, h:mm a')
          if(!roundTable.thumbnail){
            roundTable.thumbnail = 'assets/img/courses/courses1.jpg'
          }
        }
      } else {
        this.mydashboardData = [];
      }
      this.SpinnerService.hide()
    }, (err) => {
      console.log(err);
    });
  }
  SortDataPast(data, isOneonone) {
    var sortDate = data.sort(function (a, b) { return new Date(a.ScheduleDate).getTime() - new Date(b.ScheduleDate).getTime() });
    const now = Date.now();
    if (isOneonone) {
      const futureDates = sortDate.filter(date => {
        return (date.ScheduleDate && new Date(date.ScheduleDate).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)) || date.isrecorded;
      });
      return futureDates;
    } else {
      const futureDates = sortDate.filter(date => {
        return (date.ScheduleDate && new Date(date.ScheduleDate).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0));
      });
      return futureDates;
    }

  }
  // indexFilter(filterData){
  //   this.modelData = filterData;
  // }
  sort(property1, property2) {
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property1;
    let direction = this.isDesc ? 1 : -1;
    if (property2) {
      this.teacherPayoutDetails.sort(function (a, b) {
        if (a[property1][property2] < b[property1][property2]) {
          return -1 * direction;
        }
        else if (a[property1][property2] > b[property1][property2]) {
          return 1 * direction;
        }
        else {
          return 0;
        }
      });
    } else {
      this.teacherPayoutDetails.sort(function (a, b) {
        if (a[property1] < b[property1]) {
          return -1 * direction;
        }
        else if (a[property1] > b[property1]) {
          return 1 * direction;
        }
        else {
          return 0;
        }
      });
    }

  };
  redirect() {
    this.router.navigateByUrl('/');
  }
  redirectshare() {
    // this.router.navigate(['/roundtable-details/' + data._id], { state: data });
  }
 
  videodetails(data) {
    this.router.navigateByUrl('/videoDetails/' + data._id);
  }
  detailsMeeting(data, pagename) {
    data.pagename = pagename;
    var jsonStr = JSON.stringify(data)
    localStorage.setItem('livedetails', jsonStr);
    console.log(data)
    if(this.isTeacher){
      this.router.navigate(['/live-class-details/' + data._id], { state: data });
    }
    else{
      this.router.navigate(['/live-class-details/' + data._id], { state: data });
    }
  }

  enrollClicked(data, pagename) {
    console.log(data,'data');
    console.log(pagename,'pagename')
    // if(data.price != 0){  
    //   data.pagename = pagename;
    //   this.router.navigate(['/live-class-details/' + data._id], { state: data });
    // }
    if(this.isStudent){
      
      this.SpinnerService.show();
      var profileName = localStorage.getItem('profileName');
      profileName = profileName.replace(/ /gi, "+");
      var meetingId = profileName+"-"+Math.round(Math.random() * 1000000000)
      const webToken = localStorage.getItem("webtoken");
      var request = {}
      request['meetingID'] = meetingId;
      request['classID'] = data._id;

      this.rest.getWalletBalance(webToken).subscribe((response) => {
        console.log("material", response)
        for(var i=0;i < this.videoList.length;i++){
          this.Price = this.videoList[i].price;
          console.log(this.Price,'price')
        }
        if(response.data < data.price){
          this.SpinnerService.hide();
          this.toastr.errorToastr("Insufficient balance. Please recharge your wallet.")
        }
        else{
          this.SpinnerService.hide();
          if(data.isTemplate){
            this.router.navigate(['/request-live-class/' + data._id.toString()]);
          }
          else {
            this.rest.createLiveClass(request, webToken).subscribe((result) => {
              console.log('liveclassResultDetails',result)
              if(result['status']){
                this.SpinnerService.hide();
                this.toastr.successToastr("You've Successfully Enrolled to the Class.")
                window.location.href='/sidenav/upcoming/live-class';
              }
              else{
                this.SpinnerService.hide();
                this.toastr.errorToastr("Please login to continue this page");
                console.log(result.error)
              }
            })
          }
        }
      })
    }
    
  }
  clickPageNumber(pageNumber) {
    this.currentPageNumber = pageNumber;
    console.log(this.currentPageNumber)
    if (this.isLogin == 'true' && this.isTeacher) {
      this.getLiveclassList();
    }
    else if (this.isLogin == 'true' && this.isStudent) {
      this.getLiveclassWithoutlogin();
    }

    else {
      this.getLiveclassWithoutlogin();
    }
  }
  shareWhatsup(data) {

    var isMobile = false; //initiate as false


    let frontwebsites = environment.frontUrl;
    console.log('fdfdff',data,frontwebsites)
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4)))
     { 
    isMobile = true;
      }
      var textToSend = "Hi! I am forwarding a class from buddi.life. Please check it out here. https://www.buddi.life/live-class-details/" + data._id;
      var encodedUrl = encodeURIComponent(textToSend);
      if (isMobile) {
          window.open(
              "https://api.whatsapp.com/send?text=" + encodedUrl
          );
      } else {
          window.open(
              "https://web.whatsapp.com/send?text=" + encodedUrl
          );
      }
    // let frontwebsites = environment.frontUrl;
    // console.log('fdfdff',data)
    // window.open("https://web.whatsapp.com/send?text=testing.buddi.life/live-class-details/" + data._id)
  }
  shareWhatsup1(data) {

    var isMobile = false; //initiate as false


    let frontwebsites = environment.frontUrl;
    console.log('fdfdff',data,frontwebsites)
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4)))
     { 
    isMobile = true;
      }
      var textToSend = "Hi! I am forwarding a class from buddi.life. Please check it out here. https://www.buddi.life/live-class-details/" + data._id;
      var encodedUrl = encodeURIComponent(textToSend);
      if (isMobile) {
          window.open(
              "https://api.telegram.org/send?text=" + encodedUrl
          );
      } else {
          window.open(
              "https://telegram.me/share/url?url=" + encodedUrl
          );
      }
    // let frontwebsites = environment.frontUrl;
    // console.log('fdfdff',data)
    // window.open("https://web.whatsapp.com/send?text=testing.buddi.life/live-class-details/" + data._id)
  }
  redirectToCreate(){
    this.router.navigate(['/create-liveclass'])
  }
  redirectToLoginPage(){
    this.router.navigateByUrl('/student/login');
  }
  dropdownChange(event){
    var filter = event.target.options[event.target.selectedIndex].value.substring(event.target.options[event.target.selectedIndex].value.indexOf(":") + 1);
    if (this.isLogin == 'true' && this.isTeacher) {
      this.getLiveclassList(filter);
    }
    else {
      this.getLiveclassWithoutlogin(filter);
    }
  }

  searchClicked(){
    console.log("Clicked")
    let searchBox = (document.getElementById('searchBar') as HTMLInputElement).value
    this.currentSearch = searchBox;
    if(searchBox != ""){ 
      if (this.isLogin == 'true' && this.isTeacher) {
        this.getLiveclassList(null, this.currentSearch);
      }
      else {
        this.getLiveclassWithoutlogin(null, this.currentSearch);
      }
    }
  }
}
