<!-- <div class="row p-2">
    <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12">
        <div class="card shadow borderR_10">
            <div class="body">
                <div class="row align-items-center p-3">
                    <div *ngIf="thisWeekWork.length > 0" class="col-md-8">
                        <h6>
                            <div class="weight-600 col-pink pt-1">
                                Good job this week. Keep Going!!
                            </div>
                        </h6>
                        <p class="font-13 max-width-600">
                            The categories you worked on this week:
                        </p>
                        <div class="row font-13">
                            <div
                                *ngFor="let item of thisWeekWork"
                                class="col-lg-6"
                            >
                                <div>
                                    <i class="fa fa-circle dot1 mr-2"></i>
                                    {{ item.categoryID.categoryName }} -
                                    {{ item.percentage }}%
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div *ngIf="thisWeekWork.length == 0" class="col-md-8">
                        <h6>
                            <div class="weight-600 col-pink pt-1">
                                Oops. Looks like you haven't started learning this week. 
                            </div>
                        </h6>
                        <p class="font-13 max-width-600">
                            Use our various resources to learn, and check back here to see your progress
                        </p>
                    </div> -->
                    <!-- <div class="container"> -->
                        <!-- <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="card" style="width: 18rem;">
                                <img class="card-img-top" src="..." alt="Card image cap">
                                <div class="card-body">
                                  <h5 class="card-title">Card title</h5>
                                  <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                  <a href="#" class="btn btn-primary">Go somewhere</a>
                                </div>
                              </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="card" style="width: 18rem;">
                                <img class="card-img-top" src="..." alt="Card image cap">
                                <div class="card-body">
                                  <h5 class="card-title">Card title</h5>
                                  <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                  <a href="#" class="btn btn-primary">Go somewhere</a>
                                </div>
                              </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="card" style="width: 18rem;">
                                <img class="card-img-top" src="..." alt="Card image cap">
                                <div class="card-body">
                                  <h5 class="card-title">Card title</h5>
                                  <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                  <a href="#" class="btn btn-primary">Go somewhere</a>
                                </div>
                              </div>
                        </div>

                        </div> -->
                    <!-- </div> -->
                <!-- </div>
            </div>
        </div>
    </div>
    <div class="">
        <div class="card l-bg-purple text-white p-4 mt-1 mb-3 shadow borderR_10">
          <div class="info d-flex justify-content-between align-items-center">
            <div class="d-flex flex-column text-white font-22"> <span>English Vocabulary Test</span>
              <small class="mt-2"><i class="far fa-clock mr-2"></i> 25, Feb, 2020. 04:00 PM</small> </div>
          </div>
        </div>
        <div class="card l-bg-green text-white p-3 shadow borderR_10" >
          <div class="info d-flex justify-content-between align-items-center">
            <div class="d-flex flex-column text-white font-22"> <span>Science Homework Submition</span>
              <small class="mt-2"><i class="far fa-clock mr-2"></i> 28, Feb, 2020. 10:00 AM</small> </div>
          </div>
        </div>
      </div>
</div>  -->


<!-- <br /> -->







<!-- 
<div class="row p-2">
        <div  *ngFor="let item of courseData" class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
            <div class="card shadow borderR_10">
                <div class="header text-center pt-2">
                    <h6>Your Progress</h6>
                </div>
                <div class="tableBody p-0 font-12">
                    <div class="table-responsive">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>Subject</th>
                                    <th>Progress</th>
                                    <th>Duration</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{item.moduleID.moduleName}}</td>
                                    <td>
                                        {{item.percentage}}%<mat-progress-bar
                                            mode="determinate"
                                            value="30"
                                            color="warn"
                                        ></mat-progress-bar>
                                    </td>
                                    <td>2 Months</td>
                                </tr>
                                <tr *ngFor="let topic of item.topicList">
                                    <td>{{topic.topicID.topicName}}</td>
                                    <td>
                                        {{topic.completedPercentage}}%<mat-progress-bar
                                            mode="determinate"
                                            value="55"
                                        ></mat-progress-bar>
                                    </td>
                                    <td>3 Months</td>
                                </tr>
                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
</div>  -->

<div class="container">
        <div class="row">
        <div class="col-md-4">
            <div class="card" style="height: 20rem;">
                <!-- <img class="card-img-top" src="..." alt="Card image cap"> -->
                <div class="card-body">
                  <h5 class="card-title">upcoming Courses</h5>
                  <div class="chartjs-size-monitor" style="position: absolute; left: 0px; top: 0px; right: 0px; bottom: 0px; overflow: hidden; pointer-events: none; visibility: hidden; z-index: -1;">
                    <div class="chartjs-size-monitor-expand" style="position:absolute;left:0;top:0;right:0;bottom:0;overflow:hidden;pointer-events:none;visibility:hidden;z-index:-1;">
                        <div style="position:absolute;width:1000000px;height:1000000px;left:0;top:0"></div>
                    </div>
                    <div class="chartjs-size-monitor-shrink" style="position:absolute;left:0;top:0;right:0;bottom:0;overflow:hidden;pointer-events:none;visibility:hidden;z-index:-1;">
                        <div style="position:absolute;width:200%;height:200%;left:0; top:0"></div>
                    </div>
                </div> <canvas id="chart-line" width="299" height="200" class="chartjs-render-monitor" style="display: block; width: 299px; height: 200px;"></canvas>
                </div>
              </div>
        </div>

        
            <div class="col-md-4">
                <div class="card" style="height: 20rem;" >
                    <!-- <img class="card-img-top" src="..." alt="Card image cap"> -->
                    <div class="card-body">
                      <h5 class="card-title">Upcoming Classes</h5>
                      <div class="chartjs-size-monitor" style="position: absolute; left: 0px; top: 0px; right: 0px; bottom: 0px; overflow: hidden; pointer-events: none; visibility: hidden; z-index: -1;">
                        <div class="chartjs-size-monitor-expand" style="position:absolute;left:0;top:0;right:0;bottom:0;overflow:hidden;pointer-events:none;visibility:hidden;z-index:-1;">
                            <div style="position:absolute;width:1000000px;height:1000000px;left:0;top:0"></div>
                        </div>
                        <div class="chartjs-size-monitor-shrink" style="position:absolute;left:0;top:0;right:0;bottom:0;overflow:hidden;pointer-events:none;visibility:hidden;z-index:-1;">
                            <div style="position:absolute;width:200%;height:200%;left:0; top:0"></div>
                        </div>
                    </div> 
                    <canvas id="chart-line1" width="299" height="200" class="chartjs-render-monitor" style="display: block; width: 299px; height: 200px;"></canvas>
                    </div>
                  </div>
            </div>

          
                <div class="col-md-4">
                    <div class="card" style="height: 20rem;">
                        <!-- <img class="card-img-top" src="..." alt="Card image cap"> -->
                        <div class="card-body">
                          <h5 class="card-title">Upcoming Tests</h5>
                          <div class="chartjs-size-monitor" style="position: absolute; left: 0px; top: 0px; right: 0px; bottom: 0px; overflow: hidden; pointer-events: none; visibility: hidden; z-index: -1;">
                            <div class="chartjs-size-monitor-expand" style="position:absolute;left:0;top:0;right:0;bottom:0;overflow:hidden;pointer-events:none;visibility:hidden;z-index:-1;">
                                <div style="position:absolute;width:1000000px;height:1000000px;left:0;top:0"></div>
                            </div>
                            <div class="chartjs-size-monitor-shrink" style="position:absolute;left:0;top:0;right:0;bottom:0;overflow:hidden;pointer-events:none;visibility:hidden;z-index:-1;">
                                <div style="position:absolute;width:200%;height:200%;left:0; top:0"></div>
                            </div>
                        </div> 
                        <canvas id="chart-line3" width="299" height="200" class="chartjs-render-monitor" style="display: block; width: 299px; height: 200px;"></canvas>
                        </div>
                      </div>
                </div>


    </div>
    </div>

    <div class="container">
<div class="row">
    <div class="col-md-4" >
        <div class="card"style="height: 20rem;" >
            <!-- <img class="card-img-top" src="..." alt="Card image cap"> -->
            <div class="card-body">
              <h5 class="card-title">Completed Courses</h5>
              <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              <a href="#" class="btn btn-primary">Go somewhere</a> -->
            </div>
          </div>
    </div>
    <div class="col-md-4" style="height: 20rem;">
        <div class="card"style="height: 20rem;" >
            <!-- <img class="card-img-top" src="..." alt="Card image cap"> -->
            <div class="card-body">
              <h5 class="card-title">Completed Classes</h5>
              <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              <a href="#" class="btn btn-primary">Go somewhere</a> -->
            </div>
          </div>
    </div>
    <div class="col-md-4" style="height: 20rem;">
        <div class="card"style="height: 20rem;" >
            <!-- <img class="card-img-top" src="..." alt="Card image cap"> -->
            <div class="card-body">
              <h5 class="card-title">Completed Tests</h5>
              <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              <a href="#" class="btn btn-primary">Go somewhere</a> -->
            </div>
          </div>
    </div>
</div>
</div>
<div class="container">
    <div class="row">
        <div class="col-md-6">
            <div class="card" style="height: 20rem;" >
                <!-- <img class="card-img-top" src="..." alt="Card image cap"> -->
                <div class="card-body">
                  <h5 class="card-title">Overall Performance</h5>
                  <!-- <p class="card-text"> -->
                   
                <!-- </p> -->
                
                </div>
              </div>
        </div>
        <div class="col-md-3">
            <div class="card"style="height: 20rem;" >
                <!-- <img class="card-img-top" src="..." alt="Card image cap"> -->
                <div class="card-body">
                  <h5 class="card-title">Watched Videos</h5>
                  <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                  <a href="#" class="btn btn-primary">Go somewhere</a> -->
                </div>
              </div>
        </div>
        <div class="col-md-3" >
            <div class="card"style="height: 20rem;" >
                <!-- <img class="card-img-top" src="..." alt="Card image cap"> -->
                <div class="card-body">
                  <h5 class="card-title">Study Material Read</h5>
                  <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                  <a href="#" class="btn btn-primary">Go somewhere</a> -->
                </div>
              </div>
        </div>

    </div>
    </div>
    
    <div style="width: 310px; height: 200px;">
        <app-pie-chart [data]="pieChartData" title="Angular Pie Chart" is3d=false></app-pie-chart>
    </div>