import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageSixComponent } from './components/pages/Students/home-page-six/home-page-six.component';
import { HomePageOneComponent } from './components/pages/home-page-one/home-page-one.component';
import { HomePageTwoComponent } from './components/pages/home-page-two/home-page-two.component';
// import { HomePageThreeComponent } from './components/pages/home-page-three/home-page-three.component';
import { HomePageFourComponent } from './components/pages/home-page-four/home-page-four.component';
import { HomePageFiveComponent } from './components/pages/home-page-five/home-page-five.component';
import { HomePageSevenComponent } from './components/pages/home-page-seven/home-page-seven.component';
import { LoginPageComponent } from './components/pages/Teachers/login-page/login-page.component';
import { RegisterPageComponent } from './components/pages//Teachers/register-page/register-page.component';
import { ErrorPageComponent } from './components/pages/error-page/error-page.component';
import { ComingSoonPageComponent } from './components/pages/coming-soon-page/coming-soon-page.component';
import { CoursesListPageComponent } from './components/pages/courses-list-page/courses-list-page.component';
import { CoursesDetailsPageComponent } from './components/pages/courses-details-page/courses-details-page.component';
import { MyDashboardPageComponent } from './components/pages/Teachers/my-dashboard-page/my-dashboard-page.component';
import { ForgetpasswordComponent } from './components/pages/Teachers/forgetpassword/forgetpassword.component';
import { StudentloginComponent } from './components/pages/Students/studentlogin/studentlogin.component';
import { StudentRegisterPageComponent } from './components/pages/Students/student-register-page/student-register-page.component';
import { StudentforgetpasswordComponent } from './components/pages/Students/studentforgetpassword/studentforgetpassword.component';
import { CreatemettingComponent } from './components/pages/createmetting/createmetting.component';
import { EditProfileComponent } from './components/pages/Teachers/edit-profile/edit-profile.component';
import { GroupCreateComponent } from './components/pages/Teachers/group-create/group-create.component';
import { TeacherRequestsComponent } from './components/pages/Teachers/teacher-requests/teacher-requests.component';
import { StudentProfileComponent } from './components/pages/Students/student-profile/student-profile.component';
import { StudentEditProfileComponent } from './components/pages/Students/student-edit-profile/student-edit-profile.component';
import { GrpUserCreateStudentComponent } from './components/pages/Students/grp-user-create-student/grp-user-create-student.component';
import { MeetingDetailsComponent } from './components/pages/Students/meeting-details/meeting-details.component';
import { CreateCourseComponent } from './components/pages/create-course/create-course.component';
import { CoursesListComponent } from './components/pages/courses-list/courses-list.component';
import { CourseDetailsComponent } from './components/pages/course-details/course-details.component';
import { InvitemeetingComponent } from './components/pages/Teachers/invitemeeting/invitemeeting.component';
import { ExploreComponent } from './components/pages/explore/explore.component';
import { ChangepasswordComponent } from './components/pages/changepassword/changepassword.component';
import { VideoListingComponent } from './components/pages/video-listing/video-listing.component';
import { ListingWebinarComponent } from './components/pages/listing-webinar/listing-webinar.component';
import { ListingClassroomsComponent } from './components/pages/listing-classrooms/listing-classrooms.component';
import { MenuDropdownComponent } from './components/pages/menu-dropdown/menu-dropdown/menu-dropdown.component';
import { CreateLiveclassComponent } from './components/pages/create-liveclass/create-liveclass.component';
import { CurrencyPaymentComponent } from './components/pages/currency-payment/currency-payment.component';
import { CoinPaymentComponent } from './components/pages/coin-payment/coin-payment.component';

// const routes: Routes = [
//     {path: '', component: HomePageSixComponent},    
//     {path: 'explore/:type', component: ExploreComponent},
//     {path: 'explore/:id', component: ExploreComponent},
import { StudyclassRequestDetailsComponent } from './components/pages/studyclass-request-details/studyclass-request-details.component';
import { RoundclassRequestDetailsComponent } from './components/pages/roundclass-request-details/roundclass-request-details.component';
import { AboutComponent } from './components/pages/about/about.component';
import { FooterTeamComponent } from './components/pages/footer-team/footer-team.component';
import { FooterAdvisorsComponent } from './components/pages/footer-advisors/footer-advisors.component';
import { FooterPartnersComponent } from './components/pages/footer-partners/footer-partners.component';
import { FooterContactComponent } from './components/pages/footer-contact/footer-contact.component';
import { FaqPageComponent } from './components/pages/faq-page/faq-page.component';
import { FooterPrivacyComponent } from './components/pages/footer-privacy/footer-privacy.component';
import { FooterTermsandconditionComponent } from './components/pages/footer-termsandcondition/footer-termsandcondition.component';
// import { BuddiK12Component } from './components/pages/k12/buddi-k12/buddi-k12.component';
import { VRComponent } from './components/pages/vr/vr.component';
import { BuddiAdvisorsComponent } from './components/pages/buddi-advisors/buddi-advisors.component'
// import { OneTOoneCreateComponent } from './components/pages/Students/one-toone-create/one-toone-create.component';
import { CreateExamComponent } from './components/pages/create-exam/create-exam.component';
import { ExamDetailsComponent } from './components/pages/exam-details/exam-details.component'
//import { CalendarComponent } from './components/pages/calendar/calendar.component';
import { TeacherEbookCreateComponent } from './components/pages/Teachers/teacher-ebook-create/teacher-ebook-create.component';
import { TeacherVideoCreateComponent } from './components/pages/Teachers/teacher-video-create/teacher-video-create.component';
import { TeacherAudioCreateComponent } from './components/pages/Teachers/teacher-audio-create/teacher-audio-create.component';
import { EbookDetailsComponent } from './components/ebook-details/ebook-details.component';
import { TechnologyRequiredComponent } from './components/pages/technology-required/technology-required.component'
import { VideoDetailsComponent } from './components/pages/video-details/video-details.component';
import { AudioDetailsComponent } from './components/pages/audio-details/audio-details.component';
import { AboutTeacherComponent } from './components/pages/about-teacher/about-teacher.component'
import { StudentFaqPageComponent } from './components/pages/student-faq-page/student-faq-page.component'
import { PressReleasesComponent } from './components/pages/press-releases/press-releases.component'
import { EditCourseComponent } from './components/pages/edit-course/edit-course.component'
import { EditExamComponent } from './components/pages/edit-exam/edit-exam.component'
import { BuddiTeamDetailsComponent } from './components/pages/buddi-team-details/buddi-team-details.component'
import { BuddiTeacherDetailsComponent } from './components/pages/buddi-teacher-details/buddi-teacher-details.component'
import { BuddiAdvisorsDetailsComponent } from './components/pages/buddi-advisors-details/buddi-advisors-details.component';
import { BuddigroupComponent } from './components/common/buddigroup/buddigroup.component';
import { SchoolRegistrationComponent } from './components/pages/school-registration/school-registration.component';
import { ThanksSchoolRegistrationComponent } from './components/pages/thanks-school-registration/thanks-school-registration.component'
import { BuddiLiveRedirectComponent } from './components/pages/buddi-live-redirect/buddi-live-redirect.component'
import { StudentsRegisterComponent } from './components/pages/students-register/students-register.component'
import { ThanksStudentRegistrationComponent } from './components/pages/thanks-student-registration/thanks-student-registration.component'
import { BuddiTestComponent } from './components/pages/buddi-test/buddi-test.component'
import { BuddiTestPageComponent } from './components/pages/buddi-test-page/buddi-test-page.component'
import { BuddiTestListComponent } from './components/pages/buddi-test-list/buddi-test-list.component'
import { PaymentsuccessComponent } from './components/pages/paymentsuccess/paymentsuccess.component';
import { BuddioneOnoneComponent } from './components/pages/buddione-onone/buddione-onone.component';
import { BuddiTestCorrectionComponent } from './components/pages/buddi-test-correction/buddi-test-correction.component'
import { TestsRequestComponent } from './components/pages/tests-request/tests-request.component'
import { TeacherVideoUploadComponent } from './components/pages/teacher-video-upload/teacher-video-upload.component'
import { BuddiTestDetailsComponent } from './components/pages/buddi-test-details/buddi-test-details.component';
import { OneOnoneSuccessComponent } from './components/pages/one-onone-success/one-onone-success.component';
import { StudentTestDetailsComponent } from './components/pages/student-test-details/student-test-details.component'
import { StudentTestResultComponent } from './components/pages/student-test-result/student-test-result.component'
import { KycComponent } from './components/pages/kyc/kyc.component';
import { BuddiTestReviewComponent } from './components/pages/buddi-test-review/buddi-test-review.component'
import { NointernetComponent } from './components/pages/nointernet/nointernet.component';
import { SearchByComponent } from './components/pages/search-by/search-by.component';
//import { NeetCrashComponent} from './components/pages/neet-crash/neet-crash.component';
import { FeedbackTeacherComponent } from './components/pages/Teachers/feedback-teacher/feedback-teacher.component';
import { AutomationComponent } from './components/pages/automation/automation.component';
//import {BuddiK12AdsComponent} from './components/pages/k12-ads/buddi-k12-ads/buddi-k12-ads.component'
//import { CommonDetailsPageComponent } from './components/pages/common-details-page/common-details-page.component';
import { EvaluationTestDetailsComponent } from './components/pages/evaluation-test/evaluation-test-details/evaluation-test-details.component'
import {TeachersPricingComponent} from './components/pages/Teachers/teachers-pricing/teachers-pricing.component';
// import { OfferHeaderComponent } from './components/pages/offer-header/offer-header.component';
import { FullListingComponent } from './components/pages/full-listing/full-listing.component';
import { VideodetailsComponent } from './components/pages/videodetails/videodetails.component';
import { UploadedVideoDetailsComponent } from './components/pages/uploaded-video-details/uploaded-video-details.component'
import { StudentVideoDetailsComponent } from './components/pages/Students/student-video-details/student-video-details.component'
import { UploadClassCourseComponent } from './components/pages/Teachers/upload-class-course/upload-class-course.component'
import { ClassCourseListComponent } from './components/pages/Teachers/class-course-list/class-course-list.component';
import { ClassCourseDetailsComponent } from './components/pages/Teachers/class-course-details/class-course-details.component';
import {StudentCourseDetailsComponent} from './components/pages/Students/student-course-details/student-course-details.component';
import { ChartdashboardComponent } from './components/pages/chartdashboard/chartdashboard.component';
import { SidenavComponent } from './components/pages/sidenav/sidenav.component';
import { ChapterTopicComponent } from './components/pages/chapter-topic/chapter-topic.component';
import { SubjectNavComponent } from './components/pages/subject-nav/subject-nav.component';
import { CreateWebinarComponent } from './components/pages/create-webinar/create-webinar.component';
import {ListMylearningComponent} from './components/pages/Students/list-mylearning/list-mylearning.component';
import {StudyMaterialDetailsComponent} from './components/pages/study-material-details/study-material-details.component';
import { PaymentComponent } from './components/pages/payment/payment.component';
import { TeacherMyLearningComponent } from './components/pages/Teachers/teacher-my-learning/teacher-my-learning.component'
import { UploadTeacherSmartmaterialComponent } from './components/pages/Teachers/upload-teacher-smartmaterial/upload-teacher-smartmaterial.component'
import { SmartmaterialsDetailsComponent } from './components/pages/Teachers/smartmaterials-details/smartmaterials-details.component'
import { TeacherTestUploadComponent } from './components/pages/Teachers/teacher-test-upload/teacher-test-upload.component'
import { TeacherTestDetailsComponent } from './components/pages/Teachers/teacher-test-details/teacher-test-details.component'
import { TestDetailsStudentComponent } from './components/pages/Students/test-details-student/test-details-student.component'
import { StudentStartTestComponent } from './components/pages/Students/student-start-test/student-start-test.component'
import { TeacherCorrectionPageComponent } from './components/pages/Teachers/teacher-correction-page/teacher-correction-page.component'
import { StudentCorrectionResultComponent } from './components/pages/Students/student-correction-result/student-correction-result.component'
//import { TeacherTestDetailsComponent } from './components/pages/Teachers/teacher-test-details/teacher-test-details.component';
import {PaymentHistoryComponent} from './components/pages/payment-history/payment-history.component';
import { UpcomingclassComponent } from './components/pages/upcomingclass/upcomingclass.component';
import { CartCheckoutComponent } from './components/pages/cart-checkout/cart-checkout.component';
import {FullListSmartComponent} from './components/pages/full-list-smart/full-list-smart.component';
import {FullListTestComponent} from './components/pages/full-list-test/full-list-test.component';
import { LiveclassListingComponent } from './components/pages/liveclass-listing/liveclass-listing.component';
import { InstitutionPageComponent } from './components/pages/institution-page/institution-page.component';
import { LiveClassDetailsComponent } from './components/pages/live-class-details/live-class-details.component';
import { LiveClassRequestDetailsComponent } from './components/pages/live-class-request-details/live-class-request-details.component';
import { LiveClassRequestListingComponent } from './components/pages/live-class-request-listing/live-class-request-listing.component';
import { LearningToolsComponent } from './components/pages/learning-tools/learning-tools.component';
import { CreateStudyHallComponent } from './components/pages/create-study-hall/create-study-hall.component';
import { CreateRoundtableComponent } from './components/pages/create-roundtable/create-roundtable.component';
import { StudyhallDetailsComponent } from './components/pages/studyhall-details/studyhall-details.component';
import { StudyhallListComponent } from './components/pages/studyhall-list/studyhall-list.component';
import { RoundtableListComponent } from './components/pages/roundtable-list/roundtable-list.component';
import { RoundtableDetailsComponent } from './components/pages/roundtable-details/roundtable-details.component';
import { StudyhallRequestListComponent } from './components/pages/studyhall-request-list/studyhall-request-list.component';
import { AllStudyclassListingComponent } from './components/pages/all-studyclass-listing/all-studyclass-listing.component';
import { RoundtableRequestListComponent } from './components/pages/roundtable-request-list/roundtable-request-list.component';
import { StudentRequestLiveClassComponent } from './components/pages/Students/student-request-live-class/student-request-live-class.component';

import { AllLiveclassListingComponent } from './components/pages/all-liveclass-listing/all-liveclass-listing.component';
import { AllRoundtableListingComponent } from './components/pages/all-roundtable-listing/all-roundtable-listing.component';
import { AddvideoDashboardListComponent } from './components/pages/addvideo-dashboard-list/addvideo-dashboard-list.component';
import { AddsmartmaterialListComponent } from './components/pages/addsmartmaterial-list/addsmartmaterial-list.component';
import { AddbudditestListComponent } from './components/pages/addbudditest-list/addbudditest-list.component';
import { TeacherRewardComponent } from './components/pages/teacher-reward/teacher-reward.component';
import { StudentRewardComponent } from './components/pages/student-reward/student-reward.component';
import { WalletComponent } from './components/pages/wallet/wallet.component';
import { WalletRechargeComponent } from './components/pages/wallet-recharge/wallet-recharge.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { InvestorComponent } from './components/pages/investor/investor.component';
import { PricingPlansComponent } from './components/pages/pricing-plans/pricing-plans.component';
import { TutionClassPageComponent } from './components/pages/tution-class-page/tution-class-page.component';
import { CBSEComponent } from './components/pages/cbse/cbse-online-tution-content.component';
import { TeachingComponent } from './components/pages/teaching/online-teaching-jobs.component';
import { InstitutionCreationFormComponent } from './components/pages/institution-creation-form/institution-creation-form.component';
import { AddTutorLearnerComponent } from './components/pages/add-tutor-learner/add-tutor-learner.component';

import { PartnerStyleOneComponent} from './components/common/partner-style-one/partner-style-one.component';
import { GetstartedComponent } from './components/pages/getstarted/getstarted.component';
import { LearningassetsComponent } from './components/pages/learningassets/learningassets.component';
import { NFTComponent } from './components/pages/nft/nft.component';
import { MetaverseComponent } from './components/pages/metaverse/metaverse.component';
import { InstitutionDetailsComponent } from './components/pages/institution-details/institution-details.component';
import { BuytokensComponent } from './components/pages/buytokens/buytokens.component';
import { MarketplaceComponent } from './components/pages/marketplace/marketplace.component';
import { LiveComponent } from './live/live.component';
import { CreateliveComponent } from './createlive/createlive.component';
import { LivelistComponent } from './livelist/livelist.component';
import { UpcommingComponent } from './upcomming/upcomming.component';
import { CompletedComponent } from './completed/completed.component';

import { UpcomingLiveClassComponent } from './components/pages/upcoming-live-classes/upcoming-live-class.component';
import { UpcomingStudyhallComponent } from './components/pages/upcoming-studyhall/upcoming-studyhall.component';
import { UpcomingroundtableComponent } from './components/pages/upcomingroundtable/upcomingroundtable.component';
import { UpcomingTestsComponent } from './components/pages/upcoming-tests/upcoming-tests.component';
import { UpcomingVideosComponent } from './components/pages/upcoming-videos/upcoming-videos.component';
import { UpcomingSmartMaterialComponent } from './components/pages/upcoming-smart-material/upcoming-smart-material.component';
import { CompletedStudyhallTabComponent } from './components/pages/completed-studyhall-tab/completed-studyhall-tab.component';
import { CompletedLiveclassTabComponent } from './components/pages/completed-liveclass-tab/completed-liveclass-tab.component';
import { CompletedRoundtableTabComponent } from './components/pages/completed-roundtable-tab/completed-roundtable-tab.component';
import { ProfileComponent } from './profile/profile.component';
import { ProfileAdvancedComponent } from './components/pages/profile-advanced/profile-advanced.component';
import { SettingsComponent } from './settings/settings.component';
import { NotificationsComponent } from './components/pages/notifications/notifications.component';
import { AssetComponent } from './asset/asset.component';
import { BuddiCoinComponent } from './buddi-coin/buddi-coin.component';
import { TransactionHistoryComponent } from './transaction-history/transaction-history.component';
import { CoinTransactionHistoryComponent } from './coin-transaction-history/coin-transaction-history.component';
import { BuddiRewardsComponent } from './buddi-rewards/buddi-rewards.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { NftAssetTransactionComponent } from './nft-asset-transaction/nft-asset-transaction.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { UpgradePacakgeComponent } from './components/pages/upgarde-package/upgrade-package.component';
import { SummarySnapshotComponent } from './components/pages/summary-snapshot/summary-snapshot.component';
import { TutorialsComponent } from './components/pages/tutorials/tutorials.component';
import { DemoClassComponent } from './components/pages/demo-class/demo-class.component';
import { InstituteCreateListComponent } from './components/pages/institute-create-list/institute-create-list.component';
import { InstituteListDetailsComponent } from './components/pages/institute-list-details/institute-list-details.component';
import { BuddiTalkComponent } from './components/pages/buddi-talk/buddi-talk.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { LogpageComponent } from './logpage/logpage.component';
import { ClasscreationComponent } from './classcreation/classcreation.component';
import { EnrollClassesComponent } from './enroll-classes/enroll-classes.component';

const routes: Routes = [
  // { path: '', component: VideoListingComponent },
  { path: 'marketplace', component:MarketplaceComponent},
  { path: 'buytoken', component: BuytokensComponent},
  { path: 'metaverse', component:  MetaverseComponent },
  { path: 'nft', component: NFTComponent }, 
  { path: 'learningassets', component:LearningassetsComponent }, 
  { path: 'getstarted', component:GetstartedComponent },
  { path: 'partner-style-one', component:PartnerStyleOneComponent },

  { path: 'sidenav/upcoming/live-class', component:UpcomingLiveClassComponent },
  { path: 'sidenav/upcoming/study-hall', component:UpcomingStudyhallComponent },
  { path: 'sidenav/upcoming/round-table', component:UpcomingroundtableComponent },
  { path: 'sidenav/upcoming/video-class', component:UpcomingVideosComponent },
  { path: 'sidenav/upcoming/study-material', component:UpcomingSmartMaterialComponent },
  { path: 'sidenav/upcoming/evalution-test', component:UpcomingTestsComponent },
  { path: 'sidenav/completed/live-class', component:CompletedLiveclassTabComponent },
  { path: 'sidenav/completed/study-hall', component:CompletedStudyhallTabComponent },
  { path: 'sidenav/completed/round-table', component:CompletedRoundtableTabComponent },
  { path: 'sidenav/upcoming', component:UpcommingComponent },
  { path: 'profile', component:ProfileComponent },
  { path: 'student-profile', component:StudentProfileComponent },
  { path: 'profile/advanced', component: ProfileAdvancedComponent },
  { path: 'settings', component: SettingsComponent },
  { path: 'notifications', component: NotificationsComponent },
  { path: 'nft-asset', component: AssetComponent },
  { path: 'buddi-coin', component: BuddiCoinComponent },
  { path: 'currency-transaction-history', component: TransactionHistoryComponent },
  { path: 'buddi-coin-transaction-history', component: CoinTransactionHistoryComponent },
  { path: 'buddi-rewards', component: BuddiRewardsComponent },
  { path: 'invoice', component: InvoicesComponent },
  { path: 'nft-asset-transaction', component: NftAssetTransactionComponent },
  { path: 'sidenav/payment/subscription', component: SubscriptionComponent },
  { path: 'upgrade', component: UpgradePacakgeComponent },
  { path: 'summary-snapshot', component: SummarySnapshotComponent },
  { path: 'tutorials', component: TutorialsComponent },
  { path: 'demo-class', component: DemoClassComponent },
  { path: 'institute-list', component: InstituteCreateListComponent },
  { path: 'institute-details/:institutionID', component: InstituteListDetailsComponent },
  { path: 'buddiTalk', component: BuddiTalkComponent },
  { path: '', component: AboutUsComponent },
  { path: 'logpage', component: LogpageComponent },
  { path: 'class-creation', component: ClasscreationComponent },
  { path: 'enroll-classes', component: EnrollClassesComponent },
  

  { path: 'Live', component:LiveComponent },
  { path: 'Createlive', component:CreateliveComponent },
  { path: 'Livelist', component:LivelistComponent },
  
 { path: 'cbse-online-tuition', component: CBSEComponent },
 { path: 'online-teaching-jobs', component:TeachingComponent },
 { path: 'investors', component: InvestorComponent },
 { path: 'online-tuition-classes', component: TutionClassPageComponent },
 { path: 'Institute-create', component: InstitutionCreationFormComponent },
 { path: 'institution-details', component: InstitutionDetailsComponent },
 { path: 'Pricing-plans', component: PricingPlansComponent },
 { path: 'add-tutor-learner', component: AddTutorLearnerComponent },
  { path: 'institutionspage', component: InstitutionPageComponent },
  { path: 'liveClassList', component: LiveclassListingComponent },
  { path: 'liveClassList/:categoryid/:moduleid/:courseid/:topicid', component: LiveclassListingComponent },
  { path: 'studyhallList', component: StudyhallListComponent },
  { path: 'studyhallList/:categoryid/:moduleid/:courseid/:topicid', component: StudyhallListComponent},
  { path: 'roundtableList', component: RoundtableListComponent },
  { path: 'roundtableList/:categoryid/:moduleid/:courseid/:topicid', component: RoundtableListComponent },
  { path: 'webinar-listing', component: ListingWebinarComponent},
  { path: 'classRoomList', component: ListingClassroomsComponent },
  { path: 'chart', component: ChartdashboardComponent },
   { path: 'payment-history', component: PaymentHistoryComponent },
  { path: 'mylearning', component: ListMylearningComponent },
  { path: 'sidenav', component: SidenavComponent },
  { path: 'sidenav/:id/:type', component: SidenavComponent },
  {
    path: 'dashboard',
    loadChildren: './components/share-module/share-module.module#ShareModuleModule'
  }, 
  { path: 'courseslists', component: MenuDropdownComponent },

  { path: 'video-selected-listing', component: AddvideoDashboardListComponent }, 
  { path: 'material-selected-listing', component: AddsmartmaterialListComponent }, 
  { path: 'budditest-selected-listing', component: AddbudditestListComponent }, 

  { path: 'all-video-listing', component: FullListingComponent }, 
  { path: 'all-studyhall-listing', component: AllStudyclassListingComponent }, 
  { path: 'all-roundtable-listing', component: AllRoundtableListingComponent }, 
  { path: 'all-liveclass-listing', component: AllLiveclassListingComponent }, 
  { path: 'all-video-listing/:categoryid/:moduleid/:courseid/:topicid', component: FullListingComponent},
  { path: 'all-study-listing', component: FullListSmartComponent },
  { path: 'all-study-listing/:categoryid/:moduleid/:courseid/:topicid', component: FullListSmartComponent},
  { path: 'all-test-listing', component: FullListTestComponent },
  { path: 'all-test-listing/:categoryid/:moduleid/:courseid/:topicid', component: FullListTestComponent},
  { path: 'videoDetails/:id', component: VideodetailsComponent }, 
  { path: 'studyMaterialDetails/:id', component: StudyMaterialDetailsComponent },
  { path: 'Home', component: HomePageSixComponent }, 
  {path: 'teacher-pricing', component: TeachersPricingComponent }, 
//   { path: 'buddi-K12-Ads', component: BuddiK12AdsComponent,
//   data: {
//     seo: {
//       metaTags: [
//         {name: 'title', content: 'Online Classes for K-12 Students, K-12 Classes - buddi Academy'},
//       {name: 'description', content: 'buddi.life teaching students for digital learning online classes. buddi.life K 5-12 school portion in digital class. K-12 program have best instructors to ensure that your fundamentals are built strongly'},
//       {name: 'keywords', content: 'k-12 digital learning, k 12 online school class,digital learning platform k 12 online learning school'}
//       ]
//     }
//   }
// },
//   { path: 'NEET-Crash', component: NeetCrashComponent,
//   data: {
//     seo: {
//       metaTags: [
//         {name: 'title', content: 'Best NEET Crash Course, Crash Courses for NEET - buddi Academy'},
//       {name: 'description', content: 'buddi.life helps School students for NEET Digital coaching for online classes. buddi.life NEET digital learning preparation class for budding doctors.'},
//       {name: 'keywords', content: 'NEET digital learning, NEET online class,  NEET digital learning platform Students, NEET online preparation school students, NEET Digital learning MBBS-BDS'}
//       ]
//     }
//   }
//   },
  // { path: 'offer', component: OfferHeaderComponent },
  { path: 'searchby/:id/:type', component: SearchByComponent },
  { path: 'automation/:id', component: AutomationComponent},
  { path: 'logoutmeeting/automation/:id', component: AutomationComponent},
  { path: 'nointernet', component: NointernetComponent },  
  { path: 'buddi-payment/:id', component:BuddioneOnoneComponent},
  { path: 'payment', component:PaymentComponent},
  { path: 'kyc', component:KycComponent},  
  { path: 'feedbackTeacher', component:FeedbackTeacherComponent},
  { path: 'feedbackTeacher/:id', component:FeedbackTeacherComponent},
  { path: 'payment-success', component: PaymentsuccessComponent },    
  { path: 'payment-success/:id', component: PaymentsuccessComponent },
  { path: 'video-enroll/:id', component: VideoDetailsComponent },
  { path: 'audio-enroll/:id', component: AudioDetailsComponent },
  // { path: 'details/:type/:id', component: CommonDetailsPageComponent},
  // { path: 'addTo/:id', component: CommonDetailsPageComponent},
  // { path: 'addTo/:type/:id', component: CommonDetailsPageComponent},
  { path: 'ebook-enroll/:id', component: EbookDetailsComponent },
  { path: 'video-details/:id', component: VideoDetailsComponent },
  { path: 'audio-details/:id', component: AudioDetailsComponent },
  { path: 'ebook-details/:id', component: EbookDetailsComponent },
  { path: 'create/:type', component: TeacherEbookCreateComponent },
  { path: 'edit/:type/:id', component: TeacherEbookCreateComponent },
  //{ path: 'myCalendar', component: CalendarComponent },
  // { path: 'student-onetoone-create', component: OneTOoneCreateComponent },
  // { path: 'student-onetoone-create/:type', component: OneTOoneCreateComponent },
  // { path: 'student-onetoone-create/:type/:id', component: OneTOoneCreateComponent },
  // { path: 'student-onetoone-edit/:id', component: OneTOoneCreateComponent },
  { path: 'teacher/home', component: HomePageFiveComponent },
  { path: 'student/home', component: HomePageSixComponent },
  { path: 'student/videolisting', component: VideoListingComponent },
  { path: 'listing', component: VideoListingComponent },
  { path: 'Currency-wallet', component: CoinPaymentComponent },
  { path: 'currencypayment', component: CurrencyPaymentComponent },
  { path: 'explore/:type', component: ExploreComponent },
  { path: 'explore/:id', component: ExploreComponent },
  { path: 'explore/:subjectname/:boardname/:gradename/:videoname', component: ExploreComponent },
  { path: 'explores/:board', component: ExploreComponent },
  { path: 'edit-course/:courseid/:categoryname', component: EditCourseComponent },
  { path: 'edit-exam/:courseid/:categoryname', component: EditExamComponent },
  { path: 'technology-required', component: TechnologyRequiredComponent },
  { path: 'about-us', component: AboutComponent },
  { path: 'tutor-reward', component: TeacherRewardComponent },
  { path: 'learner-reward', component: StudentRewardComponent },
  { path: 'about-us-teacher', component: AboutTeacherComponent },
  { path: 'audio-edit/:id', component: TeacherAudioCreateComponent },
  { path: 'student-audio-create', component: TeacherAudioCreateComponent },
  { path: 'teacher-audio-create', component: TeacherAudioCreateComponent },
  { path: 'video-edit/:id', component: TeacherVideoCreateComponent },
  { path: 'teacher-video-create', component: TeacherVideoCreateComponent },
  { path: 'student-video-create', component: TeacherVideoCreateComponent },
  { path: 'ver', component: VRComponent },
  { path: 'meetingnotstart', component: OneOnoneSuccessComponent },
  { path: 'oneOnone', component: OneOnoneSuccessComponent },
  { path: 'success/:type', component: OneOnoneSuccessComponent },
  { path: 'press-releases', component: PressReleasesComponent },
  { path: 'blog', component: BlogComponent },


  { path: 'buddi-management-team', component: FooterTeamComponent },
  { path: 'buddi-advisor', component: FooterAdvisorsComponent },
  { path: 'buddi-advisors', component: BuddiAdvisorsComponent },
  { path: 'create-exam', component: CreateExamComponent },
  { path: 'buddi-partners', component: FooterPartnersComponent },
  { path: 'contact-us', component: FooterContactComponent },
  { path: 'buddi-faq', component: FaqPageComponent },
  { path: 'buddi-faq-student', component: StudentFaqPageComponent },
  { path: 'buddi-privacy', component: FooterPrivacyComponent },
  { path: 'changepassword', component: ChangepasswordComponent },
  { path: 'cart-checkout', component:CartCheckoutComponent},
  { path: 'wallet', component:WalletComponent},
  { path: 'wallet-recharge', component: WalletRechargeComponent},
  // { path: 'K-12/:id', component: BuddiK12Component,
  // data: {
  //   seo: {
  //     metaTags: [
  //       {name: 'title', content: 'Online Classes for K-12 Students, K-12 Classes - buddi Academy'},
  //     {name: 'description', content: 'buddi.life teaching students for digital learning online classes. buddi.life K 5-12 school portion in digital class. K-12 program have best instructors to ensure that your fundamentals are built strongly'},
  //     {name: 'keywords', content: 'k-12 digital learning, k 12 online school class,digital learning platform k 12 online learning school'}
  //     ]
  //   }
  // }
  // },
  // { path: 'K-12', component: BuddiK12Component },
  { path: 'buddi-terms-and-conditions', component: FooterTermsandconditionComponent },
  { path: 'teacher/mydashboard', component: MyDashboardPageComponent },
  { path: 'student/mydashboard', component: StudentProfileComponent },
  { path: 'invite/:id', component: InvitemeetingComponent },
  // { path: 'JEE', component: JeeComponent },
  // { path: 'JEE/:id', component: JeeComponent,
  // data: {
  //   seo: {
  //     metaTags: [
  //       {name: 'title', content: 'Best JEE Mains & Advanced Online Classes , JEE Classes - buddi Academy'},
  //     {name: 'description', content: 'buddi.life  JEE  Digital Learning integrated Program is available for Class 10, 11 and 12. Program is taught by the best tutors to ensure that you score high in the JEE exam.'},
  //     {name: 'keywords', content: 'JEE Digital Learning Program, JEE Online Digital Learning Classes, JEE Digital Coaching Program, Online JEE Digital Coaching Program , JEE Digital Learning Program Class 10, 11 12 '}
  //     ]
  //   }
  // }
  // },
  // { path: 'NEET', component: NeetComponent },
  // { path: 'NEET/:id', component: NeetComponent,
  // data: {
  //   seo: {
  //     metaTags: [
  //       {name: 'title', content: 'Best NEET Crash Course, Crash Courses for NEET - buddi Academy'},
  //     {name: 'description', content: 'buddi.life helps School students for NEET Digital coaching for online classes. buddi.life NEET digital learning preparation class for budding doctors.'},
  //     {name: 'keywords', content: 'NEET digital learning, NEET online class,  NEET digital learning platform Students, NEET online preparation school students, NEET Digital learning MBBS-BDS'}
  //     ]
  //   }
  // }
  // },  
  // { path: 'Coding', component: CodingComponent },
  // { path: 'Coding/:id', component: CodingComponent,
  // data: {
  //   seo: {
  //     metaTags: [
  //       {name: 'title', content: 'Best Coding Classes for Kids, Kids for Coding - buddi Academy'},
  //     {name: 'description', content: 'buddi.life teaching kids and students for Digital learning of game development and MOBILE APP Development.  buddi.lifeGame & Mobile APP development program develops you in Increasinglogical-thinking skills, develop analytical-thinking skills, enhances problem-solving skills, boosts creativity, computational-thinking skills.'},
  //     {name: 'keywords', content: 'digital learning GAME development , digital learning  MOBILE APPdevelopment, digital training program game development, digital training program Mobile APP development.'}
  //     ]
  //   }
  // }
  // },
  { path: 'create-meeting', component: CreatemettingComponent },
  { path: 'create-roundtable', component: CreateRoundtableComponent },
  { path: 'create-studyhall', component: CreateStudyHallComponent },
  { path: 'create-studyhall/:id', component: CreateStudyHallComponent },
  { path: 'create-roundtable/:id', component: CreateRoundtableComponent },
  { path: 'create-webinar', component: CreateWebinarComponent },
  { path: 'create-liveclass', component: CreateLiveclassComponent },
  { path: 'meeting-edit/:id', component: CreatemettingComponent },
  { path: 'create-course', component: CreateCourseComponent },
  { path: 'courses-list', component: CoursesListComponent },
  { path: 'teacher/edit-profile', component: EditProfileComponent },
  { path: 'student/edit-profile', component: StudentEditProfileComponent },
  { path: 'course-portal-online', component: HomePageTwoComponent },
  // {path: 'distant-learning', component: HomePageThreeComponent},
  { path: 'modern-teaching', component: HomePageFourComponent },
  { path: 'teacher/grp-user-create', component: GroupCreateComponent },
  { path: 'student/grp-user-create', component: GrpUserCreateStudentComponent },
  { path: 'teacher/forgot-password', component: ForgetpasswordComponent },
  { path: 'teacher/forgot-password/:id', component: ForgetpasswordComponent },
  { path: 'student/forgot-password', component: StudentforgetpasswordComponent },
  { path: 'student/forgot-password/:id', component: StudentforgetpasswordComponent },
  { path: 'remote-training-online', component: HomePageSevenComponent },
  { path: 'teacher/login', component: LoginPageComponent },
  { path: 'teacher/login/:id', component: LoginPageComponent },
  { path: 'student/login', component: StudentloginComponent },
  { path: 'student/login/:id', component: StudentloginComponent },
  { path: 'teacher/register', component: RegisterPageComponent },
  { path: 'student/register', component: StudentRegisterPageComponent },
  { path: 'error-404', component: ErrorPageComponent },
  { path: 'coming-soon', component: ComingSoonPageComponent },
  { path: 'courses-list', component: CoursesListPageComponent },
  { path: 'courses/:id/:id', component: CourseDetailsComponent },
  { path: 'exams/:id/:id', component: ExamDetailsComponent },
  { path: 'enroll/:id', component: CoursesDetailsPageComponent },
  { path: 'student/one2oneAccept/:id', component: MeetingDetailsComponent },
  { path: 'student/viewRequest/:id', component: MeetingDetailsComponent },
  { path: 'student/meetingDetails/:id', component: MeetingDetailsComponent },
  { path: 'teacher/meetingDetails/:id', component: CoursesDetailsPageComponent },
  { path: 'teacher/request/:id', component: CoursesDetailsPageComponent },
  { path: 'teacher-video-upload', component: TeacherVideoUploadComponent},
  {path: 'teacher-video-upload/:id', component: TeacherVideoUploadComponent},
  {path: 'teacher-video-upload/:categoryid/:moduleid/:courseid/:topicid', component: TeacherVideoUploadComponent},
  { path: 'teacher/accept/:id', component: CoursesDetailsPageComponent },
  { path: 'teacher/requests', component: TeacherRequestsComponent },
  { path: 'teacher/logoutmeeting/:id', component: CoursesDetailsPageComponent },
  { path: 'student/logoutmeeting/:id', component: MeetingDetailsComponent },
  { path: 'logoutmeeting/:id', component: CoursesDetailsPageComponent },
  { path: 'buddi-management-team/:name', component: BuddiTeamDetailsComponent },
  { path: 'buddi-teachers-team/:name', component: BuddiTeacherDetailsComponent},
  { path: 'buddi-advisor-details/:name', component: BuddiAdvisorsDetailsComponent },
  { path: 'buddigroup/:id', component: BuddigroupComponent },
  { path: 'buddigroupaccept/:id', component: BuddigroupComponent },
  { path: 'school-registration', component: SchoolRegistrationComponent },
  { path: 'thanks-school-registration', component: ThanksSchoolRegistrationComponent },
  { path: 'buddigroup/logoutmeeting/:id', component: BuddigroupComponent },
  { path: 'buddiLive', component: BuddiLiveRedirectComponent },
  { path: 'buddilive', component: BuddiLiveRedirectComponent },
  { path: 'students-register', component: StudentsRegisterComponent },
  { path: 'thanks-student-registration', component: ThanksStudentRegistrationComponent },
  { path: 'buddi-test-details/:id', component: BuddiTestComponent },
  { path: 'buddi-test/:id', component: BuddiTestPageComponent},
  { path: 'buddi-test-list/:name/:id', component: BuddiTestListComponent},
  { path: 'buddi-test-correction/:id', component: BuddiTestCorrectionComponent},
  { path: 'buddi-test-request/:name', component:TestsRequestComponent},
  { path: 'buddi-test-request/:id/:name', component:BuddiTestDetailsComponent},
  { path: 'student-test-details/:id', component:StudentTestDetailsComponent},
  { path: 'student-test-result/:id',component:StudentTestResultComponent},
  { path: 'buddi-test-review/:id',component:BuddiTestReviewComponent},
  { path: 'evaluation-test-details/:id',component:EvaluationTestDetailsComponent},
  { path: 'uploaded-video-details/:id', component: UploadedVideoDetailsComponent}, 
  { path: 'student-video-details/:id', component: StudentVideoDetailsComponent},
  { path: 'upload-class-course', component: UploadClassCourseComponent},
  { path: 'class-course-list/:id', component: ClassCourseListComponent},
  { path: 'ClassCourseDetails/:id', component: ClassCourseDetailsComponent},
  {path: 'StudentCourseDetails/:id', component: StudentCourseDetailsComponent},
  { path: 'Teacher-my-learning', component: TeacherMyLearningComponent},
  { path: 'teacher-smartmaterial-upload', component: UploadTeacherSmartmaterialComponent},
  {path: 'teacher-smartmaterial-edit/:id', component: UploadTeacherSmartmaterialComponent},
  {path: 'teacher-smartmaterial-upload/:categoryid/:moduleid/:courseid/:topicid', component: UploadTeacherSmartmaterialComponent},
  {path: 'teacher-smartmaterial-details/:id', component: SmartmaterialsDetailsComponent},
  {path: 'teacher-test-upload', component: TeacherTestUploadComponent},
  {path: 'teacher-test-upload/:categoryid/:moduleid/:courseid/:topicid', component: TeacherTestUploadComponent},
  {path: 'teacher-test-edit/:id', component: TeacherTestUploadComponent},
  {path: 'teacher-test-details/:id', component: TeacherTestDetailsComponent},
  {path: 'test-details-student/:id', component: TestDetailsStudentComponent},
  {path: 'student-start-test/:id', component: StudentStartTestComponent},
  {path: 'teacher-correction-page/:id', component: TeacherCorrectionPageComponent},
  {path: 'student-correction-result/:id', component: StudentCorrectionResultComponent},
  {path: 'studyhall-details/:id', component: StudyhallDetailsComponent},
  {path: 'roundtable-details/:id', component: RoundtableDetailsComponent},
  {path: 'live-class-details/:id', component: LiveClassDetailsComponent},
  {path: 'studyhall-request-details/:id', component: StudyclassRequestDetailsComponent},
  {path: 'roundtable-request-details/:id', component: RoundclassRequestDetailsComponent},
  {path: 'live-class-request-details/:id', component: LiveClassRequestDetailsComponent},
  {path: 'live-class-request-listing/:id', component: LiveClassRequestListingComponent},
  {path: 'studyhall-request-list/:id', component: StudyhallRequestListComponent},
  {path: 'roundtable-request-list/:id', component: RoundtableRequestListComponent},
  {path: 'learning-tools', component: LearningToolsComponent},
  {path: 'request-live-class/:id', component: StudentRequestLiveClassComponent},
  { path: '**', component: ErrorPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }