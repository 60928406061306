
<app-header-style-one-teacher></app-header-style-one-teacher>

<!-- <div class="page-title-area page-title-style-three item-bg4 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a >Home</a></li>
                <li>Class</li>
            </ul>
            <h2>Create Round Table</h2>
        </div>
    </div>
</div> -->
<section class="login-area container">
  <div class="row m-0">
      <div class="col-lg-12 col-md-12 p-0">
          <div class="login-content">
              <div class="d-table">
                  <div class="d-table-cell">
                      <div class="login-form">

                        <br>
                        <h3 class="row">
                          <span class="col-lg 10">
                            <i class="fas fa-film"></i>
                            <span class="ml-4">Create Round Table</span> 
                          </span>
                          <button  class="default-btn col-lg-2  mr-5" (click)="backto()">Back</button>
                        </h3>
                          <!-- <button  class="default-btn float-right mr-1" (click)="backto()">Back</button> -->
                          <form [formGroup]="meetingForm" >
                            <div class="row mx-0 ">
                            </div>
                            <div class="row mx-0">
                              <div class="col-md-6 description">
                                <div class="form-group col-md-12">
                                  <input type="text"  maxlength="40" placeholder="Class Name" formControlName="meetingName" class="meeting-des form-control" [ngClass]="{ 'is-invalid': submitted && f.meetingName.errors }" >
                                  <div *ngIf="submitted && f.meetingName.errors" class="invalid-feedback">
                                    <div *ngIf="f.meetingName.errors?.required">Class Name is required</div>
                                    <div *ngIf="f.meetingName.errors?.maxlength">Class Name can be max 40 characters long.</div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6 description">
                                <div class="form-group col-md-12">
                                  <input type="text" readonly formControlName="meetingId"  class="meeting-des form-control" [ngClass]="{ 'is-invalid': submitted && f.meetingId.errors }" required>
                                  <div *ngIf="submitted && f.meetingId.errors" class="invalid-feedback">
                                    <div *ngIf="f.meetingId.errors.required">Class Id is required</div>
                                  </div>
                                </div>
                              </div>
                              <!-- <div class="col-md-6 description">
                                <div class="form-group col-md-12">
                                <input type="text" class="meeting-she form-control"   placeholder="Select Date & Time" formControlName="ScheduleDate"
                                 [owlDateTimeTrigger]="dtdp"  [owlDateTime]="dtdp"  [min]="min" [ngClass]="{ 'is-invalid': submitted && f.ScheduleDate.errors }"/>
                                <span><owl-date-time #dtdp  [hour12Timer]="true"></owl-date-time></span>
                                <div *ngIf="submitted && f.ScheduleDate.errors" class="invalid-feedback">
                                  <div *ngIf="f.ScheduleDate.errors.required">Date & Time is required</div>
                                </div>
                              </div>
                              </div> -->
                              <div class="col-md-6 description">
                                <div class="form-group col-md-12">
                                <input type="text" class="meeting-she form-control"   placeholder="Select Date & Time From ~ To" formControlName="ScheduleDate"
                                [owlDateTimeTrigger]="dtRange1"  [selectMode]="'range'"  [owlDateTime]="dtRange1" [min]="min" [ngClass]="{ 'is-invalid': submitted && f.ScheduleDate.errors }"/>
                                <span><owl-date-time #dtRange1 [hour12Timer]="true"></owl-date-time></span>
                                <div *ngIf="submitted && f.ScheduleDate.errors" class="invalid-feedback">
                                  <div *ngIf="f.ScheduleDate.errors.required">Date & Time is required</div>
                                </div>
                              </div>
                              </div>
                             
                              <div class="col-md-6 description display">
                                <div class="form-group col-md-12">
                                  <input value="Webinar" maxlength="40"  type="text" formControlName="meetingType"  class="meeting-des form-control">
                                  <!-- <div *ngIf="submitted && f.meetingType.errors" class="invalid-feedback">
                                    <div *ngIf="f.meetingType.errors.required">meetingType is required</div> -->
                                 </div>
                                 </div>
                                <!-- <div class="form-group col-md-12">

                                 
                                  <select class="form-control col-md-12" formControlName="meetingType">
                                    <option [value]="'Public'">Public</option>
                                    <option [value]="'Group'" *ngIf="groupname !=''">School</option>
                                  </select>
                                  <span *ngIf="groupname !=''">
                                    School Short Name ({{groupname}})
                                  </span>
                                </div> -->

                                <!-- <div class="form-group col-md-12">
                                  <select class="form-control col-md-12"  formControlName="meetingType">   
                                  <option value="Webinar" >Round Table</option>
                                  </select>
                                </div>
                              </div> -->
                              <!-- <div class="col-md-6 description"  *ngIf="isClassRoom" >

                              <div class="form-group col-md-12">
                              
                                <select class="form-control col-md-12"  formControlName="selectDays">       
                                <option value="">Select Days</option>
                                <option value="Monday" >Monday</option>
                                <option value="Tuesday">Tuesday</option>
                                <option value="Wednesday">Wednesday</option>
                                <option value="Thursday">Thursday</option>
                                <option value="Friday">Friday</option>
                                <option value="Saturday">Saturday</option>
                                <option value="Sunday">Sunday</option>
                                </select>

                              </div>
                              </div> -->
                              <!-- <div class="col-md-3 description">
                                <div class="form-group col-md-12">
                                <input type="text" class="meeting-she form-control"   placeholder="From time" formControlName="ScheduleDate"
                             [owlDateTime]="dt2"[owlDateTimeTrigger]="dt2"  [min]="min" [ngClass]="{ 'is-invalid': submitted && f.ScheduleDate.errors }"/>
                                <span><owl-date-time #dt2  [pickerType]="'timer'"></owl-date-time></span>
                                <div *ngIf="submitted && f.ScheduleDate.errors" class="invalid-feedback">
                                  <div *ngIf="f.ScheduleDate.errors.required"> Time is required</div>
                                </div>
                              </div>
                              </div> -->
                        

                              <!-- <input placeholder="Time:"
               [(ngModel)]="dateTime2"
               [owlDateTimeTrigger]="dt2" [owlDateTime]="dt2">
        <owl-date-time [pickerType]="'timer'" #dt2></owl-date-time> -->
                              <div class="col-md-6 description">
                                <div class="form-group col-md-12">
                                  <input placeholder="Full Name" maxlength="40" [ngClass]="{ 'is-invalid': submitted && f.fullName.errors }" type="text" formControlName="fullName"  class="meeting-des form-control">
                                  <div *ngIf="submitted && f.fullName.errors" class="invalid-feedback">
                                    <div *ngIf="f.fullName.errors.required">Full Name is required</div>
                                  </div>
                                </div>
                              </div>
                  
                              <div class="col-md-6 description" *ngIf="coursecategorylist.length !=0">
                                <div class="form-group col-md-12">
                                  <select class="form-control col-md-12" (change)="selectChange($event)" formControlName="courseCategory" >
                                    <option value="" selected>Select Category</option>
                                    <option *ngFor="let obj of coursecategorylist" [ngValue]="obj._id">{{obj.categoryName}}</option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-md-6 description" *ngIf="gradeapi.length !=0">
                                <div class="form-group col-md-12">
                                  <select class="form-control col-md-12" (change)="selectChange($event)" formControlName="courseGrade" >
                                    <option value="" disabled>Select Grade</option>
                                    <option *ngFor="let obj of gradeapi" [ngValue]="obj._id">{{obj.gradeName}}</option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-md-6 description" *ngIf="courseboardlist.length !=0">
                                <div class="form-group col-md-12">
                                  <select class="form-control col-md-12" (change)="selectChange($event)" formControlName="courseBoard" >
                                    <option value="" disabled>Select Board</option>
                                    <option *ngFor="let obj of courseboardlist" [ngValue]="obj._id">{{obj.boardName}}</option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-md-6 description" *ngIf="coursesubjectlist.length !=0">
                                <div class="form-group col-md-12">
                                  <select class="form-control col-md-12" (change)="selectChange($event)" formControlName="courseSubject" >
                                    <option value="" disabled>Select Subject</option>
                                    <option *ngFor="let obj of coursesubjectlist" [ngValue]="obj._id">{{obj.subjectName}}</option>
                                  </select>
                                </div>
                              </div>

                              <div class="col-md-6 description">
                              <div class="form-group col-md-12">
                                <div class="input-group">
                                  <div class="custom-file">
                                    <input type="hidden" formControlName="fileupload"  name="" value="">
                                    <input type="file"  class="custom-file-input" accept="image/*" (change)="fileChange($event)" id="inputGroupFile01"
                                      aria-describedby="inputGroupFileAddon01" accept="image/*">
                                    <label class="custom-file-label" for="inputGroupFile01">{{selectedFileNmae}}</label>
                                  </div>
                                </div>
                                </div>
                              </div>


                              <div class="col-md-6 description" *ngIf="languageapi.length !=0">
                                <div class="form-group col-md-12">
                                  <select class="form-control col-md-12" (change)="selectChange($event)" formControlName="courseLanguage" >
                                    <option value="" disabled>Select Language</option>
                                    <option *ngFor="let obj of languageapi" [ngValue]="obj._id">{{obj.languageName}}</option>
                                  </select>
                                </div>
                              </div>
                              <!-- <div class="col-md-6 description">
                                <div class="form-group col-md-12">
                                  <input type="text"  placeholder="City" formControlName="city"  class="meeting-des form-control" >

                                </div>
                              </div>
                              <div class="col-lg-6">
                                <div class="form-group col-md-12">
                                <ng-select [items]="alltag"
                                     bindLabel="name"
                                     bindValue="name"
                                     [multiple]="true"
                                     formControlName="tag"
                                     placeholder="Select Tag"
                                     maxSelectedItems ='6'
                                     [(ngModel)]="selecttag"
                                     >
                                    </ng-select>
                                </div>
                              </div> -->
                              <!-- <div class="col-md-6 description">
                                <div class="form-group col-md-12"> -->
                                  <!-- <label>Re-occurance</label> -->
                                  <!-- <select class="form-control col-md-12"  formControlName="occurance"
                                  >
                                  <option value="">Select Re-occurance</option>
                                  <option value="Daily">Daily</option>
                                  <option value="Weekly" >Weekly</option>
                                  <option value="Monthly">Monthly</option>
                                  </select>
                                </div>
                              </div> -->
                              <div class="col-md-6 description">
                                <div class="form-group col-md-12">
                                  <!-- <label>Duration of Meeting</label> -->
                                  <select class="form-control col-md-12"  formControlName="grouplevel"
                                  >
                                  <option  value="">Pricing Level</option>
                                  <option value="Level1">Level1</option>
                                  <option value="Level2" >Level2</option>
                                  <option value="Level3">Level3</option>
                                  <option value="Level4">Level4</option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-md-6 description">
                                <div class="form-group col-md-12">
                                  <!-- <label>Duration of Meeting</label> -->
                                  <select class="form-control col-md-12"  formControlName="meetduration"
                                  >
                                  <option  value="">Select Duration of Class</option>
                                  <option value="30">30</option>
                                  <option value="60" >60</option>
                                  <option value="90">90</option>
                                  <option value="120">120</option>
                                  </select>
                                </div>
                              </div>
                              <!-- <div class="col-md-6 description">
                                <div class="form-group col-md-12">
                                  <select class="form-control col-md-12"  (change)="selectAmount($event)" formControlName="meetpricetype"
                                  >
                                  <option value="Free">Free</option>
                                  <option value="Premium">Premium</option>
                                  </select>
                                </div>
                              </div> -->
                              <div class="col-md-6 description" *ngIf="isamount">
                                <div class="form-group col-md-12">
                                  <input type="text" placeholder="Amount(INR)" (keypress)="numberOnly($event)" placeholder="Amount" formControlName="meetamount"   pattern="^[0-9]*$" class="meeting-des form-control" >
                                </div>
                              </div>


                            </div>






                            <div class="col-md-6 description">
                              <div class="form-group col-md-12">
                                <!-- <label>Meeting Description</label> -->
                                <textarea type="text" maxlength="120"  placeholder="Class Description" formControlName="meetdescription"  class="meeting-des form-control" >

                                </textarea>
                              </div>

                            </div>
                            <div class="text-center row mt-3">
                              <div class="col-lg-5">

                              </div>
                              <button class="default-btn col-lg-2 mb-1 mr-1"
                                  (click)="onSubmit()"
                                  id = "registerButton"
                                   >
                                   <i class='bx bx-save icon-arrow before'></i>
                                   <span class="label">{{ this.editData._id ? 'Update Class' : 'Create Round Table'}}</span>
                                   <i class="bx bx-save icon-arrow after"></i>
                                  </button>
                              <!-- <div class="col-md-3 ml-3 description">
                               

                              </div>
                              <div class="col-md-3 description">
                                <div>

                                  
                                 </div>
                              </div> -->
                            </div>
                            <!-- <div class="row mx-0 mb-1">
                              <div class="col-md-3 ml-3 description">
                              

                              </div>
                              <div class="col-md-3 description">
                                <div>

                                 
                                 </div>
                              </div>
                            </div> -->
                          </form>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
	<img src="assets/img/spinner-default.gif" alt="">
</ngx-spinner>
