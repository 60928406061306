<app-header-style-one-teacher></app-header-style-one-teacher>
<section class="register-area bodyHeight">
  <div class="container">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="register-image">
                <img src="../../../../../assets/img/tutor_register.jpeg" alt="image">
            </div>
        </div>
        <div class="col-lg-6 col-md-12 p-0">
            <div class="register-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="register-form">
                              <!-- <div class="row ml-4">
                               <button routerLink="/teacher/register" class=" col-lg-5 btn btnType2 btn-block my-4 loginButtonselfpage">
                                Teacher
                              </button>
                              <span class="col-lg-1">
                              </span>
                              <button routerLink="/student/register" class=" col-lg-5 btn btnType2 btn-block my-4 loginbutton">
                                Student
                              </button>
                            </div> -->
                            <h3 id="header">Sign up</h3>
                            <div class="connect-with-social row">
                              <div class="col-lg-6">
                                <button class="facebook" style="background: #00adee;border: 1px solid;" (click)="teacherLogin()">
                                  <span>Tutor</span>
                                </button>
                              </div>
                              <div class="col-lg-6">
                                <button class="google mb-1 " style="background: #fff;color: #222;border: 1px solid black;" (click)="studentLogin()">
                                   <span>Learner</span>
                                </button>
                              </div>
                            </div>
                          
                            <div class="registerForm"  id="registerFormIDt">
                                <div class="tab-content" id="registerTabContent">
                                  <div
                                    class="tab-pane fade show active"
                                    id="userregister"
                                    role="tabpanel"
                                    aria-labelledby="userregister-tab"
                                  >
                                    <form class="p-4 mt-0" [formGroup]="registerform">
                                      <div class="row">
                                        <!-- <div class="col-lg-6">
                                          <div class="form-group">
                                            <input
                                              type="text"
                                              id="registerFormfirstname"
                                              class="form-control"
                                              placeholder="First Name"
                                              formControlName="first_name" maxlength="25"
                                              [ngClass]="{'is-invalid': !f.first_name.pristine && f.first_name.errors}"
                                            />
                                            <div *ngIf="!f.first_name.pristine && f.first_name.errors" class="invalid-feedback">
                                              <div *ngIf="f.first_name.errors?.required" >First Name is Required</div>
                                              <div *ngIf="f.first_name.errors?.pattern"> First name can only have letters</div>
                                              <div *ngIf="f.first_name.errors.cannotContainSpace">First Name cannot contain spaces.</div>
                                          </div>
                                          </div>
                                        </div> -->
                                        <!-- <div class="col-lg-6">
                                          <div class="form-group">
                                            <input
                                              type="text" maxlength="25"
                                              id="registerFormlastname"
                                              class="form-control"
                                              placeholder="Last Name"
                                              formControlName="last_name" maxlength="25"
                                              [ngClass]="{'is-invalid': !f.last_name.pristine && f.last_name.errors}"
                                            />
                                            <div *ngIf="!f.last_name.pristine && f.last_name.errors" class="invalid-feedback">
                                              <div *ngIf="f.last_name.errors?.required" >Last Name is Required</div>
                                              <div *ngIf="f.last_name.errors?.pattern"> Last name can only have letters</div>
                                              <div *ngIf="f.last_name.errors.cannotContainSpace">Last Name cannot contain spaces.</div>
                                            </div>
                                          </div>
                                        </div> -->
                                      </div>
                                      <div class="form-group">
                                        <input
                                          type="text"
                                          id="number"
                                          class="form-control"
                                          formControlName="mobileNumber1"
                                          placeholder="Mobile Number *"
                                          [ngClass]="{'is-invalid': !f.mobileNumber1.pristine && f.mobileNumber1.errors}"
                                        />
                                        <div *ngIf="!f.mobileNumber1.pristine && f.mobileNumber1.errors" class="invalid-feedback">
                                          <div *ngIf="f.mobileNumber1.errors.required">Mobile No is Required</div>
                                          <div *ngIf="f.mobileNumber1.errors.pattern">Enter only number</div>
                                          <div *ngIf="f.mobileNumber1.errors.minlength">Mobile No must have 10 digits</div>
                                          <div *ngIf="f.mobileNumber1.errors.maxlength">Mobile No cannot have more than 20 digits</div>
                                      </div>
                                      </div>
                                      <div class="form-group">
                                        <input
                                          type="text" maxlength="25"
                                          id="registerFormprofilename"
                                          class="form-control"
                                          placeholder="Profile Name *"
                                          formControlName="Profile_name" maxlength="25"
                                          [ngClass]="{'is-invalid': !f.Profile_name.pristine && f.Profile_name.errors}"
                                        />
                                        <div *ngIf="!f.Profile_name.pristine && f.Profile_name.errors" class="invalid-feedback">
                                          <div *ngIf="f.Profile_name.errors?.required" >Profile Name is Required</div>
                                          <div *ngIf="f.Profile_name.errors?.pattern"> Profile name can only have letters</div>
                                        </div>
                                      </div>
                                      <div class="form-group">
                                        <input
                                          type="email"
                                          id="registerFormEmail"
                                          placeholder="Email Id"
                                          class="form-control"
                                          pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$"
                                          formControlName="email"
                                        />
                                        <div *ngIf="f.email.errors && !f.email.pristine" class="invalid-feedback">
                                          <div *ngIf="f.email.errors?.required" >Email is required</div>
                                          <div *ngIf="f.email.errors?.pattern">Email must be a valid email</div>
                                      </div>
                                      </div>
                                   
                                       <div class="form-group">
                                       <pwEye (eyePassword)="receivedData($event)"></pwEye>
                                          <input [type]="cpasswordEye"
                                            id="registerFormPassword"
                                            name="password"
                                            placeholder="Password"
                                            autocomplete="new-password"
                                            class="form-control"
                                            formControlName="passWord"
                                               [ngClass]="{'is-invalid': !f.passWord.pristine && f.passWord.errors}">
                                        
                                            <div *ngIf="!f.passWord.pristine && f.passWord.errors" class="invalid-feedback">
                                              <div *ngIf="f.passWord.errors.required" >Password is Required</div>
                                              <div *ngIf="f.passWord.errors?.minlength">Must be atleast 6 characters</div>
                                              <div *ngIf="f.passWord.errors.pattern">
                                                  must contain one number<br>
                                                  must contain atleast 1 letter in capital case<br>
                                                  must contain atleast 1 letter in small case<br>
                                                  must contain atleast one special character<br>
                                              </div>
                                            </div>
                                           
                                      </div>
                                      <div class="form-group">
                                        <!-- <label>Email</label> -->
                                        <input
                                          type="text"
                                          maxlength="25"
                                          id="registerFormreferralid"
                                          placeholder="buddi referral I'd"
                                          class="form-control"
                                          formControlName="referralId"
                                        />
                                      </div>
                      
                                      <!-- <div class="form-group">
                                        <input
                                          [type]="cpasswordEye"
                                          id="registerFormCheckPassword"
                                          class="form-control"
                                          placeholder="Confirm Password"
                                          formControlName="checkpassWord"
                                          [ngClass]="{'is-invalid': !f.checkpassWord.pristine && f.checkpassWord.errors}"
                                        />
                                     
                                        <div *ngIf="!f.checkpassWord.pristine && f.checkpassWord.errors" class="invalid-feedback">
                                          <div *ngIf="f.checkpassWord.errors?.required" >Confirm Password is Required</div>
                                      </div>                                       
                      
                                      </div>        --> 
                                      <button class="btn btnType2 btn-block my-4"
                                      (click)="RegisterSubmit()"
                                      id = "registerButton"
                                       >                      
                                        Register
                                      </button>

                                      <p id="signedin">Already signed up? <a (click)="teacher()">Log in</a></p>
                                      <!-- <p class="regLink mt-3">
                                        Already have buddi account? <a routerLink="/teacher/login">Login</a>
                                      </p> -->
                                    </form>
                                  </div>
                                </div>
                              </div>
                              <!-- <div class="registerForm"  id="OTPFormIDt">
                                <div class="tab-content" id="registerTabContent">
                                  <div
                                    class="tab-pane fade show active"
                                    id="userregister"
                                    role="tabpanel"
                                    aria-labelledby="userregister-tab"
                                  >
                                  </div>
                                </div>
                              </div> -->

                              <div class="registerForm"  id="OTPFormIDt">

                                <!-- <h3>Enter OTP</h3> -->
                                <div class="tab-content" id="registerTabContent">
                                  <div
                                    class="tab-pane fade show active"
                                    id="userregister"
                                    role="tabpanel"
                                    aria-labelledby="userregister-tab"
                                  >
                                    <form class="pl-4  pr-4 m-0" [formGroup]="otpForm">
                                      <div class="row">
                                        <div class="col-lg-12">
                                          <p>An OTP has been sent to {{phoneNumber}}. If this is not your number please  
                                          <a [ngClass]="{'md-btn md-btn-success': !isChangeNumberButtonDisabled,
                                            'md-btn disabled': isChangeNumberButtonDisabled }" >
                                          <span id = "changeNumberButton" (click)="changePhoneNumber()" style="color:rgb(4, 31, 184);"><b class="hover">click here</b></span>
                                          </a>
                                          </p>
                                        </div>

                                        <!-- <div class="form-group"> -->
                                          <!-- <label>Mobile Number</label> -->
                                          <!-- <input
                                            type="text" value= {{phoneNumber}}
                                            id="number"
                                            class="form-control"
                                            formControlName="mobileNumber1"
                                            placeholder="Mobile Number"
                                            [ngClass]="{'is-invalid': !f.mobileNumber1.pristine && f.mobileNumber1.errors}"
                                          />
                                        </div> -->
                                        <div class="col-lg-8">
                                          <div class="form-group">
                                            <input
                                              type="text"
                                              id="registerFormOtp"
                                              class="form-control"
                                              placeholder="OTP"
                                              formControlName="otp"
                                              [ngClass]="{'is-invalid': !otpf.otp.pristine && otpf.otp.errors}"
                                            />
                                            <div *ngIf="!otpf.otp.pristine && otpf.otp.errors" class="invalid-feedback">
                                              <div *ngIf="otpf.otp.errors.required">
                                                OTP is Required
                                              </div>
                                              <div *ngIf="otpf.otp.errors.maxlength">
                                                OTP Can only hve 6 digits
                                              </div>
                                              <div *ngIf="otpf.otp.errors.minlength">
                                                OTP must have 6 digits
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <button class="btn btnType2 btn-block my-4"
                                      id = "otpButton"
                                      (click)="OTPSubmit()">
                                      Submit
                                    </button>
                                     <p id = "otpResendTimer" >{{timeLeft}} Seconds Left....</p> 
                                     <a [ngClass]="{'md-btn md-btn-success': !isOTPButtonDisabled,
                                    'md-btn disabled': isOTPButtonDisabled }" >
                                      <p id = "otpResendButtont" (click)="otpResendClicked()">Didn't Receive the code? <b class="hover">Resend OTP</b></p>
                                    </a>
                                    <p id = "otpSentSuccess">OTP Sent!</p> 
                                    </form>
                                  </div>
                                </div>
                              </div>
                              <div class="registerForm"  id="successmessage">
                                <!-- <h3>Form Submitted!</h3> -->
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</section>