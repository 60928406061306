import { Component, OnInit,ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Options } from 'ng5-slider';
import { ApiService } from '../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { Location } from '@angular/common';
import * as moment from 'moment';
import { MediaService } from '../../../shared/media.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ViewChild } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-kyc',
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.scss']
})
export class KycComponent implements OnInit {

 	kycForm: FormGroup;
 	public isSubmit:Boolean = false;
 	formData;
 	public kycStatus:Boolean = false;
 	public newKyc:Boolean = false;
 	public isTermsAnd:Boolean = false;
  public isRejected:Boolean = false;
  selectedgradelist = [];
  selectedsubjectlist = [];
  selectedboardlist = [];
  listsubjects : any;
  listgrade : any;
  listboard : any;
  dropdowngradeSettings: any = {};
  dropdownboardSettings: any = {};
  dropdownsubjectSettings: any = {};
  public currentOccupation:any = [];
  public engagementList:any = [];
  public internetConnectionList:any = [];
  public packageInterestList:any = [];
  public currentOccupationChecked:any= [];
  public selection:any = [];
  public formData1;
  public getAvailableTimesList:any = [];


  public currentOccupationList:any = [];
  public modeofEngagementArray:any = [];
  public packageInterestArray:any = [];
  public internetConnectionArray:any = [];
  public getAvailableTimesUSArray:any = [];
  public getAvailableTimesArray:any = [];
  public availableDeviceList:any = [];
  public availableDeviceArray:any = [];
  // public getAvailableTimesUSArray:any = [];
  // public getAvailableTimesArray:any = [];
  public mergeTwoField:any = [];
  	constructor(private mediaAPI:MediaService,private location:Location,private my_router: ActivatedRoute,public toastr: ToastrManager, private formBuilder: FormBuilder, private rest: ApiService, private router: Router,private SpinnerService: NgxSpinnerService,private sanitizer: DomSanitizer,private datePipe: DatePipe) { }

  	ngOnInit(): void {  
  	  if(!localStorage.getItem('webtoken')){
  		  this.router.navigate(['/teacher/login'])
  	  }		
      this.getAllCheckboxDetails()
this.dropdowngradeSettings = {
  singleSelection: false,
  idField: '_id',
  textField: 'gradeName',
  selectAllText: 'Select All',
  unSelectAllText: 'UnSelect All',
  itemsShowLimit: 3
};

this.dropdownboardSettings = {
  singleSelection: false,
  idField: '_id',
  textField: 'boardName',
  selectAllText: 'Select All',
  unSelectAllText: 'UnSelect All',
  itemsShowLimit: 3
};

this.dropdownsubjectSettings = {
  singleSelection: false,
  idField: '_id',
  textField: 'subjectName',
  selectAllText: 'Select All',
  unSelectAllText: 'UnSelect All',
  itemsShowLimit: 3
};

      const webToken = localStorage.getItem('webtoken');
      this.kycStatusCall();
  		this.kycForm = this.formBuilder.group({
  			passportPhoto: ['', Validators.required],
  			passportPhotoFilename:'Choose File',
  			addressProofFront: ['', Validators.required],
  			addressProofFrontFilename: 'Choose File',
  			addressProofBack: ['', Validators.required],
  			addressProofBackFilename: 'Choose File',
  			panCard: ['', Validators.required],  			
  			panCardFilename: 'Choose File',
  			underGraduates: ['', Validators.required],
  			underGraduatesFilename: 'Choose File',
  			postGraduates: ['', Validators.required],
  			postGraduatesFilename: 'Choose File',
  			yearofexperience: ['', Validators.required],
  			yearofexperienceFilename: 'Choose File',
  			agree:['', Validators.required],
        gender:['', Validators.required],
        currentOccupation:['', Validators.required],
        modeofEngagement:['', Validators.required],
        packageInterest:['', Validators.required],
        internetConnection:['', Validators.required],
        availableDevice:['', Validators.required],
        location:['', Validators.required],
        resume:['', Validators.required],
        resumeFilename : 'Choose File',
        getAvailableTimesUS:[''],
        getAvailableTimes:[''],
        phonemic:['Yes']
      });
      console.log('dffdfdfdfdffkycdata',this.kycForm)

      this.SpinnerService.show();
      this.rest.studentSubject(webToken).subscribe((result) =>{
       this.SpinnerService.hide();
        console.log('subject',result.data[0].subjectName)
        this.listsubjects = result.data;
      })
      this.SpinnerService.show();
      this.rest.board(webToken).subscribe((result) =>{
        this.SpinnerService.hide();
        console.log('boards',result.data)
        this.listboard = result.data;
      })
      this.SpinnerService.show();
      this.rest.gradefield().subscribe((result) =>{
         this.SpinnerService.hide();
        console.log('grades',result.data)
        this.listgrade = result.data;
      });
  	}
    getAllCheckboxDetails(){
      this.SpinnerService.show();
      const webToken = localStorage.getItem('webtoken');
      this.rest.getAllList(webToken).subscribe((result) =>{
        this.SpinnerService.hide();
        if(result.status){
          this.currentOccupation = result.data.Occupation ? result.data.Occupation : [];
          this.engagementList = result.data.EmployementType ? result.data.EmployementType : [];
          this.packageInterestList = result.data.PackageInterested ? result.data.PackageInterested : [];
          this.internetConnectionList = result.data.InternetConnection ? result.data.InternetConnection : [];
          this.availableDeviceList = result.data.AvailableDevice ? result.data.AvailableDevice : [];
        }
      });
  
      // this.SpinnerService.show();
      // this.rest.getOccupation(webToken).subscribe((result) =>{
      //   this.SpinnerService.hide();
      //   if(result.status){
      //     this.currentOccupation = result.data;
      //   }
      // });
      // this.SpinnerService.show();
      // this.rest.engagementList(webToken).subscribe((result) =>{
      //   this.SpinnerService.hide();
      //   if(result.status){
      //     this.engagementList = result.data;
      //   }
      // });      
      // this.SpinnerService.show();
      // this.rest.internetConnectionList(webToken).subscribe((result) =>{
      //   this.SpinnerService.hide();
      //   if(result.status){
      //     this.internetConnectionList = result.data;
      //   }
      // });
      // this.SpinnerService.show();
      // this.rest.packageInterestList(webToken).subscribe((result) =>{
      //   this.SpinnerService.hide();
      //   if(result.status){
      //     this.packageInterestList = result.data;
      //   }
      // });
      this.SpinnerService.show();
      this.rest.getAvailableTimes(webToken).subscribe((result) =>{
        this.SpinnerService.hide();
        if(result.status){
          this.getAvailableTimesList = result.data;
        }
      });
      // this.rest.getAvailableDeviceList(webToken).subscribe((result) =>{
      //   this.SpinnerService.hide();
      //   if(result.status){
      //     this.availableDeviceList = result.data;
      //   }
      // });
      9894384486
    }
  	get f() { return this.kycForm.controls; }
  	kycStatusCall(){
  		const webToken = localStorage.getItem('webtoken');
  		this.rest.kycStatus(webToken).subscribe((result) => {
  			if(result.status){
  				if(result.data.data !=null){
  					this.kycStatus = result.data.data.isApproved;
  					localStorage.setItem('kycStatus', this.kycStatus ?'true':'false');
  					if(this.kycStatus){
              localStorage.setItem('exp',result.data.data.yearofexperience)
            }           
            if(this.kycStatus){
              localStorage.setItem('kycStatus','true');
  						this.router.navigate(['/sidenav'])
  					}
  				}else{
            this.newKyc = true;
            localStorage.setItem('kycStatus','false');
          }			
  			}else{
  				this.newKyc = true;
          localStorage.setItem('kycStatus','false');
  			}
        if(result.data==undefined){
          // if(result.data.data ==undefined && result.data.data ==null && result.data.kycStatus==null)
          this.newKyc = true;
          localStorage.setItem('kycStatus','false');
        }else if(result.data!=undefined){
          if(result.data.kycStatus!=null){
            this.isRejected = result.data.kycStatus.rejected
            localStorage.setItem('kycStatus',!this.isRejected?'true':'false');
          }
        }
        
  		});
  	}
  	fileChange(event) {
  		var imageSize = 2;  		
  		if(event.target.id =='passportPhoto'){
  			imageSize = 1;
  		}
  		if(event.target.files[0].size/1024/1024 <imageSize){
  			if(event.target.files[0].type =='image/jpeg' || event.target.files[0].type =='image/png' || event.target.files[0].type=='application/pdf'){
  				let fileList: FileList = event.target.files;
    			this.kycForm.patchValue({ [event.target.id]: fileList[0] });
    			var uploadName = [event.target.id]+'Filename';
    			this.kycForm.patchValue({ [uploadName]: fileList[0].name});;
  			}else{
  				this.toastr.errorToastr('Invalid File Format')
  			}	
  		}else{
  			this.toastr.errorToastr('File Size Should be max'+imageSize+' MB')
  		}  	
  	}
  	submitProof(){
  		this.isSubmit = true;
          this.currentOccupationList = this.currentOccupation.filter(function(val,key){
            return val.selected;
          })
          this.modeofEngagementArray = this.engagementList.filter(function(val,key){
            return val.selected;
          })
          this.packageInterestArray = this.packageInterestList.filter(function(val,key){
            return val.selected;
          })
          this.internetConnectionArray = this.internetConnectionList.filter(function(val,key){
            return val.selected;
          })
          
          this.getAvailableTimesUSArray = this.getAvailableTimesList.US.filter(function(val,key){
            return val.selected;
          })
          this.getAvailableTimesArray = this.getAvailableTimesList.other.filter(function(val,key){
            return val.selected;
          })
          this.availableDeviceArray = this.availableDeviceList.filter(function(val,key){
            return val.selected;
          })

          this.mergeTwoField = [...this.getAvailableTimesUSArray,...this.getAvailableTimesArray]
          console.log('checkingarrayavailableDeviceArray', this.availableDeviceArray.length ==0) 
          console.log('checkingarrayselectedgradelist', this.selectedgradelist.length ==0)
          console.log('checkingarrayselectedboardlist', this.selectedboardlist.length==0) 
          console.log('checkingarrayselectedsubjectlist', this.selectedsubjectlist.length==0)   
          console.log('checkingarraykycForm.valid', !this.kycForm.valid)
          console.log('checkingarraykycForm.valid',  this.isSubmit)
          console.log('checkingarrayisTermsAnd', !this.isTermsAnd) 
          console.log('checkingarraykycForm.value.agree', !this.kycForm.value.agree) 

  		  if(this.availableDeviceArray.length ==0 || this.selectedgradelist.length ==0 || this.selectedboardlist.length==0 || this.selectedsubjectlist.length==0 || (!this.kycForm.valid && this.isSubmit) || !this.isTermsAnd || !this.kycForm.value.agree){
  			  if((!this.isTermsAnd) ||(!this.kycForm.value.agree)){
            this.toastr.errorToastr('Please Validate Terms  Conditions')
          }
          return false;

  		  }else{   
  			  const webToken = localStorage.getItem('webtoken');
  			  this.formData = new FormData();
      		this.formData.append("passportPhoto", this.kycForm.value.passportPhoto);
      		this.formData.append("addressProofFront", this.kycForm.value.addressProofFront);
      		this.formData.append("addressProofBack", this.kycForm.value.addressProofBack);
      		this.formData.append("panCard", this.kycForm.value.panCard);
      		this.formData.append("underGraduates", this.kycForm.value.underGraduates);
      		this.formData.append("postGraduates", this.kycForm.value.postGraduates);
      		this.formData.append("yearofexperience", this.kycForm.value.yearofexperience);    
     		  this.formData.append("currentOccupation", (this.currentOccupationList.length!=0 ? JSON.stringify(this.currentOccupationList):''));      
          this.formData.append("modeofEngagement", (this.modeofEngagementArray.length!=0 ? JSON.stringify(this.modeofEngagementArray):''));
          this.formData.append("packageInterest", (this.packageInterestArray.length!=0 ? JSON.stringify(this.packageInterestArray):''));
          this.formData.append("internetConnection", (this.internetConnectionArray.length!=0 ? JSON.stringify(this.internetConnectionArray):''));
          this.formData.append("availableTimes",  (this.mergeTwoField.length!=0 ? JSON.stringify(this.mergeTwoField):''));
          this.formData.append("availableDevice", (this.availableDeviceArray.length!=0 ? JSON.stringify(this.availableDeviceArray):''));
                    
          this.formData.append("location", this.kycForm.value.location);
          this.formData.append("resume", this.kycForm.value.resume);
          this.formData.append("grades", (this.selectedgradelist.length!=0 ? JSON.stringify(this.selectedgradelist):''));
          this.formData.append("boards", (this.selectedboardlist.length!=0 ? JSON.stringify(this.selectedboardlist):''));
          this.formData.append("subjects", (this.selectedsubjectlist.length!=0 ?JSON.stringify(this.selectedsubjectlist):''));
          this.formData.append("gender", this.kycForm.value.gender);
          this.formData.append("phonemic", this.kycForm.value.phonemic);
          
          this.SpinnerService.show();
  			  this.rest.addkyc(webToken,this.formData).subscribe((result) => {
  				this.SpinnerService.hide();
  				if(result.status){
  					this.toastr.successToastr(result.message);
  					localStorage.setItem('kycStatus', this.kycStatus ?'true':'false');
  					this.kycStatus = false;
  					this.newKyc = false;
  					//this.router.navigate(['/success/kyc']);
            this.router.navigate(['/sidenav']);
  				}else{
  					this.toastr.errorToastr(result.error);
  				}
  			});
  // this.formData1 = {
  //   grades: this.selectedgradelist,
  //   boards: this.selectedboardlist,
  //   subjects: this.selectedsubjectlist,
  //   gender:this.kycForm.value.gender,

  // } 
  
  //   this.rest.userData_UpdateTeacher(this.formData1, webToken).subscribe((response) => {
     
  //   });
  		}
  	}
  
  	termsAndCondition(){
  		this.isTermsAnd = true;
  		window.open('assets/pdf/ONLINE CONTRACT TUTOR AGREEMENT.pdf', '_blank');
  	}

  onItemboardSelect(item: any) {
 //   this.selectedboardlist.push(item)
  }
 
  onItemboardDeSelect(item1: any) {
   this.selectedboardlist = this.selectedboardlist.filter(item => !item1._id.includes(item))
  }

  onItemboardallDeSelect(item1: any) {
    for (let i = 0; i < item1.length; i++) {
      this.selectedboardlist = this.selectedboardlist.filter(item => !item1[i]._id.includes(item))
    };
  }
  onItemboardallSelect(item1: any) {
    this.selectedboardlist = [];
    for (let i = 0; i < item1.length; i++) {
      this.selectedboardlist.push(item1[i])
    };
  }

  onItemsubjectSelect(item: any) {
  //  this.selectedsubjectlist.push(item)
  }
 
 onItemsubjectDeSelect(item1: any) {
   this.selectedsubjectlist = this.selectedsubjectlist.filter(item => !item1._id.includes(item))
 }

 onItemsubjectallDeSelect(item1: any) {
  for (let i = 0; i < item1.length; i++) {
    this.selectedsubjectlist = this.selectedsubjectlist.filter(item => !item1[i]._id.includes(item))
    };
}
onItemsubjectallSelect(item1: any) {
  this.selectedsubjectlist = [];
  for (let i = 0; i < item1.length; i++) {
    this.selectedsubjectlist.push(item1[i])
    };
}
onItemgradeSelect(item: any) {
  //this.selectedgradelist.push(item)
}
 
onItemgradeDeSelect(item1: any) {
 this.selectedgradelist = this.selectedgradelist.filter(item => !item1._id.includes(item))
}

onItemgradeallDeSelect(item1: any) {
  for (let i = 0; i < item1.length; i++) {
    this.selectedgradelist = this.selectedgradelist.filter(item => !item1[i]._id.includes(item))
  };
}
onItemgradeallSelect(item1: any) {
  this.selectedgradelist = [];
  for (let i = 0; i < item1.length; i++) {
    this.selectedgradelist.push(item1[i])
    };
}
checkBoxClick(data,id){
  console.log(data);
  var list = $('.'+id);
  for(let i=0;i<list.length-1;i++){
    console.log(list[i])
  }

}
getSelection(item,event) {
  item.selected = !item.selected;
}
}
