<app-header-style-one-teacher></app-header-style-one-teacher>

<!-- <div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About</li>
            </ul>
            <h2>About Us</h2>
        </div>
    </div>
</div> -->

<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <!-- <img src="../../../../assets/img/black-logo.png" class="shadow" alt="image"> -->
                    <img src="../../../../assets/img/aboutus.jpg" class="shadow" alt="image">
                  
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">About Us</span>
                    <p>Scentric Networks has launched buddi.life, the lifelong learning platform. Buddi.life is a fully integrated learning platform connecting teachers and students to provide the best learning experience.  Scentric Networks is started by Alumnus of prestigious institutions like Indian Institute of Science, Indian School of Business, Drexel University, University of Plymouth and other great universities. Scentric Network team bring over solid experience in technology, education and business to provide best value for its stake holders.</p>
                    <p>Buddi offers learning in K12, NEET, JEE and Programming. Buddi offers this in a unique way to make sure progress is guaranteed for each student.</p>
                   
                </div>
            </div>
        </div>

    </div>
</section>