
<section class="courses-area pt-100 pb-70">
    <div class="container">
        <div class="tab courses-list-tab">
            <!-- <ul class="tabs">
                <li><a href="#">JEE</a></li>
                <li><a href="#">NEET</a></li>
                <li><a href="#">CODING</a></li>
            </ul> -->
            <a *ngIf="isLogin =='true' && isLogin!=undefined" routerLink="/student/myprofile" class="default-btn float-right ml-1">My Profile</a>
            <ul *ngIf="isLogin =='true' && isLogin!=undefined" class="tabs nav nav-tabs">
                <li class="active"><a data-toggle="tab" href="#home" class="active">Sessions</a></li>
                <li><a data-toggle="tab" href="#menu2">Popular Video</a></li>
                <li><a data-toggle="tab" href="#menu4">Audio</a></li>
                

            </ul>
    <div class="tab-content myDashboard-content">
        <div id="home" class="tab-pane active">
            <div class="container-fluid">
                <div class="row">
                    
                    <div class="col-lg-3 col-md-6" *ngFor="let slide of meetingsData " >
                        <div class="single-courses-box without-box-shadow mb-30">
                            <div class="courses-image">
                                <a  (click)="detailsMeeting(slide,'Livesession')" class="d-block"><img [src]="slide.image =='' ? 'assets/img/courses/courses1.jpg':slide.image" alt="image"></a>
                                <div class="courses-tag">
                                    <a (click)="detailsMeeting(slide,'Livesession')" class="d-block">{{slide.meetingID}}</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img [src]="slide.teacherID!=undefined && slide.teacherID.userPhoto!='' ? 'assets/img/user1.jpg'  :'assets/img/user1.jpg'" class="rounded-circle mr-2" alt="image">
                                    <span *ngIf="slide.teacherID!=undefined">{{slide.teacherID.firstName}}  {{slide.teacherID.lastName}} {{myId==slide.teacherID._id ?'(Mine)':''}}</span>
                                </div>
                                <h3><a (click)="detailsMeeting(slide,'Livesession')" class="d-inline-block"><span >{{slide.name}} - {{slide.ScheduleDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</span></a></h3>
                           
                            </div>
        
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <!-- <i class='bx bx-user'></i> 10 students -->
                                    </li>
                                    <li class="courses-lesson">
                                        <!-- <i class='bx bx-book-open'></i> 6 lessons -->
                                    </li>
                                    <li class="courses-price">
                                        {{slide.meetingPriceType}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="meetingsData.length ==0">
                        Currently Session not available.
                    </div>
                </div>
            </div>
        </div>
        <!-- <div id="menu2" class="tab-pane fade">
            <div class="recent-orders-table table-responsive">                
            Coming Soon...
            </div>
            
        </div>
        <div id="menu4" class="tab-pane fade">
            <div class="recent-orders-table table-responsive">                
            Coming Soon...
            </div>
            
        </div> -->
    </div>

        </div>
    </div>
</section>

            <!-- <div class="tab-content">
                <div class="tabs-item">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a routerLink="/meetingDetails" class="d-block"><img src="assets/img/courses/courses1.jpg" alt="image"></a>
                                </div>
    
                                <div class="courses-content">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user1.jpg" class="shadow" alt="image">
                                            <span>Steven Smith</span>
                                        </div>
    
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star-half'></i>
                                            </div>
        
                                            <div class="rating-total">
                                                4.5 (2)
                                            </div>
                                        </div>
                                    </div>
    
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Raque Professional IT Expert Certificate Course</a></h3>
                                    <p>Education encompasses both the teaching and learning of knowledge.</p>
                                </div>
    
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 10 students
                                        </li>
    
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 6 lessons
                                        </li>
    
                                        <li class="courses-price">
                                            Free
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses2.jpg" alt="image"></a>
                                </div>
    
                                <div class="courses-content">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user2.jpg" class="shadow" alt="image">
                                            <span>Sarah Taylor</span>
                                        </div>
    
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star-half'></i>
                                            </div>
        
                                            <div class="rating-total">
                                                4.5 (2)
                                            </div>
                                        </div>
                                    </div>
    
                                    <h3><a routerLink="/meetingDetails" class="d-inline-block">Certified Graphic Design with Free Project Course</a></h3>
                                    <p>Education encompasses both the teaching and learning of knowledge.</p>
                                </div>
    
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 15 students
                                        </li>
    
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 10 lessons
                                        </li>
    
                                        <li class="courses-price">
                                            $250
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses3.jpg" alt="image"></a>
                                </div>
    
                                <div class="courses-content">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user3.jpg" class="shadow" alt="image">
                                            <span>Anderson</span>
                                        </div>
    
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bx-star'></i>
                                            </div>
        
                                            <div class="rating-total">
                                                4.0 (1)
                                            </div>
                                        </div>
                                    </div>
    
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Photography Crash Course for Photographer</a></h3>
                                    <p>Education encompasses both the teaching and learning of knowledge.</p>
                                </div>
    
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 5 students
                                        </li>
    
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 5 lessons
                                        </li>
    
                                        <li class="courses-price">
                                            $150
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses4.jpg" alt="image"></a>
                                </div>
    
                                <div class="courses-content">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user4.jpg" class="shadow" alt="image">
                                            <span>Liam King</span>
                                        </div>
    
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bx-star'></i>
                                            </div>
        
                                            <div class="rating-total">
                                                4.0 (1)
                                            </div>
                                        </div>
                                    </div>
    
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Web Design for Developing Technology with Joy</a></h3>
                                    <p>Education encompasses both the teaching and learning of knowledge.</p>
                                </div>
    
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 10 students
                                        </li>
    
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 6 lessons
                                        </li>
    
                                        <li class="courses-price">
                                            <span>$200</span>
                                            $195
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses5.jpg" alt="image"></a>
                                </div>
    
                                <div class="courses-content">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user5.jpg" class="shadow" alt="image">
                                            <span>Lina</span>
                                        </div>
    
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                            </div>
        
                                            <div class="rating-total">
                                                5.0 (1)
                                            </div>
                                        </div>
                                    </div>
    
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Information About UI/UX Design Degree</a></h3>
                                    <p>Education encompasses both the teaching and learning of knowledge.</p>
                                </div>
    
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 5 students
                                        </li>
    
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 5 lessons
                                        </li>
    
                                        <li class="courses-price">
                                            $178
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
<!--     
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses6.jpg" alt="image"></a>
                                </div>
    
                                <div class="courses-content">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user6.jpg" class="shadow" alt="image">
                                            <span>David Warner</span>
                                        </div>
    
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bx-star'></i>
                                            </div>
        
                                            <div class="rating-total">
                                                4.0 (1)
                                            </div>
                                        </div>
                                    </div>
    
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Photography Photo modify and Beautiful</a></h3>
                                    <p>Education encompasses both the teaching and learning of knowledge.</p>
                                </div>
    
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 11 students
                                        </li>
    
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 1 lessons
                                        </li>
    
                                        <li class="courses-price">
                                            $500
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> -->
                   

                <!-- <div class="tabs-item">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses1.jpg" alt="image"></a>
                                </div>
    
                                <div class="courses-content">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user1.jpg" class="shadow" alt="image">
                                            <span>Steven Smith</span>
                                        </div>
    
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star-half'></i>
                                            </div>
        
                                            <div class="rating-total">
                                                4.5 (2)
                                            </div>
                                        </div>
                                    </div>
    
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Raque Professional IT Expert Certificate Course</a></h3>
                                    <p>Education encompasses both the teaching and learning of knowledge.</p>
                                </div>
    
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 10 students
                                        </li>
    
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 6 lessons
                                        </li>
    
                                        <li class="courses-price">
                                            Free
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses2.jpg" alt="image"></a>
                                </div>
    
                                <div class="courses-content">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user2.jpg" class="shadow" alt="image">
                                            <span>Sarah Taylor</span>
                                        </div>
    
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star-half'></i>
                                            </div>
        
                                            <div class="rating-total">
                                                4.5 (2)
                                            </div>
                                        </div>
                                    </div>
    
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Certified Graphic Design with Free Project Course</a></h3>
                                    <p>Education encompasses both the teaching and learning of knowledge.</p>
                                </div>
    
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 15 students
                                        </li>
    
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 10 lessons
                                        </li>
    
                                        <li class="courses-price">
                                            $250
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses3.jpg" alt="image"></a>
                                </div>
    
                                <div class="courses-content">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user3.jpg" class="shadow" alt="image">
                                            <span>Anderson</span>
                                        </div>
    
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bx-star'></i>
                                            </div>
        
                                            <div class="rating-total">
                                                4.0 (1)
                                            </div>
                                        </div>
                                    </div>
    
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Photography Crash Course for Photographer</a></h3>
                                    <p>Education encompasses both the teaching and learning of knowledge.</p>
                                </div>
    
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 5 students
                                        </li>
    
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 5 lessons
                                        </li>
    
                                        <li class="courses-price">
                                            $150
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses4.jpg" alt="image"></a>
                                </div>
    
                                <div class="courses-content">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user4.jpg" class="shadow" alt="image">
                                            <span>Liam King</span>
                                        </div>
    
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bx-star'></i>
                                            </div>
        
                                            <div class="rating-total">
                                                4.0 (1)
                                            </div>
                                        </div>
                                    </div>
    
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Web Design for Developing Technology with Joy</a></h3>
                                    <p>Education encompasses both the teaching and learning of knowledge.</p>
                                </div>
    
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 10 students
                                        </li>
    
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 6 lessons
                                        </li>
    
                                        <li class="courses-price">
                                            <span>$200</span>
                                            $195
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses5.jpg" alt="image"></a>
                                </div>
    
                                <div class="courses-content">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user5.jpg" class="shadow" alt="image">
                                            <span>Lina</span>
                                        </div>
    
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                            </div>
        
                                            <div class="rating-total">
                                                5.0 (1)
                                            </div>
                                        </div>
                                    </div>
    
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Information About UI/UX Design Degree</a></h3>
                                    <p>Education encompasses both the teaching and learning of knowledge.</p>
                                </div>
    
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 5 students
                                        </li>
    
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 5 lessons
                                        </li>
    
                                        <li class="courses-price">
                                            $178
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses6.jpg" alt="image"></a>
                                </div>
    
                                <div class="courses-content">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user6.jpg" class="shadow" alt="image">
                                            <span>David Warner</span>
                                        </div>
    
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bx-star'></i>
                                            </div>
        
                                            <div class="rating-total">
                                                4.0 (1)
                                            </div>
                                        </div>
                                    </div>
    
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Photography Photo modify and Beautiful</a></h3>
                                    <p>Education encompasses both the teaching and learning of knowledge.</p>
                                </div>
    
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 11 students
                                        </li>
    
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 1 lessons
                                        </li>
    
                                        <li class="courses-price">
                                            $500
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->

                <!-- <div class="tabs-item">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses1.jpg" alt="image"></a>
                                </div>
    
                                <div class="courses-content">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user1.jpg" class="shadow" alt="image">
                                            <span>Steven Smith</span>
                                        </div>
    
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star-half'></i>
                                            </div>
        
                                            <div class="rating-total">
                                                4.5 (2)
                                            </div>
                                        </div>
                                    </div>
    
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Raque Professional IT Expert Certificate Course</a></h3>
                                    <p>Education encompasses both the teaching and learning of knowledge.</p>
                                </div>
    
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 10 students
                                        </li>
    
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 6 lessons
                                        </li>
    
                                        <li class="courses-price">
                                            Free
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses2.jpg" alt="image"></a>
                                </div>
    
                                <div class="courses-content">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user2.jpg" class="shadow" alt="image">
                                            <span>Sarah Taylor</span>
                                        </div>
    
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star-half'></i>
                                            </div>
        
                                            <div class="rating-total">
                                                4.5 (2)
                                            </div>
                                        </div>
                                    </div>
    
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Certified Graphic Design with Free Project Course</a></h3>
                                    <p>Education encompasses both the teaching and learning of knowledge.</p>
                                </div>
    
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 15 students
                                        </li>
    
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 10 lessons
                                        </li>
    
                                        <li class="courses-price">
                                            $250
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses3.jpg" alt="image"></a>
                                </div>
    
                                <div class="courses-content">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user3.jpg" class="shadow" alt="image">
                                            <span>Anderson</span>
                                        </div>
    
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bx-star'></i>
                                            </div>
        
                                            <div class="rating-total">
                                                4.0 (1)
                                            </div>
                                        </div>
                                    </div>
    
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Photography Crash Course for Photographer</a></h3>
                                    <p>Education encompasses both the teaching and learning of knowledge.</p>
                                </div>
    
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 5 students
                                        </li>
    
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 5 lessons
                                        </li>
    
                                        <li class="courses-price">
                                            $150
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses4.jpg" alt="image"></a>
                                </div>
    
                                <div class="courses-content">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user4.jpg" class="shadow" alt="image">
                                            <span>Liam King</span>
                                        </div>
    
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bx-star'></i>
                                            </div>
        
                                            <div class="rating-total">
                                                4.0 (1)
                                            </div>
                                        </div>
                                    </div>
    
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Web Design for Developing Technology with Joy</a></h3>
                                    <p>Education encompasses both the teaching and learning of knowledge.</p>
                                </div>
    
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 10 students
                                        </li>
    
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 6 lessons
                                        </li>
    
                                        <li class="courses-price">
                                            <span>$200</span>
                                            $195
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses5.jpg" alt="image"></a>
                                </div>
    
                                <div class="courses-content">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user5.jpg" class="shadow" alt="image">
                                            <span>Lina</span>
                                        </div>
    
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                            </div>
        
                                            <div class="rating-total">
                                                5.0 (1)
                                            </div>
                                        </div>
                                    </div>
    
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Information About UI/UX Design Degree</a></h3>
                                    <p>Education encompasses both the teaching and learning of knowledge.</p>
                                </div>
    
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 5 students
                                        </li>
    
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 5 lessons
                                        </li>
    
                                        <li class="courses-price">
                                            $178
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses6.jpg" alt="image"></a>
                                </div>
    
                                <div class="courses-content">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user6.jpg" class="shadow" alt="image">
                                            <span>David Warner</span>
                                        </div>
    
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bx-star'></i>
                                            </div>
        
                                            <div class="rating-total">
                                                4.0 (1)
                                            </div>
                                        </div>
                                    </div>
    
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Photography Photo modify and Beautiful</a></h3>
                                    <p>Education encompasses both the teaching and learning of knowledge.</p>
                                </div>
    
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 11 students
                                        </li>
    
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 1 lessons
                                        </li>
    
                                        <li class="courses-price">
                                            $500
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            <!-- </div>
        </div>
    </div> -->
