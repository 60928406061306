import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from "@angular/common";
import { ToastrManager } from "ng6-toastr-notifications";
import {
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
@Component({
  selector: 'app-demo-class',
  templateUrl: './demo-class.component.html',
  styleUrls: ['./demo-class.component.scss']
})
export class DemoClassComponent implements OnInit {
  public isStudent: boolean = false;
  public isTeacher: boolean = false;
  createForm: FormGroup;
  submitted = false;
  constructor( private router: Router, private location: Location,  private formBuilder: FormBuilder,public toastr: ToastrManager,) { }

  ngOnInit(): void {
    this.isTeacher =
            localStorage.getItem("profileType").toLowerCase() == "teacher";
        this.isStudent =
            localStorage.getItem("profileType").toLowerCase() == "student";
            this.createForm = this.formBuilder.group({
              fname: ["", Validators.required],
              lname: ["", Validators.required],
              Company: ["", Validators.required],
              mail: ["", Validators.required],
              phone: ["", Validators.required],
             
          });
  }

  get f() {
    return this.createForm.controls;
}

  goBack() {
    this.location.back();
}
onSubmit(){
  this.submitted = true;
  console.log("Submit form values",this.createForm.value);
  if (!this.createForm.valid) {
      const controls = this.createForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
              if (name == "fname") {
                  this.toastr.errorToastr("First Name Required");
                  return;
              }
              if (name == "lname") {
                  this.toastr.errorToastr("Last Name Required");
                  return;
              }
              else if (name == "Company") {
                  this.toastr.errorToastr("Company Required");
                  return;
              } else if (name == "mail") {
                  this.toastr.errorToastr("Mail Required");
                  return;
              } else if (name == "phone") {
                  this.toastr.errorToastr("Phone Required");
                  return;
              } else {
                  this.toastr.errorToastr("Invalid Fields");
                  return;
              }
          }
      }
  }
}
}
