import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../shared/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as socketio from 'socket.io-client';
import { environment } from '../../../../environments/environment'
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-metaverse-header',
  templateUrl: './metaverse-header.component.html',
  styleUrls: ['./metaverse-header.component.scss']
})
export class MetaverseHeaderComponent implements OnInit {
  isLogin: any = false;
  studlog:any=true;
  pagename: any;
  loginname: any;
  allheader: any = [];
  cartSize: number;
  public studentData: any = [];
  public coursecategory: any = [];
  public coursecategoryselected: any = [];
  notidata: any = [];
  socket: any;
  public usertype: any = '';
  public isTeacher: boolean = false;
  public notification: any = [];
  public notificationLength: any = [];
  constructor(
    private userApi: ApiService,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    public toastr: ToastrManager,
  ) {
    this.socket = socketio(environment.socket.baseUrl, environment.socket.config);
    this.getmessage().subscribe(data => console.log(data));
  }

  ngOnInit(): void {
    $(function() {
      // whenever we hover over a menu item that has a submenu
      $('li.parent').on('mouseover', function() {
        var $menuItem = $(this),
            $submenuWrapper = $('> .wrapper', $menuItem);
        
        // grab the menu item's position relative to its positioned parent
        var menuItemPos = $menuItem.position();
        
        // place the submenu in the correct position relevant to the menu item
        $submenuWrapper.css({
          top: menuItemPos.top,
          left: menuItemPos.left + Math.round($menuItem.outerWidth() * 0.50)
        });
      });
    });
    var a = window.location.href;
    this.isLogin = localStorage.getItem('isLoggedin');
    if (localStorage.getItem('profileType') != null) {
      this.isTeacher = localStorage.getItem('profileType').toLowerCase() == 'teacher'
    }
    if (this.isTeacher) {0  
      localStorage.clear();

    }
    if (localStorage.getItem('isLoggedin') == null) {
      localStorage.setItem('profileType', 'Student');
      localStorage.setItem('userType', 'student')
      localStorage.setItem('isLoggedin', 'false')
    }
    if (localStorage.getItem('isLoggedin') == 'false') {
      localStorage.setItem('userType', 'student')
    }
    
    if (localStorage.getItem('cartItems') == null) {
      this.cartSize = 0
    }
    else {
      this.cartSize = JSON.parse(localStorage.getItem('cartItems')).length;
    }

    window.addEventListener('storage', () => {
      console.log("called")
    }, false);

    //   this.userApi.getCourseCategoryHeader().subscribe((result) => {
    //     if (result.status) {
    //       this.allheader = result.data;
    //     } else {
    //     }
    // }, (err) => {
    //     console.log(err);
    // });

    // this.userApi.getCourseCategoryModuleHeader().subscribe((result) => {
    //   if (result.status) {
    //     this.coursecategory = result.data.splice(0,10);
    //   } else {
    //   }
    // }, (err) => {
    //   console.log(err);
    // });

    

    // if(this.isTeacher){
    //   localStorage.setItem('profileType','Teacher') 
    // }
    //  if(!this.isTeacher){
    //   localStorage.setItem('profileType','Student') 
    // }
    const webToken = localStorage.getItem('webtoken');
    this.usertype = localStorage.getItem('profileType') == 'Student' ? 'student' : 'teacher'

    // if (!this.isTeacher && this.isLogin) {
    //   this.userApi.userName_DisplayStudent(webToken).subscribe((response) => {
    //     if (response.status) {
    //       this.studentData = response.data;
    //       console.log('students', this.studentData);
    //     }
    //   });
      //  setInterval(()=>{
      //   this.userApi.getstudentnotification(webToken).subscribe((response) => {
      //     if(response.status){
      //       this.notification = response.data.notifyData;
      //       this.notificationLength = response.data.length.length
      //       console.log(response)
      //       console.log(response)
      //     }
      //    })
      //  },3000);

   // }
    // else{
    //   this.userApi.userName_DisplayTeacher(webToken).subscribe((response) => {
    //     if(response.status){
    //     this.studentData = response.data;
    //     console.log('students',this.studentData);
    //     }
    //    });
    // }





    this.pagename = localStorage.getItem('profileType');
    this.loginname = this.pagename + '/login';
    this.isLogin = localStorage.getItem('isLoggedin');
    if (this.isLogin == 'true') {
      this.studlog=false;
      this.getnotificationInterval();
    }else{
      this.studlog=true;
    }

  }
  getnotificationInterval() {
    var userid = localStorage.getItem('userId');


    this.pushData('CREATEROOM', userid);
    this.getmessage().subscribe(data => console.log(data))
    // const webToken = localStorage.getItem('webtoken');
    // setInterval(()=>{
    //  this.userApi.getstudentnotification(webToken).subscribe((response) => {
    //    if(response.status){
    //      this.notification = response.data.notifyData;
    //      this.notificationLength = response.data.Totallength
    //    }
    //   })
    // },3000);
  }
  requestAccept(data) {
    const webToken = localStorage.getItem('webtoken');
    this.userApi.statusChangeNotification(data._id, webToken).subscribe((result) => {
      if (result.status) {
        //this.router.navigate(["/"+data.redirectUrl]);
        window.location.href = data.redirectUrl;
      }
    });
  }
  detailsAccept(data) {
    this.router.navigate(["/myCalendar"]);
  }
  logout() {
    localStorage.setItem('isLoggedin', 'false');
    window.location.href = localStorage.getItem('profileType').toLowerCase() + '/login';
  }
  getData(): Observable<any> {
    return new Observable((observer) =>
      this.socket.on('CREATEROOM', (userid) => observer.next(userid))
      //this.socket.on('SEND_MESSAGE', (data) => observer.next(data))
    );

  }

  getmessage(): Observable<any> {
    return new Observable((observer) =>
      this.socket.on('message', (data) => {
        this.notidata = JSON.stringify(data);
        this.notification = data.notifyData;
        this.notificationLength = data.Totallength;
      })

    );
  }

  listen(event: string): Observable<any> {
    return new Observable(subscriber => {

      this.socket.on(event, data => {
        subscriber.next(data);
      })
    })
  }

  pushData(event: string, data?: any) {
    this.socket.emit(event, data);

  }

  // selectedmenu(categoryid, moduleid) {
  //   this.SpinnerService.show();
  //   var req = {
  //     moduleID : moduleid
  //   }
  //   this.userApi.getClassCourseTemplatefromModule(req).subscribe((result) => {
  //     this.SpinnerService.hide();
  //     if(result.status){
  //       if(result.data.templateList.length > 1){
  //         this.router.navigate(['/class-course-list/' + moduleid]);
  //       }
  //       else{
  //         var templateID = result.data.templateList[0]._id;
  //         if(this.isTeacher){
  //           this.router.navigate(['/ClassCourseDetails/' + templateID]);
  //         }
  //         else{
  //           this.router.navigate(['/StudentCourseDetails/' + templateID]);
  //         }
          
  //       }
  //     }
  //     else{
  //       this.toastr.errorToastr("There was an issue. Please try again later.")
  //     }
  //   });
  // }


}