
import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../../shared/api.service";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { DatePipe } from '@angular/common';
const datepipe: DatePipe = new DatePipe('en-US')

@Component({
  selector: 'app-all-liveclass-listing',
  templateUrl: './all-liveclass-listing.component.html',
  styleUrls: ['./all-liveclass-listing.component.scss']
})
export class AllLiveclassListingComponent implements OnInit {
  constructor(private rest: ApiService, private router: Router) {}
  public classList: any = [];
    public videoList: any = [];
    public WebinarsList: any = [];
    public classroomList: any = [];
    public RoundList: any = [];
    public smartMaterialList: any = [];
    public testList: any = [];
    public mydashboardData: any = [];
    public isTeacher: Boolean = false;
    public isStudent: Boolean = false;
    public isLogin: any = 'false';
    public currentPageNumber : number = 1;
  public totalVideoCount: number;
  public totalPageCount: number;
  public currentVideoCount: number;
  term: string;
  public webinarData: any = [];
  isDesc: any = 1;
  column: string = 'remainingClass';
  p: number = 1;
  count: number = 4;
  ngOnInit() {

    const token = localStorage.getItem("webtoken");
    var request = {};
    this.isTeacher =
        localStorage.getItem("profileType").toLowerCase() == "teacher";
    this.isStudent =
        localStorage.getItem("profileType").toLowerCase() == "student";
    var reqData = {
      "notFetchCompleted": true
    }


    if (this.isTeacher) {
      this.rest
      .getUserPersonalClassRequestsTeacher(token, reqData)
      .subscribe((result) => {
          console.log(result, "result===");
          if (result.status) {
              this.classList =  result.data && result.data.data ? result.data.data.upcoming : [];
              console.log("after respose", this.classList);
              for(var item of this.classList){
                if(item.startDateTime){
                  item.startDateTime = datepipe.transform(item.startDateTime, 'MMM d, y, h:mm a')
                }
              }
          }
      });
} else {

  this.rest
      .getUserPersonalClassRequestsStudent(token, reqData)
      .subscribe((result) => {
          console.log(result, "result");
          if (result.status) {
              this.classList = result.data.data.upcoming;
              console.log("after respose============", this.classList);
              for(var item of this.classList){
                if(item.startDateTime){
                  item.startDateTime = datepipe.transform(item.startDateTime, 'MMM d, y, h:mm a')
                }
              }
          }
      });
    }
  this.getPurchasedSmartMaterial();
  this.getPurchasedVideos();
  this.getPurchasedTests();

  }
   videodetails(data) {
        console.log("routing", data);
        this.router.navigateByUrl("/videoDetails/" + data.videoID._id);
    }

    

    getPurchasedVideos() {
      const webToken = localStorage.getItem("webtoken");
      if(this.isTeacher){
        this.rest.NewVideoListing(webToken, {}).subscribe((response) => {
          console.log("videos", response)
          if(response.status){
            this.videoList = response.data.data;
            for(var video of this.videoList){
              console.log(video.thumbnail != null && video.thumbnail == "")
              if(video.thumbnail != null && video.thumbnail == ""){
                console.log("1")
                video.thumbnail = "assets/img/courses/courses1.jpg"
              }
            }
            console.log(this.videoList)
          }
        })
      }
      else{
        this.rest.getPurchasedVideos(webToken).subscribe((response) => {
          console.log("videos", response)
          if(response.status){
            this.videoList = response.data;
            for(var video of this.videoList){
              console.log(video.thumbnail != null && video.thumbnail == "")
              if(video.thumbnail != null && video.thumbnail == ""){
                console.log("1")
                video.thumbnail = "assets/img/courses/courses1.jpg"
              }
            }
            console.log(this.videoList)
          }
        })
      }
    }

    getPurchasedSmartMaterial() {
      const webToken = localStorage.getItem("webtoken");
      if(this.isTeacher){
        this.rest.NewSmartMaterialListing(webToken, {}).subscribe((response) => {
          
          console.log("material", response)
          if(response.status){
            this.smartMaterialList = response.data.data;
            for(var material of this.smartMaterialList){
              if(material.thumbnail && material.thumbnail == ""){
                material.thumbnail = "slide/img/courses/courses1.jpg"
              }
            }
            console.log(this.smartMaterialList)
          }
        })
      }
      else{
        this.rest.getPurchasedSmartMaterial(webToken).subscribe((response) => {
          console.log("material", response)
          if(response.status){
            this.smartMaterialList = response.data;
            for(var material of this.smartMaterialList){
              if(material.thumbnail && material.thumbnail == ""){
                material.thumbnail = "slide/img/courses/courses1.jpg"
              }
            }
            console.log(this.smartMaterialList)
          }
        })
      }
      
    }
    
    getPurchasedTests() {
        const webToken = localStorage.getItem("webtoken");
  
        this.rest.getPurchasedTests(webToken).subscribe((response) => {
          console.log("test", response)
          if(response.status){
            this.testList = response.data;
            for(var test of this.testList){
              if(test.thumbnail && test.thumbnail == ""){
                test.thumbnail = "slide/img/courses/courses1.jpg"
              }
            }
            console.log(this.testList)
          }
        })
      }

    getClassRoomList() {
        const webToken = localStorage.getItem("webtoken");
        var request = {};
        request["limit"] = environment.listing__itemsPerPage;
        request["page"] = 1;
        this.rest.getClassRoomTeacher(webToken, request).subscribe(
            (response) => {
                console.log("response", response);
                if (
                    response.status &&
                    response.data != null &&
                    response.data.length != 0
                ) {
                    this.classroomList = response.data.classroomsrecord;
                    console.log(this.classroomList, "classroomsrecord");
                    // console.log(this.videoList)
                } else {
                    this.mydashboardData = [];
                }
            },
            (err) => {
                console.log(err);
            }
        );
    }
    testDetails(data) {
        if(this.isTeacher){
            this.router.navigate(['/teacher-test-details/' + data._id], { state: data });
          }
          else{
            this.router.navigate(['/test-details-student/' + data._id], { state: data });
          }
    }
    detailsMeeting(data, pagename) {
        data.pagename = pagename;
        var jsonStr = JSON.stringify(data);
        localStorage.setItem("livedetails", jsonStr);
        this.router.navigate(
            ["/student/meetingDetails/" + "/" + data.meetingID],
            { state: data }
        );
    }
    liveClassDetails(data) {
        if (this.isTeacher) {
            this.router.navigate(["live-class-details/" + data.personalClassID._id]);
        } else {
          console.log('fdfdf')
            this.router.navigate(["live-class-request-details/" + data._id]);
        }
    }
    StudyhallClassDetails(data) {
      if (this.isTeacher) {
        
          this.router.navigate(["studyhall-details/" + data._id]);
      } else {
        console.log('fdfdf')
          this.router.navigate(["studyhall-request-details/" + data._id]);
          // live-class-request-listing
      }
  }
  RoundtableClassDetails(data) {
    if (this.isTeacher) {
        this.router.navigate(["roundtable-details/" + data._id]);
    } else {
      console.log('fdfdf')
        this.router.navigate(["roundtable-request-details/" + data._id]);
    }
}
    detailsVideo(data){
      this.router.navigate(['videoDetails/' + data._id])
    }
    detailsSmartMaterial(data){
      this.router.navigate(['studyMaterialDetails/' + data._id])
    }
    redirectLiveclass(){
      this.router.navigateByUrl('/all-liveclass-listing');
    } 
    redirectStudyhall(){
      this.router.navigateByUrl('/all-studyhall-listing');
    } 
    redirectRoundtable(){
      this.router.navigateByUrl('/all-roundtable-listing');
    } 
  }