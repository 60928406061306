<div class="container"  *ngIf="profiletype=='student'">
    <div class="card-deck">
        <div class="col-lg-4 col-md-6" routerLink="/student-profile" style="cursor:pointer ;">
           <div class="card text-center">
            <img src="../../assets/img/Basic_profile.jpeg" alt="">
            <div class="card-body">
                Basic Profile
            </div>
           </div>
        </div>
        <!-- <div class="col-lg-4 col-md-6" routerLink="/" style="cursor:pointer ;">
            <div class="card text-center">
                <img src="../../assets/img/advanced-profile.jpeg" alt="">
                <div class="card-body">
                    Advanced Profile
                </div>
            </div>
        </div> -->
    </div>
</div>

<div class="container"  *ngIf="profiletype=='teacher'">
    <div class="card-deck">
        <div class="col-lg-4 col-md-6" routerLink="/teacher/mydashboard" style="cursor:pointer ;">
           <div class="card text-center">
            <img src="../../assets/img/Basic_profile.jpeg" alt="">
            <div class="card-body">
                Basic Profile
            </div>
           </div>
        </div>
        <!-- <div class="col-lg-4 col-md-6" routerLink="/profile/advanced" style="cursor:pointer ;">
            <div class="card text-center">
                <img src="../../assets/img/advanced-profile.jpeg" alt="">
                <div class="card-body">
                    Advanced Profile
                </div>
            </div>
        </div> -->
        <div class="col-lg-4 col-md-6" routerLink="/institution-details" style="cursor:pointer ;">
            <div class="card text-center">
                <img src="../../assets/img/Institution.jpeg" alt="">
                <div class="card-body">
                    Institutions
                </div>
            </div>
        </div>
    </div>
</div>

