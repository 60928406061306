import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ApiService } from '../../../shared/api.service';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-one-onone-success',
  templateUrl: './one-onone-success.component.html',
  styleUrls: ['./one-onone-success.component.scss']
})
export class OneOnoneSuccessComponent implements OnInit {
	public isoneonone:boolean = false;
	public meetingnotstart:boolean = false;
  public pagename:String = '';
  public isTeacher:boolean = false;
  public meetingTime:any ='';
  public iskyc:boolean = false;
  public isStudy:boolean = false;
  public isLibrary:boolean = false;
  public enrollclass:boolean = false;
  public coursecategorylist:any = [];
  public message:string = ''
  	constructor(private SpinnerService:NgxSpinnerService,private router:Router,private location:Location,private rest: ApiService) { }

  	ngOnInit(): void {
      this.meetingTime = window.history.state.date!=undefined ? window.history.state.date : '';
      this.message = window.history.state.message!=undefined ? window.history.state.message : '';
      this.isStudy = window.location.href.match('Study')!=null ? true : false;
      this.isLibrary = window.location.href.match('library')!=null ? true : false; 
  		this.isoneonone =  window.location.href.match('oneOnone')!=null ? true : false;
      this.iskyc =  window.location.href.match('kyc')!=null ? true : false;
      this.enrollclass =  window.location.href.match('enrollclass')!=null ? true : false;      
  		this.meetingnotstart =  window.location.href.match('meetingnotstart')!=null ? true : false;
  	  this.pagename = window.location.href.split('/')[window.location.href.split('/').length-1]
      this.isTeacher = localStorage.getItem('profileType').toLowerCase() =='teacher' 
      this.getcoursecategory();
    }
  redirectLocation(routerPath,tabname){
    var data = {
      'pagename':tabname
    }
    var routerData = JSON.stringify(data)
    var isTeacherText =  this.isTeacher ? '/sidenav' : '/sidenav'
    if(this.meetingnotstart){
      this.router.navigate([isTeacherText], { state: data })
    }else{
      this.router.navigate([isTeacherText], { state: data })
    }    
  }
  backtoExplorePage(){
    //this.router.navigate(['/explore/'+this.coursecategorylist[0]._id]);
    this.router.navigate(['/liveClassList'])
  }
  getcoursecategory() {
    this.SpinnerService.show();
    this.rest.getCourseCategoryHeader().subscribe((result) => {
      this.SpinnerService.hide();
      if(result.status){
        this.coursecategorylist = result.data;
      }     
    }, (err) => {
    console.log(err);
    });
  }
}
