<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <!-- <ul>
                <li><a>Home</a></li>
                <li>Dashboard</li>
                <li>Edit</li>
            </ul> -->
            <h2>Learner profile</h2>
            <button class="col-lg-1" *ngIf="isOneonone.pageName!='oneOnone'" (click)="backto()"><i class="fas fa-long-arrow-alt-left mr-1"></i>Back</button>
        </div>
    </div>
</div>

<form [formGroup]="registerForm" id="editForm" (ngSubmit)="onSubmit(registerForm)" class="my-dashboard-area ptb-100">
    <div class="container">
        <div class="myDashboard-profile">
            <div class="row align-items-center">
                <!-- <div class="col-lg-4 col-md-5">
                    <div class="profile-image">
                       
                    </div>
                </div> -->

                <div class="col-lg-12 col-md-12">
                    <div>
                        <!-- <h3 class="titleProfile"><span class=""><span>Profile Id&nbsp;</span></span></h3> -->
                    </div>
                    <div class="row borderpro">
                        <div class="col-lg-12">
                        <div class="myDashboard-navigation">
                            <h5 >Profile Info</h5>
                            <!-- <ul class="nav nav-tabs">
                                <li class="active"><a data-toggle="tab" href="#home" class="active">Contact Info</a></li>
                            </ul> -->
                        </div>
                    </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Profile Image</label>
                                <div class="input-group">
                                    
                                    <div class="custom-file">
                                        <input type="hidden" formControlName="fileupload" name=""
                                            value="">
                                        <input type="file" class="custom-file-input"
                                        (change)="fileChange($event)" id="inputGroupFile01"
                                            aria-describedby="inputGroupFileAddon01" accept="image/*"
                                            [ngClass]="{ 'is-invalid': submitted && f.fileupload.errors}">
                                        <label class="custom-file-label"
                                            for="inputGroupFile01">{{selectedFileName}}</label>
                                    </div>
                                    <div *ngIf="submitted && f.fileupload.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="f.fileupload.errors.required">Profile Image is
                                            required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label >First Name</label>
                                <input type="text" formControlName="firstname" class="form-control" maxlength="25"
                                class="form-control"  [ngClass]="{'is-invalid': !f.firstname.pristine && f.firstname.errors}" />
                                   
                                <div *ngIf="f.firstname.touched && f.firstname.invalid" class="invalid-feedback">
                                    <div *ngIf="f.firstname.errors.required">First Name is required
                                    </div>
                                    <div *ngIf="f.firstname.errors.maxlength">only 25 characters are allowed</div>
                                    <div *ngIf="f.firstname.errors.cannotContainSpace">First Name cannot contain spaces.</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label >Last Name</label>
                            <div class="form-group">
                                
                                <input type="text"  formControlName="lastname" class="form-control"  maxlength="25"
                                    class="form-control"  [ngClass]="{'is-invalid': !f.lastname.pristine && f.lastname.errors}" />                                   
                                    <div *ngIf="f.lastname.touched && f.lastname.invalid" class="invalid-feedback">
                                        <div *ngIf="f.lastname.errors.required">Last Name is required
                                        </div>
                                        <div *ngIf="f.lastname.errors.maxlength">only 25 characters are allowed</div>
                                        <div  *ngIf="f.lastname.errors.cannotContainSpace">Last Name cannot contain spaces.</div>
                                    </div>
                                </div>
                        </div>
                        <div class="col-sm-6">
                            <label >Gender</label>
                            <div class="form-group">
                                
                                <select class="browser-default custom-select" formControlName="gender">
                                    <option value="" selected>Select Gender</option>
                                    <option>Male</option>
                                    <option>Female</option>
                                    <option>Rather not say</option>
                                </select>
                                <!-- <div *ngIf="submitted && f.gender.errors" class="invalid-feedback">
                                    <div *ngIf="f.gender.errors.required"> &nbsp; Gender is required
                                    </div>
                                </div> -->
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <label >Short Name</label>
                            <div class="form-group">
                                <!-- <label>Last Name</label> -->
                                <input placeholder="School Name" type="text" formControlName="schoolName" maxlength="40"
                                    class="form-control" />
                            </div>
                        </div>
                        <!-- <div class="col-sm-6">
                            <div class="form-group">
                                <input id="dob" name="dob" placeholder="Date of Birth" type="date" formControlName="dob"
                                     class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.dob.errors }" />
                                <div *ngIf="submitted && f.dob.errors" class="invalid-feedback">
                                    <div *ngIf="f.dob.errors.required">Date of Birth is required</div>
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="col-sm-6">
                            <div class="form-group">
                                <textarea                                            
                                placeholder="Describe yourself here..."
                                rows="2" cols="50"
                                maxlength="100"
                                formControlName="bio"
                                class="form-control"></textarea>                        
                            </div>
                        </div>                 -->
                    </div>
                </div>
            </div>
        </div>

        <div class="myDashboard-navigation">
            <h5 >Contact Info</h5>
            <!-- <ul class="nav nav-tabs">
                <li class="active"><a data-toggle="tab" href="#home" class="active">Contact Info</a></li>
            </ul> -->
        </div>
        <div class="tab-content myDashboard-content">
            <div id="home" class="tab-pane active">
                <div class="row borderpro">
                    <div class="col-sm-6">
                        <label >Email-Id</label>
                        <div class="form-group">
                            <input type="text" formControlName="email" class="form-control" />
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <label >Mobile Number</label>
                        <div class="form-group">
                            <input type="text" formControlName="Mobilenumber" class="form-control" />
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <label >Address 1</label>
                        <div class="form-group">
                            <input type="text" formControlName="address1" placeholder="Address 1" maxlength="100" class="form-control" />
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <label >Address 2</label>
                        <div class="form-group">
                            <input type="text" formControlName="address2" placeholder="Address 2"  maxlength="100" class="form-control" />
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label >City/District/Town</label>
                        <div class="form-group">
                            <input type="text" placeholder="City/District/Town"  maxlength="40" formControlName="city"
                                class="form-control" />
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label >State</label>
                        <div class="form-group">
                            <input type="text" placeholder="State" formControlName="state"  maxlength="40" class="form-control" />
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label >Country</label>
                        <div class="form-group">
                        <select formControlName="countries" class="browser-default custom-select">
                        <option value="" selected>Select Country</option>
                        <option *ngFor="let countries of listcountries">{{countries.name}}</option>
                        
                    </select>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label > Code</label>
                        <div class="form-group">
                            <input type="number" placeholder="Area Code" formControlName="area" maxlength="10"
                                class="form-control" />
                        </div>
                    </div>                
                  
                 
                    <div class="col-lg-12">
                        <div class="myDashboard-navigation">
                            <h5 >Guardian Info</h5>
                            <!-- <ul class="nav nav-tabs">
                                <li class="active"><a data-toggle="tab" href="#home" class="active">Contact Info</a></li>
                            </ul> -->
                        </div>
                    </div>
                   
                    <br>
                    <div class="col-sm-6">
                        <label >Guardian Name</label>

                        <div class="form-group">

                            <input type="text" placeholder="Guardian Name" formControlName="guardianName"
                                class="form-control"  maxlength="40" />
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label >Guardian Phone Number</label>

                        <div class="form-group">

                            <input type="number" placeholder="Guardian Phone Number" formControlName="guardianPhone"
                                class="form-control"  maxlength="15" />
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <!-- <div class="myDashboard-navigation">
                            <ul class="nav nav-tabs">
                                <li class="active"><a data-toggle="tab" href="#home" class="active">Education Info</a></li>
                            </ul>
                        </div> -->
                        <div class="myDashboard-navigation">
                            <h5 >Education Info</h5>
                            <!-- <ul class="nav nav-tabs">
                                <li class="active"><a data-toggle="tab" href="#home" class="active">Contact Info</a></li>
                            </ul> -->
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label >Categories</label>
                        <div class="form-group">
                        <select formControlName="categories" class="browser-default custom-select">
                        <option disabled value="">Select Categories</option>
                        <option *ngFor="let obj of listcategories" [ngValue]="obj._id">{{obj.categoryName}}</option>
                        
                    </select>
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <label >Grades</label>
                        <div class="form-group">
                        <select formControlName="grades" class="browser-default custom-select">
                        <option disabled value="">Select Grade</option>
                        <option *ngFor="let obj of listgrade" [ngValue]="obj._id">{{obj.gradeName}}</option>
                        
                    </select>
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <label >Boards</label>
                        <div class="form-group">
                        <select formControlName="boards" class="browser-default custom-select">
                        <option disabled  value="" >Select Board</option>
                        <option *ngFor="let obj of listboard" [ngValue]="obj._id">{{obj.boardName}}</option>
                        
                    </select>
                        </div>
                    </div>
                    <div class="col-sm-6" >
                        <label >Subjects</label>
                        <div class="form-group">
                        <select formControlName="subjects" class="browser-default custom-select" >
                        <option disabled value="" >Select Subject</option>
                        <option *ngFor="let obj of listsubjects" [ngValue]="obj._id">{{obj.subjectName}}</option>
                        
                    </select>
                        </div>
                    </div>

                    <div class="col-sm-6" >
                        <label >Languages</label>
                        <div class="form-group">
                        <select formControlName="languages" class="browser-default custom-select" >
                            
                            <option disabled value="">Select Languages</option>
                            <option *ngFor="let obj of listlanguages"  [ngValue]="obj._id">{{obj.languageName}}</option>
                        </select>
                        </div>
                    </div>
<!-- 
                    <div class="col-sm-6"> 
                        <div class="form-group">
                        <select formControlName="board" class="browser-default custom-select">
                        <option disabled>Select Board</option>
                        <option *ngFor="let board of listboard">{{board.boardName}}</option>
                        
                    </select>
                        </div>
                    </div> -->
                    <!-- <div class="col-sm-6">
                        <div class="form-group">
                            <input type="number"  placeholder = "Postal Code" formControlName="postalCode"
                                class="form-control"/>
                        </div>
                    </div> -->
                    <!-- <div class="col-sm-6">
                        <div class="form-group">
                            <input type="text" placeholder="Current City" formControlName="currentCity"
                                class="form-control"/>
                        </div>
                    </div> -->
                </div>
                <button  class="col-lg-2 float-right">Update Profile &nbsp;<i
                        class="fas fa-long-arrow-alt-right"></i></button>
            </div>

            <div id="menu1" class="tab-pane fade">
                <div class="row borderpro">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <!-- <label>Qualification</label> -->
                            <input type="text" placeholder="Qualification" formControlName="qualification"
                                maxlength="25" class="form-control" />
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <!-- <label>Profession</label> -->
                            <input type="text" placeholder="Profession" formControlName="profession" maxlength="25"
                                class="form-control" />
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <!-- <label>Current Education</label> -->
                            <input type="text" placeholder="Current Education" formControlName="currentEducation"
                                maxlength="25" class="form-control" />
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <!-- <label>Preferences</label> -->
                            <input type="text" placeholder="Preferences" formControlName="preferences" maxlength="40"
                                class="form-control" />
                        </div>
                    </div>
                </div>
                <button class="col-lg-2 float-right">Update Profile &nbsp;<i
                        class="fas fa-long-arrow-alt-right"></i></button>
            </div>

            <div id="menu3" class="tab-pane fade">
                <div class="row borderpro">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <!-- <label>Facebook</label> -->
                            <input type="text" placeholder="Paste your Facebook URL here" maxlength="100"
                                formControlName="facebook" class="form-control" />
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <!-- <label>Twitter</label> -->
                            <input type="text" placeholder="Paste your Twitter URL here" maxlength="100"
                                formControlName="twitter" class="form-control" />
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <!-- <label>Instagram</label> -->
                            <input type="text" placeholder="Paste your Instagram URL here" maxlength="100"
                                formControlName="instagram" class="form-control" />
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <!-- <label>LinkedIn</label> -->
                            <input type="text" placeholder="Paste your LinkedIn URL here" maxlength="100"
                                formControlName="linkedIn" class="form-control" />
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <!-- <label>You Tube</label> -->
                            <input type="text" placeholder="Paste your You Tube URL here" maxlength="100"
                                formControlName="youtube" class="form-control" />
                        </div>
                    </div>
                </div>
                <button  class="col-lg-2 float-right">Update Profile &nbsp;<i
                        class="fas fa-long-arrow-alt-right"></i></button>
            </div>

        </div>
        <!-- <div class="myDashboard-content">
            <p>Hello <strong>James Anderson</strong> (not <strong>James Anderson</strong>? <a routerLink="/my-dashboard">Log out</a>)</p>
            <p>From your account dashboard you can view your <a routerLink="/orders">recent orders</a>, manage your <a routerLink="/edit-address">shipping and billing addresses</a>, <a routerLink="/edit-account">edit your password</a>, and <a routerLink="/edit-account">account details</a>.</p>
            <h3>Recent Orders</h3>
            <div class="recent-orders-table table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Order</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Total</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>#074145O5</td>
                            <td>October 18, 2019</td>
                            <td>Completed</td>
                            <td>$49.00 for 1 item</td>
                            <td><a routerLink="/single-courses" class="view-button">View</a></td>
                        </tr>
                        <tr>
                            <td>#074145O6</td>
                            <td>October 18, 2019</td>
                            <td>On Hold</td>
                            <td>$49.00 for 1 item</td>
                            <td><a routerLink="/single-courses" class="view-button">View</a></td>
                        </tr>
                        <tr>
                            <td>#074145O7</td>
                            <td>October 18, 2019</td>
                            <td>Completed</td>
                            <td>$49.00 for 1 item</td>
                            <td><a routerLink="/single-courses" class="view-button">View</a></td>
                        </tr>
                        <tr>
                            <td>#074145O5</td>
                            <td>October 18, 2019</td>
                            <td>Completed</td>
                            <td>$49.00 for 1 item</td>
                            <td><a routerLink="/single-courses" class="view-button">View</a></td>
                        </tr>
                        <tr>
                            <td>#074145O6</td>
                            <td>October 18, 2019</td>
                            <td>On Hold</td>
                            <td>$49.00 for 1 item</td>
                            <td><a routerLink="/single-courses" class="view-button">View</a></td>
                        </tr>
                        <tr>
                            <td>#074145O7</td>
                            <td>October 18, 2019</td>
                            <td>Completed</td>
                            <td>$49.00 for 1 item</td>
                            <td><a routerLink="/single-courses" class="view-button">View</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div> -->
    </div>
</form>

<div class="modal" id="myModalcheck" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">                      
                <span class="bold">please select subject grade language board and categories</span>
            </div>
            <!-- <span class="modal-body bold text-primary" > -->
                <!-- <commaFor [forData]="reviewLike"></commaFor> -->
                <!-- <span *ngFor="let item of reviewLike">{{item.name}},</span> -->
            <!-- </span> -->
            <div class="modal-footer p-0">
                <button type="button" (click)="closePopup1()" class="btn font12  btn-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
  
    </div>
</div>
<div  class="modal modelHeight" id="myModal1">
    <div class="modal-dialog">
        <div class="modal-content">
            <!--  <div class="modal-body text-danger">
                Your One-on-One free classes Credit has been Completed.! Please Subscribe and Continue for the buddi Online Classes.
            </div> -->
             <div class="modal-body text-danger">
                Please update your Education info. in My Profile. Click Ok to Continue.
            </div>
            <div class="modal-footer p-0">
                <button type="button" (click)="closePopup()" class="btn font12  btn-secondary" data-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>