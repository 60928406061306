import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ChartsModule } from 'ng2-charts';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from 'ngx-perfect-scrollbar';

// import { EmptyObjectPipe } from '../custom-pipes/empty-object.pipe';
import { ShareModuleRoutingModule } from './share-module-routing.module';
//import { AppModule } from '../../app.module';
import { HeaderStyleOneComponent } from '../../components/common/header-style-one/header-style-one.component';
import { ChapterTopicComponent } from './chapter-topic/chapter-topic.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { SubjectNavComponent } from './subject-nav/subject-nav.component';
import { ListingComponent } from './listing/listing.component';
import { DashboardChartComponent } from './dashboard-chart/dashboard-chart.component';

import { StudentDashboardComponent } from './student-dashboard/student-dashboard.component';
// import { StudentProfileComponent } from './student-profile/student-profile.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false,
};

@NgModule({
  declarations: [
    // HeaderStyleOneComponent,
    ChapterTopicComponent,
    SidenavComponent,
    SubjectNavComponent,
    ListingComponent,
    DashboardChartComponent,
    StudentDashboardComponent,
    // StudentProfileComponent,
    
  ],
  imports: [
    CommonModule,
    // EmptyObjectPipe,
    ChartsModule,
    ShareModuleRoutingModule,
    NgApexchartsModule,
    FormsModule, 
    ReactiveFormsModule,
    MatProgressBarModule,
    PerfectScrollbarModule
    //AppModule
  ],
  exports: [],
  
  schemas: [ CUSTOM_ELEMENTS_SCHEMA]
})
export class ShareModuleModule { }
