
<div *ngIf="isteacherRoute">
        <app-header-style-one-teacher></app-header-style-one-teacher>
    </div>
    <div *ngIf="!isteacherRoute">
        <app-header-style-one></app-header-style-one>
    </div>

    <section class="courses-details-area pt-100 pb-70" style="padding-top: 30px!important" *ngIf="isquestion && !uploadimage">
        <div class="container">
                <div class="modal" id="myimageModal1">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-body">
                                    <img  src={{showimageinmodal}} id="imagepreview" style="width: 425px; height: 425px;" >
                                </div>

                            </div>

                         
                        </div>
                    </div>

                    <div class="modal" id="answerimagemodal">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-body">
                                        <img  src={{showanswerimageinmodal}} id="imagepreview1" style="width: 425px; height: 425px;" >
                                    </div>

                                    
                                <div class="row" style="padding-bottom: 2%;">
                                   
                                    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <a type="submit" class="button" 
                                            style=" margin-left: 10px;font-size: 12px;"  (click)="previousbutton()"><span class="label" style="font-weight: 600;">Previous
                                                </span></a>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <a type="submit" class="button"
                                            style=" margin-left: 10px;font-size: 12px;"   (click)="nextbutton()"><span class="label" style="font-weight: 600;">Next
                                                </span></a>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <a type="submit" class="button" 
                                            style=" margin-left: 10px;font-size: 12px;"  data-dismiss="modal" ><span class="label" style="font-weight: 600;">Cancel
                                                </span></a>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal" id="endtestmodal">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-body">
                                          <p> Are you sure you want to end test correction for this test?</p>
                                        </div>
    
                                        
                                    <div class="row" style="padding-bottom: 2%;">
                                       
                                        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                               
                                        </div>
                                        <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                                                <a type="submit" class="button"
                                                style=" margin-left: 10px;font-size: 12px;"   (click)="endtest()" data-dismiss="modal"><span class="label" style="font-weight: 600;">End Test
                                                    </span></a>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                                <a type="submit" class="button" 
                                                style=" margin-left: 10px;font-size: 12px;"  data-dismiss="modal" ><span class="label" style="font-weight: 600;">Cancel
                                                    </span></a>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                <!-- Exam Timer -->
                <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="box-shadow: 0px 0px 1px 0px;">
                            <div class="row" style="height: 75px;padding-top: 2%;">
                                <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                                    <p style="word-break: break-word;font-size: 20px;color:black;font-weight: 700">
                                        {{TestData.testID.testName}} 
                                     </p>
                                </div>
                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                        <a type="submit" class="button"
                                        style=" margin-left: 10px;font-size: 12px;"  data-toggle="modal" data-target="#answerimagemodal" (click)="viewanswerimage();"><span class="label" style="font-weight: 600;">View Answers
                                            </span></a>
                                </div>
                            </div>
                        </div>
            
                    </div>
    
    
    
    
    
    
    <div class="row">
    
                          <!-- Question with options -->
                <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12" style="box-shadow: 0px 0px 1px 0px;margin-top:5%;padding-bottom: 2%">
                        <div class="row"
                            style="padding: 5px 5px 5px 10px;border-bottom: 1px solid lightgrey;background-color: #00adee;color: white;">
                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8" style="border-right: 1px solid white;">
                                <p style="color: white;font-weight: 600;"> Question {{questioncount}}
                                </p>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                               
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4" >
                           
                            </div>
                           
                        </div>   
                        <div class="row"
                        style="padding: 5px 5px 5px 10px;;margin-top: 2%;background-color: white">
                        <img class="img" [src]="url" height="100" width="100%"  (click)="imageselected(url)" style="margin: 3px;padding: 5px 5px 5px 10px;border-bottom: 1px solid lightgrey;color: white;" data-toggle="modal" data-target="#myimageModal1"  > <br/>
                      
                        </div>
                        <form [formGroup]="createForm">
                        <div class="row">
                                <div class="col-md-6">
                                        <div class="form-group mb-3">
                                            <label labelStyle>Mark obtained</label>
                                            <input type="number" formControlName="marks" placeholder="Mark obtained" class="form-control"
                                                maxlength="50"  />                                           
                                        </div>
                                    </div>
                        </div>
                        <div class="row">
                                <div class="col-md-6">
                                        <div class="form-group mb-3">
                                            <label labelStyle>Remark</label>
                                            <textarea type="text" maxlength="120" formControlName="remarks"
                                            class="form-control" maxlength="50"></textarea>                                    
                                        </div>
                                    </div>
                        </div>
                        </form>

        
        
                    </div>
                    <!-- End Of Question with options -->
    
                <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12">
                        </div>
    
                        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12"
                        style="box-shadow: 0px 0px 1px 0px;padding: 5px 5px 5px 10px;margin-top:5%;padding-bottom: 2%">
                        <div class="instructor-details" style="padding-top: 5%;">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="textheader">
                                        <!-- {{livedetails.testName}} -->
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <a type="submit" class="button" 
                                        style=" margin-left: 10px;font-size: 12px;"  data-toggle="modal" data-target="#endtestmodal"><span class="label" style="font-weight: 600;">End Test
                                            </span></a>
                                </div>
                            </div>                      
                            <hr>
                            <div class="row" style="margin-top: 3%;">
                                <div class="col-lg-10 col-md-10">
                                    <h6 style="margin-left: 1%;margin-bottom: 2%;font-size: 20px;font-weight: 700;">
                                        <!-- {{livedetails.topicID.topicName}} -->
                                    </h6>
                                </div>    
                                <div class="col-lg-2 col-md-2">
    
                                </div>
                                <hr>                       
                                <ng-container >
                                    <div style="margin-top: 3%;" *ngFor="let subjects1 of subjectname1array; let i = index">                                  
                                        <a type="submit" class="buttontext"
                                            style=" margin-left: 10px;margin-top:2%"
                                            (click)="questionnumberbutton(subjects1.imageNumber)"><span class="label"
                                                style="font-weight: 600;color: black">{{subjects1.imageNumber}}</span></a>
                                       
                                    </div>
                                </ng-container>
                            </div>
        
                         
                        </div>
                    </div>
    
    
                </div>
    
    
                
                <div class="row" style="margin-top:5%;padding-bottom: 2%">
        
                        <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                        <a *ngIf="buttonprev" (click)="previousquestion()" type="submit" class="button"
                                        style=" margin-left: 10px;font-size: 13px;margin-top: 2%;background-color: var(--mainColor);color: white;"
                                        ><span class="label"
                                            style="font-weight: 600;">Previous</span></a>
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1">
    
                                </div>
                              
                                <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                    <a *ngIf="buttonnext" (click)="nextquestion()" type="submit" class="button"
                                        style=" margin-left: 10px;font-size: 13px;margin-top: 2%;background-color: var(--mainColor);color: white;"
                                        ><span class="label"
                                            style="font-weight: 600;">Save & Next</span></a>
                                    <a *ngIf="buttonlast" (click)="lastquestion()" type="submit" class="button"
                                            style=" margin-left: 10px;font-size: 13px;margin-top: 2%;background-color: var(--mainColor);color: white;"
                                            ><span class="label"
                                                style="font-weight: 600;">Save</span></a>
                                </div>
                            </div>
                        </div>
    
                    </div>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
                </div>
        </section>
            
    