import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
declare var $:any;
@Component({
  selector: 'app-hometwo-main-banner',
  templateUrl: './hometwo-main-banner.component.html',
  styleUrls: ['./hometwo-main-banner.component.scss']
})
export class HometwoMainBannerComponent implements OnInit {
  // showModal : boolean;
  // isLogin: any = 'false';
  // public isStudent: boolean = false;
  // public isTeacher: boolean = false;
  public isUserLoggedIn:string;
  constructor(private router: Router,public toastr: ToastrManager) { }

  ngOnInit(): void {
    var owl = $('.slider');
    owl.owlCarousel({
        items:1,
        loop:false,
        autoplay:true,
        nav:true,
        margin: 10,
        // autoplayTimeout:1000,
        autoplayHoverPause:true
    });
    // $('.owl-carousel,.owl.nav,button.owl-prev').click(function(){
    //   $('.youtube-video')[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
    // });

    // $('.owl-carousel,.owl.nav,button.owl-next').click(function(){
    //   $('.youtube-video')[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
    // });

 $('.owl-carousel,.owl.nav,button.owl-prev').click(function(e) {
  //  alert('prev')
      var stopAllYouTubeVideos = () => { 
        var iframes = document.querySelectorAll('iframe');
        Array.prototype.forEach.call(iframes, iframe => { 
          iframe.contentWindow.postMessage(JSON.stringify({ event: 'command', 
        func: 'stopVideo' }), '*');
       });
      }
      stopAllYouTubeVideos();


    });
    
    $('.owl-carousel,.owl.nav,button.owl-next').click(function(e) {
      var stopAllYouTubeVideos = () => { 
        var iframes = document.querySelectorAll('iframe');
        Array.prototype.forEach.call(iframes, iframe => { 
          iframe.contentWindow.postMessage(JSON.stringify({ event: 'command', 
        func: 'stopVideo' }), '*');
       });
      }
      stopAllYouTubeVideos();

     

    });

    this.isUserLoggedIn = localStorage.getItem('isLoggedin');

  }
 studentclick() {   
    if(this.isUserLoggedIn.toLowerCase()=="true") {
      this.toastr.errorToastr("Already you have Logged in");
    }
    else {
      this.router.navigate(['/student/login']);
    }
  }
  teacherclick(){
    if(this.isUserLoggedIn.toLowerCase()=="true") {
      this.toastr.errorToastr("Already you have Logged in");
    }
    else {
      this.router.navigate(['/teacher/login']);
    }
  //   if(this.isTeacher && this.isLogin == 'true'){
  //     this.router.navigate(["/sidenav"]);
  //     console.log('already login this page');
  //    }else{
  //      this.router.navigate(["/teacher/login"])
  //    }
  }

  // onClick(event)
  // {

  //   this.showModal = true; // Show-Hide Modal Check  
  // }
  // hides()
  // {
  //   this.showModal = false;
  // }
 
  neetcrash(){
    window.location.href ='/NEET-Crash';
  }
  k12(){
    window.location.href ='/K-12';
  }
}
