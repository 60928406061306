import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../app/shared/api.service";
import { Router} from "@angular/router";

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {

  public isStudent = false;
  public isTeacher = false;
  public institutionDetails: any  = {};
  public isBasicInstitution = false;
  constructor( private rest: ApiService,
               private router: Router) { }

  ngOnInit(): void {
    this.isTeacher =
    localStorage.getItem("profileType").toLowerCase() == "teacher";
this.isStudent =
    localStorage.getItem("profileType").toLowerCase() == "student";
    this.getInstitutionDetails()
  }
  getInstitutionDetails() {
    const webToken = localStorage.getItem("webtoken");
    this.rest.getInstitutionDetails(webToken).subscribe((response) => {
        console.log(response)
        if(response.redirectToCreate){
            this.router.navigate(["/Pricing-plans"]);
        }
        this.institutionDetails = response.data.institution;
        this.isBasicInstitution = response.data.institution.subscriptionType ? response.data.institution.subscriptionType.price == 0 : false;
        console.log(this.institutionDetails)
    });
}
}
