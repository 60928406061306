import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of, observable, throwError, from } from 'rxjs';
import { map, catchError, tap, retry, timeout } from 'rxjs/operators';
import { ToastrManager } from 'ng6-toastr-notifications';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  customChangeDetector = new Subject<string>();
  customChangeDetector$ = this.customChangeDetector.asObservable();
  constructor(private http: HttpClient, public toastr: ToastrManager,
    private router: Router, private title: Title, private meta: Meta) { }

    updateTitle(title: string | undefined){
      this.title.setTitle(title);
    }
    updateMetaTags(metaTags: MetaDefinition[]){
      metaTags.forEach(m=> this.meta.updateTag(m));
    }
    
  public BASE_URL = environment.BASE_URL;

  //public BASE_URL = 'http://localhost:3000/iBuddyPlatformAPI/';

  private token = localStorage.getItem('webtoken');
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
      // 'Authorization': "Bearer "+this.token
    })
  };
  ngOnInit() {
  }

  // AddUser (registerID): Observable<any> {
  AddUserStudent(param: any) {
    console.log("Param", JSON.stringify(param))
    return this.http.post<any>(this.BASE_URL + 'student/createStudent', JSON.stringify(param), this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  AddUserTeacher(param: any) {
    console.log("Param", JSON.stringify(param))
    return this.http.post<any>(this.BASE_URL + 'teacher/createTeacher', JSON.stringify(param), this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
    
  }
  checkUserTeacher(param: any) {
    console.log("Param", JSON.stringify(param))
    return this.http.post<any>(this.BASE_URL + 'teacher/sendOtp', JSON.stringify(param), this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
    
  }

  checkUserStudent(param: any) {
    console.log("Param", JSON.stringify(param))
    return this.http.post<any>(this.BASE_URL + 'student/sendOtp', JSON.stringify(param), this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
    
  }
  AddUserWithoutOTP(param: any) {
    console.log("Param", JSON.stringify(param))
    return this.http.post<any>(this.BASE_URL + 'user/userCreateWithoutOTP', JSON.stringify(param), this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  deloneononemeeting(req): Observable<any> {
    return this.http.post<any>(this.BASE_URL + 'user/deloneononemeeting', req, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  deleteStudyhallID(req): Observable<any> {
    return this.http.post<any>(this.BASE_URL + 'user/deleteStudyhallID', req, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  deleteRoundtableID(req): Observable<any> {
    return this.http.post<any>(this.BASE_URL + 'user/deleteRoundtableID', req, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  AddEnterpriseUser(param: any, token) {
    console.log("Param", JSON.stringify(param))
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'enterprise/insertTeacherData', JSON.stringify(param), headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  removeList(param: any, token) {
    console.log("Param", JSON.stringify(param))
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'user/removeFromList', JSON.stringify(param), headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getTeacherPaymentAllFinal(param: any, token) {
    console.log("Param", JSON.stringify(param))
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'teacherAccount/getTeacherPaymentAllFinal', JSON.stringify(param), headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  CPcheckOTPForPassword(param: any, token) {
    console.log("Param", param)
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'user/otpVerifyForPassword', param, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  detaillist(): Observable<any> {
    return this.http.get<any>(this.BASE_URL + 'user/getadmingroupclass', this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
   getAllPartner(): Observable<any> {
    return this.http.get<any>(this.BASE_URL + 'partner/allEduPartner', this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }



  getsessionDetailsTeacher(param: any,token) {
  const headers_object = {headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'Bearer ' + token})};
    console.log("Param", JSON.stringify(param))
    return this.http.post<any>(this.BASE_URL + 'user/getsessionDetailsTeacher', JSON.stringify(param), headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  updateEndMeetingTime(param: any,token) {
  const headers_object = {headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'Bearer ' + token})};
    console.log("Param", JSON.stringify(param))
    return this.http.post<any>(this.BASE_URL + 'user/updateEndMeetingTime', JSON.stringify(param), headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  

  alreadyEnroll(param: any,token) {
  const headers_object = {headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'Bearer ' + token})};
    console.log("Param", JSON.stringify(param))
    return this.http.post<any>(this.BASE_URL + 'user/alreadyEnroll', JSON.stringify(param), headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  addTeacherPayment(param: any,token) {
  const headers_object = {headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'Bearer ' + token})};
    console.log("Param", JSON.stringify(param))
    return this.http.post<any>(this.BASE_URL + 'teacherAccount/addTeacherPayment', JSON.stringify(param), headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  updatePaymentClassandExam(param: any,token) {
  const headers_object = {headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'Bearer ' + token})};
    console.log("Param", JSON.stringify(param))
    return this.http.post<any>(this.BASE_URL + 'payment/updatePaymentClassandExam', JSON.stringify(param), headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  libraryTeacher(req,webtoken){
   const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + webtoken
      })
    };      
    return this.http.post<any>(this.BASE_URL + 'user/libraryTeacher',req,headers_object).pipe(
    catchError(this.handleError)
    );
  }

  VideoUploadTeacher(webtoken){
    const headers_object = {
       headers: new HttpHeaders({
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + webtoken
       })
     };      
     return this.http.get<any>(this.BASE_URL + 'teacherVideos/getUserVideos',headers_object).pipe(
     catchError(this.handleError)
     );
   }
   paymentTest(data,token): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
     return this.http.post<any>(this.BASE_URL + 'paymentDetails/paymentSuccessful',data,headers_object).pipe(
    retry(1),
    catchError(this.handleError)
  );
  }

   SmartmaterialUploadTeacher(webtoken){
    const headers_object = {
       headers: new HttpHeaders({
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + webtoken
       })
     };      
     return this.http.get<any>(this.BASE_URL + 'teacherSmartMaterial/getUserSmartMaterial',headers_object).pipe(
     catchError(this.handleError)
     );
   }

   testsUploadedbyTeacher(webtoken, data){
    const headers_object = {
       headers: new HttpHeaders({
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + webtoken
       })
     };      
     return this.http.post<any>(this.BASE_URL + 'teacherTests/getUserTests',data, headers_object).pipe(
     catchError(this.handleError)
     );
   }

   testscorrectionbyTeacher(webtoken){
    const headers_object = {
       headers: new HttpHeaders({
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + webtoken
       })
     };      
     return this.http.post<any>(this.BASE_URL + 'teacherTests/getTestsForCorrection',null,headers_object).pipe(
     catchError(this.handleError)
     );
   }

   NewVideoListingWithoutLogin(data){
    let headers_object = {};
    if(data['webToken']){
      headers_object = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + data['webToken']
        })
      };
    }
    else{
      headers_object = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
    }    
    return this.http.post<any>(this.BASE_URL + 'teacherVideos/getAllVideos', data, headers_object).pipe(
    catchError(this.handleError)
    );
  }
  NewVideoListing(webtoken, data){
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + webtoken
      })
    };
    return this.http.post<any>(this.BASE_URL + 'teacherVideos/getUserVideos', data, headers_object).pipe(
    catchError(this.handleError)
    );
  }
  getUserVideosStudent(webtoken, data){
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + webtoken
      })
    };
    return this.http.post<any>(this.BASE_URL + 'teacherVideos/getUserVideosStudent', data, headers_object).pipe(
    catchError(this.handleError)
    );
  }
  addVideoToPurchased(token, data): Observable<any> {
    const headers_object = {headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token})};
    let post_url = this.BASE_URL + 'teacherVideos/addVideoToPurchased';
    return this.http.post<any>(post_url, data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
   }

   getPurchasedVideos(token): Observable<any> {
     console.log('token',token)
     const headers_object = {headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token})};
    let get_url = this.BASE_URL + 'teacherVideos/getPurchasedVideos';
    return this.http.get<any>(get_url,headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
   }
  

   
  NewSmartMaterialListing(webtoken, data){
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + webtoken
      })
    };     
    return this.http.post<any>(this.BASE_URL + 'teacherSmartMaterial/getUserSmartMaterial', data, headers_object).pipe(
    catchError(this.handleError)
    );
  }
  NewSmartMaterialListingWithoutLogin(data){
    let headers_object = {};
    if(data['webToken']){
      headers_object = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + data['webToken']
        })
      };
    }
    else{
      headers_object = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
    }      
    return this.http.post<any>(this.BASE_URL + 'teacherSmartMaterial/getAllSmartMaterial',data, headers_object).pipe(
    catchError(this.handleError)
    );
  }
  addMaterialToPurchased(token, data): Observable<any> {
    const headers_object = {headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token})};
    let post_url = this.BASE_URL + 'teacherSmartMaterial/addMaterialToPurchased';
    return this.http.post<any>(post_url, data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
   }

   getPurchasedSmartMaterial(token): Observable<any> {
    const headers_object = {headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token})};
    let get_url = this.BASE_URL + 'teacherSmartMaterial/getPurchasedSmartMaterial';
    return this.http.get<any>(get_url, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
   }
    
  getVideoDetails(webToken, param: any) {
    console.log("Param", JSON.stringify(param))
    var headers_object = {};
    if(!webToken){
      headers_object = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    }
    else{
      headers_object = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + webToken
        })
      }
    }
    return this.http.post<any>(this.BASE_URL + 'teacherVideos/getVideoDetails', JSON.stringify(param), headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getStudyDetails(webToken, param: any) {
    console.log("Param", JSON.stringify(param))
    var headers_object = {};
    if(!webToken){
      headers_object = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    }
    else{
      headers_object = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + webToken
        })
      }
    }
    return this.http.post<any>(this.BASE_URL + 'teacherSmartMaterial/getSmartMaterialDetails', JSON.stringify(param), headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  publicSolution(req,webtoken){
   const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + webtoken
      })
    };      
    return this.http.post<any>(this.BASE_URL + 'user/solutionPublic',req,headers_object).pipe(
    catchError(this.handleError)
    );
  }
  publicQuestion(req,webtoken){
   const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + webtoken
      })
    };      
    return this.http.post<any>(this.BASE_URL + 'user/questionPublic',req,headers_object).pipe(
    catchError(this.handleError)
    );
  }
  groupSolution(req,webtoken){
   const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + webtoken
      })
    };      
    return this.http.post<any>(this.BASE_URL + 'user/solutionGroup',req,headers_object).pipe(
    catchError(this.handleError)
    );
  }
  groupQuestion(req,webtoken){
   const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + webtoken
      })
    };      
    return this.http.post<any>(this.BASE_URL + 'user/questionGroup',req,headers_object).pipe(
    catchError(this.handleError)
    );
  }

  privateSolution(req,webtoken){
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + webtoken
      })
    };      
    return this.http.post<any>(this.BASE_URL + 'user/solutionPrivate',req,headers_object).pipe(
    catchError(this.handleError)
    );
  }
  classStudent(req,webtoken){
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + webtoken
      })
    };      
    return this.http.post<any>(this.BASE_URL + 'user/studentClass',req,headers_object).pipe(
    catchError(this.handleError)
    );
  }
  youtubeStudent(req,webtoken){
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + webtoken
      })
    };      
    return this.http.post<any>(this.BASE_URL + 'user/studentYouTube',req,headers_object).pipe(
    catchError(this.handleError)
    );
  }
  buddiStudent(req,webtoken){
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + webtoken
      })
    };      
    return this.http.post<any>(this.BASE_URL + 'user/studentBuddi',req,headers_object).pipe(
    catchError(this.handleError)
    );
  }
  isFreeClass(token): Observable<any> {
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'payment/isFreeClass', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
   getCategoryProduct(token): Observable<any> {
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'payment/getCategoryProduct', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getMylearning(webtoken): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + webtoken
      })
    };
    return this.http.post<any>(this.BASE_URL + 'paymentDetails/getPaidItems',null, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getPaymentHistory(webtoken): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + webtoken
      })
    };
    return this.http.get<any>(this.BASE_URL + 'paymentDetails/getPaymentHistory', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
getCategoryProductReduce(token): Observable<any> {
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'payment/getCategoryProductReduce', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
 getPaymentById(param: any,token) {
  const headers_object = {headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'Bearer ' + token})};
    console.log("Param", JSON.stringify(param))
    return this.http.post<any>(this.BASE_URL + 'payment/getPaymentById',param, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getChapter(param: any,token) {
  const headers_object = {headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'Bearer ' + token})};
    console.log("Param", JSON.stringify(param))
    return this.http.post<any>(this.BASE_URL + 'chapter/getCoderChapter',param, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
   getPackageById(param: any,token) {
  const headers_object = {headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'Bearer ' + token})};
    console.log("Param", JSON.stringify(param))
    return this.http.post<any>(this.BASE_URL + 'productPackage/getPackageById',param, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }


  getsessionDetails(param: any): Observable<any> {
    console.log("Param", JSON.stringify(param))
    return this.http.post<any>(this.BASE_URL + 'user/getsessionDetails', JSON.stringify(param), this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getsession(param: any): Observable<any> {
    console.log("Param", JSON.stringify(param))
    return this.http.post<any>(this.BASE_URL + 'user/getsession', JSON.stringify(param), this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // AddUser (registerID): Observable<any> {
  checkOTPTeacher(param: any) {
    console.log("Param", JSON.stringify(param))
    return this.http.post<any>(this.BASE_URL + 'teacher/registerotpVerifyForPassword', JSON.stringify(param), this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  checkOTPTeacherValid(param: any) {
    console.log("Param", JSON.stringify(param))
    return this.http.post<any>(this.BASE_URL + 'teacher/registerotpVerifyForPassword', JSON.stringify(param), this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  
  checkOTPStudent(param: any) {
    console.log("Param", JSON.stringify(param))
    return this.http.post<any>(this.BASE_URL + 'student/registerotpVerifyForPassword', JSON.stringify(param), this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  verifyEmailwithPassword(param: any) {
    console.log("Param", JSON.stringify(param))
    return this.http.post<any>(this.BASE_URL + 'student/verifyEmailwithPassword', JSON.stringify(param), this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  checkOTPforgotStudent(data) {
    console.log("Param", data)
    return this.http.post<any>(this.BASE_URL + 'student/otpVerifyForPassword', data, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  checkOTPforgotTeacher(data) {
    return this.http.post<any>(this.BASE_URL + 'teacher/otpVerifyForPassword', data, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  checkmailforgotTeacher(data) {
    return this.http.post<any>(this.BASE_URL + 'teacher/forgotPassword', data, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  checkmailforgotStudent(data) {
    return this.http.post<any>(this.BASE_URL + 'student/forgotPassword', data, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  ChangePasswordforboth(data, type, token) {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    if (type == "teacher") {
      return this.http.post<any>(this.BASE_URL + 'teacher/changePassword', data, headers_object).pipe(
        retry(1),
        catchError(this.handleError)
      );
    }
    else if (type == "student") {
      return this.http.post<any>(this.BASE_URL + 'student/changePassword', data, headers_object).pipe(
        retry(1),
        catchError(this.handleError)
      );
    }
  }


  checkOTPForPassword(param: any) {
    console.log("Param", JSON.stringify(param))
    return this.http.post<any>(this.BASE_URL + 'user/otpVerifyForPassword', JSON.stringify(param), this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  sendOTP(param: any) {
    console.log("Param", JSON.stringify(param))
    return this.http.post<any>(this.BASE_URL + 'user/sendOtp', JSON.stringify(param), this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  teacherforgotsendOTP(data) {
    console.log("Param", data)
    return this.http.post<any>(this.BASE_URL + 'teacher/sendOtp', data, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  teacherforgotemail(data) {
    console.log("Param", data)
    return this.http.post<any>(this.BASE_URL + 'teacher/sendEmail', data, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  studentforgotemail(data) {
    return this.http.post<any>(this.BASE_URL + 'student/sendEmail', data, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  studentforgotsendOTP(data) {
    return this.http.post<any>(this.BASE_URL + 'student/sendOtp', data, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  cpsendOTP(param: any, token) {
    console.log("Param", param)
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'user/sendOtp', param, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  changePassword(param: any) {
    console.log("Param", JSON.stringify(param))
    return this.http.post<any>(this.BASE_URL + 'user/changePassword', JSON.stringify(param), this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  forgotPasswordchangestudent(data) {
    return this.http.post<any>(this.BASE_URL + 'student/forgotPassword', data, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  forgotPasswordchangeteacher(data) {
    return this.http.post<any>(this.BASE_URL + 'teacher/forgotPassword', data, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  changePhoneNumber(param: any) {
    console.log("Param", JSON.stringify(param))
    return this.http.post<any>(this.BASE_URL + 'user/changePhoneNumber', JSON.stringify(param), this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  // Login Page API
  loginPageStudent(loginID): Observable<any> {
    console.log(loginID);
    return this.http.post<any>(this.BASE_URL + 'student/login', JSON.stringify(loginID), this.httpOptions).pipe(
      //   tap(),
      //   catchError(this.handleError <any> ('loginPage'))
      // );
      retry(1),
      catchError(this.handleError)
    );
  }




  loginPageTeacher(loginID): Observable<any> {
    console.log(loginID);
    return this.http.post<any>(this.BASE_URL + 'teacher/login', JSON.stringify(loginID), this.httpOptions).pipe(
      //   tap(),
      //   catchError(this.handleError <any> ('loginPage'))
      // );
      retry(1),
      catchError(this.handleError)
    );
  }
  getaccessTokenSocial(accesstoken,routername): Observable<any> {
    return this.http.post<any>(this.BASE_URL + 'externalAuth/'+routername+'/google/token',accesstoken,this.httpOptions).pipe(
      //   tap(),
      //   catchError(this.handleError <any> ('loginPage'))
      // );
      retry(1),
      catchError(this.handleError)
    );
  }
  facebookLinktopage(accesstoken,routername): Observable<any> {
    return this.http.post<any>(this.BASE_URL + 'externalAuth/'+routername+'/facebook/token', accesstoken, this.httpOptions).pipe(
      //   tap(),
      //   catchError(this.handleError <any> ('loginPage'))
      // );
      retry(1),
      catchError(this.handleError)
    );
  }
  forgotPage(email): Observable<any> {
    console.log(email);
    const passData = { email: email };
    return this.http.post<any>(this.BASE_URL + 'user/users/forgot', JSON.stringify(passData), this.httpOptions).pipe(
      //   tap(),
      //   catchError(this.handleError <any> ('loginPage'))
      // );
      retry(1),
      catchError(this.handleError)
    );
  }
  delete_Alarm(idValue, token): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'alarm/delAlarm/' + idValue, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  update_Alarm_Data(data, token): Observable<any> {
    // console.log(token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'alarm/updateAlarm', JSON.stringify(data), headers_object).pipe(
      //   tap(),
      //   catchError(this.handleError <any> ('id'))
      // );
      retry(1),
      catchError(this.handleError)
    );
  }
  resetPage(registerID, id): Observable<any> {
    console.log(registerID);
    return this.http.post<any>(this.BASE_URL + 'user/resetPassword/' + id, JSON.stringify(registerID), this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  activatePage(userData): Observable<any> {
    return this.http.post<any>(this.BASE_URL + 'student/verifyEmail', userData).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  activateteacherPage(userData): Observable<any> {
    return this.http.post<any>(this.BASE_URL + 'teacher/verifyEmail', userData).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }


  // //teacher login verify
  // teacher_login_verify(webtoken,token): Observable<any> {
  //   console.log(token);
  //   const headers_object = {headers: new HttpHeaders({
  //     'Content-Type':  'application/json',
  //     'Authorization': 'Bearer ' + webtoken})};
  //    return this.http.get<any>(this.BASE_URL + 'teacher/verifyEmail'+token, headers_object).pipe(
  //    retry(1),
  //   catchError(this.handleError)
  // );
  // }

  // student_login_verify(webtoken,token): Observable<any> {
  //   console.log(token);
  //   const headers_object = {headers: new HttpHeaders({
  //     'Content-Type':  'application/json',
  //     'Authorization': 'Bearer ' + webtoken})};
  //    return this.http.get<any>(this.BASE_URL + 'student/verifyEmail'+token, headers_object).pipe(
  //    retry(1),
  //   catchError(this.handleError)
  // );
  // }


  unsetLoginUser() {
    // return this.http.get<any>(this.BASE_URL + 'user/logout', this.httpOptions).pipe(

    localStorage.clear();
    //this.toastr.successToastr('Oops! You are logged out. Want to get back in again?');
    this.router.navigate(['/']);
    // );
  }

  teacherAcceptedRequest(token): Observable<any> {
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'user/teacherAcceptedRequest', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getTeacherPaymentAll(token): Observable<any> {
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'teacherAccount/getTeacherPaymentAll', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  buddigroupRecordStudent(token): Observable<any> {
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'user/buddigroupRecordStudent', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getsameTeahcer(token): Observable<any> {
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'user/getsameTeahcer', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  kycStatus(token): Observable<any> {
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'kyc/kycStatus', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
   getMyPaymentDetails(token): Observable<any> {
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'payment/getMyPaymentDetails', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getMyRequestSuccess(token): Observable<any> {
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'user/getMyRequestSuccess', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  studentOneonone(data, token) {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    let post_url_userUpdate = this.BASE_URL +'user/student_oneonone';
    return this.http.post<any>(post_url_userUpdate, data, headers_object).pipe(
      catchError(this.handleError)
    );
  }
  getmyDashboadLibrary(token): Observable<any> {
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'user/getmyDashboadLibrary', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  audioStudent(data, token) {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    let post_url_userUpdate = this.BASE_URL + 'user/studentAudio';
    return this.http.post<any>(post_url_userUpdate, data, headers_object).pipe(
      catchError(this.handleError)
    );
  }
  studyLibraryStudent(data, token) {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    let post_url_userUpdate = this.BASE_URL + 'user/studentStudyLibrary';
    return this.http.post<any>(post_url_userUpdate, data, headers_object).pipe(
      catchError(this.handleError)
    );
  }
  videoStudent(data, token) {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    let post_url_userUpdate = this.BASE_URL + 'user/studentVideo';
    return this.http.post<any>(post_url_userUpdate, data, headers_object).pipe(
      catchError(this.handleError)
    );
  }
  dashboadSolution(data, token) {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    let post_url_userUpdate = this.BASE_URL + 'user/dashboadSolution';
    return this.http.post<any>(post_url_userUpdate, data, headers_object).pipe(
      catchError(this.handleError)
    );
  }
   dashboadSolutionGroup(data, token) {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    let post_url_userUpdate = this.BASE_URL + 'user/dashboadSolutionGroup';
    return this.http.post<any>(post_url_userUpdate, data, headers_object).pipe(
      catchError(this.handleError)
    );
  }
    dashboadQuestion(data, token) {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    let post_url_userUpdate = this.BASE_URL + 'user/dashboadQuestion';
    return this.http.post<any>(post_url_userUpdate, data, headers_object).pipe(
      catchError(this.handleError)
    );
  }
   dashboadQuestionGroup(data, token) {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    let post_url_userUpdate = this.BASE_URL + 'user/dashboadQuestionGroup';
    return this.http.post<any>(post_url_userUpdate, data, headers_object).pipe(
      catchError(this.handleError)
    );
  }
  getmyDashboadmeetingTeacher(token): Observable<any> {
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'user/getmyDashboadmeetingTeacher', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getLiveclassmeetingTeacher(token, data): Observable<any> {
    
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'user/getLiveclassmeetingTeacher', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getInstitutionsWithoutLogin(token, data): Observable<any> {
    
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'institution/getInstitutionsWithoutLogin', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  
  getWebinarsmeetingTeacher(token, data): Observable<any> {
    
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'user/getWebinarsmeetingTeacher', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getWebinarsmeetingTeacherPost(token,req): Observable<any> {
    
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'user/getWebinarsmeetingTeacherDATA',req, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  
  getLiveclassTeacherWithoutLogin(token, data): Observable<any> {
    var headers_object;
    if(token){
      headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token})};
    }
    else{
      headers_object = {headers: new HttpHeaders()};
    }
    return this.http.post<any>(this.BASE_URL + 'user/getLiveclassTeacherWithoutLogin', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getWebinarsmeetingTeacherWithoutLogin(token, data): Observable<any> {
    var headers_object;
    if(token){
      headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token})};
    }
    else{
      headers_object = {headers: new HttpHeaders()};
    }
    return this.http.post<any>(this.BASE_URL + 'user/getWebinarsmeetingTeacherWithoutLogin', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getClassRoomTeacher(token, data): Observable<any> {
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'user/getclassRoomsTeacher', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getClassRoomWithoutlogin(token, data): Observable<any> {
    var headers_object;
    if(token){
      headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token})};
    }
    else{
      headers_object = {headers: new HttpHeaders()};
    }
    return this.http.post<any>(this.BASE_URL + 'user/getclassRoomsWithoutLogin', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  
    getmyDashboadmeetingTeacherRecord(token): Observable<any> {
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'user/getmyDashboadmeetingTeacherRecord', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getmyDashboadmeeting(token): Observable<any> {
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'user/getmyDashboadmeeting', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  userName_DisplayStudent(token): Observable<any> {
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'student/getStudent', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updatePricingTemplate(token,data): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'teacher/updatePricingTemplate',data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
      );
    }

  getChapterDetails(req,token): Observable<any> {
    const headers_object = {headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Bearer ' + token})};        
     return this.http.post<any>(this.BASE_URL + 'classList/getChapterTitle', req,headers_object).pipe(
     retry(1),
    catchError(this.handleError)
    );
  }

  scheduleTeacherAccept(token): Observable<any> {
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'user/schedule', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getstudentnotification(token): Observable<any> {
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'user/getstudentnotification', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getmyMeeting(token): Observable<any> {
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'user/getmyMeetingPublic', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  userName_DisplayTeacher(token): Observable<any> {
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'teacher/getTeacher', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  Getteacherallpopulate(token): Observable<any> {
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'teacher/getTeacherWithArrayPopulate', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  Kyclist(token): Observable<any> {
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'kyc/kyclist', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  studentSubject(token): Observable<any> {
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'course/getSubjects', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getOccupation(token): Observable<any> {
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'course/getCurrentOccupation', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getAvailableDeviceList(token): Observable<any> {
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'course/getAvailableDevice', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  engagementList(token): Observable<any> {
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'course/getModeOfEngagement', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getAllList(token): Observable<any> {
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'course/getAllListData', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  internetConnectionList(token): Observable<any> {
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'course/getInternetConnectionDetails', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
   getAvailableTimes(token): Observable<any> {
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'course/getAvailableTime', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  packageInterestList(token): Observable<any> {
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'course/getPackageInterest', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  board(token): Observable<any> {
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'course/getBoards', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  grades(token): Observable<any> {
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'course/getGrades', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  languages(token): Observable<any> {
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'course/getlanguages', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }


 
  categories(token): Observable<any> {
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'course/getCourseCategories', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  userData_UpdateTeacher(data, token) {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    let post_url_userUpdate = this.BASE_URL + 'teacher/updateTeacher';
    return this.http.post<any>(post_url_userUpdate, data, headers_object).pipe(
      catchError(this.handleError)
    );
  }

  UpdateCourseDetails(data, token) {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    let post_url_userUpdate = this.BASE_URL + 'course/updateCourse';
    return this.http.post<any>(post_url_userUpdate, data, headers_object).pipe(
      catchError(this.handleError)
      );
    }
    Addreviews(req,token): Observable<any> {
      const headers_object = {headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token})};        
       return this.http.post<any>(this.BASE_URL + 'user/Addreviews', req,headers_object).pipe(
       retry(1),
      catchError(this.handleError)
      );
    }
    reviewLikeSave(req,token): Observable<any> {
      const headers_object = {headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token})};        
       return this.http.post<any>(this.BASE_URL + 'user/addLikeReview', req,headers_object).pipe(
       retry(1),
      catchError(this.handleError)
    );
  }

  UpdateExamDetails(data, token) {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    let post_url_userUpdate = this.BASE_URL + 'exam/updateExam';
    return this.http.post<any>(post_url_userUpdate, data, headers_object).pipe(
      catchError(this.handleError)
    );
  }

  Deletecourse(token, passdata): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'course/deleteCourse', passdata, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  changecourseurl(token, passdata): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'course/changeCourseStatus', passdata, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  changeexamurl(token, passdata): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'exam/changeExamStatus', passdata, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }


  userData_UpdateStudent(data, token) {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    let post_url_userUpdate = this.BASE_URL + 'student/updateStudent';
    return this.http.post<any>(post_url_userUpdate, data, headers_object).pipe(
      catchError(this.handleError)
    );
  }
  userPricingData_Update(data, token) {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    let post_url_userUpdate = this.BASE_URL + 'user/userUpdateWithPricing';
    return this.http.post<any>(post_url_userUpdate, data, headers_object).pipe(
      );
    }
    updateLikeeviews(req,token): Observable<any> {
      const headers_object = {headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token})};        
       return this.http.post<any>(this.BASE_URL + 'user/updateLikeeviews', req,headers_object).pipe(
       retry(1),
      catchError(this.handleError)
      );
    }
    getAutomationGroup(req,token): Observable<any> {
      const headers_object = {headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token})};        
       return this.http.post<any>(this.BASE_URL + 'user/getAutomationGroup', req,headers_object).pipe(
       retry(1),
      catchError(this.handleError)
      );
    }
    updateviewCount(req,token): Observable<any> {
      const headers_object = {headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token})};        
       return this.http.post<any>(this.BASE_URL + 'user/updateviewCount', req,headers_object).pipe(
       retry(1),
      catchError(this.handleError)
      );
    }
    uploadvideoupdateviewCount(req,token): Observable<any> {
      const headers_object = {headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token})};        
       return this.http.post<any>(this.BASE_URL + 'teacherVideos/addViewCount', req,headers_object).pipe(
       retry(1),
      catchError(this.handleError)
      );
    }

    videoupvote(req,token): Observable<any> {
      const headers_object = {headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token})};        
       return this.http.post<any>(this.BASE_URL + 'teacherVideos/addVideoUpvote', req,headers_object).pipe(
       retry(1),
      catchError(this.handleError)
      );
    }

      testupvote(req,token): Observable<any> {
      const headers_object = {headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token})};        
       return this.http.post<any>(this.BASE_URL + 'teacherTests/addTestUpvote', req,headers_object).pipe(
       retry(1),
      catchError(this.handleError)
      );
    }

    

    videodownvote(req,token): Observable<any> {
      const headers_object = {headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token})};        
       return this.http.post<any>(this.BASE_URL + 'teacherVideos/addVideoDownvote', req,headers_object).pipe(
       retry(1),
      catchError(this.handleError)
      );
    }

    testdownvote(req,token): Observable<any> {
      const headers_object = {headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token})};        
       return this.http.post<any>(this.BASE_URL + 'teacherTests/addTestDownvote', req,headers_object).pipe(
       retry(1),
      catchError(this.handleError)
      );
    }
  
    updatereviews(req,token): Observable<any> {
      const headers_object = {headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token})};        
       return this.http.post<any>(this.BASE_URL + 'user/updatereviews', req,headers_object).pipe(
       retry(1),
      catchError(this.handleError)
      );
    }
    getreviews(req,token): Observable<any> {
      const headers_object = {headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token})};        
       return this.http.post<any>(this.BASE_URL + 'user/getreviews', req).pipe(
       retry(1),
      catchError(this.handleError)
      );
    }
    getLikereviewsByUser(req,token): Observable<any> {
      const headers_object = {headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token})};        
       return this.http.post<any>(this.BASE_URL + 'user/getLikereviewsByUser', req,headers_object).pipe(
       retry(1),
      catchError(this.handleError)
      );
    }
    getLikereviews(req,token): Observable<any> {
      const headers_object = {headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token})};        
       return this.http.post<any>(this.BASE_URL + 'user/getLikereviews', req).pipe(
       retry(1),
      catchError(this.handleError)
    );
  }

  notificationCourseExam(data, token) {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    let post_url_userUpdate = this.BASE_URL + 'user/notificationCourseExam';
    return this.http.post<any>(post_url_userUpdate, data, headers_object).pipe(
      catchError(this.handleError)
    );
  }

  productNotification(data, token) {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    let post_url_userUpdate = this.BASE_URL + 'user/productNotification';
    return this.http.post<any>(post_url_userUpdate, data, headers_object).pipe(
      catchError(this.handleError)
    );
  }
  userPricingData_Get(id, token) {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    let post_url_userUpdate = this.BASE_URL + 'user/getUserDataWithPricing';
    return this.http.post<any>(post_url_userUpdate, id, headers_object).pipe(
      catchError(this.handleError)
    );
  }

  // userDataUploadStudent(data,token) {
  //   const headers_object = {headers: new HttpHeaders({
  //     'Authorization': 'Bearer ' + token})};
  //   let post_url_userUpdate = this.BASE_URL + 'enterprise/uploadStudentData';
  //   return this.http.post<any>(post_url_userUpdate, data, headers_object).pipe(
  //     catchError(this.handleError)
  //   );
  // }
  userDataUpload(data, token) {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    let post_url_userUpdate = this.BASE_URL + 'enterprise/uploadStudentData';
    return this.http.post<any>(post_url_userUpdate, data, headers_object).pipe(
      catchError(this.handleError)
    );
  }
  enterpriseTeacherGet(token): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'enterprise/enterpriseTeacherGet', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getAllHeaderandSub(): Observable<any> {
    // const headers_object = {headers: new HttpHeaders({
    //   'Content-Type':  'application/json',
    //   'Authorization': 'Bearer ' + token})};
    return this.http.get<any>(this.BASE_URL + 'user/getAllHeaderandSub').pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  enterpriseStudentGet(token): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'enterprise/enterpriseStudentGet', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateAdminEnterprise(data, token) {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    let post_url_userUpdate = this.BASE_URL + 'enterprise/updateAdminEnterprise';
    return this.http.post<any>(post_url_userUpdate, data, headers_object).pipe(
      catchError(this.handleError)
    );
  }
  myLife_DispalyData(token): Observable<any> {
    // console.log(token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'myLifeList/myLifeListAll', headers_object).pipe(
      //   tap(),
      //   catchError(this.handleError <any> ('id'))
      // );
      retry(1),
      catchError(this.handleError)
    );
  }
  activities_all(): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.get<any>(this.BASE_URL + 'allSongs', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  add_Alarm_NewData(data, token): Observable<any> {
    // console.log(token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'alarm/alarmCreate', JSON.stringify(data), headers_object).pipe(
      //   tap(),
      //   catchError(this.handleError <any> ('id'))
      // );
      retry(1),
      catchError(this.handleError)
    );
  }
  alarm_active(alarmValues, webToken): Observable<any> {
    // console.log(token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + webToken
      })
    };
    return this.http.post<any>(this.BASE_URL + 'alarm/alarmActive', JSON.stringify(alarmValues), headers_object).pipe(
      //   tap(),
      //   catchError(this.handleError <any> ('id'))
      // );
      retry(1),
      catchError(this.handleError)
    );
  }
  alarm_DisplayData(token): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'alarm/alarmGet', headers_object).pipe(
      //   tap(),
      //   catchError(this.handleError <any> (''))
      // );
      retry(1),
      catchError(this.handleError)
    );

  }
  lifeTimeData_display(token): Observable<any> {
    console.log(token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    var todoget = { mylifeId: "all" }
    return this.http.post<any>(this.BASE_URL + 'myLifeList/allMylife', JSON.stringify(todoget), headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  todo_getvalue(token): Observable<any> {
    console.log(token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'myLifeList/allMylife',).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  deleteRemainder(idValue, token): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'myLifeList/deleteRemainder', JSON.stringify(idValue), headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getlocation() {
    return this.http.get('https://ipapi.co/json/');
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //  window.alert(errorMessage);
    if (errorMessage) {
      console.log('dfdfdfdfdfdfdfdfffd',errorMessage)
      // this.router.navigate(['/login']);
    }

    return throwError(errorMessage);
  }
  lifeTimeData_ByStudentId(token): Observable<any> {
    // console.log(token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    var data = {}
    return this.http.post<any>(this.BASE_URL + 'myLifeList/myLifeList', JSON.stringify(data), headers_object).pipe(
      //   tap(),
      //   catchError(this.handleError <any> ('id'))
      // );
      retry(1),
      catchError(this.handleError)
    );
  }

  add_myLife_NewData(data, token): Observable<any> {
    // console.log(token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'myLifeList/myLifeListCreate', JSON.stringify(data), headers_object).pipe(
      //   tap(),
      //   catchError(this.handleError <any> ('id'))
      // );
      retry(1),
      catchError(this.handleError)
    );
  }
  update_myLife_NewData(data, token): Observable<any> {
    // console.log(token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'myLifeList/mylifeupdate', JSON.stringify(data), headers_object).pipe(
      //   tap(),
      //   catchError(this.handleError <any> ('id'))
      // );
      retry(1),
      catchError(this.handleError)
    );
  }

  myLife_dashBoardData(token): Observable<any> {
    // console.log(token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'mylife/mylife', headers_object).pipe(
      //   tap(),
      //   catchError(this.handleError <any> (''))
      // );
      retry(1),
      catchError(this.handleError)
    );
  }

  user_dashBoardData(token): Observable<any> {
    // console.log(token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'user/dashboarddata', headers_object).pipe(
      //   tap(),
      //   catchError(this.handleError <any> (''))
      // );
      retry(1),
      catchError(this.handleError)
    );
  }

  meetingfilter(token, req): Observable<any> {
    // console.log(token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'user/getMeetingByMeetingID', req, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  deleteAudioList(idValue, token): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'userPlaylist/removefromlist', JSON.stringify(idValue), headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  deleteVideoList(idValue, token): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'videos/removefromlist', JSON.stringify(idValue), headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  deletePhotoList(idValue, token): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'user/removefromlist', JSON.stringify(idValue), headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  userSongList_display(idValue, token): Observable<any> {
    console.log(token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'playlistsongs/getSongs', JSON.stringify(idValue), headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  userVideoList_display(idValue, token): Observable<any> {
    console.log(token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'videos/videolistFindSongs', idValue, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }


  editMeeting(data, token): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'user/editMeeting', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateStudyhall(data, token): Observable<any> {
    console.log('data====UpdateStudyhall',data)
    console.log('token====UpdateStudyhall',token)

    const headers_object = {
      headers: new HttpHeaders({
        'Authorization' : 'Bearer ' + token
      })
    };

    return this.http.post<any>(this.BASE_URL + 'user/updateStudyhall', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)

    );
  }
  updateRoundtable(data, token): Observable<any> {
    console.log('data====updateRoundtable',data)
    console.log('token====updateRoundtable',token)

    const headers_object = {
      headers: new HttpHeaders({
        'Authorization' : 'Bearer ' + token
      })
    };

    return this.http.post<any>(this.BASE_URL + 'user/updateRoundtable', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)

    );
  }
  editOneonone(data, token): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'user/editOneonone', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  createMeeting(data, token): Observable<any> {

    console.log('data-api',data);

    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'user/createMeeting', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  createRoundtable(data, token): Observable<any> {

    console.log('data-api',data);

    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'user/createroundTable', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  createLiveClass(data, token): Observable<any> {

    console.log('data-api',data);

    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'user/createLiveclassMeeting', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  filterLibrary(data): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<any>(this.BASE_URL + 'user/filterLibrary', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  filterSolutionSearchBy(data):Observable<any>{
    const headers_object = {
      headers:new HttpHeaders({
        'Content-Type':'application/json'
      })
    }
    return this.http.post<any>(this.BASE_URL + 'Solution/filterSolution',data,headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }
  filterQuestionSearchBy(data):Observable<any>{
    const headers_object = {
      headers:new HttpHeaders({
        'Content-Type':'application/json'
      })
    }
    return this.http.post<any>(this.BASE_URL + 'Question/filterQuestion',data,headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }
  exploreFilterLibrary(data): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<any>(this.BASE_URL + 'user/filterLibraryExplore', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  filterSolutionExplore(data): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<any>(this.BASE_URL + 'solution/filterSolutionExplore', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  } 
  filterQuestionExplore(data): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<any>(this.BASE_URL + 'question/filterQuestionExplore', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  } 
  libraryEnroll(data, token): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'user/libraryEnroll', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  insertNotification(data, token): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'user/insertNotification', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  isAlreadyPaid(data, token): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'payment/isAlreadyPaid', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  createRequest(data, token): Observable<any> {
    
    console.log(data,'data');
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'user/createRequest', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  meetingAction(idValue, token, action, request): Observable<any> {
    var passdata = { meetingId: idValue, action: action, request: request }
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'user/meetingAction', JSON.stringify(passdata), headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  meetingActionLiveClass(token, idValue, action, request): Observable<any> {
    var passdata = { meetingId: idValue, action: action, request: request }
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'user/meetingActionLiveClass', JSON.stringify(passdata), headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  meetingActionStudyClass(token, idValue, action, request): Observable<any> {
    var passdata = { meetingId: idValue, action: action, request: request }
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'user/meetingActionStudyClass', JSON.stringify(passdata), headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  meetingActionRoundClass(token, idValue, action, request): Observable<any> {
    var passdata = { meetingId: idValue, action: action, request: request }
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'user/meetingActionRoundClass', JSON.stringify(passdata), headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  meetingActionEnroll(req, token, action): Observable<any> {
    // var passdata = {meetingId : idValue,action:action }
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'user/meetingActionEnroll', req, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  // meetingActionTeacher(idValue, token, action, request): Observable<any> {
  //   var passdata = { meetingId: idValue, action: action, request: request }
  //   const headers_object = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': 'Bearer ' + token
  //     })
  //   };
  //   return this.http.post<any>(this.BASE_URL + 'user/meetingActionTeacher', JSON.stringify(passdata), headers_object).pipe(
  //     retry(1),
  //     catchError(this.handleError)
  //   );
  // }

  meetingActionJoin(idValue, action): Observable<any> {
    var passdata = { meetingId: idValue, action: action }
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<any>(this.BASE_URL + 'user/meetingActionJoin', JSON.stringify(passdata), headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  inviteRedirect(idValue): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get<any>(this.BASE_URL + 'user/inviteRedirect/' + idValue, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  inviteMeeting(idValue): Observable<any> {
    var passdata = idValue

    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<any>(this.BASE_URL + 'user/inviteRedirect', JSON.stringify(passdata), headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  updateMetting(idValue, passtoken): Observable<any> {
    var passdata = { meetId: idValue, userid: passtoken }
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<any>(this.BASE_URL + 'user/updatemeeting', JSON.stringify(passdata), headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  meetingRecordings(recordValue, server): Observable<any> {
    var passdata = { recordId: recordValue, server: server }
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<any>(this.BASE_URL + 'user/meetingrecord', JSON.stringify(passdata), headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }



  getMyRecordings(token): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'user/getMyRecordings', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }


  saveviewcount(req, token): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'videos/addviewcount', req, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  reviewVideoSave(req, token): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'videos/AddVideoreviews', req, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  // reviewLikeSave(req, token): Observable<any> {
  //   const headers_object = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': 'Bearer ' + token
  //     })
  //   };
  //   return this.http.post<any>(this.BASE_URL + 'videos/addLikeReview', req, headers_object).pipe(
  //     retry(1),
  //     catchError(this.handleError)
  //   );
  // }
  updateViewCount(req, token): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'videos/updateViewCount', req, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  // updateLikeeviews(req, token): Observable<any> {
  //   const headers_object = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': 'Bearer ' + token
  //     })
  //   };
  //   return this.http.post<any>(this.BASE_URL + 'videos/updateLikeeviews', req, headers_object).pipe(
  //     retry(1),
  //     catchError(this.handleError)
  //   );
  // }


  reviewVideoUpdate(req, token): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'videos/updateVideoreviews', req, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  reviewVideoGet(req, token): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'videos/getvideosreviews', req).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  // getLikereviewsByUser(req, token): Observable<any> {
  //   const headers_object = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': 'Bearer ' + token
  //     })
  //   };
  //   return this.http.post<any>(this.BASE_URL + 'videos/getLikereviewsByUser', req, headers_object).pipe(
  //     retry(1),
  //     catchError(this.handleError)
  //   );
  // }
  // getLikereviews(req, token): Observable<any> {
  //   const headers_object = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': 'Bearer ' + token
  //     })
  //   };
  //   return this.http.post<any>(this.BASE_URL + 'videos/getLikereviews', req).pipe(
  //     retry(1),
  //     catchError(this.handleError)
  //   );
  // }
  requestAccept(req, token): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'user/requestAccept', req, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  statusChangeNotification(id, token): Observable<any> {
    var data = {
      _id: id
    };
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'user/statusChangeNotification', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  removeMeeting(meetingid, token): Observable<any> {
    var passdata = { meetingId: meetingid }
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'user/removeMeeting', JSON.stringify(passdata), headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  removeMeetingTeacher(meetingid, token): Observable<any> {
    var passdata = { meetingId: meetingid }
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'user/removeMeeting', JSON.stringify(passdata), headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  removeMeetingbyMeetingId(meetingid, token): Observable<any> {
    var passdata = { meetingId: meetingid }
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'user/removeCalendarbyMeetingId', JSON.stringify(meetingid), headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getAllCatCourses(token): Observable<any> {
    // var data = {};
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'course/getCourseWithCategory', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getMyCourses(token): Observable<any> {
    var data = {};
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'course/getMyCourses', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getCourseDetails(token, data): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'course/getCourseDetails', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getTestDetails(token, data): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'test/getTestTransactionDetails', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getsinglestudentresultdetails(token, data): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'test/getSingleStudentResult', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getallstudenttest(token): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'test/getAllStudentResults', null, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  accepttest(token, data): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'test/acceptTestCorrection', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  accepttestreview(token, data): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'test/acceptTestReview', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getCourseDetailswithoutauth(token, data): Observable<any> {
    // const headers_object = {headers: new HttpHeaders({
    //   'Content-Type':  'application/json',
    //   'Authorization': 'Bearer ' + token})};
    return this.http.post<any>(this.BASE_URL + 'course/getCourseDetailsWithoutAuth', data).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getExamDetailswithoutauth(token, data): Observable<any> {
    // const headers_object = {headers: new HttpHeaders({
    //   'Content-Type':  'application/json',
    //   'Authorization': 'Bearer ' + token})};
    return this.http.post<any>(this.BASE_URL + 'exam/getExamDetailsWithoutAuth', data).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getExamDetails(token, data): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'exam/getExamDetails', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getAllMeeting(token): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'user/getAllMeeting', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // getMyMeeting(token): Observable<any> {
  //     const headers_object = {headers: new HttpHeaders({
  //       'Content-Type':  'application/json',
  //       'Authorization': 'Bearer ' + token})};
  //      return this.http.get<any>(this.BASE_URL + 'user/getMyMeeting', headers_object).pipe(
  //       retry(1),
  //       catchError(this.handleError)
  //    );
  // }
  filterBuddiGroup(data): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<any>(this.BASE_URL + 'user/filterBuddiGroup', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  exploreFilterBuddi(data): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<any>(this.BASE_URL + 'user/filterBuddiGroupExplore', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }


  filterMeeting(data): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<any>(this.BASE_URL + 'user/filterMeeting', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  

  exploreFilterMeeting(data): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<any>(this.BASE_URL + 'user/filterMeetingExplore', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  filterRequest(data): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<any>(this.BASE_URL + 'user/filterRequest', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getMeetingAll(): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.get<any>(this.BASE_URL + 'user/getMeetingAll', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getAllRequest(): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.get<any>(this.BASE_URL + 'user/getAllRequest', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getMeetingWithCategoryGroup(data): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    var req = {
      "userId": data
    }
    return this.http.post<any>(this.BASE_URL + 'user/getMeetingWithCategoryGroup', req, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }


  getCourseNameById(data): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    var req = {
      "id": data
    }
    return this.http.post<any>(this.BASE_URL + 'course/getCourseNameById', req, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }


  getMeetingbyIDRequest(data): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    var req = {
      "meetingId": data
    }
    return this.http.post<any>(this.BASE_URL + 'user/getMeetingbyIDRequest', req, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getMeetingbyID(data): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    var req = {
      "meetingId": data
    }
    return this.http.post<any>(this.BASE_URL + 'user/getMeetingbyID', req, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getStudyHallbyID(data): Observable<any> {
    console.log('data',data);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    var req = {
      "updateClassID": data
    }
    return this.http.post<any>(this.BASE_URL + 'user/getStudyHallbyID', req, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getRoundtablebyID(data): Observable<any> {
    console.log('data',data);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    var req = {
      "updateClassID": data
    }
    return this.http.post<any>(this.BASE_URL + 'user/getRoundtablebyID', req, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getMeetingWithCategory(): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.get<any>(this.BASE_URL + 'user/getMeetingWithCategory', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getCategorymeeting(value): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.get<any>(this.BASE_URL + 'user/getMeetingWithCategory/' + value, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getMeetingCategory(): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.get<any>(this.BASE_URL + 'user/getMeetingWithCategory', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getHomeLessons(): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get<any>(this.BASE_URL + 'user/getHomeMeeting', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getHomeCourses(token): Observable<any> {
    var data = {};
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'course/getAllUserCourses', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  listcategory(): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get<any>(this.BASE_URL + 'user/listmeetcategory', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  categoryPartners(): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get<any>(this.BASE_URL + 'user/getpartners', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  createCourse(data, token): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'course/createCourse', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  createExam(data, token): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'exam/createExam', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getLoginURL(passdata): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<any>(this.BASE_URL + 'courseUser/getLoginURL', passdata, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  deleteExambyID(token, passdata): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'Exam/deleteExam', passdata, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getLoginToken(passdata): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<any>(this.BASE_URL + 'courseUser/userLogin', JSON.stringify(passdata), headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getAllCategory(): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get<any>(this.BASE_URL + 'user/listmeetcategory', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getquicksightembed(token): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    let post_url = this.BASE_URL + 'graphDashboard/getStudentDashboard';
    return this.http.post<any>(post_url, null, headers_object).pipe(
      catchError(this.handleError)
    );
  }


  getCourseCategory(): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get<any>(this.BASE_URL + 'course/getCourseCategories', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getCourseCategoryHeader(): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get<any>(this.BASE_URL + 'course/getCourseCategoriesHeader', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getCourseCategoryModuleHeader(): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get<any>(this.BASE_URL + 'classCourses/getCategoriesForMenu', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  
  boardfield(): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get<any>(this.BASE_URL + 'course/getBoards', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  subjectfield(): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get<any>(this.BASE_URL + 'course/getSubjects', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  languagefield(): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get<any>(this.BASE_URL + 'course/getLanguages', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  gradefield(): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get<any>(this.BASE_URL + 'course/getGrades', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  language(): Observable<any> {
    return this.http.get<any>("assets/json/language.json").pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getcountry(): Observable<any> {
    return this.http.get<any>("assets/json/country.json").pipe(
      retry(1),
      catchError(this.handleError)
    );
  }


  grade(): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get<any>(this.BASE_URL + 'course/getGrades', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getalltestlist(data): Observable<any> {
    let headers_object = {};
    if(data['webToken']){
      headers_object = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + data['webToken']
        })
      };
    }
    else{
      headers_object = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
    }
    return this.http.post<any>(this.BASE_URL + 'teacherTests/getAllTests', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getcorrectedtestlist(token): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'teacherTests/getStudentCorrectedTests', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  addTestToPurchased(token, data): Observable<any> {
    const headers_object = {headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token})};
    let post_url = this.BASE_URL + 'teacherTests/addTestToPurchased';
    return this.http.post<any>(post_url, data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
   }

   getPurchasedTests(token): Observable<any> {
    const headers_object = {headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token})};
    let get_url = this.BASE_URL + 'teacherTests/getPurchasedTests';
    return this.http.get<any>(get_url, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
   }
    getmaterialid(): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<any>(this.BASE_URL + 'classDocumentType/getClassDocumentType',null, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getClassCourseCategory(): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get<any>(this.BASE_URL + 'classCourses/getCategoriesForMenu', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getClassCourseTemplatefromModule(req): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<any>(this.BASE_URL + 'classCourses/getCourseTemplatesFromModule',req, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

   getClassCoursedetails(req): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<any>(this.BASE_URL + 'classCourses/getCourseDetails',req, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getTopicMaterial(req): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<any>(this.BASE_URL + 'classCourses/getTopicMaterial',req, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

    topiclist(data,token): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'classTopic/getTopicList',JSON.stringify(data), headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }


  cities(): Observable<any> {
    return this.http.get<any>("assets/json/cities.json").pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  privacy(): Observable<any> {
    return this.http.get<any>("assets/json/privacy.json").pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  examlevel(): Observable<any> {
    return this.http.get<any>("assets/json/examlevel.json").pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  certification(): Observable<any> {
    return this.http.get<any>("assets/json/certification.json").pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  pricing(): Observable<any> {
    return this.http.get<any>("assets/json/pricing.json").pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  tagfield(): Observable<any> {
    return this.http.get<any>('assets/json/tag.json').pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  security(): Observable<any> {
    return this.http.get<any>('assets/json/security.json').pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  buddiGroup(): Observable<any> {
    return this.http.get<any>("assets/json/buddiGroup.json").pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getBuddiGroubyID(id): Observable<any> {
    return this.http.get<any>("assets/json/buddigroupdetails.json").pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  userPhotoList_display(idValue, token): Observable<any> {
    console.log(token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'user/albumFindMedia', JSON.stringify(idValue), headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getphotosfroms3(standard, subject) {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<any>('https://www.buddiforlife.com:3000/iBuddyPlatformAPI/eurekaCourse/getAllCourses', JSON.stringify(standard, subject), headers_object).pipe(
      retry(1),
    );
  }

  getCourses(param: any) {
    console.log("Param", JSON.stringify(param))
    return this.http.post<any>(this.BASE_URL + 'eurekaCourse/getAllCourses', JSON.stringify(param), this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getAllCoursesbyGroup(token, formData): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'course/getAllGroupCourses', formData, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getCoursesByCategory(token, formData): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'course/getCoursesByCategory', formData, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  

    explorefilterCategory(token, formData): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'course/getCoursesByCategoryExplore', formData, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getTestsByCategory(formData): Observable<any> {
   
    return this.http.post<any>(this.BASE_URL + 'test/getTestByCategory', formData).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getTestsdetails(formData): Observable<any> {   
    return this.http.post<any>(this.BASE_URL + 'test/getTestDetails', formData).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getuncorrected(formData, token): Observable<any> {   
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'test/getUncorrectedTests', formData , headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }  

  studentstarttest(formData, token): Observable<any> {   
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'teacherTests/studentStartTest', formData , headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }  

  revieweddata(formData, token): Observable<any> {   
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'test/getUnreviewedTests', formData , headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }  

  

  getaccepted(token): Observable<any> {   
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'test/getAcceptedTests', null , headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }    

  getreviewdata(token): Observable<any> {   
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'test/getUnreviewedTests', null , headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }    

  starttest(formData,token): Observable<any> {  
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    }; 
    return this.http.post<any>(this.BASE_URL + 'test/startTestStudent', formData, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  starttestcorrection(formData,token): Observable<any> {  
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    }; 
    return this.http.post<any>(this.BASE_URL + 'test/getTestForCorrection', formData, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  starttestreview(formData,token): Observable<any> {  
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    }; 
    return this.http.post<any>(this.BASE_URL + 'test/getTestForReview', formData, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  finishtest(formData,token): Observable<any> {  
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    }; 
    return this.http.post<any>(this.BASE_URL + 'test/finishTestStudent', formData, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  finishTestForCorrection(formData,token): Observable<any> {  
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    }; 
    return this.http.post<any>(this.BASE_URL + 'test/finishTestForCorrection', formData, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  finishTestForReview(formData,token): Observable<any> {  
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    }; 
    return this.http.post<any>(this.BASE_URL + 'test/finishTestForReview', formData, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  

  sendstudentReviewTest(token,formData): Observable<any> {  
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    }; 
    return this.http.post<any>(this.BASE_URL + 'test/studentReviewTest', formData, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  

  updatetest(formData,token): Observable<any> {  
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    }; 
    return this.http.post<any>(this.BASE_URL + 'test/updateTestStudent', formData, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updatetestimage(formData,token): Observable<any> {  
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    }; 
    return this.http.post<any>(this.BASE_URL + 'test/updateTestStudentImage', formData, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  studentfinishtest(formData,token): Observable<any> {  
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    }; 
    return this.http.post<any>(this.BASE_URL + 'teacherTests/studentFinishTest', formData, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updatetestforcorrection(formData,token): Observable<any> {  
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    }; 
    return this.http.post<any>(this.BASE_URL + 'test/updateTestForCorrection', formData, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateTestForReview(formData,token): Observable<any> {  
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    }; 
    return this.http.post<any>(this.BASE_URL + 'test/updateTestForReview', formData, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  

  getExamsByCategory(token, formData): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'exam/getExamsByCategory', formData, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getCoursesByCategorypublic(formData) {
    return this.http.post<any>(this.BASE_URL + 'course/getPublicCourses', formData).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  exploreFilterCategoryPublic(formData) {
    return this.http.post<any>(this.BASE_URL + 'course/getPublicCoursesExplore', formData).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getExamsByCategorypublic(formData) {
    return this.http.post<any>(this.BASE_URL + 'exam/getPublicExams', formData).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  schoolregistration(formData): Observable<any> {
    return this.http.post<any>(this.BASE_URL + 'school/registerSchool', formData).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  studentsregister(formData): Observable<any> {
    return this.http.post<any>(this.BASE_URL + 'studentRegister/registerStudent', formData).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  contactusregister(formData): Observable<any> {
    return this.http.post<any>(this.BASE_URL + 'contactUs/contact', formData).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }




  getFutureCoursesbyGroup(token, formData): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'course/getFutureGroupCourses', formData, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getFutureExamsbyGroup(token, formData): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'exam/getFutureGroupExams', formData, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  updatePaymentDetails(token, formData): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'payment/updatePaymentDetails', formData, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  addkyc(token, formData): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'kyc/addkyc', formData, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  addPaymentGetRes(token, formData): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'payment/addPaymentGetRes', formData, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getPastCoursesbyGroup(token, formData): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'course/getPastGroupCourses', formData, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getDraftCoursesbyGroup(token, formData): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'course/getDraftCourses', formData, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getDraftExamsbyGroup(token, formData): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'exam/getDraftExams', formData, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }



  getPastExamsbyGroup(token, formData): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'exam/getPastGroupExams', formData, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getsubjects(param: any) {
    console.log("Param", JSON.stringify(param))
    return this.http.post<any>(this.BASE_URL + 'eurekaCourse/getSubjects', JSON.stringify(param), this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
 
  getStandards() {
    // console.log("Param", JSON.stringify())
    return this.http.get<any>(this.BASE_URL + 'eurekaCourse/getStandards', this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getJoinURL(passdata): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<any>(this.BASE_URL + 'courseUser/enrollStudentInCourse', passdata, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getJoinURLexam(passdata): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<any>(this.BASE_URL + 'courseUser/enrollStudentInExam', passdata, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  SongAlbumList(token): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'userPlaylist/playlistFindSongs', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  GetAllProductPackage(): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get<any>(this.BASE_URL + 'productPackage/allPackage', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  addFeedBackTeacher(data, token): Observable<any> {
    const headers_object = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'feedback/createfeedbackTeacher', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

 getfeedbPendingTeacher(token):Observable<any>{
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.BASE_URL + 'feedback/getfeedbackPendingTeacher', headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getFeedBackDetailsForLiveClass(token, data):Observable<any>{
    console.log('token in the user find ', token);
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.BASE_URL + 'feedback/getFeedBackDetailsForLiveClass', data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  url: string = "https://raw.githubusercontent.com/sagarshirbhate/Country-State-City-Database/master/Contries.json";

  allCountries(): Observable<any> {
    return this.http.get(this.url);
  }
  updateBuddiTime(param: any,token) {
  const headers_object = {headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'Bearer ' + token})};
    console.log("Param", JSON.stringify(param))
    return this.http.post<any>(this.BASE_URL + 'user/updateEndBuddiTime', JSON.stringify(param), headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  addPaymentDetail(param:any, token) {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token})
    };
    console.log("Param", JSON.stringify(param))
    return this.http.post<any>(this.BASE_URL + 'paymentDetails/addPaymentDetail', JSON.stringify(param), headers_object).pipe(
    retry(1),
      catchError(this.handleError)
    );
  }

  updatePaymentDetail(param:any, token) {
    const headers_object = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token})
    };
    console.log("Param", JSON.stringify(param))
    return this.http.post<any>(this.BASE_URL + 'paymentDetails/updatePaymentDetail', JSON.stringify(param), headers_object).pipe(
    retry(1),
      catchError(this.handleError)
    );
  }

  getLiveClassDetails(data, token = null) : Observable<any> {
    var headers_object;
    if(token){
      headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token})};
    }
    else{
      headers_object = {headers: new HttpHeaders()};
    }
    let post_url = this.BASE_URL + 'teacherPersonalClass/getPersonalClassDetails';
    console.log(data);
    return this.http.post<any>(post_url, data, headers_object).pipe(
      catchError(this.handleError)
    );
   }
   getStudyhallDetails(data, token = null) : Observable<any> {
    var headers_object;
    if(token){
      headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token})};
    }
    else{
      headers_object = {headers: new HttpHeaders()};
    }
    let post_url = this.BASE_URL + 'teacherPersonalClass/getStudyClassDetails';
    console.log(data);
    return this.http.post<any>(post_url, data, headers_object).pipe(
      catchError(this.handleError)
    );
   }
   getRoundClassDetails(data, token = null) : Observable<any> {
    var headers_object;
    if(token){
      headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token})};
    }
    else{
      headers_object = {headers: new HttpHeaders()};
    }
    let post_url = this.BASE_URL + 'teacherPersonalClass/getRoundClassDetails';
    console.log(data);
    return this.http.post<any>(post_url, data, headers_object).pipe(
      catchError(this.handleError)
    );
   }
   getInstitutionDetailsWithoutLogin(data, token = null ) : Observable<any> {
    var headers_object;
    if(token){
      headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token})};
    }
    else{
      headers_object = {headers: new HttpHeaders()};
    }
    let post_url = this.BASE_URL + 'institution/getInstitutionDetailsWithoutLogin';
    console.log(data);
    return this.http.post<any>(post_url, data, headers_object).pipe(
      catchError(this.handleError)
    );
   }
   getUserPersonalClassesStudent(token) : Observable<any> { 
    var headers_object;
    if(token){
      headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token})};
    }
    else{
      headers_object = {headers: new HttpHeaders()};
    }
    let get_url = this.BASE_URL + 'teacherPersonalClass/getUserPersonalClassesStudent';
    return this.http.get<any>(get_url, headers_object).pipe(
      catchError(this.handleError)
    );
   }
   getUserStudyClassesStudent(token,data) : Observable<any> { 
    var headers_object;
    if(token){
      headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token})};
    }
    else{
      headers_object = {headers: new HttpHeaders()};
    }
    let get_url = this.BASE_URL + 'teacherPersonalClass/getUserStudyClassesStudent';
    return this.http.post<any>(get_url, data, headers_object).pipe(
      catchError(this.handleError)
    );
   }
   getUserRoundClassesStudent(token,data) : Observable<any> { 
    var headers_object;
    if(token){
      headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token})};
    }
    else{
      headers_object = {headers: new HttpHeaders()};
    }
    let get_url = this.BASE_URL + 'teacherPersonalClass/getUserRoundClassesStudent';
    return this.http.post<any>(get_url, data, headers_object).pipe(
      catchError(this.handleError)
    );
   }

   getUserPersonalClassRequestsStudent(token, data): Observable<any> {
    const headers_object = {headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token})};
    let post_url = this.BASE_URL + 'teacherPersonalClass/getUserPersonalClassRequestsStudent';
    return this.http.post<any>(post_url, data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
   }
   getUserStudyClassRequestsStudent(token, data): Observable<any> {
    const headers_object = {headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token})};
    let post_url = this.BASE_URL + 'teacherPersonalClass/getUserStudyClassRequestsStudent';
    return this.http.post<any>(post_url, data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
   }
   getUserPersonalClassesTeacher(token): Observable<any> {
    const headers_object = {headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token})};
    let get_url = this.BASE_URL + 'teacherPersonalClass/getUserPersonalClassesTeacher';
    return this.http.get<any>(get_url, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
   }
   getUserStudyClassesTeacher(token,data): Observable<any> {
    const headers_object = {headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token})};
    let get_url = this.BASE_URL + 'teacherPersonalClass/getUserStudyClassesTeacher';
    return this.http.post<any>(get_url, data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
   }
   getUserRoundClassesTeacher(token,data): Observable<any> {
     console.log('data',data);
    const headers_object = {headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token})};
    let get_url = this.BASE_URL + 'teacherPersonalClass/getUserRoundClassesTeacher';
    return this.http.post<any>(get_url, data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
   }
   getUserPersonalClassRequestsTeacher(token, data): Observable<any> {
    console.log('token',token,data);
    const headers_object = {headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token})};
    let post_url = this.BASE_URL + 'teacherPersonalClass/getUserPersonalClassRequestsTeacher';
    return this.http.post<any>(post_url, data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
   }
   getUserRoundClassRequestsTeacher(token, data): Observable<any> {
    const headers_object = {headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token})};
    let post_url = this.BASE_URL + 'teacherPersonalClass/getUserRoundClassRequestsTeacher';
    return this.http.post<any>(post_url, data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
   }
   getUserStudyClassRequestsTeacher(token, data): Observable<any> {
    console.log('token', token, data);
    const headers_object = {headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token})};
    let post_url = this.BASE_URL + 'teacherPersonalClass/getUserStudyClassRequestsTeacher';
    return this.http.post<any>(post_url, data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
   }
   
   getTeacherPersonalClassDetails(token, data): Observable<any> {
    const headers_object = {headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token})};
    let get_url = this.BASE_URL + 'teacherPersonalClass/getTeacherPersonalClassDetails';
    return this.http.post<any>(get_url, data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
   }
   getTeacherStudyClassDetails(token, data): Observable<any> {
    const headers_object = {headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token})};
    let get_url = this.BASE_URL + 'teacherPersonalClass/getTeacherStudyClassDetails';
    return this.http.post<any>(get_url, data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
   }
   getTeacherRoundClassDetails(token, data): Observable<any> {
    const headers_object = {headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token})};
    let get_url = this.BASE_URL + 'teacherPersonalClass/getTeacherRoundClassDetails';
    return this.http.post<any>(get_url, data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
   }
   personalClassRequestAction(token, data): Observable<any> {
    const headers_object = {headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token})};
    let post_url = this.BASE_URL + 'teacherPersonalClass/personalClassRequestAction';
    return this.http.post<any>(post_url, data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
   }
   
   studyClassRequestAction(token, data): Observable<any> {
    const headers_object = {headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token})};
    let post_url = this.BASE_URL + 'teacherPersonalClass/studyClassRequestAction';
    return this.http.post<any>(post_url, data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
   }
   getCourseCompletionDetails(token): Observable<any> {
    const headers_object = {headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token})};
    let get_url = this.BASE_URL + 'courseCompletion/getCourseCompletionDetails';
    return this.http.get<any>(get_url, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
   }

   addCourseCompletion(token, data): Observable<any> {
     let headers_object = {};
     if(token == null){
      headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token})};
     }
     else{
      headers_object = {headers: new HttpHeaders()};
     }
    
    let post_url = this.BASE_URL + 'courseCompletion/addCourseCompletion';
    return this.http.post<any>(post_url, data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
   }

   getWalletBalance(token): Observable<any> {
    const headers_object = {headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token})};
    let get_url = this.BASE_URL + 'paymentDetails/getWalletBalance';
    return this.http.get<any>(get_url, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
   }

   addMoneyToWallet(token, data): Observable<any> {
    const headers_object = {headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token})};
    let post_url = this.BASE_URL + 'paymentDetails/addMoneyToWallet';
    return this.http.post<any>(post_url, data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
   }

   completeMoneyToWallet(token, data): Observable<any> {
    const headers_object = {headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token})};
    let post_url = this.BASE_URL + 'paymentDetails/completeMoneyToWallet';
    return this.http.post<any>(post_url, data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
   }

   purchaseInstitution(token, data): Observable<any> {
    const headers_object = {headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token})};
    let post_url = this.BASE_URL + 'institution/purchaseInstitution';
    return this.http.post<any>(post_url, data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
   }

   completePurchaseInstitution(token, data): Observable<any> {
    const headers_object = {headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token})};
    let post_url = this.BASE_URL + 'institution/completePurchaseInstitution';
    return this.http.post<any>(post_url, data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
   }

   getinstitutionTypes(token): Observable<any> {
    const headers_object = {headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token})};
    let get_url = this.BASE_URL + 'institution/getInstitutionTypes';
    return this.http.get<any>(get_url, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
   }

   createInstitution(token, data): Observable<any> {
    const headers_object = {headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token})};
    let post_url = this.BASE_URL + 'institution/createInstitution';
    return this.http.post<any>(post_url, data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
   }

   getInstitutionDetails(token): Observable<any> {
    const headers_object = {headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token})};
    let get_url = this.BASE_URL + 'institution/getInstitutionDetails';
    return this.http.get<any>(get_url, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
   }

   addTeacherToInstitution(token, data): Observable<any> {
    const headers_object = {headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token})};
    let post_url = this.BASE_URL + 'institution/addTeacherToInstitution';
    return this.http.post<any>(post_url, data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
   }

   addStudentToInstitution(token, data): Observable<any> {
    const headers_object = {headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token})};
    let post_url = this.BASE_URL + 'institution/addStudentToInstitution';
    return this.http.post<any>(post_url, data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
   }

   upgradeInstitution(token, data): Observable<any> {
    const headers_object = {headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token})};
    let post_url = this.BASE_URL + 'institution/upgradeInstitution';
    return this.http.post<any>(post_url, data, headers_object).pipe(
      retry(1),
      catchError(this.handleError)
    );
   }
}
