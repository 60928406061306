<section class="how-it-works-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
           <!-- <span class="sub-title">Find Courses</span> -->
           <h2>Take your experience online in 3 Simple Steps</h2>
            <!-- <h1>Get Started</h1> -->
        </div>

        <div class="row work">
            <div class="col-lg-4 col-md-6">
                <div class="single-work-process mb-30">
                    <div class="icon">
                        <a (click)="registerclick()" href="javascript:void(0)"> <i class='bx bx-search-alt'></i> </a>
                    </div>
                   
                    <h3><a (click)="registerclick()" href="javascript:void(0)" style="color: Black;">Create a Live Class</a></h3>
                   <!-- <p>An nec placerat repudiare scripserit, temporibus complectitur at sea, vel ignota fierent rreloquentiam id.</p>-->
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-work-process mb-30">
                    <div class="icon">
                        <a (click)="enrollattend()" href="javascript:void(0)">   <i class='bx bx-info-square'></i> </a>
                    </div>  
                    <h3><a (click)="enrollattend()" href="javascript:void(0)"style="color: Black;">Share with Learners</a></h3>
                   <!-- <p>An nec placerat repudiare scripserit, temporibus complectitur at sea, vel ignota fierent rreloquentiam id.</p>-->
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-work-process mb-30">
                    <div class="icon">
                        <a (click)="enrollattend()" href="javascript:void(0)"> <i class='bx bx-like'></i> </a>                </div>
                    <h3><a (click)="enrollattend()" href="javascript:void(0)" style="color: Black;">Complete the  Class.</a></h3>
                   <!-- <p>An nec placerat repudiare scripserit, temporibus complectitur at sea, vel ignota fierent rreloquentiam id.</p>-->
                </div>
            </div>
        </div>
    </div>
</section>