import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-home-page-one',
  templateUrl: './home-page-one.component.html',
  styleUrls: ['./home-page-one.component.scss']
})
export class HomePageOneComponent implements OnInit {
  isLogin:any ="false";
  constructor( 
    private userApi: ApiService,
    public toastr: ToastrManager,
    public router: Router,
    ) { }

  ngOnInit(): void {
    this.isLogin = localStorage.getItem('isLoggedin');
    if(this.isLogin =="false" || this.isLogin==null){
      // window.location.href = '/login';
    }
    var token =  localStorage.getItem('webtoken');

  }

}
