import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-livelist',
  templateUrl: './livelist.component.html',
  styleUrls: ['./livelist.component.scss']
})
export class LivelistComponent implements OnInit {
  public isStudent: boolean = false;
  public isTeacher: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

}
