<div >
    <div *ngIf="isTeacher">
        <app-header-style-one-teacher></app-header-style-one-teacher>
    </div>
    <div *ngIf="!isTeacher">
        <app-header-style-one></app-header-style-one>
    </div>
</div>
<div class="containerBg" style="padding-bottom: 5%">
    <div class="container">
        <h3 style="color: var(--mainColor); padding-top: 3%" >Class Courses</h3>
        <hr>
        <div class="container-fluid row p-0" style="cursor: pointer;padding-top: 3%" *ngIf="coursecategorylist.length !=0">
            <div class="col-lg-3 col-md-6"
                *ngFor="let slide of coursecategorylist | paginate: { id: 'pagination11',itemsPerPage: count111, currentPage: p111 }">
                <div class="single-courses-box without-box-shadow mb-30">
                    <div class="courses-image">
                        <img style="width:100%" (click)="coursedetails(slide._id)"
                            style="width: 250px;height: 175px;"
                            [src]="slide.thumbnail ? slide.thumbnail : 'assets/img/teacherregister.jpg'" alt=""
                            width="100%">                   
                    </div>
                    <div class="courses-content">
                   
                        <h3>
                            <a (click)="coursedetails(slide._id)"
                                class="d-inline-block">
                                <span class="text-break">
                                    {{slide.courseName}}
                                </span>
                            </a>      
                        </h3>
                    </div>       
                     
                    <div class="courses-box-footer">
                        {{categoryname}},
                        {{modulename}}
                    </div>
                     <div class="courses-box-footer">
                        Number of Topics : {{slide.topicList.length}}
                    </div> 
                  
                </div>
            </div>
        </div>
        <div style="float: right;" *ngIf="coursecategorylist.length!=0">
            <pagination-controls (pageChange)="p111 = $event" id="pagination111"></pagination-controls>
        </div>
        <div *ngIf="coursecategorylist.length ==0 ">
            No Course(s) Available.
        </div>
    </div>

   
</div>