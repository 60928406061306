<!-- <div class="row">
    <div class="col-lg-6">
        <div class="feedback-slides owl-carousel owl-theme">
            <div class="single-feedback-item">
                <p>“ I was looking for a good online learning provider for my daughter studying in 12th std. I am happy I found buddi Academy which takes a personalised approach to education, by giving individual attention to each learner. The quality of teaching is excellent and I gladly recommend buddi to anyone who wants their child to excel.”</p>
        
                <div class="info">
                    <h3>Sridevi</h3>
                    <span>Chennai</span>
                   
                </div>
            </div>
        
            <div class="single-feedback-item">
                <p>“My daughter is a very bright learner and a good chess player. She has been learning maths and chemistry from buddi team. She was able to pick up the concepts quickly and interact with the tutor very easily. Never felt it as an online class as it was very smooth and easy. Due to Corona kids are not able to go to school and learn thoroughly. Buddi can definitely help to clear all your weaker areas. I request all parents to try buddi.”</p>
        
                <div class="info">
                    <h3>Bindhu</h3>
                    <span>Chennai</span>
                   
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-12">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/W49HGEAWLYQ" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        
    </div>
    <div class="col-lg-6">
        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" data-interval="false" >
           
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img class="biology" style="border-radius: 10px;"
                    src="../../../../../assets/img/sridevi.jpg" data-toggle="modal"  data-target="#myModal11"
                    data-backdrop="false" style="cursor: pointer; border-radius: 10px;">
                    <div class="overlay2" data-toggle="modal" data-target="#myModal11" data-backdrop="false">
                        <a class="icon2" style="color: #FF0000;">
                            <i c class='bx bxs-right-arrow'></i>
                        </a>
                    </div>
                <iframe class="iframe-carousel" width="560" height="315" style="border-radius: 10px;" src="https://www.youtube.com/embed/W49HGEAWLYQ?enablejsapi=1&version=3&playerapiid=ytplayer" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div class="modal fade" id="myModal11" style="padding-top: 100px;">
                <div class="modal-dialog">
                    <div class="modal-content" style="border-radius: 13px;">
                        <div class="modal-body">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><b>&times;</b></span>
                            </button>
                            <iframe class="iframe"
                                src="https://www.youtube.com/embed/9tTi95bis1Q?enablejsapi=1&version=3&playerapiid=ytplayer"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>
                        </div>
          
                    </div>
                </div>
            </div>
              <div class="carousel-item" >
                <img class="biology" style="border-radius: 10px;"
                src="../../../../../assets/img/Bindhu.jpg" data-toggle="modal" data-target="#myModal11"
                data-backdrop="false" style="cursor: pointer; border-radius: 10px;">
                <div class="overlay2" data-toggle="modal" data-target="#myModal11" data-backdrop="false">
                    <a class="icon2" style="color: #FF0000;">
                        <i c class='bx bxs-right-arrow'></i>
                    </a>
                </div>
                <iframe class="iframe-carousel" width="560" height="315" style="border-radius: 10px;" src="https://www.youtube.com/embed/kxMF-lpJbzg?enablejsapi=1&version=3&playerapiid=ytplayer" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              
            </div>
            <a class="carousel-control-prev" style="width: 3%;" href="#carouselExampleIndicators" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon"  aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" style="width: 3%;" href="#carouselExampleIndicators" role="button" data-slide="next">
              <span class="carousel-control-next-icon"  aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
    </div>
   
</div> -->
<section class="home-slides slider owl-carousel owl-theme">
  <div class="main-banner ">
      <div class="d-table">
          <div class="d-table-cell">
              <!-- <div class="container"> -->
                  <div class="main-banner-content" style="max-width: 1260px;">
                      <div class="row">
                          <div class="col-lg-7 col-md-7 col-sm-7" style="padding-bottom: 10px; width: 92%;">
                              <!-- <span class="sub-title" style="color: black;">buddi-NEET 2021</span> -->
                              <h2 style="color: black; font-size: 16px; font-weight: 500; line-height: 1.9;">“ I was looking for a good online learning provider for my daughter studying in 12th std. I am happy I found buddi Academy which takes a personalised approach to education, by giving individual attention to each learner. The quality of teaching is excellent and I gladly recommend buddi to anyone who wants their child to excel.”</h2>
                              <div class="info">
                                <h3 style="color:var(--mainColor);">Sridevi</h3>
                                <span style="color:var(--mainColor);">Chennai</span>
                               
                            </div>
                          
                          </div>
                          <div class="col-lg-5 col-md-5 col-sm-5 hovervideo" >
                              <img src="../../../../../assets/img/sridevi.jpg"  data-toggle="modal" data-target="#myModalfeedback1" data-backdrop="false" style="cursor: pointer; border-radius: 10px;">
                              <!-- <iframe class="iframe" src="https://www.youtube.com/embed/Wzm15soEfOE?enablejsapi=1&version=3&playerapiid=ytplayer" frameborder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                              <div class="overlay1" data-toggle="modal" data-target="#myModalfeedback1" data-backdrop="false">
                                  <a  class="icon">
                              <i c class='bx bxs-right-arrow' style="color: #FF0000 ;"></i>
                          </a>
                          </div>
                          </div>
                          <div  class="modal" id="myModalfeedback1">
                              <div class="modal-dialog" >
                                <div class="modal-content" style="border-radius: 13px;">
                                  <div class="modal-body">
                                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                          <span aria-hidden="true"><b>&times;</b></span>
                                        </button>
                                      <iframe class="iframe" src="https://www.youtube.com/embed/W49HGEAWLYQ?enablejsapi=1&version=3&playerapiid=ytplayer" frameborder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                  </div>

                                </div>
                              </div>
                            </div>
                      </div>
                  </div>
              <!-- </div> -->
          </div>
      </div>
  </div>
  
  <div class="main-banner ">
      <div class="d-table">
          <div class="d-table-cell">
              <!-- <div class="container"> -->
                  <div class="main-banner-content" style="max-width: 1260px;">
                      
                      <div class="row " >
                          <div class="col-lg-7 col-md-7 col-sm-7" style="padding-bottom: 10px; width: 92%;">
                              <!-- <span class="sub-title" style="color: black;">K12 2021</span> -->
                              <h2 style="color: black; font-size: 16px; font-weight: 500;  line-height: 1.9;">“ My daughter is a very bright learner and a good chess player. She has been learning maths and chemistry from buddi team. She was able to pick up the concepts quickly and interact with the tutor very easily. Never felt it as an online class as it was very smooth and easy. Due to Corona kids are not able to go to school and learn thoroughly. Buddi can definitely help to clear all your weaker areas. I request all parents to try buddi.”</h2>
                              <div class="info" >
                                <h3 style="color:var(--mainColor);">Bindhu</h3>
                                <span style="color:var(--mainColor);">Chennai</span>
                               
                            </div>
                              
                              <!-- <a class="default-btn mr-2 p-3 " style="cursor: pointer;" title="Click here" (click)="k12()">K-12 2021</a> -->
                          
                          </div>
                          <div class="col-lg-5 col-md-5 col-sm-5 hovervideo">
                             <img src="../../../../../assets/img/Bindhu.jpg" style="border-radius: 10px;" data-toggle="modal" data-target="#myModalfeedback2" data-backdrop="false">
                              <!-- <iframe class="iframe" src="https://www.youtube.com/embed/w0Z_X8EBUfk?enablejsapi=1&version=3&playerapiid=ytplayer" frameborder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                              <div class="overlay1" data-toggle="modal" data-target="#myModalfeedback2" data-backdrop="false">
                                  <a  class="icon">
                              <i c class='bx bxs-right-arrow' style="color: #FF0000 ;"></i>
                          </a>
                          </div>
                          </div>
                          <div  class="modal" id="myModalfeedback2">
                              <div class="modal-dialog">
                                <div class="modal-content" style="border-radius: 13px;">
                                  <div class="modal-body">
                                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                          <span aria-hidden="true"><b>&times;</b></span>
                                        </button>
                                        <iframe class="iframe" src="https://www.youtube.com/embed/kxMF-lpJbzg?enablejsapi=1&version=3&playerapiid=ytplayer" frameborder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                  </div>

                                </div>
                              </div>
                            </div>
                      </div>
                  </div>
              <!-- </div> -->
          </div>
      </div>
  </div>

  <!-- <div class="main-banner ">
      <div class="d-table">
          <div class="d-table-cell" style="background-color: #dcf5ff  ;">
              <div class="container">
                  <div class="main-banner-content" >
                      
                      <div class="row " >
                          <div class="col-lg-7 col-md-7 col-sm-7" style="padding-bottom: 10px;">
                              <span class="sub-title" style="color: black;">Coding 2021</span>
                              <h1 style="color:var(--mainColor);" class="buddititle">buddi-Coding</h1>
                              <h2 style="color: black;" class="buddisub">Take Your Child to Next Level of Logical Thinking
                              </h2>
                              
                              <a class="default-btn mr-2 p-3 " style="cursor: pointer;" title="Click here" (click)="k12()">K-12 2021</a>
                          
                          </div>
                          <div class="col-lg-5 col-md-5 col-sm-5 hovervideo">
                             <img src="../../../../../assets/img/coding1.jpg" style="border-radius: 10px;" data-toggle="modal" data-target="#myModal2" data-backdrop="false">
                             
                              <div class="overlaycoding" data-toggle="modal" data-target="#myModal3" data-backdrop="false">
                                  <a href="#" class="icon">
                              <i c class='bx bxs-right-arrow'></i>
                          </a>
                          </div>
                          </div>
                          <div  class="modal" id="myModal3">
                              <div class="modal-dialog">
                                <div class="modal-content" style="border-radius: 13px;">
                                  <div class="modal-body">
                                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                          <span aria-hidden="true"><b>&times;</b></span>
                                        </button>
                                        <iframe class="iframe" src="https://www.youtube.com/embed/p6qVqYK8a2c?enablejsapi=1&version=3&playerapiid=ytplayer" frameborder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                  </div>

                                </div>
                              </div>
                            </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div> -->

  <!-- <div class="main-banner ">
      <div class="d-table">
          <div class="d-table-cell" style="background-color: #dcf5ff  ;">
              <div class="container">
                  <div class="main-banner-content" >
                      
                      <div class="row " >
                          <div class="col-lg-7 col-md-7 col-sm-7" style="padding-bottom: 10px;">
                              <span class="sub-title" style="color: black;">buddi Academy</span>
                              <h1 style="color:var(--mainColor);" class="buddititle">Time Management Tips for K-12, JEE & NEET Exams</h1>
                              <h2 style="color: black;" class="buddisub">Simple yet useful steps to be followed to complete exam successfully on time.
                              </h2>
                              
                            
                          
                          </div>
                          <div class="col-lg-5 col-md-5 col-sm-5 hovervideo">
                             <img src="../../../../../assets/img/timetips.jpg" style="border-radius: 10px;" data-toggle="modal" data-target="#myModal2" data-backdrop="false">
                             
                              <div class="overlaycoding" data-toggle="modal" data-target="#myModal4" data-backdrop="false">
                                  <a href="#" class="icon">
                              <i c class='bx bxs-right-arrow'></i>
                          </a>
                          </div>
                          </div>
                          <div  class="modal" id="myModal4">
                              <div class="modal-dialog">
                                <div class="modal-content" style="border-radius: 13px;">
                                  <div class="modal-body">
                                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                          <span aria-hidden="true"><b>&times;</b></span>
                                        </button>
                                        <iframe class="iframe" src="https://www.youtube.com/embed/j0cKS6KGgDU?enablejsapi=1&version=3&playerapiid=ytplayer" frameborder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                  </div>

                                </div>
                              </div>
                            </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div> -->

  <!-- <div class="main-banner ">
      <div class="d-table">
          <div class="d-table-cell" style="background-color: #dcf5ff  ;">
              <div class="container">
                  <div class="main-banner-content" >
                      
                      <div class="row " >
                          <div class="col-lg-7 col-md-7 col-sm-7" style="padding-bottom: 10px;">
                              <span class="sub-title" style="color: black;">buddi Academy</span>
                              <h1 style="color:var(--mainColor);" class="buddititle">Overcome Exams Without Fear</h1>
                              <h2 style="color: black;" class="buddisub">Tips to develop self- belief, self- discipline and self- confidence to overcome any challenge in life
                              </h2>
                              
                            
                          
                          </div>
                          <div class="col-lg-5 col-md-5 col-sm-5 hovervideo">
                             <img src="../../../../../assets/img/withoutfear.jpg" style="border-radius: 10px;" data-toggle="modal" data-target="#myModal5" data-backdrop="false">
                             
                              <div class="overlay5" data-toggle="modal" data-target="#myModal5" data-backdrop="false">
                                  <a href="#" class="icon">
                              <i c class='bx bxs-right-arrow'></i>
                          </a>
                          </div>
                          </div>
                          <div  class="modal" id="myModal5">
                              <div class="modal-dialog">
                                <div class="modal-content" style="border-radius: 13px;">
                                  <div class="modal-body">
                                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                          <span aria-hidden="true"><b>&times;</b></span>
                                        </button>
                                        <iframe class="iframe" src="https://www.youtube.com/embed/D8726i60q0A?enablejsapi=1&version=3&playerapiid=ytplayer" frameborder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                  </div>

                                </div>
                              </div>
                            </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div> -->

</section>