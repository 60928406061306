import { Component, OnInit,Inject, ElementRef, ViewChild, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Options } from 'ng5-slider';
import { ApiService } from '../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {NgSelectModule, NgOption} from '@ng-select/ng-select';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-tests-request',
  templateUrl: './tests-request.component.html',
  styleUrls: ['./tests-request.component.scss']
})
export class TestsRequestComponent implements OnInit {

  public courseData:any=[];
  public examData:any=[];
  public coursecategorylist : any = [];
  public courseboardlist : any = [];
  public coursesubjectlist : any = [];
  public languageapi : any = [];
  public gradeapi : any = [];
  public allMeeting : any = [];
  public allMeetingPublic : any = [];
  selectedValue = "";   
  public section = "Select section";
  exploreForm: FormGroup;
  exploreFormPublic: FormGroup;
  public userprofdata : any =[];
  public isLogin : any=[];
  public isStudent:any = '';
  public groupNameTeacher:any='';
  public groupName:any='';
  public isSession:boolean=true;
  public isCourse:boolean=true;
  public isexams:boolean=true;  
  public inviteUrl = "";
  public frontwebsite = environment.frontUrl;
  public requestedDataList:any = [];
  public uncorrecteddata:any = [];
  public revieweddata:any = [];
  public  name : any;
  public isAcceptedtests:boolean=false;
  public isReviewedtests:boolean=false;
   //For Pagination
   un: number = 1;
   countun: number = 4;
 
   p1: number = 1;
   count1: number = 4;
   
  p2: number = 1;
  count2: number = 4;

  review: number = 1;
  countreview:number = 4;
  // ------------------
  constructor(private SpinnerService:NgxSpinnerService,private activatedRoute:ActivatedRoute,public location: Location, public toastr: ToastrManager, private formBuilder: FormBuilder, private rest: ApiService, private router: Router, private my_router: ActivatedRoute ) { }

  ngOnInit(): void {
    this.name = this.my_router.snapshot.params['name'];
    var urlArray = window.location.href.split('/');
    var pageId=  urlArray[urlArray.length-1];
    this.getcoursecategory();
    this.getboardfield();
    this.getsubjectfield();
    this.getlanguage();
    this.getgrade();
    //this.getMeetingAll();
    this.exploreForm = this.formBuilder.group({    
      gradeID: ['all'],
      subjectID: ['all'],
      languageID:['all'],     
      categoryID: ['5f12a2efbf9506355c8adb5c'],
      boardID: ['all'],
      meetingType:['Public'],
      section:['all']
    });
    this.getAllRequest();
   this.filterData(this.exploreForm.value);
   
  }
  // this.getAllRequest();
  get f() { return this.exploreForm.controls; }


  filterData(reqForm){
    var req = {
      gradeID:reqForm.gradeID == 'all' ? '' : reqForm.gradeID,
      subjectID:reqForm.subjectID == 'all' ? '' : reqForm.subjectID,
      languageID:reqForm.languageID == 'all' ? '' : reqForm.languageID,
      categoryID:reqForm.categoryID == 'all' ? '' : reqForm.categoryID,
      boardID:reqForm.boardID == 'all' ? '' : reqForm.boardID,
      teacherID:localStorage.getItem('teacherID'),
      // acceptStatus:false,
      // meetingType:reqForm.meetingType,
      groupname:reqForm.meetingType == 'Group' ? this.groupName : '',
    }

  }


  getMeetingAll(){
    this.rest.getMeetingAll().subscribe((result) => {
      this.allMeeting = result.data;
    }, (err) => {
    console.log(err);
    });
  }

  getcoursecategory() {
    this.rest.getCourseCategoryHeader().subscribe((result) => {
      this.coursecategorylist = result.data;
      var urlArray = window.location.href.split('/');
      this.coursecategorylist.forEach(function(val,key){
      })
    }, (err) => {
    console.log(err);
    });
  }

  getboardfield(){
    this.rest.boardfield().subscribe((result) => {
      this.courseboardlist = result.data;
    }, (err) => {
    console.log(err);
    });
  }

  getsubjectfield(){
    this.rest.subjectfield().subscribe((result) => {
      this.coursesubjectlist = result.data;
    }, (err) => {
    console.log(err);
    });
  }

  getlanguage(){
    this.rest.languagefield().subscribe((result) => {
      this.languageapi = result.data;
    }, (err) => {
    console.log(err);
    });
  }

  detailsRequest(data,pagename){
    data.pagename = pagename;
    this.router.navigate(['/teacher/request'+"/"+data.meetingID], { state:data });
  }

  getgrade(){
    this.rest.grade().subscribe((result) => {
      this.gradeapi = result.data;
    }, (err) => {
    console.log(err);
    });
  }
  selectChange (event: any) {
    this.selectedValue = event.target.options[event.target.selectedIndex].text; 
    var reqForm =  this.exploreForm.value;
    if(reqForm.subjectID == 'all'){
      reqForm.subjectID = ''
    }
    this.courseData=[];
    this.allMeeting=[];
    this.getAllRequest();
    // this.coursefilterData(reqForm);
   }
   detailsMeeting(data,pagename){
    data.pagename = pagename;
    var jsonStr= JSON.stringify(data)
    localStorage.setItem('livedetails',jsonStr);
    this.router.navigate(['/enroll/'+"/"+data.meetingID], { state:data });
  }

  coursedetails(data) {
    console.log(data);
    this.router.navigateByUrl('courses/'+data.categoryID.categoryName+"/"+data._id);
  }

  Examdetails(data) {
    console.log(data);
    this.router.navigateByUrl('exams/'+data.categoryID.categoryName+"/"+data._id);
  }

  getAllRequest() {
    const subject = [];
    var formData = {};
    const webToken = localStorage.getItem('webtoken');
    if (this.exploreForm.value.subjectID != "all") {

      subject.push(this.exploreForm.value.subjectID);
      formData["subjectID"] = subject;
    }
    else {
      formData["subjectID"] = "all";
    }
    formData["language"] = this.exploreForm.value.languageID;
    formData["category"] = this.exploreForm.value.categoryID;
    formData["board"] = this.exploreForm.value.boardID;
    if(this.name == "Accept") {
      this.isAcceptedtests = true;
      this.rest.getuncorrected(formData,webToken).subscribe((result) => {
        if(result.status) {
          this.uncorrecteddata = result.data;
        }
        else {
          this.toastr.errorToastr(result.message);
        }
      }, (err) => {
        console.log(err);
      });
    }
    else {
      this.isReviewedtests = true;
      this.rest.revieweddata(formData,webToken).subscribe((result) => {
        if(result.status) {
          this.revieweddata = result.data;
        }
        else {
          this.toastr.errorToastr(result.message);
        }
      }, (err) => {
        console.log(err);
      });
    }
    
  }

  testdetails(data) {
    this.router.navigate(['/buddi-test-request/' + data + '/Accepted']);
  }

  testdetailsreview(data) {
    this.router.navigate(['/buddi-test-request/' + data + '/Review']);
  }
}

