<div class="page-title-area page-title-style-two jarallax image" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
           
            <h2 style="color:#0054ee;">PERSONALISED EDUCATION FOR ALL. 
                </h2>
            <p style="color: white;font-size: 20px;font-weight :700">AI Powered Adaptive Learning Platform. </p>
            <p style="color: white;font-size: 20px;font-weight :700">Ace Your Learning With buddi Digital Solution. Take Classes From Anywhere Using Any Device. </p>
            
        </div>
    </div>
</div>