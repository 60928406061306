<!-- //page done on feb 5 2022 by vamsi-->
<app-header-style-one *ngIf="!isTeacher"></app-header-style-one>
    <app-header-style-one-teacher
        *ngIf="isTeacher"
    ></app-header-style-one-teacher>
<!-- <app-header-style-four></app-header-style-four> -->

<!-- <app-homesix-main-banner></app-homesix-main-banner> -->

      
        <!--  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea.</p>-->
    

<!-- <app-hometwo-main-banner></app-hometwo-main-banner> FRIST MARKETING -->
<div class="container hig">

<div class="row non">
    <div class="col-md-6 pic" >
    <img src="../../../../../assets/img/buddi learn.png" alt="buddi learn"/>
    </div>
    <div class="col-md-6 tag">
        <h1>Welcome to buddi.life
            </h1>
        
        <h5>Learn in small steps.<br>
        Earn while you learn. <br>
        </h5>
           <a routerLink="/getstarted"><button class="btn">Get Started</button></a>

    </div>
</div>
</div>

<!-- <app-listing-webinar></app-listing-webinar>
<app-listing-classrooms></app-listing-classrooms> -->

<!-- 
<section class="courses-categories-area bg-F7F9FB pt-100 pb-70">
    <div class="container">
        <div class="section-title text-left">
            <span class="sub-title">Categories</span>
            <h2>Product Categories</h2>
            <a routerLink="/explore/5f12a2efbf9506355c8adb5c" class="default-btn"><i
                    class='bx bx-show-alt icon-arrow before'></i><span class="label">Explore</span><i
                    class="bx bx-show-alt icon-arrow after"></i></a>
        </div>

        <app-student-categories></app-student-categories>
    </div>

    <div id="particles-js-circle-bubble-2"></div>
</section> -->

<!-- <div class="container selfclass">
    <h3 class="sample">Essential Life Skills</h3>
    <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-3 hovervideo">
            <img width="260" height="135" style="border-radius: 10px;"
                src="../../../../../assets/img/timetipssample.jpeg" data-toggle="modal" data-target="#myModal6"
                data-backdrop="false" style="cursor: pointer; border-radius: 10px;">
            <br>
            <span style=" font-size: 15px; color: var(--mainColor); padding-top: 10px;">Time Management Tips for K-12,
                JEE & NEET Exams</span>
            
            <div class="overlay1" data-toggle="modal" data-target="#myModal6" data-backdrop="false">
                <a class="icon" style="color: #FF0000;">
                    <i c class='bx bxs-right-arrow'></i>
                </a>
            </div>
        </div>
        <div class="modal" id="myModal6">
            <div class="modal-dialog">
                <div class="modal-content" style="border-radius: 13px;">
                    <div class="modal-body">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><b>&times;</b></span>
                        </button>
                        <iframe class="iframe"
                            src="https://www.youtube.com/embed/j0cKS6KGgDU?enablejsapi=1&version=3&playerapiid=ytplayer"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>

                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-3 hovervideo">
            <img width="260" height="135" style="border-radius: 10px;"
                src="../../../../../assets/img/withoutfearsample.jpeg" data-toggle="modal" data-target="#myModal7"
                data-backdrop="false" style="cursor: pointer; border-radius: 10px;">
            <br>
            <span style=" font-size: 15px; color: var(--mainColor); padding-top: 10px;">Tips to develop self- belief,
                self- discipline and self- confidence to overcome Exams.</span>
           
            <div class="overlay1" data-toggle="modal" data-target="#myModal7" data-backdrop="false">
                <a class="icon" style="color: #FF0000;">
                    <i c class='bx bxs-right-arrow'></i>
                </a>
            </div>
        </div>
        <div class="modal" id="myModal7">
            <div class="modal-dialog">
                <div class="modal-content" style="border-radius: 13px;">
                    <div class="modal-body">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><b>&times;</b></span>
                        </button>
                        <iframe class="iframe"
                            src="https://www.youtube.com/embed/D8726i60q0A?enablejsapi=1&version=3&playerapiid=ytplayer"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>

                </div>
            </div>
        </div>

        <div class="col-lg-3 col-md-3 col-sm-3 hovervideo">
            <img width="260" height="135" style="border-radius: 10px;"
                src="../../../../../assets/img/self-confidence.jpg" data-toggle="modal" data-target="#myModal8"
                data-backdrop="false" style="cursor: pointer; border-radius: 10px;">
            <br>
            <span style=" font-size: 15px; color: var(--mainColor); padding-top: 10px;">Self - Belief Visualise your success by attaining self- confidence.</span>
           
            <div class="overlay1" data-toggle="modal" data-target="#myModal8" data-backdrop="false">
                <a class="icon" style="color: #FF0000;">
                    <i c class='bx bxs-right-arrow'></i>
                </a>
            </div>
        </div>
        <div class="modal" id="myModal8">
            <div class="modal-dialog">
                <div class="modal-content" style="border-radius: 13px;">
                    <div class="modal-body">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><b>&times;</b></span>
                        </button>
                        <iframe class="iframe"
                            src="https://www.youtube.com/embed/YPvSPX8lwco?enablejsapi=1&version=3&playerapiid=ytplayer"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>

                </div>
            </div>
        </div>

    </div>

</div> -->
<br>
<app-student-features></app-student-features>

<section class="funfacts-style-two ptb-70">
    <div class="container">
        <app-student-funfacts></app-student-funfacts>
    </div>

    <div class="container">
        <div class="section-title">
            <h2 style="margin-top: 10% ;">buddi Unique Model</h2>
            </div>
        <div class="row">
            <div class="col-md-12 unique">
        
        <img src="../../../../../assets/img/buddi unique model.jpeg" alt="buddi unique model">
            </div>
        </div>
        </div>

        <div class="container">
            <div class="section-title">
                <h2>Get Started Now</h2>
                </div>
            <div class="row ">
                <div class="col-md-6 learner" >
                <div class="single-instructor-box" >
                    <div class="image">
                        <img src="../../../../../assets/img/Student-Login-image.png" alt="Student-Login-image" style="cursor: pointer;" title="Learner login" (click)="studentclick()" 
                            onerror="this.src='../../../../../assets/img/for-Student.png';" alt="image" />
            
                            <div class="content">
                                <!-- <h3><a routerLink="/student/login" style="cursor: pointer;" title="click"> For Learner </a></h3> -->
                               
                               <h3><a (click)="studentclick()"  href="javascript:void(0)" style="cursor: pointer;" title="Learner login">I'm a Learner</a></h3>
                               
                            </div>
                    </div>
                </div>
                </div>
                <div class="col-md-6 learner">
                    <div class="single-instructor-box">
                        <div class="image">
                            <img src="../../../../../assets/img/Teacher-Login-image.png" alt="Teacher-Login-image" style="cursor: pointer;" title="Tutor login"  (click)="teacherclick()" 
                                onerror="this.src='../../../../../assets/img/Teacher-Login-image.png';" alt="image" />
                                <div class="content">
                                    <!-- <h3><a routerLink="/teacher/login" style="cursor: pointer;" title="click">For Tutor</a></h3> -->
                                    <h3><a (click)="teacherclick()" href="javascript:void(0)" style="cursor: pointer;" title="Tutor login">I'm a Tutor</a></h3>
                                 
                                </div>
                          
                        </div>
                
                       
                    </div>
                </div>
            </div>
            
        
                
            
            
          
            <!-- <app-student-instructor></app-student-instructor> -->
        </div>
        

    <div id="particles-js-circle-bubble"></div>
</section>



<app-student-offer></app-student-offer>

<app-student-how-it-works></app-student-how-it-works>



<section class="instructor-area pt-100 pb-70">
    <div class="container ">
        <div class="section-title">
            <!-- <span class="sub-title">Make Connections</span> -->
            <h2>Our buddies </h2>
            <!--  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea.</p>-->
        </div>

        <app-student-instructor></app-student-instructor>
    </div>
    
    <div class="container">
        <h2 style="margin-top: 10%;">buddi Award</h2>
        <div class="row">
 <div class="col-md-4" id="certificate">
<img src="../../../../../assets/img/buddi_certificate.jpeg" alt="buddi_certificate"/>
 </div>
 <div class="col-md-8" id="view">
     <div class="pdf">
         <h1>Online Learning Providers in India<br>Certificate of Publication to "buddi.life"</h1>
 </div>
         <div class="pdf1">
          <a href="../../../../../assets/img/buddi_certificate.pdf " target="blank">   <button class="btn">Know More</button></a>
         </div>

 </div>

    </div>
    </div>

    
    <div id="particles-js-circle-bubble-3"></div>
</section>
<section class="videiAdSection">
    <div class="container">
    <div class="containerVideo"> 
        <iframe class="responsive-iframe"
        src="https://www.youtube.com/embed/jB3WUNvXw7U" 
        title="YouTube video player" frameborder="0"
         allow="accelerometer; autoplay; clipboard-write; 
         encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    </iframe>
</div>
      </div>
</section>
<section class="feedback-area ptb-50">
    <div class="container">
    <div class="section-title">

        <h2>How Parents feel about us  </h2>

    </div>
    <app-k12-feedback></app-k12-feedback>
    </div>
</section>

<section class="testimonials-area pt-100">
    <div class="container">
        <div class="section-title">
            <h2>Learners Love us</h2>
        </div>
        <app-student-feedback></app-student-feedback>
    </div>
</section>

<!-- <app-become-instructor-partner></app-become-instructor-partner> -->

<!-- <app-student-how-it-works></app-student-how-it-works> -->

<!-- <app-video-listing></app-video-listing> -->
<section  class="has1"><div class="container">
    <div class="section-title">
        <h2 style="margin-top: 10%;">Join Our Community</h2>
        </div>
<div class="row non">
    <div class="col-md-6 tag1" >
    
        
        <h5>Our reward program incentivise Tutors and Learners for 
            their contribution and participation.
             We are unique in that. 
          Join us for a wealthy future. </h5>
       
            
            <div class="check">
        <a href="https://www.facebook.com/buddi.life" target="_blank"><button class="btn "> <i class="bi bi-facebook"></i> Join Facebook</button></a>
          <a href="https://t.me/joinchat/1pIJHebFwxA5Zjc1" target="_blank"><button class="btn"><i class="bi bi-telegram"></i> Join Telegram</button></a>
            </div>
    </div>
    <div class="col-md-6 pic"><img src="../../../../../assets/img/Facebook community.jpg" alt="Facebook community"/>

    </div>
</div>
</div>
</section>
<section >
<div class="container">
<!-- <section class="partner-area bg-color ptb-100"> -->
 
        <div class="section-title">
            <h2>Our Company & Partners</h2>
        </div>
    
      <app-partner-style-one></app-partner-style-one>
    
<!-- </section> -->
</div>
</section>




