import { Component, OnInit,Inject, ElementRef, ViewChild, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Options } from 'ng5-slider';
import { ApiService } from '../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {NgSelectModule, NgOption} from '@ng-select/ng-select';
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from 'moment';

@Component({
  selector: 'app-create-webinar',
  templateUrl: './create-webinar.component.html',
  styleUrls: ['./create-webinar.component.scss']
})
export class CreateWebinarComponent implements OnInit {

  @ViewChild('mapRef', {static: true }) mapElement: ElementRef;
  meetingForm: FormGroup;
  submitted = false;
  selectedValue = "";
  formData;
  public meetCategory : any = [];
  public coursecategorylist : any = [];
  public courseboardlist : any = [];
  public coursesubjectlist : any = [];
  public languageapi : any = [];
  public gradeapi : any = [];
  public city : any = [];
  // public meetingCategory = "Select category";
  // public meetingType = "Group";
  public meetingType = "Select Classes";
  public language = "Select Language";
  public occurance ="Select Re-occurance";
  public meetduration = "Select Duration of Session";
  public meetpricetype = "Free";
  public meetamount = 0;
  public isamount = false;
  public isClassRoom = false;
  public min: any = new Date();
  selectedFileNmae: string = 'Choose Thumbnail Image';
  public alltag:any = [];
  public userId:any = '';
  public groupname:any ='';
  public selected:any = [];
  public selected1: any = [];
  public editData:any=[];
  public paramData:any = [];
  public isEnterprise = '';
  public selecttag:any = [];
   public isEdit:Boolean = false;
  constructor(private SpinnerService:NgxSpinnerService,private activatedRoute:ActivatedRoute,public location: Location, public toastr: ToastrManager, private formBuilder: FormBuilder, private rest: ApiService, private router: Router ) { }
  ngOnInit() {
    window.scrollTo(0, 0);
    // this.meetingCategory = "Select category";
    var profileName = localStorage.getItem('profileName');
    this.userId = localStorage.getItem('userId');
    const webToken = localStorage.getItem('webtoken');
    this.rest.userName_DisplayTeacher(webToken).subscribe((result) => {
      console.log(result);
      this.groupname =  result.data !=undefined && result.data.groupName != 'buddi' ? result.data.groupName : ''
      localStorage.setItem('groupname', result.data !=undefined && result.data.groupName != 'buddi' ? result.data.groupName : '');
    });
    profileName = profileName.replace(/ /gi, "+");
    var meetingId = profileName+"-"+Math.round(Math.random() * 1000000000)
    // this.meetingdetails();
    this.getcoursecategory();
    this.getboardfield();
    this.getsubjectfield();
    this.getlanguage();
    this.getgrade();
    this.cities();
    this.tagfield();
    this.meetingForm = this.formBuilder.group({
      id:null,
      meetingName: ['', Validators.required],
      meetingId: [meetingId, Validators.required],
      ScheduleDate: ['', Validators.required],
      // meetingCategory: [''],
      // meetingType: ['', Validators.required],
      tag: [''],
      fullName: ['', Validators.required],
      meetduration: [''],
      grouplevel:[''],
      meetdescription: [''],
      occurance: [''],
      moderatorpassword: [''],
      attendeespassword: [''],
      city: [''],
      meetpricetype: [''],
      meetamount: [''],
      upload_name: [null],
      fileupload: [''],

      // new Filed Added
      courseGrade: ['', Validators.required],
      courseSubject: ['', Validators.required],
      courseLanguage:['', Validators.required],
      courseCategory: ['', Validators.required],
      courseBoard: ['', Validators.required]
      // name:null
    });


    var urlArray = window.location.href.split('/');
      this.rest.getMeetingbyID(urlArray[urlArray.length-1]).subscribe((result) => {
      if(result.data.length!=0){
        this.editData =result.data[0];
         this.isEdit = this.editData._id ?true:false;
        this.meetingForm.patchValue({ courseCategory:  this.editData.categoryID._id});
        this.meetingForm.patchValue({ courseGrade:  this.editData.gradeID._id});
        this.meetingForm.patchValue({ courseSubject:  this.editData.subjectID._id});
        this.meetingForm.patchValue({ courseLanguage:  this.editData.languageID._id});
        this.meetingForm.patchValue({ courseBoard:  this.editData.boardID._id});

        this.meetingForm.patchValue({ meetingName:  this.editData.name});
        this.meetingForm.patchValue({ meetingId:  this.editData.meetingID});
        this.meetingForm.patchValue({ ScheduleDate:  this.editData.ScheduleDate});
        this.meetingForm.patchValue({ meetingType:  this.editData.meetingType});
        //this.meetingForm.patchValue({ tag: this.editData.tag == "" ? '' : JSON.stringify(this.editData.tag)});
        this.selecttag  = this.editData.tag == "" ? '' : JSON.parse(this.editData.tag);
        this.meetingForm.patchValue({ fullName:  this.editData.fullName});
        this.meetingForm.patchValue({ language:  this.editData.Languages});
        this.groupname  = this.editData[0].groupname
        this.meetingForm.patchValue({id:this.editData._id})
        this.meetingForm.patchValue({ meetduration:  this.editData.Duration});
        this.meetingForm.patchValue({ meetdescription:  this.editData.meetingdescription});
        this.meetingForm.patchValue({ occurance:  this.editData.occurance});
        this.meetingForm.patchValue({ city:  this.editData.city});
        this.selectedFileNmae = this.editData.originalname == '' ? '' : this.editData.originalname;
        this.meetingForm.patchValue({ meetpricetype:  this.editData.meetingPriceType});
        this.isamount = this.editData.meetingPriceType =="Premium" ? true : false;
        this.meetingForm.patchValue({ meetamount:  this.editData.PayableAmount});
        this.meetingForm.patchValue({ id:  this.editData._id});
        this.meetingForm.patchValue({ fileupload:  this.editData.fileupload});
        this.meetingForm.patchValue({ name:  this.editData.name});
        var image = this.editData.image == ''? 'default image':this.editData.image
        this.selectedFileNmae = this.editData.image.length >40 ? this.editData.image.slice(0,40) : image;
        }
        }, (err) => {
        console.log(err);
      });



  }
  tagfield(){
    this.rest.tagfield().subscribe((result) => {
      this.alltag = result;
      console.log(this.alltag);
    }, (err) => {
    console.log(err);
    });
  }
  backto(){
    this.location.back();
  }
  fileChange(event) {
    let fileList: FileList = event.target.files;
    this.meetingForm.patchValue({ upload_name: fileList[0] });
    this.selectedFileNmae = fileList[0].name;
    this.meetingForm.patchValue({ fileupload: this.selectedFileNmae });
    console.log(this.meetingForm.value);
  }
  goBack(){
    this.location.back();
  }


  getcoursecategory() {
    this.rest.getCourseCategoryHeader().subscribe((result) => {
      this.coursecategorylist = result.data;
      if(Object.keys(this.paramData).length == 0 && !this.isEdit){
        this.meetingForm.patchValue({ courseCategory: this.coursecategorylist[0]._id })
      }
      console.log(this.coursecategorylist);
    }, (err) => {
    console.log(err);
    });
  }

  getboardfield(){
    this.rest.boardfield().subscribe((result) => {
      this.courseboardlist = result.data;
      if(Object.keys(this.paramData).length == 0 && !this.isEdit){
        this.meetingForm.patchValue({ courseBoard: this.courseboardlist[0]._id })
      }
      console.log(this.courseboardlist);
    }, (err) => {
    console.log(err);
    });
  }

  getsubjectfield(){
    this.rest.subjectfield().subscribe((result) => {
      this.coursesubjectlist = result.data;
      if(Object.keys(this.paramData).length == 0 && !this.isEdit) {
        this.meetingForm.patchValue({ courseSubject: this.coursesubjectlist[0]._id })
      }
      console.log(this.coursesubjectlist);
    }, (err) => {
    console.log(err);
    });
  }

  getlanguage(){
    this.rest.languagefield().subscribe((result) => {
      this.languageapi = result.data;
      if(Object.keys(this.paramData).length == 0 && !this.isEdit){
        this.meetingForm.patchValue({ courseLanguage: this.languageapi[0]._id })
      }
      console.log(this.languageapi);
    }, (err) => {
    console.log(err);
    });
  }

  getgrade(){
    this.rest.grade().subscribe((result) => {
      this.gradeapi = result.data;
      if(Object.keys(this.paramData).length == 0 && !this.isEdit){
        this.meetingForm.patchValue({ courseGrade: this.gradeapi[0]._id })
      }
    }, (err) => {
    console.log(err);
    });
  }

  cities(){
    this.rest.cities().subscribe((result) => {
      // this.city = result;
    }, (err) => {
    console.log(err);
    });
  }

  selectAmount(event: any) {
    var selected = event.target.options[event.target.selectedIndex].text;
    if(selected == 'Premium'){
      this.isamount = true;
    }else{
      this.isamount = false;
    }
  }


  // selectClassRoom(event: any) {
  //   var selected1 = event.target.options[event.target.selectedIndex].text;
  //   if(selected1 == 'Classroom'){
  //     this.isClassRoom = true;
  //   }else{
  //     this.isClassRoom = false;
  //   }
  // }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  selectChange (event: any) {
   this.selectedValue = event.target.options[event.target.selectedIndex].text;
  }
  get f() { return this.meetingForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.meetingForm.invalid) {
      return;
    }
    const data = this.meetingForm.value;
    console.log(data);
    if(data.meetpricetype == "Premium"){
      if(data.meetamount == 0){
        this.toastr.errorToastr('Amount Should be greater than 0');
        return false;
      }else if(data.meetamount == ""){
        this.toastr.errorToastr('Enter Meeting amount');
        return false;
      }
    }else{
      data.meetamount = 0;
    }

    if(data.meetingType =="Select Session Type"){
      this.toastr.errorToastr('Please choose Type');
      return false;
    }
    if(data.language == 'Select Language'){
      data.language = '';
    }
    if(data.meetduration == 'Select Duration of Session'){

      data.meetduration = '';
    }
    if(data.meetdescription == undefined){
      data.meetdescription = '';
    }
    if(data.occurance == "Select Re-occurance"){
      data.occurance = '';
    }
    if(data.city == undefined || data.city.length == 0){
      data.city = '';
    }
    // var meetingCategoryName =this.selectedValue;

    var groupname = localStorage.getItem('groupname')
    if(data.upload_name!=null){

      this.formData = new FormData();
      this.formData.append("upload_name", data.upload_name, data.upload_name.name);
      this.formData.append("meetingName", data.meetingName);
      // New field Start
      this.formData.append("categoryID", data.courseCategory);
      this.formData.append("languageID", data.courseLanguage);
      this.formData.append("subjectID", data.courseSubject);
      this.formData.append("boardID", data.courseBoard);
      this.formData.append("gradeID", data.courseGrade);
      // New Field ENd

      this.formData.append("city", data.city);
      this.formData.append("occurance", data.occurance);
      this.formData.append("Duration", data.meetduration);
      this.formData.append("meetingdescription", data.meetdescription);
      this.formData.append("meetingId", data.meetingId);
      this.formData.append("ScheduleDate", data.ScheduleDate[0]);
      this.formData.append("fullName", data.fullName);
      this.formData.append("meetingType", 'Webinar');
      this.formData.append("meetingPriceType", data.meetpricetype);
      this.formData.append("PayableAmount", data.meetamount);
      this.formData.append("name", data.meetingName);
      this.formData.append("tag",data.tag =="" || data.tag.length ==0  ? '' : JSON.stringify(data.tag));
      this.formData.append("originalname",this.selectedFileNmae);
      this.formData.append("groupname",'Webinar');

      // this.formData.append("groupname", data.meetingType == 'Group' ? groupname : '');
      console.log(this.formData,'dffdfdfd');
    }
    
    
    else{
      this.formData = {

        categoryID:data.courseCategory,
        languageID:data.courseLanguage,
        subjectID:data.courseSubject,
        boardID:data.courseBoard,
        gradeID:data.courseGrade,
        meetingName     :  data.meetingName,
        originalname:this.selectedFileNmae,
        name : data.meetingName,
        meetingId     : data.meetingId,
        ScheduleDate : data.ScheduleDate[0],
        fullName        : data.fullName,
        // meetingCategory : data.meetingCategory,
        meetingPriceType:data.meetpricetype,
        PayableAmount:data.meetamount,
        meetingType     : 'Webinar',
        tag     :  data.tag =="" || data.tag.length ==0  ? '' : JSON.stringify(data.tag),
        // groupname       : data.meetingType == 'Group' ? groupname : '',
        groupname : 'Webinar',
        // meetingCategoryName     : meetingCategoryName,
        Languages : data.language,
        city  : data.city,
        occurance : data.occurance,
        Duration  : data.meetduration,
        meetingdescription   : data.meetdescription
      };
    }
    const webToken = localStorage.getItem('webtoken');
     this.SpinnerService.show()

      if(!this.editData._id){
        
      this.rest.createRoundtable(this.formData, webToken).subscribe((result) => {
        console.log('result', result);
        this.SpinnerService.hide()
       if(result.status) {
         this.toastr.successToastr('Hooray! Your Class has been created');
        this.addToCalendar(result.data[0], webToken);

        //  this.backto()
        } else {
       }
      }, (err) => {
      console.log(err);
      });
    }else{
      this.rest.editMeeting(this.formData,webToken).subscribe((response) => {
        this.SpinnerService.hide();
        if(response.status){
          this.toastr.successToastr('Hooray....! Your Updated has been created');
          this.backto()
        }else {
          this.toastr.errorToastr(response.message);
        }
      });
    }
  }
 addToCalendar(data,webToken){
    var req={
      myLifeListDatepick:moment(data.ScheduleDate).format('YYYY-MM-DD'),
      myLifeListEventType: "Class",
      myLifeListTimepick:moment(data.ScheduleDate).format('HH:mm:ss'),
      setReminder: 0,
      todoEventType: "Class",
      meetingType:data.meetingType,
      todoSchedule: data.ScheduleDate,
      todoSetReminder: "",
      todosetReminderValue: "",
      sessionName:data.name,
      meetingId : data._id,
      myLifeListTitle:data.meetingType,
      sessionId:data.meetingID,
      profileType:'teacher'
    };
    this.SpinnerService.show()
    this.rest.add_myLife_NewData(req,webToken).subscribe((result) => {
      this.SpinnerService.hide()
      if(result.status){
        this.toastr.successToastr("Round table Class Added to your Calender");
        if(!this.editData._id){
          this.router.navigate(['/webinar-listing']);
        }else{
          this.location.back();
        }
      }else{
        this.toastr.errorToastr(result.message);
      }

    });
  }

}
