import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nft-asset-transaction',
  templateUrl: './nft-asset-transaction.component.html',
  styleUrls: ['./nft-asset-transaction.component.scss']
})
export class NftAssetTransactionComponent implements OnInit {
  public isStudent: boolean = true;
  public isTeacher: boolean = true;
  constructor() { }

  ngOnInit(): void {
    this.isTeacher =
    localStorage.getItem("profileType").toLowerCase() == "teacher";
this.isStudent =
    localStorage.getItem("profileType").toLowerCase() == "student";
  }

}
