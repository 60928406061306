<div>
	<app-header-style-one-teacher></app-header-style-one-teacher>
</div>

<div class="containerBg">
	<div class="container">
        <h4 labelStyle style="padding-top: 2%"> Upload Class Course</h4>
        <hr>
            <form [formGroup]="createForm">
                    <div class="row" style="padding-top: 3%">
                            <div class="col-md-6" >
                                    <div class="form-group">
                                        <label labelStyle>Choose File</label>
                                        <div class="input-group">
                                            <div class="custom-file">
                                                <input type="hidden" formControlName="fileupload"  name="" value="">
                                                <input type="file" class="custom-file-input" accept=".xlsx" (change)="fileChange($event)" id="inputGroupFile01"
                                                    aria-describedby="inputGroupFileAddon01" [ngClass]="{ 'is-invalid': submitted && f.fileupload.errors}">
                                                <label class="custom-file-label" for="inputGroupFile01">{{selectedFileName}}</label>
                                            </div>
                                            <div>									
                                            </div>								
                                        </div>
                                        <div *ngIf="submitted && f.fileupload.errors" class="invalid-error-span">
                                            <div *ngIf="f.fileupload.errors.required" style="font-size: 80%;color: #dc3545;">Document is required</div>
                                        </div>
                                        <!-- <p><small>Holding down the Ctrl key, click each of the each video(s) files for multiple select upload</small></p> -->
                                        </div>
                                </div>
                                </div>

                                <div class="row" style="padding-top: 5%;padding-bottom: 5%">
                                        <div class="col-md-12 description">
                                            <div class="text-center mt-3">
                                               <button style="width:30%" type="button" (click)="onSubmit('create')"  class="default-btn">
                                                   <i class='bx bx-save icon-arrow before'></i>
                                                    <span class="label"> Add Course</span> 
                                                   <i class="bx bx-save icon-arrow after"></i>
                                                </button>
                                            </div>
                                         </div>
                                    </div>
                                </form>
                                </div>
                                </div>
