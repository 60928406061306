import { Component, OnInit,Inject, ElementRef, ViewChild, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Options } from 'ng5-slider';
import { ApiService } from '../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {NgSelectModule, NgOption} from '@ng-select/ng-select';
import { environment } from '../../../../environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-courses-list',
  templateUrl: './courses-list.component.html',
  styleUrls: ['./courses-list.component.scss']
})
export class CoursesListComponent implements OnInit {
  public courseData:any=[];
  public myId:any=''
  public categoryname:any=''
  startDate: string; 
  public isselected = false;

  constructor(private activatedRoute:ActivatedRoute,public location: Location, public toastr: ToastrManager, private formBuilder: FormBuilder, private rest: ApiService, private router: Router) { }

  ngOnInit(): void {
    //this.categoryname = this.activatedRoute.snapshot.params['id'];
    var userId = localStorage.getItem('userId');
   console.log(userId);
    const webToken = localStorage.getItem('webtoken');
    this.myId = localStorage.getItem('userId');    
    const groupname = localStorage.getItem('groupname');
    var formData = {};
    formData["groupName"]=groupname;
    formData["categoryName"]="";
    const usetType = localStorage.getItem('userType');
    if(usetType.toLowerCase()=="teacher") {
      this.isselected=true;
    }
    else {
      this.isselected=false;
    }
    
    this.rest.getAllCoursesbyGroup(webToken,formData).subscribe((result) => {    
      this.courseData = result.data;
      var curdata = result.data.startDate;
     const currentFormatDate = moment(curdata).subtract(330, "minutes").toDate();
     this.startDate = moment(currentFormatDate).format('DD-MM-YYYY');
    });
  }
  coursedetails(data) {
    console.log(data);
    this.router.navigateByUrl('courses/'+data.categoryID.categoryName+"/"+data._id);
  }

}
