<header class="header-area p-relative">

    <div class="top-header top-header-style-four">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-12 col-md-12">   

                    <div class="row">
                    <div class="col-lg-2">

                    </div>
    
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul class="top-header-login-register">
                        
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="navbar-area navbar-style-three ">
        <div class="ednuv-responsive-nav">
            <div class="container">
                <div class="ednuv-responsive-menu">
                    <div class="logo">
                        <a routerLink="/Home">
                            <img src="assets/img/black-logo.png" class="logoImg" alt="logo">
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="ednuv-nav">
            <div class="container">
                <!-- <p class="linkPosition" tabindex="0"><a _ngcontent-aow-c51="" class="nav-link" routerLink="/institutionspage" ng-reflect-router-link="/institutionspage">Institutions</a></p> -->
                <nav class="navbar navbar-expand-md navbar-light">
                    <a routerLink="/Home">
                        <img src="assets/img/black-logo.png" class="logo" alt="logo">
                    </a>
                   <div class="collapse navbar-collapse mean-menu">
                       
                        <ul class="navbar-nav col-md-12 customNavbar">
                           
                            <!--   <div class="dropdown " >
                                <button class="btn dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                 Explore 
                                </button>
                                <ul class="dropdown-menu multi-level listWrapper" role="menu" aria-labelledby="dropdownMenu">
                               
                                    <li class="dropdown-submenu" *ngFor="let categorymenu of coursecategory">
                                        <a  class="dropdown-item" tabindex="-1" >{{categorymenu.categoryName}}</a>

                                        <ul class="dropdown-menu" *ngIf="categorymenu.moduleList.length > 0">
                                          <li class="dropdown-item" *ngFor="let submenu of categorymenu.moduleList"><a tabindex="-1"  (click)="selectedmenu(submenu.categoryID,submenu.moduleID)">{{submenu.moduleName}}</a></li>
                                         
                                        </ul>
                                      </li>
                                      <a  routerLink="/showMenu"class="dropdown-item" tabindex="-1" ><span class="hover" style="color:#00adee">View All</span></a>

                                     
                                    <li class="dropdown-divider"></li>
                                 
                                  </ul>  -->
                                 
                                  <!-- <ul class=" dropdown-menu ul-l" style="width: 400px">
                                        <li class="li-l parent" *ngFor="let categorymenu of coursecategory">
                                                <a   >{{categorymenu.categoryName}}</a>
                                                <div class="wrapper" style="padding-left: 20%">
                                                        <ul class="ul-l " *ngIf="categorymenu.moduleList.length > 0">
                                                          <li class="li-l" *ngFor="let submenu of categorymenu.moduleList">
                                                                <a (click)="selectedmenu(submenu.categoryID,submenu.moduleID)">{{submenu.moduleName}}</a>
                                                          </li>
                                                        </ul>
                                                    </div>
                                        </li>
                                    </ul> -->
                                   
                            <!-- </div>  -->
                                
                            
                            <!-- <li class="col-lg-2 search">
                                <div class="input-icons">
                                    <i class="fa fa-map-marker icon" aria-hidden="true"></i>
                                
                                <input type="text" class="input-field" style="border:none !important;background-color: rgb(246, 246, 248);width: 100%; padding-right: 10px; padding-bottom: 10px; padding-top: 10px;" placeholder="Chennai" />
                            </div>
                            </li> -->
                            <li class=" nav-item col-xs-1"  >
                                <!-- <a   href="javascript:void(0)" class="nav-link" [routerLink]="'/'+obj.categoryName+'/'+obj._id">{{obj.categoryName}}</a> -->
                               <a routerLink="/courseslists" class="nav-link">Courses</a>
                               <!-- <label><i class="bx bx-search"></i></label> -->

                            </li>
                            <li class=" nav-item col-xs-1 " >
                               <a routerLink="/Pricing-plans" class="nav-link ">Pricing </a>
                            </li>
                            <li class=" nav-item col-xs-1 " >
                                <a routerLink="/metaverse" class="nav-link">
                                    Metaverse
                                 </a>
                                
                                  
                               </li>
                        
                     

                            <li class=" nav-item col-xs-1"  >
                                <!-- <a   href="javascript:void(0)" class="nav-link" [routerLink]="'/'+obj.categoryName+'/'+obj._id">{{obj.categoryName}}</a> -->
                                <a routerLink="/getstarted" class="nav-link">
                                    Live Classes
                                  </a>
                               <!-- <label><i class="bx bx-search"></i></label> -->

                            </li>
                            <li class=" nav-item col-xs-1"  >
                                <!-- <a   href="javascript:void(0)" class="nav-link" [routerLink]="'/'+obj.categoryName+'/'+obj._id">{{obj.categoryName}}</a> -->
                                <a routerLink="/learningassets" class="nav-link">
                                    Learning Assets
                                 </a>
                               <!-- <label><i class="bx bx-search"></i></label> -->

                            </li>
                            <li class=" nav-item col-xs-2" >
                                
                                <a routerLink="/nft" class="nav-link"  >
                                    NFT
                                      </a>
                            </li>
                            <li class=" nav-item col-xs-2" *ngIf="isLogin=='true'" routerLink="/sidenav">
                                <div style="display: flex;align-items: center;"> <a class="nav-link">Dashboard</a>
                                    <i class="bi bi-person-circle" style="cursor: pointer;"></i></div>
                            </li>
                            
                            <li class=" nav-item col-xs-2"  (click)="logout()"  *ngIf="isLogin=='true'">
                                <div style="display: flex;align-items: center;">
                                <a class="nav-link">Logout</a>
                                <i class="fas fa-sign-out-alt" style="cursor: pointer;"></i>
                            </div>
                            </li>
                            
<!-- <div>
    <i class="bi bi-person-circle"></i>
    <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button
            class="accordion-button"
            type="button"
            data-mdb-toggle="collapse"
            data-mdb-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            Accordion Item #1
          </button>
        </h2>
        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-mdb-parent="#accordionExample">
          <div class="accordion-body">
            <strong>This is the first item's accordion body.</strong> It is hidden by default,
            until the collapse plugin adds the appropriate classes that we use to style each
            element. These classes control the overall appearance, as well as the showing and
            hiding via CSS transitions. You can modify any of this with custom CSS or
            overriding our default variables. It's also worth noting that just about any HTML
            can go within the <strong>.accordion-body</strong>, though the transition does
            limit overflow.
          </div>
        </div>
      </div>
</div> -->

<!-- <li class=" nav-item col-xs-1" *ngIf="isLogin=='true'">
    <div class="dropdown " >
       <button class="btn dropdown-toggle" style="padding-left:0; font-weight:500;font-size:15px;" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="bi bi-person-circle"></i>
       </button>
       <ul class="dropdown-menu listWrapper" role="menu" aria-labelledby="dropdownMenu">
      
           <li class="dropdown-submenu" style ="cursor: pointer; padding-left:20" >
            <a class="nav-link" routerLink="/sidenav" >Dashboard</a>
            </li> -->
            <!-- <li class="dropdown-submenu" style ="cursor: pointer; padding-left:20;">
                <a class="nav-link"  routerLink="/wallet" id="wallet">Payments</a>
            </li> -->
            <!-- <li class="dropdown-submenu" style ="cursor: pointer; padding-left:20;">
                <a  style="font-size:14px;" (click)="logout()" ><i class="fas fa-sign-out-alt"></i> Logout</a>
            </li>
        
       </ul>
   </div>
   </li> -->
   <!-- <div class="accordion accordion-flush" id="accordionFlushExample">
    <div class="accordion-item">
      <h2 class="accordion-header" id="flush-headingOne">
        <ul class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
            <i class="bi bi-person-circle"></i>
        </ul>
      </h2>
      <div id="flush-collapseOne" >
        <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the first item's accordion body.</div>
      </div>
    </div>
   </div> -->

                            <!-- <li class=" nav-item col-xs-2" *ngIf="isLogin=='true'">
                                <div class="dropdown " >
                                   <button class="btn dropdown-toggle"  style="padding-left:0; font-weight:500;font-size:15px;" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="bi bi-person-circle"></i>
                                   </button>
                                   <ul class="dropdown-menu listWrapper" role="menu" aria-labelledby="dropdownMenu">
                                  
                                    <li class=" nav-item col-lg-1" *ngIf="isLogin=='true'" routerLink="/sidenav">
                                        <a class="nav-link" >Dashboard</a>
                                     </li>
                                     <li class=" nav-item col-lg-1"  *ngIf="isLogin=='true'" routerLink="/sidenav">
                                        <a class="nav-link">Upcoming Activities</a>
                                    </li>
                                    <li class=" nav-item col-lg-1"  *ngIf="isLogin=='true'"routerLink="/sidenav">
                                        <a class="nav-link"  >Completed Activities</a>
                                    </li>
                                    <li class=" nav-item col-lg-1"  *ngIf="isLogin=='true'" routerLink="/wallet">
                                        <a class="nav-link" id="wallet">Payments</a>
                                    </li>
                                   </ul>
                               </div>
                               </li> -->
                               <li class="nav-item col-xs-1" *ngIf="isLogin=='false' || isLogin==null">
                                <a routerLink="/teacher/login" class="nav-link">Login</a>
                            </li>
                            
                            <!-- <li class=" nav-item col-lg-2" routerLink="/institutionspage">
                             <a   href="javascript:void(0)" class="nav-link" [routerLink]="'/'+obj.categoryName+'/'+obj._id">{{obj.categoryName}}</a>
                               <a class="nav-link" >Institutions</a>
                            </li> -->
                       
                            <!-- <li class=" nav-item col-lg-2">
                                <a   href="javascript:void(0)" class="nav-link" [routerLink]="'/'+obj.categoryName+'/'+obj._id">{{obj.categoryName}}</a> 
                               <a class="nav-link" >Enterprise</a>
                            </li> -->
                            <!-- <li class=" nav-item col-lg-3">
                               
                            </li> -->
                    
                            <!-- <li class=" nav-item col-lg-1" *ngIf="isLogin=='true'" routerLink="/sidenav">
                                <a class="nav-link" >Dashboard</a>
                             </li>
                            <li class=" nav-item col-lg-1"  *ngIf="isLogin=='true'" routerLink="/wallet">
                                <a class="nav-link" id="wallet">Wallet</a>
                            </li> -->
                            <!-- <li class=" nav-item col-lg-2">
                                <i class="fa fa-cog" aria-hidden="true"></i>    
                            </li> -->
                           
                            <!-- <div class="dropdown" *ngIf="isLogin=='true'">
                                <i class="fa fa-cog dropbtn" aria-hidden="true"></i> -->
                                <!-- <button class="dropbtn">Dropdown</button> -->
                                <!-- <div class="dropdown-content">
                                  <a routerLink="/sidenav"> My Dashboard</a>
                                  <a class="fa fa-sign-out"(click)="logout()"> </a> -->
                                  <!-- <a href="#">Link 3</a> -->
                                <!-- </div>
                              </div> -->
                            <!-- <li class="nav-item col-lg-2" *ngIf="isLogin=='true'">
                                
                              
                                    <a class="nav-link " routerLink="/student/mydashboard">
                                        My Dashboard
                                    </a>
                              
                            </li>
                            <li class="nav-item col-lg-1" *ngIf="isLogin=='true'" >
                               
                                    <a class="nav-link" [routerLink]="" (click)="logout()" >Logout</a>
                             
                            </li> -->
                            <!-- <li class="nav-item dropdown iconLink" *ngIf="isLogin=='true'">
                                
                                <a class="nav-link dropdown-toggle" href="javascript:void(0)" id="notificationDropLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="bx bxs-bell bx-tada-hover"></i><sup>{{notificationLength}}</sup></a>
                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="notificationDropLink">
                                   <ul class="notificationList">
                                      <li *ngFor="let slide of notification" [ngStyle]="{'background':!slide.isread ? 'lightgrey' : 'white' }"> -->

                                       <!--  <div class="row" (click)="requestAccept(slide)">
                                            <span class="col-lg-3">
                                                  <img [src]="slide.userPhoto ? slide.userPhoto : 'assets/img/profile300.png'" class="rounded-circle mr-2 profileImage" alt="image">
                                            </span>
                                              <span class="col-lg-7">
                                                {{slide.message}}
                                            </span>
                                        </div> -->
                                        <!--  <h4>
                                             <small> {{slide.ScheduleDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</small>
                                        </h4> -->
                                         <!-- <p (click)="requestAccept(slide)">
                                            <img [src]="slide.userPhoto ? slide.userPhoto : 'assets/img/profile300.png'" class="rounded-circle mr-2 profileImage" alt="image">
                                             
                                        {{slide.message}}</p>
                                      </li>
                                                                           
                                   </ul>
                                </div>
                             </li> -->

                           
                            <!-- <li class="nav-item col-lg-1" *ngIf="isLogin=='false' || isLogin==null">
                                <a routerLink="/student/register" class="nav-link">Register</a>
                            </li> -->
                            
                           <!--  <li class="nav-item col-lg-3" *ngIf="isLogin=='false' || isLogin==null">
                                <span>
                                    <a routerLink="/teacher/home" class="nav-link">Are you a Teacher?</a>
                                </span>
                            </li> -->
                        </ul>
                                             
                    </div>
                </nav>
            </div>
        </div>
    </div>
    
</header>

<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            
            <div class="search-overlay-close">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>

            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class='bx bx-search-alt'></i></button>
                </form>
            </div>
        </div>
    </div>
</div>