import { Component, OnInit,Inject, ElementRef, ViewChild, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Options } from 'ng5-slider';
import { ApiService } from '../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {NgSelectModule, NgOption} from '@ng-select/ng-select';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from '../../../../environments/environment';
import { MediaService } from '../../../shared/media.service';

@Component({
  selector: 'app-video-listing',
  templateUrl: './video-listing.component.html',
  styleUrls: ['./video-listing.component.scss']
})

export class VideoListingComponent implements OnInit {
  
  public videoList:any=[];
  public smartList:any=[];
  constructor(private api: MediaService,private SpinnerService:NgxSpinnerService,private activatedRoute:ActivatedRoute,public location: Location, public toastr: ToastrManager, private formBuilder: FormBuilder, private rest: ApiService, private router: Router ) { }

  ngOnInit(): void {
//     this.getVideoList();
// this.getSmartMaterialList();

   }
  getVideoList(){
    this.SpinnerService.show();
    this.rest.NewVideoListing({}, {}).subscribe((result) => {

      console.log('result of explore', result)
      this.SpinnerService.hide();
      if(result.status && result.data){        
        this.videoList = result.data.splice(0,4);
        console.log('list video',this.videoList)

      }else{
        this.videoList = [];
      }
    }, (err) => {
    console.log(err);
    });
  }
  getSmartMaterialList(){
    this.SpinnerService.show();
    this.rest.NewSmartMaterialListing({}, {}).subscribe((result) => {

      console.log('result of material', result)
      this.SpinnerService.hide();
      if(result.status && result.data.Solutions){        
        this.smartList = result.data.Solutions;
        console.log('list material',this.smartList)

      }else{
        this.smartList = [];
      }
    }, (err) => {
    console.log(err);
    });
  }
  getTestList(){
    this.SpinnerService.show();
    this.rest.NewSmartMaterialListing({}, {}).subscribe((result) => {

      console.log('result of material', result)
      this.SpinnerService.hide();
      if(result.status && result.data.Solutions){        
        this.smartList = result.data.Solutions;
        console.log('list material',this.smartList)

      }else{
        this.smartList = [];
      }
    }, (err) => {
    console.log(err);
    });
  }
 redirect(){
    this.router.navigateByUrl('/all-video-listing');
  } 
  redirects(){
    this.router.navigateByUrl('/all-study-listing');
  }
  videodetails(data){
     this.router.navigateByUrl('/videoDetails/'+data._id);
  }
  studydetails(data){
    this.router.navigateByUrl('/studyMaterialDetails/'+data._id);
 }
}