
import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../../shared/api.service";
import { Router, ActivatedRoute } from "@angular/router";
import { DatePipe } from '@angular/common';
import { environment } from "../../../../environments/environment";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrManager } from "ng6-toastr-notifications";
import { CommonTextColorDirective } from '../../custom-directive/common-text-color.directive';
const datepipe: DatePipe = new DatePipe('en-US')


@Component({
  selector: 'app-completed-liveclass-tab',
  templateUrl: './completed-liveclass-tab.component.html',
  styleUrls: ['./completed-liveclass-tab.component.scss']
})
export class CompletedLiveclassTabComponent implements OnInit {

  public materialID: any = ''
  public isStudent = false;
  public isTeacher = false;
  public classList = [];
  public currentLiveClassFilter = 'none';
  public currentLiveClassPageNumber = 1;
  public currentPageNumber: number = 1;
  public totalLiveClassPageCount: number = 1;
  constructor(private rest: ApiService,private router:Router,public toastr: ToastrManager) { }

  ngOnInit() {
    this.isTeacher =
    localStorage.getItem("profileType").toLowerCase() == "teacher";
    this.isStudent =
        localStorage.getItem("profileType").toLowerCase() == "student";
    var reqData = {
      "notFetchUpcoming": true
    }
    this.fetchCompletedLiveClasses(reqData);
  }
  LiveClassDetails(data) {
    if (this.isTeacher) {
      
        this.router.navigate(["live-class-details/" + data._id]);
    } else {
      console.log('fdfdf')
        this.router.navigate(["live-class-details/" + data._id]);
    }
}
  clickLiveClassPageNumber(pageNumber) {
    this.currentLiveClassPageNumber = pageNumber;
    console.log(this.currentLiveClassPageNumber);
    var reqData = {
        notFetchCompleted: false,
        filter: this.currentLiveClassFilter,
        limit: 5,
        page: pageNumber
    };
    this.fetchCompletedLiveClasses(reqData)
  }


  liveClassFilter(filter) {
    if(this.currentLiveClassFilter == filter){

    }
    else{
        this.currentLiveClassFilter = filter;
        this.currentPageNumber = 1;
        var reqData = {
            notFetchCompleted: false,
            filter: filter,
            limit: 5
        };
        console.log(reqData);
        this.fetchCompletedLiveClasses(reqData);
        console.log('reqData,=========',reqData)
    }
    
}

  fetchCompletedLiveClasses(reqData) {
    const token = localStorage.getItem("webtoken");
    if (this.isTeacher) {
      this.rest
          .getUserPersonalClassRequestsTeacher(token, reqData)
          .subscribe((result) => {
              console.log(result, "result===");
              if (result.status) {
                  this.classList =  result.data && result.data.data && result.data.data.completed? result.data.data.completed : [];
                  console.log("after respose", this.classList);
                  for(var item of this.classList){
                    if(item.startDateTime){
                      item.startDateTime = datepipe.transform(item.startDateTime, 'MMM d, y, h:mm a')
                    }
                  }
              }
          });
  } else {
      this.rest
      .getUserPersonalClassRequestsStudent(token, reqData)
      .subscribe((result) => {
          console.log(result, "result");
          if (result.status) {
              this.classList = result.data.data.completed;
              console.log("after respose", this.classList);
              for(var item of this.classList){
                if(item.startDateTime){
                  item.startDateTime = datepipe.transform(item.startDateTime, 'MMM d, y, h:mm a')
                }
              }
          }
      });
    }
  }
}


