<app-header-style-one *ngIf="!isTeacher"></app-header-style-one>
<app-header-style-one-teacher *ngIf="isTeacher"></app-header-style-one-teacher>

    <div class="container  mt-3" >
        <div class="page-title-content" >
            <ul>
                <li><a href="/student/home">Home</a></li>
                <li><a href="institute-list">Institute list</a></li>
                <li *ngIf="liveDetails" >{{liveDetails.className}}</li>
            </ul>
            <h4 style="color: #000000;padding-left: 0;padding-bottom:10px;" class="col-lg-12">
					Institute details
				</h4>
        </div>
    </div>


<section class="courses-details-area pt-50 pb-70" *ngIf="liveDetails">
    <div class="container">
        <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-lg-5">
                    <div class="courses-title">
                        <h2>{{liveDetails.className}} 
                            <!-- - {{livedetails.ScheduleDate |  date :"MMM dd,yyyy, hh:mm aaa"}} -->
                        </h2>
                       
                    </div>

                    <!-- <div class="courses-meta">
                        <ul>
                            <li>
                                <i class='bx bx-folder-open'></i>
                                <span>Category</span>
                                <span *ngIf="livedetails.categoryID">{{livedetails.categoryID.categoryName}} / {{livedetails.moduleID.moduleName}} / {{livedetails.topicID.topicName}}</span>
                            </li>
                        </ul>
                    </div> -->
                </div>

                </div>
                </div>

        <div class="row">
            <div class="col-lg-8">
                <div class="courses-details-image text-center">
                    <img [src]="liveDetails.thumbnail ? liveDetails.thumbnail : 'assets/img/courses/courses1.jpg'" alt="image">
                </div>
                <div class="mt-3 mb-3">
                    <strong class="mb-3">
                        Description
                    </strong>
                    <div class="col-lg-12 text-break">
                            {{liveDetails.description}}
                    </div>
                </div>
               
            </div>

            <div class="col-lg-4">
                <div class="courses-sidebar-information">
                    <ul>
                        <li><span><i class="fa fa-institution"></i>Institution Name:</span>
                        {{liveDetails.institutionName}}</li>
                        <li><span><i class="fa fa-institution"></i>Institution Short Name:</span>
                        {{liveDetails.institutionShortName}}</li>
                        <li><span><i class="bi bi-telephone"></i>Phone Number:</span>
                        {{liveDetails.phoneNumber}}</li>
                        <li><span><i class="bi bi-house"></i>Institution Address:</span>
                        {{liveDetails.institutionAddress}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <img src="assets/img/spinner-default.gif" alt="">
</ngx-spinner>