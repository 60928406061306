import { Component, OnInit,Inject, ElementRef, ViewChild, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Options } from 'ng5-slider';
import { ApiService } from '../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {NgSelectModule, NgOption} from '@ng-select/ng-select';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from '../../../../environments/environment';
import { MediaService } from '../../../shared/media.service';
@Component({
  selector: 'app-explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.scss']
})
export class ExploreComponent implements OnInit {
  
  public courseData:any=[];
  public testevaluationData:any=[];
  public testbenchmarkingData:any=[];
  public testpreviousyearData:any=[];
  public testprogressiveData:any=[];
  public examData:any=[];
  public coursecategorylist : any = [];
  public courseboardlist : any = [];
  public coursesubjectlist : any = [];
  public languageapi : any = [];
  public gradeapi : any = [];
  public allMeeting : any = [];
  public allMeetingPublic : any = [];
  selectedValue = "";   
  public section = "Select section";
  exploreForm: FormGroup;
  // exploreSearch: FormGroup;
  exploreFormPublic: FormGroup;
  exploretestform: FormGroup;
  public userprofdata : any =[];
  public isLogin : any = 'false';
  public isStudent:any = '';
  public isTeacher:Boolean = false;
  public groupNameTeacher:any='';
  public groupName:any='';
  public isSession:boolean=true;
  public isCourse:boolean=true;
  public isexams:boolean=true;  
  public istests:boolean=false;  
  public songsList:any=[];
  public ebooklistbyId :any = []
  public videoList:any = [];
  public inviteUrl = "";
  public frontwebsite = environment.frontUrl;  
  public buddiGroupData:any = [];
  public isVideo:boolean = true;
  public isAudio:boolean = true;
  public iseBook:boolean = true;
  public isBuddiGroup:boolean= true;
  public isEvaluation:boolean= true;
  public isPartnerUser:any = [];
  public studentIsSchool:Boolean = false;  
  public videoYoutubePublic:any = [];
  public solutionList:any = [];
  public questionList:any = [];
  term: string;
  public message:string = ''
   //For Pagination
   p: number = 1;
   count: number = 4;
 
   p1: number = 1;
   count1: number = 4;

  p2: number = 1;
  count2: number = 4;

  p3: number = 1;
  count3: number = 4;

  p4: number = 1;
  count4: number = 4;

  p5: number = 1;
  count5: number = 4;

  p6: number = 1;
  count6: number = 4;

  p7: number = 1;
  count7: number = 4;

   p8: number = 1;
  count8: number = 4;

  test1:number = 1;
  testcount1:number = 4;
  test2:number = 1;
  testcount2:number = 4;
  test3:number = 1;
  testcount3:number = 4;
  test4:number = 1;
  testcount4:number = 4;

  subject : any;
  modulename ="Study Material";
  videomodulename = "Video classes";
  private pageIdRedirect:any = '';
  constructor(private api: MediaService,private SpinnerService:NgxSpinnerService,private activatedRoute:ActivatedRoute,public location: Location, public toastr: ToastrManager, private formBuilder: FormBuilder, private rest: ApiService, private router: Router ) { }

  ngOnInit(): void {
    $(document).ready(function() {
      $(window).scroll(function() {
        if ($(document).scrollTop() > 5) {
            $(".searchFixed").addClass("is-sticky");
            $(".searchFixed").removeClass("topFit");
            $(".classSpace").removeClass("add-space");
        } else {
            $(".searchFixed").removeClass("is-sticky");
            $(".searchFixed").addClass("topFit");
            $(".classSpace").addClass("add-space");
        }
      });
    });
    var urlArray = window.location.href.split('/');
    var pageId=  urlArray[urlArray.length-1];
    this.pageIdRedirect = pageId
    var subject = this.activatedRoute.snapshot.params['subjectname'];
    // console.log('akdbkhasbdkjasbdbsa',this.subject)
    this.isTeacher = localStorage.getItem('profileType').toLowerCase() =='teacher';
    this.isStudent = localStorage.getItem('teacherID') =='' ||  localStorage.getItem('teacherID') == undefined? true : false
    this.isLogin = localStorage.getItem('isLoggedin'); 
    this.studentIsSchool = (localStorage.getItem('groupName') !='buddi' && this.isLogin=='true')? true : false;
    if(this.isStudent){
      this.getStudentdetails()
    }else{
     this.getTeacherdetails()
    }
    this.getcoursecategory();
    this.getboardfield();
    this.getsubjectfield();
    this.getlanguage();
    this.getgrade();
    // if(this.activatedRoute.snapshot.params['videoname'] == "Videoclasses"){
    //   section:['Video classes']
    // }
    // else if(this.activatedRoute.snapshot.params['videoname'] == "StudyMaterial"){
    //   section:['Study Material']
    // }
    // this.exploreSearch = this.formBuilder.group({
    //   term: ''
    // });  
    if(this.activatedRoute.snapshot.params['subjectname']!== undefined) {
      this.exploreForm = this.formBuilder.group({    
        gradeID: [this.activatedRoute.snapshot.params['gradename']],
        subjectID: [this.activatedRoute.snapshot.params['subjectname']],
        languageID:['all'],
       
        categoryID: ['5f12a2efbf9506355c8adb5c'],
        boardID: [this.activatedRoute.snapshot.params['boardname']],
        // meetingType:[this.studentIsSchool],
        meetingType:[false],
        section:['all'],
        term:''
      });
      if(this.activatedRoute.snapshot.params['videoname'] == "StudyMaterial"){
        this.exploreForm = this.formBuilder.group({    
          gradeID: [this.activatedRoute.snapshot.params['gradename']],
          subjectID: [this.activatedRoute.snapshot.params['subjectname']],
          languageID:['all'],
         
          categoryID: ['5f12a2efbf9506355c8adb5c'],
          boardID: [this.activatedRoute.snapshot.params['boardname']],
          // meetingType:[this.studentIsSchool],
          meetingType:[false],
          section:[this.modulename],
        term:''
        });
      }
      if(this.activatedRoute.snapshot.params['videoname'] == "Videoclasses"){
        this.exploreForm = this.formBuilder.group({    
          gradeID: [this.activatedRoute.snapshot.params['gradename']],
          subjectID: [this.activatedRoute.snapshot.params['subjectname']],
          languageID:['all'],
         
          categoryID: ['5f12a2efbf9506355c8adb5c'],
          boardID: [this.activatedRoute.snapshot.params['boardname']],
          // meetingType:[this.studentIsSchool],
          meetingType:[false],
          section:[this.videomodulename],
        term:''
        });
      }
      
    }
    else {
      this.exploreForm = this.formBuilder.group({    
        gradeID: ['all'],
        subjectID: ['all'],
        languageID:['all'],       
        categoryID: [pageId],
        boardID: ['all'],
        //meetingType:[this.studentIsSchool],
        meetingType:[false],
        section:['all'],
        term:''
      });
    }
    this.isPartnerUser = localStorage.getItem('isPartner') =='true'?true:false;
    this.filterData(this.exploreForm.value);
    this.filterLibrary(this.exploreForm.value);
    this.coursefilterData(this.exploreForm.value);
    this.gettestsdata(this.exploreForm.value);
    this.getevaluationtestsdata(this.exploreForm.value);
    this.filterbuddiGroup(this.exploreForm.value);
    this.filterSolution(this.exploreForm.value);
    this.filterQuestion(this.exploreForm.value);
  
  }
  get f() { return this.exploreForm.controls; }
  filterLibrary(reqForm){
    var req = {
      gradeID:reqForm.gradeID == 'all' ? '' : reqForm.gradeID,
      subjectID:reqForm.subjectID == 'all' ? '' : reqForm.subjectID,
      languageID:reqForm.languageID == 'all' ? '' : reqForm.languageID,
      categoryID:reqForm.categoryID == 'all' ? '' : reqForm.categoryID,
      boardID:reqForm.boardID == 'all' ? '' : reqForm.boardID,
      securitytype:reqForm.meetingType?'Group':'Public',
      groupname:reqForm.meetingType == 'Group' ? this.groupName : '',
      searchby:reqForm.term
    }
    this.filterLibraryAPI(req)
  }
  filterSolution(reqForm){
    var req = {
      gradeID:reqForm.gradeID == 'all' ? '' : reqForm.gradeID,
      subjectID:reqForm.subjectID == 'all' ? '' : reqForm.subjectID,
      languageID:reqForm.languageID == 'all' ? '' : reqForm.languageID,
      categoryID:reqForm.categoryID == 'all' ? '' : reqForm.categoryID,
      boardID:reqForm.boardID == 'all' ? '' : reqForm.boardID,
      securitytype:reqForm.meetingType?'Group':'Public',
      groupname:reqForm.meetingType == 'Group' ? this.groupName : '',
      searchby:reqForm.term
    }
    this.SpinnerService.show();
    this.rest.filterSolutionExplore(req).subscribe((result) => {
      this.SpinnerService.hide();
      if(result.status){        
        this.solutionList = result.data;
      }else{
        this.solutionList = [];
      }
    }, (err) => {
    console.log(err);
    });
  }
  filterQuestion(reqForm){
    var req = {
      gradeID:reqForm.gradeID == 'all' ? '' : reqForm.gradeID,
      subjectID:reqForm.subjectID == 'all' ? '' : reqForm.subjectID,
      languageID:reqForm.languageID == 'all' ? '' : reqForm.languageID,
      categoryID:reqForm.categoryID == 'all' ? '' : reqForm.categoryID,
      boardID:reqForm.boardID == 'all' ? '' : reqForm.boardID,
      securitytype:reqForm.meetingType?'Group':'Public',
      groupname:reqForm.meetingType == 'Group' ? this.groupName : '',
      searchby:reqForm.term
    }
    this.SpinnerService.show();
    this.rest.filterQuestionExplore(req).subscribe((result) => {
      this.SpinnerService.hide();
      if(result.status){        
        this.questionList = result.data;
      }else{
        this.questionList = [];
      }
    }, (err) => {
    console.log(err);
    });
  }
  filterLibraryAPI(req){
    this.SpinnerService.show();
    this.rest.exploreFilterLibrary(req).subscribe((result) => {
      this.SpinnerService.hide();
      if(result.status){        
        this.videoList = result.data.videos;
        this.songsList = result.data.audios;
        this.ebooklistbyId = result.data.ebooks;
        this.videoYoutubePublic = result.data.videosYoutube;
      }else{
        this.videoList = this.songsList = this.ebooklistbyId = this.videoYoutubePublic = [];
      }
    }, (err) => {
    console.log(err);
    });
  }
  getTeacherdetails(){
    const webToken = localStorage.getItem('webtoken');
    this.rest.userName_DisplayTeacher(webToken).subscribe((result) => {
      console.log(result.data);
      this.groupName = result.data.groupName
      this.userprofdata = result.data;
    }, (err) => {
    console.log(err);
    });
  }
  getStudentdetails(){
    const webToken = localStorage.getItem('webtoken');
    this.rest.userName_DisplayStudent(webToken).subscribe((result) => {
      if(result.status){
      this.groupName = result.data.groupName;
      this.userprofdata = result.data;
      }
      
    }, (err) => {
    console.log(err);
    });
  }

  filterData(reqForm){
    var req = {
      gradeID:reqForm.gradeID == 'all' ? '' : reqForm.gradeID,
      subjectID:reqForm.subjectID == 'all' ? '' : reqForm.subjectID,
      languageID:reqForm.languageID == 'all' ? '' : reqForm.languageID,
      categoryID:reqForm.categoryID == 'all' ? '' : reqForm.categoryID,
      boardID:reqForm.boardID == 'all' ? '' : reqForm.boardID,
      meetingType:reqForm.meetingType?'Group':'Public',
      groupname:reqForm.meetingType == 'Group' ? this.groupName : '',
      searchby:reqForm.term,
    }

    this.getDataAPI(req);
  }
  filterbuddiGroup(reqForm){
  var req = {
      gradeID:reqForm.gradeID == 'all' ? '' : reqForm.gradeID,
      subjectID:reqForm.subjectID == 'all' ? '' : reqForm.subjectID,
      languageID:reqForm.languageID == 'all' ? '' : reqForm.languageID,
      categoryID:reqForm.categoryID == 'all' ? '' : reqForm.categoryID,
      boardID:reqForm.boardID == 'all' ? '' : reqForm.boardID,
      isadmin:"yes",
      meetingType:"",
      searchby:reqForm.term,
    }
    this.SpinnerService.show();
    this.rest.exploreFilterBuddi(req).subscribe((result) => {
      this.SpinnerService.hide();
      if(result.status){
        this.buddiGroupData = result.data;
      }else{
        this.buddiGroupData = [];
      }
    });
  }

  coursefilterData(reqForm){
    const userType = localStorage.getItem('userType');
    var req = {
      gradeID:reqForm.gradeID == 'all' ? '' : reqForm.gradeID,
      subjectID:reqForm.subjectID == 'all' ? '' : reqForm.subjectID,
      languageID:reqForm.languageID == 'all' ? '' : reqForm.languageID,
      categoryID:reqForm.categoryID == 'all' ? '' : reqForm.categoryID,
      boardID:reqForm.boardID == 'all' ? '' : reqForm.boardID
    }
    const Typeofuser = localStorage.getItem('userType');
    const webToken = localStorage.getItem('webtoken');
    var formData = {};
    formData["gradeID"]=reqForm.gradeID;
    formData["subjectID"]=reqForm.subjectID;
    formData["languageID"]=reqForm.languageID;
    formData["categoryID"]=reqForm.categoryID;
    formData["boardID"]=reqForm.boardID;
    formData["userType"]=Typeofuser.toLowerCase();    
    this.isLogin = localStorage.getItem('isLoggedin');   
    // Below is For Courses 
    if(this.isLogin=="true") {     
      this.rest.explorefilterCategory(webToken,formData).subscribe((result) => {   
        if(!reqForm.meetingType) {
          this.courseData = result.data.Public;
        }
        else {
          this.courseData = result.data.Group;
        }     
       
      }, (err) => {
        console.log(err);
        });
    }
    else {
      this.rest.exploreFilterCategoryPublic(formData).subscribe((result) => {  
        if(!reqForm.meetingType) {
          this.courseData = result.data.Public;
        }
        else {
          this.courseData = result.data.Group;
        }     
       
      }, (err) => {
        console.log(err);
        });
    }
    
// Below is For Exams 
    if(this.isLogin=="true") {     
      this.rest.getExamsByCategory(webToken,formData).subscribe((result) => {   
        if(!reqForm.meetingType) {
          this.examData = result.data.Public;         
        }
        else {
          this.examData = result.data.Group;
        }     
       
      }, (err) => {
        console.log(err);
        });
    }
    else {
      this.rest.getExamsByCategorypublic(formData).subscribe((result) => {  
        if(!reqForm.meetingType) {
          this.examData = result.data.Public;
        }
        else {
          this.examData = result.data.Group;
        }     
       
      }, (err) => {
        console.log(err);
        });
    }
  }
  sectionChange(event : any){
    this.selectedValue = event.target.options[event.target.selectedIndex].text;    
    if(this.selectedValue=="All") {
      this.isVideo = true;
      this.isSession = true;
      this.isCourse = true;
      this.isexams = true;
      this.istests = false;
      this.iseBook= true;
      this.isAudio = true;
      this.isBuddiGroup = true;
      this.isEvaluation = true;
      this.courseData=[];
      this.allMeeting=[];
      this.examData=[];
      this.changeRole(this.exploreForm.value.meetingType);
    }
    else if(this.selectedValue=="Group Classes") {
      this.isSession = true;
      this.isCourse = false;
      this.isexams = false;
      this.istests = false;
      this.isVideo = false;
      this.iseBook= false;
      this.isBuddiGroup = true;
      this.isAudio = false;
      this.isEvaluation = false;
      this.courseData=[];
      this.examData=[];
      this.changemoduleforsession(this.exploreForm.value.meetingType);
      
    }
    else if(this.selectedValue=="Courses") {
      this.isSession = false;
      this.isCourse = true;
      this.isexams = false;
      this.istests = false;
      this.isVideo = false;
      this.isBuddiGroup = false;
      this.iseBook= false;
      this.isAudio = false;
      this.isEvaluation = false;
      this.allMeeting=[];
      this.examData=[];
      this.changemoduleforcourse(this.exploreForm.value.meetingType);
    }    
    else if(this.selectedValue=="Exams") {
      this.isSession = false;
      this.isCourse = false;
      this.isexams = true;
      this.istests = false;
      this.isVideo = false;
      this.isBuddiGroup = false;
      this.iseBook= false;
      this.isAudio = false;
      this.isEvaluation = false;
      this.allMeeting=[];
      this.courseData=[];
      
      this.changemoduleforexam(this.exploreForm.value.meetingType);
    }  
    else if(this.selectedValue == 'Video Classes'){
      this.isSession = false;
      this.isCourse = false;
      this.isexams = false;
      this.istests = false;
      this.isBuddiGroup = false;
      this.isEvaluation = false;
      // this.allMeeting=[];
      // this.courseData=[];
      // this.examData=[];
      this.isVideo = true;
      this.iseBook= false;
      this.isAudio = false;
      this.changemoduleforsession(this.exploreForm.value.meetingType);
    }  
    else if(this.selectedValue == 'Audio Classes'){
      this.isSession = false;
      this.isCourse = false;
      this.istests = false;
      this.isexams = false;
      this.isAudio = true;
      this.iseBook= false;
      this.isBuddiGroup = false;
      this.isEvaluation = false;
      this.isVideo = false;
      this.changemoduleforsession(this.exploreForm.value.meetingType);
    }
    else if(this.selectedValue == 'Study Material'){
      this.isSession = false;
      this.isCourse = false;
      this.istests = false;
      this.isexams = false;
      this.isAudio = false;
      this.isBuddiGroup = false;
      this.isEvaluation = false;
      this.iseBook = true;
      this.isVideo = false;
       this.changemoduleforsession(this.exploreForm.value.meetingType);
    }
    else if(this.selectedValue == 'buddi Group Classes'){
      this.isSession = false;
      this.istests = false;
      this.isCourse = false;
      this.isexams = false;
      this.isAudio = false;
      this.isBuddiGroup = true;
      this.iseBook = false;
      this.isEvaluation = false;
      this.isVideo = false;
       this.changemoduleforsession(this.exploreForm.value.meetingType);
    }
    else if(this.selectedValue == 'Tests'){
   

      this.gettestsdata(this.exploreForm.value.meetingType);

      this.isSession = false;
      this.istests = true;
      this.isCourse = false;
      this.isexams = false;
      this.isAudio = false;
      this.isBuddiGroup = false;
      this.iseBook = false;
      this.isVideo = false;
      this.isEvaluation = false;
       this.changemoduleforsession(this.exploreForm.value.meetingType);

      
    }
    else if(this.selectedValue == 'Evaluation Tests'){
   

      this.getevaluationtestsdata(this.exploreForm.value.meetingType);

      this.isSession = false;
      this.istests = false;
      this.isCourse = false;
      this.isexams = false;
      this.isAudio = false;
      this.isBuddiGroup = false;
      this.iseBook = false;
      this.isVideo = false;
      this.isEvaluation = true;
       this.changemoduleforsession(this.exploreForm.value.meetingType);

      
    }
    
  }


  gettestsdata(reqForm) {
    const subject = [];
    var formData = {};
    if(this.exploreForm.value.subjectID!= "all") {    
      subject.push(this.exploreForm.value.subjectID);
      formData["subjectID"]=subject;
    }
    else {
      formData["subjectID"]="all";
    }
    formData["language"]=this.exploreForm.value.languageID;
    formData["category"]=this.exploreForm.value.categoryID;
    // formData["category"]="JEE";
    formData["board"]=this.exploreForm.value.boardID;

    this.rest.getTestsByCategory(formData).subscribe((result) => {  
       
      this.testevaluationData = result.data.Evaluation;
      this.testbenchmarkingData = result.data.Benchmarking;
      this.testprogressiveData = result.data.Progressive;
      this.testpreviousyearData = result.data.PreviousYear;
 
  }, (err) => {
    console.log(err);
    });
  }

  getevaluationtestsdata(reqForm) {
    const subject = [];
    var formData = {};
    if(this.exploreForm.value.subjectID!= "all") {    
      subject.push(this.exploreForm.value.subjectID);
      formData["subjectID"]=subject;
    }
    else {
      formData["subjectID"]="all";
    }
    formData["language"]=this.exploreForm.value.languageID;
    formData["category"]=this.exploreForm.value.categoryID;
    // formData["category"]="JEE";
    formData["board"]=this.exploreForm.value.boardID;

    this.rest.getTestsByCategory(formData).subscribe((result) => {  
       
      this.testevaluationData = result.data.Evaluation;
      this.testbenchmarkingData = result.data.Benchmarking;
      this.testprogressiveData = result.data.Progressive;
      this.testpreviousyearData = result.data.PreviousYear;
 
  }, (err) => {
    console.log(err);
    });
  }

  changeRole(type){  
    this.exploreForm.patchValue({ meetingType: !this.exploreForm.value.meetingType});
    var req = {
      gradeID:this.exploreForm.value.gradeID == 'all' ? '' : this.exploreForm.value.gradeID,
      subjectID:this.exploreForm.value.subjectID == 'all' ? '' : this.exploreForm.value.subjectID,
      languageID:this.exploreForm.value.languageID == 'all' ? '' : this.exploreForm.value.languageID,
      categoryID:this.exploreForm.value.categoryID == 'all' ? '' : this.exploreForm.value.categoryID,
      boardID:this.exploreForm.value.boardID == 'all' ? '' : this.exploreForm.value.boardID,
      meetingType: this.exploreForm.value.meetingType?'Group':'Public',
      groupname:type == 'Group' ? this.groupName : '',
      searchby:this.exploreForm.value.term,
    }
    this.filterLibrary(this.exploreForm.value);
    this.getDataAPI(req);
    this.filterSolution(this.exploreForm.value);
    this.filterQuestion(this.exploreForm.value);
    const Typeofuser = localStorage.getItem('userType');
    const webToken = localStorage.getItem('webtoken');
    var formData = {};
    formData["gradeID"]=this.exploreForm.value.gradeID;
    formData["subjectID"]=this.exploreForm.value.subjectID;
    formData["languageID"]=this.exploreForm.value.languageID;
    formData["categoryID"]=this.exploreForm.value.categoryID;
    formData["boardID"]=this.exploreForm.value.boardID;
    formData["userType"]=Typeofuser.toLowerCase();
    
    this.isLogin = localStorage.getItem('isLoggedin');

     // Below is For Courses 
    if(this.isLogin=="true") {    
      this.rest. explorefilterCategory(webToken,formData).subscribe((result) => {  
        if(!this.exploreForm.value.meetingType) {
          this.courseData = result.data.Public;
        }
        else {
          this.courseData = result.data.Group;
        }
       
       
      }, (err) => {
        console.log(err);
        });
    }
    else {
      this.rest. exploreFilterCategoryPublic(formData).subscribe((result) => {  
        if(!this.exploreForm.value.meetingType) {
          this.courseData = result.data.Public;
        }
        else {
          this.courseData = result.data.Group;
        }
       
       
      }, (err) => {
        console.log(err);
        });
    }

    // Below is For Exams 
    if(this.isLogin=="true") {     
      this.rest.getExamsByCategory(webToken,formData).subscribe((result) => {   
        if(!this.exploreForm.value.meetingType) {
          this.examData = result.data.Public;
        }
        else {
          this.examData = result.data.Group;
        }     
       
      }, (err) => {
        console.log(err);
        });
    }
    else {
      this.rest.getExamsByCategorypublic(formData).subscribe((result) => {   
        if(!this.exploreForm.value.meetingType) {
          this.examData = result.data.Public;
        }
        else {
          this.examData = result.data.Group;
        }     
       
      }, (err) => {
        console.log(err);
        });
    }
   
   
  }


  getDataAPI(req){
    this.SpinnerService.show();
    this.rest.exploreFilterMeeting(req).subscribe((result) => {
      this.SpinnerService.hide();
      if(result.status){
        this.allMeeting = result.data;   
      }else{
        this.allMeeting = [];
      }
      
    }, (err) => {
    console.log(err);
    });

  }


  changemoduleforsession(type){
    var req = {
      gradeID:this.exploreForm.value.gradeID == 'all' ? '' : this.exploreForm.value.gradeID,
      subjectID:this.exploreForm.value.subjectID == 'all' ? '' : this.exploreForm.value.subjectID,
      languageID:this.exploreForm.value.languageID == 'all' ? '' : this.exploreForm.value.languageID,
      categoryID:this.exploreForm.value.categoryID == 'all' ? '' : this.exploreForm.value.categoryID,
      boardID:this.exploreForm.value.boardID == 'all' ? '' : this.exploreForm.value.boardID,
      meetingType:type,
      groupname:type == 'Group' ? this.groupName : '',
      searchby:this.exploreForm.value.term,
    }
    this.exploreForm.patchValue({ meetingType: type});
    this.filterLibrary(req);
    this.getDataAPI(req);
    this.filterbuddiGroup(this.exploreForm.value);
    this.filterSolution(this.exploreForm.value);
    this.filterQuestion(this.exploreForm.value)
  }

  changemoduleforcourse(type){
    const Typeofuser = localStorage.getItem('userType');
    const webToken = localStorage.getItem('webtoken');
    var formData = {};
    formData["gradeID"]=this.exploreForm.value.gradeID;
    formData["subjectID"]=this.exploreForm.value.subjectID;
    formData["languageID"]=this.exploreForm.value.languageID;
    formData["categoryID"]=this.exploreForm.value.categoryID;
    formData["boardID"]=this.exploreForm.value.boardID;
    formData["userType"]=Typeofuser.toLowerCase();
    
    this.isLogin = localStorage.getItem('isLoggedin');
    if(this.isLogin=="true") {    
      this.rest. explorefilterCategory(webToken,formData).subscribe((result) => {  
        if(!type) {
          this.courseData = result.data.Public;
        }
        else {
          this.courseData = result.data.Group;
        }
      }, (err) => {
        console.log(err);
        });
    }
    else {
      this.rest. exploreFilterCategoryPublic(formData).subscribe((result) => {  
        if(!type) {
          this.courseData = result.data.Public;
        }
        else {
          this.courseData = result.data.Group;
        }
      }, (err) => {
        console.log(err);
        });
    }
  }

  changemoduleforexam(type){
    const Typeofuser = localStorage.getItem('userType');
    const webToken = localStorage.getItem('webtoken');
    var formData = {};
    formData["gradeID"]=this.exploreForm.value.gradeID;
    formData["subjectID"]=this.exploreForm.value.subjectID;
    formData["languageID"]=this.exploreForm.value.languageID;
    formData["categoryID"]=this.exploreForm.value.categoryID;
    formData["boardID"]=this.exploreForm.value.boardID;
    formData["userType"]=Typeofuser.toLowerCase();
    
    this.isLogin = localStorage.getItem('isLoggedin');
    if(this.isLogin=="true") {    
      this.rest. getExamsByCategory(webToken,formData).subscribe((result) => {  
        if(!type) {
          this.examData = result.data.Public;
        }
        else {
          this.examData = result.data.Group;
        }
      }, (err) => {
        console.log(err);
        });
    }
    else {
      this.rest. getExamsByCategorypublic(formData).subscribe((result) => {  
        if(!type) {
          this.examData = result.data.Public;
        }
        else {
          this.examData = result.data.Group;
        }
      }, (err) => {
        console.log(err);
        });
    }
  }
  getMeetingAll(){
    this.rest.getMeetingAll().subscribe((result) => {
      this.allMeeting = result.data;
      console.log('module',this.allMeeting);
    }, (err) => {
    console.log(err);
    });
  }

  getcoursecategory() {
    this.rest.getCourseCategoryHeader().subscribe((result) => {
      this.coursecategorylist = result.data;
      console.log('course category',this.coursecategorylist)
      var urlArray = window.location.href.split('/');
      this.coursecategorylist.forEach(function(val,key){
      })
    }, (err) => {
    console.log(err);
    });
  }

  getboardfield(){
    this.rest.boardfield().subscribe((result) => {
      this.courseboardlist = result.data;
      console.log('board',this.courseboardlist)
    }, (err) => {
    console.log(err);
    });
  }

  getsubjectfield(){
    this.rest.subjectfield().subscribe((result) => {
      this.coursesubjectlist = result.data;
      console.log('sub',this.coursesubjectlist)
    }, (err) => {
    console.log(err);
    });
  }

  getlanguage(){
    this.rest.languagefield().subscribe((result) => {
      this.languageapi = result.data;
    }, (err) => {
    console.log(err);
    });
  }

 

  getgrade(){
    this.rest.grade().subscribe((result) => {
      this.gradeapi = result.data;
      console.log('garde',this.gradeapi)
    }, (err) => {
    console.log(err);
    });
  }
  selectChange (event: any) {
    this.selectedValue = event.target.options[event.target.selectedIndex].text; 
    var reqForm =  this.exploreForm.value;
    if(reqForm.subjectID == 'all'){
      reqForm.subjectID = ''
    }
    this.courseData=[];
    this.allMeeting=[];
    this.filterData(reqForm);
    this.coursefilterData(reqForm);
    this.filterLibrary(reqForm);
    this.filterbuddiGroup(reqForm);
    this.gettestsdata(reqForm);
    this.filterSolution(reqForm);
    this.filterQuestion(reqForm);
   }
   detailsMeeting(data,pagename){
    data.pagename = pagename;
    data.filterData = this.exploreForm.value;
    var jsonStr= JSON.stringify(data)
    localStorage.setItem('livedetails',jsonStr);
    this.router.navigate(['/enroll/'+"/"+data.meetingID], { state:data });
  }
  buddiGroupRedirect(data){
    this.router.navigateByUrl('buddigroupaccept/'+data._id);
  } 

  Examdetails(data) {
    console.log(data);
    this.router.navigateByUrl('exams/'+data.categoryID.categoryName+"/"+data._id);
  }
  redirectDetails(data,type){    
    this.router.navigateByUrl('addTo/'+type+'/'+data._id);
  }
  ebookdetails(data){
    this.router.navigate(['/ebook-enroll/'+data._id]);
  }
  videodetails(data){
    this.router.navigate(['/video-enroll/'+data._id]);
  }
  audiodetails(data){
    this.router.navigate(['/audio-enroll/'+data._id]);
  }
  coursedetails(data) {
    console.log(data);
    this.router.navigateByUrl('courses/'+data.categoryID.categoryName+"/"+data._id);
  }
  gettestdetails(id) {      
    this.router.navigateByUrl('buddi-test-details/'+id);
  }
  redirectSearchBy(itemName){
    this.router.navigate(['searchby/'+itemName+'/'+this.pageIdRedirect],{ state:this.exploreForm.value });
  }
  backto(){
    this.location.back();
  }
  actieveTwoWay(event){
    this.exploreForm.patchValue({ term: event.target.value});
    this.filterData(this.exploreForm.value);
    this.filterLibrary(this.exploreForm.value);
    this.filterbuddiGroup(this.exploreForm.value);
    this.filterSolution(this.exploreForm.value);
    this.filterQuestion(this.exploreForm.value);
  }
}
