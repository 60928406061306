import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-currency-payment',
  templateUrl: './currency-payment.component.html',
  styleUrls: ['./currency-payment.component.scss']
})
export class CurrencyPaymentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
