import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-buddi-teacher-details',
  templateUrl: './buddi-teacher-details.component.html',
  styleUrls: ['./buddi-teacher-details.component.scss']
})
export class BuddiTeacherDetailsComponent implements OnInit {

  public isstudent = false;
  public isteacher = false;
  public isuma = false;
  public isvidhya = false;
  public issoniya = false;
  public isnandhini = false;
  public ispriyanka = false;
  public issreeja = false;
  public isrithika = false;
  public isnivetha = false;
  public isvignesh = false;
  public isbalkeespraveen = false;
  public isvineethaanil = false;
  public isjothi = false;
  name : any;

  constructor(private my_router: ActivatedRoute) { }

  ngOnInit(): void {
    this.name = this.my_router.snapshot.params['name'];
    var usertype = localStorage.getItem('profileType');
    if (usertype.toLowerCase() == "teacher") {
      this.isteacher = true;
    }
    else {
      this.isstudent = true;
    }

    if(this.name.toLowerCase() == "umakirthiga") {
    this.isuma = true;
    this.issoniya = false;
    this.isvidhya = false;
    this.isvineethaanil = false;
    this.isnandhini = false;
    }
    else if(this.name.toLowerCase() == "vidhya") {
      this.isuma = false;
      this.issoniya = false;
      this.isvidhya = true;
      this.isvineethaanil = false;
      this.isnandhini = false;
    }
    else if(this.name.toLowerCase() == "soniya") {
      this.isuma = false;
      this.issoniya = true;
      this.isvidhya = false;
      this.isvineethaanil = false;
      this.isnandhini = false;
    }
    else if(this.name.toLowerCase() == "nandhini") {
      this.isuma = false;
      this.issoniya = false;
      this.isvidhya = false;
      this.isbalkeespraveen = false;
      this.isvineethaanil = false;
      this.isnandhini = true;
    }
    else if(this.name.toLowerCase() == "priyanka") {
      this.isuma = false;
      this.issoniya = false;
      this.isvidhya = false;
      this.isnandhini = false;
      this.isbalkeespraveen = false;
      this.isvineethaanil = false;
      this.ispriyanka = true;
    }
    else if(this.name.toLowerCase() == "sreeja") {
      this.isuma = false;
      this.issoniya = false;
      this.isvidhya = false;
      this.isnandhini = false;
      this.ispriyanka = false;
      this.isbalkeespraveen = false;
      this.isvineethaanil = false;
      this.issreeja = true;
    }
    else if(this.name.toLowerCase() == "rethikaramesh") {
      this.isuma = false;
      this.issoniya = false;
      this.isvidhya = false;
      this.isnandhini = false;
      this.ispriyanka = false;
      this.issreeja = false;
      this.isbalkeespraveen = false;
      this.isvineethaanil = false;
      this.isrithika = true;
    }
    else if(this.name.toLowerCase() == "nivetha") {
      this.isuma = false;
      this.issoniya = false;
      this.isvidhya = false;
      this.isnandhini = false;
      this.ispriyanka = false;
      this.issreeja = false;
      this.isrithika = false;
      this.isbalkeespraveen = false;
      this.isvineethaanil = false;
      this.isnivetha= true;
    }
    else if(this.name.toLowerCase() == "vignesh") {
      this.isuma = false;
      this.issoniya = false;
      this.isvidhya = false;
      this.isnandhini = false;
      this.ispriyanka = false;
      this.issreeja = false;
      this.isrithika = false;
      this.isnivetha= false;
      this.isbalkeespraveen = false;
      this.isvignesh = true;
      this.isvineethaanil = false;
    }
    else if(this.name.toLowerCase() == "balkeesparveen") {
      this.isuma = false;
      this.issoniya = false;
      this.isvidhya = false;
      this.isnandhini = false;
      this.ispriyanka = false;
      this.issreeja = false;
      this.isrithika = false;
      this.isnivetha= false;
      this.isvignesh = false;
      this.isbalkeespraveen = true;
      this.isvineethaanil = false;
    }
    else if(this.name.toLowerCase() == "vineethaanil") {
      this.isuma = false;
      this.issoniya = false;
      this.isvidhya = false;
      this.isnandhini = false;
      this.ispriyanka = false;
      this.issreeja = false;
      this.isrithika = false;
      this.isnivetha= false;
      this.isvignesh = false;
      this.isbalkeespraveen = false;
      this.isvineethaanil = true;
    }
    else if(this.name.toLowerCase() == "jothiudayashankar") {
      this.isuma = false;
      this.issoniya = false;
      this.isvidhya = false;
      this.isnandhini = false;
      this.ispriyanka = false;
      this.issreeja = false;
      this.isrithika = false;
      this.isnivetha= false;
      this.isvignesh = false;
      this.isbalkeespraveen = false;
      this.isvineethaanil = false;
      this.isjothi = true;
    }
  }

}
