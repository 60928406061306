<app-header-style-one *ngIf="!isTeacher"></app-header-style-one>
    <app-header-style-one-teacher
        *ngIf="isTeacher"
    ></app-header-style-one-teacher>
    <div class="containerBg">
        <div class="container">
            <div class="row mt-5">
                <div class="col-md-12">
                    <div class="serviceTitle">
                        <h3 class="col-lg-12" labelStyle>
                            <span>
                                <!-- <span 
                                    >{{
                                        this.editData._id ? "Update" : "Create"
                                    }}
                                    Institution</span> -->
                                <span class="ml-2 ">Institution Details</span>
                            </span>
                            <button                                type="button" 
                                class="default-btn float-right mr-2 p-2 ml-2"
                                (click)="goBack()"
                                ><i class="fas fa-long-arrow-alt-left mr-1"></i
                                >Back</button>
                            <button style="width: 20%;"
                            type="button"
                            (click)="redirectToAddTutorLearner()"
                            class="default-btn float-right"
                            id="redirectToAddTutorLearner"
                        >
                            <i class="bx bx-save icon-arrow before"></i>
                            Add Tutor/Learner
                            <i class="bx bx-save icon-arrow after"></i>
                          </button>
                            
                                <button (click)="su()"
                                type="button"
                                class="default-btn float-right mr-2 p-2"
                                >Summary</button>
                          
                        </h3>
                    </div>
                    <hr />
                </div>
            </div>
            <div class="row mb-5">
              <div class="col-lg-12 col-md-12">
                  <div class="tab-content">
                      <div class="tabs-item">
                          <div class="products-details-tab-content">
                              <ul class="additional-information">
                                  <li>
                                      <span>Institution Name  :</span>{{institutionDetails.institutionName}}
                                  </li>
                                  <li>
                                      <span>Institution Short Name  :</span>
                                      {{institutionDetails.institutionShortName}}
                                  </li>
                                  <li>
                                    <span>Institution type:</span>
                                    {{institutionDetails.institutionType}}
                                </li> 
                                  <li>
                                      <span>Locality:</span>
                                      {{institutionDetails.institutionAddress}}
                                  </li>
                                  <li><span>Description  : </span>
                                    {{institutionDetails.description}}
                                  </li>
                                  <li>
                                      <span>Phone Number  :</span>
                                      {{institutionDetails.phoneNumber}}
                                  </li>
                                  <li>
                                    <span>Classes  :</span>
                                    {{institutionDetails.classes}}
                                </li>
                                <li>
                                  <span>LinkedIn URL  :</span>
                                  {{institutionDetails.linkedin}}
                              </li>
                              <li>
                                <span>Facebook URL   :</span>
                                {{institutionDetails.whatsapp}}
                            </li> 
                         
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
         
                <div class="container-fluid row" *ngIf="sum">
                  <div class="col-lg-3 col-md-3">
                  <h5 style="color: var(--mainColor); margin-bottom: 10px;">Summary Details</h5>
                </div>
                    <div class="orders-table table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Institutions</th>
                                    <th>Subscriptions</th>
                                    <th>Total Tutors</th>
                                    <th>Total Learners</th>
                                    <th>Remaining Classes</th>
                                </tr>
                            </thead>
                            <tbody>
                                 <tr>
                                    <td>1</td>
                                    <td>{{institutionDetails.institutionName}}</td>
                                    <td> {{institutionDetails.subscriptionType.institutionTypeName}}</td>
                                    <td (click)="tutor()" style="cursor:pointer;"> {{institutionDetails.totalTeachers}}</td>
                                    <td (click)="learner()" style="cursor:pointer;"> {{institutionDetails.totalStudents}}</td>
                                   <td style="display: flex;flex-direction: column;">
                                    <div>Live Classes: {{institutionDetails.remainingLiveClass}}</div>
                                   <div>Study hall: {{institutionDetails.remainingStudyHall}}</div>
                                   <div>Round Table: {{institutionDetails.remainingRoundTable}}</div>
                                   </td>
                                </tr>
                                <div class="modal fade" id="Institution" role="dialog">
                                    <div class="modal-dialog">
                                      <div class="modal-content">
                                        <div class="modal-header">
                                            <h4 class="modal-title">Institution details</h4>
                                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                                        </div>
                                        <div class="modal-body">
                                        <p><b>Institution Name  :</b> {{institutionDetails.institutionName}}</p>
                                        <p><b>Institution Short Name  :</b> {{institutionDetails.institutionShortName}}</p> 
                                        <p><b>Institution Address  :</b> {{institutionDetails.institutionAddress}}</p> 
                                        <p><b>Expertise  : </b>{{institutionDetails.expertise}}</p> 
                                        <p><b>Description  : </b>{{institutionDetails.description}}</p> 
                                        <p><b>Phone Number  : </b>{{institutionDetails.phoneNumber}}</p>
                                        <p><b>Classes  : </b>{{institutionDetails.classes}}</p> 
                                        <p><b>LinkedIn URL  : </b>{{institutionDetails.linkedin}}</p> 
                                        <p><b>Whatsapp URL   : </b>{{institutionDetails.whatsapp}}</p>  
                                        <p><b>Reviews   : </b> {{institutionDetails.reviews}}</p>     
                                      </div>
                                      </div>
                                      
                                    </div>
                                  </div>


                                  <!-- <div class="modal fade" id="Subscription" role="dialog">
                                    <div class="modal-dialog">
                                      <div class="modal-content">
                                        <div class="modal-header">
                                            <h4 class="modal-title">Subscription details</h4>
                                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                                          </div>
                                          <div class="modal-body">
                                        <p><b>Package Type  :</b> {{institutionDetails.packageType}}</p>
                                        <p><b>Next Billing Date  :</b> {{institutionDetails.nextBillingDate}}</p>
                                         <p><b>Subscription Type  : </b>{{institutionDetails.subscriptionType.institutionTypeName}}</p>
                                         <p><b>Price  : </b>{{institutionDetails.subscriptionType.price}}</p>
                                      </div>
                                      </div>
                                    </div>
                                  </div> -->

                                  <!-- <div class="modal fade" id="tutor" role="dialog">
                                    <div class="modal-dialog" style="max-width:666px">
                                      <div class="modal-content"> -->
                                        <!-- <div class="modal-header">
                                            <h4 class="modal-title">Tutor details</h4>
                                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                                          </div>
                                          <div class="modal-body"  *ngFor="let teach of teachlist;let i = index">
                                            <p style="text-decoration: underline;color: var(--mainColor);"><b>Tutor : {{i+1}}</b></p>
                                        <p><b>First Name :</b> {{teach.firstName}}</p>
                                        <p><b>Last Name :</b> {{teach.lastName}}</p>
                                        <p><b>Phone Number :</b> {{teach.phoneNumber}}</p>
                                        <p><b>Email : </b> {{teach.email}}</p>
                                      </div> -->
                                      <!-- <div class="row">
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                            <div class="card shadow borderR_10">
                                              <div class="header text-center pt-2">
                                                <h6>Tutors Details</h6>
                                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                                              </div>
                                              <div class="tableBody p-0 font-12">
                                                <div class="table-responsive">
                                                  <table class="table table-hover">
                                                    <thead>
                                                      <tr>
                                                        <th>S.No</th>
                                                        <th>First Name&nbsp; <i class="fa fa-sort"></i></th>
                                                        <th>Last Name&nbsp; <i class="fa fa-sort"></i></th>
                                                        <th>Phone Number &nbsp; <i class="fa fa-sort"></i></th>
                                                        <th>Email &nbsp; <i class="fa fa-sort"></i></th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr *ngFor="let teach of teachlist;let i = index">
                                                        <td>{{i+1}}</td>
                                                        <td>{{teach.firstName}}</td>
                                                        <td>{{teach.lastName}}</td>
                                                        <td>{{teach.phoneNumber}}</td>
                                                        <td>{{teach.email}}</td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                      </div>
                                    </div>
                                  </div> -->
                                  <!-- <div class="modal fade" id="learner" role="dialog">
                                    <div class="modal-dialog" style="max-width:666px">
                                      <div class="modal-content"> -->
                                        <!-- <div class="modal-header">
                                            <h4 class="modal-title">Learner details</h4>
                                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                                          </div>
                                          <div class="modal-body" *ngFor="let stud of studlist;let h = index">
                                            <p style="text-decoration: underline;color: var(--mainColor);"><b>Learner : {{h+1}}</b></p>
                                            <p><b>First Name :</b>{{stud.firstName}}</p>
                                            <p><b>Last Name :</b>{{stud.lastName}}</p>
                                            <p><b>Phone Number :</b>{{stud.phoneNumber}}</p>
                                            <p><b>Email : </b>{{stud.email}}</p>  
                                      </div> -->
                                      <!-- <div class="row">
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                            <div class="card shadow borderR_10">
                                              <div class="header text-center pt-2">
                                                <h6>Learners Details</h6>
                                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                                              </div>
                                              <div class="tableBody p-0 font-12">
                                                <div class="table-responsive">
                                                  <table class="table table-hover">
                                                    <thead>
                                                      <tr>
                                                        <th>S.No</th>
                                                        <th>First Name&nbsp; <i class="fa fa-sort"></i></th>
                                                        <th>Last Name&nbsp; <i class="fa fa-sort"></i></th>
                                                        <th>Phone Number &nbsp; <i class="fa fa-sort"></i></th>
                                                        <th>Email &nbsp; <i class="fa fa-sort"></i></th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr *ngFor="let stud of studlist;let i = index">
                                                        <td>{{i+1}}</td>
                                                        <td>{{stud.firstName}}</td>
                                                        <td>{{stud.lastName}}</td>
                                                        <td>{{stud.phoneNumber}}</td>
                                                        <td>{{stud.email}}</td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                            </div>
                                        </div>
                                    </div> -->
                                      <!-- </div>
                                    </div>
                                  </div> -->

                            </tbody>
                        </table>
            </div>
           </div>

           <div class="row" *ngIf="tutortable">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="card shadow borderR_10">
                  <div class="header text-center pt-2">
                    <h6 >Tutor Details</h6>
                    <button type="button" class="close" data-dismiss="modal" (click)="tutorclose()">&times;</button>
                  </div>
                  <div class="tableBody p-0 font-12">
                    <div class="table-responsive">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th>S.no</th>
                            <th>First Name&nbsp; <i class="fa fa-sort"></i></th>
                            <th>Last Name&nbsp; <i class="fa fa-sort"></i></th>
                            <th>Phone Number &nbsp; <i class="fa fa-sort"></i></th>
                            <th>Email &nbsp; <i class="fa fa-sort"></i></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let teach of teachlist;let i = index">
                            <td>{{i+1}}</td>
                            <td>{{teach.firstName}}</td>
                            <td>{{teach.lastName}}</td>
                            <td>{{teach.phoneNumber}}</td>
                            <td>{{teach.email}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
            </div>
        </div>
        <div class="row"  *ngIf="learnertable">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div class="card shadow borderR_10">
                <div class="header text-center pt-2">
                  <h6>Learner Details</h6>
                  <button type="button" class="close" data-dismiss="modal" (click)="learnerclose()">&times;</button>
                </div>
                <div class="tableBody p-0 font-12">
                  <div class="table-responsive">
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <th>S.no</th>
                          <th>First Name&nbsp; <i class="fa fa-sort"></i></th>
                          <th>Last Name&nbsp; <i class="fa fa-sort"></i></th>
                          <th>Phone Number &nbsp; <i class="fa fa-sort"></i></th>
                          <th>Email &nbsp; <i class="fa fa-sort"></i></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let stud of studlist;let i = index">
                          <td>{{i+1}}</td>
                          <td>{{stud.firstName}}</td>
                          <td>{{stud.lastName}}</td>
                          <td>{{stud.phoneNumber}}</td>
                          <td>{{stud.email}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
          </div>
      </div>
       
    
           <!-- <div>
                <h3>Institution Name: {{institutionDetails.institutionName}}</h3>
                <h3>Remaining Live Classes: {{isBasicInstitution ? 'NA' : institutionDetails.remainingStudyHall}}</h3>
                <h3>Remaining Study Hall: {{isBasicInstitution ? 'NA' : institutionDetails.remainingLiveClass}}</h3>
                <h3>Remaining Round Table: {{isBasicInstitution ? 'NA' : institutionDetails.remainingRoundTable}}</h3>
                <h3>Remaining Videos: {{isBasicInstitution ? 'NA' : institutionDetails.remainingVideos}}</h3>
                <h3>Remaining Study Material: {{isBasicInstitution ? 'NA' : institutionDetails.remainingStudyMaterial}}</h3>
                <h3>Remaining Tests: {{isBasicInstitution ? 'NA' : institutionDetails.remainingTests}}</h3>
                <h3>Balance to be paid: {{isBasicInstitution ? 'NA' : institutionDetails.balanceToBePaid ? institutionDetails.balanceToBePaid : 0}}</h3>

           </div> -->
        </div>
    </div>
    <ngx-spinner
        bdColor="rgba(51, 51, 51, 0.8)"
        size="default"
        type="ball-spin-clockwise"
    >
        <img src="assets/img/spinner-default.gif" alt="" />
    </ngx-spinner>