import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ApiService } from "../../../shared/api.service";
import { Location } from "@angular/common";
import { ToastrManager } from "ng6-toastr-notifications";
@Component({
  selector: 'app-add-tutor-learner',
  templateUrl: './add-tutor-learner.component.html',
  styleUrls: ['./add-tutor-learner.component.scss']
})
export class AddTutorLearnerComponent implements OnInit {
  public isstudent = false;
  public isteacher = false;
  public editData: any = [];
  createForm: FormGroup;
  acreateForm: FormGroup;
  submitted = false;
  constructor(   
    private formBuilder: FormBuilder,  
    private location: Location,
    private rest: ApiService,
    public toastr: ToastrManager,
    ) { }

  ngOnInit(): void {
    const userType = localStorage.getItem('userType');      
    if(userType.toLowerCase()=="teacher") {
      this.isteacher = true;
    }
    else {
      this.isstudent = true;
    }
    this.createForm = this.formBuilder.group({
      studentmail: [""],
    });
    this.acreateForm = this.formBuilder.group({
      
      teachermail: [""],
    });
  }

  get f() {
    return this.createForm.controls;
  }
  get g() {
    return this.acreateForm.controls;
  }
  goBack() {
    this.location.back();
  }

  addStudent(status) {
    this.submitted = true;
    console.log(this.createForm)
    if (!this.createForm.value.studentmail) {
      this.toastr.errorToastr("Student email is required")
      return;
    }
    const webToken = localStorage.getItem('webtoken');
    console.log(this.createForm.value)
    let data = this.createForm.value
    var req = {}
    req["studentToAdd"] = data.studentmail;
    this.rest
      .addStudentToInstitution(webToken, req)
      .subscribe((result) => {
        if(result.status){
          this.toastr.successToastr("Added student successfully")
        }
        else{
          this.toastr.errorToastr(result.message)
        }
      })
  }

  addTeacher(status) {
    this.submitted = true;
    console.log(this.acreateForm)
    if (!this.acreateForm.value.teachermail) {
      this.toastr.errorToastr("Teacher email is required")
      return;
    }
    const webToken = localStorage.getItem('webtoken');
    console.log(this.acreateForm.value)
    let data = this.acreateForm.value
    var req = {}
    req["teacherToAdd"] = data.teachermail;
    this.rest
      .addTeacherToInstitution(webToken, req)
      .subscribe((result) => {
        if(result.status){
          this.toastr.successToastr("Added teacher successfully")
        }
        else{
          this.toastr.errorToastr(result.message)
        }
      })
  }
}
