
<div *ngIf="isteacherRoute">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>
<div *ngIf="!isteacherRoute">
    <app-header-style-one></app-header-style-one>
</div>

<section class="courses-details-area pt-100 pb-70" *ngIf="livedetails.length!=0">
    <div class="container">
        <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="courses-title">
                        <h2 style="overflow-wrap: break-word;">{{livedetails.videoName}} 
                        </h2>    
                        <div id="half-stars-example">
                            <div class="rating-group">
                                <input class="rating__input rating__input--none" onclick="return false;" checked name="rating2" id="rating2-0" value="0" type="radio">
                                <label aria-label="0 stars" class="rating__label" for="rating2-0">&nbsp;</label>
                                <label aria-label="0.5 stars" class="rating__label rating__label--half" for="rating2-05"><i class="rating__icon rating__icon--star fa fa-star-half"></i></label>
                                <input class="rating__input" name="rating2" id="rating2-05" value="0.5" onclick="return false;" type="radio" [(ngModel)]="is0p5star" [value]="true">
                                <label aria-label="1 star" class="rating__label" for="rating2-10"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                <input class="rating__input" name="rating2" id="rating2-10" value="1" type="radio" onclick="return false;" [(ngModel)]="is1star" [value]="true">
                                <label aria-label="1.5 stars" class="rating__label rating__label--half" for="rating2-15"><i class="rating__icon rating__icon--star fa fa-star-half"></i></label>
                                <input class="rating__input" name="rating2" id="rating2-15" onclick="return false;" value="1.5" type="radio" [(ngModel)]="is1p5star" [value]="true">
                                <label aria-label="2 stars" class="rating__label" for="rating2-20"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                <input class="rating__input" name="rating2" id="rating2-20" value="2" onclick="return false;" type="radio" [(ngModel)]="is2star" [value]="true">
                                <label aria-label="2.5 stars" class="rating__label rating__label--half" for="rating2-25"><i class="rating__icon rating__icon--star fa fa-star-half"></i></label>
                                <input class="rating__input" name="rating2" id="rating2-25" onclick="return false;" value="2.5" type="radio" [(ngModel)]="is2p5star" [value]="true">
                                <label aria-label="3 stars" class="rating__label" for="rating2-30"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                <input class="rating__input" name="rating2" id="rating2-30" value="3" onclick="return false;" type="radio" [(ngModel)]="is3star" [value]="true">
                                <label aria-label="3.5 stars" class="rating__label rating__label--half" for="rating2-35"><i class="rating__icon rating__icon--star fa fa-star-half"></i></label>
                                <input class="rating__input" name="rating2" id="rating2-35" value="3.5" onclick="return false;" type="radio" [(ngModel)]="is3p5star" [value]="true">
                                <label aria-label="4 stars" class="rating__label" for="rating2-40"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                <input class="rating__input" name="rating2" id="rating2-40" value="4" onclick="return false;" type="radio" [(ngModel)]="is4star" [value]="true">
                                <label aria-label="4.5 stars" class="rating__label rating__label--half" for="rating2-45"><i class="rating__icon rating__icon--star fa fa-star-half"></i></label>
                                <input class="rating__input" name="rating2" id="rating2-45" value="4.5" onclick="return false;" type="radio" [(ngModel)]="is4p5star" [value]="true">
                                <label aria-label="5 stars" class="rating__label" for="rating2-50"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                <input class="rating__input" name="rating2" id="rating2-50" value="5" onclick="return false;" type="radio" [(ngModel)]="is5star" [value]="true">
                            </div>
                         
                        </div>
                        
                       
                    </div>
                    <div class="courses-meta">
                        <ul>
                            <li>
                                <i class='bx bx-folder-open'></i>
                                <span>Category</span>
                                <a>{{livedetails.categoryID.categoryName}}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="courses-meta">                        
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="courses-price">
                        <div class="courses-review">
                        </div>
                        <div class="price"></div>
                        <!-- <a class="default-btn mr-2" target="_blank" *ngIf="livedetails.providerByName=='You Tube' && isLoggedin =='true'" [href]="livedetails.name">                            
                            <i class='bx bx-paper-plane icon-arrow before'></i>
                                <span class="label">Watch</span>
                            <i class="bx bx-paper-plane icon-arrow after"></i>
                        </a>   -->
                        <a  class="default-btn mr-1" (click)="backto()">Back</a>
                         
                        <!-- <a class="default-btn " *ngIf="isrequest && (isLoggedin =='true')" (click)="requestAccept()">                            
                            <i class="fas fa-plus icon-arrow before"></i>
                            <span class="label">Add to Library</span>
                            <i class="fas fa-plus icon-arrow after"></i>
                        </a>                         -->
                        <a class="default-btn " *ngIf="isLoggedin=='false'" (click)="login()">    
                            <i class='bx bx-paper-plane icon-arrow before'></i>
                            <span class="label">Login</span>
                            <i class="bx bx-paper-plane icon-arrow after"></i>
                        </a>                      
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-8">                
                <div *ngIf="(!isrequest || isLoggedin=='true')" class="video-size-details d-block mx-auto embed-responsive embed-responsive-16by9 mb-1">
                    <video  controls class=""  (ended)="videoEnded()" (play)="playvideo($event)" #videoPlayer [src]="livedetails.videoPath" class="d-block mx-auto" alt="No Video Available in Album"></video>
                </div>
                <div  *ngIf="isLoggedin=='false' || livedetails.providerByName=='You Tube'" class="courses-details-image text-center">
                    <img [src]="livedetails.thumbname !='' ? livedetails.thumbname : 'assets/img/courses/courses1.jpg'" alt="image">
                </div>
                <div class="row">
                    <div class="col-lg-8 pt-3">
                        {{viewCount}} Views
                    </div>
                     <div class="col-lg-2 pt-2" >                        
                        <!-- <i  *ngIf="isLoggedin=='true' && !isteacherRoute" (click)="likeReview(reviewForm,'add')"  class="fa fa-thumbs-up ml-1 mr-1" style="font-size:20px" [class]="(userlike.length ==0 && !isteacherRoute) ? 'text-primary':''"><span  style="font-size:16px" [class]="(userlike.length ==0 && !isteacherRoute)? 'text-primary':''"></span></i> -->
                        <!-- <i  *ngIf="isLoggedin=='false'" class="fa fa-thumbs-up ml-1 mr-1" style="font-size:20px" ><span  style="font-size:16px" ></span></i> -->
                        <i  *ngIf="isLoggedin=='true' && liked"   class="fa fa-thumbs-up icon-liked" style="font-size:20px" [class]="(userlike.length ==0 && !isteacherRoute) ? 'text-primary':''"><span  style="font-size:16px" [class]="(userlike.length ==0 && !isteacherRoute)? 'text-primary':''"></span></i>
                        <i  *ngIf="isLoggedin=='true' && !liked" (click)="upvote()"  class="fa fa-thumbs-up" style="font-size:20px" [class]="(userlike.length ==0 && !isteacherRoute) ? 'text-primary':''"><span  style="font-size:16px" [class]="(userlike.length ==0 && !isteacherRoute)? 'text-primary':''"></span></i>
                        <span class="mt-2" style="font-size:20px" [class]="userlike.length !=0 && !isteacherRoute ? 'text-primary':''">
                            <span  style="font-size:16px" [class]="userlike.length !=0 && !isteacherRoute ? 'text-primary':''"> 
                            {{livedetails.upvotes}} 
                            </span>
                        </span>
                    </div>       
                    
                    <div class="col-lg-2 pt-2" >                        
                        <!-- <i  *ngIf="isLoggedin=='true' && !isteacherRoute" (click)="likeReview(reviewForm,'add')"  class="fa fa-thumbs-up ml-1 mr-1" style="font-size:20px" [class]="(userlike.length ==0 && !isteacherRoute) ? 'text-primary':''"><span  style="font-size:16px" [class]="(userlike.length ==0 && !isteacherRoute)? 'text-primary':''"></span></i> -->
                        <!-- <i  *ngIf="isLoggedin=='false'" class="fa fa-thumbs-up ml-1 mr-1" style="font-size:20px" ><span  style="font-size:16px" ></span></i> -->
                        <i  *ngIf="isLoggedin=='true' && unliked"  class="fa fa-thumbs-down icon-liked" style="font-size:20px" [class]="(userlike.length ==0 && !isteacherRoute) ? 'text-primary':''"><span  style="font-size:16px" [class]="(userlike.length ==0 && !isteacherRoute)? 'text-primary':''"></span></i>
                        <i  *ngIf="isLoggedin=='true' && !unliked" (click)="downvote()"  class="fa fa-thumbs-down" style="font-size:20px" [class]="(userlike.length ==0 && !isteacherRoute) ? 'text-primary':''"><span  style="font-size:16px" [class]="(userlike.length ==0 && !isteacherRoute)? 'text-primary':''"></span></i>
                        <span class="mt-2"  style="font-size:20px" [class]="userlike.length !=0 && !isteacherRoute ? 'text-primary':''">
                            <span  style="font-size:16px" [class]="userlike.length !=0 && !isteacherRoute ? 'text-primary':''"> 
                            {{livedetails.downvotes}}
                            </span>
                        </span>
                    </div>             
                </div> 

                <hr>
               
                     <div class="modal fade" id="myModal" role="dialog">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">                      
                                <span class="bold">Likes {{reviewLike.length}} </span>
                            </div>
                            <span class="modal-body bold text-primary" >
                                <commaFor [forData]="reviewLike"></commaFor>
                                <!-- <span *ngFor="let item of reviewLike">{{item.name}},</span> -->
                            </span>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                  
                    </div>
                </div>
                <div class="mt-5">
                    <label>
                        Description
                    </label>
                    <div class="col-lg-12 text-break">
                            {{livedetails.description =='' ? '-' : livedetails.description}}
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="courses-sidebar-information">
                    <ul>
                       <!--  <li>
                            <span><i class='bx bx-group'></i> Privacy Type:</span>
                            {{livedetails.securitytype =='Group' ? 'School':livedetails.securitytype}}
                        </li> -->
                        <li *ngIf="livedetails.securitytype =='Group'">
                            <span><i class='bx bx-time'></i> School Name:</span>
                            {{livedetails.groupname}}
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i>Grade:</span>
                            {{livedetails.topicID.gradeID.gradeName}}
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i>Board:</span>
                            {{livedetails.boardID.boardName}}
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i>Subject:</span>
                            {{livedetails.topicID.subjectID.subjectName}}
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i>Language:</span>
                            {{livedetails.languageID.languageName}}
                        </li>
                        <li style="overflow-wrap: break-word;">
                            <span><i class='bx bx-time'></i> Module Name:</span>
                            {{livedetails.topicID.moduleName}}
                        </li>
                        <!-- <li style="overflow-wrap: break-word;">
                            <span><i class='bx bx-time'></i> Chapter Name:</span>
                            {{livedetails.topicID.chapterName}}
                        </li> -->
                        <li *ngIf="livedetails.classType !=''" style="overflow-wrap: break-word;">
                            <span><i class='bx bx-time'></i> Topic Name:</span>
                            {{livedetails.topicID.topicName}}
                        </li>
                        <li style="overflow-wrap: break-word;">
                            <span><i class='bx bx-time'></i> Level:</span>
                            {{livedetails.level}}
                        </li>
                        <li style="overflow-wrap: break-word;">
                            <span><i class='bx bx-time'></i> Price:</span>
                            {{livedetails.price}}
                        </li>
                       
                        <!-- <li>
                            <span><i class='bx bx-time'></i> Type(Optional):</span>
                            {{livedetails.type =='' ? '-' :livedetails.type }}
                        </li>
                       
                        
                      
                        <li class="hashtag">
                            <span><i class='bx bxs-badge-check'></i> Tag:</span>                            
                            <a  *ngFor="let tagObj of tagArray"> {{tagObj | removespace}}</a>                           
                        </li> -->
                        <!-- <li style="overflow-wrap: break-word;">
                            <span><i class='bx bxs-badge-check'></i> Author:</span>
                            {{livedetails.autor =='' ? '-' : livedetails.autor}}
                        </li> -->
                       <!--  <li>
                            <span><i class='bx bxs-badge-check'></i> Additional.info:</span>
                            {{livedetails.additional =='' ? '-' : livedetails.additional}}
                        </li> -->
                        <!-- <li *ngIf="livedetails.isPartner" style="overflow-wrap: break-word;">
                            <span><i class='bx bxs-badge-check'></i> Provider By:</span>
                            {{livedetails.partner ==null? livedetails.providerByName ==''? 'buddi Premium' : livedetails.providerByName : livedetails.partner.partnerName}}
                        </li> -->
                       
                      
                        
                       
                    </ul>
                </div>
                <div class="row">
                    <div  *ngIf="isteacherRoute">
                        <button class="default-btn ml-1" (click)="editvideo()" *ngIf="livedetails.teacherID == userId && (isLoggedin =='true')" > 
                            <a  style="color:white" target=”_blank”>Edit</a>
                        </button>
                    </div>
                    <!-- <div  (click)="editvideo()"  *ngIf="!isteacherRoute && livedetails.securitytype =='Private'">
                        <button class="default-btn ml-1"> 
                            <a style="color:white" target=”_blank”>Edit</a>
                        </button>
                    </div> -->

                    <div class="float-right" (click)="deletevideo()" *ngIf="isteacherRoute">
                        <button class="default-btn ml-1" *ngIf="livedetails.teacherID == userId && (isLoggedin =='true')" > 
                            <a  style="color:white" target=”_blank”>Delete</a>
                        </button>
                    </div>
                    <!-- <div class="float-right" (click)="deletevideo()" *ngIf="!isteacherRoute && livedetails.securitytype =='Private'">
                        <button class="default-btn ml-1"> 
                            <a  style="color:white" target=”_blank”>Delete</a>
                        </button>
                    </div> -->
                    <!-- <div class="ml-3" *ngIf="!isrequest && livedetails.securitytype !='Private' && isLoggedin=='true' && !isteacherRoute">
                        <a class="default-btn" (click)="removeFromList(livedetails)">     -->
                            <!-- <i class='bx bx-paper-plane icon-arrow before'></i> -->
                            <!-- <span class="label">Remove</span> -->
                            <!-- <i class="bx bx-paper-plane icon-arrow after"></i> -->
                        <!-- </a> 
                    </div> -->
                    
                </div>            
            </div>
        </div>
    </div>
</section>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <img src="assets/img/spinner-default.gif" alt="">
</ngx-spinner>