<section class="courses-area pt-100 pb-70">
    <div class="container">
        <!-- <div class="section-title text-left">
            <span class="sub-title">Discover Courses</span>
            <h2>Our Popular Online Courses</h2>
            <a routerLink="/courses-2-columns-style-1" class="default-btn"><i class='bx bx-show-alt icon-arrow before'></i><span class="label">All Courses</span><i class="bx bx-show-alt icon-arrow after"></i></a>
        </div> -->

        <!-- <div class="shorting-menu">
            <button class="filter" data-filter="all">All (06)</button>
            <button class="filter" data-filter=".business">Business (02)</button>
            <button class="filter" data-filter=".design">Design (05)</button>
            <button class="filter" data-filter=".development">Development (04)</button>
            <button class="filter" data-filter=".language">Language (02)</button>
            <button class="filter" data-filter=".management">Management (03)</button>
            <button class="filter" data-filter=".photography">Photography (04)</button>
        </div> -->
        <div class="container courses-list-tab">
            <div class="tab courses-list-tab">
                <!-- <ul class="tabs">
                    <li><a href="#">JEE</a></li>
                    <li><a href="#">NEET</a></li>
                    <li><a href="#">CODING</a></li>
                </ul> -->
                <a *ngIf="isLogin =='true'" routerLink="/teacher/myprofile" class="default-btn float-right ml-1">My Profile</a>
                <a *ngIf="groupName !='buddi' && isLogin =='true'" routerLink="/create-meeting" class="default-btn float-right">Create Meeting</a>
                <ul class="tabs nav nav-tabs">
                    <li class="active"><a data-toggle="tab" href="#home" class="active">Group Session</a></li>
                    <li><a data-toggle="tab" href="#menu2">Popular Video</a></li>
                    <li><a data-toggle="tab" href="#menu4">Audio</a></li>
                    <!-- <li><a data-toggle="tab" href="#menu4">My Session</a></li> -->
    
                </ul>
        <div class="tab-content myDashboard-content" *ngIf="isLogin =='true'">
            <div id="home" class="tab-pane active">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-3 col-md-6" *ngFor="let slide of meetingsData" >
                            <div class="single-courses-box without-box-shadow mb-30">
                                <div class="courses-image">
                                    <a  (click)="detailsMeeting(slide,'Livesession')" class="d-block"><img [src]="slide.image =='' ? 'assets/img/courses/courses1.jpg':slide.image" alt="image"></a>
                                    <div class="courses-tag">
                                        <a (click)="detailsMeeting(slide,'Livesession')" class="d-block">{{slide.meetingID}}</a>
                                    </div>
                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img [src]="slide.teacherID!=undefined && slide.teacherID.userPhoto!='' ? 'assets/img/user1.jpg'  :'assets/img/user1.jpg'" class="rounded-circle mr-2" alt="image">
                                        <span *ngIf="slide.teacherID!=undefined">{{slide.teacherID.firstName}}  {{slide.teacherID.lastName}} {{myId==slide.teacherID._id ?'(Mine)':''}}</span>
                                    </div>
                                    <h3><a (click)="detailsMeeting(slide,'Livesession')" class="d-inline-block"><span>{{slide.name}} - {{slide.ScheduleDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</span></a></h3>
                               
                                </div>
            
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <!-- <i class='bx bx-user'></i> 10 students -->
                                        </li>
                                        <li class="courses-lesson">
                                            <!-- <i class='bx bx-book-open'></i> 6 lessons -->
                                        </li>
                                        <li class="courses-price">
                                            {{slide.meetingPriceType}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="meetingsData.length ==0">
                            Currently Session not available.
                        </div>
                    </div>
                </div>
            </div>
            <div id="menu2" class="tab-pane fade">
                <div class="recent-orders-table table-responsive">                
                Coming Soon...
                </div>
                
            </div>
            <div id="menu4" class="tab-pane fade">
                <div class="recent-orders-table table-responsive">                
                Coming Soon...
                </div>
                
            </div>
        </div>
    
            </div>
        </div>
    </div>
</section>