<div *ngIf="isStudent">
    <!-- <app-header-style-one></app-header-style-one> -->
</div>
<div *ngIf="isTeacher">
    <!-- <app-header-style-one-teacher></app-header-style-one-teacher> -->
</div>

<!-- <div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Error</li>
            </ul>
            <h2>Error 404</h2>
        </div>
    </div>
</div> -->
<div class="container mt-3">
    <h5 style="color: #000000; cursor: pointer" class="col-lg-12">
       Completed  Live Classes
    </h5>
    
    <div class="orders-table table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th>Class Name</th>
                    <th>Tutor Name</th>
                    <th>Topic</th>
                    <th>Date</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor='let class of classList'>
                    <td *ngIf = "class.personalClassID">{{class.personalClassID.className || class.personalClassID }}</td>
                    <td *ngIf = "class.personalClassID">{{class.personalClassID.teacherID.profileName}}</td>
                    <td *ngIf = "class.personalClassID">{{class.personalClassID.topicID.topicName}}</td>
                    <td *ngIf = "!class.personalClassID"> - </td>
                    <td *ngIf = "!class.personalClassID"> - </td>
                    <td *ngIf = "!class.personalClassID"> - </td>
                    <td>{{class.startDateTime}}</td>
                    <td>{{class.isCompleted ? 'Completed' : class.isRejected ? 'Rejected by Teacher' : 'Cancelled'}}</td>
                    <!--<td>
                        <a routerLink="/single-courses" class="view-button"
                            >View</a
                        >
                    </td> -->
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="container mt-3">
    <h5 style="color: #000000; cursor: pointer" class="col-lg-12">
       Completed Study Hall
    </h5>
    
    <div class="orders-table table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th>Class Name</th>
                    <th>Tutor Name</th>
                    <th>Topic</th>
                    <th>Date</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor='let class of studyHallList'>
                    <td *ngIf = "class.personalClassID">{{class.personalClassID.className || class.personalClassID }}</td>
                    <td *ngIf = "class.personalClassID">{{class.personalClassID.teacherID.profileName}}</td>
                    <td *ngIf = "!class.personalClassID"> - </td>
                    <td *ngIf = "!class.personalClassID"> - </td>
                    <td *ngIf = "class.personalClassID">All Topics</td>
                    <td>{{class.startDateTime}}</td>
                    <td>{{class.isCompleted ? 'Completed' : class.isRejected ? 'Rejected by Teacher' : 'Cancelled'}}</td>
                    <!--<td>
                        <a routerLink="/single-courses" class="view-button"
                            >View</a
                        >
                    </td> -->
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="container mt-3">
    <h5 style="color: #000000; cursor: pointer" class="col-lg-12">
       Completed Round Tables
    </h5>
    
    <div class="orders-table table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th>Class Name</th>
                    <th>Tutor Name</th>
                    <th>Topic</th>
                    <th>Date</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor='let class of roundTableList'>
                    <td *ngIf = "class.personalClassID">{{class.personalClassID.className || class.personalClassID }}</td>
                    <td *ngIf = "class.personalClassID">{{class.personalClassID.teacherID.profileName}}</td>
                    <td *ngIf = "class.personalClassID">{{class.personalClassID.topicID.topicName}}</td>
                    <td *ngIf = "!class.personalClassID"> - </td>
                    <td *ngIf = "!class.personalClassID"> - </td>
                    <td *ngIf = "!class.personalClassID"> - </td>
                    <td>{{class.startDateTime}}</td>
                    <td>{{class.isCompleted ? 'Completed' : class.isRejected ? 'Rejected by Teacher' : 'Cancelled'}}</td>
                    <!--<td>
                        <a routerLink="/single-courses" class="view-button"
                            >View</a
                        >
                    </td> -->
                </tr>
            </tbody>
        </table>
    </div>
</div>
