<div *ngIf="isTeacher">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>
<div *ngIf="!isTeacher">
    <app-header-style-one></app-header-style-one>
</div>

<div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li>Home</li>
                <li>Success</li>
            </ul>
        </div>
    </div>
</div>
<div class="container">
<div class="row" *ngIf="!isTeacher">
    <div class="col-lg-9">
    </div>
    <div class="col-lg-3 mt-2">
        <button *ngIf="!isoneonone"  (click)="backtoExplorePage()" class="default-btn"> <i style="margin-right: 8px;" class="fas fa-long-arrow-alt-left"></i>
            <span class="">Back to Explore Page</span>
        </button>
    </div>  
</div>
</div>
<div class="error-404-area">
    <div class="container">
        <div class="notfound">
           
            <div class="notfound-bg">
                <div>
                </div>
                <div>
                </div>
            </div>
            <div *ngIf="isoneonone">
                <h5 class="text-center pt-3">Thank you!</h5>
                <br>
                <h5 class="mb-4">Click Go to your <a class="text-success" (click)="redirectLocation('mydashboard','menu1')">Dashboard </a>.
                </h5>
                <h5>Your request for One-on-One Class have been Scheduled and added to your Calendar. We will notify you, Once the Teacher Accepted your requests.
                Please check status in Notifications and Calendar.</h5>
                <br>
            </div>
            <div *ngIf="meetingnotstart">
                <h5 class="text-center pt-3">Thank you!</h5>
                <br>
               
                <h5>Your Class Not Started Yet.
                    <br></h5><h5>Your Class Scheduled {{meetingTime |  date :"MMM dd,yyyy, hh:mm aaa"}}</h5>
                 <h5 class="mb-4">Click Go to your <a class="text-success" (click)="redirectLocation('mydashboard','home')">Dashboard </a>.
                </h5>
                <br>
            </div>

            <div *ngIf="!meetingnotstart && !isoneonone && pagename!='Class' && !iskyc && !isStudy && !isLibrary && !enrollclass">
                <!-- <h5 class="text-center pt-3">Thank you!</h5> -->
                <br>
                <h5 class="mb-4">Click Go to your <a class="text-success" (click)="redirectLocation('mydashboard',pagename=='e-book' || pagename =='Question' || pagename =='Solution'?'studymaterial': pagename=='Class' ? 'menu1':'menu4')">Dashboard </a>.
                </h5>
                <h5>{{pagename =='e-book' || pagename =='Question' || pagename =='Solution' ? (pagename=='Question' ? 'Question Paper' : pagename): pagename+' Class'}} Successfully added.</h5>
                <br>
            </div>
            <div *ngIf="pagename=='Class'">
                <h5 class="text-center pt-3">Thank you!</h5>
                <br>
                <h5 class="mb-4">Click Go to your <a class="text-success" (click)="redirectLocation('mydashboard','menu1')">Dashboard </a>.
                </h5>
                <h5>Class Successfully added.</h5>
                <br>
            </div>
            <div *ngIf="iskyc">
                <h5 class="text-center pt-3">Thank you!</h5>
                <br>
                <!-- <h5 class="mb-4">Click Go to your <a class="text-success" (click)="redirectLocation('/mydashboard','')">My Dashboard </a>.
                </h5> -->
                <h5>Your KYC submitted Successfully.Once Verified by the buddi Team, you will get notified via e-mail.</h5>
                <br>
            </div>
            <div *ngIf="isStudy">
                 <h5 class="text-center pt-3">Thank you!</h5>
                <br>
                <h5 class="mb-4">Click Go to your <a class="text-success" (click)="redirectLocation('/mydashboard','studymaterial')">Dashboard </a>.
                </h5>
                <h5>{{message}} to your Study Material.</h5>
                <br>
            </div>
            <div *ngIf="isLibrary">
                 <h5 class="text-center pt-3">Thank you!</h5>
                <br>
                <h5 class="mb-4">Click Go to your <a class="text-success" (click)="redirectLocation('/mydashboard','menu4')">Dashboard </a>.
                </h5>
                <h5>{{message}} to your Library.</h5>
                <br>
            </div>
            <div *ngIf="enrollclass">
                 <h5 class="text-center pt-3">Thank you!</h5>
                <br>
                <h5 class="mb-4">Click Go to your <a class="text-success" (click)="redirectLocation('/mydashboard','menu1')">Dashboard </a>.
                </h5>
                <h5>{{message}} to your Class.</h5>
                <br>
            </div>

        </div>
    </div>
</div>
