import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'studentList',
  templateUrl: './student-list.component.html',
  styleUrls: ['./student-list.component.scss']
})
export class StudentListComponent implements OnInit {
  @Input() listData:any = [];
  constructor() { }

  ngOnInit(): void {
  	console.log(this.listData)
  }

}
