<div class="preloader">
    <div class="loader">
        <div class="shadow"></div>
        <div class="box"><img src="../assets/img/bllogo.png" class="img-fluid" /></div>
    </div>
</div>
<!-- <div class="preloader preloader-alt no-split"
 style="display: none;"><span class="spinner spinner-alt load-done">
     <img class="spinner-brand" src="../assets/img/logo-full-white.png" alt="">
</span></div> -->

    