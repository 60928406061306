import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-privacy',
  templateUrl: './footer-privacy.component.html',
  styleUrls: ['./footer-privacy.component.scss']
})
export class FooterPrivacyComponent implements OnInit {
  public isstudent = false;
  public isteacher = false;

  constructor() { }

  ngOnInit(): void {
    var usertype = localStorage.getItem('profileType');
    if (usertype.toLowerCase() == "teacher") {
      this.isteacher = true;
    }
    else {
      this.isstudent = true;
    }
  }

}
