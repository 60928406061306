<app-header-style-one-teacher></app-header-style-one-teacher>

<app-homefive-main-banner></app-homefive-main-banner>
<br>
<app-homefive-main-banners></app-homefive-main-banners>
<br>
<app-boxes></app-boxes>
<br>
<app-teacheroffer></app-teacheroffer>

<!-- <section class="funfacts-area pt-100">
    <div class="container">
        <div class="funfacts-inner">
            <app-funfacts></app-funfacts>
            <div id="particles-js-circle-bubble"></div>
        </div>
    </div>
</section> -->

<!-- <app-homefive-courses></app-homefive-courses> -->

<!-- <section class="courses-categories-area pb-70">
    <div class="container">
        <div class="section-title text-left">
            <span class="sub-title">Courses Categories</span>
            <h2>Browse Trending Categories</h2>
            <a routerLink="/courses-category-style-2" class="default-btn"><i class='bx bx-show-alt icon-arrow before'></i><span class="label">View All</span><i class="bx bx-show-alt icon-arrow after"></i></a>
        </div>

        <app-categories-style-two></app-categories-style-two>
    </div>

    <div id="particles-js-circle-bubble-2"></div>
</section> -->
<br>

<!-- <app-become-instructor-partner></app-become-instructor-partner> -->
<section class="instructor-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <!-- <span class="sub-title">Make Connections</span> -->
            <h2>Our Team of  Tutors</h2>
           
        </div>

        <app-instructors-style-three></app-instructors-style-three>
    </div>

    <div id="particles-js-circle-bubble-3"></div>
</section>

<section class="testimonials-area pt-100">
    <div class="container">
        <div class="section-title">
           
            <h2>What Teachers Say</h2>
        </div>

        <app-feedback-style-two></app-feedback-style-two>
    </div>
</section>
<app-how-it-works></app-how-it-works>
<app-offer1></app-offer1>
<!-- <section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-left">
            <span class="sub-title">Explore News</span>
            <h2>Our Latest Insights</h2>
            <a routerLink="/blog-style-1" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">Read All</span><i class="bx bx-book-reader icon-arrow after"></i></a>
        </div>

        <app-blog></app-blog>
    </div>
</section> -->