import { Component, OnInit } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cart-checkout',
  templateUrl: './cart-checkout.component.html',
  styleUrls: ['./cart-checkout.component.scss']
})
export class CartCheckoutComponent implements OnInit {

  cartItems:any;
  totalSum: any;
  emptyCart:boolean;
  constructor(
    public toastr: ToastrManager,
    private router: Router
  ) { }

  ngOnInit(): void {
    //this.cartItems = localStorage.getItem('cartItems');
    // let tempCart = [
    //   {
    //    "itemID":"60b0ad1927831d227c69ef40",
    //    "itemName":"Monish Test",
    //    "itemType":"Video",
    //    "price":100
    //   },
    //   {
    //     "itemID":"6098d55add0b6d0c98838db7",
    //     "itemName":"Monish Test",
    //     "itemType":"SmartMaterial",
    //     "price":100
    //    }
    // ];
    // localStorage.setItem('cartItems', JSON.stringify(tempCart));
    console.log(localStorage.getItem('cartItems'));
    this.cartItems = JSON.parse(localStorage.getItem('cartItems'))
    if(this.cartItems.length == 0){
      this.emptyCart = true;
    }
    this.totalSum = this.cartItems.filter(item => item.price)
                                  .reduce((sum, current) => sum + current.price, 0);
  }

  checkPromoCode(){
    alert("Invalid Promo Code");
  }

  removeItem(id){
    for(let i = 0; i < this.cartItems.length; ++i){
      if (this.cartItems[i].itemID === id) {
          this.cartItems.splice(i,1);
          
      }
    }
    console.log(this.cartItems)
    if(this.cartItems.length == 0){
      this.emptyCart = true;
    }
    this.totalSum = this.cartItems.filter(item => item.price)
    .reduce((sum, current) => sum + current.price, 0);
    localStorage.setItem('cartItems', JSON.stringify(this.cartItems))
    document.getElementById('cartSize').innerText = "Cart(" + this.cartItems.length.toString() + ")"
  }

  redirectToPayment(){
    this.router.navigate(["/payment"])
  }
}