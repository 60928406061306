import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removespace'
})
export class RemovespacePipe implements PipeTransform {

   transform(value: any): any {
    if(value){
        return value.replace(/ /g,'');
    }
    return null;
  }
}
