import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../../shared/api.service";
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ExternalLibraryService } from "../util";
import { environment } from "../../../../environments/environment";
declare let Razorpay: any;
@Component({
  selector: 'app-institute-onload',
  templateUrl: './upgrade-package.component.html',
  styleUrls: ['./upgrade-package.component.scss']
})
export class UpgradePacakgeComponent implements OnInit {

  public isStudent = false;
  public isTeacher = false;
  buttonshow=true;
  hg=true;
  cls=true;
  isLoggedin = "false"
  RAZORPAY_OPTIONS: any;
  orderID = "";
  paymentID = ";";
  amount = 0;
  institutionTypes: any;
  userprofdata: any;
  institutionID: "";
  constructor(public toastr: ToastrManager,
    private rest: ApiService,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private razorpayService: ExternalLibraryService) { }

  ngOnInit(): void {
    const webToken = localStorage.getItem('webtoken');
    this.isTeacher =
        localStorage.getItem("profileType").toLowerCase() == "teacher";
    this.isStudent =
        localStorage.getItem("profileType").toLowerCase() == "student";
    if(this.isTeacher){
      this.buttonshow=false;
    }
    if (webToken != null) {
      this.getUserdetails();
    }
    this.getInstitutionTypes();
    this.razorpayService
            .lazyLoadLibrary("https://checkout.razorpay.com/v1/checkout.js")
            .subscribe();
        this.RAZORPAY_OPTIONS = {
            key: environment.razorpayKey,
            amount: "",
            name: environment.paymentNameHead,
            order_id: "",
            description: environment.paymentNameSub,
            prefill: {
                name: "",
                contact: "",
                method: "",
            },
            modal: {},
            theme: {
                color: "#0096C5",
            },
        };
  }
  rout(value){
    if(this.isTeacher){
      const webToken = localStorage.getItem("webtoken");
      this.rest.userName_DisplayTeacher(webToken).subscribe((result) => {
        let currentInstitutionPriceCheck = result.data.institutionType ? result.data.institutionType.price >= value : true
        console.log(result.data.institutionType.price)
        if(result.data.institutionID && currentInstitutionPriceCheck) {
          this.toastr.errorToastr('You are already part of an institution');
        }
        else if(result.data.institutionID){
          this.amount = value;
          this.RAZORPAY_OPTIONS["amount"] = this.amount * 100;
          this.RAZORPAY_OPTIONS["handler"] = this.razorPaySuccessHandler.bind(this);
          this.institutionID = result.data.institutionID;
          this.initiateOrder();
        }
        else{
          this.router.navigate(['/Institute-create']);
        }
      }, (err) => {
      console.log(err);
      this.toastr.errorToastr('Something went wrong, please try again later.');
      });
    }else if(!this.isTeacher){
      this.router.navigate(['/teacher/login']);
      this.toastr.errorToastr('Please login to continue');
    }
  }
  close(){
    this.router.navigate(['/class-creation']);
 }  

 initiateOrder() {
  const webToken = localStorage.getItem("webtoken");
  var request = {
      amount: this.amount,
  };
  if (!webToken) {
      this.toastr.errorToastr("Please login to continue");
      this.router.navigate(["/teacher/login"]);
  } else {
      this.SpinnerService.show();
      this.rest
          .purchaseInstitution(webToken, request)
          .subscribe((response) => {
              this.SpinnerService.hide();
              console.log("material", response);
              this.orderID = response.data.orderId;
              this.paymentID = response.data._id;
              this.RAZORPAY_OPTIONS["order_id"] = response.data.orderId;

              let razorpay = new Razorpay(this.RAZORPAY_OPTIONS);
              razorpay.open();
          });
  }
}

public razorPaySuccessHandler(response) {
  console.log(response);
  var jsonData = {};
  const webToken = localStorage.getItem("webtoken");
  jsonData["razorpay_order_id"] = this.orderID;
  jsonData["razorpay_payment_id"] = response.razorpay_payment_id;
  jsonData["razorpay_signature"] = response.razorpay_signature;
  jsonData["paymentID"] = this.paymentID;
  this.SpinnerService.show();
  this.rest
      .completePurchaseInstitution(webToken, jsonData)
      .subscribe((response) => {
          console.log(response)
          this.SpinnerService.hide();
          if(response['status']) {
              this.toastr.successToastr("Purchase Successful");
              this.upgradeInstitution();
          }
          else{
              this.toastr.errorToastr('Something went wrong. Please try again later.')
          }
          
      });
}

upgradeInstitution(){
  var institutionUpgradeType = this.institutionTypes.find((item) => { return item.price == this.amount})
  if(!institutionUpgradeType){
    this.toastr.errorToastr('Something went wrong. Please try again later.')
  }
  else{
    var jsonData = {};
    const webToken = localStorage.getItem("webtoken");
    jsonData["monthsPurchased"] = 12;
    jsonData["institutionID"] = this.institutionID;
    jsonData["institutionType"] = institutionUpgradeType._id;
    this.rest
    .upgradeInstitution(webToken, jsonData)
    .subscribe((response) => {
      if(response.status){
        this.toastr.successToastr('Upgraded Successfully')
        this.router.navigate(['/sidenav']);
      }
      else{
        this.toastr.errorToastr('Something went wrong. Please try again later.')
      }
    })

  }


}

getUserdetails() {
    const webToken = localStorage.getItem('webtoken');
    this.rest.Getteacherallpopulate(webToken).subscribe((result) => {
      this.userprofdata = result.data;
    }, (err) => {
      console.log(err);
    });
  }

  getInstitutionTypes() {
    const webToken = localStorage.getItem("webtoken");
    this.rest.getinstitutionTypes(webToken).subscribe((response) => {
        console.log(response);
        this.institutionTypes = response.data;
        console.log(this.institutionTypes);
    });
  }
}


