<app-header-style-one></app-header-style-one>
<div class="wrap cf">
    <div class="heading cf">
        <h1>My Cart</h1>
        <a href="#" class="continue">Continue Shopping</a>
    </div>
    <div class="cart">
        <ul class="cartWrap" *ngFor="let d of cartItems; let i = index">
            <li [ngClass]="i % 2 == 0 ? 'items even' : 'items odd' ">
                <div class="infoWrap">
                    <div class="cartSection">
                        <p class="itemNumber">{{d.itemType}}</p>
                        <h3>{{d.itemName}}</h3>
                    </div>
                    <div class="prodTotal cartSection floatright">
                        <p *ngIf="d.itemID">Rs. {{d.price}}</p>
                    </div>
                    <div class="cartSection removeWrap floatright">
                        <a *ngIf="d.itemID" (click)="removeItem(d.itemID)" href="javascript:void(0)" class="remove">x</a>
                    </div>
                </div>
            </li>
        </ul>
        <h2 *ngIf="emptyCart"> No Items Added </h2>
    </div>
    <div *ngIf="!emptyCart" class="promoCode">
        <label for="promo">Have A Promo Code?</label><input type="text" name="promo" placholder="Enter Code" />
        <a  (click)="checkPromoCode()" href="javascript:void(0)" id="promoCode" class="btn"></a>
    </div>
    <div class="subtotal cf">
        <ul>
            <!--<li class="totalRow"><span class="label">Subtotal</span><span class="value">$35.00</span></li>
            <li class="totalRow"><span class="label">Shipping</span><span class="value">$5.00</span></li>
            <li class="totalRow"><span class="label">Tax</span><span class="value">$4.00</span></li> -->
            <li  *ngIf="!emptyCart" class="totalRow final">
                <span class="label">Total</span>
                <span  *ngIf="!emptyCart" class="value">Rs. {{totalSum}}</span>
            </li>
            <li *ngIf="!emptyCart" class="totalRow">
                <a (click)="redirectToPayment()" href="javascript:void(0)" class="btn continue">Checkout</a>
            </li>
        </ul>
    </div>
</div>