<div *ngIf="isstudent">
    <app-header-style-one></app-header-style-one>
</div>
<div *ngIf="isteacher">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>

<!-- <div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About</li>
            </ul>
            <h2>About Us</h2>
        </div>
    </div>
</div> -->

<section class="about-area ptb-50">
    <div class="container">
        <div class="row align-items-center">
       
          
            <div class="col-lg-12 col-md-12">
                <div class="about-content" style="text-align:justify;">
                                        
                    <p style="font-size: 25px; color: #07095a; text-align:center"><b>Reward Program for Learners using  BUDDI Coin
                    </b></p>   
                   <p style="font-size: 18px; color: black;">Learning has become a very complex and expensive process. We want to remove the complexity and make it affordable to all learners. Learners benefit the maximum in our buddi community. 
                </p>
                <p style="font-size: 18px; color: black;">Buddi Coin is a social coin for Education. Learners gets rewards for learning and participation in our community. </p>
                <p style="font-size: 18px; color: black;">In India, only 27% of the learners move from school to college for higher studies. We like to increase this number by offering rewards through buddi coin. Learners can learn and accumulate buddi coin which can be used for higher studies.</p>
                <p style="font-size: 18px; color: black;">
                    We are unique in our approach when compared to other market places. In our model, each and every contributor will be benefitted and create good wealth. </p>
                                          
                </div>
            </div>
            <div class="col-lg-12 col-md-12" style="text-align: center; padding-top: 30px;">
                 <div class="about-content">
                   <p style="font-size: 20px; text-align: justify; color: black;">Following lists the activities that can give rewards. 
                </p>
                <p style="font-size: 20px; color: #07095a; text-align:left; font-weight:bold;">Earn while you learn...
                </p>
                   <p style="font-size: 18px; text-align: justify; color: black;">1. Learner Signup
                </p>
                <p style="font-size: 18px; text-align: justify; color: black;">2. Learner Live Class Completion
                </p>
                <p style="font-size: 18px; text-align: justify; color: black;">3. Learner Referral
                </p>
                <!-- <p style="font-size: 18px; text-align: justify; color: black;">4. Course Completion
               </p> -->
                <p style="font-size: 18px; text-align: justify; color: black;">4. NFT Token Creation - For each Asset
                </p>
                <!-- <p style="font-size: 18px; text-align: justify; color: black;">6. Doubt Clearance 
                </p> -->
               

                </div> 
            </div>
            <!-- <div class="col-lg-12 col-md-12" style="text-align: center; padding-top: 30px;">
                <div class="about-content">
                    <img src="../../../../assets/img/buddi-arch.jpg" class="shadow" alt="image">
                    <p style="font-size: 20px; text-align: justify; padding-top: 30px; color: black;">Our focus and goal is to give the best teaching we can, to learners and have them excel in their studies. </p>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/UdSahnSDt3Q" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <p style="font-size: 19px; text-align: justify; padding-top: 30px; color: black;">buddi is an AI powered adaptive learning platform that takes a much personalised approach to teaching by bringing the best coaching to learners. It integrates live one-on-one classes, group classes, courses, video classes and audio classes to prepare for K12, NEET and JEE exams. Our USP is to offer a personalised approach to each learner to bring an assured improvement in their performance. Our teachers are well trained to bring the best in every learner. buddi with over 1,000's students is expanding to over 4 countries and is led by a well experienced global team.</p> 
                </div>
            </div>-->
        </div>

    </div>
</section>