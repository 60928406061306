import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChapterTopicComponent } from './chapter-topic/chapter-topic.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { SubjectNavComponent } from './subject-nav/subject-nav.component';
const routes: Routes = [
  	{ path: 'accordion', component: ChapterTopicComponent },
  	{ path: '', component: SidenavComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShareModuleRoutingModule { }
