<div *ngIf="isTeacher">
	<app-header-style-one-teacher></app-header-style-one-teacher>
</div>
<div *ngIf="!isTeacher">
	<app-header-style-one></app-header-style-one>
</div>

<div class="containerBg">
	<div class="container">
	   <div class="row mx-0 mt-5">
		  <div class="col-md-12">
			 <div class="serviceTitle">
				<h3 class="col-lg-12" labelStyle><span><i class="fas fa-music"></i><span class="ml-2">{{ this.editData._id ? 'Update' : 'Create'}} Audio</span> </span>
					<a type="button" class="default-btn mb-2  ml-2 float-right p-2" (click)="goBack()"><i class="fas fa-long-arrow-alt-left"></i>&nbsp;Back</a>
				</h3>
			 </div>
			 <hr>
		  </div>
	   </div>
	   <div class="container">
			<form [formGroup]="createForm">
				<div class="row">
					<div class="col-lg-6 description">
						<div class="form-group mb-3">
						   <input type="text" formControlName="benefit" placeholder= "Module Name"  class="form-control" maxlength="50" [ngClass]="{ 'is-invalid': submitted && f.benefit.errors}" />
						   <div *ngIf="submitted && f.benefit.errors" class="invalid-feedback">
							<div *ngIf="f.benefit.errors.required">Module Name is required</div>
						 </div>
						</div>
					</div>

				
									
					<div class="col-md-6 description" *ngIf="coursecategorylist.length !=0">
                        <div class="form-group">
                          <!-- <select class="form-control col-md-12" (change)="selectChange($event)" formControlName="courseCategory" >
                            <option value="" disabled>Select Category</option>
                            <option *ngFor="let obj of coursecategorylist" [ngValue]="obj._id">{{obj.categoryName}}</option>
                          </select> -->
						  <ng-multiselect-dropdown   [placeholder]="'Select category'" [settings]="dropdownSettings" [data]="coursecategorylist"  [(ngModel)]="selectedcategorylist" [ngModelOptions]="{standalone: true}" [settings]="dropdownSettings" (onSelect)="onItemcategorySelect($event)" (onSelectAll)="onItemcategoryallSelect($event)" (onDeSelectAll)="onItemcategoryallDeSelect($event)"(onDeSelect)="onItemcategoryDeSelect($event)" >
						
						</ng-multiselect-dropdown>
                        </div>
					</div>

					<div class="col-lg-6 description">
						<div class="form-group mb-3">
						   <input type="text" formControlName="chapterName" placeholder= "Chapter Name"  class="form-control" maxlength="50" [ngClass]="{ 'is-invalid': submitted && f.chapterName.errors}" />
						   <div *ngIf="submitted && f.chapterName.errors" class="invalid-feedback">
							<div *ngIf="f.chapterName.errors.required">Chapter Name is required</div>
						 </div>
						</div>
					</div>
				
					
					<div class="col-md-6 description" *ngIf="isTeacher">
						<div class="form-group ">
						   <select class="form-control p-0 col-md-12" required formControlName="securitytype"
						   [ngClass]="{ 'invalid-select': submitted && f.securitytype.errors?.required }" >
						   <option [value]="'Public'">Public</option>
						   <option [value]="'Private'">Private</option>
						   <option [value]="'Institution'">Institution</option>
						   <option [value]="'Group'" *ngIf="groupName!='' && isTeacher">School</option>
						</select>
						<span *ngIf="groupName!='' && isTeacher"> School Short Name :  {{groupName}}</span>
						   <div *ngIf="submitted && f.securitytype.errors?.required" class="invalid-error-span">
							  <div>Session Type is required</div>
						   </div>
						</div>
					 </div>
					 <div class="col-md-6 description" *ngIf="!isTeacher">
						Private
					</div>

					<div class="col-md-6">
						<div class="form-group">
							<input type="text" class="form-control" placeholder="Topic Name" id="filmname" aria-describedby="emailHelp"
									maxlength="130" required formControlName="flimname" [ngClass]="{ 'is-invalid': submitted && f.flimname.errors}">
							<div *ngIf="submitted && f.flimname.errors" class="invalid-feedback">
								<div *ngIf="f.flimname.errors.required">Topic Name required</div>
							</div>
						</div>
					</div>	
							
                      <div class="col-md-6 description" *ngIf="gradeapi.length !=0">
                        <div class="form-group">
                          <!-- <select class="form-control col-md-12" (change)="selectChange($event)" formControlName="courseGrade" >
                            <option value="" disabled>Select Grade</option>
                            <option *ngFor="let obj of gradeapi" [ngValue]="obj._id">{{obj.gradeName}}</option>
                          </select> -->
						  <ng-multiselect-dropdown  [placeholder]="'Select Grades'"  [settings]="dropdowngradeSettings" [data]="gradeapi"  [(ngModel)]="selectedgradelist" [ngModelOptions]="{standalone: true}" (onSelect)="onItemgradeSelect($event)" (onSelectAll)="onItemgradeallSelect($event)" (onDeSelectAll)="onItemgradeallDeSelect($event)" (onDeSelect)="onItemgradeDeSelect($event)" >
						
						</ng-multiselect-dropdown>
                        </div>
                      </div>

					  <div class="col-md-6">
						<div class="form-group mb-3">
						   <input type="text" formControlName="caption" placeholder="Sub Topic Name"  class="form-control" required 
						   		maxlength="40" [ngClass]="{ 'is-invalid': submitted && f.caption.errors}" />
						   <div *ngIf="submitted && f.caption.errors" class="invalid-feedback">
							<div *ngIf="f.caption.errors.required">Sub Topic Name is required</div>
						 </div>
						</div>
					</div>			
					
                      <div class="col-md-6 description" *ngIf="courseboardlist.length !=0">
                        <div class="form-group">
                          <select class="form-control col-md-12" (change)="selectChange($event)" formControlName="courseBoard" >
                            <option value="" disabled>Select Board</option>
                            <option *ngFor="let obj of courseboardlist" [ngValue]="obj._id">{{obj.boardName}}</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6 description" *ngIf="coursesubjectlist.length !=0">
                        <div class="form-group">
                          <!-- <select class="form-control col-md-12" (change)="selectChange($event)" formControlName="courseSubject" >
                            <option value="" disabled>Select Subject</option>
                            <option *ngFor="let obj of coursesubjectlist" [ngValue]="obj._id">{{obj.subjectName}}</option>
                          </select> -->
						  <ng-multiselect-dropdown  [placeholder]="'Select Subject'" [settings]="dropdownsubjectSettings" [data]="coursesubjectlist"  [(ngModel)]="selectedsubjectlist" [ngModelOptions]="{standalone: true}"  (onSelect)="onItemsubjectSelect($event)" (onSelectAll)="onItemsubjectallSelect($event)" (onDeSelectAll)="onItemsubjectallDeSelect($event)" (onDeSelect)="onItemsubjectDeSelect($event)" >
						

						</ng-multiselect-dropdown>
                        </div>
					  </div>
					  <div class="col-md-6 description" *ngIf="languageapi.length !=0">
						<div class="form-group">
							<label></label>
						  <select class="form-control col-md-12" (change)="selectChange($event)" formControlName="courseLanguage" >
							<option value="" disabled>Select Language</option>
							<option *ngFor="let obj of languageapi" [ngValue]="obj._id">{{obj.languageName}}</option>
						  </select>
						</div>
					  </div>
					 <div class="col-md-6">
						<div class="form-group">
							<label labelStyle>Choose Audio</label>
							<div class="input-group">
								<div class="custom-file">
									 <input type="hidden" formControlName="fileupload"  name="" value="">
									<input type="file" class="custom-file-input" accept="audio/*" (change)="songuploads($event)" id="inputGroupFile01"
										aria-describedby="inputGroupFileAddon01" [ngClass]="{ 'is-invalid': submitted && f.fileupload.errors}">
									 <label class="custom-file-label" for="inputGroupFile01">{{uploadSongName}}</label>
	
								</div>							
							</div>
							<div *ngIf="submitted && f.fileupload.errors" class="invalid-error-span">
								<div *ngIf="f.fileupload.errors.required" style="font-size: 80%;color: #dc3545;">Document is required</div>
							</div>
							<!-- <span class="control">Holding down the Ctrl key, click each of the each audio(s) files for Multiple Upload .</span> -->
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label labelStyle>Choose Audio Thumbnail</label>
								<div class="input-group">
									<div class="custom-file">
										<input type="hidden" formControlName="thumbnail"  name="" value="">
										<input type="file"  accept="image/*" class="custom-file-input" [ngClass]="{ 'is-invalid': submitted && f.thumbnail.errors}"  (change)="onSelectFile($event)" id="inputGroupFile01"
											aria-describedby="inputGroupFileAddon01">
										<label class="custom-file-label" for="inputGroupFile01">{{this.thumbnailPlace =='' ? 'Choose File' : this.thumbnailPlace}}</label>
									</div>
								</div>
								<div *ngIf="submitted && f.thumbnail.errors" class="invalid-error-span">
									<div *ngIf="f.thumbnail.errors.required" style="font-size: 80%;color: #dc3545;">Thumbnail Image is required</div>
								</div>
							</div>
						</div>						
					<div class="col-lg-6 description">
						<div class="form-group mb-3">
						   <label></label>
						   <input type="text" formControlName="type" placeholder="Type"  class="form-control" maxlength="40"/>
						   <div *ngIf="submitted && f.type.errors" class="invalid-error-span">
							  <div *ngIf="f.type.errors.required">Type is required</div>
						   </div>
						</div>
					 </div>
					<div class="col-md-6">
						<div class="form-group mb-3">
						   <textarea type="text" maxlength="120" formControlName="description"  class="form-control" ></textarea>
						</div>
					 </div>
					<div class="col-md-6 description">
						<div class="form-group ">
						   <ng-select [items]="alltag"
						   bindLabel="name"
						   bindValue="name"
						   [multiple]="true"
						   formControlName="tag"
						   placeholder="Select Tag"
						   maxSelectedItems ='6'
						    [(ngModel)]="selecttag"
						   >
							</ng-select>
						</div>
					</div>					
					 <div class="col-md-6">
						<div class="form-group mb-3">
						   <input type="text" formControlName="autor" [ngClass]="{ 'is-invalid': submitted && f.autor.errors?.required}" 
						   		maxlength="25" placeholder="Author" class="form-control"  />
						   <div *ngIf="submitted && f.autor.errors" class="invalid-error-span">
							<div *ngIf="f.autor.errors.required">Author is required</div>
						 </div>
						</div>
					 </div>						
						<div class="col-md-6">
							<div class="form-group mb-3">
							   <input type="text" formControlName="additional" 
							   [ngClass]="{ 'is-invalid': submitted && f.additional.errors?.required}" 
							   maxlength="100" placeholder="Additional.info(if Any)" class="form-control"  />
							   <div *ngIf="submitted && f.additional.errors" class="invalid-error-span">
								<div *ngIf="f.additional.errors.required">Additional is required</div>
							 </div>
							</div>
						 </div>
						 <div  *ngIf="isPartnerUser"  class="col-md-6">
							<div class="form-group mb-3">
							   <select class="form-control p-0 col-md-12" required formControlName="providerBy">
								   <option [value]="'buddi Premium'">buddi Premium</option>
								  <option [value]="'Partner'">Partner</option>
							   </select>
							</div>
						</div>	
						<div *ngIf="f.providerBy.value =='Partner' && partnerList.length!=0 && isPartnerUser" class="col-md-6 description" >
							<div class="form-group">
							  <select class="form-control col-md-12" (change)="selectChange($event)" formControlName="partner" >
								<option *ngFor="let obj of partnerList" [ngValue]="obj._id">{{obj.partnerName}}</option>
							  </select>
							</div>
						</div>
					
					
				</div>
				<div class="row">
					<div class="col-md-12 description">
						<div class="text-center mt-3">
						   <button type="button" (click)="onSubmit('create')"  class="default-btn mb-2" id="addAudioButton">							   
							   <i class='bx bx-save icon-arrow before'></i>
							   <span class="label">{{ this.editData.length!=0? 'Update' : 'Add'}} Audios</span>
							   <i class="bx bx-save icon-arrow after"></i>
							</button>
						</div>
					 </div>
				</div>
			</form>
		   <br>
		   <div class="row">
			<div class="col-lg-12">
				<h4 labelStyle>Upload Queue Files</h4>
				<table class="table table-bordered">
					<thead class="thead-dark">
					   <th>Name</th>
					   <th>Type</th>
					   <th>Size</th>
					   <th>Action</th>
					</thead>
					<tbody>
							 <tr *ngIf="songs.length == 0">
								 <td colspan="4" class="text-center" labelStyle>No Records Added</td>
							 </tr>
					   <tr *ngFor="let item of songs">
						  <td>{{item.name}}</td>
						  <td>{{item.type}}</td>
						  <td>{{item.size}}</td>
						  <td><a href="javascript:void(0)" (click)="deleteItem(item)"><i class="fa fa-trash"></i></a></td>
					   </tr>
					</tbody>
			 </table>
			 </div>
		   </div>
	 	</div>
	</div>
 </div>
 <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
	<img src="assets/img/spinner-default.gif" alt="">
</ngx-spinner>

