<div *ngIf="!isStudent">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>
<div *ngIf="isStudent">
    <app-header-style-one></app-header-style-one>
</div>
<div class="container">
    <div class="flexButton mb-3 mt-3">
        <h5 style="color: #000000;" *ngIf="tutvdo">Product Demo Videos - Tutor</h5>
        <h5 style="color: #000000;" *ngIf="leavdo">Product Demo Videos - Learner</h5>
        <div class="btn-group ml-3" style="margin-left:0rem !important">
            <button type="button" class="" (click)="learn()">Learner</button>     
            <button type="button"  (click)="tutor()">Tutor</button> 
            <button type="button" (click)=" backto()"><i class="fas fa-long-arrow-alt-left mr-1"></i>Back</button>
            </div>
    </div>
</div>
<div class="container" *ngIf="tutvdo">
    <div class="row">
     <div class="col-lg-4 col-md-6">
        <div class="single-courses-item mb-30 p-4">
            <video width="320" height="240" controls>
                <source src="../../../../assets/tutorials/TutorRegister_001T.mp4" type="video/mp4">
            </video>
            <h4 class="text-center mt-2">How to register as a Tutor?
            </h4>
        </div>
     </div>
     <div class="col-lg-4 col-md-6">
        <div class="single-courses-item mb-30 p-4">
            <video width="320" height="240" controls>
                <source src="../../../../assets/tutorials/TutorRegister_002T.mp4" type="video/mp4">
            </video>
            <h4 class="text-center mt-2">How to login as a Tutor?
            </h4>
        </div>
     </div>
     <div class="col-lg-4 col-md-6">
        <div class="single-courses-item mb-30 p-4">
            <video width="320" height="240" controls>
                <source src="../../../../assets/tutorials/TutorSubscription_003T.mp4" type="video/mp4">
            </video>
            <h4 class="text-center mt-2">How to Subscribe as a Tutor?
            </h4>
        </div>
     </div>
     <div class="col-lg-4 col-md-6">
        <div class="single-courses-item mb-30 p-4">
            <video width="320" height="240" controls>
                <source src="../../../../assets/tutorials/TutorCreateLiveClass_004T.mp4" type="video/mp4">
            </video>
            <h4 class="text-center mt-2">How to Create a Live Class (1:1)?
            </h4>
        </div>
     </div>
     <div class="col-lg-4 col-md-6">
        <div class="single-courses-item mb-30 p-4">
            <video width="320" height="240" controls>
                <source src="../../../../assets/tutorials/TutorStartClass_005T.mp4" type="video/mp4">
            </video>
            <h4 class="text-center mt-2">How to Start a Live Class (1:1)?
            </h4>
        </div>
     </div>
    </div>
</div>
<div class="container" *ngIf="leavdo">
    <div class="row">
     <div class="col-lg-4 col-md-6">
        <div class="single-courses-item mb-30 p-4">
            <video width="320" height="240" controls>
                <source src="../../../../assets/tutorials/LearnerRegister_001L.mp4" type="video/mp4">
            </video>
            <h4 class="text-center mt-2">How to register as a learner?
            </h4>
        </div>
     </div>
     <div class="col-lg-4 col-md-6">
        <div class="single-courses-item mb-30 p-4">
            <video width="320" height="240" controls>
                <source src="../../../../assets/tutorials/LearnerLogin_002L.mp4" type="video/mp4">
            </video>
            <h4 class="text-center mt-2">How to login as a learner?
            </h4>
        </div>
     </div>
     <div class="col-lg-4 col-md-6">
        <div class="single-courses-item mb-30 p-4">
            <video width="320" height="240" controls>
                <source src="../../../../assets/tutorials/LearnerEnrollClass_003L.mp4" type="video/mp4">
            </video>
            <h4 class="text-center mt-2">How to enroll for a live class (1:1)?
            </h4>
        </div>
     </div>
     <div class="col-lg-4 col-md-6">
        <div class="single-courses-item mb-30 p-4">
            <video width="320" height="240" controls>
                <source src="../../../../assets/tutorials/LeanerJoinClass_004L.mp4" type="video/mp4">
            </video>
            <h4 class="text-center mt-2">How to join a live class (1:1)?
            </h4>
        </div>
     </div>
    </div>
</div>