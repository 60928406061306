<div class="partner-slides owl-carousel owl-theme">
    <div class="single-partner-item">
        <a href="#" target="_blank" class="d-block">
            <img src="assets/img/partner/partner7.png" alt="image">
        </a>
    </div>

    <div class="single-partner-item">
        <a href="#" target="_blank" class="d-block">
            <img src="assets/img/partner/partner8.png" alt="image">
        </a>
    </div>

    <div class="single-partner-item">
        <a href="#" target="_blank" class="d-block">
            <img src="assets/img/partner/partner9.png" alt="image">
        </a>
    </div>

    <div class="single-partner-item">
        <a href="#" target="_blank" class="d-block">
            <img src="assets/img/partner/partner10.png" alt="image">
        </a>
    </div>

    <div class="single-partner-item">
        <a href="#" target="_blank" class="d-block">
            <img src="assets/img/partner/partner11.png" alt="image">
        </a>
    </div>

    <div class="single-partner-item">
        <a href="#" target="_blank" class="d-block">
            <img src="assets/img/partner/partner12.png" alt="image">
        </a>
    </div>
</div>