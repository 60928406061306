import { Component, OnInit, Inject, ElementRef, ViewChild, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Options } from 'ng5-slider';
import { ApiService } from '../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from '../../../../environments/environment';
import { MediaService } from '../../../shared/media.service';
import * as moment from 'moment';

@Component({
  selector: 'app-full-list-test',
  templateUrl: './full-list-test.component.html',
  styleUrls: ['./full-list-test.component.scss']
})
export class FullListTestComponent implements OnInit {
  public meetingsData: any = [];
  public meetingsDataRecord: any = [];
  public meetingsDataListen: any = [];
  public meetingsDataAll: any = [];
  public allmeeting: any = [];
  public inviteUrl = "";
  public frontwebsite = environment.frontUrl;
  public lessonData: any = [];
  public myId: any = ''
  public isLogin: any = 'false';
  public isCancel: any = 'false';
  // public isStudent : any 
  public testList: any = [];
  public testListPrivate: any = [];
  public testListGroup: any = [];
  public teacherTestuploadlist: any = [];
  public testYoutubePublic: any = [];
  public publicsessionData: any = [];
  public publicsessionDataRecord: any = [];
  public requestedDataList: any = [];
  public requestedDataAccpet: any = [];
  public requestedDataAccpetRecord: any = [];
  public teacherPayoutDetails: any = [];
  public mydashboardData: any = [];
  public isStudent: boolean = false;
  public isTeacher: boolean = false;
  public currentPageNumber : number = 1;
  public totalTestCount: number;
  public totalPageCount: number;
  public currentTestCount: number;
  term: string;
  public webinarData: any = [];
  isDesc: any = 1;
  column: string = 'remainingClass';
  p: number = 1;
  count: number = 4;
  teacherDetails = {};
  public categoryID = null;
  public moduleID = null;
  public courseID = null;
  public topicID = null;
  marked=false;
  // public videoList:any=[];

  constructor(private api: MediaService, private SpinnerService: NgxSpinnerService, private activatedRoute: ActivatedRoute, public location: Location, public toastr: ToastrManager, private formBuilder: FormBuilder, private rest: ApiService, private router: Router) { }

  ngOnInit() {
    this.isLogin = localStorage.getItem('isLoggedin');
    console.log(this.isLogin)
    this.isStudent = localStorage.getItem('profileType').toLowerCase() == 'student'
    this.isTeacher = localStorage.getItem('profileType').toLowerCase() == 'teacher'
    this.categoryID = this.activatedRoute.snapshot.params['categoryid'];
    this.moduleID = this.activatedRoute.snapshot.params['moduleid'];
    this.courseID = this.activatedRoute.snapshot.params['courseid'];
    this.topicID = this.activatedRoute.snapshot.params['topicid'];
    // if (this.isLogin == 'true' && this.isTeacher) {
    //   this.getTestList();
    // }
    // else if (this.isLogin == 'true' && this.isStudent) {
    //   this.getTestlistWithoutLogin();
    // }

    // else {
      this.getTestlistWithoutLogin();
    //}
  }
  toggleVisibility(e){
    this.marked= e.target.checked; 
  }

getTestList(search = null) {

    const webToken = localStorage.getItem('webtoken');

    this.SpinnerService.show();
    var request = {};
    if(this.topicID){
      request = {
        topicID: this.topicID,
        moduleID: this.moduleID, 
        courseID: this.courseID,
        categoryID: this.categoryID
      }
    }
    if(search){
      request['search'] = search;
    }
    request['limit'] = environment.listing__itemsPerPage;
    request['page'] = this.currentPageNumber;
    this.rest.testsUploadedbyTeacher(webToken, request).subscribe((response) => {
      console.log('response', response)
      
      if (response.status && response.data != null && response.data.length != 0) {
        this.testList = response.data.data;
        console.log(this.testList, 'this.webinarData')
        this.totalTestCount = response.data.totalCount;
        this.totalPageCount = Math.ceil(this.totalTestCount / environment.listing__itemsPerPage)
        this.currentTestCount = ((this.currentPageNumber - 1) * environment.listing__itemsPerPage)
        this.teacherDetails = response.data.teacherDetails;
        for(var roundTable of this.testList){
          roundTable.startDateTime = (roundTable.startDateTime)
          console.log('roundTable.startDateTime',roundTable.startDateTime);
          if(!roundTable.thumbnail){
            roundTable.thumbnail = 'assets/img/courses/courses1.jpg'
          }
        }
      } else {
        this.mydashboardData = [];
      }
      this.SpinnerService.hide()
    }, (err) => {
      console.log(err);
    });
  }

  getTestlistWithoutLogin(search = null) {
    
    this.isLogin = localStorage.getItem('isLoggedin');
    this.SpinnerService.show();
    var request = {};
    if(this.topicID){
      request = {
        topicID: this.topicID,
        moduleID: this.moduleID, 
        courseID: this.courseID,
        categoryID: this.categoryID
      }
    }
    if(search){
      request['search'] = search;
    }
    request['limit'] = environment.listing__itemsPerPage;
    request['page'] = this.currentPageNumber;
    if(this.isLogin && this.isTeacher){
      request['webToken'] = localStorage.getItem('webtoken');
    }
    this.rest.getalltestlist(request).subscribe((response) => {
      console.log('response', response)
      
      if (response.status && response.data != null) {
        this.testList = response.data.data;
        console.log(this.testList, 'this.webinarData')
        this.totalTestCount = response.data.totalCount;
        this.totalPageCount = Math.ceil(this.totalTestCount / environment.listing__itemsPerPage)
        this.currentTestCount = ((this.currentPageNumber - 1) * environment.listing__itemsPerPage)
        this.teacherDetails = response.data.teacherDetails;
        // console.log(this.testList)
        for(var roundTable of this.testList){
          roundTable.startDateTime = new Date( roundTable.startDateTime).toLocaleString()
          if(!roundTable.thumbnail){
            roundTable.thumbnail = 'assets/img/courses/courses1.jpg'
          }
        }
      } else {
        this.mydashboardData = [];
      }
      this.SpinnerService.hide()
    }, (err) => {
      console.log(err);
    });
  }
  SortDataPast(data, isOneonone) {
    var sortDate = data.sort(function (a, b) { return new Date(a.ScheduleDate).getTime() - new Date(b.ScheduleDate).getTime() });
    const now = Date.now();
    if (isOneonone) {
      const futureDates = sortDate.filter(date => {
        return (date.ScheduleDate && new Date(date.ScheduleDate).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)) || date.isrecorded;
      });
      return futureDates;
    } else {
      const futureDates = sortDate.filter(date => {
        return (date.ScheduleDate && new Date(date.ScheduleDate).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0));
      });
      return futureDates;
    }

  }
  // indexFilter(filterData){
  //   this.modelData = filterData;
  // }
  sort(property1, property2) {
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property1;
    let direction = this.isDesc ? 1 : -1;
    if (property2) {
      this.teacherPayoutDetails.sort(function (a, b) {
        if (a[property1][property2] < b[property1][property2]) {
          return -1 * direction;
        }
        else if (a[property1][property2] > b[property1][property2]) {
          return 1 * direction;
        }
        else {
          return 0;
        }
      });
    } else {
      this.teacherPayoutDetails.sort(function (a, b) {
        if (a[property1] < b[property1]) {
          return -1 * direction;
        }
        else if (a[property1] > b[property1]) {
          return 1 * direction;
        }
        else {
          return 0;
        }
      });
    }

  };
  redirect() {
    this.router.navigateByUrl('/');
  }
  testdetails(data) {
    this.router.navigateByUrl('/teacher-test-details/' + data._id);
  }
  detailsMeeting(data, pagename) {
    data.pagename = pagename;
    var jsonStr = JSON.stringify(data)
    localStorage.setItem('livedetails', jsonStr);
    console.log(data)
    if(this.isTeacher){
      this.router.navigate(['/teacher-test-details/' + data._id], { state: data });
    }
    else{
      this.router.navigate(['/test-details-student/' + data._id], { state: data });
    }
  }
  clickPageNumber(pageNumber) {
    this.currentPageNumber = pageNumber;
    console.log(this.currentPageNumber)
    // if (this.isLogin == 'true' && this.isTeacher) {
    //   this.getTestList();
    // }
    // else if (this.isLogin == 'true' && this.isStudent) {
    //   this.getTestlistWithoutLogin();
    // }

    // else {
      this.getTestlistWithoutLogin();
    //}
  }

  redirectToCreate(){
    this.router.navigate(['/teacher-test-upload'])
  }
  
  redirectshare(){

  }

  shareWhatsup(data) {
    // let frontwebsites = environment.frontUrl;
    console.log('fdfdff',data)
    window.open("https://web.whatsapp.com/send?text=https://www.buddi.life/test-details-student/" + data._id)
  }
  shareWhatsup1(data) {
    // let frontwebsites = environment.frontUrl;
    console.log('fdfdff',data)
    window.open("https://telegram.me/share/url?url=https://www.buddi.life/test-details-student/" + data._id)
  }

  addToDashboard(data){
    console.log('data',data);
    const webToken = localStorage.getItem('webtoken');
    var request = {};
    request['testID'] = data._id
    this.rest.addTestToPurchased(webToken, request).subscribe((result) => {
      console.log('result====addtodashboard',result);
      if(result.status){
        this.toastr.successToastr("Test Added to your dashboard");
        this.router.navigate(['/sidenav']);
      }
      else{
        this.toastr.errorToastr("Please login to continue this page");
        console.log(result)
      }
    }) 
  }
  dropdownChange(event){
    var filter = event.target.options[event.target.selectedIndex].value.substring(event.target.options[event.target.selectedIndex].value.indexOf(":") + 1);
    if (this.isLogin == 'true' && this.isTeacher) {
      this.getTestList(filter);
    }
    else {
      this.getTestlistWithoutLogin(filter);
    }
  }
  redirectToLoginPage(){
    this.router.navigateByUrl('/student/login');
  }
  searchClicked(){
    console.log("Clicked")
    let searchBox = (document.getElementById('searchBar') as HTMLInputElement).value
    if(searchBox != ""){ 
      if (this.isLogin == 'true' && this.isTeacher) {
        this.getTestList(searchBox);
      }
      else {
        this.getTestlistWithoutLogin(searchBox);
      }
    }
  }
}
