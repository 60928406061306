import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { transpileModule } from 'typescript';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../shared/api.service';
import { Toastr, ToastrManager } from 'ng6-toastr-notifications';
import { Location } from '@angular/common';
import { MathJaxDirective } from '../../../math-jax/math-jax.directive';
import { Directive, HostListener } from '@angular/core';

@Component({
  selector: 'app-buddi-test-page',
  templateUrl: './buddi-test-page.component.html',
  styleUrls: ['./buddi-test-page.component.scss']
})
export class BuddiTestPageComponent implements OnInit {


  @ViewChild('mathjax', { read: MathJaxDirective, static: true })
  mathjax: MathJaxDirective;
  selectedoptionform: FormGroup;
  selectedimageform: FormGroup;
  imagecheckboxform: FormGroup;
  public TestlinkID: any;
  fileList: File[] = [];
  formDatasubjective;
  public TestData: any = [];
  time: number = 0;
  timegiven: number = 0;
  totaltime: number = 0;
  public testid: any;
  display;
  public answerimage: any = [];
  interval;
  images = [];
  uploadingimages = [];
  public isstudent = false;
  selectedFileNmae: string = 'Choose File';
  public isteacher = false;
  public isinstruction = true;
  public isquestion = false;
  public isoption1 = false;
  public isoption2 = false;
  public isquestiontype3: boolean = false;
  public showlastmodal: boolean = false;
  public showquestions: boolean = true;
  public uploadimage: boolean = false;
  public isimagecheckbox: boolean = false;
  public isoption3 = false;
  public isoption4 = false;
  public subjectnameasstring: string = "";
  public result1: any;
  public answerlist: any = [];
  public questioncount: number = 0;
  public answeredcount: number = 0;
  public totalquestioncount: number = 0;
  public unansweredcount: number = 0;
  public reviewedcount: number = 0;
  public subjectlength: number = 0;
  public showimageinmodal = "";
  public subjectname1 = "";
  public subjectname2 = "";
  public subjectname3 = "";
  public subjectname4 = "";
  public previousselect = "";
  public subjectname1array: any = [];
  public subjectname2array: any = [];
  public subjectname3array: any = [];
  public subjectname4array: any = [];
  public showModalBox: boolean = false;
  public ischecked: boolean = false;
  public choisequestion: String = "";
  public samplequestion: String = ""
  public sdasd: String = ""
  public deductionmarks;
  public correctmarks;
  public subjectname1arrowup: boolean = true;
  public subjectname1arrowdown: boolean = false;
  public subjectname1arrayquestion: boolean = true;
  public subjectname2arrowup: boolean = false;
  public subjectname2arrowdown: boolean = true;
  public subjectname2arrayquestion: boolean = false;
  public subjectname3arrowup: boolean = false;
  public subjectname3arrowdown: boolean = true;
  public subjectname3arrayquestion: boolean = false;
  public subjectname4arrowup: boolean = false;
  public subjectname4arrowdown: boolean = true;
  public subjectname4arrayquestion: boolean = false;
  public subjectname1arraylength: any;
  public subjectname2arraylength: any;
  public subjectname3arraylength: any;
  public subjectname4arraylength: any;
  public currentsubjectname: any;
  public endtestresult = "";

  public containsImage = false;
  constructor(private location: Location, private formBuilder: FormBuilder, private my_router: ActivatedRoute, public toastr: ToastrManager, private rest: ApiService, private router: Router) { }
  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }


  ngOnInit(): void {

    this.samplequestion = "What is the value of \\( 1+6+\\frac{9 \\left( 1^{2}+2^{2}+3^{2} \\right) }{7}+\\frac{12 \\left( 1^{2}+2^{2}+3^{2}+4^{2} \\right) }{9}+\\frac{12 \\left( 1^{2}+2^{2}+3^{2}+4^{2}+5^{2} \\right) }{11}+ \\ldots \\) to 15 places";

    this.TestlinkID = this.my_router.snapshot.params['id'];
    this.selectedoptionform = this.formBuilder.group({
      option1: [''],
      option2: [''],
      option3: [''],
      option4: [''],
      upload_name: [null],
      fileupload: [''],
      questiontype3text: ['']
    });
    this.selectedimageform = this.formBuilder.group({
      //   file: ['', [Validators.required]],
      // fileSource: ['', [Validators.required]],
      answerImage: [''],
      id: [''],
      upload_name: [null],
    });

    this.imagecheckboxform = this.formBuilder.group({
      imagecheckbox: ['']
    });

    var usertype = localStorage.getItem('profileType');
    if (usertype.toLowerCase() == "teacher") {
      this.isteacher = true;
    }
    else {
      this.isstudent = true;
    }

    this.loadpage();

  }

  // convenience getter for easy access to form fields
  get f() { return this.selectedoptionform.controls; }

  loadpage() {
    var formData = {};
    formData["testID"] = this.TestlinkID
    formData["studentID"] = localStorage.getItem('StudentID');
    this.rest.getTestsdetails(formData).subscribe((result) => {

      this.TestData = result.data;
      this.totaltime = result.data.totalTime;
      this.subjectlength = result.data.subject.length;

      for (var i = 0; i < this.TestData.subject.length; i++) {
        if (this.subjectnameasstring == "") {
          this.subjectnameasstring = this.TestData.subject[i].subjectName
        }
        else {
          this.subjectnameasstring = this.subjectnameasstring + "," + this.TestData.subject[i].subjectName
        }
      }
    }, (err) => {
      console.log(err);
    });

    // console.log("testdata", this.TestData);


    this.isinstruction = true;
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.time === 0) {
        this.pauseTimer();
        this.endtest();
      } else {
        this.time--;
      }
      // this.display = this.transform(this.time)
      const minutes: number = Math.floor(this.time / 60);
      this.display = ('00' + minutes).slice(-2) + ':' + ('00' + Math.floor(this.time - minutes * 60)).slice(-2);
    }, 1000);
  }

  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return minutes + ':' + (value - minutes * 60);
  }

  pauseTimer() {
    clearInterval(this.interval);
  }

  loaddata() {
    var token = localStorage.getItem('webtoken');
    var formData = {};
    var regexSpace = /\ /gi;
    formData["id"] = this.TestlinkID
    this.rest.starttest(formData, token).subscribe((result) => {
      this.answerlist = result.data.answerList;
      this.totalquestioncount = this.answerlist.length;
      if (result.data.sectionFormat == "Marks") {
        const distinctmarks = this.answerlist.filter(
          (thing, i, arr) => arr.findIndex(t => t.correctAnswerMarks === thing.correctAnswerMarks) === i
        );
        console.log("marks", distinctmarks);
        for (var i = 0; i < distinctmarks.length; i++) {
          if (i == 0) {
            this.subjectname1 = distinctmarks[i].correctAnswerMarks;
          }
          else if (i == 1) {
            this.subjectname2 = distinctmarks[i].correctAnswerMarks;
          }
          else if (i == 2) {
            this.subjectname3 = distinctmarks[i].correctAnswerMarks;
          }
          else if (i == 3) {
            this.subjectname4 = distinctmarks[i].correctAnswerMarks;
          }
        }

        for (var i = 0; i < this.answerlist.length; i++) {
          if (this.answerlist[i].correctAnswerMarks == this.subjectname1) {
            this.subjectname1array.push(this.answerlist[i]);
          }
          else if (this.answerlist[i].correctAnswerMarks == this.subjectname2) {
            this.subjectname2array.push(this.answerlist[i]);
          }
          else if (this.answerlist[i].correctAnswerMarks == this.subjectname3) {
            this.subjectname3array.push(this.answerlist[i]);
          }
          else if (this.answerlist[i].correctAnswerMarks == this.subjectname4) {
            this.subjectname4array.push(this.answerlist[i]);
          }
        }

        this.subjectname1arraylength = this.subjectname1array.length;
        this.subjectname2arraylength = this.subjectname2array.length;
        this.subjectname3arraylength = this.subjectname3array.length;
        this.subjectname4arraylength = this.subjectname4array.length;

        this.subjectname1 = this.subjectname1 + " Marks"
        this.subjectname2 = this.subjectname2 + " Marks"
        this.subjectname3 = this.subjectname3 + " Marks"
        this.subjectname4 = this.subjectname4 + " Marks"
      }
      else {
        const distinctsubjects = this.answerlist.filter(
          (thing, i, arr) => arr.findIndex(t => t.subject === thing.subject) === i
        );

        for (var i = 0; i < distinctsubjects.length; i++) {
          if (i == 0) {
            this.subjectname1 = distinctsubjects[i].subject;
          }
          else if (i == 1) {
            this.subjectname2 = distinctsubjects[i].subject;
          }
          else if (i == 2) {
            this.subjectname3 = distinctsubjects[i].subject;
          }
          else if (i == 3) {
            this.subjectname4 = distinctsubjects[i].subject;
          }
        }

        for (var i = 0; i < this.answerlist.length; i++) {
          if (this.answerlist[i].subject == this.subjectname1) {
            this.subjectname1array.push(this.answerlist[i]);
          }
          else if (this.answerlist[i].subject == this.subjectname2) {
            this.subjectname2array.push(this.answerlist[i]);
          }
          else if (this.answerlist[i].subject == this.subjectname3) {
            this.subjectname3array.push(this.answerlist[i]);
          }
          else if (this.answerlist[i].subject == this.subjectname4) {
            this.subjectname4array.push(this.answerlist[i]);
          }
        }

        this.subjectname1arraylength = this.subjectname1array.length;
        this.subjectname2arraylength = this.subjectname2array.length;
        this.subjectname3arraylength = this.subjectname3array.length;
        this.subjectname4arraylength = this.subjectname4array.length;
      }



      // for(var i = 0; i < this.answerlist.length; i++ ){
      //   console.log(this.answerlist[i])
      // }

      for (var i = 0; i < this.answerlist.length; i++) {
        if (this.answerlist[i].questionText.replace(regexSpace, "").includes("<i=") && this.answerlist[i].questionText.includes(">")) {
          var tempString = this.answerlist[i].questionText;
          var listOfMatches = [...this.answerlist[i].questionText.matchAll('<.*?>')];
          this.answerlist[i]["images"] = [];
          for (var j = 0; j < listOfMatches.length; j++) {
            var startIndex = j == 0 ? 0 : listOfMatches[j - 1]["index"] + listOfMatches[j - 1][0].length;
            this.answerlist[i]["images"].push(tempString.substring(startIndex, listOfMatches[j]["index"]))
            console.log(listOfMatches[j][0].replace(" ", ""))
            this.answerlist[i]["images"].push("https://s3.ap-south-1.amazonaws.com/buddi.bucket/budditests/" + listOfMatches[j][0].replace(regexSpace, "").replace("<i=", "").replace(">", ""));
            if (j == listOfMatches.length - 1) {
              this.answerlist[i]["images"].push(tempString.substring(listOfMatches[j]["index"] + 1 + listOfMatches[j][0].length, tempString.length))
            }
          }
          console.log("hello", this.answerlist[i])
          // this.answerlist[i]["imagePath"] = "https://s3.ap-south-1.amazonaws.com/buddi.bucket/budditests/" + tempString.substring(tempString.indexOf("<") + 1, tempString.indexOf(">")).split("=")[1];
          // this.answerlist[i]["beforeImage"] = tempString.substring(0, tempString.indexOf("<"))
          // this.answerlist[i]["afterImage"] = tempString.substring(tempString.indexOf(">") + 1, tempString.length)
        }
        if (this.answerlist[i].option1 !== undefined) {
          if (this.answerlist[i].option1.replace(regexSpace, "").includes("<i=") && this.answerlist[i].option1.includes(">")) {
            var tempString = this.answerlist[i].option1;
            var listOfMatches = [...this.answerlist[i].option1.matchAll('<.*?>')];
            this.answerlist[i]["option1Images"] = [];
            for (var j = 0; j < listOfMatches.length; j++) {
              var startIndex = j == 0 ? 0 : listOfMatches[j - 1]["index"] + listOfMatches[j - 1][0].length;
              this.answerlist[i]["option1Images"].push(tempString.substring(startIndex, listOfMatches[j]["index"]))
              this.answerlist[i]["option1Images"].push("https://s3.ap-south-1.amazonaws.com/buddi.bucket/budditests/" + listOfMatches[j][0].replace(regexSpace, "").replace(" ", "").replace("<i=", "").replace(">", ""));
              if (j == listOfMatches.length - 1) {
                this.answerlist[i]["option1Images"].push(tempString.substring(listOfMatches[j]["index"] + 1 + listOfMatches[j][0].length, tempString.length))
              }
            }
          }
        }

        if (this.answerlist[i].option2 !== undefined) {
          if (this.answerlist[i].option2.replace(regexSpace, "").includes("<i=") && this.answerlist[i].option2.includes(">")) {
            var tempString = this.answerlist[i].option2;
            var listOfMatches = [...this.answerlist[i].option2.matchAll('<.*?>')];
            this.answerlist[i]["option2Images"] = [];
            for (var j = 0; j < listOfMatches.length; j++) {
              var startIndex = j == 0 ? 0 : listOfMatches[j - 1]["index"] + listOfMatches[j - 1][0].length;
              this.answerlist[i]["option2Images"].push(tempString.substring(startIndex, listOfMatches[j]["index"]))
              this.answerlist[i]["option2Images"].push("https://s3.ap-south-1.amazonaws.com/buddi.bucket/budditests/" + listOfMatches[j][0].replace(regexSpace, "").replace("<i=", "").replace(">", ""));
              if (j == listOfMatches.length - 1) {
                this.answerlist[i]["option2Images"].push(tempString.substring(listOfMatches[j]["index"] + 1 + listOfMatches[j][0].length, tempString.length))
              }
            }
          }
        }

        if (this.answerlist[i].option3 !== undefined) {
          if (this.answerlist[i].option3.replace(regexSpace, "").includes("<i=") && this.answerlist[i].option3.includes(">")) {
            var tempString = this.answerlist[i].option3;
            var listOfMatches = [...this.answerlist[i].option3.matchAll('<.*?>')];
            this.answerlist[i]["option3Images"] = [];
            for (var j = 0; j < listOfMatches.length; j++) {
              var startIndex = j == 0 ? 0 : listOfMatches[j - 1]["index"] + listOfMatches[j - 1][0].length;
              this.answerlist[i]["option3Images"].push(tempString.substring(startIndex, listOfMatches[j]["index"]))
              this.answerlist[i]["option3Images"].push("https://s3.ap-south-1.amazonaws.com/buddi.bucket/budditests/" + listOfMatches[j][0].replace(regexSpace, "").replace("<i=", "").replace(">", ""));
              if (j == listOfMatches.length - 1) {
                this.answerlist[i]["option3Images"].push(tempString.substring(listOfMatches[j]["index"] + 1 + listOfMatches[j][0].length, tempString.length))
              }
            }
          }
        }

        if (this.answerlist[i].option4 !== undefined) {
          if (this.answerlist[i].option4.replace(regexSpace, "").includes("<i=") && this.answerlist[i].option4.includes(">")) {
            var tempString = this.answerlist[i].option4;
            var listOfMatches = [...this.answerlist[i].option4.matchAll('<.*?>')];
            this.answerlist[i]["option4Images"] = [];
            for (var j = 0; j < listOfMatches.length; j++) {
              var startIndex = j == 0 ? 0 : listOfMatches[j - 1]["index"] + listOfMatches[j - 1][0].length;
              this.answerlist[i]["option4Images"].push(tempString.substring(startIndex, listOfMatches[j]["index"]))
              this.answerlist[i]["option4Images"].push("https://s3.ap-south-1.amazonaws.com/buddi.bucket/budditests/" + listOfMatches[j][0].replace(regexSpace, "").replace("<i=", "").replace(">", ""));
              if (j == listOfMatches.length - 1) {
                this.answerlist[i]["option4Images"].push(tempString.substring(listOfMatches[j]["index"] + 1 + listOfMatches[j][0].length, tempString.length))
              }
            }
          }
        }

      }

      this.answeredcount = this.answerlist.filter(item => item.status === 'Answered').length;
      this.unansweredcount = this.answerlist.filter(item => item.status === 'Unanswered').length;
      this.reviewedcount = this.answerlist.filter(item => item.status === 'Reviewed').length;
      this.testid = result.data._id;
      this.oninitdata(result.data.currentTime);
      console.log("question", this.answerlist);

    }, (err) => {
      console.log(err);
    });
  }

  questionnumberbutton(count) {
    const countquestion = count - 1;
    this.deductionmarks = this.answerlist[countquestion].incorrectAnswerMarks;
    this.correctmarks = this.answerlist[countquestion].correctAnswerMarks;
    if (this.answerlist[countquestion].questionType == 3) {
      if (this.answerlist[countquestion].studentAnswer == "<image>") {
        this.isimagecheckbox = true;
        this.selectedoptionform.controls['questiontype3text'].reset();
      }
      else {
        this.isimagecheckbox = false;
        this.selectedoptionform.patchValue({ questiontype3text: this.answerlist[countquestion].studentAnswer });
      }
    }
    else {
      this.selectedoptionform.controls['option1'].reset();
      this.selectedoptionform.controls['option2'].reset();
      this.selectedoptionform.controls['option3'].reset();
      this.selectedoptionform.controls['option4'].reset();
      this.selectedoptionform.controls['questiontype3text'].reset();


      if (this.answerlist[countquestion].numberOfOptions > 1) {
        if (this.answerlist[countquestion].studentAnswer != null) {
          for (var i = 0; i < this.answerlist[countquestion].studentAnswer.length; i++) {
            if (this.answerlist[countquestion].studentAnswer[i] == "1") {
              setTimeout(() => {
                this.isoption1 = true;
              },
                500);
            }
            else if (this.answerlist[countquestion].studentAnswer[i] == "2") {
              setTimeout(() => {
                this.isoption2 = true;
              },
                500);
            }
            else if (this.answerlist[countquestion].studentAnswer[i] == "3") {
              setTimeout(() => {
                this.isoption3 = true;
              },
                500);
            }
            else if (this.answerlist[countquestion].studentAnswer[i] == "4") {
              setTimeout(() => {
                this.isoption4 = true;
              },
                500);
            }
          }
        }
        if (this.answerlist[countquestion].correctAnswer.length == 1) {
          this.choisequestion = "Single Choice Question"
        }
        else {
          this.choisequestion = "Multiple Choice Question"
        }
      }
      else {
        if (this.answerlist[countquestion].correctAnswer == "") {
          this.choisequestion = "Subjective Type Question"
        }
        else {
          this.choisequestion = "Single Choice Question"
        }

        if (this.answerlist[countquestion].studentAnswer != null) {
          if (this.answerlist[countquestion].studentAnswer[0] == "1") {
            setTimeout(() => {
              this.isoption1 = true;
            },
              500);
          }
          else if (this.answerlist[countquestion].studentAnswer[0] == "2") {
            setTimeout(() => {
              this.isoption2 = true;
            },
              500);
          }
          else if (this.answerlist[countquestion].studentAnswer[0] == "3") {
            setTimeout(() => {
              this.isoption3 = true;
            },
              500);
          }
          else if (this.answerlist[countquestion].studentAnswer[0] == "4") {
            setTimeout(() => {
              this.isoption4 = true;
            },
              500);
          }
        }
      }
    }


    let assigndata: any = {};

    this.questioncount = countquestion;
    // document.getElementById("mathjax").innerHTML = this.answerlist[this.questioncount].questionText;
    // MathJax.Hub.Queue(["Typeset",MathJax.Hub]);
    // document.getElementById("mathjax").innerHTML = this.answerlist[this.questioncount].questionText;
    //  this.answerlist = result.data.answerList;

    // document.getElementById("mathdiv").innerHTML = this.answerlist[this.questioncount].questionText;
    // MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
    // if (this.answerlist[countquestion].option1 !== undefined || this.answerlist[countquestion].option1 != "") {
    //   document.getElementById("mathone").innerHTML = this.answerlist[countquestion].option1;
    //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
    // }
    // if (this.answerlist[countquestion].option2 !== undefined || this.answerlist[countquestion].option2 != "") {
    //   document.getElementById("mathtwo").innerHTML = this.answerlist[countquestion].option2;
    //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
    // }
    // if (this.answerlist[countquestion].option3 !== undefined || this.answerlist[countquestion].option3 != "") {
    //   document.getElementById("maththree").innerHTML = this.answerlist[countquestion].option3;
    //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
    // }
    // if (this.answerlist[countquestion].option4 !== undefined || this.answerlist[countquestion].option4 != "") {
    //   document.getElementById("mathfour").innerHTML = this.answerlist[countquestion].option4;
    //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
    // }
    if (this.answerlist[this.questioncount].images == undefined) {
      document.getElementById("mathdiv").innerHTML = this.answerlist[this.questioncount].questionText;
      MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
    }
    else {
      for (var i = 0; i < this.answerlist[this.questioncount].images.length; i++) {
        if (!this.answerlist[this.questioncount].images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
          console.log(this.answerlist[this.questioncount].images[i])
          document.getElementById("mathdiv").innerHTML = this.answerlist[this.questioncount].images[i];
          MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
        }

      }
    }

    if (this.answerlist[this.questioncount].option1 !== undefined || this.answerlist[this.questioncount].option1 != "") {
      if (this.answerlist[this.questioncount].option1Images == undefined) {
        document.getElementById("mathone").innerHTML = this.answerlist[countquestion].option1;
        MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
      }
      else {
        for (var i = 0; i < this.answerlist[this.questioncount].option1Images.length; i++) {
          if (!this.answerlist[this.questioncount].option1Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
            console.log(this.answerlist[this.questioncount].option1Images[i])
            document.getElementById("mathone").innerHTML = this.answerlist[this.questioncount].option1Images[i];
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
          }

        }
      }
    }
    if (this.answerlist[this.questioncount].option2 !== undefined || this.answerlist[this.questioncount].option2 != "") {
      if (this.answerlist[this.questioncount].option2Images == undefined) {
        document.getElementById("mathtwo").innerHTML = this.answerlist[countquestion].option2;
        MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
      }
      else {
        for (var i = 0; i < this.answerlist[this.questioncount].option2Images.length; i++) {
          if (!this.answerlist[this.questioncount].option2Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
            console.log(this.answerlist[this.questioncount].option2Images[i])
            document.getElementById("mathtwo").innerHTML = this.answerlist[this.questioncount].option2Images[i];
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
          }

        }
      }
    }
    if (this.answerlist[this.questioncount].option3 !== undefined || this.answerlist[this.questioncount].option3 != "") {
      if (this.answerlist[this.questioncount].option3Images == undefined) {
        document.getElementById("maththree").innerHTML = this.answerlist[countquestion].option3;
        MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
      }
      else {
        for (var i = 0; i < this.answerlist[this.questioncount].option3Images.length; i++) {
          if (!this.answerlist[this.questioncount].option3Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
            console.log(this.answerlist[this.questioncount].option3Images[i])
            document.getElementById("maththree").innerHTML = this.answerlist[this.questioncount].option3Images[i];
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
          }

        }
      }
    }
    if (this.answerlist[this.questioncount].option4 !== undefined || this.answerlist[this.questioncount].option4 != "") {
      if (this.answerlist[this.questioncount].option4Images == undefined) {
        document.getElementById("mathfour").innerHTML = this.answerlist[countquestion].option4;
        MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
      }
      else {
        for (var i = 0; i < this.answerlist[this.questioncount].option4Images.length; i++) {
          if (!this.answerlist[this.questioncount].option4Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
            console.log(this.answerlist[this.questioncount].option4Images[i])
            document.getElementById("mathfour").innerHTML = this.answerlist[this.questioncount].option4Images[i];
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
          }

        }
      }
    }
    //  var math = MathJax.Hub.getAllJax("mathdiv")[0];
    // console.log("math", math);
    //MathJax.Hub.Queue(["Text", math,this.answerlist[this.questioncount].questionText]);

    //     alert(this.questioncount);
    //     alert(this.subjectname2arraylength);
    // if(this.questioncount + 1 <= this.subjectname1arraylength) {
    // this.currentsubjectname = this.subjectname1;
    // }

    // if(this.questioncount + 1 <= this.subjectname2arraylength + this.subjectname1arraylength  && this.questioncount > this.subjectname1arraylength ) {
    //   this.currentsubjectname = this.subjectname2;
    //   }

    //   if(this.questioncount + 1 <= this.subjectname2arraylength + this.subjectname1arraylength + this.subjectname3arraylength  && this.questioncount > this.subjectname1arraylength + this.subjectname2arraylength ) {
    //     this.currentsubjectname = this.subjectname3;
    //     }

    //     if(this.questioncount + 1 <= this.subjectname2arraylength + this.subjectname1arraylength + this.subjectname3arraylength + this.subjectname4arraylength && this.questioncount > this.subjectname1arraylength + this.subjectname2arraylength + this.subjectname3arraylength ) {
    //       this.currentsubjectname = this.subjectname4;
    //       }

  }

  onSubmit(FormGroup) {
    var token = localStorage.getItem('webtoken');
    const selectedanswer = [];
    const data = this.selectedoptionform.value;
    const count = this.questioncount;
    var formData = {};
    this.formDatasubjective = new FormData();
    // condition if question type = 3 or subjective
    if (this.answerlist[count].questionType == 3) {

      /* --- single image upload condition below -- */

      // if (data.upload_name != null) {
      //   this.isquestiontype3 = true;
      //   let imagename = "<i=" + data.upload_name.name + ">";
      //   this.formDatasubjective.append("id", this.testid);
      //   this.formDatasubjective.append("answerImage", data.upload_name, imagename)

      //   this.answerlist.find(item => item.questionNumber == this.questioncount + 1).studentAnswer = [];
      //   this.answerlist.find(item => item.questionNumber == this.questioncount + 1).studentAnswer = imagename;
      //   this.answerlist.find(item => item.questionNumber == this.questioncount + 1).status = "Answered";
      // }

      /* return if both textbox and checkbox has values */

      if (this.isimagecheckbox == true) {
        this.answerlist.find(item => item.questionNumber == this.questioncount + 1).studentAnswer = [];
        this.answerlist.find(item => item.questionNumber == this.questioncount + 1).studentAnswer = "<image>";
        this.answerlist.find(item => item.questionNumber == this.questioncount + 1).status = "Answered";
      }
      else if (data.questiontype3text == undefined || data.questiontype3text == null || data.questiontype3text == "") {
        this.answerlist.find(item => item.questionNumber == this.questioncount + 1).studentAnswer = [];
        this.answerlist.find(item => item.questionNumber == this.questioncount + 1).status = "Unanswered";
      }
      else {
        this.answerlist.find(item => item.questionNumber == this.questioncount + 1).studentAnswer = [];
        this.answerlist.find(item => item.questionNumber == this.questioncount + 1).studentAnswer = data.questiontype3text;
        this.answerlist.find(item => item.questionNumber == this.questioncount + 1).status = "Answered";
      }


      /* single image upload api code */
      // formData["answerList"] = this.answerlist;
      // formData["id"] = this.testid;
      // formData["currentTime"] = (this.time).toFixed(0);
      // if (this.isquestiontype3 == true) {
      //   this.rest.updatetestimage(this.formDatasubjective, token).subscribe((result) => {
      //     if (result.message.toLowerCase() == "uploaded successfully") {
      //       data.upload_name = null;
      //       this.selectedFileNmae = "Choose File"
      //       this.isquestiontype3 = false;
      //       this.selectedoptionform.controls['option1'].reset();
      //       this.selectedoptionform.controls['option2'].reset();
      //       this.selectedoptionform.controls['option3'].reset();
      //       this.selectedoptionform.controls['option4'].reset();
      //       this.selectedoptionform.controls['questiontype3text'].reset();
      //       this.deductionmarks = this.answerlist[count + 1].incorrectAnswerMarks;
      //       this.correctmarks = this.answerlist[count + 1].correctAnswerMarks;
      //       if (count + 1 == this.totalquestioncount - 1) {
      //         this.showlastmodal = true;
      //         this.showquestions = false;
      //       }
      //       // if (this.answerlist[count + 1].option1 !== undefined || this.answerlist[count + 1].option1 != "") {
      //       //   document.getElementById("mathone").innerHTML = this.answerlist[count + 1].option1;
      //       //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
      //       // }
      //       // if (this.answerlist[count + 1].option2 !== undefined || this.answerlist[count + 1].option2 != "") {
      //       //   document.getElementById("mathtwo").innerHTML = this.answerlist[count + 1].option2;
      //       //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
      //       // }
      //       // if (this.answerlist[count + 1].option3 !== undefined || this.answerlist[count + 1].option3 != "") {
      //       //   document.getElementById("maththree").innerHTML = this.answerlist[count + 1].option3;
      //       //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
      //       // }
      //       // if (this.answerlist[count + 1].option4 !== undefined || this.answerlist[count + 1].option4 != "") {
      //       //   document.getElementById("mathfour").innerHTML = this.answerlist[count + 1].option4;
      //       //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
      //       // }
      //       if (this.answerlist[count + 1].option1 !== undefined || this.answerlist[count + 1].option1 != "") {
      //         if (this.answerlist[count + 1].option1Images == undefined) {
      //           document.getElementById("mathone").innerHTML = this.answerlist[count + 1].option1;
      //           MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
      //         }
      //         else {
      //           for (var i = 0; i < this.answerlist[count + 1].option1Images.length; i++) {
      //             if (!this.answerlist[count + 1].option1Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
      //               console.log(this.answerlist[count + 1].option1Images[i])
      //               document.getElementById("mathone").innerHTML = this.answerlist[count + 1].option1Images[i];
      //               MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
      //             }

      //           }
      //         }
      //       }
      //       if (this.answerlist[count + 1].option2 !== undefined || this.answerlist[count + 1].option2 != "") {
      //         if (this.answerlist[count + 1].option2Images == undefined) {
      //           document.getElementById("mathtwo").innerHTML = this.answerlist[count + 1].option2;
      //           MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
      //         }
      //         else {
      //           for (var i = 0; i < this.answerlist[count + 1].option2Images.length; i++) {
      //             if (!this.answerlist[count + 1].option2Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
      //               console.log(this.answerlist[count + 1].option2Images[i])
      //               document.getElementById("mathtwo").innerHTML = this.answerlist[count + 1].option2Images[i];
      //               MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
      //             }
      //           }
      //         }
      //       }
      //       if (this.answerlist[count + 1].option3 !== undefined || this.answerlist[count + 1].option3 != "") {
      //         if (this.answerlist[count + 1].option3Images == undefined) {
      //           document.getElementById("maththree").innerHTML = this.answerlist[count + 1].option3;
      //           MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
      //         }
      //         else {
      //           for (var i = 0; i < this.answerlist[count + 1].option3Images.length; i++) {
      //             if (!this.answerlist[count + 1].option3Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
      //               console.log(this.answerlist[count + 1].option3Images[i])
      //               document.getElementById("maththree").innerHTML = this.answerlist[count + 1].option3Images[i];
      //               MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
      //             }

      //           }
      //         }
      //       }
      //       if (this.answerlist[count + 1].option4 !== undefined || this.answerlist[count + 1].option4 != "") {
      //         if (this.answerlist[count + 1].option4Images == undefined) {
      //           document.getElementById("mathfour").innerHTML = this.answerlist[count + 1].option4;
      //           MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
      //         }
      //         else {
      //           for (var i = 0; i < this.answerlist[count + 1].option4Images.length; i++) {
      //             if (!this.answerlist[count + 1].option4Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
      //               console.log(this.answerlist[count + 1].option4Images[i])
      //               document.getElementById("mathfour").innerHTML = this.answerlist[count + 1].option4Images[i];
      //               MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
      //             }

      //           }
      //         }
      //       }

      //       if (this.answerlist[count + 1].numberOfOptions > 1) {
      //         if (this.answerlist[count + 1].studentAnswer != null) {
      //           for (var i = 0; i < this.answerlist[count + 1].studentAnswer.length; i++) {
      //             if (this.answerlist[count + 1].studentAnswer[i] == "1") {
      //               this.isoption1 = true;
      //             }
      //             else if (this.answerlist[count + 1].studentAnswer[i] == "2") {
      //               this.isoption2 = true;
      //             }
      //             else if (this.answerlist[count + 1].studentAnswer[i] == "3") {
      //               this.isoption3 = true;
      //             }
      //             else if (this.answerlist[count + 1].studentAnswer[i] == "4") {
      //               this.isoption4 = true;
      //             }
      //           }
      //         }
      //       }
      //       else {
      //         if (this.answerlist[count + 1].studentAnswer != null) {
      //           if (this.answerlist[count + 1].studentAnswer[0] == "1") {
      //             this.isoption1 = true;
      //           }
      //           else if (this.answerlist[count + 1].studentAnswer[0] == "2") {
      //             this.isoption2 = true;
      //           }
      //           else if (this.answerlist[count + 1].studentAnswer[0] == "3") {
      //             this.isoption3 = true;
      //           }
      //           else if (this.answerlist[count + 1].studentAnswer[0] == "4") {
      //             this.isoption4 = true;
      //           }
      //         }
      //       }

      //       this.answeredcount = this.answerlist.filter(item => item.status === 'Answered').length;
      //       this.unansweredcount = this.answerlist.filter(item => item.status === 'Unanswered').length;
      //       this.reviewedcount = this.answerlist.filter(item => item.status === 'Reviewed').length;
      //       console.log("answerlist", this.answerlist);
      //       if (this.answerlist[count + 1].images == undefined) {
      //         document.getElementById("mathdiv").innerHTML = this.answerlist[count + 1].questionText;
      //         MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
      //       }
      //       else {
      //         for (var i = 0; i < this.answerlist[count + 1].images.length; i++) {
      //           if (!this.answerlist[count + 1].images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
      //             console.log(this.answerlist[count + 1].images[i])
      //             document.getElementById("mathdiv").innerHTML = this.answerlist[count + 1].images[i];
      //             MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
      //           }

      //         }
      //       }
      //     }
      //     else {
      //       this.toastr.errorToastr(result.message);
      //     }
      //   }, (err) => {
      //     console.log(err);
      //   });
      // }
      formData["answerList"] = this.answerlist;
      formData["id"] = this.testid;
      formData["currentTime"] = (this.time).toFixed(0);
      this.rest.updatetest(formData, token).subscribe((result) => {
        if (result.message.toLowerCase() == "saved") {
          data.upload_name = null;
          this.selectedFileNmae = "Choose File"
          this.isquestiontype3 = false;
          this.deductionmarks = this.answerlist[count + 1].incorrectAnswerMarks;
          this.correctmarks = this.answerlist[count + 1].correctAnswerMarks;

          if (this.answerlist[count + 1].questionType == 3) {
            if (this.answerlist[count + 1].studentAnswer == "<image>") {
              this.isimagecheckbox = true;
              this.selectedoptionform.controls['questiontype3text'].reset();
            }
            else {
              this.isimagecheckbox = false;
              this.selectedoptionform.controls['questiontype3text'].reset();
              this.selectedoptionform.patchValue({ questiontype3text: this.answerlist[count + 1].studentAnswer });
            }
          }
          else {
            this.selectedoptionform.controls['option1'].reset();
            this.selectedoptionform.controls['option2'].reset();
            this.selectedoptionform.controls['option3'].reset();
            this.selectedoptionform.controls['option4'].reset();

            if (count + 1 == this.totalquestioncount - 1) {
              this.showlastmodal = true;
              this.showquestions = false;
            }
            // if (this.answerlist[count + 1].option1 !== undefined || this.answerlist[count + 1].option1 != "") {
            //   document.getElementById("mathone").innerHTML = this.answerlist[count + 1].option1;
            //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
            // }
            // if (this.answerlist[count + 1].option2 !== undefined || this.answerlist[count + 1].option2 != "") {
            //   document.getElementById("mathtwo").innerHTML = this.answerlist[count + 1].option2;
            //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
            // }
            // if (this.answerlist[count + 1].option3 !== undefined || this.answerlist[count + 1].option3 != "") {
            //   document.getElementById("maththree").innerHTML = this.answerlist[count + 1].option3;
            //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
            // }
            // if (this.answerlist[count + 1].option4 !== undefined || this.answerlist[count + 1].option4 != "") {
            //   document.getElementById("mathfour").innerHTML = this.answerlist[count + 1].option4;
            //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
            // }
            if (this.answerlist[count + 1].option1 !== undefined || this.answerlist[count + 1].option1 != "") {
              if (this.answerlist[count + 1].option1Images == undefined) {
                document.getElementById("mathone").innerHTML = this.answerlist[count + 1].option1;
                MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
              }
              else {
                for (var i = 0; i < this.answerlist[count + 1].option1Images.length; i++) {
                  if (!this.answerlist[count + 1].option1Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
                    console.log(this.answerlist[count + 1].option1Images[i])
                    document.getElementById("mathone").innerHTML = this.answerlist[count + 1].option1Images[i];
                    MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
                  }

                }
              }
            }
            if (this.answerlist[count + 1].option2 !== undefined || this.answerlist[count + 1].option2 != "") {
              if (this.answerlist[count + 1].option2Images == undefined) {
                document.getElementById("mathtwo").innerHTML = this.answerlist[count + 1].option2;
                MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
              }
              else {
                for (var i = 0; i < this.answerlist[count + 1].option2Images.length; i++) {
                  if (!this.answerlist[count + 1].option2Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
                    console.log(this.answerlist[count + 1].option2Images[i])
                    document.getElementById("mathtwo").innerHTML = this.answerlist[count + 1].option2Images[i];
                    MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
                  }

                }
              }
            }
            if (this.answerlist[count + 1].option3 !== undefined || this.answerlist[count + 1].option3 != "") {
              if (this.answerlist[count + 1].option3Images == undefined) {
                document.getElementById("maththree").innerHTML = this.answerlist[count + 1].option3;
                MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
              }
              else {
                for (var i = 0; i < this.answerlist[count + 1].option3Images.length; i++) {
                  if (!this.answerlist[count + 1].option3Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
                    console.log(this.answerlist[count + 1].option3Images[i])
                    document.getElementById("maththree").innerHTML = this.answerlist[count + 1].option3Images[i];
                    MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
                  }

                }
              }
            }
            if (this.answerlist[count + 1].option4 !== undefined || this.answerlist[count + 1].option4 != "") {
              if (this.answerlist[count + 1].option4Images == undefined) {
                document.getElementById("mathfour").innerHTML = this.answerlist[count + 1].option4;
                MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
              }
              else {
                for (var i = 0; i < this.answerlist[count + 1].option4Images.length; i++) {
                  if (!this.answerlist[count + 1].option4Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
                    console.log(this.answerlist[count + 1].option4Images[i])
                    document.getElementById("mathfour").innerHTML = this.answerlist[count + 1].option4Images[i];
                    MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
                  }

                }
              }
            }

            if (this.answerlist[count + 1].numberOfOptions > 1) {
              if (this.answerlist[count + 1].studentAnswer != null) {
                for (var i = 0; i < this.answerlist[count].studentAnswer.length; i++) {
                  if (this.answerlist[count + 1].studentAnswer[i] == "1") {
                    setTimeout(() => {
                      this.isoption1 = true;
                    },
                      500);
                  }
                  else if (this.answerlist[count + 1].studentAnswer[i] == "2") {
                    setTimeout(() => {
                      this.isoption2 = true;
                    },
                      500);
                  }
                  else if (this.answerlist[count + 1].studentAnswer[i] == "3") {
                    setTimeout(() => {
                      this.isoption3 = true;
                    },
                      500);
                  }
                  else if (this.answerlist[count + 1].studentAnswer[i] == "4") {
                    setTimeout(() => {
                      this.isoption4 = true;
                    },
                      500);
                  }
                }
              }
            }
            else {
              if (this.answerlist[count + 1].studentAnswer != null) {
                if (this.answerlist[count + 1].studentAnswer[0] == "1") {
                  setTimeout(() => {
                    this.isoption1 = true;
                  },
                    500);
                }
                else if (this.answerlist[count + 1].studentAnswer[0] == "2") {
                  setTimeout(() => {
                    this.isoption2 = true;
                  },
                    500);
                }
                else if (this.answerlist[count + 1].studentAnswer[0] == "3") {
                  setTimeout(() => {
                    this.isoption3 = true;
                  },
                    500);
                }
                else if (this.answerlist[count + 1].studentAnswer[0] == "4") {
                  setTimeout(() => {
                    this.isoption4 = true;
                  },
                    500);
                }
              }
            }
          }

          // ------ code to load the next question ----
          this.questioncount = count + 1;
          this.answeredcount = this.answerlist.filter(item => item.status === 'Answered').length;
          this.unansweredcount = this.answerlist.filter(item => item.status === 'Unanswered').length;
          this.reviewedcount = this.answerlist.filter(item => item.status === 'Reviewed').length;
          console.log("answerlist", this.answerlist);
          // document.getElementById("mathdiv").innerHTML = this.answerlist[count + 1].questionText;
          // MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
          if (this.answerlist[count + 1].images == undefined) {
            document.getElementById("mathdiv").innerHTML = this.answerlist[count + 1].questionText;
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
          }
          else {
            for (var i = 0; i < this.answerlist[count + 1].images.length; i++) {
              if (!this.answerlist[count + 1].images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
                console.log(this.answerlist[count + 1].images[i])
                document.getElementById("mathdiv").innerHTML = this.answerlist[count + 1].images[i];
                MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
              }
            }
          }

          // ------ End of code to load the next question ------------------------------------------


          // Code to load side div with subject or mark wise ----------------------------------------        

          if (count + 1 == this.subjectname1arraylength) {
            if (this.subjectname2 == "") {

            }
            else {
              this.subjectname2arrowdown = false;
              this.subjectname2arrowup = true;
              this.subjectname2arrayquestion = true;

              this.subjectname1arrowdown = true;
              this.subjectname1arrowup = false;
              this.subjectname1arrayquestion = false;
            }

          }

          if (count + 1 == this.subjectname2arraylength + this.subjectname1arraylength) {
            if (this.subjectname3 == "") {
              this.subjectname1arrowdown = false;
              this.subjectname1arrowup = true;
              this.subjectname1arrayquestion = true;

              this.subjectname2arrowdown = true;
            }
            else {
              this.subjectname3arrowdown = false;
              this.subjectname3arrowup = true;
              this.subjectname3arrayquestion = true;

              this.subjectname2arrowdown = true;
              this.subjectname2arrowup = false;
              this.subjectname2arrayquestion = false;
            }

          }

          if (count + 1 == this.subjectname3arraylength + this.subjectname1arraylength + this.subjectname2arraylength) {

            if (this.subjectname4 == "") {
              this.subjectname1arrowdown = false;
              this.subjectname1arrowup = true;
              this.subjectname1arrayquestion = true;

              this.subjectname3arrowdown = true;
              this.subjectname3arrowup = false;
              this.subjectname3arrayquestion = false;
            }
            else {
              this.subjectname4arrowdown = false;
              this.subjectname4arrowup = true;
              this.subjectname4arrayquestion = true;

              this.subjectname3arrowdown = true;
              this.subjectname3arrowup = false;
              this.subjectname3arrayquestion = false;
            }

            if (count + 1 == this.subjectname4arraylength + this.subjectname3arraylength + this.subjectname1arraylength + this.subjectname2arraylength) {
              this.subjectname1arrowdown = false;
              this.subjectname1arrowup = true;
              this.subjectname1arrayquestion = true;

              this.subjectname2arrowdown = true;
              this.subjectname2arrowup = false;
              this.subjectname2arrayquestion = false;
            }

          }


          // End of Code to load side div with subject or mark wise --------------------------------


        }
        else {
          this.toastr.errorToastr(result.message);
        }
      }, (err) => {
        console.log(err);
      });
    }
    // End of condition if question type = 3 or subjective

    else {
      if (data.option1 == true) {
        selectedanswer.push(1);
      }
      if (data.option2 == true) {
        selectedanswer.push(2);
      }
      if (data.option3 == true) {
        selectedanswer.push(3);
      }
      if (data.option4 == true) {
        selectedanswer.push(4);
      }

      if (selectedanswer.length > 0) {
        this.answerlist.find(item => item.questionNumber == this.questioncount + 1).studentAnswer = [];
        this.answerlist.find(item => item.questionNumber == this.questioncount + 1).studentAnswer = selectedanswer;
        this.answerlist.find(item => item.questionNumber == this.questioncount + 1).status = "Answered";
      }
      else {
        this.answerlist.find(item => item.questionNumber == this.questioncount + 1).studentAnswer = [];
        this.answerlist.find(item => item.questionNumber == this.questioncount + 1).status = "Unanswered";
      }
    }

    formData["answerList"] = this.answerlist;
    formData["id"] = this.testid;
    formData["currentTime"] = (this.time).toFixed(0);
    this.rest.updatetest(formData, token).subscribe((result) => {
      if (result.message.toLowerCase() == "saved") {
        this.selectedoptionform.controls['option1'].reset();
        this.selectedoptionform.controls['option2'].reset();
        this.selectedoptionform.controls['option3'].reset();
        this.selectedoptionform.controls['option4'].reset();
        console.log("count", count)
        console.log("questioncount", this.questioncount)
        console.log("totalcount", this.totalquestioncount)
        if (count + 1 == this.totalquestioncount - 1) {
          this.showlastmodal = true;
          this.showquestions = false;
        }
        if (this.totalquestioncount == this.questioncount + 1) {
          //End of questions
          if (count + 1 == this.subjectname1arraylength) {
            if (this.subjectname2 == "") {

            }
            else {
              this.subjectname2arrowdown = false;
              this.subjectname2arrowup = true;
              this.subjectname2arrayquestion = true;

              this.subjectname1arrowdown = true;
              this.subjectname1arrowup = false;
              this.subjectname1arrayquestion = false;
            }

          }

          if (count + 1 == this.subjectname2arraylength + this.subjectname1arraylength) {
            if (this.subjectname3 == "") {
              this.subjectname1arrowdown = false;
              this.subjectname1arrowup = true;
              this.subjectname1arrayquestion = true;

              this.subjectname2arrowdown = true;
              this.subjectname2arrowup = false;
              this.subjectname2arrayquestion = false;
            }
            else {
              this.subjectname3arrowdown = false;
              this.subjectname3arrowup = true;
              this.subjectname3arrayquestion = true;

              this.subjectname2arrowdown = true;
              this.subjectname2arrowup = false;
              this.subjectname2arrayquestion = false;
            }

          }
          if (count + 1 == this.subjectname3arraylength + this.subjectname1arraylength + this.subjectname2arraylength) {

            if (this.subjectname4 == "") {
              this.subjectname1arrowdown = false;
              this.subjectname1arrowup = true;
              this.subjectname1arrayquestion = true;

              this.subjectname3arrowdown = true;
              this.subjectname3arrowup = false;
              this.subjectname3arrayquestion = false;
            }
            else {
              this.subjectname4arrowdown = false;
              this.subjectname4arrowup = true;
              this.subjectname4arrayquestion = true;

              this.subjectname3arrowdown = true;
              this.subjectname3arrowup = false;
              this.subjectname3arrayquestion = false;
            }
          }
          if (count + 1 == this.subjectname4arraylength + this.subjectname3arraylength + this.subjectname1arraylength + this.subjectname2arraylength) {
            this.subjectname1arrowdown = false;
            this.subjectname1arrowup = true;
            this.subjectname1arrayquestion = true;
          }
          this.questioncount = 0;
          this.onlastdata();
          return;
        }
        else {
          this.questioncount = count + 1;
        }
        // if (this.answerlist[count + 1].option1 !== undefined || this.answerlist[count + 1].option1 != "") {
        //   document.getElementById("mathone").innerHTML = this.answerlist[count + 1].option1;
        //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
        // }
        // if (this.answerlist[count + 1].option2 !== undefined || this.answerlist[count + 1].option2 != "") {
        //   document.getElementById("mathtwo").innerHTML = this.answerlist[count + 1].option2;
        //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
        // }
        // if (this.answerlist[count + 1].option3 !== undefined || this.answerlist[count + 1].option3 != "") {
        //   document.getElementById("maththree").innerHTML = this.answerlist[count + 1].option3;
        //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
        // }
        // if (this.answerlist[count + 1].option4 !== undefined || this.answerlist[count + 1].option4 != "") {
        //   document.getElementById("mathfour").innerHTML = this.answerlist[count + 1].option4;
        //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
        // }

        
        if (this.answerlist[count + 1].option1 !== undefined || this.answerlist[count + 1].option1 != "") {
          if (this.answerlist[count + 1].option1Images == undefined) {
            document.getElementById("mathone").innerHTML = this.answerlist[count + 1].option1;
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
          }
          else {
            for (var i = 0; i < this.answerlist[count + 1].option1Images.length; i++) {
              if (!this.answerlist[count + 1].option1Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
                console.log(this.answerlist[count + 1].option1Images[i])
                document.getElementById("mathone").innerHTML = this.answerlist[count + 1].option1Images[i];
                MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
              }

            }
          }
        }
        if (this.answerlist[count + 1].option2 !== undefined || this.answerlist[count + 1].option2 != "") {
          if (this.answerlist[count + 1].option2Images == undefined) {
            document.getElementById("mathtwo").innerHTML = this.answerlist[count + 1].option2;
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
          }
          else {
            for (var i = 0; i < this.answerlist[count + 1].option2Images.length; i++) {
              if (!this.answerlist[count + 1].option2Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
                console.log(this.answerlist[count + 1].option2Images[i])
                document.getElementById("mathtwo").innerHTML = this.answerlist[count + 1].option2Images[i];
                MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
              }

            }
          }
        }
        if (this.answerlist[count + 1].option3 !== undefined || this.answerlist[count + 1].option3 != "") {
          if (this.answerlist[count + 1].option3Images == undefined) {
            document.getElementById("maththree").innerHTML = this.answerlist[count + 1].option3;
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
          }
          else {
            for (var i = 0; i < this.answerlist[count + 1].option3Images.length; i++) {
              if (!this.answerlist[count + 1].option3Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
                console.log(this.answerlist[count + 1].option3Images[i])
                document.getElementById("maththree").innerHTML = this.answerlist[count + 1].option3Images[i];
                MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
              }

            }
          }
        }
        if (this.answerlist[count + 1].option4 !== undefined || this.answerlist[count + 1].option4 != "") {
          if (this.answerlist[count + 1].option4Images == undefined) {
            document.getElementById("mathfour").innerHTML = this.answerlist[count + 1].option4;
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
          }
          else {
            for (var i = 0; i < this.answerlist[count + 1].option4Images.length; i++) {
              if (!this.answerlist[count + 1].option4Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
                console.log(this.answerlist[count + 1].option4Images[i])
                document.getElementById("mathfour").innerHTML = this.answerlist[count + 1].option4Images[i];
                MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
              }

            }
          }
        }

        if (this.answerlist[count + 1].numberOfOptions > 1) {
          if (this.answerlist[count + 1].studentAnswer != null) {
            for (var i = 0; i < this.answerlist[count + 1].studentAnswer.length; i++) {
              if (this.answerlist[count + 1].studentAnswer[i] == "1") {
                setTimeout(() => {
                  this.isoption1 = true;
                },
                  500);
              }
              else if (this.answerlist[count + 1].studentAnswer[i] == "2") {
                setTimeout(() => {
                  this.isoption2 = true;
                },
                  500);
              }
              else if (this.answerlist[count + 1].studentAnswer[i] == "3") {
                setTimeout(() => {
                  this.isoption3 = true;
                },
                  500);
              }
              else if (this.answerlist[count + 1].studentAnswer[i] == "4") {
                setTimeout(() => {
                  this.isoption4 = true;
                },
                  500);
              }
            }
          }
          if (this.answerlist[count + 1].correctAnswer.length == 1) {
            this.choisequestion = "Single Choice Question"
          }
          else {
            this.choisequestion = "Multiple Choice Question"
          }
        }
        else {
          if (this.answerlist[count + 1].correctAnswer == "") {
            this.choisequestion = "Subjective Type Question"
          }
          else {
            this.choisequestion = "Single Choice Question"
          }
          if (this.answerlist[count + 1].studentAnswer != null) {
            if (this.answerlist[count + 1].studentAnswer[0] == "1") {
              setTimeout(() => {
                this.isoption1 = true;
              },
                500);
            }
            else if (this.answerlist[count + 1].studentAnswer[0] == "2") {
              setTimeout(() => {
                this.isoption2 = true;
              },
                500);
            }
            else if (this.answerlist[count + 1].studentAnswer[0] == "3") {
              setTimeout(() => {
                this.isoption3 = true;
              },
                500);
            }
            else if (this.answerlist[count + 1].studentAnswer[0] == "4") {
              setTimeout(() => {
                this.isoption4 = true;
              },
                500);
            }
          }
        }

        this.questioncount = count + 1;
        this.answeredcount = this.answerlist.filter(item => item.status === 'Answered').length;
        this.unansweredcount = this.answerlist.filter(item => item.status === 'Unanswered').length;
        this.reviewedcount = this.answerlist.filter(item => item.status === 'Reviewed').length;
        console.log("answerlist", this.answerlist);
        // document.getElementById("mathdiv").innerHTML = this.answerlist[count + 1].questionText;
        // MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
        if (this.answerlist[count + 1].images == undefined) {
          document.getElementById("mathdiv").innerHTML = this.answerlist[count + 1].questionText;
          MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
        }
        else {
          for (var i = 0; i < this.answerlist[count + 1].images.length; i++) {
            if (!this.answerlist[count + 1].images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
              console.log(this.answerlist[count + 1].images[i])
              document.getElementById("mathdiv").innerHTML = this.answerlist[count + 1].images[i];
              MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
            }

          }
        }



        if (count + 1 == this.subjectname1arraylength) {
          if (this.subjectname2 == "") {

          }
          else {
            this.subjectname2arrowdown = false;
            this.subjectname2arrowup = true;
            this.subjectname2arrayquestion = true;

            this.subjectname1arrowdown = true;
            this.subjectname1arrowup = false;
            this.subjectname1arrayquestion = false;
          }

        }

        if (count + 1 == this.subjectname2arraylength + this.subjectname1arraylength) {
          if (this.subjectname3 == "") {
            this.subjectname1arrowdown = false;
            this.subjectname1arrowup = true;
            this.subjectname1arrayquestion = true;

            this.subjectname2arrowdown = true;
            this.subjectname2arrowup = false;
            this.subjectname2arrayquestion = false;
          }
          else {
            this.subjectname3arrowdown = false;
            this.subjectname3arrowup = true;
            this.subjectname3arrayquestion = true;

            this.subjectname2arrowdown = true;
            this.subjectname2arrowup = false;
            this.subjectname2arrayquestion = false;
          }

        }
        if (count + 1 == this.subjectname3arraylength + this.subjectname1arraylength + this.subjectname2arraylength) {

          if (this.subjectname4 == "") {

            this.subjectname1arrowdown = false;
            this.subjectname1arrowup = true;
            this.subjectname1arrayquestion = true;

            this.subjectname3arrowdown = true;
            this.subjectname3arrowup = false;
            this.subjectname3arrayquestion = false;
          }
          else {
            this.subjectname4arrowdown = false;
            this.subjectname4arrowup = true;
            this.subjectname4arrayquestion = true;

            this.subjectname3arrowdown = true;
            this.subjectname3arrowup = false;
            this.subjectname3arrayquestion = false;
          }
        }

        if (count + 1 == this.subjectname4arraylength + this.subjectname3arraylength + this.subjectname1arraylength + this.subjectname2arraylength) {
          this.subjectname1arrowdown = false;
          this.subjectname1arrowup = true;
          this.subjectname1arrayquestion = true;
        }



      }
      else {
        this.toastr.errorToastr(result.message);
      }

    }, (err) => {
      console.log(err);
    });
  }

  reviewlater() {
    const selectedanswer = [];
    const data = this.selectedoptionform.value;
    const count = this.questioncount;
    var formData = {};
    this.formDatasubjective = new FormData();
    // condition if question type = 3 or subjective
    if (this.answerlist[count].questionType == 3) {
      this.isquestiontype3 = true;

      if (this.isimagecheckbox == true) {
        this.answerlist.find(item => item.questionNumber == this.questioncount + 1).studentAnswer = [];
        this.answerlist.find(item => item.questionNumber == this.questioncount + 1).studentAnswer = "<image>";
        this.answerlist.find(item => item.questionNumber == this.questioncount + 1).status = "Reviewed";
      }
      else if (data.questiontype3text == undefined || data.questiontype3text == null || data.questiontype3text == "") {
        this.answerlist.find(item => item.questionNumber == this.questioncount + 1).studentAnswer = [];
        this.answerlist.find(item => item.questionNumber == this.questioncount + 1).status = "Reviewed";
      }
      else {      
        this.answerlist.find(item => item.questionNumber == this.questioncount + 1).studentAnswer = [];
        this.answerlist.find(item => item.questionNumber == this.questioncount + 1).studentAnswer = data.questiontype3text;
        this.answerlist.find(item => item.questionNumber == this.questioncount + 1).status = "Reviewed";
      }

      formData["answerList"] = this.answerlist;
      formData["id"] = this.testid;
      formData["currentTime"] = (this.time).toFixed(0);
      var token = localStorage.getItem('webtoken');
      this.rest.updatetest(formData, token).subscribe((result) => {
        if (result.message.toLowerCase() == "saved") {
          data.upload_name = null;
          this.selectedFileNmae = "Choose File"
          this.isquestiontype3 = false;
          if (count + 1 == this.totalquestioncount - 1) {
            this.showlastmodal = true;
            this.showquestions = false;
          }
          if (this.answerlist[count + 1].questionType == 3) {
            if (this.answerlist[count + 1].studentAnswer == "<image>") {
              this.isimagecheckbox = true;
              //this.selectedoptionform.controls['questiontype3text'].reset();
              this.selectedoptionform.patchValue({ questiontype3text: "" });
            }
            else {
              this.isimagecheckbox = false;
              this.selectedoptionform.patchValue({ questiontype3text: this.answerlist[count + 1].studentAnswer });
            }
          }
          else {
            this.selectedoptionform.controls['option1'].reset();
            this.selectedoptionform.controls['option2'].reset();
            this.selectedoptionform.controls['option3'].reset();
            this.selectedoptionform.controls['option4'].reset();
          
            // if (this.answerlist[count + 1].option1 !== undefined || this.answerlist[count + 1].option1 != "") {
            //   document.getElementById("mathone").innerHTML = this.answerlist[count + 1].option1;
            //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
            // }
            // if (this.answerlist[count + 1].option2 !== undefined || this.answerlist[count + 1].option2 != "") {
            //   document.getElementById("mathtwo").innerHTML = this.answerlist[count + 1].option2;
            //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
            // }
            // if (this.answerlist[count + 1].option3 !== undefined || this.answerlist[count + 1].option3 != "") {
            //   document.getElementById("maththree").innerHTML = this.answerlist[count + 1].option3;
            //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
            // }
            // if (this.answerlist[count + 1].option4 !== undefined || this.answerlist[count + 1].option4 != "") {
            //   document.getElementById("mathfour").innerHTML = this.answerlist[count + 1].option4;
            //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
            // }
            if (this.answerlist[count + 1].option1 !== undefined || this.answerlist[count + 1].option1 != "") {
              if (this.answerlist[count + 1].option1Images == undefined) {
                document.getElementById("mathone").innerHTML = this.answerlist[count + 1].option1;
                MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
              }
              else {
                for (var i = 0; i < this.answerlist[count + 1].option1Images.length; i++) {
                  if (!this.answerlist[count + 1].option1Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
                    console.log(this.answerlist[count + 1].option1Images[i])
                    document.getElementById("mathone").innerHTML = this.answerlist[count + 1].option1Images[i];
                    MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
                  }
  
                }
              }
            }
            if (this.answerlist[count + 1].option2 !== undefined || this.answerlist[count + 1].option2 != "") {
              if (this.answerlist[count + 1].option2Images == undefined) {
                document.getElementById("mathtwo").innerHTML = this.answerlist[count + 1].option2;
                MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
              }
              else {
                for (var i = 0; i < this.answerlist[count + 1].option2Images.length; i++) {
                  if (!this.answerlist[count + 1].option2Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
                    console.log(this.answerlist[count + 1].option2Images[i])
                    document.getElementById("mathtwo").innerHTML = this.answerlist[count + 1].option2Images[i];
                    MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
                  }
  
                }
              }
            }
            if (this.answerlist[count + 1].option3 !== undefined || this.answerlist[count + 1].option3 != "") {
              if (this.answerlist[count + 1].option3Images == undefined) {
                document.getElementById("maththree").innerHTML = this.answerlist[count + 1].option3;
                MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
              }
              else {
                for (var i = 0; i < this.answerlist[count + 1].option3Images.length; i++) {
                  if (!this.answerlist[count + 1].option3Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
                    console.log(this.answerlist[count + 1].option3Images[i])
                    document.getElementById("maththree").innerHTML = this.answerlist[count + 1].option3Images[i];
                    MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
                  }
  
                }
              }
            }
            if (this.answerlist[count + 1].option4 !== undefined || this.answerlist[count + 1].option4 != "") {
              if (this.answerlist[count + 1].option4Images == undefined) {
                document.getElementById("mathfour").innerHTML = this.answerlist[count + 1].option4;
                MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
              }
              else {
                for (var i = 0; i < this.answerlist[count + 1].option4Images.length; i++) {
                  if (!this.answerlist[count + 1].option4Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
                    console.log(this.answerlist[count + 1].option4Images[i])
                    document.getElementById("mathfour").innerHTML = this.answerlist[count + 1].option4Images[i];
                    MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
                  }
  
                }
              }
            }
            if (this.answerlist[count + 1].numberOfOptions > 1) {
              if (this.answerlist[count + 1].studentAnswer != null) {
                for (var i = 0; i < this.answerlist[count + 1].studentAnswer.length; i++) {
                  if (this.answerlist[count + 1].studentAnswer[i] == "1") {
                    setTimeout(() => {
                      this.isoption1 = true;
                    },
                      500);
                  }
                  else if (this.answerlist[count + 1].studentAnswer[i] == "2") {
                    setTimeout(() => {
                      this.isoption2 = true;
                    },
                      500);
                  }
                  else if (this.answerlist[count + 1].studentAnswer[i] == "3") {
                    setTimeout(() => {
                      this.isoption3 = true;
                    },
                      500);
                  }
                  else if (this.answerlist[count + 1].studentAnswer[i] == "4") {
                    setTimeout(() => {
                      this.isoption4 = true;
                    },
                      500);
                  }
                }
              }
              if (this.answerlist[count + 1].correctAnswer.length == 1) {
                this.choisequestion = "Single Choice Question"
              }
              else {
                this.choisequestion = "Multiple Choice Question"
              }
            }
            else {
              if (this.answerlist[count + 1].correctAnswer == "") {
                this.choisequestion = "Subjective Type Question"
              }
              else {
                this.choisequestion = "Single Choice Question"
              }
              if (this.answerlist[count + 1].studentAnswer != null) {
                if (this.answerlist[count + 1].studentAnswer[0] == "1") {
                  setTimeout(() => {
                    this.isoption1 = true;
                  },
                    500);
                }
                else if (this.answerlist[count + 1].studentAnswer[0] == "2") {
                  setTimeout(() => {
                    this.isoption2 = true;
                  },
                    500);
                }
                else if (this.answerlist[count + 1].studentAnswer[0] == "3") {
                  setTimeout(() => {
                    this.isoption3 = true;
                  },
                    500);
                }
                else if (this.answerlist[count + 1].studentAnswer[0] == "4") {
                  setTimeout(() => {
                    this.isoption4 = true;
                  },
                    500);
                }
              }
            }
          }
        
          this.questioncount = count + 1;
          this.answeredcount = this.answerlist.filter(item => item.status === 'Answered').length;
          this.unansweredcount = this.answerlist.filter(item => item.status === 'Unanswered').length;
          this.reviewedcount = this.answerlist.filter(item => item.status === 'Reviewed').length;
          console.log("answerlist", this.answerlist);
          // document.getElementById("mathdiv").innerHTML = this.answerlist[count + 1].questionText;
          // MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
          if (this.answerlist[count + 1].images == undefined) {
            document.getElementById("mathdiv").innerHTML = this.answerlist[count + 1].questionText;
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
          }
          else {
            for (var i = 0; i < this.answerlist[count + 1].images.length; i++) {
              if (!this.answerlist[count + 1].images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
                console.log(this.answerlist[count + 1].images[i])
                document.getElementById("mathdiv").innerHTML = this.answerlist[count + 1].images[i];
                MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
              }

            }
          }
          if (count + 1 == this.subjectname1arraylength) {
            if (this.subjectname2 == "") {

            }
            else {
              this.subjectname2arrowdown = false;
              this.subjectname2arrowup = true;
              this.subjectname2arrayquestion = true;

              this.subjectname1arrowdown = true;
              this.subjectname1arrowup = false;
              this.subjectname1arrayquestion = false;
            }

          }

          if (count + 1 == this.subjectname2arraylength + this.subjectname1arraylength) {
            if (this.subjectname3 == "") {
              this.subjectname1arrowdown = false;
              this.subjectname1arrowup = true;
              this.subjectname1arrayquestion = true;

              this.subjectname2arrowdown = true;
              this.subjectname2arrowup = false;
              this.subjectname2arrayquestion = false;
            }
            else {
              this.subjectname3arrowdown = false;
              this.subjectname3arrowup = true;
              this.subjectname3arrayquestion = true;

              this.subjectname2arrowdown = true;
              this.subjectname2arrowup = false;
              this.subjectname2arrayquestion = false;
            }

          }
          if (count + 1 == this.subjectname3arraylength + this.subjectname1arraylength + this.subjectname2arraylength) {

            if (this.subjectname4 == "") {

              this.subjectname1arrowdown = false;
              this.subjectname1arrowup = true;
              this.subjectname1arrayquestion = true;

              this.subjectname3arrowdown = true;
              this.subjectname3arrowup = false;
              this.subjectname3arrayquestion = false;
            }
            else {
              this.subjectname4arrowdown = false;
              this.subjectname4arrowup = true;
              this.subjectname4arrayquestion = true;

              this.subjectname3arrowdown = true;
              this.subjectname3arrowup = false;
              this.subjectname3arrayquestion = false;
            }
          }

          if (count + 1 == this.subjectname4arraylength + this.subjectname3arraylength + this.subjectname1arraylength + this.subjectname2arraylength) {
            this.subjectname1arrowdown = false;
            this.subjectname1arrowup = true;
            this.subjectname1arrayquestion = true;
          }
        }
        else {
          this.toastr.errorToastr(result.message);
        }
      }, (err) => {
        console.log(err);
      });

    }
    // End of condition if question type = 3 or subjective
    else {
      this.isquestiontype3 = false;
      if (data.option1 == true) {
        selectedanswer.push(1);
      }
      if (data.option2 == true) {
        selectedanswer.push(2);
      }
      if (data.option3 == true) {
        selectedanswer.push(3);
      }
      if (data.option4 == true) {
        selectedanswer.push(4);
      }

      if (selectedanswer.length > 0) {
        this.answerlist.find(item => item.questionNumber == this.questioncount + 1).studentAnswer = [];
        this.answerlist.find(item => item.questionNumber == this.questioncount + 1).studentAnswer = selectedanswer;
        this.answerlist.find(item => item.questionNumber == this.questioncount + 1).status = "Reviewed";
      }
      else {
        this.answerlist.find(item => item.questionNumber == this.questioncount + 1).studentAnswer = [];
        this.answerlist.find(item => item.questionNumber == this.questioncount + 1).status = "Reviewed";
      }

      var token = localStorage.getItem('webtoken');
      var formData = {};
      formData["answerList"] = this.answerlist;
      formData["id"] = this.testid;


      this.rest.updatetest(formData, token).subscribe((result) => {
        if (result.message.toLowerCase() == "saved") {
       
          if (count + 1 == this.totalquestioncount - 1) {
            this.showlastmodal = true;
            this.showquestions = false;
          }

          if (this.answerlist[count + 1].questionType == 3) {
            if (this.answerlist[count + 1].studentAnswer == "<image>") {
              this.isimagecheckbox = true;
              //this.selectedoptionform.controls['questiontype3text'].reset();
              this.selectedoptionform.patchValue({ questiontype3text: "" });
            }
            else {
              this.isimagecheckbox = false;
              this.selectedoptionform.patchValue({ questiontype3text: this.answerlist[count + 1].studentAnswer });
            }
          }
          else {
            this.selectedoptionform.controls['option1'].reset();
            this.selectedoptionform.controls['option2'].reset();
            this.selectedoptionform.controls['option3'].reset();
            this.selectedoptionform.controls['option4'].reset();
            // if (this.answerlist[count + 1].option1 !== undefined || this.answerlist[count + 1].option1 != "") {
            //   document.getElementById("mathone").innerHTML = this.answerlist[count + 1].option1;
            //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
            // }
            // if (this.answerlist[count + 1].option2 !== undefined || this.answerlist[count + 1].option2 != "") {
            //   document.getElementById("mathtwo").innerHTML = this.answerlist[count + 1].option2;
            //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
            // }
            // if (this.answerlist[count + 1].option3 !== undefined || this.answerlist[count + 1].option3 != "") {
            //   document.getElementById("maththree").innerHTML = this.answerlist[count + 1].option3;
            //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
            // }
            // if (this.answerlist[count + 1].option4 !== undefined || this.answerlist[count + 1].option4 != "") {
            //   document.getElementById("mathfour").innerHTML = this.answerlist[count + 1].option4;
            //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
            // }
            if (this.answerlist[count + 1].option1 !== undefined || this.answerlist[count + 1].option1 != "") {
              if (this.answerlist[count + 1].option1Images == undefined) {
                document.getElementById("mathone").innerHTML = this.answerlist[count + 1].option1;
                MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
              }
              else {
                for (var i = 0; i < this.answerlist[count + 1].option1Images.length; i++) {
                  if (!this.answerlist[count + 1].option1Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
                    console.log(this.answerlist[count + 1].option1Images[i])
                    document.getElementById("mathone").innerHTML = this.answerlist[count + 1].option1Images[i];
                    MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
                  }
  
                }
              }
            }
            if (this.answerlist[count + 1].option2 !== undefined || this.answerlist[count + 1].option2 != "") {
              if (this.answerlist[count + 1].option2Images == undefined) {
                document.getElementById("mathtwo").innerHTML = this.answerlist[count + 1].option2;
                MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
              }
              else {
                for (var i = 0; i < this.answerlist[count + 1].option2Images.length; i++) {
                  if (!this.answerlist[count + 1].option2Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
                    console.log(this.answerlist[count + 1].option2Images[i])
                    document.getElementById("mathtwo").innerHTML = this.answerlist[count + 1].option2Images[i];
                    MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
                  }
  
                }
              }
            }
            if (this.answerlist[count + 1].option3 !== undefined || this.answerlist[count + 1].option3 != "") {
              if (this.answerlist[count + 1].option3Images == undefined) {
                document.getElementById("maththree").innerHTML = this.answerlist[count + 1].option3;
                MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
              }
              else {
                for (var i = 0; i < this.answerlist[count + 1].option3Images.length; i++) {
                  if (!this.answerlist[count + 1].option3Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
                    console.log(this.answerlist[count + 1].option3Images[i])
                    document.getElementById("maththree").innerHTML = this.answerlist[count + 1].option3Images[i];
                    MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
                  }
  
                }
              }
            }
            if (this.answerlist[count + 1].option4 !== undefined || this.answerlist[count + 1].option4 != "") {
              if (this.answerlist[count + 1].option4Images == undefined) {
                document.getElementById("mathfour").innerHTML = this.answerlist[count + 1].option4;
                MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
              }
              else {
                for (var i = 0; i < this.answerlist[count + 1].option4Images.length; i++) {
                  if (!this.answerlist[count + 1].option4Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
                    console.log(this.answerlist[count + 1].option4Images[i])
                    document.getElementById("mathfour").innerHTML = this.answerlist[count + 1].option4Images[i];
                    MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
                  }
  
                }
              }
            }
            if (this.answerlist[count + 1].numberOfOptions > 1) {
              if (this.answerlist[count + 1].studentAnswer != null) {
                for (var i = 0; i < this.answerlist[count + 1].studentAnswer.length; i++) {
                  if (this.answerlist[count + 1].studentAnswer[i] == "1") {
                    setTimeout(() => {
                      this.isoption1 = true;
                    },
                      500);
                  }
                  else if (this.answerlist[count + 1].studentAnswer[i] == "2") {
                    setTimeout(() => {
                      this.isoption2 = true;
                    },
                      500);
                  }
                  else if (this.answerlist[count + 1].studentAnswer[i] == "3") {
                    setTimeout(() => {
                      this.isoption3 = true;
                    },
                      500);
                  }
                  else if (this.answerlist[count + 1].studentAnswer[i] == "4") {
                    setTimeout(() => {
                      this.isoption4 = true;
                    },
                      500);
                  }
                }
              }
              if (this.answerlist[count + 1].correctAnswer.length == 1) {
                this.choisequestion = "Single Choice Question"
              }
              else {
                this.choisequestion = "Multiple Choice Question"
              }
            }
            else {
              if (this.answerlist[count + 1].correctAnswer == "") {
                this.choisequestion = "Subjective Type Question"
              }
              else {
                this.choisequestion = "Single Choice Question"
              }
              if (this.answerlist[count + 1].studentAnswer != null) {
                if (this.answerlist[count + 1].studentAnswer[0] == "1") {
                  setTimeout(() => {
                    this.isoption1 = true;
                  },
                    500);
                }
                else if (this.answerlist[count + 1].studentAnswer[0] == "2") {
                  setTimeout(() => {
                    this.isoption2 = true;
                  },
                    500);
                }
                else if (this.answerlist[count + 1].studentAnswer[0] == "3") {
                  setTimeout(() => {
                    this.isoption3 = true;
                  },
                    500);
                }
                else if (this.answerlist[count + 1].studentAnswer[0] == "4") {
                  setTimeout(() => {
                    this.isoption4 = true;
                  },
                    500);
                }
              }
            }
          }

        

          this.questioncount = count + 1;
          this.answeredcount = this.answerlist.filter(item => item.status === 'Answered').length;
          this.unansweredcount = this.answerlist.filter(item => item.status === 'Unanswered').length;
          this.reviewedcount = this.answerlist.filter(item => item.status === 'Reviewed').length;
          console.log("answerlist", this.answerlist);
          // document.getElementById("mathdiv").innerHTML = this.answerlist[count + 1].questionText;
          // MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
          if (this.answerlist[count + 1].images == undefined) {
            document.getElementById("mathdiv").innerHTML = this.answerlist[count + 1].questionText;
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
          }
          else {
            for (var i = 0; i < this.answerlist[count + 1].images.length; i++) {
              if (!this.answerlist[count + 1].images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
                console.log(this.answerlist[count + 1].images[i])
                document.getElementById("mathdiv").innerHTML = this.answerlist[count + 1].images[i];
                MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
              }

            }
          }
        }
        else {
          this.toastr.errorToastr(result.message);
        }

      }, (err) => {
        console.log(err);
      });
    }

  }

  previous() {

    if (this.questioncount > 0) {
      const count = this.questioncount - 1;
      this.deductionmarks = this.answerlist[count].incorrectAnswerMarks;
    this.correctmarks = this.answerlist[count].correctAnswerMarks;
    if (this.answerlist[count].questionType == 3) {
      if (this.answerlist[count].studentAnswer == "<image>") {
        this.isimagecheckbox = true;
        this.selectedoptionform.controls['questiontype3text'].reset();
      }
      else {
        this.isimagecheckbox = false;
        this.selectedoptionform.patchValue({ questiontype3text: this.answerlist[count].studentAnswer });
      }
    }
    else {
      this.selectedoptionform.controls['option1'].reset();
      this.selectedoptionform.controls['option2'].reset();
      this.selectedoptionform.controls['option3'].reset();
      this.selectedoptionform.controls['option4'].reset();
      this.selectedoptionform.controls['questiontype3text'].reset();
      
      // if (this.answerlist[count].option1 !== undefined || this.answerlist[count].option1 != "") {
      //   document.getElementById("mathone").innerHTML = this.answerlist[count].option1;
      //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
      // }
      // if (this.answerlist[count].option2 !== undefined || this.answerlist[count].option2 != "") {
      //   document.getElementById("mathtwo").innerHTML = this.answerlist[count].option2;
      //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
      // }
      // if (this.answerlist[count].option3 !== undefined || this.answerlist[count].option3 != "") {
      //   document.getElementById("maththree").innerHTML = this.answerlist[count].option3;
      //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
      // }
      // if (this.answerlist[count].option4 !== undefined || this.answerlist[count].option4 != "") {
      //   document.getElementById("mathfour").innerHTML = this.answerlist[count].option4;
      //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
      // }      
      if (this.answerlist[count].option1 !== undefined || this.answerlist[count].option1 != "") {
        if (this.answerlist[count].option1Images == undefined) {
          document.getElementById("mathone").innerHTML = this.answerlist[count].option1;
          MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
        }
        else {
          for (var i = 0; i < this.answerlist[count].option1Images.length; i++) {
            if (!this.answerlist[count].option1Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
              console.log(this.answerlist[count].option1Images[i])
              document.getElementById("mathone").innerHTML = this.answerlist[count].option1Images[i];
              MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
            }

          }
        }
      }
      if (this.answerlist[count].option2 !== undefined || this.answerlist[count].option2 != "") {
        if (this.answerlist[count].option2Images == undefined) {
          document.getElementById("mathtwo").innerHTML = this.answerlist[count].option2;
          MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
        }
        else {
          for (var i = 0; i < this.answerlist[count].option2Images.length; i++) {
            if (!this.answerlist[count].option2Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
              console.log(this.answerlist[count].option2Images[i])
              document.getElementById("mathtwo").innerHTML = this.answerlist[count].option2Images[i];
              MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
            }

          }
        }
      }
      if (this.answerlist[count].option3 !== undefined || this.answerlist[count].option3 != "") {
        if (this.answerlist[count].option3Images == undefined) {
          document.getElementById("maththree").innerHTML = this.answerlist[count].option3;
          MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
        }
        else {
          for (var i = 0; i < this.answerlist[count].option3Images.length; i++) {
            if (!this.answerlist[count].option3Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
              console.log(this.answerlist[count].option3Images[i])
              document.getElementById("maththree").innerHTML = this.answerlist[count].option3Images[i];
              MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
            }

          }
        }
      }
      if (this.answerlist[count].option4 !== undefined || this.answerlist[count].option4 != "") {
        if (this.answerlist[count].option4Images == undefined) {
          document.getElementById("mathfour").innerHTML = this.answerlist[count].option4;
          MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
        }
        else {
          for (var i = 0; i < this.answerlist[count].option4Images.length; i++) {
            if (!this.answerlist[count].option4Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
              console.log(this.answerlist[count].option4Images[i])
              document.getElementById("mathfour").innerHTML = this.answerlist[count].option4Images[i];
              MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
            }

          }
        }
      }
      if (this.answerlist[count].numberOfOptions > 1) {
        if (this.answerlist[count].studentAnswer != null) {
          for (var i = 0; i < this.answerlist[count].studentAnswer.length; i++) {
            if (this.answerlist[count].studentAnswer[i] == "1") {
              setTimeout(() => {
                this.isoption1 = true;
              },
                500);
            }
            else if (this.answerlist[count].studentAnswer[i] == "2") {
              setTimeout(() => {
                this.isoption2 = true;
              },
                500);
            }
            else if (this.answerlist[count].studentAnswer[i] == "3") {
              setTimeout(() => {
                this.isoption3 = true;
              },
                500);
            }
            else if (this.answerlist[count].studentAnswer[i] == "4") {
              setTimeout(() => {
                this.isoption4 = true;
              },
                500);
            }
          }
        }

      }
      else {
        if (this.answerlist[count].studentAnswer != null) {
          if (this.answerlist[count].studentAnswer[0] == "1") {
            setTimeout(() => {
              this.isoption1 = true;
            },
              500);
          }
          else if (this.answerlist[count].studentAnswer[0] == "2") {
            setTimeout(() => {
              this.isoption2 = true;
            },
              500);
          }
          else if (this.answerlist[count].studentAnswer[0] == "3") {
            setTimeout(() => {
              this.isoption3 = true;
            },
              500);
          }
          else if (this.answerlist[count].studentAnswer[0] == "4") {
            setTimeout(() => {
              this.isoption4 = true;
            },
              500);
          }
        }
      }
    }
      this.questioncount = count;
      if (this.answerlist[count].images == undefined) {
        document.getElementById("mathdiv").innerHTML = this.answerlist[count].questionText;
        MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
      }
      else {
        for (var i = 0; i < this.answerlist[count].images.length; i++) {
          if (!this.answerlist[count].images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
            console.log(this.answerlist[count].images[i])
            document.getElementById("mathdiv").innerHTML = this.answerlist[count].images[i];
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
          }

        }
      }

      if (count + 2 == 1) {
        if (this.subjectname4 == "") {

        }
        else {
          this.subjectname4arrowdown = false;
          this.subjectname4arrowup = true;
          this.subjectname4arrayquestion = true;

          this.subjectname1arrowdown = true;
          this.subjectname1arrowup = false;
          this.subjectname1arrayquestion = false;
        }
      }

      if (count + 2 == this.subjectname1arraylength + 1) {
        this.subjectname1arrowdown = false;
        this.subjectname1arrowup = true;
        this.subjectname1arrayquestion = true;

        this.subjectname2arrowdown = true;
        this.subjectname2arrowup = false;
        this.subjectname2arrayquestion = false;
      }

      if (count + 2 == this.subjectname2arraylength + this.subjectname1arraylength + 1) {
        this.subjectname2arrowdown = false;
        this.subjectname2arrowup = true;
        this.subjectname2arrayquestion = true;

        this.subjectname3arrowdown = true;
        this.subjectname3arrowup = false;
        this.subjectname3arrayquestion = false;
      }
      if (count + 2 == this.subjectname3arraylength + this.subjectname1arraylength + this.subjectname2arraylength + 1) {
        this.subjectname3arrowdown = false;
        this.subjectname3arrowup = true;
        this.subjectname3arrayquestion = true;

        this.subjectname4arrowdown = true;
        this.subjectname4arrowup = false;
        this.subjectname4arrayquestion = false;
      }
    }
    else {

    }

  }

  oninitdata(timer) {
    const count = this.questioncount;
    if (timer === undefined || timer == "") {
      this.time = this.totaltime * 60;
      this.startTimer();
    }
    else {
      this.time = timer;
      this.startTimer();
    }
    this.deductionmarks = this.answerlist[count].incorrectAnswerMarks;
    this.correctmarks = this.answerlist[count].correctAnswerMarks;
    if (this.answerlist[count].numberOfOptions > 1) {
      if (this.answerlist[count].studentAnswer != null) {
        for (var i = 0; i < this.answerlist[count].studentAnswer.length; i++) {
          if (this.answerlist[count].studentAnswer[i] == "1") {
            this.isoption1 = true;
          }
          else if (this.answerlist[count].studentAnswer[i] == "2") {
            this.isoption2 = true;
          }
          else if (this.answerlist[count].studentAnswer[i] == "3") {
            this.isoption3 = true;
          }
          else if (this.answerlist[count].studentAnswer[i] == "4") {
            this.isoption4 = true;
          }
        }
      }
      if (this.answerlist[count].correctAnswer.length == 1) {
        this.choisequestion = "Single Choice Question"
      }
      else {
        this.choisequestion = "Multiple Choice Question"
      }
    }
    else {
      if (this.answerlist[count].correctAnswer == "") {

        this.choisequestion = "Subjective Type Question"
      }
      else {
        this.choisequestion = "Single Choice Question"
      }
      if (this.answerlist[count].studentAnswer != null) {
        if(this.answerlist[count].questionType == 3) {
          if (this.answerlist[count].studentAnswer == "<image>") {
            this.isimagecheckbox = true;
          }
          else {
            this.isimagecheckbox = false;
            this.selectedoptionform.patchValue({ questiontype3text: this.answerlist[count].studentAnswer });
          }
        }
        else {
          if (this.answerlist[count].studentAnswer[0] == "1") {
            this.isoption1 = true;
          }
          else if (this.answerlist[count].studentAnswer[0] == "2") {
            this.isoption2 = true;
          }
          else if (this.answerlist[count].studentAnswer[0] == "3") {
            this.isoption3 = true;
          }
          else if (this.answerlist[count].studentAnswer[0] == "4") {
            this.isoption4 = true;
          }
        }
      
      }
    }

    this.questioncount = count;
    if(this.answerlist[count].questionType!= 3) {
      if (this.answerlist[count].option1 !== undefined || this.answerlist[count].option1 != "") {
        document.getElementById("mathone").innerHTML = this.answerlist[count].option1;
        MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
      }
      if (this.answerlist[count].option2 !== undefined || this.answerlist[count].option2 != "") {
        document.getElementById("mathtwo").innerHTML = this.answerlist[count].option2;
        MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
      }
      if (this.answerlist[count].option3 !== undefined || this.answerlist[count].option3 != "") {
        document.getElementById("maththree").innerHTML = this.answerlist[count].option3;
        MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
      }
      if (this.answerlist[count].option4 !== undefined || this.answerlist[count].option4 != "") {
        document.getElementById("mathfour").innerHTML = this.answerlist[count].option4;
        MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
      }
    }
 


  }

  option1clicked() {
    const data = this.selectedoptionform.value;
    if (data.option1 == true) {
      this.isoption1 = false;
    }
    else {
      this.isoption1 = true;
    }
  }

  option2clicked() {
    const data = this.selectedoptionform.value;
    if (data.option2 == true) {
      this.isoption2 = false;
    }
    else {
      this.isoption2 = true;
    }
  }
  option3clicked() {
    const data = this.selectedoptionform.value;
    if (data.option3 == true) {
      this.isoption3 = false;
    }
    else {
      this.isoption3 = true;
    }
  }
  option4clicked() {
    const data = this.selectedoptionform.value;
    if (data.option4 == true) {
      this.isoption4 = false;
    }
    else {
      this.isoption4 = true;
    }
  }

  endtest() {
    var token = localStorage.getItem('webtoken');
    var formData = {};
    formData["id"] = this.testid;
    this.rest.finishtest(formData, token).subscribe((result) => {
      let containsimage = this.answerlist.some(x => x.studentAnswer === "<image>");    
     
      if (result.message.toLowerCase().includes("test finished")) {       
        if(containsimage == true) {
          this.toastr.successToastr("Test submitted successfully. Please upload all the images here.");
          this.endtestresult = result.message;
          this.uploadimage = true;
        }
        else {
          this.toastr.successToastr(result.message);
           this.router.navigate(['/buddi-test-details/' + this.TestlinkID]);
        }       
        
      }
      else if (result.message.toLowerCase().includes("correction")) {      
        if(containsimage == true) {
          this.toastr.successToastr("Test submitted successfully. Please upload all the images here.");
          this.uploadimage = true;
          this.endtestresult = result.message;
        }
        else {
          this.toastr.successToastr(result.message);
           this.router.navigate(['/buddi-test-details/' + this.TestlinkID]);
        }       
      }
      else {
        this.toastr.errorToastr(result.message);
      }

    }, (err) => {
      console.log(err);
    });

  }

  fileChange(event) {
    let fileList: FileList = event.target.files;
    this.selectedoptionform.patchValue({ upload_name: fileList[0] });
    this.selectedFileNmae = fileList[0].name;
    this.selectedoptionform.patchValue({ fileupload: this.selectedFileNmae });
    const data = this.selectedoptionform.value;
  }

  changed(evt) {
    this.ischecked = evt.target.checked;
  }

  getquestions() {
    this.isinstruction = false;
    this.isquestion = true;
    this.loaddata();
  }

  // justtry() {
  //   alert("clicked");
  //   var math = document.getElementById("mathjax");    
  //   const el: HTMLElement = document.getElementById('mathjax');    
  //   MathJax.Hub.Queue(["Typeset",MathJax.Hub,math]); 
  //   let newFromInterface = new MathJaxDirective(ViewChild('mathjax', { read: MathJaxDirective, static: true }),MathJax.Hub.Queue(['Typeset', MathJax.Hub, math]));
  //   newFromInterface.MathJaxTypeset();

  //   MathJax.Hub.Typeset('mathjax', { read: MathJaxDirective, static: true }),this.callback();
  // }

  // callback() {
  //   alert("callback");
  //   MathJax.Hub.Queue(["Typeset",MathJax.Hub]);
  //   var math = document.getElementById("mathjax");    
  //   const el: HTMLElement = document.getElementById('mathjax');    
  //   MathJax.Hub.Queue(["Typeset",MathJax.Hub,math]); 
  //   let newFromInterface = new MathJaxDirective(ViewChild('mathjax', { read: MathJaxDirective, static: true }),MathJax.Hub.Queue(['Typeset', MathJax.Hub, math]));
  //   newFromInterface.MathJaxTypeset();
  //   document.getElementById("mathjax").innerHTML = this.answerlist[this.questioncount].questionText;
  // }

  onlastdata() {
    const count = this.questioncount;
    this.deductionmarks = this.answerlist[count].incorrectAnswerMarks;
    this.correctmarks = this.answerlist[count].correctAnswerMarks;
    if (this.answerlist[count].questionType == 3) {
      if (this.answerlist[count].studentAnswer == "<image>") {
        this.isimagecheckbox = true;
        this.selectedoptionform.controls['questiontype3text'].reset();
      }
      else {
        this.isimagecheckbox = false;
        this.selectedoptionform.patchValue({ questiontype3text: this.answerlist[count].studentAnswer });
      }
    }
    else {
      if (this.answerlist[count].numberOfOptions > 1) {
        if (this.answerlist[count].studentAnswer != null) {
          for (var i = 0; i < this.answerlist[count].studentAnswer.length; i++) {
            if (this.answerlist[count].studentAnswer[i] == "1") {
              setTimeout(() => {
                this.isoption1 = true;
              },
                500);
            }
            else if (this.answerlist[count].studentAnswer[i] == "2") {
              setTimeout(() => {
                this.isoption2 = true;
              },
                500);
            }
            else if (this.answerlist[count].studentAnswer[i] == "3") {
              setTimeout(() => {
                this.isoption3 = true;
              },
                500);
            }
            else if (this.answerlist[count].studentAnswer[i] == "4") {
              setTimeout(() => {
                this.isoption4 = true;
              },
                500);
            }
          }
        }
        if (this.answerlist[count].correctAnswer.length == 1) {
          this.choisequestion = "Single Choice Question"
        }
        else {
          this.choisequestion = "Multiple Choice Question"
        }
  
      }
      else {
        if (this.answerlist[count].correctAnswer == "") {
          this.choisequestion = "Subjective Type Question"
        }
        else {
          this.choisequestion = "Single Choice Question"
        }
        if (this.answerlist[count].studentAnswer != null) {
          if (this.answerlist[count].studentAnswer[0] == "1") {
            setTimeout(() => {
              this.isoption1 = true;
            },
              500);
          }
          else if (this.answerlist[count].studentAnswer[0] == "2") {
            setTimeout(() => {
              this.isoption2 = true;
            },
              500);
          }
          else if (this.answerlist[count].studentAnswer[0] == "3") {
            setTimeout(() => {
              this.isoption3 = true;
            },
              500);
          }
          else if (this.answerlist[count].studentAnswer[0] == "4") {
            setTimeout(() => {
              this.isoption4 = true;
            },
              500);
          }
        }
      }
    }

   
    this.questioncount = count;
    document.getElementById("mathdiv").innerHTML = this.answerlist[this.questioncount].questionText;
    MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
    // var math = MathJax.Hub.getAllJax("mathdiv")[0];
    // MathJax.Hub.Queue(["Text", math, this.answerlist[this.questioncount].questionText]);
    // var math = document.getElementById("mathjax");        
    // MathJax.Hub.Queue(["Typeset",MathJax.Hub,math]);   
    // MathJax.Callback.Queue(
    //   ["Require", MathJax.Ajax, "[MathJax]/extensions/AMSmath.js"],
    //   this.answerlist[count].questionText     
    // );    
    // document.getElementById("mathjax").innerHTML = this.answerlist[count].questionText;

  }

  subjectname1arrowdownfunction() {
    this.subjectname1arrowdown = false;
    this.subjectname1arrowup = true;
    this.subjectname1arrayquestion = true;
  }

  subjectname1arrowupfunction() {
    this.subjectname1arrowdown = true;
    this.subjectname1arrowup = false;
    this.subjectname1arrayquestion = false;
  }

  subjectname2arrowdownfunction() {
    this.subjectname2arrowdown = false;
    this.subjectname2arrowup = true;
    this.subjectname2arrayquestion = true;
  }

  subjectname2arrowupfunction() {
    this.subjectname2arrowdown = true;
    this.subjectname2arrowup = false;
    this.subjectname2arrayquestion = false;
  }

  subjectname3arrowdownfunction() {
    this.subjectname3arrowdown = false;
    this.subjectname3arrowup = true;
    this.subjectname3arrayquestion = true;
  }

  subjectname3arrowupfunction() {
    this.subjectname3arrowdown = true;
    this.subjectname3arrowup = false;
    this.subjectname3arrayquestion = false;
  }

  subjectname4arrowdownfunction() {
    this.subjectname4arrowdown = false;
    this.subjectname4arrowup = true;
    this.subjectname4arrayquestion = true;
  }

  subjectname4arrowupfunction() {
    this.subjectname4arrowdown = true;
    this.subjectname4arrowup = false;
    this.subjectname4arrayquestion = false;
  }

  option1divclicked() {
    if (this.isoption1 == true) {
      this.isoption1 = false;
    }
    else {
      this.isoption1 = true;
      this.isoption2 = false;
      this.isoption3 = false;
      this.isoption4 = false;
    }
  }

  option2divclicked() {
    if (this.isoption2 == true) {
      this.isoption2 = false;
    }
    else {
      this.isoption2 = true;
      this.isoption1 = false;
      this.isoption3 = false;
      this.isoption4 = false;
    }
  }

  option3divclicked() {
    if (this.isoption3 == true) {
      this.isoption3 = false;
    }
    else {
      this.isoption3 = true;
      this.isoption2 = false;
      this.isoption1 = false;
      this.isoption4 = false;
    }
  }

  option4divclicked() {
    if (this.isoption4 == true) {
      this.isoption4 = false;
    }
    else {
      this.isoption4 = true;
      this.isoption2 = false;
      this.isoption3 = false;
      this.isoption1 = false;
    }
  }

  lastquestion() {
    this.showlastmodal = false;
    this.showquestions = true;
  }

  onFileChange(event) {

    this.formDatasubjective = new FormData();

    if (event.target.files && event.target.files[0]) {

      var filesAmount = event.target.files.length;

      for (let i = 0; i < filesAmount; i++) {



        var reader = new FileReader();
        reader.onload = (event: any) => {

          this.images.push(event.target.result);

          //  this.selectedimageform.patchValue({

          //     fileSource: this.images

          //  });

        }



        reader.readAsDataURL(event.target.files[i]);
        var selectedFile = event.target.files[i];
        this.fileList.push(selectedFile);

      }



    }



  }

  imageselected(url) {
    this.showimageinmodal = url;
  }

  removeSelectedFile(index) {
    // Delete the item from fileNames list
      this.images.splice(index, 1);
      this.fileList.splice(index, 1);
  }

  uploadimages() {
    var token = localStorage.getItem('webtoken');
    this.formDatasubjective.append("id", this.testid);
    for (let i = 0; i < this.fileList.length; i++) {
      // let fileList1: FileList = this.fileList[i];
      let imagename = "<i=" + this.fileList[i].name + ">";
      this.formDatasubjective.append("answerImage", this.fileList[i], imagename);
      //  let imagename = "<i=" + data.upload_name.name + ">";
      //  this.formDatasubjective.append("id", this.testid);
      //  this.formDatasubjective.append("answerImage", data.upload_name, imagename)
      // let name = event.target.files[i].name;
      // this.uploadingimages.push(fileList,name);  

      // this.selectedimageform.patchValue({ upload_name: fileList[0] });
      //   this.selectedimageform.patchValue({

      //     answerImage: this.uploadingimages

      //  });
    }
    this.rest.updatetestimage(this.formDatasubjective, token).subscribe((result) => {
      if (result.message.toLowerCase() == "uploaded successfully") {
        this.toastr.successToastr(result.message + ". " + this.endtestresult);
        this.router.navigate(['/buddi-test-details/' + this.TestlinkID]);
      }
      else {
       this.toastr.errorToastr(result.message);
      }
    });
  }

  imagecheckboxfunction(evt) {
    if (evt.target.checked == true) {
      this.isimagecheckbox = true;
    }
    else {
      this.isimagecheckbox = false;
    }
  }
}

