import { Component, OnInit } from "@angular/core";
import {
    FormBuilder,
    FormGroup,
    FormArray,
    FormControl,
    Validators,
    ValidatorFn,
} from "@angular/forms";
import { Options } from "ng5-slider";
import { ApiService } from "../../../shared/api.service";
import { ToastrManager } from "ng6-toastr-notifications";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { Location } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";
import { MediaService } from "../../../shared/media.service";
import { DatePipe } from '@angular/common';
const datepipe: DatePipe = new DatePipe('en-US')

@Component({
  selector: 'app-institute-list-details',
  templateUrl: './institute-list-details.component.html',
  styleUrls: ['./institute-list-details.component.scss']
})
export class InstituteListDetailsComponent implements OnInit {
  isLoggedin = "false";
  public teacherID = "";
  public studentID = "";
  public meetingimage = "";
  public meetingurl = "";
  public meetingsactionText = "";
  public meetingCategorypopup = "";
  public meetingScheduleDatepopup = "";
  public meetingrunningpopup = "";
  public meetingIDpopup = "";
  public meeting_ID = "";
  public meetingname = "";
  public meetingScheduleDate = "";
  public inviteUrl = "";
  public frontwebsite = environment.frontUrl;
  public redirectUrl = "";
  public meetingDuration = "";
  public meetingLanguage = "";
  public meetingCity = "";
  public meetingoccurance = "";
  public meetingdescription = "";
  public profileName = "";
  public meetingId = "";
  public urlArray: any = [];
  public pagename: any = "";
  public tag: any = "";
  public groupName: any;
  public isone2one: boolean = false;
  public isTeacher: boolean = false;
  public isStudent: boolean = false;
  public viewRequest: boolean = false;
  public isrecordPast: boolean = false;
  public packageActive: boolean = false;
  public paymentDetails: any = [];
  public isAlreadyJoined: Boolean = false;
  public liveDetails: any = {};
  
  constructor(
      private api: MediaService,
      private location: Location,
      private my_router: ActivatedRoute,
      public toastr: ToastrManager,
      private activatedRoute: ActivatedRoute,
      private rest: ApiService,
      private router: Router,
      private SpinnerService: NgxSpinnerService,
      private sanitizer: DomSanitizer,
      private datePipe: DatePipe,
      
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    const webToken = localStorage.getItem("webtoken");
    var institutionID = this.activatedRoute.snapshot.params['institutionID'];
    this.isTeacher =
        localStorage.getItem("profileType").toLowerCase() == "teacher";
    this.isStudent =
        localStorage.getItem("profileType").toLowerCase() == "student";
    // if(!liveClassID){
    //     this.router.navigate(['/sidenav'])
    // }
    var request = {}
    request['institutionID'] = institutionID;
    this.rest
    .getInstitutionDetailsWithoutLogin(request, webToken ? webToken : null)
    .subscribe((result) => {
      console.log(result)
        if (result['status']) {
          this.liveDetails = result['data']
          console.log(this.liveDetails,'details')
        }
  });
  }

  backto() {
    this.location.back()
  }


  redirectToLoginPage(){
    this.router.navigate(['/student/login']);
  }

  // viewClassRequests(id){
  //   this.router.navigate(['/live-class-request-listing/' + id])
  // }
  

  redirectToRequest(id){
    this.router.navigate(['/live-class-request-details/' + id ])
  }
}
