import { Component, OnInit,Input,EventEmitter,Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../shared/api.service';
import { NgxSpinnerService } from "ngx-spinner";
import { MediaService } from '../../../shared/media.service';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-student-reuselist',
  templateUrl: './student-reuselist.component.html',
  styleUrls: ['./student-reuselist.component.scss']
})
export class StudentReuselistComponent implements OnInit {
	@Input() dataList:any = [] ;
	@Input() totallength:Number = 0;
	@Input() pagelimit:Number = 0;  
  @Input() searchText:any = '';
  @Output() pageNumber = new EventEmitter();
  @Input() type:String ='';
  @Input() itemName:String = '';
  @Input() config:any = [];
  @Input() redirectText:String = 'details/';
  constructor(private router:Router,private toastr:ToastrManager,private api:MediaService,private SpinnerService: NgxSpinnerService,private userApi:ApiService) { }

  	ngOnInit(): void {
    console.log(this.dataList)
  	}

  	redirectDetails(data){    
      this.router.navigateByUrl(this.redirectText+data._id);
    }
  	pageChanged(event){
  		var data={
  			currentPage:event,
  			list:this.itemName,
  			type:this.config.type
  		}
  		this.pageNumber.emit(data)
  	}
}
