import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-institution-page',
  templateUrl: './institution-page.component.html',
  styleUrls: ['./institution-page.component.scss']
})
export class InstitutionPageComponent implements OnInit {
  public isStudent: boolean = false;
  public isTeacher: boolean = false;
  constructor( private router: Router) { }

  ngOnInit(): void {
    this.isTeacher =
            localStorage.getItem("profileType").toLowerCase() == "teacher";
        this.isStudent =
            localStorage.getItem("profileType").toLowerCase() == "student";
  }
  rout(){
  //   if(this.isTeacher){
  //     this.router.navigate(['/Institute-create']);
  //   }else{
  //     this.router.navigate(['/teacher/login']);
  //   }
  }

}
