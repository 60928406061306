

<a data-toggle="modal" data-target="#myModal1" labelStyle>
	View
</a>

<div  class="modal" id="myModal1">
	<div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">     
				<h6>Enrolled Students : {{listData.length}}</h6>
				<br>
            		<table class="table">
						<thead>
							<tr>
								<th>No</th>
								<th>First Name</th>
								<th>Last Name</th>
							</tr>			
						</thead>
						<tbody *ngIf="listData.length!=0;else noData">
							<tr *ngFor="let data of listData;index as i">
								<td>
									{{i+1}}
								</td>
								<td>
									{{data.userID.firstName}}
								</td>
								<td>
									{{data.userID.lastName}}
								</td>
							</tr>						
								
						</tbody>
						<ng-template #noData>
							<tbody>
								<tr>
									<td colspan="2">
										No Student Data
									</td>
								</tr>
							</tbody>
						</ng-template>
					</table>
            	
            </div>
        </div>
    </div>
</div>