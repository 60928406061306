<div *ngIf="isstudent">
    <app-header-style-one></app-header-style-one>
</div>
<div *ngIf="isteacher">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>

<!-- <div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>FAQ's</li>
            </ul>
            <h2>Frequently Asked Question</h2>
        </div>
    </div>
</div> -->

<section class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="tab faq-accordion-tab">
            <ul class="tabs d-flex flex-wrap justify-content-center">
                <li><a data-toggle="tab" href="classes"><i class='bx bx-book-open'></i> <span>Classes</span></a></li>
                <li><a data-toggle="tab" href="courses"><i class='bx bx-book-open'></i> <span>Courses</span></a></li>
                <li><a data-toggle="tab" href="sales"><i class='bx bx-shopping-bag'></i> <span>Sales Question</span></a></li>
                <li><a data-toggle="tab" href="usage"><i class='bx bx-book-open'></i> <span>Usage Guides</span></a></li>
               
            </ul>

            <div class="tab-content">
                <div class="tabs-item"  id="classes">
                    <div class="row">
                     
    
                        <!-- <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: How to Create a new class in buddi.life?</h3>
                                <h3>Q: How will I get paid?</h3>
                                <p><strong>A:</strong> To create a new class, log in with your account → go to site user account → products → buddi live classes → create class</p>
                                <p>Now open a new page for meeting general settings</p>
                                <p><strong>A:</strong>  The teachers will be paid on a weekly basis based on the number of classes taken per week. (10% of the fee will be charged by us) </p>
                          
                            </div>
                        </div> -->
    
                        <!-- <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is class name?</h3>
                                <h3>Q: What are the benefits by choosing buddi.life?</h3>
                                <p><strong>A:</strong> Enter a meeting topic name. For example what kind of meetings like a business, education, etc… related topic name.</p>
                                <p>Have to enter meeting name</p>
                                <p><strong>A:</strong>
                                 
                                    <ul>
                                        <p><strong>A:</strong>
                                        <li>You can decide the price</li>
                                        <li>  Lower cost</li>
                                        <li> Micro learning platform where the students can learn specific topics of their choice</li>
                                        <li> 800+ courses</li>
                                        </p>
                                    </ul>
                               
                               
                            </div>
                        </div> -->
    
                        <!-- <div class="col-lg-6 col-md-6">
                            <div class="faq-item"> -->
                                <!-- <h3>Q: Schedule ?</h3> -->
                                <!-- <h3>Q: Will you provide students for us to teach? </h3> -->
                                <!-- <p><strong>A:</strong>Select a date and time for your meeting. You can start your meeting at any time before the scheduled time.</p>
                                <p>Have to click select date & time → select to date and time</p> -->
                                <!-- <p><strong>A:</strong>If you have students learning from you, you can use our platform to teach as well as you can source them as well</p>
                                
                              
                            </div>
                        </div> -->
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <!-- <h3>Q: What is Class category?</h3> -->
                                <h3>
                                    Q: Will there be any continuity in class with the students?
                                    </h3>
                                <!-- <p><strong>A:</strong>The site administrator may have created course categories to help teachers and students find their courses easily.</p>
                                <p>Have to click select category →select anyone example (life skills, business, etc….)</p> -->
                                <p><strong>A:</strong>It depends upon the teacher’s choice. You can discuss it with the student/teacher regarding classes and timings.</p>
                          
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <!-- <h3>Q: Class type?</h3> -->
                                <h3>Q: Can students from abroad learn from this platform?</h3>
                                <!-- <p><strong>A:</strong> The meeting type is used for meeting visual public or private or group</p>
                                <p>Have to click privacy type → select private or public or group</p> -->
                                <p><strong>A:</strong> Anyone from anywhere can use our platform.</p>
                               
                            </div>
                        </div>
    
                        <!-- <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q:  Class image?</h3>
                                <h3>Q: Is buddi.life a user friendly platform?</h3>
                                <p><strong>A:</strong>Meeting image help to explain or simply highlight a learning topic. An image can be used to make your buddi.life meeting look better.</p>
                                <p>Have to click choose file → select to upload image</p>
                                <p><strong>A:</strong>Yes, buddi.life is a user friendly platform.</p>
                               
                            </div>
                        </div> -->
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <!-- <h3>Q:  Tag?</h3> -->
                                <h3>Q: What kind of subject expertise are you looking for?</h3>
                                <!-- <p><strong>A:</strong>Tag is used to select very shortly word what kind of related course is created.</p>
                                <p>Have to click select item → select anyone option (what you need)</p> -->
                                <p><strong>A:</strong>We have 800+ courses, so whatever subject you are expert in, you can choose and take classes.</p>
                               
                            </div>
                        </div>

                        
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <!-- <h3>Q:  Tag?</h3> -->
                                <h3>Q:How to create a class in buddi.life?</h3>
                                <!-- <p><strong>A:</strong>Tag is used to select very shortly word what kind of related course is created.</p>
                                <p>Have to click select item → select anyone option (what you need)</p> -->
                                <p><strong>A:</strong>Log in as a tutor –> Live classes -> Live classes (Explore) -> Create Live Class -> Enter required details -> Add</p>
                               
                            </div>
                        </div>


                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <!-- <h3>Q:  Tag?</h3> -->
                                <h3>Q: How do I accept requested classes?</h3>
                                <!-- <p><strong>A:</strong>Tag is used to select very shortly word what kind of related course is created.</p>
                                <p>Have to click select item → select anyone option (what you need)</p> -->
                                <p><strong>A:</strong>Log in as a tutor -> Live Classes -> Live Classes (Explore) -> Select created class -> View requests -> Accept/Reject</p>
                               
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <!-- <h3>Q:  Tag?</h3> -->
                                <h3>Q: How to cancel a class?</h3>
                                <!-- <p><strong>A:</strong>Tag is used to select very shortly word what kind of related course is created.</p>
                                <p>Have to click select item → select anyone option (what you need)</p> -->
                                <p><strong>A:</strong>Log in as Tutor -> Live classes (under Upcoming Activities) -> Click Cancel (under Action)</p>
                               
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <!-- <h3>Q:  Tag?</h3> -->
                                <h3>Q: How to reschedule a class?</h3>
                                <!-- <p><strong>A:</strong>Tag is used to select very shortly word what kind of related course is created.</p>
                                <p>Have to click select item → select anyone option (what you need)</p> -->
                                <p><strong>A:</strong>Log in as Tutor -> Live classes (under Upcoming Activities) -> Click Reschedule (under Action)</p>
                               
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <!-- <h3>Q:  Tag?</h3> -->
                                <h3>Q: How to start a class?</h3>
                                <!-- <p><strong>A:</strong>Tag is used to select very shortly word what kind of related course is created.</p>
                                <p>Have to click select item → select anyone option (what you need)</p> -->
                                <p><strong>A:</strong>Log in as a tutor -> Live classes (under Upcoming Activities –> Click Start Class (under Action)</p>
                               
                            </div>
                        </div>

                        
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <!-- <h3>Q:  Tag?</h3> -->
                                <h3>Q: How to register as an institute?</h3>
                                <!-- <p><strong>A:</strong>Tag is used to select very shortly word what kind of related course is created.</p>
                                <p>Have to click select item → select anyone option (what you need)</p> -->
                                <p><strong>A:</strong>Login/Sign up as a Tutor -> Pricing -> Create Institute -> Fill in the required details -> Submit</p>
                               
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <!-- <h3>Q:  Tag?</h3> -->
                                <h3>Q: How do I add a Tutor/Learner?</h3>
                                <!-- <p><strong>A:</strong>Tag is used to select very shortly word what kind of related course is created.</p>
                                <p>Have to click select item → select anyone option (what you need)</p> -->
                                <p><strong>A:</strong>Login as a Tutor -> Dashboard -> Institutions -> Add Tutor/Learner -> Enter the Tutor/Learner’s Email/Phone number -> Submit</p>
                               
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <!-- <h3>Q:  Tag?</h3> -->
                                <h3>Q: How to add a live class in institution?</h3>
                                <!-- <p><strong>A:</strong>Tag is used to select very shortly word what kind of related course is created.</p>
                                <p>Have to click select item → select anyone option (what you need)</p> -->
                                <p><strong>A:</strong>Login as Tutor -> Live Classes -> Live Classes -> Create Live Class -> Enter the required details and select “Institution” under “Privacy Type” -> Add</p>
                               
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <!-- <div class="faq-item">
                                <h3>Q:  Language?</h3>
                                <p><strong>A:</strong>Course language used select preferred language</p>
                                <p>Have to click select language → select anyone (preferred language)</p>
                            </div> -->
                            <!-- <div class="faq-item">
                                <h3>Q:  Re-occurrence?</h3>
                                <p><strong>A:</strong>Have to click select re-occurrence → select daily or weekly or monthly.</p>
                                <p>This helps to find classes re-occurrence time like daily, weekly, or monthly.</p>
                            </div> -->
                            <!-- <div class="faq-item">
                                <h3>Q:  Duration of class?</h3>
                                <p><strong>A:</strong>Enter the time duration for the duration of the class</p>
                                <p>Have to click select duration of class → select 30 or 60 or 90 or 120 minutes</p>
                            </div> -->
                            <!-- <div class="faq-item">
                                <h3>Q:  Class price?</h3>
                                <p><strong>A:</strong>	If the class is premium you have to pay money for class</p>
                                <p>Have to select classes → free or premium</p>
                                <p>If you select premium option → enter the amount for classes (In rupes)</p>
                                <p>To find out classes are free or premium </p>
                            </div> -->
                            <!-- <div class="faq-item">
                                <h3>Q:  Class description?</h3>
                                <p><strong>A:</strong>The class  description is used to shortly explain about new classes</p>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="tabs-item"  id="courses">
                    <div class="row">
                        <!-- <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>What is the Assignment </h3>
                                <p><strong>A:</strong> Assignments allow students to submit work to their teacher for grading. The work may be text typed online or uploaded files of any type the teacher’s device can read. Grading may be by simple percentages or custom scales, or more complex rubrics may be used. Students may submit as individuals or in groups.</p>
                            </div>
                        </div> -->
    
                        <!-- <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>STEP 1 </h3>
                                <p><strong>A:</strong> On your course page, click Turn editing on (black pencil icon, top left ). Editing icons and links will appear to edit the topic</p>
                            </div>
                        </div> -->
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: How to create a new course in buddi.life?</h3>
                                <p><strong>A:</strong> To create a new course, Log in with your account → go-to site user account → products → buddi course → create course.</p>
                                <p>now open a new page for course general settings</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is a Course full name?</h3>
                                <p><strong>A:</strong> Is the name of the course. It is displayed as a link on course lists on the Frontpage & Dashboard and in reports. It is also used in the browser title bar when the course is viewed.</p>
                                <p>Have to enter full name</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is Course short name ?</h3>
                                <p><strong>A:</strong>this short name for a course like quality control management, example short name QCM.</p>
                                <p>It will be used in several places where the long name is not stored for student </p>
                                <p>Have to enter short name</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is Course category?</h3>
                                <p><strong>A:</strong>The site administrator may have created course categories to help teachers and students find their courses easily.</p>
                                <p>Have to click select category →select anyone example (life skills, business, etc….)</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Course image?</h3>
                                <p><strong>A:</strong> Course image help to explain or simply highlight a learning topic. An image can be used to make your buddi.life course look better, helping to engage your students more.</p>
                                <p>Have to click choose file → select to upload image.</p>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Course start date?</h3>
                                <p><strong>A:</strong> If you use the "Weekly" course format, the start date will appear in the first section of the course. For example, selecting 27 July will display "27 July - 2 August" in the first section (when default display is selected for that section).</p>
                                <p>This setting will affect the display of logs.</p>
                                <p>Have to enter the date</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q:  Course end date?</h3>
                                <p><strong>A:</strong> The course end date is used for determining whether a course should be included in a user's list of courses. The course end date is also used for calculating the retention period before data is deleted. It may also be used by a custom report e.g. reporting activity for the duration of a course.</p>
                                <p>Have to enter end date.</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q:  Privacy?</h3>
                                <p><strong>A:</strong>The course privacy is used for course visual public or private.</p>
                                <p>Have to click privacy type → select private or public.</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q:  Tag?</h3>
                                <p><strong>A:</strong>Tag is used to select very shortly word what kind of related course is created.</p>
                                <p>Have to click select item → select anyone option (what you need)</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q:  Language?</h3>
                                <p><strong>A:</strong>Course language used select preferred language</p>
                                <p>Have to click select language → select anyone (preferred language)</p>
                            </div>
                            <!-- <div class="faq-item">
                                <h3>Q:  Course description?</h3>
                                <p><strong>A:</strong>The course description is used to shortly explain about the new course. </p>
                                <p>Finally, click to create the course button and open course main page settings</p>
                            </div> -->
                            
                        </div>
                    </div>
                </div>

               

                <div class="tabs-item" id="sales">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <!-- <h3 style="text-align: center;">No data Available to display.</h3>  -->
                                
                                 <h3>Q: How will I get paid?</h3>
                             
                                 <p><strong>A:</strong>  The teachers will be paid on a weekly basis based on the number of classes taken per week. (10% of the fee will be charged by us) </p>                               
                            </div>
                        </div>  
                        
                        
<div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <!-- <h3>Q: Schedule ?</h3> -->
                                <h3>Q: Will you provide students for us to teach? </h3>
                                <!-- <p><strong>A:</strong>Select a date and time for your meeting. You can start your meeting at any time before the scheduled time.</p>
                                <p>Have to click select date & time → select to date and time</p> -->
                                <p><strong>A:</strong>If you have students learning from you, you can use our platform to teach as well as you can source them as well</p>
                                
                              
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs-item" id="usage">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3 style="text-align: center;">No data Available to display.</h3>                              
                            </div>
                        </div>   
                    </div>
                </div>

                <div class="tabs-item" id="general">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3 style="text-align: center;">No data Available to display.</h3>                             
                            </div>
                        </div>   
                    </div>
                </div>

              

               
            </div>
        </div>
    </div>
</section>

<app-become-instructor-partner></app-become-instructor-partner>