<app-header-style-one-teacher></app-header-style-one-teacher>
<div class="page-title-area page-title-style-two-neet item-bg2 jarallax image" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <!-- <li style="color:#f21250">buddi NEET Pro</li> -->

            </ul>
            <h2 style="color:var(--mainColor)">What You Need To Teach.</h2>
            <!-- <button class="default-btn col-lg-1 mr-1 p-2 float-right">Explore</button> -->
        </div>
    </div>
</div>

<section class="courses-categories-area bg-F7F9FB pt-100 pb-70">
    <div class="container">
        <div class="section-title text-left">
            <!-- <span class="sub-title">Courses Categories</span> -->
            <h2>Technical Requirements</h2>
            <!-- <a routerLink="/courses-category-style-3" class="default-btn"><i class='bx bx-show-alt icon-arrow before'></i><span class="label">View All</span><i class="bx bx-show-alt icon-arrow after"></i></a> -->
        </div>

        <div class="courses-categories-slides owl-carousel owl-theme">
            <div class="single-categories-courses-item bg1 mb-30" >
                <div class="icon">
                    <i class='bx bx-code-alt'></i>
                </div>
                <h3 style="color: #ff1949;">System</h3>
                <span style="color:#ffffff">Windows, MAC or Linux. Intel Core i3 and above CPU, 4GB RAM</span>
                <!-- <a routerLink="/" class="learn-more-btn">Learn More <i class='bx bx-book-reader'></i></a>
                <a routerLink="/" class="link-btn"></a> -->
            </div>
        
            <div class="single-categories-courses-item bg2 mb-30">
                <div class="icon">
                    <i class='bx bx-camera'></i>
                </div>
                <h3 style="color: #ff1949;">Network </h3>
                <span style="color:#ffffff">Wired DSL Internet Connection - at least 20 Mbps</span>             
            </div>
        
            <div class="single-categories-courses-item bg3 mb-30">
                <div class="icon">
                    <i class='bx bx-camera'></i>
                </div>
                <h3 style="color: #ff1949;">Camera </h3>
                <span style="color:#ffffff">HD External camera or HD internal camera</span>
                <br>
            </div>
        
            <div class="single-categories-courses-item bg4 mb-30">
                <div class="icon">
                    <i class='bx bx-camera'></i>
                </div>
                <h3 style="color: #ff1949;">Headset</h3>
                <span style="color:#ffffff">Good headset with Microphone</span>
                <br>
            </div>
        </div>
    </div>

    <div id="particles-js-circle-bubble-2"></div>
</section>

<section class="offer-area pt-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="section-title">
           <h2>WHAT WE OFFER YOU</h2>
            
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-offer-box">
                    <div class="icon">
                        <i class='bx bx-book-reader'></i>
                    </div>
                    <h3>Earn upto 60K per month</h3>
                  
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-offer-box">
                    <div class="icon">
                        <i class='bx bx-book-reader'></i>
                    </div>
                    <h3>Join the online teaching revolution</h3>
                  
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-offer-box">
                    <div class="icon">
                        <i class='bx bx-target-lock'></i>
                    </div>
                    <h3>Be the part of new education system</h3>
                   
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-offer-box">
                    <div class="icon">
                        <i class='bx bxs-thermometer'></i>
                    </div>
                    <h3>Grow with us and reap many benefits</h3>
                   
                </div>
            </div>
        </div>
    </div>
</section>

<section class="how-it-works-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
           <!-- <span class="sub-title">Find Courses</span> -->
            <h2>How to Join Us?</h2>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-work-process mb-30">
                    <div class="icon">
                        <a > <i class='bx bx-search-alt'></i> </a>
                    </div>
                   
                    <h3><a  style="color: white;">Apply</a></h3>
                   <p>Complete the form.</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-work-process mb-30">
                    <div class="icon">
                        <a  >   <i class='bx bx-detail'></i> </a>
                    </div>  
                    <h3><a  style="color: white;">Interview</a></h3>
                    <p>After we screen your profile, we will arrange for an interview process.</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-work-process mb-30">
                    <div class="icon">
                        <a > <i class='bx bx-task'></i> </a>                </div>
                    <h3><a style="color: white;">Teacher Training</a></h3>
                    
                    <p>Once selected, your profile will be created. We will provide you with training and orientation program.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-work-process1 mb-30">
                    <div class="icon">
                        <a > <i class='bx bx-like'></i> </a>                </div>
                    <h3><a style="color: white;">Join Our Team</a></h3>
                    <p>You will join the team of prestigious teachers. Your contribution as a teacher

                        will start to create a strong intelligent student community.</p>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="offer-area1 pt-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="section-title">
           <h2>WHAT WE EXPECT FROM YOU</h2>
            
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-offer-box">
                    <div class="icon">
                        <i class='bx bx-book-reader'></i>
                    </div>
                    <h3>At least 100 Hrs per Month</h3>
                    <br>
                  
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-offer-box">
                    <div class="icon">
                        <i class='bx bx-book-reader'></i>
                    </div>
                    <h3>Passionate approach to teaching</h3>
                  
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-offer-box">
                    <div class="icon">
                        <i class='bx bx-target-lock'></i>
                    </div>
                    <h3>Have a good internet connection</h3>
                   <br>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-offer-box">
                    <div class="icon">
                        <i class='bx bxs-thermometer'></i>
                    </div>
                    <h3>Kid Friendliness and Pleasant approach</h3>
                   <br>
                </div>
            </div>
        </div>
    </div>
</section>


