import { Component, OnInit ,Output,EventEmitter} from '@angular/core';

@Component({
  selector: 'pwEye',
  templateUrl: './password-eye.component.html',
  styleUrls: ['./password-eye.component.scss']
})
export class PasswordEyeComponent implements OnInit {
	public password = 'password';
	public show = true;
	@Output() eyePassword = new EventEmitter();
  	constructor() { }

  	ngOnInit(): void {
  	}
  	passwordTypeChange(){
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
      this.eyePassword.emit('text');
    } else {
      this.password = 'password';
      this.show = false;
      this.eyePassword.emit('password');
    }
  }
}
