import { Component, OnInit,Input,EventEmitter,Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../shared/api.service';
import { NgxSpinnerService } from "ngx-spinner";
import { MediaService } from '../../../shared/media.service';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'librarylist',
  templateUrl: './librarylist.component.html',
  styleUrls: ['./librarylist.component.scss']
})
export class LibrarylistComponent implements OnInit {

  @Input() data:any = [] ;
  @Output() pageNumber =  new EventEmitter<any>();
  @Input() type:String ='';
  @Input() itemName:String = '';
  @Input() config:any = [];
  constructor(private router:Router,private toastr:ToastrManager,private api:MediaService,private SpinnerService: NgxSpinnerService,private userApi:ApiService) { }

  	ngOnInit(): void {
    console.log(this.data)
  	}
    ngAfterViewInit() {
        // this.cd.detectChanges();
    }
  	redirectDetails(data){   
      if(data.isClass){
        this.router.navigateByUrl('student/meetingDetails/'+data.meetingID);
      }else{
        this.router.navigateByUrl(this.config.redirect+data._id);
      }
    }
  	pageChanged(event){
  		var data={
  			currentPage:event,
  			list:this.config.list,
  			type:this.config.type
  		}
  		this.pageNumber.emit(data)
  	}
  	fetchrecordvideo(id,type) {
    	var req = {
    	  [type]: id
    	}
    	// this.SpinnerService.show()
    	this.api.fetchrecordvideo(req).subscribe((result) => {
    	  // this.SpinnerService.hide()
    	  if (result.status) {
    	    window.open(result.data[0].recordurl, '_blank');
    	  }
    	}, (err) => {
    	  this.toastr.errorToastr(err.message);
    	});

  	}
}
