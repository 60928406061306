<div *ngIf="isTeacher">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>
<div *ngIf="!isTeacher">
    <app-header-style-one></app-header-style-one>
</div>
<div class="containerBg">
    <div class="container">
        <div class="row mt-5">
            <div class="col-md-12">
                <div class="serviceTitle">
                    <h3 class="col-lg-12" labelStyle>
                        <span>
                            <span class="ml-2"
                                >{{
                                    this.editData._id ? "Update" : "Create"
                                }}
                                Round Table </span
                            >
                        </span>
                        <button
                            type="button"
                            class="default-btn float-right"
                            (click)="goBack()"
                            ><i class="fas fa-long-arrow-alt-left"></i
                            >&nbsp;Back</button
                        >
                    </h3>
                </div>
                <hr />
            </div>
        </div>
        <div class="container">
            <form [formGroup]="createForm">
                <div class="row">
                    <!-- <div class="col-lg-6 description">
						<div class="form-group mb-3">
                <label labelStyle>Video Name</label>
						   <input type="text" formControlName="videoname" placeholder="Video Name"  class="form-control" maxlength="50" [ngClass]="{ 'is-invalid': submitted && f.videoname.errors}" />
						   	<div *ngIf="submitted && f.videoname.errors" class="invalid-feedback">
								<div *ngIf="f.videoname.errors.required">
									Video Name is required
								</div>
						 	</div>
						</div>
					</div>					 -->
                    <div class="col-md-6 description">
                        <div class="form-group mb-3">
                            <label labelStyle>Name</label>
                            <div class="not">
                            <input
                                type="text"
                                maxlength="500"
                                formControlName="className"
                                class="form-control mr-2"
                            >
                            <i class='fas fa-exclamation-circle' style="color: green;" data-toggle="tooltip" data-placement="right" title="Enter the name of your round table"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group mb-3">
                            <label labelStyle>Description</label>
                            <div class="not">
                            <input
                                type="text"
                                maxlength="500"
                                formControlName="description"
                                class="form-control mr-2"
                            >
                            <i class='fas fa-exclamation-circle' style="color: green;" data-toggle="tooltip" data-placement="right" title="Short note about your round table"></i>
                            </div>
                        </div>
                    </div>
                    
                    <div
                        class="col-md-6 description"
                        *ngIf="coursecategorylist.length != 0"
                    >
                        <div class="form-group" style="margin-right: 20px;">
                            <label labelStyle>Category</label>
                            <div class="not">
                            <select
                                class="form-control col-md-12 mr-2"
                                (change)="selectcategoryChange($event)"
                                formControlName="courseCategory"
                            >
                                <option value="" disabled>
                                    Select Category
                                </option>
                                <option
                                    *ngFor="let obj of coursecategorylist"
                                    [ngValue]="obj._id"
                                >
                                    {{ obj.categoryName }}
                                </option>
                            </select>
                            <i class='fas fa-exclamation-circle' style="color: green;"data-toggle="tooltip" data-placement="right" title="Select the specific category of students whom you are going to teach "></i>
                            </div>
                        </div>
                    </div>

                    <div
                        class="col-md-6 description"
                        *ngIf="modulelist.length != 0"
                    >
                        <div class="form-group" style="margin-right: 20px;">
                            <label labelStyle>Module</label>
                            <div class="not">
                            <select
                                class="form-control col-md-12 mr-2"
                                (change)="selectmoduleChange($event)"
                                formControlName="courseModule"
                            >
                                <option value="" disabled>Select Module</option>
                                <option
                                    *ngFor="let obj of modulelist"
                                    [ngValue]="obj.moduleID"
                                >
                                    {{ obj.moduleName }}
                                </option>
                            </select>
                            <i class='fas fa-exclamation-circle' style="color: green;"data-toggle="tooltip" data-placement="right" title="Select the module according to your selected course"></i>
                            </div>
                        </div>
                    </div>

                    <div
                        class="col-md-6 description"
                        *ngIf="templatelist.length != 0"
                    >
                        <div class="form-group" style="margin-right: 20px;">
                            <label labelStyle>Template</label>
                            <div class="not">
                            <select
                                class="form-control col-md-12 mr-2"
                                (change)="selecttemplateChange($event)"
                                formControlName="courseTemplate"
                            >
                                <option value="" disabled>
                                    Select Template
                                </option>
                                <option
                                    *ngFor="let obj of templatelist"
                                    [ngValue]="obj._id"
                                >
                                    {{ obj.courseName }}
                                </option>
                            </select>
                            <i class='fas fa-exclamation-circle' style="color: green;"data-toggle="tooltip" data-placement="right" title="Choose the template for your course"></i>
                            </div>
                        </div>
                    </div>

                    <div
                        class="col-md-6 description"
                        *ngIf="topicapi.length != 0"
                    >
                        <div class="form-group" style="margin-right: 20px;">
                            <label labelStyle>Topic</label>
                            <div class="not">
                            <select
                                class="form-control col-md-12 mfa-rotate-270 mr-2"
                                (change)="selecttopicChange($event)"
                                formControlName="courseTopic"
                            >
                                <option value="" disabled>Select Topic</option>
                                <option
                                    *ngFor="let obj of topicapi"
                                    [ngValue]="obj.topicID._id"
                                >
                                    {{ obj.topicID.topicName }}
                                </option>
                            </select>
                            <i class='fas fa-exclamation-circle' style="color: green;"data-toggle="tooltip" data-placement="right" title="Choose the topic you are going to teach"></i>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-md-6 description" *ngIf="gradeapi.length !=0">
                        <div class="form-group">
                            <label labelStyle>Grade</label>
                          <select class="form-control col-md-12" (change)="selectgradeChange($event)" formControlName="courseGrade" >
                            <option value="" disabled>Select Grade</option>
                            <option *ngFor="let obj of gradeapi" [ngValue]="obj._id">{{obj.gradeName}}</option>
                          </select>
						
                        </div>
                      </div> -->

                    <!--<div class="col-md-6 description" *ngIf="courseboardlist.length !=0">
                        <div class="form-group">
                            <label labelStyle>Board</label>
                          <select class="form-control col-md-12" (change)="selectboardChange($event)" formControlName="courseBoard" >
                            <option value="" disabled>Select Board</option>
                            <option *ngFor="let obj of courseboardlist" [ngValue]="obj._id">{{obj.boardName}}</option>
                          </select>
                        </div>
                      </div>-->
                    <!-- <div class="col-md-6 description" *ngIf="coursesubjectlist.length !=0">
                        <div class="form-group">
                            <label labelStyle>Subject</label>
                          <select class="form-control col-md-12" (change)="selectsubjectChange($event)" formControlName="courseSubject" >
                            <option value="" disabled>Select Subject</option>
                            <option *ngFor="let obj of coursesubjectlist" [ngValue]="obj._id">{{obj.subjectName}}</option>
                          </select>

                        </div>
					  </div> -->
                    <div
                        class="col-md-6 description"
                        *ngIf="languageapi.length != 0"
                    >
                        <div class="form-group" style="margin-right: 20px;">
                            <label labelStyle>Language</label>
                            <div class="not">
                            <select
                                class="form-control col-md-12 mr-2"
                                (change)="selectlanguageChange($event)"
                                formControlName="courseLanguage"
                            >
                                <option value="" disabled>
                                    Select Language
                                </option>
                                <option
                                    *ngFor="let obj of languageapi"
                                    [ngValue]="obj._id"
                                >
                                    {{ obj.languageName }}
                                </option>
                            </select>
                            <i class='fas fa-exclamation-circle' style="color: green;"data-toggle="tooltip" data-placement="right" title="Choose the language using which you will communicate"></i>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-md-6">
                        <div class="form-group mb-3">
                            <label labelStyle>Privacy</label>
                            <select
                                class="form-control p-0 col-md-12"
                                (change)="privacytype($event)"
                                required
                                formControlName="privacy"
                            >
                                <option [value]="" disabled>
                                    Select Privacy Type
                                </option>
                                <option [value]="'Private'">Private</option>
                                <option [value]="'Public'">Public</option>
                            </select>
                        </div>
                    </div> -->
                 <div class="col-md-6">
                    <div class="form-group " >
                        <label labelStyle>Level</label>
                        <div class="not">
                        <select
                            id="levelid"
                            class="form-control mr-2"
                            formControlName="level"
                             (change)="levelChange($event)"
                        >
                            <option value="" disabled>Select Level</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                        </select>
                        <i class='fas fa-exclamation-circle' style="color: green;"data-toggle="tooltip" data-placement="right" title="Select the level of difficulty for your round table"></i>
                        </div>
                    </div>
                    </div>
                    <div class="col-md-6 description">
                        <div class="form-group mb-3">
                            <label labelStyle>Price</label>
                            <div class="not">
                            <input
                                type="text"
                                maxlength="5"
                                formControlName="price"
                                class="form-control mr-2"
                            >
                            <i class='fas fa-exclamation-circle' style="color: green;" data-toggle="tooltip" data-placement="right" title=" Enter the amount you are going to charge per round table"></i>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group mb-3" style="margin-right: 20px;">
                            <label labelStyle>Privacy Type</label>
                            <div class="not">
                            <select
                                class="form-control p-0 col-md-12 mr-2"
                                (change)="privacytype($event)"
                                required
                                formControlName="privacy"
                            >
                                <option value="" disabled>
                                    Select Privacy Type
                                </option>
                                <option [value]="'Private'">Private</option>
                                <option [value]="'Institution'">Institution</option>
                                <option [value]="'Public'" >Buddi</option>
                            </select>
                            <i class='fas fa-exclamation-circle' style="color: green;"data-toggle="tooltip" data-placement="right" title="Select the type of privacy for your round table"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label labelStyle>Choose Class Thumbnail</label>
                            <div class="not">
                            <div class="input-group mr-2">
                                <div class="custom-file">
                                    <input
                                        type="hidden"
                                        formControlName="thumbnail"
                                        name=""
                                        value=""
                                    />
                                    <input
                                        type="file"
                                        accept="image/*"
                                        class="custom-file-input"
                                        [ngClass]="{
                                            'is-invalid':
                                                submitted && f.thumbnail.errors
                                        }"
                                        (change)="onSelectFile($event)"
                                        id="inputGroupFile01"
                                        aria-describedby="inputGroupFileAddon01"
                                    />
                                    <label
                                        class="custom-file-label"
                                        style="
                                            word-break: break-all;
                                            overflow: hidden;
                                        "
                                        for="inputGroupFile01"
                                        >{{
                                            this.thumbnailPlace == ""
                                                ? "Choose File"
                                                : this.thumbnailPlace
                                        }}</label
                                    >
                                </div>
                            </div>
                            <i class='fas fa-exclamation-circle' style="color: green;"data-toggle="tooltip" data-placement="right" title="Choose appropriate photo for your round table"></i>
                            </div>
                            <div
                                *ngIf="submitted && f.thumbnail.errors"
                                class="invalid-error-span"
                            >
                                <div
                                    *ngIf="f.thumbnail.errors.required"
                                    style="font-size: 80%; color: #dc3545"
                                >
                                    Thumbnail Image is required
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 description">
                        <div class="form-group" >
                          <label labelStyle>Date and Time</label>
                          <div class="not">
                            <input
                                type="datetime-local"
                                class="meeting-she form-control mr-2"
                                placeholder="Select Date & Time From ~ To"
                                formControlName="startDateTime"
                                [ngClass]="{
                                    'is-invalid':
                                        submitted && f.startDateTime.errors
                                }"
                            />
                            <i class='fas fa-exclamation-circle' style="color: green;"data-toggle="tooltip" data-placement="right" title="Select the suitable date and time to conduct the round table"></i>
                            </div>
                            <div
                                *ngIf="submitted && f.startDateTime.errors"
                                class="invalid-feedback"
                            >
                                <div *ngIf="f.startDateTime.errors.required">
                                    Date & Time is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 description">
                                <div class="form-group" style="margin-right: 20px;">
                                 <label labelStyle>Duration of Class</label>
                                 <div class="not">
                                  <select class="form-control col-md-12 mr-2"  formControlName="totalClassTime"
                                  >
                                  <option  value="">Select Duration of Class</option>
                                  <option value="30">30</option>
                                  <option value="60" >60</option>
                                  <option value="90">90</option>
                                  <option value="120">120</option>
                                  </select>
                                  <i class='fas fa-exclamation-circle' style="color: green;"data-toggle="tooltip" data-placement="right" title="Select the total duration per round table"></i>
                                  </div>
                                </div>
                              </div>
                </div>
                <div class="row">
                    <div class="col-md-12 description">
                        <div class="text-center mt-3">
                            <button
                                type="button"
                                (click)="onSubmit('create')"
                                class="default-btn"
                                id="addMaterialButton"
                            >
                                <i class="bx bx-save icon-arrow before"></i>
                                <span class="label"
                                    >{{
                                        this.editData._id
                                        ? "Update"
                                        : "Add"
                                }}
                                </span>
                                <i class="bx bx-save icon-arrow after"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            <br />
            <div class="row"></div>
        </div>
    </div>
</div>
<ngx-spinner
    bdColor="rgba(51, 51, 51, 0.8)"
    size="default"
    type="ball-spin-clockwise"
>
    <img src="assets/img/spinner-default.gif" alt="" />
</ngx-spinner>
