import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Options } from 'ng5-slider';
import { ApiService } from '../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { Location } from '@angular/common';
declare var $: any;
import * as moment from 'moment';

@Component({
  selector: 'app-paymentsuccess',
  templateUrl: './paymentsuccess.component.html',
  styleUrls: ['./paymentsuccess.component.scss']
})
export class PaymentsuccessComponent implements OnInit {
	public isTeacher:boolean = false;
	public paymentDetails:any = []
 	constructor(private location:Location,private my_router: ActivatedRoute,public toastr: ToastrManager, private formBuilder: FormBuilder, private rest: ApiService, private router: Router,private SpinnerService: NgxSpinnerService,private sanitizer: DomSanitizer,private datePipe: DatePipe) { }


  	ngOnInit(): void {
		var urlArray = window.location.href.split('/');
  		this.isTeacher = localStorage.getItem('profileType').toLowerCase() =='teacher'
  		const webToken = localStorage.getItem('webtoken');
		this.rest.getPaymentById({_id:urlArray[urlArray.length-1]},webToken).subscribe((response) => {
			if(response.status){
				this.paymentDetails = response.data[0];
			}
		})
  	}

}
