import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ngfor-comma',
  styleUrls: ['./ng-for-comma.component.scss'],
  template: `<span *ngIf="data.length!=0;else notData" class="wordWarp">
  {{data}}
</span>

<ng-template #notData>
    -
</ng-template>`
  
})
export class NgForCommaComponent implements OnInit {
  @Input() forData: any = '';
  @Input() keyName:any =[] ;
  public data:any = [];
  constructor() { }

  ngOnInit(): void {
  	var temp = [];
  	var indexName = this.keyName;
  	this.forData.forEach(function(val,key){
  		Object.keys(val).forEach(function(value,index){
  			if(value == indexName){
  			temp.push(val[value])
  			}
  		})
  		
  	})
  	this.data = temp.length!=0 ? temp.join(',') : ''; 
	}
}

@Component({
  selector: 'commaFor',
  styleUrls: ['./ng-for-comma.component.scss'],
  template: `<span *ngFor="let data of forData;last as isLast" class="wordWarp">
  {{data.name}}<span *ngIf="!isLast">,</span>
</span>

<ng-template #notData>
    -
</ng-template>`
  
})
export class commaForComponent  {
  @Input() forData: any = '';
  @Input() keyName:any =[] ;
  public data:any = [];
  constructor() { }

 
}