
import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../../shared/api.service";
import { DatePipe } from '@angular/common';
import { ToastrManager } from "ng6-toastr-notifications";
import { Router } from '@angular/router';
const datepipe: DatePipe = new DatePipe('en-US')


@Component({
  selector: 'app-completed-studyhall-tab',
  templateUrl: './completed-studyhall-tab.component.html',
  styleUrls: ['./completed-studyhall-tab.component.scss']
})
export class CompletedStudyhallTabComponent implements OnInit {
  public isStudent = false;
  public isTeacher = false;
  public studyHallList = [];
  public classroomList = [];
  public currentPageNumber: number = 1;
  public currentLiveClassPageNumber = 1;
  public currentStudyClassFilter = 'none';
  public totalLiveClassPageCount: number = 1;
  constructor(private rest: ApiService,private router: Router,public toastr: ToastrManager) { }

  ngOnInit() {
    this.isTeacher =
    localStorage.getItem("profileType").toLowerCase() == "teacher";
    this.isStudent =
        localStorage.getItem("profileType").toLowerCase() == "student";
    var reqData = {
      "notFetchUpcoming": true
    }
    this.fetchCompletedStudyHalls(reqData);
  }

  StudyhallClassDetails(data) {
    if (this.isTeacher) {
      
        this.router.navigate(["studyhall-details/" + data._id]);
    } else {
      console.log('fdfdf')
        this.router.navigate(["studyhall-request-details/" + data._id]);
    }
}
  clickLiveClassPageNumber(pageNumber) {
    this.currentLiveClassPageNumber = pageNumber;
    console.log(this.currentLiveClassPageNumber);
    var reqData = {
        notFetchCompleted: false,
        filter: this.currentStudyClassFilter,
        limit: 5,
        page: pageNumber
    };
    this.fetchCompletedStudyHalls(reqData);
  }
  
studyClassFilter(filter){
  if(this.currentStudyClassFilter == filter){

  }
  else{
      this.currentStudyClassFilter = filter;
      this.currentPageNumber = 1;
      var reqData = {
        notFetchCompleted: false,
          filter: filter,
          limit: 5
      };
      this.fetchStudyClasses(reqData);
      console.log('reqData,=========',reqData)
  }
}

 fetchStudyClasses(request){
  const token = localStorage.getItem("webtoken");

  if (this.isTeacher) {
    this.rest
          .getUserStudyClassRequestsTeacher(token,request)
          .subscribe((result) => {
              console.log(result, "result===");
              if (result.status) {
                  this.classroomList = result.data && result.data.data ? result.data.data.upcoming : [];
                  this.totalLiveClassPageCount = Math.ceil(result.data.totalCount / 5)
                  console.log("fetchStudyhalll=================teacher", this.classroomList);
                  for(var item of this.classroomList){
                    //item.enrolledStudents =  item.enrolledStudents.join(',')
                    if(item.startDateTime){
                      item.startDateTime = datepipe.transform(item.startDateTime, 'MMM d, y, h:mm a')
                    }
                  }
              }
          });
  
  } else {
  this.rest
  .getUserStudyClassesStudent(token,request)
  .subscribe((result) => {
      console.log(result, "result======Studyhall");
      if (result.status) {
        this.classroomList = result.data && result.data.data ? result.data.data.upcoming : [];
        this.totalLiveClassPageCount = Math.ceil(result.data.totalCount / 5)
          console.log("fetch studyhall", this.classroomList);
          for(var item of this.classroomList){
            if(item.startDateTime){
              item.startDateTime = datepipe.transform(item.startDateTime, 'MMM d, y, h:mm a')
            }
          }
      }
  });    
  }
}

  fetchCompletedStudyHalls(reqData) {
    const token = localStorage.getItem("webtoken");
    if (this.isTeacher) {
      this.rest
          .getUserStudyClassRequestsTeacher(token, reqData)
          .subscribe((result) => {
              console.log(result, "result===");
              if (result.status) {
                  this.studyHallList = result.data.data.completed;
                  console.log("study hall", this.studyHallList);
                  for(var item of this.studyHallList){
                    if(item.startDateTime){
                      item.startDateTime = datepipe.transform(item.startDateTime, 'MMM d, y, h:mm a')
                    }
                  }
              }
          });
  } else {
      this.rest
      .getUserStudyClassRequestsStudent(token, reqData)
      .subscribe((result) => {
          console.log(result, "result");
          if (result.status) {
              this.studyHallList = result.data.data.completed;
              console.log("study hall", this.studyHallList);
              for(var item of this.studyHallList){
                if(item.startDateTime){
                  item.startDateTime = datepipe.transform(item.startDateTime, 'MMM d, y, h:mm a')
                }
              }
          }
      });
    }
  }

}


