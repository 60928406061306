import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from '../../../shared/api.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-buddi-talk',
  templateUrl: './buddi-talk.component.html',
  styleUrls: ['./buddi-talk.component.scss']
})
export class BuddiTalkComponent implements OnInit {
  public isstudent = false;
  public isteacher = false;
  public meetingsData: any = [];
  public meetingsDataRecord: any = [];
  public meetingsDataListen: any = [];
  public meetingsDataAll: any = [];
  public allmeeting: any = [];
  public inviteUrl = "";
  public frontwebsite = environment.frontUrl;
  public lessonData: any = [];
  public myId: any = ''
  public isLogin: any = 'false';
  public isCancel: any = 'false';
  // public isStudent : any 
  public videoList: any = [];
  public videoListPrivate: any = [];
  public videoListGroup: any = [];
  public teacherVideouploadlist: any = [];
  public videoYoutubePublic: any = [];
  public publicsessionData: any = [];
  public publicsessionDataRecord: any = [];
  public requestedDataList: any = [];
  public requestedDataAccpet: any = [];
  public requestedDataAccpetRecord: any = [];
  public teacherPayoutDetails: any = [];
  public mydashboardData: any = [];
  public isStudent: boolean = false;
  public isTeacher: boolean = false;
  public currentPageNumber : number = 1;
  public totalVideoCount: number;
  public totalPageCount: number;
  public currentVideoCount: number;
  term: string;
  public webinarData: any = [];
  isDesc: any = 1;
  column: string = 'remainingClass';
  p: number = 1;
  count: number = 4;
  teacherDetails = {};
  public categoryID = null;
  public moduleID = null;
  public courseID = null;
  public topicID = null;
  constructor( private SpinnerService: NgxSpinnerService, private rest: ApiService) { }

  ngOnInit(): void {
    const userType = localStorage.getItem('userType');      
    if(userType.toLowerCase()=="teacher") {
      this.isteacher = true;
    }
    else {
      this.isstudent = true;
    }
  }
}
