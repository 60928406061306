import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder,FormControl, Validators } from '@angular/forms';

import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../../shared/api.service';
import {User} from '../../model/user';
import { ToastrManager } from 'ng6-toastr-notifications';
import { SocialAuthService } from 'angularx-social-login';
import { SocialUser } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';


import * as $ from "jquery";
import { Location } from '@angular/common';
@Component({
  selector: 'app-studentlogin',
  templateUrl: './studentlogin.component.html',
  styleUrls: ['./studentlogin.component.scss']
})
export class StudentloginComponent implements OnInit {

  public submitted = false;
  public loginID = new User('', '');
  userType: any;
  otpForm: FormGroup;
  registerform: FormGroup;
  emailform : FormGroup;
  user: SocialUser;
  isLogin:any ="false";  
  public password = 'password';
  public show = false;
  public shows = false;
  public showslength = false;
  otpSubmitted = false;
  phoneNumber;
  interval;
  timeLeft: number = 25;
  changeNumberStatus = false;
  separateDialCode = true;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  isOTPButtonDisabled = false;
  isChangeNumberButtonDisabled = false;
	// phoneForm = new FormGroup({
	// 	phone: new FormControl(undefined, [Validators.required])
	// });


  constructor(private location:Location,public fb: FormBuilder,
    private my_router: ActivatedRoute,public router: Router,public toastr: ToastrManager,private userApi: ApiService,private authService: SocialAuthService) { }

  ngOnInit() {

    // changePreferredCountries() {
    //   this.preferredCountries = [CountryISO.India, CountryISO.Canada];
    // }
  
    document.getElementById('mobilenumber').hidden = false;
    document.getElementById('email').hidden = true;
    document.getElementById('OTPFormIDstudent').hidden = true;
     this.isOTPButtonDisabled = false;

    this.registerform = this.fb.group({
      mobileNumber1: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(20), Validators.pattern("^[0-9 (),.+'-]+$")]],
      phone: new FormControl(undefined, [Validators.required]),
     

    });

    this.emailform = this.fb.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      passWord:  ['', [ Validators.required,Validators.minLength(8),Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@_#$!%*?&])[A-Za-z0-9@$!%*#_?&]{8,}$")]],
    })

    this.otpForm = this.fb.group({
      otp: ['', [Validators.required, Validators.maxLength(6), Validators.minLength(6), Validators.pattern("^[0-9]*$")]]
    })

    let verifytoken = this.my_router.snapshot.paramMap.get('id');
    if (verifytoken !== 'undefined' && verifytoken!=null) { 
      var userData = {};
      userData["token"] = verifytoken;
      this.userApi.activatePage(userData).subscribe((result) => {
          if (result.status) {
            this.toastr.successToastr(result.message);
            this.router.navigate(['student/login']);
          } else {
            this.toastr.errorToastr(result.message);
            this.router.navigate(['student/login']);
          }
      }, (err) => {
          console.log(err);
      });
    }
    localStorage.setItem('teacherID', '');
    localStorage.setItem('StudentID', '');
    localStorage.setItem('profileName','');
    localStorage.setItem('userType', '');
    localStorage.setItem('isLoggedin','false');
    this.isLogin = localStorage.getItem('isLoggedin');    
    this.authService.authState.subscribe((user) => {
      this.user = user;
      if(this.user.provider == "FACEBOOK"){
        this.userApi.facebookLinktopage({
          access_token:this.user.authToken
        },'student').subscribe((result) => {
          console.log('result after subs', result);    
          if (result.success) {    
            localStorage.setItem('webtoken', result.token);
            localStorage.setItem('userId', result.user_id);
            localStorage.setItem('profileName', result.profileName);           
            localStorage.setItem('userType', result.userType);
            localStorage.setItem('profileType', 'Student');
            localStorage.setItem('StudentID', result.user_id);
            localStorage.setItem('isLoggedin', 'true');
            this.toastr.successToastr('Welcome , you are now logged in!  ');
            this.userType = result.userType;
            console.log(this.userType);
            var inviteid = this.my_router.snapshot.queryParamMap.get('invite');
            if(inviteid == null){
              if(window.history.state.isredirect){
                this.location.back();
              }else{
                window.location.href = '/sidenav';
              }            
            }else{
              var split_id = inviteid.split('-');
              window.location.href = '/invite/'+split_id[1];
            }
          } 
          else {
            this.toastr.errorToastr(result.message);
          }
        }, (err) => {
        console.log(err);
        });
      }else{
        this.userApi.getaccessTokenSocial({
          access_token:this.user.idToken
        },'student').subscribe((result) => {
          console.log('result after subs', result);    
          if (result.success) {    
            localStorage.setItem('webtoken', result.token);
            localStorage.setItem('userId', result.user_id);
            localStorage.setItem('profileName', result.profileName);
            localStorage.setItem('userType', result.userType);
            localStorage.setItem('StudentID', result.user_id);
            localStorage.setItem('profileType', 'Student');
            localStorage.setItem('isLoggedin', 'true');
            this.toastr.successToastr('Welcome , you are now logged in!  ');
            this.userType = result.userType;
            console.log(this.userType);
            var inviteid = this.my_router.snapshot.queryParamMap.get('invite');
            if(inviteid == null){
              if(window.history.state.isredirect){
                this.location.back();
              }else{
                window.location.href = '/sidenav';
              }  
            }else{
              var split_id = inviteid.split('-');
              window.location.href = '/invite/'+split_id[1];
            }       
          } 
          else {
            this.toastr.errorToastr(result.message);
          }
        }, (err) => {
        });
      }
    }); 
  }

  get emailf(){
    return this.emailform.controls;
  }
  get f() { return this.registerform.controls; }

  get otpf() { return this.otpForm.controls; }
  showmobile(){
    document.getElementById('mobilenumber').hidden = false;
    document.getElementById('email').hidden = true;
    
  }
  showemail(){

    document.getElementById('mobilenumber').hidden = true;
    document.getElementById('email').hidden = false;
  }
  submitEmail(emailform){


    this.submitted = true;
    this.loginID.email = this.loginID.email.toLowerCase();
    // if (this.loginID.email === '' || this.loginID.passWord === '') {
    //   this.toastr.errorToastr('Input User Name and Password');
    // } 
    // else {
      var req={
       "email" : this.emailform.value.email,
        "password" :this.emailform.value.passWord
      }
      this.userApi.loginPageStudent(req).subscribe((result) => {
        console.log('result',result)
      if (result.success) {
        localStorage.setItem('webtoken', result.token);
        localStorage.setItem('userId', result.user_id);
        localStorage.setItem('profileName', result.profileName);
        localStorage.setItem('userType', result.userType);        
        localStorage.setItem('groupname', result.groupName);
        localStorage.setItem('profileType', 'Student');
        localStorage.setItem('StudentID', result.user_id);
        localStorage.setItem('isLoggedin', 'true');
        this.toastr.successToastr('Welcome , you are now logged in!  ');
        this.userType = result.userType;
        var inviteid = this.my_router.snapshot.queryParamMap.get('invite');
        if(inviteid == null){
          if(window.history.state.isredirect){
            this.location.back();
          }else{
            window.location.href = '/enroll-classes';
          }  
        }else{
          var split_id = inviteid.split('-');
          window.location.href = '/invite/'+split_id[1];
        }
      } 
      else {
        this.toastr.errorToastr(result.message);
      }
    }, (err) => {
    console.log(err);
    });
  // }
}

  
  startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } 
      else {
        document.getElementById('otpResendTimer').hidden = true;
        document.getElementById('otpResendButton').hidden = false;
        
        clearInterval(this.interval);
      }
    },1000)
  }


  loginSubmit() {

    this.timeLeft = 20;
    this.startTimer();
    document.getElementById('otpResendButton').hidden = true;

    // this.isOTPButtonDisabled = true;

    document.getElementById('loginFormIDstudent').hidden = true;
    document.getElementById('OTPFormIDstudent').hidden = false;
      console.log('checking register',this.registerform.value)
      this.submitted = true;

      if (this.registerform.value.mobileNumber1 == null || this.registerform.value.mobileNumber1 == undefined || this.registerform.value.mobileNumber1 == '') {
        this.toastr.errorToastr('Require Valid MobileNumber');
        document.getElementById('OTPFormIDstudent').hidden = true;
        document.getElementById('loginFormIDstudent').hidden = false;
  
        this.shows = true;
      }
      else {
        if (this.registerform.value.mobileNumber1.length < 10) {
          this.showslength = true;
        }
      // console.log(this.registerform.invalid);
      var phoneNumber = this.registerform.value.mobileNumber1.number;
      console.log('replace',phoneNumber);
      this.phoneNumber= phoneNumber.replace(/\s/g, '');
      console.log('find values',this.phoneNumber)
      if(!this.changeNumberStatus) {
        if(!this.phoneNumber.invalid) {
          console.log('fdfdfdf')
          if (this.registerform.value.passWord === this.registerform.value.checkpassWord) {
            const data = this.registerform.value;
            console.log(data);
            data.profileName = data.first_name;
            data.cpassword = data.checkpassWord;

          //   this.userApi.AddUserWithoutOTP(data).subscribe(res => {
          //   console.log('result', res );
          //   if(res.status){
          //     this.toastr.successToastr(res.message);
          //     this.router.navigate(['/login']);
          //   }else{
          //     this.toastr.errorToastr(res.message);
          //   }
          // });
          var req={
            "phoneNumber": this.phoneNumber, 
          }
            this.userApi.checkUserStudent(req).subscribe(res => {
            console.log('result', res );


            if(res.status != true)
            {
              // this.toastr.successToastr("Please Submit the New User details ");
              if(res.isTeacher){
                this.toastr.errorToastr("This phone number is already linked to a teacher account");
                this.teacherLogin()
              }
              else{
                document.getElementById('OTPFormIDstudent').hidden = true;
                document.getElementById('loginFormIDstudent').hidden = false;
                window.location.href = '/student/register';
              }
              
            }
            if(res.status == true){
              // document.getElementById('loginFormIDt').hidden = false;
              document.getElementById('OTPFormIDstudent').hidden = false;
              document.getElementById('header').hidden = true;
              document.getElementById('signedin').hidden = true;
              document.getElementById('successmessage').hidden = false;
              // document.getElementById('OTPFormIDt').hidden = false;
              // this.startTimer()
            //  this.toastr.successToastr(res.message);
            }else{
              //this.toastr.successToastr("Almost done! Fill the fields to complete registration ");
              // this.toastr.errorToastr(res.message);
              // alert(res.message)
            }
          });
          }   
        } 
        else {
            this.toastr.errorToastr('Please check all fields');
        }
      }
      }
      // else{
      //   if(!this.registerform.invalid) {
      //     if (this.registerform.value.passWord === this.registerform.value.checkpassWord) {
      //       const data = this.registerform.value;
      
      //       this.userApi.changePhoneNumber(data).subscribe(res => {
      //       console.log('result', res );
      //       if(res.status){
      //         document.getElementById('loginFormIDt').hidden = true;
      //         // document.getElementById('OTPFormIDt').hidden = false;
      //         // document.getElementById('otpResendTimer').hidden = false;
      //         // this.startTimer()
      //         this.toastr.successToastr(res.message);
      //       }else{

      //         this.toastr.errorToastr(res.message);
      //       }
      //       //this.toastr.successToastr('Activation Mail Sent to your Registered email ID, Check your Mailbox');
      //       //this.router.navigate(['/login']);
      //     });
      //     }
      //     else {
      //       this.toastr.errorToastr('Password Mismatch');
      //       // alert("Insert Valid Password")
      //     }
      //   } 
      //   else {
      //       //this.toastr.errorToastr('You must fill all fields');
      //       this.toastr.errorToastr('Please check all fields');
      //   }        
      // }
  }

  otpResendClicked() {
    console.log("Clicked");
    document.getElementById('otpResendButton').hidden = true;
    document.getElementById('otpResendTimer').hidden = false;
    this.timeLeft = 60;
    this.startTimer();
    console.log('checking resend otp', this.phoneNumber)
    // var data = this.phoneNumber;
    var req={
      "phoneNumber": this.phoneNumber, 
    }
    this.userApi.checkUserStudent(req).subscribe(res => {
      console.log('resendOtp',res);
      if(res.status){
        this.toastr.successToastr(res.message);
        //this.router.navigate(['/login']);
      }
      else {
        this.toastr.errorToastr(res.message);
      }
    });

    (<HTMLInputElement> document.getElementById("otpResendButton")).disabled = true;
    this.isOTPButtonDisabled = true;
    document.getElementById('otpResendButton').hidden = true;
    document.getElementById('otpSentSuccess').hidden = false;
   

    

  }
  changePhoneNumber() {
    this.changeNumberStatus = true;
    // document.getElementById('loginFormIDstudent').hidden = false;
    // document.getElementById('OTPFormIDstudent').hidden = true;
    // document.getElementById('otpResendTimer').hidden = true;
    // document.getElementById('otpResendButtont').hidden = true;
    window.location.href = "/student/login/";

    // clearInterval(this.interval);
    // this.timeLeft = 15;
  }

  studentLogin(){
    window.location.href = '/student/login';
  }
  teacherLogin(){
    window.location.href = '/teacher/login';
  }
  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(x => console.log(x));
  }
  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(x => console.log(x));
  }
  signOut(): void {
    this.authService.signOut();
  }

//   onLoggedin(userform) {
//     this.submitted = true;
//     this.loginID.email = this.loginID.email.toLowerCase();
//     if (this.loginID.email === '' || this.loginID.passWord === '') {
//       this.toastr.errorToastr('Input User Name and Password');
//     } 
//     else {
//       var req={
//         email: this.loginID.email,
//         password:this.loginID.passWord 
//       }
//       this.userApi.loginPageStudent(req).subscribe((result) => {
//       if (result.success) {
//         localStorage.setItem('webtoken', result.token);
//         localStorage.setItem('userId', result.user_id);
//         localStorage.setItem('profileName', result.profileName);
//         localStorage.setItem('userType', result.userType);        
//         localStorage.setItem('groupname', result.groupName);
//         localStorage.setItem('profileType', 'Student');
//         localStorage.setItem('StudentID', result.user_id);
//         localStorage.setItem('isLoggedin', 'true');
//         this.toastr.successToastr('Welcome , you are now logged in!  ');
//         this.userType = result.userType;
//         var inviteid = this.my_router.snapshot.queryParamMap.get('invite');
//         if(inviteid == null){
//           if(window.history.state.isredirect){
//             this.location.back();
//           }else{
//             window.location.href = '/student/mydashboard';
//           }  
//         }else{
//           var split_id = inviteid.split('-');
//           window.location.href = '/invite/'+split_id[1];
//         }
//       } 
//       else {
//         this.toastr.errorToastr(result.message);
//       }
//     }, (err) => {
//     console.log(err);
//     });
//   }
// }


OTPSubmit() {
  this.otpSubmitted = true;
  var data = this.registerform.value;
   console.log('resultttsss', this.loginID);
 
   var req = {
    "phoneNumber": this.phoneNumber,
    "otp": this.otpForm.value.otp
  }   
  if (!this.otpForm.invalid) {
    data["otp"] = this.otpForm.value.otp;
    this.userApi.checkOTPforgotStudent(req).subscribe(result => {
      console.log(result,'result==================checkOTPTeacherValid')
      if (result.status) {
       console.log(result.data,'-----------reasdfdfdfdfdfdfdfd---------')
        var data = result.data.student;
                localStorage.setItem('webtoken', data.token);
                localStorage.setItem('StudentID', data.user_id);
                localStorage.setItem('isPartner', (data.email == 'buddilife@gmail.com' ? 'true' : 'false'));
                localStorage.setItem('profileName', data.profileName);
                localStorage.setItem('userType', data.userType);
                localStorage.setItem('isLoggedin', 'true');
                localStorage.setItem('userId', data.user_id);
                localStorage.setItem('groupname', data.groupName);
                localStorage.setItem('profileType', 'Student');
      

           
                //         var inviteid = this.my_router.snapshot.queryParamMap.get('invite');
                //         if(inviteid == null){
                //           if(window.history.state.isredirect){
                //             this.location.back();
                //           }else{
                //             window.location.href = '/student/mydashboard';
                //           }  
                //         }else{
                //           var split_id = inviteid.split('-');
                //           window.location.href = '/invite/'+split_id[1];
                //         }
                //       } 
                //       else {
                //         this.toastr.errorToastr(result.message);
                //       }
                //     }, (err) => {
                //     console.log(err);
                //     });
                //   }
                // }
                this.toastr.successToastr('Welcome , you are now logged in!  ');
                this.userType = data.userType;
                console.log(this.userType);
                var inviteid = this.my_router.snapshot.queryParamMap.get('invite');
                if(inviteid == null){
                            if(window.history.state.isredirect){
                              this.location.back();
                            }else{
                              window.location.href = '/enroll-classes';
                            }  
                          }else{
                            var split_id = inviteid.split('-');
                            window.location.href = '/invite/'+split_id[1];
                          }
                        
        this.toastr.successToastr(result.message);
        this.router.navigate(['/student/mydashboard']);
      } else {
        this.toastr.errorToastr(result.message);
      }
    }, (err) => {
          console.log(err);
    });
  }
  else {
    console.log(this.otpForm);
    this.toastr.errorToastr("OTP Cannot be empty");
  }
}
  clearInput(type) {
    this.loginID.email = null;
    this.loginID.passWord = null;
    $(".loginTab").removeClass('active');
    $("#"+type+"-tab").addClass('active');
    $(".loginContent").removeClass('show').removeClass('active');
    $("#"+type).addClass('show').addClass('active');
  }
   passwordTypeChange(){
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }

  feedbackform(webToken) {
    this.userApi.getfeedbPendingTeacher(webToken).subscribe((result) => {
      console.log(result, 'result');
      if (result.data.length != 0) {
        localStorage.setItem('isPendindTeacher', 'true');
        // this.toastr.successToastr(result.message);
        this.router.navigate(['feedbackTeacher']);
      }
      else {
        // window.location.href = '/kyc';
        window.location.href = '/sidenav';
      }
    }, (err) => {
      console.log(err);
    });
  }
  receivedData(data){
    this.password = data;
  }
}
