<app-header-style-one *ngIf="!isTeacher"></app-header-style-one>
    <app-header-style-one-teacher
        *ngIf="isTeacher"
    ></app-header-style-one-teacher>
<div class="data">

<div class="cards">
<div class="arrange"><p>What are you looking to do in Learning Assets ?</p></div>
<div class="row">

    <div class="col-lg-4 col-md-6" >
        <div class="card" style="width: 18rem;">
            <img src="../../../../assets/img/video-lesson.jpeg" alt="Video class" routerLink="/all-video-listing" class="card-img-top" alt="...">
            <div class="card-body">
              <h5 routerLink="/all-video-listing" class="card-title"> Video Lessons</h5>
              <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> -->
              <a routerLink="/all-video-listing">Explore   <i class="fas fa-arrow-right"></i></a>
            </div>
            
          </div>
    </div>

    <div class="col-lg-4 col-md-6">
        <div class="card" style="width: 18rem; ">
            <img src="../../../../assets/img/Study Materials.jpeg" alt="Study Materials" routerLink="/all-study-listing" class="card-img-top" alt="...">
            <div class="card-body">
              <h5 routerLink="/all-study-listing" class="card-title">Study Material</h5>
              <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> -->
              <a routerLink="/all-study-listing">Explore   <i class="fas fa-arrow-right"></i></a>
            </div>
            
          </div>
    </div>

    <div class="col-lg-4 col-md-6" >
        <div class="card" style="width: 18rem; ">
            <img src="../../../../assets/img/Evaluation Tests.jpeg" alt="Evaluation Tests" routerLink="/all-test-listing" class="card-img-top" alt="...">
            <div class="card-body">
              <h5 routerLink="/all-test-listing" class="card-title"> Evaluation Tests</h5>
              <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> -->
              <a routerLink="/all-test-listing">Explore   <i class="fas fa-arrow-right"></i></a>
            </div>
            
          </div>
    </div>

</div>
