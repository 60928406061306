import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partner-style-one',
  templateUrl: './partner-style-one.component.html',
  styleUrls: ['./partner-style-one.component.scss']
})
export class PartnerStyleOneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
