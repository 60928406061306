import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emptyObject'
})
export class EmptyObjectPipe implements PipeTransform {

  transform(value) {
  	if(value!=undefined){
  		if(Object.keys(value).length!=0){
    		return value[Object.keys(value)[0]];
    	}else{
    		return '-';
    	}
  	}else{
  		return '-';
  	}
   
  }

}
