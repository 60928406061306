<div class="container">
    <div class="card-deck">
        <div class="col-lg-4 col-md-6" routerLink="/notifications" style="cursor:pointer ;">
           <div class="card text-center">
            <img src="../../assets/img/notification.jpeg" alt="">
            <div class="card-body">
                Notifications
            </div>
           </div>
        </div>
    </div>
</div>
        