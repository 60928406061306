<div *ngIf="!isStudent">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>
<div *ngIf="isStudent">
    <app-header-style-one></app-header-style-one>
</div>
<!-- <div class="container" routerLink="/sidenav"></div> -->
<div *ngIf="isStudent == true && isTeacher == false" class="container mt-3 mb-3">
    <div class="flexButton">
    <h5 style="color: #000000;">Upcoming Live Classes</h5>
    <div class="btn-group ml-3" >
<button type="button"  (click)="liveClassFilter('today')">Today</button> 
<button type="button"  (click)="liveClassFilter('tomorrow')"> Tomorrow</button>
<button type="button"  (click)="liveClassFilter('week')">This Week</button>
<button type="button"  (click)="liveClassFilter('month')">This Month</button>
<button type="button"  (click)="backto()"><i class="fas fa-long-arrow-alt-left mr-1"></i>Back</button>
    </div>
    </div>
   
    <br>
    <div class="container" style="margin: 0;padding: 0;">
        <div class="orders-table table-responsive mb-3">
            <table class="table">
                <thead>
                    <tr>
                        <th>Sl.No</th>
                        <th>Class Name</th>
                        <th>Topic Name</th>
                        <th>Date & Time</th>
                        <th>Tutor Name</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor='let class of classList; let i = index'>
                        <td>{{i + 1}}</td>
                        <td><a routerLink="javascript:void(0)" (click)="LiveClassDetails(class)" class="d-inline-block">{{class.name}}</a></td>
                        <td>{{class.personalClassID && class.personalClassID.topicID ? class.personalClassID.topicID.topicName : '-'}}</td>
                        <td>{{class.startDateTime}}</td>
                        <td>{{class.fullName}}</td>
                        <td class="align"><a *ngIf="class.isAccepted == true" href="javascript:void(0)" class="classAccepted" (click)="liveClassFunction(class, 'join')"> <i class="fas fa-sign-in-alt"  data-toggle="tooltip" data-placement="bottom" title="Join class"></i></a><br  *ngIf="class.isAccepted == true">
                        <a *ngIf="class.isAccepted == false" href="javascript:void(0)" class="classPending" (click)="liveClassFunction(class, 'pending')"><i class="fas fa-spinner" data-toggle="tooltip" data-placement="bottom" title="Pending"></i></a><br  *ngIf="class.isAccepted == false">
                        <a href="javascript:void(0)" [ngClass]="class.isAccepted == true ? 'classAccepted' : 'classPending'" (click)="liveClassFunction(class, 'reschedule')"><i class="far fa-calendar-alt" data-toggle="tooltip" data-placement="bottom" title="Reschedule"></i></a><br>
                        <a href="javascript:void(0)" [ngClass]="class.isAccepted == true ? 'classAccepted' : 'classPending'" (click)="liveClassFunction(class, 'cancel')"><i class="fas fa-ban" data-toggle="tooltip" data-placement="bottom" title="Cancel"></i></a><br>
                        <a href="javascript:void(0)" [ngClass]="class.isAccepted == true ? 'classAccepted' : 'classPending'" (click)="liveClassFunction(class, 'share')"> <i class="fas fa-share-alt-square" data-toggle="tooltip" data-placement="bottom" title="Share"></i></a></td>
                    </tr>
                </tbody>
            </table>
            <h4
            style="color: #000000; text-align: center"
            class="col-lg-12 mt-3"
            *ngIf="classList.length == 0"
        >
        No Live classes Available
        </h4>
            <div class="pagination-area text-center">
                    <a
                        href="javascript:void(0)"
                        (click)="clickLiveClassPageNumber(1)"
                        *ngIf="currentLiveClassPageNumber > 2 && totalLiveClassPageCount > 1"
                        class="page-numbers"
                        >1</a
                    >
                    <span
                        class="page-numbers"
                        *ngIf="currentLiveClassPageNumber > 2 && totalLiveClassPageCount > 1"
                        aria-current="page"
                        >...</span
                    >
                    <a
                        href="javascript:void(0)"
                        (click)="clickLiveClassPageNumber(currentLiveClassPageNumber - 1)"
                        *ngIf="currentLiveClassPageNumber > 1 && totalLiveClassPageCount > 1"
                        class="page-numbers"
                        >{{ currentLiveClassPageNumber - 1 }}</a
                    >
                    <span
                        class="page-numbers current"
                        *ngIf="totalLiveClassPageCount != 0"
                        aria-current="page"
                        >{{ currentLiveClassPageNumber }}</span
                    >
                    <a
                        href="javascript:void(0)"
                        (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 1)"
                        *ngIf="
                            totalLiveClassPageCount > 1 &&
                            currentLiveClassPageNumber + 1 <= totalLiveClassPageCount
                        " class="page-numbers">{{ currentLiveClassPageNumber + 1 }}</a>
                <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 2)" *ngIf="
                            totalLiveClassPageCount > 1 &&
                            currentLiveClassPageNumber + 2 <= totalLiveClassPageCount
                        " class="page-numbers">{{ currentLiveClassPageNumber + 2 }}</a>
                <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 3)" *ngIf="
                            totalLiveClassPageCount > 1 &&
                            currentLiveClassPageNumber + 3 <= totalLiveClassPageCount
                        " class="page-numbers">{{ currentLiveClassPageNumber + 3 }}</a>
                <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 4)" *ngIf="
                            totalLiveClassPageCount > 1 &&
                            currentLiveClassPageNumber + 4 <= totalLiveClassPageCount
                        " class="page-numbers">{{ currentLiveClassPageNumber + 4 }}</a>
                <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 1)" *ngIf="
                            totalLiveClassPageCount > 1 &&
                            currentLiveClassPageNumber + 1 <= totalLiveClassPageCount
                        " class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
            </div>
        </div>        
    </div>
    <!--<div style="color: #aba3a3" *ngIf="classList.length == 0">
        No Live Classes Available.
    </div>-->
</div>

<div *ngIf="isTeacher == true" class="container mt-3">
    <!-- <h3 style="color: var(--mainColor); cursor: pointer" class="col-lg-12">
        Live Classes
    </h3> --><br />
    <div class="flexButton">
        <h5 style="color: #000000;"
            >
            Upcoming Live Classes
            <!-- <span class="more-text" *ngIf="classList">more</span> -->
        </h5>
            <div class="btn-group ml-3"  >
                <button type="button"  (click)="liveClassFilter('today')">Today</button> 
       <button type="button"  (click)="liveClassFilter('tomorrow')"> Tomorrow</button>
       <button type="button"  (click)="liveClassFilter('week')"> This Week</button>
       <button type="button"  (click)="liveClassFilter('month')"> This Month</button>
       <button type="button"  routerLink="/sidenav"><i class="fas fa-long-arrow-alt-left mr-1"></i>Back</button>
    </div>
    </div>
    <br>
    <div class="container" style="margin: 0;padding: 0;">
        <div class="orders-table table-responsive mb-3" >


            <table class="table">
                <thead>
                    <tr>
                        <th>Sl.No</th>
                        <th>Class Name</th>
                        <th>Topic Name</th>
                        <th>Date & Time</th>
                        <th>Learner Name</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor='let class of classList; let i = index'>
                        <td>{{i + 1}}</td>
                        <td><a routerLink="javascript:void(0)" (click)="LiveClassDetails(class)" class="d-inline-block">{{class.name}}</a></td>
                        <td>{{class.personalClassID.topicID.topicName}}</td>
                        <td>{{class.startDateTime}}</td>
                        <td>{{class.studentID.firstName && class.studentID.firstName ? class.studentID.firstName + " " + class.studentID.lastName : class.studentID.profileName}}</td>
                        <td class="align"><a href="javascript:void(0)"
                                *ngIf="class.isAccepted == false"
                                [ngClass]="class.isAccepted == true ? 'classAccepted' : 'classPending'"
                                (click)="liveClassFunction(class, 'accept')">|<i class="fas fa-calendar-check" data-toggle="tooltip" data-placement="bottom" title="Accept"></i></a><br *ngIf="class.isAccepted == false">
                            <a href="javascript:void(0)"
                                *ngIf="class.isAccepted == false"
                                [ngClass]="class.isAccepted == true ? 'classAccepted' : 'classPending'"
                                (click)="liveClassFunction(class, 'reject')"> <i class="fas fa-ban" data-toggle="tooltip" data-placement="bottom" title="Reject"></i></a><br *ngIf="class.isAccepted == false">
                            <a href="javascript:void(0)"
                                *ngIf="class.isAccepted == true"
                                [ngClass]="class.isAccepted == true ? 'classAccepted' : 'classPending'"
                                (click)="liveClassFunction(class, 'start')"> <i class="far fa-play-circle" data-toggle="tooltip" data-placement="bottom" title="Start class"></i></a><br *ngIf="class.isAccepted == true">
                            <a href="javascript:void(0)"
                                [ngClass]="class.isAccepted == true ? 'classAccepted' : 'classPending'"
                                (click)="liveClassFunction(class, 'reschedule')"> <i class="far fa-calendar-alt" data-toggle="tooltip" data-placement="bottom" title="Reschedule"></i></a><br>
                            <a href="javascript:void(0)"
                                [ngClass]="class.isAccepted == true ? 'classAccepted' : 'classPending'"
                                (click)="liveClassFunction(class, 'cancel')"> <i class="fa fa-window-close" data-toggle="tooltip" data-placement="bottom" title="Cancel"></i></a><br>
                            <a href="javascript:void(0)"
                                [ngClass]="class.isAccepted == true ? 'classAccepted' : 'classPending'"
                                (click)="liveClassFunction(class, 'share')">  <i class="fas fa-share-alt-square" data-toggle="tooltip" data-placement="bottom" title="Share"></i></a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h4
            style="color: #000000; text-align: center"
            class="col-lg-12 mt-3"
            *ngIf="classList.length == 0"
        >
        No Live classes Available
        </h4>
        <div class="pagination-area text-center">
            <a
                href="javascript:void(0)"
                (click)="clickLiveClassPageNumber(1)"
                *ngIf="currentLiveClassPageNumber > 2 && totalLiveClassPageCount > 1"
                class="page-numbers"
                >1</a
            >
            <span
                class="page-numbers"
                *ngIf="currentLiveClassPageNumber > 2 && totalLiveClassPageCount > 1"
                aria-current="page"
                >...</span
            >
            <a
                href="javascript:void(0)"
                (click)="clickLiveClassPageNumber(currentLiveClassPageNumber - 1)"
                *ngIf="currentLiveClassPageNumber > 1 && totalLiveClassPageCount > 1"
                class="page-numbers"
                >{{ currentLiveClassPageNumber - 1 }}</a
            >
            <span
                class="page-numbers current"
                *ngIf="totalLiveClassPageCount != 0"
                aria-current="page"
                >{{ currentLiveClassPageNumber }}</span
            >
            <a
                href="javascript:void(0)"
                (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 1)"
                *ngIf="
                    totalLiveClassPageCount > 1 &&
                    currentLiveClassPageNumber + 1 <= totalLiveClassPageCount
                " class="page-numbers">{{ currentLiveClassPageNumber + 1 }}</a>
        <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 2)" *ngIf="
                    totalLiveClassPageCount > 1 &&
                    currentLiveClassPageNumber + 2 <= totalLiveClassPageCount
                " class="page-numbers">{{ currentLiveClassPageNumber + 2 }}</a>
        <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 3)" *ngIf="
                    totalLiveClassPageCount > 1 &&
                    currentLiveClassPageNumber + 3 <= totalLiveClassPageCount
                " class="page-numbers">{{ currentLiveClassPageNumber + 3 }}</a>
        <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 4)" *ngIf="
                    totalLiveClassPageCount > 1 &&
                    currentLiveClassPageNumber + 4 <= totalLiveClassPageCount
                " class="page-numbers">{{ currentLiveClassPageNumber + 4 }}</a>
        <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 1)" *ngIf="
                    totalLiveClassPageCount > 1 &&
                    currentLiveClassPageNumber + 1 <= totalLiveClassPageCount
                " class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
    </div>
        </div>
        <!--<div style="color: #aba3a3" class="col-lg-12"  *ngIf="classList.length == 0">
            No Live Classes Available.
        </div>-->
    </div>
</div>