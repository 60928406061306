import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../shared/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as socketio from 'socket.io-client';
import { environment } from '../../../../environments/environment'
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-marketplace',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.scss']
})
export class MarketplaceComponent implements OnInit {
  public isstudent = false;
  public isteacher = false;
  constructor() { }

  ngOnInit(): void {
    const userType = localStorage.getItem('userType');      
    if(userType.toLowerCase()=="teacher") {
      this.isteacher = true;
    }
    else {
      this.isstudent = true;
    }
  }
}