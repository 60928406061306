<app-metaverse-header *ngIf="isStudent"></app-metaverse-header>
    <app-metaverse-header-teacher
        *ngIf="isTeacher"
    ></app-metaverse-header-teacher>

<app-nft-banner></app-nft-banner>

<div class="space"></div>
<app-nft-education></app-nft-education>
<div class="space"></div>
<div class="background">
    <div class="space"></div>
<app-metaverse-student-funfacts></app-metaverse-student-funfacts>
<div class="space" style="margin-top: 5%;"></div>
<app-buddi-unique-model></app-buddi-unique-model>
<div class="space"></div>
<app-tutor-learner></app-tutor-learner>
<div class="space"></div>
<section class="instructor-area pt-100 pb-70">
    <div class="container ">
        <div class="section-title">
            <!-- <span class="sub-title">Make Connections</span> -->
            <h2 style="color: white;"> Browse by Category</h2>
            <!--  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea.</p>-->
        </div>
  
      <app-nft-explore></app-nft-explore>
      
    </div>
    </section>
    <div class="space"></div>
</div>