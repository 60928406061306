<div *ngIf="isstudent">
    <app-header-style-one></app-header-style-one>
</div>
<div *ngIf="isteacher">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>

<!-- <div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About</li>
            </ul>
            <h2>About Us</h2>
        </div>
    </div>
</div> -->

<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <!-- <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    
                    <img src="../../../../assets/img/aboutus.jpg" class="shadow" alt="image">
                  
                </div>
            </div> -->
          
            <div class="col-lg-12 col-md-12">
                <div class="about-content">
                                        
                    <p style="font-size: 40px; color: #07095a; text-align:center"><b>the buddi</b></p>   
                   <p style="font-size: 20px; color: black;">Large Reach – Low Cost – High Volume – Wide Network             </p>
                              
                </div>
            </div>
            
            <div class="col-lg-12 col-md-12" style="text-align: center; padding-top: 50px;">
                <div class="about-content">
                    <img src="../../../../assets/img/buddi unique model.jpeg" alt="buddi unique model">
                </div>
            </div>
            <div class="col-lg-12 col-md-12" style="text-align: center; padding-top: 50px;">
                <div class="about-content">
                    <img src="../../../../assets/img/Deliverymode.png" alt="Deliverymode">
                </div>
            </div>
            <div class="col-lg-12 col-md-12" style="text-align: center; ">
                <div class="about-content">
                    <!-- <img src="../../../../assets/img/Whybuddi.png" alt="Whybuddi"> -->
                    
                    <p style="font-size: 40px; color: #07095a; text-align:center; line-height:100px "><b>buddi.life Four Pillars</b></p> 
                    
                </div>
                
            </div>
            <ul>
                <li>Online Micro Learning, Low cost, Marketplace to connect Learners,
                    Tutors, Schools, Institutions</li>
                <li>Metaverse based immersive learning experience with 3D Models</li>
                <li> Reward programs using buddi Tokens to incentivize Tutors and
                    Learners</li>
                    <li> Create, lists and sell educational Digital NFT’s on Marketplace</li>
              </ul> 

              <div class="col-lg-12 col-md-12" style="text-align: center; padding-top: 50px;">
                <div class="about-content">
 
            <p style="font-size: 40px; color: #07095a; text-align:center; line-height:100px"><b>the Solution</b></p> 
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <ul>
                      <h4 style="line-height: 90px;">  buddi approach solves the problems via:</h4>
                        <li>Micro Learning: Modular and Cost-effective
                            </li>
                        <li> Metaverse: Immersive Learning Experience
                            Scholarship Program: Empower and assist 
                              learners to aspire for higher Studies</li>
                        <li>   buddiCERT and NFT:  NFT Based Academic  
                            Certificates and Educational Assets</li>
                          
                      </ul> 
                    </div>
                
                <div class="col-md-6">
                    <img src="../../../../assets/img/solution.jpeg" alt="solution">
                </div>
            </div>
            

            <div class="col-lg-12 col-md-12" style="text-align: center; padding-top: 30px;">
                <!-- <div class="about-content">
                   <p style="font-size: 22px; text-align: justify; color: black;">We understood flaws in teaching in the current model and made a small step contribution from our side in education to rectify that. We wanted to offer two things:</p>
                   <p style="font-size: 20px; text-align: justify; color: black;">1. Making learning personalised and adaptive.</p>
                   <p style="font-size: 20px; text-align: justify; color: black;">2. Bring predictability to results.</p>
                   <p style="font-size: 21px; text-align: justify; color: black;">These are achieved by using various tools as shown in the following illustration, incorporating the use of AI and ML based algorithms.</p>
                </div> -->
            </div>
            <!-- <div class="col-lg-12 col-md-12" style="text-align: center; padding-top: 30px;">
                <div class="about-content">
                    <img src="../../../../assets/img/buddi-arch.jpg" class="shadow" alt="image">
                    <p style="font-size: 20px; text-align: justify; padding-top: 30px; color: black;">Our focus and goal is to give the best teaching we can, to students and have them excel in their studies. </p>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/UdSahnSDt3Q" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <p style="font-size: 19px; text-align: justify; padding-top: 30px; color: black;">buddi is an AI powered adaptive learning platform that takes a much personalised approach to teaching by bringing the best coaching to students. It integrates live one-on-one classes, group classes, courses, video classes and audio classes to prepare for K12, NEET and JEE exams. Our USP is to offer a personalised approach to each student to bring an assured improvement in their performance. Our teachers are well trained to bring the best in every student. buddi with over 1,000's students is expanding to over 4 countries and is led by a well experienced global team.</p> 
                </div>
            </div>-->
        </div>

    </div>
</section>