<div *ngIf="isstudent">
    <app-header-style-one></app-header-style-one>
</div>
<div *ngIf="isteacher">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>

<div class="containerBg">
    <div class="container">
        <div class="row mt-5">
            <div class="col-md-12">
                <div class="serviceTitle">
                    <h3 class="col-lg-12" labelStyle>
                        <span>
                            <span class="ml-2" style="color: black;"
                                >{{
                                    this.editData._id ? "Update" : "Add"
                                }}
                                Learner/Tutor</span
                            >
                        </span>
                        <button
                            type="button"
                            class="default-btn mb-2 ml-2 float-right p-2"
                            (click)="goBack()"
                            ><i class="fas fa-long-arrow-alt-left"></i
                            >&nbsp;Back</button>
                    </h3>
                </div>
                <hr />
            </div>
        </div>
       
            
        <div class="container">
            <h4>Add Learner</h4>
            <form [formGroup]="createForm">
                <div class="row">
                    <div class="col-md-6 description">
                        <div class="form-group mb-3">
                            <label labelStyle>Student Email/Phone number</label>
                            <input
                                type="email"
                                formControlName="studentmail"
                                pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$"
                                class="form-control"
                            /> 
                            <div *ngIf="submitted && f.studentmail.errors" class="invalid-feedback">
                                <div *ngIf="f.studentmail.errors.required">studentmail is required</div>
                           
                              </div>
                        </div>
                        <button
                        style="width: 30%"
                        type="button"
                        (click)="addStudent('Add')"
                        class="default-btn p-2"
                        id="addMaterialButton"
                    >
                        <i class="bx bx-save icon-arrow before"></i>
                        <span class="label"
                            >{{
                                this.editData.length != 0
                                    ? "Update"
                                    : "Submit"
                            }}
                        </span>
                        <i class="bx bx-save icon-arrow after"></i>
                    </button>
                    </div>
                </div>
            </form>
            <br />
        </div>
 
    
        
        <div class="container">
            <h4>Add Tutor</h4>
            <form [formGroup]="acreateForm" >
                <div class="row">
                    <div class="col-md-6 description">
                        <div class="form-group mb-3">
                            <label labelStyle>Teacher Email/Phone number</label>
                            <input
                                type="email"
                                formControlName="teachermail"
                                pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$"
                                class="form-control"
                                
                            /> 
                            <div *ngIf="submitted && g.teachermail.errors" class="invalid-feedback">
                                <div *ngIf="g.teachermail.errors.required">teachermail is required</div>

                           
                              </div>
                        </div>
                        <button
                        style="width: 30%"
                        type="button"
                        (click)="addTeacher('Add')"
                        class="default-btn p-2"
                        id="addMaterialButton"
                    >
                        <i class="bx bx-save icon-arrow before"></i>
                        <span class="label"
                            >{{
                                this.editData.length != 0
                                    ? "Update"
                                    : "Submit"
                            }}
                        </span>
                        <i class="bx bx-save icon-arrow after"></i>
                    </button>
                    </div>
                
                </div>
            </form>
            <br />
        </div>
    </div>

</div>
