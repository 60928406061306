import { Component, OnInit } from '@angular/core';
import { transpileModule } from 'typescript';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../shared/api.service';
import { Toastr, ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-buddi-test-correction',
  templateUrl: './buddi-test-correction.component.html',
  styleUrls: ['./buddi-test-correction.component.scss']
})
export class BuddiTestCorrectionComponent implements OnInit {
  selectedoptionform: FormGroup;
  public TestlinkID: any;
  formDatasubjective;
  public TestData: any = [];
  time: number = 0;
  public testid: any;
  display;
  public answerimage: any = [];
  interval;
  public isstudent = false;
  selectedFileNmae: string = 'Choose File';
  public isteacher = false;
  public isinstruction = false;
  public isquestion = true;
  public isoption1 = false;
  public isoption2 = false;
  public isquestiontype3: boolean = false;
  public isoption3 = false;
  public isoption4 = false;
  public subjectnameasstring: string = "";
  public result1: any;
  public answerlist: any = [];
  public questioncount: number = 0;
  public answeredcount: number = 0;
  public totalquestioncount: number = 0;
  public unansweredcount: number = 0;
  public reviewedcount: number = 0;
  public subjectname1 = "";
  public subjectname2 = "";
  public subjectname3 = "";
  public subjectname4 = "";
  public subjectname1array: any = [];
  public subjectname2array: any = [];
  public subjectname3array: any = [];
  public subjectname4array: any = [];
  public imagesarray: any = [];
  public isquestiontext: boolean = false;
  public isquestiontextnull: boolean = false;
  public isquestionimage: boolean = false;
  public teachercorrectcheck: any = [];
  public totalmarksempty: number = 0;
  public totalremarksempty: number = 0;
  public subjectname1arrowup: boolean = false;
  public subjectname1arrowdown: boolean = true;
  public subjectname1arrayquestion: boolean = false;
  public subjectname2arrowup: boolean = false;
  public subjectname2arrowdown: boolean = true;
  public subjectname2arrayquestion: boolean = false;
  public subjectname3arrowup: boolean = false;
  public subjectname3arrowdown: boolean = true;
  public subjectname3arrayquestion: boolean = false;
  public subjectname4arrowup: boolean = false;
  public subjectname4arrowdown: boolean = true;
  public subjectname4arrayquestion: boolean = false;
  public viewimages: boolean = false;
  public indexofimage= 0;
  public imageToShow = '';
  public totalimages= 0;

  constructor(private formBuilder: FormBuilder, private my_router: ActivatedRoute, public toastr: ToastrManager, private rest: ApiService, private router: Router) { }

  ngOnInit(): void {
    this.TestlinkID = this.my_router.snapshot.params['id'];
    this.selectedoptionform = this.formBuilder.group({
      option1: [''],
      option2: [''],
      option3: [''],
      option4: [''],
      upload_name: [null],
      fileupload: [''],
      Remarks: [''],
      marksObtained: ['']
    });

    var usertype = localStorage.getItem('profileType');
    if (usertype.toLowerCase() == "teacher") {
      this.isteacher = true;
    }
    else {
      this.isstudent = true;
    }

    this.loadpage();
    this.loaddata();
  }

  // convenience getter for easy access to form fields
  get f() { return this.selectedoptionform.controls; }

  loadpage() {
    // var formData = {};
    // formData["testID"] = this.TestlinkID
    // formData["studentID"] = localStorage.getItem('StudentID');
    // this.rest.getTestsdetails(formData).subscribe((result) => {

    //   this.TestData = result.data;

    //   for (var i = 0; i < this.TestData.subject.length; i++) {
    //     if (this.subjectnameasstring == "") {
    //       this.subjectnameasstring = this.TestData.subject[i].subjectName
    //     }
    //     else {
    //       this.subjectnameasstring = this.subjectnameasstring + "," + this.TestData.subject[i].subjectName
    //     }
    //   }

    // }, (err) => {
    //   console.log(err);
    // });

    // console.log("testdata", this.TestData);

    // setTimeout(() => {
    //   this.isinstruction = false;
    //   this.isquestion = true;
    //   this.startTimer();
    // },
    //   1000);


  }

  startTimer() {
    console.log("=====>");
    this.interval = setInterval(() => {
      if (this.time === 0) {
        this.time++;
      } else {
        this.time++;
      }
      this.display = this.transform(this.time)
    }, 1000);
  }

  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return minutes + ':' + (value - minutes * 60);
  }

  pauseTimer() {
    clearInterval(this.interval);
  }

  loaddata() {
    var token = localStorage.getItem('webtoken');
    var formData = {};
    formData["id"] = this.TestlinkID
    var regexSpace = /\ /gi;
    this.rest.starttestcorrection(formData, token).subscribe((result) => {
      this.answerlist = result.data.answerList;
      this.totalquestioncount = this.answerlist.length;
      let containsimage = this.answerlist.some(x => x.studentAnswer === "<image>");    
      
      if(result.data.answerImages.length > 0) {
        this.imagesarray = result.data.answerImages;
        this.imageToShow = this.imagesarray[0];
        this.totalimages = this.imagesarray.length;
        this.viewimages = true;
      }
      if (result.data.sectionFormat == "Marks") {
        const distinctmarks = this.answerlist.filter(
          (thing, i, arr) => arr.findIndex(t => t.correctAnswerMarks === thing.correctAnswerMarks) === i
        );
        console.log("marks", distinctmarks);
        for (var i = 0; i < distinctmarks.length; i++) {
          if (i == 0) {
            this.subjectname1 = distinctmarks[i].correctAnswerMarks;
          }
          else if (i == 1) {
            this.subjectname2 = distinctmarks[i].correctAnswerMarks;
          }
          else if (i == 2) {
            this.subjectname3 = distinctmarks[i].correctAnswerMarks;
          }
          else if (i == 3) {
            this.subjectname4 = distinctmarks[i].correctAnswerMarks;
          }
        }

        for (var i = 0; i < this.answerlist.length; i++) {
          if (this.answerlist[i].correctAnswerMarks == this.subjectname1) {
            this.subjectname1array.push(this.answerlist[i]);
          }
          else if (this.answerlist[i].correctAnswerMarks == this.subjectname2) {
            this.subjectname2array.push(this.answerlist[i]);
          }
          else if (this.answerlist[i].correctAnswerMarks == this.subjectname3) {
            this.subjectname3array.push(this.answerlist[i]);
          }
          else if (this.answerlist[i].correctAnswerMarks == this.subjectname4) {
            this.subjectname4array.push(this.answerlist[i]);
          }
        }

        this.subjectname1 = this.subjectname1 + " Marks"
        this.subjectname2 = this.subjectname2 + " Marks"
        this.subjectname3 = this.subjectname3 + " Marks"
        this.subjectname4 = this.subjectname4 + " Marks"

        for (var i = 0; i < this.answerlist.length; i++) {
          if (this.answerlist[i].questionText.replace(regexSpace, "").includes("<i=") && this.answerlist[i].questionText.includes(">")) {
            var tempString = this.answerlist[i].questionText;
            var listOfMatches = [...this.answerlist[i].questionText.matchAll('<.*?>')];
            this.answerlist[i]["images"] = [];
            for (var j = 0; j < listOfMatches.length; j++) {
              var startIndex = j == 0 ? 0 : listOfMatches[j - 1]["index"] + listOfMatches[j - 1][0].length;
              this.answerlist[i]["images"].push(tempString.substring(startIndex, listOfMatches[j]["index"]))
              console.log(listOfMatches[j][0].replace(" ", ""))
              this.answerlist[i]["images"].push("https://s3.ap-south-1.amazonaws.com/buddi.bucket/budditests/" + listOfMatches[j][0].replace(regexSpace, "").replace("<i=", "").replace(">", ""));
              if (j == listOfMatches.length - 1) {
                this.answerlist[i]["images"].push(tempString.substring(listOfMatches[j]["index"] + 1 + listOfMatches[j][0].length, tempString.length))
              }
            }
          }        
        }
      }
      else {
        const distinctsubjects = this.answerlist.filter(
          (thing, i, arr) => arr.findIndex(t => t.subject === thing.subject) === i
        );
  
        for (var i = 0; i < distinctsubjects.length; i++) {
          if (i == 0) {
            this.subjectname1 = distinctsubjects[i].subject;
          }
          else if (i == 1) {
            this.subjectname2 = distinctsubjects[i].subject;
          }
          else if (i == 2) {
            this.subjectname3 = distinctsubjects[i].subject;
          }
          else if (i == 3) {
            this.subjectname4 = distinctsubjects[i].subject;
          }
        }
  
        for (var i = 0; i < this.answerlist.length; i++) {
          if (this.answerlist[i].subject == this.subjectname1) {
            this.subjectname1array.push(this.answerlist[i]);
          }
          else if (this.answerlist[i].subject == this.subjectname2) {
            this.subjectname2array.push(this.answerlist[i]);
          }
          else if (this.answerlist[i].subject == this.subjectname3) {
            this.subjectname3array.push(this.answerlist[i]);
          }
          else if (this.answerlist[i].subject == this.subjectname4) {
            this.subjectname4array.push(this.answerlist[i]);
          }
        }

        for (var i = 0; i < this.answerlist.length; i++) {
          if (this.answerlist[i].questionText.replace(regexSpace, "").includes("<i=") && this.answerlist[i].questionText.includes(">")) {
            var tempString = this.answerlist[i].questionText;
            var listOfMatches = [...this.answerlist[i].questionText.matchAll('<.*?>')];
            this.answerlist[i]["images"] = [];
            for (var j = 0; j < listOfMatches.length; j++) {
              var startIndex = j == 0 ? 0 : listOfMatches[j - 1]["index"] + listOfMatches[j - 1][0].length;
              this.answerlist[i]["images"].push(tempString.substring(startIndex, listOfMatches[j]["index"]))
              console.log(listOfMatches[j][0].replace(" ", ""))
              this.answerlist[i]["images"].push("https://s3.ap-south-1.amazonaws.com/buddi.bucket/budditests/" + listOfMatches[j][0].replace(regexSpace, "").replace("<i=", "").replace(">", ""));
              if (j == listOfMatches.length - 1) {
                this.answerlist[i]["images"].push(tempString.substring(listOfMatches[j]["index"] + 1 + listOfMatches[j][0].length, tempString.length))
              }
            }
          }
          if (this.answerlist[i].option1.replace(regexSpace, "").includes("<i=") && this.answerlist[i].option1.includes(">")) {
            var tempString = this.answerlist[i].option1;
            var listOfMatches = [...this.answerlist[i].option1.matchAll('<.*?>')];
            this.answerlist[i]["option1Images"] = [];
            for (var j = 0; j < listOfMatches.length; j++) {
              var startIndex = j == 0 ? 0 : listOfMatches[j - 1]["index"] + listOfMatches[j - 1][0].length;
              this.answerlist[i]["option1Images"].push(tempString.substring(startIndex, listOfMatches[j]["index"]))
              this.answerlist[i]["option1Images"].push("https://s3.ap-south-1.amazonaws.com/buddi.bucket/budditests/" + listOfMatches[j][0].replace(regexSpace, "").replace(" ", "").replace("<i=", "").replace(">", ""));
              if (j == listOfMatches.length - 1) {
                this.answerlist[i]["option1Images"].push(tempString.substring(listOfMatches[j]["index"] + 1 + listOfMatches[j][0].length, tempString.length))
              }
            }
          }
          if (this.answerlist[i].option2.replace(regexSpace, "").includes("<i=") && this.answerlist[i].option2.includes(">")) {
            var tempString = this.answerlist[i].option2;
            var listOfMatches = [...this.answerlist[i].option2.matchAll('<.*?>')];
            this.answerlist[i]["option2Images"] = [];
            for (var j = 0; j < listOfMatches.length; j++) {
              var startIndex = j == 0 ? 0 : listOfMatches[j - 1]["index"] + listOfMatches[j - 1][0].length;
              this.answerlist[i]["option2Images"].push(tempString.substring(startIndex, listOfMatches[j]["index"]))
              this.answerlist[i]["option2Images"].push("https://s3.ap-south-1.amazonaws.com/buddi.bucket/budditests/" + listOfMatches[j][0].replace(regexSpace, "").replace("<i=", "").replace(">", ""));
              if (j == listOfMatches.length - 1) {
                this.answerlist[i]["option2Images"].push(tempString.substring(listOfMatches[j]["index"] + 1 + listOfMatches[j][0].length, tempString.length))
              }
            }
          }
          if (this.answerlist[i].option3.replace(regexSpace, "").includes("<i=") && this.answerlist[i].option3.includes(">")) {
            var tempString = this.answerlist[i].option3;
            var listOfMatches = [...this.answerlist[i].option3.matchAll('<.*?>')];
            this.answerlist[i]["option3Images"] = [];
            for (var j = 0; j < listOfMatches.length; j++) {
              var startIndex = j == 0 ? 0 : listOfMatches[j - 1]["index"] + listOfMatches[j - 1][0].length;
              this.answerlist[i]["option3Images"].push(tempString.substring(startIndex, listOfMatches[j]["index"]))
              this.answerlist[i]["option3Images"].push("https://s3.ap-south-1.amazonaws.com/buddi.bucket/budditests/" + listOfMatches[j][0].replace(regexSpace, "").replace("<i=", "").replace(">", ""));
              if (j == listOfMatches.length - 1) {
                this.answerlist[i]["option3Images"].push(tempString.substring(listOfMatches[j]["index"] + 1 + listOfMatches[j][0].length, tempString.length))
              }
            }
          }
          if (this.answerlist[i].option4.replace(regexSpace, "").includes("<i=") && this.answerlist[i].option4.includes(">")) {
            var tempString = this.answerlist[i].option4;
            var listOfMatches = [...this.answerlist[i].option4.matchAll('<.*?>')];
            this.answerlist[i]["option4Images"] = [];
            for (var j = 0; j < listOfMatches.length; j++) {
              var startIndex = j == 0 ? 0 : listOfMatches[j - 1]["index"] + listOfMatches[j - 1][0].length;
              this.answerlist[i]["option4Images"].push(tempString.substring(startIndex, listOfMatches[j]["index"]))
              this.answerlist[i]["option4Images"].push("https://s3.ap-south-1.amazonaws.com/buddi.bucket/budditests/" + listOfMatches[j][0].replace(regexSpace, "").replace("<i=", "").replace(">", ""));
              if (j == listOfMatches.length - 1) {
                this.answerlist[i]["option4Images"].push(tempString.substring(listOfMatches[j]["index"] + 1 + listOfMatches[j][0].length, tempString.length))
              }
            }
          }
        }
      } 
      

      this.answeredcount = this.answerlist.filter(item => item.status === 'Answered').length;
      this.unansweredcount = this.answerlist.filter(item => item.status === 'Unanswered').length;
      this.reviewedcount = this.answerlist.filter(item => item.status === 'Reviewed').length;
      this.testid = result.data._id;
      this.oninitdata();
    }, (err) => {
      console.log(err);
    });
  }

  questionnumberbutton(count) {
    const countquestion = count - 1;

    if(this.answerlist[countquestion].questionType == 3) {
      this.selectedoptionform.controls['marksObtained'].reset();
      this.selectedoptionform.controls['Remarks'].reset();

      if (this.answerlist[countquestion].studentAnswer !== undefined) {
        if (this.answerlist[countquestion].studentAnswer.toLowerCase().includes("https://s3.ap-south-1.amazonaws.com")) {
          this.isquestiontextnull = false;
          this.isquestionimage = true;
          this.isquestiontext = false;
          this.selectedoptionform.patchValue({ marksObtained: this.answerlist[countquestion].marksObtained })
          this.selectedoptionform.patchValue({ Remarks: this.answerlist[countquestion].remarks })
        }
        else {
          this.isquestiontextnull = false;
          this.isquestionimage = false;
          this.isquestiontext = true;
          this.selectedoptionform.patchValue({ marksObtained: this.answerlist[countquestion].marksObtained })
          this.selectedoptionform.patchValue({ Remarks: this.answerlist[countquestion].remarks })
        }
      }
      else {
        this.isquestiontextnull = true;
        this.isquestionimage = false;
        this.isquestiontext = false;
        this.selectedoptionform.patchValue({ marksObtained: this.answerlist[countquestion].marksObtained })
        this.selectedoptionform.patchValue({ Remarks: this.answerlist[countquestion].remarks })
      }
    }
    else {
      this.selectedoptionform.controls['option1'].reset();
      this.selectedoptionform.controls['option2'].reset();
      this.selectedoptionform.controls['option3'].reset();
      this.selectedoptionform.controls['option4'].reset();

      if (this.answerlist[countquestion].option1 !== undefined || this.answerlist[countquestion].option1 != "") {
        if (this.answerlist[countquestion].option1Images == undefined) {
          document.getElementById("mathone").innerHTML = this.answerlist[countquestion].option1;
          MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
        }
        else {
          for (var i = 0; i < this.answerlist[countquestion].option1Images.length; i++) {
            if (!this.answerlist[countquestion].option1Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
              console.log(this.answerlist[countquestion].option1Images[i])
              document.getElementById("mathone").innerHTML = this.answerlist[countquestion].option1Images[i];
              MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
            }
  
          }
        }
      }
      if (this.answerlist[countquestion].option2 !== undefined || this.answerlist[countquestion].option2 != "") {
        if (this.answerlist[countquestion].option2Images == undefined) {
          document.getElementById("mathtwo").innerHTML = this.answerlist[countquestion].option2;
          MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
        }
        else {
          for (var i = 0; i < this.answerlist[countquestion].option2Images.length; i++) {
            if (!this.answerlist[countquestion].option2Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
              console.log(this.answerlist[countquestion].option2Images[i])
              document.getElementById("mathtwo").innerHTML = this.answerlist[countquestion].option2Images[i];
              MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
            }
  
          }
        }
      }
      if (this.answerlist[countquestion].option3 !== undefined || this.answerlist[countquestion].option3 != "") {
        if (this.answerlist[countquestion].option3Images == undefined) {
          document.getElementById("maththree").innerHTML = this.answerlist[countquestion].option3;
          MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
        }
        else {
          for (var i = 0; i < this.answerlist[countquestion].option3Images.length; i++) {
            if (!this.answerlist[countquestion].option3Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
              console.log(this.answerlist[countquestion].option3Images[i])
              document.getElementById("maththree").innerHTML = this.answerlist[countquestion].option3Images[i];
              MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
            }
  
          }
        }
      }
      if (this.answerlist[countquestion].option4 !== undefined || this.answerlist[countquestion].option4 != "") {
        if (this.answerlist[countquestion].option4Images == undefined) {
          document.getElementById("mathfour").innerHTML = this.answerlist[countquestion].option4;
          MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
        }
        else {
          for (var i = 0; i < this.answerlist[countquestion].option4mages.length; i++) {
            if (!this.answerlist[countquestion].option4Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
              console.log(this.answerlist[countquestion].option4Images[i])
              document.getElementById("mathfour").innerHTML = this.answerlist[countquestion].option4Images[i];
              MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
            }
  
          }
        }
      }
      if (this.answerlist[countquestion].numberOfOptions > 1) {
        if (this.answerlist[countquestion].studentAnswer != null) {
          for (var i = 0; i < this.answerlist[countquestion].studentAnswer.length; i++) {
            if (this.answerlist[countquestion].studentAnswer[i] == "1") {
              setTimeout(() => {
                this.isoption1 = true;
              },
                500);
            }
            else if (this.answerlist[countquestion].studentAnswer[i] == "2") {
              setTimeout(() => {
                this.isoption2 = true;
              },
                500);
            }
            else if (this.answerlist[countquestion].studentAnswer[i] == "3") {
              setTimeout(() => {
                this.isoption3 = true;
              },
                500);
            }
            else if (this.answerlist[countquestion].studentAnswer[i] == "4") {
              setTimeout(() => {
                this.isoption4 = true;
              },
                500);
            }
          }
        }
  
      }
      else {
        if (this.answerlist[countquestion].studentAnswer != null) {
          if (this.answerlist[countquestion].studentAnswer[0] == "1") {
            setTimeout(() => {
              this.isoption1 = true;
            },
              500);
          }
          else if (this.answerlist[countquestion].studentAnswer[0] == "2") {
            setTimeout(() => {
              this.isoption2 = true;
            },
              500);
          }
          else if (this.answerlist[countquestion].studentAnswer[0] == "3") {
            setTimeout(() => {
              this.isoption3 = true;
            },
              500);
          }
          else if (this.answerlist[countquestion].studentAnswer[0] == "4") {
            setTimeout(() => {
              this.isoption4 = true;
            },
              500);
          }
        }
      }



    }
   
    
    // if (this.answerlist[countquestion].option1 !== undefined || this.answerlist[countquestion].option1 != "") {
    //   document.getElementById("mathone").innerHTML = this.answerlist[countquestion].option1;
    //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
    // }
    // if (this.answerlist[countquestion].option2 !== undefined || this.answerlist[countquestion].option2 != "") {
    //   document.getElementById("mathtwo").innerHTML = this.answerlist[countquestion].option2;
    //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
    // }
    // if (this.answerlist[countquestion].option3 !== undefined || this.answerlist[countquestion].option3 != "") {
    //   document.getElementById("maththree").innerHTML = this.answerlist[countquestion].option3;
    //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
    // }
    // if (this.answerlist[countquestion].option4 !== undefined || this.answerlist[countquestion].option4 != "") {
    //   document.getElementById("mathfour").innerHTML = this.answerlist[countquestion].option4;
    //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
    // }
    if (this.answerlist[countquestion].images == undefined) {
      document.getElementById("mathdiv").innerHTML = this.answerlist[countquestion].questionText;
      MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
    }
    else {
      for (var i = 0; i < this.answerlist[countquestion].images.length; i++) {
        if (!this.answerlist[countquestion].images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
          console.log(this.answerlist[countquestion].images[i])
          document.getElementById("mathdiv").innerHTML = this.answerlist[countquestion].images[i];
          MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
        }

      }
    }

 
    // if(this.answerlist[countquestion].questionType == 3) {
    //   if(this.answerlist[countquestion].studentAnswer.includes("https://s3.ap-south-1.amazonaws.com")) {
    //     this.isquestionimage = true;
    //     this.selectedoptionform.patchValue({ marksObtained:  this.answerlist[count + 1].marksObtained})
    //     this.selectedoptionform.patchValue({ Remarks:  this.answerlist[count + 1].remarks})
    //   }
    //   else {
    //     this.isquestiontext = true;      
    //     this.selectedoptionform.patchValue({ marksObtained:  this.answerlist[count + 1].marksObtained})
    //     this.selectedoptionform.patchValue({ Remarks:  this.answerlist[count + 1].remarks})
    //   }
    // }

    let assigndata: any = {};

    this.questioncount = countquestion;
    // document.getElementById("mathdiv").innerHTML = this.answerlist[this.questioncount].questionText;
    // MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);

    //  this.answerlist = result.data.answerList;
  }

  onSubmit(FormGroup) {
    var token = localStorage.getItem('webtoken');
    const selectedanswer = [];
    const data = this.selectedoptionform.value;
    const count = this.questioncount;
    var formData = {};
    const marks = data.marksObtained;
    const remarksstring = data.Remarks;

    // condition if question type = 3 or subjective
    if (this.answerlist[count].questionType == 3) {
      this.isquestiontype3 = true;
      if (marks != null) {
        if (remarksstring != "") {
          // this.answerlist.find(item => item.questionNumber == this.questioncount + 1).studentAnswer = [];
          //  this.answerlist.find(item => item.questionNumber == this.questioncount + 1).marksObtained = "sdf","dasd";
          if (this.answerlist[count].correctAnswerMarks >= data.marksObtained) {
            this.answerlist.find(item => item.questionNumber == this.questioncount + 1).marksObtained = data.marksObtained;
            this.answerlist.find(item => item.questionNumber == this.questioncount + 1).remarks = data.Remarks;
            formData["answerList"] = this.answerlist;
            formData["id"] = this.testid;
            if (this.isquestiontype3 == true) {
              this.rest.updatetestforcorrection(formData, token).subscribe((result) => {
                if (result.message.toLowerCase() == "saved") {
                  if (this.answerlist[count + 1].questionType == 3) {
                    if (this.answerlist[count + 1].studentAnswer !== undefined) {
                      if (this.answerlist[count + 1].studentAnswer.toLowerCase().includes("https://s3.ap-south-1.amazonaws.com")) {
                        this.isquestionimage = true;
                        this.isquestiontext = false;
                        this.isquestiontextnull = false;
                        this.selectedoptionform.patchValue({ marksObtained: this.answerlist[count + 1].marksObtained })
                        this.selectedoptionform.patchValue({ Remarks: this.answerlist[count + 1].remarks })
                      }
                      else {
                        this.isquestiontext = true;
                        this.isquestionimage = false;
                        this.isquestiontextnull = false;
                        this.selectedoptionform.patchValue({ marksObtained: this.answerlist[count + 1].marksObtained })
                        this.selectedoptionform.patchValue({ Remarks: this.answerlist[count + 1].remarks })
                      }
                    }
                    else {
                      this.isquestiontextnull = true;
                      this.isquestiontext = true;
                      this.isquestionimage = false;
                      this.selectedoptionform.controls['marksObtained'].reset();
                      this.selectedoptionform.controls['Remarks'].reset();
                    }

                  }
                  else {
                    data.upload_name = null;
                    this.selectedFileNmae = "Choose File"
                    this.isquestiontype3 = false;
                    this.selectedoptionform.controls['option1'].reset();
                    this.selectedoptionform.controls['option2'].reset();
                    this.selectedoptionform.controls['option3'].reset();
                    this.selectedoptionform.controls['option4'].reset();
                    this.selectedoptionform.controls['marksObtained'].reset();
                    this.selectedoptionform.controls['Remarks'].reset();

                    if (this.answerlist[count + 1].option1 !== undefined || this.answerlist[count + 1].option1 != "") {
                      if (this.answerlist[count + 1].option1Images == undefined) {
                        document.getElementById("mathone").innerHTML = this.answerlist[count + 1].option1;
                        MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
                      }
                      else {
                        for (var i = 0; i < this.answerlist[count + 1].option1Images.length; i++) {
                          if (!this.answerlist[count + 1].option1Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
                            console.log(this.answerlist[count + 1].option1Images[i])
                            document.getElementById("mathone").innerHTML = this.answerlist[count + 1].option1Images[i];
                            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
                          }
  
                        }
                      }
                    }
                    if (this.answerlist[count + 1].option2 !== undefined || this.answerlist[count + 1].option2 != "") {
                      if (this.answerlist[count + 1].option2Images == undefined) {
                        document.getElementById("mathtwo").innerHTML = this.answerlist[count + 1].option2;
                        MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
                      }
                      else {
                        for (var i = 0; i < this.answerlist[count + 1].option2Images.length; i++) {
                          if (!this.answerlist[count + 1].option2Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
                            console.log(this.answerlist[count + 1].option2Images[i])
                            document.getElementById("mathtwo").innerHTML = this.answerlist[count + 1].option2Images[i];
                            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
                          }
  
                        }
                      }
                    }
                    if (this.answerlist[count + 1].option3 !== undefined || this.answerlist[count + 1].option3 != "") {
                      if (this.answerlist[count + 1].option3Images == undefined) {
                        document.getElementById("maththree").innerHTML = this.answerlist[count + 1].option3;
                        MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
                      }
                      else {
                        for (var i = 0; i < this.answerlist[count + 1].option3Images.length; i++) {
                          if (!this.answerlist[count + 1].option3Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
                            console.log(this.answerlist[count + 1].option3Images[i])
                            document.getElementById("maththree").innerHTML = this.answerlist[count + 1].option3Images[i];
                            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
                          }
  
                        }
                      }
                    }
                    if (this.answerlist[count + 1].option4 !== undefined || this.answerlist[count + 1].option4 != "") {
                      if (this.answerlist[count + 1].option4Images == undefined) {
                        document.getElementById("mathfour").innerHTML = this.answerlist[count + 1].option4;
                        MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
                      }
                      else {
                        for (var i = 0; i < this.answerlist[count + 1].option4mages.length; i++) {
                          if (!this.answerlist[count + 1].option4Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
                            console.log(this.answerlist[count + 1].option4Images[i])
                            document.getElementById("mathfour").innerHTML = this.answerlist[count + 1].option4Images[i];
                            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
                          }
  
                        }
                      }
                    }
  
                    if (this.answerlist[count + 1].numberOfOptions > 1) {
                      if (this.answerlist[count + 1].studentAnswer != null) {
                        for (var i = 0; i < this.answerlist[count + 1].studentAnswer.length; i++) {
                          if (this.answerlist[count + 1].studentAnswer[i] == "1") {
                            setTimeout(() => {
                              this.isoption1 = true;
                            },
                              500);
                          }
                          else if (this.answerlist[count + 1].studentAnswer[i] == "2") {
                            setTimeout(() => {
                              this.isoption2 = true;
                            },
                              500);
                          }
                          else if (this.answerlist[count + 1].studentAnswer[i] == "3") {
                            setTimeout(() => {
                              this.isoption3 = true;
                            },
                              500);
                          }
                          else if (this.answerlist[count + 1].studentAnswer[i] == "4") {
                            setTimeout(() => {
                              this.isoption4 = true;
                            },
                              500);
                          }
                        }
                      }
                    }
                  }
                  
                  // if (this.answerlist[count + 1].option1 !== undefined || this.answerlist[count + 1].option1 != "") {
                  //   document.getElementById("mathone").innerHTML = this.answerlist[count + 1].option1;
                  //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
                  // }
                  // if (this.answerlist[count + 1].option2 !== undefined || this.answerlist[count + 1].option2 != "") {
                  //   document.getElementById("mathtwo").innerHTML = this.answerlist[count + 1].option2;
                  //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
                  // }
                  // if (this.answerlist[count + 1].option3 !== undefined || this.answerlist[count + 1].option3 != "") {
                  //   document.getElementById("maththree").innerHTML = this.answerlist[count + 1].option3;
                  //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
                  // }
                  // if (this.answerlist[count + 1].option4 !== undefined || this.answerlist[count + 1].option4 != "") {
                  //   document.getElementById("mathfour").innerHTML = this.answerlist[count + 1].option4;
                  //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
                  // }

                  if (this.answerlist[count + 1].images == undefined) {
                    document.getElementById("mathdiv").innerHTML = this.answerlist[count + 1].questionText;
                    MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
                  }
                  else {
                    for (var i = 0; i < this.answerlist[count + 1].images.length; i++) {
                      if (!this.answerlist[count + 1].images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
                        console.log(this.answerlist[count + 1].images[i])
                        document.getElementById("mathdiv").innerHTML = this.answerlist[count + 1].images[i];
                        MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
                      }

                    }
                  }

               
                  this.questioncount = count + 1;
                  // document.getElementById("mathdiv").innerHTML = this.answerlist[this.questioncount].questionText;
                  // MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
                }
                else {
                  this.toastr.errorToastr(result.message);
                }
              }, (err) => {
                console.log(err);
              });
            }

            this.questioncount = count + 1;
          }
          else {
            this.toastr.errorToastr("Maximum marks is " + this.answerlist[count].correctAnswerMarks);
          }
        }
        else {
          this.toastr.errorToastr("Remarks Required");
        }
      }
      else {
        this.toastr.errorToastr("Marks Obtained cannot be null");
      }
    }

    // End of condition if question type = 3 or subjective
    else {
      this.selectedoptionform.controls['option1'].reset();
      this.selectedoptionform.controls['option2'].reset();
      this.selectedoptionform.controls['option3'].reset();
      this.selectedoptionform.controls['option4'].reset();
      this.selectedoptionform.controls['marksObtained'].reset();
      this.selectedoptionform.controls['Remarks'].reset();
      // if (this.answerlist[count + 1].option1 !== undefined || this.answerlist[count + 1].option1 != "") {
      //   document.getElementById("mathone").innerHTML = this.answerlist[count + 1].option1;
      //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
      // }
      // if (this.answerlist[count + 1].option2 !== undefined || this.answerlist[count + 1].option2 != "") {
      //   document.getElementById("mathtwo").innerHTML = this.answerlist[count + 1].option2;
      //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
      // }
      // if (this.answerlist[count + 1].option3 !== undefined || this.answerlist[count + 1].option3 != "") {
      //   document.getElementById("maththree").innerHTML = this.answerlist[count + 1].option3;
      //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
      // }
      // if (this.answerlist[count + 1].option4 !== undefined || this.answerlist[count + 1].option4 != "") {
      //   document.getElementById("mathfour").innerHTML = this.answerlist[count + 1].option4;
      //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
      // }

      if (this.answerlist[count + 1].images == undefined) {
        document.getElementById("mathdiv").innerHTML = this.answerlist[count + 1].questionText;
        MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
      }
      else {
        for (var i = 0; i < this.answerlist[count + 1].images.length; i++) {
          if (!this.answerlist[count + 1].images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
            console.log(this.answerlist[count + 1].images[i])
            document.getElementById("mathdiv").innerHTML = this.answerlist[count + 1].images[i];
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
          }

        }
      }

      if (this.answerlist[count + 1].option1 !== undefined || this.answerlist[count + 1].option1 != "") {
        if (this.answerlist[count + 1].option1Images == undefined) {
          document.getElementById("mathone").innerHTML = this.answerlist[count + 1].option1;
          MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
        }
        else {
          for (var i = 0; i < this.answerlist[count + 1].option1Images.length; i++) {
            if (!this.answerlist[count + 1].option1Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
              console.log(this.answerlist[count + 1].option1Images[i])
              document.getElementById("mathone").innerHTML = this.answerlist[count + 1].option1Images[i];
              MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
            }

          }
        }
      }
      if (this.answerlist[count + 1].option2 !== undefined || this.answerlist[count + 1].option2 != "") {
        if (this.answerlist[count + 1].option2Images == undefined) {
          document.getElementById("mathtwo").innerHTML = this.answerlist[count + 1].option2;
          MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
        }
        else {
          for (var i = 0; i < this.answerlist[count + 1].option2Images.length; i++) {
            if (!this.answerlist[count + 1].option2Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
              console.log(this.answerlist[count + 1].option2Images[i])
              document.getElementById("mathtwo").innerHTML = this.answerlist[count + 1].option2Images[i];
              MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
            }

          }
        }
      }
      if (this.answerlist[count + 1].option3 !== undefined || this.answerlist[count + 1].option3 != "") {
        if (this.answerlist[count + 1].option3Images == undefined) {
          document.getElementById("maththree").innerHTML = this.answerlist[count + 1].option3;
          MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
        }
        else {
          for (var i = 0; i < this.answerlist[count + 1].option3Images.length; i++) {
            if (!this.answerlist[count + 1].option3Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
              console.log(this.answerlist[count + 1].option3Images[i])
              document.getElementById("maththree").innerHTML = this.answerlist[count + 1].option3Images[i];
              MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
            }

          }
        }
      }
      if (this.answerlist[count + 1].option4 !== undefined || this.answerlist[count + 1].option4 != "") {
        if (this.answerlist[count + 1].option4Images == undefined) {
          document.getElementById("mathfour").innerHTML = this.answerlist[count + 1].option4;
          MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
        }
        else {
          for (var i = 0; i < this.answerlist[count + 1].option4mages.length; i++) {
            if (!this.answerlist[count + 1].option4Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
              console.log(this.answerlist[count + 1].option4Images[i])
              document.getElementById("mathfour").innerHTML = this.answerlist[count + 1].option4Images[i];
              MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
            }

          }
        }
      }

      if (this.answerlist[count + 1].studentAnswer != null) {
        if (this.answerlist[count + 1].studentAnswer[0] == "1") {
          setTimeout(() => {
            this.isoption1 = true;
          },
            500);
        }
        else if (this.answerlist[count + 1].studentAnswer[0] == "2") {
          setTimeout(() => {
            this.isoption2 = true;
          },
            500);
        }
        else if (this.answerlist[count + 1].studentAnswer[0] == "3") {
          setTimeout(() => {
            this.isoption3 = true;
          },
            500);
        }
        else if (this.answerlist[count + 1].studentAnswer[0] == "4") {
          setTimeout(() => {
            this.isoption4 = true;
          },
            500);
        }
      }
      else {
      }
      if (this.answerlist[count + 1].questionType == 3) {
        if (this.answerlist[count + 1].studentAnswer.includes("https://s3.ap-south-1.amazonaws.com")) {
          this.isquestionimage = true;
          this.selectedoptionform.patchValue({ marksObtained: this.answerlist[count + 1].marksObtained })
          this.selectedoptionform.patchValue({ Remarks: this.answerlist[count + 1].remarks })
        }
        else if (this.answerlist[count + 1].studentAnswer.length > 2) {
          this.isquestiontext = true;
          this.selectedoptionform.patchValue({ marksObtained: this.answerlist[count + 1].marksObtained })
          this.selectedoptionform.patchValue({ Remarks: this.answerlist[count + 1].remarks })
        }
      }
      this.questioncount = count + 1;
      // document.getElementById("mathdiv").innerHTML = this.answerlist[this.questioncount].questionText;
      // MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
    }
  }

  reviewlater() {
    this.router.navigate(['teacher/mydashboard']);
  }

  previous() {

    if (this.questioncount > 0) {
      const count = this.questioncount - 1;
      if(this.answerlist[count].questionType == 3){
        if (this.answerlist[count].studentAnswer !== undefined) {
          if (this.answerlist[count].studentAnswer.toLowerCase().includes("https://s3.ap-south-1.amazonaws.com")) {
            this.isquestionimage = true;
            this.isquestiontext = false;
            this.isquestiontextnull = false;
            this.selectedoptionform.patchValue({ marksObtained: this.answerlist[count].marksObtained })
            this.selectedoptionform.patchValue({ Remarks: this.answerlist[count].remarks })
          }
          else {
            this.isquestiontext = true;
            this.isquestiontextnull = false;
            this.isquestionimage = false;
            this.selectedoptionform.patchValue({ marksObtained: this.answerlist[count].marksObtained })
            this.selectedoptionform.patchValue({ Remarks: this.answerlist[count].remarks })
          }
        }
        else {
          this.isquestiontextnull = true;
          this.isquestiontext = false;
          this.isquestionimage = false;
          this.selectedoptionform.patchValue({ marksObtained: this.answerlist[count].marksObtained })
          this.selectedoptionform.patchValue({ Remarks: this.answerlist[count].remarks })
        }
      }
      else {
        this.selectedoptionform.controls['option1'].reset();
        this.selectedoptionform.controls['option2'].reset();
        this.selectedoptionform.controls['option3'].reset();
        this.selectedoptionform.controls['option4'].reset();
  
        if (this.answerlist[count].numberOfOptions > 1) {
          if (this.answerlist[count].studentAnswer != null) {
            for (var i = 0; i < this.answerlist[count].studentAnswer.length; i++) {
              if (this.answerlist[count].studentAnswer[i] == "1") {
                setTimeout(() => {
                  this.isoption1 = true;
                },
                  500);
              }
              else if (this.answerlist[count].studentAnswer[i] == "2") {
                setTimeout(() => {
                  this.isoption2 = true;
                },
                  500);
              }
              else if (this.answerlist[count].studentAnswer[i] == "3") {
                setTimeout(() => {
                  this.isoption3 = true;
                },
                  500);
              }
              else if (this.answerlist[count].studentAnswer[i] == "4") {
                setTimeout(() => {
                  this.isoption4 = true;
                },
                  500);
              }
            }
          }
  
        }
        else {
          if (this.answerlist[count].studentAnswer != null) {
            if (this.answerlist[count].studentAnswer[0] == "1") {
              setTimeout(() => {
                this.isoption1 = true;
              },
                500);
            }
            else if (this.answerlist[count].studentAnswer[0] == "2") {
              setTimeout(() => {
                this.isoption2 = true;
              },
                500);
            }
            else if (this.answerlist[count].studentAnswer[0] == "3") {
              setTimeout(() => {
                this.isoption3 = true;
              },
                500);
            }
            else if (this.answerlist[count].studentAnswer[0] == "4") {
              setTimeout(() => {
                this.isoption4 = true;
              },
                500);
            }
          }
        }

        if (this.answerlist[count].option1 !== undefined || this.answerlist[count].option1 != "") {
          if (this.answerlist[count].option1Images == undefined) {
            document.getElementById("mathone").innerHTML = this.answerlist[count].option1;
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
          }
          else {
            for (var i = 0; i < this.answerlist[count].option1Images.length; i++) {
              if (!this.answerlist[count].option1Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
                console.log(this.answerlist[count].option1Images[i])
                document.getElementById("mathone").innerHTML = this.answerlist[count].option1Images[i];
                MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
              }
  
            }
          }
        }
        if (this.answerlist[count].option2 !== undefined || this.answerlist[count].option2 != "") {
          if (this.answerlist[count].option2Images == undefined) {
            document.getElementById("mathtwo").innerHTML = this.answerlist[count].option2;
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
          }
          else {
            for (var i = 0; i < this.answerlist[count].option2Images.length; i++) {
              if (!this.answerlist[count].option2Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
                console.log(this.answerlist[count].option2Images[i])
                document.getElementById("mathtwo").innerHTML = this.answerlist[count].option2Images[i];
                MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
              }
  
            }
          }
        }
        if (this.answerlist[count].option3 !== undefined || this.answerlist[count].option3 != "") {
          if (this.answerlist[count].option3Images == undefined) {
            document.getElementById("maththree").innerHTML = this.answerlist[count].option3;
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
          }
          else {
            for (var i = 0; i < this.answerlist[count].option3Images.length; i++) {
              if (!this.answerlist[count].option3Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
                console.log(this.answerlist[count].option3Images[i])
                document.getElementById("maththree").innerHTML = this.answerlist[count].option3Images[i];
                MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
              }
  
            }
          }
        }
        if (this.answerlist[count].option4 !== undefined || this.answerlist[count].option4 != "") {
          if (this.answerlist[count].option4Images == undefined) {
            document.getElementById("mathfour").innerHTML = this.answerlist[count].option4;
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
          }
          else {
            for (var i = 0; i < this.answerlist[count].option4mages.length; i++) {
              if (!this.answerlist[count].option4Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
                console.log(this.answerlist[count].option4Images[i])
                document.getElementById("mathfour").innerHTML = this.answerlist[count].option4Images[i];
                MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
              }
  
            }
          }
        }
      }

      this.questioncount = count;
      // document.getElementById("mathdiv").innerHTML = this.answerlist[this.questioncount].questionText;
      // MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
      // if (this.answerlist[count].option1 !== undefined || this.answerlist[count].option1 != "") {
      //   document.getElementById("mathone").innerHTML = this.answerlist[count].option1;
      //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
      // }
      // if (this.answerlist[count].option2 !== undefined || this.answerlist[count].option2 != "") {
      //   document.getElementById("mathtwo").innerHTML = this.answerlist[count].option2;
      //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
      // }
      // if (this.answerlist[count].option3 !== undefined || this.answerlist[count].option3 != "") {
      //   document.getElementById("maththree").innerHTML = this.answerlist[count].option3;
      //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
      // }
      // if (this.answerlist[count].option4 !== undefined || this.answerlist[count].option4 != "") {
      //   document.getElementById("mathfour").innerHTML = this.answerlist[count].option4;
      //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
      // }
      if (this.answerlist[count].images == undefined) {
        document.getElementById("mathdiv").innerHTML = this.answerlist[count].questionText;
        MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
      }
      else {
        for (var i = 0; i < this.answerlist[count].images.length; i++) {
          if (!this.answerlist[count].images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
            console.log(this.answerlist[count].images[i])
            document.getElementById("mathdiv").innerHTML = this.answerlist[count].images[i];
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
          }

        }
      }    
    }
    else {

    }

  }

  oninitdata() {
    const count = this.questioncount;
    if(this.answerlist[count].questionType == 3) {
      if (this.answerlist[count].studentAnswer !== undefined) {
        if (this.answerlist[count].studentAnswer.toLowerCase().includes("https://s3.ap-south-1.amazonaws.com")) {
          this.isquestiontextnull = false;
          this.isquestionimage = true;
          this.isquestiontext = false;
          this.selectedoptionform.patchValue({ marksObtained: this.answerlist[count].marksObtained })
          this.selectedoptionform.patchValue({ Remarks: this.answerlist[count].remarks })
        }
        else {
          this.isquestiontextnull = false;
          this.isquestionimage = false;
          this.isquestiontext = true;
          this.selectedoptionform.patchValue({ marksObtained: this.answerlist[count].marksObtained })
          this.selectedoptionform.patchValue({ Remarks: this.answerlist[count].remarks })
        }
      }
      else {
        this.isquestiontextnull = true;
        this.isquestionimage = false;
        this.isquestiontext = false;
        this.selectedoptionform.patchValue({ marksObtained: this.answerlist[count].marksObtained })
        this.selectedoptionform.patchValue({ Remarks: this.answerlist[count].remarks })
      }
    }
    else {
      if (this.answerlist[count].numberOfOptions > 1) {
        if (this.answerlist[count].studentAnswer != null) {
          for (var i = 0; i < this.answerlist[count].studentAnswer.length; i++) {
            if (this.answerlist[count].studentAnswer[i] == "1") {
              this.isoption1 = true;
            }
            else if (this.answerlist[count].studentAnswer[i] == "2") {
              this.isoption2 = true;
            }
            else if (this.answerlist[count].studentAnswer[i] == "3") {
              this.isoption3 = true;
            }
            else if (this.answerlist[count].studentAnswer[i] == "4") {
              this.isoption4 = true;
            }
          }
        }
  
      }
      else {
  
        if (this.answerlist[count].studentAnswer != null) {
          if (this.answerlist[count].studentAnswer[0] == "1") {
            this.isoption1 = true;
          }
          else if (this.answerlist[count].studentAnswer[0] == "2") {
            this.isoption2 = true;
          }
          else if (this.answerlist[count].studentAnswer[0] == "3") {
            this.isoption3 = true;
          }
          else if (this.answerlist[count].studentAnswer[0] == "4") {
            this.isoption4 = true;
          }
        }
      }
    }
  

    let assigndata: any = {};

    this.questioncount = count;
    // document.getElementById("mathdiv").innerHTML = this.answerlist[this.questioncount].questionText;
    // MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
    // if (this.answerlist[count].option1 !== undefined || this.answerlist[count].option1 != "") {
    //   document.getElementById("mathone").innerHTML = this.answerlist[count].option1;
    //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
    // }
    // if (this.answerlist[count].option2 !== undefined || this.answerlist[count].option2 != "") {
    //   document.getElementById("mathtwo").innerHTML = this.answerlist[count].option2;
    //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
    // }
    // if (this.answerlist[count].option3 !== undefined || this.answerlist[count].option3 != "") {
    //   document.getElementById("maththree").innerHTML = this.answerlist[count].option3;
    //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
    // }
    // if (this.answerlist[count].option4 !== undefined || this.answerlist[count].option4 != "") {
    //   document.getElementById("mathfour").innerHTML = this.answerlist[count].option4;
    //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
    // }
    if (this.answerlist[count].images == undefined) {
      document.getElementById("mathdiv").innerHTML = this.answerlist[count].questionText;
      MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
    }
    else {
      for (var i = 0; i < this.answerlist[count].images.length; i++) {
        if (!this.answerlist[count].images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
          console.log(this.answerlist[count].images[i])
          document.getElementById("mathdiv").innerHTML = this.answerlist[count].images[i];
          MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
        }

      }
    }

    if (this.answerlist[count].option1 !== undefined || this.answerlist[count].option1 != "") {
      if (this.answerlist[count].option1Images == undefined) {
        document.getElementById("mathone").innerHTML = this.answerlist[count].option1;
        MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
      }
      else {
        for (var i = 0; i < this.answerlist[count].option1Images.length; i++) {
          if (!this.answerlist[count].option1Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
            console.log(this.answerlist[count].option1Images[i])
            document.getElementById("mathone").innerHTML = this.answerlist[count].option1Images[i];
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
          }

        }
      }
    }
    if (this.answerlist[count].option2 !== undefined || this.answerlist[count].option2 != "") {
      if (this.answerlist[count].option2Images == undefined) {
        document.getElementById("mathtwo").innerHTML = this.answerlist[count].option2;
        MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
      }
      else {
        for (var i = 0; i < this.answerlist[count].option2Images.length; i++) {
          if (!this.answerlist[count].option2Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
            console.log(this.answerlist[count].option2Images[i])
            document.getElementById("mathtwo").innerHTML = this.answerlist[count].option2Images[i];
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
          }

        }
      }
    }
    if (this.answerlist[count].option3 !== undefined || this.answerlist[count].option3 != "") {
      if (this.answerlist[count].option3Images == undefined) {
        document.getElementById("maththree").innerHTML = this.answerlist[count].option3;
        MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
      }
      else {
        for (var i = 0; i < this.answerlist[count].option3Images.length; i++) {
          if (!this.answerlist[count].option3Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
            console.log(this.answerlist[count].option3Images[i])
            document.getElementById("maththree").innerHTML = this.answerlist[count].option3Images[i];
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
          }

        }
      }
    }
    if (this.answerlist[count].option4 !== undefined || this.answerlist[count].option4 != "") {
      if (this.answerlist[count].option4Images == undefined) {
        document.getElementById("mathfour").innerHTML = this.answerlist[count].option4;
        MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
      }
      else {
        for (var i = 0; i < this.answerlist[count].option4mages.length; i++) {
          if (!this.answerlist[count].option4Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
            console.log(this.answerlist[count].option4Images[i])
            document.getElementById("mathfour").innerHTML = this.answerlist[count].option4Images[i];
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
          }

        }
      }
    }
  }

  option1clicked() {
    const data = this.selectedoptionform.value;
    if (data.option1 == true) {
      this.isoption1 = false;
    }
    else {
      this.isoption1 = true;
    }
  }
  option2clicked() {
    const data = this.selectedoptionform.value;
    if (data.option2 == true) {
      this.isoption2 = false;
    }
    else {
      this.isoption2 = true;
    }
  }
  option3clicked() {
    const data = this.selectedoptionform.value;
    if (data.option3 == true) {
      this.isoption3 = false;
    }
    else {
      this.isoption3 = true;
    }
  }
  option4clicked() {
    const data = this.selectedoptionform.value;
    if (data.option4 == true) {
      this.isoption4 = false;
    }
    else {
      this.isoption4 = true;
    }
  }

  endtest() {
    this.teachercorrectcheck = this.answerlist.filter(item => item.questionType == 3);
    for (var i = 0; i < this.teachercorrectcheck.length; i++) {
      if (this.teachercorrectcheck[i].marksObtained !== undefined) {
      }
      else {
        this.totalmarksempty = this.totalmarksempty + 1;
      }
      if (this.teachercorrectcheck[i].remarks !== undefined || this.teachercorrectcheck[i].remarks != null || this.teachercorrectcheck[i].remarks !== "") {

      }
      else {
        this.totalremarksempty = this.totalremarksempty + 1;
      }
    }

    if (this.totalmarksempty > 0) {
      this.toastr.errorToastr(this.totalmarksempty + " questions Mark Obtained is empty. Please check");
    }
    else {
      if (this.totalremarksempty > 0) {
        this.toastr.errorToastr(this.totalremarksempty + " questions remark is empty. Please check");
      }
      else {
        var token = localStorage.getItem('webtoken');
        var formData = {};
        formData["id"] = this.testid;
        this.rest.finishTestForCorrection(formData, token).subscribe((result) => {
          if (result.message.toLowerCase().includes("success")) {
            this.toastr.successToastr(result.message);
            this.router.navigate(['teacher/mydashboard']);
          }
          else {
            this.toastr.errorToastr(result.message);
          }

        }, (err) => {
          console.log(err);
        });
      }
    }



  }

  fileChange(event) {
    let fileList: FileList = event.target.files;
    this.selectedoptionform.patchValue({ upload_name: fileList[0] });
    this.selectedFileNmae = fileList[0].name;
    this.selectedoptionform.patchValue({ fileupload: this.selectedFileNmae });
  }

  subjectname1arrowdownfunction() {
    this.subjectname1arrowdown = false;
    this.subjectname1arrowup = true;
    this.subjectname1arrayquestion = true;
  }

  subjectname1arrowupfunction() {
    this.subjectname1arrowdown = true;
    this.subjectname1arrowup = false;
    this.subjectname1arrayquestion = false;
  }

  subjectname2arrowdownfunction() {
    this.subjectname2arrowdown = false;
    this.subjectname2arrowup = true;
    this.subjectname2arrayquestion = true;
  }

  subjectname2arrowupfunction() {
    this.subjectname2arrowdown = true;
    this.subjectname2arrowup = false;
    this.subjectname2arrayquestion = false;
  }

  subjectname3arrowdownfunction() {
    this.subjectname3arrowdown = false;
    this.subjectname3arrowup = true;
    this.subjectname3arrayquestion = true;
  }

  subjectname3arrowupfunction() {
    this.subjectname3arrowdown = true;
    this.subjectname3arrowup = false;
    this.subjectname3arrayquestion = false;
  }

  subjectname4arrowdownfunction() {
    this.subjectname4arrowdown = false;
    this.subjectname4arrowup = true;
    this.subjectname4arrayquestion = true;
  }

  subjectname4arrowupfunction() {
    this.subjectname4arrowdown = true;
    this.subjectname4arrowup = false;
    this.subjectname4arrayquestion = false;
  }

  previousimage() {
    if(this.indexofimage - 1 >= 0) {
      this.imageToShow =  this.imagesarray[this.indexofimage - 1]; 
      this.indexofimage = this.indexofimage - 1;
    }
    else {
     
    }
  }

  nextimage() {
    if(this.indexofimage + 1 < this.totalimages) {
      this.imageToShow =  this.imagesarray[this.indexofimage + 1] ;
      this.indexofimage = this.indexofimage + 1;
    }
    else {
    
    }
  }
}


