import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-tution-class-page',
  templateUrl: './tution-class-page.component.html',
  styleUrls: ['./tution-class-page.component.scss']
})
export class TutionClassPageComponent implements OnInit {
  public isstudent = false;
  public isteacher = false;
  constructor(private meta: Meta) {
    this.meta.addTags([
      { name: 'description', content: 'Buddi Life is an online tutoring services provider with 800+ courses and 100+ teachers. Become proficient in your field of study. Sign up for our classes now!' },
      { name:'title', content: 'Best Online Tuition Classes | Online Tutoring Services | Buddi Life' },
      { name:'keywords',content:'Online Tuition Classes,Online Tutoring Services,Best Online Tuition Classes,Online Class Tuition' }
    ]);
  }

  ngOnInit(): void {
    const userType = localStorage.getItem('userType');      
  if(userType.toLowerCase()=="teacher") {
    this.isteacher = true;
  }
  else {
    this.isstudent = true;
  }
  }
}
