<div *ngIf="!isStudent">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>
<div *ngIf="isStudent">
    <app-header-style-one></app-header-style-one>
</div>
<!-- <div class="container" routerLink="/sidenav"><button style="width: 10%;height:45px;margin-left:89%;margin-top:1%;padding: 9px;"><i class="fas fa-long-arrow-alt-left mr-1"></i>Back</button></div> -->
<div *ngIf="isTeacher">
    <div class="container mt-3 mb-3">
        <div class="flexButton">
            <h5 style="
                    color: #000000;
                    cursor: pointer;            
                  "
                >
                Upcoming Study Hall Classes
                <!-- <span class="more-text" *ngIf="classList">more</span> -->
            </h5>
            <div class="btn-group ml-3" style="margin-left:0rem !important">
                <button type="button"  (click)="studyClassFilter('today')">Today</button> 
                <button type="button"  (click)="studyClassFilter('tomorrow')" >Tomorrow</button> 
                <button type="button"  (click)="studyClassFilter('week')">This Week</button> 
                <button type="button"  (click)="studyClassFilter('month')" >This Month</button> 
                <button type="button"  (click)="backto()"><i class="fas fa-long-arrow-alt-left mr-1"></i>Back</button>
                </div>
        </div>
        <br>
 

            <!-- <button (click)="studyClassFilter('today')" class="mr-1">Today</button>
            <button (click)="studyClassFilter('tomorrow')" class="mr-1">Tomorrow</button>
            <button (click)="studyClassFilter('week')" class="mr-1">This Week</button>
            <button (click)="studyClassFilter('month')" class="mr-1">This Month</button> -->
        <div class="orders-table table-responsive mb-3">
        
            <table class="table">
                <thead>
                    <tr>
                        <th>Sl.No</th>
                        <th>Class Name</th>
                        <!-- <th> </th> -->
                        <th>Topic Name</th>
                        <th>Date & Time</th>
                        <th>Enrolled Learners</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor='let class of classroomList; let i = index' >
                        <td>{{i + 1}}</td>

                        <td class="height_set"> <a routerLink="javascript:void(0)" (click)="StudyhallClassDetails(class)"
                                class="d-inline-block"
                               >{{class.name}}</a></td>
                        <!-- <td *ngIf="class.teacherID">{{class.teacherID.firstName}} {{class.teacherID.lastName}}</td> -->
                        <td>
                            All Topics
                        </td>
                        <td *ngIf="class">
                            {{class.startDateTime}}
                        </td>
                        <td> {{class.enrolledStudents.join(',')}} </td>                      
                        <td>
                            <div class="Action align">
                                <div>
                                    <a href="javascript:void(0)"
                                   
                                    (click)="studyClassFunction(class, 'start')"> <i class="far fa-play-circle" data-toggle="tooltip" data-placement="bottom" title="Start class"></i></a>
                                    <!-- <a class="Action" href="javascript:void(0)"
                                        (click)="studyClassFunction(class,'start')">

                                        Start
                                    </a> -->
                                </div>
                                <div>
                                    <!-- {{class.isCompleted ? 'Completed' : class.isRejected ? 'Rejected by Teacher' :
                                    'Cancelled'}} -->
                                    <a href="javascript:void(0)"
                                   
                                    (click)="studyClassFunction(class, 'cancel')">
                                    <i class="fa fa-window-close" data-toggle="tooltip" data-placement="bottom" title="Cancel"></i></a>
                                </div>
                                <div>
                                    <a class="Action" href="javascript:void(0)"
                                        (click)="studyClassFunction(class,'share')">
                                        <i class="fas fa-share-alt-square" data-toggle="tooltip" data-placement="bottom" title="Share"></i>
                                    </a>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h4
                style="color: #000000; text-align: center"
                class="col-lg-12 mt-3"
                *ngIf="classroomList.length == 0"
            >
            No Study Hall Classes Available
            </h4>
            <div class="pagination-area text-center">
                <a
                    href="javascript:void(0)"
                    (click)="clickLiveClassPageNumber(1)"
                    *ngIf="currentLiveClassPageNumber > 2 && totalLiveClassPageCount > 1"
                    class="page-numbers"
                    >1</a
                >
                <span
                    class="page-numbers"
                    *ngIf="currentLiveClassPageNumber > 2 && totalLiveClassPageCount > 1"
                    aria-current="page"
                    >...</span
                >
                <a
                    href="javascript:void(0)"
                    (click)="clickLiveClassPageNumber(currentLiveClassPageNumber - 1)"
                    *ngIf="currentLiveClassPageNumber > 1 && totalLiveClassPageCount > 1"
                    class="page-numbers"
                    >{{ currentLiveClassPageNumber - 1 }}</a
                >
                <span
                    class="page-numbers current"
                    *ngIf="totalLiveClassPageCount != 0"
                    aria-current="page"
                    >{{ currentLiveClassPageNumber }}</span
                >
                <a
                    href="javascript:void(0)"
                    (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 1)"
                    *ngIf="
                        totalLiveClassPageCount > 1 &&
                        currentLiveClassPageNumber + 1 <= totalLiveClassPageCount
                    " class="page-numbers">{{ currentLiveClassPageNumber + 1 }}</a>
            <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 2)" *ngIf="
                        totalLiveClassPageCount > 1 &&
                        currentLiveClassPageNumber + 2 <= totalLiveClassPageCount
                    " class="page-numbers">{{ currentLiveClassPageNumber + 2 }}</a>
            <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 3)" *ngIf="
                        totalLiveClassPageCount > 1 &&
                        currentLiveClassPageNumber + 3 <= totalLiveClassPageCount
                    " class="page-numbers">{{ currentLiveClassPageNumber + 3 }}</a>
            <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 4)" *ngIf="
                        totalLiveClassPageCount > 1 &&
                        currentLiveClassPageNumber + 4 <= totalLiveClassPageCount
                    " class="page-numbers">{{ currentLiveClassPageNumber + 4 }}</a>
            <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 1)" *ngIf="
                        totalLiveClassPageCount > 1 &&
                        currentLiveClassPageNumber + 1 <= totalLiveClassPageCount
                    " class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
        </div>
        </div>
        <!--<div style="color: #aba3a3" *ngIf="classroomList.length == 0">
            No Studyhall Classes Available.
        </div>-->
    </div>

   
    </div>


<div *ngIf="isStudent">


    <div class="container mt-3">
        <!-- <h3 style="color: var(--mainColor); cursor: pointer" class="col-lg-12">
            Studyhall Classes </h3> -->
            <div class="flexButton">
                <h5 style="color: #000000;">
                    Upcoming Study Hall Classes
                    <!-- <span class="more-text" *ngIf="classList">more</span> -->
                </h5>
                <div class="btn-group ml-3" style="margin-left:0rem !important">
                    <button type="button" (click)="studyClassFilter('today')">Today</button> 
                    <button type="button" (click)="studyClassFilter('tomorrow')" >Tomorrow</button> 
                    <button type="button" (click)="studyClassFilter('week')">This Week</button> 
                    <button type="button" (click)="studyClassFilter('month')" >This Month</button> 
                    <button type="button" routerLink="/sidenav"><i class="fas fa-long-arrow-alt-left mr-1"></i>Back</button>
                </div>
            </div>
            <br>
     
        <div class="orders-table table-responsive mb-3">
            <table class="table">
                <thead>
                    <tr>
                        <th>Sl.No</th>
                        <th>Class Name</th>
                        <th>Topic Name</th>
                        <th>Date & Time</th>
                        <th>Tutor Name</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor='let class of classroomList; let i = index'>
                        <td>{{i + 1}}</td>

                        <td *ngIf="class.personalClassID" class="height_set">
                            <a routerLink="javascript:void(0)" (click)="StudyhallClassDetails(class)"
                                class="d-inline-block"
                               >{{class.personalClassID.className}}</a>
                        </td>
                        <td>
                            All Topics
                        </td>
                        <td *ngIf="class">
                            {{class.startDateTime}}
                        </td>
                        <td *ngIf="class.personalClassID">{{class.personalClassID && class.personalClassID.teacherID ? class.personalClassID.teacherID.profileName : '-'}}</td>
                        <td>
                            <!-- <div class="Action">
                            <div>
                                Join
                            </div>
                            <div>
                               Share
                            </div>Classes
                        </div> -->
                        <div class="row">
                           
                                <div class="Action align">
                                        <div class="col-lg-6">
                                      <a class="Action" href="javascript:void(0)"
                                        (click)="studyClassFunction(class,'join')">
                                        <i class="fas fa-sign-in-alt" data-toggle="tooltip" data-placement="bottom" title="Join class"></i>
                                    </a>
                                </div>

                                <div class="col-lg-6">
                                    <a class="Action" href="javascript:void(0)"
                                        (click)="studyClassFunction(class,'share')">
                                        <i class="fas fa-share-alt-square" data-toggle="tooltip" data-placement="bottom" title="Share"></i>
                                    </a>
                                </div>
                           
                        </div>
                    </div>
                        </td>



                        <!-- <a routerLink="/single-courses" class="view-button"
                            >View</a
                        > -->
                    </tr>
                </tbody>
            </table>
            <h4
                style="color: #000000; text-align: center"
                class="col-lg-12 mt-3"
                *ngIf="classroomList.length == 0"
            >
            No Study Hall Classes Available
            </h4>
            <div class="pagination-area text-center">
                <a
                    href="javascript:void(0)"
                    (click)="clickLiveClassPageNumber(1)"
                    *ngIf="currentLiveClassPageNumber > 2 && totalLiveClassPageCount > 1"
                    class="page-numbers"
                    >1</a
                >
                <span
                    class="page-numbers"
                    *ngIf="currentLiveClassPageNumber > 2 && totalLiveClassPageCount > 1"
                    aria-current="page"
                    >...</span
                >
                <a
                    href="javascript:void(0)"
                    (click)="clickLiveClassPageNumber(currentLiveClassPageNumber - 1)"
                    *ngIf="currentLiveClassPageNumber > 1 && totalLiveClassPageCount > 1"
                    class="page-numbers"
                    >{{ currentLiveClassPageNumber - 1 }}</a
                >
                <span
                    class="page-numbers current"
                    *ngIf="totalLiveClassPageCount != 0"
                    aria-current="page"
                    >{{ currentLiveClassPageNumber }}</span
                >
                <a
                    href="javascript:void(0)"
                    (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 1)"
                    *ngIf="
                        totalLiveClassPageCount > 1 &&
                        currentLiveClassPageNumber + 1 <= totalLiveClassPageCount
                    " class="page-numbers">{{ currentLiveClassPageNumber + 1 }}</a>
            <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 2)" *ngIf="
                        totalLiveClassPageCount > 1 &&
                        currentLiveClassPageNumber + 2 <= totalLiveClassPageCount
                    " class="page-numbers">{{ currentLiveClassPageNumber + 2 }}</a>
            <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 3)" *ngIf="
                        totalLiveClassPageCount > 1 &&
                        currentLiveClassPageNumber + 3 <= totalLiveClassPageCount
                    " class="page-numbers">{{ currentLiveClassPageNumber + 3 }}</a>
            <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 4)" *ngIf="
                        totalLiveClassPageCount > 1 &&
                        currentLiveClassPageNumber + 4 <= totalLiveClassPageCount
                    " class="page-numbers">{{ currentLiveClassPageNumber + 4 }}</a>
            <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 1)" *ngIf="
                        totalLiveClassPageCount > 1 &&
                        currentLiveClassPageNumber + 1 <= totalLiveClassPageCount
                    " class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
        </div>
        </div>
    </div>

 
</div>