<app-header-style-one *ngIf="!isTeacher"></app-header-style-one>
<app-header-style-one-teacher
    *ngIf="isTeacher"
></app-header-style-one-teacher>
<br />

<div class="container">
    <div class="container">
       <div class="row align">
         <div class="pipe">
            <h5 style="color: #000000;font-size: 25px;" >Round Table</h5>
         </div>
         <div class="pipe">
            <a *ngIf="isTeacher && isLogin" href="javascript:void(0)" (click)="redirectToCreate()"class="default-btn" >
            <i class="bx bx-paper-plane icon-arrow before"></i>
            <span class="label">Create Round Table</span>
            <i class="bx bx-paper-plane icon-arrow after"></i>
            </a>
        
          </div>
       </div>
    </div>
    <div class="container">
        <div class="courses-topbar">
                    <div class="topbar-ordering-and-search">
                        <div class="align1">
                            <div class="pipe col-lg-6 col-md-4 custom-switch"  *ngIf="isLogin=='true'">
                                <input type="checkbox" class="custom-control-input" id="customSwitches" (change)="toggleVisibility($event)">
                                <label class="custom-control-label" for="customSwitches"></label>
                                <div style="padding: 4px;">Switch <span *ngIf="marked">buddi</span><span *ngIf="!marked">Institutions</span></div>
                              </div>
                            <div class="col-lg-2 col-md-3 pipe col-sm-6" >
                                <div class="topbar-ordering">
                                    <select class="nice-select1" (change)="dropdownChange($event)">
                                        <option value="all" selected="selected">All</option>
                                        <option value="today">Today</option>
                                        <option value="tomorrow">Tomorrow </option>
                                        <option value="week">This Week</option>
                                        <option value="month">This Month</option>
                                    </select>
                                </div> 
                            </div>

                            <div class="pipe col-lg-3 col-md-4 col-sm-6">
                                <div class="topbar-search">
                                    <form>
                                        <label><i class="bx bx-search"></i></label>
                                        <input type="text" class="input-search" placeholder="Search here..."id="searchBar" /></form>
                                </div>
                            </div>
                            <div class="btn">
                                <button (click)="searchClicked()">Search</button>
                            </div>
                        </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
        <div class="col-lg-4 col-md-6" *ngFor="let slide of videoList">
            <div class="single-courses-item mb-30 p-4">
                <div class="courses-image">
                    <!-- <a routerLink="javascript:void(0)" (click)="detailsMeeting(slide, 'Livesession')" class="d-block"><img src={{slide.thumbnail}} alt="image"></a> -->
                </div>
                <h3 class="mb-3">
                    <a
                        routerLink="javascript:void(0)"
                        (click)="detailsMeeting(slide, 'Livesession')"
                        style="height: 60px"
                        data-toggle="tooltip" title="{{ slide.className }}">{{  slide.className | slice:0:40 }}{{slide.className.length > 40 ? '...' : ''}}</a
                    >
                </h3>
                <div style="overflow: hidden">
                    <p style="float: left">
                        <i class="bx bx-calendar"></i> {{ slide.startDateTime }}
                    </p>
                    <!-- <p style="float:right">{{slide.languageID.languageName}}</p> -->
                    <p style="float: right">
                        {{
                            slide.totalClassTime ? slide.totalClassTime : 30
                        }}
                        mins
                    </p>
                </div>
                <div class="courses-content px-0 my-3">
                    <div class="course-author d-flex align-items-center">
                        <img
                            [src]="
                                slide.teacherID &&
                                slide.teacherID.userPhoto &&
                                slide.teacherID.userPhoto != ''
                                    ? slide.teacherID.userPhoto
                                    : 'assets/img/profilePhotoPlaceholder1.png'
                            "
                            class="shadow"
                            alt="image"
                        />
                        <div class="w-100 d-flex justify-content-between">
                            <span>{{
                                slide.teacherID
                                    ? slide.teacherID.profileName
                                    : ""
                            }}</span>
                            <span>{{ slide.languageID.languageName }}</span>
                        </div>
                    </div>

                    <div
                        *ngIf="slide.rating && slide.rating != 0"
                        class="courses-rating"
                    >
                        <div class="review-stars-rated">
                            <i
                                *ngIf="slide.rating < 1"
                                class="bx bxs-star-half"
                            ></i>
                            <i
                                *ngIf="slide.rating >= 1"
                                class="bx bxs-star"
                            ></i>
                            <i
                                *ngIf="slide.rating >= 1.5 && slide.rating < 2"
                                class="bx bxs-star-half"
                            ></i>
                            <i
                                *ngIf="slide.rating >= 2"
                                classd-flex
                                align-items-center="bx bxs-star"
                            ></i>
                            <i
                                *ngIf="slide.rating >= 3 && slide.rating > 3.5"
                                class="bx bxs-star-half"
                            ></i>
                            <i
                                *ngIf="slide.rating >= 4"
                                class="bx bxs-star"
                            ></i>
                            <i
                                *ngIf="slide.rating >= 4 && slide.rating < 4.5"
                                class="bx bxs-star-half"
                            ></i>
                            <i
                                *ngIf="slide.rating >= 5"
                                class="bx bxs-star"
                            ></i>
                        </div>
                        <div class="rating-total">
                            {{ slide.rating }}
                        </div>
                    </div>
                </div>
                <div class="gradeContent">
                    <h4>{{ slide.categoryID.categoryName }}</h4>
                    <!-- <p>{{slide.courseID.courseName}}</p> -->
                    <p class="text-overflow">{{ slide.moduleID.moduleName }}</p>
                    <p data-toggle="tooltip" title="{{ slide.topicID.topicName }}" class="text">{{  slide.topicID.topicName | slice:0:50 }}{{slide.topicID.topicName.length > 50 ? '...' : ''}}</p>
                </div>


                <div *ngIf="isLogin == 'true' && isStudent">
                    <div class="button_centers">
                        <div class="btn">
                            <button
                                (click)="enrollClicked(slide, 'Livesession')"
                                class=""
                            >
                                Enroll
                            </button>
                        </div>
                        <div class="btn">
                            <button
                                (click)="redirectshare()"
                                class=""
                                data-toggle="modal"
                                data-target="#exampleModalCenter"
                            >
                                Share
                            </button>
                        </div>
                    </div>
                </div>
                <div *ngIf="isLogin == 'false'">
                    <div class="button_centers">
                        <div class="btn">
                            <button
                                (click)="redirectToLoginPage()"
                                class=""
                            >
                                Login
                            </button>
                        </div>
                    </div>
                </div>
                <div *ngIf="isTeacher">
                    <div class="button_centers1">
              
                        <div class="btn">
                            <button
                                (click)="redirectshare()"
                                class=""
                                data-toggle="modal"
                                data-target="#exampleModalCenter"
                            >
                                Share
                            </button>
                        </div>
                    </div>
                </div>
                <div class="courses-box-footer mt-3">
                    <ul>
                        <li class="courses-lesson">
                            <i class="fa fa-rupee-sign"></i>
                            {{ slide.price != 0 ? slide.price : "Free" }}

                            <!-- <i class='bx bx-book-open'></i> {{slide.totalClassTime ? slide.totalClassTime : 30}} mins -->
                        </li>
                        <li class="courses-lesson _leeson_2">
                            <i class="bx bx-book-open"></i> Level
                            {{ slide.level ? slide.level : 1 }}
                        </li>
                        <!-- <li class="courses-lesson"> 
                            {{slide.price != 0 ? slide.price : 'Free'}}
                        </li> -->
                        <!-- <li class="courses-lesson _leeson_2"> <i class='fa fa-rupee-sign'></i>
                            *******
                        </li>
                        <li class="courses-lesson"> <i class='fa fa-rupee-sign'></i>
                            INR 1999.00/buddi
                        </li>
                        <li class="courses-lesson _leeson_2">
                            Rating </li> -->
                    </ul>
                </div>
            </div>
            
                <div
                    class="modal fade"
                    id="exampleModalCenter"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                >
                    <div
                        class="modal-dialog modal-dialog-centered"
                        role="document"
                    >
               <div class="modal-content share_content">
                        <div class="modal-header">
                            <h5 class="modal-title"id="exampleModalCenterTitle">
                             Share with
                            </h5>
                            <button  type="button" class="close" data-dismiss="modal"  aria-label="Close" >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="fly">
                            <div class="social_icon_section" (click)="shareWhatsup(slide)">
                               <i class="fa fa-whatsapp what" data-toggle="tooltip" data-placement="right" title="Click here" style="font-size: 42px;color:#25D366;margin-bottom: 8px;"></i>
                               <strong >Whatsapp</strong>
                            </div>
                            <div class="social_icon_section" (click)="shareWhatsup1(slide)">
                               <i class="fa fa-telegram" data-toggle="tooltip" data-placement="right" title="Click here" style="font-size: 42px;color: #0088cc;margin-bottom: 8px;"></i>
                               <strong >Telegram</strong>
                         </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        <h4
                style="color: #000000; text-align: center"
                class="col-lg-12"
                *ngIf="videoList.length == 0"
            >
                No Round Table classes Available
            </h4>
            <div
                class="col-lg-12 col-md-12 col-sm-12"
                style="margin-bottom: 1cm"
            >
                <div class="pagination-area text-center">
                    <a
                        href="javascript:void(0)"
                        (click)="clickPageNumber(1)"
                        *ngIf="currentPageNumber > 2 && totalPageCount > 1"
                        class="page-numbers"
                        >1</a
                    >
                    <span
                        class="page-numbers"
                        *ngIf="currentPageNumber > 2 && totalPageCount > 1"
                        aria-current="page"
                        >...</span
                    >
                    <a
                        href="javascript:void(0)"
                        (click)="clickPageNumber(currentPageNumber - 1)"
                        *ngIf="currentPageNumber > 1 && totalPageCount > 1"
                        class="page-numbers"
                        >{{ currentPageNumber - 1 }}</a
                    >
                    <span
                        class="page-numbers current"
                        *ngIf="totalPageCount != 0"
                        aria-current="page"
                        >{{ currentPageNumber }}</span
                    >
                    <a
                        href="javascript:void(0)"
                        (click)="clickPageNumber(currentPageNumber + 1)"
                        *ngIf="
                            totalPageCount > 1 &&
                            currentPageNumber + 1 <= totalPageCount
                        "
                        class="page-numbers"
                        >{{ currentPageNumber + 1 }}</a
                    >
                    <a
                        href="javascript:void(0)"
                        (click)="clickPageNumber(currentPageNumber + 2)"
                        *ngIf="
                            totalPageCount > 1 &&
                            currentPageNumber + 2 <= totalPageCount
                        "
                        class="page-numbers"
                        >{{ currentPageNumber + 2 }}</a
                    >
                    <a
                        href="javascript:void(0)"
                        (click)="clickPageNumber(currentPageNumber + 3)"
                        *ngIf="
                            totalPageCount > 1 &&
                            currentPageNumber + 3 <= totalPageCount
                        "
                        class="page-numbers"
                        >{{ currentPageNumber + 3 }}</a
                    >
                    <a
                        href="javascript:void(0)"
                        (click)="clickPageNumber(currentPageNumber + 4)"
                        *ngIf="
                            totalPageCount > 1 &&
                            currentPageNumber + 4 <= totalPageCount
                        "
                        class="page-numbers"
                        >{{ currentPageNumber + 4 }}</a
                    >
                    <a
                        href="javascript:void(0)"
                        (click)="clickPageNumber(currentPageNumber + 1)"
                        *ngIf="
                            totalPageCount > 1 &&
                            currentPageNumber + 1 <= totalPageCount
                        "
                        class="next page-numbers"
                        ><i class="bx bx-chevron-right"></i
                    ></a>
                </div>
    </div>
</div>
