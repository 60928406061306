
<div class="container-fluid m-3 row " >
  <div class="col-lg-12  p-3 radius shadow" style="background-color: #FFFFFF; ">
  <ul class="nav nav-tabs" id="myTab" role="tablist"  >
     <li class="nav-item" >
          <a class="nav-link active" id="summary-tab" data-toggle="tab" href="#summary" role="tab" aria-controls="summary" aria-selected="true">Summary</a>
      </li>
      <li class="nav-item" >
          <a class="nav-link " id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">{{data.studentDetails.subjects.subjectName}}</a>
      </li>
    
  </ul>
  <div class="tab-content dashboard_bg" id="myTabContent" >
    
      <div class="tab-pane fade show " id="home" role="tabpanel" aria-labelledby="home-tab" >
        <app-chapter-topic [dataChapter]="data" ></app-chapter-topic>
      </div>
      <div class="tab-pane fade show active" id="summary" role="tabpanel" aria-labelledby="summary-tab">
        <app-dashboard-chart [data]="data"></app-dashboard-chart>
      </div>
      <div class="tab-pane fade" id="mylearning" role="tabpanel" aria-labelledby="mylearning-tab"> <br>
        My Learning Details</div>
      <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab"> <br>
      <app-student-profile></app-student-profile></div>
        <div class="tab-pane fade" id="account" role="tabpanel" aria-labelledby="account-tab"> <br>
          Account Details</div>
          <div class="tab-pane fade" id="payment" role="tabpanel" aria-labelledby="payment-tab"> <br>
            Payment Details</div>
            <div class="tab-pane fade" id="notification" role="tabpanel" aria-labelledby="notification-tab"> <br>
              Notification Details</div>
              <div class="tab-pane fade" id="messages" role="tabpanel" aria-labelledby="messages-tab"> <br>
                Messages Details</div>

          <div class="tab-pane fade active"  id="courses" role="tabpanel" aria-labelledby="courses-tab"> <br>
            <app-dashboard-chart></app-dashboard-chart></div>
      <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">...</div>
  </div>
</div>
</div>
