import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
declare var $:any;
@Component({
  selector: 'app-home-page-six',
  templateUrl: './home-page-six.component.html',
  styleUrls: ['./home-page-six.component.scss']
})
export class HomePageSixComponent implements OnInit {
  public isUserLoggedIn:string;
  public userType:string;
  public isTeacher: Boolean;
  constructor(private router: Router,public toastr: ToastrManager) { }
  
  ngOnInit(): void {
    if(localStorage.getItem('profileType') ==null || localStorage.getItem('webtoken') ==null ){
      localStorage.clear();
    }
    console.log(localStorage.getItem('profileType'))
    console.log(localStorage.getItem('userType'))
    // localStorage.setItem('teacherID', '');
    // localStorage.setItem('StudentID', '');
    //     localStorage.setItem('profileName','');
    //     localStorage.setItem('userType', '');
    // localStorage.setItem('isLoggedin','false');
    var owl = $('.slider');
    owl.owlCarousel({
        items:1,
        loop:false,
        autoplay:true,
        nav:true,
        margin: 10,
        // autoplayTimeout:1000,
        autoplayHoverPause:true
    });
    // $('.owl-carousel,.owl.nav,button.owl-prev').click(function(){
    //   $('.youtube-video')[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
    // });

    // $('.owl-carousel,.owl.nav,button.owl-next').click(function(){
    //   $('.youtube-video')[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
    // });

 $('.close').click(function(e) {
  //  alert('prev')
      var stopAllYouTubeVideos = () => { 
        var iframes = document.querySelectorAll('iframe');
        Array.prototype.forEach.call(iframes, iframe => { 
          iframe.contentWindow.postMessage(JSON.stringify({ event: 'command', 
        func: 'stopVideo' }), '*');
       });
      }
      stopAllYouTubeVideos();


    });
    
    $('.owl-carousel,.owl.nav,button.owl-next').click(function(e) {
      var stopAllYouTubeVideos = () => { 
        var iframes = document.querySelectorAll('iframe');
        Array.prototype.forEach.call(iframes, iframe => { 
          iframe.contentWindow.postMessage(JSON.stringify({ event: 'command', 
        func: 'stopVideo' }), '*');
       });
      }
      stopAllYouTubeVideos();

     

    });
    this.isUserLoggedIn = localStorage.getItem('isLoggedin');
    this.userType = localStorage.getItem('userType');
    this.isTeacher = this.userType === 'teacher'
  }
  studentclick() {   
    if(this.isUserLoggedIn.toLowerCase()=="true") {
      this.toastr.errorToastr("Already you have Logged in");
    }
    else {
      this.router.navigate(['/student/login']);
    }
  }
  teacherclick(){
    if(this.isUserLoggedIn.toLowerCase()=="true") {
      this.toastr.errorToastr("Already you have Logged in");
    }
    else {
      this.router.navigate(['/teacher/login']);
    }
  }

 

}
