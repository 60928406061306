<app-header-style-one></app-header-style-one>


<!-- <div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About</li>
            </ul>
            <h2>About Us</h2>
        </div>
    </div>
</div> -->

<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="../../../../assets/img/advisor.jpg" class="shadow" alt="image">
                    <!-- <img src="../../../../assets/img/aboutus-buddi.jpg" class="shadow" alt="image"> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">STRATEGIC ADVISOR</span>
                    <p>Prof.Samuel Johnson is a Digital coach and Educational Technology trainer. 
                        Working for the VIT-AP  University with VSB - VIT AP School of Business.  
                        He has worked in VIT Vellore, VIT Chennai in the area of faculty training and their Digital learning platforms setups. </p>
                    <p>Presently working as a Faculty in VIT AP School of Business ( VSB) VIT-AP, Also taking care of Student Welfare, Events and Branding activities at VIT-AP Amaravati Campus, Andhra Pradesh. He is a Digital Education Coach, Transforming many educational institutions in Higher Education Universities, Colleges and Schools. He is also consulting e-learning and Blended learning for corporate organizations in their learning and Development. Worked for VIT Vellore with Academic Staff College and VIT Chennai besides coordinating “Learning and Research Cell”, taking care of Faculty Training and their Continuous education programs for the last 12 years with VIT UNIVERSITY. Having worked with Mafoi Academy which is now RANDSTAD for training young faculty members, corporate employees. I AM A WIPRO and Dale Carnegie Certified Master Trainer. A Motivational Speaker and Corporate Trainer.</p>
                   <p>Having started corporate work experience with Mafoi Academy of Training which is now Randstad and being corporate HR trainer for Manufacturing and IT companies, Samuel Johnson is giving models to HR community to transform from Human Resources to Huma Relationships  </p>
                    <p>To name a few corporates where I give Learning and Development sessions. </p>
                    <ol>
                        <li>VALEO</li>
                        <li>HYUNDAI</li>
                        <li>FORD</li>
                        <li>RENAULT NISSAN</li>
                        <li>MICROSOFT </li>
                        <li>CTS</li>
                        <li>WIPRO</li>
                    </ol>
                </div>
            </div>
        </div>

    </div>
</section>