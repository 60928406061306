import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../app/shared/api.service';

@Component({
  selector: 'app-classcreation',
  templateUrl: './classcreation.component.html',
  styleUrls: ['./classcreation.component.scss']
})
export class ClasscreationComponent implements OnInit {
  isLogin: any = false;
  pagename: any;
  loginname: any;
  cartSize: number;
  public isTeacher: boolean = false;
  public profiletype = "";
  public userprofdataTeacher: any = [];
  constructor( private router: Router,private userApi: ApiService) { }

  ngOnInit(): void {
    this.profiletype = localStorage.getItem('profileType').toLowerCase();
    const webToken = localStorage.getItem('webtoken');
    if (localStorage.getItem('profileType') != null) {
      this.isTeacher = localStorage.getItem('profileType').toLowerCase() == 'teacher'
    }
    if (localStorage.getItem('cartItems') == null) {
      this.cartSize = 0
    }
    else {
      this.cartSize = JSON.parse(localStorage.getItem('cartItems')).length;
    }
    window.addEventListener('storage', () => {
      console.log("called")
    }, false);


    this.pagename = localStorage.getItem('profileType');
    this.loginname = this.pagename + '/login';
    this.isLogin = localStorage.getItem('isLoggedin');

    this.getTeacherdetails();
  }

  getTeacherdetails(){
    const webToken = localStorage.getItem('webtoken');
    this.userApi.userName_DisplayTeacher(webToken).subscribe((result) => {
      console.log(result.data);
      this.userprofdataTeacher = result.data;
    }, (err) => {
    console.log(err);
    });
  }
  logout() {
    localStorage.setItem('isLoggedin', 'false');
    window.location.href = localStorage.getItem('profileType').toLowerCase() + '/login';
  }
}
