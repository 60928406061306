<div *ngIf="isTeacher == true || isStudent == true" class="container mt-3">
    <h3 style="color: var(--mainColor); cursor: pointer" class="col-lg-12">
        buddi Tests
    </h3>
    <!-- <h3 (click) = "redirectbuddiTests()"style="color: var(--mainColor); cursor: pointer"  class="col-lg-12">
        buddi Tests
        <span class="more-text" *ngIf="testList.length!=0">more</span>
           </h3> -->
    <div class="container-fluid row" style="cursor: pointer">
        <div class="col-lg-4 col-md-6 float:left" *ngFor="let test of testList">
            <div class="single-courses-item mb-30">
                <div class="courses-image">
                    <a
                        routerLink="javascript:void(0)"
                        (click)="testDetails(test)"
                        class="d-block"
                    >
                        <img
                            [src]="
                                test.thumbnail
                                    ? test.thumbnail
                                    : 'assets/img/courses/courses1.jpg'
                            "
                            alt="image"
                    /></a>
                </div>

                <div class="courses-content">
                    <div
                        class="
                            d-flex
                            justify-content-between
                            align-items-center
                        "
                    >
                        <div class="course-author d-flex align-items-center">
                            <img
                                src="assets/img/user1.jpg"
                                class="shadow"
                                alt="image"
                            />
                            <span>{{
                                test.teacherID
                                    ? test.teacherID.firstName
                                    : "Buddi"
                            }}</span>
                        </div>

                        <div *ngIf="test.rating && test.rating != 0" class="courses-rating">
                                <div class="review-stars-rated">
                                    <i *ngIf="test.rating < 1" class='bx bxs-star-half'></i>
                                    <i *ngIf="test.rating >= 1" class='bx bxs-star'></i>
                                    <i *ngIf="test.rating >= 1.5 && test.rating < 2" class='bx bxs-star-half'></i>
                                    <i *ngIf="test.rating >= 2" class='bx bxs-star'></i>
                                    <i *ngIf="test.rating >= 2 && test.rating < 2.5" class='bx bxs-star-half'></i>
                                    <i *ngIf="test.rating >= 3" class='bx bxs-star'></i>
                                    <i *ngIf="test.rating >= 3 && test.rating > 3.5" class='bx bxs-star-half'></i>
                                    <i *ngIf="test.rating >= 4" class='bx bxs-star'></i>
                                    <i *ngIf="test.rating >= 4 && test.rating < 4.5" class='bx bxs-star-half'></i>
                                    <i *ngIf="test.rating >= 5" class='bx bxs-star'></i>
                                </div>
                                <div class="rating-total">
                                    {{test.rating}}
                                </div>
                            </div>
                    </div>
                    <h3>
                        <a
                            routerLink="javascript:void(0)"
                            (click)="testDetails(test)"
                            class="d-inline-block"
                            style="height: 60px"
                            >{{ test.testName ? test.testName : "" }}</a
                        >
                    </h3>
                    <div style="overflow: hidden">
                        <p style="float: left">
                            {{
                                test.languageID ? test.languageID.languageName : ""
                            }}
                        </p>
                    </div>

                    <p>
                        {{ test.moduleID ? test.moduleID.moduleName : "NA" }}
                    </p>
                </div>

                <div class="courses-box-footer">
                    <ul>
                        <li class="students-number">
                            <i class="bx bx-user"></i>
                            {{ test.numberOfQuestions ? test.numberOfQuestions : "0" }}
                            questions
                        </li>
                        <li class="students-number">
                            <i class="bx bx-user"></i>
                            Level
                            {{ test.level ? test.level : "1" }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div
            style="color: #aba3a3;"
            class="col-lg-12"
            *ngIf="testList.length == 0"
        >
            No Tests Available.
        </div>
    </div>
</div>