import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { ApiService } from '../../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-teachers-pricing',
  templateUrl: './teachers-pricing.component.html',
  styleUrls: ['./teachers-pricing.component.scss']
})
export class TeachersPricingComponent implements OnInit {

  exploreForm: FormGroup;
  exploreFormPublic: FormGroup;

  constructor(private formBuilder: FormBuilder,private userApi: ApiService,public toastr: ToastrManager, private router: Router, private location:Location) { }

  ngOnInit(): void {
    this.exploreForm = this.formBuilder.group({    
      firstname: ['', [Validators.required, Validators.pattern("^[a-zA-Z ,.'-]+$")]],
      personallevel1: [''],
      personallevel2: [''],
      personallevel3: [''],
      personallevel4: [''],
      videolevel1: [''],
      videolevel2: [''],
      videolevel3: [''],
      videolevel4: [''],
      testlevel1: [''],
      testlevel2: [''],
      testlevel3: [''],
      testlevel4: [''],
      // monitoredlevel1: [''],
      // monitoredlevel2: [''],
      // monitoredlevel3: [''],
      // monitoredlevel4: [''],
      materiallevel1: [''],
      materiallevel2: [''],
      materiallevel3: [''],
      materiallevel4: [''],
      grouplevel1: [''],
      grouplevel2: [''],
      grouplevel3: [''],
      grouplevel4: [''],
      studyHallLevel1: [''],
      studyHallLevel2: [''],
      studyHallLevel3: [''],
      studyHallLevel4: [''],
      roundTableLevel1: [''],
      roundTableLevel2: [''],
      roundTableLevel3: [''],
      roundTableLevel4: ['']
    });

    this.getpricing();
  }

  get f() { return this.exploreForm.controls; }


  getpricing() {
    const webToken = localStorage.getItem('webtoken');
    if(webToken != null){
    this.userApi.updatePricingTemplate(webToken, null).subscribe((result) => {      
      if(result.status) {
        var pricingTemplate = result.data.pricingTemplate;
        this.exploreForm.patchValue({ personallevel1: pricingTemplate.personalClassPrice && pricingTemplate.personalClassPrice.level1Price ? pricingTemplate.personalClassPrice.level1Price : 0});
        this.exploreForm.patchValue({ personallevel2: pricingTemplate.personalClassPrice && pricingTemplate.personalClassPrice.level2Price ? pricingTemplate.personalClassPrice.level2Price : 0});
        this.exploreForm.patchValue({ personallevel3: pricingTemplate.personalClassPrice && pricingTemplate.personalClassPrice.level3Price ? pricingTemplate.personalClassPrice.level3Price : 0});
        this.exploreForm.patchValue({ personallevel4: pricingTemplate.personalClassPrice && pricingTemplate.personalClassPrice.level4Price ? pricingTemplate.personalClassPrice.level4Price : 0});

        this.exploreForm.patchValue({ videolevel1: pricingTemplate.videoClassPrice && pricingTemplate.videoClassPrice.level1Price ? pricingTemplate.videoClassPrice.level1Price : 0});
        this.exploreForm.patchValue({ videolevel2: pricingTemplate.videoClassPrice && pricingTemplate.videoClassPrice.level2Price ? pricingTemplate.videoClassPrice.level2Price : 0});
        this.exploreForm.patchValue({ videolevel3: pricingTemplate.videoClassPrice && pricingTemplate.videoClassPrice.level3Price ? pricingTemplate.videoClassPrice.level3Price : 0});
        this.exploreForm.patchValue({ videolevel4: pricingTemplate.videoClassPrice && pricingTemplate.videoClassPrice.level4Price ? pricingTemplate.videoClassPrice.level4Price : 0});

        this.exploreForm.patchValue({ testlevel1: pricingTemplate.testPrice && pricingTemplate.testPrice.level1Price ? pricingTemplate.testPrice.level1Price : 0});
        this.exploreForm.patchValue({ testlevel2: pricingTemplate.testPrice && pricingTemplate.testPrice.level2Price ? pricingTemplate.testPrice.level2Price : 0});
        this.exploreForm.patchValue({ testlevel3: pricingTemplate.testPrice && pricingTemplate.testPrice.level3Price ? pricingTemplate.testPrice.level3Price : 0});
        this.exploreForm.patchValue({ testlevel4: pricingTemplate.testPrice && pricingTemplate.testPrice.level4Price ? pricingTemplate.testPrice.level4Price : 0});

        // this.exploreForm.patchValue({ monitoredlevel1: pricingTemplate.monitoredTestPrice && pricingTemplate.monitoredTestPrice.level1Price ? pricingTemplate.monitoredTestPrice.level1Price : 0});
        // this.exploreForm.patchValue({ monitoredlevel2: pricingTemplate.monitoredTestPrice && pricingTemplate.monitoredTestPrice.level2Price ? pricingTemplate.monitoredTestPrice.level2Price : 0});
        // this.exploreForm.patchValue({ monitoredlevel3: pricingTemplate.monitoredTestPrice && pricingTemplate.monitoredTestPrice.level3Price ? pricingTemplate.monitoredTestPrice.level3Price : 0});
        // this.exploreForm.patchValue({ monitoredlevel4: pricingTemplate.monitoredTestPrice && pricingTemplate.monitoredTestPrice.level4Price ? pricingTemplate.monitoredTestPrice.level4Price : 0});

        this.exploreForm.patchValue({ materiallevel1: pricingTemplate.smartMaterialPrice && pricingTemplate.smartMaterialPrice.level1Price ? pricingTemplate.smartMaterialPrice.level1Price : 0});
        this.exploreForm.patchValue({ materiallevel2: pricingTemplate.smartMaterialPrice && pricingTemplate.smartMaterialPrice.level2Price ? pricingTemplate.smartMaterialPrice.level2Price : 0});
        this.exploreForm.patchValue({ materiallevel3: pricingTemplate.smartMaterialPrice && pricingTemplate.smartMaterialPrice.level3Price ? pricingTemplate.smartMaterialPrice.level3Price : 0});
        this.exploreForm.patchValue({ materiallevel4: pricingTemplate.smartMaterialPrice && pricingTemplate.smartMaterialPrice.level4Price ? pricingTemplate.smartMaterialPrice.level4Price : 0});

        this.exploreForm.patchValue({ grouplevel1: pricingTemplate.groupClassPrice && pricingTemplate.groupClassPrice.level1Price ? pricingTemplate.groupClassPrice.level1Price : 0});
        this.exploreForm.patchValue({ grouplevel2: pricingTemplate.groupClassPrice && pricingTemplate.groupClassPrice.level2Price ? pricingTemplate.groupClassPrice.level2Price : 0});
        this.exploreForm.patchValue({ grouplevel3: pricingTemplate.groupClassPrice && pricingTemplate.groupClassPrice.level3Price ? pricingTemplate.groupClassPrice.level3Price : 0});
        this.exploreForm.patchValue({ grouplevel4: pricingTemplate.groupClassPrice && pricingTemplate.groupClassPrice.level4Price ? pricingTemplate.groupClassPrice.level4Price : 0});

        this.exploreForm.patchValue({ studyHallLevel1: pricingTemplate.studyHallPrice && pricingTemplate.studyHallPrice.level1Price ? pricingTemplate.studyHallPrice.level1Price : 0});
        this.exploreForm.patchValue({ studyHallLevel2: pricingTemplate.studyHallPrice && pricingTemplate.studyHallPrice.level2Price ? pricingTemplate.studyHallPrice.level2Price : 0});
        this.exploreForm.patchValue({ studyHallLevel3: pricingTemplate.studyHallPrice && pricingTemplate.studyHallPrice.level3Price ? pricingTemplate.studyHallPrice.level3Price : 0});
        this.exploreForm.patchValue({ studyHallLevel4: pricingTemplate.studyHallPrice && pricingTemplate.studyHallPrice.level4Price ? pricingTemplate.studyHallPrice.level4Price : 0});

        this.exploreForm.patchValue({ roundTableLevel1: pricingTemplate.roundTablePrice && pricingTemplate.roundTablePrice.level1Price ? pricingTemplate.roundTablePrice.level1Price : 0});
        this.exploreForm.patchValue({ roundTableLevel2: pricingTemplate.roundTablePrice && pricingTemplate.roundTablePrice.level2Price ? pricingTemplate.roundTablePrice.level2Price : 0});
        this.exploreForm.patchValue({ roundTableLevel3: pricingTemplate.roundTablePrice && pricingTemplate.roundTablePrice.level3Price ? pricingTemplate.roundTablePrice.level3Price : 0});
        this.exploreForm.patchValue({ roundTableLevel4: pricingTemplate.roundTablePrice && pricingTemplate.roundTablePrice.level4Price ? pricingTemplate.roundTablePrice.level4Price : 0});
        

      }
      else {
      }
    }, (err) => {
      console.log(err);
      });
  }
}

submit() {
  const webToken = localStorage.getItem('webtoken');
  const data = this.exploreForm.value;
  var req: any =   {
    "personalClassPrice":{
        "level1Price":data.personallevel1,
        "level2Price":data.personallevel2,
        "level3Price":data.personallevel3,
        "level4Price":data.personallevel4
    },
    "videoClassPrice":{
        "level1Price":data.videolevel1,
        "level2Price":data.videolevel2,
        "level3Price":data.videolevel3,
        "level4Price":data.videolevel4
    },
    "testPrice":{
        "level1Price":data.testlevel1,
        "level2Price":data.testlevel2,
        "level3Price":data.testlevel3,
        "level4Price":data.testlevel4
    },
    // "monitoredTestPrice":{
    //     "level1Price":data.monitoredlevel1,
    //     "level2Price":data.monitoredlevel2,
    //     "level3Price":data.monitoredlevel3,
    //     "level4Price":data.monitoredlevel4
    // },
    "smartMaterialPrice":{
        "level1Price":data.materiallevel1,
        "level2Price":data.materiallevel2,
        "level3Price":data.materiallevel3,
        "level4Price":data.materiallevel4
    },
    "groupClassPrice":{
        "level1Price":data.grouplevel1,
        "level2Price":data.grouplevel2,
        "level3Price":data.grouplevel3,
        "level4Price":data.grouplevel4
    },
    "studyHallPrice":{
      "level1Price":data.studyHallLevel1,
      "level2Price":data.studyHallLevel2,
      "level3Price":data.studyHallLevel3,
      "level4Price":data.studyHallLevel4
    },
    "roundTablePrice":{
      "level1Price":data.roundTableLevel1,
      "level2Price":data.roundTableLevel2,
      "level3Price":data.roundTableLevel3,
      "level4Price":data.roundTableLevel4
  }
}
    if(webToken != null){
    this.userApi.updatePricingTemplate(webToken, req).subscribe((result) => {      
      if(result.status) {
        this.toastr.successToastr(result.message);
        // setTimeout(() => {
        //   window.location.reload();
        // },
        //   1000);
        this.location.back();
       
      }
    }, (err) => {
      console.log(err);
      this.toastr.errorToastr(err);
      });
    }
}

}
