<app-header-style-one *ngIf="!isTeacher"></app-header-style-one>
<app-header-style-one-teacher *ngIf="isTeacher"></app-header-style-one-teacher>
<div class="container">
<div class="container" routerLink="/institution-details"><button style="width: 10%;height:45px;margin-left: 89%;margin-top:1%;padding: 9px;"><i class="fas fa-long-arrow-alt-left mr-1"></i>Back</button></div>
<div class="container-fluid row">
  <div class="col-lg-3 col-md-3">
    <h5 style="color: #222; margin-bottom: 10px;">Summary Details</h5>
  </div>
      <div class="orders-table table-responsive">
          <table class="table">
              <thead>
                  <tr>
                      <th>S.No</th>
                      <th>Institutions</th>
                      <th>Subscriptions</th>
                      <th>Total Tutors</th>
                      <th>Total Learners</th>
                      <th>Remaining Classes</th>
                  </tr>
              </thead>
              <tbody>
                   <tr>
                      <td>1</td>
                      <td>{{institutionDetails.institutionName}}</td>
                      <td> {{institutionDetails.subscriptionType.institutionTypeName}}</td>
                      <td (click)="tutor()" style="cursor:pointer;"><a style="color: var(--mainColor);text-decoration: underline;">View</a></td>
                      <td (click)="learner()" style="cursor:pointer;"><a style="color: var(--mainColor);text-decoration: underline;">View</a></td>
                     <td style="display: flex;flex-direction: column;">
                      <div>Live Classes: {{institutionDetails.remainingLiveClass}}</div>
                     <div>Study hall: {{institutionDetails.remainingStudyHall}}</div>
                     <div>Round Table: {{institutionDetails.remainingRoundTable}}</div>
                     </td>
                  </tr>
                  <div class="modal fade" id="Institution" role="dialog">
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header">
                              <h4 class="modal-title">Institution details</h4>
                              <button type="button" class="close" data-dismiss="modal">&times;</button>
                          </div>
                          <div class="modal-body">
                          <p><b>Institution Name  :</b> {{institutionDetails.institutionName}}</p>
                          <p><b>Institution Short Name  :</b> {{institutionDetails.institutionShortName}}</p> 
                          <p><b>Institution Address  :</b> {{institutionDetails.institutionAddress}}</p> 
                          <p><b>Expertise  : </b>{{institutionDetails.expertise}}</p> 
                          <p><b>Description  : </b>{{institutionDetails.description}}</p> 
                          <p><b>Phone Number  : </b>{{institutionDetails.phoneNumber}}</p>
                          <p><b>Classes  : </b>{{institutionDetails.classes}}</p> 
                          <p><b>LinkedIn URL  : </b>{{institutionDetails.linkedin}}</p> 
                          <p><b>Whatsapp URL   : </b>{{institutionDetails.whatsapp}}</p>  
                          <p><b>Reviews   : </b> {{institutionDetails.reviews}}</p>     
                        </div>
                        </div> 
                      </div>
                    </div>
              </tbody>
          </table>
</div>
</div>

<div class="row" *ngIf="tutortable">
<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
  <div class="card shadow borderR_10">
    <div class="header text-center pt-2">
      <h6 >Tutor Details</h6>
      <button type="button" class="close" data-dismiss="modal" (click)="tutorclose()">&times;</button>
    </div>
    <div class="tableBody p-0 font-12">
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>S.no</th>
              <th>First Name&nbsp; <i class="fa fa-sort"></i></th>
              <th>Last Name&nbsp; <i class="fa fa-sort"></i></th>
              <th>Phone Number &nbsp; <i class="fa fa-sort"></i></th>
              <th>Email &nbsp; <i class="fa fa-sort"></i></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let teach of teachlist;let i = index">
              <td>{{i+1}}</td>
              <td>{{teach.firstName}}</td>
              <td>{{teach.lastName}}</td>
              <td>{{teach.phoneNumber}}</td>
              <td>{{teach.email}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
</div>
<div class="row"  *ngIf="learnertable">
<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
<div class="card shadow borderR_10">
  <div class="header text-center pt-2">
    <h6>Learner Details</h6>
    <button type="button" class="close" data-dismiss="modal" (click)="learnerclose()">&times;</button>
  </div>
  <div class="tableBody p-0 font-12">
    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>S.no</th>
            <th>First Name&nbsp; <i class="fa fa-sort"></i></th>
            <th>Last Name&nbsp; <i class="fa fa-sort"></i></th>
            <th>Phone Number &nbsp; <i class="fa fa-sort"></i></th>
            <th>Email &nbsp; <i class="fa fa-sort"></i></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let stud of studlist;let i = index">
            <td>{{i+1}}</td>
            <td>{{stud.firstName}}</td>
            <td>{{stud.lastName}}</td>
            <td>{{stud.phoneNumber}}</td>
            <td>{{stud.email}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</div>
</div>
</div>