import { Component, OnInit,Inject, ElementRef, ViewChild, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Options } from 'ng5-slider';
import { ApiService } from '../../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {NgSelectModule, NgOption} from '@ng-select/ng-select';
import { environment } from '../../../../../environments/environment';
@Component({
  selector: 'app-homefive-courses',
  templateUrl: './homefive-courses.component.html',
  styleUrls: ['./homefive-courses.component.scss']
})
export class HomefiveCoursesComponent implements OnInit {

 
  public meetingsData: any = [];
  public meetingsDataListen: any = [];
  public meetingsDataAll: any = [];
  public allmeeting:any = [];
  public inviteUrl = "";
  public frontwebsite = environment.frontUrl;
  public meetingVisible = true;
  public lessonData:any=[];
  public myId:any=''
  public groupName:any='';
  public isLogin:any='false';
  
  public userdetails:any=[];
  constructor(private activatedRoute:ActivatedRoute,public location: Location, public toastr: ToastrManager, private formBuilder: FormBuilder, private rest: ApiService, private router: Router) { }

  ngOnInit(): void {
    this.isLogin = localStorage.getItem('isLoggedin');
    const webToken = localStorage.getItem('webtoken');
    this.myId = localStorage.getItem('userId');
    this.allmeetingList();
    this.rest.userName_DisplayTeacher(webToken).subscribe((result) => {
      console.log(result);
      
      this.groupName=result.data.groupName;
var req={
      groupname:this.groupName
    }

      this.rest.getMeetingWithCategoryGroup(this.groupName).subscribe((result) => {
        if(result.status){
          var exist = false;
          if(result.data.length > 0){
            exist = true;
            this.allmeeting = result.data
              this.meetingsData = result.data;
  
  
              // var sortRecord =  recordData.sort(function(a,b){return new Date(a.ScheduleDate).getTime() - new Date(b.ScheduleDate).getTime()});
              // this.meetingsRecodings=sortRecord
              var sortDate = this.meetingsData.sort(function(a,b){return new Date(a.ScheduleDate).getTime() - new Date(b.ScheduleDate).getTime()});
              const now = Date.now();
              const futureDates = sortDate.filter(date => {
                return date.ScheduleDate && new Date(date.ScheduleDate).setHours(0, 0, 0, 0) >=new Date().setHours(0, 0, 0, 0);
              });
             this.meetingsData = futureDates
              if(this.meetingsData[0] !=undefined){
                var profileName = this.meetingsData[0].teacherID.profileName;
                profileName = profileName.replace(/ /gi, "").replace(/-/gi, "_");
                this.inviteUrl =  this.frontwebsite+"invite/"+profileName+"-"+this.meetingsData[0].inviteurl;
  
              }
  
          }else{
              this.meetingVisible = false;
          }
          if(exist &&  this.allmeeting.length !=0){
            var temp = [];
            this.allmeeting.forEach(element => {
              var req={
                internalMeetingID:element.internalMeetingID,
                server:element.server
              }
              temp.push(req);
              console.log(element);
            });
          //this.getRecordings(temp,temp[0].server)
          }else{
            // this.meetingrecordvisible = false;
          }
        }
      }, (err) => {
      console.log(err);
      });
    });
    

  }
  allmeetingList(){
    this.rest.getMeetingWithCategoryGroup(this.groupName).subscribe((result) => {

      var temp = [];
      var mapArray = [];
      mapArray = result.data
      // result.data.forEach(function(key,val){mapArray.push(val)})
      // mapArray.forEach(function(val,key){
      //   if(val.meetingType == 'Public'){
      //     temp.push(val);
      //    }
      // })
        var sortDate = mapArray.sort(function(a,b){return a.ScheduleDate.localeCompare( b.ScheduleDate)});
      const now = Date.now();
      const futureDates = mapArray.filter(date => {
        return date.ScheduleDate && new Date(date.ScheduleDate).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)
      });
      this.lessonData = futureDates;
      console.log(this.lessonData);
     });
  }
    detailsMeeting(data,pagename){
      data.pagename = pagename;
      var jsonStr= JSON.stringify(data)
      localStorage.setItem('livedetails',jsonStr);
      this.router.navigate(['/teacher/meetingDetails/'+"/"+data.meetingID], { state:data });
    }
}

