<div class="hero-banner bg-white">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-banner-content black-color">
                            <span class="sub-title">We Challenge Young Minds</span>
                            <h1>Be a Part of the online education revolution. Join and Contribute</h1>
                            <p>Earn, by teaching at the convenience of your home. Flexible hours. Get Certified and Grow with us.</p>
                            <div class="btn-box">
                                <a routerLink="/teacher/register" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">Apply Now</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                                <!-- <a routerLink="/contact" class="optional-btn">Get Started Free</a> -->
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="hero-banner-image text-center">
                            <img src="assets/img/banner-img3.png" alt="image">
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
