import { Component, OnInit } from '@angular/core';
import { transpileModule } from 'typescript';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../shared/api.service';
import { Toastr, ToastrManager } from 'ng6-toastr-notifications';
import { Directive, HostListener } from '@angular/core';

@Component({
  selector: 'app-student-test-result',
  templateUrl: './student-test-result.component.html',
  styleUrls: ['./student-test-result.component.scss']
})
export class StudentTestResultComponent implements OnInit {
  selectedoptionform: FormGroup;
  public TestlinkID: any;
  formDatasubjective;
  public TestData: any = [];
  time: number = 0;
  public testid: any;
  display;
  public answerimage: any = [];
  interval;
  public isstudent = false;
  selectedFileNmae: string = 'Choose File';
  public isteacher = false;
  public isinstruction = false;
  public isquestion = true;
  public isoption1 = false;
  public isoption2 = false;
  public isquestiontype3: boolean = false;
  public isoption3 = false;
  public isoption4 = false;
  public isoption1correct = false;
  public isoption2correct = false;
  public isoption3correct = false;
  public isoption4correct = false;
  public isoption1incorrect = false;
  public isoption2incorrect = false;
  public isoption3incorrect = false;
  public isoption4incorrect = false;
  public subjectnameasstring: string = "";
  public result1: any;
  public answerlist: any = [];
  public questioncount: number = 0;
  public answeredcount: number = 0;
  public totalquestioncount: number = 0;
  public unansweredcount: number = 0;
  public reviewedcount: number = 0;
  public subjectname1 = "";
  public subjectname2 = "";
  public subjectname3 = "";
  public subjectname4 = "";
  public subjectname1array: any = [];
  public subjectname2array: any = [];
  public subjectname3array: any = [];
  public subjectname4array: any = [];
  public isquestiontext: boolean = false;
  public isquestionimage: boolean = false;
  public isquestiontextnull: boolean = false;
  public istobereviewed: boolean = false;
  testDetails: any;
  public totalmarksforquestion: number = 0;
  public totalmarksobtainedforquestion: number = 0;
  public questioncountimage: string = "";
  public isfirsttime: boolean = true;
  public subjectname1arrowup: boolean = true;
  public subjectname1arrowdown: boolean = false;
  public subjectname1arrayquestion: boolean = true;
  public subjectname2arrowup: boolean = false;
  public subjectname2arrowdown: boolean = true;
  public subjectname2arrayquestion: boolean = false;
  public subjectname3arrowup: boolean = false;
  public subjectname3arrowdown: boolean = true;
  public subjectname3arrayquestion: boolean = false;
  public subjectname4arrowup: boolean = false;
  public subjectname4arrowdown: boolean = true;
  public subjectname4arrayquestion: boolean = false;
  public subjectname1arraylength: any;
  public subjectname2arraylength: any;
  public subjectname3arraylength: any;
  public subjectname4arraylength: any;
  public indexofimage= 0;
  public imageToShow = '';
  public totalimages= 0;
  public viewimages: boolean = false;
  public imagesarray: any = [];


  constructor(private formBuilder: FormBuilder, private my_router: ActivatedRoute, public toastr: ToastrManager, private rest: ApiService, private router: Router) { }
  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }

  ngOnInit(): void {
    this.TestlinkID = this.my_router.snapshot.params['id'];
    this.selectedoptionform = this.formBuilder.group({
      option1: [''],
      option2: [''],
      option3: [''],
      option4: [''],
      upload_name: [null],
      fileupload: [''],
      Remarks: [''],
      marksObtained: ['']
    });

    var usertype = localStorage.getItem('profileType');
    if (usertype.toLowerCase() == "teacher") {
      this.isteacher = true;
    }
    else {
      this.isstudent = true;
    }

    this.loadpage();
    this.loaddata();
  }

  // convenience getter for easy access to form fields
  get f() { return this.selectedoptionform.controls; }

  loadpage() {
    // var formData = {};
    // formData["testID"] = this.TestlinkID
    // formData["studentID"] = localStorage.getItem('StudentID');
    // this.rest.getTestsdetails(formData).subscribe((result) => {

    //   this.TestData = result.data;

    //   for (var i = 0; i < this.TestData.subject.length; i++) {
    //     if (this.subjectnameasstring == "") {
    //       this.subjectnameasstring = this.TestData.subject[i].subjectName
    //     }
    //     else {
    //       this.subjectnameasstring = this.subjectnameasstring + "," + this.TestData.subject[i].subjectName
    //     }
    //   }

    // }, (err) => {
    //   console.log(err);
    // });

    // console.log("testdata", this.TestData);

    // setTimeout(() => {
    //   this.isinstruction = false;
    //   this.isquestion = true;
    //   this.startTimer();
    // },
    //   1000);


  }

  // startTimer() {
  //   console.log("=====>");
  //   this.interval = setInterval(() => {
  //     if (this.time === 0) {
  //       this.time++;
  //     } else {
  //       this.time++;
  //     }
  //     this.display = this.transform(this.time)
  //   }, 1000);
  // }

  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return minutes + ':' + (value - minutes * 60);
  }

  pauseTimer() {
    clearInterval(this.interval);
  }

  loaddata() {
    var token = localStorage.getItem('webtoken');
    var formData = {};
    formData["id"] = this.TestlinkID
    var regexSpace = /\ /gi;
    this.rest.getsinglestudentresultdetails(token, formData).subscribe((result) => {
      this.testDetails = result.data;
      this.answerlist = result.data.answerList;
      this.totalquestioncount = this.answerlist.length;
      let containsimage = this.answerlist.some(x => x.studentAnswer === "<image>");    
      this.answeredcount = this.testDetails.totalAnswered;
      this.unansweredcount = this.testDetails.totalUnanswered;
      this.reviewedcount = this.testDetails.totalReviewed  
      if(result.data.answerImages.length > 0) {
        this.imagesarray = result.data.answerImages;
        this.imageToShow = this.imagesarray[0];
        this.totalimages = this.imagesarray.length;
        this.viewimages = true;
      }

      if (this.testDetails.reviewRequestDate === undefined) {
        this.istobereviewed = true;
      }

      if (result.data.sectionFormat == "Marks") {
        const distinctmarks = this.answerlist.filter(
          (thing, i, arr) => arr.findIndex(t => t.correctAnswerMarks === thing.correctAnswerMarks) === i
        );
        console.log("marks", distinctmarks);
        for (var i = 0; i < distinctmarks.length; i++) {
          if (i == 0) {
            this.subjectname1 = distinctmarks[i].correctAnswerMarks;
          }
          else if (i == 1) {
            this.subjectname2 = distinctmarks[i].correctAnswerMarks;
          }
          else if (i == 2) {
            this.subjectname3 = distinctmarks[i].correctAnswerMarks;
          }
          else if (i == 3) {
            this.subjectname4 = distinctmarks[i].correctAnswerMarks;
          }
        }

        for (var i = 0; i < this.answerlist.length; i++) {
          if (this.answerlist[i].correctAnswerMarks == this.subjectname1) {
            this.subjectname1array.push(this.answerlist[i]);
          }
          else if (this.answerlist[i].correctAnswerMarks == this.subjectname2) {
            this.subjectname2array.push(this.answerlist[i]);
          }
          else if (this.answerlist[i].correctAnswerMarks == this.subjectname3) {
            this.subjectname3array.push(this.answerlist[i]);
          }
          else if (this.answerlist[i].correctAnswerMarks == this.subjectname4) {
            this.subjectname4array.push(this.answerlist[i]);
          }
        }

        this.subjectname1 = this.subjectname1 + " Marks"
        this.subjectname2 = this.subjectname2 + " Marks"
        this.subjectname3 = this.subjectname3 + " Marks"
        this.subjectname4 = this.subjectname4 + " Marks"

        if (this.answerlist[i].questionText.replace(regexSpace, "").includes("<i=") && this.answerlist[i].questionText.includes(">")) {
          var tempString = this.answerlist[i].questionText;
          var listOfMatches = [...this.answerlist[i].questionText.matchAll('<.*?>')];
          this.answerlist[i]["images"] = [];
          for (var j = 0; j < listOfMatches.length; j++) {
            var startIndex = j == 0 ? 0 : listOfMatches[j - 1]["index"] + listOfMatches[j - 1][0].length;
            this.answerlist[i]["images"].push(tempString.substring(startIndex, listOfMatches[j]["index"]))
            this.answerlist[i]["images"].push("https://s3.ap-south-1.amazonaws.com/buddi.bucket/budditests/" + listOfMatches[j][0].replace(regexSpace, "").replace("<i=", "").replace(">", ""));
            if (j == listOfMatches.length - 1) {
              this.answerlist[i]["images"].push(tempString.substring(listOfMatches[j]["index"] + 1 + listOfMatches[j][0].length, tempString.length))
            }
          }
          // this.answerlist[i]["imagePath"] = "https://s3.ap-south-1.amazonaws.com/buddi.bucket/budditests/" + tempString.substring(tempString.indexOf("<") + 1, tempString.indexOf(">")).split("=")[1];
          // this.answerlist[i]["beforeImage"] = tempString.substring(0, tempString.indexOf("<"))
          // this.answerlist[i]["afterImage"] = tempString.substring(tempString.indexOf(">") + 1, tempString.length)
          console.log("hello", this.answerlist[i]);
        }
      }
      else {
        const distinctsubjects = this.answerlist.filter(
          (thing, i, arr) => arr.findIndex(t => t.subject === thing.subject) === i
        ); 
       
  
        for (var i = 0; i < distinctsubjects.length; i++) {
          if (i == 0) {
            this.subjectname1 = distinctsubjects[i].subject;
          }
          else if (i == 1) {
            this.subjectname2 = distinctsubjects[i].subject;
          }
          else if (i == 2) {
            this.subjectname3 = distinctsubjects[i].subject;
          }
          else if (i == 3) {
            this.subjectname4 = distinctsubjects[i].subject;
          }
        }
  
        for (var i = 0; i < this.answerlist.length; i++) {
          if (this.answerlist[i].subject == this.subjectname1) {
            this.subjectname1array.push(this.answerlist[i]);
          }
          else if (this.answerlist[i].subject == this.subjectname2) {
            this.subjectname2array.push(this.answerlist[i]);
          }
          else if (this.answerlist[i].subject == this.subjectname3) {
            this.subjectname3array.push(this.answerlist[i]);
          }
          else if (this.answerlist[i].subject == this.subjectname4) {
            this.subjectname4array.push(this.answerlist[i]);
          }
        }
        for (var i = 0; i < this.answerlist.length; i++) {
          console.log(i);
          if (this.answerlist[i].questionText.replace(regexSpace, "").includes("<i=") && this.answerlist[i].questionText.includes(">")) {
            var tempString = this.answerlist[i].questionText;
            var listOfMatches = [...this.answerlist[i].questionText.matchAll('<.*?>')];
            this.answerlist[i]["images"] = [];
            for (var j = 0; j < listOfMatches.length; j++) {
              var startIndex = j == 0 ? 0 : listOfMatches[j - 1]["index"] + listOfMatches[j - 1][0].length;
              this.answerlist[i]["images"].push(tempString.substring(startIndex, listOfMatches[j]["index"]))
              this.answerlist[i]["images"].push("https://s3.ap-south-1.amazonaws.com/buddi.bucket/budditests/" + listOfMatches[j][0].replace(regexSpace, "").replace("<i=", "").replace(">", ""));
              if (j == listOfMatches.length - 1) {
                this.answerlist[i]["images"].push(tempString.substring(listOfMatches[j]["index"] + 1 + listOfMatches[j][0].length, tempString.length))
              }
            }
            // this.answerlist[i]["imagePath"] = "https://s3.ap-south-1.amazonaws.com/buddi.bucket/budditests/" + tempString.substring(tempString.indexOf("<") + 1, tempString.indexOf(">")).split("=")[1];
            // this.answerlist[i]["beforeImage"] = tempString.substring(0, tempString.indexOf("<"))
            // this.answerlist[i]["afterImage"] = tempString.substring(tempString.indexOf(">") + 1, tempString.length)
            console.log("hello", this.answerlist[i]);
          }
          if (this.answerlist[i].option1.replace(regexSpace, "").includes("<i=") && this.answerlist[i].option1.includes(">")) {
            var tempString = this.answerlist[i].option1;
            var listOfMatches = [...this.answerlist[i].option1.matchAll('<.*?>')];
            this.answerlist[i]["option1Images"] = [];
            for (var j = 0; j < listOfMatches.length; j++) {
              var startIndex = j == 0 ? 0 : listOfMatches[j - 1]["index"] + listOfMatches[j - 1][0].length;
              this.answerlist[i]["option1Images"].push(tempString.substring(startIndex, listOfMatches[j]["index"]))
              this.answerlist[i]["option1Images"].push("https://s3.ap-south-1.amazonaws.com/buddi.bucket/budditests/" + listOfMatches[j][0].replace(regexSpace, "").replace("<i=", "").replace(">", ""));
              if (j == listOfMatches.length - 1) {
                this.answerlist[i]["option1Images"].push(tempString.substring(listOfMatches[j]["index"] + 1 + listOfMatches[j][0].length, tempString.length))
              }
            }
          }
          if (this.answerlist[i].option2.replace(regexSpace, "").includes("<i=") && this.answerlist[i].option2.includes(">")) {
            var tempString = this.answerlist[i].option2;
            var listOfMatches = [...this.answerlist[i].option2.matchAll('<.*?>')];
            this.answerlist[i]["option2Images"] = [];
            for (var j = 0; j < listOfMatches.length; j++) {
              var startIndex = j == 0 ? 0 : listOfMatches[j - 1]["index"] + listOfMatches[j - 1][0].length;
              this.answerlist[i]["option2Images"].push(tempString.substring(startIndex, listOfMatches[j]["index"]))
              this.answerlist[i]["option2Images"].push("https://s3.ap-south-1.amazonaws.com/buddi.bucket/budditests/" + listOfMatches[j][0].replace(regexSpace, "").replace("<i=", "").replace(">", ""));
              if (j == listOfMatches.length - 1) {
                this.answerlist[i]["option2Images"].push(tempString.substring(listOfMatches[j]["index"] + 1 + listOfMatches[j][0].length, tempString.length))
              }
            }
          }
          if (this.answerlist[i].option3.replace(regexSpace, "").includes("<i=") && this.answerlist[i].option3.includes(">")) {
            var tempString = this.answerlist[i].option3;
            var listOfMatches = [...this.answerlist[i].option3.matchAll('<.*?>')];
            this.answerlist[i]["option3Images"] = [];
            for (var j = 0; j < listOfMatches.length; j++) {
              var startIndex = j == 0 ? 0 : listOfMatches[j - 1]["index"] + listOfMatches[j - 1][0].length;
              this.answerlist[i]["option3Images"].push(tempString.substring(startIndex, listOfMatches[j]["index"]))
              this.answerlist[i]["option3Images"].push("https://s3.ap-south-1.amazonaws.com/buddi.bucket/budditests/" + listOfMatches[j][0].replace(regexSpace, "").replace("<i=", "").replace(">", ""));
              if (j == listOfMatches.length - 1) {
                this.answerlist[i]["option3Images"].push(tempString.substring(listOfMatches[j]["index"] + 1 + listOfMatches[j][0].length, tempString.length))
              }
            }
          }
          if (this.answerlist[i].option4.replace(regexSpace, "").includes("<i=") && this.answerlist[i].option4.includes(">")) {
            var tempString = this.answerlist[i].option4;
            var listOfMatches = [...this.answerlist[i].option4.matchAll('<.*?>')];
            this.answerlist[i]["option4Images"] = [];
            for (var j = 0; j < listOfMatches.length; j++) {
              var startIndex = j == 0 ? 0 : listOfMatches[j - 1]["index"] + listOfMatches[j - 1][0].length;
              this.answerlist[i]["option4Images"].push(tempString.substring(startIndex, listOfMatches[j]["index"]))
              this.answerlist[i]["option4Images"].push("https://s3.ap-south-1.amazonaws.com/buddi.bucket/budditests/" + listOfMatches[j][0].replace(regexSpace, "").replace("<i=", "").replace(">", ""));
              if (j == listOfMatches.length - 1) {
                this.answerlist[i]["option4Images"].push(tempString.substring(listOfMatches[j]["index"] + 1 + listOfMatches[j][0].length, tempString.length))
              }
            }
          }
      }
    
        if (this.answerlist[i].solution !== undefined) {
          if (this.answerlist[i].solution.replace(regexSpace, "").includes("<i=") && this.answerlist[i].solution.includes(">")) {
            var tempString = this.answerlist[i].solution;
            var listOfMatches = [...this.answerlist[i].solution.matchAll('<.*?>')];
            this.answerlist[i]["solutionImages"] = [];
            for (var j = 0; j < listOfMatches.length; j++) {
              var startIndex = j == 0 ? 0 : listOfMatches[j - 1]["index"] + listOfMatches[j - 1][0].length;
              this.answerlist[i]["solutionImages"].push(tempString.substring(startIndex, listOfMatches[j]["index"]))
              this.answerlist[i]["solutionImages"].push("https://s3.ap-south-1.amazonaws.com/buddi.bucket/budditests/" + listOfMatches[j][0].replace(regexSpace, "").replace("<i=", "").replace(">", ""));
              if (j == listOfMatches.length - 1) {
                this.answerlist[i]["solutionImages"].push(tempString.substring(listOfMatches[j]["index"] + 1 + listOfMatches[j][0].length, tempString.length))
              }
            }
          }
        }

      }

      
      this.testid = result.data._id;
      this.oninitdata();
    }, (err) => {
      console.log(err);
    });
  }

  questionnumberbutton(count) {
    const countquestion = count - 1;
    this.questioncount = countquestion;
    this.isoption1correct = false;
    this.isoption2correct = false;
    this.isoption3correct = false;
    this.isoption4correct = false;
    this.isoption1incorrect = false;
    this.isoption2incorrect = false;
    this.isoption3incorrect = false;
    this.isoption4incorrect = false;
    this.selectedoptionform.controls['option1'].reset();
    this.selectedoptionform.controls['option2'].reset();
    this.selectedoptionform.controls['option3'].reset();
    this.selectedoptionform.controls['option4'].reset();
    var element = document.getElementById('option1id');
    var element2 = document.getElementById('option2id');
    var element3 = document.getElementById('option3id');
    var element4 = document.getElementById('option4id');
    if (element != null) {
      document.getElementById("option1id").style.boxShadow = "0px 0px 5px 0px";
      document.getElementById("option2id").style.boxShadow = "0px 0px 5px 0px";
      document.getElementById("option3id").style.boxShadow = "0px 0px 5px 0px";
      document.getElementById("option4id").style.boxShadow = "0px 0px 5px 0px";
    }
    this.totalmarksforquestion = this.answerlist[countquestion].correctAnswerMarks;
    this.totalmarksobtainedforquestion = 0;
    if (this.answerlist[countquestion].option1 !== undefined) {
      if (this.answerlist[countquestion].studentAnswer != null) {
        if (element != null && element2 != null && element3 != null && element4 != null) {
          for (var i = 0; i < this.answerlist[countquestion].studentAnswer.length; i++) {
            if (this.answerlist[countquestion].studentAnswer[i] == "1") {
              if (this.answerlist[countquestion].correctAnswer.includes(1)) {
                document.getElementById("option1id").style.boxShadow = "0px 0px 5px 3px green";
                this.isoption1correct = true;
                this.totalmarksobtainedforquestion = this.totalmarksobtainedforquestion + this.answerlist[countquestion].correctAnswerMarks;
              }
              else {
                document.getElementById("option1id").style.boxShadow = "0px 0px 5px 0px red";
                this.isoption1incorrect = true;
                this.totalmarksobtainedforquestion = this.answerlist[countquestion].incorrectAnswerMarks + this.totalmarksobtainedforquestion;
              }
              this.isoption1 = true;
            }
            else if (this.answerlist[countquestion].studentAnswer[i] == "2") {
              if (this.answerlist[countquestion].correctAnswer.includes(2)) {
                document.getElementById("option2id").style.boxShadow = "0px 0px 5px 3px green";
                this.totalmarksobtainedforquestion = this.totalmarksobtainedforquestion + this.answerlist[countquestion].correctAnswerMarks;
                this.isoption2correct = true;
              }
              else {
                document.getElementById("option2id").style.boxShadow = "0px 0px 5px 0px red";
                this.totalmarksobtainedforquestion = this.answerlist[countquestion].incorrectAnswerMarks + this.totalmarksobtainedforquestion;
                this.isoption2incorrect = true;
              }
              this.isoption2 = true;
            }
            else if (this.answerlist[countquestion].studentAnswer[i] == "3") {
              if (this.answerlist[countquestion].correctAnswer.includes(3)) {
                document.getElementById("option3id").style.boxShadow = "0px 0px 5px 3px green";
                this.totalmarksobtainedforquestion = this.totalmarksobtainedforquestion + this.answerlist[countquestion].correctAnswerMarks;
                this.isoption3correct = true;
              }
              else {
                document.getElementById("option3id").style.boxShadow = "0px 0px 5px 0px red";
                this.totalmarksobtainedforquestion = this.answerlist[countquestion].incorrectAnswerMarks + this.totalmarksobtainedforquestion;
                this.isoption3incorrect = true;
              }
              this.isoption3 = true;
            }
            else if (this.answerlist[countquestion].studentAnswer[i] == "4") {
              if (this.answerlist[countquestion].correctAnswer.includes(4)) {
                document.getElementById("option4id").style.boxShadow = "0px 0px 5px 3px green";
                this.totalmarksobtainedforquestion = this.totalmarksobtainedforquestion + this.answerlist[countquestion].correctAnswerMarks;
                this.isoption4correct = true;
              }
              else {
                document.getElementById("option4id").style.boxShadow = "0px 0px 5px 0px red";
                this.totalmarksobtainedforquestion = this.answerlist[countquestion].incorrectAnswerMarks + this.totalmarksobtainedforquestion;
                this.isoption4incorrect = true;
              }
              this.isoption4 = true;
            }
          }
        }
        else {
          for (var i = 0; i < this.answerlist[countquestion].studentAnswer.length; i++) {
            if (this.answerlist[countquestion].studentAnswer[i] == "1") {
              if (this.answerlist[countquestion].correctAnswer.includes(1)) {
                this.isoption1correct = true;
                this.totalmarksobtainedforquestion = this.totalmarksobtainedforquestion + this.answerlist[countquestion].correctAnswerMarks;
              }
              else {
                this.isoption1incorrect = true;
                this.totalmarksobtainedforquestion = this.answerlist[countquestion].incorrectAnswerMarks + this.totalmarksobtainedforquestion;
              }
              this.isoption1 = true;
            }
            else if (this.answerlist[countquestion].studentAnswer[i] == "2") {
              if (this.answerlist[countquestion].correctAnswer.includes(2)) {
                this.totalmarksobtainedforquestion = this.totalmarksobtainedforquestion + this.answerlist[countquestion].correctAnswerMarks;
                this.isoption2correct = true;
              }
              else {
                this.totalmarksobtainedforquestion = this.answerlist[countquestion].incorrectAnswerMarks + this.totalmarksobtainedforquestion;
                this.isoption2incorrect = true;
              }
              this.isoption2 = true;
            }
            else if (this.answerlist[countquestion].studentAnswer[i] == "3") {
              if (this.answerlist[countquestion].correctAnswer.includes(3)) {
                this.totalmarksobtainedforquestion = this.totalmarksobtainedforquestion + this.answerlist[countquestion].correctAnswerMarks;
                this.isoption3correct = true;
              }
              else {
                this.totalmarksobtainedforquestion = this.answerlist[countquestion].incorrectAnswerMarks + this.totalmarksobtainedforquestion;
                this.isoption3incorrect = true;
              }
              this.isoption3 = true;
            }
            else if (this.answerlist[countquestion].studentAnswer[i] == "4") {
              if (this.answerlist[countquestion].correctAnswer.includes(4)) {
                this.totalmarksobtainedforquestion = this.totalmarksobtainedforquestion + this.answerlist[countquestion].correctAnswerMarks;
                this.isoption4correct = true;
              }
              else {
                this.totalmarksobtainedforquestion = this.answerlist[countquestion].incorrectAnswerMarks + this.totalmarksobtainedforquestion;
                this.isoption4incorrect = true;
              }
              this.isoption4 = true;
            }
          }
        }

      }
      else {
        this.totalmarksforquestion = this.answerlist[countquestion].correctAnswerMarks;
        this.totalmarksobtainedforquestion = 0;
        this.selectedoptionform.controls['option1'].reset();
        this.selectedoptionform.controls['option2'].reset();
        this.selectedoptionform.controls['option3'].reset();
        this.selectedoptionform.controls['option4'].reset();
      }
    }
    else {
      this.totalmarksforquestion = this.answerlist[countquestion].correctAnswerMarks;
      this.totalmarksobtainedforquestion = 0;
      if (this.answerlist[countquestion].studentAnswer != null) {
        if (this.answerlist[countquestion].studentAnswer[0] == "1") {
          this.isoption1 = true;
        }
        else if (this.answerlist[countquestion].studentAnswer[0] == "2") {
          this.isoption2 = true;
        }
        else if (this.answerlist[countquestion].studentAnswer[0] == "3") {
          this.isoption3 = true;
        }
        else if (this.answerlist[countquestion].studentAnswer[0] == "4") {
          this.isoption4 = true;
        }
      }
    }
    // if(this.answerlist[countquestion].questionType == 3) {
    //   if(this.answerlist[countquestion].studentAnswer.includes("https://s3.ap-south-1.amazonaws.com")) {
    //     this.isquestionimage = true;
    //     this.selectedoptionform.patchValue({ marksObtained:  this.answerlist[count + 1].marksObtained})
    //     this.selectedoptionform.patchValue({ Remarks:  this.answerlist[count + 1].remarks})
    //   }
    //   else {
    //     this.isquestiontext = true;      
    //     this.selectedoptionform.patchValue({ marksObtained:  this.answerlist[count + 1].marksObtained})
    //     this.selectedoptionform.patchValue({ Remarks:  this.answerlist[count + 1].remarks})
    //   }
    // }

    if (this.answerlist[countquestion].questionType == 3) {
      this.totalmarksforquestion = this.answerlist[countquestion].correctAnswerMarks;
      this.totalmarksobtainedforquestion = this.answerlist[countquestion].marksObtained;
      if (this.answerlist[countquestion].studentAnswer !== undefined) {
        if (this.answerlist[countquestion].studentAnswer.toLowerCase().includes("https://s3.ap-south-1.amazonaws.com")) {
          this.isquestionimage = true;
          this.isquestiontextnull = false;
          this.isquestiontext = false;
          this.questioncountimage = this.answerlist[countquestion].studentAnswer;
          this.selectedoptionform.patchValue({ marksObtained: this.answerlist[countquestion].marksObtained })
          this.selectedoptionform.patchValue({ Remarks: this.answerlist[countquestion].remarks })
        }
        else {
          this.isquestiontext = true;
          this.isquestionimage = false;
          this.isquestiontextnull = false;
          this.selectedoptionform.patchValue({ marksObtained: this.answerlist[countquestion].marksObtained })
          this.selectedoptionform.patchValue({ Remarks: this.answerlist[countquestion].remarks })
        }
      }
      else {
        this.isquestiontextnull = true;
        this.isquestiontext = false;
        this.isquestionimage = false;
        this.selectedoptionform.patchValue({ marksObtained: this.answerlist[countquestion].marksObtained })
        this.selectedoptionform.patchValue({ Remarks: this.answerlist[countquestion].remarks })
      }

    }
    if (this.answerlist[this.questioncount].images == undefined) {
      document.getElementById("mathdiv").innerHTML = this.answerlist[this.questioncount].questionText;
      MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
    }
    else {
      for (var i = 0; i < this.answerlist[this.questioncount].images.length; i++) {
        if (!this.answerlist[this.questioncount].images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
          console.log(this.answerlist[this.questioncount].images[i])
          document.getElementById("mathdiv").innerHTML = this.answerlist[this.questioncount].images[i];
          MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
        }

      }
    }

    if (this.answerlist[this.questioncount].option1 !== undefined || this.answerlist[this.questioncount].option1 != "") {
      if (this.answerlist[this.questioncount].option1Images == undefined) {
        console.log(document.getElementById("mathone"), document.getElementById("mathone").innerHTML)

        document.getElementById("mathone").innerHTML = this.answerlist[countquestion].option1;
        MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
      }
      else {
        for (var i = 0; i < this.answerlist[this.questioncount].option1Images.length; i++) {
          if (!this.answerlist[this.questioncount].option1Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
            console.log(this.answerlist[this.questioncount].option1Images[i])
            document.getElementById("mathone").innerHTML = this.answerlist[this.questioncount].option1Images[i];
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
          }

        }
      }
    }
    if (this.answerlist[this.questioncount].option2 !== undefined || this.answerlist[this.questioncount].option2 != "") {
      if (this.answerlist[this.questioncount].option2Images == undefined) {
        document.getElementById("mathtwo").innerHTML = this.answerlist[countquestion].option2;
        MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
      }
      else {
        for (var i = 0; i < this.answerlist[this.questioncount].option2Images.length; i++) {
          if (!this.answerlist[this.questioncount].option2Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
            console.log(this.answerlist[this.questioncount].option2Images[i])
            document.getElementById("mathtwo").innerHTML = this.answerlist[this.questioncount].option2Images[i];
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
          }

        }
      }
    }
    if (this.answerlist[this.questioncount].option3 !== undefined || this.answerlist[this.questioncount].option3 != "") {
      if (this.answerlist[this.questioncount].option3Images == undefined) {
        document.getElementById("maththree").innerHTML = this.answerlist[countquestion].option3;
        MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
      }
      else {
        for (var i = 0; i < this.answerlist[this.questioncount].option3Images.length; i++) {
          if (!this.answerlist[this.questioncount].option3Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
            console.log(this.answerlist[this.questioncount].option3Images[i])
            document.getElementById("maththree").innerHTML = this.answerlist[this.questioncount].option3Images[i];
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
          }

        }
      }
    }
    if (this.answerlist[this.questioncount].option4 !== undefined || this.answerlist[this.questioncount].option4 != "") {
      if (this.answerlist[this.questioncount].option4Images == undefined) {
        document.getElementById("mathfour").innerHTML = this.answerlist[countquestion].option4;
        MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
      }
      else {
        for (var i = 0; i < this.answerlist[this.questioncount].option4mages.length; i++) {
          if (!this.answerlist[this.questioncount].option4Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
            console.log(this.answerlist[this.questioncount].option4Images[i])
            document.getElementById("mathfour").innerHTML = this.answerlist[this.questioncount].option4Images[i];
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
          }

        }
      }
    }
    if (this.answerlist[this.questioncount].solution !== undefined || this.answerlist[this.questioncount].solution != "") {
      if (this.answerlist[this.questioncount].solutionImages == undefined) {
        document.getElementById("mathsolution").innerHTML = this.answerlist[countquestion].solution;
        MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathsolution")]);
      }
      else {
        for (var i = 0; i < this.answerlist[this.questioncount].solutionmages.length; i++) {
          if (!this.answerlist[this.questioncount].solutionImages[i].includes("https://s3.ap-south-1.amazonaws.com")) {
            console.log(this.answerlist[this.questioncount].solutionImages[i])
            document.getElementById("mathsolution").innerHTML = this.answerlist[this.questioncount].solutionImages[i];
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathsolution")]);
          }

        }
      }
    }

    let assigndata: any = {};



    //  this.answerlist = result.data.answerList;
  }

  onSubmit(FormGroup) {
    var token = localStorage.getItem('webtoken');
    const selectedanswer = [];
    const data = this.selectedoptionform.value;
    const count = this.questioncount;
    var formData = {};
    const marks = data.marksObtained;
    const remarksstring = data.Remarks;
    this.isoption1correct = false;
    this.isoption2correct = false;
    this.isoption3correct = false;
    this.isoption4correct = false;
    this.isoption1incorrect = false;
    this.isoption2incorrect = false;
    this.isoption3incorrect = false;
    this.isoption4incorrect = false;
    this.selectedoptionform.controls['option1'].reset();
    this.selectedoptionform.controls['option2'].reset();
    this.selectedoptionform.controls['option3'].reset();
    this.selectedoptionform.controls['option4'].reset();
    var element = document.getElementById('option1id');
    var element2 = document.getElementById('option2id');
    var element3 = document.getElementById('option3id');
    var element4 = document.getElementById('option4id');
    if (element != null) {
      document.getElementById("option1id").style.boxShadow = "0px 0px 5px 0px";
      document.getElementById("option2id").style.boxShadow = "0px 0px 5px 0px";
      document.getElementById("option3id").style.boxShadow = "0px 0px 5px 0px";
      document.getElementById("option4id").style.boxShadow = "0px 0px 5px 0px";
    }

    if (this.totalquestioncount == this.questioncount + 1) {
      this.oninitdata();
    }
    else {
      this.totalmarksforquestion = this.answerlist[count + 1].correctAnswerMarks;
      this.totalmarksobtainedforquestion = 0;
      if (this.answerlist[count + 1].option1 !== undefined) {
        if (this.answerlist[count + 1].studentAnswer != null) {
          if (element != null && element2 != null && element3 != null && element4 != null) {
            for (var i = 0; i < this.answerlist[count + 1].studentAnswer.length; i++) {
              if (this.answerlist[count + 1].studentAnswer[i] == "1") {
                if (this.answerlist[count + 1].correctAnswer.includes(1)) {
                  document.getElementById("option1id").style.boxShadow = "0px 0px 5px 3px green";
                  this.isoption1correct = true;
                  this.totalmarksobtainedforquestion = this.totalmarksobtainedforquestion + this.answerlist[count + 1].correctAnswerMarks;
                }
                else {
                  document.getElementById("option1id").style.boxShadow = "0px 0px 5px 0px red";
                  this.isoption1incorrect = true;
                  this.totalmarksobtainedforquestion = this.answerlist[count + 1].incorrectAnswerMarks + this.totalmarksobtainedforquestion;
                }
                this.isoption1 = true;
              }
              else if (this.answerlist[count + 1].studentAnswer[i] == "2") {
                if (this.answerlist[count + 1].correctAnswer.includes(2)) {
                  document.getElementById("option2id").style.boxShadow = "0px 0px 5px 3px green";
                  this.totalmarksobtainedforquestion = this.totalmarksobtainedforquestion + this.answerlist[count + 1].correctAnswerMarks;
                  this.isoption2correct = true;
                }
                else {
                  document.getElementById("option2id").style.boxShadow = "0px 0px 5px 0px red";
                  this.totalmarksobtainedforquestion = this.answerlist[count + 1].incorrectAnswerMarks + this.totalmarksobtainedforquestion;
                  this.isoption2incorrect = true;
                }
                this.isoption2 = true;
              }
              else if (this.answerlist[count + 1].studentAnswer[i] == "3") {
                if (this.answerlist[count + 1].correctAnswer.includes(3)) {
                  document.getElementById("option3id").style.boxShadow = "0px 0px 5px 3px green";
                  this.totalmarksobtainedforquestion = this.totalmarksobtainedforquestion + this.answerlist[count + 1].correctAnswerMarks;
                  this.isoption3correct = true;
                }
                else {
                  document.getElementById("option3id").style.boxShadow = "0px 0px 5px 0px red";
                  this.totalmarksobtainedforquestion = this.answerlist[count + 1].incorrectAnswerMarks + this.totalmarksobtainedforquestion;
                  this.isoption3incorrect = true;
                }
                this.isoption3 = true;
              }
              else if (this.answerlist[count + 1].studentAnswer[i] == "4") {
                if (this.answerlist[count + 1].correctAnswer.includes(4)) {
                  document.getElementById("option4id").style.boxShadow = "0px 0px 5px 3px green";
                  this.totalmarksobtainedforquestion = this.totalmarksobtainedforquestion + this.answerlist[count + 1].correctAnswerMarks;
                  this.isoption4correct = true;
                }
                else {
                  document.getElementById("option4id").style.boxShadow = "0px 0px 5px 0px red";
                  this.totalmarksobtainedforquestion = this.answerlist[count + 1].incorrectAnswerMarks + this.totalmarksobtainedforquestion;
                  this.isoption4incorrect = true;
                }
                this.isoption4 = true;
              }
            }
          }
          else {
            for (var i = 0; i < this.answerlist[count + 1].studentAnswer.length; i++) {
              if (this.answerlist[count + 1].studentAnswer[i] == "1") {
                if (this.answerlist[count + 1].correctAnswer.includes(1)) {
                  this.isoption1correct = true;
                  this.totalmarksobtainedforquestion = this.totalmarksobtainedforquestion + this.answerlist[count + 1].correctAnswerMarks;
                }
                else {
                  this.isoption1incorrect = true;
                  this.totalmarksobtainedforquestion = this.answerlist[count + 1].incorrectAnswerMarks + this.totalmarksobtainedforquestion;
                }
                this.isoption1 = true;
              }
              else if (this.answerlist[count + 1].studentAnswer[i] == "2") {
                if (this.answerlist[count + 1].correctAnswer.includes(2)) {
                  this.totalmarksobtainedforquestion = this.totalmarksobtainedforquestion + this.answerlist[count + 1].correctAnswerMarks;
                  this.isoption2correct = true;
                }
                else {
                  this.totalmarksobtainedforquestion = this.answerlist[count + 1].incorrectAnswerMarks + this.totalmarksobtainedforquestion;
                  this.isoption2incorrect = true;
                }
                this.isoption2 = true;
              }
              else if (this.answerlist[count + 1].studentAnswer[i] == "3") {
                if (this.answerlist[count + 1].correctAnswer.includes(3)) {
                  this.totalmarksobtainedforquestion = this.totalmarksobtainedforquestion + this.answerlist[count + 1].correctAnswerMarks;
                  this.isoption3correct = true;
                }
                else {
                  this.totalmarksobtainedforquestion = this.answerlist[count + 1].incorrectAnswerMarks + this.totalmarksobtainedforquestion;
                  this.isoption3incorrect = true;
                }
                this.isoption3 = true;
              }
              else if (this.answerlist[count + 1].studentAnswer[i] == "4") {
                if (this.answerlist[count + 1].correctAnswer.includes(4)) {
                  this.totalmarksobtainedforquestion = this.totalmarksobtainedforquestion + this.answerlist[count + 1].correctAnswerMarks;
                  this.isoption4correct = true;
                }
                else {
                  this.totalmarksobtainedforquestion = this.answerlist[count + 1].incorrectAnswerMarks + this.totalmarksobtainedforquestion;
                  this.isoption4incorrect = true;
                }
                this.isoption4 = true;
              }
            }
          }

        }
        else {
          this.totalmarksforquestion = this.answerlist[count + 1].correctAnswerMarks;
          this.totalmarksobtainedforquestion = 0;
          this.selectedoptionform.controls['option1'].reset();
          this.selectedoptionform.controls['option2'].reset();
          this.selectedoptionform.controls['option3'].reset();
          this.selectedoptionform.controls['option4'].reset();
        }
      }
      else {
        this.totalmarksforquestion = this.answerlist[count + 1].correctAnswerMarks;
        this.totalmarksobtainedforquestion = 0;
        if (this.answerlist[count + 1].studentAnswer != null) {
          if (this.answerlist[count + 1].studentAnswer[0] == "1") {
            this.isoption1 = true;
          }
          else if (this.answerlist[count + 1].studentAnswer[0] == "2") {
            this.isoption2 = true;
          }
          else if (this.answerlist[count + 1].studentAnswer[0] == "3") {
            this.isoption3 = true;
          }
          else if (this.answerlist[count + 1].studentAnswer[0] == "4") {
            this.isoption4 = true;
          }
        }
      }

      if (this.answerlist[count + 1].questionType == 3) {
        this.totalmarksforquestion = this.answerlist[count + 1].correctAnswerMarks;
        this.totalmarksobtainedforquestion = this.answerlist[count + 1].marksObtained;
        if (this.answerlist[count + 1].studentAnswer !== undefined) {
          if (this.answerlist[count + 1].studentAnswer.includes("https://s3.ap-south-1.amazonaws.com")) {
            this.isquestionimage = true;
            this.isquestiontext = false;
            this.isquestiontextnull = false;
            this.questioncountimage = this.answerlist[count + 1].studentAnswer;
            this.selectedoptionform.patchValue({ marksObtained: this.answerlist[count + 1].marksObtained })
            this.selectedoptionform.patchValue({ Remarks: this.answerlist[count + 1].remarks })
          }
          else if (this.answerlist[count + 1].studentAnswer.length > 2) {
            this.isquestiontext = true;
            this.isquestiontextnull = false;
            this.isquestionimage = false;
            this.selectedoptionform.patchValue({ marksObtained: this.answerlist[count + 1].marksObtained })
            this.selectedoptionform.patchValue({ Remarks: this.answerlist[count + 1].remarks })
          }
        }
        else {
          this.isquestiontextnull = true;
          this.isquestionimage = false;
          this.isquestiontext = false;
          this.selectedoptionform.patchValue({ marksObtained: this.answerlist[count + 1].marksObtained })
          this.selectedoptionform.patchValue({ Remarks: this.answerlist[count + 1].remarks })
        }

      }
      this.questioncount = count + 1;
      // if (this.answerlist[count + 1].option1 !== undefined || this.answerlist[count + 1].option1 != "") {
      //   document.getElementById("mathone").innerHTML = this.answerlist[count + 1].option1;
      //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
      // }
      // if (this.answerlist[count + 1].option2 !== undefined || this.answerlist[count + 1].option2 != "") {
      //   document.getElementById("mathtwo").innerHTML = this.answerlist[count + 1].option2;
      //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
      // }
      // if (this.answerlist[count + 1].option3 !== undefined || this.answerlist[count + 1].option3 != "") {
      //   document.getElementById("maththree").innerHTML = this.answerlist[count + 1].option3;
      //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
      // }
      // if (this.answerlist[count + 1].option4 !== undefined || this.answerlist[count + 1].option4 != "") {
      //   document.getElementById("mathfour").innerHTML = this.answerlist[count + 1].option4;
      //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
      // }

      // document.getElementById("mathsolution").innerHTML = this.answerlist[count + 1].solution;
      // MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathsolution")]);

      if (this.answerlist[this.questioncount].images == undefined) {
        document.getElementById("mathdiv").innerHTML = this.answerlist[this.questioncount].questionText;
        MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
      }
      else {
        for (var i = 0; i < this.answerlist[this.questioncount].images.length; i++) {
          if (!this.answerlist[this.questioncount].images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
            console.log(this.answerlist[this.questioncount].images[i])
            document.getElementById("mathdiv").innerHTML = this.answerlist[this.questioncount].images[i];
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
          }

        }
      }

      if (this.answerlist[this.questioncount].option1 !== undefined || this.answerlist[this.questioncount].option1 != "") {
        if (this.answerlist[this.questioncount].option1Images == undefined) {
          document.getElementById("mathone").innerHTML = this.answerlist[this.questioncount].option1;
          MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
        }
        else {
          for (var i = 0; i < this.answerlist[this.questioncount].option1Images.length; i++) {
            if (!this.answerlist[this.questioncount].option1Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
              console.log(this.answerlist[this.questioncount].option1Images[i])
              document.getElementById("mathone").innerHTML = this.answerlist[this.questioncount].option1Images[i];
              MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
            }

          }
        }
      }
      if (this.answerlist[this.questioncount].option2 !== undefined || this.answerlist[this.questioncount].option2 != "") {
        if (this.answerlist[this.questioncount].option2Images == undefined) {
          document.getElementById("mathtwo").innerHTML = this.answerlist[this.questioncount].option2;
          MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
        }
        else {
          for (var i = 0; i < this.answerlist[this.questioncount].option2Images.length; i++) {
            if (!this.answerlist[this.questioncount].option2Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
              console.log(this.answerlist[this.questioncount].option2Images[i])
              document.getElementById("mathtwo").innerHTML = this.answerlist[this.questioncount].option2Images[i];
              MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
            }

          }
        }
      }
      if (this.answerlist[this.questioncount].option3 !== undefined || this.answerlist[this.questioncount].option3 != "") {
        if (this.answerlist[this.questioncount].option3Images == undefined) {
          document.getElementById("maththree").innerHTML = this.answerlist[this.questioncount].option3;
          MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
        }
        else {
          for (var i = 0; i < this.answerlist[this.questioncount].option3Images.length; i++) {
            if (!this.answerlist[this.questioncount].option3Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
              console.log(this.answerlist[this.questioncount].option3Images[i])
              document.getElementById("maththree").innerHTML = this.answerlist[this.questioncount].option3Images[i];
              MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
            }

          }
        }
      }
      if (this.answerlist[this.questioncount].option4 !== undefined || this.answerlist[this.questioncount].option4 != "") {
        if (this.answerlist[this.questioncount].option4Images == undefined) {
          document.getElementById("mathfour").innerHTML = this.answerlist[this.questioncount].option4;
          MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
        }
        else {
          for (var i = 0; i < this.answerlist[this.questioncount].option4mages.length; i++) {
            if (!this.answerlist[this.questioncount].option4Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
              console.log(this.answerlist[this.questioncount].option4Images[i])
              document.getElementById("mathfour").innerHTML = this.answerlist[this.questioncount].option4Images[i];
              MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
            }

          }
        }
      }
      if (this.answerlist[this.questioncount].solution !== undefined || this.answerlist[this.questioncount].solution != "") {
        if (this.answerlist[this.questioncount].solutionImages == undefined) {
          document.getElementById("mathsolution").innerHTML = this.answerlist[this.questioncount].solution;
          MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathsolution")]);
        }
        else {
          for (var i = 0; i < this.answerlist[this.questioncount].solutionmages.length; i++) {
            if (!this.answerlist[this.questioncount].solutionImages[i].includes("https://s3.ap-south-1.amazonaws.com")) {
              console.log(this.answerlist[this.questioncount].solutionImages[i])
              document.getElementById("mathsolution").innerHTML = this.answerlist[this.questioncount].solutionImages[i];
              MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathsolution")]);
            }

          }
        }
      }
    }

    if (this.questioncount == this.subjectname1arraylength) {
      if (this.subjectname2 == "") {

      }
      else {
        this.subjectname2arrowdown = false;
        this.subjectname2arrowup = true;
        this.subjectname2arrayquestion = true;

        this.subjectname1arrowdown = true;
        this.subjectname1arrowup = false;
        this.subjectname1arrayquestion = false;
      }

    }

    if (this.questioncount == this.subjectname2arraylength + this.subjectname1arraylength) {
      if (this.subjectname3 == "") {
        this.subjectname1arrowdown = false;
        this.subjectname1arrowup = true;
        this.subjectname1arrayquestion = true;

        this.subjectname2arrowdown = true;
       }
      else {
        this.subjectname3arrowdown = false;
        this.subjectname3arrowup = true;
        this.subjectname3arrayquestion = true;

        this.subjectname2arrowdown = true;
        this.subjectname2arrowup = false;
        this.subjectname2arrayquestion = false;
      }

    }

    if (this.questioncount == this.subjectname3arraylength + this.subjectname1arraylength + this.subjectname2arraylength) {
      
      if (this.subjectname4 == "") {
        this.subjectname1arrowdown = false;
        this.subjectname1arrowup = true;
        this.subjectname1arrayquestion = true;

        this.subjectname3arrowdown = true;
        this.subjectname3arrowup = false;
        this.subjectname3arrayquestion = false;
      }
      else {
        this.subjectname4arrowdown = false;
        this.subjectname4arrowup = true;
        this.subjectname4arrayquestion = true;

        this.subjectname3arrowdown = true;
        this.subjectname3arrowup = false;
        this.subjectname3arrayquestion = false;
      }

      if (this.questioncount == this.subjectname4arraylength + this.subjectname3arraylength + this.subjectname1arraylength + this.subjectname2arraylength) {
        this.subjectname1arrowdown = false;
        this.subjectname1arrowup = true;
        this.subjectname1arrayquestion = true;
        
        this.subjectname2arrowdown = true;
        this.subjectname2arrowup = false;
        this.subjectname2arrayquestion = false;
      } 
    }
  }

  reviewlater() {
    this.router.navigate(['student/home']);
  }

  previous() {

    if (this.questioncount > 0) {
      const count = this.questioncount - 1;
      this.isoption1correct = false;
      this.isoption2correct = false;
      this.isoption3correct = false;
      this.isoption4correct = false;
      this.isoption1incorrect = false;
      this.isoption2incorrect = false;
      this.isoption3incorrect = false;
      this.isoption4incorrect = false;
      this.selectedoptionform.controls['option1'].reset();
      this.selectedoptionform.controls['option2'].reset();
      this.selectedoptionform.controls['option3'].reset();
      this.selectedoptionform.controls['option4'].reset();
      var element = document.getElementById('option1id');
      var element2 = document.getElementById('option2id');
      var element3 = document.getElementById('option3id');
      var element4 = document.getElementById('option4id');
      if (element != null) {
        document.getElementById("option1id").style.boxShadow = "0px 0px 5px 0px";
        document.getElementById("option2id").style.boxShadow = "0px 0px 5px 0px";
        document.getElementById("option3id").style.boxShadow = "0px 0px 5px 0px";
        document.getElementById("option4id").style.boxShadow = "0px 0px 5px 0px";
      } this.totalmarksforquestion = this.answerlist[count].correctAnswerMarks;
      this.totalmarksobtainedforquestion = 0;
      if (this.answerlist[count].option1 !== undefined) {
        if (this.answerlist[count].studentAnswer != null) {
          if (element != null && element2 != null && element3 != null && element4 != null) {
            for (var i = 0; i < this.answerlist[count].studentAnswer.length; i++) {
              if (this.answerlist[count].studentAnswer[i] == "1") {
                if (this.answerlist[count].correctAnswer.includes(1)) {
                  setTimeout(() => {
                    document.getElementById("option1id").style.boxShadow = "0px 0px 5px 3px green";
                    this.isoption1correct = true;
                    this.totalmarksobtainedforquestion = this.totalmarksobtainedforquestion + this.answerlist[count].correctAnswerMarks;
                    this.isoption1 = true;
                  },
                    500);

                }
                else {
                  setTimeout(() => {
                    document.getElementById("option1id").style.boxShadow = "0px 0px 5px 0px red";
                    this.isoption1incorrect = true;
                    this.totalmarksobtainedforquestion = this.answerlist[count].incorrectAnswerMarks + this.totalmarksobtainedforquestion;
                    this.isoption1 = true;
                  },
                    500);

                }
              }
              else if (this.answerlist[count].studentAnswer[i] == "2") {
                if (this.answerlist[count].correctAnswer.includes(2)) {
                  setTimeout(() => {
                    document.getElementById("option2id").style.boxShadow = "0px 0px 5px 3px green";
                    this.totalmarksobtainedforquestion = this.totalmarksobtainedforquestion + this.answerlist[count].correctAnswerMarks;
                    this.isoption2correct = true;
                    this.isoption2 = true;
                  },
                    500);

                }
                else {
                  setTimeout(() => {
                    document.getElementById("option2id").style.boxShadow = "0px 0px 5px 0px red";
                    this.totalmarksobtainedforquestion = this.answerlist[count].incorrectAnswerMarks + this.totalmarksobtainedforquestion;
                    this.isoption2incorrect = true;
                    this.isoption2 = true;
                  },
                    500);

                }
              }
              else if (this.answerlist[count].studentAnswer[i] == "3") {
                if (this.answerlist[count].correctAnswer.includes(3)) {
                  setTimeout(() => {
                    document.getElementById("option3id").style.boxShadow = "0px 0px 5px 3px green";
                    this.totalmarksobtainedforquestion = this.totalmarksobtainedforquestion + this.answerlist[count].correctAnswerMarks;
                    this.isoption3correct = true;
                    this.isoption3 = true;
                  },
                    500);

                }
                else {
                  setTimeout(() => {
                    document.getElementById("option3id").style.boxShadow = "0px 0px 5px 0px red";
                    this.totalmarksobtainedforquestion = this.answerlist[count].incorrectAnswerMarks + this.totalmarksobtainedforquestion;
                    this.isoption3incorrect = true;
                    this.isoption3 = true;
                  },
                    500);

                }
              }
              else if (this.answerlist[count].studentAnswer[i] == "4") {
                if (this.answerlist[count].correctAnswer.includes(4)) {
                  setTimeout(() => {
                    document.getElementById("option4id").style.boxShadow = "0px 0px 5px 3px green";
                    this.totalmarksobtainedforquestion = this.totalmarksobtainedforquestion + this.answerlist[count].correctAnswerMarks;
                    this.isoption4correct = true;
                    this.isoption4 = true;
                  },
                    500);

                }
                else {
                  setTimeout(() => {
                    document.getElementById("option4id").style.boxShadow = "0px 0px 5px 0px red";
                    this.totalmarksobtainedforquestion = this.answerlist[count].incorrectAnswerMarks + this.totalmarksobtainedforquestion;
                    this.isoption4incorrect = true;
                    this.isoption4 = true;
                  },
                    500);

                }
              }
            }
          }
          else {
            for (var i = 0; i < this.answerlist[count].studentAnswer.length; i++) {
              if (this.answerlist[count].studentAnswer[i] == "1") {
                if (this.answerlist[count].correctAnswer.includes(1)) {
                  this.isoption1correct = true;
                  this.totalmarksobtainedforquestion = this.totalmarksobtainedforquestion + this.answerlist[count].correctAnswerMarks;
                }
                else {
                  this.isoption1incorrect = true;
                  this.totalmarksobtainedforquestion = this.answerlist[count].incorrectAnswerMarks + this.totalmarksobtainedforquestion;
                }
                setTimeout(() => {
                  this.isoption1 = true;
                },
                  500);
              }
              else if (this.answerlist[count].studentAnswer[i] == "2") {
                if (this.answerlist[count].correctAnswer.includes(2)) {
                  this.totalmarksobtainedforquestion = this.totalmarksobtainedforquestion + this.answerlist[count].correctAnswerMarks;
                  this.isoption2correct = true;
                }
                else {
                  this.totalmarksobtainedforquestion = this.answerlist[count].incorrectAnswerMarks + this.totalmarksobtainedforquestion;
                  this.isoption2incorrect = true;
                }
                setTimeout(() => {
                  this.isoption2 = true;
                },
                  500);
              }
              else if (this.answerlist[count].studentAnswer[i] == "3") {
                if (this.answerlist[count].correctAnswer.includes(3)) {
                  this.totalmarksobtainedforquestion = this.totalmarksobtainedforquestion + this.answerlist[count].correctAnswerMarks;
                  this.isoption3correct = true;
                }
                else {
                  this.totalmarksobtainedforquestion = this.answerlist[count].incorrectAnswerMarks + this.totalmarksobtainedforquestion;
                  this.isoption3incorrect = true;
                }
                setTimeout(() => {
                  this.isoption3 = true;
                },
                  500);
              }
              else if (this.answerlist[count].studentAnswer[i] == "4") {
                if (this.answerlist[count].correctAnswer.includes(4)) {
                  this.totalmarksobtainedforquestion = this.totalmarksobtainedforquestion + this.answerlist[count].correctAnswerMarks;
                  this.isoption4correct = true;
                }
                else {
                  this.totalmarksobtainedforquestion = this.answerlist[count].incorrectAnswerMarks + this.totalmarksobtainedforquestion;
                  this.isoption4incorrect = true;
                }
                setTimeout(() => {
                  this.isoption4 = true;
                },
                  500);
              }
            }
          }

        }
        else {
          this.totalmarksforquestion = this.answerlist[count].correctAnswerMarks;
          this.totalmarksobtainedforquestion = 0;
          this.selectedoptionform.controls['option1'].reset();
          this.selectedoptionform.controls['option2'].reset();
          this.selectedoptionform.controls['option3'].reset();
          this.selectedoptionform.controls['option4'].reset();
        }
      }
      else {
        this.totalmarksforquestion = this.answerlist[count].correctAnswerMarks;
        this.totalmarksobtainedforquestion = 0;
        if (this.answerlist[count].studentAnswer != null) {
          if (this.answerlist[count].studentAnswer[0] == "1") {
            setTimeout(() => {
              this.isoption1 = true;
            },
              500);
          }
          else if (this.answerlist[count].studentAnswer[0] == "2") {
            setTimeout(() => {
              this.isoption2 = true;
            },
              500);
          }
          else if (this.answerlist[count].studentAnswer[0] == "3") {
            setTimeout(() => {
              this.isoption3 = true;
            },
              500);
          }
          else if (this.answerlist[count].studentAnswer[0] == "4") {
            setTimeout(() => {
              this.isoption4 = true;
            },
              500);
          }
        }
      }

      if (this.answerlist[count].questionType == 3) {
        this.totalmarksforquestion = this.answerlist[count].correctAnswerMarks;
        this.totalmarksobtainedforquestion = this.answerlist[count].marksObtained;
        if (this.answerlist[count].studentAnswer !== undefined) {
          if (this.answerlist[count].studentAnswer.toLowerCase().includes("https://s3.ap-south-1.amazonaws.com")) {
            this.isquestionimage = true;
            this.isquestiontext = false;
            this.isquestiontextnull = false;
            this.questioncountimage = this.answerlist[count].studentAnswer;
            this.selectedoptionform.patchValue({ marksObtained: this.answerlist[count].marksObtained })
            this.selectedoptionform.patchValue({ Remarks: this.answerlist[count].remarks })
          }
          else {
            this.isquestiontext = true;
            this.isquestiontextnull = false;
            this.isquestionimage = false;
            this.selectedoptionform.patchValue({ marksObtained: this.answerlist[count].marksObtained })
            this.selectedoptionform.patchValue({ Remarks: this.answerlist[count].remarks })
          }
        }
        else {
          this.isquestiontextnull = true;
          this.isquestionimage = false;
          this.isquestiontext = false;
          this.selectedoptionform.patchValue({ marksObtained: this.answerlist[count].marksObtained })
          this.selectedoptionform.patchValue({ Remarks: this.answerlist[count].remarks })
        }

      }

      this.questioncount = count;
      if (this.answerlist[this.questioncount].images == undefined) {
        document.getElementById("mathdiv").innerHTML = this.answerlist[this.questioncount].questionText;
        MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
      }
      else {
        for (var i = 0; i < this.answerlist[this.questioncount].images.length; i++) {
          if (!this.answerlist[this.questioncount].images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
            console.log(this.answerlist[this.questioncount].images[i])
            document.getElementById("mathdiv").innerHTML = this.answerlist[this.questioncount].images[i];
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
          }

        }
      }

      if (this.answerlist[this.questioncount].option1 !== undefined || this.answerlist[this.questioncount].option1 != "") {
        if (this.answerlist[this.questioncount].option1Images == undefined) {
          document.getElementById("mathone").innerHTML = this.answerlist[this.questioncount].option1;
          MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
        }
        else {
          for (var i = 0; i < this.answerlist[this.questioncount].option1Images.length; i++) {
            if (!this.answerlist[this.questioncount].option1Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
              console.log(this.answerlist[this.questioncount].option1Images[i])
              document.getElementById("mathone").innerHTML = this.answerlist[this.questioncount].option1Images[i];
              MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
            }

          }
        }
      }
      if (this.answerlist[this.questioncount].option2 !== undefined || this.answerlist[this.questioncount].option2 != "") {
        if (this.answerlist[this.questioncount].option2Images == undefined) {
          document.getElementById("mathtwo").innerHTML = this.answerlist[this.questioncount].option2;
          MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
        }
        else {
          for (var i = 0; i < this.answerlist[this.questioncount].option2Images.length; i++) {
            if (!this.answerlist[this.questioncount].option2Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
              console.log(this.answerlist[this.questioncount].option2Images[i])
              document.getElementById("mathtwo").innerHTML = this.answerlist[this.questioncount].option2Images[i];
              MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
            }

          }
        }
      }
      if (this.answerlist[this.questioncount].option3 !== undefined || this.answerlist[this.questioncount].option3 != "") {
        if (this.answerlist[this.questioncount].option3Images == undefined) {
          document.getElementById("maththree").innerHTML = this.answerlist[this.questioncount].option3;
          MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
        }
        else {
          for (var i = 0; i < this.answerlist[this.questioncount].option3Images.length; i++) {
            if (!this.answerlist[this.questioncount].option3Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
              console.log(this.answerlist[this.questioncount].option3Images[i])
              document.getElementById("maththree").innerHTML = this.answerlist[this.questioncount].option3Images[i];
              MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
            }

          }
        }
      }
      if (this.answerlist[this.questioncount].option4 !== undefined || this.answerlist[this.questioncount].option4 != "") {
        if (this.answerlist[this.questioncount].option4Images == undefined) {
          document.getElementById("mathfour").innerHTML = this.answerlist[this.questioncount].option4;
          MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
        }
        else {
          for (var i = 0; i < this.answerlist[this.questioncount].option4mages.length; i++) {
            if (!this.answerlist[this.questioncount].option4Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
              console.log(this.answerlist[this.questioncount].option4Images[i])
              document.getElementById("mathfour").innerHTML = this.answerlist[this.questioncount].option4Images[i];
              MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
            }

          }
        }
      }
      if (this.answerlist[this.questioncount].solution !== undefined || this.answerlist[this.questioncount].solution != "") {
        if (this.answerlist[this.questioncount].solutionImages == undefined) {
          document.getElementById("mathsolution").innerHTML = this.answerlist[this.questioncount].solution;
          MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathsolution")]);
        }
        else {
          for (var i = 0; i < this.answerlist[this.questioncount].solutionmages.length; i++) {
            if (!this.answerlist[this.questioncount].solutionImages[i].includes("https://s3.ap-south-1.amazonaws.com")) {
              console.log(this.answerlist[this.questioncount].solutionImages[i])
              document.getElementById("mathsolution").innerHTML = this.answerlist[this.questioncount].solutionImages[i];
              MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathsolution")]);
            }

          }
        }
      }
      if(count + 2 == 1) {
        if(this.subjectname4 == "") {

        }
        else {
          this.subjectname4arrowdown = false;
          this.subjectname4arrowup = true;
          this.subjectname4arrayquestion = true;

          this.subjectname1arrowdown = true;
          this.subjectname1arrowup = false;
          this.subjectname1arrayquestion = false;
        }
      }

      if (count + 2 == this.subjectname1arraylength + 1) {        
          this.subjectname1arrowdown = false;
          this.subjectname1arrowup = true;
          this.subjectname1arrayquestion = true;

          this.subjectname2arrowdown = true;
          this.subjectname2arrowup = false;
          this.subjectname2arrayquestion = false;
      }

      if (count + 2 == this.subjectname2arraylength + this.subjectname1arraylength + 1) {
          this.subjectname2arrowdown = false;
          this.subjectname2arrowup = true;
          this.subjectname2arrayquestion = true;

          this.subjectname3arrowdown = true;
          this.subjectname3arrowup = false;
          this.subjectname3arrayquestion = false;
      }
      if (count + 2 == this.subjectname3arraylength + this.subjectname1arraylength + this.subjectname2arraylength + 1) {
          this.subjectname3arrowdown = false;
          this.subjectname3arrowup = true;
          this.subjectname3arrayquestion = true;

          this.subjectname4arrowdown = true;
          this.subjectname4arrowup = false;
          this.subjectname4arrayquestion = false;
      }
    }
    else {

    }

  }

  oninitdata() {
    const countquestion = 0;
    this.questioncount = countquestion;
    this.isoption1correct = false;
    this.isoption2correct = false;
    this.isoption3correct = false;
    this.isoption4correct = false;
    this.isoption1incorrect = false;
    this.isoption2incorrect = false;
    this.isoption3incorrect = false;
    this.isoption4incorrect = false;
    this.selectedoptionform.controls['option1'].reset();
    this.selectedoptionform.controls['option2'].reset();
    this.selectedoptionform.controls['option3'].reset();
    this.selectedoptionform.controls['option4'].reset();
    var element = document.getElementById('option1id');
    var element2 = document.getElementById('option2id');
    var element3 = document.getElementById('option3id');
    var element4 = document.getElementById('option4id');
    if (element != null) {
      document.getElementById("option1id").style.boxShadow = "0px 0px 5px 0px";
      document.getElementById("option2id").style.boxShadow = "0px 0px 5px 0px";
      document.getElementById("option3id").style.boxShadow = "0px 0px 5px 0px";
      document.getElementById("option4id").style.boxShadow = "0px 0px 5px 0px";
    }
    // console.log(this.answerlist[this.questioncount]);
    // if(this.answerlist[this.questioncount].images == undefined){
    //   console.log("images null")
    //   console.log(document.getElementById("mathdiv"))
    //   document.getElementById("mathdiv").innerHTML = this.answerlist[this.questioncount].questionText;
    //   console.log(document.getElementById("mathdiv"))
    //   MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
    //   console.log("0002")
    // }
    // else {
    //   console.log("images not null")
    //   for(var i = 0; i < this.answerlist[this.questioncount].images.length; i++){
    //     if(!this.answerlist[this.questioncount].images[i].includes("https://s3.ap-south-1.amazonaws.com")){
    //       console.log(this.answerlist[this.questioncount].images[i])
    //       document.getElementById("mathdiv" ).innerHTML = this.answerlist[this.questioncount].images[i];
    //       MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathdiv")]);
    //     }

    //   }

    // }


    this.totalmarksforquestion = this.answerlist[countquestion].correctAnswerMarks;
    this.totalmarksobtainedforquestion = 0;
    if (this.answerlist[countquestion].numberOfOptions > 1) {

      if (this.answerlist[countquestion].studentAnswer != null) {

        if (element != null && element2 != null && element3 != null && element4 != null) {
          for (var i = 0; i < this.answerlist[countquestion].studentAnswer.length; i++) {
            if (this.answerlist[countquestion].studentAnswer[i] == "1") {
              if (this.answerlist[countquestion].correctAnswer.includes(1)) {
                setTimeout(() => {
                  this.isoption1correct = true;
                  document.getElementById("option1id").style.boxShadow = "0px 0px 5px 3px green";
                  this.totalmarksobtainedforquestion = this.totalmarksobtainedforquestion + this.answerlist[countquestion].correctAnswerMarks;
                  this.isoption1 = true;
                },
                  500);

              }
              else {
                setTimeout(() => {
                  document.getElementById("option1id").style.boxShadow = "0px 0px 5px 0px red";
                  this.isoption1incorrect = true;
                  this.totalmarksobtainedforquestion = this.answerlist[countquestion].incorrectAnswerMarks + this.totalmarksobtainedforquestion;
                  this.isoption1 = true;
                },
                  500);

              }

            }
            else if (this.answerlist[countquestion].studentAnswer[i] == "2") {
              if (this.answerlist[countquestion].correctAnswer.includes(2)) {
                setTimeout(() => {
                  document.getElementById("option2id").style.boxShadow = "0px 0px 5px 3px green";
                  this.totalmarksobtainedforquestion = this.totalmarksobtainedforquestion + this.answerlist[countquestion].correctAnswerMarks;
                  this.isoption2correct = true;
                  this.isoption2 = true;
                },
                  500);

              }
              else {
                setTimeout(() => {
                  document.getElementById("option2id").style.boxShadow = "0px 0px 5px 0px red";
                  this.totalmarksobtainedforquestion = this.answerlist[countquestion].incorrectAnswerMarks + this.totalmarksobtainedforquestion;
                  this.isoption2incorrect = true;
                  this.isoption2 = true;
                },
                  500);

              }
            }
            else if (this.answerlist[countquestion].studentAnswer[i] == "3") {
              if (this.answerlist[countquestion].correctAnswer.includes(3)) {
                setTimeout(() => {
                  document.getElementById("option3id").style.boxShadow = "0px 0px 5px 3px green";
                  this.totalmarksobtainedforquestion = this.totalmarksobtainedforquestion + this.answerlist[countquestion].correctAnswerMarks;
                  this.isoption3correct = true;
                  this.isoption3 = true;
                },
                  500);

              }
              else {
                setTimeout(() => {
                  document.getElementById("option3id").style.boxShadow = "0px 0px 5px 0px red";
                  this.totalmarksobtainedforquestion = this.answerlist[countquestion].incorrectAnswerMarks + this.totalmarksobtainedforquestion;
                  this.isoption3incorrect = true;
                  this.isoption3 = true;
                },
                  500);

              }

            }
            else if (this.answerlist[countquestion].studentAnswer[i] == "4") {
              if (this.answerlist[countquestion].correctAnswer.includes(4)) {
                setTimeout(() => {
                  document.getElementById("option4id").style.boxShadow = "0px 0px 5px 3px green";
                  this.totalmarksobtainedforquestion = this.totalmarksobtainedforquestion + this.answerlist[countquestion].correctAnswerMarks;
                  this.isoption4correct = true;
                  this.isoption4 = true;
                },
                  500);

              }
              else {
                setTimeout(() => {
                  document.getElementById("option4id").style.boxShadow = "0px 0px 5px 0px red";
                  this.totalmarksobtainedforquestion = this.answerlist[countquestion].incorrectAnswerMarks + this.totalmarksobtainedforquestion;
                  this.isoption4incorrect = true;
                  this.isoption4 = true;
                },
                  500);

              }
            }
          }
        }
        else {
          for (var i = 0; i < this.answerlist[countquestion].studentAnswer.length; i++) {
            if (this.answerlist[countquestion].studentAnswer[i] == "1") {
              if (this.answerlist[countquestion].correctAnswer.includes(1)) {
                setTimeout(() => {
                  document.getElementById("option1id").style.boxShadow = "0px 0px 5px 3px green";
                  this.isoption1correct = true;
                  this.totalmarksobtainedforquestion = this.totalmarksobtainedforquestion + this.answerlist[countquestion].correctAnswerMarks;
                  this.isoption1 = true;
                },
                  500);

              }
              else {
                setTimeout(() => {
                  document.getElementById("option1id").style.boxShadow = "0px 0px 5px 0px red";
                  this.isoption1incorrect = true;
                  this.totalmarksobtainedforquestion = this.answerlist[countquestion].incorrectAnswerMarks + this.totalmarksobtainedforquestion;
                  this.isoption1 = true;
                },
                  500);

              }
            }
            else if (this.answerlist[countquestion].studentAnswer[i] == "2") {
              if (this.answerlist[countquestion].correctAnswer.includes(2)) {
                setTimeout(() => {
                  document.getElementById("option1id").style.boxShadow = "0px 0px 5px 3px green";
                  this.totalmarksobtainedforquestion = this.totalmarksobtainedforquestion + this.answerlist[countquestion].correctAnswerMarks;
                  this.isoption2correct = true;
                  this.isoption2 = true;
                },
                  500);

              }
              else {
                setTimeout(() => {
                  document.getElementById("option2id").style.boxShadow = "0px 0px 5px 0px red";
                  this.totalmarksobtainedforquestion = this.answerlist[countquestion].incorrectAnswerMarks + this.totalmarksobtainedforquestion;
                  this.isoption2incorrect = true;
                  this.isoption2 = true;
                },
                  500);

              }
              this.isoption2 = true;
            }
            else if (this.answerlist[countquestion].studentAnswer[i] == "3") {
              if (this.answerlist[countquestion].correctAnswer.includes(3)) {
                setTimeout(() => {
                  document.getElementById("option3id").style.boxShadow = "0px 0px 5px 3px green";
                  this.totalmarksobtainedforquestion = this.totalmarksobtainedforquestion + this.answerlist[countquestion].correctAnswerMarks;
                  this.isoption3correct = true;
                  this.isoption3 = true;
                },
                  500);

              }
              else {
                setTimeout(() => {
                  document.getElementById("option3id").style.boxShadow = "0px 0px 5px 0px red";
                  this.totalmarksobtainedforquestion = this.answerlist[countquestion].incorrectAnswerMarks + this.totalmarksobtainedforquestion;
                  this.isoption3incorrect = true;
                  this.isoption3 = true;
                },
                  500);

              }
              this.isoption3 = true;
            }
            else if (this.answerlist[countquestion].studentAnswer[i] == "4") {
              if (this.answerlist[countquestion].correctAnswer.includes(4)) {
                setTimeout(() => {
                  document.getElementById("option4id").style.boxShadow = "0px 0px 5px 3px green";
                  this.totalmarksobtainedforquestion = this.totalmarksobtainedforquestion + this.answerlist[countquestion].correctAnswerMarks;
                  this.isoption4correct = true;
                  this.isoption4 = true;
                },
                  500);

              }
              else {
                setTimeout(() => {
                  document.getElementById("option4id").style.boxShadow = "0px 0px 5px 0px red";
                  this.totalmarksobtainedforquestion = this.answerlist[countquestion].incorrectAnswerMarks + this.totalmarksobtainedforquestion;
                  this.isoption4incorrect = true;
                  this.isoption4 = true;
                },
                  500);

              }
            }
          }
        }

      }
      else {
        this.totalmarksforquestion = this.answerlist[countquestion].correctAnswerMarks;
        this.totalmarksobtainedforquestion = 0;
        this.selectedoptionform.controls['option1'].reset();
        this.selectedoptionform.controls['option2'].reset();
        this.selectedoptionform.controls['option3'].reset();
        this.selectedoptionform.controls['option4'].reset();
      }
    }
    else {
      this.totalmarksforquestion = this.answerlist[countquestion].correctAnswerMarks;
      this.totalmarksobtainedforquestion = 0;
      if (this.answerlist[countquestion].studentAnswer != null) {
        if (this.answerlist[countquestion].studentAnswer[0] == "1") {
          this.isoption1 = true;
        }
        else if (this.answerlist[countquestion].studentAnswer[0] == "2") {
          this.isoption2 = true;
        }
        else if (this.answerlist[countquestion].studentAnswer[0] == "3") {
          this.isoption3 = true;
        }
        else if (this.answerlist[countquestion].studentAnswer[0] == "4") {
          this.isoption4 = true;
        }
      }
    }

    if (this.answerlist[countquestion].questionType == 3) {
      this.totalmarksforquestion = this.answerlist[countquestion].correctAnswerMarks;
      this.totalmarksobtainedforquestion = this.answerlist[countquestion].marksObtained;

      if (this.answerlist[countquestion].studentAnswer.toLowerCase().includes("https://s3.ap-south-1.amazonaws.com")) {
        this.isquestionimage = true;
        this.questioncountimage = this.answerlist[countquestion].studentAnswer;
        this.selectedoptionform.patchValue({ marksObtained: this.answerlist[countquestion].marksObtained })
        this.selectedoptionform.patchValue({ Remarks: this.answerlist[countquestion].remarks })
      }
      else {
        this.isquestiontext = true;
        this.selectedoptionform.patchValue({ marksObtained: this.answerlist[countquestion].marksObtained })
        this.selectedoptionform.patchValue({ Remarks: this.answerlist[countquestion].remarks })
      }
    }

    if (this.answerlist[this.questioncount].option1 !== undefined || this.answerlist[this.questioncount].option1 != "") {
      if (this.answerlist[this.questioncount].option1Images == undefined) {
        document.getElementById("mathone").innerHTML = this.answerlist[this.questioncount].option1;
        MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
      }
      else {
        for (var i = 0; i < this.answerlist[this.questioncount].option1Images.length; i++) {
          if (!this.answerlist[this.questioncount].option1Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
            console.log(this.answerlist[this.questioncount].option1Images[i])
            document.getElementById("mathone").innerHTML = this.answerlist[this.questioncount].option1Images[i];
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathone")]);
          }

        }
      }
    }
    if (this.answerlist[this.questioncount].option2 !== undefined || this.answerlist[this.questioncount].option2 != "") {
      if (this.answerlist[this.questioncount].option2Images == undefined) {
        document.getElementById("mathtwo").innerHTML = this.answerlist[this.questioncount].option2;
        MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
      }
      else {
        for (var i = 0; i < this.answerlist[this.questioncount].option2Images.length; i++) {
          if (!this.answerlist[this.questioncount].option2Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
            console.log(this.answerlist[this.questioncount].option2Images[i])
            document.getElementById("mathtwo").innerHTML = this.answerlist[this.questioncount].option2Images[i];
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathtwo")]);
          }

        }
      }
    }
    if (this.answerlist[this.questioncount].option3 !== undefined || this.answerlist[this.questioncount].option3 != "") {
      if (this.answerlist[this.questioncount].option3Images == undefined) {
        document.getElementById("maththree").innerHTML = this.answerlist[this.questioncount].option3;
        MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
      }
      else {
        for (var i = 0; i < this.answerlist[this.questioncount].option3Images.length; i++) {
          if (!this.answerlist[this.questioncount].option3Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
            console.log(this.answerlist[this.questioncount].option3Images[i])
            document.getElementById("maththree").innerHTML = this.answerlist[this.questioncount].option3Images[i];
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("maththree")]);
          }

        }
      }
    }
    if (this.answerlist[this.questioncount].option4 !== undefined || this.answerlist[this.questioncount].option4 != "") {
      if (this.answerlist[this.questioncount].option4Images == undefined) {
        document.getElementById("mathfour").innerHTML = this.answerlist[this.questioncount].option4;
        MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
      }
      else {
        for (var i = 0; i < this.answerlist[this.questioncount].option4mages.length; i++) {
          if (!this.answerlist[this.questioncount].option4Images[i].includes("https://s3.ap-south-1.amazonaws.com")) {
            console.log(this.answerlist[this.questioncount].option4Images[i])
            document.getElementById("mathfour").innerHTML = this.answerlist[this.questioncount].option4Images[i];
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById("mathfour")]);
          }

        }
      }
    }
    let assigndata: any = {};


  }

  option1clicked() {
    const data = this.selectedoptionform.value;
    if (data.option1 == true) {
      this.isoption1 = false;
    }
    else {
      this.isoption1 = true;
    }
  }
  option2clicked() {
    const data = this.selectedoptionform.value;
    if (data.option2 == true) {
      this.isoption2 = false;
    }
    else {
      this.isoption2 = true;
    }
  }
  option3clicked() {
    const data = this.selectedoptionform.value;
    if (data.option3 == true) {
      this.isoption3 = false;
    }
    else {
      this.isoption3 = true;
    }
  }
  option4clicked() {
    const data = this.selectedoptionform.value;
    if (data.option4 == true) {
      this.isoption4 = false;
    }
    else {
      this.isoption4 = true;
    }
  }

  endtest() {
    var token = localStorage.getItem('webtoken');
    var formData = {};
    formData["id"] = this.testid;
    this.rest.finishTestForCorrection(formData, token).subscribe((result) => {
      if (result.message.toLowerCase().includes("success")) {
        this.toastr.successToastr(result.message);
        this.router.navigate(['teacher/home']);
      }
      else {
        this.toastr.errorToastr(result.message);
      }

    }, (err) => {
      console.log(err);
    });

  }

  back() {
    this.router.navigate(['student/home']);
  }

  fileChange(event) {
    let fileList: FileList = event.target.files;
    this.selectedoptionform.patchValue({ upload_name: fileList[0] });
    this.selectedFileNmae = fileList[0].name;
    this.selectedoptionform.patchValue({ fileupload: this.selectedFileNmae });
  }

  reviewtest() {
    var token = localStorage.getItem('webtoken');
    var formData = {};
    formData["id"] = this.TestlinkID;
    this.rest.sendstudentReviewTest(token, formData).subscribe((result) => {
      if (result.status) {
        this.toastr.successToastr(result.message);
        this.router.navigate(['student/home']);
      }
      else {
        this.toastr.errorToastr(result.message);
      }
    }, (err) => {
      console.log(err);
    });
  }

  subjectname1arrowdownfunction() {
    this.subjectname1arrowdown = false;
    this.subjectname1arrowup = true;
    this.subjectname1arrayquestion = true;
  }

  subjectname1arrowupfunction() {
    this.subjectname1arrowdown = true;
    this.subjectname1arrowup = false;
    this.subjectname1arrayquestion = false;
  }

  subjectname2arrowdownfunction() {
    this.subjectname2arrowdown = false;
    this.subjectname2arrowup = true;
    this.subjectname2arrayquestion = true;
  }

  subjectname2arrowupfunction() {
    this.subjectname2arrowdown = true;
    this.subjectname2arrowup = false;
    this.subjectname2arrayquestion = false;
  }

  subjectname3arrowdownfunction() {
    this.subjectname3arrowdown = false;
    this.subjectname3arrowup = true;
    this.subjectname3arrayquestion = true;
  }

  subjectname3arrowupfunction() {
    this.subjectname3arrowdown = true;
    this.subjectname3arrowup = false;
    this.subjectname3arrayquestion = false;
  }

  subjectname4arrowdownfunction() {
    this.subjectname4arrowdown = false;
    this.subjectname4arrowup = true;
    this.subjectname4arrayquestion = true;
  }

  subjectname4arrowupfunction() {
    this.subjectname4arrowdown = true;
    this.subjectname4arrowup = false;
    this.subjectname4arrayquestion = false;
  }

   previousimage() {
    if(this.indexofimage - 1 >= 0) {
      this.imageToShow =  this.imagesarray[this.indexofimage - 1]; 
      this.indexofimage = this.indexofimage - 1;
    }
    else {
     
    }
  }

  nextimage() {
    if(this.indexofimage + 1 < this.totalimages) {
      this.imageToShow =  this.imagesarray[this.indexofimage + 1] ;
      this.indexofimage = this.indexofimage + 1;
    }
    else {
    
    }
  }
}



