

<div *ngIf="isTeacher">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>
<div *ngIf="!this.isTeacher">
    <app-header-style-one></app-header-style-one>
</div>
<div  class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a>Home</a></li>
                <!-- <li><a>Classes</a></li> -->
                <li>buddi One-on-one Classes</li>
            </ul>
            <h2></h2>           
        </div>
    </div>
</div>
<div class="container">
	<div >
		<form [formGroup]="paymentBuddiForm" class="">
            <div>
               <h4 class="mt-2"> {{buddiOneonone.productName}} One-on-One Classes </h4>
               <br>
            </div>
            <div class="row">
                <div class="col-lg-3 mt-2">
                    <span>No Of One-on-one Classes</span>
                </div>
                <div class="col-lg-3 mt-2">
                    <div class="form-group">                    
                        <input type="text" (keyup)="changeCount($event)" formControlName="noofclasses" class="form-control" maxlength="25" [ngClass]="{ 'is-invalid': submitted && f.noofclasses.errors }" />
                        <div *ngIf="submitted && f.noofclasses.errors" class="invalid-feedback">
                            <div *ngIf="f.noofclasses.errors.required">No Of class is required
                            </div>
                        </div>                    
                    </div>
                </div>
                <div class="col-lg-3 mt-2">
                    {{f.noofclasses.value}} * {{buddiOneonone.productPrice}} = {{f.noofclasses.value*buddiOneonone.productPrice}}
                </div>
                <div class="col-lg-3">
                    <span>
                        <a  (click)="onSubmit()" href="javascript:void(0)" class="default-btn mt-2" style="border-radius: 5px;">
                            <i class='bx bx-paper-plane icon-arrow before'></i>
                                <span class="label">Pay Now</span>
                            <i class="bx bx-paper-plane icon-arrow after"></i>
                        </a>
                    </span>                   
                </div>
            </div>           
        </form>
	</div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <img src="assets/img/spinner-default.gif" alt="">
</ngx-spinner>