import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-press-releases',
  templateUrl: './press-releases.component.html',
  styleUrls: ['./press-releases.component.scss']
})
export class PressReleasesComponent implements OnInit {

  public isstudent = false;
  public isteacher = false;
  constructor() { }

  ngOnInit(): void {
    const userType = localStorage.getItem('userType');      
    if(userType.toLowerCase()=="teacher") {
      this.isteacher = true;
    }
    else {
      this.isstudent = true;
    }
  }

}
