<app-header-style-one></app-header-style-one>
<div class="containerBg">
	<div class="container">
		<div class="row mx-0">
			<div class="col-md-12">
				<div class="serviceTitle">
					<h2 class="titleWithIcon"> <span>Join Meeting</span></h2>
        </div>
        <hr>
      </div>
    </div>
    <form [formGroup]="inviteForm" (ngSubmit)="onSubmit(inviteForm)">
      <div class="row mx-0 ">
      </div>
			<div class="row mx-0">
        <div class="col-md-12 description">
          <div class="form-group col-md-6">
            <label>Enter Your Name You will be login a guest user </label>
            <input type="text" formControlName="fullName" [(ngModel)]="fullName" class="meeting-des form-control" [ngClass]="{ 'is-invalid': submitted && f.fullName.errors }" >
            <div *ngIf="submitted && f.fullName.errors" class="invalid-feedback">
              <div *ngIf="f.fullName.errors.required">Your Name is required</div>
						</div>
						<p class="regLink mt-1">
							Already a buddi User? <a routerLink="/teacher/login">Login Here</a>
						</p>
          </div>
        </div>

			</div>
      <div class="row mx-0">
        <div class="col-md-12 description">
          <div class="form-group col-md-2">
            <button type="submit" class="btn btnType1">Join Meeting &nbsp;<i class="fas fa-long-arrow-alt-right"></i></button>
          </div>
        </div>
      </div>
    </form>
	</div>
</div>
