<div *ngIf="!isStudent">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>
<div *ngIf="isStudent">
    <app-header-style-one></app-header-style-one>
</div>
<!-- <div class="container" routerLink="/sidenav"><button style="width: 10%;height:45px;margin-left:89%;margin-top:1%;padding: 9px;"><i class="fas fa-long-arrow-alt-left mr-1"></i>Back</button></div> -->
<div *ngIf="isStudent">
    <div class="container mt-3 mb-3">
        <div class="flexButton mb-3">
            <h5 style="color: #000000;cursor: pointer;">Completed Study Hall</h5>
            <div class="btn-group ml-3" style="margin-left:0rem !important">
                <button type="button" (click)="studyClassFilter('today')">Today</button> 
                <button type="button" (click)="studyClassFilter('tomorrow')" >Yesterday</button> 
                <button type="button" (click)="studyClassFilter('week')">Last Week</button> 
                <button type="button" (click)="studyClassFilter('month')" >Last Month</button> 
                <button type="button" routerLink="/sidenav"><i class="fas fa-long-arrow-alt-left mr-1"></i>Back</button>
            </div>
        </div>
        
        <div class="orders-table table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>Sl.No</th>
                        <th>Class Name</th>
                        <th>Topic</th>
                        <th>Date & Time</th>
                        <th>Tutor Name</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor='let class of studyHallList;let i = index'>
                        <td>{{i+1}}</td>
                        <td *ngIf = "class.personalClassID"><a routerLink="javascript:void(0)" (click)="StudyhallClassDetails(class)"
                            class="d-inline-block"
                           >{{class.personalClassID.className || class.personalClassID }}</a></td>
                        <td *ngIf = "!class.personalClassID"> - </td>
                        <td *ngIf = "!class.personalClassID"> - </td>
                        <td *ngIf = "class.personalClassID">All Topics</td>
                        <td>{{class.startDateTime}}</td>
                        <td *ngIf = "class.personalClassID">{{class.personalClassID.teacherID.profileName}}</td>
                        <td>{{class.isCompleted ? 'Completed' : class.isRejected ? 'Rejected by Teacher' : 'Cancelled'}}</td>
                        <!--<td>
                            <a routerLink="/single-courses" class="view-button"
                                >View</a
                            >
                        </td> -->
                    </tr>
                </tbody>
            </table>
            <div class="pagination-area text-center">
                <a
                    href="javascript:void(0)"
                    (click)="clickLiveClassPageNumber(1)"
                    *ngIf="currentLiveClassPageNumber > 2 && totalLiveClassPageCount > 1"
                    class="page-numbers"
                    >1</a
                >
                <span
                    class="page-numbers"
                    *ngIf="currentLiveClassPageNumber > 2 && totalLiveClassPageCount > 1"
                    aria-current="page"
                    >...</span
                >
                <a
                    href="javascript:void(0)"
                    (click)="clickLiveClassPageNumber(currentLiveClassPageNumber - 1)"
                    *ngIf="currentLiveClassPageNumber > 1 && totalLiveClassPageCount > 1"
                    class="page-numbers"
                    >{{ currentLiveClassPageNumber - 1 }}</a
                >
                <span
                    class="page-numbers current"
                    *ngIf="totalLiveClassPageCount != 0"
                    aria-current="page"
                    >{{ currentLiveClassPageNumber }}</span
                >
                <a
                    href="javascript:void(0)"
                    (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 1)"
                    *ngIf="
                        totalLiveClassPageCount > 1 &&
                        currentLiveClassPageNumber + 1 <= totalLiveClassPageCount
                    " class="page-numbers">{{ currentLiveClassPageNumber + 1 }}</a>
            <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 2)" *ngIf="
                        totalLiveClassPageCount > 1 &&
                        currentLiveClassPageNumber + 2 <= totalLiveClassPageCount
                    " class="page-numbers">{{ currentLiveClassPageNumber + 2 }}</a>
            <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 3)" *ngIf="
                        totalLiveClassPageCount > 1 &&
                        currentLiveClassPageNumber + 3 <= totalLiveClassPageCount
                    " class="page-numbers">{{ currentLiveClassPageNumber + 3 }}</a>
            <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 4)" *ngIf="
                        totalLiveClassPageCount > 1 &&
                        currentLiveClassPageNumber + 4 <= totalLiveClassPageCount
                    " class="page-numbers">{{ currentLiveClassPageNumber + 4 }}</a>
            <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 1)" *ngIf="
                        totalLiveClassPageCount > 1 &&
                        currentLiveClassPageNumber + 1 <= totalLiveClassPageCount
                    " class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
        </div>
        </div>
    </div>
</div>
<div *ngIf="isTeacher">
    <div class="container mt-3 mb-3">
        <div class="flexButton mb-3">
            <h5 style="color: #000000;cursor: pointer;">Completed Study Hall</h5>
            <div class="btn-group ml-3" style="margin-left:0rem !important">
                <button type="button" (click)="studyClassFilter('today')">Today</button> 
                <button type="button" (click)="studyClassFilter('tomorrow')" >Yesterday</button> 
                <button type="button" (click)="studyClassFilter('week')">Last Week</button> 
                <button type="button" (click)="studyClassFilter('month')" >Last Month</button> 
                <button type="button" routerLink="/sidenav"><i class="fas fa-long-arrow-alt-left mr-1"></i>Back</button>
            </div>
        </div>
        
        <div class="orders-table table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>Sl.No</th>
                        <th>Class Name</th>
                        <th>Topic</th>
                        <th>Date & Time</th>
                        <th>Learner Name</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor='let class of studyHallList;let i = index'>
                        <td>{{i+1}}</td>
                        <td *ngIf = "class.personalClassID"><a routerLink="javascript:void(0)" (click)="StudyhallClassDetails(class)"
                            class="d-inline-block"
                           >{{class.personalClassID.className || class.personalClassID }}</a></td>
                        <td *ngIf = "!class.personalClassID"> - </td>
                        <td *ngIf = "!class.personalClassID"> - </td>
                        <td *ngIf = "class.personalClassID">All Topics</td>
                        <td>{{class.startDateTime}}</td>
                        <td *ngIf = "class.personalClassID">{{class.studentID.firstName}} {{class.studentID.lastName}}</td>
                        <td>{{class.isCompleted ? 'Completed' : class.isRejected ? 'Rejected by Teacher' : 'Cancelled'}}</td>
                        <!--<td>
                            <a routerLink="/single-courses" class="view-button"
                                >View</a
                            >
                        </td> -->
                    </tr>
                </tbody>
            </table>
            <div class="pagination-area text-center">
                <a
                    href="javascript:void(0)"
                    (click)="clickLiveClassPageNumber(1)"
                    *ngIf="currentLiveClassPageNumber > 2 && totalLiveClassPageCount > 1"
                    class="page-numbers"
                    >1</a
                >
                <span
                    class="page-numbers"
                    *ngIf="currentLiveClassPageNumber > 2 && totalLiveClassPageCount > 1"
                    aria-current="page"
                    >...</span
                >
                <a
                    href="javascript:void(0)"
                    (click)="clickLiveClassPageNumber(currentLiveClassPageNumber - 1)"
                    *ngIf="currentLiveClassPageNumber > 1 && totalLiveClassPageCount > 1"
                    class="page-numbers"
                    >{{ currentLiveClassPageNumber - 1 }}</a
                >
                <span
                    class="page-numbers current"
                    *ngIf="totalLiveClassPageCount != 0"
                    aria-current="page"
                    >{{ currentLiveClassPageNumber }}</span
                >
                <a
                    href="javascript:void(0)"
                    (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 1)"
                    *ngIf="
                        totalLiveClassPageCount > 1 &&
                        currentLiveClassPageNumber + 1 <= totalLiveClassPageCount
                    " class="page-numbers">{{ currentLiveClassPageNumber + 1 }}</a>
            <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 2)" *ngIf="
                        totalLiveClassPageCount > 1 &&
                        currentLiveClassPageNumber + 2 <= totalLiveClassPageCount
                    " class="page-numbers">{{ currentLiveClassPageNumber + 2 }}</a>
            <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 3)" *ngIf="
                        totalLiveClassPageCount > 1 &&
                        currentLiveClassPageNumber + 3 <= totalLiveClassPageCount
                    " class="page-numbers">{{ currentLiveClassPageNumber + 3 }}</a>
            <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 4)" *ngIf="
                        totalLiveClassPageCount > 1 &&
                        currentLiveClassPageNumber + 4 <= totalLiveClassPageCount
                    " class="page-numbers">{{ currentLiveClassPageNumber + 4 }}</a>
            <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 1)" *ngIf="
                        totalLiveClassPageCount > 1 &&
                        currentLiveClassPageNumber + 1 <= totalLiveClassPageCount
                    " class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
        </div>
        </div>
    </div>
</div>
