<div >
        <div *ngIf="isteacher">
                <app-header-style-one-teacher></app-header-style-one-teacher>
            </div>

            <div *ngIf="!isteacher">
                <app-header-style-one></app-header-style-one>
            </div>
</div>
<div class="containerBg" style="padding-bottom: 5%">
    <div class="container" *ngIf="categorydetails.length!=0">
      
            <div class="row" style="background-color: #f9f8f85e;padding: 3% 0px 10%;border-radius: 20px;">
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                <span style="font-weight: 600;color: #000000;font-size: 16px;"> {{categoryname}} > {{modulename}}</span>
        </div>
               
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12" >
                        <h5  style="font-weight: 700;color: #000000;font-size: 25px;padding-top: 3%;padding-bottom: 3%">{{categorydetails.courseName}}</h5>
                    </div>
           
            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                    <h6 style="color: #000000;">{{categorydetails.courseHeading}}</h6>
            </div>
           

        </div>

        <div class="row">
            <h5 style="font-weight: 700;font-size: 24px;padding-top:2%"> What You will Learn</h5>
            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                    <span style="font-weight: 600;color: black;font-size: 16px;"> {{categorydetails.whatYouWillLearn}}</span>
            </div>
        </div>

        <div class="row">
                <h5 style="font-weight: 700;font-size: 24px;padding-top:2%"> Requirements</h5>
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                        <span style="font-weight: 600;color: black;font-size: 16px;"> {{categorydetails.requirements}}</span>
                </div>
            </div>

            <div class="row">
                    <h5 style="font-weight: 700;font-size: 24px;padding-top:2%"> Description</h5>
                    <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                            <span style="font-weight: 600;color: black;font-size: 16px;"> {{categorydetails.description}}</span>
                    </div>
                </div>

                <div class="row">
                        <h5 style="font-weight: 700;font-size: 24px;padding-top:2%"> Topics</h5>     
                        
                        <div class="col-xs-12 col-md-12 col-sm-12 col-lg-12" style="padding-top: 2%">
                                <div *ngFor="let item of categorydetails.topicList;let i = index;">
                                        <button class="accordion" (click)="toggleAccordian($event, i)" [id]=item.topicID._id> {{item.topicID.topicName}} </button>
                                        <div class="panel" hide="!item.isActive">                                 
                                          <ul class="nav" *ngIf="isteacher">
                                            <li class="nav-item pr-2  mt-2">
                                              <button class="default-btn"  (click)="liveClass(item.topicID.courseID,item.topicID._id)" >
                                                 Live Classes
                                              </button>

                                              <br>
                                              <p></p>
                                         </li>
                                         <li class="nav-item pr-2  mt-2">
                                              <a class="default-btn" (click)="testclasses(item.topicID.courseID,item.topicID._id)">
                                                 buddi Tests
                                             </a>
                                         </li>
                                           <li class="nav-item pr-2  mt-2" style="cursor: pointer;">
                                      <a class="default-btn" (click)="studyHall()">
                                         Study Hall
                                     </a>
                                 </li>
                                        <li class="nav-item pr-2  mt-2" style="cursor: pointer;">
                                          <a class="default-btn" (click)="videoclasses(item.topicID.courseID, item.topicID._id)">
                                             Video Lessons
                                         </a>
                                     </li>
                                     <li class="nav-item pr-2  mt-2" style="cursor: pointer;">
                                          <a class="default-btn" (click)="studymaterialclasses(item.topicID.courseID, item.topicID._id)">
                                             Smart Material
                                         </a>
                                     </li>
                                          </ul>
                                        </div>
                                      </div>
                        </div>

                    </div>

                    

    </div>
</div>
