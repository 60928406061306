import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Location } from '@angular/common';
@Component({
  selector: 'app-group-create',
  templateUrl: './group-create.component.html',
  styleUrls: ['./group-create.component.scss']
})
export class GroupCreateComponent implements OnInit {

  
  form1: FormGroup;
  form2: FormGroup;
  form3: FormGroup;
  form4: FormGroup;
  form5: FormGroup;
  form6: FormGroup;
  submitted: boolean;
  div1:boolean=true;
  div2:boolean=true;
  div3:boolean=true;
  div4:boolean=true;
  div5:boolean=true;
  div6:boolean=true;
  userCreated:boolean=false;
  formData;

  constructor(public fb: FormBuilder,public location:Location,public toastr: ToastrManager,private userApi: ApiService) { }

  ngOnInit() {

    this.form1 = this.fb.group({
      firstName: ['',Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      phoneNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern("^[0-9]*$")]],
      password: ["Pokemon123##"]

      // passWord:  ['Welcome@123'],
      // cpassword:['Welcome@123']
    });

    this.form2 = this.fb.group({
      firstName2: ['',Validators.required],
      lastName2: ['', Validators.required],
      email2: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      phoneNumber2: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern("^[0-9]*$")]],
      password2: ["Pokemon123##"]
      // passWord:  ['Welcome@123'],
      // cpassword:['Welcome@123']
    });

    this.form3 = this.fb.group({
      firstName3: ['',Validators.required],
      lastName3: ['', Validators.required],
      email3: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      phoneNumber3: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern("^[0-9]*$")]],
      passwor3: ["Pokemon123##"]
      // passWord:  ['Welcome@123'],
      // cpassword:['Welcome@123']
    });

    this.form4 = this.fb.group({
      firstName4: ['',Validators.required],
      lastName4: ['', Validators.required],
      email4: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      phoneNumber4: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern("^[0-9]*$")]],
      password4: ["Pokemon123##"]
      // passWord:  ['Welcome@123'],
      // cpassword:['Welcome@123']
    });

    this.form5 = this.fb.group({
      firstName5: ['',Validators.required],
      lastName5: ['', Validators.required],
      email5: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      phoneNumber5: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern("^[0-9]*$")]],
      password5: ["Pokemon123##"]
      // passWord:  ['Welcome@123'],
      // cpassword:['Welcome@123']
    });

    this.form6 = this.fb.group({
      firstName6: ['',Validators.required],
      lastName6: ['', Validators.required],
      email6: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      phoneNumber6: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern("^[0-9]*$")]],
      password6: ["Pokemon123##"]
      // passWord:  ['Welcome@123'],
      // cpassword:['Welcome@123']
    });
    
  }
  get f() {return this.form1.controls; }
  get f2() {return this.form2.controls; }
  get f3() {return this.form3.controls; }
  get f4() {return this.form4.controls; }
  get f5() {return this.form5.controls; }
  get f6() {return this.form6.controls; }

  form1Submitted: boolean = false;
  form2Submitted: boolean = false;
  form3Submitted: boolean = false;
  form4Submitted: boolean = false;
  form5Submitted: boolean = false;
  form6Submitted: boolean = false;

  onSubmit() {    
    this.form1Submitted = true;    
    if (this.form1.invalid) {
      return;      
    }    
    console.log(this.form1.value);
    const data = this.form1.value;
    // data.profileName = data.firstName;
    this.formData = new FormData();
    this.formData.append("firstName", data.firstName.replace(/\s/g, ""));
    this.formData.append("lastName", data.lastName.replace(/\s/g, ""));
    this.formData.append("email", data.email);
    this.formData.append("phoneNumber", data.phoneNumber);

    let jsonObject = {};

    for (const [key, value]  of this.formData) {
        jsonObject[key] = value;
    }
    const webToken = localStorage.getItem('webtoken');
    this.userApi.AddEnterpriseUser(jsonObject,webToken).subscribe(res => {
      console.log('result', res );
      if(res.status){
        this.toastr.successToastr(res.message);
        this.div1=false;
      }else{
        this.toastr.errorToastr(res.message);
      }
    });
    
  }
  backto(){
    this.location.back();
  }
  onSubmit2() {
    this.form2Submitted = true;        
    if (this.form2.invalid) {
      return;      
    }
    const data = this.form2.value;
    // data.profileName = data.firstName;
    this.formData = new FormData();
    this.formData.append("firstName", data.firstName2.replace(/\s/g, ""));
    this.formData.append("lastName", data.lastName2.replace(/\s/g, ""));
    this.formData.append("email", data.email2);
    this.formData.append("phoneNumber", data.phoneNumber2);

    let jsonObject = {};

    for (const [key, value]  of this.formData) {
        jsonObject[key] = value;
    }
    const webToken = localStorage.getItem('webtoken');
    this.userApi.AddEnterpriseUser(jsonObject,webToken).subscribe(res => {
      console.log('result', res );
      if(res.status){
        this.toastr.successToastr(res.message);
        this.div2=false;
      }else{
        this.toastr.errorToastr(res.message);
      }
    });
  }

  onSubmit3() {
    this.form3Submitted = true;
    if (this.form3.invalid) {
      return;      
    }

    console.log(this.form3.value);
    const data = this.form3.value;
    this.formData = new FormData();
    this.formData.append("firstName", data.firstName3.replace(/\s/g, ""));
    this.formData.append("lastName", data.lastName3.replace(/\s/g, ""));
    this.formData.append("email", data.email3);
    this.formData.append("phoneNumber", data.phoneNumber3);

    let jsonObject = {};

    for (const [key, value]  of this.formData) {
        jsonObject[key] = value;
    }
    const webToken = localStorage.getItem('webtoken');
    this.userApi.AddEnterpriseUser(jsonObject,webToken).subscribe(res => {
      console.log('result', res );
      if(res.status){
        this.toastr.successToastr(res.message);
        this.div3=false;
      }else{
        this.toastr.errorToastr(res.message);
      }
    });
  }

  onSubmit4() {
    this.form4Submitted = true;
    if (this.form4.invalid) {
      return;      
    }
    console.log(this.form4.value);
    const data = this.form4.value;
    this.formData = new FormData();
    this.formData.append("firstName", data.firstName4.replace(/\s/g, ""));
    this.formData.append("lastName", data.lastName4.replace(/\s/g, ""));
    this.formData.append("email", data.email4);
    this.formData.append("phoneNumber", data.phoneNumber4);

    let jsonObject = {};

    for (const [key, value]  of this.formData) {
        jsonObject[key] = value;
    }
    // data.profileName = data.firstName;
    const webToken = localStorage.getItem('webtoken');
    this.userApi.AddEnterpriseUser(jsonObject,webToken).subscribe(res => {
      console.log('result', res );
      if(res.status){
        this.toastr.successToastr(res.message);
        this.div4=false;
      }else{
        this.toastr.errorToastr(res.message);
      }
    });
  }

  onSubmit5() {
    this.form5Submitted = true;
    if (this.form5.invalid) {
      return;      
    }
    console.log(this.form5.value);
    const data = this.form5.value;
    this.formData = new FormData();
    this.formData.append("firstName", data.firstName5.replace(/\s/g, ""));
    this.formData.append("lastName", data.lastName5.replace(/\s/g, ""));
    this.formData.append("email", data.email5);
    this.formData.append("phoneNumber", data.phoneNumber5);

    let jsonObject = {};

    for (const [key, value]  of this.formData) {
        jsonObject[key] = value;
    }
    // data.profileName = data.firstName;
    const webToken = localStorage.getItem('webtoken');
    this.userApi.AddEnterpriseUser(jsonObject,webToken).subscribe(res => {
      console.log('result', res );
      if(res.status){
        this.toastr.successToastr(res.message);
        this.div5=false;
      }else{
        this.toastr.errorToastr(res.message);
      }
    });
  }

  onSubmit6() {
    this.form6Submitted = true;
    if (this.form6.invalid) {
      return;      
    }
    console.log(this.form6.value);
    const data = this.form6.value;
    // data.profileName = data.firstName;
    this.formData = new FormData();
    this.formData.append("firstName", data.firstName6.replace(/\s/g, ""));
    this.formData.append("lastName", data.lastName6.replace(/\s/g, ""));
    this.formData.append("email", data.email6);
    this.formData.append("phoneNumber", data.phoneNumber6);

    let jsonObject = {};

    for (const [key, value]  of this.formData) {
        jsonObject[key] = value;
    }
    const webToken = localStorage.getItem('webtoken');
    this.userApi.AddEnterpriseUser(jsonObject,webToken).subscribe(res => {
      console.log('result', res );
      if(res.status){
        this.backto()
        this.toastr.successToastr(res.message);
        this.div6=false;
        this.userCreated=true;
      }else{
        this.toastr.errorToastr(res.message);
      }
    });
    
  }

}
