
<div class="instructor-slides owl-carousel owl-theme">
    <div class="single-instructor-box mb-30">
        <div class="image">
            <img src="../../../../assets/img/UmaKirthiga_Biology.jpeg" alt="UmaKirthiga_Biology" routerLink="/buddi-teachers-team/UmaKirthiga" style="cursor: pointer;" title="click"
                onerror="this.src='../../../../../assets/img/umakarthiga_biology.jpg';" alt="image" />

            <ul class="social">
                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
            </ul>
        </div>

        <div class="content">
            <h3><a routerLink="/buddi-teachers-team/UmaKirthiga" style="cursor: pointer;" title="click">Uma Kirthiga</a></h3>
            <span>Biology</span>
        </div>
    </div>

    <div class="single-instructor-box mb-30">
        <div class="image">
            <img src="../../../../assets/img/vidhya_Mathematics.jpeg" alt="vidhya_Mathematics" routerLink="/buddi-teachers-team/Vidhya" style="cursor: pointer;" title="click"
                onerror="this.src='../../../../../assets/img/vidhyasri_maths.jpg';" alt="image" />
            <!--   <ul class="social">
                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
            </ul> -->
        </div>

        <div class="content">
            <h3><a routerLink="/buddi-teachers-team/Vidhya" style="cursor: pointer;" title="click">Vidhya </a></h3>
            <span>Mathematics</span>
        </div>
    </div>
    <div class="single-instructor-box mb-30">
        <div class="image">
            <img src="../../../../assets/img/jothiudayashankar_English.jpeg" alt="jothiudayashankar_English" style="cursor: pointer;" title="click" routerLink="/buddi-teachers-team/jothiudayashankar"
                onerror="this.src='../../../../../assets/img/jothi.JPG';" alt="image" />
            <!--   <ul class="social">
                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
            </ul> -->
        </div>

        <div class="content">
            <h3><a routerLink="/buddi-teachers-team/jothiudayashankar" style="cursor: pointer;" title="click">Jothipriya Udayashankar </a></h3>
            <span>English</span>
        </div>
    </div>

    <div class="single-instructor-box mb-30">
        <div class="image">
            <img src="../../../../assets/img/Soniya_Coding.jpg" alt="Soniya_Coding" routerLink="/buddi-teachers-team/Soniya" style="cursor: pointer;" title="click"
                onerror="this.src='../../../../../assets/img/sonia_computerscience.JPEG';" alt="image" />

            <!--   <ul class="social">
                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
            </ul> -->
        </div>

        <div class="content">
            <h3><a routerLink="/buddi-teachers-team/Soniya" style="cursor: pointer;" title="click">Soniya </a></h3>
            <span>Coding</span>
        </div>
    </div>

    <div class="single-instructor-box mb-30">
        <div class="image">
            <img src="../../../../assets/img/Nandhini_Physics.jpeg" alt="Nandhini_Physics
            " routerLink="/buddi-teachers-team/Nandhini" style="cursor: pointer;" title="click"
                onerror="this.src='../../../../../assets/img/nandhini_physics.jpg';" alt="image" />

            <!--   <ul class="social">
                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
            </ul> -->
        </div>

        <div class="content">
            <h3><a routerLink="/buddi-teachers-team/Nandhini" style="cursor: pointer;" title="click">Nandhini </a></h3>
            <span>Physics</span>
        </div>
    </div>

    <div class="single-instructor-box mb-30">
        <div class="image">
            <img src="../../../../assets/img/Priyanka_Chemistry.jpeg" alt="Priyanka_Chemistry" style="cursor: pointer;" title="click" routerLink="/buddi-teachers-team/Priyanka"
                onerror="this.src='../../../../../assets/img/nandhini_physics.jpg';" alt="image" />

            <!--   <ul class="social">
                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
            </ul> -->
        </div>

        <div class="content">
            <h3><a routerLink="/buddi-teachers-team/Priyanka" style="cursor: pointer;" title="click">Priyanka </a></h3>
            <span>Chemistry</span>
        </div>
    </div>

    <div class="single-instructor-box mb-30">
        <div class="image">
            <img src="../../../../assets/img/Sreeja_Chemistry.jpeg" alt="Sreeja_Chemistry
            " style="cursor: pointer;" title="click" routerLink="/buddi-teachers-team/Sreeja"
                onerror="this.src='../../../../../assets/img/nandhini_physics.jpg';" alt="image" />

            <!--   <ul class="social">
                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
            </ul> -->
        </div>

        <div class="content">
            <h3><a routerLink="/buddi-teachers-team/Sreeja" style="cursor: pointer;" title="click">V.S.Sreeja </a></h3>
            <span>Chemistry</span>
        </div>
    </div>
    <div class="single-instructor-box mb-30">
        <div class="image">
            <img src="../../../../assets/img/RethikaRamesh_Biology.jpeg" alt="RethikaRamesh_Biology" style="cursor: pointer;" title="click" routerLink="/buddi-teachers-team/RethikaRamesh"
                onerror="this.src='../../../../../assets/img/nandhini_physics.jpg';" alt="image" />

            <!--   <ul class="social">
                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
            </ul> -->
        </div>

        <div class="content">
            <h3><a routerLink="/buddi-teachers-team/RethikaRamesh" style="cursor: pointer;" title="click">Rethika Ramesh </a></h3>
            <span>Biology</span>
        </div>
    </div>

    <div class="single-instructor-box mb-30">
        <div class="image">
            <img src="../../../../assets/img/Nivetha_Maths.jpeg" alt="Nivetha_Maths" style="cursor: pointer;" title="click" routerLink="/buddi-teachers-team/Nivetha"
                onerror="this.src='../../../../../assets/img/Nivetha.JPG';" alt="image" />

            <!--   <ul class="social">
                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
            </ul> -->
        </div>

        <div class="content">
            <h3><a routerLink="/buddi-teachers-team/Nivetha" style="cursor: pointer;" title="click"> S. Nivetha </a></h3>
            <span>Maths</span>
        </div>
    </div>

    <div class="single-instructor-box mb-30">
        <div class="image">
            <img src="../../../../assets/img/Vignesh_Computer Sicence.jpeg" alt="Vignesh_Computer Sicence" style="cursor: pointer;" title="click" routerLink="/buddi-teachers-team/Vignesh"
                onerror="this.src='../../../../../assets/img/Vignesh.JPG';" alt="image" />

            <!--   <ul class="social">
                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
            </ul> -->
        </div>

        <div class="content">
            <h3><a routerLink="/buddi-teachers-team/Vignesh" style="cursor: pointer;" title="click"> V. Vignesh </a></h3>
            <span>Computer Science</span>
        </div>
    </div>

    <div class="single-instructor-box mb-30">
        <div class="image">
            <img src="../../../../assets/img/BalkeesParveen_Physics.jpeg" alt="BalkeesParveen_Physics" style="cursor: pointer;" title="click" routerLink="/buddi-teachers-team/BalkeesParveen"
                onerror="this.src='../../../../../assets/img/Balkees-Parveen.JPG';" alt="image" />

            <!--   <ul class="social">
                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
            </ul> -->
        </div>

        <div class="content">
            <h3><a routerLink="/buddi-teachers-team/BalkeesParveen" style="cursor: pointer; " title="click"> Balkees Parveen</a></h3>
            <span>Physics</span>
        </div>
    </div>
    <div class="single-instructor-box mb-30">
        <div class="image">
            <img src="../../../../assets/img/VineethaAnil_Social Sicence.jpeg" alt="VineethaAnil_Social Sicence" style="cursor: pointer;" title="click" routerLink="/buddi-teachers-team/VineethaAnil"
                onerror="this.src='../../../../../assets/img/Vineetha-Anil.JPG';" alt="image" />

            <!--   <ul class="social">
                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
            </ul> -->
        </div>

        <div class="content">
            <h3><a routerLink="/buddi-teachers-team/VineethaAnil" style="cursor: pointer;" title="click"> Vineetha Anil</a></h3>
            <span>Soft Skills, Social Science</span>
        </div>
    </div>
</div>
