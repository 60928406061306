import { Component, OnInit,Inject, ElementRef, ViewChild, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Options } from 'ng5-slider';
import { ApiService } from '../../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {NgSelectModule, NgOption} from '@ng-select/ng-select';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-teacher-requests',
  templateUrl: './teacher-requests.component.html',
  styleUrls: ['./teacher-requests.component.scss']
})
export class TeacherRequestsComponent implements OnInit {

  public courseData:any=[];
  public examData:any=[];
  public coursecategorylist : any = [];
  public courseboardlist : any = [];
  public coursesubjectlist : any = [];
  public languageapi : any = [];
  public gradeapi : any = [];
  public allMeeting : any = [];
  public allMeetingPublic : any = [];
  selectedValue = "";   
  public section = "Select section";
  exploreForm: FormGroup;
  exploreFormPublic: FormGroup;
  public userprofdata : any =[];
  public isLogin : any=[];
  public isStudent:any = '';
  public groupNameTeacher:any='';
  public groupName:any='';
  public isSession:boolean=true;
  public isCourse:boolean=true;
  public isexams:boolean=true;  
  public inviteUrl = "";
  public frontwebsite = environment.frontUrl;
  public requestedDataList:any = [];
   //For Pagination
   p: number = 1;
   count: number = 4;
 
   p1: number = 1;
   count1: number = 4;
   
  p2: number = 1;
  count2: number = 4;
  // ------------------
  constructor(private SpinnerService:NgxSpinnerService,private activatedRoute:ActivatedRoute,public location: Location, public toastr: ToastrManager, private formBuilder: FormBuilder, private rest: ApiService, private router: Router ) { }

  ngOnInit(): void {
    var urlArray = window.location.href.split('/');
    var pageId=  urlArray[urlArray.length-1];
    this.getcoursecategory();
    this.getboardfield();
    this.getsubjectfield();
    this.getlanguage();
    this.getgrade();
    this.exploreForm = this.formBuilder.group({    
      gradeID: ['all'],
      subjectID: ['all'],
      languageID:['all'],     
      categoryID: ['all'],
      boardID: ['all'],
      meetingType:['Public'],
      section:['all']
    });
    this.filterData(this.exploreForm.value);   
    this.coursefilterData(this.exploreForm.value);
    this.isStudent = localStorage.getItem('teacherID') =='' ||  localStorage.getItem('teacherID') == undefined? true : false
    this.isLogin = localStorage.getItem('isLoggedin');
    if(this.isStudent){
     this.getStudentdetails()
    }else{
      this.getTeacherdetails()
    }
  }
  get f() { return this.exploreForm.controls; }
  getTeacherdetails(){
    const webToken = localStorage.getItem('webtoken');
    this.rest.userName_DisplayTeacher(webToken).subscribe((result) => {
      console.log(result.data);
      this.groupName = result.data.groupName
      this.userprofdata = result.data;
    }, (err) => {
    console.log(err);
    });
  }
  getStudentdetails(){
    const webToken = localStorage.getItem('webtoken');
    this.rest.userName_DisplayStudent(webToken).subscribe((result) => {
      if(result.status){
      this.groupName = result.data.groupName
      this.userprofdata = result.data;
      }
      
    }, (err) => {
    console.log(err);
    });
  }

  filterData(reqForm){
    var req = {
      gradeID:reqForm.gradeID == 'all' ? '' : reqForm.gradeID,
      subjectID:reqForm.subjectID == 'all' ? '' : reqForm.subjectID,
      languageID:reqForm.languageID == 'all' ? '' : reqForm.languageID,
      categoryID:reqForm.categoryID == 'all' ? '' : reqForm.categoryID,
      boardID:reqForm.boardID == 'all' ? '' : reqForm.boardID,
      teacherID:localStorage.getItem('teacherID'),
      groupname:reqForm.meetingType == 'Group' ? this.groupName : '',
    }
    this.getDataAPI(req)
  }
  detailsAccept(data) {
    this.router.navigate(['/teacher/accept' + "/" + data.meetingID], { state: data });
  }
  coursefilterData(reqForm){
    const userType = localStorage.getItem('userType');
    var req = {
      gradeID:reqForm.gradeID == 'all' ? '' : reqForm.gradeID,
      subjectID:reqForm.subjectID == 'all' ? '' : reqForm.subjectID,
      languageID:reqForm.languageID == 'all' ? '' : reqForm.languageID,
      categoryID:reqForm.categoryID == 'all' ? '' : reqForm.categoryID,
      boardID:reqForm.boardID == 'all' ? '' : reqForm.boardID
    }
    const Typeofuser = localStorage.getItem('userType');
    const webToken = localStorage.getItem('webtoken');
    var formData = {};
    formData["gradeID"]=reqForm.gradeID;
    formData["subjectID"]=reqForm.subjectID;
    formData["languageID"]=reqForm.languageID;
    formData["categoryID"]=reqForm.categoryID;
    formData["boardID"]=reqForm.boardID;
    formData["userType"]=Typeofuser.toLowerCase();
    
    this.isLogin = localStorage.getItem('isLoggedin');
   
    // Below is For Courses 
    if(this.isLogin=="true") {     
      this.rest.getCoursesByCategory(webToken,formData).subscribe((result) => {   
        if(result.status){
          if(reqForm.meetingType=="Public") {
            this.courseData = result.data.Public;
          }
          else {
            this.courseData = result.data.Group;
          }
        }
           
       
      }, (err) => {
        console.log(err);
        });
    }
    else {
      this.rest.getCoursesByCategorypublic(formData).subscribe((result) => {  
        if(result.status){
        if(reqForm.meetingType=="Public") {
          this.courseData = result.data.Public;
        }
        else {
          this.courseData = result.data.Group;
        }   
      }  
       
      }, (err) => {
        console.log(err);
        });
    }
    
// Below is For Exams 
    if(this.isLogin=="true") {     
      this.rest.getExamsByCategory(webToken,formData).subscribe((result) => {   
        if(result.status){
        if(reqForm.meetingType=="Public") {
          this.examData = result.data.Public;         
        }
        else {
          this.examData = result.data.Group;
        }     
      }
       
      }, (err) => {
        console.log(err);
        });
    }
    else {
      this.rest.getExamsByCategorypublic(formData).subscribe((result) => {  
        if(result.status){
        if(reqForm.meetingType=="Public") {
          this.examData = result.data.Public;
        }
        else {
          this.examData = result.data.Group;
        }     
      }
      }, (err) => {
        console.log(err);
        });
    }
  }
  sectionChange(event : any){
    this.selectedValue = event.target.options[event.target.selectedIndex].text;    
    if(this.selectedValue=="All") {
      this.isSession = true;
      this.isCourse = true;
      this.isexams = true;
      this.courseData=[];
      this.allMeeting=[];
      this.examData=[];
      this.changeRole(this.exploreForm.value.meetingType);
    }
    else if(this.selectedValue=="Classes") {
      this.isSession = true;
      this.isCourse = false;
      this.isexams = false;
      this.courseData=[];
      this.examData=[];
      this.changemoduleforsession(this.exploreForm.value.meetingType);
    }
    else if(this.selectedValue=="Courses") {
      this.isSession = false;
      this.isCourse = true;
      this.isexams = false;
      this.allMeeting=[];
      this.examData=[];
      this.changemoduleforcourse(this.exploreForm.value.meetingType);
    }    
    else if(this.selectedValue=="Exams") {
      this.isSession = false;
      this.isCourse = false;
      this.isexams = true;
      this.allMeeting=[];
      this.courseData=[];
      this.changemoduleforexam(this.exploreForm.value.meetingType);
    }    
  }

  changeRole(type){
    var req = {
      gradeID:this.exploreForm.value.gradeID == 'all' ? '' : this.exploreForm.value.gradeID,
      subjectID:this.exploreForm.value.subjectID == 'all' ? '' : this.exploreForm.value.subjectID,
      languageID:this.exploreForm.value.languageID == 'all' ? '' : this.exploreForm.value.languageID,
      categoryID:this.exploreForm.value.categoryID == 'all' ? '' : this.exploreForm.value.categoryID,
      boardID:this.exploreForm.value.boardID == 'all' ? '' : this.exploreForm.value.boardID,
      meetingType:type,
      groupname:type == 'Group' ? this.groupName : '',
    }
    this.exploreForm.patchValue({ meetingType: type})
    this.getDataAPI(req);
    const Typeofuser = localStorage.getItem('userType');
    const webToken = localStorage.getItem('webtoken');
    var formData = {};
    formData["gradeID"]=this.exploreForm.value.gradeID;
    formData["subjectID"]=this.exploreForm.value.subjectID;
    formData["languageID"]=this.exploreForm.value.languageID;
    formData["categoryID"]=this.exploreForm.value.categoryID;
    formData["boardID"]=this.exploreForm.value.boardID;
    formData["userType"]=Typeofuser.toLowerCase();
    
    this.isLogin = localStorage.getItem('isLoggedin');

     // Below is For Courses 
    if(this.isLogin=="true") {    
      this.rest. getCoursesByCategory(webToken,formData).subscribe((result) => {  
        if(result.status){
        if(type=="Public") {
          this.courseData = result.data.Public;
        }
        else {
          this.courseData = result.data.Group;
        }
       
      }
      }, (err) => {
        console.log(err);
        });
    }
    else {
      this.rest. getCoursesByCategorypublic(formData).subscribe((result) => {  
        if(result.status){
        if(type=="Public") {
          this.courseData = result.data.Public;
        }
        else {
          this.courseData = result.data.Group;
        }
      }
       
      }, (err) => {
        console.log(err);
        });
    }

    // Below is For Exams 
    if(this.isLogin=="true") {     
      this.rest.getExamsByCategory(webToken,formData).subscribe((result) => {   
        if(result.status){
        if(type=="Public") {
          this.examData = result.data.Public;
        }
        else {
          this.examData = result.data.Group;
        }     
      }
      }, (err) => {
        console.log(err);
        });
    }
    else {
      this.rest.getExamsByCategorypublic(formData).subscribe((result) => {   
        if(result.status){
        if(type=="Public") {
          this.examData = result.data.Public;
        }
        else {
          this.examData = result.data.Group;
        }     
      }
      }, (err) => {
        console.log(err);
        });
    }
   
   
  }
  getDataAPI(req){
    this.rest.filterRequest(req).subscribe((result) => {
      if(result.status){
        // this.allMeeting = result.data!=undefined && result.data.length >0 ? result.data :[];
        if(result.status){
          if(result.data.length > 0){
            
              const now = Date.now();
              const futureDates = result.data.filter(date => {
                return date.ScheduleDate && new Date(date.ScheduleDate).setHours(0, 0, 0, 0) >=new Date().setHours(0, 0, 0, 0);
              });
              var sortDate =futureDates.sort(function(a,b){return new Date(a.ScheduleDate).getTime() - new Date(b.ScheduleDate).getTime()});
              this.requestedDataList = sortDate
              console.log(this.requestedDataList);
              // if(this.requestedDataList[0] !=undefined){
              //   var profileName = this.requestedDataList[0].teacherID.profileName;
              //   profileName = profileName.replace(/ /gi, "").replace(/-/gi, "_");
              //   this.inviteUrl =  this.frontwebsite+"invite/"+profileName+"-"+this.requestedDataList[0].inviteurl;
  
              // }
  
          }else{
            this.requestedDataList = [];
          }
          
        }


   
      }
      
    }, (err) => {
    console.log(err);
    });
  }
  changemoduleforsession(type){
    var req = {
      gradeID:this.exploreForm.value.gradeID == 'all' ? '' : this.exploreForm.value.gradeID,
      subjectID:this.exploreForm.value.subjectID == 'all' ? '' : this.exploreForm.value.subjectID,
      languageID:this.exploreForm.value.languageID == 'all' ? '' : this.exploreForm.value.languageID,
      categoryID:this.exploreForm.value.categoryID == 'all' ? '' : this.exploreForm.value.categoryID,
      boardID:this.exploreForm.value.boardID == 'all' ? '' : this.exploreForm.value.boardID,
      meetingType:type,
      groupname:type == 'Group' ? this.groupName : '',
    }
    this.exploreForm.patchValue({ meetingType: type})
    this.getDataAPI(req)
      // this.rest.filterMeeting(req).subscribe((result) => {
      //   if(result.status){
      //     this.allMeeting = result.data!=undefined && result.data.length >0 ? result.data :[];
      //   }
        
      // }, (err) => {
      // console.log(err);
      // });
  }

  changemoduleforcourse(type){
    const Typeofuser = localStorage.getItem('userType');
    const webToken = localStorage.getItem('webtoken');
    var formData = {};
    formData["gradeID"]=this.exploreForm.value.gradeID;
    formData["subjectID"]=this.exploreForm.value.subjectID;
    formData["languageID"]=this.exploreForm.value.languageID;
    formData["categoryID"]=this.exploreForm.value.categoryID;
    formData["boardID"]=this.exploreForm.value.boardID;
    formData["userType"]=Typeofuser.toLowerCase();
    
    this.isLogin = localStorage.getItem('isLoggedin');
    if(this.isLogin=="true") {    
      this.rest. getCoursesByCategory(webToken,formData).subscribe((result) => {  
        if(result.status){
        if(type=="Public") {
          this.courseData = result.data.Public;
        }
        else {
          this.courseData = result.data.Group;
        }
      }
      }, (err) => {
        console.log(err);
        });
    }
    else {
      this.rest. getCoursesByCategorypublic(formData).subscribe((result) => {  
        if(result.status){
        if(type=="Public") {
          this.courseData = result.data.Public;
        }
        else {
          this.courseData = result.data.Group;
        }
      }
      }, (err) => {
        console.log(err);
        });
    }
  }

  changemoduleforexam(type){
    const Typeofuser = localStorage.getItem('userType');
    const webToken = localStorage.getItem('webtoken');
    var formData = {};
    formData["gradeID"]=this.exploreForm.value.gradeID;
    formData["subjectID"]=this.exploreForm.value.subjectID;
    formData["languageID"]=this.exploreForm.value.languageID;
    formData["categoryID"]=this.exploreForm.value.categoryID;
    formData["boardID"]=this.exploreForm.value.boardID;
    formData["userType"]=Typeofuser.toLowerCase();
    
    this.isLogin = localStorage.getItem('isLoggedin');
    if(this.isLogin=="true") {    
      this.rest. getExamsByCategory(webToken,formData).subscribe((result) => {  
        if(result.status){
        if(type=="Public") {
          this.examData = result.data.Public;
        }
        else {
          this.examData = result.data.Group;
        }
      }
      }, (err) => {
        console.log(err);
        });
    }
    else {
      this.rest. getExamsByCategorypublic(formData).subscribe((result) => {  
        if(result.status){
        if(type=="Public") {
          this.examData = result.data.Public;
        }
        else {
          this.examData = result.data.Group;
        }
      }
      }, (err) => {
        console.log(err);
        });
    }
  }
  getMeetingAll(){
    this.rest.getMeetingAll().subscribe((result) => {
      this.allMeeting = result.data;
    }, (err) => {
    console.log(err);
    });
  }

  getcoursecategory() {
    this.rest.getCourseCategoryHeader().subscribe((result) => {
      this.coursecategorylist = result.data;
      var urlArray = window.location.href.split('/');
      this.coursecategorylist.forEach(function(val,key){
      })
    }, (err) => {
    console.log(err);
    });
  }

  getboardfield(){
    this.rest.boardfield().subscribe((result) => {
      this.courseboardlist = result.data;
    }, (err) => {
    console.log(err);
    });
  }

  getsubjectfield(){
    this.rest.subjectfield().subscribe((result) => {
      this.coursesubjectlist = result.data;
    }, (err) => {
    console.log(err);
    });
  }

  getlanguage(){
    this.rest.languagefield().subscribe((result) => {
      this.languageapi = result.data;
    }, (err) => {
    console.log(err);
    });
  }

  detailsRequest(data,pagename){
    if(data.classGroup){
     this.router.navigate(['/automation' + "/" + data.meetingID], { state: data });
    }else{
      data.pagename = pagename;
      this.router.navigate(['/teacher/request'+"/"+data.meetingID], { state:data });
    }
  }

  getgrade(){
    this.rest.grade().subscribe((result) => {
      this.gradeapi = result.data;
    }, (err) => {
    console.log(err);
    });
  }
  selectChange (event: any) {
    this.selectedValue = event.target.options[event.target.selectedIndex].text; 
    var reqForm =  this.exploreForm.value;
    if(reqForm.subjectID == 'all'){
      reqForm.subjectID = ''
    }
    this.courseData=[];
    this.allMeeting=[];
    this.filterData(reqForm);
    // this.coursefilterData(reqForm);
   }
   detailsMeeting(data,pagename){
    data.pagename = pagename;
    var jsonStr= JSON.stringify(data)
    localStorage.setItem('livedetails',jsonStr);
    this.router.navigate(['/enroll/'+"/"+data.meetingID], { state:data });
  }

  coursedetails(data) {
    console.log(data);
    this.router.navigateByUrl('courses/'+data.categoryID.categoryName+"/"+data._id);
  }

  Examdetails(data) {
    console.log(data);
    this.router.navigateByUrl('exams/'+data.categoryID.categoryName+"/"+data._id);
  }
}
