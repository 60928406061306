<div >
    <div *ngIf="isteacher">
            <app-header-style-one-teacher></app-header-style-one-teacher>
        </div>

        <div *ngIf="!isteacher">
            <app-header-style-one></app-header-style-one>
        </div>
</div>
<div class="containerBg" style="padding-bottom: 5%">
<div class="container" *ngIf="categorydetails.length!=0">
  
    <div class="row" style="background-color: #f9f8f85e;padding: 3% 0px 10%;border-radius: 20px;">
            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
            <span style="font-weight: 600;color:#000000;font-size: 16px;"> {{categoryname}} > {{modulename}}</span>
    </div>
           
            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12" >
                    <h5  style="font-weight: 700;color: #000000;font-size: 25px;padding-top: 3%;padding-bottom: 3%">{{categorydetails.courseName}}</h5>
                </div>
       
        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                <h6 style="color:#000000;">{{categorydetails.courseHeading}}</h6>
        </div>

    </div>


    <div class="row">
        <h5 style=" font-size: 24px;padding-top:2%"> What You will Learn</h5>
        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                <span style="color: black;font-size: 16px;"> {{categorydetails.whatYouWillLearn}}</span>
        </div>
    </div>

    <div class="row">
            <h5 style=" font-size: 24px;padding-top:2%"> Requirements</h5>
            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                    <span style="color: black;font-size: 16px;"> {{categorydetails.requirements}}</span>
            </div>
        </div>

        <div class="row">
                <h5 style="font-size: 24px;padding-top:2%"> Description</h5>
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                        <span style="color: black;font-size: 16px;"> {{categorydetails.description}}</span>
                </div>
            </div>

        <div class="row">
                <h5 style=" font-size: 24px;padding-top:2%"> Tests</h5>
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12" style="display: flex;">
                        <span style=" color: black;font-size: 16px;"> Take Tests to see where you stand.</span>
                                    
                </div>
                <div class="nav-item pr-2  mt-2" style="cursor: pointer;">
                  <a class="default-btn" (click)="practiceTest()" >
                      Practice Test
                  </a>
              </div>
                <div class="nav-item pr-2  mt-2" style="cursor: pointer;">
                  <a class="default-btn" (click)="courseTest()">
                      Completed Test
                  </a>
              </div>
            </div>

            


            <div class="row">
                    <h5 style=" font-size: 24px;padding-top:2%"> Topics</h5>     
                    
                    <div class="col-xs-12 col-md-12 col-sm-12 col-lg-12" style="padding-top: 2%">
                            <div *ngFor="let item of categorydetails.topicList;let i = index;">
                                    <button class="accordion" (click)="toggleAccordian($event, i)" [id]=item.topicID._id> {{item.topicID.topicName}} </button>
                                    <div class="panel" hide="!item.isActive">                                 
                                      <ul class="nav">
                                        <li class="nav-item pr-2  mt-2">
                                          <button class="default-btn" (click)="liveClass(item.topicID.courseID,item.topicID._id)" >
                                        Live Classes
                                          </button>

                                          <br>
                                          <p></p>
                                     </li>
                                     <li class="nav-item pr-2  mt-2" style="cursor: pointer;">
                                      <a class="default-btn" (click)="test(item.topicID.courseID, item.topicID._id)">
                                        buddi Tests
                                     </a>
                                 </li>
                                 <li class="nav-item pr-2  mt-2" style="cursor: pointer;">
                                      <a class="default-btn" (click)="groupclasses(item.topicID.courseID, item.topicID._id)">
                                         Study Hall
                                     </a>
                                 </li>
                                        <li class="nav-item pr-2  mt-2" style="cursor: pointer;">
                                          <a class="default-btn" (click)="videoclasses(item.topicID.courseID, item.topicID._id)">
                                             Video Lessons
                                         </a>
                                     </li>
                                     <li class="nav-item pr-2  mt-2" style="cursor: pointer;">
                                          <a class="default-btn" (click)="study(item.topicID.courseID, item.topicID._id)">
                                             Smart Material
                                         </a>
                                     </li>
                                  
                                      </ul>
                                    </div>
                                  </div>
                    </div>

                </div>
<!-- <div class="row">
    <div class="col-lg-12" *ngIf="isvideo">
        <p>Checking</p>
    </div>
</div> -->
                

</div>
</div>
<div class="container  mt-3" *ngIf="isvideo">

    <h3 (click)="redirect()" style="color:var(--mainColor);cursor: pointer" class="col-lg-12" >
        Video Lessons  <span class="more-text" *ngIf="videoList.length!=0">more</span>
      </h3>
      <div class="container-fluid row" style="cursor: pointer" *ngIf="videoList.length !=0">
        <div class="col-lg-3 col-md-6 videolists" *ngFor="let slide of videoList">
          <div class="single-courses-box without-box-shadow mb-30">
            <div class="courses-image">
              <img style="width:100%" (click)="videodetails(slide)" style="width: 250px;height: 175px;" [src]="slide.thumbnail ? slide.thumbnail : 'assets/img/videocat-thumb-1.jpg'" alt="" width="100%">
            </div>
            <div class="courses-content">
              <div class="course-author d-flex align-items-center" *ngIf="slide.teacherID !=undefined">
                <img
                  [src]="slide.teacherID!=undefined ? 'assets/img/user1.jpg' :'assets/img/user1.jpg'"
                  class="rounded-circle mr-2" alt="image">
                <span *ngIf="slide.teacherID!=undefined">
                  <b>{{slide.teacherID.profileName}}</b>
                </span>
              </div>
              <h3 class="text-center">
                <a (click)="videodetails(slide)" class="d-inline-block">
                  <span class="text-break" *ngIf="slide.topicID!=undefined">{{slide.topicID.moduleName}}</span>
                   <span class="text-break" *ngIf="slide.topicID!=undefined">
                    <br>{{slide.topicID.topicName}}</span>
                </a>
              </h3>
            </div>
            <!-- <list5 [slide]="slide"></list5> -->
            <div *ngIf="slide.categoryID!=undefined ">
              {{slide.categoryID ? slide.categoryID.categoryName : '' }} , {{slide.courseID ? slide.courseID.courseName : '' }} ,
              {{slide.boardID ? slide.boardID.boardName : '' }}
            </div>
            <div  *ngIf="slide.languageID!=undefined ">
                {{slide.languageID ? slide.languageID.languageName : '' }}
              <!-- {{slide.languageID ? slide.topicID.subjectID.subjectName : '' }} -->
              
            </div>
            <div class="courses-box-footer">
                <i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{slide.price ? slide.price : '0'}}
            </div>

          </div>
        </div>
      </div>
      <div style="color:var(--mainColor)" class="col-lg-12" *ngIf="(videoList.length ==0)">
        No Video(s) Available.
      </div>
    
    
      </div>

      <div class="container  mt-3" *ngIf="isstudymaterial">

        <h3 (click)="redirect()" style="color:var(--mainColor);cursor: pointer" class="col-lg-12" >
          Smart Material  <span class="more-text" *ngIf="smart.length!=0">more</span>
          </h3>
          <div class="container-fluid row" style="cursor: pointer" *ngIf="smart.length !=0">
            <div class="col-lg-3 col-md-6 videolists" *ngFor="let slide of smart">
              <div class="single-courses-box without-box-shadow mb-30">
                <div class="courses-image">
                  <img style="width:100%" (click)="smartdetails(slide)" style="width: 250px;height: 175px;" [src]="slide.thumbnail ? slide.thumbnail : 'assets/img/videocat-thumb-1.jpg'" alt="" width="100%">
                </div>
                <div class="courses-content">
                  <div class="course-author d-flex align-items-center" *ngIf="slide.teacherID !=undefined">
                    <img
                      [src]="slide.teacherID!=undefined ? 'assets/img/user1.jpg' :'assets/img/user1.jpg'"
                      class="rounded-circle mr-2" alt="image">
                    <span *ngIf="slide.teacherID!=undefined">
                      <b>{{slide.teacherID.profileName}}</b>
                    </span>
                  </div>
                  <h3 class="text-center">
                    <a (click)="videodetails(slide)" class="d-inline-block">
                      <span class="text-break" *ngIf="slide.moduleID!=undefined">{{slide.moduleID.moduleName}}</span>
                       <span class="text-break" *ngIf="slide.topicID!=undefined">
                        <br>{{slide.topicID.topicName}}</span>
                    </a>
                  </h3>
                </div>
                <!-- <list5 [slide]="slide"></list5> -->
                <div *ngIf="slide.categoryID!=undefined ">
                  {{slide.categoryID ? slide.categoryID.categoryName : '' }} , {{slide.courseID ? slide.courseID.courseName : '' }} ,
                  {{slide.boardID ? slide.boardID.boardName : '' }}
                </div>
                <div  *ngIf="slide.languageID!=undefined ">
                    {{slide.languageID ? slide.languageID.languageName : '' }}
                  <!-- {{slide.languageID ? slide.topicID.subjectID.subjectName : '' }} -->
                  
                </div>
                <div class="courses-box-footer">
                    <i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{slide.price ? slide.price : '0'}}
                </div>
    
              </div>
            </div>
          </div>
          <div style="color: #000000;" class="col-lg-12" *ngIf="(smart.length ==0)">
            No Smart Material Available.
          </div>
        
        
          </div>

          <!-- <div class="container  mt-3" *ngIf="isvideo">

            <h3 (click)="redirect()" style="color:var(--mainColor);cursor: pointer" class="col-lg-12" >
               Tests   <span class="more-text" *ngIf="videoList.length!=0">more</span>
              </h3>
              <div class="container-fluid row" style="cursor: pointer" *ngIf="videoList.length !=0">
                <div class="col-lg-3 col-md-6 videolists" *ngFor="let slide of videoList">
                  <div class="single-courses-box without-box-shadow mb-30">
                    <div class="courses-image">
                      <img style="width:100%" (click)="videodetails(slide)" style="width: 250px;height: 175px;" [src]="slide.thumbnail ? slide.thumbnail : 'assets/img/videocat-thumb-1.jpg'" alt="" width="100%">
                    </div>
                    <div class="courses-content">
                      <div class="course-author d-flex align-items-center" *ngIf="slide.teacherID !=undefined">
                        <img
                          [src]="slide.teacherID!=undefined ? 'assets/img/user1.jpg' :'assets/img/user1.jpg'"
                          class="rounded-circle mr-2" alt="image">
                        <span *ngIf="slide.teacherID!=undefined">
                          <b>{{slide.teacherID.profileName}}</b>
                        </span>
                      </div>
                      <h3 class="text-center">
                        <a (click)="videodetails(slide)" class="d-inline-block">
                          <span class="text-break" *ngIf="slide.topicID!=undefined">{{slide.topicID.moduleName}}</span>
                           <span class="text-break" *ngIf="slide.topicID!=undefined">
                            <br>{{slide.topicID.topicName}}</span>
                        </a>
                      </h3>
                    </div>
                 
                    <div *ngIf="slide.categoryID!=undefined ">
                      {{slide.categoryID ? slide.categoryID.categoryName : '' }} , {{slide.courseID ? slide.courseID.courseName : '' }} ,
                      {{slide.boardID ? slide.boardID.boardName : '' }}
                    </div>
                    <div  *ngIf="slide.languageID!=undefined ">
                        {{slide.languageID ? slide.languageID.languageName : '' }}
                    
                    </div>
                    <div class="courses-box-footer">
                        <i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{slide.price ? slide.price : '0'}}
                    </div>
        
                  </div>
                </div>
              </div>
              <div style="color:var(--mainColor)" class="col-lg-12" *ngIf="(videoList.length ==0)">
                No Video(s) Available.
              </div>
            
            
              </div> -->


          <!--------------- Start of Student Test Code --------------->
    
              <div class="container  mt-3" *ngIf="istest">
    
                <h3 (click)="redirect()" style="color:var(--mainColor);cursor: pointer" class="col-lg-12" >
                   Tests   <span class="more-text" *ngIf="testList.length!=0">more</span>
                  </h3>
                  <div class="container-fluid row" style="cursor: pointer" *ngIf="testList.length !=0">
                    <div class="col-lg-3 col-md-6 testList" *ngFor="let slide of testList">
                      <div class="single-courses-box without-box-shadow mb-30">
                        <div class="courses-image">
                          <img style="width:100%" (click)="testdetails(slide)" style="width: 250px;height: 175px;" [src]="slide.thumbnail ? slide.thumbnail : 'assets/img/videocat-thumb-1.jpg'" alt="" width="100%">
                        </div>
                        <div class="courses-content">
                          <div class="course-author d-flex align-items-center" *ngIf="slide.teacherID !=undefined">
                            <img
                              [src]="slide.teacherID!=undefined ? 'assets/img/user1.jpg' :'assets/img/user1.jpg'"
                              class="rounded-circle mr-2" alt="image">
                            <span *ngIf="slide.teacherID!=undefined">
                              <b>{{slide.teacherID.profileName}}</b>
                            </span>
                          </div>
                          <h3 class="text-center">
                            <a (click)="testdetails(slide)" class="d-inline-block">
                              <span class="text-break" *ngIf="slide.topicID!=undefined">{{slide.testName}}</span>
                               <span class="text-break" *ngIf="slide.topicID!=undefined">
                                <br>{{slide.topicID.topicName}}</span>
                            </a>
                          </h3>
                        </div>
                        <!-- <list5 [slide]="slide"></list5> -->
                        <div *ngIf="slide.categoryID!=undefined ">
                          {{slide.categoryID ? slide.categoryID.categoryName : '' }},
                          {{slide.boardID ? slide.boardID.boardName : '' }}
                        </div>
                        <div  *ngIf="slide.languageID!=undefined ">
                            {{slide.languageID ? slide.languageID.languageName : '' }}
                          <!-- {{slide.languageID ? slide.topicID.subjectID.subjectName : '' }} -->
                          
                        </div>
                        <div class="courses-box-footer">
                            <i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{slide.price ? slide.price : '0'}}
                        </div>
            
                      </div>
                    </div>
                  </div>
                  <div style="color:var(--mainColor)" class="col-lg-12" *ngIf="(testList.length ==0)">
                    No Test(s) Available.
                  </div>
                
                
                  </div>

            <!----------End of Student Test List Code ------------->
