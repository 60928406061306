<div class="container-fluid row">
  <!-- <div class="col-lg-12  p-3 radius " style="background-color: #FFFFFF;    border-bottom: 1px solid #ebe2e2; "> -->
    <div class="col-lg-12  p-3 radius " style="background-color: #FFFFFF;">
    <!-- Below code for tab like summary and subject (science,maths, etc..) Feature use -->
    <!-- Changed by Madhan Kumar -->

    <!-- <ul class="nav nav-tabs" id="myTab" role="tablist"  >
     <li class="nav-item" >
          <a class="nav-link active" id="summary-tab" data-toggle="tab" href="#summary" role="tab" aria-controls="summary" aria-selected="true">Summary</a>
      </li>
      <li class="nav-item" >
          <a class="nav-link " id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">{{data.studentDetails.subjects.subjectName}}</a>
      </li>
    
  </ul> -->
    <div class="tab-content dashboard_bg" id="myTabContent">

      <div class="tab-pane fade show " id="home" role="tabpanel" aria-labelledby="home-tab">
        <app-chapter-topic [dataChapter]="data"></app-chapter-topic>
      </div>
    
      <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab"> <br>
        <!-- Profile Details -->
        <div *ngIf="profiletype=='student'">
          <app-student-profile></app-student-profile>
        </div>
        <div *ngIf="profiletype=='teacher'">
          <app-my-dashboard-page></app-my-dashboard-page>
        </div>
      
      </div>
      <!-- <div class="tab-pane fade show active" id="learning" role="tabpanel" aria-labelledby="learning-tab"> <br>
        <app-list-mylearning></app-list-mylearning></div> -->
        <div class="tab-pane fade" id="completedcourses" role="tabpanel" aria-labelledby="completed-courses-tab"> <br>
          <app-completed-classes></app-completed-classes>
          </div>
          <div class="tab-pane fade" id="upcomingcourses" role="tabpanel" aria-labelledby="upcoming-courses-tab"> <br>
            <app-upcomingclass></app-upcomingclass>
            </div>
            <!-- <div class="tab-pane fade show active" *ngIf="profiletype=='teacher'" id="instituteload" role="tabpanel"  aria-labelledby="upcoming-tab"> <br>  
              <app-institute-onload></app-institute-onload>
              </div> -->
            <div class="tab-pane fade show active" id="upcoming" role="tabpanel"  aria-labelledby="upcoming-tab"> <br>  
              <!-- <div (click)="up()">upcoming</div> -->
              <app-upcomming></app-upcomming>
              </div>
            <div class="test tab-pane fade  " id="upcomingLiveClass" role="tabpanel" aria-labelledby="liveclass-tab"> <br>
              <app-upcoming-live-class></app-upcoming-live-class>
              </div>
            <div class="test tab-pane fade" id="upcomingcoursesStudyhall" role="tabpanel" aria-labelledby="studyhall-tab"> <br>
              <app-upcoming-studyhall></app-upcoming-studyhall>
              </div>
              <div class="tab-pane fade " id="upcomingcoursesRoundtable" role="tabpanel" aria-labelledby="roundtable-tab"> <br>
                <app-upcomingroundtable></app-upcomingroundtable>
                </div>
                <div class="tab-pane fade" id="completed" role="tabpanel" aria-labelledby="completed-tab"> <br>
                  <app-completed></app-completed>
                  </div>
                <div class="test tab-pane fade" id="completedLiveclass" role="tabpanel" aria-labelledby="completed-liveclass-tab"> <br>
                  <app-completed-liveclass-tab></app-completed-liveclass-tab>
                  </div>
                <div class="test tab-pane fade" id="completedStudyhall" role="tabpanel" aria-labelledby="completed-studyhall-tab"> <br>
                  <app-completed-studyhall-tab></app-completed-studyhall-tab>
                  </div>
                  <div class="tab-pane fade " id="completedRoundtable" role="tabpanel" aria-labelledby="completed-roundtable-tab"> <br>
                    <app-completed-roundtable-tab></app-completed-roundtable-tab>
                    </div>
              <div class="tab-pane fade " id="upcomingTests" role="tabpanel" aria-labelledby="tests-tab"> <br>
                <app-upcoming-tests></app-upcoming-tests>
              </div>
              <div class="tab-pane fade " id="upcomingVideos" role="tabpanel" aria-labelledby="videos-tab"> <br>
                <app-upcoming-videos></app-upcoming-videos>
              </div>
              <div class="tab-pane fade " id="upcomingSmartMaterial" role="tabpanel" aria-labelledby="smart-material-tab"> <br>
                <app-upcoming-smart-material></app-upcoming-smart-material>
              </div>
          <div class="tab-pane fade" id="notifications" role="tabpanel" aria-labelledby="notifications-tab"> <br>
            <app-notifications></app-notifications>
            </div>
            <div class="tab-pane fade" id="advancedProfile" role="tabpanel" aria-labelledby="advanced-tab"> <br>
              <div *ngIf="profiletype=='teacher'">
              <app-profile-advanced></app-profile-advanced>
              </div>
              </div>
        <div class="tab-pane fade" id="summary" role="tabpanel" aria-labelledby="summary-tab">
          <app-chartdashboard [data]="data"></app-chartdashboard>
        </div>
      <!-- <div class="tab-pane fade" id="payment" role="tabpanel" aria-labelledby="payment-tab"> <br>
        <app-payment-history></app-payment-history>
        </div> -->
        <div class="tab-pane fade" id="profileshow" role="tabpanel" aria-labelledby="profile-show-tab"> <br>
          <app-profile></app-profile>
          </div>
          <div class="tab-pane fade" id="nft" role="tabpanel" aria-labelledby="nft-tab"> <br>
          <app-asset></app-asset>
            </div>
        <div class="tab-pane fade" id="paymentwallet" role="tabpanel" aria-labelledby="pay-tab"> <br>
          <app-payment></app-payment>
          </div>
          <div class="tab-pane fade" id="setting" role="tabpanel" aria-labelledby="setting-tab"> <br>
            <app-settings></app-settings>
            </div>
        <!-- <div class="tab-pane fade" id="paymentwallet" role="tabpanel" aria-labelledby="pay"> <br>
      <app-coin-payment></app-coin-payment>
          </div> -->

      <!-- <div class="tab-pane fade" id="courses" role="tabpanel" aria-labelledby="courses-tab"> <br>
        <app-list-mylearning></app-list-mylearning>
      </div> -->
    

            <div class="tab-pane fade"  id="teachercourses" role="tabpanel" aria-labelledby="teacher-courses-tab"> <br>
              
                  <!-- <app-teacher-my-learning></app-teacher-my-learning> -->
               
              </div>
           
      <!-- <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">...</div> -->
    </div>
  </div>
</div>