


import { Component, OnInit } from "@angular/core";
import {
    FormBuilder,
    FormGroup,
    FormArray,
    FormControl,
    Validators,
    ValidatorFn,
} from "@angular/forms";
import { Options } from "ng5-slider";
import { ApiService } from "../../../shared/api.service";
import { ToastrManager } from "ng6-toastr-notifications";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { DatePipe } from "@angular/common";
import { Location } from "@angular/common";
const datepipe: DatePipe = new DatePipe('en-US')
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { MediaService } from "../../../shared/media.service";
declare var $: any;
@Component({
  selector: 'app-roundtable-details',
  templateUrl: './roundtable-details.component.html',
  styleUrls: ['./roundtable-details.component.scss']
})
export class RoundtableDetailsComponent implements OnInit {
    isLoggedin = "false";
    public teacherID = "";
    public studentID = "";
    public meetingimage = "";
    public meetingurl = "";
    public meetingsactionText = "";
    public meetingCategorypopup = "";
    public meetingScheduleDatepopup = "";
    public meetingrunningpopup = "";
    public meetingIDpopup = "";
    public meeting_ID = "";
    public meetingname = "";
    public meetingScheduleDate = "";
    public inviteUrl = "";
    public frontwebsite = environment.frontUrl;
    public redirectUrl = "";
    public meetingDuration = "";
    public meetingLanguage = "";
    public meetingCity = "";
    public meetingoccurance = "";
    public meetingdescription = "";
    public livedetails: any = [];
    public profileName = "";
    public meetingId = "";
    public urlArray: any = [];
    public pagename: any = "";
    public tag: any = "";
    public groupName: any;
    public isone2one: boolean = false;
    public isTeacher: boolean = false;
    public isStudent: boolean = false;
    public viewRequest: boolean = false;
    public isrecordPast: boolean = false;
    public packageActive: boolean = false;
    public paymentDetails: any = [];
    public isAlreadyJoined: Boolean = false;
    constructor(
        private api: MediaService,
        private location: Location,
        private my_router: ActivatedRoute,
        public toastr: ToastrManager,
        private formBuilder: FormBuilder,
        private rest: ApiService,
        private router: Router,
        private SpinnerService: NgxSpinnerService,
        private sanitizer: DomSanitizer,
        private datePipe: DatePipe,
        
    ) {}

    ngOnInit() {
        window.scrollTo(0, 0);
        console.log(this.livedetails);
        this.isLoggedin = localStorage.getItem("isLoggedin");
        console.log(this.isLoggedin)
        this.teacherID = localStorage.getItem("StudentID");
        const webToken = localStorage.getItem("webtoken");
        var urlArray = window.location.href.split("/");
        var liveClassID = urlArray[urlArray.length - 1]
        this.isTeacher =
            localStorage.getItem("profileType").toLowerCase() == "teacher";
        this.isStudent =
            localStorage.getItem("profileType").toLowerCase() == "student";

        this.pagename = "LiveClass";
        var request = {}
        request['classID'] = liveClassID;
        this.rest
        .getRoundClassDetails(request, webToken ? webToken : null)
        .subscribe((result) => {
            if (result['status']) {

              this.livedetails = result['data']
              if(this.livedetails.startDateTime){
                this.livedetails.startDateTime =  datepipe.transform(this.livedetails.startDateTime, 'MMM d, y, h:mm a')

                // this.livedetails.startDateTime = new Date(this.livedetails.startDateTime).toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'})
                console.log(result)
              }
              this.isAlreadyJoined = result['data']['isAlreadyJoined']

            }
        });
    }

    backto() {
      this.location.back();
    }

    joinStudyClass() {
      this.SpinnerService.show();
      var profileName = localStorage.getItem('profileName');
      profileName = profileName.replace(/ /gi, "+");
      var meetingId = profileName+"-"+Math.round(Math.random() * 1000000000)
      const webToken = localStorage.getItem("webtoken");
      var request = {}
      request['meetingID'] = meetingId;
      request['classID'] = this.livedetails._id;
      this.rest.getWalletBalance(webToken).subscribe((response) => {
        console.log("material", response)
        if(response.data < this.livedetails.price){
          this.SpinnerService.hide();
          this.toastr.errorToastr("Insufficient balance. Please recharge your wallet.")
       
        }
        else{
          this.SpinnerService.hide();
          this.rest.createRoundtable(request, webToken).subscribe((result) => {
            console.log('createMeeting',result)
            if(result['status']){
              this.SpinnerService.hide();
              this.toastr.successToastr("Successfully joined class")
              this.router.navigate(['/sidenav/upcoming/round-table'])
            }
            else{
              this.SpinnerService.hide();
              this.toastr.errorToastr("Something went wrong. Please try again later")
              console.log(result.error)
            }
          })
        }
      })
      
    }

    redirectToLoginPage(){
      this.router.navigate(['/student/login']);
    }
    editRoundtable(){
      console.log("IN ANGLUAR======",this.livedetails);
  
      // var text = this.livedetails.automation?'automation':'manual'
      this.router.navigate(['/create-roundtable/'+this.livedetails._id]);
    }
    deleteRoundtableID(){
      console.log("IN ANGLUAR",this.livedetails);
      var req={
          updateClassID : this.livedetails._id,
          }
           console.log('id==========================',this.livedetails._id);
           this.SpinnerService.show()
       this.rest.deleteRoundtableID(req).subscribe((result) => {
       this.SpinnerService.hide()
       console.log(result);
        if(result.status){
             this.toastr.successToastr(result.message);          
            this.location.back();
          }else{
             this.toastr.errorToastr(result.message);       
          }
       });
      }
    // viewClassRequests(id){
    //   this.router.navigate(['/roundtable-request-list/' + id])
    // }
    viewClassRequests(id){
      this.router.navigate(['/sidenav/upcoming/round-table'])
    }
}
