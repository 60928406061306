<app-header-style-one-teacher></app-header-style-one-teacher>

<div class="page-title-area page-title-style-three item-bg4 jarallax" data-jarallax='{"speed": 0.3}'>
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a>Home</a></li>
        <li>Request</li>
      </ul>
      <h2>View Our Request</h2>
    </div>
  </div>
</div>
<div class="container  mt-3">
  <h3 style="color: var(--mainColor);">
    Tests
</h3>
  <div class="container">
    <form [formGroup]="exploreForm">
      <div class="row">

        <div class="col-lg-12 row">
          <div class="col-md-2 description" *ngIf="coursecategorylist.length !=0">
            <div class="form-group">
              <label>Category Name</label>
              <select class="form-control" (change)="selectChange($event)" formControlName="categoryID">
                <option *ngFor="let obj of coursecategorylist" [ngValue]="obj._id">{{obj.categoryName}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-2 description" *ngIf="gradeapi.length !=0">
            <div class="form-group">
              <label>Grade</label>
              <select class="form-control" (change)="selectChange($event)" formControlName="gradeID">
                <option value="all">All</option>
                <option *ngFor="let obj of gradeapi" [ngValue]="obj._id">{{obj.gradeName}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-2 description" *ngIf="courseboardlist.length !=0">
            <div class="form-group">
              <label>Board</label>
              <select class="form-control" (change)="selectChange($event)" formControlName="boardID">
                <option value="all">All</option>
                <option *ngFor="let obj of courseboardlist" [ngValue]="obj._id">{{obj.boardName}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-2 description" *ngIf="coursesubjectlist.length !=0">
            <div class="form-group">
              <label>Subject</label>
              <select class="form-control" (change)="selectChange($event)" formControlName="subjectID">
                <option value="all">All</option>
                <option *ngFor="let obj of coursesubjectlist" [ngValue]="obj._id">{{obj.subjectName}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-2 description" *ngIf="languageapi.length !=0">
            <div class="form-group">
              <label>Language</label>
              <select class="form-control" (change)="selectChange($event)" formControlName="languageID">
                <option value="all">All</option>
                <option *ngFor="let obj of languageapi" [ngValue]="obj._id">{{obj.languageName}}</option>
              </select>
            </div>
          </div>

       
        </div>
      </div>

    </form>

  </div>

    <div class="row" *ngIf="isAcceptedtests">
      <div class="col-lg-3 col-md-6"
      *ngFor="let slideun of uncorrecteddata | paginate: { id: 'paginationun',itemsPerPage: countun, currentPage: un }">
      <div class="single-courses-box without-box-shadow mb-30">
          <div class="courses-image">
              <a class="d-block"><img src='assets/img/courses/courses1.jpg' (click)="testdetails(slideun._id)"
                  style="width: 250px;height: 175px;" alt="image"></a>
              <!-- <div class="courses-tag">
              <a  class="d-block">{{slidef.categoryID ? slidef.categoryID.categoryName : null }} ({{slidef.gradeID ? slidef.gradeID.gradeName : null }})</a>
          </div> -->
          </div>
          <div class="courses-content">                                  
              <div class="course-author d-flex align-items-center">
                  <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image">
                  <span>{{slideun.dateTestCompleted |  date :"MMM dd,yyyy, hh:mm aaa"}}</span>
              </div>
              <h3><a (click)="testdetails(slideun._id)"
                      class="d-inline-block">{{slideun.testID ? slideun.testID.testName : 'Not Available'}}</a>
              </h3>

          </div>
          <div class="courses-box-footer">
              {{slideun.subjectString}}

          </div>
      </div>
  </div>

  <div *ngIf="uncorrecteddata?.length < 1">
      No Test(s) Available.
  </div>
  <div class="col-lg-12 col-md-12">
      <div style="float: right;">
          <pagination-controls (pageChange)="un = $event" id="paginationun"></pagination-controls>
      </div>
  </div>
    </div>  


    <div class="row" *ngIf="isReviewedtests">
      <div class="col-lg-3 col-md-6"
      *ngFor="let slidere of revieweddata | paginate: { id: 'paginationreview',itemsPerPage: countreview, currentPage: review }">
      <div class="single-courses-box without-box-shadow mb-30">
          <div class="courses-image">
              <a class="d-block"><img src='assets/img/courses/courses1.jpg' (click)="testdetailsreview(slidere._id)"
                  style="width: 250px;height: 175px;" alt="image"></a>
              <!-- <div class="courses-tag">
              <a  class="d-block">{{slidef.categoryID ? slidef.categoryID.categoryName : null }} ({{slidef.gradeID ? slidef.gradeID.gradeName : null }})</a>
          </div> -->
          </div>
          <div class="courses-content">                                  
              <div class="course-author d-flex align-items-center">
                  <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image">
                  <span>{{slidere.dateTestCompleted |  date :"MMM dd,yyyy, hh:mm aaa"}}</span>
              </div>
              <h3><a (click)="testdetailsreview(slidere._id)"
                      class="d-inline-block">{{slidere.testID ? slidere.testID.testName : 'Not Available'}}</a>
              </h3>

          </div>
          <div class="courses-box-footer">
              {{slidere.subjectString}}

          </div>
      </div>
  </div>

  <div *ngIf="revieweddata?.length < 1">
      No Review Test(s) Available.
  </div>
  <div class="col-lg-12 col-md-12">
      <div style="float: right;">
          <pagination-controls (pageChange)="review = $event" id="paginationreview"></pagination-controls>
      </div>
  </div>
    </div>  
  <div class="container" style="padding-top: 5%;">
  </div>
</div>