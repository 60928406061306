import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Options } from 'ng5-slider';
import { ApiService } from '../../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { Location } from '@angular/common';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
declare var $: any;
@Component({
  selector: 'app-teacher-details',
  templateUrl: './teacher-details.component.html',
  styleUrls: ['./teacher-details.component.scss']
})
export class TeacherDetailsComponent implements OnInit {

  isLoggedin = "false";
  public teacherID = "";
  public studentID = '';
  public meetingimage = "";
  public meetingurl = "";
  public meetingsactionText = "";
  public meetingCategorypopup = "";
  public meetingScheduleDatepopup = "";
  public meetingrunningpopup = "";
  public meetingIDpopup = "";
  public meeting_ID = "";
  public meetingname = "";
  public meetingScheduleDate = "";
  public inviteUrl = "";
  public frontwebsite = environment.frontUrl;
  public redirectUrl = "";
  public meetingDuration = '';
  public meetingLanguage = '';
  public meetingCity = '';
  public meetingoccurance = '';
  public meetingdescription = '';
  public livedetails:any;
  public profileName = '';
  public meetingId='';
  public urlArray:any=[];
  public pagename:any = '';
  public tag:any='';
  public groupName:any;
  public isone2one:boolean = false;
  public viewRequest:boolean = false;
  constructor(private location:Location,private my_router: ActivatedRoute,public toastr: ToastrManager, private formBuilder: FormBuilder, private rest: ApiService, private router: Router,private SpinnerService: NgxSpinnerService,private sanitizer: DomSanitizer,private datePipe: DatePipe) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    console.log(this.livedetails);
    this.isLoggedin = localStorage.getItem('isLoggedin');
    this.teacherID = localStorage.getItem('StudentID');
    const webToken = localStorage.getItem('webtoken');
    var urlArray = window.location.href.split('/');
    this.isone2one = window.location.href.match('one2oneAccept')!=null ? true : false;
    this.viewRequest = window.location.href.match('viewRequest')!=null ? true : false;
    
      this.pagename ="Meeting"
      if(this.isone2one){
        this.rest.getMeetingbyIDRequest(urlArray[urlArray.length-1]).subscribe((result) => {
          var temp = [];
          var urlArray = window.location.href.split('/');
          this.pagename ="Meeting";          
           temp = result.data.filter(function(val){return(val.meetingID == urlArray[5])});          
           if(temp.length ==0){
            this.livedetails = []
           }else{
            var temp1 = [];
            if(temp[0].tag !='' && temp[0].tag != "undefined"){
             temp[0].tag = temp[0].tag
            }else{
             this.tag = ''
            }
            console.log(temp)
           this.livedetails = temp.length >0 ? temp[0]:[];
           if(this.livedetails != undefined && this.livedetails.length !=0 && this.livedetails.studentID && this.livedetails.studentID !=undefined){
            this.profileName = this.livedetails.studentID.profileName.replace(/ /gi, "").replace(/-/gi, "_");
            this.inviteUrl =  this.frontwebsite+"invite/"+this.profileName+"-"+ this.livedetails.inviteurl;
          }
          if(this.livedetails != undefined && this.livedetails.length !=0){    
            this.detailsMeeting( this.livedetails,'student');
          }
    
    
           }
           
        });
      }else{
        this.rest.getMeetingbyID(urlArray[urlArray.length-1]).subscribe((result) => {
          var temp = [];
          var urlArray = window.location.href.split('/');
          this.pagename ="Meeting"
          
           temp = result.data.filter(function(val){return(val.meetingID == urlArray[5])});          
          
           if(temp.length ==0){
            this.livedetails = []
           }else{
            var temp1 = [];
            if(temp[0].tag !='' && temp[0].tag != "undefined"){
             temp[0].tag = temp[0].tag
             // this.tag = temp[0].tag
            }else{
             this.tag = ''
            }
            console.log(temp)
           this.livedetails = temp.length >0 ? temp[0]:[];
           if(this.livedetails != undefined && this.livedetails.length !=0 && this.livedetails.teacherID && this.livedetails.teacherID !=undefined){
            this.profileName = this.livedetails.teacherID.profileName.replace(/ /gi, "").replace(/-/gi, "_");
            this.inviteUrl =  this.frontwebsite+"invite/"+this.profileName+"-"+ this.livedetails.inviteurl;
          }
          // this.SpinnerService.show();
          if(this.livedetails != undefined && this.livedetails.length !=0){
    
            this.detailsMeeting( this.livedetails,'teacher');
          }
          // this.SpinnerService.hide();
    
    
           }
           
        });
      }

  
  }
  backto(){
    this.location.back();
  }
  editMeeting(data){
    this.router.navigate(['/createmeeting',JSON.stringify(data)]);
  }
  detailsMeeting(data,text){
    window.scroll(0,0);
    if(text =='student'){
      this.studentID = localStorage.getItem('StudentID');
      this.meetingname = data.name;
      if(data.teacherID){
      var profileName = data.studentID.profileName;
      profileName = profileName.replace(/ /gi, "").replace(/-/gi, "_");
      this.inviteUrl =  this.frontwebsite+"invite/"+profileName+"-"+data.inviteurl;
    }
    }else{
      this.teacherID = localStorage.getItem('teacherID');
      this.meetingname = data.name;
      if(data.teacherID){
      var profileName = data.teacherID.profileName;
      profileName = profileName.replace(/ /gi, "").replace(/-/gi, "_");
      this.inviteUrl =  this.frontwebsite+"invite/"+profileName+"-"+data.inviteurl;
      }
    }
    
    this.meetingIDpopup = data.meetingID;
    this.meeting_ID = data._id;
    this.meetingrunningpopup = data.running;
    this.meetingScheduleDate = data.ScheduleDate;
    this.meetingDuration = data.Duration ? data.Duration +'Min' : '';
    this.meetingLanguage = data.Languages;
    this.meetingCity = data.city;
    this.meetingoccurance = data.occurance;
    this.meetingdescription = data.meetingdescription;
    var backimage = "";
    this.meetingsactionText = "Join";
  }
  copyMessage(){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.inviteUrl ;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.successToastr("Copied Successfully");
  }
  removeMeeting(data){
    const webToken = localStorage.getItem('webtoken');
    if(confirm("Are you sure to delete ")) {
      // this.SpinnerService.show();
      this.rest.removeMeeting(data,webToken).subscribe((result) => {
        // this.SpinnerService.hide();
        if(result.status){
          this.router.navigate(['/courses-4-columns-style-3']);
        }else{
          this.toastr.errorToastr(result.message);
        }
      });
   }

  }
  meetingsaction(id,action,data){
    const webToken = localStorage.getItem('webtoken');
    if(action == 'login'){
      this.router.navigate(['/'+localStorage.getItem('profileType')+'/login']);
    }else{
      this.rest.meetingAction(id,webToken,action,this.isone2one).subscribe((result) => {
        if(result.status){
          location.href = result.data[0];
        }else{
          if(result.message == 'Meeting Not Started.'){
            this.toastr.successToastr(result.message);
            var req={
              myLifeListDatepick:moment(data.ScheduleDate).format('YYYY-MM-DD'),
              myLifeListEventType: "Meeting",
              myLifeListTimepick:moment(data.ScheduleDate).format('HH:mm:ss'),
              myLifeListTitle:data.meetingCategory.categoryName,
              setReminder: 0,
              todoDescription:data.meetingCategory.categoryName,
              todoEventType: "Meeting",
              meetingType:data.meetingType,
              todoSchedule: data.ScheduleDate,
              userType : 'student',
              studentID : this.livedetails._id,
              todoSetReminder: "",
              meetingImage:data.meetingCategory.image,
              todosetReminderValue: "",
              sessionName:data.name,
              meetingId : data._id,
              sessionId:data.meetingID,
            };

            this.rest.add_myLife_NewData(req,webToken).subscribe((result) => {
              if(result.status){
                this.toastr.successToastr("Class Added to your Calender");
              }else{
                this.toastr.errorToastr(result.message);
              }

            });
           
          }else{
            this.toastr.errorToastr(result.message);
          }
        }
      }, (err) => {
        console.log(err);
      });
    }
  }

}
