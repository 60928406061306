
import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../../shared/api.service";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { DatePipe } from '@angular/common';
const datepipe: DatePipe = new DatePipe('en-US')

@Component({
  selector: 'app-addbudditest-list',
  templateUrl: './addbudditest-list.component.html',
  styleUrls: ['./addbudditest-list.component.scss']
})
export class AddbudditestListComponent implements OnInit {

  constructor(private rest: ApiService, private router: Router) { }

  public testList: any = [];

  public isTeacher: Boolean = false;
  public isStudent: Boolean = false;
  public isLogin: any = 'false';
  public currentPageNumber: number = 1;
  public totalVideoCount: number;
  public totalPageCount: number;
  public currentVideoCount: number;
  term: string;
  public webinarData: any = [];
  isDesc: any = 1;
  column: string = 'remainingClass';
  p: number = 1;
  count: number = 4;

  ngOnInit() {
    const token = localStorage.getItem("webtoken");

    this.isTeacher =
      localStorage.getItem("profileType").toLowerCase() == "teacher";
    this.isStudent =
      localStorage.getItem("profileType").toLowerCase() == "student";

    this.getPurchasedTests();
  }

  getPurchasedTests() {
    const webToken = localStorage.getItem("webtoken");

    this.rest.getPurchasedTests(webToken).subscribe((response) => {
      console.log("test", response)
      if (response.status) {
        this.testList = response.data;
        for (var test of this.testList) {
          if (test.thumbnail && test.thumbnail == "") {
            test.thumbnail = "slide/img/courses/courses1.jpg"
          }
        }
        console.log(this.testList)
      }
    })
  }
  testDetails(data) {
    if(this.isTeacher){
        this.router.navigate(['/teacher-test-details/' + data._id], { state: data });
      }
      else{
        this.router.navigate(['/test-details-student/' + data._id], { state: data });
      }
}
}
