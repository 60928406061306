import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "../../../shared/api.service";
import { ExternalLibraryService } from '../util';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
declare let Razorpay: any;
declare let UPI: any;
@Component({
  selector: 'app-coin-payment',
  templateUrl: './coin-payment.component.html',
  styleUrls: ['./coin-payment.component.scss']
})
export class CoinPaymentComponent implements OnInit {

  public isStudent: boolean = true;
  public isTeacher: boolean = true;
  amount = 0;
  orderID = "";
  paymentID = ";"
  public walletBalance = 0;
  RAZORPAY_OPTIONS: any;
  methodOfPayment = "";
  Percent;
  totalamount;
  afterpricing=true;
  value1="0.00";
  createForm: FormGroup;
  addmoney:FormGroup;
  submitted = false;
  constructor( private razorpayService: ExternalLibraryService,
    private SpinnerService: NgxSpinnerService, 
    private rest: ApiService, 
    private router: Router,  
     public toastr: ToastrManager,
     private formBuilder: FormBuilder,) { }

  ngOnInit() {
    console.log('Calculate process fees',this.Percent);
    this.isStudent = localStorage.getItem('profileType').toLowerCase() == 'student'
    this.isTeacher = localStorage.getItem('profileType').toLowerCase() == 'teacher'
    this.getWalletBalance();
    this.methodOfPayment = 'Razorpay';
    this.razorpayService
    .lazyLoadLibrary('https://checkout.razorpay.com/v1/checkout.js')
    .subscribe();
    this.RAZORPAY_OPTIONS = {
      "key": environment.razorpayKey,
      "amount": "",
      "name": environment.paymentNameHead,
      "order_id": "",
      "description": environment.paymentNameSub,
      "prefill": {
        "name": "",
        "contact": "",
        "method": ""
      },
      "modal": {},
      "theme": {
        "color": "#0096C5"
      }

  };
  this.createForm = this.formBuilder.group({
    TransactionID: ["", Validators.required],
    upiamount: ["", Validators.required],
   });
   this.addmoney = this.formBuilder.group({
    rechargeAmount: ["", Validators.required],
   });
  }

  get f() {
    return this.createForm.controls;
  }
  get d() {
    return this.addmoney.controls;
  }

  handleInput(event: Event) {
    var value1 = (event.target as HTMLInputElement).value;
    this.Percent= parseInt(value1) * (0.02);
    console.log('Calculate process fees',this.Percent);
    this.totalamount=parseInt(value1) + this.Percent;
    console.log('Total amount',this.totalamount)
    this.amount = this.totalamount;
    console.log('purchase amount',this.amount);
  }

  handleInputUPI(event: Event) {
      var value1 = (event.target as HTMLInputElement).value;
      this.Percent= 0;
      console.log('Calculate process fees',this.Percent);
      this.totalamount=parseInt(value1) + this.Percent;
      console.log('Total amount',this.totalamount)
      this.amount = this.totalamount;
      console.log('purchase amount',this.amount);
  }

  initiateOrder() {
    const webToken = localStorage.getItem("webtoken");
    var request = {
      amount:this.amount, 
      methodOfPayment:this.methodOfPayment
    }
    if(this.methodOfPayment == "UPI"){
      let transactionID = (<HTMLInputElement>document.getElementById('TransactionID')).value;
      console.log(transactionID);
      request['transactionID'] = transactionID;

    }
    this.SpinnerService.show()
    this.rest.addMoneyToWallet(webToken, request).subscribe((response) => {
      this.SpinnerService.hide()
      console.log("material", response)
      if(this.methodOfPayment == "Razorpay"){
        this.orderID = response.data.orderId
        this.paymentID = response.data._id
        this.RAZORPAY_OPTIONS['order_id'] = response.data.orderId;
      
        let razorpay = new Razorpay(this.RAZORPAY_OPTIONS)
          razorpay.open();
      } else if(this.methodOfPayment == "UPI"){
          this.toastr.successToastr("UPI Amount Will be Added to Your Currency Wallet within 24 Hours.");
          window.location.href='/sidenav';
      }
    })
  }
  radioOnClick(value){
    console.log(value)
    this.methodOfPayment = value;
   }

  submit(){
    this.submitted = true;
    if (!this.addmoney.valid) {
        const controls = this.addmoney.controls;
        for (const name in controls) {
          if (controls[name].invalid) {
          if (name == "rechargeAmount") {
            this.toastr.errorToastr("Enter the amount is Required");
            return;
          } else {
            this.toastr.errorToastr("Invalid Fields");
            return;
          }
        }
      }
    }
    console.log('Calculate process fees',this.Percent);
    console.log('Total amount',this.totalamount)
    console.log('purchase amount',this.amount);
    this.RAZORPAY_OPTIONS['amount'] = this.amount * 100;
    this.RAZORPAY_OPTIONS['handler'] = this.razorPaySuccessHandler.bind(this);
    this.initiateOrder();
  }
  submit1(){
    this.submitted = true;
        if (!this.createForm.valid) {
            const controls = this.createForm.controls;
            for (const name in controls) {
              if (controls[name].invalid) {
              if (name == "TransactionID") {
                this.toastr.errorToastr("UPI TransactionID is Required");
                return;
              } else if (name == "upiamount") {
                this.toastr.errorToastr("Enter the amount is Required");
                return;
              } else {
                this.toastr.errorToastr("Invalid Fields");
                return;
              }
            }
          }
        }
    console.log('Calculate process fees',this.Percent);
    console.log('Total amount',this.totalamount)
    console.log('purchase amount',this.amount);
    this.RAZORPAY_OPTIONS['amount'] = this.amount * 100;
    this.RAZORPAY_OPTIONS['handler'] = this.razorPaySuccessHandler.bind(this);
    this.initiateOrder();
  }
  

  public razorPaySuccessHandler(response) {
    console.log(response)
    var jsonData = {};
    const webToken = localStorage.getItem("webtoken");
    jsonData["razorpay_order_id"] = this.orderID;
    jsonData["razorpay_payment_id"] = response.razorpay_payment_id;
    jsonData["razorpay_signature"] = response.razorpay_signature;
    jsonData["paymentID"] = this.paymentID;
    jsonData["methodOfPayment"] = this.methodOfPayment;
    this.SpinnerService.show()
    this.rest.completeMoneyToWallet(webToken, jsonData).subscribe((response) => {
      this.SpinnerService.hide()
      this.toastr.successToastr('Recharge Successful')
      window.location.href='/Currency-wallet';
      // this.afterpricing=false;
    })

  }

  getWalletBalance() {
    const webToken = localStorage.getItem("webtoken");
    this.rest.getWalletBalance(webToken).subscribe((response) => {
      console.log("material", response)
      this.walletBalance = response.data
    })
    
  }
}
