import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-buddi-live-redirect',
  templateUrl: './buddi-live-redirect.component.html',
  styleUrls: ['./buddi-live-redirect.component.scss']
})
export class BuddiLiveRedirectComponent implements OnInit {
  public isstudent = false;
  public isteacher = false;

  constructor( private my_router: ActivatedRoute,private router: Router) { }

  ngOnInit(): void {
    var usertype = localStorage.getItem('profileType');
    if (usertype.toLowerCase() == "teacher") {
      this.isteacher = true;
    }
    else {
      this.isstudent = true;
    }
    this.router.navigate([""]);
  }

}
