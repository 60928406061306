<div *ngIf="isstudent">
    <app-header-style-one></app-header-style-one>
</div>
<div *ngIf="isteacher">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>

<!-- <div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Instructor Details</li>
            </ul>
            <h2>Instructor Details</h2>
        </div>
    </div>
</div> -->
  <!-- Below is for Uday Shankar-->
<section  *ngIf="issamuel" class="instructor-details-area pt-100 pb-70">
    <div class="container">
        <div class="instructor-details-desc">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <div class="instructor-details-sidebar">
                        <img src="../../../../assets/img/advisor.jpg" alt="image">

                        <!-- <div class="instructor-details-meta">
                            <ul>
                                <li>
                                    <i class='bx bxs-group'></i>
                                    <span>Follower</span>
                                    <a routerLink="/">102</a>
                                </li>
                                <li>
                                    <i class='bx bx-group'></i>
                                    <span>Following</span>
                                    <a routerLink="/">81</a>
                                </li>
                            </ul>
                        </div> -->
                        <!-- <a  class="default-btn" style="margin-top: 5%;"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Follow</span><i class="bx bx-plus-circle icon-arrow after"></i></a> -->
                        <ul class="social-link" style="margin-top: 5%;">
                            <!-- <li><a href="#" class="d-block facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li> -->
                            <li><a class="d-block facebook" ><i class='bx bxl-facebook'></i></a></li>
                            <li><a class="d-block twitter" ><i class='bx bxl-twitter'></i></a></li>
                            <li><a class="d-block instagram" ><i class='bx bxl-instagram'></i></a></li>
                            <li><a class="d-block linkedin" ><i class='bx bxl-linkedin'></i></a></li>
                            <li><a class="d-block pinterest" ><i class='bx bxl-pinterest-alt'></i></a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-8 col-md-8">
                    <div class="instructor-details">
                        <h3>Prof.Samuel Johnson</h3>
                        <span class="sub-title">Trainer</span>
                        <p>Prof.Samuel Johnson is a Digital coach and Educational Technology trainer. 
                            Working for the VIT-AP  University with VSB - VIT AP School of Business.</p>

                            <p>
                                He has worked in VIT Vellore, VIT Chennai in the area of faculty training and their Digital learning platforms setups. 
                            </p>

                            <p>Presently working as a Faculty in VIT AP School of Business ( VSB) VIT-AP, Also taking care of Student Welfare, Events and Branding activities at VIT-AP Amaravati Campus, Andhra Pradesh. He is a Digital Education Coach, Transforming many educational institutions in Higher Education Universities, Colleges and Schools. He is also consulting e-learning and Blended learning for corporate organizations in their learning and Development. Worked for VIT Vellore with Academic Staff College and VIT Chennai besides coordinating “Learning and Research Cell”, taking care of Faculty Training and their Continuous education programs for the last 12 years with VIT UNIVERSITY. Having worked with Mafoi Academy which is now RANDSTAD for training young faculty members, corporate employees. I AM A WIPRO and Dale Carnegie Certified Master Trainer. A Motivational Speaker and Corporate Trainer.</p>
                      
                        <!-- <div class="instructor-details-info">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <h3>My Education</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Harvard University</span>
                                            Bachelor in Mathematics
                                        </li>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>University of Toronto</span>
                                            Bachelor in English
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <h3>My Experience</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Envato</span>
                                            2000-2010
                                        </li>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>EnvyTheme</span>
                                            2010-2020
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

