import { Component, OnInit, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Options } from 'ng5-slider';
import { ApiService } from '../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { Location } from '@angular/common';
import * as moment from 'moment';
import { MediaService } from '../../../shared/media.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ViewChild } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-videodetails',
  templateUrl: './videodetails.component.html',
  styleUrls: ['./videodetails.component.scss']
})
export class VideodetailsComponent implements OnInit {
  public videoID: any = ''
  constructor(private mediaAPI: MediaService, private location: Location, private activatedRoute: ActivatedRoute, public toastr: ToastrManager, private formBuilder: FormBuilder, private rest: ApiService, private router: Router, private SpinnerService: NgxSpinnerService, private sanitizer: DomSanitizer, private datePipe: DatePipe) { }
  public livedetails: any = [];
  public categoryarrayvalues;
  public subjectarrayvalues;
  public gradearrayvalues;
  isLoggedin = "false";
   public userId : any = '' ;
  public purchase;
  public payment;
  public teacherID;
  clicked = false;
  formData;
  amount: any;
  videoname: any;
  public isTeacher: any = false;
  isItemAddedToCart: boolean = false;


  ngOnInit(): void {
    this.userId=  localStorage.getItem('userId');
    const webToken = localStorage.getItem('webtoken');
    this.isLoggedin = localStorage.getItem('isLoggedin');
    this.payment = localStorage.getItem('paymentID')
    this.videoID = this.activatedRoute.snapshot.params.id
    this.isTeacher = localStorage.getItem("profileType").toLowerCase() == "teacher";
    this.teacherID = localStorage.getItem('teacherID');
    this.getvideoDetails();
    this.isVideoAddedToCart();
  }
  getvideoDetails() {
    const webToken = localStorage.getItem('webtoken');
    this.rest.getVideoDetails(webToken, { videoID: this.videoID }).subscribe((response) => {
      console.log('dfdfdfdf', response)
      if (response.status) {
        // console.log(response.data.teacherID._id)
        this.livedetails = response.data;
        console.log(this.livedetails)
        this.amount = response.data.price;
        this.videoname = response.data.videoName;
        this.purchase = response.data.isVideoPurchased
        console.log('final', this.livedetails)
        let categoryarray: any = [];
        let gradearray: any = [];
        let subjectarray: any = [];
        if (this.livedetails.categoryID.length > 0) {
          for (var i = 0; i < this.livedetails.categoryID.length; i++) {
            categoryarray.push(this.livedetails.categoryID[i].categoryName);
          }
          this.categoryarrayvalues = categoryarray.toString();
        }

        // if (this.livedetails.subjectID.length > 0) {
        //   for (var i = 0; i < this.livedetails.subjectID.length; i++) {
        //     subjectarray.push(this.livedetails.subjectID[i].subjectName);
        //   }
        //   this.subjectarrayvalues = subjectarray.toString();
        // }

        // if (this.livedetails.gradeID.length > 0) {
        //   for (var i = 0; i < this.livedetails.gradeID.length; i++) {
        //     gradearray.push(this.livedetails.gradeID[i].gradeName);
        //   }
        //   this.gradearrayvalues = gradearray.toString();
        // }
        // if(this.livedetails.length!=0 && this.livedetails.tag !=''){
        //   this.tagArray = this.livedetails.tag.split(',')
        // }
        // this.reviewGet(this.livedetails._id);
      }
    });
  }
  requestAccept() {
    alert(this.videoID)
    const webToken = localStorage.getItem('webtoken');
    this.formData = new FormData();
    this.formData.append("paymentID", "60c5fbda392adabe8ed07b7c");
    this.formData.append("itemID", this.videoID);
    this.formData.append("itemType", "Video");
    var req = {
      paymentID: "60c5fbda392adabe8ed07b7c",
      itemID: this.videoID,
      itemType: "Video"
    }
    localStorage.setItem('paymentID', "60c5fbda392adabe8ed07b7c");
    this.rest.paymentTest(req, webToken).subscribe((result) => {

      // console.log('result of explore', result)
      // this.SpinnerService.hide();
      if (result.status) {
        alert(result.message)
        // this.videoList = result.data.splice(0,4);
        // console.log('list video',this.videoList)

      } else {
        // this.videoList = [];
      }
    }, (err) => {
      console.log(err);
    });

  }
  addcart() {
    var tempcart = [];
    var cart = {

      "itemID": this.videoID,
      "itemName": this.videoname,
      "itemType": "Video",
      "price": this.amount

    }
    tempcart.push(cart);
    tempcart = tempcart.concat(JSON.parse(localStorage.getItem('cartItems') || '[]'));
    localStorage.setItem('cartItems', JSON.stringify(tempcart));

    this.isItemAddedToCart = true;
    document.getElementById('cartSize').innerText = "Cart(" + tempcart.length.toString() + ")"
    alert("Video Added To Cart")


  }

  isVideoAddedToCart() {
    let tempCart = JSON.parse(localStorage.getItem('cartItems') || '[]');
    for (var item of tempCart) {
      console.log(item);
      if (item.itemID == this.videoID && item.itemType == "Video") {
        this.isItemAddedToCart = true;
      }
    }
  }
  payments() {
    var buyNowItem = {};
    var tempVideoID = this.videoID;
    buyNowItem = {
      "itemID": this.videoID,
      "itemName": this.videoname,
      "itemType": "Video",
      "price": this.amount
    };
    localStorage.setItem('buyNowItem', JSON.stringify(buyNowItem))
    if(this.isItemAddedToCart){
      let tempCart = JSON.parse(localStorage.getItem('cartItems') || '[]');
      var newCart = tempCart.filter(function(item){
        if(item.itemID == tempVideoID && item.itemType == "Video"){
          return false;
        }
        else{
          return true;
        }
      })
      localStorage.setItem('cartItems', JSON.stringify(newCart))
    }
    this.router.navigateByUrl('/payment')
  }
  login() {
    var data = {
      isredirect: true
    }
    this.router.navigate(['/student/login'], { state: data });
  }
  backto() {
    this.location.back();
  }
  toggleVideo(event: any) {
    // var data={ itemId : this.reviewForm.value.itemId}
    //  this.rest.updateviewCount(data,localStorage.getItem('webtoken')).subscribe((response) => {

    //    if(response.status){
    //      this.reviewGet(this.livedetails._id);
    //    }
    //  });

    var reqData = {
      courseID: this.livedetails.courseID._id,
      topicID: this.livedetails.topicID._id,
      methodOfLearning: "videoclass"
    };
    if(!this.clicked){
      console.log(reqData);
      this.rest.addCourseCompletion(localStorage.getItem('webtoken'), reqData).subscribe((response) => {
        console.log(response);
        this.clicked = true;
       });
    }
    
  }
  editvideo(){
    console.log('this.livedetails',this.livedetails)
    this.router.navigate(['/teacher-video-upload/'+this.livedetails._id]);
  }

  deletevideo(){
    this.SpinnerService.show()
    var req={
      videoID : this.livedetails._id,
      }
      
    this.mediaAPI.deleteuploadedVideo(req).subscribe((result) => {
         this.SpinnerService.hide()
      if(result.status){
        this.toastr.successToastr(result.message);          
        this.location.back();
      }else{
        this.toastr.errorToastr(result.message);          
      }
    });
  }
  removeFromList(data){
    var req={
    "libraryType":"videoId",
    "libraryId":data._id
    }
    const webToken = localStorage.getItem('webtoken');
    this.rest.removeList(req,webToken).subscribe((result) => {
      if(result){
        this.toastr.successToastr(result.message);          
        var data = {
          message:'Removed'
        }  
        this.router.navigate(['/success/library'], { state: data });    
      }else{
        this.toastr.errorToastr(result.message);          
      }
    })    
  }
  addToDashboard(data){
    const webToken = localStorage.getItem('webtoken');
    var request = {};
    request['videoID'] = data._id
    this.SpinnerService.show();
    this.rest.getWalletBalance(webToken).subscribe((response) => {
      console.log("material", response)
      if(response.data < this.livedetails.price){
        this.SpinnerService.hide();
        this.toastr.errorToastr("Insufficient balance. Please recharge your wallet.")
      }
      else{
        this.SpinnerService.hide();
        this.rest.addVideoToPurchased(webToken, request).subscribe((result) => {
          if(result.status){
            this.toastr.successToastr("Video Added to your dashboard");
            this.router.navigate(['/sidenav']);
          }
          else{
            this.toastr.errorToastr("Something went wrong. Please try again later")
            console.log(result)
          }
        }) 
      }
    })
    
  }

  // redirectToEdit(data){
  //   this.router.navigate(['/teacher-video-upload/' + data._id])
  // }
}
