import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Options } from 'ng5-slider';
import { ApiService } from '../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { Location } from '@angular/common';
declare var $: any;
import * as moment from 'moment';

@Component({
  selector: 'app-course-details',
  templateUrl: './course-details.component.html',
  styleUrls: ['./course-details.component.scss']
})
export class CourseDetailsComponent implements OnInit {

  courseSideID: any;  //course id of the course on the course.buddiforlife.com side
  courseID: any;
  groupname1: string;
  formdata;
  courseDetails: any;
  startDate: string;
  formData;
  typeuser: string;
  lastupdateddate: string;
  endDate: string;
  public pagename: any = '';
  isUserLoggedIn: string;
  isenroll: string;
  islogin: string;
  public isstudent = false;
  public isteacher = false;
  public ispublish = false;
  public courseURL = environment.COURSE_URL;
  public iscreatedby = false;

  constructor(private location: Location, private my_router: ActivatedRoute, public toastr: ToastrManager, private formBuilder: FormBuilder, private rest: ApiService, private router: Router, private SpinnerService: NgxSpinnerService, private sanitizer: DomSanitizer, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.courseID = this.my_router.snapshot.params['id'];
    var usertype = localStorage.getItem('profileType');
    this.typeuser = usertype.toLowerCase();
    const userType = localStorage.getItem('userType');

    var courseformData = {};
    if (usertype.toLowerCase() == "teacher") {
      this.isenroll = "false";
      this.isteacher = true;
      courseformData["userType"] = "teacher";
    }
    else {
      this.isstudent = true;
      courseformData["userType"] = "student";
    }

    courseformData["id"] = this.courseID;
    const webToken = localStorage.getItem('webtoken');
    this.SpinnerService.show();
    if (webToken == null) {
      this.rest.getCourseDetailswithoutauth(webToken, courseformData).subscribe((result) => {
        this.SpinnerService.hide();
        this.courseDetails = result.data;
        this.courseSideID = this.courseDetails.courseID;
        var curdata = result.data.startDate;
        const currentFormatDate = moment(curdata).subtract(330, "minutes").toDate();
        this.startDate = moment(currentFormatDate).format('DD-MM-YYYY');
        var curdata = result.data.endDate;
        const currentFormtDate = moment(curdata).subtract(330, "minutes").toDate();
        this.endDate = moment(currentFormtDate).format('DD-MM-YYYY');
        var lastupdateddata = result.data.modifiedDate;
        const currentFormatDate1 = moment(lastupdateddata).subtract(330, "minutes").toDate();
        this.lastupdateddate = moment(currentFormatDate1).format('DD-MM-YYYY');
        this.groupname1 = localStorage.getItem('groupname');
        this.pagename = "Course Details"
        if (usertype.toLowerCase() == "teacher") {
          var userid = localStorage.getItem('userId');
          if (result.data.createdBy == userid) {
            this.iscreatedby = true;
            this.islogin = "true";
          }
        }
        else if (usertype.toLowerCase() == "student") {
          if (result.data.studentEnrolledIn == true) {
            this.islogin = "true";
          }
          else {
            this.isenroll = "true";
          }
        }

      });

    }
    else {

    }
    this.rest.getCourseDetails(webToken, courseformData).subscribe((result) => {
      this.SpinnerService.hide();
      this.courseDetails = result.data;
      this.courseSideID = this.courseDetails.courseID;
      var curdata = result.data.startDate;
      const currentFormatDate = moment(curdata).subtract(330, "minutes").toDate();
      this.startDate = moment(currentFormatDate).format('DD-MM-YYYY');
      var curdata = result.data.endDate;
      const currentFormtDate = moment(curdata).subtract(330, "minutes").toDate();
      this.endDate = moment(currentFormtDate).format('DD-MM-YYYY');
      var lastupdateddata = result.data.modifiedDate;
      const currentFormatDate1 = moment(lastupdateddata).subtract(330, "minutes").toDate();
      this.lastupdateddate = moment(currentFormatDate1).format('DD-MM-YYYY');
      this.groupname1 = localStorage.getItem('groupname');
      this.pagename = "Course Details"
      if (usertype.toLowerCase() == "teacher") {
        var userid = localStorage.getItem('userId');
        if (result.data.createdBy == userid) {
          this.iscreatedby = true;
          if (result.data.status == "Draft") {
            this.ispublish = true;
          }
          this.islogin = "true";
        }
      }
      else if (usertype.toLowerCase() == "student") {
        if (result.data.studentEnrolledIn == true) {
          this.islogin = "true";
        }
        else {
          this.isenroll = "true";
        }
      }

    });
    this.isUserLoggedIn = localStorage.getItem('isLoggedin');

  }

  courseJoin(courseid, startdate) {
    var userId = localStorage.getItem('userId');
    const userType = localStorage.getItem('userType');

    const startDate1 = new Date(startdate).getTime() / 1000
    const currentdate = new Date().getTime() / 1000
    const startDateshow = moment(startDate1).format('DD-MM-YYYY');
    if (startDate1 <= currentdate || userType.toLowerCase() == "teacher") {
      if (!userId) {
        this.courseDetails.
          this.toastr.errorToastr("Please login to access courses");
      }
      else {
        var userData = {};
        userData["userId"] = userId;
        userData["userType"] = userType;
        userData["courseId"] = courseid;
      }
      var redirectURL = this.courseURL + "course/view.php?id=" + this.courseSideID;
      this.rest.getLoginURL(userData).subscribe((result) => {
        if (result) {
          if (!result["data"]) {
            this.toastr.errorToastr('This user has not registered for courses');
          }
          else {
            if (!result["data"]["loginurl"] || result['exception'] == 'invalid_parameter_exception') {
              this.toastr.errorToastr('This user has not registered for courses');
            }
            else {
              console.log(result["data"]["loginurl"]);
              console.log(result["data"]["loginurl"] + "&wantsurl=" + redirectURL)
              window.open(result["data"]["loginurl"] + "&wantsurl=" + redirectURL);
            }
          }
        }
      });
    }
    else {
      this.toastr.errorToastr("Course has not started yet. Please check for the start date.");
    }
  
  }

  courseEnroll(courseid, startdate) {
    const startDate1 = new Date(startdate).getTime() / 1000
    const currentdate = new Date().getTime() / 1000
    const startDateshow = moment(startdate).format('DD-MM-YYYY');

      var userId = localStorage.getItem('userId');
      var userType = localStorage.getItem('userType');
      var userData = {};
      userData["userId"] = userId;
      userData["userType"] = userType;
      userData["courseId"] = courseid;
      var redirectURL = this.courseURL + "course/view.php?id=" + this.courseSideID;
      this.rest.getJoinURL(userData).subscribe((result) => {
        console.log(result);
        var globalVar = result;
        if (result) {
          if (!result["data"]) {
            this.toastr.errorToastr('This user has not registered for courses');
          }
          else {
            if (!result["data"]["loginurl"] || result['exception'] == 'invalid_parameter_exception') {
              this.toastr.errorToastr('This user has not registered for courses');
            }
            else {
              console.log(result);
               //Calendar Start

               this.courseDetails.isCourse = true;
               const webToken = localStorage.getItem('webtoken');
               this.rest.notificationCourseExam(this.courseDetails,webToken).subscribe((notifyResult) => {
 
                });
               this.addToCalendar(this.courseDetails, webToken);

               //Calendar End
              this.toastr.successToastr('Enrollement Successfull. Enrolled course is added to your Dashboard ');
              if (userType.toLowerCase() == "teacher") {
                this.router.navigate(['/teacher/mydashboard']);
              }
              else {
                this.router.navigate(['/student/mydashboard']);
              }
            }
          }
        }
      });
   
  }

  coursedetails(ID, name) {
    this.router.navigateByUrl('edit-course/' + ID + "/" + name);
  }

  publishcourse(ID , name) {
    const webToken = localStorage.getItem('webtoken');
    const userType = localStorage.getItem('userType');
    var userData = {};
    userData["id"] = ID;
    userData["userType"] = userType;
    this.rest.changecourseurl(webToken,userData).subscribe((response) => {
      if (response.status) {
        this.router.navigate(['/teacher/mydashboard']).then(() => {
          this.toastr.successToastr('Course Published Successfully');
        })
      }
      else {
        console.log(response);
        this.toastr.errorToastr(response.message);
      }
    });
   
  }

  deletecourse(id) {
    const webToken = localStorage.getItem('webtoken');
    var courseformData = {};
    courseformData["_id"] = id;
    this.rest.Deletecourse(webToken, courseformData).subscribe((response) => {
      if (response.status) {
        this.router.navigate(['/teacher/mydashboard']).then(() => {
          this.toastr.successToastr('Course Deleted Successfully');
        })
      }
      else {
        this.toastr.errorToastr(response.message);
      }
    }, (err) => {
      console.log(err);
    });
  }

  addToCalendar(data,webToken){
    var req={
      myLifeListDatepick:moment(data.startDate).format('YYYY-MM-DD'),
      myLifeListEventType: "courses",
      myLifeListTimepick:moment(data.startDate).format('HH:mm:ss'),
      setReminder: 0,
      todoEventType: "courses",
      meetingType:data.privacy,
      todoSchedule: data.startDate,
      todoSetReminder: "",
      todosetReminderValue: "",
      sessionName:data.shortName,
      meetingId : data._id,
      categoryID :data.categoryID,
      myLifeListTitle:data.privacy,
      sessionId:data._id,
      profileType:'student'
    };

    this.rest.add_myLife_NewData(req,webToken).subscribe((result) => {
      if(result.status){
        this.toastr.successToastr("Class Added to your Calender");
        //this.location.back();
      }else{
        this.toastr.errorToastr(result.message);
      }

    });
  }
}
