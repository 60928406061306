import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Options } from 'ng5-slider';
import { ApiService } from '../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { Location } from '@angular/common';
declare var $: any;
import * as moment from 'moment';

@Component({
  selector: 'app-buddi-test-details',
  templateUrl: './buddi-test-details.component.html',
  styleUrls: ['./buddi-test-details.component.scss']
})

export class BuddiTestDetailsComponent implements OnInit {

  courseSideID: any;  //course id of the course on the course.buddiforlife.com side
  testID: any;
  groupname1: string;
  formdata;
  testDetails: any;
  startDate: string;
  formData;
  typeuser: string;
  lastupdateddate: string;
  endDate: string;
  public pagename: any = '';
  isUserLoggedIn: string;
  isenroll: string;
  islogin: string;
  public isstudent = false;
  public isteacher = false;
  public ispublish = false;
  public courseURL = environment.COURSE_URL;
  public iscreatedby = false;
  public name: any;
  public isreview: boolean = false;
  public isaccept: boolean = false;

  constructor(private location: Location, private my_router: ActivatedRoute, public toastr: ToastrManager, private formBuilder: FormBuilder, private rest: ApiService, private router: Router, private SpinnerService: NgxSpinnerService, private sanitizer: DomSanitizer, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.testID = this.my_router.snapshot.params['id'];
    this.name = this.my_router.snapshot.params['name'];
    if (this.name == "Review") {
      this.isreview = true;
    }
    else {
      this.isaccept = true;
    }
    var usertype = localStorage.getItem('profileType');
    this.typeuser = usertype.toLowerCase();
    const userType = localStorage.getItem('userType');

    var testformData = {};

    testformData["id"] = this.testID;
    const webToken = localStorage.getItem('webtoken');
    this.SpinnerService.show();

    this.rest.getTestDetails(webToken, testformData).subscribe((result) => {
      if (result.status) {
        this.testDetails = result.data;
      }
      else {
        this.toastr.errorToastr(result.message);
      }

    });
    this.isUserLoggedIn = localStorage.getItem('isLoggedin');

  }

  courseJoin(courseid, startdate) {

  }

  back() {
    this.location.back();
    //this.router.navigate(['/buddi-test-request']);
  }

  accepttest(id) {
    var testformData = {};
    testformData["id"] = id;
    const webToken = localStorage.getItem('webtoken');
    this.rest.accepttest(webToken, testformData).subscribe((result) => {
      if (result.status) {
        this.toastr.successToastr(result.message);
        this.router.navigate(['/teacher/mydashboard']);
      }
      else {
        this.toastr.errorToastr(result.message);
      }
    });
  }

  accepttestreview(id) {
    var testformData = {};
    testformData["id"] = id;
    const webToken = localStorage.getItem('webtoken');
    this.rest.accepttestreview(webToken, testformData).subscribe((result) => {
      if (result.status) {
        this.toastr.successToastr(result.message);
        this.router.navigate(['/teacher/mydashboard']);
      }
      else {
        this.toastr.errorToastr(result.message);
      }
    });
  }

}


