<app-header-style-one *ngIf="!isTeacher"></app-header-style-one>
<app-header-style-one-teacher *ngIf="isTeacher"></app-header-style-one-teacher>
<div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a>Home</a></li>
                <li><a>Classes</a></li>
                <li *ngIf="livedetails.length !=0">{{livedetails.name}} - {{livedetails.ScheduleDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</li>
            </ul>
            <h2>Classes</h2>
        </div>
    </div>
</div>
<div  class="container">
    <div *ngIf="livedetails.length==0">
        <br>
        <h3>Currently No session Available </h3>
        <br>
    </div>
</div>
<section class="CourseMain"> 
    <div class="container">       
        <div class="courseMainCard">       
            <div class="courseImg">
                <img  [src]="parentTable.image =='' ? 'assets/img/courses/courses1.jpg':parentTable.image" class="img-fluid" />
            </div>
            <div class="CourseDetails">
                <h4>{{parentTable.name}} - {{parentTable.studentID.firstName}} {{parentTable.studentID.lastName}}</h4>
                <h6>{{parentTable.fullName}} </h6>
                <h6>
                    Start Date: {{parentTable.ScheduleDate  |  date :"MMM dd,yyyy, hh:mm aaa"}}
                </h6>
                <h6>
                    {{parentTable.isWeekdays ? 'Week Days' : 'Week End'}} | {{parentTable.dayList}}
                </h6>
                <div>
                    {{parentTable.categoryID ? parentTable.categoryID.categoryName : '' }}, {{parentTable.gradeID ? parentTable.gradeID.gradeName : '' }}, {{parentTable.boardID ? parentTable.boardID.boardName : '' }}          
                </div>
                <div class="courses-box-footer">
                    {{parentTable.subjectID ? parentTable.subjectID.subjectName : '' }}, {{parentTable.languageID ? parentTable.languageID.languageName : '' }}
                </div>
            </div>
            <div class="CourseDetails" >  
                 <gbutton  [textLabel]="'Pending'"  *ngIf="isLoggedin =='true' && !isTeacher && !parentTable.acceptStatus"></gbutton>
                 <gbutton  [textLabel]="'Back'" class="ml-1"  (click)="backto()"></gbutton>
                 <gbutton  [textLabel]="'Accept'" class="ml-1" (click)="requestAccept(parentTable)" *ngIf="isTeacher  && isLoggedin =='true' && !parentTable.acceptStatus"></gbutton>

              	<!-- <a class="default-btn mr-1 ml-5" (click)="backto()">    
                    <span class="label">Back</span>
                </a> -->
              <!--   <a class="default-btn " >   
                    <i class='bx bx-paper-plane icon-arrow before'></i>
                    <span class="label">Accept</span>
                    <i class="bx bx-paper-plane icon-arrow after"></i>
                </a>   -->


            </div>
        </div>
        <div class="scheduleMainCard">
            <div class="row">
                <div class="col-md-4">
                    <div class="scheduleTitle">
                        <h3>Schedule</h3>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="scheduleDateMain row">
                        <ul>
                            <li  *ngFor="let slide of childTable">
                                <div class="scheduleTitleCard">
                                    <h2 style="">
                                        <small>
                                            {{slide.ScheduleDate |  date :"EEEE MMM dd,yyyy, hh:mm aaa"}}
                                        </small>
                                        {{slide.time}}
                                    </h2>
                                    <div class="scheduleDescription">
                                       <h5>{{slide.name}}</h5>
                                       <h6>{{slide.fullName}}</h6>
                                    </div>
                                </div>
                                <span class="btn btnLive" *ngIf="isLoggedin =='false'" (click)="login()">
                                    Login
                                </span>
                                <span class="btn btnLive" *ngIf="isLoggedin =='true' &&slide.acceptStatus && !isTeacher && slide.isEnable" (click)="meetingsaction(slide.meetingID,'join',parentTable)">
                                	<span>
                                		Join
                                	</span>
                                </span>
                                <span class="btn btnLive" *ngIf="isLoggedin =='true' && isTeacher && slide.isEnable && slide.acceptStatus" (click)="meetingsaction(slide.meetingID,'start',parentTable)">
                                    Start
                                </span>                            
                            </li>
                        </ul>
                    </div>
                </div>
            </div>            
        </div>
    </div>
</section>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <img src="assets/img/spinner-default.gif" alt="">
</ngx-spinner>
