import { Component, OnInit } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';
import { ApiService } from "../../../shared/api.service";
import { ExternalLibraryService } from '../util';
import { environment } from '../../../../environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
declare let Razorpay: any;
@Component({
  selector: 'app-wallet-recharge',
  templateUrl: './wallet-recharge.component.html',
  styleUrls: ['./wallet-recharge.component.scss']
})

export class WalletRechargeComponent implements OnInit {

  public isStudent = false;
  public isTeacher = false;
  public walletBalance = 0;
  RAZORPAY_OPTIONS: any;
  public teacherID;
  orderID = "";
  paymentID = ";"
  amount = 0;
  isLoggedin = "false"
  constructor(
    private rest: ApiService,
    private router: Router,
    public toastr: ToastrManager,
    private razorpayService: ExternalLibraryService,
    private SpinnerService: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.isLoggedin = localStorage.getItem('isLoggedin');
    this.teacherID = localStorage.getItem('teacherID')

    const token = localStorage.getItem("webtoken");
    this.isTeacher =
        localStorage.getItem("profileType").toLowerCase() == "teacher";
    this.isStudent =
        localStorage.getItem("profileType").toLowerCase() == "student";
        var reqData = {
          notFetchCompleted: true,
          limit: 5
      };
      this.razorpayService
      .lazyLoadLibrary('https://checkout.razorpay.com/v1/checkout.js')
      .subscribe();
      this.RAZORPAY_OPTIONS = {
        "key": environment.razorpayKey,
        "amount": "",
        "name": environment.paymentNameHead,
        "order_id": "",
        "description": environment.paymentNameSub,
        "prefill": {
          "name": "",
          "contact": "",
          "method": ""
        },
        "modal": {},
        "theme": {
          "color": "#0096C5"
        }

    };
  }

  initiateOrder() {
    const webToken = localStorage.getItem("webtoken");
    var request = {
      amount:this.amount
    }
    this.SpinnerService.show()
    this.rest.addMoneyToWallet(webToken, request).subscribe((response) => {
      this.SpinnerService.hide()
      console.log("material", response)
      this.orderID = response.data.orderId
      this.paymentID = response.data._id
      this.RAZORPAY_OPTIONS['order_id'] = response.data.orderId;
    
      let razorpay = new Razorpay(this.RAZORPAY_OPTIONS)
        razorpay.open();
    })
  }

  submit(){
    var rechargeAmount =  (<HTMLInputElement>document.getElementById('rechargeAmount')).value;
    console.log(rechargeAmount);
    this.amount = parseInt(rechargeAmount);
    if(this.amount % 100 != 0){
      this.toastr.errorToastr('Recharge amount should be a multiple of 100')
    }
    this.RAZORPAY_OPTIONS['amount'] = this.amount * 100;
    this.RAZORPAY_OPTIONS['handler'] = this.razorPaySuccessHandler.bind(this);
    this.initiateOrder();
    

  }

  public razorPaySuccessHandler(response) {
    console.log(response)
    var jsonData = {};
    const webToken = localStorage.getItem("webtoken");
    jsonData["razorpay_order_id"] = this.orderID;
    jsonData["razorpay_payment_id"] = response.razorpay_payment_id;
    jsonData["razorpay_signature"] = response.razorpay_signature;
    jsonData["paymentID"] = this.paymentID;
    this.SpinnerService.show()
    this.rest.completeMoneyToWallet(webToken, jsonData).subscribe((response) => {
      this.SpinnerService.hide()
      this.toastr.successToastr('Recharge Successful')
      this.router.navigate(['/Currency-wallet']);
    })

  }
}