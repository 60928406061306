import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Options } from 'ng5-slider';
import { MediaService } from '../../../../shared/media.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ApiService } from '../../../../shared/api.service';
import { NgxSpinnerService } from "ngx-spinner";
import {NgSelectModule, NgOption} from '@ng-select/ng-select';
@Component({
  selector: 'app-teacher-ebook-create',
  templateUrl: './teacher-ebook-create.component.html',
  styleUrls: ['./teacher-ebook-create.component.scss']
})
export class TeacherEbookCreateComponent implements OnInit {

  selectedCityId: number;
  videosList = [];
  videos : any =[];
  allfiles : any = [];
  nameList : any = [];
  createForm: FormGroup;
  submitted = false;
  uploaVideoName: string = 'Choose File';
  selectedRouteId:any;
  selectedRouteValue:any;
  videosListget: any;
  meetCategory:any=[];
  selectedValue = "";
  alltag:any=[];
  public userId:any = '';
  securityArray:any=[];
  public groupName:any='';
  public meetingType = "Select Session Type";
  public tagtype ="Select Tag";
  public editData:any=[];
  public paramData:any = [];
  public isEnterprise = '';
  public thumbnailFile : any = [];
  public thumbnailPlace:any = '';
  public coursecategorylist : any = [];
  public courseboardlist : any = [];
  public coursesubjectlist : any = [];
  public languageapi : any = [];
  public gradeapi : any = [];
  public teacherID:any='';
  public isTeacher:boolean=false;
  public studentID:any = '';
  public selecttag:any = [];
  public partnerList:any = [];
  public isPartnerUser:any = [];
  public isEdit:Boolean = false;
  public typeName:string = '';
  selectedcategorylist = [];
  selectedgradelist = [];
  selectedsubjectlist = [];
  dropdownSettings: any = {};
   dropdowngradeSettings: any = {};
   dropdownsubjectSettings: any = {};
  constructor(private activatedRoute :ActivatedRoute,public toastr: ToastrManager,private SpinnerService: NgxSpinnerService,private rest : ApiService,private location:Location,private api: MediaService,private formBuilder: FormBuilder,private router: Router) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.tagfield();
    this.getSecurity();
    this.typeName = this.activatedRoute.snapshot.params.type;
    this.selectedRouteId = localStorage.getItem('collectionid');
    this.selectedRouteValue = localStorage.getItem('collectionname');
    this.isTeacher = localStorage.getItem('profileType').toLowerCase() =='teacher' 
    this.userId = localStorage.getItem('userId');
    const webToken = localStorage.getItem('webtoken');
    this.isPartnerUser = localStorage.getItem('isPartner') =='true'?true:false;
    this.createForm = this.formBuilder.group({
      id: null,
      caption: ['', Validators.required],
      chapterName: ['', Validators.required],
      upload_name: [null],
      fileupload: ['', Validators.required],
      flimname: ['', Validators.required],
      type: [''],
      benefit: ['', Validators.required],
      thumbnail: ['', Validators.required],
      tag: [''],
      description: [''],
      securitytype: ['Private', Validators.required],
      additional: [''],
      autor: [''],
      // courseGrade: ['', Validators.required],
      // courseSubject: ['', Validators.required],
      courseLanguage:['', Validators.required],
      // courseCategory: ['', Validators.required],
      courseBoard: ['', Validators.required],
      providerBy:['buddi Premium', Validators.required],
      partner:['', Validators.required]
    });

    this.dropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'categoryName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3
  };

    this.dropdowngradeSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'gradeName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3
  };
  
  this.dropdownsubjectSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'subjectName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3
  };
    this.createForm.patchValue({securitytype:'Public'});
    this.getcoursecategory();
    this.getboardfield();
    this.getsubjectfield();
    this.getlanguage();
    this.getgrade();
    this.getPartner();
    if(this.isTeacher){
      this.rest.userName_DisplayTeacher(webToken).subscribe((result) => {
        this.teacherID = result.data._id
        this.groupName =  result.data !=undefined && result.data.groupName != 'buddi' ? result.data.groupName : ''
        localStorage.setItem('groupname', result.data !=undefined && result.data.groupName != 'buddi' ? result.data.groupName : '');
      });
    }else{
    this.rest.userName_DisplayStudent(webToken).subscribe((result) => {
      this.studentID = result.data._id     
    });
   }

   // Edit Section Start
    if(this.activatedRoute.snapshot.params.id){
      var req={
        id:this.activatedRoute.snapshot.params.id,
      }
      switch(this.typeName){
      case 'ebook':{
        this.api.getEbookByID(req,webToken).subscribe((result) => {
          if(result.status && result.data.length!=0){
            this.editData =result.data;
            this.prePopulateData(this.editData[0]);            
          }else{
            this.editData = [];
          }
        })
        break;
      }
      case 'solution':{
        this.api.getSolution(req,webToken).subscribe((result) => {
          if(result.status && result.data.length!=0){
            this.editData =result.data;
            this.prePopulateData(this.editData);
          }else{
            this.editData = [];
          }
        })
        break;
      }
      case 'question':{
        this.api.getQuestion(req,webToken).subscribe((result) => {
          if(result.status && result.data.length!=0){
            this.editData =result.data;
            this.prePopulateData(this.editData);
          }else{
            this.editData = [];
          }
        })
      }
      break;
      }
    }           
    // Edit Section End  
  }

  prePopulateData(data){
     if(this.isPartnerUser){
          this.createForm.patchValue({ providerBy:  data.isbuddi_premium ? 'buddi Premium' :'Partner'})
          if(!data.isbuddi_premium){
            this.createForm.patchValue({ partner:  data.partner._id}); 
          }       
        }
      this.isEdit = data._id ?true:false;
      this.createForm.patchValue({ 
        caption:  data.caption,
        chapterName: data.chapterName,
        flimname:  data.flimname,
        type:  data.type,
        benefit:  data.benefit,
        name:  data.name,
        id:  data._id,
        description:  data.description,
        securitytype:  data.securitytype,
        additional:  data.additional,
        autor:  data.autor,
        thumbnail:  data.thumbname,
        thumbname:  data.thumbname,
        // courseCategory: data.categoryID._id,
        // courseGrade: data.gradeID._id,
        courseBoard: data.boardID._id,
        // courseSubject: data.subjectID._id,
        courseLanguage: data.languageID._id,
        fileupload: data.name,
      })
      this.selectedcategorylist = data.categoryID;

      this.selectedgradelist = data.gradeID;
      this.selectedsubjectlist = data.subjectID;
      console.log("selectedsubjectlist",this.selectedsubjectlist);
      this.selecttag  = data.tag == "" ? '' :data.tag.split(",");
      this.groupName  = data.groupname;
      this.thumbnailPlace = data.thumbfilename;
      this.uploaVideoName = data.originalname;
  }
  getPartner(){
    this.rest.getAllPartner().subscribe((result) => {
      if(result.status){
        this.partnerList = result.data;  
        if(this.partnerList.length!=0){
          this.createForm.patchValue({ partner: this.partnerList[0]._id })
        }
      }
    }, (err) => {

    });
  }
  get f() {
    return this.createForm.controls;
  }
  selectChange (event: any) {
   this.selectedValue = event.target.options[event.target.selectedIndex].text;
  }
  goBack(){
    this.location.back();
  }

  tagfield(){
   this.rest.tagfield().subscribe((result) => {
     this.alltag = result;
     console.log(this.alltag);
   }, (err) => {
   console.log(err);
   });
  }
  getSecurity(){
   this.rest.security().subscribe((result) => {
     this.securityArray = result;
     console.log(this.securityArray);
   }, (err) => {
   console.log(err);
   });
  }
  getVideoList() {
   this.api.getVideoList().subscribe((response) => {
     this.selectedRouteId = localStorage.getItem('collectionid');
     this.selectedRouteValue = localStorage.getItem('collectionname');
     if(response.status){
       this.videosListget = response.data.reverse();
       console.log(this.videosListget);
     }
   });
  }
  deleteItem(item: any) {
    const index = this.videos.indexOf(item);
    this.nameList.splice(index,1);
    if(this.nameList.join(',').length > 29){
      this.uploaVideoName =  this.nameList.join(',').substring(0,30)+'...';
    }else{
      this.uploaVideoName =  this.nameList.join(',');
    }
    if(this.uploaVideoName =='...'  || this.nameList.length ==0){
      this.uploaVideoName = 'Choose File';
    }
    this.videos.splice(index,1);
    this.allfiles.splice(index,1);
  }
  resetData(){
    this.createForm.reset();
    this.videos =[];
    this.allfiles =[];
    this.videosList = [];
    this.submitted = false;
    this.nameList  = [];
    this.uploaVideoName = 'Choose File';
  }
  onSelectFile(event) {
    this.thumbnailFile=[];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      this.thumbnailFile.push(event.target.files[0])
      this.createForm.patchValue({ thumbnail: this.thumbnailFile[0].name });
      this.thumbnailPlace = this.thumbnailFile[0].name;
      reader.onload = (event) => {        
     }
    }
  }

  fileChange(event) {
    if(this.createForm.value.id && event.target.files.length < 2 && this.allfiles.length <1){
      const files = event.target.files;
      if(files){
        for(let i=0;i< files.length; i++){
          const video = {
            name : "",
            type : "",
            size : "",
            url : "",
          };
          this.allfiles.push(files[i]);
          this.nameList.push(files[i].name);
          video.name = files[i].name;
          video.type = files[i].type;
          video.size = files[i].size;
          const reader = new FileReader();
          reader.onload = (filedata) => {
            video.url = reader.result + " ";
            this.videos.push(video);
          }
        reader.readAsDataURL(files[i]);
      }
    }
    if(this.nameList.join(',').length > 29){
      this.uploaVideoName =  this.nameList.join(',').substring(0,30)+'...';
    }else{
      this.uploaVideoName =  this.nameList.join(',')
    }
    this.createForm.patchValue({ fileupload: this.uploaVideoName });
    event.srcElement.value = null;
    }else if(!this.createForm.value.id){
      const files = event.target.files;
      if(files){
        for(let i=0;i< files.length; i++){
          const video = {
            name : "",
            type : "",
            size : "",
            url : "",
          };
          this.allfiles.push(files[i]);
          this.nameList.push(files[i].name);
          video.name = files[i].name;
          video.type = files[i].type;
          video.size = files[i].size;
          const reader = new FileReader();
          reader.onload = (filedata) => {
            video.url = reader.result + " ";
            this.videos.push(video);
          }
        reader.readAsDataURL(files[i]);
      }
    }
    if(this.nameList.join(',').length > 29){
      this.uploaVideoName =  this.nameList.join(',').substring(0,30)+'...';
    }else{
      this.uploaVideoName =  this.nameList.join(',')
    }
    this.createForm.patchValue({ fileupload: this.uploaVideoName });
    event.srcElement.value = null;
    }else{
      return false;
    }   
  }

getcoursecategory() {
  this.rest.getCourseCategoryHeader().subscribe((result) => {
    this.coursecategorylist = result.data;
    if(Object.keys(this.paramData).length == 0 && !this.isEdit){
      this.createForm.patchValue({ courseCategory: this.coursecategorylist[0]._id })
    }
  }, (err) => {
  console.log(err);
  });
}

getboardfield(){
  this.rest.boardfield().subscribe((result) => {
    this.courseboardlist = result.data;
    if(Object.keys(this.paramData).length == 0 && !this.isEdit){
      this.createForm.patchValue({ courseBoard: this.courseboardlist[0]._id })
    }
  }, (err) => {
  console.log(err);
  });
}

getsubjectfield(){
  this.rest.subjectfield().subscribe((result) => {
    this.coursesubjectlist = result.data;
    if(Object.keys(this.paramData).length == 0 && !this.isEdit){
      this.createForm.patchValue({ courseSubject: this.coursesubjectlist[0]._id })
    }
  }, (err) => {
  console.log(err);
  });
}

getlanguage(){
  this.rest.languagefield().subscribe((result) => {
    this.languageapi = result.data;
    if(Object.keys(this.paramData).length == 0 && !this.isEdit){
      this.createForm.patchValue({ courseLanguage: this.languageapi[0]._id })
    }
  }, (err) => {
  console.log(err);
  });
}

getgrade(){
  this.rest.grade().subscribe((result) => {
    this.gradeapi = result.data;
    if(Object.keys(this.paramData).length == 0 && !this.isEdit){
      this.createForm.patchValue({ courseGrade: this.gradeapi[0]._id })
    }
  }, (err) => {
  console.log(err);
  });
}

  onSubmit(status) {
    this.submitted = true;
    if (!this.createForm.valid) {     
      // const invalid = [];
      //   const controls = this.createForm.controls;
      //   for (const name in controls) {
      //       if (controls[name].invalid) {
      //           alert(name);
      //       }
      //   }
      return;
    }
    if(this.selectedcategorylist.length == 0) {
      this.toastr.errorToastr("Category Required");
      return;
    }
    if(this.selectedgradelist.length == 0) {
      this.toastr.errorToastr("Grade Required");
      return;
    }
    if(this.selectedsubjectlist.length == 0) {
      this.toastr.errorToastr("Subject Required");
      return;
    }
    let data = this.createForm.value;
    let categoryresult = this.selectedcategorylist.map(a => a._id);
    let graderesult = this.selectedgradelist.map(a => a._id);
    console.log('grade', graderesult)
    let subjectresult = this.selectedsubjectlist.map(a => a._id);
    console.log("subjectresult", JSON.stringify(subjectresult).slice(1,-1).replace(/['"]+/g, ''));
    console.log("graderesult", JSON.stringify(graderesult).slice(1,-1).replace(/['"]+/g, ''));
    console.log("categoryresult", JSON.stringify(categoryresult).slice(1,-1).replace(/['"]+/g, ''));
    var formData = new FormData();
    for(let i =0; i < this.allfiles.length; i++){
      formData.append("upload_name", this.allfiles[i], this.allfiles[i]['name']);
    }
    if(this.thumbnailFile.length !=0){
      formData.append("thumbname", this.thumbnailFile[0],this.thumbnailFile[0]['name']);
    }
    formData.append("flimname", data.flimname);
    formData.append("caption", data.caption);
    formData.append("userVideolistId", this.selectedRouteId);
    formData.append("typename", data.caption );
    formData.append("chapterName", data.chapterName );
    formData.append("type", data.type);
    formData.append("benefit", data.benefit);
    formData.append("profileType",  this.isTeacher ?'teacher' :'student');
    formData.append("isPartner", this.isPartnerUser);
    if(this.isPartnerUser){
      formData.append("providerBy",data.providerBy);
      if(data.providerBy =='buddi Premium'){
        formData.append("isbuddi_premium",'true');
      }else{
        formData.append("partner",data.partner);
        formData.append("isbuddi_premium",'false');
      }
    }    
    formData.append("originalname",this.uploaVideoName);
    formData.append("tag",data.tag);
    formData.append("description", data.description);
    formData.append("securitytype", this.isTeacher ?data.securitytype :'Private');
    formData.append("additional", data.additional);
    formData.append("autor", data.autor);
    formData.append("categoryID", JSON.stringify(categoryresult).slice(1,-1).replace(/['"]+/g, ''));
    formData.append("languageID", data.courseLanguage);
    formData.append("subjectID", JSON.stringify(subjectresult).slice(1,-1).replace(/['"]+/g, ''));
    formData.append("boardID", data.courseBoard);
    formData.append("gradeID", JSON.stringify(graderesult).slice(1,-1).replace(/['"]+/g, ''));
    formData.append("groupname", data.securitytype =='Group' ? this.groupName : '');
    this.SpinnerService.show();
    if(this.typeName == 'solution'){
      if(!this.createForm.value.id){
      this.api.uploadSolutionPaper(formData).subscribe((response) => {
        console.log('after api');
        console.log(formData);
        this.SpinnerService.hide();
        this.resetData();
        if(response.status){
          this.router.navigate(['/success/Solution']);
          this.toastr.infoToastr(response.message);
        }
      });
    }else{
      formData.append("id", data.id);
      this.api.editSolutionPaper(formData).subscribe((response) => {
        this.SpinnerService.hide();
        this.resetData();
        if(response.status){
          this.location.back();
          this.toastr.infoToastr(response.message);
        }else {
          this.toastr.errorToastr(response.message);
        }
     });
    }
    }else if(this.typeName == 'ebook'){
      if(!this.createForm.value.id){
      this.api.uploadebook(formData).subscribe((response) => {
       
        this.SpinnerService.hide();
        this.resetData();
        if(response.status){
          this.router.navigate(['/success/e-book']);
          this.toastr.infoToastr(response.message);
        }
     });
    }else{
      formData.append("id", data.id);
      this.api.editEbook(formData).subscribe((response) => {
        console.log('after api');
        console.log(formData);
        this.SpinnerService.hide();
        this.resetData();
        if(response.status){
          this.location.back();
          this.toastr.infoToastr(response.message);
        }else {
          this.toastr.errorToastr(response.message);
        }
      });
    }
    }else if(this.typeName == 'question'){
      if(!this.createForm.value.id){
      this.api.uploadQuestionPaper(formData).subscribe((response) => {
        this.SpinnerService.hide();
        this.resetData();
        if(response.status){
          this.router.navigate(['/success/Question']);
          this.toastr.infoToastr(response.message);
        }
      });
      }else{
      formData.append("id", data.id);
      this.api.editQuestionPaper(formData).subscribe((response) => {
        this.SpinnerService.hide();
        this.resetData();
        if(response.status){
          this.location.back();
          this.toastr.infoToastr(response.message);
        }else {
          this.toastr.errorToastr(response.message);
        }
      });

    }
  }

}

onItemsubjectSelect(item: any) {
   this.selectedsubjectlist.push(item)
   this.selectedsubjectlist = Array.from(this.selectedsubjectlist.reduce((m, t) => m.set(t._id, t), new Map()).values());
  }
  
  onItemsubjectDeSelect(item1: any) {
    console.log(item1);
    this.selectedsubjectlist = this.selectedsubjectlist.filter(item => !item1._id.includes(item))
  }
 
  onItemsubjectallDeSelect(item1: any) {
   for (let i = 0; i < item1.length; i++) {
     this.selectedsubjectlist = this.selectedsubjectlist.filter(item => !item1[i]._id.includes(item))
     };
 }
 onItemsubjectallSelect(item1: any) {
   this.selectedsubjectlist = [];
   for (let i = 0; i < item1.length; i++) {
     this.selectedsubjectlist.push(item1[i])
     };
 }

 onItemcategorySelect(item: any) {
   this.selectedcategorylist.push(item)
   this.selectedcategorylist = Array.from(this.selectedcategorylist.reduce((m, t) => m.set(t._id, t), new Map()).values());
  //this.selectedcategorylist.push(item)
 }
 
 onItemcategoryDeSelect(item1: any) {
   this.selectedcategorylist = this.selectedcategorylist.filter(item => !item1._id.includes(item))
 }
 
 onItemcategoryallDeSelect(item1: any) {
   for (let i = 0; i < item1.length; i++) {
     this.selectedcategorylist = this.selectedcategorylist.filter(item => !item1[i]._id.includes(item))
     };
 }
 onItemcategoryallSelect(item1: any) {
   this.selectedcategorylist = [];
   for (let i = 0; i < item1.length; i++) {
     this.selectedcategorylist.push(item1[i])
     };
 }
 
 onItemgradeSelect(item: any) {
   this.selectedgradelist.push(item)
   this.selectedgradelist = Array.from(this.selectedgradelist.reduce((m, t) => m.set(t._id, t), new Map()).values());
  }
  
  onItemgradeDeSelect(item1: any) {
    console.log('de-select');
  console.log(item1);
    this.selectedgradelist = this.selectedgradelist.filter(item => !item1._id.includes(item))
    console.log('selected list',this.selectedgradelist);
  }
 
  onItemgradeallDeSelect(item1: any) {
   for (let i = 0; i < item1.length; i++) {
     this.selectedgradelist = this.selectedgradelist.filter(item => !item1[i]._id.includes(item))
     };
 }
 onItemgradeallSelect(item1: any) {
   this.selectedgradelist = [];
   for (let i = 0; i < item1.length; i++) {
     this.selectedgradelist.push(item1[i])
     };
 }

}
