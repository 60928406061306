<div *ngIf="isstudent">
    <app-header-style-one></app-header-style-one>
</div>
<div *ngIf="isteacher">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>

<section class="faq-area pt-50">
        <div class="container">
            <div class="col-lg-12 col-md-12" style="margin-bottom: 3%;">
                <div class="about-content">
                    <h2>Our buddiTalk</h2>
                </div>
            </div>
            <div class="card-deck">
                <div class="col-lg-4 col-md-6">
                     <div class="card">
                          <img src="../../../../assets/img/Email-Newsletter-smaller.webp" class="card-img-top" alt="Blockchain_blog">
                          <div class="card-body">
                            <p class="card-text" style="width: 278px;height: 44px;">buddi Talk Newsletter<br>Introduction to buddi.life</p>
                            <a href="../../../../assets/buddiTalk/buddi Newsletter Vol 1.pdf" target="_blank">View Details</a>
                            <p>Edition: April 2022, Vol 1</p>
                          </div>
                     </div>
                </div>
            </div>
        </div>
</section>
