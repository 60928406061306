
<div *ngIf="!isStudent">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>
<div *ngIf="isStudent">
    <app-header-style-one></app-header-style-one>
</div>
<div class="container" routerLink="/sidenav"><button style="width: 10%;height:45px;margin-left: 89%;margin-top:1%;padding: 9px;"><i class="fas fa-long-arrow-alt-left mr-1"></i>Back</button></div>
<div class="error-404-area">
    <div class="container">
        <div class="notfound">
            <div class="notfound-bg">
                <div></div>
            </div>
            <h5>Coming soon!</h5>
            <!-- <h3>Error 404 : Page Not Found</h3>
            <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
            <a routerLink="/" class="default-btn"><i class='bx bx-home-circle icon-arrow before'></i><span class="label">Back Home</span><i class="bx bx-home-circle icon-arrow after"></i></a> -->
        </div>
    </div>
</div>
