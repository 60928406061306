<div *ngIf="isTeacher">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>
<div *ngIf="!isTeacher">
    <app-header-style-one></app-header-style-one>
</div>
<div class="containerBg">
    <div class="container">
        <div class="row mt-5">
            <div class="col-md-12">
                <div class="serviceTitle">
                    <h3 class="col-lg-12" labelStyle>
                        <span>
                            <span class="ml-2">Request Live Class - {{liveDetails.className ? liveDetails.className : ''}}</span>
                        </span>
                        <br>
                        <br>
                        <div class="container">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label  class="name"
                                        >Class schedule as per your convenient datetime</label
                                    >
                                    <input
                                        type="text"
                                        id="classDateTime"
                                        class="meeting-she form-control"
                                        placeholder="Select Date & Time"
                                        [min]="min"
                                        [owlDateTimeTrigger]="dtdp"
                                        [owlDateTime]="dtdp"
                                        [(ngModel)]="dateTime"
                                        (ngModelChange)="dateChange($event)"
                                    />
                                    <span
                                        ><owl-date-time
                                            #dtdp
                                            [hour12Timer]="true"
                                        ></owl-date-time
                                    ></span>
                                </div>
                                <div class="col-md-6 description" >
                                    <div class="form-group" style="margin-right: 20px;">                                  
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" id="payInPersonCheckbox" name="classTemp">
                                            <label class="form-check-label" for="isClass1">I want to pay in person</label>
                                            </div>
                                    </div>
                                </div>
                                <input
                                type="submit"
                                class="btn btn-primary"
                                value="Submit"
                                (click)="joinLiveClass('selectDateTime')"
                            />
                            </div>
                            <hr/>
                        </div>
                        <br>
                        <div class="container">
                            <div class="col-md-6">
                                    <label class="name"
                                        >or</label
                                    >
                            </div>
                        </div>
                        <br>
                        <div class="container">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="name"
                                        >Class start within half an hour from now.Click on "Submit"</label
                                    >
                                </div>
                                <input
                                type="submit"
                                class="btn btn-primary"
                                value="Submit"
                                (click)="joinLiveClass('now')"
                            />
                            </div>
                            
                        </div>
                    </h3>
                </div>
                <hr/>
            </div>
        </div>
    </div>
</div>
<ngx-spinner
    bdColor="rgba(51, 51, 51, 0.8)"
    size="default"
    type="ball-spin-clockwise"
>
    <img src="assets/img/spinner-default.gif" alt="" />
</ngx-spinner>
