import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Options } from 'ng5-slider';
import { MediaService } from '../../../../shared/media.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ApiService } from '../../../../shared/api.service';
import { NgxSpinnerService } from "ngx-spinner";
import {NgSelectModule, NgOption} from '@ng-select/ng-select';
import { createTrue } from 'typescript';

@Component({
  selector: 'app-student-course-details',
  templateUrl: './student-course-details.component.html',
  styleUrls: ['./student-course-details.component.scss']
})
export class StudentCourseDetailsComponent implements OnInit {

  public id;
  public categorydetails : any = [];
  public isteacher: boolean = false;
  public isstudent: boolean = false;
  public isvideo: boolean = false;
  public ispersonalised: boolean = false;
  public isstudymaterial: boolean = false;
  public istest: boolean = false;
  public isgroupclass: boolean = false;
  public categoryid = "";
  public moduleid = "";
  public categoryname = "";
  public modulename = "";
  testID: any = ""; 
  public videoList:any=[];
  public smart:any=[];
  public testList:any=[];
  public correctedtestList:any=[];
  constructor(private activatedRoute :ActivatedRoute,public toastr: ToastrManager,private SpinnerService: NgxSpinnerService,private rest : ApiService,private location:Location,private api: MediaService,private formBuilder: FormBuilder,private router: Router) { }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.params.id;
    var usertype = localStorage.getItem('profileType');
    if (usertype.toLowerCase() == "teacher") {
      this.isteacher = true;
    }
    else {
      this.isstudent = true;
    }

    var req = {
      courseID : this.id
    }
    this.rest.getClassCoursedetails(req).subscribe((result) => {
      if(result.status){
        this.categorydetails = result.data;
        this.categoryid = result.data.categoryID._id;
        this.moduleid = result.data.moduleID._id;
        this.categoryname = result.data.categoryID.categoryName;
        this.modulename = result.data.moduleID.moduleName;
        var req = {
          courseID: this.id
        }
        this.rest.getTestsByCategory(req).subscribe((testResult) => {
          console.log(testResult)
          if(testResult && testResult.data.Course.length == 1){
            
            this.testID = testResult.data.Course[0]._id
          }
        })
      }
    });
  }

  toggleAccordian(event, index) {
    const element = event.target;
    element.classList.toggle("active");
    const panel = element.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }

  gettestlist() {
    var token = localStorage.getItem('webtoken');
    this.rest.getalltestlist({}).subscribe((result) => {
      if(result.status){

        this.testList = result.data.filter(
          categoryid => categoryid.topicID.courseID === this.id);
      }
    });

    // this.rest.getcorrectedtestlist(token).subscribe((result) => {
    //   if(result.status){
    //     this.testList = result.data.filter(
    //       categoryid => categoryid.topicID.courseID === this.id);
    //    // this.testList = result.data;
    //   }
    // });
  }

 //videoclasses() {
  videoclasses(course, topic) {
    // this.isvideo = true;
    // this.ispersonalised= false;
    // this.istest = false;
    // this.isstudymaterial = false;
    // this.isgroupclass = false;
    // // alert(this.id);
    // var req = {
    //   topicID : id
    // }
    // this.rest.getTopicMaterial(req).subscribe((result) => {
    //   if(result.status){
    //     // alert('success');
    //     console.log('topic', result.data.Videos)
    //     this.videoList = result.data.Videos
        
    //   }
    // });
    let data = {}
    this.router.navigate(['/all-video-listing/' + this.categoryid + "/" + this.moduleid + "/" + course + "/" + topic]);
// this.router.navigate(['/teacher-video-upload/' + this.categoryid + "/" + this.moduleid + "/" + course + "/" + topic]);
  }
  study(course, topic){
    // this.isvideo = false;
    // this.ispersonalised= false;
    // this.istest = false;
    // this.isstudymaterial = true;
    // this.isgroupclass = false;
    // var req = {
    //   topicID : id
    // }
    // this.rest.getTopicMaterial(req).subscribe((result) => {
    //   if(result.status){
    //     // alert('success');
    //     console.log('topic', result.data.SmartMaterial.Solutions)
    //     this.smart = result.data.SmartMaterial.Solutions;
        
    //   }
    // });
    this.router.navigate(['/all-study-listing/' + this.categoryid + "/" + this.moduleid + "/" + course + "/" + topic]);
  }
  test(course, topic){
    this.router.navigate(['/all-test-listing/' + this.categoryid + "/" + this.moduleid + "/" + course + "/" + topic]);
  }
  groupclasses(course,topic){
    console.log(course, topic)
    this.router.navigate(['/studyhallList/' + this.categoryid + "/" + this.moduleid + "/" + course + "/" + topic]);
  }
  practiceTest(){
    this.router.navigate(['/all-test-listing/']);
  }
  courseTest(){
    if(this.testID){
      console.log(this.testID)
      this.router.navigate(['/buddi-test-details/' + this.testID])
    }
    else{
      this.toastr.errorToastr("No Tests Available");
    } 
    
  }
  personalized(){
    this.isvideo = false;
    this.ispersonalised= false;
    this.istest = false;
    this.isstudymaterial = false;
    this.isgroupclass = true;
  }
  redirect(){
    this.router.navigateByUrl('/all-video-listing');
  } 
  videodetails(data){
     this.router.navigateByUrl('/videoDetails/'+data._id);
  }
  testdetails(data) {
    this.router.navigateByUrl('/test-details-student/' + data._id)
  }
  smartdetails(data){
    this.router.navigateByUrl('/studyMaterialDetails/'+data._id);
  }
  liveClass(course,topic) {
    this.router.navigate(['/liveClassList/' + this.categoryid + "/" + this.moduleid + "/" + course + "/" + topic]);
  }
}
