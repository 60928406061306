import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-buddi-advisors-details',
  templateUrl: './buddi-advisors-details.component.html',
  styleUrls: ['./buddi-advisors-details.component.scss']
})
export class BuddiAdvisorsDetailsComponent implements OnInit {
  public isstudent = false;
  public isteacher = false;
  public issamuel = false;
  public ismukund = false;
  public isarif = false;
  name: any;

  constructor(private my_router: ActivatedRoute) { }

  ngOnInit(): void {
    this.name = this.my_router.snapshot.params['name'];
    var usertype = localStorage.getItem('profileType');
    if (usertype.toLowerCase() == "teacher") {
      this.isteacher = true;
    }
    else {
      this.isstudent = true;
    }

    if (this.name.toLowerCase() == "samuel") {
      this.issamuel = true;
    }
    else {
      this.issamuel = false;
    }
  }

}
