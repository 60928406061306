import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../shared/api.service';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public isstudent = false;
  public isteacher = false;
  isLogin: any = false;
  pagename: any;
  loginname: any;
  allheader: any = [];
  isLoggedin = "false";
  usertype1 = '';


  //************************************************** Staging Starts **************************************************/
  stagingEnglish = '5f43aa7a0c46160c254a05d2';
  stagingTamil = '5f4d0b767f22ab6769601c94';
  stagingBiology = '5f4d0b937f22ab6769601c97';
  stagingPhysics = '5f4d0b897f22ab6769601c96';
  stagingChemistry = '5f4e18ec1fd24c27db1c667c';
  stagingComputerSceince = '5f4d0bae7f22ab6769601c98';
  stagingScience = '5f4e189e1fd24c27db1c667b';
  stagingSocialScience = '5f4d0bd47f22ab6769601c99';
  stagingMaths = '5f4d0b817f22ab6769601c95';


  stagingStateBoard = '5f4d06587f22ab6769601c8d';
  stagingCBSE = '5f43aa8c0c46160c254a05d3';


  stagingGrade12 = '5f758aebdae301341f57dc9b';
  stagingGrade11 = '5f758ae9dae301341f57dc99';
  stagingGrade10 = '5f758ae6dae301341f57dc98';
  stagingGrade09 = '5f758ae2dae301341f57dc96';

  stagingclass12 = '5f758aebdae301341f57dc9b';
  stagingclass11 = '5f758ae9dae301341f57dc99';
  stagingclass10 = '5f758ae6dae301341f57dc98';
  stagingclass09 = '5f758ae2dae301341f57dc96';

  //************************************************** Staging End **************************************************/

  //************************************************** Testing Starts **************************************************/
  TestingEnglish = '5f43aa7a0c46160c254a05d2';
  TestingTamil = '5f4d0b767f22ab6769601c94';
  TestingBiology = '5f4d0b937f22ab6769601c97';
  TestingPhysics = '5f4d0b897f22ab6769601c96';
  TestingChemistry = '5f4e18ec1fd24c27db1c667c';
  TestingComputerSceince = '5f4d0bae7f22ab6769601c98';
  TestingScience = '5f4e189e1fd24c27db1c667b';
  TestingSocialScience = '5f4d0bd47f22ab6769601c99';
  TestingMaths = '5f4d0b817f22ab6769601c95';


  TestingStateBoard = '5f4d06587f22ab6769601c8d';
  TestingCBSE = '5f43aa8c0c46160c254a05d3';


  TestingGrade12 = '5f6864c40a19fd6e912fbf9f';
  TestingGrade11 = '5f6864c10a19fd6e912fbf95';
  TestingGrade10 = '5f6864be0a19fd6e912fbf8b';
  TestingGrade09 = '5f6864bb0a19fd6e912fbf8a';

  Testingclass12 = '5f6864c40a19fd6e912fbf9f';
  Testingclass11 = '5f6864c10a19fd6e912fbf95';
  Testingclass10 = '5f6864be0a19fd6e912fbf8b';
  Testingclass09 = '5f6864bb0a19fd6e912fbf8a';

  //************************************************** Testing End **************************************************/

  //************************************************** Live Starts **************************************************/
  LiveEnglish = '5f43aa7a0c46160c254a05d2';
  LiveTamil = '5f4e478e5957de337d6b303d';
  LiveBiology = '5f4e47b85957de337d6b3040';
  LivePhysics = '5f4e47a95957de337d6b303f';
  LiveChemistry = '5fab889119427312dc3a3d21';
  LiveComputerSceince = '5f4e47dd5957de337d6b3042';
  LiveScience = '5f4e47c95957de337d6b3041';
  LiveSocialScience = '5f4e47775957de337d6b303c';
  LiveMaths = '5f4e479f5957de337d6b303e';


  LiveStateBoard = '5f4e943d5957de337d6b3050';
  LiveCBSE = '5f43aa8c0c46160c254a05d3';


  LiveGrade12 = '5f6dd90e72e97f6cccb8812f';
  LiveGrade11 = '5f6dd90b72e97f6cccb8812e';
  LiveGrade10 = '5f6dd90872e97f6cccb8812d';
  LiveGrade09 = '5f6dd90572e97f6cccb8812c';

  Liveclass12 = '5f6dd90e72e97f6cccb8812f';
  Liveclass11 = '5f6dd90b72e97f6cccb8812e';
  Liveclass10 = '5f6dd90872e97f6cccb8812d';
  Liveclass09 = '5f6dd90572e97f6cccb8812c';

  //************************************************** Live End **************************************************/
  constructor(
    private userApi: ApiService,
    private router: Router,
    public toastr: ToastrManager
  ) { }

  ngOnInit(): void {
    // const webToken = localStorage.getItem('webtoken');

    var usertype = localStorage.getItem('profileType');
    if (usertype != null) {
      if (usertype.toLowerCase() == "teacher") {
        this.isteacher = true;
      }
      else {
        this.isstudent = true;
      }
    }
    else {
      this.isstudent = true;
    }




    // this.userApi.getCourseCategoryHeader().subscribe((result) => {
    //   if (result.status) {
    //     this.allheader = result.data;
    //     console.log('footer', this.allheader)
    //   } else {
    //   }
    // }, (err) => {
    //   console.log(err);
    // });
  }

     rout(){
      this.isLoggedin = localStorage.getItem('isLoggedin');
      var usertype1= localStorage.getItem('profileType');
    
      if(this.isLoggedin == 'false'){
        window.location.href = '/teacher/login';
      }
     else if (usertype1.toLowerCase() == "student") {
        this.isstudent = true;
      window.location.href = '/student/login';
            this.toastr.errorToastr('this User is not a Tutor');
      }
      else if(this.isLoggedin == 'true'){
        window.location.href = '/Institute-create';
      }
    }
  isteacherFooter(){
    this.isLoggedin = localStorage.getItem('isLoggedin');
    var usertype1= localStorage.getItem('profileType');
  
    if(this.isLoggedin == 'false'){
      window.location.href = '/teacher/login';
    }
   else if (usertype1.toLowerCase() == "student") {
      this.isstudent = true;

    window.location.href = '/student/login';
          this.toastr.errorToastr('This User is not a Tutor');

    }
    // if (usertype1.toLowerCase() == "teacher") {
    //   this.isteacher = false;

    // window.location.href = '/teacher/login';
    // }

    else if(this.isLoggedin == 'true'){

      window.location.href = '/sidenav';
    }
    else{
      window.location.href = '/teacher/login';

    }
  }

  //************************************************** Staging Starts **************************************************/
    english(){
      window.location.href ='/explore/'+this.stagingEnglish+'/all/all/all';
    }
    tamil(){
      window.location.href ='/explore/'+this.stagingTamil+'/all/all/all';
  }
  biology(){
    window.location.href ='/explore/'+this.stagingBiology+'/all/all/all';
  }
  physics(){
    window.location.href ='/explore/'+this.stagingPhysics+'/all/all/all';
  }
  chemistry(){
    window.location.href ='/explore/'+this.stagingChemistry+'/all/all/all';
  }
  computerscience(){
    window.location.href ='/explore/'+this.stagingComputerSceince+'/all/all/all';
  }
  science(){
    window.location.href ='/explore/'+this.stagingScience+'/all/all/all';
  }
  socialscience(){
    window.location.href ='/explore/'+this.stagingSocialScience+'/all/all/all';
  }
    maths(){
      window.location.href ='/explore/'+this.stagingMaths+'/all/all/all';
  }

  stateboard(){
    window.location.href ='/explore/all/'+this.stagingStateBoard+'/all/all';
  }
  cbse(){
    window.location.href ='/explore/all/'+this.stagingCBSE+'/all/all';
  }

  grade12(){
    window.location.href ='/explore/all/all/'+this.stagingGrade12+'/StudyMaterial';
  }
  grade11(){
    window.location.href ='/explore/all/all/'+this.stagingGrade11+'/StudyMaterial';
  }
  grade10(){
    window.location.href ='/explore/all/all/'+this.stagingGrade10+'/StudyMaterial';
  }
  grade9(){
    window.location.href ='/explore/all/all/'+this.stagingGrade09+'/StudyMaterial';
  }

  class12(){
    window.location.href ='/explore/all/all/'+this.stagingclass12+'/Videoclasses';
  }
  class11(){
    window.location.href ='/explore/all/all/'+this.stagingclass11+'/Videoclasses';
  }
  class10(){
    window.location.href ='/explore/all/all/'+this.stagingclass10+'/Videoclasses';
  }
  class9(){
    window.location.href ='/explore/all/all/'+this.stagingclass09+'/Videoclasses';
  }
  //************************************************** Staging End **************************************************/


  //************************************************** Testing Starts **************************************************/
  // english() {
  //   window.location.href = '/explore/' + this.TestingEnglish + '/all/all/all';
  // }
  // tamil() {
  //   window.location.href = '/explore/' + this.TestingTamil + '/all/all/all';
  // }
  // biology() {
  //   window.location.href = '/explore/' + this.TestingBiology + '/all/all/all';
  // }
  // physics() {
  //   window.location.href = '/explore/' + this.TestingPhysics + '/all/all/all';
  // }
  // chemistry() {
  //   window.location.href = '/explore/' + this.TestingChemistry + '/all/all/all';
  // }
  // computerscience() {
  //   window.location.href = '/explore/' + this.TestingComputerSceince + '/all/all/all';
  // }
  // science() {
  //   window.location.href = '/explore/' + this.TestingScience + '/all/all/all';
  // }
  // socialscience() {
  //   window.location.href = '/explore/' + this.TestingSocialScience + '/all/all/all';
  // }
  // maths() {
  //   window.location.href = '/explore/' + this.TestingMaths + '/all/all/all';
  // }

  // stateboard() {
  //   window.location.href = '/explore/all/' + this.TestingStateBoard + '/all/all';
  // }
  // cbse() {
  //   window.location.href = '/explore/all/' + this.TestingCBSE + '/all/all';
  // }

  // grade12() {
  //   window.location.href = '/explore/all/all/' + this.TestingGrade12 + '/StudyMaterial';
  // }
  // grade11() {
  //   window.location.href = '/explore/all/all/' + this.TestingGrade11 + '/StudyMaterial';
  // }
  // grade10() {
  //   window.location.href = '/explore/all/all/' + this.TestingGrade10 + '/StudyMaterial';
  // }
  // grade9() {
  //   window.location.href = '/explore/all/all/' + this.TestingGrade09 + '/StudyMaterial';
  // }

  // class12() {
  //   window.location.href = '/explore/all/all/' + this.Testingclass12 + '/Videoclasses';
  // }
  // class11() {
  //   window.location.href = '/explore/all/all/' + this.Testingclass11 + '/Videoclasses';
  // }
  // class10() {
  //   window.location.href = '/explore/all/all/' + this.Testingclass10 + '/Videoclasses';
  // }
  // class9() {
  //   window.location.href = '/explore/all/all/' + this.Testingclass09 + '/Videoclasses';
  // }
  //************************************************** Testing End **************************************************/


  //************************************************** Live Starts **************************************************/
  // english(){
  //   window.location.href ='/explore/'+this.LiveEnglish+'/all/all/all';
  // }
  // tamil(){
  //   window.location.href ='/explore/'+this.LiveTamil+'/all/all/all';
  // }
  // biology(){
  // window.location.href ='/explore/'+this.LiveBiology+'/all/all/all';
  // }
  // physics(){
  // window.location.href ='/explore/'+this.LivePhysics+'/all/all/all';
  // }
  // chemistry(){
  // window.location.href ='/explore/'+this.LiveChemistry+'/all/all/all';
  // }
  // computerscience(){
  // window.location.href ='/explore/'+this.LiveComputerSceince+'/all/all/all';
  // }
  // science(){
  // window.location.href ='/explore/'+this.LiveScience+'/all/all/all';
  // }
  // socialscience(){
  // window.location.href ='/explore/'+this.LiveSocialScience+'/all/all/all';
  // }
  // maths(){
  //   window.location.href ='/explore/'+this.LiveMaths+'/all/all/all';
  // }

  // stateboard(){
  // window.location.href ='/explore/all/'+this.LiveStateBoard+'/all/all';
  // }
  // cbse(){
  // window.location.href ='/explore/all/'+this.LiveCBSE+'/all/all';
  // }

  // grade12(){
  // window.location.href ='/explore/all/all/'+this.LiveGrade12+'/StudyMaterial';
  // }
  // grade11(){
  // window.location.href ='/explore/all/all/'+this.LiveGrade11+'/StudyMaterial';
  // }
  // grade10(){
  // window.location.href ='/explore/all/all/'+this.LiveGrade10+'/StudyMaterial';
  // }
  // grade9(){
  // window.location.href ='/explore/all/all/'+this.LiveGrade09+'/StudyMaterial';
  // }

  // class12(){
  // window.location.href ='/explore/all/all/'+this.Liveclass12+'/Videoclasses';
  // }
  // class11(){
  // window.location.href ='/explore/all/all/'+this.Liveclass11+'/Videoclasses';
  // }
  // class10(){
  // window.location.href ='/explore/all/all/'+this.Liveclass10+'/Videoclasses';
  // }
  // class9(){
  // window.location.href ='/explore/all/all/'+this.Liveclass09+'/Videoclasses';
  // }
  //************************************************** Live End **************************************************/


}
