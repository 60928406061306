<div class="row">
    <div class="col-lg-6">
        <div class="feedback-slides owl-carousel owl-theme">
            <div class="single-feedback-item">
                <p>“ I was looking for a good online learning provider for my daughter studying in 12th std. I am happy I found buddi Academy which takes a personalised approach to education, by giving individual attention to each student. The quality of teaching is excellent and I gladly recommend buddi to anyone who wants their child to excel.”</p>
        
                <div class="info">
                    <h3>Sridevi</h3>
                    <span>Chennai</span>
                    <!-- <img src="assets/img/user1.jpg" class="shadow rounded-circle" alt="image"> -->
                </div>
            </div>
        
            <div class="single-feedback-item">
                <p>“My daughter is a very bright student and a good chess player. She has been learning maths and chemistry from buddi team. She was able to pick up the concepts quickly and interact with the teacher very easily. Never felt it as an online class as it was very smooth and easy. Due to Corona kids are not able to go to school and learn thoroughly. Buddi can definitely help to clear all your weaker areas. I request all parents to try buddi.”</p>
        
                <div class="info">
                    <h3>Bindhu</h3>
                    <span>Chennai</span>
                   
                </div>
            </div>
        
            <!-- <div class="single-feedback-item">
                <p>“If a man empties his purse into his head, no man can take it away from him. An investment in knowledge always pays the best interest.”</p>
        
                <div class="info">
                    <h3>Lawrence</h3>
                    <span>India</span>
                    
                </div>
            </div> -->
        </div>
    </div>
    <!-- <div class="col-lg-6">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/W49HGEAWLYQ" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div> -->
    <div class="col-lg-6">
        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" data-interval="false">
            <ol class="carousel-indicators">
              <!-- <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="1"></li> -->
              <!-- <li data-target="#carouselExampleIndicators" data-slide-to="2"></li> -->
            </ol>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/W49HGEAWLYQ?enablejsapi=1&version=3&playerapiid=ytplayer" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div class="carousel-item">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/kxMF-lpJbzg?enablejsapi=1&version=3&playerapiid=ytplayer" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <!-- <div class="carousel-item">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/W49HGEAWLYQ" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div> -->
            </div>
            <a class="carousel-control-prev" style="width: 3%;" href="#carouselExampleIndicators" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" style="width: 3%;" href="#carouselExampleIndicators" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
    </div>
</div>
