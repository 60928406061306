

<section class="my-dashboard-area">
    <div class="container-fluid row p-0" style="cursor: pointer" *ngIf="data.length !=0">
    <div class="col-lg-3 col-md-6"
    *ngFor="let slide of data | paginate: { id: 'lib10',itemsPerPage: countv10, currentPage: v10 }">
    <div class="single-courses-box without-box-shadow mb-30">
        <div class="courses-image">

            <img (click)="videodetails(slide['videoId'])" style="width: 250px;height: 175px;"
                [src]="slide['videoId'].thumbname ? slide['videoId'].thumbname : 'assets/img/videocat-thumb-1.jpg'"
                alt="" width="100%">
        </div>
        <div class="courses-content">
            <div class="course-author d-flex align-items-center">
                <img [src]="slide['videoId'].teacherID!=undefined && slide['videoId'].teacherID.userPhoto!='' ?  slide['videoId'].teacherID.userPhoto :'assets/img/user1.jpg'"
                    class="rounded-circle mr-2" alt="image">
                <span
                    *ngIf="slide['videoId'].teacherID!=undefined">{{slide['videoId'].teacherID.profileName}}
                </span>
            </div>
            <h3>
                <a (click)="videodetails(slide['videoId'])"
                    class="d-inline-block">
                    <span class="text-break">{{slide['videoId'].flimname}}</span>
                </a>
            </h3>
        </div>
        <div>
            Type:
            {{slide['videoId'].securitytype=='Group' ?'School' : slide['videoId'].securitytype}}
        </div>
        <div class="courses-box-footer">
            {{slide['videoId'].categoryID ? slide['videoId'].categoryID.categoryName : '' }},
            {{slide['videoId'].gradeID ? slide['videoId'].gradeID.gradeName : '' }},
            {{slide['videoId'].boardID ? slide['videoId'].boardID.boardName : '' }}
        </div>
        <div class="courses-box-footer">
            {{slide['videoId'].subjectID ? slide['videoId'].subjectID.subjectName : '' }},
            {{slide['videoId'].languageID ? slide['videoId'].languageID.languageName : '' }}
        </div>
        <div *ngIf="slide['videoId'].isPartner" class="courses-box-footer">
            Provided By :{{slide['videoId'].partner ==null? slide['videoId'].providerByName ==''? 'buddi Premium' :  slide['videoId'].providerByName : slide['videoId'].partner.partnerName}}
        </div>
    </div>
</div>
</div>
</section>
<div style="float: right;" *ngIf="data.length !=0 ">
    <pagination-controls (pageChange)="v10 = $event" id="lib10"></pagination-controls>
</div>