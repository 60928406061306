import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../../../shared/api.service";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "../../../../../environments/environment";
import { DatePipe } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrManager } from "ng6-toastr-notifications";
const datepipe: DatePipe = new DatePipe("en-US");
@Component({
    selector: "app-list-mylearning",
    templateUrl: "./list-mylearning.component.html",
    styleUrls: ["./list-mylearning.component.scss"],
})
export class ListMylearningComponent implements OnInit {
    constructor(
        private rest: ApiService,
        private router: Router,
        private SpinnerService: NgxSpinnerService,
        public toastr: ToastrManager
    ) {}
    public classList: any = [];
    public videoList: any = [];
    public WebinarsList: any = [];
    public classroomList: any = [];
    public RoundList: any = [];
    public smartMaterialList: any = [];
    public testList: any = [];
    public mydashboardData: any = [];
    public isTeacher: Boolean = false;
    public isStudent: Boolean = false;
    public isLogin: any = "false";
    public currentPageNumber: number = 1;
    public totalVideoCount: number;
    public totalPageCount: number;
    public currentVideoCount: number;
    public currentLiveClassPageNumber = 1;
    public currentLiveClassFilter = 'none';
    public totalLiveClassPageCount = 1;
    term: string;
    public webinarData: any = [];
    isDesc: any = 1;
    column: string = "remainingClass";
    p: number = 1;
    count: number = 4;
    ngOnInit() {
        const token = localStorage.getItem("webtoken");
        var request = {};
        this.isTeacher =
            localStorage.getItem("profileType").toLowerCase() == "teacher";
        this.isStudent =
            localStorage.getItem("profileType").toLowerCase() == "student";
        var reqData = {
            notFetchCompleted: true,
            limit: 5
        };
        this.fetchLiveClasses(reqData);
        if (this.isTeacher) {
            this.rest
                .getUserStudyClassesTeacher(token, request)
                .subscribe((result) => {
                    console.log(result, "result===");
                    if (result.status) {
                        this.classroomList = result.data.data.splice(0, 3);
                        console.log(
                            "after respose===============",
                            this.classroomList
                        );
                        for (var item of this.classroomList) {
                            if (item.startDateTime) {
                                item.startDateTime = datepipe.transform(
                                    item.startDateTime,
                                    "MMM d, y, h:mm a"
                                );
                            }
                        }
                    }
                });
        } else {
            this.rest
                .getUserStudyClassesStudent(token, request)
                .subscribe((result) => {
                    console.log(result, "result======Studyhall");
                    if (result.status) {
                        this.classroomList = result.data.data.splice(0, 3);
                        console.log("after respose", this.classroomList);
                        for (var item of this.classroomList) {
                            if (item.startDateTime) {
                                item.startDateTime = datepipe.transform(
                                    item.startDateTime,
                                    "MMM d, y, h:mm a"
                                );
                            }
                        }
                    }
                });
        }
        if (this.isTeacher) {
            this.rest
                .getUserRoundClassesTeacher(token, request)
                .subscribe((result) => {
                    console.log(result, "result===");
                    if (result.status) {
                        this.RoundList = result.data.data.splice(0, 3);
                        console.log("after respose", this.RoundList);
                        for (var item of this.RoundList) {
                            if (item.startDateTime) {
                                item.startDateTime = datepipe.transform(
                                    item.startDateTime,
                                    "MMM d, y, h:mm a"
                                );
                            }
                        }
                    }
                });
        } else {
            this.rest
                .getUserRoundClassesStudent(token, request)
                .subscribe((result) => {
                    console.log(result, "result======Roundtable");
                    if (result.status) {
                        this.RoundList = result.data.data.splice(0, 3);
                        console.log("after respose", this.RoundList);
                        for (var item of this.RoundList) {
                            if (item.startDateTime) {
                                item.startDateTime = datepipe.transform(
                                    item.startDateTime,
                                    "MMM d, y, h:mm a"
                                );
                            }
                        }
                    }
                });
        }
        var request = {};
        // this.rest.getTestsByCategory(request).subscribe((result) => {
        //     if (result.status) {
        //         this.testList = result.data.Practice;
        //         for (var test of this.testList) {
        //             if (test.scheduledDate) {
        //                 test.scheduledDate = test.scheduledDate;
        //             }
        //         }
        //         console.log("after test", this.testList);
        //     }
        // });

        this.getPurchasedSmartMaterial();
        this.getPurchasedVideos();
        this.getPurchasedTests();
    }
    videodetails(data) {
        console.log("routing", data);
        this.router.navigateByUrl("/videoDetails/" + data.videoID._id);
    }

    getPurchasedVideos() {
        const webToken = localStorage.getItem("webtoken");
        if (this.isTeacher) {
            this.rest.NewVideoListing(webToken, {}).subscribe((response) => {
                console.log("videos", response);
                if (response.status) {
                    this.videoList = response.data.data.splice(0, 3);
                    for (var video of this.videoList) {
                        console.log(
                            video.thumbnail != null && video.thumbnail == ""
                        );
                        if (video.thumbnail != null && video.thumbnail == "") {
                            console.log("1");
                            video.thumbnail = "assets/img/courses/courses1.jpg";
                        }
                    }
                    console.log(this.videoList);
                }
            });
        } else {
            // this.rest.getUserVideosStudent(webToken, {}).subscribe((response) => {
            //   console.log("videos=============", response)
            //   if(response.status){
            //     this.videoList = response.data.data.splice(0,3);
            //     for(var video of this.videoList){
            //       console.log(video.thumbnail != null && video.thumbnail == "")
            //       if(video.thumbnail != null && video.thumbnail == ""){
            //         console.log("222")
            //         video.thumbnail = "assets/img/courses/courses1.jpg"
            //       }
            //     }
            //     console.log(this.videoList)
            //   }
            // })

            this.rest.getPurchasedVideos(webToken).subscribe((response) => {
                console.log("videos", response);
                if (response.status) {
                    this.videoList = response.data.splice(0, 3);
                    for (var video of this.videoList) {
                        console.log(
                            video.thumbnail != null && video.thumbnail == ""
                        );
                        if (video.thumbnail != null && video.thumbnail == "") {
                            console.log("1");
                            video.thumbnail = "assets/img/courses/courses1.jpg";
                        }
                    }
                    console.log(this.videoList);
                }
            });
        }
    }

    getPurchasedSmartMaterial() {
        const webToken = localStorage.getItem("webtoken");
        if (this.isTeacher) {
            this.rest
                .NewSmartMaterialListing(webToken, {})
                .subscribe((response) => {
                    console.log("material", response);
                    if (response.status) {
                        this.smartMaterialList = response.data.data.splice(
                            0,
                            3
                        );
                        for (var material of this.smartMaterialList) {
                            if (
                                material.thumbnail &&
                                material.thumbnail == ""
                            ) {
                                material.thumbnail =
                                    "slide/img/courses/courswes1.jpg";
                            }
                        }
                        console.log(this.smartMaterialList);
                    }
                });
        } else {
            this.rest
                .getPurchasedSmartMaterial(webToken)
                .subscribe((response) => {
                    console.log("material", response);
                    if (response.status) {
                        this.smartMaterialList = response.data.splice(0, 3);
                        for (var material of this.smartMaterialList) {
                            if (
                                material.thumbnail &&
                                material.thumbnail == ""
                            ) {
                                material.thumbnail =
                                    "slide/img/courses/courses1.jpg";
                            }
                        }
                        console.log(this.smartMaterialList);
                    }
                });
        }
    }

    getPurchasedTests() {
        const webToken = localStorage.getItem("webtoken");
        if (this.isStudent) {
            this.rest.getPurchasedTests(webToken).subscribe((response) => {
                console.log("test", response);
                if (response.status) {
                    this.testList = response.data.splice(0, 3);
                    for (var test of this.testList) {
                        if (test.thumbnail && test.thumbnail == "") {
                            test.thumbnail = "slide/img/courses/courses1.jpg";
                        }
                    }
                    console.log(this.testList);
                }
            });
        } else {
            this.rest
                .testscorrectionbyTeacher(webToken)
                .subscribe((response) => {
                    console.log("test", response);
                    if (response.status) {
                        if (response.data.length > 3) {
                            this.testList = response.data.splice(0, 3);
                        } else {
                            this.testList = response.data;
                        }
                        for (var test of this.testList) {
                            if (test.thumbnail && test.thumbnail == "") {
                                test.thumbnail =
                                    "slide/img/courses/courses1.jpg";
                            }
                        }
                        console.log(this.testList);
                    }
                });
        }
    }

    getClassRoomList() {
        const webToken = localStorage.getItem("webtoken");
        var request = {};
        request["limit"] = environment.listing__itemsPerPage;
        request["page"] = 1;
        this.rest.getClassRoomTeacher(webToken, request).subscribe(
            (response) => {
                console.log("response", response);
                if (
                    response.status &&
                    response.data != null &&
                    response.data.length != 0
                ) {
                    this.classroomList = response.data.classroomsrecord.splice(
                        0,
                        3
                    );
                    console.log(this.classroomList, "classroomsrecord");
                    // console.log(this.videoList)
                } else {
                    this.mydashboardData = [];
                }
            },
            (err) => {
                console.log(err);
            }
        );
    }
    testDetails(data) {
        if (this.isTeacher) {
            this.router.navigate(["/teacher-correction-page/" + data._id], {
                state: data,
            });
        } else {
            this.router.navigate(["/test-details-student/" + data._id], {
                state: data,
            });
        }
    }
    detailsMeeting(data, pagename) {
        data.pagename = pagename;
        var jsonStr = JSON.stringify(data);
        localStorage.setItem("livedetails", jsonStr);
        this.router.navigate(
            ["/student/meetingDetails/" + "/" + data.meetingID],
            { state: data }
        );
    }
    liveClassDetails(data) {
        console.log(data);
        if (this.isTeacher) {
            this.router.navigate([
                "live-class-details/" + data.personalClassID._id,
            ]);
        } else {
            console.log("fdfdf");
            this.router.navigate(["live-class-request-details/" + data._id]);
        }
    }
    StudyhallClassDetails(data) {
        if (this.isTeacher) {
            this.router.navigate(["studyhall-details/" + data._id]);
        } else {
            console.log("fdfdf");
            this.router.navigate(["studyhall-request-details/" + data._id]);
            // live-class-request-listing
        }
    }
    RoundtableClassDetails(data) {
        if (this.isTeacher) {
            this.router.navigate(["roundtable-details/" + data._id]);
        } else {
            console.log("fdfdf");
            this.router.navigate(["roundtable-request-details/" + data._id]);
        }
    }
    detailsVideo(data) {
        this.router.navigate(["videoDetails/" + data._id]);
    }
    detailsSmartMaterial(data) {
        this.router.navigate(["studyMaterialDetails/" + data._id]);
    }
    redirectLiveclass() {
        this.router.navigateByUrl("/all-liveclass-listing");
    }
    redirectStudyhall() {
        this.router.navigateByUrl("/all-studyhall-listing");
    }
    redirectRoundtable() {
        this.router.navigateByUrl("/all-roundtable-listing");
    }
    redirectVideoclasses() {
        this.router.navigateByUrl("/video-selected-listing");
    }
    redirectSmartmaterial() {
        this.router.navigateByUrl("/material-selected-listing");
    }
    redirectbuddiTests() {
        this.router.navigateByUrl("/budditest-selected-listing");
    }

    liveClassFunction(liveDetails, action) {
        if (action == "start" || action == "join") {
            this.rest
                .meetingActionLiveClass(
                    localStorage.getItem("webtoken"),
                    liveDetails._id,
                    action,
                    "liveClass"
                )
                .subscribe(
                    (result) => {
                        this.SpinnerService.hide();
                        if (result.status) {
                            this.SpinnerService.show();
                            var reqData = {
                                courseID: liveDetails.personalClassID.courseID,
                                topicID:
                                    liveDetails.personalClassID.topicID._id,
                                methodOfLearning: "videoclass",
                            };
                            console.log(reqData);
                            this.rest
                                .addCourseCompletion(
                                    localStorage.getItem("webtoken"),
                                    reqData
                                )
                                .subscribe((response) => {
                                    console.log(response);
                                    setTimeout(function () {
                                        location.href = result.data[0];
                                    }, 1000);
                                    this.SpinnerService.hide();
                                });
                        } else {
                            if (result.message == "Meeting Not Started.") {
                                var datajson = {
                                    date: liveDetails.startDateTime,
                                };
                                this.router.navigate(["/meetingnotstart"], {
                                    state: datajson,
                                });
                            } else {
                                this.toastr.errorToastr(result.message);
                            }
                        }
                    },
                    (err) => {
                        console.log(err);
                    }
                );
        } else if (action == "share") {
            var isMobile = false; //initiate as false

            let frontwebsites = environment.frontUrl;
            if (
                /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
                    navigator.userAgent
                ) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                    navigator.userAgent.substr(0, 4)
                )
            ) {
                isMobile = true;
            }
            if (isMobile) {
                window.open(
                    "https://api.whatsapp.com/send?text=https://www.buddi.life/live-class-details/" +
                        liveDetails._id
                );
            } else {
                window.open(
                    "https://web.whatsapp.com/send?text=https://www.buddi.life/live-class-details/" +
                        liveDetails._id
                );
            }
        }
    }

    liveClassFilter(filter) {
        if(this.currentLiveClassFilter == filter){

        }
        else{
            this.currentLiveClassFilter = filter;
            this.currentPageNumber = 1;
            var reqData = {
                notFetchCompleted: true,
                filter: filter,
                limit: 5
            };
            this.fetchLiveClasses(reqData);
        }
        
    }

    fetchLiveClasses(reqData){
        const token =  localStorage.getItem("webtoken");
        if (this.isTeacher) {
            this.rest
                .getUserPersonalClassRequestsTeacher(token, reqData)
                .subscribe((result) => {
                    console.log("result");
                    console.log(result)
                    if (result.status) {
                        this.classList =  result.data && result.data.data ? result.data.data.upcoming : [];
                        this.totalLiveClassPageCount = this.totalPageCount = Math.ceil(result.data.totalCount / 5)
                        for (var item of this.classList) {
                            if (item.startDateTime) {
                                item.startDateTime = datepipe.transform(
                                    item.startDateTime,
                                    "MMM d, y, h:mm a"
                                );
                            }
                        }
                    }
                });
        } else {
            this.rest
                .getUserPersonalClassRequestsStudent(token, reqData)
                .subscribe((result) => {
                    console.log("result");
                    console.log(result)
                    if (result.status) {
                        this.classList = result.data && result.data.data ? result.data.data.upcoming : [];
                        this.totalLiveClassPageCount = this.totalPageCount = Math.ceil(result.data.totalCount / 5)
                        for (var item of this.classList) {
                            if (item.startDateTime) {
                                item.startDateTime = datepipe.transform(
                                    item.startDateTime,
                                    "MMM d, y, h:mm a"
                                );
                            }
                        }
                    }
                });
        }
    }

    clickLiveClassPageNumber(pageNumber) {
        this.currentLiveClassPageNumber = pageNumber;
        console.log(this.currentLiveClassPageNumber);
        var reqData = {
            notFetchCompleted: true,
            filter: this.currentLiveClassFilter,
            limit: 5,
            page: pageNumber
        };
        this.fetchLiveClasses(reqData)
      }
}
