<div *ngIf="isStudent == true && isTeacher == false" class="container mt-3">
   <h3
        style="
            color: #000000;
            cursor: pointer;
            border-bottom: 1px solid #f7f0f0 !important;
        "
        class="col-lg-12"
    >
        Live Classes
        <span class="more-text" *ngIf="classList">more</span>
    </h3>
    <br />
    <div class="container-fluid row" *ngIf="classList">
        <div class="orders-table table-responsive">
        <br>
        <br>
        <table class="table">
            <thead>
                <tr>
                    <th>S. No</th>
                    <th>Class Name</th>
                    <th>Topic Name</th>
                    <th>Date</th>
                    <th>Tutor Name</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor='let class of classList; let i = index'>
                    <td>{{i + 1}}</td>
                    <td>{{class.name}}</td>
                    <td>{{class.personalClassID.topicID.topicName}}</td>
                    <td>{{class.startDateTime}}</td>
                    <td>{{class.teacherID.firstName}} {{class.teacherID.lastName}}</td>
                    <td><a href="javascript:void(0)" [ngClass]="class.isAccepted == true ? 'classPending' : 'classAccepted'" (click)="liveClassFunction(class, 'accept')"> Accept -> Start</a><br>
                    <a href="javascript:void(0)" [ngClass]="class.isAccepted == true ? 'classPending' : 'classAccepted'" (click)="liveClassFunction(class, 'reschedule')"> Reschedule</a><br>
                    <a href="javascript:void(0)" [ngClass]="class.isAccepted == true ? 'classPending' : 'classAccepted'" (click)="liveClassFunction(class, 'cancel')"> Cancel</a><br>
                    <a href="javascript:void(0)" [ngClass]="class.isAccepted == true ? 'classPending' : 'classAccepted'" (click)="liveClassFunction(class, 'share')"> Share</a></td>
                </tr>
            </tbody>
        </table>
        <div class="pagination-area text-center">
                    <a
                        href="javascript:void(0)"
                        (click)="clickLiveClassPageNumber(1)"
                        *ngIf="currentLiveClassPageNumber > 2 && totalLiveClassPageCount > 1"
                        class="page-numbers"
                        >1</a
                    >
                    <span
                        class="page-numbers"
                        *ngIf="currentLiveClassPageNumber > 2 && totalLiveClassPageCount > 1"
                        aria-current="page"
                        >...</span
                    >
                    <a
                        href="javascript:void(0)"
                        (click)="clickLiveClassPageNumber(currentLiveClassPageNumber - 1)"
                        *ngIf="currentLiveClassPageNumber > 1 && totalLiveClassPageCount > 1"
                        class="page-numbers"
                        >{{ currentLiveClassPageNumber - 1 }}</a
                    >
                    <span
                        class="page-numbers current"
                        *ngIf="totalLiveClassPageCount != 0"
                        aria-current="page"
                        >{{ currentLiveClassPageNumber }}</span
                    >
                    <a
                        href="javascript:void(0)"
                        (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 1)"
                        *ngIf="
                            totalLiveClassPageCount > 1 &&
                            currentLiveClassPageNumber + 1 <= totalLiveClassPageCount
                        "
                        class="page-numbers"
                        >{{ currentLiveClassPageNumber + 1 }}</a
                    >
                    <a
                        href="javascript:void(0)"
                        (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 2)"
                        *ngIf="
                            totalLiveClassPageCount > 1 &&
                            currentLiveClassPageNumber + 2 <= totalLiveClassPageCount
                        "
                        class="page-numbers"
                        >{{ currentLiveClassPageNumber + 2 }}</a
                    >
                    <a
                        href="javascript:void(0)"
                        (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 3)"
                        *ngIf="
                            totalLiveClassPageCount > 1 &&
                            currentLiveClassPageNumber + 3 <= totalLiveClassPageCount
                        "
                        class="page-numbers"
                        >{{ currentLiveClassPageNumber + 3 }}</a
                    >
                    <a
                        href="javascript:void(0)"
                        (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 4)"
                        *ngIf="
                            totalLiveClassPageCount > 1 &&
                            currentLiveClassPageNumber + 4 <= totalLiveClassPageCount
                        "
                        class="page-numbers"
                        >{{ currentLiveClassPageNumber + 4 }}</a
                    >
                    <a
                        href="javascript:void(0)"
                        (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 1)"
                        *ngIf="
                            totalLiveClassPageCount > 1 &&
                            currentLiveClassPageNumber + 1 <= totalLiveClassPageCount
                        "
                        class="next page-numbers"
                        ><i class="bx bx-chevron-right"></i
                    ></a>
                </div>
    </div>
        <div
            style="color: #aba3a3"
            class="col-lg-12"
            *ngIf="classList.length == 0"
        >
            No Live Classes Available.
        </div>
    </div>
</div>

<div *ngIf="isTeacher == true" class="container mt-3">
    <!-- <h3 style="color: var(--mainColor); cursor: pointer" class="col-lg-12">
        Live Classes
    </h3> --><br />
    <h3
        style="
            color: #000000;
            cursor: pointer;
            border-bottom: 1px solid #f7f0f0 !important;
        "
        class="col-lg-12"
    >
        Live Classes
        <span class="more-text" *ngIf="classList.length != 0">more</span>
    </h3>
    <br />
    <div class="container-fluid row" *ngIf="classList.length != 0">
        <div class="orders-table table-responsive">
        <a
            href="javascript:void(0)"
            (click)="liveClassFilter('today')"
            class="default-btn mr-1"
        >
            <span class="label">Today</span>
        </a>
        <a
            href="javascript:void(0)"
            (click)="liveClassFilter('tomorrow')"
            class="default-btn mr-1"
        >
            <span class="label">Tomorrow</span>
        </a>
        <a
            href="javascript:void(0)"
            (click)="liveClassFilter('week')"
            class="default-btn mr-1"
        >
            <span class="label">This Week</span>
        </a>
        <a
            href="javascript:void(0)"
            (click)="liveClassFilter('month')"
            class="default-btn mr-1"
        >
            <span class="label">This Month</span>
        </a>
        <br>
        <br>
        <table class="table">
            <thead>
                <tr>
                    <th>S. No</th>
                    <th>Class Name</th>
                    <th>Topic Name</th>
                    <th>Date</th>
                    <th>Learner Name</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor='let class of classList; let i = index'>
                    <td>{{i + 1}}</td>
                    <td>{{class.name}}</td>
                    <td>{{class.personalClassID.topicID.topicName}}</td>
                    <td>{{class.startDateTime}}</td>
                    <td>{{class.studentID.firstName}} {{class.studentID.lastName}}</td>
                    <td><a href="javascript:void(0)" [ngClass]="class.isAccepted == true ? 'classPending' : 'classAccepted'" (click)="liveClassFunction(class, 'start')"> Accept -> Start</a><br>
                    <a href="javascript:void(0)" [ngClass]="class.isAccepted == true ? 'classPending' : 'classAccepted'" (click)="liveClassFunction(class, 'reschedule')"> Reschedule</a><br>
                    <a href="javascript:void(0)" [ngClass]="class.isAccepted == true ? 'classPending' : 'classAccepted'" (click)="liveClassFunction(class, 'cancel')"> Cancel</a><br>
                    <a href="javascript:void(0)" [ngClass]="class.isAccepted == true ? 'classPending' : 'classAccepted'" (click)="liveClassFunction(class, 'share')"> Share</a></td>
                </tr>
            </tbody>
        </table>
        <div class="pagination-area text-center">
                    <a
                        href="javascript:void(0)"
                        (click)="clickLiveClassPageNumber(1)"
                        *ngIf="currentLiveClassPageNumber > 2 && totalLiveClassPageCount > 1"
                        class="page-numbers"
                        >1</a
                    >
                    <span
                        class="page-numbers"
                        *ngIf="currentLiveClassPageNumber > 2 && totalLiveClassPageCount > 1"
                        aria-current="page"
                        >...</span
                    >
                    <a
                        href="javascript:void(0)"
                        (click)="clickLiveClassPageNumber(currentLiveClassPageNumber - 1)"
                        *ngIf="currentLiveClassPageNumber > 1 && totalLiveClassPageCount > 1"
                        class="page-numbers"
                        >{{ currentLiveClassPageNumber - 1 }}</a
                    >
                    <span
                        class="page-numbers current"
                        *ngIf="totalLiveClassPageCount != 0"
                        aria-current="page"
                        >{{ currentLiveClassPageNumber }}</span
                    >
                    <a
                        href="javascript:void(0)"
                        (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 1)"
                        *ngIf="
                            totalLiveClassPageCount > 1 &&
                            currentLiveClassPageNumber + 1 <= totalLiveClassPageCount
                        "
                        class="page-numbers"
                        >{{ currentLiveClassPageNumber + 1 }}</a
                    >
                    <a
                        href="javascript:void(0)"
                        (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 2)"
                        *ngIf="
                            totalLiveClassPageCount > 1 &&
                            currentLiveClassPageNumber + 2 <= totalLiveClassPageCount
                        "
                        class="page-numbers"
                        >{{ currentLiveClassPageNumber + 2 }}</a
                    >
                    <a
                        href="javascript:void(0)"
                        (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 3)"
                        *ngIf="
                            totalLiveClassPageCount > 1 &&
                            currentLiveClassPageNumber + 3 <= totalLiveClassPageCount
                        "
                        class="page-numbers"
                        >{{ currentLiveClassPageNumber + 3 }}</a
                    >
                    <a
                        href="javascript:void(0)"
                        (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 4)"
                        *ngIf="
                            totalLiveClassPageCount > 1 &&
                            currentLiveClassPageNumber + 4 <= totalLiveClassPageCount
                        "
                        class="page-numbers"
                        >{{ currentLiveClassPageNumber + 4 }}</a
                    >
                    <a
                        href="javascript:void(0)"
                        (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 1)"
                        *ngIf="
                            totalLiveClassPageCount > 1 &&
                            currentLiveClassPageNumber + 1 <= totalLiveClassPageCount
                        "
                        class="next page-numbers"
                        ><i class="bx bx-chevron-right"></i
                    ></a>
                </div>
    </div>
        <div
            style="color: #aba3a3"
            class="col-lg-12"
            *ngIf="classList.length == 0"
        >
            No Live Classes Available.
        </div>
    </div>
</div>

<div *ngIf="isTeacher == true && isStudent != true" class="container mt-3">
    <!-- <h3 style="color: var(--mainColor); cursor: pointer" class="col-lg-12">
        buddi Tests
    </h3> --><br />
    <h3
        (click)="redirectbuddiTests()"
        style="
            color: #000000;
            cursor: pointer;
            border-bottom: 1px solid #f7f0f0 !important;
        "
        class="col-lg-12"
    >
        buddi Tests
        <span class="more-text" *ngIf="testList.length != 0">more</span>
    </h3>
    <br />
    <div class="container-fluid row" style="cursor: pointer">
        <div class="col-lg-4 col-md-6 float:left" *ngFor="let test of testList">
            <div class="single-courses-item mb-30 p-4">
                <h3>
                    <a
                        routerLink="javascript:void(0)"
                        (click)="testDetails(test)"
                        class="d-inline-block"
                        style="height: 60px"
                        >{{ test.testID.testName }}</a
                    >
                </h3>
                <div style="overflow: hidden">
                    <p style="float: left">
                        <i class="bx bx-calendar"></i> {{ test.dateTaken }}
                    </p>
                    <p style="float: right">
                        {{ test.totalTime ? test.totalTime : 30 }} mins
                    </p>
                </div>
                <div class="courses-content px-0 my-3">
                    <div class="course-author d-flex align-items-center">
                        <img
                            [src]="
                                test.studentID.firstName &&
                                test.studentID.firstName &&
                                test.studentID.userPhoto != ''
                                    ? test.studentID.userPhoto
                                    : 'assets/img/profilePhotoPlaceholder1.png'
                            "
                            class="shadow"
                            alt="image"
                        />
                        <div class="w-100 d-flex justify-content-between">
                            <span *ngIf="test.testID">{{
                                test.testID.languageID.languageName
                            }}</span>
                        </div>
                    </div>

                    <div
                        *ngIf="test.rating && test.rating != 0"
                        class="courses-rating"
                    >
                        <div class="review-stars-rated">
                            <i
                                *ngIf="test.rating < 1"
                                class="bx bxs-star-half"
                            ></i>
                            <i *ngIf="test.rating >= 1" class="bx bxs-star"></i>
                            <i
                                *ngIf="test.rating >= 1.5 && test.rating < 2"
                                class="bx bxs-star-half"
                            ></i>
                            <i
                                *ngIf="test.rating >= 2"
                                classd-flex
                                align-items-center="bx bxs-star"
                            ></i>
                            <i
                                *ngIf="test.rating >= 3 && test.rating > 3.5"
                                class="bx bxs-star-half"
                            ></i>
                            <i *ngIf="test.rating >= 4" class="bx bxs-star"></i>
                            <i
                                *ngIf="test.rating >= 4 && test.rating < 4.5"
                                class="bx bxs-star-half"
                            ></i>
                            <i *ngIf="test.rating >= 5" class="bx bxs-star"></i>
                        </div>
                        <div class="rating-total">
                            {{ test.rating }}
                        </div>
                    </div>
                </div>
                <div class="gradeContent">
                    <h4 *ngIf="test.testID">
                        {{
                            test.testID.categoryID
                                ? test.testID.categoryID.categoryName
                                : ""
                        }}
                    </h4>
                    <!-- <p>{{slide.courseID.courseName}}</p> -->
                    <p class="text-overflow" *ngIf="test.testID">
                        {{
                            test.testID.moduleID
                                ? test.testID.moduleID.moduleName
                                : "NA"
                        }}
                    </p>
                    <!--<p class="text-overflow" *ngIf="test.testID"> {{ test.testID.topicID
                        ? test.testID.topicID.topicName
                        : "NA" }}</p>-->
                </div>

                <div class="courses-box-footer mt-3">
                    <ul>
                        <li
                            class="courses-lesson _leeson_2"
                            *ngIf="test.testID"
                        >
                            <i class="bx bx-book-open"></i> Level
                            {{ test.testID.level ? test.testID.level : 1 }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div
            style="color: #aba3a3"
            class="col-lg-12"
            *ngIf="classList.length == 0"
        >
            No Live Classes Available.
        </div>
    </div>
</div>

<div *ngIf="isTeacher != true && isStudent == true" class="container mt-3">
    <!-- <h3 style="color: var(--mainColor); cursor: pointer" class="col-lg-12">
        buddi Tests
    </h3> -->
    <h3
        (click)="redirectbuddiTests()"
        style="color: #000000; cursor: pointer"
        class="col-lg-12"
    >
        buddi Tests
        <span class="more-text" *ngIf="testList.length != 0">more</span>
    </h3>
    <br />
    <div class="container-fluid row" style="cursor: pointer">
        <div class="col-lg-4 col-md-6 float:left" *ngFor="let test of testList">
            <div class="single-courses-item mb-30 p-4">
                <h3>
                    <a
                        routerLink="javascript:void(0)"
                        (click)="testDetails(test)"
                        class="d-inline-block"
                        style="height: 60px"
                        >{{ test.testName }}</a
                    >
                </h3>
                <div style="overflow: hidden">
                    <p style="float: right">
                        {{ test.totalTime ? test.totalTime : 30 }} mins
                    </p>
                </div>
                <div class="courses-content px-0 my-3">
                    <div class="course-author d-flex align-items-center">
                        <img
                            [src]="
                                test.teacherID.firstName &&
                                test.teacherID.firstName &&
                                test.teacherID.userPhoto != ''
                                    ? test.teacherID.userPhoto
                                    : 'assets/img/profilePhotoPlaceholder1.png'
                            "
                            class="shadow"
                            alt="image"
                        />
                        <div class="w-100 d-flex justify-content-between">
                            <span *ngIf="test">{{
                                test.languageID.languageName
                            }}</span>
                        </div>
                    </div>

                    <div
                        *ngIf="test.rating && test.rating != 0"
                        class="courses-rating"
                    >
                        <div class="review-stars-rated">
                            <i
                                *ngIf="test.rating < 1"
                                class="bx bxs-star-half"
                            ></i>
                            <i *ngIf="test.rating >= 1" class="bx bxs-star"></i>
                            <i
                                *ngIf="test.rating >= 1.5 && test.rating < 2"
                                class="bx bxs-star-half"
                            ></i>
                            <i
                                *ngIf="test.rating >= 2"
                                classd-flex
                                align-items-center="bx bxs-star"
                            ></i>
                            <i
                                *ngIf="test.rating >= 3 && test.rating > 3.5"
                                class="bx bxs-star-half"
                            ></i>
                            <i *ngIf="test.rating >= 4" class="bx bxs-star"></i>
                            <i
                                *ngIf="test.rating >= 4 && test.rating < 4.5"
                                class="bx bxs-star-half"
                            ></i>
                            <i *ngIf="test.rating >= 5" class="bx bxs-star"></i>
                        </div>
                        <div class="rating-total">
                            {{ test.rating }}
                        </div>
                    </div>
                </div>
                <div class="gradeContent">
                    <h4 *ngIf="test">
                        {{
                            test.categoryID ? test.categoryID.categoryName : ""
                        }}
                    </h4>
                    <!-- <p>{{slide.courseID.courseName}}</p> -->
                    <p class="text-overflow" *ngIf="test">
                        {{ test.moduleID ? test.moduleID.moduleName : "NA" }}
                    </p>
                    <p class="text-overflow" *ngIf="test">
                        {{ test.topicID ? test.topicID.topicName : "NA" }}
                    </p>
                </div>

                <div class="courses-box-footer mt-3">
                    <ul>
                        <li class="courses-lesson _leeson_2" *ngIf="test">
                            <i class="bx bx-book-open"></i> Level
                            {{ test.level ? test.level : 1 }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div
            style="color: #aba3a3"
            class="col-lg-12"
            *ngIf="testList.length == 0"
        >
            No Live Classes Available.
        </div>
    </div>
</div>

<div *ngIf="isStudent == true && isTeacher == false" class="container mt-3">
    <!-- <h3 style="color: var(--mainColor); cursor: pointer" class="col-lg-12">
 Study Hall Classes
    </h3> -->
    <h3
        (click)="redirectStudyhall()"
        style="
            color: #000000;
            cursor: pointer;
            border-bottom: 1px solid #f7f0f0 !important;
        "
        class="col-lg-12"
    >
        Study Hall Classes
        <span class="more-text" *ngIf="classroomList.length != 0">more</span>
    </h3>
    <br />
    <div class="container-fluid row" style="cursor: pointer">
        <div
            class="col-lg-4 col-md-6 float:left"
            *ngFor="let class of classroomList"
        >
            <div class="single-courses-item mb-30 p-4">
                <h3>
                    <a
                        routerLink="javascript:void(0)"
                        (click)="StudyhallClassDetails(class)"
                        class="d-inline-block"
                        style="height: 60px"
                        >{{ class.personalClassID.className }}</a
                    >
                </h3>
                <div style="overflow: hidden">
                    <p style="float: left">
                        <i class="bx bx-calendar"></i>
                        {{
                            class.startDateTime
                                ? class.startDateTime
                                : "Class Template"
                        }}
                    </p>
                    <p style="float: right">
                        {{
                            class.totalClassTime ? class.totalClassTime : 30
                        }}
                        mins
                    </p>
                </div>
                <div class="courses-content px-0 my-3">
                    <div class="course-author d-flex align-items-center">
                        <img
                            [src]="
                                class.teacherID &&
                                class.teacherID.userPhoto &&
                                class.teacherID.userPhoto != ''
                                    ? class.teacherID.userPhoto
                                    : 'assets/img/profilePhotoPlaceholder1.png'
                            "
                            class="shadow"
                            alt="image"
                        />
                        <div class="w-100 d-flex justify-content-between">
                            <span>{{
                                class.teacherID
                                    ? class.teacherID.profileName
                                    : ""
                            }}</span>
                            <span>{{ class.languageID.languageName }}</span>
                        </div>
                    </div>

                    <div
                        *ngIf="class.rating && class.rating != 0"
                        class="courses-rating"
                    >
                        <div class="review-stars-rated">
                            <i
                                *ngIf="class.rating < 1"
                                class="bx bxs-star-half"
                            ></i>
                            <i
                                *ngIf="class.rating >= 1"
                                class="bx bxs-star"
                            ></i>
                            <i
                                *ngIf="class.rating >= 1.5 && class.rating < 2"
                                class="bx bxs-star-half"
                            ></i>
                            <i
                                *ngIf="class.rating >= 2"
                                classd-flex
                                align-items-center="bx bxs-star"
                            ></i>
                            <i
                                *ngIf="class.rating >= 3 && class.rating > 3.5"
                                class="bx bxs-star-half"
                            ></i>
                            <i
                                *ngIf="class.rating >= 4"
                                class="bx bxs-star"
                            ></i>
                            <i
                                *ngIf="class.rating >= 4 && class.rating < 4.5"
                                class="bx bxs-star-half"
                            ></i>
                            <i
                                *ngIf="class.rating >= 5"
                                class="bx bxs-star"
                            ></i>
                        </div>
                        <div class="rating-total">
                            {{ class.rating }}
                        </div>
                    </div>
                </div>
                <div class="gradeContent">
                    <h4>
                        {{
                            class.personalClassID.categoryID
                                ? class.personalClassID.categoryID.categoryName
                                : ""
                        }}
                    </h4>
                    <!-- <p>{{slide.courseID.courseName}}</p> -->
                    <p class="text-overflow">
                        {{
                            class.personalClassID.moduleID
                                ? class.personalClassID.moduleID.moduleName
                                : "NA"
                        }}
                    </p>
                    <p class="text-overflow">
                        {{
                            class.personalClassID.topicID
                                ? class.personalClassID.topicID.topicName
                                : "NA"
                        }}
                    </p>
                </div>

                <div class="courses-box-footer mt-3">
                    <ul>
                        <li class="courses-lesson">
                            <i class="fa fa-rupee-sign"></i>

                            {{
                                class.personalClassID.price != 0
                                    ? class.personalClassID.price
                                    : "Free"
                            }}

                            <!-- <i class='bx bx-book-open'></i> {{slide.totalClassTime ? slide.totalClassTime : 30}} mins -->
                        </li>
                        <li class="courses-lesson _leeson_2">
                            <i class="bx bx-book-open"></i> Level
                            {{
                                class.personalClassID.level
                                    ? class.personalClassID.level
                                    : 1
                            }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div
            style="color: #aba3a3"
            class="col-lg-12"
            *ngIf="classroomList.length == 0"
        >
            No Study Hall Classes Available.
        </div>
    </div>
</div>
<div *ngIf="isTeacher == true" class="container mt-3">
    <!-- <h3 style="color: var(--mainColor); cursor: pointer" class="col-lg-12">
        Studyhall Classes
    </h3> --><br />
    <h3
        (click)="redirectStudyhall()"
        style="
            color: #000000;
            cursor: pointer;
            border-bottom: 1px solid #f7f0f0 !important;
        "
        class="col-lg-12"
    >
        Study Hall Classes
        <span class="more-text" *ngIf="classroomList.length != 0">more</span>
    </h3>
    <div class="container-fluid row" style="cursor: pointer">
        <div
            class="col-lg-4 col-md-6 float:left"
            *ngFor="let class of classroomList"
        >
            <div class="single-courses-item mb-30 p-4">
                <h3>
                    <a
                        routerLink="javascript:void(0)"
                        (click)="StudyhallClassDetails(class)"
                        class="d-inline-block"
                        style="height: 60px"
                        >{{ class.className }}</a
                    >
                </h3>
                <div style="overflow: hidden">
                    <p style="float: left">
                        <i class="bx bx-calendar"></i>
                        {{
                            class.startDateTime
                                ? class.startDateTime
                                : "Class Template"
                        }}
                    </p>
                    <p style="float: right">
                        {{
                            class.totalClassTime ? class.totalClassTime : 30
                        }}
                        mins
                    </p>
                </div>
                <div class="courses-content px-0 my-3">
                    <div class="course-author d-flex align-items-center">
                        <img
                            [src]="
                                class.teacherID &&
                                class.teacherID.userPhoto &&
                                class.teacherID.userPhoto != ''
                                    ? class.teacherID.userPhoto
                                    : 'assets/img/profilePhotoPlaceholder1.png'
                            "
                            class="shadow"
                            alt="image"
                        />
                        <div class="w-100 d-flex justify-content-between">
                            <span>{{
                                class.teacherID
                                    ? class.teacherID.profileName
                                    : ""
                            }}</span>
                            <span>{{ class.languageID.languageName }}</span>
                        </div>
                    </div>

                    <div
                        *ngIf="class.rating && class.rating != 0"
                        class="courses-rating"
                    >
                        <div class="review-stars-rated">
                            <i
                                *ngIf="class.rating < 1"
                                class="bx bxs-star-half"
                            ></i>
                            <i
                                *ngIf="class.rating >= 1"
                                class="bx bxs-star"
                            ></i>
                            <i
                                *ngIf="class.rating >= 1.5 && class.rating < 2"
                                class="bx bxs-star-half"
                            ></i>
                            <i
                                *ngIf="class.rating >= 2"
                                classd-flex
                                align-items-center="bx bxs-star"
                            ></i>
                            <i
                                *ngIf="class.rating >= 3 && class.rating > 3.5"
                                class="bx bxs-star-half"
                            ></i>
                            <i
                                *ngIf="class.rating >= 4"
                                class="bx bxs-star"
                            ></i>
                            <i
                                *ngIf="class.rating >= 4 && class.rating < 4.5"
                                class="bx bxs-star-half"
                            ></i>
                            <i
                                *ngIf="class.rating >= 5"
                                class="bx bxs-star"
                            ></i>
                        </div>
                        <div class="rating-total">
                            {{ class.rating }}
                        </div>
                    </div>
                </div>
                <div class="gradeContent">
                    <h4>
                        {{
                            class.categoryID
                                ? class.categoryID.categoryName
                                : ""
                        }}
                    </h4>
                    <!-- <p>{{slide.courseID.courseName}}</p> -->
                    <p class="text-overflow">
                        {{ class.moduleID ? class.moduleID.moduleName : "NA" }}
                    </p>
                    <p class="text-overflow">
                        {{ class.topicID ? class.topicID.topicName : "NA" }}
                    </p>
                </div>

                <div class="courses-box-footer mt-3">
                    <ul>
                        <li class="courses-lesson">
                            <i class="fa fa-rupee-sign"></i>

                            {{ class.price != 0 ? class.price : "Free" }}

                            <!-- <i class='bx bx-book-open'></i> {{slide.totalClassTime ? slide.totalClassTime : 30}} mins -->
                        </li>
                        <li class="courses-lesson _leeson_2">
                            <i class="bx bx-book-open"></i> Level
                            {{ class.level ? class.level : 1 }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div
            style="color: #aba3a3"
            class="col-lg-12"
            *ngIf="classroomList.length == 0"
        >
            No Study Hall Classes Available.
        </div>
    </div>
</div>
<div *ngIf="isStudent == true && isTeacher == false" class="container mt-3">
    <h3
        (click)="redirectRoundtable()"
        style="
            color: #000000;
            cursor: pointer;
            border-bottom: 1px solid #f7f0f0 !important;
        "
        class="col-lg-12"
    >
        Round Table Classes
        <span class="more-text" *ngIf="RoundList.length != 0">more</span>
    </h3>
    <br />
    <div class="container-fluid row" style="cursor: pointer">
        <div
            class="col-lg-4 col-md-6 float:left"
            *ngFor="let class of RoundList"
        >
            <div class="single-courses-item mb-30 p-4">
                <h3>
                    <a
                        routerLink="javascript:void(0)"
                        (click)="RoundtableClassDetails(class)"
                        class="d-inline-block"
                        style="height: 60px"
                        >{{ class.personalClassID.className }}</a
                    >
                </h3>
                <div style="overflow: hidden">
                    <p style="float: left">
                        <i class="bx bx-calendar"></i>
                        {{
                            class.startDateTime
                                ? class.startDateTime
                                : "Class Template"
                        }}
                    </p>
                    <p style="float: right">
                        {{
                            class.totalClassTime ? class.totalClassTime : 30
                        }}
                        mins
                    </p>
                </div>
                <div class="courses-content px-0 my-3">
                    <div class="course-author d-flex align-items-center">
                        <img
                            [src]="
                                class.teacherID &&
                                class.teacherID.userPhoto &&
                                class.teacherID.userPhoto != ''
                                    ? class.teacherID.userPhoto
                                    : 'assets/img/profilePhotoPlaceholder1.png'
                            "
                            class="shadow"
                            alt="image"
                        />
                        <div class="w-100 d-flex justify-content-between">
                            <span>{{
                                class.teacherID
                                    ? class.teacherID.profileName
                                    : ""
                            }}</span>
                            <span>{{ class.languageID.languageName }}</span>
                        </div>
                    </div>

                    <div
                        *ngIf="class.rating && class.rating != 0"
                        class="courses-rating"
                    >
                        <div class="review-stars-rated">
                            <i
                                *ngIf="class.rating < 1"
                                class="bx bxs-star-half"
                            ></i>
                            <i
                                *ngIf="class.rating >= 1"
                                class="bx bxs-star"
                            ></i>
                            <i
                                *ngIf="class.rating >= 1.5 && class.rating < 2"
                                class="bx bxs-star-half"
                            ></i>
                            <i
                                *ngIf="class.rating >= 2"
                                classd-flex
                                align-items-center="bx bxs-star"
                            ></i>
                            <i
                                *ngIf="class.rating >= 3 && class.rating > 3.5"
                                class="bx bxs-star-half"
                            ></i>
                            <i
                                *ngIf="class.rating >= 4"
                                class="bx bxs-star"
                            ></i>
                            <i
                                *ngIf="class.rating >= 4 && class.rating < 4.5"
                                class="bx bxs-star-half"
                            ></i>
                            <i
                                *ngIf="class.rating >= 5"
                                class="bx bxs-star"
                            ></i>
                        </div>
                        <div class="rating-total">
                            {{ class.rating }}
                        </div>
                    </div>
                </div>
                <div class="gradeContent">
                    <h4>
                        {{
                            class.personalClassID.categoryID
                                ? class.personalClassID.categoryID.categoryName
                                : ""
                        }}
                    </h4>
                    <!-- <p>{{slide.courseID.courseName}}</p> -->
                    <p class="text-overflow">
                        {{
                            class.personalClassID.moduleID
                                ? class.personalClassID.moduleID.moduleName
                                : "NA"
                        }}
                    </p>
                    <p class="text-overflow">
                        {{
                            class.personalClassID.topicID
                                ? class.personalClassID.topicID.topicName
                                : "NA"
                        }}
                    </p>
                </div>

                <div class="courses-box-footer mt-3">
                    <ul>
                        <li class="courses-lesson">
                            <i class="fa fa-rupee-sign"></i>

                            {{
                                class.personalClassID.price != 0
                                    ? class.personalClassID.price
                                    : "Free"
                            }}

                            <!-- <i class='bx bx-book-open'></i> {{slide.totalClassTime ? slide.totalClassTime : 30}} mins -->
                        </li>
                        <li class="courses-lesson _leeson_2">
                            <i class="bx bx-book-open"></i> Level
                            {{
                                class.personalClassID.level
                                    ? class.personalClassID.level
                                    : 1
                            }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div
            style="color: #aba3a3"
            class="col-lg-12"
            *ngIf="RoundList.length == 0"
        >
            No Round Table Classes Available.
        </div>
    </div>
</div>
<div *ngIf="isTeacher == true" class="container mt-3">
    <!-- <h3 style="color: var(--mainColor); cursor: pointer" class="col-lg-12">
        Roundtable Classes
    </h3> -->
    <h3
        (click)="redirectRoundtable()"
        style="
            color: #000000;
            cursor: pointer;
            border-bottom: 1px solid #f7f0f0 !important;
        "
        class="col-lg-12"
    >
        Round Table Classes
        <span class="more-text" *ngIf="RoundList.length != 0">more</span>
    </h3>
    <br />
    <div class="container-fluid row" style="cursor: pointer">
        <div class="col-lg-4 col-md-6" *ngFor="let class of RoundList">
            <div class="single-courses-item mb-30 p-4">
                <h3>
                    <a
                        routerLink="javascript:void(0)"
                        (click)="StudyhallClassDetails(class)"
                        class="d-inline-block"
                        style="height: 60px"
                        >{{ class.className }}</a
                    >
                </h3>
                <div style="overflow: hidden">
                    <p style="float: left">
                        <i class="bx bx-calendar"></i>
                        {{
                            class.startDateTime
                                ? class.startDateTime
                                : "Class Template"
                        }}
                    </p>
                    <p style="float: right">
                        {{
                            class.totalClassTime ? class.totalClassTime : 30
                        }}
                        mins
                    </p>
                </div>
                <div class="courses-content px-0 my-3">
                    <div class="course-author d-flex align-items-center">
                        <img
                            [src]="
                                class.teacherID &&
                                class.teacherID.userPhoto &&
                                class.teacherID.userPhoto != ''
                                    ? class.teacherID.userPhoto
                                    : 'assets/img/profilePhotoPlaceholder1.png'
                            "
                            class="shadow"
                            alt="image"
                        />
                        <div class="w-100 d-flex justify-content-between">
                            <span>{{
                                class.teacherID
                                    ? class.teacherID.profileName
                                    : ""
                            }}</span>
                            <span>{{ class.languageID.languageName }}</span>
                        </div>
                    </div>

                    <div
                        *ngIf="class.rating && class.rating != 0"
                        class="courses-rating"
                    >
                        <div class="review-stars-rated">
                            <i
                                *ngIf="class.rating < 1"
                                class="bx bxs-star-half"
                            ></i>
                            <i
                                *ngIf="class.rating >= 1"
                                class="bx bxs-star"
                            ></i>
                            <i
                                *ngIf="class.rating >= 1.5 && class.rating < 2"
                                class="bx bxs-star-half"
                            ></i>
                            <i
                                *ngIf="class.rating >= 2"
                                classd-flex
                                align-items-center="bx bxs-star"
                            ></i>
                            <i
                                *ngIf="class.rating >= 3 && class.rating > 3.5"
                                class="bx bxs-star-half"
                            ></i>
                            <i
                                *ngIf="class.rating >= 4"
                                class="bx bxs-star"
                            ></i>
                            <i
                                *ngIf="class.rating >= 4 && class.rating < 4.5"
                                class="bx bxs-star-half"
                            ></i>
                            <i
                                *ngIf="class.rating >= 5"
                                class="bx bxs-star"
                            ></i>
                        </div>
                        <div class="rating-total">
                            {{ class.rating }}
                        </div>
                    </div>
                </div>
                <div class="gradeContent">
                    <h4>
                        {{
                            class.categoryID
                                ? class.categoryID.categoryName
                                : ""
                        }}
                    </h4>
                    <!-- <p>{{slide.courseID.courseName}}</p> -->
                    <p class="text-overflow">
                        {{ class.moduleID ? class.moduleID.moduleName : "NA" }}
                    </p>
                    <p class="text-overflow">
                        {{ class.topicID ? class.topicID.topicName : "NA" }}
                    </p>
                </div>

                <div class="courses-box-footer mt-3">
                    <ul>
                        <li class="courses-lesson">
                            <i class="fa fa-rupee-sign"></i>

                            {{ class.price != 0 ? class.price : "Free" }}

                            <!-- <i class='bx bx-book-open'></i> {{slide.totalClassTime ? slide.totalClassTime : 30}} mins -->
                        </li>
                        <li class="courses-lesson _leeson_2">
                            <i class="bx bx-book-open"></i> Level
                            {{ class.level ? class.level : 1 }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div
            style="color: #aba3a3"
            class="col-lg-12"
            *ngIf="RoundList.length == 0"
        >
            No Round Table Classes Available.
        </div>
    </div>
</div>

<div *ngIf="isTeacher == true || isStudent == true" class="container mt-3">
    <!-- <h3 style="color: var(--mainColor); cursor: pointer" class="col-lg-12">
        Smart Material
    </h3> --><br />
    <h3
        (click)="redirectSmartmaterial()"
        style="color: #000000; cursor: pointer; border-bottom: 1px solid #f7f0f0 !important;"
        class="col-lg-12"
    >
        Smart Materials
        <span class="more-text" *ngIf="smartMaterialList.length != 0"
            >more</span
        >
    </h3>
    <br />
    <div class="container-fluid row" style="cursor: pointer">
        <div class="col-lg-4 col-md-6" *ngFor="let slide of smartMaterialList">
            <div class="single-courses-item mb-30 p-4">
                <div class="courses-image">
                    <h5>
                        <a
                            routerLink="javascript:void(0)"
                            (click)="detailsSmartMaterial(slide)"
                            class="d-inline-block"
                            style="height: 60px"
                            >{{ slide.materialName }}</a
                        >
                    </h5>
                </div>

                <div class="courses-content px-0 my-3">
                    <!-- <div
                        class="
                            d-flex
                            justify-content-between
                            align-items-center
                        "
                    >
                        <div class="course-author d-flex align-items-center">
                            <img
                                [src]="
                                    slide.teacherID.userPhoto
                                        ? slide.teacherID.userPhoto
                                        : 'assets/img/profilePhotoPlaceholder1.png'
                                "
                                class="shadow"
                                alt="image"
                            />
                            <span>{{
                                slide.teacherID ? slide.teacherID.firstName : ""
                            }}</span>
                        </div> -->
                    <div class="course-author d-flex align-items-center">
                        <img
                            [src]="
                                slide.teacherID &&
                                slide.teacherID.userPhoto &&
                                slide.teacherID.userPhoto != ''
                                    ? slide.teacherID.userPhoto
                                    : 'assets/img/profilePhotoPlaceholder1.png'
                            "
                            class="shadow"
                            alt="image"
                        />
                        <div class="w-100 d-flex justify-content-between">
                            <span>{{
                                slide.teacherID
                                    ? slide.teacherID.profileName
                                    : ""
                            }}</span>
                            <span>{{ slide.languageID.languageName }}</span>
                        </div>
                    </div>
                    <div
                        *ngIf="slide.rating && slide.rating != 0"
                        class="courses-rating"
                    >
                        <div class="review-stars-rated">
                            <i
                                *ngIf="slide.rating < 1"
                                class="bx bxs-star-half"
                            ></i>
                            <i
                                *ngIf="slide.rating >= 1"
                                class="bx bxs-star"
                            ></i>
                            <i
                                *ngIf="slide.rating >= 1.5 && slide.rating < 2"
                                class="bx bxs-star-half"
                            ></i>
                            <i
                                *ngIf="slide.rating >= 2"
                                class="bx bxs-star"
                            ></i>
                            <i
                                *ngIf="slide.rating >= 2 && slide.rating < 2.5"
                                class="bx bxs-star-half"
                            ></i>
                            <i
                                *ngIf="slide.rating >= 3"
                                class="bx bxs-star"
                            ></i>
                            <i
                                *ngIf="slide.rating >= 3 && slide.rating > 3.5"
                                class="bx bxs-star-half"
                            ></i>
                            <i
                                *ngIf="slide.rating >= 4"
                                class="bx bxs-star"
                            ></i>
                            <i
                                *ngIf="slide.rating >= 4 && slide.rating < 4.5"
                                class="bx bxs-star-half"
                            ></i>
                            <i
                                *ngIf="slide.rating >= 5"
                                class="bx bxs-star"
                            ></i>
                        </div>
                        <div class="rating-total">
                            {{ slide.rating }}
                        </div>
                    </div>
                    <!-- </div> -->
                </div>

                <div class="gradeContent">
                    <h4>{{ slide.categoryID.categoryName }}</h4>
                    <!-- <p>{{slide.courseID.courseName}}</p> -->
                    <p class="text-overflow">{{ slide.moduleID.moduleName }}</p>
                    <p class="text-overflow">{{ slide.topicID.topicName }}</p>
                </div>

                    <div class="gradeContent">
                        <h4>{{ slide.categoryID.categoryName}}</h4>
                        <!-- <p>{{slide.courseID.courseName}}</p> -->
                        <p class="text-overflow">{{slide.moduleID.moduleName}}</p>
                            <p class="text-overflow">{{slide.topicID.topicName}}</p>
                    </div>                 

                    <div class="courses-box-footer mt-3">
                        <ul>
                            <li class="courses-lesson">
                                <i class='fa fa-rupee-sign'></i>
                                {{slide.price != 0 ? slide.price : 'Free'}}
                                <!-- <i class='bx bx-book-open'></i> {{slide.totalClassTime ? slide.totalClassTime : 30}} mins -->
                            </li>
                            <li class="courses-lesson _leeson_2">
                                <i class='bx bx-book-open'></i> Level {{slide.level ? slide.level : 1}}
                            </li>
                            <!-- <li class="courses-lesson"> <i class='fa fa-rupee-sign'></i> -->
                                <!-- {{slide.price != 0 ? slide.price : 'Free'}} -->
                            <!-- </li> -->slide
                            <!-- <li class="courses-lesson _leeson_2"> <i class='fa fa-rupee-sign'></i>
                                *******
                            </li>
                            <li class="courses-lesson"> <i class='fa fa-rupee-sign'></i>
                                INR 1999.00/buddi
                            </li> -->
                        <!-- <li class="courses-lesson _leeson_2">
                                Rating </li> -->
                    </ul>
                </div>
            </div>
        </div>
        <div
            style="color: #aba3a3"
            class="col-lg-12"
            *ngIf="smartMaterialList.length == 0"
        >
            No Smart Materials Available.
        </div>
    </div>

    <br />
</div>

<div *ngIf="isTeacher == true || isStudent == true" class="container mt-3">
    <!-- <h3 style="color: var(--mainColor); cursor: pointer" class="col-lg-12">
        Video Classes
    </h3> -->
    <h3
        (click)="redirectVideoclasses()"

        style="color: #000000; cursor: pointer; border-bottom: 1px solid #f7f0f0 !important;"
        class="col-lg-12"
    >
        Video Classes
        <span class="more-text" *ngIf="videoList.length != 0">more</span>
    </h3>
    <br />
    <div class="container-fluid row" style="cursor: pointer">
        <div class="container-fluid row">
            <div class="col-lg-4 col-md-6" *ngFor="let slide of videoList">
                <div class="single-courses-item mb-30 p-4">
                    <div class="courses-image">
                        <h5>
                            <a
                                routerLink="javascript:void(0)"
                                (click)="detailsVideo(slide)"
                                class="d-inline-block"
                                style="height: 60px"
                                >{{ slide.videoName }}</a
                            >
                        </h5>
                    </div>

                    <div class="courses-content px-0 my-3">
                        <!-- <div
                        class="
                            d-flex
                            justify-content-between
                            align-items-center
                        "
                    > -->
                        <div class="course-author d-flex align-items-center">
                            <img
                                [src]="
                                    slide.teacherID &&
                                    slide.teacherID.userPhoto &&
                                    slide.teacherID.userPhoto != ''
                                        ? slide.teacherID.userPhoto
                                        : 'assets/img/profilePhotoPlaceholder1.png'
                                "
                                class="shadow"
                                alt="image"
                            />
                            <div class="w-100 d-flex justify-content-between">
                                <span>{{
                                    slide.teacherID
                                        ? slide.teacherID.profileName
                                        : ""
                                }}</span>
                                <span>{{ slide.languageID.languageName }}</span>
                            </div>
                        </div>

                        <div
                            *ngIf="slide.rating && slide.rating != 0"
                            class="courses-rating"
                        >
                            <div class="review-stars-rated">
                                <i
                                    *ngIf="slide.rating < 1"
                                    class="bx bxs-star-half"
                                ></i>
                                <i
                                    *ngIf="slide.rating >= 1"
                                    class="bx bxs-star"
                                ></i>
                                <i
                                    *ngIf="
                                        slide.rating >= 1.5 && slide.rating < 2
                                    "
                                    class="bx bxs-star-half"
                                ></i>
                                <i
                                    *ngIf="slide.rating >= 2"
                                    class="bx bxs-star"
                                ></i>
                                <i
                                    *ngIf="
                                        slide.rating >= 2 && slide.rating < 2.5
                                    "
                                    class="bx bxs-star-half"
                                ></i>
                                <i
                                    *ngIf="slide.rating >= 3"
                                    class="bx bxs-star"
                                ></i>
                                <i
                                    *ngIf="
                                        slide.rating >= 3 && slide.rating > 3.5
                                    "
                                    class="bx bxs-star-half"
                                ></i>
                                <i
                                    *ngIf="slide.rating >= 4"
                                    class="bx bxs-star"
                                ></i>
                                <i
                                    *ngIf="
                                        slide.rating >= 4 && slide.rating < 4.5
                                    "
                                    class="bx bxs-star-half"
                                ></i>
                                <i
                                    *ngIf="slide.rating >= 5"
                                    class="bx bxs-star"
                                ></i>
                            </div>
                            <div class="rating-total">
                                {{ slide.rating }}
                            </div>
                        </div>
                    </div>

                    <!-- </div> -->

                    <!-- <div style="overflow: hidden">
                        <p style="float: left">{{ slide.description }}</p>
                    </div>
                    <div style="overflow: hidden">
                        <p style="float: left">
                            {{ slide.languageID.languageName }}
                        </p>
                    </div> -->

                    <div class="gradeContent">
                        <h4>{{ slide.categoryID.categoryName }}</h4>
                        <!-- <p>{{slide.courseID.courseName}}</p> -->
                        <p class="text-overflow">
                            {{ slide.moduleID.moduleName }}
                        </p>
                        <p class="text-overflow">
                            {{ slide.topicID.topicName }}
                        </p>
                    </div>

                    <div class="courses-box-footer mt-3">
                        <ul>
                            <li class="courses-lesson">
                                <i class="fa fa-rupee-sign"></i>
                                {{ slide.price != 0 ? slide.price : "Free" }}
                                <!-- <i class='bx bx-book-open'></i> {{slide.totalClassTime ? slide.totalClassTime : 30}} mins -->
                            </li>
                            <li class="courses-lesson _leeson_2">
                                <i class="bx bx-book-open"></i> Level
                                {{ slide.level ? slide.level : 1 }}
                            </li>
                            <!-- <li class="courses-lesson"> <i class='fa fa-rupee-sign'></i> -->
                            <!-- {{slide.price != 0 ? slide.price : 'Free'}} -->
                            <!-- </li> -->
                            <!-- <li class="courses-lesson _leeson_2"> <i class='fa fa-rupee-sign'></i>
                                        *******
                                    </li>
                                    <li class="courses-lesson"> <i class='fa fa-rupee-sign'></i>
                                        INR 1999.00/buddi
                                    </li> -->
                            <!-- <li class="courses-lesson _leeson_2">
                                        Rating </li> -->
                        </ul>
                    </div>
                </div>
            </div>
            <div
                style="color: #aba3a3"
                class="col-lg-12"
                *ngIf="videoList.length == 0"
            >
                No Video Classes Available.
            </div>
        </div>
    </div>
</div>
