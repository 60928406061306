import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, Validators} from '@angular/forms';
import { ApiService } from '../../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { lastnamevalidator } from './../../../../shared/lastnamevalidator'

import { environment } from '../../../../../environments/environment';
@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
  registerForm: FormGroup;
  selectedFileName: string = 'Upload Profile Photo';
  public userPhoto: any;
  name: any;
  submitted = false;
  showModal: boolean;
  formData;
  formData1;
  stateInfo: any[] = [];
  countryInfo: any[] = [];
  cityInfo: any[] = [];
  id: any;
  facebook: any;
  twitter: any;
  instagram: any;
  linkedin: any;
  youtube: any;
  Address1: any;
  bio: any;
  public isTeacher:boolean=false;
  public meetingsData: any = [];
  public meetingsDataListen: any = [];
  public meetingsDataAll: any = [];
  public allmeeting:any = [];
  public inviteUrl = "";
  public frontwebsite = environment.frontUrl;
  public meetingVisible = true;
  public lessonData:any=[];
  public myId:any=''
  public groupName:any='';
  public isLogin:any='false';
  listsubjects : any;
  listlanguages : any;
  listboard : any;
  cities = [];
  selectedcategorylist = [];
  selectedgradelist = [];
  selectedsubjectlist = [];
  selectedboardlist = [];
  selectedlanguagelist = [];
  listgrade : any;
  listcategories: any;
  dropdownSettings: any = {};
  dropdowngradeSettings: any = {};
  dropdownboardSettings: any = {};
  dropdownsubjectSettings: any = {};
  dropdownlanguageSettings: any = {};
  listcountries : any;
  isFullTime:boolean = false;
  constructor(private activateRoute:ActivatedRoute,private location:Location,private formBuilder: FormBuilder,private userApi: ApiService,public toastr: ToastrManager, private router: Router) { }

  ngOnInit() {
    this.isLogin = localStorage.getItem('isLoggedin');
    const webToken = localStorage.getItem('webtoken');
    this.myId = localStorage.getItem('userId');
    this.isTeacher = localStorage.getItem('profileType').toLowerCase() =='teacher'
    this.dropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'categoryName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3
  };

  this.dropdowngradeSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'gradeName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3
};

this.dropdownboardSettings = {
  singleSelection: false,
  idField: '_id',
  textField: 'boardName',
  selectAllText: 'Select All',
  unSelectAllText: 'UnSelect All',
  itemsShowLimit: 3
};

this.dropdownsubjectSettings = {
  singleSelection: false,
  idField: '_id',
  textField: 'subjectName',
  selectAllText: 'Select All',
  unSelectAllText: 'UnSelect All',
  itemsShowLimit: 3
};

this.dropdownlanguageSettings = {
  singleSelection: false,
  idField: '_id',
  textField: 'languageName',
  selectAllText: 'Select All',
  unSelectAllText: 'UnSelect All',
  itemsShowLimit: 3
};

    this.userApi.studentSubject(webToken).subscribe((result) =>{
      this.listsubjects = result.data;
    })

    this.userApi.board(webToken).subscribe((result) =>{
      this.listboard = result.data;
    })

    this.userApi.languagefield().subscribe((result) =>{
      this.listlanguages = result.data;
    })

    this.userApi.gradefield().subscribe((result) =>{
      this.listgrade = result.data;
    })

    this.userApi.getCourseCategory().subscribe((result) =>{
      this.listcategories = result.data;
    })

    this.userApi.getcountry().subscribe((result) =>{
      this.listcountries = result;
    })
    this.userdetails();

    this.userApi.userName_DisplayTeacher(webToken).subscribe((result) => {      
      this.groupName=result.data.groupName;
      var req={
        groupname:this.groupName
      }

    });
  
  
   
    // this.getCountries();
    
    this.registerForm = this.formBuilder.group({
      id: null,
      upload_name: [null],
      fileupload: [''],
      firstname: ['', [Validators.required, Validators.pattern("^[a-zA-Z ,.'-]+$"), lastnamevalidator.cannotContainSpace]],
      lastname: ['', [Validators.required, Validators.pattern("^[a-zA-Z ,.'-]+$"), lastnamevalidator.cannotContainSpace]],
      email: ['', [Validators.required, Validators.minLength(6)]],
      mobileNumber1: [''],
      dob: [''],
      gender: [''],
      street: [''],
      area: [''],
      city: [''],
      categories: [''],
      grades: [''],
      schoolName: [''],
      subjects: [''],
      boards:[''],
      guardianName: [''],
      guardianPhone: [''],
      address1:[''],
      address2:[''],
      state: [''],
      countries: [''],
      postalCode: [''],
      currentCity: [''],
      qualification: [''],
      preferences: [''],
      currentEducation: [''],
      profession: [''],
      facebook: [''],
      twitter: [''],
      instagram: [''],
      linkedIn: [''],
      youtube: [''],
      bio:[''],
      Mobilenumber:[''],
      accountName:[''],
      accountNumber:[''],
      accountType:[''],
      bankName:[''],
      bankBranch:[''],
      ifscCode:[''],
  });
  }

  get f() {return this.registerForm.controls; }

  getCountries(){
    this.userApi.allCountries().
    subscribe(
      data2 => {
        this.countryInfo=data2.Countries;
      },
      err => console.log(err),
      () => console.log('complete')
    )
  }

  onChangeCountry(countryValue) {
    this.stateInfo=this.countryInfo[countryValue].States;
    this.cityInfo=this.stateInfo[0].Cities;
  }

  onChangeState(stateValue) {
    this.cityInfo=this.stateInfo[stateValue].Cities;
  }

  fileChange(event) {
    let fileList: FileList = event.target.files;
    this.registerForm.patchValue({ upload_name: fileList[0] });
    this.selectedFileName = fileList[0].name;
    this.registerForm.patchValue({ fileupload: this.selectedFileName });
  }

  userdetails() {
    const webToken = localStorage.getItem('webtoken');
    if(webToken != null){
    this.userApi.Getteacherallpopulate(webToken).subscribe((result) => {
      let assigndata : any = {};
      assigndata.id = result.data._id;
      console.log(result)
      // this.isFullTime = result.data.modeofEngagement!=undefined && result.data.modeofEngagement.length!=0? result.data.modeofEngagement[0].employmentType =='Full -Time' ? true : false: false;
      // if(!this.isFullTime){
        // this.registerForm.get('accountName').setValidators([Validators.required]);
        // this.registerForm.get('accountNumber').setValidators([Validators.required]);
        // this.registerForm.get('accountType').setValidators([Validators.required]);
        // this.registerForm.get('bankName').setValidators([Validators.required]);
        // this.registerForm.get('bankBranch').setValidators([Validators.required]);
        // this.registerForm.get('ifscCode').setValidators([Validators.required]);
      // }
      assigndata.Mobilenumber = result.data.phoneNumber;
      assigndata.Address1 = result.data.address1;
      assigndata.firstname = result.data.firstName;
      assigndata.lastname = result.data.lastName;
      assigndata.email = result.data.email;
      assigndata.mobileNumber1 = result.data.mobileNumber1;
      assigndata.dob = result.data.dob;
      assigndata.gender = result.data.gender =='undefined' ? "" : result.data.gender;     
      assigndata.countries = result.data.country =='undefined' ? "" : result.data.country;
      assigndata.address1 = result.data.address1;
      assigndata.address2 = result.data.address2;
      assigndata.street = result.data.street;
      assigndata.guardianName = result.data.guardianName;
      assigndata.guardianPhone = result.data.guardianPhone;      
      this.selectedsubjectlist = result.data.subjects;
      
      this.selectedlanguagelist = result.data.preferredLanguage;
      this.selectedgradelist = result.data.grades;
      this.selectedboardlist = result.data.boards;
      this.selectedcategorylist = result.data.categories;
      assigndata.accountName = result.data.accountName =='undefined' ? '' : result.data.accountName;
      assigndata.accountNumber = result.data.accountNumber =='undefined' ? '' : result.data.accountNumber;
      assigndata.accountType = result.data.accountType =='undefined' ? '' : result.data.accountType;
      assigndata.bankName = result.data.bankName =='undefined' ? '' : result.data.bankName;
      assigndata.bankBranch = result.data.bankBranch =='undefined' ? '' : result.data.bankBranch;
      assigndata.ifscCode = result.data.ifscCode =='undefined' ? '' : result.data.ifscCode;
      assigndata.area = result.data.area =='undefined' ? '' : result.data.area;
      assigndata.city = result.data.city =='undefined' ? '' : result.data.city;
      assigndata.state = result.data.state =='undefined' ? '' :  result.data.state;
      assigndata.postalCode = result.data.postalCode =='undefined' ? '' : result.data.postalCode;
      assigndata.currentCity = result.data.currentCity =='undefined' ? '' : result.data.currentCity;
      assigndata.preferences = result.data.preferences =='undefined' ? '' : result.data.preferences;
      assigndata.qualification = result.data.qualification =='undefined' ? '' : result.data.qualification;
      assigndata.currentEducation = result.data.currentEducation =='undefined' ? '' : result.data.currentEducation;
      assigndata.profession = result.data.profession =='undefined' ? '' : result.data.profession;
      this.registerForm.patchValue(assigndata);
      this.selectedFileName = result.data.userPhoto.split('/')[result.data.userPhoto.split('/').length-1].length>29 ? result.data.userPhoto.split('/')[result.data.userPhoto.split('/').length-1].slice(0,30)+'...':result.data.userPhoto.split('/')[result.data.userPhoto.split('/').length-1];
      this.userPhoto = result.data.userPhoto
      this.name = result.data.profileName;      
      this.id = result.data._id;
      const userId = {id:this.id}
      this.userApi.userPricingData_Get(userId,webToken).subscribe((response) => {
        let assignAddData : any = {};
        assignAddData.facebook = response.data.facebookURL =='undefined' ? '' : response.data.facebookURL;
        assignAddData.twitter = response.data.twitterURL =='undefined' ? '' : response.data.twitterURL;
        assignAddData.instagram = response.data.instagramURL =='undefined' ? '' : response.data.instagramURL;
        assignAddData.linkedIn = response.data.linkedinURL =='undefined' ? '' : response.data.linkedinURL;
        assignAddData.youtube = response.data.youtubeURL =='undefined' ? '' : response.data.youtubeURL;
        assignAddData.bio = response.data.userBio =='undefined' ? '' :  response.data.userBio;
        this.registerForm.patchValue(assignAddData);
        console.log(assignAddData);  
      });
                
    }, (err) => {
    console.log(err);
    });
   }
  }

 

  onSubmit(FormGroup) {
    this.submitted = true;
    if (this.registerForm.invalid && !this.registerForm.value.id) {
      return;      
    }
    if(this.registerForm.invalid) {
     
       //To check the invalid field
       const invalid = [];
       const controls = this.registerForm.controls;
       for (const name in controls) {
           if (controls[name].invalid) {
               invalid.push(name);
             if(name == "lastname") {
               this.toastr.errorToastr('please check Last Name');
             }
             else {
              // this.toastr.errorToastr('Please check all'+name);
             }
           }
       }
      return;
    }
    this.showModal = false;
  console.log('profile update value',this.registerForm.value);
  const data = this.registerForm.value;
  console.log('Form Data',data);
  console.log('gender',this.registerForm.value.gender)

  if(data.upload_name!=null){
  this.formData = new FormData();
  this.formData.append("id", data.id);
  this.formData.append("firstName",data.firstname);
  this.formData.append("lastName",data.lastname);
  this.formData.append("profileName",data.firstname);
  this.formData.append("mobileNumber1",data.mobileNumber1);
  this.formData.append("dob",data.dob);
  this.formData.append("gender",data.gender);
  this.formData.append("upload_name",data.upload_name,data.upload_name.name);
  this.formData.append("street", data.street);
  this.formData.append("area", data.area);
  this.formData.append("city", data.city);
  this.formData.append("email", data.email);
  // this.formData.append("preferredLanguage", this.selectedlanguagelist);
  // this.formData.append("grades", this.selectedgradelist);
  this.formData.append("address1", data.address1);
  this.formData.append("address2", data.address2);
  this.formData.append("schoolName",data.schoolName);
  // this.formData.append("subjects",this.selectedsubjectlist);
  // this.formData.append("boards", this.selectedboardlist);
  // this.formData.append("categories", this.selectedcategorylist);
  this.formData.append("guardianName", data.guardianName);
  this.formData.append("guardianPhone", data.guardianPhone);
  this.formData.append("state", data.state);
  this.formData.append("country", data.countries);
  this.formData.append("postalCode", data.postalCode);
  this.formData.append("currentCity", data.currentCity);
  this.formData.append("qualification", data.qualification);
  this.formData.append("preferences", data.preferences);
  this.formData.append("currentEducation", data.currentEducation);
  this.formData.append("profession", data.profession);
  // this.formData.append("guardianPhone", "123456789");
  // this.formData.append("facebookURL", data.facebook);
  // this.formData.append("twitterURL", data.twitter);
  // this.formData.append("userBio", data.bio);
  // this.formData.append("youtubeURL", data.youtube);
  this.formData1 = {
    grades: this.selectedgradelist,
    boards: this.selectedboardlist,
    categories: this.selectedcategorylist,
    subjects: this.selectedsubjectlist,
    preferredLanguage : this.selectedlanguagelist
  } 
  }

  if(data.upload_name == null){
    this.formData = {
      firstName: data.firstname,
      lastName: data.lastname,
      profileName: data.firstname,
      mobileNumber1: data.mobileNumber1,
      dob: data.dob,
      gender: this.registerForm.value.gender,
      street: data.street,
      area: data.area,
      city: data.city,
      accountName: data.accountName,
      accountNumber: data.accountNumber,
      accountType: data.accountType,
      bankName: data.bankName,
      bankBranch: data.bankBranch,
      ifscCode: data.ifscCode,
      grades: this.selectedgradelist,
      boards: this.selectedboardlist,
      categories: this.selectedcategorylist,
      schoolName: data.schoolName,
      preferredLanguage : this.selectedlanguagelist,
      subjects: this.selectedsubjectlist,
      guardianName: data.guardianName,
      guardianPhone: data.guardianPhone,
      state: data.state,
      country: data.countries,
      address1:data.address1,
      address2:data.address2,
      postalCode: data.postalCode,
      currentCity: data.currentCity,
      qualification: data.qualification,
      preferences: data.preferences,
      currentEducation: data.currentEducation,
      profession: data.profession,
      linkedinURL: data.linkedIn,
      instagramURL: data.instagram,
      facebookURL: data.facebook,
      twitterURL: data.twitter,
      userBio: data.bio,
      youtubeURL: data.youtube,
      email: data.email
    }
  }
  const webToken = localStorage.getItem('webtoken'); 
  if(data.upload_name!=null){
    this.userApi.userData_UpdateTeacher(this.formData1, webToken).subscribe((response) => {
     
    });
  }
  this.userApi.userData_UpdateTeacher(this.formData, webToken).subscribe((response) => {
  if(response.status){
    if(window.history.state.tabName!=undefined){
      var data = {
      'pagename':window.history.state.tabName
      }
      this.router.navigate(['/sidenav'],{state:data});
    }else{
      this.router.navigate(['/sidenav']);
    }
     
      this.toastr.successToastr('User Profile Update Successfully');
    }
  }, (err) => {    
  console.log(err);
  });

}

backto(){
  this.router.navigate(['/sidenav']);
}
nextTo(event){
  $('a.active').parent().next().children()[0].click();
}

onItemcategorySelect(item: any) {
 //this.selectedcategorylist.push(item)
}

onItemcategoryDeSelect(item1: any) {
  this.selectedcategorylist = this.selectedcategorylist.filter(item => !item1._id.includes(item))
}

onItemcategoryallDeSelect(item1: any) {
  for (let i = 0; i < item1.length; i++) {
    this.selectedcategorylist = this.selectedcategorylist.filter(item => !item1[i]._id.includes(item))
    };
}
onItemcategoryallSelect(item1: any) {
  this.selectedcategorylist = [];
  for (let i = 0; i < item1.length; i++) {
    this.selectedcategorylist.push(item1[i])
    };
}

onItemgradeSelect(item: any) {
 // this.selectedgradelist.push(item)
 }
 
 onItemgradeDeSelect(item1: any) {
   this.selectedgradelist = this.selectedgradelist.filter(item => !item1._id.includes(item))
 }

 onItemgradeallDeSelect(item1: any) {
  for (let i = 0; i < item1.length; i++) {
    this.selectedgradelist = this.selectedgradelist.filter(item => !item1[i]._id.includes(item))
    };
}
onItemgradeallSelect(item1: any) {
  this.selectedgradelist = [];
  for (let i = 0; i < item1.length; i++) {
    this.selectedgradelist.push(item1[i])
    };
}

 onItemboardSelect(item: any) {
  //this.selectedboardlist.push(item)
 }
 
 onItemboardDeSelect(item1: any) {
   this.selectedboardlist = this.selectedboardlist.filter(item => !item1._id.includes(item))
 }

 onItemboardallDeSelect(item1: any) {
  for (let i = 0; i < item1.length; i++) {
    this.selectedboardlist = this.selectedboardlist.filter(item => !item1[i]._id.includes(item))
    };
}
onItemboardallSelect(item1: any) {
  this.selectedboardlist = [];
  for (let i = 0; i < item1.length; i++) {
    this.selectedboardlist.push(item1[i])
    };
}

 onItemsubjectSelect(item: any) {
 // this.selectedsubjectlist.push(item)
 }
 
 onItemsubjectDeSelect(item1: any) {
   this.selectedsubjectlist = this.selectedsubjectlist.filter(item => !item1._id.includes(item))
 }

 onItemsubjectallDeSelect(item1: any) {
  for (let i = 0; i < item1.length; i++) {
    this.selectedsubjectlist = this.selectedsubjectlist.filter(item => !item1[i]._id.includes(item))
    };
}
onItemsubjectallSelect(item1: any) {
  this.selectedsubjectlist = [];
  for (let i = 0; i < item1.length; i++) {
    this.selectedsubjectlist.push(item1[i])
    };
}

 onItemlanguageSelect(item: any) {
 // this.selectedlanguagelist.push(item)
 }
 
 onItemlanguageDeSelect(item1: any) {
   this.selectedlanguagelist = this.selectedlanguagelist.filter(item => !item1._id.includes(item))
 }

 onItemlanguageallDeSelect(item1: any) {
  for (let i = 0; i < item1.length; i++) {
    this.selectedlanguagelist = this.selectedlanguagelist.filter(item => !item1[i]._id.includes(item))
    };
}
onItemlanguageallSelect(item1: any) {
  this.selectedlanguagelist = [];
  for (let i = 0; i < item1.length; i++) {
    this.selectedlanguagelist.push(item1[i])
    };
}
}
