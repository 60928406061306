<!-- <app-header-style-one></app-header-style-one> -->

<div *ngIf="!isTeacher">
  <app-header-style-one></app-header-style-one>
</div>
<div *ngIf="isTeacher">
  <app-header-style-one-teacher></app-header-style-one-teacher>
</div>


<div class="container  mt-3">
  <div class="container pl-0">
    <form [formGroup]="exploreForm">
      <div class="searchFixed">
        <div class="container">
          <div class="row  pt-2">
            <div class="col-lg-12">          
              <div class="row" *ngIf="isLogin == 'true'">           
                <div class="col-lg-6 description">
                </div>
            <div class="col-lg-2 col-sm-2  col-xs-2 col-md-2 mt-3 p-0 description">
              <p class="font12size" style="float: right;font-weight: 600;color:var(--mainColor);font-size: 18px">
               
                  <span *ngIf="studentIsSchool">                    
                   <input type="checkbox" (click)="changeRole(true)" formControlName="meetingType">Only Schools
                  </span>
              
            </div>           
            <div class="col-lg-3 col-sm-3 col-xs-3 col-md-3 description">
              
              <div class="form-group" >
                <!-- <form [formGroup]="exploreSearch"> -->
                <input type="text" class="form-control font12size" (change)="actieveTwoWay($event)" formControlName="term" placeholder="Search By Name, Category Name" >
                <!-- </form> -->
              </div>
            </div>
            <div class="col-lg-1 col-sm-2 col-xs-2 col-md-2 description">
              <a  (click)="backto()" class="default-btn backButton">
                <span class="">Back</span>
              </a>
            </div>
            <!--  <div class="col-lg-1">
            </div> -->
          </div>
          <div class="row col-lg-12" *ngIf="isLogin == 'false'" style="padding-bottom: 2%;">
           <!--   <div class="col-lg-2 p-3">
              Search by
            </div> -->
            <div class="col-lg-6 description">
            </div>
            <div class="col-lg-2 col-sm-2 col-xs-2 col-md-2 mt-3 p-0 description">
              <p class="font12size" style="float: right;font-weight: 600;color:var(--mainColor);font-size: 18px"> 
                <span *ngIf="studentIsSchool">  
                  <input type="checkbox" disabled="true" (click)="changeRole(true)" formControlName="meetingType">Only Schools
                </span>                 
            </div>
            
             <div class="col-lg-3 col-sm-3 col-xs-3 col-md-3 description">
             <!--  <p style="font-weight: 600;color:var(--mainColor);font-size: 18px">Select Our Products</p> -->
             <div class="form-group" >
                <input type="text" class="form-control font12size" (change)="actieveTwoWay($event)" placeholder="Search By Name, Category Name"  formControlName="term" >
              </div>
            </div>
            <div class="col-lg-1 col-sm-2 col-xs-2 col-md-2 description">
              <a  (click)="backto()" class="default-btn backButton">
                <span class="">Back</span>
              </a>
            </div>
            <!--  <div class="col-lg-1">
            </div> -->
          </div>
        </div>

        <div class="col-lg-12 row">
          <div class="col-md-2 description" *ngIf="coursecategorylist.length !=0">
            <div class="form-group">
              <label style="color:var(--mainColor)">Category Name</label>
              <select class="form-control" (change)="selectChange($event)" formControlName="categoryID">
                <option *ngFor="let obj of coursecategorylist" [ngValue]="obj._id">{{obj.categoryName}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-2 description" *ngIf="gradeapi.length !=0">
            <div class="form-group">
              <label style="color:var(--mainColor)">Grade</label>
              <select class="form-control" (change)="selectChange($event)" formControlName="gradeID">
                <option value="all">All</option>
                <option *ngFor="let obj of gradeapi" [ngValue]="obj._id">{{obj.gradeName}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-2 description" *ngIf="courseboardlist.length !=0">
            <div class="form-group">
              <label style="color:var(--mainColor)">Board</label>
              <select class="form-control" (change)="selectChange($event)" formControlName="boardID">
                <option value="all">All</option>
                <option *ngFor="let obj of courseboardlist" [ngValue]="obj._id">{{obj.boardName}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-2 description" *ngIf="coursesubjectlist.length !=0">
            <div class="form-group">
              <label style="color:var(--mainColor)">Subject</label>
              <select class="form-control" (change)="selectChange($event)" formControlName="subjectID">
                <option value="all">All</option>
                <option *ngFor="let obj of coursesubjectlist" [ngValue]="obj._id">{{obj.subjectName}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-2 description" *ngIf="languageapi.length !=0">
            <div class="form-group">
              <label style="color:var(--mainColor)">Language</label>
              <select class="form-control" (change)="selectChange($event)" formControlName="languageID">
                <option value="all">All</option>
                <option *ngFor="let obj of languageapi" [ngValue]="obj._id">{{obj.languageName}}</option>
              </select>
            </div>
          </div>

          <div class="col-md-2 description">
            <div class="form-group">
              <label style="color:var(--mainColor)">Modules</label>
              <select class="form-control" (change)="sectionChange($event)" formControlName="section">
                <option value="all">All</option>
                <option value="Group Classes">Group Classes</option>
                <option value="Course">Courses</option>
                <!-- <option value="Exams">Exams</option> -->
                <option value="Tests">Tests</option>
                <option value="Audio classes">Evaluation Tests</option>
                <!-- <option value="e-Book">e-Book</option> -->
                <option value="Audio classes">Audio Classes</option>
                <option value="Video classes">Video Lessons</option>
                <option value="Study Material">Study Material</option>
                <!-- <option value="buddi Group Classes">buddi Group Classes</option> -->
              </select>
            </div>
          </div>

        </div>
      </div>
    </div>
    </div>
    </form>

  </div>
  <hr>
  <div class="classSpace"></div>
  <div>
  </div>
  <h3 class="col-lg-12 mt-5" (click)="redirectSearchBy('class')" style="color:var(--mainColor);cursor: pointer;" *ngIf="isSession">Classes <span class="more-text" *ngIf="allMeeting.length!=0">more</span></h3>
  <div class="row container" *ngIf="allMeeting !=undefined && allMeeting.length !=0 && isSession">
    <div class="col-lg-3 col-md-6"
      *ngFor="let slide of allMeeting">
      <div class="single-courses-box without-box-shadow mb-30">
        <div class="courses-image">
          <a (click)="detailsMeeting(slide,'Livesession')" class="d-block">
            <img
              [src]="slide.image =='' ? 'assets/img/courses/courses1.jpg':slide.image"
              style="width: 250px;height: 175px;" alt="image">
            </a>
        </div>
        <div class="courses-content">
          <div class="course-author d-flex align-items-center">
            <img
              [src]="slide.teacherID!=undefined && slide.teacherID.userPhoto!='' ? slide.teacherID.userPhoto :'assets/img/user1.jpg'"
              class="rounded-circle mr-2" alt="image">
            <span *ngIf="slide.teacherID!=undefined">{{slide.teacherID.firstName}}
            </span>
          </div>
          <div class="course-author d-flex align-items-center">
            <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image">
            {{slide.ScheduleDate |  date :"MMM dd,yyyy, hh:mm aaa"}}
          </div>
          <h3>
            <a (click)="detailsMeeting(slide,'Livesession')" class="d-inline-block">
              <span class="text-break">{{slide.name}}</span>
            </a>
          </h3>
        </div>
        <div>
          {{slide.categoryID ? slide.categoryID.categoryName : '' }}, {{slide.gradeID ? slide.gradeID.gradeName : '' }},
          {{slide.boardID ? slide.boardID.boardName : '' }}
        </div>
        <div class="courses-box-footer">
          {{slide.subjectID ? slide.subjectID.subjectName : '' }},
          {{slide.languageID ? slide.languageID.languageName : '' }}
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-12" style="color:var(--mainColor)" *ngIf="(allMeeting.length==0 && isSession) || allMeeting ==undefined || allMeeting.length ==0 && isSession">
    No Class(es) Available.
  </div>
  <br *ngIf="isSession">
  <hr *ngIf="isSession">

  <h3 class="col-lg-12" (click)="redirectSearchBy('buddi')" style="color:var(--mainColor);cursor: pointer;" *ngIf="isBuddiGroup">buddi Group Classes <span class="more-text" *ngIf="buddiGroupData.length!=0">more</span></h3>
  <div class="row container" *ngIf="buddiGroupData.length!=0 && isBuddiGroup">
    <div class="col-lg-3 col-md-6"
      *ngFor="let slide of buddiGroupData">
      <div class="single-courses-box without-box-shadow mb-30">
        <div class="courses-image">
          <a (click)="buddiGroupRedirect(slide)" class="d-block">
            <img [src]="'assets/img/courses/courses1.jpg'" style="width: 250px;height: 175px;" alt="image">
          </a>
        </div>
        <div class="courses-content">
          <div class="course-author d-flex align-items-center">
              <img [src]="slide.instructor!=undefined && slide.instructor.userPhoto!='' ? slide.instructor.userPhoto :'assets/img/user1.jpg'"class="rounded-circle mr-2" alt="image">
              <span *ngIf="slide.instructor!=undefined">{{slide.instructor.firstName}}</span>
            </div>
          <div class="course-author d-flex align-items-center">           
            <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image">
            {{slide.startTime |  date :"MMM dd,yyyy"}} | {{slide.endTime |  date :"MMM dd,yyyy"}}
          </div>
          <h3>
            <a (click)="detailsMeeting(slide,'Livesession')" class="d-inline-block"><span class="text-break">{{slide.name}}</span></a>
          </h3>

        </div>

        <div>
          {{slide.categoryID ? slide.categoryID.categoryName : '' }}, {{slide.gradeID ? slide.gradeID.gradeName : '' }},
          {{slide.boardID ? slide.boardID.boardName : '' }}
        </div>
        <div class="courses-box-footer">
          {{slide.subjectID ? slide.subjectID.subjectName : '' }},
          {{slide.languageID ? slide.languageID.languageName : '' }}
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-12" style="color:var(--mainColor)" *ngIf="(buddiGroupData.length ==0 && isBuddiGroup) || (buddiGroupData.length ==0 && isBuddiGroup)">
    No buddi Group Class(es) Available.
  </div>
  <!-- <div style="float: right;" *ngIf="isBuddiGroup && buddiGroupData.length!=0">
    <pagination-controls (pageChange)="p7 = $event" id="pagination7"></pagination-controls>
  </div> -->
<br *ngIf="isBuddiGroup">
<hr *ngIf="isBuddiGroup">


  <h3 class="col-lg-12" (click)="redirectSearchBy('course')" style="color:var(--mainColor);cursor: pointer;" *ngIf="isCourse">Courses <span class="more-text">more</span></h3>
  <div class="row container" *ngIf="isCourse" style="cursor: pointer;border-bottom: 1px solid #d2d0d0">
    <div class="col-lg-3 col-md-6"
      *ngFor="let slidecp of courseData |filter:exploreForm.value.term">
      <div class="single-courses-box without-box-shadow mb-30">
        <div class="courses-image">
          <a (click)="coursedetails(slidecp)" class="d-block"><img
              [src]="slidecp.courseImage =='' ? 'assets/img/courses/courses1.jpg':slidecp.courseImage"
              style="width: 250px;height: 175px;" alt="image"></a>

        </div>
        <div class="courses-content">
          <div class="course-author d-flex align-items-center">
            <img [src]="slidecp.createdBy!=undefined && slidecp.createdBy.userPhoto!='' ? slidecp.createdBy.userPhoto :'assets/img/user1.jpg'" class="rounded-circle mr-2" alt="image">
            <span>{{slidecp.createdBy ? slidecp.createdBy.firstName : null }}</span>
          </div>
          <div class="course-author d-flex align-items-center">
            <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image"
              style="width: 30px;height: 30px;">
            <span>{{slidecp.startDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</span>
          </div>
          <h3><a (click)="coursedetails(slidecp)" class="d-inline-block">{{slidecp.fullName}}</a></h3>

        </div>
        <div class="courses-box-footer">
          {{slidecp.categoryID ? slidecp.categoryID.categoryName : 'N/A' }} ,
          {{slidecp.gradeID ? slidecp.gradeID.gradeName : 'N/A' }} ,
          {{slidecp.boardID ? slidecp.boardID.boardName : 'N/A' }}
          <br>
          {{slidecp.subjectID ? slidecp.subjectID.subjectName : 'N/A' }} ,
          {{slidecp.languageID ? slidecp.languageID.languageName : 'N/A' }}

        </div>
      </div>
    </div>
    <div class="col-lg-12" *ngIf="(courseData?.length < 1 && isCourse) || (courseData|filter:exploreForm.value.term).length==0" style="padding-bottom: 3%;color:var(--mainColor)">
      No Course(s) Available.
    </div>
   <!--  <div class="col-lg-12 col-md-12" *ngIf="courseData?.length > 0 && isCourse">
      <div style="float: right;">
        <pagination-controls (pageChange)="p = $event" id="pagination1"></pagination-controls>
      </div>
    </div> -->
  </div>



  <!-- <h3 class="col-lg-12" *ngIf="isexams" style="padding-top: 2%;">Exams</h3>
  <div class="row container" *ngIf="isexams" style="cursor: pointer;border-bottom: 1px solid #d2d0d0">
    <div class="col-lg-3 col-md-6"
      *ngFor="let slidedex of examData | paginate: { id: 'pagination3',itemsPerPage: count2, currentPage: p2 }">
      <div class="single-courses-box without-box-shadow mb-30">
        <div class="courses-image">
          <a (click)="Examdetails(slidedex)" class="d-block"><img
              [src]="slidedex.examImage =='' ? 'assets/img/courses/courses1.jpg':slidedex.examImage"
              style="width: 250px;height: 175px;" alt="image"></a>

        </div>
        <div class="courses-content">
          <div class="course-author d-flex align-items-center">
            <img [src]="slidedex.createdBy!=undefined && slidedex.createdBy.userPhoto!='' ? slidedex.createdBy.userPhoto :'assets/img/user1.jpg'" class="rounded-circle mr-2" alt="image">
            <span>{{slidedex.createdBy ? slidedex.createdBy.firstName : null }}</span>
          </div>
          <div class="course-author d-flex align-items-center">
            <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image"
              style="width: 30px;height: 30px;">
            <span>{{slidedex.startDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</span>
          </div>
          <h3><a (click)="Examdetails(slidedex)" class="d-inline-block">{{slidedex.fullName}}</a></h3>

        </div>
        <div class="courses-box-footer">
          {{slidedex.categoryID ? slidedex.categoryID.categoryName : 'N/A' }} ,
          {{slidedex.gradeID ? slidedex.gradeID.gradeName : 'N/A' }} ,
          {{slidedex.boardID ? slidedex.boardID.boardName : 'N/A' }}
          <br>
          {{slidedex.subjectID ? slidedex.subjectID.subjectName : 'N/A' }} ,
          {{slidedex.languageID ? slidedex.languageID.languageName : 'N/A' }}

        </div>
      </div>
    </div>
    <div class="col-lg-12" *ngIf="examData?.length < 1 && isexams" style="padding-bottom: 3%;">
      No Exam(s) Available.
    </div>
    <div class="col-lg-12 col-md-12" *ngIf="examData?.length > 0 && isexams">
      <div style="float: right;">
        <pagination-controls (pageChange)="p2 = $event" id="pagination3"></pagination-controls>
      </div>
    </div>
  </div> -->

  <h3 *ngIf="istests" (click)="redirectSearchBy('tests')" style="padding-top: 5%;color:var(--mainColor);cursor: pointer;">Tests  <span class="more-text">more</span></h3>
  <div class="row container" *ngIf="istests" style="cursor: pointer">
  <div class="col-lg-12 col-md-12" style="cursor: pointer;border-bottom: 1px solid #d2d0d0" >
    <h3  (click)="redirectSearchBy('evaluation-test')" style="margin-bottom: 2%;color:var(--mainColor);font-size: 1.75rem;">Evaluation  <span class="more-text">more</span></h3>
    <div class="row">
    <div class="col-lg-3 col-md-6"
    *ngFor="let slidetest1 of testevaluationData | filter:exploreForm.value.term">
    <div class="single-courses-box without-box-shadow mb-30">
      <div class="courses-image">
        <a (click)="gettestdetails(slidetest1._id)" class="d-block"><img src='assets/img/courses/courses1.jpg'
            style="width: 250px;height: 175px;" alt="image"></a>

      </div>
      <div class="courses-content">
        <div class="course-author d-flex align-items-center">
          <img src="assets/img/usercourse.png" class="rounded-circle mr-2" alt="image">
          <span>{{slidetest1.category ? slidetest1.category.categoryName : 'Not Applicable'}}</span>
        </div>
        <div class="course-author d-flex align-items-center">
          <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image"
            style="width: 30px;height: 30px;">
          <span>{{slidetest1.scheduledDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</span>
        </div>
        <h3><a  class="d-inline-block">{{slidetest1.testName}}</a></h3>

      </div>
      <div class="courses-box-footer" >
        
        {{slidetest1.subjectString}}
      </div>
    </div>
  </div>
</div>
  <div style="color:var(--mainColor)" *ngIf="testevaluationData?.length < 1 || (testevaluationData|filter:exploreForm.value.term).length==0 && istests">
    No Evaluation Test(s) Available.
  </div>
  <!-- <div class="col-lg-12 col-md-12" *ngIf="testevaluationData?.length > 0 && istests">
    <div style="float: right;">
      <pagination-controls (pageChange)="test1 = $event" id="paginationtest1"></pagination-controls>
    </div>
  </div>  --> 
  <div style="margin-top: 2%;"></div>
</div>

<div class="col-lg-12 col-md-12" style="cursor: pointer;border-bottom: 1px solid #d2d0d0;margin-bottom: 2%;">
  <h3 (click)="redirectSearchBy('benchmarking-test')" style="margin-top: 2%;color:var(--mainColor);cursor: pointer;font-size: 1.75rem;" >Benchmarking <span class="more-text">more</span></h3>
  <div class="row">
  <div class="col-lg-3 col-md-6"
  *ngFor="let slidetest2 of testbenchmarkingData  | filter:exploreForm.value.term">
  <div class="single-courses-box without-box-shadow mb-30">
    <div class="courses-image">
      <a  (click)="gettestdetails(slidetest2._id)" class="d-block"><img src='assets/img/courses/courses1.jpg'
          style="width: 250px;height: 175px;" alt="image"></a>

    </div>
    <div class="courses-content">
      <div class="course-author d-flex align-items-center">
        <img src="assets/img/usercourse.png" class="rounded-circle mr-2" alt="image">
        <span>{{slidetest2.category ? slidetest2.category.categoryName : 'Not Applicable'}}</span>
      </div>
      <div class="course-author d-flex align-items-center">
        <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image"
          style="width: 30px;height: 30px;">
        <span>{{slidetest2.scheduledDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</span>
      </div>
      <h3><a  class="d-inline-block">{{slidetest2.testName}}</a></h3>

    </div>
    <div class="courses-box-footer">
      {{slidetest2.subjectString}}       
    </div>
  </div>
</div>
</div>
<div style="color:var(--mainColor)" *ngIf="testbenchmarkingData?.length < 1 || (testbenchmarkingData|filter:exploreForm.value.term).length==0 && istests">
  No Benchmarking Test(s) Available.
</div>
<!-- <div class="col-lg-12 col-md-12" *ngIf="testbenchmarkingData?.length > 0 && istests">
  <div style="float: right;">
    <pagination-controls (pageChange)="test2 = $event" id="paginationtest2"></pagination-controls>
  </div>
</div> -->  
<div style="margin-top: 2%;"></div>
</div>

<div class="col-lg-12 col-md-12" style="cursor: pointer;border-bottom: 1px solid #d2d0d0;margin-bottom: 2%;">
  <h3 (click)="redirectSearchBy('previousyear-test')" style="margin-top: 2%;color:var(--mainColor);font-size: 1.75rem;">Previous Year <span class="more-text">more</span></h3>
  <div class="row">
<div class="col-lg-3 col-md-6"
  *ngFor="let slidetest3 of testpreviousyearData | filter:exploreForm.value.term">
  <div class="single-courses-box without-box-shadow mb-30">
    <div class="courses-image">
      <a  (click)="gettestdetails(slidetest3._id)" class="d-block"><img src='assets/img/courses/courses1.jpg'
          style="width: 250px;height: 175px;" alt="image"></a>

    </div>
    <div class="courses-content">
      <div class="course-author d-flex align-items-center">
        <img src="assets/img/usercourse.png" class="rounded-circle mr-2" alt="image">
        <span>{{slidetest3.category ? slidetest3.category.categoryName : 'Not Applicable'}}</span>
      </div>
      <div class="course-author d-flex align-items-center">
        <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image"
          style="width: 30px;height: 30px;">
        <span>{{slidetest3.scheduledDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</span>
      </div>
      <h3><a  class="d-inline-block">{{slidetest3.testName}}</a></h3>

    </div>
    <div class="courses-box-footer">
      {{slidetest3.subjectString}}       
    </div>
  </div>
</div>
</div>
<div style="color:var(--mainColor)" *ngIf="testpreviousyearData?.length < 1 || (testpreviousyearData|filter:exploreForm.value.term).length==0 && istests">
  No Previous Year Test(s) Available.
</div>
<!-- <div class="col-lg-12 col-md-12" *ngIf="testpreviousyearData?.length > 0 && istests">
  <div style="float: right;">
    <pagination-controls (pageChange)="test3 = $event" id="paginationtest3"></pagination-controls>
  </div>
</div> -->
<div style="margin-top: 2%;"></div>
</div>

<div class="col-lg-12 col-md-12" style="cursor: pointer;border-bottom: 1px solid #d2d0d0;margin-bottom: 2%;">
  <h3 (click)="redirectSearchBy('progressive-test')" style="margin-top: 2%;color:var(--mainColor);font-size: 1.75rem;">Progressive <span class="more-text">more</span></h3>
  <div class="row">
<div class="col-lg-3 col-md-6"
  *ngFor="let slidetest4 of testprogressiveData | filter:exploreForm.value.term">
  <div class="single-courses-box without-box-shadow mb-30">
    <div class="courses-image">
      <a (click)="gettestdetails(slidetest4._id)" class="d-block"><img src='assets/img/courses/courses1.jpg'
          style="width: 250px;height: 175px;" alt="image"></a>

    </div>
    <div class="courses-content">
      <div class="course-author d-flex align-items-center">
        <img src="assets/img/usercourse.png" class="rounded-circle mr-2" alt="image">
        <span>{{slidetest4.category ? slidetest4.category.categoryName : 'Not Applicable'}}</span>
      </div>
      <div class="course-author d-flex align-items-center">
        <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image"
          style="width: 30px;height: 30px;">
        <span>{{slidetest4.scheduledDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</span>
      </div>
      <h3><a  class="d-inline-block">{{slidetest4.testName}}</a></h3>

    </div>
    <div class="courses-box-footer">
      {{slidetest4.subjectString}}       
    </div>
  </div>
</div>
</div>
<div style="color:var(--mainColor)" *ngIf="testprogressiveData?.length < 1 || (testprogressiveData|filter:exploreForm.value.term).length==0 && istests">
  No Progressive Tests(s) Available.
</div>
  <div style="margin-top: 2%;"></div>
  </div>
  </div>

  
  <div class="container" style="padding-top: 2%;">
  </div>

















  <h3 *ngIf="isEvaluation" (click)="redirectSearchBy('tests')" style="padding-top: 5%;color:var(--mainColor);cursor: pointer;">Tests  <span class="more-text">more</span></h3>
  <div class="row container" *ngIf="isEvaluation" style="cursor: pointer">
  <div class="col-lg-12 col-md-12" style="cursor: pointer;border-bottom: 1px solid #d2d0d0" >
    <h3  (click)="redirectSearchBy('evaluation-test')" style="margin-bottom: 2%;color:var(--mainColor);font-size: 1.75rem;">Evaluation  <span class="more-text">more</span></h3>
    <div class="row">
    <div class="col-lg-3 col-md-6"
    *ngFor="let slidetest1 of testevaluationData | filter:exploreForm.value.term">
    <div class="single-courses-box without-box-shadow mb-30">
      <div class="courses-image">
        <a (click)="gettestdetails(slidetest1._id)" class="d-block"><img src='assets/img/courses/courses1.jpg'
            style="width: 250px;height: 175px;" alt="image"></a>

      </div>
      <div class="courses-content">
        <div class="course-author d-flex align-items-center">
          <img src="assets/img/usercourse.png" class="rounded-circle mr-2" alt="image">
          <span>{{slidetest1.category ? slidetest1.category.categoryName : 'Not Applicable'}}</span>
        </div>
        <div class="course-author d-flex align-items-center">
          <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image"
            style="width: 30px;height: 30px;">
          <span>{{slidetest1.scheduledDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</span>
        </div>
        <h3><a  class="d-inline-block">{{slidetest1.testName}}</a></h3>

      </div>
      <div class="courses-box-footer" >
        
        {{slidetest1.subjectString}}
      </div>
    </div>
  </div>
</div>
  <div style="color:var(--mainColor)" *ngIf="testevaluationData?.length < 1 || (testevaluationData|filter:exploreForm.value.term).length==0 && istests">
    No Evaluation Test(s) Available.
  </div>
  <!-- <div class="col-lg-12 col-md-12" *ngIf="testevaluationData?.length > 0 && istests">
    <div style="float: right;">
      <pagination-controls (pageChange)="test1 = $event" id="paginationtest1"></pagination-controls>
    </div>
  </div>  --> 
  <div style="margin-top: 2%;"></div>
</div>

<div class="col-lg-12 col-md-12" style="cursor: pointer;border-bottom: 1px solid #d2d0d0;margin-bottom: 2%;">
  <h3 (click)="redirectSearchBy('benchmarking-test')" style="margin-top: 2%;color:var(--mainColor);cursor: pointer;font-size: 1.75rem;" >Benchmarking <span class="more-text">more</span></h3>
  <div class="row">
  <div class="col-lg-3 col-md-6"
  *ngFor="let slidetest2 of testbenchmarkingData  | filter:exploreForm.value.term">
  <div class="single-courses-box without-box-shadow mb-30">
    <div class="courses-image">
      <a  (click)="gettestdetails(slidetest2._id)" class="d-block"><img src='assets/img/courses/courses1.jpg'
          style="width: 250px;height: 175px;" alt="image"></a>

    </div>
    <div class="courses-content">
      <div class="course-author d-flex align-items-center">
        <img src="assets/img/usercourse.png" class="rounded-circle mr-2" alt="image">
        <span>{{slidetest2.category ? slidetest2.category.categoryName : 'Not Applicable'}}</span>
      </div>
      <div class="course-author d-flex align-items-center">
        <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image"
          style="width: 30px;height: 30px;">
        <span>{{slidetest2.scheduledDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</span>
      </div>
      <h3><a  class="d-inline-block">{{slidetest2.testName}}</a></h3>

    </div>
    <div class="courses-box-footer">
      {{slidetest2.subjectString}}       
    </div>
  </div>
</div>
</div>
<div style="color:var(--mainColor)" *ngIf="testbenchmarkingData?.length < 1 || (testbenchmarkingData|filter:exploreForm.value.term).length==0 && istests">
  No Benchmarking Test(s) Available.
</div>
<!-- <div class="col-lg-12 col-md-12" *ngIf="testbenchmarkingData?.length > 0 && istests">
  <div style="float: right;">
    <pagination-controls (pageChange)="test2 = $event" id="paginationtest2"></pagination-controls>
  </div>
</div> -->  
<div style="margin-top: 2%;"></div>
</div>

<div class="col-lg-12 col-md-12" style="cursor: pointer;border-bottom: 1px solid #d2d0d0;margin-bottom: 2%;">
  <h3 (click)="redirectSearchBy('previousyear-test')" style="margin-top: 2%;color:var(--mainColor);font-size: 1.75rem;">Previous Year <span class="more-text">more</span></h3>
  <div class="row">
<div class="col-lg-3 col-md-6"
  *ngFor="let slidetest3 of testpreviousyearData | filter:exploreForm.value.term">
  <div class="single-courses-box without-box-shadow mb-30">
    <div class="courses-image">
      <a  (click)="gettestdetails(slidetest3._id)" class="d-block"><img src='assets/img/courses/courses1.jpg'
          style="width: 250px;height: 175px;" alt="image"></a>

    </div>
    <div class="courses-content">
      <div class="course-author d-flex align-items-center">
        <img src="assets/img/usercourse.png" class="rounded-circle mr-2" alt="image">
        <span>{{slidetest3.category ? slidetest3.category.categoryName : 'Not Applicable'}}</span>
      </div>
      <div class="course-author d-flex align-items-center">
        <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image"
          style="width: 30px;height: 30px;">
        <span>{{slidetest3.scheduledDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</span>
      </div>
      <h3><a  class="d-inline-block">{{slidetest3.testName}}</a></h3>

    </div>
    <div class="courses-box-footer">
      {{slidetest3.subjectString}}       
    </div>
  </div>
</div>
</div>
<div style="color:var(--mainColor)" *ngIf="testpreviousyearData?.length < 1 || (testpreviousyearData|filter:exploreForm.value.term).length==0 && istests">
  No Previous Year Test(s) Available.
</div>
<!-- <div class="col-lg-12 col-md-12" *ngIf="testpreviousyearData?.length > 0 && istests">
  <div style="float: right;">
    <pagination-controls (pageChange)="test3 = $event" id="paginationtest3"></pagination-controls>
  </div>
</div> -->
<div style="margin-top: 2%;"></div>
</div>

<div class="col-lg-12 col-md-12" style="cursor: pointer;border-bottom: 1px solid #d2d0d0;margin-bottom: 2%;">
  <h3 (click)="redirectSearchBy('progressive-test')" style="margin-top: 2%;color:var(--mainColor);font-size: 1.75rem;">Progressive <span class="more-text">more</span></h3>
  <div class="row">
<div class="col-lg-3 col-md-6"
  *ngFor="let slidetest4 of testprogressiveData | filter:exploreForm.value.term">
  <div class="single-courses-box without-box-shadow mb-30">
    <div class="courses-image">
      <a (click)="gettestdetails(slidetest4._id)" class="d-block"><img src='assets/img/courses/courses1.jpg'
          style="width: 250px;height: 175px;" alt="image"></a>

    </div>
    <div class="courses-content">
      <div class="course-author d-flex align-items-center">
        <img src="assets/img/usercourse.png" class="rounded-circle mr-2" alt="image">
        <span>{{slidetest4.category ? slidetest4.category.categoryName : 'Not Applicable'}}</span>
      </div>
      <div class="course-author d-flex align-items-center">
        <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image"
          style="width: 30px;height: 30px;">
        <span>{{slidetest4.scheduledDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</span>
      </div>
      <h3><a  class="d-inline-block">{{slidetest4.testName}}</a></h3>

    </div>
    <div class="courses-box-footer">
      {{slidetest4.subjectString}}       
    </div>
  </div>
</div>
</div>
<div style="color:var(--mainColor)" *ngIf="testprogressiveData?.length < 1 || (testprogressiveData|filter:exploreForm.value.term).length==0 && istests">
  No Progressive Tests(s) Available.
</div>
  <div style="margin-top: 2%;"></div>
  </div>
  </div>

  
  <div class="container" style="padding-top: 2%;">
  </div>


















  <!-- Ebook Start -->
  <h3 class="col-lg-12" (click)="redirectSearchBy('ebook')" style="color:var(--mainColor);cursor: pointer;" *ngIf="iseBook">e-Books 
    <span class="more-text" *ngIf="ebooklistbyId.length!=0">more</span>
  </h3>
  <div class="container-fluid row" style="cursor: pointer" *ngIf="ebooklistbyId.length !=0 && iseBook">
    <div class="col-lg-3 col-md-6" *ngFor="let slide of ebooklistbyId">
      <div class="single-courses-box without-box-shadow mb-30">
        <div class="courses-image">
          <img style="width:100%" (click)="ebookdetails(slide)"
            [src]="slide.thumbname ? slide.thumbname : 'assets/img/videocat-thumb-1.jpg'" alt="" width="100%">
        </div>
        <div class="courses-content">
          <div class="course-author d-flex align-items-center" *ngIf="slide.studentID !=undefined">
            <img
              [src]="slide.studentID!=undefined && slide.studentID.userPhoto!='' ? slide.studentID.userPhoto  :'assets/img/user1.jpg'"
              class="rounded-circle mr-2" alt="image">
            <span *ngIf="slide.studentID!=undefined">{{slide.studentID.profileName}}
            </span>
          </div>
          <div class="course-author d-flex align-items-center" *ngIf="slide.studentID ==undefined">
            <img
              [src]="slide.teacherID!=undefined && slide.teacherID.userPhoto!='' ? slide.teacherID.userPhoto :'assets/img/user1.jpg'"
              class="rounded-circle mr-2" alt="image">
            <span *ngIf="slide.teacherID!=undefined">{{slide.teacherID.profileName}}
            </span>
          </div>
          <h3>
            <a (click)="ebookdetails(slide)" class="d-inline-block"><span class="text-break">{{slide.flimname}}</span>
            </a>
          </h3>
        </div>
        <!-- <list5 [slide]="slide"></list5> -->
        <div>
          {{slide.categoryID ? slide.categoryID[0].categoryName : '' }}, {{slide.gradeID ? slide.gradeID[0].gradeName : '' }},
          {{slide.boardID ? slide.boardID.boardName : '' }}
        </div>
        <div class="courses-box-footer">
          {{slide.subjectID ? slide.subjectID[0].subjectName : '' }},
          {{slide.languageID ? slide.languageID.languageName : '' }}
        </div>
      </div>
    </div>
  </div>
  <div style="color:var(--mainColor)" class="col-lg-12" *ngIf="(ebooklistbyId.length ==0 && iseBook) || (ebooklistbyId.length==0 && iseBook)">
    No e-Book(s) Available.
  </div>
  <br *ngIf="iseBook">
  <hr *ngIf="iseBook">
  <!-- Ebook End -->

  <!-- Solution Start -->
   <h3 class="col-lg-12" (click)="redirectSearchBy('solution')" style="color:var(--mainColor);cursor: pointer;" *ngIf="iseBook">Solutions <span class="more-text" *ngIf="solutionList.length!=0">more</span>
  </h3>
  <div class="container-fluid row" style="cursor: pointer" *ngIf="solutionList.length !=0 && iseBook">   
    <div class="col-lg-3 col-md-6" *ngFor="let slide of solutionList">
        <div class="single-courses-box without-box-shadow mb-30">
            <div class="courses-image">
                <img style="width:100%" (click)="redirectDetails(slide,'solution')" style="width: 250px;height: 175px;"  [src]="slide.thumbname ? slide.thumbname : 'assets/img/videocat-thumb-1.jpg'" alt=""  width="100%">
            </div>
            <div class="courses-content">
                <div class="course-author d-flex align-items-center">
                    <img [src]="slide.teacherID!=undefined && slide.teacherID.userPhoto!='' ? slide.teacherID.userPhoto  :'assets/img/user1.jpg'"class="rounded-circle mr-2" alt="image">
                    <span *ngIf="slide.teacherID!=undefined">{{slide.teacherID.profileName}}</span>  
                </div>
                <h3>
                    <a (click)="redirectDetails(slide,'solution')" class="d-inline-block"> 
                        <span class="text-break">{{slide.flimname}}</span>
                    </a>      
                </h3>
            </div>               
            <!-- <list5 [slide]="slide"></list5>  -->
            <div>
              {{slide.categoryID ? slide.categoryID[0].categoryName : '' }}, {{slide.gradeID ? slide.gradeID[0].gradeName : '' }},
              {{slide.boardID ? slide.boardID.boardName : '' }}
            </div>
            <div class="courses-box-footer">
              {{slide.subjectID ? slide.subjectID[0].subjectName : '' }},
              {{slide.languageID ? slide.languageID.languageName : '' }}
            </div>
        </div>
    </div>
  </div>
  <div style="color:var(--mainColor)" class="col-lg-12" *ngIf="solutionList.length ==0 && iseBook">
    No Solution(s) Available.
  </div>

  <br *ngIf="iseBook">
  <hr *ngIf="iseBook">
  <!-- Solution End -->

  <!-- Question Start -->
  <h3 class="col-lg-12" (click)="redirectSearchBy('question')" style="color:var(--mainColor);cursor: pointer;" *ngIf="iseBook">Question Papers<span class="more-text" *ngIf="questionList.length!=0">more</span>
  </h3>
  <div class="container-fluid row" style="cursor: pointer" *ngIf="questionList.length !=0 && iseBook">   
    <div class="col-lg-3 col-md-6" *ngFor="let slide of questionList">
        <div class="single-courses-box without-box-shadow mb-30">
            <div class="courses-image">
                <img style="width:100%" (click)="redirectDetails(slide,'question')" style="width: 250px;height: 175px;"  [src]="slide.thumbname ? slide.thumbname : 'assets/img/videocat-thumb-1.jpg'" alt=""  width="100%">
            </div>
            <div class="courses-content">
                <div class="course-author d-flex align-items-center">
                    <img [src]="slide.teacherID!=undefined && slide.teacherID.userPhoto!='' ? slide.teacherID.userPhoto  :'assets/img/user1.jpg'"class="rounded-circle mr-2" alt="image">
                    <span *ngIf="slide.teacherID!=undefined">{{slide.teacherID.profileName}}</span>  
                </div>
                <h3>
                    <a (click)="redirectDetails(slide,'question')" class="d-inline-block"> 
                        <span class="text-break">{{slide.flimname}}</span>
                    </a>      
                </h3>
            </div>               
            <!-- <list5 [slide]="slide"></list5>  -->
            <div>
              {{slide.categoryID ? slide.categoryID[0].categoryName : '' }}, {{slide.gradeID ? slide.gradeID[0].gradeName : '' }},
              {{slide.boardID ? slide.boardID.boardName : '' }}
            </div>
            <div class="courses-box-footer">
              {{slide.subjectID ? slide.subjectID[0].subjectName : '' }},
              {{slide.languageID ? slide.languageID.languageName : '' }}
            </div>
        </div>
    </div>
  </div>
  <div style="color:var(--mainColor)" class="col-lg-12" *ngIf="questionList.length ==0 && iseBook">
    No Question(s) Available.
  </div>

  <br *ngIf="iseBook">
  <hr *ngIf="iseBook">
  <!-- Question End -->

  <!-- Audio Start -->
  <h3 style="color:var(--mainColor);cursor: pointer;" (click)="redirectSearchBy('audio')" class="col-lg-12" *ngIf="isAudio">
    Audio Classes  
    <span class="more-text" *ngIf="songsList.length!=0">more</span>
  </h3>
  <div class="container-fluid row" style="cursor: pointer" *ngIf="songsList.length !=0 && isAudio">
    <div class="col-lg-3 col-md-6" *ngFor="let slide of songsList |filter:exploreForm.value.term">
      <div class="single-courses-box without-box-shadow mb-30">
        <div class="courses-image">
          <img style="width:100%" (click)="audiodetails(slide)"
            [src]="slide.thumbname ? slide.thumbname : 'assets/img/videocat-thumb-1.jpg'" alt="" width="100%">
        </div>
        <div class="courses-content">
          <div class="course-author d-flex align-items-center" *ngIf="slide.studentID !=undefined">
            <img
              [src]="slide.studentID!=undefined && slide.studentID.userPhoto!='' ? slide.studentID.userPhoto  :'assets/img/user1.jpg'"
              class="rounded-circle mr-2" alt="image">
            <span *ngIf="slide.studentID!=undefined">{{slide.studentID.profileName}}
            </span>
          </div>
          <div class="course-author d-flex align-items-center" *ngIf="slide.studentID ==undefined">
            <img
              [src]="slide.teacherID!=undefined && slide.teacherID.userPhoto!='' ? slide.teacherID.userPhoto  :'assets/img/user1.jpg'"
              class="rounded-circle mr-2" alt="image">
            <span *ngIf="slide.teacherID!=undefined">{{slide.teacherID.profileName}}
            </span>
          </div>
          <h3>
            <a (click)="audiodetails(slide)" class="d-inline-block"><span class="text-break">{{slide.flimname}}</span>
            </a>
          </h3>
        </div>
        <!-- <list5 [slide]="slide" [hideData]=true></list5> -->
        <div>
          {{slide.categoryID ? slide.categoryID[0].categoryName : '' }}, {{slide.gradeID ? slide.gradeID[0].gradeName : '' }},
          {{slide.boardID ? slide.boardID.boardName : '' }}
        </div>
        <div class="courses-box-footer">
          {{slide.subjectID ? slide.subjectID[0].subjectName : '' }},
          {{slide.languageID ? slide.languageID.languageName : '' }}
        </div>
      </div>
    </div>
  </div>
  <div style="color:var(--mainColor);cursor: pointer;" class="col-lg-12" *ngIf="(songsList.length ==0 && isAudio) || (songsList.length==0 && isAudio)">
    No Audio(s) Available.
  </div>
  <br *ngIf="isAudio">
  <hr *ngIf="isAudio">

  <h3 (click)="redirectSearchBy('video')" style="color:var(--mainColor);cursor: pointer" class="col-lg-12" *ngIf="isVideo">
    Video Lessons  <span class="more-text" *ngIf="videoList.length!=0">more</span>
  </h3>
  <div class="container-fluid row" style="cursor: pointer" *ngIf="videoList.length !=0 && isVideo">
    <div class="col-lg-3 col-md-6" *ngFor="let slide of videoList">
      <div class="single-courses-box without-box-shadow mb-30">
        <div class="courses-image">
          <img style="width:100%" (click)="videodetails(slide)" style="width: 250px;height: 175px;" [src]="slide.thumbname ? slide.thumbname : 'assets/img/videocat-thumb-1.jpg'" alt="" width="100%">
        </div>
        <div class="courses-content">
          <div class="course-author d-flex align-items-center" *ngIf="slide.studentID !=undefined">
            <img
              [src]="slide.studentID!=undefined && slide.studentID.userPhoto!='' ? slide.studentID.userPhoto  :'assets/img/user1.jpg'"
              class="rounded-circle mr-2" alt="image">
            <span *ngIf="slide.studentID!=undefined">{{slide.studentID.profileName}}
            </span>
          </div>
          <div class="course-author d-flex align-items-center" *ngIf="slide.studentID ==undefined">
            <img
              [src]="slide.teacherID!=undefined && slide.teacherID.userPhoto!='' ? slide.teacherID.userPhoto  :'assets/img/user1.jpg'"
              class="rounded-circle mr-2" alt="image">
            <span *ngIf="slide.teacherID!=undefined">{{slide.teacherID.profileName}}
            </span>
          </div>
          <h3>
            <a (click)="videodetails(slide)" class="d-inline-block"><span class="text-break">{{slide.flimname}}</span>
            </a>
          </h3>
        </div>
        <!-- <list5 [slide]="slide"></list5> -->
        <div *ngIf="slide.categoryID.length > 0">
          {{slide.categoryID ? slide.categoryID[0].categoryName : '' }}, {{slide.gradeID ? slide.gradeID[0].gradeName : '' }},
          {{slide.boardID ? slide.boardID.boardName : '' }}
        </div>
        <div class="courses-box-footer" *ngIf="slide.subjectID.length > 0">
          {{slide.subjectID ? slide.subjectID[0].subjectName : '' }},
          {{slide.languageID ? slide.languageID.languageName : '' }}
        </div>

        <div class="courses-box-footer" *ngIf="slide.subjectID.length == 0">
          {{ '' }},
          {{slide.languageID ? slide.languageID.languageName : '' }}
        </div>
      </div>
    </div>
  </div>
  <div style="color:var(--mainColor)" class="col-lg-12" *ngIf="(videoList.length ==0 && isVideo) || (videoList.length==0 && isVideo)">
    No Video(s) Available.
  </div>

  <br *ngIf="isVideo">
  <hr *ngIf="isVideo">

  <!-- videoYoutube Start -->
  <h3 (click)="redirectSearchBy('videoyoutube')" style="color:var(--mainColor);cursor: pointer" class="col-lg-12" *ngIf="isVideo">
   Study Hall 
   <span class="more-text" *ngIf="videoYoutubePublic.length!=0">more</span>
  </h3>
    <div class="container-fluid row" style="cursor: pointer" *ngIf="videoYoutubePublic.length !=0 && isVideo">
    <div class="col-lg-3 col-md-6" *ngFor="let slide of videoYoutubePublic">
      <div class="single-courses-box without-box-shadow mb-30">
        <div class="courses-image">
          <img style="width:100%" (click)="videodetails(slide)" style="width: 250px;height: 175px;" [src]="slide.thumbname ? slide.thumbname : 'assets/img/videocat-thumb-1.jpg'" alt="" width="100%">
        </div>
        <div class="courses-content">
          <div class="course-author d-flex align-items-center" *ngIf="slide.studentID !=undefined">
            <img
              [src]="slide.studentID!=undefined && slide.studentID.userPhoto!='' ? slide.studentID.userPhoto  :'assets/img/user1.jpg'"
              class="rounded-circle mr-2" alt="image">
            <span *ngIf="slide.studentID!=undefined">{{slide.studentID.profileName}}
            </span>
          </div>
          <div class="course-author d-flex align-items-center" *ngIf="slide.studentID ==undefined">
            <img
              [src]="slide.teacherID!=undefined && slide.teacherID.userPhoto!='' ? slide.teacherID.userPhoto  :'assets/img/user1.jpg'"
              class="rounded-circle mr-2" alt="image">
            <span *ngIf="slide.teacherID!=undefined">{{slide.teacherID.profileName}}
            </span>
          </div>
          <h3>
            <a (click)="videodetails(slide)" class="d-inline-block">
              <span class="text-break">{{slide.flimname}}</span>
            </a>
          </h3>
        </div>
        <!-- <list5 [slide]="slide"></list5> -->
        <div *ngIf="slide.categoryID.length > 0">
          {{slide.categoryID ? slide.categoryID[0].categoryName : '' }}, {{slide.gradeID ? slide.gradeID[0].gradeName : '' }},
          {{slide.boardID ? slide.boardID.boardName : '' }}
        </div>
        <div class="courses-box-footer" *ngIf="slide.subjectID.length > 0">
          {{slide.subjectID ? slide.subjectID[0].subjectName : '' }},
          {{slide.languageID ? slide.languageID.languageName : '' }}
        </div>
        <div class="courses-box-footer"  *ngIf="slide.subjectID.length == 0">
          {{'' }},
          {{slide.languageID ? slide.languageID.languageName : '' }}
        </div>
      
      </div>
    </div>
  </div>
  <div style="color:#aba3a3;" class="col-lg-12" *ngIf="(videoYoutubePublic.length ==0 && isVideo) || (videoYoutubePublic.length==0 && isVideo)">
    No Study Hall Classes Available.
  </div>

  <div class="container-fluid row" style="cursor: pointer" *ngIf="questionList.length !=0 && iseBook">   
    <div class="col-lg-3 col-md-6" *ngFor="let slide of questionList">
        <div class="single-courses-box without-box-shadow mb-30">
            <div class="courses-image">
                <img style="width:100%" (click)="redirectDetails(slide,'question')" style="width: 250px;height: 175px;"  [src]="slide.thumbname ? slide.thumbname : 'assets/img/videocat-thumb-1.jpg'" alt=""  width="100%">
            </div>
            <div class="courses-content">
                <div class="course-author d-flex align-items-center">
                    <img [src]="slide.teacherID!=undefined && slide.teacherID.userPhoto!='' ? slide.teacherID.userPhoto  :'assets/img/user1.jpg'"class="rounded-circle mr-2" alt="image">
                    <span *ngIf="slide.teacherID!=undefined">{{slide.teacherID.profileName}}</span>  
                </div>
                <h3>
                    <a (click)="redirectDetails(slide,'question')" class="d-inline-block"> 
                        <span class="text-break">{{slide.flimname}}</span>
                    </a>      
                </h3>
            </div>               
            <!-- <list5 [slide]="slide"></list5>  -->
            <div>
              {{slide.categoryID ? slide.categoryID[0].categoryName : '' }}, {{slide.gradeID ? slide.gradeID[0].gradeName : '' }},
              {{slide.boardID ? slide.boardID.boardName : '' }}
            </div>
            <div class="courses-box-footer">
              {{slide.subjectID ? slide.subjectID[0].subjectName : '' }},
              {{slide.languageID ? slide.languageID.languageName : '' }}
            </div>
        </div>
    </div>
  </div>
  <div class="container-fluid row" style="cursor: pointer" *ngIf="questionList.length !=0 && iseBook">   
    <div class="col-lg-3 col-md-6" *ngFor="let slide of questionList">
        <div class="single-courses-box without-box-shadow mb-30">
            <div class="courses-image">
                <img style="width:100%" (click)="redirectDetails(slide,'question')" style="width: 250px;height: 175px;"  [src]="slide.thumbname ? slide.thumbname : 'assets/img/videocat-thumb-1.jpg'" alt=""  width="100%">
            </div>
            <div class="courses-content">
                <div class="course-author d-flex align-items-center">
                    <img [src]="slide.teacherID!=undefined && slide.teacherID.userPhoto!='' ? slide.teacherID.userPhoto  :'assets/img/user1.jpg'"class="rounded-circle mr-2" alt="image">
                    <span *ngIf="slide.teacherID!=undefined">{{slide.teacherID.profileName}}</span>  
                </div>
                <h3>
                    <a (click)="redirectDetails(slide,'question')" class="d-inline-block"> 
                        <span class="text-break">{{slide.flimname}}</span>
                    </a>      
                </h3>
            </div>               
            <!-- <list5 [slide]="slide"></list5>  -->
            <div>
              {{slide.categoryID ? slide.categoryID[0].categoryName : '' }}, {{slide.gradeID ? slide.gradeID[0].gradeName : '' }},
              {{slide.boardID ? slide.boardID.boardName : '' }}
            </div>
            <div class="courses-box-footer">
              {{slide.subjectID ? slide.subjectID[0].subjectName : '' }},
              {{slide.languageID ? slide.languageID.languageName : '' }}
            </div>
        </div>
    </div>
  </div>
  <!-- <div style="color:var(--mainColor)" class="col-lg-12" *ngIf="questionList.length ==0 && iseBook">
    No Round table isAvailable.
  </div> -->

  <br>
  <br>
  <!-- Video ENd  -->
  <!-- Library End -->
</div>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <img src="assets/img/spinner-default.gif" alt="">
</ngx-spinner>