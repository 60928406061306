<app-header-style-one *ngIf="!isTeacher"></app-header-style-one>
    <app-header-style-one-teacher
        *ngIf="isTeacher"
    ></app-header-style-one-teacher>
<!-- <app-hometwo-main-banner></app-hometwo-main-banner><br> -->
<app-institution-mainbanner></app-institution-mainbanner><br>
<!-- <app-hometwo-main-banner></app-hometwo-main-banner><br> -->
 <!-- <app-homefive-main-banner></app-homefive-main-banner> -->
<div class="hero-banner bg-white">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-banner-content black-color">
                            <!-- <span class="sub-title">We Challenge Young Minds</span> -->
                            <h1 style="text-align: center;    font-size: 35px;
                            padding-left: 71px;">Take your Institution online, Offer your education online, Reach more learners globally.</h1>
                            <!-- <div class="btn-box">
                                <a routerLink="/teacher/register" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">Apply Now</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                                 <a routerLink="/contact" class="optional-btn">Get Started Free</a>
                            </div> -->
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="hero-banner-image text-center">
                            <img src="assets/img/banner-img3.png" alt="image">
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
<br>
<app-homefive-main-banners></app-homefive-main-banners>
<!-- <app-boxes></app-boxes> -->
<section class="pricing-area ptb-50">v 
        <div class="section-title">
                <!-- <span class="sub-title">Make Connections</span> -->
                <h2>Pricing Plans</h2>
                 </div>
    <div class="container">
                   <div class="pricing-table table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">buddi Application for tutors and learners</th>
                        <th scope="col">Free  <br>
                            (Start without Any commitment)
                        </th>
                      
                        <th scope="col">Basic    <br>
                            (Connect with learners Easily)
                        </th>
                    
                        <th scope="col">Professional<br>
                            (Take your business to next level)
                        </th>
                        <th scope="col">Business <br>
                            (Scale your businees)
                        </th>
                       
                    </tr>
                </thead>
                
                <tbody>
                    <!-- <tr>
                        <td></td>
                        <td>Start without Any commitment
                        </td>
                        <td> Connect with learners Easily
                        </td>
                        <td>Take your business to next level
                        </td>
                        <td>Scale your businees
                        </td>
                    </tr> -->
                    <tr>
                        <td>Website
                        </td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                    </tr>
                    <tr>
                        <td>Android App (Soon)

                        </td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                    </tr>
                    <tr>
                        <td>iOS App (Soon)

                        </td>
                        <td><i class='bx bx-check'></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                    </tr>
                    <tr>
                        <td>Transaction fees</td>
                        <td>10%</td>
                        <td> 0%</td>
                        <td> 0%</td>
                        <td>0%</td>
                    </tr>
                    <tr>
                        <td>Live Personal classes</td>
                        <td>Unlimted</td>
                        <td> 10 Classes</td>
                        <td> 50 Classes</td>
                        <td>Unlimted</td>
                    </tr>
                    <tr>
                        <td>Study Classes ( Group Class)</td>
                        <td>Unlimted</td>
                        <td> 10 Classes</td>
                        <td> 50 Classes</td>
                        <td>Unlimted</td>
                    </tr>
                    <tr>
                        <td>Round Table ( Webinars)</td>
                        <td>Unlimted</td>
                        <td> 10 Classes</td>
                        <td>50 Classes</td>
                        <td>Unlimted</td>
                    </tr>
                    <!-- <tr>
                        <td>Transection Fees</td>
                        <td>10%</td>
                        <td> 0%<td>
                        <td>0%</td>
                        <td>0%</td>
                    </tr> -->
                    <tr>
                        <td>Unlimted Learners
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'></i></td>
                    </tr>
                    <tr>
                        <td>Unlimited Video Class Sharing</td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>

                    </tr>
                    <tr>
                        <td>Unlimited Smart Content Sharing</td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                    </tr>
                    <tr>
                        <td>Unlimited Online Tests</td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                    </tr>
                    <tr>
                        <td>Unlimited Sharing</td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                    </tr>
                  
                    <tr>
                        <td>Administration Dashboard</td>
                        <td> <i class="bi-x-circle-fill "></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                    </tr>
                    <tr>
                        <td>Admin Controls (add/remove admin, tutor, learners)</td>
                        <td> <i class="bi-x-circle-fill "></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                    </tr>
                    <tr>
                        <td> Fee Management</td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                    </tr>
                    <tr>
                        <td> Payment gateway integration
                        </td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                    </tr>
                    <tr>
                        <td>Single Admin Access</td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                    </tr>
                    <tr>
                        <td>Course Completion Report
                        </td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                    </tr>
                    <tr>
                        <td>Tutor and Learner Notifications </td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                    </tr>
                  
                  
                    <tr>
                        <td>Institute Listing and Branding
                        </td>
                        <td> <i class="bi-x-circle-fill "></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                    </tr>
                    <tr>
                        <td>Powered by Buddi Life Logo

                        </td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                    </tr>
                    <tr>
                        <td>Buddi Rewards Programs for Learners and Tutors

                        </td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                    </tr>
                    <tr>
                        <td>BuddiCert Program Access
                        </td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                        <td><i class='bx bx-check'  ></i></td>
                    </tr>
                   <tr>
                    <td>Affiliate Marketring
                    </td>
                    <td> <i class="bi-x-circle-fill "></i></td>
                    <td> <i class="bi-x-circle-fill "></i></td>
                    <td><i class='bx bx-check'  ></i></td>
                    <td><i class='bx bx-check'  ></i></td>
                </tr>
                <tr>
                <td>Live chat support</td>
                <td> <i class="bi-x-circle-fill "></i></td>
                <td> <i class="bi-x-circle-fill "></i></td>
                <td><i class='bx bx-check'  ></i></td>
                <td><i class='bx bx-check'  ></i></td>
            </tr>
            <tr>
                <td>Phone support</td>
                <td> <i class="bi-x-circle-fill "></i></td>
                <td> <i class="bi-x-circle-fill "></i></td>
                <td> <i class="bi-x-circle-fill "></i></td>
                <td><i class='bx bx-check'  ></i></td>
            </tr>
            <tr>
                <td>Bulk data import

                </td>
                <td> <i class="bi-x-circle-fill "></i></td> 
                <td> <i class="bi-x-circle-fill "></i></td> 
                <td> <i class="bi-x-circle-fill "></i></td>
                <td><i class='bx bx-check'  ></i></td>
            </tr>
            <tr>
                <td>Dedicated WhatsApp Support 

                </td>
               <td> <i class="bi-x-circle-fill "></i></td>
               <td> <i class="bi-x-circle-fill "></i></td>
               <td> <i class="bi-x-circle-fill "></i></td>
                <td><i class='bx bx-check'  ></i></td>
            </tr>

                    <tr>
                       
                        <td></td>

                        <td><strong>Free </strong><br/>
                            
                        </td>
                        
                        <td><strong>INR. 499/- <br> USD 9.99</strong>
                        </td>
                        <td><strong>INR. 2999/- <br> USD 59.99 </strong>
                        </td>
                        <td><strong>INR. 9999/- <br> USD 149.99 </strong>
                        </td>
                    </tr>
                    
<td></td>
<td><a><button class="btn buy" (click)="rout()">Sign Up</button></a></td>
<td><a routerLink="/teacher/login"><button class="btn buy">Buy Now</button></a></td>
<td><a routerLink="/teacher/login"><button class="btn buy">Buy Now</button></a></td>
<td><a routerLink="/teacher/login"><button class="btn buy">Buy Now</button></a></td>
                    

                </tbody>
            </table>                                                                                                                                                                                                   
        </div>
    </div>
</section>3<section class="how-it-works-area pt-50 pb-50">
        <div class="section-title">
                <!-- <span class="sub-title">Make Connections</span> -->
                <h2>Institution Partners </h2>
               
            </div>
    <div class="container">
        <h3>
            Make your Institution future ready. Let us be your learning partner. </h3><br>
           <h5> Online classes has become the integral part of teaching. We will help you with all technology needs. You concentrate on teaching.
        </h5>
        <div class="col-lg-12 col-md-12" style="text-align: center; padding-top: 30px;">
                <div class="about-content">
                  <p style="font-size: 20px; text-align: left; color: black;font-weight:bold;">Benefits  for joining the subscription model:
 
               </p>
               <p style="font-size: 18px; color: black; text-align:justify; ">1. Platform fees discounted

               </p>
                  <p style="font-size: 18px; text-align: justify; color: black;">2. Institution listing 

               </p>
               <p style="font-size: 18px; text-align: justify; color: black;">3. Accept payment from learners

               </p>
               <p style="font-size: 18px; text-align: justify; color: black;">4. Add and associate Learners and tutors for your institute
               </p>
               <p style="font-size: 18px; text-align: justify; color: black;">5. Get more learners online

              </p>
               <p style="font-size: 18px; text-align: justify; color: black;">6. Get more offline learners

               </p>
               <p style="font-size: 18px; text-align: justify; color: black;">7. Get buddi coins for all your activities

               </p>
               <p style="font-size: 18px; text-align: justify; color: black;">8. Revenue thru buddiCERT digital Certifications

                </p>
                <p style="font-size: 18px; text-align: justify; color: black;">9. Powered by buddi.life 

                    </p>
              
               </div> 
           </div>
        <!-- <div class="section-title">           
            <h2>How It Works? </h2>
        </div> -->

        <!-- <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-work-process mb-30">
                    <div class="icon">
                        <a (click)="registerclick()" href="javascript:void(0)"> <i class='bx bx-search-alt'></i> </a>
                    </div>
                    <h3><a (click)="registerclick()" href="javascript:void(0)" style="color: white;">Register</a></h3>                   
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-work-process mb-30">
                    <div class="icon">
                        <a (click)="joinclass()" href="javascript:void(0)" ><i class='bx bx-info-square'></i></a>
                    </div>
                    <h3><a (click)="joinclass()" href="javascript:void(0)" style="color: white;">Join</a></h3>          
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-work-process mb-30">
                    <div class="icon">
                        <a (click)="joinclass()" href="javascript:void(0)"> <i class='bx bx-like'></i> </a>
                    </div>
                    <h3><a (click)="joinclass()" href="javascript:void(0)" style="color: white;">Take Classes</a></h3>          
                </div>
            </div>
        </div> -->
    </div>
</section>
<section class="boxes-area">
        <div class="container-fluid">
                <div class="section-title">
                        <!-- <span class="sub-title">Make Connections</span> -->
                        <h2>buddi Offers</h2>
                       
                    </div>
            <div class="row">
                <div class="col-lg-3 col-md-6">
                    <div class="single-boxes-item">
                       <h3> 50+ Institutions</h3>
                       <!-- <a routerLink="/courses-2-columns-style-1" class="boxes-btn">View Courses<i class='bx bx-plus'></i></a>-->
                    </div>
                </div>
    
                <div class="col-lg-3 col-md-6">
                    <div class="single-boxes-item bg-image">
                        <h3>1000's of Learners</h3>
                        <!-- <p>Just your teaching skills are enough</p> -->
                       <!-- <a routerLink="/courses-2-columns-style-2" class="boxes-btn">View More<i class='bx bx-plus'></i></a>-->
                    </div>
                </div>
    
                <div class="col-lg-3 col-md-6">
                    <div class="single-boxes-item bg-color">
                        <h3>Over 500+ Courses</h3>
                        <!-- <p>Just your teaching skills are enough</p> -->
                      <!--  <a routerLink="/courses-3-columns-style-1" class="boxes-btn">View Courses<i class='bx bx-plus'></i></a>-->
                    </div>
                </div>
    
                <div class="col-lg-3 col-md-6">
                    <div class="single-boxes-item">
                        <h3>100+ Tutors</h3>
                        <!-- <p>Teach from the comfort of your home</p> -->
                      <!--  <a routerLink="/courses-4-columns-style-1" class="boxes-btn">View More<i class='bx bx-plus'></i></a>-->
                    </div>
                </div>
            </div>
        </div>
    </section>
    

<app-teacheroffer></app-teacheroffer>

<section class="offer-area pt-100 jarallax" data-jarallax='{"speed": 0.3}'>
        <div class="container">
            <div class="section-title">
                <!-- <span class="sub-title">Make Connections</span> -->
                <h2>What Features buddi Offers</h2>
            </div>
    
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-offer-box">
                        <div class="icon">
                            <i class='bx bx-book-reader'></i>
                        </div>
                        <h3>Live classes ( One- on- One )</h3>
                      
                    </div>
                </div>
    
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-offer-box">
                        <div class="icon">
                            <i class='bx bx-book-reader'></i>
                        </div>
                        <h3>Study Hall (Group classes)</h3>
                      
                    </div>
                </div>
    
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-offer-box">
                        <div class="icon">
                            <i class='bx bx-target-lock'></i>
                        </div>
                        <h3>Round Table (Large seminars)</h3>
                       
                    </div>
                </div>
    
                <div class="col-lg-3 col-md-6 col-sm-6 ">
                    <div class="single-offer-box">
                        <div class="icon">
                            <i class=' bx bx-info-square'></i>
                        </div>
                        <h3>buddiCERT- Learners records on Blockchain</h3>
                       
                    </div>
                </div>
                 <!-- <div class="col-lg-3 col-md-6 col-sm-6 ">
                    <div class="single-offer-box">
                        <div class="icon">
                            <i class='bx bxs-thermometer'></i>
                        </div>
                        <h3>buddiCERT – Certification program</h3>
                       
                    </div>
                </div>  -->
            </div>
        </div>
    </section>
<app-how-it-works></app-how-it-works>
<!-- <section class="funfacts-area pt-100">
    <div class="container">
        <div class="funfacts-inner">
            <app-funfacts></app-funfacts>
            <div id="particles-js-circle-bubble"></div>
        </div>
    </div>
</section> -->

<!-- <app-homefive-courses></app-homefive-courses> -->

<!-- <section class="courses-categories-area pb-70">
    <div class="container">
        <div class="section-title text-left">
            <span class="sub-title">Courses Categories</span>
            <h2>Browse Trending Categories</h2>
            <a routerLink="/courses-category-style-2" class="default-btn"><i class='bx bx-show-alt icon-arrow before'></i><span class="label">View All</span><i class="bx bx-show-alt icon-arrow after"></i></a>
        </div>

        <app-categories-style-two></app-categories-style-two>
    </div>

    <div id="particles-js-circle-bubble-2"></div>
</section> -->
<br>

<!-- <app-become-instructor-partner></app-become-instructor-partner> -->
<section class="instructor-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <!-- <span class="sub-title">Make Connections</span> -->
            <h2>Our buddies</h2>
           
        </div>

        <app-instructors-style-three></app-instructors-style-three>
    </div>

    <div id="particles-js-circle-bubble-3"></div>
</section>

<section class="testimonials-area pt-100">
    <div class="container">
        <div class="section-title">
           
            <h2>Our Partner Tutor Says</h2>
        </div>

        <app-feedback-style-two></app-feedback-style-two>
    </div>
</section>


<!-- <app-offer1></app-offer1> -->
<!-- <section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-left">
            <span class="sub-title">Explore News</span>
            <h2>Our Latest Insights</h2>
            <a routerLink="/blog-style-1" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">Read All</span><i class="bx bx-book-reader icon-arrow after"></i></a>
        </div>

        <app-blog></app-blog>
    </div>
</section> -->
