<app-header-style-two></app-header-style-two>

<app-homeseven-main-banner></app-homeseven-main-banner>

<app-homeseven-courses></app-homeseven-courses>

<app-how-it-works></app-how-it-works>

<section class="instructor-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Make Connections</span>
            <h2>Team of Instructors</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea.</p>
        </div>

        <app-instructors-style-one></app-instructors-style-one>
    </div>

    <div id="particles-js-circle-bubble-3"></div>
</section>

<section class="feedback-area ptb-50">
    <div class="container">
        <app-feedback-style-one></app-feedback-style-one>
    </div>
</section>

<section class="partner-area ptb-70">
    <div class="container">
        <app-partner-style-two></app-partner-style-two>
    </div>
</section>

<app-become-instructor-partner></app-become-instructor-partner>