<section class="instructor-area pt-100 pb-70">
    <!-- <div class="instructor-slides owl-carousel owl-theme"> -->
    <div class="container">
        <div class="section-title">
            <!-- <span class="sub-title">Make Connections</span> -->
                       <h2>India's 1st</h2>
                       <h2> Micro Learning Marketplace</h2>
          
            <!--  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea.</p>-->
        </div>
        <div class="row">
            <div class="col-md-6" >
            <div class="single-instructor-box" >
                <div class="image">
                    <img src="../../../../../assets/img/for-Student.png" style="cursor: pointer;" title="click" (click)="studentclick()" 
                        onerror="this.src='../../../../../assets/img/for-Student.png';" alt="image" />
        
                        <div class="content">
                            <!-- <h3><a routerLink="/student/login" style="cursor: pointer;" title="click"> For Learner </a></h3> -->
                            <h3><a (click)="studentclick()"  href="javascript:void(0)" style="cursor: pointer;" title="click"> For Learner </a></h3>
                           
                        </div>
                </div>
            </div>
            </div>
            <div class="col-md-6">
                <div class="single-instructor-box">
                    <div class="image">
                        <img src="../../../../../assets/img/Teacher-Login-image.png" style="cursor: pointer;" title="click"  (click)="teacherclick()" 
                            onerror="this.src='../../../../../assets/img/Teacher-Login-image.png';" alt="image" />
                            <div class="content">
                                <!-- <h3><a routerLink="/teacher/login" style="cursor: pointer;" title="click">For Tutor</a></h3> -->
                                <h3><a   href="javascript:void(0)"  (click)="teacherclick()"  style="cursor: pointer;" title="click">For Tutor</a></h3>
                             
                            </div>
                      
                    </div>
            
                   
                </div>
            </div>
        </div>
        
    
            
        
        
      
        <!-- <app-student-instructor></app-student-instructor> -->
    </div>
<!-- </div> -->
    <!-- <div class="main-banner ">
        <div class="d-table">
            <div class="d-table-cell" style="background-color: #dcf5ff  ;">
                <div class="container">
                    <div class="main-banner-content" >
                        <div class="row">
                            <div class="col-lg-7 col-md-7 col-sm-7" style="padding-bottom: 10px;">
                                <span class="sub-title" style="color: black;">buddi.life</span>
                                <h2 style="color:var(--mainColor);" class="buddititle">Micro Learning </h2>
                                <h2 style="color: black; " class="buddisub">Take One Topic at a time. Build your Learning Gradually.
                                </h2> -->
                                
                               <!--  <a class="default-btn mr-2 p-3 " style="cursor: pointer;" title="Click here" (click)="neetcrash()">buddi-NEET 2021</a> -->
                               <!-- <span style="color:var(--mainColor) ;">NEET</span> -->
                            <!-- </div>
                            <div class="col-lg-5 col-md-5 col-sm-5 hovervideo" >
                                <img src="../../../../../assets/img/bannerslide1.png"  data-toggle="modal" data-target="#myModal1" data-backdrop="false" style="cursor: pointer; border-radius: 10px;"> -->
                                <!-- <iframe class="iframe" src="https://www.youtube.com/embed/Wzm15soEfOE?enablejsapi=1&version=3&playerapiid=ytplayer" frameborder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                                <!-- <div class="overlay1" data-toggle="modal" data-target="#myModal1" data-backdrop="false">
                                     <a  style="color: #FF0000;" class="icon">
                                <i c class='bx bxs-right-arrow'></i>
                            </a> 
                            </div> -->
                            <!-- </div> -->
                            <!-- <div  class="modal" id="myModal1">
                                <div class="modal-dialog" >
                                  <div class="modal-content" style="border-radius: 13px;">
                                    <div class="modal-body">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true"><b>&times;</b></span>
                                          </button>
                                        <iframe class="iframe" src="https://www.youtube.com/embed/JrnREWj7nQw?enablejsapi=1&version=3&playerapiid=ytplayer" frameborder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>

                                  </div>
                                </div>
                              </div> -->
                        <!-- </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="main-banner ">
        <div class="d-table">
            <div class="d-table-cell" style="background-color: #dcf5ff  ;">
                <div class="container">
                    <div class="main-banner-content" >
                        
                        <div class="row " >
                            <div class="col-lg-7 col-md-7 col-sm-7" style="padding-bottom: 10px;">
                                <span class="sub-title" style="color: black;">buddi.life</span>
                                <h2 style="color:var(--mainColor);" class="buddititle">Low Cost - A Relief during pandemic times </h2>
                                <h2 style="color: black;" class="buddisub">No high fees. Pay as you go.
                                </h2>
                                 -->
                                <!-- <a class="default-btn mr-2 p-3 " style="cursor: pointer;" title="Click here" (click)="k12()">K-12 2021</a> -->
                            
                            <!-- </div>
                            <div class="col-lg-5 col-md-5 col-sm-5 hovervideo">
                               <img src="../../../../../assets/img/bannerslide2.png" style="border-radius: 10px;" data-toggle="modal" data-target="#myModal2" data-backdrop="false"> -->
                                <!-- <iframe class="iframe" src="https://www.youtube.com/embed/w0Z_X8EBUfk?enablejsapi=1&version=3&playerapiid=ytplayer" frameborder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                                <!-- <div class="overlay" data-toggle="modal" data-target="#myModal2" data-backdrop="false">
                                     <a style="color: #FF0000;" class="icon">
                                <i  class='bx bxs-right-arrow'></i>
                            </a> 
                            </div> -->
                            <!-- </div> -->
                            <!-- <div  class="modal" id="myModal2">
                                <div class="modal-dialog">
                                  <div class="modal-content" style="border-radius: 13px;">
                                    <div class="modal-body">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true"><b>&times;</b></span>
                                          </button>
                                          <iframe class="iframe" src="https://www.youtube.com/embed/w0Z_X8EBUfk?enablejsapi=1&version=3&playerapiid=ytplayer" frameborder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>

                                  </div>
                                </div>
                              </div> -->
                        <!-- </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="main-banner ">
        <div class="d-table">
            <div class="d-table-cell" style="background-color: #dcf5ff  ;">
                <div class="container">
                    <div class="main-banner-content" >
                        
                        <div class="row " >
                            <div class="col-lg-7 col-md-7 col-sm-7" style="padding-bottom: 10px;">
                                <span class="sub-title" style="color: black;">buddi.life</span>
                                <h2 style="color:var(--mainColor);" class="buddititle">Learn in your Preferred Language </h2>
                                <h2 style="color: black;" class="buddisub">Support of Multiple Indian and Global languages.
                                </h2> -->
                                
                                <!-- <a class="default-btn mr-2 p-3 " style="cursor: pointer;" title="Click here" (click)="k12()">K-12 2021</a> -->
                            
                            <!-- </div>
                            <div class="col-lg-5 col-md-5 col-sm-5 hovervideo">
                               <img src="../../../../../assets/img/bannerslide3.png" style="border-radius: 10px;" data-toggle="modal" data-target="#myModal2" data-backdrop="false"> -->
                                <!-- <iframe class="iframe" src="https://www.youtube.com/embed/w0Z_X8EBUfk?enablejsapi=1&version=3&playerapiid=ytplayer" frameborder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                                <!-- <div class="overlaycoding" data-toggle="modal" data-target="#myModal3" data-backdrop="false">
                                 <a style="color: #FF0000;" class="icon">
                                <i  class='bx bxs-right-arrow'></i>
                            </a> 
                            </div> -->
                            <!-- </div> -->
                            <!-- <div  class="modal" id="myModal3">
                                <div class="modal-dialog">
                                  <div class="modal-content" style="border-radius: 13px;">
                                    <div class="modal-body">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true"><b>&times;</b></span>
                                          </button>
                                          <iframe class="iframe" src="https://www.youtube.com/embed/p6qVqYK8a2c?enablejsapi=1&version=3&playerapiid=ytplayer" frameborder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>

                                  </div>
                                </div>
                              </div> -->
                        <!-- </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <!-- <div class="main-banner ">
        <div class="d-table">
            <div class="d-table-cell" style="background-color: #dcf5ff  ;">
                <div class="container">
                    <div class="main-banner-content" >
                        
                        <div class="row " >
                            <div class="col-lg-7 col-md-7 col-sm-7" style="padding-bottom: 10px;">
                                <span class="sub-title" style="color: black;">buddi.life</span>
                                <h2 style="color:var(--mainColor);" class="buddititle">Over 500+ Courses Options</h2>
                                <h2 style="color: black;" class="buddisub">500+ Courses and growing. Wide variety to choose.
                                </h2> -->
                                
                                <!-- <a class="default-btn mr-2 p-3 " style="cursor: pointer;" title="Click here" (click)="k12()">K-12 2021</a> -->
                            
                            <!-- </div>
                            <div class="col-lg-5 col-md-5 col-sm-5 hovervideo">
                               <img src="../../../../../assets/img/bannerslide4.png" style="border-radius: 10px;" data-toggle="modal" data-target="#myModal2" data-backdrop="false"> -->
                                <!-- <iframe class="iframe" src="https://www.youtube.com/embed/w0Z_X8EBUfk?enablejsapi=1&version=3&playerapiid=ytplayer" frameborder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                                <!-- <div class="overlaycoding" data-toggle="modal" data-target="#myModal4" data-backdrop="false">
                                     <a style="color: #FF0000;" class="icon">
                                <i  class='bx bxs-right-arrow'></i>
                            </a> 
                            </div> -->
                            <!-- </div> -->
                            <!-- <div  class="modal" id="myModal4">
                                <div class="modal-dialog">
                                  <div class="modal-content" style="border-radius: 13px;">
                                    <div class="modal-body">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true"><b>&times;</b></span>
                                          </button>
                                          <iframe class="iframe" src="https://www.youtube.com/embed/j0cKS6KGgDU?enablejsapi=1&version=3&playerapiid=ytplayer" frameborder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>

                                  </div>
                                </div>
                              </div> -->
                        <!-- </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <!-- <div class="main-banner ">
        <div class="d-table">
            <div class="d-table-cell" style="background-color: #dcf5ff  ;">
                <div class="container">
                    <div class="main-banner-content" >
                        
                        <div class="row " >
                            <div class="col-lg-7 col-md-7 col-sm-7" style="padding-bottom: 10px;">
                                <span class="sub-title" style="color: black;">buddi.life</span>
                                <h2 style="color:var(--mainColor);" class="buddititle">Your Certificates on Blockchain</h2>
                                <h2 style="color: black;" class="buddisub">Safe. Secure. Verifiable. The future is here.
                                </h2> -->
                                
                                <!-- <a class="default-btn mr-2 p-3 " style="cursor: pointer;" title="Click here" (click)="k12()">K-12 2021</a> -->
<!--                             
                            </div>
                            <div class="col-lg-5 col-md-5 col-sm-5 hovervideo">
                               <img src="../../../../../assets/img/bannerslide5.png" style="border-radius: 10px;" data-toggle="modal" data-target="#myModal5" data-backdrop="false"> -->
                                <!-- <iframe class="iframe" src="https://www.youtube.com/embed/w0Z_X8EBUfk?enablejsapi=1&version=3&playerapiid=ytplayer" frameborder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                                <!-- <div class="overlay5" data-toggle="modal" data-target="#myModal5" data-backdrop="false">
                                     <a style="color: #FF0000;" class="icon">
                                <i  class='bx bxs-right-arrow'></i>
                            </a> 
                            </div> -->
                            <!-- </div> -->
                            <!-- <div  class="modal" id="myModal5">
                                <div class="modal-dialog">
                                  <div class="modal-content" style="border-radius: 13px;">
                                    <div class="modal-body">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true"><b>&times;</b></span>
                                          </button>
                                          <iframe class="iframe" src="https://www.youtube.com/embed/D8726i60q0A?enablejsapi=1&version=3&playerapiid=ytplayer" frameborder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>

                                  </div>
                                </div>
                              </div> -->
                        <!-- </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->


    <!-- <div class="main-banner ">
        <div class="d-table">
            <div class="d-table-cell" style="background-color: #dcf5ff  ;">
                <div class="container">
                    <div class="main-banner-content" >
                        
                        <div class="row " >
                            <div class="col-lg-7 col-md-7 col-sm-7" style="padding-bottom: 10px;">
                                <span class="sub-title" style="color: black;">buddi.life</span>
                                <h2 style="color:var(--mainColor);" class="buddititle">Micro Learning</h2>
                                <h2 style="color: black;" class="buddisub">Take one topic at a time. Build your learning gradually.
                                </h2>
                                
                             <a class="default-btn mr-2 p-3 " style="cursor: pointer;" title="Click here" (click)="k12()">K-12 2021</a> 
                            
                            </div>
                            <div class="col-lg-5 col-md-5 col-sm-5 hovervideo">
                               <img src="../../../../../assets/img/self-confidence.jpg" style="border-radius: 10px;" data-toggle="modal" data-target="#myModalself" data-backdrop="false">
                                <iframe class="iframe" src="https://www.youtube.com/embed/w0Z_X8EBUfk?enablejsapi=1&version=3&playerapiid=ytplayer" frameborder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> 
                                <div class="overlaySuccess" data-toggle="modal" data-target="#myModalself" data-backdrop="false">
                                    <a style="color: #FF0000;" class="iconSuccess">
                                <i  class='bx bxs-right-arrow'></i>
                            </a>
                            </div>
                            </div>
                            <div  class="modal" id="myModalself">
                                <div class="modal-dialog">
                                  <div class="modal-content" style="border-radius: 13px;">
                                    <div class="modal-body">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true"><b>&times;</b></span>
                                          </button>
                                          <iframe class="iframe" src="https://www.youtube.com/embed/YPvSPX8lwco?enablejsapi=1&version=3&playerapiid=ytplayer" frameborder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>

                                  </div>
                                </div>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <!-- <div class="main-banner item-bg3">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content text-center">
                        <span class="sub-title">Aim for Excellence</span>
                        <h1>Learn a new skill from online courses</h1>
                        <p>Ednuv supports students by introducing collaborators outside R, internships and research experience abroad.</p>
                        <div class="btn-box">
                            <a routerLink="/courses-2-columns-style-3" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                            <a routerLink="/contact" class="optional-btn">Get Started Free</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</section>