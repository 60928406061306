import { Component, OnInit } from '@angular/core';
//import * as $ from 'jquery';
declare var $:any;
// import 'bootstrap/dist/js/bootstrap.min.js';

@Component({
  selector: 'app-k12-feedback',
  templateUrl: './k12-feedback.component.html',
  styleUrls: ['./k12-feedback.component.scss']
})
export class K12FeedbackComponent implements OnInit {

  imageObject = [{
    video: 'https://www.youtube.com/embed/W49HGEAWLYQ?enablejsapi=1&version=3&playerapiid=ytplayer',
    title: 'Youtube example one with title.',
    alt: 'youtube video'
}, {
    video: 'https://www.youtube.com/embed/kxMF-lpJbzg?enablejsapi=1&version=3&playerapiid=ytplayer',
    alt: 'youtube video'
}];
  constructor() { }

  ngOnInit(): void {

    
    $('.carousel-control-prev').click(function(e) {
      
      var stopAllYouTubeVideos = () => { 
        var iframes = document.querySelectorAll('iframe');
        Array.prototype.forEach.call(iframes, iframe => { 
          iframe.contentWindow.postMessage(JSON.stringify({ event: 'command', 
        func: 'stopVideo' }), '*');
       });
      }
      stopAllYouTubeVideos();

      // $(".iframe-carousel").each(function() { 
        
      //    //var src= $(this).attr('src');
      //    //$(this).attr('src',src);  
      //    $(this).contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
      //  });

    });
    
    $('.carousel-control-next').click(function(e) {
            
      // $(".iframe-carousel").each(function() { 
      //   var src= $(this).attr('src');       
      //   $(this).attr('src',src);  
      // });
      var stopAllYouTubeVideos = () => { 
        var iframes = document.querySelectorAll('iframe');
        Array.prototype.forEach.call(iframes, iframe => { 
          iframe.contentWindow.postMessage(JSON.stringify({ event: 'command', 
        func: 'stopVideo' }), '*');
       });
      }
      stopAllYouTubeVideos();

      // $(".iframe-carousel").each(function() { 
        
      //   //var src= $(this).attr('src');
      //   //$(this).attr('src',src);  
      //   $(this).contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
      // });

    });

  }

}
