import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'totalAmount'
})
export class TotalAmountPipe implements PipeTransform {

  transform(teacherPayoutDetails: any[], myAccountSearch: string): any[] {
    if (!teacherPayoutDetails) {
      return [];
    }
// if (!myAccountSearch) {
    //   return teacherPayoutDetails;
    // }
  	if(myAccountSearch==undefined){
  		myAccountSearch = ''
  	}
    // myAccountSearch = myAccountSearch.toLocaleLowerCase();
    // console.log('account serarch',myAccountSearch);

    var temp =  teacherPayoutDetails.filter(it => {
      console.log(it,'checking')
      return it.paymentDetails.productName.toLocaleLowerCase().includes(myAccountSearch);
    });

     var totalAmount = temp.reduce(function(tot, arr) { 
        return tot + arr.paymentDetails.amount;
      },0);
     return totalAmount;
}
}