import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-buddi-test',
  templateUrl: './buddi-test.component.html',
  styleUrls: ['./buddi-test.component.scss']
})

export class BuddiTestComponent implements OnInit {
  public TestID: any;
  public TestData: any = [];
  public PreviewTest: any = [];
  public isreview: boolean = false;
  public isshow: boolean = false;
  public isstudent = false;
  public isteacher = false;
  public isLoggedin = false;
  public testname = "";

  constructor(private my_router: ActivatedRoute, public toastr: ToastrManager, private rest: ApiService, private router: Router) { }

  ngOnInit(): void {
    this.TestID = this.my_router.snapshot.params['id'];
    var loggedin = localStorage.getItem('isLoggedin');
    var usertype = localStorage.getItem('profileType');
    if (loggedin == "true") {
      this.isLoggedin = true;
    }
    else if (loggedin == "false") {
      this.isLoggedin = false;
    }
    if (usertype != null) {
      if (usertype.toLowerCase() == "teacher") {
        this.isteacher = true;
      }
      else {
        this.isstudent = true;
      }
    }
    else {
      this.isstudent = true;
    }
    var formData = {};
    formData["testID"] = this.TestID
    formData["studentID"] = localStorage.getItem('StudentID');
    this.rest.getTestsdetails(formData).subscribe((result) => {
      
      this.TestData = result.data;
      this.testname = result.data.testName;
      this.PreviewTest = result.previousAttempts.filter(item => item.testIsActive === false && item.marksGained !== undefined );
      if (this.PreviewTest.length > 0) {
        this.isreview = true;
      }
      else {
        this.isreview = false;
      }

    }, (err) => {
      console.log(err);
    });

  }

  starttest() {

    this.router.navigateByUrl('buddi-test/' + this.TestID);
  }
  showmarks() {
    this.isshow = true;
  }

  testdetails(id) {
    this.router.navigate(['/student-test-details/' + id]);
  }
  login(){
    var data={
     isredirect:true
   }
   this.router.navigate(['/student/login'],{state:data});
 }
}
