<div *ngIf="isstudent">
    <app-header-style-one></app-header-style-one>
</div>
<div *ngIf="isteacher">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>

<section class="instrctor-area pt-100 pb-70">
    <div class="col-lg-12 col-md-12" style="margin-bottom: 5%;">
        <div class="about-content">
            <h3 style="color:#00adee; text-align: center;">Management Team</h3>
            <!-- <span class="sub-title" style="color: var(--mainColor);">Management Team</span> -->
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <a href="" routerLink="/buddi-management-team/Uday"> <img src="../../../../assets/img/uday.jpg" alt="uday"> </a>
                    </div>

                    <div class="member-content">
                        <h3><a href="" routerLink="/buddi-management-team/Uday">Uday Shankar</a></h3>
                        <span>Technology & Strategy 
                            </span>
                        <!-- <ul class="social">
                            <li><a class="facebook"><i class='bx bxl-facebook'></i></a></li>
                            <li><a class="twitter"><i class='bx bxl-twitter'></i></a></li>
                            <li><a class="instagram"><i class='bx bxl-instagram'></i></a></li>
                            <li><a class="linkedin"><i class='bx bxl-linkedin'></i></a></li>
                        </ul> -->
                    </div>
                </div>
            </div>


                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-instructor-member mb-30">
                            <div class="member-image">
                                <a href="" routerLink="/buddi-management-team/Mukundan"> <img src="../../../../assets/img/mukundan.jpg" alt="mukundan"></a>
                            </div>
        
                            <div class="member-content">
                                <h3><a href="" routerLink="/buddi-management-team/Mukundan">Mukundan</a></h3>
                                <span> Marketing & Operations
                                    </span>
                                <!-- <ul class="social">
                                    <li><a href="#" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                    <li><a href="#" class="twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                    <li><a href="#" class="instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                    <li><a href="#" class="linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                </ul> -->
                                <!-- <ul class="social">
                                    <li><a class="facebook"><i class='bx bxl-facebook'></i></a></li>
                                    <li><a class="twitter"><i class='bx bxl-twitter'></i></a></li>
                                    <li><a class="instagram"><i class='bx bxl-instagram'></i></a></li>
                                    <li><a class="linkedin"><i class='bx bxl-linkedin'></i></a></li>
                                </ul> -->
        
                            </div>
                        </div>
                    </div>





           
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <a href="" routerLink="/buddi-management-team/Mugundan"><img src="../../../../assets/img/mugundan.jpg" alt="mugundan"></a>
                    </div>

                    <div class="member-content">
                        <h3><a href="" routerLink="/buddi-management-team/Mugundan">Mugundan Chinnasamy</a></h3>
                        <span> Head - Product Development
                            </span>
                        <!-- <ul class="social">
                            <li><a class="facebook"><i class='bx bxl-facebook'></i></a></li>
                            <li><a class="twitter"><i class='bx bxl-twitter'></i></a></li>
                            <li><a class="instagram"><i class='bx bxl-instagram'></i></a></li>
                            <li><a class="linkedin"><i class='bx bxl-linkedin'></i></a></li>
                        </ul> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">

                
                    <div class="single-instructor-member mb-30">
                        <div class="member-image">
                            <a href="" routerLink="/buddi-management-team/Sundarrajan"><img src="../../../../assets/img/sundarrajan.jpg" alt="sundarrajan"></a>
                        </div>
    
                        <div class="member-content">
                            <h3><a href="" routerLink="/buddi-management-team/Sundarrajan">Sundar Rajan</a></h3>
                            <span> Head - Stakeholder Relations

                                </span>
                            <!-- <ul class="social">
                                <li><a class="facebook"><i class='bx bxl-facebook'></i></a></li>
                                <li><a class="twitter"><i class='bx bxl-twitter'></i></a></li>
                                <li><a class="instagram"><i class='bx bxl-instagram'></i></a></li>
                                <li><a class="linkedin"><i class='bx bxl-linkedin'></i></a></li>
                            </ul> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-instructor-member mb-30">
                            <div class="member-image">
                                <a href="" routerLink="/buddi-management-team/Kabilan"><img src="../../../../assets/img/kabilan.jpg" alt="kabilan"></a>
                            </div>
        
                            <div class="member-content">
                                <h3><a href="" routerLink="/buddi-management-team/Kabilan">Kabilan Mani</a></h3>
                                <span> Head - Marketing
                                    </span>
                                <!-- <ul class="social">
                                    <li><a class="facebook"><i class='bx bxl-facebook'></i></a></li>
                                    <li><a class="twitter"><i class='bx bxl-twitter'></i></a></li>
                                    <li><a class="instagram"><i class='bx bxl-instagram'></i></a></li>
                                    <li><a class="linkedin"><i class='bx bxl-linkedin'></i></a></li>
                                </ul> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-instructor-member mb-30">
                                <div class="member-image">
                                    <a href="" routerLink="/buddi-management-team/anita"><img src="../../../../assets/img/jothiudayashankar_English.jpeg" alt="jothiudayashankar_English"></a>
                                </div>
            
                                <div class="member-content">
                                    <h3><a href="" routerLink="/buddi-management-team/anita">Jothi Priya</a></h3>
                                    <span> Head - Content Management
                                        </span>
                                    <!-- <ul class="social">
                                        <li><a class="facebook"><i class='bx bxl-facebook'></i></a></li>
                                        <li><a class="twitter"><i class='bx bxl-twitter'></i></a></li>
                                        <li><a class="instagram"><i class='bx bxl-instagram'></i></a></li>
                                        <li><a class="linkedin"><i class='bx bxl-linkedin'></i></a></li>
                                    </ul> -->
                                </div>
                            </div>
                        </div>
                    <!-- <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-instructor-member mb-30">
                                <div class="member-image">
                                    <a href="" routerLink="/buddi-management-team/soniya"><img src="../../../../assets/img/soniya.jpg" alt="images"></a>
                                </div>
            
                                <div class="member-content">
                                    <h3><a href="" routerLink="/buddi-management-team/soniya">Soniya</a></h3>
                                    <span> Head - Content Development
                                        </span>
                                    <ul class="social">
                                        <li><a class="facebook"><i class='bx bxl-facebook'></i></a></li>
                                        <li><a class="twitter"><i class='bx bxl-twitter'></i></a></li>
                                        <li><a class="instagram"><i class='bx bxl-instagram'></i></a></li>
                                        <li><a class="linkedin"><i class='bx bxl-linkedin'></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div> -->

                        <!-- <div class="col-lg-4 col-md-6 col-sm-6">
                                <div class="single-instructor-member mb-30">
                                    <div class="member-image">
                                        <a href="" routerLink="/buddi-management-team/Sathis"><img src="../../../../assets/img/sathis.jpg" alt="images"></a>
                                    </div>
                
                                    <div class="member-content">
                                        <h3><a href="" routerLink="/buddi-management-team/Sathis">Sathis</a></h3>
                                        <span> Product Improvement Specialist

                                            </span>
                                        <ul class="social">
                                            <li><a class="facebook"><i class='bx bxl-facebook'></i></a></li>
                                            <li><a class="twitter"><i class='bx bxl-twitter'></i></a></li>
                                            <li><a class="instagram"><i class='bx bxl-instagram'></i></a></li>
                                            <li><a class="linkedin"><i class='bx bxl-linkedin'></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div> -->
                             
                                <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-instructor-member mb-30">
                                            <div class="member-image">
                                                <a href="" routerLink="/buddi-management-team/mohan"><img src="../../../../assets/img/mohan.jpg" alt="mohan"></a>
                                            </div>
                        
                                            <div class="member-content">
                                                <h3><a href="" routerLink="/buddi-management-team/mohan">Mohana Sundaram
                                                </a></h3>
                                                <span> Chief Architect - Blockchain
                                                    </span>
                                                <!-- <ul class="social">
                                                    <li><a class="facebook"><i class='bx bxl-facebook'></i></a></li>
                                                    <li><a class="twitter"><i class='bx bxl-twitter'></i></a></li>
                                                    <li><a class="instagram"><i class='bx bxl-instagram'></i></a></li>
                                                    <li><a class="linkedin"><i class='bx bxl-linkedin'></i></a></li>
                                                </ul> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                            <div class="single-instructor-member mb-30">
                                                <div class="member-image">
                                                    <a href="" routerLink="/buddi-management-team/thirupathi"><img src="../../../../assets/img/thirupathi.jpg" alt="thirupathi"></a>
                                                </div>
                            
                                                <div class="member-content">
                                                    <h3><a href="" routerLink="/buddi-management-team/thirupathi">Thirupathi A
                                                    </a></h3>
                                                    <span> Manager - Full Stack Development

                                                        </span>
                                                    <!-- <ul class="social">
                                                        <li><a class="facebook"><i class='bx bxl-facebook'></i></a></li>
                                                        <li><a class="twitter"><i class='bx bxl-twitter'></i></a></li>
                                                        <li><a class="instagram"><i class='bx bxl-instagram'></i></a></li>
                                                        <li><a class="linkedin"><i class='bx bxl-linkedin'></i></a></li>
                                                    </ul> -->
                                                </div>
                                            </div>
                                        </div>
        </div>
    </div>

    <div id="particles-js-circle-bubble-3"></div>
</section>

<app-become-instructor-partner></app-become-instructor-partner>