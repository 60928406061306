import { Component, OnInit,Inject, ElementRef, ViewChild, NgZone, HostListener} from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Options } from 'ng5-slider';
import { ApiService } from '../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {NgSelectModule, NgOption} from '@ng-select/ng-select';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from '../../../../environments/environment';
import { MediaService } from '../../../shared/media.service';

@Component({
  selector: 'app-search-by',
  templateUrl: './search-by.component.html',
  styleUrls: ['./search-by.component.scss']
})
export class SearchByComponent implements OnInit {

  public courseData:any=[];
  public testevaluationData:any=[];
  public testbenchmarkingData:any=[];
  public testpreviousyearData:any=[];
  public testprogressiveData:any=[];
  public examData:any=[];
  public coursecategorylist : any = [];
  public courseboardlist : any = [];
  public coursesubjectlist : any = [];
  public languageapi : any = [];
  public gradeapi : any = [];
  public allMeeting : any = [];
  public allMeetingPublic : any = [];
  selectedValue = "";   
  public section = "Select section";
  exploreForm: FormGroup;
  exploreFormPublic: FormGroup;
  exploretestform: FormGroup;
  public userprofdata : any =[];
  public isLogin : any = 'false';
  public isStudent:any = '';
  public isTeacher:Boolean = false;
  public groupNameTeacher:any='';
  public groupName:any='';
  public isCourse:boolean=true;
  public inviteUrl = "";
  public frontwebsite = environment.frontUrl;  
  public buddiGroupData:any = [];
  public isPartnerUser:any = [];
  public filterString:any = '';
  public videoYoutubePublic:any = [];
   //For Pagination
  p: number = 1;
  count: number = 40;
  subject : any;
  public istests:boolean=false;  
  public isprogressive:boolean=false;  
  public isbenchmark:boolean=false;  
  public ispreviousyear:boolean=false;  
  public isevaluation:boolean=false;  
  // modulename ="Study Material";
  // videomodulename = "Video classes";
  private pageIdRedirect:any = '';
  searchText: string = '';
  public itemNameFromRoute:any = '';
  keyword = 'flimname';
  public data:any = [];
  public maintainLocalData:any = [];
  public studentIsSchool:Boolean = false;
  public paginationData:any = [];
  public dataList:any = [];
  public config: any;
  public selectItem :any = true;
  public filteredFormData:any = [];


   p1: number = 1;
   count1: number = 40;

  p2: number = 1;
  count2: number = 40;

  p3: number = 1;
  count3: number = 40;

  p4: number = 1;
  count4: number = 40;

  p5: number = 1;
  count5: number = 40;

  p6: number = 1;
  count6: number = 40;

  p7: number = 1;
  count7: number = 40;
    p8: number = 1;
  count8: number = 40;

  test1:number = 1;
  testcount1:number = 4;
  test2:number = 1;
  testcount2:number = 4;
  test3:number = 1;
  testcount3:number = 4;
  test4:number = 1;
  testcount4:number = 4;
  @HostListener('document:click', ['$event'])
  DocumentClick(event) {
    if(event.target.classList.contains('searchByDrop')){
      this.selectItem = false
    }else{
      this.selectItem = true
    }
  }
  constructor(private elem: ElementRef,private api: MediaService,private SpinnerService:NgxSpinnerService,private activatedRoute:ActivatedRoute,public location: Location, public toastr: ToastrManager, private formBuilder: FormBuilder, private rest: ApiService, private router: Router ) { }

  ngOnInit(): void {
   
    $(document).ready(function() {
      $(window).scroll(function() {
        if ($(document).scrollTop() > 5) {
            $(".searchFixed").addClass("is-sticky");
            $(".searchFixed").removeClass("topFit");
            $(".classSpace").removeClass("add-space");

        } else {
            $(".searchFixed").removeClass("is-sticky");
            $(".searchFixed").addClass("topFit");
            $(".classSpace").addClass("add-space");
        }
      });
  });
    this.filteredFormData = window.history.state;
    var urlArray = window.location.href.split('/');
    var pageId=  this.activatedRoute.snapshot.params.type.toLowerCase();
    this.itemNameFromRoute = this.activatedRoute.snapshot.params.id.toLowerCase();
    this.pageIdRedirect = pageId
    var subject = this.activatedRoute.snapshot.params['subjectname'];    
    this.isLogin = localStorage.getItem('isLoggedin');  
    this.studentIsSchool = (localStorage.getItem('groupName') !='buddi' && this.isLogin=='true')? true : false;
    this.getcoursecategory();
    this.getboardfield();
    this.getsubjectfield();
    this.getlanguage();
    this.getgrade();  
      this.exploreForm = this.formBuilder.group({    
        gradeID: [this.filteredFormData.gradeID !='' && this.filteredFormData.gradeID !='all' && this.filteredFormData.gradeID !=undefined ? this.filteredFormData.gradeID : 'all'],
        subjectID: [this.filteredFormData.subjectID !='' && this.filteredFormData.subjectID !='all' && this.filteredFormData.subjectID !=undefined ? this.filteredFormData.subjectID : 'all'],
        languageID:[this.filteredFormData.languageID !='' && this.filteredFormData.languageID !='all' && this.filteredFormData.languageID !=undefined ? this.filteredFormData.languageID : 'all'],       
        categoryID: [this.filteredFormData.categoryID !='' && this.filteredFormData.categoryID !='all' && this.filteredFormData.categoryID !=undefined ? this.filteredFormData.categoryID : pageId],
        boardID: [this.filteredFormData.boardID !='' && this.filteredFormData.boardID !='all' && this.filteredFormData.boardID !=undefined ? this.filteredFormData.boardID : 'all'],
        meetingType:this.filteredFormData.meetingType ? true : false,
        section:['all'],
        searchText:'',
        currentPage:1,
        searchby:this.filteredFormData.term == undefined ? '' : this.filteredFormData.term,
      });
    // }
  this.isPartnerUser = localStorage.getItem('isPartner') =='true'?true:false; 
  if(this.itemNameFromRoute =='audio' || this.itemNameFromRoute =='video' ||this.itemNameFromRoute =='ebook'||this.itemNameFromRoute =='videoyoutube'){
    this.filterLibrary(this.exploreForm.value);
    this.istests = false;
  }else if(this.itemNameFromRoute =='class'){      
    this.filterData(this.exploreForm.value);
    this.istests = false;
  }else if(this.itemNameFromRoute =='buddi'){      
    this.filterbuddiGroup(this.exploreForm.value);
    this.istests = false;
  }else if(this.itemNameFromRoute =='course'){      
    this.coursefilterData(this.exploreForm.value);
    this.istests = false;    
  }else if(this.itemNameFromRoute =='tests'){
    this.istests = true;
    this.gettestsdata("tests");
   }else if(this.itemNameFromRoute =='solution'){
    this.filterSolution([]);
   }else if(this.itemNameFromRoute =='question'){
    this.filterQuestion([]);
   }
   if(this.itemNameFromRoute =='evaluation-test'){
    this.istests = true;
    this.gettestsdata("evaluation-test");
   }

   if(this.itemNameFromRoute =='progressive-test'){
    this.istests = true;
    this.gettestsdata("progressive-test");
   }

   if(this.itemNameFromRoute =='benchmarking-test'){
    this.istests = true;
    this.gettestsdata("benchmarking-test");
   }

   if(this.itemNameFromRoute =='previousyear-test'){
    this.istests = true;
    this.gettestsdata("previousyear-test");
   }
   
   this.isTeacher = localStorage.getItem('profileType').toLowerCase() =='teacher';
   this.isStudent = localStorage.getItem('teacherID') =='' ||  localStorage.getItem('teacherID') == undefined? true : false
   this.isLogin = localStorage.getItem('isLoggedin');  
   if(this.isStudent){
    this.getStudentdetails()
   }else{
     this.getTeacherdetails()
   }
  }
  get f() { return this.exploreForm.controls; }

  gettestsdata(category) {
    const subject = [];
    var formData = {};
    if(this.exploreForm.value.subjectID!= "all") {    
      subject.push(this.exploreForm.value.subjectID);
      formData["subjectID"]=subject;
    }
    else {
      formData["subjectID"]="all";
    }
    formData["language"]=this.exploreForm.value.languageID;
    formData["category"]=this.exploreForm.value.categoryID;
    // formData["category"]="JEE";
    formData["board"]=this.exploreForm.value.boardID;

    this.rest.getTestsByCategory(formData).subscribe((result) => {  
      this.testevaluationData = result.data.Evaluation;
      this.testbenchmarkingData = result.data.Benchmarking;
      this.testprogressiveData = result.data.Progressive;
      this.testpreviousyearData = result.data.PreviousYear;
      if(category == "progressive-test") {
        this.isprogressive = true;
        this.isbenchmark = false;
        this.isevaluation = false;
        this.ispreviousyear = false;
      }
      if(category == "evaluation-test") {
        this.isprogressive = false;
        this.isbenchmark = false;
        this.isevaluation = true;
        this.ispreviousyear = false;
      }
      if(category == "benchmarking-test") {
        this.isprogressive = false;
        this.isbenchmark = true;
        this.isevaluation = false;
        this.ispreviousyear = false;
      }
      if(category == "previousyear-test") {
        this.isprogressive = false;
        this.isbenchmark = false;
        this.isevaluation = false;
        this.ispreviousyear = true;
      }
      if(category == "tests") {
        this.isprogressive = true;
        this.isbenchmark = true;
        this.isevaluation = true;
        this.ispreviousyear = true;
      }

       
     
 
      this.dataList = [...this.testevaluationData,...this.testbenchmarkingData,...this.testprogressiveData,...this.testpreviousyearData]
      this.keyword = 'testName';  
  }, (err) => {
    console.log(err);
    });
  }
  filterSolution(data){
    var reqForm = this.exploreForm.value;
    var req = {
      gradeID:reqForm.gradeID == 'all' ? '' : reqForm.gradeID,
      subjectID:reqForm.subjectID == 'all' ? '' : reqForm.subjectID,
      languageID:reqForm.languageID == 'all' ? '' : reqForm.languageID,
      categoryID:reqForm.categoryID == 'all' ? '' : reqForm.categoryID,
      boardID:reqForm.boardID == 'all' ? '' : reqForm.boardID,
      securitytype:reqForm.meetingType?'Group':'Public',
      groupname:reqForm.meetingType == 'Group' ? this.groupName : '',
      currentPage:data.currentPage ? data.currentPage :1,
      list : this.itemNameFromRoute,
      itemsPerPage : environment.searchBy_itemsPerPage,
      searchby :reqForm.searchby
    }
    this.SpinnerService.show();
    this.rest.filterSolutionSearchBy(req).subscribe((result) => {
      this.SpinnerService.hide();
      if(result.status){        
        this.dataList = result.data ==undefined ? [] : result.data;
        this.paginationData.dataCount = result.dataCount;
        this.paginationData.currentPage = result.currentPage;
        this.data = this.dataList;
        this.config = {
          itemsPerPage: environment.searchBy_itemsPerPage,
          currentPage: result.currentPage,
          totalItems:  this.paginationData.dataCount,
          id:req.list
        };
      }else{
        this.dataList = [];
      }
      
    }, (err) => {
    console.log(err);
    });
  }
  filterQuestion(data){
    var reqForm = this.exploreForm.value;
    var req = {
      gradeID:reqForm.gradeID == 'all' ? '' : reqForm.gradeID,
      subjectID:reqForm.subjectID == 'all' ? '' : reqForm.subjectID,
      languageID:reqForm.languageID == 'all' ? '' : reqForm.languageID,
      categoryID:reqForm.categoryID == 'all' ? '' : reqForm.categoryID,
      boardID:reqForm.boardID == 'all' ? '' : reqForm.boardID,
      securitytype:reqForm.meetingType?'Group':'Public',
      groupname:reqForm.meetingType == 'Group' ? this.groupName : '',
      currentPage:data.currentPage ? data.currentPage :1,
      list : this.itemNameFromRoute,
      itemsPerPage : environment.searchBy_itemsPerPage,
      searchby :reqForm.searchby
    }
    this.SpinnerService.show();
    this.rest.filterQuestionSearchBy(req).subscribe((result) => {
      this.SpinnerService.hide();
      if(result.status){        
        this.dataList = result.data ==undefined ? [] : result.data;
        this.paginationData.dataCount = result.dataCount;
        this.paginationData.currentPage = result.currentPage;
        this.data = this.dataList;
        this.config = {
          itemsPerPage: environment.searchBy_itemsPerPage,
          currentPage: result.currentPage,
          totalItems:  this.paginationData.dataCount,
          id:'seachbyquestion'
        };
      }else{
        this.dataList = [];
      }
      
    }, (err) => {
    console.log(err);
    });
  }
  filterLibrary(reqForm){
    var req = {
      gradeID:reqForm.gradeID == 'all' ? '' : reqForm.gradeID,
      subjectID:reqForm.subjectID == 'all' ? '' : reqForm.subjectID,
      languageID:reqForm.languageID == 'all' ? '' : reqForm.languageID,
      categoryID:reqForm.categoryID == 'all' ? '' : reqForm.categoryID,
      boardID:reqForm.boardID == 'all' ? '' : reqForm.boardID,
      securitytype:reqForm.meetingType?'Group':'Public',
      groupname:reqForm.meetingType == 'Group' ? this.groupName : '',
      currentPage:reqForm.currentPage,
      list : this.itemNameFromRoute,
      itemsPerPage : environment.searchBy_itemsPerPage,
      searchby :reqForm.searchby
    }
    this.filterLibraryAPI(req)
  }
  filterLibraryAPI(req){
    this.SpinnerService.show()
    console.log(req);
    this.rest.filterLibrary(req).subscribe((result) => {
      this.SpinnerService.hide()
      if(result.status){        
        this.dataList = result.data ==undefined ? [] : result.data;
        this.paginationData.dataCount = result.dataCount;
        this.paginationData.currentPage = result.currentPage;
        this.data = this.dataList;
        this.config = {
          itemsPerPage: environment.searchBy_itemsPerPage,
          currentPage: result.currentPage,
          totalItems:  this.paginationData.dataCount,
          id:'seachbylibrary'
        };
        this.maintainLocalData = this.data;
      }else{
        this.dataList = [];
      }
      
    }, (err) => {
    console.log(err);
    });
  }
  getTeacherdetails(){
    const webToken = localStorage.getItem('webtoken');
    this.rest.userName_DisplayTeacher(webToken).subscribe((result) => {
      console.log(result.data);
      this.groupName = result.data.groupName
      this.userprofdata = result.data;
    }, (err) => {
    console.log(err);
    });
  }
  getStudentdetails(){
    const webToken = localStorage.getItem('webtoken');
    this.rest.userName_DisplayStudent(webToken).subscribe((result) => {
      if(result.status){
      this.groupName = result.data.groupName
      this.userprofdata = result.data;
      }
      
    }, (err) => {
    console.log(err);
    });
  }

  filterData(reqForm){
    var req = {
      gradeID:reqForm.gradeID == 'all' ? '' : reqForm.gradeID,
      subjectID:reqForm.subjectID == 'all' ? '' : reqForm.subjectID,
      languageID:reqForm.languageID == 'all' ? '' : reqForm.languageID,
     // categoryID:reqForm.categoryID == 'all' ? '' : '',
      categoryID:reqForm.categoryID == 'all' ? '' : reqForm.categoryID,
      boardID:reqForm.boardID == 'all' ? '' : reqForm.boardID,
      meetingType:reqForm.meetingType?'Group':'Public',
      groupname:reqForm.meetingType == 'Group' ? this.groupName : '',
      currentPage:reqForm.currentPage,
      list : this.itemNameFromRoute,
      itemsPerPage : environment.searchBy_itemsPerPage,
      searchby :reqForm.searchby
    }

    this.getDataAPI(req);
  }
  filterbuddiGroup(reqForm){
  var req = {
      gradeID:reqForm.gradeID == 'all' ? '' : reqForm.gradeID,
      subjectID:reqForm.subjectID == 'all' ? '' : reqForm.subjectID,
      languageID:reqForm.languageID == 'all' ? '' : reqForm.languageID,
      categoryID:reqForm.categoryID == 'all' ? '' : reqForm.categoryID,
      boardID:reqForm.boardID == 'all' ? '' : reqForm.boardID,
      isadmin:"yes",
      meetingType:"",
      currentPage:reqForm.currentPage,
      list : this.itemNameFromRoute,
      itemsPerPage : environment.searchBy_itemsPerPage,
      searchby :reqForm.searchby
    }
    this.rest.filterBuddiGroup(req).subscribe((result) => {
      if(result.status){        
        this.dataList = result.data ==undefined ? [] : result.data
        this.paginationData.dataCount = result.dataCount;
        this.paginationData.currentPage = result.currentPage;
        this.data = this.dataList;
        this.keyword='name';
        this.config = {
          itemsPerPage: environment.searchBy_itemsPerPage,
          currentPage: result.currentPage,
          totalItems:  this.paginationData.dataCount,          
          id:'seachbybuddi'
        };
      }else{
        this.dataList = [];
      }      
    });
  }

  coursefilterData(reqForm){
    const userType = localStorage.getItem('userType');
    var req = {
      gradeID:reqForm.gradeID == 'all' ? '' : reqForm.gradeID,
      subjectID:reqForm.subjectID == 'all' ? '' : reqForm.subjectID,
      languageID:reqForm.languageID == 'all' ? '' : reqForm.languageID,
      categoryID:reqForm.categoryID == 'all' ? '' : reqForm.categoryID,
      boardID:reqForm.boardID == 'all' ? '' : reqForm.boardID,
      currentPage:reqForm.currentPage,
      list : this.itemNameFromRoute,
      itemsPerPage : environment.searchBy_itemsPerPage,
      searchby :reqForm.searchby
    }
    const Typeofuser = localStorage.getItem('userType');
    const webToken = localStorage.getItem('webtoken');
    var formData = {};
    formData["gradeID"]=reqForm.gradeID;
    formData["subjectID"]=reqForm.subjectID;
    formData["languageID"]=reqForm.languageID;
    formData["categoryID"]=reqForm.categoryID;
    formData["boardID"]=reqForm.boardID;
    formData["userType"]=Typeofuser.toLowerCase();    
    formData["currentPage"]=this.exploreForm.value.currentPage;
    formData["itemsPerPage"]=environment.searchBy_itemsPerPage;
    formData["searchby"]=this.exploreForm.value.searchby;
    this.isLogin = localStorage.getItem('isLoggedin');   
    // Below is For Courses 
    if(this.isLogin=="true") {     
      this.rest.getCoursesByCategory(webToken,formData).subscribe((result) => {   
        if(!reqForm.meetingType) {
          this.courseData = result.data.Public;
          this.dataList = this.courseData;
        }
        else {
          this.courseData = result.data.Group;
          this.dataList = this.courseData;
        }   
         this.keyword = 'fullName';  
        this.data =  this.dataList;
        this.maintainLocalData = this.data;
      }, (err) => {
        console.log(err);
        });
    }
    else {
      this.rest.getCoursesByCategorypublic(formData).subscribe((result) => {  
        if(!reqForm.meetingType) {
          this.courseData = result.data.Public;
          this.dataList = this.courseData;
        }
        else {
          this.courseData = result.data.Group;
          this.dataList = this.courseData;
        }     
         this.keyword = 'fullName';
       this.data =  this.dataList;
        this.maintainLocalData = this.data;
      }, (err) => {
        console.log(err);
        });
    }
    

  }

  changeRole(type){
    var req = {
      gradeID:this.exploreForm.value.gradeID == 'all' ? '' : this.exploreForm.value.gradeID,
      subjectID:this.exploreForm.value.subjectID == 'all' ? '' : this.exploreForm.value.subjectID,
      languageID:this.exploreForm.value.languageID == 'all' ? '' : this.exploreForm.value.languageID,
      categoryID:this.exploreForm.value.categoryID == 'all' ? '' : this.exploreForm.value.categoryID,
      boardID:this.exploreForm.value.boardID == 'all' ? '' : this.exploreForm.value.boardID,
      meetingType: !this.exploreForm.value.meetingType?'Group':'Public',
      groupname:type == 'Group' ? this.groupName : '',
      currentPage:this.exploreForm.value.currentPage,
      list : this.itemNameFromRoute,
      itemsPerPage : environment.searchBy_itemsPerPage,
      searchby :this.exploreForm.value.searchby
    }
    this.exploreForm.patchValue({ meetingType: !this.exploreForm.value.meetingType});
    if(this.itemNameFromRoute =='class'){      
      this.getDataAPI(req);
    }else if(this.itemNameFromRoute =='audio' || this.itemNameFromRoute =='video' ||this.itemNameFromRoute =='ebook'||this.itemNameFromRoute =='videoyoutube'){
      this.filterLibrary(this.exploreForm.value);
    }else if(this.itemNameFromRoute =='solution'){
      this.filterSolution(this.exploreForm.value);
    }else if(this.itemNameFromRoute =='question'){
      this.filterQuestion(this.exploreForm.value);
    }
    const Typeofuser = localStorage.getItem('userType');
    const webToken = localStorage.getItem('webtoken');
    var formData = {};
    formData["gradeID"]=this.exploreForm.value.gradeID;
    formData["subjectID"]=this.exploreForm.value.subjectID;
    formData["languageID"]=this.exploreForm.value.languageID;
    formData["categoryID"]=this.exploreForm.value.categoryID;
    formData["boardID"]=this.exploreForm.value.boardID;
    formData["userType"]=Typeofuser.toLowerCase();

    formData["currentPage"]=this.exploreForm.value.currentPage;
    formData["itemsPerPage"]=environment.searchBy_itemsPerPage;
    formData["searchby"]=this.exploreForm.value.searchby;
    this.isLogin = localStorage.getItem('isLoggedin');
    if(this.itemNameFromRoute =='course'){    
      if(this.isLogin=="true") {    
      this.rest. getCoursesByCategory(webToken,formData).subscribe((result) => {  
        if(!this.exploreForm.value.meetingType) {
          this.courseData = result.data.Public;
           this.dataList = this.courseData;
        }
        else {
          this.courseData = result.data.Group;
           this.dataList = this.courseData;
        }
        this.keyword = 'fullName';
        this.data =  this.courseData;
        this.maintainLocalData = this.data;
      }, (err) => {
        console.log(err);
        });
    }
    else {
      this.rest. getCoursesByCategorypublic(formData).subscribe((result) => {  
        if(!this.exploreForm.value.meetingType) {
          this.courseData = result.data.Public;
           this.dataList = this.courseData;
        }
        else {
          this.courseData = result.data.Group;
           this.dataList = this.courseData;
        }
         this.keyword = 'fullName';
        this.data =  this.dataList;
          this.maintainLocalData = this.data;
       
      }, (err) => {
        console.log(err);
        });
      }
    }   
  }


  getDataAPI(req){
    this.rest.filterMeeting(req).subscribe((result) => {
      if(result.status){
        this.dataList = result.data ==undefined ? [] : result.data;
        this.paginationData.dataCount = result.dataCount;
        this.paginationData.currentPage = result.currentPage;
        this.data = this.dataList;
        this.keyword='name';
        this.config = {
          itemsPerPage: environment.searchBy_itemsPerPage,
          currentPage: result.currentPage,
          totalItems:  this.paginationData.dataCount
        };   
      }else{
        this.dataList = [];
      }
      
    }, (err) => {
    console.log(err);
    });

  }



  // changemoduleforcourse(type){
  //   const Typeofuser = localStorage.getItem('userType');
  //   const webToken = localStorage.getItem('webtoken');
  //   var formData = {};
  //   formData["gradeID"]=this.exploreForm.value.gradeID;
  //   formData["subjectID"]=this.exploreForm.value.subjectID;
  //   formData["languageID"]=this.exploreForm.value.languageID;
  //   formData["categoryID"]=this.exploreForm.value.categoryID;
  //   formData["boardID"]=this.exploreForm.value.boardID;
  //   formData["userType"]=Typeofuser.toLowerCase();
    
  //   this.isLogin = localStorage.getItem('isLoggedin');
  //   if(this.isLogin=="true") {    
  //     this.rest. getCoursesByCategory(webToken,formData).subscribe((result) => {  
  //       if(!type) {
  //         this.courseData = result.data.Public;
  //       }
  //       else {
  //         this.courseData = result.data.Group;
  //       }
  //       this.data =  this.courseData;
  //        this.maintainLocalData = this.data;
  //     }, (err) => {
  //       console.log(err);
  //       });
  //   }
  //   else {
  //     this.rest. getCoursesByCategorypublic(formData).subscribe((result) => {  
  //       if(!type) {
  //         this.courseData = result.data.Public;
  //       }
  //       else {
  //         this.courseData = result.data.Group;
  //       }
  //       this.data =  this.courseData;
  //        this.maintainLocalData = this.data;
  //     }, (err) => {
  //       console.log(err);
  //       });
  //   }
  // }
  getcoursecategory() {
    this.rest.getCourseCategoryHeader().subscribe((result) => {
      this.coursecategorylist = result.data;
      console.log('course category',this.coursecategorylist)
      var urlArray = window.location.href.split('/');
      this.coursecategorylist.forEach(function(val,key){
      })
    }, (err) => {
    console.log(err);
    });
  }

  getboardfield(){
    this.rest.boardfield().subscribe((result) => {
      this.courseboardlist = result.data;
      console.log('board',this.courseboardlist)
    }, (err) => {
    console.log(err);
    });
  }

  getsubjectfield(){
    this.rest.subjectfield().subscribe((result) => {
      this.coursesubjectlist = result.data;
      console.log('sub',this.coursesubjectlist)
    }, (err) => {
    console.log(err);
    });
  }

  getlanguage(){
    this.rest.languagefield().subscribe((result) => {
      this.languageapi = result.data;
    }, (err) => {
    console.log(err);
    });
  }
  getgrade(){
    this.rest.grade().subscribe((result) => {
      this.gradeapi = result.data;
      console.log('garde',this.gradeapi)
    }, (err) => {
    console.log(err);
    });
  }
  selectChange (event: any) {
    this.exploreForm.patchValue({ currentPage: 1});
    this.selectedValue = event.target.options[event.target.selectedIndex].text; 
    var reqForm =  this.exploreForm.value;
    if(reqForm.subjectID == 'all'){
      reqForm.subjectID = ''
    }
    this.courseData=[];
    this.allMeeting=[];
    if(this.itemNameFromRoute =='class'){
      this.filterData(reqForm);
    }else if(this.itemNameFromRoute =='audio' || this.itemNameFromRoute =='video' ||this.itemNameFromRoute =='ebook'||this.itemNameFromRoute =='videoyoutube'){
     this.filterLibrary(reqForm);
    }else if(this.itemNameFromRoute =='buddi'){
     this.filterbuddiGroup(reqForm);
    }else if(this.itemNameFromRoute =='solution'){
      this.filterSolution([])
    }else if(this.itemNameFromRoute =='question'){
      this.filterQuestion([])
    }else{      
      this.coursefilterData(reqForm);
       this.gettestsdata(this.itemNameFromRoute);
    }
  
   }
   detailsMeeting(data,pagename){
    data.pagename = pagename;
    var jsonStr= JSON.stringify(data)
    localStorage.setItem('livedetails',jsonStr);
    this.router.navigate(['/enroll/'+"/"+data.meetingID], { state:data });
  }
  buddiGroupRedirect(data){
     this.router.navigateByUrl('buddigroupaccept/'+data._id);
  }
  Examdetails(data) {
    console.log(data);
    this.router.navigateByUrl('exams/'+data.categoryID.categoryName+"/"+data._id);
  }

    ebookdetails(data){
      this.router.navigate(['/ebook-enroll/'+data._id]);
    }
    videodetails(data){
      this.router.navigate(['/video-enroll/'+data._id]);
    }
    audiodetails(data){
      this.router.navigate(['/audio-enroll/'+data._id]);
    }
    coursedetails(data) {
      this.router.navigateByUrl('courses/'+data.categoryID.categoryName+"/"+data._id);
    }
    gettestdetails(id) {      
      this.router.navigateByUrl('buddi-test-details/'+id);
    }
    redirectSearchBy(itemName){
      this.router.navigate(['searchby/'+this.pageIdRedirect+'/'+itemName]);
    }


  

  pageChanged(event){
    this.config.currentPage = event;
    this.exploreForm.patchValue({ currentPage: event});
    if(this.itemNameFromRoute =='class'){
      this.filterData(this.exploreForm.value);
    }else if(this.itemNameFromRoute =='audio' || this.itemNameFromRoute =='video' ||this.itemNameFromRoute =='ebook'||this.itemNameFromRoute =='videoyoutube'){
      this.filterLibrary(this.exploreForm.value);
    }else if(this.itemNameFromRoute =='buddi'){      
      this.filterbuddiGroup(this.exploreForm.value);
    }
  } 
  onFocused(e){
      this.selectItem = false;
  }
  backto(){
    this.location.back();
  }
  selectItemClick(item){
    var itemNameFilter = '';
    if(this.itemNameFromRoute =='class' || this.itemNameFromRoute =='buddi'){
      itemNameFilter = item.name;
    }else if(this.itemNameFromRoute =='solution' || this.itemNameFromRoute =='question' || this.itemNameFromRoute =='audio' || this.itemNameFromRoute =='video' ||this.itemNameFromRoute =='ebook'||this.itemNameFromRoute =='videoyoutube'){
      itemNameFilter = item.flimname;
    }else if(this.itemNameFromRoute =='course'){
      itemNameFilter = item.fullName;
    }
    else if(this.itemNameFromRoute =='course'){
      itemNameFilter = item.fullName;
    }
    else if(this.itemNameFromRoute =='tests'){
      itemNameFilter = item.testName;

       this.testevaluationData = this.testevaluationData.filter(function(val,key){return val._id == item._id});
      this.testbenchmarkingData = this.testbenchmarkingData.filter(function(val,key){return val._id == item._id});
      this.testprogressiveData = this.testprogressiveData.filter(function(val,key){return val._id == item._id});
      this.testpreviousyearData = this.testpreviousyearData.filter(function(val,key){return val._id == item._id});
    }
    
     this.exploreForm.patchValue({ searchby:itemNameFilter});
     this.dataList = this.dataList.filter(function(val,key){return val._id == item._id});
     this.selectItem = true;
     this.config = {
        itemsPerPage: environment.searchBy_itemsPerPage,
        currentPage: 1,
        totalItems:  this.dataList
      };
  }
  searchbyName(e){
    this.selectItem = false;
    this.exploreForm.patchValue({ currentPage: 1});
    if(this.itemNameFromRoute =='class'){
      this.filterData(this.exploreForm.value);
    }else if(this.itemNameFromRoute =='audio' || this.itemNameFromRoute =='video' ||this.itemNameFromRoute =='ebook'||this.itemNameFromRoute =='videoyoutube'){
      this.filterLibrary(this.exploreForm.value);
    }else if(this.itemNameFromRoute =='buddi'){      
      this.filterbuddiGroup(this.exploreForm.value);
    }else if(this.itemNameFromRoute =='solution'){
      this.filterSolution(this.exploreForm.value)
    }else if(this.itemNameFromRoute =='question'){
      this.filterQuestion(this.exploreForm.value)
    }
    else if(this.itemNameFromRoute =='tests'){    
      this.gettestsdata("tests");
    }
    else if(this.itemNameFromRoute =='evaluation-test'){
      this.gettestsdata("evaluation-test");
     }
  
     else if(this.itemNameFromRoute =='progressive-test'){
      this.gettestsdata("progressive-test");
     }
  
     else if(this.itemNameFromRoute =='benchmarking-test'){
      this.gettestsdata("benchmarking-test");
     }
  
     else if(this.itemNameFromRoute =='previousyear-test'){
      this.gettestsdata("previousyear-test");
     }
  }  
  receivePageNumber(data){
    if(data.list == 'solution'){
      this.filterSolution(data);
    }else if(data.list == 'question'){
      this.filterQuestion(data);
    }
    
  }
}