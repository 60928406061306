import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../../shared/api.service";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { DatePipe } from '@angular/common';
const datepipe: DatePipe = new DatePipe('en-US')
@Component({
  selector: 'app-addvideo-dashboard-list',
  templateUrl: './addvideo-dashboard-list.component.html',
  styleUrls: ['./addvideo-dashboard-list.component.scss']
})
export class AddvideoDashboardListComponent implements OnInit {

  constructor(private rest: ApiService, private router: Router) {}
  public classList: any = [];
  public videoList: any = [];
  public smartMaterialList: any = [];
  public testList: any = [];
  public mydashboardData: any = [];
  public isTeacher: Boolean = false;
  public isStudent: Boolean = false;
  public isLogin: any = 'false';
  public currentPageNumber : number = 1;
public totalVideoCount: number;
public totalPageCount: number;
public currentVideoCount: number;
term: string;
public webinarData: any = [];
isDesc: any = 1;
column: string = 'remainingClass';
p: number = 1;
count: number = 4;

  ngOnInit() {
    const token = localStorage.getItem("webtoken");
  
    this.isTeacher =
        localStorage.getItem("profileType").toLowerCase() == "teacher";
    this.isStudent =
        localStorage.getItem("profileType").toLowerCase() == "student";
 
    this.getPurchasedVideos();
  }
  getPurchasedVideos() {
    const webToken = localStorage.getItem("webtoken");
    if(this.isTeacher){
      var request = {};
      request['limit'] = environment.listing__itemsPerPage;
      request['page'] = this.currentPageNumber;
      this.rest.NewVideoListing(webToken,request).subscribe((response) => {
        console.log("videos", response)
        if(response.status){
          this.videoList = response.data.data;
          this.totalVideoCount = response.data.totalCount;
          this.totalPageCount = Math.ceil(this.totalVideoCount / environment.listing__itemsPerPage)
          this.currentVideoCount = ((this.currentPageNumber - 1) * environment.listing__itemsPerPage)
          for(var video of this.videoList){
            console.log(video.thumbnail != null && video.thumbnail == "")
            if(video.thumbnail != null && video.thumbnail == ""){
              console.log("1")
              video.thumbnail = "assets/img/courses/courses1.jpg"
            }
          }
          console.log(this.videoList)
        }
      })
    }
    else{
      var request = {};
      request['limit'] = environment.listing__itemsPerPage;
      request['page'] = this.currentPageNumber;
      this.rest.getPurchasedVideos(webToken).subscribe((response) => {
        console.log("videos", response)
        if(response.status){
          this.videoList = response.data;
          for(var video of this.videoList){
            console.log(video.thumbnail != null && video.thumbnail == "")
            if(video.thumbnail != null && video.thumbnail == ""){
              console.log("1")
              video.thumbnail = "assets/img/courses/courses1.jpg"
            }
          }
          console.log(this.videoList)
        }
      })
    }
  }

  getPurchasedSmartMaterial() {
    const webToken = localStorage.getItem("webtoken");
    if(this.isTeacher){
      this.rest.NewSmartMaterialListing(webToken, {}).subscribe((response) => {
        
        console.log("material", response)
        if(response.status){
          this.smartMaterialList = response.data.data;
          for(var material of this.smartMaterialList){
            if(material.thumbnail && material.thumbnail == ""){
              material.thumbnail = "slide/img/courses/courses1.jpg"
            }
          }
          console.log(this.smartMaterialList)
        }
      })
    }
    else{
      this.rest.getPurchasedSmartMaterial(webToken).subscribe((response) => {
        console.log("material", response)
        if(response.status){
          this.smartMaterialList = response.data;
          for(var material of this.smartMaterialList){
            if(material.thumbnail && material.thumbnail == ""){
              material.thumbnail = "slide/img/courses/courses1.jpg"
            }
          }
          console.log(this.smartMaterialList)
        }
      })
    }
    
  }
  
  getPurchasedTests() {
      const webToken = localStorage.getItem("webtoken");

      this.rest.getPurchasedTests(webToken).subscribe((response) => {
        console.log("test", response)
        if(response.status){
          this.testList = response.data;
          for(var test of this.testList){
            if(test.thumbnail && test.thumbnail == ""){
              test.thumbnail = "slide/img/courses/courses1.jpg"
            }
          }
          console.log(this.testList)
        }
      })
    }
    clickPageNumber(pageNumber) {
      this.currentPageNumber = pageNumber;
      console.log(this.currentPageNumber)
      // if (this.isLogin == 'true' && this.isTeacher) {
      //   this.getSmartMaterialList();
      // }
      // else if (this.isLogin == 'true' && this.isStudent) {
      //   this.getSmartMaterialWithoutLogin();
      // }
  
      // else {
        // this.getSmartMaterialWithoutLogin();
      //}
    }
    detailsVideo(data){
      this.router.navigate(['videoDetails/' + data._id])
    }
}
