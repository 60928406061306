<div class="container">
    <div class="card-deck">
        <div class="col-lg-4 col-md-6" routerLink="/sidenav/upcoming/live-class" style="cursor:pointer ;">
           <div class="card text-center">
          <img src="../../assets/img/upcoming-live-class.jpeg" alt="">
            <div class="card-body">
                  Live Classes
            </div>
           </div>
        </div>
        <div class="col-lg-4 col-md-6" routerLink="/sidenav/upcoming/study-hall" style="cursor:pointer ;">
            <div class="card text-center">
                <img src="../../assets/img/upcoming-study-hall.jpeg" alt="">
                <div class="card-body">
                   Study Hall
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6" routerLink="/sidenav/upcoming/round-table" style="cursor:pointer ;">   
            <div class="card text-center">
                <img src="../../assets/img/upcoming-round-table.jpeg" alt="">
                <div class="card-body">
                    Round Table
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6" routerLink="/sidenav/upcoming/video-class" style="cursor:pointer ;">
            <div class="card text-center">
                <img src="../../assets/img/upcoming-video-class.jpeg" alt="">
                <div class="card-body">
                    Video Lessons
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6" routerLink="/sidenav/upcoming/study-material" style="cursor:pointer ;">
            <div class="card text-center">
                <img src="../../assets/img/Study_material.jpeg" alt="">
                <div class="card-body">
                    Study Material
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6" routerLink="/sidenav/upcoming/evalution-test" style="cursor:pointer ;">
            <div class="card text-center">
                <img src="../../assets/img/upcoming-test.jpeg" alt="">
                <div class="card-body">
                    Evaluation Tests
                </div>
            </div>
        </div>
    </div>
</div>
