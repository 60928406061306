
<div *ngIf="isteacherRoute">
        <app-header-style-one-teacher></app-header-style-one-teacher>
    </div>
    <div *ngIf="!isteacherRoute">
        <app-header-style-one></app-header-style-one>
    </div>
    
    <section class="courses-details-area pt-50 pb-70" *ngIf="livedetails.length!=0">
        <div class="container">         
            <div class="courses-details-header">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="courses-title">
                            <h2 style="overflow-wrap: break-word;">{{livedetails.testName}} 
                            </h2>    
                         
                        </div>
                        <div class="courses-meta">
                            <ul>
                                <li>
                                    <i class='bx bx-folder-open'></i>
                                    <span>Category</span>
                                    <a>{{livedetails.categoryID ? livedetails.categoryID.categoryName : 'NA'}}</a>
                                </li>
                            </ul>
                        </div>
                        <div class="courses-meta">                        
                        </div>
                    </div>
    
                    <div class="col-lg-6">
                        <div class="courses-price">
                            <div class="courses-review">
                            </div>
                            <div class="price"></div>
                            <!-- <a class="default-btn mr-2" target="_blank" *ngIf="livedetails.providerByName=='You Tube' && isLoggedin =='true'" [href]="livedetails.name">                            
                                <i class='bx bx-paper-plane icon-arrow before'></i>
                                    <span class="label">Watch</span>
                                <i class="bx bx-paper-plane icon-arrow after"></i>
                            </a>   -->
                            <!-- <a  class="default-btn mr-1" (click)="backto()"><i class="fas fa-long-arrow-alt-left mr-1"></i>Back</a> -->
                            <!-- <button  (click)="backto()" style="width: 16%;height:45px;margin-left:83%;margin-top:1%;padding: 9px;"><i class="fas fa-long-arrow-alt-left mr-1"></i>Back</button> -->
                            <button class="default-btn ml-1" style="font-size: 12px;" (click)="backto()" > 
                                <a  target=”_blank”><i class="fas fa-long-arrow-alt-left mr-1"></i>Back</a>
                            </button>
                            <!-- <a class="default-btn " *ngIf="livedetails.status=='Draft' && (isLoggedin =='true')" (click)="publishvideo()">                            
                                <i class="fas fa-plus icon-arrow before"></i>
                                <span class="label">Publish</span>
                                <i class="fas fa-plus icon-arrow after"></i>
                            </a>                         -->
                            <a class="default-btn " *ngIf="isLoggedin=='false'" (click)="login()">    
                                <i class='bx bx-paper-plane icon-arrow before'></i>
                                <span class="label">Login</span>
                                <i class="bx bx-paper-plane icon-arrow after"></i>
                            </a>                      
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="row">
                <div class="col-lg-8">                
                  
                        <img [src]="livedetails.thumbnail !='' ? livedetails.thumbnail : 'assets/img/courses/courses1.jpg'" alt="image">
                 
                    <div class="row">
                        <div class="col-lg-8 pt-3">
                            {{viewCount}} Views
                        </div>
                         <div class="col-lg-2 pt-2" >                        
                            <!-- <i  *ngIf="isLoggedin=='true' && !isteacherRoute" (click)="likeReview(reviewForm,'add')"  class="fa fa-thumbs-up ml-1 mr-1" style="font-size:20px" [class]="(userlike.length ==0 && !isteacherRoute) ? 'text-primary':''"><span  style="font-size:16px" [class]="(userlike.length ==0 && !isteacherRoute)? 'text-primary':''"></span></i> -->
                            <!-- <i  *ngIf="isLoggedin=='false'" class="fa fa-thumbs-up ml-1 mr-1" style="font-size:20px" ><span  style="font-size:16px" ></span></i> -->
                            <i  *ngIf="isLoggedin=='true' && liked"   class="fa fa-thumbs-up icon-liked" style="font-size:20px" [class]="(userlike.length ==0 && !isteacherRoute) ? 'text-primary':''"><span  style="font-size:16px" [class]="(userlike.length ==0 && !isteacherRoute)? 'text-primary':''"></span></i>
                            <i  *ngIf="isLoggedin=='true' && !liked"  class="fa fa-thumbs-up" style="font-size:20px" [class]="(userlike.length ==0 && !isteacherRoute) ? 'text-primary':''"><span  style="font-size:16px" [class]="(userlike.length ==0 && !isteacherRoute)? 'text-primary':''"></span></i>
                            <span class="mt-2" style="font-size:20px" [class]="userlike.length !=0 && !isteacherRoute ? 'text-primary':''">
                                <span  style="font-size:16px" [class]="userlike.length !=0 && !isteacherRoute ? 'text-primary':''"> 
                                {{livedetails.upvotes}} 
                                </span>
                            </span>
                        </div>       
                        
                        <div class="col-lg-2 pt-2" >                        
                            <!-- <i  *ngIf="isLoggedin=='true' && !isteacherRoute" (click)="likeReview(reviewForm,'add')"  class="fa fa-thumbs-up ml-1 mr-1" style="font-size:20px" [class]="(userlike.length ==0 && !isteacherRoute) ? 'text-primary':''"><span  style="font-size:16px" [class]="(userlike.length ==0 && !isteacherRoute)? 'text-primary':''"></span></i> -->
                            <!-- <i  *ngIf="isLoggedin=='false'" class="fa fa-thumbs-up ml-1 mr-1" style="font-size:20px" ><span  style="font-size:16px" ></span></i> -->
                            <i  *ngIf="isLoggedin=='true' && unliked"  class="fa fa-thumbs-down icon-liked" style="font-size:20px" [class]="(userlike.length ==0 && !isteacherRoute) ? 'text-primary':''"><span  style="font-size:16px" [class]="(userlike.length ==0 && !isteacherRoute)? 'text-primary':''"></span></i>
                            <i  *ngIf="isLoggedin=='true' && !unliked"  class="fa fa-thumbs-down" style="font-size:20px" [class]="(userlike.length ==0 && !isteacherRoute) ? 'text-primary':''"><span  style="font-size:16px" [class]="(userlike.length ==0 && !isteacherRoute)? 'text-primary':''"></span></i>
                            <span class="mt-2"  style="font-size:20px" [class]="userlike.length !=0 && !isteacherRoute ? 'text-primary':''">
                                <span  style="font-size:16px" [class]="userlike.length !=0 && !isteacherRoute ? 'text-primary':''"> 
                                {{livedetails.downvotes}}
                                </span>
                            </span>
                        </div>             
                    </div> 
    
                    <hr>
                   
                         <div class="modal" id="myModal" role="dialog">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">                      
                                    <span class="bold">Likes {{reviewLike.length}} </span>
                                </div>
                                <span class="modal-body bold text-primary" >
                                    <commaFor [forData]="reviewLike"></commaFor>
                                    <!-- <span *ngFor="let item of reviewLike">{{item.name}},</span> -->
                                </span>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                      
                        </div>
                    </div>
    
    
                 
                    <div class="mt-5">
                        <label>
                            Description
                        </label>
                        <div class="col-lg-12 text-break">
                                {{livedetails.description =='' ? '-' : livedetails.description}}
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="courses-sidebar-information">
                        <ul>
                           <!--  <li>
                                <span><i class='bx bx-group'></i> Privacy Type:</span>
                                {{livedetails.securitytype =='Group' ? 'School':livedetails.securitytype}}
                            </li> -->
                            <!-- <li *ngIf="livedetails.securitytype =='Group'">
                                <span><i class='bx bx-time'></i> School Name:</span>
                                {{livedetails.groupname}}
                            </li> -->
                            <!-- <li>
                                <span><i class='bx bx-tachometer'></i>Grade:</span>
                                {{livedetails.topicID.gradeID.gradeName}}
                            </li> -->
                            <li>
                                <span><i class='bx bx-tachometer'></i>Board:</span>
                                {{livedetails.boardID.boardName}}
                            </li>
                            <!-- <li>
                                <span><i class='bx bx-tachometer'></i>Subject:</span>
                                {{livedetails.topicID.subjectID.subjectName}}
                            </li> -->
                            <li>
                                <span><i class='bx bx-tachometer'></i>Language:</span>
                                {{livedetails.languageID.languageName}}
                            </li>
                            <!-- <li style="overflow-wrap: break-word;">
                                <span><i class='bx bx-time'></i> Course Name:</span>
                                {{livedetails.courseID.courseName}}
                            </li>
                            <li style="overflow-wrap: break-word;">
                                <span><i class='bx bx-time'></i> Module Name:</span>
                                {{livedetails.moduleID.moduleName}}
                            </li> -->
                            <!-- <li style="overflow-wrap: break-word;">
                                <span><i class='bx bx-time'></i> Chapter Name:</span>
                                {{livedetails.topicID.chapterName}}
                            </li> -->
                            <li *ngIf="livedetails.classType !=''" style="overflow-wrap: break-word;">
                                    <span><i class='bx bx-time'></i> Topic Name:</span>
                                    {{livedetails.topicID.topicName}}
                                </li>
                            <li style="overflow-wrap: break-word;">
                                    <span><i class='bx bx-time'></i> Total Questions:</span>
                                    {{livedetails.numberOfQuestions}}
                                </li>
                                <li style="overflow-wrap: break-word;">
                                    <span><i class='bx bx-time'></i> Total Marks:</span>
                                    {{livedetails.totalMarks}}
                                </li>
                                <li style="overflow-wrap: break-word;">
                                        <span><i class='bx bx-time'></i> Total time:</span>
                                        {{livedetails.totalTime}} Mins
                                    </li>
                          
                            <li style="overflow-wrap: break-word;">
                                <span><i class='bx bx-time'></i> Level:</span>
                                {{livedetails.level}}
                            </li>
                            <li style="overflow-wrap: break-word;">
                                <span><i class='bx bx-time'></i> Price:</span>
                                {{livedetails.price}}
                            </li>
                           
                            <!-- <li>
                                <span><i class='bx bx-time'></i> Type(Optional):</span>
                                {{livedetails.type =='' ? '-' :livedetails.type }}
                            </li>
                           
                            
                          
                            <li class="hashtag">
                                <span><i class='bx bxs-badge-check'></i> Tag:</span>                            
                                <a  *ngFor="let tagObj of tagArray"> {{tagObj | removespace}}</a>                           
                            </li> -->
                            <!-- <li style="overflow-wrap: break-word;">
                                <span><i class='bx bxs-badge-check'></i> Author:</span>
                                {{livedetails.autor =='' ? '-' : livedetails.autor}}
                            </li> -->
                           <!--  <li>
                                <span><i class='bx bxs-badge-check'></i> Additional.info:</span>
                                {{livedetails.additional =='' ? '-' : livedetails.additional}}
                            </li> -->
                            <!-- <li *ngIf="livedetails.isPartner" style="overflow-wrap: break-word;">
                                <span><i class='bx bxs-badge-check'></i> Provider By:</span>
                                {{livedetails.partner ==null? livedetails.providerByName ==''? 'buddi Premium' : livedetails.providerByName : livedetails.partner.partnerName}}
                            </li> -->
                           
                          
                            
                           
                        </ul>
                    </div>
                    <div class="row">
                        <div  *ngIf="isteacherRoute">
                            <button class="default-btn ml-1" style="font-size: 12px;" (click)="editvideo()"  *ngIf="iscreator && (isLoggedin =='true' || isLoggedin =='True')"> 
                                <a  target=”_blank”><i class="bi bi-pencil-square kin mr-3"></i>Edit</a>
                            </button>
                        </div>
                        <!-- <div  (click)="editvideo()"  *ngIf="!isteacherRoute && livedetails.securitytype =='Private'">
                            <button class="default-btn ml-1"> 
                                <a style="color:white" target=”_blank”>Edit</a>
                            </button>
                        </div> -->
    
                        <div class="float-right" (click)="deletevideo()" *ngIf="isteacherRoute">
                            <button class="default-btn ml-1"  style="font-size: 12px;" *ngIf="iscreator && (isLoggedin =='true' || isLoggedin =='True')" > 
                                <a  target=”_blank”>Delete</a>
                            </button>
                        </div>
                        <!-- <div class="float-right" (click)="deletevideo()" *ngIf="!isteacherRoute && livedetails.securitytype =='Private'">
                            <button class="default-btn ml-1"> 
                                <a  style="color:white" target=”_blank”>Delete</a>
                            </button>
                        </div> -->
                        <!-- <div class="ml-3" *ngIf="!isrequest && livedetails.securitytype !='Private' && isLoggedin=='true' && !isteacherRoute">
                            <a class="default-btn" (click)="removeFromList(livedetails)">     -->
                                <!-- <i class='bx bx-paper-plane icon-arrow before'></i> -->
                                <!-- <span class="label">Remove</span> -->
                                <!-- <i class="bx bx-paper-plane icon-arrow after"></i> -->
                            <!-- </a> 
                        </div> -->
                        
                    </div>            
                </div>
            </div>
        </div>
    </section>
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
        <img src="assets/img/spinner-default.gif" alt="">
    </ngx-spinner>