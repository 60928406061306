
import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../../shared/api.service";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { DatePipe } from '@angular/common';
const datepipe: DatePipe = new DatePipe('en-US')
@Component({
  selector: 'app-addsmartmaterial-list',
  templateUrl: './addsmartmaterial-list.component.html',
  styleUrls: ['./addsmartmaterial-list.component.scss']
})
export class AddsmartmaterialListComponent implements OnInit {

  constructor(private rest: ApiService, private router: Router) {}

  public smartMaterialList: any = [];
  public mydashboardData: any = [];
  public isTeacher: Boolean = false;
  public isStudent: Boolean = false;
  public isLogin: any = 'false';
  public currentPageNumber : number = 1;
public totalVideoCount: number;
public totalPageCount: number;
public currentVideoCount: number;
term: string;
public webinarData: any = [];
isDesc: any = 1;
column: string = 'remainingClass';
p: number = 1;
count: number = 4;

  ngOnInit() {
    const token = localStorage.getItem("webtoken");
  
    this.isTeacher =
        localStorage.getItem("profileType").toLowerCase() == "teacher";
    this.isStudent =
        localStorage.getItem("profileType").toLowerCase() == "student";
    this.getPurchasedSmartMaterial();
  }


  getPurchasedSmartMaterial() {
    const webToken = localStorage.getItem("webtoken");
    if(this.isTeacher){
        var request = {};
    request['limit'] = environment.listing__itemsPerPage;
    request['page'] = this.currentPageNumber;
      this.rest.NewSmartMaterialListing(webToken,request).subscribe((response) => {
        
        console.log("material", response)
        if(response.status){
          this.smartMaterialList = response.data.data;
          this.totalVideoCount = response.data.totalCount;
        this.totalPageCount = Math.ceil(this.totalVideoCount / environment.listing__itemsPerPage)
        this.currentVideoCount = ((this.currentPageNumber - 1) * environment.listing__itemsPerPage)
          for(var material of this.smartMaterialList){
            if(material.thumbnail && material.thumbnail == ""){
              material.thumbnail = "slide/img/courses/courses1.jpg"
            }
          }
          console.log(this.smartMaterialList)
        }
      })
    }
    else{
      this.rest.getPurchasedSmartMaterial(webToken).subscribe((response) => {
        console.log("material", response)
        if(response.status){
          this.smartMaterialList = response.data;
          for(var material of this.smartMaterialList){
            if(material.thumbnail && material.thumbnail == ""){
              material.thumbnail = "slide/img/courses/courses1.jpg"
            }
          }
          console.log(this.smartMaterialList)
        }
      })
    }
    
  }
  clickPageNumber(pageNumber) {
    this.currentPageNumber = pageNumber;
    console.log(this.currentPageNumber)
    // if (this.isLogin == 'true' && this.isTeacher) {
    //   this.getSmartMaterialList();
    // }
    // else if (this.isLogin == 'true' && this.isStudent) {
    //   this.getSmartMaterialWithoutLogin();
    // }

    // else {
      // this.getSmartMaterialWithoutLogin();
    //}
  }


  detailsSmartMaterial(data){
    this.router.navigate(['studyMaterialDetails/' + data._id])
  }
}
