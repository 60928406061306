import { Component, ChangeDetectorRef,OnInit,Inject, ElementRef, ViewChild, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Options } from 'ng5-slider';
import { ApiService } from '../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {NgSelectModule, NgOption} from '@ng-select/ng-select';
import { NgxSpinnerService } from "ngx-spinner";
import { ExternalLibraryService } from '../util';
declare let Razorpay: any;
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-buddione-onone',
  templateUrl: './buddione-onone.component.html',
  styleUrls: ['./buddione-onone.component.scss']
})
export class BuddioneOnoneComponent implements OnInit {

  public isTeacher:boolean = false;
	public paymentDetails:any = [];
  paymentBuddiForm: FormGroup;
  public submitted: boolean = false;
  public buddiOneonone:any = [];
  razorpayResponse;
  RAZORPAY_OPTIONS:any;
  public token:any ='';
  transactionDetailsFirst:any =[];
  public isLoggin:Boolean = false;
 	constructor(private cd:  ChangeDetectorRef, private razorpayService: ExternalLibraryService,private location:Location,private my_router: ActivatedRoute,public toastr: ToastrManager, private formBuilder: FormBuilder, private userApi: ApiService, private router: Router,private SpinnerService: NgxSpinnerService) { }


  	ngOnInit(): void {
      this.razorpayService
      .lazyLoadLibrary('https://checkout.razorpay.com/v1/checkout.js')
      .subscribe();
    this.RAZORPAY_OPTIONS = {
      "key":  environment.razorpayKey,
      "amount": "",
      "name": environment.paymentNameHead,
      "order_id": "",
      "description": environment.paymentNameSub,
      "prefill": {
        "name": "amaithisingam@gmail.com",
        "contact": "",
        "method": ""
      },
      "modal": {},
      "theme": {
        "color": "#0096C5"
      }
    };
     this.isLoggin = localStorage.getItem('isLoggedin') =='false' ? false : true;
		  var urlArray = window.location.href.split('/');
  		this.isTeacher = localStorage.getItem('profileType').toLowerCase() =='teacher';
      this.token = localStorage.getItem('webtoken');
		  this.paymentBuddiForm = this.formBuilder.group({
        noofclasses: ['1',Validators.required]
      });      
      this.userApi.getPackageById({_id:urlArray[urlArray.length-1]}, this.token).subscribe((response) => {
      if(response.status){
        this.buddiOneonone = response.data[0];
        this.showBuyCond( this.buddiOneonone);   
      }
     })
  	}
    get f() { return this.paymentBuddiForm.controls; }
    onSubmit(){
    if(!this.isLoggin){
       this.toastr.errorToastr('Please Login to continue.')
    }else{
      if(!this.isTeacher && this.buddiOneonone.showBuy) {
        this.SpinnerService.show()
        this.addPayment(this.token,this.buddiOneonone);   
        this.SpinnerService.hide()      
      }else{
         this.toastr.errorToastr(this.buddiOneonone.productName+' One-on-one Package Already Added')
      }
    }
      
    }
    changeCount(event){
      if(event.target.value =='' || event.target.value < 0){
        this.paymentBuddiForm.patchValue({ noofclasses: 0 });
      }else{
        this.paymentBuddiForm.patchValue({ noofclasses: Number(event.target.value) });
      }
    }
    addPayment(token,data){
      var req={
        _id:data._id,
        productPrice:this.paymentBuddiForm.value.noofclasses * this.buddiOneonone.productPrice,
        remainingClass:this.paymentBuddiForm.value.noofclasses,
        remainingExam:data.totalExam
      }
      this.SpinnerService.show()
      this.userApi.addPaymentGetRes(token,req).subscribe((response) => {
        this.SpinnerService.hide()
        if(response.status){
          this.transactionDetailsFirst = response.data;
          this.SpinnerService.show()
          this.proceed(response.data.productPrice);
          this.SpinnerService.hide()
         }          
      });
    }
    proceed(productPrice) {
      this.RAZORPAY_OPTIONS.amount = this.paymentBuddiForm.value.noofclasses * this.buddiOneonone.productPrice+'00';
      this.RAZORPAY_OPTIONS['handler'] = this.razorPaySuccessHandler.bind(this);
      let razorpay = new Razorpay(this.RAZORPAY_OPTIONS)
      razorpay.open();
    }
    showBuyCond(packageData){
      var req ={
        productDetails:packageData
      }
      this.userApi.isAlreadyPaid(req,this.token).subscribe((result) => {
        if(result.status){   
          this.buddiOneonone.showBuy = result.data.length !=0 ? false : true; 
        }
      });
    }
    public razorPaySuccessHandler(response) {
      this.transactionDetailsFirst.status = true;
      this.transactionDetailsFirst.invoiceID = response.razorpay_payment_id;    
      this.transactionDetailsFirst.validFrom = new Date();
      this.transactionDetailsFirst.remainingClass = this.paymentBuddiForm.value.noofclasses;
      this.transactionDetailsFirst.validTill = new Date();    
      this.SpinnerService.show()
      this.userApi.updatePaymentDetails(this.token,this.transactionDetailsFirst).subscribe((response) => {
        this.SpinnerService.hide()
        if(response.status){
           this.SpinnerService.show()
          this.userApi.productNotification(response.data,this.token).subscribe((notifyResult) => {
             this.SpinnerService.hide()
            if(notifyResult){
               this.router.navigate(['/payment-success',response.data[0]._id]);
  
  
              // this.GetAllProduct();
            }
          });
        }
      });
      this.razorpayResponse = `Razorpay Response`; 
      this.cd.detectChanges();    
    }
  
    public test() {
      document.getElementById('response-modal').style.display = 'block';
    }

}
