import { Component, OnInit } from "@angular/core";


import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "../../../shared/api.service";
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';

declare let Razorpay: any;
@Component({
    selector: "app-pricing-plans",
    templateUrl: "./pricing-plans.component.html",
    styleUrls: ["./pricing-plans.component.scss"],
})
export class PricingPlansComponent implements OnInit {
    public isStudent: boolean = false;
    public isTeacher: boolean = false;
    buttonshow=true;
    hg=true;
    isLoggedin = "false"
    userprofdata: any;
    
    constructor(
      
      private SpinnerService: NgxSpinnerService,
      public toastr: ToastrManager,
      private rest: ApiService,
      private router: Router ) {}

    ngOnInit(): void {
      const webToken = localStorage.getItem('webtoken');
        this.isTeacher =
            localStorage.getItem("profileType").toLowerCase() == "teacher";
        this.isStudent =
            localStorage.getItem("profileType").toLowerCase() == "student";

        if(this.isTeacher){
          this.buttonshow=false;
        }
        if (webToken != null) {
          this.getUserdetails();
        }
        // $(document).ready(function() {
        //   $('#tallModal').modal('show');
        // });
      //   $(window).on('load', function() {
      //     $('#myModal').modal('show');
      // });
    }
  fo(){
    this.hg=false;
  }
    rout(value){
      if(this.isTeacher){
        const webToken = localStorage.getItem("webtoken");
        this.rest.userName_DisplayTeacher(webToken).subscribe((result) => {
          let currentInstitutionPriceCheck = result.data.institutionType ? result.data.institutionType.price >= value : true
          if(result.data.institutionID && currentInstitutionPriceCheck) {
            this.toastr.errorToastr('You are already part of an institution');
          }
          else if(result.data.institutionID){
            this.router.navigate(['/upgrade']);
          }
          else{
            this.router.navigate(['/Institute-create']);
          }
        }, (err) => {
        console.log(err);
        this.toastr.errorToastr('Something went wrong, please try again later.');
        });
      }else if(!this.isTeacher){
        this.router.navigate(['/teacher/login']);
        this.toastr.errorToastr('Please login to continue');
      }
    }

    getUserdetails() {
      const webToken = localStorage.getItem('webtoken');
      this.rest.Getteacherallpopulate(webToken).subscribe((result) => {
        this.userprofdata = result.data;
      }, (err) => {
        console.log(err);
      });
    }
}
