import { Component, OnInit,HostListener } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Options } from 'ng5-slider';
import { ApiService } from '../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { Location } from '@angular/common';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { RemovespacePipe } from '../../custom-pipes/removespace.pipe';
import { MediaService } from '../../../shared/media.service';
declare var $: any;
@Component({
selector: 'app-courses-details-page',
templateUrl: './courses-details-page.component.html',
styleUrls: ['./courses-details-page.component.scss'],
providers:[RemovespacePipe]
})
export class CoursesDetailsPageComponent implements OnInit {

isLoggedin = "false";
public teacherID = "";
public meetingimage = "";
public meetingurl = "";
public meetingsactionText = "";
public meetingCategorypopup = "";
public meetingScheduleDatepopup = "";
public meetingrunningpopup = "";
public meetingIDpopup = "";
public meeting_ID = "";
public meetingname = "";
public meetingScheduleDate = "";
public inviteUrl = "";
public frontwebsite = environment.frontUrl;
public redirectUrl = "";
public meetingDuration = '';
public meetingLanguage = '';
public meetingCity = '';
public meetingoccurance = '';
public meetingdescription = '';
public livedetails:any =[];
public profileName = '';
public meetingId='';
public urlArray:any=[];
public pagename:any = '';
public tag:any='';
public groupName:any;
public isStudent:any;
public isEntroll:any=false;
public isteacherRoute:any =false;
public isrequest:boolean = false;
public isaccept:boolean = false;
public isTeacher:boolean = false;
public isLogout:boolean = false;
public studentCount:any =[];
public isone2one:boolean = false;
public viewRequest:boolean = false;
public isrecordPast:boolean = false;
public isMobile:any ='';
public paymentDetails:any = [];
public packageActive:boolean = false;
public meetingIDData:boolean = false;
constructor(private api:MediaService,private location:Location,private my_router: ActivatedRoute,public toastr: ToastrManager, private formBuilder: FormBuilder, private rest: ApiService, private router: Router,private SpinnerService: NgxSpinnerService,private sanitizer: DomSanitizer,private datePipe: DatePipe) { }

ngOnInit() {
	this.isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent);
	if(this.isMobile){
		window.location.href = "intent:#Intent;action=com.buddiopenfromweb.alwin;category=android.intent.category.DEFAULT;category=android.intent.category.BROWSABLE;S.msg_from_browser=Launched%20from%20Browser;end"
	}
	window.scrollTo(0, 0);
	console.log(this.livedetails);	

	this.isLoggedin = localStorage.getItem('isLoggedin');
	this.teacherID = localStorage.getItem('teacherID');
	this.isLogout = window.location.href.match('logoutmeeting')!=null ? true : false;
	this.isTeacher = localStorage.getItem('profileType').toLowerCase() =='teacher'
	this.isone2one = window.location.href.match('accept')!=null ? true : false;
	this.viewRequest = window.location.href.match('viewRequest')!=null ? true : false;
	this.isStudent = localStorage.getItem('teacherID') =='' || localStorage.getItem('teacherID') == undefined? true : false
	const webToken = localStorage.getItem('webtoken');	
	var urlArray = window.location.href.split('/');
	this.isrequest = window.location.href.match('request') !=null ? true : false;
	this.isaccept = window.location.href.match('accept') !=null ? true : false;
	this.isteacherRoute = window.location.href.match('teacher') !=null ? true : false
	this.pagename ="Meeting"
	this.isEntroll = window.location.href.match('enroll') !=null ? true : false;

	if(this.isrequest || this.isaccept){
		this.rest.getMeetingbyIDRequest(urlArray[urlArray.length-1]).subscribe((result) => {			
			var temp = [];
			temp = result.data.filter(function(val){return(val.meetingID == urlArray[urlArray.length-1])});
			if(temp.length ==0){
				this.livedetails = []
			}else{
				var temp1 = [];
				if(temp[0].tag !='' && temp[0].tag != "undefined"){
					//this.tag = JSON.parse(temp[0].tag).join(',')
					this.tag = temp[0].tag
				}else{
					this.tag = ''
				}
				this.livedetails = temp.length >0 ? temp[0]:[];
				this.meetingIDData = this.livedetails.meetingID;
				this.isrecordPast = new Date(this.livedetails.ScheduleDate).setHours(0, 0, 0, 0) <new Date().setHours(0, 0, 0, 0);
				if(this.livedetails != undefined && this.livedetails.length !=0 && this.livedetails.teacherID && this.livedetails.teacherID !=undefined){
					this.profileName = this.livedetails.teacherID.profileName.replace(/ /gi, "").replace(/-/gi, "_");
					this.inviteUrl = this.frontwebsite+"invite/"+this.livedetails.meetingID;
				}
				this.isone2one = this.livedetails.classType =="One-on-One" ? true :false
				if(this.isTeacher && this.isone2one){
					this.callTeacher(urlArray[urlArray.length-1],this.livedetails)
				}

				if(this.livedetails != undefined && this.livedetails.length !=0){
					this.detailsMeeting(this.livedetails);
					//this.reduceClassCall();
				}
			}
		});
	}else{
		this.rest.getMeetingbyID(urlArray[urlArray.length-1]).subscribe((result) => {
			if(result.length!=0){
				var temp = [];
				this.studentCount = result.studentCount;
				temp = result.data.filter(function(val){return(val.meetingID == urlArray[urlArray.length-1])});
				if(temp.length ==0){
					this.livedetails = []
				}else{
					var temp1 = [];
					if(temp[0].tag !='' && temp[0].tag != "undefined"){
						//this.tag = JSON.parse(temp[0].tag).join(',')
						this.tag = temp[0].tag
					}else{
						this.tag = ''
					}
					this.meetingIDData = this.livedetails.meetingID;
					this.livedetails = temp.length >0 ? temp[0]:[];
					this.isrecordPast = new Date(this.livedetails.ScheduleDate).setHours(0, 0, 0, 0) <new Date().setHours(0, 0, 0, 0);
					if(this.livedetails != undefined && this.livedetails.length !=0 && this.livedetails.teacherID && this.livedetails.teacherID !=undefined){
						this.profileName = this.livedetails.teacherID.profileName.replace(/ /gi, "").replace(/-/gi, "_");
						this.inviteUrl = this.frontwebsite+"invite/"+this.livedetails.meetingID;
					}
					this.isone2one = this.livedetails.classType =="One-on-One" ? true :false
					if(this.isTeacher && this.isone2one){
						this.callTeacher(urlArray[urlArray.length-1],this.livedetails)
					}

					if(this.livedetails != undefined && this.livedetails.length !=0){
						this.detailsMeeting(this.livedetails);
					}
				}
			}
		});
	}
}
reduceClassCall(isOneoneoneClass){
	if(isOneoneoneClass){
		const webToken = localStorage.getItem('webtoken');
		this.rest.getCategoryProductReduce(webToken).subscribe((response) => {
		if(response.status){
			this.paymentDetails = response.data;			
			if(this.isLogout){
				var req={
				_id:"",
				remainingClass:0
	  			}
	  			if(this.paymentDetails.free.length!=0 && this.paymentDetails.free[0].remainingClass!=0){
					req._id = this.paymentDetails.free[0]._id,
					req.remainingClass = this.paymentDetails.free[0].remainingClass-1
	  			}else{
					if(this.livedetails.categoryID.categoryName == 'K-12'){
		  				req._id = this.paymentDetails.k12[0]._id,
		  				req.remainingClass = this.paymentDetails.k12[0].remainingClass-1
					}
					if(this.livedetails.categoryID.categoryName  == 'NEET'){
		  				req._id = this.paymentDetails.neet[0]._id,
		  				req.remainingClass = this.paymentDetails.neet[0].remainingClass-1
					}
					if(this.livedetails.categoryID.categoryName  == 'JEE'){
		  				req._id = this.paymentDetails.jee[0]._id,
		  				req.remainingClass = this.paymentDetails.jee[0].remainingClass-1
					}
					if(this.livedetails.categoryID.categoryName  == 'CA'){
		  				req._id = this.paymentDetails.ca[0]._id,
		  				req.remainingClass = this.paymentDetails.ca[0].remainingClass-1
					}
					if(this.livedetails.categoryID.categoryName  == 'NEETPG'){
		  				req._id = this.paymentDetails.neetpg[0]._id,
		  				req.remainingClass = this.paymentDetails.neetpg[0].remainingClass-1
					}
					if(this.livedetails.categoryID.categoryName  == 'UPSC'){
		  				req._id = this.paymentDetails.upsc[0]._id,
		  				req.remainingClass = this.paymentDetails.upsc[0].remainingClass-1
					}
					if(this.livedetails.categoryID.categoryName  == 'Coding'){
		  				req._id = this.paymentDetails.coding[0]._id,
		  				req.remainingClass = this.paymentDetails.coding[0].remainingClass-1
					}
	  			}
	  			this.rest.updatePaymentClassandExam(req,webToken).subscribe((response) => {
					console.log(response);
	  			});
			}
			
		}	
	});
	}	
}
backto(){
	var data = {
      'pagename':'menu1'
    }
    var routerData = JSON.stringify(data)
    var isTeacherText =  this.isTeacher ? '/sidenav' : '/sidenav' 

	if(this.isLogout || this.isaccept || (!this.isEntroll && !this.isrequest)){	
		this.router.navigate([isTeacherText], { state: data })		
	}else{

		this.location.back();
	}
}
editMeeting(){
	this.router.navigate(['/meeting-edit/'+this.livedetails.meetingID]);
}
detailsMeeting(data){
	if(!this.isTeacher && this.isLogout){
		this.reduceClassCall(data.classType=='One-on-One');
	}
	
	window.scroll(0,0);
	this.teacherID = localStorage.getItem('teacherID');
	this.meetingname = data.name;
	if(data.teacherID){
		var profileName = data.teacherID.profileName;
		profileName = profileName.replace(/ /gi, "").replace(/-/gi, "_");
		this.inviteUrl = this.frontwebsite+"invite/"+data.meetingID;
	}
	this.meetingIDpopup = data.meetingID;
	this.meeting_ID = data._id;
	this.meetingrunningpopup = data.running;
	this.meetingScheduleDate = data.ScheduleDate;
	this.meetingDuration = data.Duration ? data.Duration +'Min' : '';
	this.meetingLanguage = data.Languages;
	this.meetingCity = data.city;
	this.meetingoccurance = data.occurance;
	this.meetingdescription = data.meetingdescription;
	var backimage = "";
	if(localStorage.getItem('isLoggedin') == 'true' && (data.teacherID !=undefined || this.isStudent)){
		if( this.teacherID== data.teacherID._id){
			this.meetingsactionText = "Start";
		}else{
			this.meetingsactionText = "Join";
		}
	}
	if(this.isEntroll){
		this.meetingsactionText = "Entroll";
	}
	if(localStorage.getItem('isLoggedin') == 'false'){
		this.meetingsactionText = "Login";
	}
}
copyMessage(){
	const selBox = document.createElement('textarea');
	selBox.style.position = 'fixed';
	selBox.style.left = '0';
	selBox.style.top = '0';
	selBox.style.opacity = '0';
	selBox.value = this.inviteUrl ;
	document.body.appendChild(selBox);
	selBox.focus();
	selBox.select();
	document.execCommand('copy');
	document.body.removeChild(selBox);
	this.toastr.successToastr("Copied Successfully");
}
removeMeeting(data){	
	const webToken = localStorage.getItem('webtoken');
	if(confirm("Are you sure to delete ")) {
		this.rest.removeMeeting(data,webToken).subscribe((result) => {
			if(result.status){
				this.location.back();
			}else{
				this.toastr.errorToastr(result.message);
			}
		});
	}
}
fetchrecordvideo(id,type) {
    var req = {
      [type]: id
    }
    this.SpinnerService.show()
    this.api.fetchrecordvideo(req).subscribe((result) => {
    	this.SpinnerService.hide()
      	if(result.status) {
        	window.open(result.data[0].recordurl, '_blank');
      	}
    	}, (err) => {
     		this.toastr.errorToastr(err.message);
   		});
}
requestAccept(data){
	data.userType = 'teacher';
	const webToken = localStorage.getItem('webtoken');
	data.meetingID = data.meetingID;
	data.meetingName = data.name;
	this.rest.requestAccept({"meetingID":data.meetingID,"name":data.name},webToken).subscribe((result) => {
		if(result.status){
			this.rest.scheduleTeacherAccept(webToken).subscribe((result) => {
			console.log(result)
		})
		this.addToCalendar(data,webToken,false);
		//this.router.navigate(['/teacher/requests/']);
		}else{
			this.toastr.errorToastr(result.message);
		}
	});
}

callTeacher(url,data){	
		const webToken = localStorage.getItem('webtoken');
		this.rest.updateEndMeetingTime({meetingID:url},webToken).subscribe((result) => {
		var exp = localStorage.getItem('exp');
		if(result.status){
			var diffData = new Date(result.data.endMeeting).valueOf() - new Date(result.data.startMeeting).valueOf();;
			var minutes = Math.floor((diffData/1000)/60);
			//minutes>=60
			if(minutes>=60){
				var reqData = {
					startMeeting : result.data.startMeeting,
					endMeeting : result.data.endMeeting,
					_id :  result.data._id,
					studentID : result.data.studentID,
					exp : exp,
					classType:'oneonone',
					productCode:this.livedetails.categoryID._id
				}				
				if(this.isLogout){
					localStorage.setItem('isPendindTeacher','true');
					this.rest.addTeacherPayment(reqData,webToken).subscribe((innerResult) => {
					localStorage.setItem('isPendindTeacher','false');
					this.router.navigate(['feedbackTeacher']);
					});
				}
				
			}
		}
	});	
}
meetingsaction(id,action,data){
	const webToken = localStorage.getItem('webtoken');
	if(action == 'login'){
 		var dataReq={isredirect:true}
   		this.router.navigate(['/student/login'],{state:dataReq});
	}else{
		if(this.isEntroll){
			var req={
				meetingID : this.livedetails._id,
				meetingType :this.livedetails.meetingType,
				userID:this.isStudent ? localStorage.getItem('StudentID') : localStorage.getItem('teacherID'),
				role:this.isStudent ?'student':'teacher'
			}
			this.rest.meetingActionEnroll(req,webToken,action).subscribe((result) => {
				if(result.status){
					this.toastr.successToastr(result.message);
					if(result.message !="Already Enrolled. Please check ur dashboad"){
						this.addToCalendar(data,webToken,true);
					}
				}else{
					if(result.message == 'Meeting Not Started.'){
						var datajson={date:this.meetingScheduleDate}
						this.router.navigate(['/meetingnotstart'],{state:datajson});
					}else{
						this.toastr.errorToastr(result.message);
					}
				}
			}, (err) => {
			console.log(err);
			});
		}else{
			this.rest.meetingAction(id,webToken,action,this.isone2one ? 'oneonone' :false).subscribe((result) => {
				if(result.status){
					location.href = result.data[0];	
				}else{
					if(result.message == 'Meeting Not Started.'){
						this.toastr.successToastr(result.message);
						this.addToCalendar(data,localStorage.getItem('webtoken'),false);
					}else{
						this.toastr.errorToastr(result.message);
					}
				}
			}, (err) => {
				console.log(err);
			});
		}
	}
}

addToCalendar(data,webToken,isenroll){
	var req={
		myLifeListDatepick:moment(data.ScheduleDate).format('YYYY-MM-DD'),
		myLifeListEventType: "Class",
		myLifeListTimepick:moment(data.ScheduleDate).format('HH:mm:ss'),
		setReminder: 0,
		todoEventType: "Class",
		myLifeListTitle:data.meetingType =='' ? 'One-on -One Class' : data.meetingType ,
		meetingType:data.meetingType =='' ? 'One-on -One Class' : data.meetingType ,
		todoSchedule: data.ScheduleDate,
		todoSetReminder: "",
		todosetReminderValue: "",
		sessionName:data.name,
		requestId:data.requestId,
		meetingId : data._id,
		sessionId:data.meetingID,
		profileType:this.isTeacher ? 'teacher' : 'student'
	};
	this.SpinnerService.show();
	this.rest.add_myLife_NewData(req,webToken).subscribe((result) => {
		this.SpinnerService.hide();
		if(result.status){
			this.toastr.successToastr("Class Added to your Calender");
			if(this.isTeacher){
				this.router.navigate(['/teacher/accept/'+this.meetingIDData]);
				//this.router.navigate(['/teacher/requests/']);
			}else{
				if(isenroll){
					var data = {
          				message:'Added'
        			}  
        			this.router.navigate(['/success/enrollclass'], { state: data }); 
				}else{
					this.location.back();
				}
			}
		}else{
			this.toastr.errorToastr(result.message);
		}
	});
}
  removeFromList(data){
    var req={
    "libraryType":"meetingID",
    "libraryId":data._id
    }
    const webToken = localStorage.getItem('webtoken');
    this.rest.removeList(req,webToken).subscribe((result) => {
      if(result){
        this.toastr.successToastr(result.message);          
        this.router.navigate(['/success/library']);  
      }else{
        this.toastr.errorToastr(result.message);          
      }
    })    
  }

}