<app-header-style-one></app-header-style-one>

<!-- <section class="CourseMain">

 
    <div class="container">
       
    <div class="courseMainCard">
       
        <div class="courseImg">
            <img src="assets/img/jeee.jpg" class="img-fluid" />
        </div>

        <div class="CourseDetails" style="width:100%">
            <h2>Wise Mock Test class 11th</h2>
           <p> this mock test is for class 11th students who are enrolled.</p>

   
       
        </div>
        <div class="CourseDetails">
            <h2>Wise Mock Test class 11th</h2>
           <p> this mock test is for class 11th students who are enrolled.</p>

   
    
       
        </div>
     
    </div>
    <div class="scheduleMainCard">
        <div class="row">
            <div class="col-md-4">
                <div class="scheduleTitle">
                    <h3>Schedule</h3>
                    <p>sep 20 - oct 18, 6 lesson 3 tests</p>
                </div>
            </div>
            <div class="col-md-8">
            
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
             
            </div>
            <div class="col-md-8">
                <div class="scheduleDateMain">
                    <ul>
                        <li  >
                            <div class="scheduleTitleCard">
                           <img src="../../../../assets/img/documents.png">
                            <div class="scheduleDescription">
                                <h5 style="margin-left: 10px;">Physical worl measurements some basic concepts</h5>
                                 <h6 style="margin-left: 10px;">Test-1 sep 20</h6>
                                <p style="margin-left: 10px;">pre medical exams</p>
                            </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4">
             
            </div>
            <div class="col-md-8">
                <div class="scheduleDateMain">
                    <ul>
                        <li  >
                            <div class="scheduleTitleCard">
                           <img src="../../../../assets/img/documents.png">
                            <div class="scheduleDescription">
                                <h5 style="margin-left: 10px;">Analysis of physics</h5>
                                 <h6 style="margin-left: 10px;">Lesson-1 sep 21</h6>
                                <p style="margin-left: 10px;"> Ankit devgan</p>
                            </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4">
           
            </div>
            <div class="col-md-8">
                <div class="scheduleDateMain">
                    <ul>
                        <li  >
                            <div class="scheduleTitleCard">
                           <img src="../../../../assets/img/documents.png">
                            <div class="scheduleDescription">
                                <h5 style="margin-left: 10px;">Analysis of physics</h5>
                                 <h6 style="margin-left: 10px;">Lesson-2 sep 23</h6>
                                <p style="margin-left: 10px;"> Ankit devgan</p>
                            </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
</section> -->


<section class="instructor-details-area pt-100 pb-70">
    <div class="container">
    <h3> Test Details </h3>
    <br>
        <div class="instructor-details-desc">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <div class="instructor-details-sidebar">
                        <img src="../../../../assets/img/teacherlaptop.png" alt="image">

                    </div>
                </div>

                <div class="col-lg-8 col-md-8">
                    <div class="instructor-details">
                        <h3>{{TestData.testName}}</h3>
                        <span class="sub-title">{{TestData.scheduledDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</span>

                        <div class="row" style="margin-top: 5%;">
                            <div class="col-lg-2 col-md-2">
                                <i class='bx bx-group'></i>
                                <span>{{TestData.totalTime}} Mins</span>
                            </div>
                            <div class="col-lg-2 col-md-2">
                                <i class='bx bx-group'></i>
                                <span>{{TestData.totalMarks}} Marks</span>
                            </div>
                            <div class="col-lg-3 col-md-3">
                                <i class='bx bx-group'></i>
                                <span>{{TestData.numberOfQuestions}} Questions</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-3 col-md-3" style="margin-top: 10%;">
                                <button *ngIf="isLoggedin && isstudent" type="submit" (click)="starttest()"
                                    class="default-btn"><i class='bx bx-save icon-arrow before'></i><span
                                        class="label">Start</span><i class="bx bx-save icon-arrow after"></i></button>

                                <button *ngIf="!isLoggedin" type="submit" (click)="login()" class="default-btn"><i
                                        class='bx bx-save icon-arrow before'></i><span class="label">Login</span><i
                                        class="bx bx-save icon-arrow after"></i></button>
                            </div>

                            <div *ngIf="isreview && isLoggedin" class="col-lg-3 col-md-3" style="margin-top: 10%;">
                                <button type="submit" (click)="showmarks()" class="default-btn"><i
                                        class='bx bx-save icon-arrow before'></i><span class="label">Previous</span><i
                                        class="bx bx-save icon-arrow after"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="instructor-details-area  pb-70" *ngIf="isshow">
    <div class="container" *ngFor="let previewlist of PreviewTest; let i = index" style="margin-top: 4%;">
        <h4>Attempt {{i + 1}}</h4>
        <p>Summary of marks scored in the test attempted on {{previewlist.dateTaken |  date :"MMM dd,yyyy, hh:mm aaa"}}
        </p>
        <div class="row">
            <div class="col-lg-12 col-md-12" style="text-align: end;">
                <button type="submit" (click)="testdetails(previewlist._id)" class="default-btn"><i
                        class='bx bx-save icon-arrow before'></i><span class="label">View</span><i
                        class="bx bx-save icon-arrow after"></i></button>
            </div>
        </div>
        <div class="row" style="margin-top: 2%;">
            <div class="col-lg-2 col-md-2" style="box-shadow: 0px 0px 5px 0px;padding: 5px 5px 5px 10px;">
                <h6>Total Marks Scored</h6>
                <br>
                <p style="font-size: 16px;color: black;font-weight: 700;">{{previewlist.totalMarks}} <span
                        style="font-size: 11px;color: #9e9e9b;">/{{TestData.totalMarks}}</span></p>
            </div>
            <div class="col-lg-1 col-md-1">

            </div>
            <div class="col-lg-2 col-md-2" style="box-shadow: 0px 0px 5px 0px;padding: 5px 5px 5px 10px;">
                <h6>Marks Gained</h6>
                <br>
                <p style="font-size: 16px;;color: green;font-weight: 700;">{{previewlist.marksGained}} <span
                        style="font-size: 11px;color: #9e9e9b;"> Marks</span></p>
            </div>
            <div class="col-lg-1 col-md-1">

            </div>
            <div class="col-lg-2 col-md-2" style="box-shadow: 0px 0px 5px 0px;padding: 5px 5px 5px 10px;">
                <h6>Marks Lost</h6>
                <br>
                <p style="font-size: 16px;;color: red;font-weight: 700;">{{previewlist.marksLost}} <span
                        style="font-size: 11px;color: #9e9e9b;"> Marks</span></p>
            </div>
            <div class="col-lg-1 col-md-1">

            </div>
            <div class="col-lg-2 col-md-2" style="box-shadow: 0px 0px 5px 0px;padding: 5px 5px 5px 10px;">
                <h6>Total Unanswered</h6>
                <br>
                <p style="font-size: 16px;;color: orange;font-weight: 700;">{{previewlist.totalUnanswered}} <span
                        style="font-size: 11px;color: #9e9e9b;"> Questions</span></p>
            </div>
            <div class="col-lg-1 col-md-1">

            </div>
        </div>
    </div>
</section>