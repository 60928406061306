import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild, NgZone } from '@angular/core';

import { ApiService } from '../../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { lastnamevalidator } from './../../../../shared/lastnamevalidator'
import * as $ from "jquery";
import { Location } from '@angular/common';
@Component({
  selector: 'app-student-register-page',
  templateUrl: './student-register-page.component.html',
  styleUrls: ['./student-register-page.component.scss']
})
export class StudentRegisterPageComponent implements OnInit {
     public cpasswordEye = 'password'; 
  registerform: FormGroup;
  otpForm: FormGroup;
  userType: any;
  isRegisterInvisible: true;
  // div1:boolean=true;
  // div2:boolean=false;
  submitted = false;
  value : any;
  timeLeft: number = 25;
  interval;
  isOTPButtonDisabled = false;
  isChangeNumberButtonDisabled = false;
  phoneNumber;
  changeNumberStatus = false;
  otpSubmitted = false;

  constructor(
    private location:Location,
    public fb: FormBuilder,
    public toastr: ToastrManager,
    private router: Router,
    private ngZone: NgZone,
    private userApi: ApiService,
    private my_router: ActivatedRoute,

  ) {}



  ngOnInit() {
    let queryString = window.location.search;
    let params = new URLSearchParams(queryString);
    let referralId = params.get('referralId');
    document.getElementById('OTPFormIDStudent').hidden = true;
    document.getElementById('registerFormIDStudent').hidden = false;
    document.getElementById('successmessageStudent').hidden = true;
    // document.getElementById('OTPFormID').hidden = true;
    // document.getElementById('otpResendButton').hidden = true;
    // document.getElementById('otpSentSuccess').hidden = true;
    this.changeNumberStatus = false;
    this.registerform = this.fb.group({
      // userType : [''],
      email : ['', [Validators.required, Validators.email]],
      Profile_name: ['', [Validators.required, Validators.pattern("^[a-zA-Z ,.'-]+$")]],
      // last_name: ['', [Validators.required, Validators.pattern("^[a-zA-Z ,.'-]+$"), lastnamevalidator.cannotContainSpace]],
      // email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      mobileNumber1: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(20), Validators.pattern("^[0-9 ()+'-]+$")]],
      passWord:  ['', [ Validators.required,Validators.minLength(6),Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@_#$!%*?&])[A-Za-z0-9@$!%*#_?&]{6,}$")]],
      referralId : [''],
      // checkpassWord: ['', [ Validators.required]],
      // profileType:['Student', Validators.required]
      //acceptTerms: [false, Validators.requiredTrue],
    });
    if(referralId){
      this.registerform.patchValue({
        referralId:referralId
      })
    }
    this.otpForm = this.fb.group({
      otp: ['', [Validators.required, Validators.maxLength(6), Validators.minLength(6), Validators.pattern("^[0-9]*$")]]
    })
  }

  get f() {return this.registerform.controls; }

  get otpf() {return this.otpForm.controls;}


  

  startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } 
      else {
        document.getElementById('otpResendTimer').hidden = true;
        document.getElementById('otpResendButton').hidden = false;
        
        clearInterval(this.interval);
      }
    },1000)
  }


  RegisterSubmit() {

     this.timeLeft = 25;
    this.startTimer();
    // this.div1=false;
    // // document.getElementById('registerFormIDStudent').hidden = true;
    // document.getElementById('OTPFormIDStudent').hidden = false;
    document.getElementById('registerFormIDStudent').hidden = true;
    document.getElementById('OTPFormIDStudent').hidden = false;
    
      console.log('checking register',this.registerform.value)
      this.submitted = true;
      console.log(this.registerform.invalid);
      this.phoneNumber = this.registerform.value.mobileNumber1;
      if(!this.changeNumberStatus) {
        if(!this.registerform.invalid) {
          // if (this.registerform.value.passWord === this.registerform.value.checkpassWord) {
            const data = this.registerform.value;
            console.log(data);
            // data.profileName = data.first_name;
            // data.cpassword = data.checkpassWord;

          //   this.userApi.AddUserWithoutOTP(data).subscribe(res => {
          //   console.log('result', res );
          //   if(res.status){
          //     this.toastr.successToastr(res.message);
          //     this.router.navigate(['/login']);
          //   }else{
          //     this.toastr.errorToastr(res.message);
          //   }
          // });
          var req={
            "email":this.registerform.value.email,
            "phoneNumber":this.registerform.value.mobileNumber1, 
            "profileName":this.registerform.value.Profile_name,
            'password':this.registerform.value.passWord,
            'referralId':this.registerform.value.referralId,
          }
            this.userApi.AddUserStudent(req).subscribe(res => {
            console.log('result', res );
            document.getElementById('OTPFormIDStudent').hidden = false;
            document.getElementById('registerFormIDStudent').hidden = true;
            if(res.status){

              document.getElementById('registerFormIDStudent').hidden = true;
              document.getElementById('OTPFormIDStudent').hidden = false;
              // this.startTimer()
              document.getElementById('headerStudent').hidden = true;
              document.getElementById('signedinStudent').hidden = true;
              document.getElementById('successmessageStudent').hidden = false;
             // this.toastr.successToastr(res.message);
            }
            else{

              document.getElementById('OTPFormIDStudent').hidden = true;
              if(res.restype){
                console.log('checkelse')
                this.toastr.errorToastr(res.message);
              this.router.navigate(['student/login']);
              }
        

              // document.getElementById('registerFormIDStudent').hidden = true;
              // document.getElementById('OTPFormIDStudent').hidden = true;
            }
          });
          // }  
          // else {
          //   this.toastr.errorToastr('Password Mismatch');
          //   document.getElementById('registerFormIDStudent').hidden = false;
          //   document.getElementById('OTPFormIDStudent').hidden = true;
          // }
        } 
        else {
          this.toastr.errorToastr('Please check all fields');
          document.getElementById('registerFormIDStudent').hidden = false;
          document.getElementById('OTPFormIDStudent').hidden = true;
        }
      }
      // else{
      //   if(!this.registerform.invalid) {
      //     if (this.registerform.value.passWord === this.registerform.value.checkpassWord) {
      //       const data = this.registerform.value;
      //       data.profileName = data.first_name;
      //       data.cpassword = data.checkpassWord;
      //       this.userApi.changePhoneNumber(data).subscribe(res => {
      //       console.log('result', res );
      //       if(res.status){
      //         document.getElementById('registerFormIDStudent').hidden = true;
      //         document.getElementById('OTPFormID').hidden = false;
      //         // document.getElementById('otpResendTimer').hidden = false;
      //         // this.startTimer()
      //         this.toastr.successToastr(res.message);
      //       }else{

      //         this.toastr.errorToastr(res.message);
      //       }
      //       //this.toastr.successToastr('Activation Mail Sent to your Registered email ID, Check your Mailbox');
      //       //this.router.navigate(['/login']);
      //     });
      //     }
      //     else {
      //       this.toastr.errorToastr('Password Mismatch');
      //       // alert("Insert Valid Password")
      //     }
      //   } 
      //   else {
      //       //this.toastr.errorToastr('You must fill all fields');
      //       this.toastr.errorToastr('Please check all fields');
      //   }        
      // }
  }

  OTPSubmit() {
    // this.div2=true;

    this.otpSubmitted = true;
    console.log('checking register',this.registerform.value)
    var data = this.registerform.value;
    if(!this.otpForm.invalid) {
      data["otp"] = this.otpForm.value.otp;
      var req={
        "email":this.registerform.value.email,
        "phoneNumber":this.registerform.value.mobileNumber1,
        "otp":this.otpForm.value.otp
      }
      this.userApi.checkOTPStudent(req).subscribe(result => {

        console.log(result,'result==================checkOTPTeacherValid')
        if (result.status) {
         console.log(result.data,'-----------reasdfdfdfdfdfdfdfd---------')
          var data = result.data.student;
                  localStorage.setItem('webtoken', data.token);
                  localStorage.setItem('StudentID', data.user_id);
                  localStorage.setItem('isPartner', (data.email == 'buddilife@gmail.com' ? 'true' : 'false'));
                  localStorage.setItem('profileName', data.profileName);
                  localStorage.setItem('userType', data.userType);
                  localStorage.setItem('isLoggedin', 'true');
                  localStorage.setItem('userId', data.user_id);
                  localStorage.setItem('groupname', data.groupName);
                  localStorage.setItem('profileType', 'Student');
    
                  this.toastr.successToastr('Welcome , you are now logged in!  ');
                  this.userType = data.userType;
                  console.log(this.userType);
                  var inviteid = this.my_router.snapshot.queryParamMap.get('invite');
                  if(inviteid == null){
                              if(window.history.state.isredirect){
                                this.location.back();
                              }else{
                                window.location.href = '/student/mydashboard';
                              }  
                            }else{
                              var split_id = inviteid.split('-');
                              window.location.href = '/invite/'+split_id[1];
                            }
                          
          this.toastr.successToastr(result.message);
          this.router.navigate(['/student/mydashboard']);
        } else {
          this.toastr.errorToastr(result.message);
        }
      }, (err) => {
            console.log(err);
      });
      }
      else {
      console.log(this.otpForm);
      this.toastr.errorToastr("OTP Cannot be empty");
      }
  }
      

  changePhoneNumber() {
    this.changeNumberStatus = true;
    // document.getElementById('registerFormIDStudent').hidden = false;
    // document.getElementById('OTPFormIDStudent').hidden = true;
    // document.getElementById('otpResendTimerStudent').hidden = true;
    // document.getElementById('otpResendButtonStudent').hidden = true;
    // this.router.navigate(['/student/register']);
    window.location.href="/student/register/";

    // clearInterval(this.interval);
    // this.timeLeft = 15;
  }

  otpResendClicked() {
    console.log("Clicked");
    document.getElementById('otpResendButton').hidden = true;
    document.getElementById('otpResendTimer').hidden = false;
    this.timeLeft = 60;
    this.startTimer();
    console.log('checking register',this.registerform.value)
    // var data = this.registerform.value;
    var req={
      "phoneNumber":this.registerform.value.mobileNumber1, 
    }
    this.userApi.checkUserStudent(req).subscribe(res => {
      if(res.status){
        this.toastr.successToastr(res.message);
        //this.router.navigate(['/login']);
      }
      else {
        this.toastr.errorToastr(res.message);
      }
    });

    (<HTMLInputElement> document.getElementById("otpResendButton")).disabled = true;
    this.isOTPButtonDisabled = false;
    document.getElementById('otpResendButton').hidden = true;
    //document.getElementById('otpSentSuccess').hidden = false;
   

    

  }
  receivedData(data){
    this.cpasswordEye = data;
  }
  studentLogin(){
    window.location.href = '/student/register';
  }
  teacherLogin(){
    window.location.href = '/teacher/register';
  }
}
