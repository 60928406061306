import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'filterSearch'
})
export class FilterSearchPipe implements PipeTransform {

  // transform(value: unknown, ...args: unknown[]): unknown {
  //   return null;
  // }
  transform(teacherPayoutDetails: any[], myAccountSearch: string): any[] {
    if (!teacherPayoutDetails) {
      return [];
    }
    if (!myAccountSearch) {
      return teacherPayoutDetails;
    }
  
    myAccountSearch = myAccountSearch.toLocaleLowerCase();
    console.log('account serarch',myAccountSearch);

    return teacherPayoutDetails.filter(it => {
      console.log(it,'checking')
      return it.paymentDetails.productName.toLocaleLowerCase().includes(myAccountSearch);
    });
  }
}


