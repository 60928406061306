<!-- meta verse created on 18.02.2022 by vamsi -->
<app-metaverse-header *ngIf="!isTeacher"></app-metaverse-header>
    <app-metaverse-header-teacher
        *ngIf="isTeacher"
    ></app-metaverse-header-teacher>


    <app-metaverse-banner></app-metaverse-banner>

<div class="space"></div>
<app-metaverse-explore></app-metaverse-explore>
<div class="space"></div>
<div class="background">
  <div class="space"></div> 
<app-metaverse-student-funfacts></app-metaverse-student-funfacts>
<div class="space" style=" margin-top: 5%;"></div>
<app-buddi-unique-model></app-buddi-unique-model>
<div class="space"></div>
<app-tutor-learner></app-tutor-learner>
<div class="space"style=" margin-top: 5%;"></div>
<app-metaverse-student-how-it-works></app-metaverse-student-how-it-works>
<div class="space"></div>
<section class="instructor-area pt-100 pb-70">
  <div class="container ">
      <div class="section-title">
          <!-- <span class="sub-title">Make Connections</span> -->
          <h2 style="color: white;">Our buddies </h2>
          <!--  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea.</p>-->
      </div>

    <app-metaverse-student-instructor></app-metaverse-student-instructor>
  </div>
  </section>
  <div class="space"></div>




</div>
<!-- <app-header-style-one></app-header-style-one>  -->
<!-- <div class="row">
    <div class="col-lg-2">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Search Here..." [(ngModel)]="term">
        </div>
      </div>
</div>

<div class="row">
    <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12">
        <div class="card shadow borderR_10">
          <div class="header text-center pt-2">
            <h6>Payment History</h6>
          </div>
          <div class="tableBody p-0 font-12">
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th (click)="sort('profilename')">S.No &nbsp; <i class="fa fa-sort"></i></th>
                    <th (click)="sort('profilename')">Purchased Item &nbsp; <i class="fa fa-sort"></i></th>
                    <th *ngIf="video" (click)="sort('profilename')">Video Name &nbsp; <i class="fa fa-sort"></i></th>
                    <th *ngIf="material" (click)="sort('profilename')">Material Name &nbsp; <i class="fa fa-sort"></i></th>
                    <th (click)="sort('profilename')">Price &nbsp; <i class="fa fa-sort"></i></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let payment of payments | filter:term; let i = index">
                    <td>{{i+1}}</td>
                   <td>{{payment.purchasedItemType}}</td>
                    <td *ngIf="payment.purchasedItemID.videoName">{{payment.purchasedItemID ? payment.purchasedItemID.videoName : 'No Data'}}</td>
                    <td *ngIf="payment.purchasedItemID.materialName">{{payment.purchasedItemID ? payment.purchasedItemID.materialName : 'No Data'}}</td>
                    <td>{{payment.amount}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
    </div>
</div> -->


  
  <!-- <div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Error</li>
            </ul>
            <h2>Error 404</h2>
        </div>
    </div>
  </div> -->
  
  <!-- <div class="error-404-area">
    <div class="container">
        <div class="notfound">
            <div class="notfound-bg">
                <div></div>
            </div>
            <h5>Metaverse Coming Soon! </h5>
            -->
  
            <!-- <h3>Error 404 : Page Not Found</h3>
            <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
            <a routerLink="/" class="default-btn"><i class='bx bx-home-circle icon-arrow before'></i><span class="label">Back Home</span><i class="bx bx-home-circle icon-arrow after"></i></a> -->
        <!-- </div>
    </div>
  </div> -->
 