<header class="header-area p-relative">
    <div class="top-header top-header-style-four">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-12 col-md-12">   
                    <div class="row">
                    <div class="col-lg-2">
                    </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul class="top-header-login-register">
                        
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="navbar-area navbar-style-three ">
        <div class="ednuv-responsive-nav">
            <div class="container">
                <div class="ednuv-responsive-menu">
                    <div class="logo">
                        <a routerLink="/Home">
                            <img src="assets/img/black-logo.png" class="logoImg" alt="logo">
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="ednuv-nav">
            <div class="container">
              
                <nav class="navbar navbar-expand-md navbar-light">
                    <a routerLink="">
                        <img src="assets/img/black-logo.png" class="logo" alt="logo">
                    </a>
                   <div class="collapse navbar-collapse mean-menu">
                       
                        <ul class="navbar-nav col-md-12 customNavbar">
          
                            <li class=" nav-item col-xs-1">
                                <a routerLink="/Home" class="nav-link">About Us</a>
                             </li>
                             <li class=" nav-item col-xs-1" style="display: flex;align-items: center;" *ngIf="isLogin=='true'">
                                 <a routerLink="/sidenav" class="nav-link">Dashboard</a><i class="bi bi-person-circle"></i>
                              </li>
                  
                             <li class=" nav-item col-xs-1" *ngIf="isLogin=='false' || isLogin==null">
                                 <a routerLink="/student/login" class="nav-link">Login</a>
                             </li>
                             <li class=" nav-item col-xs-2"  (click)="logout()"  *ngIf="isLogin=='true'">
                                 <div style="display: flex;align-items: center;">
                                 <a class="nav-link">Logout</a>
                                 <i class="fa fa-sign-out" style="cursor: pointer;"></i>
                             </div>
                             </li>
                        </ul>                  
                    </div>
                </nav>
            </div>
        </div>
    </div>
    
</header>

<div class="container">
    <div class="main-banner-content" >
    <div class="row">
                    <div class="col-lg-7 col-md-7 col-sm-7">
                        <span class="sub-title" style="color: black;">buddi.life</span>
                        <h3 style="color: black;" class="buddititle">Expand your Educational Services Online</h3>
                        <h2 style="color: black; " class="buddisub">
                        </h2>
                        <img src="../../assets/img/teach.jpeg" class="img-thumbnail"alt="buddi.life teacher">

                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-5 hovervideo">
                        <h2 style="color: black; " class="buddisub">Are you a...
                        </h2>
                       <div class="fir"> 
                           <button routerLink="/teacher/login"><i class="fas fa-chalkboard-teacher"></i>Teacher?</button>
                           <button routerLink="/teacher/login"><i class='fas fa-institution'></i>Institute?</button>
                        </div>
                        <div  class="fir">
                           <button routerLink="/teacher/login"><i class='fas fa-user-graduate'></i>School?</button>
                           <button routerLink="/teacher/login"><i class='fas fa-award'></i>Small Enterprise?</button>
                        </div>
                    </div>
                </div>
    </div>
</div>
