<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3><a style="color:#fff">Company</a></h3>

                    <ul class="useful-link">
                        <li><a routerLink="/about-us">About</a></li>
                        <li><a routerLink="/buddi-management-team">Team</a></li>
                        <li><a routerLink="/learner-reward">Learner Reward</a></li>
                        <li><a routerLink="/tutor-reward">Tutor Reward</a></li>
                        <li><a routerLink="/institute-list">Institutions</a></li>
                        <li><a routerLink="/Pricing-plans">Pricing plans</a></li>
                        <li><a routerLink="/investors">Investors</a></li>
                        <li><a routerLink="/contact-us">Contact Us</a></li>
                        <li><a href="https://angel.co/company/buddi-life" target="_blank">We are Hiring</a></li>
                        <li><a routerLink="/press-releases">Press Releases </a></li>
                        <li><a routerLink="/blog">Blog </a></li>
                        
                    </ul>
                </div>
            </div>


            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Resources</h3>

                    <ul class="support-link">
                        <li><a routerLink="/buddi-privacy">Privacy & Terms</a></li>
                        <li *ngIf="isstudent"><a routerLink="/buddi-faq-student">FAQ</a></li>
                        <li *ngIf="isteacher"><a routerLink="/buddi-faq">FAQ</a></li>
                        <li ><a routerLink="/contact-us">Support</a></li>
                        <li ><a routerLink="/demo-class">Request a live demo</a></li>
                        <li ><a routerLink="/tutorials">Tutorials</a></li>
                        <li ><a routerLink="/buddiTalk">buddiTalk</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Quick Links</h3>

                    <ul class="useful-link">
                        <!-- <li   *ngFor="let obj of allheader">
                            <a [routerLink]="'/'+obj.categoryName+'/'+obj._id">{{obj.categoryName}}</a>
                        </li> -->
                        <li>
                            <a (click)="rout()" style="cursor: pointer;">Institutional Registration?</a>
                        </li>
                        <li>
                           <a  (click)="isteacherFooter()" style="cursor: pointer;">Tutor Registration?</a>
                            <!-- <a routerLink="/teacher/mydashboard">Are you a Teacher?</a> -->
                        </li>
                        <li>
                                <a routerLink="/liveClassList">Live Classes</a>
                            </li>
                            <li>
                                    <a routerLink="/studyhallList">Study Hall</a>
                                </li>
                                <li>
                                        <a routerLink="/roundtableList">Round Table</a>
                                    </li>
                                    <li>
                                            <a routerLink="/all-video-listing">Video Lessons</a>
                                        </li>
                                        <li>
                                                <a routerLink="/all-study-listing">Study Material</a>
                                            </li>
                                            <li>
                                                    <a routerLink="/all-test-listing">Evaluation Tests</a>
                                                </li>
                                                <li>
                                                    <a routerLink="/online-tuition-classes">Online Tuition Classes</a>
                                                </li>
                                                <li>
                                                    <a routerLink="/cbse-online-tuition">CBSE Online Tuition </a>
                                                </li>
                                                <li>
                                                    <a routerLink="/online-teaching-jobs">Online Teaching Jobs</a>
                                                </li>
                    </ul>
                    
                    <ul class="useful-link">
                   
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Contact Us</h3>

                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-map'></i>
                            <a>Chennai, Tamil Nadu.
                            </a>
                        </li>
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="tel:+91 9840844987">Tel : +91 73051 31888</a>
                        </li>
                        <li>
                            <i class='bx bx-envelope'></i>
                            <a href="mailto:care@buddi.life">care@buddi.life</a>
                        </li>
                    </ul>
<!-- 
                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/buddiAcademy" class="d-block" target="_blank"><i
                                    class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://twitter.com/buddiacademy" class="d-block" target="_blank"><i
                                    class='bx bxl-twitter'></i></a></li>
                        
                        <li><a href="https://www.linkedin.com/company/buddiacademy/" class="d-block" target="_blank"><i
                                    class='bx bxl-linkedin'></i></a></li>
                        
                    </ul> -->
                </div>
               
            </div>
            
        </div>
        <!-- <hr class="new1"> -->
        <div class="row">
            <!-- <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Subjects</h3>

                    <ul class="useful-link" >
                       
                        
                        <li style="line-height: 30px;" (click)="maths()" style="cursor: pointer;"><a>Maths</a></li>
                        <li style="line-height: 30px;" (click)="physics()" style="cursor: pointer;"><a>Physics</a></li>
                        <li style="line-height: 30px;" (click)="chemistry()" style="cursor: pointer;"><a>Chemistry</a></li>
                        <li style="line-height: 30px;" (click)="biology()" style="cursor: pointer;"><a>Biology</a></li>
                        <li style="line-height: 30px;" (click)="computerscience()" style="cursor: pointer;"><a>Computer Science</a></li>
                        <li style="line-height: 30px;" (click)="science()" style="cursor: pointer;"><a>Science</a></li>
                        <li style="line-height: 30px;" (click)="socialscience()" style="cursor: pointer;"><a>Social Science</a></li>
                        <li style="line-height: 30px;" (click)="english()" style="cursor: pointer;" ><a>English</a></li>
                        <li style="line-height: 30px;" (click)="tamil()" style="cursor: pointer;"><a>Tamil</a></li>
                        
                              
                    </ul>
                   
                </div>
            </div> -->
            <!-- <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>NCERT BOOKS</h3>

                    <ul class="useful-link" >
                        <li style="line-height: 30px;" (click)="grade12()" style="cursor: pointer;"><a>NCERT Books Class 12</a></li>
                        <li style="line-height: 30px;" (click)="grade11()" style="cursor: pointer;"><a>NCERT Books Class 11</a></li>
                        <li style="line-height: 30px;" (click)="grade10()" style="cursor: pointer;"><a>NCERT Books Class 10</a></li>
                        <li style="line-height: 30px;" (click)="grade9()" style="cursor: pointer;"><a>NCERT Books Class 9</a></li>
                       
                              
                    </ul>
                   
                </div>
            </div> -->
            <!-- <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Video Classes</h3>

                    <ul class="useful-link" >
                        <li style="line-height: 30px;" (click)="class12()" style="cursor: pointer;"><a>CBSE Class 12 </a></li>
                        <li style="line-height: 30px;" (click)="class11()" style="cursor: pointer;"><a>CBSE Class 11 </a></li>
                        <li style="line-height: 30px;" (click)="class10()" style="cursor: pointer;"><a>CBSE Class 10 </a></li>
                        <li style="line-height: 30px;" (click)="class9()" style="cursor: pointer;"><a>CBSE Class 9 </a></li>
                       
                              
                    </ul>
                   
                </div>
            </div> -->
            <!-- <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Boards</h3>

                    <ul class="useful-link" >
                        <li style="line-height: 30px;" (click)="cbse()" style="cursor: pointer;"><a>CBSE</a></li>
                        <li style="line-height: 30px;" (click)="stateboard()" style="cursor: pointer;"><a>TN State Board</a></li>   
                    </ul>
                    
                   
                </div>
            </div> -->

            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="single-footer-widget mb-30" style="float: right;">
                    <h3>Connect with us</h3>

                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/buddi.life" class="d-block" style="color: #3b5998;" target="_blank"><i
                                    class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://twitter.com/buddilife" class="d-block" style="color: #00acee;" target="_blank"><i
                                    class='bx bxl-twitter'></i></a></li>
                        
                        <li><a href="https://www.linkedin.com/company/buddiacademy/" style="color: #0077b5" class="d-block" target="_blank"><i
                                    class='bx bxl-linkedin'></i></a></li>

                        <li><a href="https://www.youtube.com/channel/UCxFiRfV9EuvfmHx7S1ZsLnA"  style="color: #FF0000" class="d-block" target="_blank"><i
                                    class='bx bxl-youtube'></i></a></li>

                        <li><a href="https://www.instagram.com/buddi.life/" class="d-block" style="color: #f03388;" target="_blank"><i
                                class='bx bxl-instagram'></i></a></li>
                    </ul>
                   
                </div>
            </div>
            <!-- <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Newsletter</h3>

                    <div class="newsletter-box">
                        <p>To get the latest news and latest updates from us.</p>
                        <form class="newsletter-form">
                            <label>Your e-mail address:</label>
                            <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                            <button type="submit">Subscribe</button>
                        </form>
                    </div>
                </div>
            </div> -->
        </div>
    </div>

    <div class="footer-bottom-area">
        <div class="container">
            <!-- <div class="logo">
                <a routerLink="/" class="d-inline-block"><img src="../../../../assets//img/buddilogo.jpeg" alt="image"></a>
            </div> -->
            <p><i class='bx bx-copyright'></i>2022 <a target="_blank"> All Rights Reserved</a> |  Scentric Networks Private Limited</p>
        </div>
    </div>
</footer>

<div class="go-top"><i class='bx bx-up-arrow-alt'></i></div>