import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../app/shared/api.service';

@Component({
  selector: 'app-enroll-classes',
  templateUrl: './enroll-classes.component.html',
  styleUrls: ['./enroll-classes.component.scss']
})
export class EnrollClassesComponent implements OnInit {
  isLogin: any = false;
  pagename: any;
  loginname: any;
  cartSize: number;
  public isTeacher: boolean = false;
  public usertype: any = '';
  public profiletype = "";
  public userprofdata: any = []; 
  constructor(    private userApi: ApiService,
    private router: Router,) { }

  ngOnInit(): void {
    this.isLogin = localStorage.getItem('isLoggedin');
    this.profiletype = localStorage.getItem('profileType').toLowerCase();
    if (localStorage.getItem('profileType') != null) {
      this.isTeacher = localStorage.getItem('profileType').toLowerCase() == 'student'
    } 
    if (localStorage.getItem('cartItems') == null) {
      this.cartSize = 0
    }
    else {
      this.cartSize = JSON.parse(localStorage.getItem('cartItems')).length;
    }

    window.addEventListener('storage', () => {
      console.log("called")
    }, false);

    this.pagename = localStorage.getItem('profileType');
    this.loginname = this.pagename + '/login';
    this.isLogin = localStorage.getItem('isLoggedin');
    this.getStudentDetails();
  }

  getStudentDetails(){
    const webToken = localStorage.getItem('webtoken');
      this.userApi.userName_DisplayStudent(webToken).subscribe((result) => {
        this.userprofdata = result.data.studentDetails;
        console.log(this.userprofdata,'userDetails')
      }, (err) => {
        console.log(err);
      });
  }

  logout() {
    localStorage.setItem('isLoggedin', 'false');
    window.location.href = localStorage.getItem('profileType').toLowerCase() + '/login';
  }
}
