import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teacher-reward',
  templateUrl: './teacher-reward.component.html',
  styleUrls: ['./teacher-reward.component.scss']
})
export class TeacherRewardComponent implements OnInit {

  public isstudent = false;
  public isteacher = false;
  isUserLoggedIn: string;  
  constructor() { }

  ngOnInit(): void {
    const userType = localStorage.getItem('userType');      
    this.isUserLoggedIn = localStorage.getItem('isLoggedin');
    if(this.isUserLoggedIn=="true") {
      if(userType.toLowerCase()=="teacher") {
        this.isteacher = true;
      }
      else {
        this.isstudent = true;
      }
    }
    else {
      this.isstudent = true;
    }
  
  }

}
