import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../shared/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ToastrManager, Toastr } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-footer-contact',
  templateUrl: './footer-contact.component.html',
  styleUrls: ['./footer-contact.component.scss']
})
export class FooterContactComponent implements OnInit {
  public isstudent = false;
  public isteacher = false;
  contactform: FormGroup;
  submitted = false;  
  formData;  

  constructor(private formBuilder: FormBuilder,private router: Router ,private rest: ApiService, private toastr: ToastrManager) { }

  ngOnInit(): void {
    var usertype = localStorage.getItem('profileType');
    if (usertype.toLowerCase() == "teacher") {
      this.isteacher = true;
    }
    else {
      this.isstudent = true;
    }

    this.contactform = this.formBuilder.group({
      name: ['', Validators.required],
      subject: ['', Validators.required],
      phonenumber: ['', Validators.required],
      email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      message: ['', Validators.required],
      classType:['']
  });
  }
 // convenience getter for easy access to form fields
 get f() { return this.contactform.controls; }     

 onSubmit(FormGroup) {
   this.submitted = true;
   if (this.contactform.invalid) {
    console.log("Invalid Fields");
    this.toastr.errorToastr("Invalid Fields");
    const data = this.contactform.value;   
      return;
  }    
   const data = this.contactform.value;            
   if(data.classType === undefined || data.classType === "") {
    this.toastr.errorToastr("Please check UserType");
   }
   else {
    var userData = {};
    userData["email"] = data.email;
    userData["name"] = data.name;
    userData["phoneNumber"] = data.phonenumber;
    userData["subject"] =data.subject;
    userData["message"] = data.message;
    userData["userType"] = data.classType;
    this.rest.contactusregister(userData).subscribe((result) => {
      console.log(result);
      if(result.status) {
        this.toastr.successToastr(result.message); 
        this.router.navigate(['']);
      } 
      else {            
        this.toastr.errorToastr(result.message); 
     }
    }, (err) => {
    console.log(err);
    this.toastr.errorToastr(err); 
    });   
   }
 }
}
