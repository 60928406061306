import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from '../../../shared/api.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  public isstudent = false;
  public isteacher = false;
  public meetingsData: any = [];
  public meetingsDataRecord: any = [];
  public meetingsDataListen: any = [];
  public meetingsDataAll: any = [];
  public allmeeting: any = [];
  public inviteUrl = "";
  public frontwebsite = environment.frontUrl;
  public lessonData: any = [];
  public myId: any = ''
  public isLogin: any = 'false';
  public isCancel: any = 'false';
  // public isStudent : any 
  public videoList: any = [];
  public videoListPrivate: any = [];
  public videoListGroup: any = [];
  public teacherVideouploadlist: any = [];
  public videoYoutubePublic: any = [];
  public publicsessionData: any = [];
  public publicsessionDataRecord: any = [];
  public requestedDataList: any = [];
  public requestedDataAccpet: any = [];
  public requestedDataAccpetRecord: any = [];
  public teacherPayoutDetails: any = [];
  public mydashboardData: any = [];
  public isStudent: boolean = false;
  public isTeacher: boolean = false;
  public currentPageNumber : number = 1;
  public totalVideoCount: number;
  public totalPageCount: number;
  public currentVideoCount: number;
  term: string;
  public webinarData: any = [];
  isDesc: any = 1;
  column: string = 'remainingClass';
  p: number = 1;
  count: number = 4;
  teacherDetails = {};
  public categoryID = null;
  public moduleID = null;
  public courseID = null;
  public topicID = null;
  constructor( private SpinnerService: NgxSpinnerService, private rest: ApiService) { }

  ngOnInit(): void {
    const userType = localStorage.getItem('userType');      
    if(userType.toLowerCase()=="teacher") {
      this.isteacher = true;
    }
    else {
      this.isstudent = true;
    }
  }

  getSmartMaterialWithoutLogin() {
    
    this.isLogin = localStorage.getItem('isLoggedin');
    this.SpinnerService.show();
    var request = {};
    if(this.topicID){
      request = {
        topicID: this.topicID,
        moduleID: this.moduleID, 
        courseID: this.courseID,
        categoryID: this.categoryID
      }
    }
    request['limit'] = environment.listing__itemsPerPage;
    request['page'] = this.currentPageNumber;
    if(this.isLogin && this.isTeacher){
      request['webToken'] = localStorage.getItem('webtoken');
    }
    this.rest.NewSmartMaterialListingWithoutLogin(request).subscribe((response) => {
      console.log('response', response)
      
      if (response.status && response.data != null) {
        this.videoList = response.data.data;
        console.log(this.videoList, 'this.webinarData')
        this.totalVideoCount = response.data.totalCount;
        this.totalPageCount = Math.ceil(this.totalVideoCount / environment.listing__itemsPerPage)
        this.currentVideoCount = ((this.currentPageNumber - 1) * environment.listing__itemsPerPage)
        this.teacherDetails = response.data.teacherDetails;
        // console.log(this.videoList)
        for(var roundTable of this.videoList){
          roundTable.startDateTime = new Date( roundTable.startDateTime).toLocaleString()
          if(!roundTable.thumbnail){
            roundTable.thumbnail = 'assets/img/courses/courses1.jpg'
          }
        }
      } else {
        this.mydashboardData = [];
      }
      this.SpinnerService.hide()
    }, (err) => {
      console.log(err);
    });
  }
  clickPageNumber(pageNumber) {
    this.currentPageNumber = pageNumber;
    console.log(this.currentPageNumber)
    // if (this.isLogin == 'true' && this.isTeacher) {
    //   this.getSmartMaterialList();
    // }
    // else if (this.isLogin == 'true' && this.isStudent) {
    //   this.getSmartMaterialWithoutLogin();
    // }

    // else {
      this.getSmartMaterialWithoutLogin();
    //}
  }

}
