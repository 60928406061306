import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../../shared/api.service";
import { Router, ActivatedRoute } from "@angular/router";
import { DatePipe } from '@angular/common';
import { environment } from "../../../../environments/environment";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrManager } from "ng6-toastr-notifications";
import { CommonTextColorDirective } from '../../custom-directive/common-text-color.directive';
import { Location } from '@angular/common';
const datepipe: DatePipe = new DatePipe('en-US')


@Component({
  selector: 'app-upcoming-live-class',
  templateUrl: './upcoming-live-class.component.html',
  styleUrls: ['./upcoming-live-class.component.scss']
})
export class UpcomingLiveClassComponent implements OnInit {

  public materialID: any = ''
  public isStudent = false;
  public isTeacher = false;
  public classList = [];
  public classroomList = [];
  public RoundList = [];
  public videoList = [];
  public testList = [];
  public smartMaterialList = [];
  public currentLiveClassPageNumber = 1;
  public currentLiveClassFilter = 'none';
  public currentStudyClassFilter = 'none';
  public currentRoundClassFilter = 'none';
  public currentPageNumber: number = 1;
  public totalLiveClassPageCount: number = 1;
  public livedetails: any = [];
  isLoggedin = "false";
  public payment;
  public purchase;
  public teacherID;
  public categoryarrayvalues;
  formData;
  amount: any;
  materialname: any;
  isItemAddedToCart: boolean = false;



  constructor(private rest: ApiService, 
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private SpinnerService: NgxSpinnerService,
    public toastr: ToastrManager,
    private location: Location,
    
    ) { }

  ngOnInit() {
    var request = {};
    this.isLoggedin = localStorage.getItem('isLoggedin');
    this.teacherID = localStorage.getItem('teacherID')
    this.materialID = this.activatedRoute.snapshot.params.id

    const token = localStorage.getItem("webtoken");
    this.isTeacher =
        localStorage.getItem("profileType").toLowerCase() == "teacher";
    this.isStudent =
        localStorage.getItem("profileType").toLowerCase() == "student";
        var reqData = {
          notFetchCompleted: true,
          limit: 5
      };
      this.fetchLiveClasses(reqData);
  }

  LiveClassDetails(data) {
    if (this.isTeacher) {
      
        this.router.navigate(["live-class-details/" + data._id]);
    } else {
      console.log('fdfdf')
        this.router.navigate(["live-class-details/" + data._id]);
    }
}
backto() {
    this.location.back()
  }
  liveClassFunction(liveDetails, action) {

    if (action == "start" || action == "join") {
      console.log(action);
        this.rest
            .meetingActionLiveClass(
                localStorage.getItem("webtoken"),
                liveDetails._id,
                action,
                "liveClass"
            )
            .subscribe(
                (result) => {
                  console.log(result);
                    this.SpinnerService.hide();
                    if (result.status) {
                        this.SpinnerService.show();
                        var reqData = {
                            courseID: liveDetails.personalClassID.courseID,
                            topicID: liveDetails.personalClassID.topicID._id,
                            methodOfLearning: "videoclass",
                        };
                        console.log(reqData);
                        this.rest
                            .addCourseCompletion(
                                localStorage.getItem("webtoken"),
                                reqData
                            )
                            .subscribe((response) => {
                                console.log(response);
                                setTimeout(function () {
                                    location.href = result.data[0];
                                }, 1000);
                                this.SpinnerService.hide();
                            });
                    } else {
                        if (result.message == "Meeting Not Started.") {
                            var datajson = {
                                date: liveDetails.startDateTime,
                            };
                            this.router.navigate(["/meetingnotstart"], {
                                state: datajson,
                            });
                        } else {
                            this.toastr.errorToastr(result.message);
                        }
                    }
                },
                (err) => {
                    console.log(err);
                }
            );
    } else if(action=="reschedule"){
        this.toastr.errorToastr("Coming soon...")
        console.log('*******')
    } else if(action=="cancel"){
        this.toastr.errorToastr("Coming soon...")
        console.log('####')
    } else if (action == "share") {
        var isMobile = false; //initiate as false

        let frontwebsites = environment.frontUrl;
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
                navigator.userAgent
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                navigator.userAgent.substr(0, 4)
            )
        ) {
            isMobile = true;
        }
        var textToSend = "Hi! I am forwarding a class from buddi.life. Please check it out here. https://www.buddi.life/live-class-details/" + liveDetails._id;
        var encodedUrl = encodeURIComponent(textToSend);
        if (isMobile) {
            window.open(
                "https://api.whatsapp.com/send?text=" + encodedUrl
            );
        } else {
            window.open(
                "https://web.whatsapp.com/send?text=" + encodedUrl
            );
        }
    }
    else if(action == "accept") {
      this.acceptLiveClassRequest(liveDetails)
    }
    else if(action == "reject"){
      this.rejectLiveClassRequest(liveDetails)
    }

}

liveClassFilter(filter) {
    if(this.currentLiveClassFilter == filter){

    }
    else{
        this.currentLiveClassFilter = filter;
        this.currentPageNumber = 1;
        var reqData = {
            notFetchCompleted: true,
            filter: filter,
            limit: 5
        };
        console.log(reqData);
        this.fetchLiveClasses(reqData);
        console.log('reqData,=========',reqData)
    }
    
}
fetchLiveClasses(reqData){
    console.log("Temp Req Data", reqData)
    const token =  localStorage.getItem("webtoken");
    if (this.isTeacher) {
        this.rest
            .getUserPersonalClassRequestsTeacher(token, reqData)
            .subscribe((result) => {
                console.log("result===============",result);
                // console.log(result)
                if (result.status) {
                    this.classList = result.data && result.data.data ? result.data.data.upcoming : [];
                    this.totalLiveClassPageCount = Math.ceil(result.data.totalCount / 5)
                    for (var item of this.classList) {
                        if (item.startDateTime) {
                            item.startDateTime = datepipe.transform(
                                item.startDateTime,
                                "MMM d, y, h:mm a"
                            );
                        }
                    }
                }
            });
    } else {
        this.rest
            .getUserPersonalClassRequestsStudent(token, reqData)
            .subscribe((result) => {
                console.log("result");
                console.log(result)
                if (result.status) {
                    this.classList =  result.data && result.data.data ? result.data.data.upcoming : [];
                    this.totalLiveClassPageCount = Math.ceil(result.data.totalCount / 5)
                    for (var item of this.classList) {
                        if (item.startDateTime) {
                            item.startDateTime = datepipe.transform(
                                item.startDateTime,
                                "MMM d, y, h:mm a"
                            );
                        }
                    }
                }
            });
    }
}

addToCourseCompletion(){
  console.log("Test");
  var reqData = {
    courseID: this.livedetails.courseID._id,
    topicID: this.livedetails.topicID._id,
    methodOfLearning: "studymaterial"
  };
    console.log('reqData==========click to view',reqData);
    this.rest.addCourseCompletion(localStorage.getItem('webtoken'), reqData).subscribe((response) => {
      console.log(response);
     });
}
isMaterialAddedToCart() {
  let tempCart = JSON.parse(localStorage.getItem('cartItems') || '[]');
  for (var item of tempCart) {
    console.log(item);
    if (item.itemID == this.materialID && item.itemType == "SmartMaterial") {
      this.isItemAddedToCart = true;
    }
  }
}
getstudyDetails() {
  const webToken = localStorage.getItem('webtoken');
  this.rest.getStudyDetails(webToken, { materialID: this.materialID }).subscribe((response) => {
    console.log('dfdfdfdf', response)
    if (response.status) {

      this.livedetails = response.data;
      this.amount = response.data.price;
      this.materialname = response.data.materialName;
      this.purchase = response.data.isMaterialPurchased
      console.log('final', this.livedetails)
      let categoryarray: any = [];
      let gradearray: any = [];
      let subjectarray: any = [];
      if (this.livedetails.categoryID.length > 0) {
        for (var i = 0; i < this.livedetails.categoryID.length; i++) {
          categoryarray.push(this.livedetails.categoryID[i].categoryName);
        }
        this.categoryarrayvalues = categoryarray.toString();
      }

      // if (this.livedetails.subjectID.length > 0) {
      //   for (var i = 0; i < this.livedetails.subjectID.length; i++) {
      //     subjectarray.push(this.livedetails.subjectID[i].subjectName);
      //   }
      //   this.subjectarrayvalues = subjectarray.toString();
      // }

      // if (this.livedetails.gradeID.length > 0) {
      //   for (var i = 0; i < this.livedetails.gradeID.length; i++) {
      //     gradearray.push(this.livedetails.gradeID[i].gradeName);
      //   }
      //   this.gradearrayvalues = gradearray.toString();
      // }
      // if(this.livedetails.length!=0 && this.livedetails.tag !=''){
      //   this.tagArray = this.livedetails.tag.split(',')
      // }
      // this.reviewGet(this.livedetails._id);
    }
  });
}
clickLiveClassPageNumber(pageNumber) {
    this.currentLiveClassPageNumber = pageNumber;
    console.log(this.currentLiveClassPageNumber);
    var reqData = {
        notFetchCompleted: true,
        filter: this.currentLiveClassFilter,
        limit: 5,
        page: pageNumber
    };
    this.fetchLiveClasses(reqData)
  }

  acceptLiveClassRequest(classID) {
      console.log("$$$$$");
    this.SpinnerService.show();
    const webToken = localStorage.getItem("webtoken");
    var request = {};
    request["classID"] = classID._id;
    request["isAccepted"] = true;
    this.rest
        .personalClassRequestAction(webToken, request)
        .subscribe((result) => {
            console.log(result);
            if (result["status"]) {
                this.SpinnerService.hide();
                this.toastr.successToastr("Successfully accepted class");
                location.reload()
            } else {
                this.SpinnerService.hide();
                this.toastr.errorToastr(
                    "Something went wrong. Please try again later"
                );
                console.log(result.error);
            }
        });
  }
  rejectLiveClassRequest(classID) {
    this.SpinnerService.show();
    const webToken = localStorage.getItem("webtoken");
    var request = {};
    request["classID"] = classID._id;
    request["isAccepted"] = false;
    this.rest
        .personalClassRequestAction(webToken, request)
        .subscribe((result) => {
            console.log(result);
            if (result["status"]) {
                this.SpinnerService.hide();
                this.toastr.successToastr("Successfully rejected class");
                location.reload()
            } else {
                this.SpinnerService.hide();
                this.toastr.errorToastr(
                    "Something went wrong. Please try again later"
                );
                console.log(result.error);
            }
        });
  }
}


