<div *ngIf="isStudent">
    <app-header-style-one></app-header-style-one>
</div>
<div *ngIf="isTeacher">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>
<div class="container vtuy" routerLink="/sidenav">
	<h2 style="color:#000000;">Currency Wallet</h2>
	<button class="mob"><i class="fas fa-long-arrow-alt-left mr-1"></i>Back</button>
</div>
<div class="container" style="margin-top: 1%;margin-bottom:1%;">	
	<div class="mb-3 mb-2 jus">
	<img src="../../../../assets/img/wallet.png" style="height: 35px;border: 1px solid #ccc;border-radius: 25px;" alt="">
	<div class="ml-2" style="color:#00adee;">
		<h6>Balance :<i class="fa fa-rupee" style="margin: 0;font-size: 11px;"></i>{{walletBalance}}</h6>
	</div>
</div>
</div>
<div class="container" style="margin-bottom:1%; display: flex;">
	
		<div class="col-lg-8 col-md-6" >
					<div class="orderSummary">
						<h3>Add Money to Wallet</h3>
						<p>Choose a Payment Method</p>
						<div class="custom-control custom-radio custom-control-inline mt-1" (click)="radioOnClick('Razorpay')">
							<input  type="radio" id="isClass" name="classTemp" class="custom-control-input" checked="checked">
							<label class="custom-control-label" for="isClass"><i class="bi bi-bank"></i>Pay Using Razorpay ( 2% Processing Fees)</label>
							</div>
						<div class="custom-control custom-radio custom-control-inline mt-3" (click)="radioOnClick('UPI')">
							<input  type="radio" id="isNotClass" name="classTemp" class="custom-control-input">
							<label class="custom-control-label" for="isNotClass"> <img src="../../../../assets/img/upi.webp" style="border:none;border-radius:0;height: 10px;margin: 0 6px;" alt="">Pay Using UPI ID ( 0% Fees) <label class="ml-1 mr-2" style="border: 1px solid #ccc;padding: 4px;" *ngIf="methodOfPayment=='UPI'">9940579076@okbizaxis</label></label>
							<a href=""data-toggle="modal" data-target="#qr-code" *ngIf="methodOfPayment=='UPI'"> (Scan QR Code)</a>
						</div>
						<form [formGroup]="createForm" class="form">
						<div class="form-group" *ngIf="methodOfPayment=='UPI'">
							<label>Transaction ID: <p>(* Note: Once Payment Successful done via UPI, Enter the UPI Transaction ID and the amount below)</p> </label>
							<input type="text" name="" id="TransactionID" class="form-control" autocomplete="off" formControlName="TransactionID" placeholder="Enter a TransactionID:" />
						</div> 
						<div class="form-group" *ngIf="methodOfPayment=='UPI'">
							<label>Amount:</label>
							<input type="text" id="upiRechargeAmount" name="rechargeAmount" formControlName="upiamount" (input)="handleInputUPI($event)" autocomplete="off" placeholder="Enter the amount here..."  class="form-control" />
							<button class="submit" (click)="submit1()">Submit</button>
						</div> 
					</form>
					</div>

		</div>
			<div class="modal fade" id="qr-code" role="dialog">
				<div class="modal-dialog">
				  <div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">Scan QR Code</h4>
						<button type="button" style="height:25px;font-size: 21px;width: 10%;" class="close" data-dismiss="modal">&times;</button>
					</div>
					<div class="modal-body">    
						<img src="../../../../assets/img/qr.jpeg" alt="">
				    </div>
				  </div> 
				</div>
			  </div>
			  <div class="col-lg-4 col-md-6" *ngIf="methodOfPayment=='UPI'">
				<div class="Summary">
					<h4 class="text-center mb-3">Scan QR Code</h4>
					<div class="">    
						<img src="../../../../assets/img/qr.jpeg" alt="">
					</div>
				</div>
			  </div>
			<div class="col-lg-4 col-md-6"  *ngIf="methodOfPayment=='Razorpay'">
				<div class="orderSummary">
					<h2 class="mob1">Payment Details</h2>
					<ul>
						<li>
							<form [formGroup]="addmoney">
							<div class="form-group group1" >
								<label>Wallet Top-up :</label>
								<div class="sub">
									<input type="text" id="rechargeAmount" name="rechargeAmount" formControlName="rechargeAmount" (input)="handleInput($event)" autocomplete="off" placeholder="Enter the amount here..."  class="form-control cls" />
								</div>
							</div>
						</form>
						</li>
						<li>
							<label>Payment Processing fee (2%):</label>
							<span>₹{{Percent}}</span>
						</li>
						<li class="totalList">
							<label>Total Amount:</label>
							<span>₹{{totalamount}}</span>
						</li>
					</ul>
					<button style="width: 40%;margin-left: 30%;" (click)="submit()">Pay now</button>
					<!-- <p>Buddi is required by law to collect applicable transaction taxes for purchases made in certain tax jurisdictions.</p> -->
				</div>
			</div>
			<!-- <div class="col-md-4"  *ngIf="upi">
				<div class="orderSummary">
					<h2>Summary</h2>
					<ul>
						<li>
							<div class="form-group1" >
								<label>Top-up Amount:</label>
								<div>
									<input type="text" id="rechargeAmount" name="rechargeAmount" class="form-control" style="margin-left: 73%;width: 50%;"/>
								</div>
							</div>
						</li>
						
						<li>
							<div class="form-group1">
								<label>Transaction i'd:</label>
								<input type="text" name="" class="form-control" style="margin-left: 7%;width: 63%;" placeholder="Enter a Transaction i'd:" />
							</div> 
						</li>
					</ul>
					<button style="width: 40%;margin-left: 30%;">Submit</button>
				</div>
			</div> -->
