<div class="partner-slides owl-carousel owl-theme">
    <div class="single-partner-item">
        <a href="#" target="_blank" class="d-block">
            <img src="../../../../assets/img/buddi partners_1.jpeg" alt="buddi partners_1">
        </a>
    </div>

    <div class="single-partner-item">
        <a href="#" target="_blank" class="d-block">
            <img src="../../../../assets/img/buddi partners_2.png" alt="buddi partners_2">
        </a>
    </div>

    <div class="single-partner-item">
        <a href="#" target="_blank" class="d-block">
            <img src="../../../../assets/img/buddi partners_3.png" alt="buddi partners_3">
        </a>
    </div>

    <div class="single-partner-item">
        <a href="#" target="_blank" class="d-block">
            <img src="../../../../assets/img/buddi partners_4.jpeg"alt="buddi partners_4">
        </a>
    </div>

    <div class="single-partner-item">
        <a href="#" target="_blank" class="d-block">
            <img src="../../../../assets/img/buddi partners_5.jpeg" alt="buddi partners_5">
        </a>
    </div>

    <div class="single-partner-item">
        <a href="#" target="_blank" class="d-block">
            <img src="../../../../assets/img/buddi partners_6.jpeg" alt="buddi partners_6">
        </a>
    </div>

    <div class="single-partner-item">
        <a href="#" target="_blank" class="d-block">
            <img src="../../../../assets/img/buddi partners_7.jpeg" alt="buddi partners_7">
        </a>
    </div>
</div>