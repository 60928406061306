<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about/about1.jpg" class="shadow" alt="image">
                    <img src="assets/img/about/about2.jpg" class="shadow" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">About Us</span>
                    <h2>Learn New Skills to go ahead for Your Career</h2>
                    <h6>We can support student forum 24/7 for national and international students.</h6>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea commodo consequat.</p>
                    <div class="features-text">
                        <h5><i class='bx bx-planet'></i>A place where you can achieve</h5>
                        <p>Education encompasses both the teaching and learning of knowledge, proper conduct, and technical competency.</p>
                    </div>
                    <a routerLink="/courses-2-columns-style-1" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>