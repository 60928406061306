import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-buddi-unique-model',
  templateUrl: './buddi-unique-model.component.html',
  styleUrls: ['./buddi-unique-model.component.scss']
})
export class BuddiUniqueModelComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
