import { Component, OnInit,Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexYAxis,
  ApexStroke,
  ApexTooltip,
  ApexDataLabels,
  ApexPlotOptions,
  ApexResponsive,
  ApexLegend,
  ApexFill,
} from 'ng-apexcharts';
declare var $: any;


export type barChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  responsive: ApexResponsive[];
  xaxis: ApexXAxis;
  legend: ApexLegend;
  fill: ApexFill;
};
@Component({
  selector: 'app-subject-nav',
  templateUrl: './subject-nav.component.html',
  styleUrls: ['./subject-nav.component.scss']
})
export class SubjectNavComponent implements OnInit {

  public iscourses = false;
  public profiletype = "";

  @ViewChild('chart') chart: ChartComponent;
  public barChartOptions: Partial<barChartOptions>;
  // public areaChartOptions: Partial<areaChartOptions>;
  constructor(  private router: Router) {}

  // Doughnut chart start
  public doughnutChartLabels: string[] = [
    'Development',
    'Java Classes',
    'Painting ',
    'Geography Class',
  ];
  public doughnutChartData: number[] = [32, 25, 20, 23];
  public doughnutChartColors: any[] = [
    {
      backgroundColor: ['#5A5FAF', '#F7BF31', '#EA6E6C', '#28BDB8'],
    },
  ];

  public doughnutChartType = 'doughnut';
  public doughnutChartOptions: any = {
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 70,
    legend: {
      display: false,
    },
  };
  public dashboardData:any = [];

  @Input() data:any =[] ;
  @Input() emitData:any = [];
  ngOnInit() {

    // $('.nav-link').on('click', '.nav-link.active', function() {
    //   var href = $(this).attr('href').substring(1);
    //   //alert(href);
    //   $(this).removeClass('active');
    //   $('.tab-pane[id="' + href + '"]').removeClass('active');
      
    // });
    $('.customLinkParent').on('click', function(){
      $('#collapseExample a.active').removeClass('active');
      
  });

  //   $(document).mouseup(function(e) {
  //     var container = $("#tablist"); // target ID or class
  //     // if the target of the click isn't the container nor a descendant of the container
  //     if (!container.is(e.target) && container.has(e.target).length === 0) {
  //         // get Event here
  //         $('.active').removeClass('active');
  //     }
  // });
    this.profiletype = localStorage.getItem('profileType').toLowerCase();
    this.chart2();
  

  }

  up(){
  this.router.navigateByUrl("/sidenav/upcoming");
  }

  private chart2() {
    this.barChartOptions = {
      series: [
        {
          name: 'Physics',
          data: [44, 55, 41, 67, 22, 43],
        },
        {
          name: 'Computer',
          data: [13, 23, 20, 8, 13, 27],
        },
        {
          name: 'Management',
          data: [11, 17, 15, 15, 21, 14],
        },
        {
          name: 'Mathes',
          data: [21, 7, 25, 13, 22, 8],
        },
      ],
      chart: {
        type: 'bar',
        height: 330,
        foreColor: '#9aa0ac',
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '20%',
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        type: 'category',
        categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      },
      legend: {
        show: false,
      },
      fill: {
        opacity: 1,
        colors: ['#25B9C1', '#4B4BCB', '#EA9022', '#9E9E9E'],
      },
    };
  }

}
