import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thanks-student-registration',
  templateUrl: './thanks-student-registration.component.html',
  styleUrls: ['./thanks-student-registration.component.scss']
})
export class ThanksStudentRegistrationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
