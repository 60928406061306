
<div class="testimonials-slides owl-carousel owl-theme">
    <div class="single-testimonials-item">
        <p>buddi. life was a very useful class for me, I can interact with tutors very easily and clear my doubts. buddi made me possible to understand concepts clearly and make it easy to learn.</p>
        <div class="info">
            <img src="../../../../../assets/img/student/gideon.jpg" alt="gideon_Student" class="shadow rounded-circle" alt="image">
            <h3>GIDEON</h3>
            <span>GRADE: 12</span>
            <span>BOARD: MATRICULATION</span>
            <!-- <span>Student</span> -->
        </div>
    </div>

    <div class="single-testimonials-item">
        <p>I learned awesome tricks from tutor.  Thanks for this opportunity joining buddi.life</p>
        <div class="info">
            <img src="../../../../../assets/img/student/vikram.jpg" alt="vikram_Student" class="shadow rounded-circle" alt="image">
            <h3>VIKRAM</h3>
            <span>GRADE: 10</span>
            <span>BOARD: STATE BOARD</span>
            <!-- <span>Student</span> -->
        </div>
    </div>

    <div class="single-testimonials-item">
        <p>Teacher made the concepts very simple and easy to learn. I understand very easily. I love buddi.life.</p>
        <div class="info">
            <img src="../../../../../assets/img/student/Bhavana.jpg" alt="Bhavana_Student" class="shadow rounded-circle" alt="image">
            <h3>BHAVANA</h3>
            <span>GRADE: 6</span>
            <span>BOARD: STATE BOARD</span>
            <!-- <span>Student</span> -->
        </div>
    </div>

    
    <div class="single-testimonials-item">
        <p>When I first step into buddi.life, I saw lots of options like coding, JEE. When I attend my first class, I saw it was so amazing and there were many things to learn. It's just my third class and everything i needed to know. They teach you practically and it is so much fun!</p>
        <div class="info">
            <img src="../../../../../assets/img/student/sanjit.jpg" alt="sanjit_Student" class="shadow rounded-circle" alt="image">
            <h3>SANJIT</h3>
            <span>GRADE: 4</span>
            <span>BOARD: CBSE</span>
            <!-- <span>Student</span> -->
        </div>
    </div>

  
</div>