<section class="boxes-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-boxes-item">
                    <h3>Teach From Anywhere</h3>
                    <p> K-12, NEET, JEE, Coding</p>
                   <!-- <a routerLink="/courses-2-columns-style-1" class="boxes-btn">View Courses<i class='bx bx-plus'></i></a>-->
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-boxes-item bg-image">
                    <h3>Join our fast growing network of tutors</h3>
                    <!-- <p>Just your teaching skills are enough</p> -->
                   <!-- <a routerLink="/courses-2-columns-style-2" class="boxes-btn">View More<i class='bx bx-plus'></i></a>-->
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-boxes-item bg-color">
                    <h3>No Investment needed from your side</h3>
                    <p>Just your teaching skills are enough</p>
                  <!--  <a routerLink="/courses-3-columns-style-1" class="boxes-btn">View Courses<i class='bx bx-plus'></i></a>-->
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-boxes-item">
                    <h3>Flexible working hours</h3>
                    <p>Teach from the comfort of your home</p>
                  <!--  <a routerLink="/courses-4-columns-style-1" class="boxes-btn">View More<i class='bx bx-plus'></i></a>-->
                </div>
            </div>
        </div>
    </div>
</section>