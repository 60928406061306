import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-buddi-rewards',
  templateUrl: './buddi-rewards.component.html',
  styleUrls: ['./buddi-rewards.component.scss']
})
export class BuddiRewardsComponent implements OnInit {
  public isStudent = false;
  public isTeacher = false;
  constructor() { }

  ngOnInit(): void {
    this.isTeacher =
    localStorage.getItem("profileType").toLowerCase() == "teacher";
this.isStudent =
    localStorage.getItem("profileType").toLowerCase() == "student";
  }


}
