import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-grp-user-create-student',
  templateUrl: './grp-user-create-student.component.html',
  styleUrls: ['./grp-user-create-student.component.scss']
})
export class GrpUserCreateStudentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
