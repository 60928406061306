<div class="container">
    <div class="section-title">
        <h2>Get Started Now</h2>
        </div>
    <div class="row ">
        <div class="col-md-6 learner" >
        <div class="single-instructor-box" >
            <div class="image">
                <img src="../../../../../assets/img/Student-Login-image.png" alt="Student-Login-image" style="cursor: pointer;" title="Learner login" (click)="studentclick()" 
                    onerror="this.src='../../../../../assets/img/for-Student.png';" alt="image" />
    
                    <div class="content">
                        <!-- <h3><a routerLink="/student/login" style="cursor: pointer;" title="click"> For Learner </a></h3> -->
                       
                       <h3><a (click)="studentclick()"  href="javascript:void(0)" style="cursor: pointer;" title="Learner login">I'm a Learner</a></h3>
                       
                    </div>
            </div>
        </div>
        </div>
        <div class="col-md-6 learner">
            <div class="single-instructor-box">
                <div class="image">
                    <img src="../../../../assets/img/Metaverse tutor-login-image.jpeg" alt="Metaverse tutor-login-image" style="cursor: pointer;" title="Tutor login"  (click)="teacherclick()" 
                        onerror="this.src='../../../../../assets/img/Teacher-Login-image.png';" alt="image" />
                        <div class="content">
                            <!-- <h3><a routerLink="/teacher/login" style="cursor: pointer;" title="click">For Tutor</a></h3> -->
                            <h3><a (click)="teacherclick()" href="javascript:void(0)" style="cursor: pointer;" title="Tutor login">I'm a Tutor</a></h3>
                         
                        </div>
                  
                </div>
        
               
            </div>
        </div>
    </div>
    

        
    
    
  
    <!-- <app-student-instructor></app-student-instructor> -->
</div>
