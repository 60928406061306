import { Component, OnInit,Input} from '@angular/core';

@Component({
  selector: 'gbutton',
  templateUrl: './global-button.component.html',
  styleUrls: ['./global-button.component.scss']
})
export class GlobalButtonComponent implements OnInit {
  @Input() textLabel:String = ''
  constructor() { }

  ngOnInit(): void {
  }

}
