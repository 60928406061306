import { Component, OnInit ,Output,EventEmitter} from '@angular/core';
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import * as moment from 'moment';
import { Location } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { MediaService } from '../../../shared/media.service';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import { ProductexpiredPipe } from '../../custom-pipes/productexpired.pipe';
import { EmptyObjectPipe } from '../../custom-pipes/empty-object.pipe';
import { NgxSpinnerService } from "ngx-spinner";
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  providers: [ProductexpiredPipe, EmptyObjectPipe]
})
export class SidenavComponent implements OnInit {

  public data: any = [];
  public userprofdata: any = []; 
  public userprofdataTeacher: any = [];
  id: any;
  dashboard: FormGroup;
  public dashboardData = [];
  public profiletype = "";
  @Output() dropdownData = new EventEmitter();
  public emitData:any = [];
  constructor(private activatedRoute: ActivatedRoute, private SpinnerService: NgxSpinnerService, private productexpired: ProductexpiredPipe, private api: MediaService, private router: Router, private userApi: ApiService, private location: Location, private formBuilder: FormBuilder, public toastr: ToastrManager, private http: HttpClient) { }

  ngOnInit(): void {
 
    this.profiletype = localStorage.getItem('profileType').toLowerCase();
    this.getStudentDetails();
    this.getTeacherdetails();
    this.dashboard = this.formBuilder.group({
      categoryID: [''],
      languageID: [''],
      boardID: [''],
      subjectID: [''],
      gradeID: [''],
    });
    const webToken = localStorage.getItem('webtoken');

    if(webToken != null){
      this.SpinnerService.show()
      this.userApi.userName_DisplayStudent(webToken).subscribe((result) => {
       this.SpinnerService.hide()
        this.id = result.data.studentDetails._id;
        var localData={
          boardID :Object.keys(result.data.studentDetails.board).length!=0 ?  result.data.studentDetails.board._id : 'undefined',
          subjectID :Object.keys(result.data.studentDetails.subjects).length!=0 ?  result.data.studentDetails.subjects._id : 'undefined',
          categoryID :Object.keys(result.data.studentDetails.categories).length!=0 ?  result.data.studentDetails.categories._id : 'undefined',
          gradeID  :Object.keys(result.data.studentDetails.grade).length!=0 ? result.data.studentDetails.grade._id : 'undefined',
          
        }
        localStorage.setItem('educationInfo',JSON.stringify(localData));
        localStorage.setItem('groupName',result.data.studentDetails.groupName);
        this.userprofdata = result.data.studentDetails;
        console.log(this.userprofdata,'userDetails')
        const userId = { id: this.id };
      }, (err) => {
        console.log(err);
      });
    }
  }
  
  get f() { return this.dashboard.controls; }
 
  up(){
    this.router.navigateByUrl("/sidenav/upcoming");
  }
  copyToClipboard(element) {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val($(element).text()).select();
    document.execCommand("copy");
    $temp.remove();
    this.toastr.successToastr('Referal Code Copied to Clipboard!');
  }
  getStudentDetails() {
    const webToken = localStorage.getItem('webtoken');
    if (webToken != null) {
      this.userApi.userName_DisplayStudent(webToken).subscribe((result) => {
        console.log('resultDetails in sideNav',result)
        if (result.status) {
          this.data = result.data;
          //  this.data.studentDetails.board = null;
          var stateParam = {
            pageName: 'dashboard'
          }
          // || Object.keys(this.data.studentDetails.language).length == 0

          //commented by Monish on 14/11/2021
          // if (Object.keys(this.data.studentDetails.board).length == 0  || Object.keys(this.data.studentDetails.subjects).length == 0 || Object.keys(this.data.studentDetails.grade).length == 0) {
          //   this.router.navigate(['/student/edit-profile'], { state: stateParam });

          // }

          console.log(this.data, 'this.data');
          this.dashboard.patchValue({
            categoryID: this.data.categoryList[0]._id,
            languageID: this.data.languages[0],
            boardID: this.data.studentDetails.board._id,
            gradeID: this.data.studentDetails.grade._id,
            subjectID: this.data.studentDetails.subjects._id,
          })
           var req= {
              "categoryID": "5f12a2efbf9506355c8adb5c",
              "gradeID": "5f6864c40a19fd6e912fbf9f",
              "boardID": "5f43aa8c0c46160c254a05d3",
              "subjectID": "5f4d0b937f22ab6769601c97",
              "languageID": "5f43aa990c46160c254a05d4"
         }
         this.getChapterDetails(req)
        }

      });
    }
  }
  getTeacherdetails(){
    const webToken = localStorage.getItem('webtoken');
    this.userApi.userName_DisplayTeacher(webToken).subscribe((result) => {
      console.log(result.data);
      // this.groupName = result.data.groupName
      this.userprofdataTeacher = result.data;
    }, (err) => {
    console.log(err);
    });
  }
  changeCategory(event) {
    this.dashboard.patchValue({
      categoryID: this.data.categoryList[event.target.selectedIndex]._id,     
    })
    console.log(this.dashboard.value);
    this.emitData = {
      categoryID: this.dashboard.value.categoryID,
      languageID: this.dashboard.value.languageID._id,
      boardID: this.dashboard.value.boardID,
      gradeID: this.dashboard.value.gradeID,
      subjectID: this.dashboard.value.subjectID,
    }
    // this.userApi.dropdownData =  this.emitData;

    this.getChapterDetails(this.emitData)
    // this.dropdownData.emit(this.emitData);
  }




    getChapterDetails(req){
  const webToken = localStorage.getItem('webtoken');
  if (webToken != null) {
     
        this.userApi.getChapterDetails(req,webToken).subscribe((response) => {
          // if(response.status){
            console.log('responseGetChapter',response)
            var responseData= response;
            this.userApi.customChangeDetector.next(responseData);
          // }
        });
     }
 }

 getAllDetails(){
  
  // this.userApi.getChapterDetails().subscribe( this.getChapterDetails = response)
              
 }

  receivedData(event){
    // this.dropdownData.emit(this.emitData);
  }
  logout(){
    localStorage.setItem('isLoggedin','false');
     window.location.href = localStorage.getItem('profileType').toLowerCase()+'/login';
  }

  invite() {
    let teacherID = localStorage.getItem('teacherID')
    if(teacherID){
      var isMobile = false; //initiate as false
      if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
      || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4)))
      { 
      isMobile = true;
        }
        var textToSend = "Hi! Iam inviting you to join buddi.life - E-learning Digital Platform . Please Click the Below link for Signup";
        var encodedUrl = encodeURIComponent(textToSend);
        if(isMobile){
          
          window.open( "https://api.whatsapp.com/send?text= Hi! Iam inviting you to join buddi.life - E-learning Digital Platform. Please Click the Below link for Signup. https://www.buddi.life/student/register?referralId=" + teacherID)
        }
        else{
          window.open( "https://web.whatsapp.com/send?text= Hi! Iam inviting you to join buddi.life - E-learning Digital Platform. Please Click the Below link for Signup. https://www.buddi.life/student/register?referralId=" + teacherID)
  
        }
      }

  }
  invite1() {
    let teacherID = localStorage.getItem('teacherID')
    if(teacherID){
      var isMobile = false; //initiate as false
      if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
      || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4)))
      { 
      isMobile = true;
        }
      var textToSend = "Hi! Iam inviting you to join buddi.life - E-learning Digital Platform . Please Click the Below link for Signup";
      var encodedUrl = encodeURIComponent(textToSend);
      if(isMobile){
        
        window.open( "https://api.telegram.org/send?text= Hi! Iam inviting you to join buddi.life - E-learning Digital Platform. Please Click the Below link for Signup. https://www.buddi.life/student/register?referralId=" + teacherID)
      }
      else{
        window.open( "https://telegram.me/share/url?url= Hi! Iam inviting you to join buddi.life - E-learning Digital Platform. Please Click the Below link for Signup. https://www.buddi.life/student/register?referralId=" + teacherID)

      }
    }

  }
}
