import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vr',
  templateUrl: './vr.component.html',
  styleUrls: ['./vr.component.scss']
})
export class VRComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  	
  }

}
