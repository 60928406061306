<app-header-style-one *ngIf="!isTeacher"></app-header-style-one>
    <app-header-style-one-teacher
        *ngIf="isTeacher"
    ></app-header-style-one-teacher>
<div class="data">
  <div class="cards">
<div class="arrange"><p>What are you looking to do on buddi.life?</p></div>
<div class="container">
<div class="row">

    <div class="col-lg-4 col-md-6">
        <div class="card" style="width: 18rem;">
            <img src="../../../../assets/img/Liveclass.jpeg" alt="Liveclass" routerLink="/liveClassList" class="card-img-top" alt="...">
            <div class="card-body">
              <h5 routerLink="/liveClassList" class="card-title">Live Classes <br> <p>( 1:1 )</p></h5>
              <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> -->
              <a routerLink="/liveClassList">Explore   <i class="fas fa-arrow-right"></i></a>
            </div>
            
          </div>
    </div>

    <div class="col-lg-4 col-md-6">
        <div class="card" style="width: 18rem; ">
            <img src="../../../../assets/img/Studyhall.jpeg" alt="Studyhall" routerLink="/studyhallList" class="card-img-top" alt="...">
            <div class="card-body">
              <h5 routerLink="/studyhallList" class="card-title">Study Hall<br> <p>(Group Class)</p></h5>
              <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> -->
              <a routerLink="/studyhallList">Explore   <i class="fas fa-arrow-right"></i></a>
            </div>
            
          </div>
    </div>

    <div class="col-lg-4 col-md-6">
        <div class="card" style="width: 18rem; ">
            <img src="../../../../assets/img/Round table.webp" alt="Round table" routerLink="/roundtableList" class="card-img-top" alt="...">
            <div class="card-body">
              <h5 routerLink="/roundtableList" class="card-title">Round Table <p>(Webinar)</p></h5>
              <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> -->
              <a routerLink="/roundtableList">Explore   <i class="fas fa-arrow-right"></i></a>
            </div>
            
          </div>
    </div>

</div>

</div>

</div>
</div>