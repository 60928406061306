import { Component, OnInit,Input, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ApiService } from '../../../shared/api.service';

const methodToValueMap = {
  'liveclass':50,
  'groupclass': 25,
  'teachertest': 25,
  'completiontest': 100,
  'studymaterial': 15,
  'videoclass': 25,
}
const barChartOptions = {
  chart: {
    type: 'bar',
    height: 330,
    foreColor: '#9aa0ac',
    stacked: true,
    toolbar: {
      show: false,
    },
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0,
        },
      },
    },
  ],
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '20%',
    },
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: false,
  },
  fill: {
    opacity: 1,
    colors: ['#25B9C1', '#4B4BCB', '#EA9022', '#9E9E9E'],
  }
}

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexYAxis,
  ApexStroke,
  ApexTooltip,
  ApexDataLabels,
  ApexPlotOptions,
  ApexResponsive,
  ApexLegend,
  ApexFill,
} from 'ng-apexcharts';
import * as Chart from 'chart.js';

export type barChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  responsive: ApexResponsive[];
  xaxis: ApexXAxis;
  legend: ApexLegend;
  fill: ApexFill;
};

export type areaChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  stroke: ApexStroke;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
  legend: ApexLegend;
  colors: string[];
};

@Component({
  selector: 'app-chartdashboard',
  templateUrl: './chartdashboard.component.html',
  styleUrls: ['./chartdashboard.component.scss']
})
export class ChartdashboardComponent implements OnInit {


  pieChartData: any[] = [
    ['Task', 'Hours'],
    ['Eat', 2],
    ['Sleep', 8],
    ['Code', 12],
    ['Commute', 3]
  ];



  public isdashboard:boolean = false;
  public meetingnotstart:boolean = false;
  public thisWeekWork: any = [];
  public courseData: any = [];
  @ViewChild('chart') chart: ChartComponent;
  public barChartOptions: Partial<barChartOptions>;
  public areaChartOptions: Partial<areaChartOptions>;
  public pagename:String = '';
  @Input() data:any =[] ;
  constructor(private router:Router,private location:Location, private userApi: ApiService) {}

  // Doughnut chart start
  public doughnutChartLabels: string[] = [
    'Physics',
    'Chemistry',
    'Mathematics',
    'Biology',
  ];
  public doughnutChartData: number[] = [32, 25, 20, 23];
  public doughnutChartColors: any[] = [
    {
      backgroundColor: ['#5A5FAF', '#F7BF31', '#EA6E6C', '#28BDB8'],
    },
  ];

  public doughnutChartType = 'doughnut';
  public doughnutChartOptions: any = {
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 70,
    legend: {
      display: false,
    },
  };

  // Doughnut chart end

  ngOnInit() {
    $(document).ready(function() {
      var ctx = document.getElementById("chart-line") as HTMLCanvasElement;
      var myLineChart = new Chart(ctx, {
          type: 'pie',
          data: {
              labels: ["Spring", "Summer", "Fall", "Winter"],
              datasets: [{
                  data: [1200, 1700, 800, 200],
                  backgroundColor: ["rgba(255, 0, 0, 0.5)", "rgba(100, 255, 0, 0.5)", "rgba(200, 50, 255, 0.5)", "rgba(0, 100, 255, 0.5)"]
              }]
          },
          options: {
              title: {
                  display: true,
                  text: 'Weather'
              }
          }
      });
      var ctx1 = document.getElementById("chart-line1") as HTMLCanvasElement;
      var myLineChart = new Chart(ctx1, {
      type: 'bar',
      data: {
          labels: [1500, 1600, 1700, 1750, 1800, 1850, 1900, 1950, 1999, 2050],
          datasets: [{
              data: [86, 114, 106, 106, 107, 111, 133, 221, 783, 2478],
              label: "Africa",
              borderColor: "#458af7",
              backgroundColor: '#458af7',
              fill: false
          }, {
              data: [282, 350, 411, 502, 635, 809, 947, 1402, 3700, 5267],
              label: "Asia",
              borderColor: "#8e5ea2",
              fill: true,
              backgroundColor: '#8e5ea2'
          }, {
              data: [168, 170, 178, 190, 203, 276, 408, 547, 675, 734],
              label: "Europe",
              borderColor: "#3cba9f",
              fill: false,
              backgroundColor: '#3cba9f'
          }]
      },
      options: {
          title: {
              display: true,
              text: 'World population per region (in millions)'
          }
      }
  });
  });

    var c = document.getElementById("chart-line3") as HTMLCanvasElement;
    var myLineChart = new Chart(c, {
        type: 'pie',
        data: {
            labels: ["Today", "Tommorow", "This week", "This month"],
            datasets: [{
                data: [1200, 1700, 800, 200],
                backgroundColor: ["rgba(255, 0, 0, 0.5)", "rgba(100, 255, 0, 0.5)", "rgba(200, 50, 255, 0.5)", "rgba(0, 100, 255, 0.5)"]
            }]
        },
        options: {
            title: {
                display: true,
                text: 'Weather'
            }
        }
    });
  
    this.isdashboard =  window.location.href.match('dashboard')!=null ? true : false;
    this.pagename = window.location.href.split('/')[window.location.href.split('/').length-1]

    this.chart1();
    this.chart2();
    this.getCourseCompletion();
    //this.chart2();
  }

 

  private chart1() {
    this.areaChartOptions = {
      series: [
        {
          name: 'Mathes',
          data: [31, 40, 28, 51, 42, 85, 77],
        },
        {
          name: 'Science',
          data: [11, 32, 45, 32, 34, 52, 41],
        },
      ],
      chart: {
        height: 350,
        type: 'area',
        toolbar: {
          show: false,
        },
        foreColor: '#9aa0ac',
      },
      colors: ['#F77A9A', '#A054F7'],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        categories: [
          'test 1',
          'test 2',
          'test 3',
          'test 4',
          'test 5',
          'test 6',
          'test 7',
        ],
      },
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'center',
        offsetX: 0,
        offsetY: 0,
      },
    };
  }

  private chart2() {
    this.barChartOptions = {
      series: [
        {
          name: 'Chapter 1',
          data: [44, 55, 41, 67, 22, 43],
        },
        {
          name: 'Chapter 2',
          data: [13, 23, 20, 8, 13, 27],
        },
        {
          name: 'Chapter 3',
          data: [11, 17, 15, 15, 21, 14],
        },
        {
          name: 'Chapter 4',
          data: [21, 7, 25, 13, 22, 8],
        },
      ],
      chart: {
        type: 'bar',
        height: 330,
        foreColor: '#9aa0ac',
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '20%',
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        type: 'category',
        categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      },
      legend: {
        show: false,
      },
      fill: {
        opacity: 1,
        colors: ['#25B9C1', '#4B4BCB', '#EA9022', '#9E9E9E'],
      },
    };
  }
  redirectLocation(routerPath,tabname){
    var data = {
      'pagename':'dashboard'
    }
    console.log('checkingData Dashboard data',data)
    var routerData = JSON.stringify(data)
    // var isTeacherText =  this.isdashboard ? '/teacher/mydashboard' : '/student/mydashboard'
 
    // this.router.navigate(['/student/mydashboard']);

      this.router.navigate(['/student/edit-profile'], { state: data })
    
  }
  getCourseCompletion() {
    
    const webToken = localStorage.getItem('webtoken');
    this.userApi.getCourseCompletionDetails(webToken).subscribe((result) => {
      console.log(result);
      if(result.status){
        this.courseData = result.data;
        var oneWeekBack = new Date();
        oneWeekBack.setDate(oneWeekBack.getDate()-7);
        oneWeekBack.setHours(0,0,0,0);
        this.thisWeekWork = result.data.reduce(function(acc, item) {
          var itemUpdatedAt = new Date(item.updatedAt);
          if(itemUpdatedAt > oneWeekBack){
            acc.push(item);
          }
          return acc;
        },[])
        console.log(this.thisWeekWork);
        if(this.thisWeekWork.length > 4){
          this.thisWeekWork.splice(0,4);
        }
        //for(let item of this.courseData){
          // item.barChartOptions = barChartOptions;
          // item.barChartOptions.xaxis = {
          //   type: 'category',
          //   categories: item.topicList.map((topic) => topic.topicName)
          // };
          // item.barChartOptions.series = [
          //   {
          //     name: 'Chapter 1',
          //     data: [44, 55, 41, 67, 22, 43],
          //   },
            
          //   {
          //     name: 'Chapter 2',
          //     data: [13, 23, 20, 8, 13, 27],
          //   },
          //   {
          //     name: 'Chapter 3',
          //     data: [11, 17, 15, 15, 21, 14],
          //   },
          //   {
          //     name: 'Chapter 4',
          //     data: [21, 7, 25, 13, 22, 8],
          //   },
          // ]
        //}
      }
    })
  }
}

