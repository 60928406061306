import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ApiService } from '../../../shared/api.service';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';



@Component({
  selector: 'app-chapter-topic',
  templateUrl: './chapter-topic.component.html',
  styleUrls: ['./chapter-topic.component.scss']
})
export class ChapterTopicComponent implements OnInit {
  public isManual: Boolean = true;

  newDashboardForm: FormGroup;
  constructor(public userApi: ApiService, private formBuilder: FormBuilder, private http: HttpClient, private router: Router, private location: Location, public toastr: ToastrManager) {

    this.newDashboardForm = this.formBuilder.group({
      PersonalizedClass: []
    });
    this.userApi.customChangeDetector$.subscribe((data: any) => {
      this.data = data;
    });
  }

   public getDatas : any;
   public checkedIDs: any = [];

   public dashboardData: any = [];
  dashboard: FormGroup;
  public checked: any;
  public data: any = [];
  @Input() dataChapter: any = [];

  ngOnInit(): void {
    this.checkedIDs = []
    this.getChapterDetails();
   this.getDatas();
  }

  // event.target.parentElement.parentElement.parentElement.firstElementChild.id

  PersonalizedClass(event, checkingParams) {
    console.log('dfdfdf', event.target.checked);
     this.getDatas =  event.target.parentElement.parentElement.parentElement.firstElementChild.id;

    console.log('getDatas1',this.getDatas);
    if (event.target.checked === true) {
      this.checkedIDs.push(checkingParams);
    } else {
      // this.checkedIDs.splice(0,checkingParams);
      var index = this.checkedIDs.indexOf(checkingParams)
      this.checkedIDs.splice(index, 1);
    }
    console.log('thisChecked', this.checkedIDs);
  }

  PushDataClass(event) {
    console.log('thisChecked', this.checkedIDs);
    this.checkedIDs;
    this.toastr.successToastr("added Form SuccessFully");
   // let GetDatas =  event.target.parentElement.parentElement.parentElement.firstElementChild.id;

    console.log('getDatas',this.getDatas);

    var data = {
      'pagename': 'dashboard',
      'getId': this.getDatas
      // 'type': isManual
    }
    console.log('checkingData Dashboard data', data)
    // var routerData = JSON.stringify(data)
    // var isTeacherText =  this.isdashboard ? '/teacher/mydashboard' : '/student/mydashboard'


    this.router.navigate(['student-onetoone-create/manual'], { state: data })
  }

  getChapterDetails() {
    console.log(this.dataChapter);
    const webToken = localStorage.getItem('webtoken');
    console.log(webToken, 'fdfdfdWEBTOK')
    if (webToken != null) {
      var req = {
        "categoryID": "5f12a2efbf9506355c8adb5c",
        "gradeID": "5f6864c40a19fd6e912fbf9f",
        "boardID": "5f43aa8c0c46160c254a05d3",
        "subjectID": "5f4d0b937f22ab6769601c97",
        "languageID": "5f43aa990c46160c254a05d4"
      }
      this.userApi.getChapterDetails(req, webToken).subscribe((response) => {
        // if(response.status){
        this.data = response;

        console.log('thisDatagetChapterDetails', this.data);
        // }
      });
    }
  }
  toggleAccordian(event, index) {
    const element = event.target;
    element.classList.toggle("active");
    if (this.data[index].isActive) {
      this.data[index].isActive = false;
    } else {
      this.data[index].isActive = true;
    }
    const panel = element.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }
}
