<div *ngIf="isstudent">
    <app-header-style-one></app-header-style-one>
</div>
<div *ngIf="isteacher">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>

<section class="instrctor-area pt-100 pb-70">
    <div class="col-lg-6 col-md-12" style="margin-bottom: 5%;">
        <div class="about-content">
            <h3 style="color:#f21250;">Advisors</h3>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                      <a href="" routerLink="/buddi-advisor-details/samuel" > <img src="../../../../assets/img/advisor.jpg" alt="images" style="height: 300px;"></a>
                    </div>

                    <div class="member-content">
                        <h3><a href="" routerLink="/buddi-advisor-details/samuel">Prof.Samuel Johnson</a></h3>
                        <span>Educational trainer</span>
                        <ul class="social">
                            <li><a class="facebook"><i class='bx bxl-facebook'></i></a></li>
                            <li><a class="twitter"><i class='bx bxl-twitter'></i></a></li>
                            <li><a class="instagram"><i class='bx bxl-instagram'></i></a></li>
                            <li><a class="linkedin"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div id="particles-js-circle-bubble-3"></div>
</section>

<app-become-instructor-partner></app-become-instructor-partner>