import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../shared/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from 'src/environments/environment';
import { ToastrManager, Toastr } from 'ng6-toastr-notifications';
import * as moment from 'moment';
import { Location } from '@angular/common';
@Component({
  selector: 'app-create-course',
  templateUrl: './create-course.component.html',
  styleUrls: ['./create-course.component.scss']
})
export class CreateCourseComponent implements OnInit {
  coursecreateform: FormGroup;
  submitted = false;
  formData;  
  public isstudent = false;
  public isteacher = false;
  public coursecategorylist : any = [];
  public courseboardlist : any = [];
  public coursesubjectlist : any = [];
  public languageapi : any = [];
  public gradeapi : any = [];  
  public coursePrivacyapi:any=[];
  public userId:any = '';
  public groupname:any ='';
  public isEnterprise = '';
  public paramData:any = [];
  public courseCategory = "Select Category";
  public courseBoard = "Select Board";
  public courseSubject = "Select Subject";
  public language = "Select Language";
  public grade = "Select Grade";  
  public coursePrivacy = "Select Privacy";  
  selectedFileNmae: string = 'Choose File';  
  public isselected = true;
  public isgroupname = false;
  public editData:any=[];
  public allboard:any = [];
  public allsubject:any = [];  
  public courseURL = environment.COURSE_URL;
  iscoursenameexists: boolean;
  isshortnameexists: boolean;
  isstartdate: boolean;

  constructor(private location:Location,private activatedRoute:ActivatedRoute,private SpinnerService: NgxSpinnerService,private router: Router ,private formBuilder: FormBuilder,private rest: ApiService, private toastr: ToastrManager) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    var profileName = localStorage.getItem('profileName');
    this.userId = localStorage.getItem('userId');
    const webToken = localStorage.getItem('webtoken');
    const userType = localStorage.getItem('userType');      
    if(userType.toLowerCase()=="teacher") {
      this.isteacher = true;
    }
    else {
      this.isstudent = true;
    }
    this.rest.userName_DisplayTeacher(webToken).subscribe((result) => {
      console.log(result);
      this.groupname =  result.data !=undefined && result.data.groupName != 'buddi' ? result.data.groupName : ''
      localStorage.setItem('groupname', result.data !=undefined && result.data.groupName != 'buddi' ? result.data.groupName : '');
    });
    this.getcourseprivacy();
    this.getcoursecategory();
    this.getboardfield();
    this.getsubjectfield();
    this.getlanguage();
    this.getgrade();
    
    this.coursecreateform = this.formBuilder.group({
      fullname: ['', Validators.required],
      shortname: ['', Validators.required],
      startdateschedule: ['', Validators.required],
      endDateschedule: ['', Validators.required],
      coursePrivacy: ['', Validators.required],
      courseGrade: [''],
      courseSubject: ['', Validators.required],
      courseLanguage:['', Validators.required],
      courseDescription:['', Validators.required],
      upload_name: [null],
      fileupload: [''],
      courseCategory: ['', Validators.required],
      courseBoard: ['', Validators.required]
    
  });

 

    }
     // convenience getter for easy access to form fields
    get f() { return this.coursecreateform.controls; }     

     onSubmit(FormGroup) {
         this.submitted = true;
 
         // stop here if form is invalid
         if (this.coursecreateform.invalid) {
           console.log("Invalid Fields");
           this.toastr.errorToastr("Invalid Fields");
             return;
         }         
      
          const data = this.coursecreateform.value;             
          const startDate1 = new Date(this.coursecreateform.value.startdateschedule).getTime() / 1000      
          const endDate1 = new Date(this.coursecreateform.value.endDateschedule).getTime() / 1000
          const currentdate = new Date().getTime() / 1000

          if(startDate1 > currentdate) {
            this.isstartdate = false;       
            if(data.upload_name!=null) {
              this.formData = new FormData();
              this.formData.append("course_image", data.upload_name, data.upload_name.name);
              this.formData.append("fullName", data.fullname);
              this.formData.append("shortName", data.shortname);
              this.formData.append("categoryID", data.courseCategory);
              this.formData.append("languageID", data.courseLanguage);
              this.formData.append("subjectID", data.courseSubject);
              this.formData.append("boardID", data.courseBoard);
              this.formData.append("gradeID", data.courseGrade);
              this.formData.append("privacy", data.coursePrivacy);
              this.formData.append("startDate", startDate1);
              this.formData.append("endDate", endDate1);
              this.formData.append("description", data.courseDescription);
              this.formData.append("status", "Draft");
              const webToken = localStorage.getItem('webtoken');
              this.rest.createCourse(this.formData, webToken).subscribe((result) => {
              var globalVar = result;
                console.log(result);
                if(result.status) {
                  this.toastr.successToastr("Course created successfully");              
                  var userId = localStorage.getItem('userId');
                  if(!userId){
                    this.toastr.errorToastr("please login to access courses");
                  }
                  else{
                    var userData = {};
                    userData["userId"]=userId;                
                    const userType = localStorage.getItem('userType');
                    userData["userType"]=userType.toLowerCase();
                    console.log("usertype : " + userType);
                   

                    var redirectURL = this.courseURL + "course/view.php?id=" + result.data.courseID;
                    this.rest.getLoginURL(userData).subscribe((result) => {
                      console.log(result);
                      if(result){
                        if(!result["data"]) {
                          this.toastr.errorToastr("user has not registered for courses");
                        }
                        else{

                            //Calendar Start

                              this.addToCalendar(globalVar.data, webToken);

                              //Calendar End

                          if(!result["data"]["loginurl"] || result['exception'] == 'invalid_parameter_exception'){
                            this.toastr.errorToastr("user has not registered for courses");
                          }
                          else {
                            this.toastr.successToastr("You are now being redirected to the Add Activity Page of the course");                       
                            console.log(result);
                           

                            console.log(result["data"]["loginurl"]);
                            console.log(result["data"]["loginurl"] + "&wantsurl=" + redirectURL)
                            window.open(result["data"]["loginurl"] + "&wantsurl=" + redirectURL);
                            if(userType.toLowerCase()=="teacher") {
                              this.router.navigate(['/teacher/mydashboard']);
                            }
                            else {
                              this.router.navigate(['/teacher/mydashboard']);
                            }                       
                          }
                        }
                      }
                    });
                  }
                } 
                else {            
                  if(result.message.toLowerCase().includes('course full name')) {
                    this.iscoursenameexists = true;
                    this.isshortnameexists = false;
                    this.toastr.errorToastr(result.message);
                  }
                  else if(result.message.toLowerCase().includes('short name')) {
                    this.isshortnameexists = true;
                    this.iscoursenameexists = false;
                    this.toastr.errorToastr(result.message);
                  }
                  else {
                    this.toastr.errorToastr(result.message);
                    this.iscoursenameexists = false;
                    this.isshortnameexists = false;
                  }
                  console.log(result.message);
               }
              }, (err) => {
              console.log(err);
              });   
            }
            else {
              this.toastr.errorToastr("Image Required");
            }
             
        
          }
          else {
            this.isstartdate = true;          
          }

                 
        }

        fileChange(event) {
          let fileList: FileList = event.target.files;
          this.coursecreateform.patchValue({ upload_name: fileList[0] });
          this.selectedFileNmae = fileList[0].name;
          this.coursecreateform.patchValue({ fileupload: this.selectedFileNmae });
          console.log(this.coursecreateform.value);
        }

        getcoursecategory() {
          this.rest.getCourseCategoryHeader().subscribe((result) => {
            this.coursecategorylist = result.data;
            if(Object.keys(this.paramData).length == 0){
              this.coursecreateform.patchValue({ courseCategory: this.coursecategorylist[0]._id })
            }
            console.log(this.coursecategorylist);
          }, (err) => {
          console.log(err);
          });
        }

        getboardfield(){
          this.rest.boardfield().subscribe((result) => {
            this.courseboardlist = result.data;
            if(Object.keys(this.paramData).length == 0){
              this.coursecreateform.patchValue({ courseBoard: this.courseboardlist[0]._id })
            }
            console.log(this.courseboardlist);
          }, (err) => {
          console.log(err);
          });
        }

        getsubjectfield(){
          this.rest.subjectfield().subscribe((result) => {
            this.coursesubjectlist = result.data;
            if(Object.keys(this.paramData).length == 0){
              this.coursecreateform.patchValue({ courseSubject: this.coursesubjectlist[0]._id })
            }
            console.log(this.coursesubjectlist);
          }, (err) => {
          console.log(err);
          });
        }

        getlanguage(){
          this.rest.languagefield().subscribe((result) => {
            this.languageapi = result.data;
            if(Object.keys(this.paramData).length == 0){
              this.coursecreateform.patchValue({ courseLanguage: this.languageapi[0]._id })
            }
            console.log(this.languageapi);
          }, (err) => {
          console.log(err);
          });
        }

        getgrade(){
          this.rest.gradefield().subscribe((result) => {
            this.gradeapi = result.data;
            if(Object.keys(this.paramData).length == 0){
              this.coursecreateform.patchValue({ courseGrade: this.gradeapi[0]._id })
            }
            console.log(this.languageapi);
          }, (err) => {
          console.log(err);
          });
        }

        getcourseprivacy(){
          this.rest.privacy().subscribe((result) => {
            this.coursePrivacyapi = result;
            if(Object.keys(this.paramData).length == 0){
              this.coursecreateform.patchValue({ coursePrivacy: this.coursePrivacyapi[0].name })
            }
          }, (err) => {
          console.log(err);
          });
        }

        selectedcategory(event: any) {
          var selected = event.target.options[event.target.selectedIndex].text;
          if(selected == 'K-12'){         
            this.isselected = true;
          }else{
            this.isselected = false;
          }          
        }

        selectedprivacy(event: any) {
          var selected = event.target.options[event.target.selectedIndex].text;
          if(selected == 'School'){         
            this.isgroupname = true;
          }else{
            this.isgroupname = false;
          }          
        }

        addToCalendar(data,webToken){
    var req={
      myLifeListDatepick:moment(data.startDate).format('YYYY-MM-DD'),
      myLifeListEventType: "Course",
      myLifeListTimepick:moment(data.startDate).format('HH:mm:ss'),
      setReminder: 0,
      todoEventType: "Course",
      meetingType:data.privacy,
      todoSchedule: data.startDate,
      todoSetReminder: "",
      todosetReminderValue: "",
      sessionName:data.shortName,
      meetingId : data._id,
      categoryID :data.categoryID,
      myLifeListTitle:data.privacy,
      sessionId:data._id,
      profileType:'teacher'
    };
    this.SpinnerService.show()
    this.rest.add_myLife_NewData(req,webToken).subscribe((result) => {
    this.SpinnerService.hide()
      if(result.status){
        this.toastr.successToastr("Class Added to your Calender");
        //this.location.back();
      }else{
        this.toastr.errorToastr(result.message);
      }

    });
  }
}
