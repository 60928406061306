
<div class="container-fluid row p-0" style="cursor: pointer" *ngIf="data.length!=0">
    <div class="col-lg-3 col-md-6" *ngFor="let slide of data | paginate: configClass">
        <div class="single-courses-box without-box-shadow mb-30">
            <div class="courses-image">
                <a (click)="redirectDetails(slide)" class="d-block">
                    <img style="width: 250px;height: 175px;" [src]="slide.image =='' ? 'assets/img/courses/courses1.jpg':slide.image" alt="image">
                </a>
            </div>
            <div class="courses-content">
                <div class="course-author d-flex align-items-center">
                    <div class="course-author d-flex align-items-center">
                        <img [src]="slide.studentID!=undefined && slide.studentID.userPhoto!='' ? slide.studentID.userPhoto  :'assets/img/user1.jpg'" class="rounded-circle mr-2" alt="image"> 
                        <span class="" *ngIf="slide.studentID!=undefined">		{{slide.studentID.firstName}}
                        </span>
                        <img  class="recordIcon" src="assets/img/recordingicon.png"  *ngIf="slide.acceptStatus && slide.isrecorded" (click)="fetchrecordvideo(slide._id,'requestId')" style="">
                    </div>
                </div>
                <div class="course-author d-flex align-items-center">
                    <span *ngIf="!slide.acceptStatus" class="col-lg-1 p-0" style="color:orange">
                    	<b>Pending</b>
                    </span>
                    <span *ngIf="slide.acceptStatus" class="col-lg-1 p-0" style="color:green">
                    	<b>Accepted</b></span>
                    </div>
                    <div class="course-author d-flex align-items-center">
                        <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image">{{slide.ScheduleDate |  date :"MMM dd,yyyy, hh:mm aaa"}}
                    </div>
                    <h3>
                    	<a (click)="slide.acceptStatus ? redirectDetails(slide) : redirectDetails(slide)" class="d-inline-block">
                            <span class="text-break">{{slide.name}}</span>
                        </a>
                    </h3>
                </div>
            <list5 [slide]="slide"></list5>
        </div>
    </div>    
</div>
<pagination-controls class="float-right" [id]="configClass.id" *ngIf="data.length!=0" (pageChange)="pageChanged($event)"></pagination-controls>
