import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../../shared/api.service';

import { MediaService } from '../../../../shared/media.service';
import { Toastr, ToastrManager } from 'ng6-toastr-notifications';
import { Location } from '@angular/common';

@Component({
  selector: 'app-student-start-test',
  templateUrl: './student-start-test.component.html',
  styleUrls: ['./student-start-test.component.scss']
})
export class StudentStartTestComponent implements OnInit {
  public isteacherRoute:any =false;
  public isinstruction:any = true;
  public isquestion:any = false;
  time: number = 0;
  public TestData: any = [];
  public TestImages: any = [];
  public livedetails: any = [];
  public submissionid: any;
  totaltime: number = 0;
  public url = "";
  interval;
  display;
  public ischecked: boolean = false;
  selectedimageform: FormGroup;
  public uploadimage: boolean = false;
  public buttonnext: boolean = true;
  public buttonprev: boolean = true;
  public showimageinmodal = "";
  formDatasubjective;
  images = [];
  uploadingimages = [];
  fileList: File[] = [];
  public questioncount: number = 0;
  public totalquestioncount: number = 0;
  public subjectname1array: any = [];
  public questionimage ="";

  constructor(private mediaAPI:MediaService,private location: Location, private formBuilder: FormBuilder, private my_router: ActivatedRoute, public toastr: ToastrManager, private rest: ApiService, private router: Router) { }

  ngOnInit(): void {    
    this.selectedimageform = this.formBuilder.group({
      answerImage: [''],
      id: [''],
      upload_name: [null],
    });
    this.isteacherRoute = localStorage.getItem('profileType').toLowerCase()=='teacher'
    var token = localStorage.getItem('webtoken');
    
    var req = {
      testID : this.my_router.snapshot.params['id']
    }

    this.mediaAPI.getteacheruploadedtestbyid(req,token).subscribe((result) => {
      if(result.status){
        this.livedetails = result.data;
      }
    });
  }

  loaddata() {    
    
    var token = localStorage.getItem('webtoken');
    
    var req = {
      testID : this.my_router.snapshot.params['id']
    }
    this.rest.studentstarttest(req,token).subscribe((result) => {
      if(result.status) {
        this.submissionid = result.data._id;
        this.TestData = result.data;
        this.TestImages = result.data.testImages;
        this.totalquestioncount = this.TestImages.length;
        this.time = this.TestData.totalTime * 60;

        for (var i = 0; i < this.TestImages.length; i++) {
            this.subjectname1array.push(this.TestImages[i]);

        }
        this.questioncount = 1;
        let item1 = this.TestImages.find(i => i.imageNumber === this.questioncount);
        this.url = item1.imagePath;
        this.startTimer();
      }
      else {
        this.toastr.errorToastr(result.message);
      }
        }, (err) => {
          console.log(err);
        });
  }

  

  startTimer() {
    this.interval = setInterval(() => {
      if (this.time === 0) {
        this.pauseTimer();
        this.uploadimages();
      } else {
        this.time--;
      }
      // this.display = this.transform(this.time)
      const minutes: number = Math.floor(this.time / 60);
      this.display = ('00' + minutes).slice(-2) + ':' + ('00' + Math.floor(this.time - minutes * 60)).slice(-2);
    }, 1000);
  }

  pauseTimer() {
    clearInterval(this.interval);
  }

  changed(evt) {
    this.ischecked = evt.target.checked;
  }

  getquestions() {
    this.isinstruction = false;
    this.isquestion = true;
    this.loaddata();
  }

  onFileChange(event) {
    this.formDatasubjective = new FormData();
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.images.push(event.target.result);
        }
        reader.readAsDataURL(event.target.files[i]);
        var selectedFile = event.target.files[i];
        this.fileList.push(selectedFile);
      }
    }
  }

  imageselected(url) {
    this.showimageinmodal = url;
  }

  removeSelectedFile(index) {
    // Delete the item from fileNames list
      this.images.splice(index, 1);
      this.fileList.splice(index, 1);
  }

  uploadimages() {
    var token = localStorage.getItem('webtoken');
    this.formDatasubjective.append("submissionID",this.TestData._id);
    for (let i = 0; i < this.fileList.length; i++) {
      let imagename = i+1 + "_" + this.fileList[i].name;
    
      this.formDatasubjective.append("answer_files", this.fileList[i], imagename);
    }
    this.rest.studentfinishtest(this.formDatasubjective,token).subscribe((result) => {
      if (result.status) {
        this.toastr.successToastr(result.message);
         this.router.navigate(['/sidenav/']);
      }
      else {
       this.toastr.errorToastr(result.message);
      }
    });
  }

  questionnumberbutton(count) {
    this.questioncount = count;
    let item1 = this.TestImages.find(i => i.imageNumber === count);
    this.url = item1.imagePath;
  }

  nextbutton() {       
      let item1 = this.TestImages.find(i => i.imageNumber === this.questioncount + 1);
      if(item1.length > 0) {
        this.url = item1.imagePath;
        const questionnumber = this.questioncount + 1;
        this.questioncount = questionnumber;
      }
    }

  previousbutton() {
    let item1 = this.TestImages.find(i => i.imageNumber === this.questioncount - 1);
    if(item1.length > 0) {
      this.url = item1.imagePath;
      const questionnumber = this.questioncount - 1;
      this.questioncount = questionnumber;
    }
  }
 

  uploadanswerimage() {
    this.isinstruction = false;
    this.isquestion = true;
    this.uploadimage = true;
  }

  back() {
    this.isquestion = true;
    this.uploadimage = false;
  }

}
