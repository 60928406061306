<app-header-style-one *ngIf="!isTeacher"></app-header-style-one>
<app-header-style-one-teacher
    *ngIf="isTeacher"
></app-header-style-one-teacher>
<br />

<div class="container">
    <div class="container">
       <div class="row align">
         <div class="pipe">
            <h4 style="color: #000000" >Institution list</h4>
         </div>
         <!-- <div class="pipe">
            <a *ngIf="isTeacher && isLogin" href="javascript:void(0)" (click)="redirectToCreate()"class="default-btn" >
            <i class="bx bx-paper-plane icon-arrow before"></i>
            <span class="label">Create Live Class</span>
            <i class="bx bx-paper-plane icon-arrow after"></i>
            </a>
        
          </div> -->
       </div>
    </div>
    <div class="container">
        <div class="courses-topbar">
                    <div class="topbar-ordering-and-search">
                        <div class="align1">
                            <!-- <div class="pipe col-lg-6 col-md-4 custom-switch"  *ngIf="isLogin=='true'" >
                                <input type="checkbox" class="custom-control-input" id="customSwitches" (change)="toggleVisibility($event)">
                                <label class="custom-control-label" for="customSwitches"></label>
                                <div style="padding: 4px;">Switch <span *ngIf="marked">buddi</span><span *ngIf="!marked">Institutions</span></div>
                            </div>
                            <div class="col-lg-2 col-md-3 pipe col-sm-6" >
                                <div class="topbar-ordering">
                                    <select class="nice-select1" (change)="dropdownChange($event)">
                                        <option value="all" selected="selected">All</option>
                                        <option value="today">Today</option>
                                        <option value="tomorrow">Tomorrow </option>
                                        <option value="week">This Week</option>
                                        <option value="month">This Month</option>
                                    </select>
                                </div> 
                            </div> -->

                            <div class="pipe col-lg-3 col-md-4 col-sm-6">
                                <div class="topbar-search">
                                    <form>
                                        <label><i class="bx bx-search"></i></label>
                                        <input type="search" class="input-search" placeholder="Search here..."id="searchBar" /></form>
                                </div>
                            </div>
                            <div class="btn">
                                <button (click)="searchClicked()">Search</button>
                            </div>
                        </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
        <div class="col-lg-4 col-md-6" *ngFor="let slide of videoList">
            <div class="single-courses-item mb-30 p-4">
                <p  (click)="detailsMeeting(slide, 'Livesession')">Institution Name :{{slide.institutionName}}</p>
                <p>Institution Short Name :{{slide.institutionShortName}}</p>
                <p>Institution Phone Number:{{slide.phoneNumber}}</p>
            </div>
            </div>
        </div>
        </div>
</div>
