import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
@Component({
  selector: 'app-tutor-learner',
  templateUrl: './tutor-learner.component.html',
  styleUrls: ['./tutor-learner.component.scss']
})
export class TutorLearnerComponent implements OnInit {
  public isUserLoggedIn:string;
  constructor(private router: Router,public toastr: ToastrManager) { }

  ngOnInit(): void {
    this.isUserLoggedIn = localStorage.getItem('isLoggedin');
  }
  studentclick() {   
    if(this.isUserLoggedIn.toLowerCase()=="true") {
      this.toastr.errorToastr("Already you have Logged in");
    }
    else {
      this.router.navigate(['/student/login']);
    }
  }
  teacherclick(){
    if(this.isUserLoggedIn.toLowerCase()=="true") {
      this.toastr.errorToastr("Already you have Logged in");
    }
    else {
      this.router.navigate(['/teacher/login']);
    }
  }

 

}
