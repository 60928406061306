import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-buddi-team-details',
  templateUrl: './buddi-team-details.component.html',
  styleUrls: ['./buddi-team-details.component.scss']
})
export class BuddiTeamDetailsComponent implements OnInit {

  
  public isstudent = false;
  public isteacher = false;
  public isuday = false;
  public ismukund = false;
  public isarif = false;
  public ismugundan=false;
  public issundarrajan=false;
  public iskabilan=false;
  public isanita=false;
  public issoniya=false;
  public issathis=false;
  
  public ismohan=false;
  public isthirupathi=false;

  name : any;

  constructor(private my_router: ActivatedRoute) { }

  ngOnInit(): void {
    this.name = this.my_router.snapshot.params['name'];
    var usertype = localStorage.getItem('profileType');
    if (usertype.toLowerCase() == "teacher") {
      this.isteacher = true;
    }
    else {
      this.isstudent = true;
    }

    if(this.name.toLowerCase() == "uday") {
    this.isuday = true;
    this.isarif = false;
    this.ismukund = false;
    }
    else if(this.name.toLowerCase() == "mukundan") {
      this.isuday = false;
      this.isarif = false;
      this.ismukund = true;
    }
    else if(this.name.toLowerCase() == "arif") {
      this.isuday = false;
      this.isarif = true;
      this.ismukund = false;
    }
    else if(this.name.toLowerCase() == "mugundan") {
      this.isuday = false;
      this.isarif = false;
      this.ismukund = false;
      this.ismugundan = true;
    }
    else if(this.name.toLowerCase() == "sundarrajan") {
      this.isuday = false;
      this.isarif = false;
      this.ismukund = false;
      this.ismugundan = false;
      this.issundarrajan=true;
        }
    else if(this.name.toLowerCase() == "kabilan") {
      this.isuday = false;
      this.isarif = false;
      this.ismukund = false;
      this.ismugundan = false;
      this.issundarrajan=false;
      this.iskabilan=true;
    }
    else if(this.name.toLowerCase() == "anita") {
      this.isuday = false;
      this.isarif = false;
      this.ismukund = false;
      this.ismugundan = false;
      this.issundarrajan=false;
      this.iskabilan=false;
      this.isanita=true;
    }
    else if(this.name.toLowerCase() == "soniya") {
      this.isuday = false;
      this.isarif = false;
      this.ismukund = false;
      this.ismugundan = false;
      this.issundarrajan=false;
      this.iskabilan=false;
      this.isanita=false;
      this.issoniya=true;
    }
    else if(this.name.toLowerCase() == "sathis") {
      this.isuday = false;
      this.isarif = false;
      this.ismukund = false;
      this.ismugundan = false;
      this.issundarrajan=false;
      this.iskabilan=false;
      this.isanita=false;
      this.issoniya=false;
      this.issathis=true;
    }
    
    else if(this.name.toLowerCase() == "mohan") {
      this.isuday = false;
      this.isarif = false;
      this.ismukund = false;
      this.ismugundan = false;
      this.issundarrajan=false;
      this.iskabilan=false;
      this.isanita=false;
      this.issoniya=false;
      this.issathis=false;
      this.ismohan=true;
     
    }
    else if(this.name.toLowerCase() == "thirupathi") {
      this.isuday = false;
      this.isarif = false;
      this.ismukund = false;
      this.ismugundan = false;
      this.issundarrajan=false;
      this.iskabilan=false;
      this.isanita=false;
      this.issoniya=false;
      this.issathis=false;
      this.ismohan=false;
      this.isthirupathi=true;
    }
  }

}
