import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-learningassets',
  templateUrl: './learningassets.component.html',
  styleUrls: ['./learningassets.component.scss']
})
export class LearningassetsComponent implements OnInit {

  public isStudent: boolean = false;
  public isTeacher: boolean = false;
  constructor() { }

  ngOnInit(): void {
    this.isTeacher =
            localStorage.getItem("profileType").toLowerCase() == "teacher";
        this.isStudent =
            localStorage.getItem("profileType").toLowerCase() == "student";
  }

}
