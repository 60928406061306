import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Options } from 'ng5-slider';
import { ApiService } from '../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { Location } from '@angular/common';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
declare var $: any;

@Component({
  selector: 'app-buddigroup',
  templateUrl: './buddigroup.component.html',
  styleUrls: ['./buddigroup.component.scss']
})
export class BuddigroupComponent implements OnInit {  
  isLoggedin = "false";
  public teacherID = "";
  public studentID = '';
  public meetingimage = "";
  public meetingurl = "";
  public meetingsactionText = "";
  public meetingCategorypopup = "";
  public meetingScheduleDatepopup = "";
  public meetingrunningpopup = "";
  public meetingIDpopup = "";
  public meeting_ID = "";
  public meetingname = "";
  public meetingScheduleDate = "";
  public inviteUrl = "";
  public frontwebsite = environment.frontUrl;
  public redirectUrl = "";
  public meetingDuration = '';
  public meetingLanguage = '';
  public meetingCity = '';
  public meetingoccurance = '';
  public meetingdescription = '';
  public livedetails:any = [];
  public profileName = '';
  public meetingId='';
  public urlArray:any=[];
  public pagename:any = '';
  public tag:any='';
  public groupName:any;
  public parentTable:any = [];
  public childTable:any = [];
  public isTeacher:boolean=false;
  public isLogout:any = false;
  public isJoin:any =false;
  public isMobile:any ='';
  public buddigroupaccept:boolean = false;
  public studentCount:any = [];
  public routerParam:any = '';
  constructor(private location:Location,private my_router: ActivatedRoute,public toastr: ToastrManager, private formBuilder: FormBuilder, private rest: ApiService, private router: Router,private SpinnerService: NgxSpinnerService,private sanitizer: DomSanitizer,private datePipe: DatePipe) { }

  ngOnInit() {  
    window.scrollTo(0, 0);
    this.routerParam = this.my_router.snapshot.paramMap.get('id');
    this.isLogout = window.location.href.match('logoutmeeting')!=null ? true : false;
    this.buddigroupaccept = window.location.href.match('buddigroupaccept')!=null ? true : false;
    this.isLoggedin = localStorage.getItem('isLoggedin');
    this.teacherID = localStorage.getItem('StudentID');
    const webToken = localStorage.getItem('webtoken');
    var urlArray = window.location.href.split('/');    
    this.isTeacher = localStorage.getItem('profileType').toLowerCase() =='teacher';
    this.isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent);
    if(this.isLogout && !this.isMobile){
      this.callTeacher(urlArray[urlArray.length-1]);
      //this.router.navigate(['/'+localStorage.getItem('profileType').toLowerCase()+'/mydashboard']);
    }
    if(this.isLogout&& this.isMobile){
      window.location.href = "intent:#Intent;action=com.buddiopenfromweb.alwin;category=android.intent.category.DEFAULT;category=android.intent.category.BROWSABLE;S.msg_from_browser=Launched%20from%20Browser;end"
    }
    if(!this.isTeacher){
      this.SpinnerService.show();
      this.rest.getsessionDetails({meetingID:this.routerParam}).subscribe((result) => {
        this.SpinnerService.hide();
        if(result.status){
          this.parentTable = result.data.parentTable[0];
          this.studentCount = result.data.studentCount;
          this.rest.alreadyEnroll({id:this.parentTable._id},webToken).subscribe((result) => {
            this.isJoin = result.data.buddiGroupEnroll.length >0 ? true : false;
          });
          var temp = result.data.childTable;
          const now = Date.now();
          const futureDates = temp.filter(date => {
            if((date.ScheduleDate && new Date(date.ScheduleDate).setHours(0, 0, 0, 0)) ==new Date().setHours(0, 0, 0, 0)){
              date.isEnable = true;
            }
          });
          this.childTable = temp;
        }       
      });
    }else{
      this.SpinnerService.show();
      this.rest.getsessionDetailsTeacher({meetingID:this.routerParam},webToken).subscribe((result) => {
        this.SpinnerService.hide();
        if(result.status){
          this.parentTable = result.data.parentTable[0];
          var temp = result.data.childTable;
          this.studentCount = result.data.studentCount;
          const now = Date.now();
          const futureDates = temp.filter(date => {
            if(date.ScheduleDate && new Date(date.ScheduleDate).setHours(0, 0, 0, 0) ==new Date().setHours(0, 0, 0, 0)){
              date.isEnable = true;
            }
          });
          this.childTable = temp;
        }       
      });
    }  
  }
  login(){
  	var data={
      isredirect:true
    }
    this.router.navigate(['/student/login'],{state:data});
  }
  enroll(){
    const webToken = localStorage.getItem('webtoken');
  	var req={
      meetingID : this.parentTable._id,
      meetingType  :"",
      libraryType:'buddiGroup',
      userID:localStorage.getItem('StudentID'),
      role:'student',
      childTable:this.parentTable._id
    }
    this.rest.meetingActionEnroll(req,webToken,'').subscribe((result) => {
      if(result.status){
        this.toastr.successToastr(result.message);
        // if(result.message !="Already Enrolled. Please check ur dashboad"){
         // this.addToCalendar(data,webToken);              
        // }   
      }else{
        if(result.message == 'Meeting Not Started.'){
          this.router.navigate(['/meetingnotstart']);  
        }else{
          this.toastr.errorToastr(result.message);
        }
      }
    }, (err) => {
      console.log(err);
    });
  }
  backto(){
    var data = {
      'pagename':'menu1'
    }
    var routerData = JSON.stringify(data)
    var isTeacherText =  this.isTeacher ? '/teacher/mydashboard' : '/student/mydashboard';
    if(this.isLogout || !this.buddigroupaccept){  
      this.router.navigate([isTeacherText], { state: data })    
    }
    else{
      this.location.back();
    }    
  }
  editmeeting(){
    this.router.navigate(['/student-onetoone-edit/'+this.livedetails.meetingID]);
  }
  detailsMeeting(data,text){
    window.scroll(0,0);
    if(text =='student'){
      this.studentID = localStorage.getItem('StudentID');
      this.meetingname = data.name;
      if(data.teacherID){
      var profileName = data.studentID.profileName;
      profileName = profileName.replace(/ /gi, "").replace(/-/gi, "_");
      this.inviteUrl =  this.frontwebsite+"invite/"+profileName+"-"+data.inviteurl;
    }
    }else{
      this.teacherID = localStorage.getItem('teacherID');
      this.meetingname = data.name;
      if(data.teacherID){
      var profileName = data.teacherID.profileName;
      profileName = profileName.replace(/ /gi, "").replace(/-/gi, "_");
      this.inviteUrl =  this.frontwebsite+"invite/"+profileName+"-"+data.inviteurl;
      }
    }    
    this.meetingIDpopup = data.meetingID;
    this.meeting_ID = data._id;
    this.meetingrunningpopup = data.running;
    this.meetingScheduleDate = data.ScheduleDate;
    this.meetingDuration = data.Duration ? data.Duration +'Min' : '';
    this.meetingLanguage = data.Languages;
    this.meetingCity = data.city;
    this.meetingoccurance = data.occurance;
    this.meetingdescription = data.meetingdescription;
    var backimage = "";
    this.meetingsactionText = "Join";
  }
  copyMessage(){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.inviteUrl ;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.successToastr("Copied Successfully");
  }
  removeMeeting(data){
    const webToken = localStorage.getItem('webtoken');
    if(confirm("Are you sure to delete ")) {
      this.rest.removeMeeting(data,webToken).subscribe((result) => {
        if(result.status){
          this.router.navigate(['/courses-4-columns-style-3']);
        }else{
          this.toastr.errorToastr(result.message);
        }
      });      
    }
  }
  meetingsaction(id,action,data){
    const webToken = localStorage.getItem('webtoken');
    if(action == 'login'){
      this.router.navigate(['/'+localStorage.getItem('profileType')+'/login']);
    }else{
    this.SpinnerService.show();
      this.rest.meetingAction(id,webToken,action,'buddigroup').subscribe((result) => {
      this.SpinnerService.hide();
        if(result.status){
          location.href = result.data[0];
        }else{
          if(result.message == 'Meeting Not Started.'){
           this.router.navigate(['/meetingnotstart']);          
          }else{
            this.toastr.errorToastr(result.message);
          }
        }
      }, (err) => {
        console.log(err);
      });
    }
  }
  deletemeeting(){
    console.log("IN ANGLUAR",this.livedetails);
    var req={
      meetingID : this.livedetails.meetingID,
    }
    this.SpinnerService.show()
    this.rest.deloneononemeeting(req).subscribe((result) => {
      this.SpinnerService.hide()
      console.log(result);
       if(result.status){
            this.toastr.successToastr(result.message);          
           this.location.back();
         }else{
            this.toastr.errorToastr(result.message);       
         }
    });
  }
  callTeacher(url){  
    const webToken = localStorage.getItem('webtoken');
    this.rest.updateBuddiTime({meetingID:url},webToken).subscribe((result) => {
    var exp = localStorage.getItem('exp');
    if(result.status){
      var diffData = new Date(result.data.endMeeting).valueOf() - new Date(result.data.startMeeting).valueOf();;
      var minutes = Math.floor((diffData/1000)/60);
      //minutes>=60
      if(minutes>=60){
        var reqData = {
          startMeeting : result.data.startMeeting,
          endMeeting : result.data.endMeeting,
          _id :  result.data._id,
          studentID : result.data.studentID,
          exp : exp,
          classType :'buddigroup',
          productCode:result.data.categoryID
        }       
        if(this.isLogout){
          localStorage.setItem('isPendindTeacher','true');
          this.rest.addTeacherPayment(reqData,webToken).subscribe((innerResult) => {
          localStorage.setItem('isPendindTeacher','false');
          this.router.navigate(['feedbackTeacher']);
          });
      }
        
      }
      this.router.navigate(['/'+localStorage.getItem('profileType').toLowerCase()+'/mydashboard']);
    }else{
      this.router.navigate(['/'+localStorage.getItem('profileType').toLowerCase()+'/mydashboard']);
    }
  }); 
}
}
