<div *ngIf="isstudent">
    <app-header-style-one></app-header-style-one>
</div>
<div *ngIf="isteacher">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>
<div class="container">
    <div class="row">
        <div class="col-md-12" id="heading">
            <h1>Join buddi life's Online Tuition Classes Today!</h1>
        </div>
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col-md-12" id="content">
           <h5>Do you wish that you had a medium to learn at your own pace?</h5>
               <h5>Do you wish to utilize your free time after school?</h5> 
               <h5>Well, we heard you!</h5>
<p>buddi life is a micro-learning platform that makes learning convenient. We serve as an excellent platform for <b>online tuition classes </b> for learners of all ages! You will find 800+ courses on buddi life in which you can excel at.</p>
        </div>
    </div>
</div>
<br>
<br>

<div class="container">
    <div class="row">
        <div class="col-md-12">
           
<h5>Why buddi life?</h5>
<p>Although we might have many reasons to have started buddi life, there’s one that precedes everything. We understand that everyone has got their own learning pace. It can be a problem in classroom education where the concepts fly past you in no time. Therefore, buddi life provides you with personalized <b>online tutoring services </b> that suit your learning speed.The best part about our e-learning platform is its range of options. We don’t just stop with subjects taught at school but cover all other advanced courses like NEET, JEE, and IELTS as well.</p>
        </div>
    </div>
    </div>
    <br>
<br>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                
<h5>A convenient learning platform</h5>
<p>Our platform connects enthusiastic learners with passionate tutors across multiple courses. There is no end to the classes that you can attend on buddi life. So, what’s waiting for you here? Let us give some reasons to enroll for the <b>online class tuition</b> at buddi life.</p>
            </div>
        </div>
        </div>
        <br>
<br>
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                   
<h5>Learn anytime from anywhere</h5>
<p>The courses on buddi life are instantly available on successful enrollment. Unless it’s a live class, the learner can leisurely go through the recorded videos whenever they get time. Also, our highly specialized platform enables remote learning as the learner need not pack their bags and come to an offline location.</p>
                </div>
            </div>
            </div>
            <br>
<br>
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        
<h5>Professional Tutors</h5>
<p>buddi l
    ife handpicks a great team of tutors proficient in their own discipline. They make the learning engaging and informative. In this way, we ensure that learners learn from the best to excel at the subjects. Our tutors refine the subject matter into easily understandable chunks to facilitate a seamless learning experience.</p>
                    </div>
                </div>
                </div>
                <br>
<br>
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                          
<h5>Personalized tutoring</h5>
<p>Most of the learning sessions will be one on one. It helps the learners to share notes and clarify doubts with their tutors. Hence, there is always a smooth flow of communication between the tutor and the learner.</p>
                        </div>
                    </div>
                    </div>
                    <br>
<br>
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                                               
<h5>Learn in your own language</h5>
<p>Don’t let the language barrier stop you from learning things that you are passionate about. buddi life wants everyone to have access to quality learning programs and therefore, we provide the 
    <b>best online tuition classes</b> in your native language. Therefore, learners will have a complete understanding of everything that is taught since it is in a familiar language.</p>
                            </div>
                        </div>
                        </div>
                        <br>
                        <br>                                                                                                                                                                                                                                                                                      
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-12">
                                      
<h5>Explore buddi life now!</h5>
<p>Right from <a href="cbse-online-tuition"> CBSE online tuition</a> to advanced courses like GMAT, TOEFL, and IELTS, we have everything. The learners can pick the subjects that they are interested in from 800+ courses.</p>
<p>
    In addition, our platform provides smart materials and recorded videos for the learner’s benefit. The tutors will also host live classes to clarify doubts and guide the learners with their careers.</p>
<p>Excel at your career with buddi life. Enroll now!</p>
                                    </div>
                                </div>
                                </div>
                                <br>
                                <br>