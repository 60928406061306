import { Directive,ElementRef } from '@angular/core';

@Directive({
  selector: '[labelStyle]'
})
export class CommonTextColorDirective {

  constructor(private elem:ElementRef) { 
  	elem.nativeElement.style.color = '#00adee'
  }

}
