import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../../shared/api.service';
import { MediaService } from '../../../../shared/media.service';
import { Toastr, ToastrManager } from 'ng6-toastr-notifications';
import { Location } from '@angular/common';

@Component({
  selector: 'app-student-correction-result',
  templateUrl: './student-correction-result.component.html',
  styleUrls: ['./student-correction-result.component.scss']
})
export class StudentCorrectionResultComponent implements OnInit {
  public isquestion: any = true;
  time: number = 0;
  public TestData: any = [];
  public resultdata: any = [];
  public TestImages: any = [];
  public livedetails: any = [];
  public answerimages: any = [];
  public marksperquestion: any = [];
  public submissionid: any;
  totaltime: number = 0;
  public url = "";
  interval;
  display;
  public ischecked: boolean = false;
  createForm: FormGroup;
  public uploadimage: boolean = false;
  public isteacherRoute: boolean = false;
  public buttonnext: boolean = true;
  public buttonprev: boolean = false;
  public buttonlast: boolean = false;
  public showimageinmodal = "";
  public showanswerimageinmodal = "";
  formDatasubjective;
  images = [];
  uploadingimages = [];
  fileList: File[] = [];
  public questioncount: number = 0;
  public answercount: number = 0;
  public totalquestioncount: number = 0;
  public subjectname1array: any = [];
  public questionimage = "";

  constructor(private mediaAPI: MediaService, private location: Location, private formBuilder: FormBuilder, private my_router: ActivatedRoute, public toastr: ToastrManager, private rest: ApiService, private router: Router) { }

  ngOnInit(): void {
    this.createForm = this.formBuilder.group({
      marks: [''],
      remarks: ['']
    });
    this.isteacherRoute = localStorage.getItem('profileType').toLowerCase() == 'teacher'
    var token = localStorage.getItem('webtoken');
    var req = {
      submissionID : this.my_router.snapshot.params.id
    }
    this.mediaAPI.getstudentcorrectedtests(req,token).subscribe((result) => {
      if (result.status) {
        const data = this.createForm.value;
          this.TestData = result.data;
        this.TestImages = this.TestData.testImages;
        this.answerimages = this.TestData.answerImages;
        this.marksperquestion = this.TestData.marksPerQuestion;
        this.totalquestioncount = this.TestImages.length;
        for (var i = 0; i < this.TestImages.length; i++) {
          this.subjectname1array.push(this.TestImages[i]);

        }
        this.questioncount = 1;
        this.answercount = 1;
        let item1 = this.TestImages.find(i => i.imageNumber === this.questioncount);
        this.url = item1.imagePath;
        if (this.marksperquestion[this.questioncount - 1].marks !== undefined) {
          this.createForm.patchValue({ marks: this.marksperquestion[this.questioncount - 1].marks })
        }
        if (this.marksperquestion[this.questioncount - 1].remarks !== undefined) {
          this.createForm.patchValue({ remarks: this.marksperquestion[this.questioncount - 1].remarks })
        }
      }
    }, (err) => {
      console.log("errormessage", err);
    });
  }





  changed(evt) {
    this.ischecked = evt.target.checked;
  }


  onFileChange(event) {
    this.formDatasubjective = new FormData();
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.images.push(event.target.result);
        }
        reader.readAsDataURL(event.target.files[i]);
        var selectedFile = event.target.files[i];
        this.fileList.push(selectedFile);
      }
    }
  }

  imageselected(url) {
    this.showimageinmodal = url;
  }

  removeSelectedFile(index) {
    // Delete the item from fileNames list
    this.images.splice(index, 1);
    this.fileList.splice(index, 1);
  }

  uploadimages() {
    var token = localStorage.getItem('webtoken');
    this.formDatasubjective.append("submissionID", this.TestData._id);
    for (let i = 0; i < this.fileList.length; i++) {
      let imagename = i + 1 + "_" + this.fileList[i].name;

      this.formDatasubjective.append("answer_files", this.fileList[i], imagename);
    }
    this.rest.studentfinishtest(this.formDatasubjective, token).subscribe((result) => {
      if (result.status) {
        this.toastr.successToastr(result.message);
        this.router.navigate(['/StudentCourseDetails/' + this.livedetails.topicID.courseID]);
      }
      else {
        this.toastr.errorToastr(result.message);
      }
    });
  }

  questionnumberbutton(count) {
    this.questioncount = count;
    this.createForm.controls['marks'].reset();
    this.createForm.controls['remarks'].reset();

    let item1 = this.TestImages.find(i => i.imageNumber === count);
    this.url = item1.imagePath;
    if (this.marksperquestion[this.questioncount - 1].marks !== undefined) {
      this.createForm.patchValue({ marks: this.marksperquestion[this.questioncount - 1].marks })
    }
    if (this.marksperquestion[this.questioncount - 1].remarks !== undefined) {
      this.createForm.patchValue({ remarks: this.marksperquestion[this.questioncount - 1].remarks })
    }
  }

  nextbutton() {
    if (this.answercount + 1 > this.answerimages.length) {
    }
    else {
      this.answercount = this.answercount + 1
      let item1 = this.answerimages.find(i => i.imageNumber === this.answercount);
      this.showanswerimageinmodal = item1.imagePath;
    }
  }

  previousbutton() {
    if (this.answercount - 1 <= 0) {
    }
    else {
      this.answercount = this.answercount - 1
      let item1 = this.answerimages.find(i => i.imageNumber === this.answercount);
      this.showanswerimageinmodal = item1.imagePath;
    }

  }

  nextquestion() {
    const data = this.createForm.value;
    this.marksperquestion.find(item => item.questionNumber == this.questioncount).marks = data.marks;
    this.marksperquestion.find(item => item.questionNumber == this.questioncount).remarks = data.remarks;
    if (this.questioncount + 1 == this.TestImages.length) {
      this.buttonnext = false;
      this.buttonprev = true;
      this.buttonlast = true;

      this.questioncount = this.questioncount + 1
      let item1 = this.TestImages.find(i => i.imageNumber === this.questioncount);
      this.url = item1.imagePath;
      this.createForm.controls['marks'].reset();
      this.createForm.controls['remarks'].reset();
      if (this.marksperquestion[this.questioncount - 1].marks !== undefined) {
        this.createForm.patchValue({ marks: this.marksperquestion[this.questioncount - 1].marks })
      }
      if (this.marksperquestion[this.questioncount - 1].remarks !== undefined) {
        this.createForm.patchValue({ remarks: this.marksperquestion[this.questioncount - 1].remarks })
      }
    }
    else {
      this.buttonnext = true;
      this.buttonprev = true;
      this.buttonlast = false;
      this.questioncount = this.questioncount + 1
      let item1 = this.TestImages.find(i => i.imageNumber === this.questioncount);
      this.url = item1.imagePath;
      this.createForm.controls['marks'].reset();
      this.createForm.controls['remarks'].reset();
      if (this.marksperquestion[this.questioncount - 1].marks !== undefined) {
        this.createForm.patchValue({ marks: this.marksperquestion[this.questioncount - 1].marks })
      }
      if (this.marksperquestion[this.questioncount - 1].remarks !== undefined) {
        this.createForm.patchValue({ remarks: this.marksperquestion[this.questioncount - 1].remarks })
      }
    }
    console.log("next", this.marksperquestion);
  }

  previousquestion() {
    const data = this.createForm.value;
    if (this.questioncount - 1 == 1) {
      this.buttonprev = false;
      this.buttonnext = true;
      this.buttonlast = false;
      this.questioncount = this.questioncount - 1
      this.createForm.controls['marks'].reset();
      this.createForm.controls['remarks'].reset();
      if (this.marksperquestion[this.questioncount - 1].marks !== undefined) {
        this.createForm.patchValue({ marks: this.marksperquestion[this.questioncount - 1].marks })
      }
      if (this.marksperquestion[this.questioncount - 1].remarks !== undefined) {
        this.createForm.patchValue({ remarks: this.marksperquestion[this.questioncount - 1].remarks })
      }
      let item1 = this.TestImages.find(i => i.imageNumber === this.questioncount);
      this.url = item1.imagePath;
    }
    else {
      this.buttonnext = true;
      this.buttonprev = true;
      this.buttonlast = false;
      this.questioncount = this.questioncount - 1
      this.createForm.controls['marks'].reset();
      this.createForm.controls['remarks'].reset();
      if (this.marksperquestion[this.questioncount - 1].marks !== undefined) {
        this.createForm.patchValue({ marks: this.marksperquestion[this.questioncount - 1].marks })
      }
      if (this.marksperquestion[this.questioncount - 1].remarks !== undefined) {
        this.createForm.patchValue({ remarks: this.marksperquestion[this.questioncount - 1].remarks })
      }
      let item1 = this.TestImages.find(i => i.imageNumber === this.questioncount);
      this.url = item1.imagePath;
    }

  }

  lastquestion() {
    const data = this.createForm.value;
    this.marksperquestion.find(item => item.questionNumber == this.questioncount).marks = data.marks;
    this.marksperquestion.find(item => item.questionNumber == this.questioncount).remarks = data.remarks;
    this.toastr.successToastr("Saved Successfully! Click End test to finish correction.");
    this.buttonlast = false;
  }

  viewanswerimage() {
    let item1 = this.TestImages.find(i => i.imageNumber === this.answercount);
    this.showanswerimageinmodal = item1.imagePath;
  }


  uploadanswerimage() {
    this.isquestion = true;
    this.uploadimage = true;
  }

  back() {
    this.isquestion = true;
    this.uploadimage = false;
  }

  endtest() {
    var token = localStorage.getItem('webtoken');
    let sum: number = this.marksperquestion.map(a => a.marks).reduce(function (a, b) {
      return a + b;
    });

    var req = {
      submissionID: this.my_router.snapshot.params['id'],
      marksObtained: sum,
      marksPerQuestion: this.marksperquestion
    }

    this.mediaAPI.teacherfinishcorrection(req, token).subscribe((result) => {
      if (result.status) {
        this.toastr.successToastr(result.message);
        this.location.back();
      }
      else {
        this.toastr.errorToastr(result.message);
      }
    }, (err) => {
      console.log("errormessage", err);
    });

  }

  backlocation() {
    this.location.back();
  }

}
