<div class="container">
    <app-header-style-one *ngIf="!isTeacher"></app-header-style-one>
    <app-header-style-one-teacher *ngIf="isTeacher"></app-header-style-one-teacher>
    <br />
    <br />
<div *ngIf="isStudent == true && isTeacher == false" class="container mt-3">
    <h3 (click)="redirectLiveclass()" style="color: #000000; cursor: pointer" class="col-lg-12">
        Live Classes  <span class="more-text" *ngIf="classList.length!=0">more</span>
    </h3>
    <!-- <h3 (click)="redirect()" style="color:var(--mainColor);cursor: pointer" class="col-lg-12" >
        Video Classes 
      </h3> -->
    <div class="container-fluid row" style="cursor: pointer">
        <div
            class="col-lg-4 col-md-6 float:left"
            *ngFor="let slide of classList"
        >
        <div class="single-courses-item mb-30 p-4">
            <div class="courses-image">
                <!-- <a routerLink="javascript:void(0)" (click)="detailsMeeting(slide, 'Livesession')" class="d-block"><img src={{slide.thumbnail}} alt="image"></a> -->
            </div>
            <h3><a routerLink="javascript:void(0)" (click)="liveClassDetails(slide)"
                class="d-inline-block" style="height:60px">{{slide.className}}</a></h3>
            <div style="overflow: hidden;">
                <p style="float:left"><i class='bx bx-calendar'></i> {{slide.startDateTime}}</p>
                <!-- <p style="float:right">{{slide.languageID.languageName}}</p> -->
                <p style="float:right">{{slide.totalClassTime ? slide.totalClassTime : 30}} mins</p> 

            </div>
            <div class="courses-content px-0 my-3">

                <div class="course-author d-flex align-items-center">
                    <img [src]="slide.teacherID && slide.teacherID.userPhoto && slide.teacherID.userPhoto != '' ? slide.teacherID.userPhoto : 'assets/img/profilePhotoPlaceholder1.png' "
                        class="shadow" alt="image">
                        <div class="w-100 d-flex justify-content-between">
                            <span>{{slide.teacherID ? slide.teacherID.profileName : '' }}</span>
                <span>{{slide.languageID.languageName}}</span>
                        </div>
                    
                </div>

                <div *ngIf="slide.rating && slide.rating != 0" class="courses-rating">
                    <div class="review-stars-rated">
                        <i *ngIf="slide.rating < 1" class='bx bxs-star-half'></i>
                        <i *ngIf="slide.rating >= 1" class='bx bxs-star'></i>
                        <i *ngIf="slide.rating >= 1.5 && slide.rating < 2" class='bx bxs-star-half'></i>
                        <i *ngIf="slide.rating >= 2" classd-flex align-items-center='bx bxs-star'></i>
                        <i *ngIf="slide.rating >= 3 && slide.rating > 3.5" class='bx bxs-star-half'></i>
                        <i *ngIf="slide.rating >= 4" class='bx bxs-star'></i>
                        <i *ngIf="slide.rating >= 4 && slide.rating < 4.5" class='bx bxs-star-half'></i>
                        <i *ngIf="slide.rating >= 5" class='bx bxs-star'></i>
                    </div>
                    <div class="rating-total">
                        {{slide.rating}}
                    </div>
                </div>



            </div>
            <div class="gradeContent">
                <h4 *ngIf= "slide.categoryID">{{ slide.categoryID.categoryName}}</h4>
                <!-- <p>{{slide.courseID.courseName}}</p> -->
                <p class="text-overflow" *ngIf= "slide.moduleID">{{slide.moduleID.moduleName}}</p>
                    <p class="text-overflow" *ngIf= "slide.topicID">{{slide.topicID.topicName}}</p>
            </div>
            <!-- <div *ngIf="isStudent || !isLogin">
                <div class="button_centers">
                <div class="btn">
                    <button (click)="detailsMeeting(slide, 'Livesession')" class="">
                        Enroll
                    </button>
                </div>
                <div class="btn">
                    <button (click)="redirectshare()" class="">
                        Share
                    </button>
                </div>
            </div>
</div> -->
            <div class="courses-box-footer mt-3">
                <ul>
                    <li class="courses-lesson">
                        <i class='fa fa-rupee-sign'></i>
                        {{slide.price != 0 ? slide.price : 'Free'}}

                        <!-- <i class='bx bx-book-open'></i> {{slide.totalClassTime ? slide.totalClassTime : 30}} mins -->
                    </li>
                    <li class="courses-lesson _leeson_2">
                        <i class='bx bx-book-open'></i> Level {{slide.level ? slide.level : 1}}
                    </li>
                    <!-- <li class="courses-lesson"> 
                        {{slide.price != 0 ? slide.price : 'Free'}}
                    </li> -->
                    <!-- <li class="courses-lesson _leeson_2"> <i class='fa fa-rupee-sign'></i>
                        *******
                    </li>
                    <li class="courses-lesson"> <i class='fa fa-rupee-sign'></i>
                        INR 1999.00/buddi
                    </li>
                    <li class="courses-lesson _leeson_2">
                        Rating </li> -->
                </ul>
            </div>
        </div>
        </div>
        <div
            style="color: var(--mainColor)"
            class="col-lg-12"
            *ngIf="classList.length == 0"
        >
            No Classes Available.
        </div>
    </div>
</div>

<div *ngIf="isTeacher == true" class="container mt-3">
    <h3 style="color: #000000; cursor: pointer" class="col-lg-12">
        Live Classes
    </h3>
    <div class="container-fluid row" style="cursor: pointer">
        <div
            class="col-lg-4 col-md-6 float:left"
            *ngFor="let class of classList"
        >
        <div class="single-courses-item mb-30 p-4">
            <h3><a routerLink="javascript:void(0)" (click)="liveClassDetails(class)" class="d-inline-block" style="height:60px">{{class.name}}</a></h3>
            <div style="overflow: hidden;">
                <p style="float:left"><i class='bx bx-calendar'></i> {{class.startDateTime ? class.startDateTime : 'Class Template'}}</p>
                <p style="float:right">{{class.totalClassTime ? class.totalClassTime : 30}} mins</p> 
            </div>
            <div class="courses-content px-0 my-3">
        
                <div class="course-author d-flex align-items-center">
                    <img [src]="class.teacherID && class.teacherID.userPhoto && class.teacherID.userPhoto != '' ? class.teacherID.userPhoto : 'assets/img/profilePhotoPlaceholder1.png' "
                        class="shadow" alt="image">
                        <div class="w-100 d-flex justify-content-between">
                            <span *ngIf="class.personalClassID">{{class.fullName  ? class.personalClassID.teacherID.profileName : '' }}</span>
                <span *ngIf="class.personalClassID">{{class.personalClassID.languageID.languageName}}</span>
                        </div>
                    
                </div>
        
                <div *ngIf="class.rating && class.rating != 0" class="courses-rating">
                    <div class="review-stars-rated">
                        <i *ngIf="class.rating < 1" class='bx bxs-star-half'></i>
                        <i *ngIf="class.rating >= 1" class='bx bxs-star'></i>
                        <i *ngIf="class.rating >= 1.5 && class.rating < 2" class='bx bxs-star-half'></i>
                        <i *ngIf="class.rating >= 2" classd-flex align-items-center='bx bxs-star'></i>
                        <i *ngIf="class.rating >= 3 && class.rating > 3.5" class='bx bxs-star-half'></i>
                        <i *ngIf="class.rating >= 4" class='bx bxs-star'></i>
                        <i *ngIf="class.rating >= 4 && class.rating < 4.5" class='bx bxs-star-half'></i>
                        <i *ngIf="class.rating >= 5" class='bx bxs-star'></i>
                    </div>
                    <div class="rating-total">
                        {{class.rating}}
                    </div>
                </div>
        
        
        
            </div>
            <div class="gradeContent">
                <h4 *ngIf="class.personalClassID">{{class.personalClassID.categoryID ? class.personalClassID.categoryID.categoryName : ''}}</h4>
                <!-- <p>{{slide.courseID.courseName}}</p> -->
                <p class="text-overflow" *ngIf="class.personalClassID"> {{ class.personalClassID.moduleID
                    ? class.personalClassID.moduleID.moduleName
                    : "NA" }}</p>
                    <p class="text-overflow" *ngIf="class.personalClassID"> {{ class.personalClassID.topicID
                        ? class.personalClassID.topicID.topicName
                        : "NA" }}</p>
            </div>
           
            <div class="courses-box-footer mt-3">
                <ul>
                    <li class="courses-lesson" *ngIf="class.personalClassID">
                        <i class='fa fa-rupee-sign'></i>
        
                        {{class.personalClassID.price != 0 ? class.personalClassID.price : 'Free'}}
        
                        <!-- <i class='bx bx-book-open'></i> {{slide.totalClassTime ? slide.totalClassTime : 30}} mins -->
                    </li>
                    <li class="courses-lesson _leeson_2" *ngIf="class.personalClassID">
                        <i class='bx bx-book-open'></i> Level {{class.personalClassID.level ? class.personalClassID.level : 1}}
                    </li>
                </ul>
            </div>
        </div>
        </div>
        <div
            style="color: var(--mainColor)"
            class="col-lg-12"
            *ngIf="classList.length == 0"
        >
            No Classes Available.
        </div>
    </div>
</div>
</div>