   <!-- <div class="content">

    <div class=" col-md-12 banner">
           <div class="container">
               <div class="row">
       <div class="head">
       <h1>Welcome to buddiCERT
           </h1>
          <p> NFT Market Place for all you Educational Assets.
          </p>
          <h1>Explore, collect, and<br> sell Educational</h1>
          <a routerLink="/getstarted" ><button class="btn">Get Started</button></a>
            </div>
            
   </div>
   </div>
   </div>     
   
   </div>  -->

  
  
   














<video  id="myVideo" autoplay muted loop controls style="margin-bottom: -8px;">
  <source src="../../../../assets/img/Metaverse_v1.mp4" alt="Metaverse_v1"  type="video/mp4">
</video>

<div class="content">
 
    
    
    <div class=" col-md-12 banner">
        <div class="container">
            <div class="row">
    <div class="head">
      <h1>Welcome to buddiCERT
      </h1>
     <p> NFT Market Place for all you Educational Assets.
     </p>
     <!-- <h1>Explore, collect, and<br> sell Educational</h1> -->
             <a routerLink="/getstarted" ><button class="btn">Get Started</button></a>
</div>
    
</div>
</div>
</div>

</div>
