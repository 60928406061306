<!-- From Madurai team created a new header. In our project old header only need like explore, webinar, classrooms -->
<!-- Changed by Madhan Kumar -->
<!-- Below code new header -->

<!-- <div class="row">
    <div class="col-lg-3 d-flex mt-3 justify-content-center" style="border-right:1px solid rgb(242,244,249)">
       <ul>
           <li>
                <a  routerlink="/student/home" ng-reflect-router-link="/student/home" href="/student/home">
                    <img  class="logo" src="assets/img/black-logo.png" alt="logo">
                </a>
           </li>
       </ul>
        
    </div>
    <div class="col-lg-9 mt-3 boxb">
        <form [formGroup]="dashboard" >
            <div class="row">
                <div class="col-lg-2  mt-2">
                    Dashboard | <i class="fas fa-home"></i>
                </div>
                <div class="col-lg-8" *ngIf="data.length!=0">
                    <select (change)="changeCategory($event)" class="browser-default  custom-select  width20 mr-1" formControlName="categoryID">
                        <option value="" disabled>Select Category</option>
                        <option *ngFor="let obj of data.categoryList" [ngValue]="obj._id">{{obj.categoryName}}</option>
                    </select>
                    boardName : <span class="setcolor">{{data.studentDetails.board.boardName}} </span> gradeName : <span class="setcolor">{{data.studentDetails.grade.gradeName}}</span>  languageName :  <span class="setcolor">{{data.studentDetails.language.languageName}}</span> 
                    <select  class="browser-default  custom-select  width20 mr-1" formControlName="languageID" >
                        <option value="" disabled>Select Category</option>
                        <option *ngFor="let obj of data.languages" [ngValue]="obj._id">{{obj.languageName}}</option>
                    </select>
                </div>
                <div class="col-lg-1  mt-2">
                    <a class="nav-link" [routerLink]="" (click)="logout()" >Logout</a>
                </div>
                <div class="col-lg-1">
                    <a  href="javascript:void(0)" id="notificationDropLink"  class="nav-link dropdown-toggle">
                    <i class="bx bxs-bell bx-tada-hover"></i>
                    <sup >0</sup></a>
                </div>
            </div>
        </form>
    </div>
</div> -->

<!-- old Header -->
<!-- <app-header-style-one></app-header-style-one>
<div class="row">
    <div class="col-3 p-0 text-center boxl">
        <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <a class="nav-link" role="tab">
                <img alt="image" class="imageStyle shadow"
                    src="https://s3.ap-south-1.amazonaws.com/buddi.bucket/amaithi5f4dfaa35a0448402de5e102/Photos/5f4dfaa35a0448402de5e102-1610957531186.jpeg">
            </a>
            <a class="nav-link" role="tab">
                Amaithi SIngam
            </a>
            <a class="nav-link active" id="dashboard-tab" data-toggle="pill" href="#dashboard" role="tab"
                aria-controls="dashboard" aria-selected="false">Dashboard</a>
            <a class="nav-link " id="learning-tab" data-toggle="pill" href="#learning" role="tab"
                aria-controls="learning" aria-selected="true">My Learning</a>
            <a class="nav-link" id="profile-tab" data-toggle="pill" href="#profile" role="tab" aria-controls="profile"
                aria-selected="false">My Profile</a>
            <a class="nav-link" id="tests-tab" data-toggle="pill" href="#tests" role="tab" aria-controls="tests"
                aria-selected="false">Account Settings</a>
            <a class="nav-link" id="account-tab" data-toggle="pill" href="#account" role="tab" aria-controls="account"
                aria-selected="false">Payment History</a>
            <a class="nav-link " id="myclass-tab" data-toggle="pill" href="#myclass" role="tab" aria-controls="myclass"
                aria-selected="false">Notifications</a>
            <a class="nav-link" id="library-tab" data-toggle="pill" href="#library" role="tab" aria-controls="library"
                aria-selected="false">Messages</a>
            <a class="nav-link" id="study-tab" data-toggle="pill" href="#study" role="tab" aria-controls="study"
                aria-selected="false">Logout</a>
        </div> -->
        <div *ngIf="profiletype=='student'">
           <app-logpage></app-logpage>
        </div>
        <div *ngIf="profiletype=='teacher'">
            <app-logpagetutor></app-logpagetutor>
        </div>
        <div class="row">
            <div class="col-2 py-0 px-4 text-left boxl" style="font-size:13px;">
                <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <div *ngIf="profiletype=='student'">
                        <a class="nav-link" role="tab">
                            <img alt="image" class="imageStyle shadow"
                                [src]="userprofdata.userPhoto =='' ? 'assets/img/profile300.png':userprofdata.userPhoto">
                        </a>
                        <a class="nav-link username" role="tab">
                            Hi,{{userprofdata.profileName}}
                        </a>
                        <div class="nav-link username"  role="tab">
                            <h6>Your Code:</h6>
                            <div style="display: flex;"> 
                                <a id="text" class="trim-info" style="font-size: 11px;">{{userprofdata._id}}</a>
                                <i class="far fa-copy" data-toggle="tooltip" data-placement="bottom" title="Copy" style="color: var(--maincolor);cursor: pointer;margin-left: 4px;" (click)="copyToClipboard('#text')"></i>
                            </div>
                          
                        </div>
                    </div>
                    <div *ngIf="profiletype=='teacher'">
                        <a class="nav-link" role="tab">
                            <img alt="image" class="imageStyle shadow"
                                [src]="userprofdataTeacher.userPhoto =='' ? 'assets/img/profile300.png':userprofdataTeacher.userPhoto">
                        </a>
                        <a class="nav-link username" role="tab">
                            Hi,{{userprofdataTeacher.profileName}}
                        </a>
                        <div class="nav-link username"  role="tab">
                            <h6>Your Code:</h6>
                            <div style="display: flex;"> 
                                <a id="text" class="trim-info" style="font-size: 11px;">{{userprofdataTeacher._id}}</a>
                                <i class="far fa-copy" data-toggle="tooltip" data-placement="bottom" title="Copy" style="text-decoration: underline;color: var(--maincolor);cursor: pointer;margin-left: 4px;" (click)="copyToClipboard('#text')"></i>
                            </div>
                          
                        </div>
                    </div>
             
                   <!-- <div class="anyClass"  data-spy="scroll"> -->
                       <!-- <a class="nav-link  customLinkParent" id="summary-tab" data-toggle="pill" href="#summary" role="tab"aria-controls="summary" aria-selected="false">
                       <i class="fas fa-chart-line"></i><b>Dashboard</b></a> -->
                       <a class="nav-link  customLinkParent" id="upcoming-tab" data-toggle="pill" href="#upcoming" role="tab"aria-controls="summary" aria-selected="false">
                        <i class="fas fa-chart-line"></i><b>Dashboard</b></a>
                       <a class="nav-link customLinkParent" id="upcoming-tab" data-toggle="pill" href="#upcoming"  role="tab" aria-controls="upcoming" aria-selected="false">
                       <i class="fas fa-chalkboard-teacher"></i><b>UpComing</b></a>
                       <a class="nav-link customLinkParent" id="completed-tab" data-toggle="pill" href="#completed" role="tab" aria-controls="completed" aria-selected="false">
                       <i class="far fa-list-alt"></i><b>Completed</b></a>
                       <a class="nav-link customLinkParent" id="profile-show-tab" data-toggle="pill" href="#profileshow" role="tab" aria-controls="profileshow" aria-selected="false">
                       <i class="far fa-user-circle"></i><b>Profile</b></a>
                       <a class="nav-link customLinkParent has" *ngIf="profiletype=='teacher'"  style="cursor: pointer;" data-toggle="modal" data-target="#exampleModalCenter"  role="tab"
                       aria-selected="false"><i class='fas fa-institution'></i><b> Invite Students</b></a>
                       <a class="nav-link customLinkParent" id="nft-tab" data-toggle="pill" href="#nft" role="tab" aria-controls="nft" aria-selected="false">
                       <i class="fa fa-ils"></i><b>NFT Assets</b></a>
                       <a class="nav-link customLinkParent" id="pay-tab" data-toggle="pill" href="#paymentwallet" role="tab" aria-controls="pay" aria-selected="false">
                       <i class='fas fa-credit-card'></i><b>Payments</b></a>
                       <a class="nav-link customLinkParent" id="setting-tab" data-toggle="pill" href="#setting" role="tab" aria-controls="settings" aria-selected="false">
                       <i class="fa fa-gear"></i><b>Settings</b></a>
        
                       <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                       <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content share_content">
                               <div class="modal-header">
                                   <h5 class="modal-title"id="exampleModalCenterTitle">
                                    Share with
                                   </h5>
                                   <button  type="button" class="close" data-dismiss="modal"  aria-label="Close" >
                                       <span aria-hidden="true">&times;</span>
                                   </button>
                               </div>
                               <div class="fly">
                                   <div class="social_icon_section" (click)="invite()">
                                      <i class="fa fa-whatsapp what" data-toggle="tooltip" data-placement="right" title="Click here" style="font-size: 42px;color:#25D366;margin-bottom: 8px;"></i>
                                      <strong >Whatsapp</strong>
                                   </div>
                                   <div class="social_icon_section" (click)="invite1()">
                                      <i class="fa fa-telegram" data-toggle="tooltip" data-placement="right" title="Click here" style="font-size: 42px;color: #0088cc;margin-bottom: 8px;"></i>
                                      <strong >Telegram</strong>
                                </div>
                               </div>
                           </div>
                           
                       </div>
                   </div>
                    <!-- <a class="btn" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false"
                        aria-controls="collapseExample">
                        Upcoming Classes</a> -->
        
                    <!-- <div class="collapse show" id="collapseExample"> -->
                        <!-- <nav> -->
                           
                            <!-- <div class="nav nav-tabs nav-fill " id="nav-tab" role="tablist"> -->
                                <!-- <a class="nav-item nav-link active " id="studyhall-tab" data-toggle="tab"
                                    href="#upcomingLiveClass" role="tab" aria-controls="nav-home"
                                    aria-selected="true"><i class="	fas fa-user-friends"></i> Live Classes</a>
                                <a class="nav-item nav-link" id="studyhall-tab" data-toggle="tab"
                                    href="#upcomingcoursesStudyhall" role="tab" aria-controls="nav-home"
                                    aria-selected="false"><i class="fas fa-users-cog"></i> Study Hall</a>
                                <a class="nav-item nav-link" id="roundtable-tab" data-toggle="tab"
                                    href="#upcomingcoursesRoundtable" role="tab" aria-controls="nav-home"
                                    aria-selected="false"><i class="fas fa-users"></i> Round Table</a>
                                <a class="nav-item nav-link" id="tests-tab" data-toggle="tab"
                                    href="#upcomingTests" role="tab" aria-controls="nav-home"
                                    aria-selected="false"><i class="fas fa-edit"></i>Evaluation Tests</a>
        
                                <a class="nav-item nav-link" id="smart-material-tab" data-toggle="tab"
                                    href="#upcomingSmartMaterial" role="tab" aria-controls="nav-home"
                                    aria-selected="false"><i class="fas fa-book-reader"></i>Study Materials</a>
                                <a class="nav-item nav-link" id="videos-tab" data-toggle="tab"
                                    href="#upcomingVideos" role="tab" aria-controls="nav-home"
                                    aria-selected="false"><i class="far fa-file-video"></i> Video Classes</a> -->
                                    <!-- <a class="nav-item nav-link" id="completed-studyhall-tab" data-toggle="tab"
                                    href="#completedStudyhall" role="tab" aria-controls="nav-home"
                                    aria-selected="false"><i class="fas fa-book-reader"></i> Study Hall</a>
                                <a class="nav-item nav-link" id="completed-roundtable-tab" data-toggle="tab"
                                    href="#completedRoundtable" role="tab" aria-controls="nav-home"
                                    aria-selected="false"><i class="far fa-file-video"></i> Round Table</a> -->
                                <!-- <a class="nav-item nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="nav-home" aria-selected="false">
                                <i class="far fa-user-circle"></i>Basic Profile</a>
                                <a class="nav-link customLinkParent" id="advanced-tab" data-toggle="pill" href="#advancedProfile" role="tab" aria-controls="advanced" aria-selected="false">
                                <i class="far fa-address-card"></i>Advanced Profile</a>
                                <a class="nav-link customLinkParent" *ngIf="profiletype=='teacher'" routerLink="/institution-details"aria-selected="false">
                                <i class='fas fa-institution'></i>Institutions</a> -->
                            <!-- </div> -->
                            <!-- <a class="nav-link customLinkParent" id="pay" data-toggle="pill" href="#paymentwallet" routerLink="/coinpayment"role="tab" aria-controls="pay" aria-selected="false">
                            <i class="far fa-user-circle"></i><b>Payment</b></a>
                            <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                <a class="nav-item nav-link" id="completed-studyhall-tab" data-toggle="tab"
                                    href="#completedStudyhall" role="tab" aria-controls="nav-home"
                                    aria-selected="false"><i class="fas fa-book-reader"></i> Study Hall</a>
                                <a class="nav-item nav-link" id="completed-roundtable-tab" data-toggle="tab"
                                    href="#completedRoundtable" role="tab" aria-controls="nav-home"
                                    aria-selected="false"><i class="far fa-file-video"></i> Round Table</a>
                                </div> -->
                        <!-- </nav> -->
                    <!-- </div> -->
                        <!-- <a class="nav-link active" href="#upcomingcoursesStudyhall" id="studyhall-tab" data-toggle="pill"
                            role="tab" aria-controls="upcomingcoursesStudyhall" aria-selected="false"> </a>
        
                        <a class="nav-link" href="#upcomingcoursesRoundtable" id="roundtable-tab" data-toggle="pill" role="tab"
                            aria-controls="upcomingcoursesRoundtable" aria-selected="false"> </a> -->
                    <!-- <a class="nav-link customLinkParent" id="payment-wallet-tab" data-toggle="pill" href="#payment" role="tab" aria-controls="payment"
                        aria-selected="false"><i class="fas fa-file-invoice"></i>Currency Wallet</a>
                    <a class="nav-link customLinkParent " id="notifictions-tab" data-toggle="pill" href="#notifications" role="tab"
                        aria-controls="notifications" aria-selected="false"><i class="far fa-bell"></i>Currency Transactions History</a>
                    <a class="nav-link customLinkParent " id="notifictions-tab" data-toggle="pill" href="#notifications" role="tab"
                        aria-controls="notifications" aria-selected="false"><i class="far fa-bell"></i>buddi Coin Wallet</a>
                    <a class="nav-link customLinkParent " id="notifictions-tab" data-toggle="pill" href="#notifications" role="tab"
                        aria-controls="notifications" aria-selected="false"><i class="far fa-bell"></i>buddi Coin Transactions History</a>
                    <a class="nav-link customLinkParent " id="notifictions-tab" data-toggle="pill" href="#notifications" role="tab"
                        aria-controls="notifications" aria-selected="false"><i class="far fa-bell"></i>buddi Rewards</a>
                    <a class="nav-link customLinkParent " id="notifictions-tab" data-toggle="pill" href="#notifications" role="tab"
                        aria-controls="notifications" aria-selected="false"><i class="far fa-bell"></i>Invoices</a>
                    <a class="nav-link customLinkParent " id="notifictions-tab" data-toggle="pill" href="#notifications" role="tab"
                        aria-controls="notifications" aria-selected="false"><i class="far fa-bell"></i>NFT's Assets Transactions</a> -->
                    <!-- <a class="nav-link customLinkParent" *ngIf="profiletype=='student'" routerLink="/student/login"
                        aria-selected="false"><i class="fas fa-sign-out-alt"></i><b> Logout</b></a>
                    <a class="nav-link customLinkParent" *ngIf="profiletype=='teacher'" routerLink="/teacher/login"
                        aria-selected="false"><i class="fas fa-sign-out-alt"></i> <b>Logout</b></a> -->
                    <!-- </div> -->
                </div>
            </div>
            <div class="col-10 p-0 dashboard_bg">
                <div class="tab-content no-border" id="v-pills-tabContent">
                    <div>
                        <app-subject-nav [data]=data (dropdownData)="receivedData($event)" [emitData]=emitData>
                        </app-subject-nav>
                    </div>
                </div>
            </div>
        </div>