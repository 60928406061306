<app-header-style-one></app-header-style-one>


<!-- <div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About</li>
            </ul>
            <h2>About Us</h2>
        </div>
    </div>
</div> -->

<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
           

            <div class="col-lg-10 col-md-12">
                <div class="about-content">
                    <span class="sub-title">LEGAL TERMS OF USE FOR SCENTRIC NETWORKS CUSTOMERS</span>
                    <p>Thank you for joining the “buddi family”. We at Scentric Networks (“Scentric
                        Networks”, “we”, “us”) respect your privacy and want you to understand how we collect, use
                        and share data about you. This Privacy Policy covers our data collection practices and describes
                        your rights to access, correct, or restrict our use of your personal data
                        Unless we link to a different policy or state otherwise, this Privacy Policy applies when you visit
                        or use our website, mobile applications, APIs or related services (the “Services”).
                        By using the Services, you agree to the terms of this Privacy Policy. You shouldn’t use the
                        Services if you don’t agree with this Privacy Policy or any other agreement that governs your use
                        of the Services.</p>
                    
                        <span class="sub-title">Table of Contents:</span>
                        
                        <p> 1. What Data We Get</p>
                        <p> 2. How We Get Data About You</p>
                        <p> 3. What We Use Your Data For</p>
                        <p> 4. Who We Share Your Data With</p>
                        <p> 5. Security</p>
                        <p> 6. Your Rights</p>
                        <p> 7. Jurisdiction-Specific Rules</p>
                        <p> 8.Updates & Contact Info</p>
                        <p> Cookie Policy</p>

                            <span class="sub-title">1. What Data We Get</span>
                            <p>We collect certain data from you directly, like information you enter yourself, data about your
                                engagement with our content and data from third-party platforms you connect with from our
                                website. We also collect some data automatically, like information about your device and what
                                parts of our Services you interact with or spend time using.</p>
                            
                            <span class="sub-title">   1.1 Data You Provide to Us</span>
                            <p>    We may collect different data from or about you depending on how you use the Services. Below
                                are some examples to help you better understand the data we collect.</p>
                                <p>When you create an account and use the Services, including through a third-party platform, we
                                    collect any data you provide directly, including:</p>
                                <p>Account Data:</p>
                                <p>In order to use certain features (like subscribing to any content from our site or that of third-
                                    party platform services from our site), you need to create a user account. When you create or
                                    
                                    update your account, we collect and store the data you provide, like your email address,
                                    password etc. and assign you a unique identifying number (“Account Data”).</p>

                                <P>Profile Data</P>
                                <P>You can also choose to provide profile information like a photo, headline, website link,
                                    social media profiles or other data. Your Profile Data will be publicly viewable by others.</P>

                                <p>Shared Content</p>
                                <p>Parts of the Services let you interact with other users or share content publicly, including
                                    by posting reviews on content, asking or answering questions, sending messages to
                                    other users or posting photos or other work you upload. Such shared content may be
                                    publicly viewable by others depending on where it is posted.</p>

                                <p>Course Data:</p>
                                <p>When you subscribe to certain content like enrolling in courses or paying for online
                                    classes and other forms of distance learning, we collect certain data including which
                                    courses, assignments and quizzes you’ve started and completed; your exchanges with
                                    instructors, teaching assistants and other enrolled users; and essays, answers to
                                    questions and other items submitted to satisfy course requirements.</p>

                                <p>Subscriber Payment Data</p>
                                <p>If you make purchases, we collect certain data about your purchase (such as your name,
                                    address and postal code) as necessary to process your order. You must provide certain
                                    payment and billing data directly to our payment processing partners, including your
                                    name, credit card information or bank details, address, and postal code. For security, we
                                    do not collect or store sensitive cardholder data, such as full credit card numbers, card
                                    authentication data or net banking details pertaining to your online banking transactions.</p>

                                <p>Content Provider Payment Data</p>
                                <p>If you are a Content Provider, you will have to provide your net banking details such as
                                    Account Number, Account Name, whether Savings or Current account, Bank Name,
                                    Bank Branch Name, Bank IFSC Code and any other details related to payment that we
                                    may ask for, to the Services to receive payments. When you link a payment account, we
                                    collect and use certain information, including your payment account email address,
                                    account ID, physical address or other data necessary for us to send payments to your
                                    account. In order to comply with applicable laws, we also work with trusted third parties
                                    who collect tax information as legally required. This tax information may include details
                                    such as PAN, Aadhaar Number, GST Number, Tax Identification Number and other
                                    personal information necessary for taxation purposes. For security, we do not collect or
                                    store sensitive bank account information. The collection, use and disclosure of your
                                    payment, billing and taxation data is subject to the privacy policy and other terms of your
                                    payment account provider.</p>

                                <p>Data About Your Account on Other Services</p>
                                <p>We may obtain certain information through your social media or other online accounts if
                                    they are connected to your user account with us. If you login to our website via
                                    Facebook or another third-party platform or service, we ask for your permission to
                                    access certain information about that other account. For example, depending on the
                                    platform or service we may collect your name, profile picture, account ID number, login
                                    email address, location, physical location of your access devices, gender, birthday and
                                    list of friends or contacts.</p>
                                <p>Those platforms and services make information available to us through their APIs. The
                                    information we receive depends on what information you (via your privacy settings) or
                                    the platform or service decide to give us.</p>
                                <p>If you access or use our Services through a third-party platform or service, or click on
                                    any third-party links, the collection, use and sharing of your data will also be subject to
                                    the privacy policies and other agreements of that third party.</p>
                                <p>Offers, Promotions and Surveys</p>
                                <p>We may invite you to complete a survey or participate in a promotion (like a contest,
                                    game or challenge), either through the Services or a third-party platform. If you
                                    participate, we will collect and store the data you provide as part of participating, such as
                                    your name, email address, date of birth and/or phone number. That data is subject to
                                    this Privacy Policy unless otherwise stated in the official rules of the promotion or in
                                    another privacy policy. The data collected will be used to administer the promotion or
                                    survey, including for notifying winners and distributing rewards. To receive a reward, you
                                    may be required to allow us to post some of your information publicly (like on a winner’s
                                    page). Where we use a third-party platform to administer a survey or promotion, the third
                                    party’s privacy policy will apply.</p>
                                <p>Communication and Support</p>
                                <p>If you contact us for support or to report a problem or concern (regardless of whether
                                    you have created an account), we collect and store your contact information, messages,
                                    and/or other data about you like your name, email address, location, operating system,
                                    IP address and/or any other data you provide or that we collect through automated
                                    means (which we cover below). We use this data to respond to you and research your
                                    question or concern, in accordance with this Privacy Policy.</p>
                                <p>In respect of all of the above, the data is stored by us and associated with your account.</p>

                                <p>1.2 Data We Collect Through Automated Means</p>
                                <p>When you access the Services (including browsing content), we collect certain data by
                                    automated means, including:</p>
                                <p>System Data</p>
                                <p>Technical data about your computer or device, like your IP address, device type,
                                    operating system type and version, unique device identifiers, browser, browser
                                    language, domain and other systems data, and platform types (“System Data”).</p>
                                <p>Usage Data</p>
                                <p>Usage statistics about your interactions with the Services, including content accessed,
                                    time spent on pages or the Service, pages visited, features used, your search queries,
                                    click data, date and time and other data regarding your use of the Services (“Usage
                                    Data”).</p>
                                <p>Approximate Geographic Data</p>

                                <p>An approximate geographic location, including information like country, city, and
                                    geographic coordinates, calculated based on your IP address.</p>

                                <p>The data listed above is collected through the use of server log files and tracking
                                    technologies, as detailed in the “Cookies and Data Collection Tools” section below. It is
                                    stored by us and associated with your account.</p>

                            <span class="sub-title">2.1 Cookies and Data Collection Tools</span>
                            <p>As detailed in our Cookie Policy, we and service providers acting on our behalf (like
                                Google Analytics and third party advertisers) use server log files and automated data
                                collection tools like cookies, tags, scripts, customised links, device or browser
                                fingerprints and web beacons (together, “Data Collection Tools”) when you access
                                and use the Services. These Data Collection Tools automatically track and collect
                                certain System Data and Usage Data (as detailed in Section 1) when you use the
                                Services. In some cases, we tie data gathered through those Data Collection Tools to
                                other data that we collect as described in this Privacy Policy.</p>
                            <p>We use cookies (small files that websites send to your device to uniquely identify your
                                browser or device or to store data in your browser) for things like analysing your use of
                                the Services, personalising your experience, making it easier to log into the Services and
                                recognising you when you return. We use web beacons (small objects that allow us to
                                measure the actions of visitors and users using the Services) for things like identifying
                                whether a page was visited, identifying whether an email was opened and advertising
                                more efficiently by excluding current users from certain promotional messages or
                                identifying the source of a new mobile app download.</p>
                            <p>We use the following type of Cookies:</p>

                            <p>Preferences: cookies that remember data about your browser and preferred settings that
                                affect the appearance and behaviour of the Services (like your preferred language).</p>
                            <p>Security: cookies used to enable you to log in and access the Services; protect against
                                fraudulent logins and help detect and prevent abuse or unauthorised use of your
                                account.</p>
                            <p>Functional: cookies that store functional settings (like the volume level you set for video
                                playback).</p>
                            <p>Session State: cookies that track your interactions with the Services to help us improve
                                the Services and your browsing experience, remember your login details and enable
                                processing of your content subscription(s). These are strictly necessary for the Services
                                to work properly, so if you disable them then certain functionalities will break or be
                                unavailable.</p>
                            <p>You can set your web browser to alert you about attempts to place cookies on your
                                computer, limit the types of cookies you allow or refuse cookies altogether. If you do, you
                                may not be able to use some or all features of the Services and your experience may be
                                different or less functional.</p>
                            <p>Some of the third-party partners who provide certain features on our site may also use
                                Local Storage Objects (also known as flash cookies or LSOs) to collect and store data.</p>

                            <span class="sub-title">2.2 Analytics</span>
                            <p>We use third-party browser and mobile analytics services like Google Analytics,
                                Statcounter etc. These services use Data Collection Tools to help us analyse your use of
                                the Services, including information like the third-party website you arrive from, how often
                                you visit, events within the Services, usage and performance data and where the
                                application was downloaded from. We use this data to improve the Services, better
                                understand how the Services perform on different devices and provide information that
                                may be of interest to you.</p>

                            <span class="sub-title">2.3 Online Advertising</span>
                            <p>We may use third-party advertising services like Facebook, Google’s ad services and.or
                                other ad networks and ad servers to deliver advertising about our Services on other
                                websites and applications you use. The ads may be based on things we know about
                                you, like your Usage Data and System Data (as detailed in Section 1) and things that
                                these ad service providers know about you based on their tracking data. The ads can be
                                based on your recent activity or activity over time and across other sites and services
                                and may be tailored to your interests.</p>

                            <p>Depending on the types of advertising services we use, they may place cookies or other
                                tracking technologies on your computer, mobile or any variant of our buddi device to
                                collect data about your use of our Services and may access those tracking technologies
                                in order to serve these tailored advertisements to you. To help deliver tailored
                                advertising, we may provide these service providers with a hashed, anonymised version
                                of your email address (in a non-human-readable form) and content that you share
                                publicly on the Services.</p>

                            <p>When using mobile applications, you may also receive tailored in-app advertisements.
                                Apple iOS, Android OS and Microsoft Windows each provide their own instructions on
                                how to control in-app tailored advertising. For other devices and operating systems, you
                                should review your privacy settings or contact your platform operator.</p>

                            <span class="sub-title">3. What We Use Your Data For</span>
                            <p>We use your data to do things like provide our Services, communicate with you,
                                troubleshoot issues, secure against fraud and abuse, improve and update our Services,
                                analyse how people use our Services, serve personalised advertising and as required by
                                law or necessary for safety and integrity.</p>
                            <p>We use the data we collect through your use of the Services to:</p>
                            <li style="color:#717c87;">Provide and administer the Services, including to display customised content and
                                facilitate communication with other users;</li>
                            
                                <li style="color:#717c87;">Process your requests and orders for content, courses, products, specific services,
                                    information or features;</li>

                                <li style="color:#717c87;">Communicate with you about your account by:</li>

                                <li style="color:#717c87;">Responding to your questions and concerns;</li>

                                <li style="color:#717c87;">Sending you administrative messages and information, including messages from
                                    instructors and teaching assistants, notifications about changes to our Service and
                                    updates to our agreements;</li>

                                <li style="color:#717c87;">Sending you information and in-app messages about your progress in courses, rewards
                                    programs, new services, new features, promotions, newsletters and/or other available
                                    courses (which you can opt out of at any time);</li>

                                <li style="color:#717c87;">Sending push notifications to your wireless device to provide updates and other relevant
                                    messages (which you can manage from the “options” or “settings” page of the mobile
                                    app);</li>

                                <li style="color:#717c87;">Manage your account preferences;</li>

                                <li style="color:#717c87;">Facilitate the Services’ technical functioning, including troubleshooting and resolving
                                    issues, securing the Services and preventing fraud and abuse;</li>

                                <li style="color:#717c87;">Solicit feedback from users;</li>

                                <li style="color:#717c87;">Market and administer surveys and promotions administered or sponsored by us;</li>

                                <li style="color:#717c87;">Learn more about you by linking your data with additional data through third-party data
                                    providers or analysing the data with the help of analytics service providers;</li>

                                <li style="color:#717c87;">Identify unique users across devices;</li>

                                <li style="color:#717c87;">Tailor advertisements across devices;</li>

                                <li style="color:#717c87;">Improve our Services and develop new products, services, and features;</li>

                                <li style="color:#717c87;">Analyse trends and traffic, track purchases, and track usage data;</li>

                                <li style="color:#717c87;">Advertise the Services on third-party websites and applications;</li>

                                <li style="color:#717c87;">As required or permitted by law; or</li>

                                <li style="color:#717c87;">As we, in our sole discretion, otherwise determine to be necessary to ensure the safety
                                    or integrity of our users, employees, third parties, the public or our Services.</li>

                            <span class="sub-title">4. Who We Share Your Data With</span>
                            <p>We share certain data about you with instructors, other students, companies performing
                                services for us, our business partners, analytics and data enrichment providers, your
                                social media providers, companies helping us run promotions and surveys and
                                advertising companies who help us promote our Services. We may also share your data
                                as needed for security, legal compliance or as part of a corporate restructuring. Lastly,
                                we can share data in other ways if it is aggregated or de-identified or if we get your
                                consent.</p>
                            <p>We may share your data with third parties under the following circumstances or as
                                otherwise described in this Privacy Policy:</p>

                                <li style="color:#717c87;">With Your Instructors: We share data that we have about you (except your email
                                    address) with instructors or teaching assistants for courses you enroll in, content
                                    subscribe for or request information about, so they can improve their courses or content
                                    for you and other students. This data may include things like your city, country, browser
                                    language, operating system, device settings, the site that brought you to us and your
                                    activities on our platform. If we collect additional data about you (like age or gender), we
                                    may share that too. We will not share your email address with instructors or teaching
                                    assistants. We also enable our instructors to implement Google Analytics on their course
                                    pages to track sources of traffic to their courses and optimise their course pages.</li>
                                <li style="color:#717c87;">With Other Students and Instructors: Depending on your settings, your shared content
                                    and profile data may be publicly viewable, including to other students and instructors. If
                                    you ask a question to an instructor or teaching assistant, your information (including your
                                    name) may also be publicly viewable by other users depending on your settings.</li>
                                <li style="color:#717c87;">With Service Providers, Contractors, and Agents: We share your data with third-party
                                    companies who perform services on our behalf, like payment processing, data analysis,
                                    marketing and advertising services (including retargeted advertising), email and hosting
                                    services and customer services and support. These service providers may access your
                                    personal data and are required to use it solely as we direct, to provide our requested
                                    service.</li>
                                <li style="color:#717c87;">With Business Partners: We have agreements with other websites and platforms to
                                    distribute our Services and drive traffic to us. Depending on your location, we may share
                                    your data with these partners.</li>
                                <li style="color:#717c87;">With Analytics and Data Enrichment Services: As part of our use of third-party analytics
                                    tools like Google Analytics and/or data enrichment services, we share certain contact
                                    information, Account Data, System Data, Usage Data (as detailed in Section 1) or de-
                                    identified data as needed. De-identified data means data where we’ve removed things
                                    like your name and email address and replaced it with a token ID. This allows these
                                    providers to provide analytics services or match your data with publicly-available
                                    database information (including contact and social information from other sources). We
                                    do this to communicate with you in a more effective and customised manner.</li>
                                <li style="color:#717c87;">To Power Social Media Features: The social media features in the Services (like the
                                    Facebook Like button) may allow the third-party social media provider to collect things
                                    like your IP address and which page of the Services you’re visiting and to set a cookie to
                                    enable the feature. Your interactions with these features are governed by the third-party
                                    company’s privacy policy.</li>
                                <li style="color:#717c87;">To Administer Promotions and Surveys: we may share your data as necessary to
                                    administer, market or sponsor promotions and surveys you choose to participate in, as
                                    required by applicable law (like to provide a winners list or make required filings) or in
                                    accordance with the rules of the promotion or survey.</li>
                                <li style="color:#717c87;">For Advertising: If we decide to offer advertising in the future, we may use and share
                                    certain System Data and Usage Data with third-party advertisers and networks to show
                                    general demographic and preference information among our users. We may also allow
                                    advertisers to collect System Data through Data Collection Tools (as detailed in Section
                                    2.1), and to use this data to offer you targeted ad delivery to personalise your user
                                    experience (through behavioural advertising) and undertake web analytics. Advertisers
                                    may also share with us the data they collect about you. To learn more or opt out from
                                    participating ad networks’ behavioural advertising, see Section 6.1 (Your Choices About
                                    the Use of Your Data) below. Note that if you opt out, you may continue to be served
                                    generic ads.</li>
                                <li style="color:#717c87;">For Security and Legal Compliance: We may disclose your data to third parties if we (in
                                    our sole discretion) have a good faith belief that the disclosure is:</li>
                                <li style="color:#717c87;">Permitted or required by law;</li>
                                <li style="color:#717c87;">Requested as part of a judicial, governmental, or legal inquiry, order or proceeding;</li>
                                <li style="color:#717c87;">Reasonably necessary as part of a valid subpoena, warrant or other legally-valid
                                    request;</li>
                                <li style="color:#717c87;">Reasonably necessary to enforce our Terms of Use, Privacy Policy and other legal
                                    agreements;</li>
                                <li style="color:#717c87;">Required to detect, prevent, or address fraud, abuse, misuse, potential violations of law
                                    (or rule or regulation) or security or technical issues; or</li>
                                <li style="color:#717c87;">Reasonably necessary in our discretion to protect against imminent harm to the rights,
                                    property, or safety of us, our users, employees, members of the public or our Services.
                                    o We may also disclose data about you to our auditors and/or legal advisors in order to
                                    assess our disclosure obligations and rights under this Privacy Policy.</li>
                                <li style="color:#717c87;">During a Change in Control: If we undergo a business transaction like a merger,
                                    acquisition, corporate divestiture or dissolution or a sale of all or some of its assets, we
                                    may share, disclose, or transfer all of your data to the successor organisation during
                                    such transition or in contemplation of a transition (including during due diligence).</li>
                                <li style="color:#717c87;">After Aggregation/De-identification: we can disclose or use aggregate or de-identified
                                    data for any purpose.</li>
                                <li style="color:#717c87;">With Your Permission: with your consent, we may share data to third parties outside the
                                    scope of this Privacy Policy.</li>
                               

                            <span class="sub-title">5. Security</span>
                            <li style="color:#717c87;">We use appropriate security based on the type and sensitivity of data being
                                stored. As with any internet-enabled system, there is always a risk of
                                unauthorised access, so it’s important to protect your password and to contact us
                                if you suspect any unauthorised access to your account.</li>
                            <p>We take appropriate security measures to protect against unauthorised access,
                                alteration, disclosure or destruction of your personal data that we collect and store.
                                These measures vary based on the type and sensitivity of the data. Unfortunately,
                                however, no system can be 100% secured, so we cannot guarantee that
                                communications between you and us, the Services or any information provided to us
                                in connection with the data we collect through the Services will be free from
                                unauthorised access by third parties. Your password is an important part of our
                                security system and it is your responsibility to protect it. You should not share your
                                password with any third party and if you believe your password or account has been
                                compromised, you should change it immediately and contact support@buddi.life with
                                any concerns.</p>

                            <span class="sub-title">6. Your Rights</span>
                            <p>You have certain rights around the use of your data, including the ability to opt out of
                                promotional emails, cookies and collection of your data by certain analytics providers.
                                You can update or terminate your account from within our Services and can also contact
                                us for individual rights requests about your personal data. Parents who believe we’ve
                                unintentionally collected personal data about their underage child should contact us for
                                help deleting that information.</p>
                            
                            <span class="sub-title">6.1 Your Choices About the Use of Your Data</span>
                            <p>You can choose not to provide certain data to us, but you may not be able to use certain
                                features of the Services.</p>
                           
                                <li style="color:#717c87;">To stop receiving promotional communications from us, you can opt out by using the
                                    unsubscribe mechanism in the promotional communication you receive or by changing
                                    the email preferences in your account. Note that regardless of your email preference
                                    settings, we will send you transactional and relationship messages regarding the
                                    Services, including administrative confirmations, order confirmations, important updates
                                    about the Services and notices about our policies.</li>
                                <li style="color:#717c87;">The browser or device you use may allow you to control cookies and other types of local
                                    data storage. Your wireless device may also allow you to control whether location or
                                    other data is collected and shared.</li>
                                <li style="color:#717c87;"> If you’re located in the US, to get information and control cookies used for tailored
                                    advertising from participating companies, see the consumer opt-out pages for
                                    the Network Advertising Initiative and Digital Advertising Alliance or if you’re located in
                                    the European Union, visit the Your Online Choices site. To opt out of Google’s display
                                    advertising or customise Google Display Network ads, visit the Google Ads Settings
                                    page.</li>
                                    <li style="color:#717c87;">To opt out of allowing Google Analytics’ usage of your data for analytics or enrichment,
                                        see the Google Analytics Opt-out Browser Add-on.</li>

                                    <p>If you have any questions about your data, our use of it or your rights, contact us at
                                        support@buddi.life.</p>

                            <span class="sub-title">6.2 Accessing, Updating, and Deleting Your Personal Data</span>
                            <p>You can access and update your personal data that we collect and maintain as follows:</p>
                            <li style="color:#717c87;">To update data you provide directly, log into your account and update your account at
                                any time.</li>
                            <li style="color:#717c87;">To terminate your account:</li>
                            <li style="color:#717c87;">If you are a student, visit your profile settings page and follow the steps detailed here.</li>
                            <li style="color:#717c87;">If you are an instructor, follow the steps detailed here.</li>
                            <li style="color:#717c87;">If you have any issues terminating your account, email us at  support@buddi.life  or visit
                                us at our Support Center here.</li>
                            <li style="color:#717c87;">Please note: even after your account is terminated, some or all of your data may still be
                                visible to others, including without limitation any data that has been (a) copied, stored, or
                                disseminated by other users (including in course/content comment(s)); (b) shared or
                                disseminated by you or others (including in your shared content); or (c) posted to a third-
                                party platform. Even after your account is terminated, we retain your data for as long as
                                we have a legitimate purpose to do so (and in accordance with applicable law), including
                                to assist with legal obligations, resolve disputes and enforce our agreements. We may
                                retain and disclose such data pursuant to this Privacy Policy after your account has
                                been terminated.</li>
                            <li style="color:#717c87;">To request to access, correct, or delete your personal data, email support@buddi.life.
                                Please allow up to 10 days for a response. For your protection, we may require that the
                                request be sent through the email address associated with your account and we may
                                need to verify your identity before implementing your request. Please note that we retain
                                certain data where we have a lawful basis to do so, including for mandatory record-
                                keeping and to complete transactions.</li>

                            <span class="sub-title">6.3 Our Policy Concerning Children</span>
                            <p>We recognise the privacy interests of children and encourage parents and guardians to
                                take an active role in their children’s online activities and interests. Children under 13 (or
                                under 16 in the European Economic Area) should not use the Services. If we learn that
                                we’ve collected personal data from a child under those ages, we will take reasonable
                                steps to delete it.</p>

                            <p>Parents who believe that we may have collected personal data from a child under those
                                ages can submit a request that it be removed to support@buddi.life.</p>

                            <span class="sub-title">7. Jurisdiction-Specific Rules</span>
                            <p>Users outside of India should note that we may transfer data to servers in India based
                                on a legal need to store it in servers in India or as needed for the purposes of running
                                the business, to servers outside of your country of residence or citizenship and this
                                applies to whether you reside in the US, EU, other areas outside of the European
                                Economic Area or other countries not covered in the above.</p>

                            <p>We are headquartered in India and in order to provide the Services to you, we must
                                transfer your data to our servers in India or other regions and process it there. By visiting
                                or using our Services, you consent to storage of your data on servers located in India or
                                other regions as we deem fit for purposes of running our business. Specifically, personal
                                data collected in the United Kingdom (“UK”), Switzerland and the European Economic
                                Area (“EEA”) is transferred and stored outside those areas. Additionally, if you are
                                located in the UK, EEA or Switzerland, you also have the right to lodge a complaint with
                                your data supervisory authority.</p>

                            <p>That data may also be processed outside of the UK, Switzerland, and the EEA by our
                                group companies or our service providers, including to process transactions, facilitate
                                payments and provide support services as described in Section 4. We have entered into
                                data processing agreements with our service providers that restrict and regulate their
                                processing of your data on our behalf. By submitting your data or using our Services,
                                you consent to this transfer, storage, and processing by us and our processors.</p>
                            
                            <span class="sub-title">8. Updates &amp; Contact Info</span>
                            <p>When we make a material change to this policy, we’ll notify users via email, in-product
                                notice or another mechanism required by law. Changes become effective the day they’re
                                posted. Please contact us via email at support@buddi.life with any questions, concerns
                                or disputes.</p>

                            <span class="sub-title">8.1 Modifications to This Privacy Policy</span>
                            <p>From time to time, we may update this Privacy Policy. If we make any material change to
                                it, we will notify you via email, through a notification posted on the Services or as
                                required by applicable law. We will also include a summary of the key changes. Unless
                                stated otherwise, modifications will become effective on the day they are posted.</p>

                            <p>As permitted by applicable law, if you continue to use the Services after the effective
                                date of any change, then your access and/or use will be deemed an acceptance of (and
                                agreement to follow and be bound by) the revised Privacy Policy. The revised Privacy
                                Policy supersedes all previous Privacy Policies.</p>

                            <span class="sub-title">8.2 Interpretation</span>
                            <p>Any capitalised terms not defined in this policy are defined as specified in our Terms of
                                Use. Any version of this Privacy Policy in a language other than English can be provided
                                for convenience on request. If there is any conflict with a non-English version, you agree
                                that the English language version will control.</p>

                            <span class="sub-title">8.3 Questions</span>
                            <p>If you have any questions, concerns or disputes regarding our Privacy Policy, please
                                feel free to contact our team at support@buddi.life.</p>

                            <span class="sub-title">Cookie Policy</span>
                            <p>What are cookies?</p>

                            <p>Cookies are small text files stored by your browser as you browse the internet. They can
                                be used to collect, store and share data about your activities across websites, including
                                on ours. Cookies also allow us to remember things about your visits to our website, to
                                store your preferences and to make the site easier to use.</p>

                            <p>We use both session cookies, which expire after a short time or when you close your
                                browser and persistent cookies, which remain stored in your browser for a set period of
                                time. We use session cookies to identify you during a single browsing session, like when
                                you log into our website. We use persistent cookies where we need to identify you over
                                a longer period, like when you request that we keep you signed in.</p>

                            <p>Why do we use cookies and similar technologies?</p>

                            <p>We use cookies and similar technologies like web beacons, pixel tags or local shared
                                objects (“flash cookies”), to deliver, measure and improve our services in various ways.
                                We use these cookies both when you visit our site and services through a browser and
                                through our mobile app. As we adopt additional technologies, we may also gather
                                additional data through other methods.</p>

                            <p>We use cookies for the following purposes:</p>

                            <p>Authentication and Security</p>

                            <li style="color:#717c87;">To log you into our website</li>

                            <li style="color:#717c87;">To protect your security</li>

                            <li style="color:#717c87;">To help detect and fight spam, abuse, and other activities that violate our Terms of Use</li>

                            <p>For example, cookies help authenticate your access to our website and prevent
                                unauthorised parties from accessing your accounts.</p>

                            <p>Preferences</p>

                            <li style="color:#717c87;">To remember data about your browser and your preferences </li>
                            <li style="color:#717c87;"> To remember your settings and other choices you’ve made</li>

                        <p>For example, cookies help us remember your preferred language or the country you’re
                            in, so that we can provide content in your preferred language without asking each time
                            you visit.</p>

                            <p>Analytics and research</p>

                            <li style="color:#717c87;">To help us understand and improve how people use our website and our Services</li>

                            <p>For example, cookies help us test different versions of our Services to see which
                                features or content users prefer, web beacons help us determine which email messages
                                are opened and cookies help us see how you interact with our platform, like the links you
                                click on.</p>

                            <p>We also work with a number of analytics partners such as Google Analytics who use
                                cookies and similar technologies to help us analyse how users use the Services,
                                including by noting the sites from which you arrive. Those service providers may either
                                collect that data themselves or we may disclose it to them.</p>

                            <p>You can opt out of some of these services through tools like the Google Analytics Opt-
                                out Browser Add-on</p>

                            <p>Personalised Content</p>

                            <li style="color:#717c87;">To customise our Services with more relevant content</li>

                            <p>For example, cookies help us show a personalised list of recommended courses or
                                featured content on the homepage.</p>

                            <p>Advertising</p>

                            <li style="color:#717c87;">To provide you with more relevant advertising</li>

                            <p>To learn more about targeting and advertising cookies and how you can opt out,
                                visit www.allaboutcookies.org/manage-cookies/index.html or if you’re located in the
                                European Union, visit the Your Online Choices site.</p>

                                <p>Please note that where advertising technology is integrated into the Services, you may
                                    still receive advertising on other websites and applications, but it will not be tailored to
                                    your interests.</p>

                                <p>When using mobile applications, you may also receive tailored in-app advertisements.
                                    Apple iOS, Android OS, and Microsoft Windows each provide its own instructions on
                                    how to control in-app tailored advertising. For other devices and operating systems, you
                                    should review your privacy settings or contact your platform operator.</p>

                                <p>What are my privacy options?</p>

                                <p>You have a number of options to control or limit how we and our partners use cookies:</p>

                                <li style="color:#717c87;">Most browsers automatically accept cookies, but you can change your browser settings
                                    to decline cookies by consulting your browser’s support articles. If you decide to decline
                                    cookies, please note that you may not be able to sign in, customise or use some
                                    interactive features in the Services.</li>

                                <li style="color:#717c87;">Flash cookies operate differently than browser cookies, so your browser’s cookie-
                                    management tools may not remove them. To learn more about how to manage Flash
                                    cookies, see Adobe’s article on managing flash cookies and Website Storage Settings
                                    panel.</li>

                                <li style="color:#717c87;">If you’re located in the US, to get information and control cookies used for tailored
                                    advertising from participating companies, see the consumer opt-out pages for
                                    the Network Advertising Initiative and Digital Advertising Alliance or if you’re located in
                                    the European Union, visit the Your Online Choices site. To opt out of Google Analytics’
                                    display advertising or customise Google Display Network ads, visit the Google Ads
                                    Settings page.</li>

                                <li style="color:#717c87;">For general information about targeting cookies and how to disable them,
                                    visit www.allaboutcookies.org.</li>

                                    <p>Updates &amp; Contact Info</p>

                                    <p>From time to time, we may update this Cookie Policy. If we do, we’ll notify you by posting
                                        the policy on our site with a new effective date. If we make any material changes, we’ll
                                        take reasonable steps to notify you in advance of the planned change.</p>

                                        <p>If you have any questions about our use of cookies, please email us at
                                            support@buddi.life.</p>
                </div>
            </div>
        </div>

    </div>
</section>