<div class="container">
    <div class="card-deck">
        <div class="col-lg-4 col-md-6" routerLink="/sidenav/completed/live-class" style="cursor:pointer ;">
            <div class="card text-center">
           <img src="../../assets/img/upcoming-live-class.jpeg" alt="">
             <div class="card-body">
                   Live Classes
             </div>
            </div>
         </div>
         <div class="col-lg-4 col-md-6" routerLink="/sidenav/completed/study-hall" style="cursor:pointer ;">
             <div class="card text-center">
                 <img src="../../assets/img/upcoming-study-hall.jpeg" alt="">
                 <div class="card-body">
                    Study Hall
                 </div>
             </div>
         </div>
         <div class="col-lg-4 col-md-6" routerLink="/sidenav/completed/round-table" style="cursor:pointer ;">   
             <div class="card text-center">
                 <img src="../../assets/img/upcoming-round-table.jpeg" alt="">
                 <div class="card-body">
                     Round Table
                 </div>
             </div>
         </div>
    </div>
</div>

