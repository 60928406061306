import { Component, OnInit,HostListener,ElementRef } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';

import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { MediaService } from '../../../../shared/media.service';
import { ignoreElements } from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { Moment } from 'moment';
import { FilterSearchPipe } from '../../../../components/custom-pipes/filter-search.pipe';
import { DiffTwoTimePipe } from '../../../../components/custom-pipes/diff-two-time.pipe';
import { TotalAmountPipe } from '../../../../components/custom-pipes/total-amount.pipe';
import { ProductexpiredPipe } from '../../../custom-pipes/productexpired.pipe';
import { EmptyObjectPipe } from '../../../custom-pipes/empty-object.pipe';

import { OwlDateTimeComponent, DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
//const moment = (_moment as any).default ? (_moment as any).default : _moment;

export const MY_MOMENT_DATE_TIME_FORMATS={
    parseInput: 'MM/YYYY',
    fullPickerInput: 'l LT',
    datePickerInput: 'MM/YYYY',
    timePickerInput: 'LT',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
};

@Component({
  selector: 'app-my-dashboard-page',
  templateUrl: './my-dashboard-page.component.html',
   styleUrls: ['./my-dashboard-page.component.scss'],
  providers: [FilterSearchPipe, DiffTwoTimePipe,TotalAmountPipe,ProductexpiredPipe,EmptyObjectPipe,

        {provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_DATE_TIME_FORMATS},
  ]
})
export class MyDashboardPageComponent implements OnInit {
  public date = new FormControl(new Date());
  form1: FormGroup;
  mylibraryForm: FormGroup;
  libraryForm: FormGroup;
  studyMaterialForm: FormGroup;
  testForm: FormGroup;
  userprofdata: any;
  id: any;
  userId: any;
  membership: string;
  enterpriseID: any;
  enterpriseName: any;
  privacy: any;
  public groupName: any;
  adminID: any;
  enterprise: any;
  courseDetails: any;
  teacherenterprisename: any;
  public profileteacherenterprisename: any;
  licenseDate: any;
  uploadform: FormGroup;
  classessForm: FormGroup;
  coursesForm: FormGroup;
  selectedFileName: string = 'Choose File';
  formdata: FormData;
  facebook: any;
  twitter: any;
  instagram: any;
  linkedIn: any;
  youtube: any;
  bio: any;
  teacherData: any;
  studentData: any;
  teacherTable: boolean = false;
  loader: boolean = false;
  loaderupload: boolean = false;
  public isclickable = true;
  public isclickabledisable = false;
  public assigndata: any = {};
  studentTable: boolean;
  dtOptions: DataTables.Settings = {};
  dupeEmailArray: any;
  dupePhoneArray: any;
  dupeArray: boolean;
  invalidArray: boolean;
  invalidDatastring: string;
  invalidDatalable: string;
  submitted: boolean;
  categorynamearray = '';
  boardnamearray = '';
  subjectnamearray = '';
  languagenamearray = '';
  gradenamearray = '';
  submittedupdate: boolean = false;
  registerForm: FormGroup;
  public userPhoto: any;
  name: any;
  showModal: boolean;
  formData;
  stateInfo: any[] = [];
  countryInfo: any[] = [];
  cityInfo: any[] = [];
  public ebooklistbyId: any = [];
  public ebooklistbyIdPrivate: any = [];
  public ebooklistbyIdGroup: any = []
  public mydashboardData: any = [];
  public uncorrecteddata: any = [];
  public reviewdata: any = [];
  public courseData: any = [];
  public PastcourseData: any = [];
  public PastcourseDatagroup: any = [];
  public DraftexamData: any = [];
  public DraftcourseData: any = [];
  public FuturecourseData: any = [];
  public FuturecourseDatagroup: any = [];
  public PastcourseExam: any = [];
  public PastcourseExamgroup: any = [];
  public FuturecourseExam: any = [];
  public FuturecourseExamgroup: any = [];
  coursestartDate: string;
  pastcoursestartDate: string;
  futurecoursestartDate: string;
  isUserLoggedIn: string;
  public isStudent: boolean = false;
  public iscoursecreate = false;
  public meetingsData: any = [];
  public meetingsDataRecord: any = [];
  public meetingsDataListen: any = [];
  public meetingsDataAll: any = [];
  public allmeeting: any = [];
  public inviteUrl = "";
  public frontwebsite = environment.frontUrl;
  public lessonData: any = [];
  public myId: any = ''
  public isLogin: any = 'false';
  public isCancel: any = 'false';
  public videoList: any = [];
  public videoListPrivate: any = [];
  public videoListGroup: any = [];
  public teacherVideouploadlist: any = [];
  public videoYoutubePublic: any = [];
  public publicsessionData: any = [];
  public publicsessionDataRecord: any = [];
  public requestedDataList: any = [];
  public requestedDataAccpet: any = [];
  public requestedDataAccpetRecord: any = [];
  public sameTeacher: any = [];
  public songsList: any = [];
  public songsListPrivate: any = [];
  public songsListGroup: any = [];
  public isTeacher: boolean = false;
  public isSchool: boolean = false;
  public isGroupnameenabled: boolean = false;
  public isEnterprisenameenabled: boolean = false;
  public isSchoolcourse: boolean = false;
  public isSchoolexam: boolean = false;
  public buddigroupData: any = [];
  public buddigroupDataRecord: any = [];
  public coursecategorylist: any = [];
  public courseboardlist: any = [];
  public coursesubjectlist: any = [];
  public languageapi: any = [];
  public gradeapi: any = [];
  public tabName ='home';
  public teacherPayoutDetails:any = [];
  term: string;
  isDesc: any = 1;
  column: string = 'remainingClass';
  p: number = 1;
  count: number = 4;

  p1: number = 1;
  count1: number = 4;

  p2: number = 1;
  count2: number = 4;

  p3: number = 1;
  count3: number = 4;

  p4: number = 1;
  count4: number = 4;

  p5: number = 1;
  count5: number = 4;

  p6: number = 1;
  count6: number = 4;

  p7: number = 1;
  count7: number = 4;

  p8: number = 1;
  count8: number = 4;

  p9: number = 1;
  count9: number = 4;

  p91: number = 1;
  count91: number = 4;

  p10: number = 1;
  count10: number = 4;

  p11: number = 1;
  count11: number = 4;

  p111: number = 1;
  count111: number = 4;

  p112: number = 1;
  count112: number = 4;

  p101: number = 1;
  count101: number = 4;

  p12: number = 1;
  count12: number = 4;

  p121: number = 1;
  count121: number = 4;

  e1: number = 1;
  counte1: number = 4;

  e: number = 1;
  counte: number = 4;

  e2: number = 1;
  counte2: number = 4;

  e3: number = 1;
  counte3: number = 4;

  e4: number = 1;
  counte4: number = 4;

  e5: number = 1;
  counte5: number = 4;

  pr1: number = 1;
  countpr1: number = 4;

  va10: number = 1;
  countva10: number = 4;

  v10: number = 1;
  countv10: number = 4;

  pr2: number = 1;
  countpr2: number = 4;

  pr3: number = 1;
  countpr3: number = 4;

  pvideoupload: number = 1;
  videouploadcount: number = 4;

  un: number = 1;
  countun: number = 4;

  review: number = 1;
  countreview: number = 4;  
  iskyc: boolean = false;
  public totalAmount:any  = 0;
  public totalmin:any  = 0
  public modelData:any = [];
  public studentIsSchool:any = [];
  public studyMaterial:any = [];
  public config:any = [];
  public configGroup:any = [];
  public isFullTime:any = false;
  public bankCheck:any = {};
  public bankDetails:any = {};
  constructor(private elem: ElementRef,private myRoute:ActivatedRoute,private SpinnerService: NgxSpinnerService,private api: MediaService, private router: Router, private userApi: ApiService, private location: Location, private formBuilder: FormBuilder, public toastr: ToastrManager) { }
  @HostListener('document:click', ['$event'])
  DocumentClick(event) {
    if (event.target.id =='myModal2'){      
      $('.active').removeClass('active');
      $('#home').addClass('active');
      $('.nav-tabs li a').first().addClass('active');
    }
    else{
      // window.location.href="teacher/mydashboard"
    }
  }
  ngOnInit() {
    window.scrollTo(0, 0);
    // commented by Monish Uday on 10/11/2021

    // this.iskyc = localStorage.getItem('kycStatus') == 'true' ? true : false;
    // if(!this.iskyc){
    //   this.router.navigateByUrl('/kyc');
    // }

    this.studentIsSchool = (localStorage.getItem('groupName') !='buddi')? true : false;
    this.tabName = window.history.state.pagename != undefined ? window.history.state.pagename : 'home';
    this.isLogin = localStorage.getItem('isLoggedin');
    const webToken = localStorage.getItem('webtoken');
    this.isUserLoggedIn = localStorage.getItem('isLoggedin');
    this.myId = localStorage.getItem('userId');
    const groupname = localStorage.getItem('groupname');
    const usetType = localStorage.getItem('userType');
    if(localStorage.getItem('isPendindTeacher')=='true'){
      this.router.navigate(['feedbackTeacher']);
    }
    this.isTeacher = localStorage.getItem('profileType').toLowerCase() == 'teacher'
    var courseformData = {};
    this.classessForm = this.formBuilder.group({
      classType: [false],
      classTypeRecord: [false]
    });
    this.coursesForm = this.formBuilder.group({
      classType: [false]
    });
    this.libraryForm = this.formBuilder.group({
      libraryTypeSplit: [false]
    });
    this.studyMaterialForm = this.formBuilder.group({
      studymaterialType: [false]
    });
    courseformData["groupName"] = groupname;
    courseformData["categoryName"] = "";
    courseformData["userType"] = usetType;
    if (usetType == "teacher") {
      this.iscoursecreate = true;
    }
    else {
      this.iscoursecreate = false;
    }
    this.SpinnerService.show()
    this.userApi.getmyDashboadmeetingTeacher(webToken).subscribe((response) => {
      this.SpinnerService.hide()
      if (response.status && response.data != null && response.data.length != 0) {
        this.requestedDataAccpet = this.SortDataPast(response.data.oneOnone, true);
        this.meetingsData = this.SortDataPast(response.data.groupDate, true);
        this.publicsessionData = this.SortDataPast(response.data.publicData, false);
        this.mydashboardData = response.data;
        this.buddigroupData = response.data.buddiGroup;
        console.log(this.videoList)
      } else {
        this.mydashboardData = [];
      }
    });
  this.getTeacherAccount();
  this.getStudyMaterial([]);
  this.getStudyMaterialQuestion([]);
  this.SpinnerService.show()
    this.userApi.getmyDashboadmeetingTeacherRecord(webToken).subscribe((response) => {
       this.SpinnerService.hide()
     if (response.status && response.data != null && response.data.length != 0) {
       this.requestedDataAccpetRecord = response.data.oneOnoneRecord
       this.meetingsDataRecord = this.SortDataPast(response.data.groupDateRecord,false);
       this.publicsessionDataRecord = response.data.publicDataRecord;
       this.buddigroupDataRecord = response.data.buddiGroupRecord;
       this.videoList = [ ...this.videoList, ...this.publicsessionDataRecord,...this.buddigroupDataRecord];
       console.log(this.videoList)
     } else {
       this.mydashboardData = [];
     }
    });
    var publicGet = '';
    this.userApi.userName_DisplayTeacher(webToken).subscribe((result) => {
      this.groupName = result.data.groupName;
      if (this.groupName === undefined || this.groupName === "") {
        this.isGroupnameenabled = true;
      }
      else {
        this.assigndata.groupName = result.data.groupName;
        this.form1.patchValue(this.assigndata);
        this.isGroupnameenabled = false;
      }
      var req = {
        userId: this.myId
      }
    });

    this.userApi.getPastCoursesbyGroup(webToken, courseformData).subscribe((result) => {
      this.PastcourseData = result.data.Public;
      this.PastcourseDatagroup = result.data.Group;
    });
    this.userApi.getDraftCoursesbyGroup(webToken, courseformData).subscribe((result) => {
      this.DraftcourseData = result.data;
    });

    this.userApi.getDraftExamsbyGroup(webToken, courseformData).subscribe((result) => {
      this.DraftexamData = result.data;
    });


    this.userApi.getFutureCoursesbyGroup(webToken, courseformData).subscribe((result) => {
      this.FuturecourseData = result.data.Public;
      this.FuturecourseDatagroup = result.data.Group;
      var curdata = result.data.startDate;
      const currentFormatDate = moment(curdata).subtract(330, "minutes").toDate();
      this.futurecoursestartDate = moment(currentFormatDate).format('DD-MM-YYYY');
    });

    this.userApi.getPastExamsbyGroup(webToken, courseformData).subscribe((result) => {
      this.PastcourseExam = result.data.Public;
      this.PastcourseExamgroup = result.data.Group;
    });

    this.userApi.getFutureExamsbyGroup(webToken, courseformData).subscribe((result) => {
      this.FuturecourseExam = result.data.Public;
      this.FuturecourseExamgroup = result.data.Group;
    });
    this.userdetails();
    this.getAllRequest();
    this.registerForm = this.formBuilder.group({
      id: null,
      upload_name: [null],
      fileupload: ['', Validators.required],
      firstname: ['', [Validators.required, Validators.maxLength(25)]],
      lastname: ['', [Validators.required, Validators.maxLength(25)]],
      email: ['', [Validators.required, Validators.minLength(6)]],
      mobileNumber1: ['', [Validators.required, Validators.minLength(6)]],
      dob: ['', [Validators.required, Validators.minLength(6)]],
      gender: [''],
      street: [''],
      area: [''],
      city: [''],
      state: [''],
      postalCode: [''],
      currentCity: [''],
      qualification: [''],
      preferences: [''],
      currentEducation: [''],
      profession: [''],
      facebook: [''],
      twitter: [''],
      instagram: [''],
      linkedIn: [''],
      youtube: [''],
      bio: ['']
    });
    this.form1 = this.formBuilder.group({
      groupName: ['', Validators.required],
      enterpriseName: ['', Validators.required],
      privacy: ["Public"]
    });

    this.mylibraryForm = this.formBuilder.group({
      libraryType: ['Video']
    });
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true
    };

    this.uploadform = this.formBuilder.group({
      upload_name: [null],
      fileupload: ['']
    });

    if (webToken != null) {
      this.getUserdetails();
    }
    this.userApi.enterpriseTeacherGet(webToken).subscribe((response) => {
      if (response.status) {
        this.teacherenterprisename = response.enterpriseName;
        this.profileteacherenterprisename = response.enterpriseName;

        if (response.enterpriseName === undefined || response.enterpriseName === "") {
          this.isEnterprisenameenabled = true;
        }
        else {
          this.assigndata.enterpriseName = response.enterpriseName;
          this.form1.patchValue(this.assigndata);
          this.isEnterprisenameenabled = false;
        }
      }
      else {
        this.isEnterprisenameenabled = false;
        console.log(response.status);
      }

    });

    this.userApi.getsameTeahcer(webToken).subscribe((response) => {
      if (response.status) {
        if (response.data.length != 0) {
          this.sameTeacher = this.SortDataPast(response.data, true);
          // this.sameTeacher = response.data;
        }
        console.log('teachers', this.teacherData);
      }

    });
    this.userApi.enterpriseStudentGet(webToken).subscribe((response) => {
      if (response.status) {
        this.studentData = response.data;
        console.log('students', this.studentData);
      }
    });

    this.userApi.getAllCoursesbyGroup(webToken, courseformData).subscribe((result) => {
      this.courseData = result.data;
    });
    this.getAlbumList();
    this.gettestsdata();

  }
  copyToClipboard(element) {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val($(element).text()).select();
    document.execCommand("copy");
    $temp.remove();
    this.toastr.successToastr(' Referal Code Copied to Clipboard!');
  }
  getTeacherAccount(){
    const webToken = localStorage.getItem('webtoken');
      var req={
      month:this.date.value.getMonth()+1,
      year:this.date.value.getFullYear()
    }
    this.userApi.getTeacherPaymentAllFinal(req,webToken).subscribe((response) => {
      console.log(response);
      if(response.status){
        this.teacherPayoutDetails = response.data;
        this.totalAmount = response.totalAmount;
        this.totalmin = response.noofmin;
      }

    });
  }
  SortDataPast(data,isOneonone) {
    var sortDate = data.sort(function (a, b) { return new Date(a.ScheduleDate).getTime() - new Date(b.ScheduleDate).getTime() });
    const now = Date.now();
    if (isOneonone) {
      const futureDates = sortDate.filter(date => {
        return (date.ScheduleDate && new Date(date.ScheduleDate).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)) || date.isrecorded;
      });
      return futureDates;
    } else {
      const futureDates = sortDate.filter(date => {
        return (date.ScheduleDate && new Date(date.ScheduleDate).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0));
      });
      return futureDates;
    }

  }
  indexFilter(filterData){
    this.modelData = filterData;
  }
  sort(property1,property2) {
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property1;
    let direction = this.isDesc ? 1 : -1;
    if(property2){
      this.teacherPayoutDetails.sort(function (a, b) {
        if (a[property1][property2] < b[property1][property2]) {
          return -1 * direction;
        }
        else if (a[property1][property2] > b[property1][property2]) {
          return 1 * direction;
        }
        else {
          return 0;
        }
      });
    }else{
      this.teacherPayoutDetails.sort(function (a, b) {
        if (a[property1] < b[property1]) {
          return -1 * direction;
        }
        else if (a[property1] > b[property1]) {
          return 1 * direction;
        }
        else {
          return 0;
        }
      });
    }
  
  };
  buddiGroupRedirectParent(data) {
    this.router.navigateByUrl('buddigroup/' + data._id);
  }
  buddiGroupRedirect(data) {
    this.router.navigateByUrl('buddigroup/' + data.parentMeetingID._id);
  }
  detailsAccept(data, pagename) {
    if(data.classGroup){
      this.router.navigate(['/automation' + "/" + data.meetingID], { state: data });
    }else{
      data.pagename = pagename;
      this.router.navigate(['/teacher/accept' + "/" + data.meetingID], { state: data });
    }
  
  }
  detailsRequest(data, pagename) {
    data.pagename = pagename;
    this.router.navigate(['/teacher/request' + "/" + data.meetingID], { state: data });
  }
  fileChange1(event) {
    let fileList: FileList = event.target.files;
    console.log(fileList);
    this.uploadform.patchValue({ upload_name: fileList[0] });
    this.selectedFileName = fileList[0].name;
    console.log(this.selectedFileName);
    this.uploadform.patchValue({ fileupload: this.selectedFileName });
  }
  getUserdetails() {
    const webToken = localStorage.getItem('webtoken');
    this.userApi.Getteacherallpopulate(webToken).subscribe((result) => {
      this.id = result.data._id;
      this.userprofdata = result.data;
      this.categorynamearray = result.data.categories;
      this.gradenamearray = result.data.grades;
      this.boardnamearray = result.data.boards;
      this.subjectnamearray = result.data.subjects;
      this.languagenamearray = result.data.preferredLanguage;      
      // this.bankCheck.isFullTime = result.data.modeofEngagement!=undefined && result.data.modeofEngagement.length!=0? result.data.modeofEngagement[0].employmentType =='Full -Time' ? true : false: false;
      this.bankCheck.isBank = result.data.bankName !=undefined &&  result.data.bankName !=""? true : false;
      if(this.bankCheck.isBank){
        this.bankDetails.accountName = result.data.accountName;
        this.bankDetails.accountNumber = result.data.accountNumber;
        this.bankDetails.bankName = result.data.bankName;
        this.bankDetails.bankBranch = result.data.bankBranch;
        this.bankDetails.ifscCode = result.data.ifscCode;
        this.bankDetails.accountType = result.data.accountType;
      }
    }, (err) => {
      console.log(err);
    });
  }
  // redirectToEdit(){
  //   $('#myModal2').modal('show');
    
  // }
  confirmBank(){
    // if(!this.bankCheck.isFullTime && !this.bankCheck.isBank){
    if(!this.bankCheck.isBank){
      var data ={
        tabName:'menu1'
      }
      this.router.navigate(['/teacher/edit-profile'], { state: data });
    }
  }
  userDataUpload() {
    this.loaderupload = true;
    const data = this.uploadform.value;
    if (data.upload_name != null) {
      this.isclickable = false;
      this.isclickabledisable = true;
    }
    else {
      this.loaderupload = false;
      this.toastr.errorToastr('Please upload a file');
    }

    console.log(data);
    this.formdata = new FormData();
    this.formdata.append("excel_file", data.upload_name, data.upload_name.name);
    const webToken = localStorage.getItem('webtoken');
    this.userApi.userDataUpload(this.formdata, webToken).subscribe((response) => {
      console.log(response.status);
      if (response.status) {
        this.loaderupload = false;
        this.dupeArray = true;
        this.invalidArray = false;
        this.dupeEmailArray = response.dupeEmailArray;
        this.dupePhoneArray = response.dupePhoneArray;
        this.toastr.successToastr('Users Created Susccessfully');
        this.isclickable = true;
        this.isclickabledisable = false;
      }
      else {
        this.loaderupload = false;
        this.invalidArray = true;
        this.invalidDatastring = response.data;
        this.invalidDatalable = response.message;
      }
      this.loaderupload = false;
      this.isclickable = true;
      this.isclickabledisable = false;
    })
  }

  viewTeachers() {
    this.loader = true;
    this.studentTable = false;
    const webToken = localStorage.getItem('webtoken');
    this.userApi.enterpriseTeacherGet(webToken).subscribe((response) => {
      this.teacherData = response.data;
      console.log('teachers', this.teacherData);
      this.loader = false;
      this.teacherTable = true;

    });

  }

  viewStudents() {
    this.teacherTable = false;
    this.loader = true;
    const webToken = localStorage.getItem('webtoken');
    this.userApi.enterpriseStudentGet(webToken).subscribe((response) => {
      this.studentData = response.data;
      console.log('students', this.studentData);
      this.loader = false;
      this.studentTable = true;
    });
  }

  nextTo(event) {
    $('a.active').parent().next().children()[0].click();
  }
  get f() { return this.registerForm.controls; }
  get g() { return this.form1.controls; }
  get h() { return this.mylibraryForm.controls; }
  get j() { return this.classessForm.controls; }
  get k() { return this.libraryForm.controls; }
  get s() { return this.studyMaterialForm.controls; }
  get r() { return this.coursesForm.controls; }
  
  getCountries() {
    this.userApi.allCountries().
      subscribe(
        data2 => {
          this.countryInfo = data2.Countries;
          console.log('Data:', this.countryInfo);
        },
        err => console.log(err),
        () => console.log('complete')
      )
  }
  changeLibrary(event) {
  }
  onChangeCountry(countryValue) {
    this.stateInfo = this.countryInfo[countryValue].States;
    this.cityInfo = this.stateInfo[0].Cities;
  }

  onChangeState(stateValue) {
    this.cityInfo = this.stateInfo[stateValue].Cities;
  }

  fileChange(event) {
    let fileList: FileList = event.target.files;
    this.registerForm.patchValue({ upload_name: fileList[0] });
    this.selectedFileName = fileList[0].name;
    this.registerForm.patchValue({ fileupload: this.selectedFileName });
  }

  userdetails() {
    const webToken = localStorage.getItem('webtoken');
    if (webToken != null) {
      this.userApi.userName_DisplayTeacher(webToken).subscribe((result) => {
        console.log(result.data);
        let assigndata: any = {};
        assigndata.id = result.data._id;
        assigndata.firstname = result.data.firstName;
        assigndata.lastname = result.data.lastName;
        assigndata.email = result.data.email;
        assigndata.mobileNumber1 = result.data.mobileNumber1;
        assigndata.dob = result.data.dob;
        assigndata.gender = result.data.gender;
        assigndata.street = result.data.street;
        assigndata.area = result.data.area == 'undefined' ? '' : result.data.area;
        assigndata.city = result.data.city == 'undefined' ? '' : result.data.city;
        assigndata.state = result.data.state == 'undefined' ? '' : result.data.state;
        assigndata.postalCode = result.data.postalCode == 'undefined' ? '' : result.data.postalCode;
        assigndata.currentCity = result.data.currentCity == 'undefined' ? '' : result.data.currentCity;
        assigndata.preferences = result.data.preferences == 'undefined' ? '' : result.data.preferences;
        assigndata.qualification = result.data.qualification == 'undefined' ? '' : result.data.qualification;
        assigndata.currentEducation = result.data.currentEducation == 'undefined' ? '' : result.data.currentEducation;
        assigndata.profession = result.data.profession == 'undefined' ? '' : result.data.profession;
        this.registerForm.patchValue(assigndata);
        this.userPhoto = result.data.userPhoto
        this.name = result.data.profileName;
        this.id = result.data._id;
        const userId = { id: this.id }
        this.userApi.userPricingData_Get(userId, webToken).subscribe((response) => {
          console.log(response)
          if (response.data) {
            let assignAddData: any = {};
            assignAddData.facebook = response.data.facebookURL == 'undefined' ? '' : response.data.facebookURL;
            assignAddData.twitter = response.data.twitterURL == 'undefined' ? '' : response.data.twitterURL;
            assignAddData.instagram = response.data.instagramURL == 'undefined' ? '' : response.data.instagramURL;
            assignAddData.linkedIn = response.data.linkedinURL == 'undefined' ? '' : response.data.linkedinURL;
            assignAddData.youtube = response.data.youtubeURL == 'undefined' ? '' : response.data.youtubeURL;
            assignAddData.bio = response.data.userBio == 'undefined' ? '' : response.data.userBio;
            this.registerForm.patchValue(assignAddData);
          }

        });

      }, (err) => {
        console.log(err);
      });
    }
  }

  onSubmit1() {
    if (this.form1.invalid && !this.form1.value.id) {
      return;
    } else {
      this.submittedupdate = true;
      if (this.form1.invalid) {
        return;
      } else {
        var req = {
          "enterpriseData": this.form1.value
        }
        const webToken = localStorage.getItem('webtoken');
        console.log(req);
        this.userApi.updateAdminEnterprise(req, webToken).subscribe((response) => {
          console.log(response);
          if (response.status) {
            $("#close").click();
            this.getUserdetails();
            this.dupeArray = true;
            this.dupeEmailArray = response.dupeEmailArray;
            this.dupePhoneArray = response.dupePhoneArray;
            this.toastr.successToastr('Users Created Sucessfully');
          } else {
            this.toastr.errorToastr('Error in adding');
          }

        })
      }
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid && !this.registerForm.value.id) {
      return;
    }
    this.showModal = false;
    const data = this.registerForm.value;

    if (data.upload_name != null) {
      this.formData = new FormData();
      this.formData.append("id", data.id);
      this.formData.append("firstName", data.firstname);
      this.formData.append("lastName", data.lastname);
      this.formData.append("profileName", data.firstname);
      this.formData.append("mobileNumber1", data.mobileNumber1);
      this.formData.append("dob", data.dob);
      this.formData.append("gender", data.gender);
      this.formData.append("upload_name", data.upload_name, data.upload_name.name);
      this.formData.append("street", data.street);
      this.formData.append("area", data.area);
      this.formData.append("city", data.city);
      this.formData.append("state", data.state);
      this.formData.append("postalCode", data.postalCode);
      this.formData.append("currentCity", data.currentCity);
      this.formData.append("qualification", data.qualification);
      this.formData.append("preferences", data.preferences);
      this.formData.append("currentEducation", data.currentEducation);
      this.formData.append("profession", data.profession);
      this.formData.append("guardianName", "Ash Ketchum");
    }

    if (data.upload_name == null) {
      this.formData = {
        firstName: data.firstname,
        lastName: data.lastname,
        profileName: data.firstname,
        mobileNumber1: data.mobileNumber1,
        dob: data.dob,
        gender: data.gender,
        street: data.street,
        area: data.area,
        city: data.city,
        state: data.state,
        postalCode: data.postalCode,
        currentCity: data.currentCity,
        qualification: data.qualification,
        preferences: data.preferences,
        currentEducation: data.currentEducation,
        profession: data.profession,
        linkedinURL: data.linkedIn,
        instagramURL: data.instagram,
        facebookURL: data.facebook,
        twitterURL: data.twitter,
        guardianName: "Ash Ketchum",
        guardianPhone: "123456789",
        userBio: data.bio,
        youtubeURL: data.youtube
      }
    }
    const webToken = localStorage.getItem('webtoken');
    this.userApi.userData_UpdateTeacher(this.formData, webToken).subscribe((response) => {
      if (response.status) {
        this.toastr.successToastr('User Profile Update Successfully');
        this.ngOnInit();
      }
    }, (err) => {
      console.log(err);
    });


  }
  backto() {
    this.location.back();
  }
  detailsMeeting(data, pagename) {
    data.pagename = pagename;
    var jsonStr = JSON.stringify(data)
    localStorage.setItem('livedetails', jsonStr);
    this.router.navigate(['/teacher/meetingDetails/' + "/" + data.meetingID], { state: data });
  }

  coursedetails(data) {
    console.log(data);
    this.router.navigateByUrl('courses/' + data.categoryID.categoryName + "/" + data._id);
  }

  Examdetails(data) {
    console.log(data);
    this.router.navigateByUrl('exams/' + data.categoryID.categoryName + "/" + data._id);
  }

  getAllRequest() {
    this.userApi.getAllRequest().subscribe((result) => {
      if (result.status) {
        this.requestedDataList = result.data;
      }
    }, (err) => {
      console.log(err);
    });
  }
  getAlbumList() {
    const webToken = localStorage.getItem('webtoken');
    this.userApi.userName_DisplayTeacher(webToken).subscribe((result) => {
      var req = {
        profileType: this.isTeacher ? 'teacher' : 'student',
        groupName: result.data.groupName
      }
      this.userApi.libraryTeacher(req, webToken).subscribe((result) => {
        if (result.status) {
          this.videoList = [...result.data.publicVideo, ...this.publicsessionDataRecord, ...this.buddigroupDataRecord];
          console.log("videolist",this.videoList);        
          this.videoListGroup = result.data.groupVideo;
          this.videoListPrivate = result.data.privateVideo;
          this.videoYoutubePublic = result.data.youtubeVideo;
          this.songsListGroup = result.data.groupSong;
          this.songsList = result.data.publicSong;
          this.songsListPrivate = result.data.privateSong;
          this.ebooklistbyId = result.data.publicEbook;
          this.ebooklistbyIdPrivate = result.data.privateEbook;
          this.ebooklistbyIdGroup = result.data.groupEbook;
        }
      });

      this.userApi.VideoUploadTeacher(webToken).subscribe((result) => {
        if (result.status) {
          this.teacherVideouploadlist = result.data;
        }
      });   
    });
  }

  getmyEbook() {
    var req = {
      profileType: this.isTeacher ? 'teacher' : 'student',
      groupName: this.groupName
    }
    this.api.getEbookByAllPrivacyType(req, localStorage.getItem('webtoken')).subscribe((result) => {
      if (result.status) {
        this.ebooklistbyId = result.data
      }
    }, (err) => {
      console.log(err);
    });
  }
  getmyaudio() {
    var req = {
      profileType: this.isTeacher ? 'teacher' : 'student',
      groupName: this.groupName
    }
    this.api.getAudioByAllPrivacyType(req, localStorage.getItem('webtoken')).subscribe((result) => {
      if (result.status) {
        console.log("audio",result.data);
        this.songsList = result.data
      }
    }, (err) => {
      console.log(err);
    });
  }

  ebookdetails(data) {
    this.router.navigate(['/ebook-details/' + data._id]);
  }
  videodetails(data) {
    this.router.navigate(['/video-details/' + data._id]);
  }
  uploadedvideodetails(data) {
    this.router.navigate(['/uploaded-video-details/' + data._id]);
  }
  audiodetails(data) {
    this.router.navigate(['/audio-details/' + data._id]);
  }
  requestMeeting() {
    this.router.navigate(['/teacher/requests']);
  }
  requestTests() {
    this.router.navigate(['/buddi-test-request/Accept']);
  }
  requestTestsreview() {
    this.router.navigate(['/buddi-test-request/Review']);
  }
  albumList(data, header, pagename) {
    data.itemname = header;
    data.pagename = pagename;
    var jsonStr = JSON.stringify(data);
    localStorage.setItem('videoDetails', jsonStr);
    this.router.navigate(['/all-details/' + header + '/' + data.typename], { state: data });
  }
  removevideo(id) {
    var video_id = id;
    var passdata = { video_id: "" };
    passdata.video_id = video_id;
    this.api.delMultipleVideo(passdata).subscribe((response) => {
      console.log(response);
      if (response.status) {
        this.toastr.successToastr(response.message);
        this.ngOnInit();
      } else {
        this.toastr.errorToastr(response.message);
      }

    });
  }
  changeRole(type) {
    if (type == 'School') {
      this.isSchool = true
    } else {
      this.isSchool = false
    }
  }
  changeRoleLibrary(type) {
    if (type == 'School') {
      this.libraryForm.patchValue({ libraryTypeSplit: 'School' });
    } else {
      this.libraryForm.patchValue({ libraryTypeSplit: 'Public' });
    }
  }
  changeRoleStudy(type) {
    if (type == 'School') {
      this.studyMaterialForm.patchValue({ studymaterialType: 'School' });
    } else {
      this.studyMaterialForm.patchValue({ studymaterialType: 'Public' });
    }
  }
  changeRoleCourse(type) {
    if (type == 'School') {
      this.isSchoolcourse = true
    } else {
      this.isSchoolcourse = false
    }
  }
  changeRoleExam(type) {
    if (type == 'School') {
      this.isSchoolexam = true
    } else {
      this.isSchoolexam = false
    }
  }
  fetchrecordvideo(id, type) {
    var req = {
      [type]: id
    }
    this.api.fetchrecordvideo(req).subscribe((result) => {
      if (result.status) {
        window.open(result.data[0].recordurl, '_blank');
      }
    }, (err) => {
      this.toastr.errorToastr(err.message);
    });
  }

  gettestsdata() {
    const webToken = localStorage.getItem('webtoken');
    this.userApi.getaccepted(webToken).subscribe((result) => {
      if (result.status) {
        this.uncorrecteddata = result.data.filter(item => item.toBeFinishedReviewing === false);;
        this.reviewdata = result.data.filter(item => item.toBeFinishedReviewing === true);
      }
      else {
        this.toastr.errorToastr(result.message);
      }
    }, (err) => {
      console.log(err);
    });
  }


  testdetails(id) {
    this.router.navigate(['/buddi-test-correction/' + id]);
  }
  chosenYearHandler( normalizedYear: Moment ) {
        const ctrlValue = this.date.value;
        //ctrlValue.year(normalizedYear.year());
         var dateSe = new Date().getFullYear()
        this.date.setValue(normalizedYear);
  }

    chosenMonthHandler( normalizedMonth: Moment, datepicker: OwlDateTimeComponent<Moment> ) {
        const ctrlValue = this.date.value;
       // ctrlValue.month(normalizedMonth.month());
        var dateSe = new Date().getMonth()
        var dataFormatWith = normalizedMonth;
        this.date.setValue(dataFormatWith);
        this.getTeacherAccount();
        datepicker.close();
    }

  testdetailsreview(id) {
    this.router.navigate(['/buddi-test-review/' + id]);
  }


// Solution Paper Start

getStudyMaterial(data){
  const webToken = localStorage.getItem('webtoken');
    var req = {
      profileType : this.isTeacher ? 'teacher' : 'student',
      currentPage : data.currentPage ? data.currentPage : 1,
      groupName : this.groupName ? this.groupName : '',
      list : data.list ? data.list : 'solution',
      type :  data.type ? data.type : 'solution',
      itemsPerPage : data.pageNumber ? data.pageNumber : environment.listing__itemsPerPage,
    }
    switch(data.type){
      case 'Public':{
        this.getPublic(req,webToken);
        break;
      }
      case 'Group':{
        this.getGroup(req,webToken);
        break;
      }
    }
    if(data.type==undefined){
      this.getPublic(req,webToken);
      this.getGroup(req,webToken);
    }  
}
getStudyMaterialQuestion(data){
  const webToken = localStorage.getItem('webtoken');
    var req = {
      profileType : this.isTeacher ? 'teacher' : 'student',
      currentPage : data.currentPage ? data.currentPage : 1,
      groupName : this.groupName ? this.groupName : '',
      list : data.list ? data.list : 'solution',
      type :  data.type ? data.type : 'solution',
      itemsPerPage : data.pageNumber ? data.pageNumber : environment.listing__itemsPerPage,
    }
    switch(data.type){
      case 'Public':{
        this.getPublicQuestion(req,webToken);
        break;
      }
      case 'Group':{
        this.getGroupQuestion(req,webToken);
        break;
      }
    }
    if(data.type==undefined){
      this.getPublicQuestion(req,webToken);
      this.getGroupQuestion(req,webToken);
    }  
}

getPublicQuestion(req,webToken){
  this.SpinnerService.show();
  this.userApi.publicQuestion(req, webToken).subscribe((result) => {
    this.SpinnerService.hide();
    if (result.status) {
      this.studyMaterial.questionPublic = result.data;
      this.config.publicQuestion = {
        itemsPerPage: environment.listing__itemsPerPage,
        currentPage: result.currentPage ? result.currentPage : 1,
        totalItems:  result.dataCount,
        id:'publicQuestionTeacher',
        type:'Public'
      };
    }else{
      this.studyMaterial.questionPublic = [];
    }
  });
}
getGroupQuestion(req,webToken){
  this.SpinnerService.show();
  this.userApi.groupQuestion(req, webToken).subscribe((result) => {
  this.SpinnerService.hide();
    if (result.status) {
      this.studyMaterial.questionGroup = result.data;
      this.config.groupQuestion = {
      itemsPerPage: environment.listing__itemsPerPage,
      currentPage: result.currentPage ? result.currentPage : 1,
      totalItems:  result.dataCount,
      id:'groupQuestionTeacher',
      type:'Group'
     };
    }else{
      this.studyMaterial.questionGroup = [];
    }
  });
}
getPublic(req,webToken){
  this.SpinnerService.show();
  this.userApi.publicSolution(req, webToken).subscribe((result) => {
    this.SpinnerService.hide();
    if(result.status) {
      this.studyMaterial.solutionPublic = result.data;
      this.config.public = {
        itemsPerPage: environment.listing__itemsPerPage,
        currentPage: result.currentPage ? result.currentPage : 1,
        totalItems:  result.dataCount,
        id:'publicSolutionTeacher',
        type:'Public'
      };
    }else{
      this.studyMaterial.solutionPublic = [];
    }
  });
}
getGroup(req,webToken){
  this.SpinnerService.show();
  this.userApi.groupSolution(req, webToken).subscribe((result) => {
  this.SpinnerService.hide();
    if(result.status) {
      this.studyMaterial.solutionGroup = result.data;
      this.config.group = {
      itemsPerPage: environment.listing__itemsPerPage,
      currentPage: result.currentPage ? result.currentPage : 1,
      totalItems:  result.dataCount,
      id:'groupSolutionTeacher',
      type:'Group'
     };
    }else{
      this.studyMaterial.solutionGroup = [];
    }
  });
}
receivePageNumber(data){
  if(data.list=="question"){
    this.getStudyMaterialQuestion(data);
  }else if(data.list=="solution"){
    this.getStudyMaterial(data);
  }

  
}
// Solution Paper End

}
