<app-header-style-one *ngIf="!isTeacher"></app-header-style-one>
    <app-header-style-one-teacher
        *ngIf="isTeacher"
    ></app-header-style-one-teacher>
<div  class="">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="/student/home">Home</a></li>
                <li><a href="/all-video-listing">Video Lessons</a></li>
                <li *ngIf="livedetails" >{{livedetails.videoName}} </li>
            </ul>
            <h3 style="color: #000000; padding-top:25px;padding-bottom:25px; cursor: pointer" class="col-lg-12">
					Video Lessons
				</h3>
        </div>
    </div>
</div>

<section class="courses-details-area pt-50 pb-70" *ngIf="livedetails">
    <div class="container">
        <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="courses-title">
                        <h2 style="overflow-wrap: break-word;">{{livedetails.flimname}} 
                        </h2>                      
                    </div>
                    <div class="courses-meta">
                        <ul>
                            <li *ngIf="livedetails.categoryID">
                                <i class='bx bx-folder-open'></i>
                                <span >Category</span>
                                <!-- <a>{{categoryarrayvalues}}</a> -->
                                <a>{{livedetails.categoryID.categoryName}}</a>

                            </li>
                        </ul>
                    </div>
                    <div class="courses-meta">                        
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="courses-price">
                        <div class="courses-review">
                        </div>
                        <div class="price"></div>
                        <a class="default-btn mr-2" target="_blank" *ngIf="livedetails.providerByName=='You Tube' && isLoggedin =='true'" [href]="livedetails.name">                            
                            <i class='bx bx-paper-plane icon-arrow before'></i>
                                <span class="label">Watch</span>
                            <i class="bx bx-paper-plane icon-arrow after"></i>
                        </a>  
                        <!-- <a  class="default-btn mr-1 kin" ><i class="fas fa-long-arrow-alt-left mr-1"></i>Back</a> -->
                        <div class="container"><button (click)="backto()" style="width: 16%;height:45px;margin-left: 83%;margin-top:1%;padding: 9px;"><i class="fas fa-long-arrow-alt-left mr-1"></i>Back</button></div>
                        <!-- <a class="default-btn " *ngIf="isrequest && (isLoggedin =='true')" (click)="requestAccept()">                            
                            <i class="fas fa-plus icon-arrow before"></i>
                            <span class="label">Add to Library</span>
                            <i class="fas fa-plus icon-arrow after"></i>
                        </a>                         -->
                        <!--<a  class="default-btn mr-1" *ngIf="isLoggedin =='true'" (click)="payments()">                            
                            <i class="fas fa-plus icon-arrow before"></i>
                            <span class="label">Buy now</span>
                            <i class="fas fa-plus icon-arrow after"></i>
                        </a>
                        <a  class="default-btn mr-1" *ngIf="isLoggedin =='true' && purchase =='true'" (click)="payments()">                            
                            
                        </a>
                        <a *ngIf="!isItemAddedToCart && isLoggedin =='true'" class="default-btn mr-1" (click)="addcart()">                            
                            <i class="fas fa-plus icon-arrow before"></i>
                            <span class="label">Add Cart</span>
                            <i class="fas fa-plus icon-arrow after"></i>
                        </a>
                       <a *ngIf="isItemAddedToCart && isLoggedin =='true'" class="default-btn mr-1 inactiveLink">                            
                            <span class="label">Added To Cart</span>
                        </a> -->
                        <!-- <div class="check" *ngIf="livedetails.teacherID!=null"> -->

                        <button  *ngIf="isLoggedin =='true' && purchase == false && livedetails.teacherID._id != teacherID" class="default-btn mr-1" (click)="addToDashboard(livedetails)">Add to Dashboard</button>
                        <!-- <a  *ngIf="isLoggedin == 'true' && livedetails.teacherID._id == teacherID" class="default-btn mr-1"  (click)="redirectToEdit(livedetails)"><i class="bi bi-pencil-square kin"></i>Edit</a> -->
                        <button class="default-btn " *ngIf="isLoggedin=='false'" (click)="login()">    
                            <i class='bx bx-paper-plane icon-arrow before'></i>
                            <span class="label">Login</span>
                            <i class="bx bx-paper-plane icon-arrow after"></i>
                        </button>
                        <!-- </div>                       -->
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-8">
            <!-- <div class="col-lg-8" *ngIf="livedetails.teacherID!=undefined"> -->
                <div *ngIf="(isLoggedin=='true' && livedetails.providerByName!='You Tube' && purchase == true) || livedetails.teacherID._id == teacherID; else thumbnail" class="video-size-details d-block mx-auto embed-responsive embed-responsive-16by9 mb-1">
                    <video  controls class="" (click)="toggleVideo($event)" #videoPlayer [src]="livedetails.videoPath" class="d-block mx-auto" alt="No Video Available in Album"></video>
                </div>
                <!--<div  *ngIf="isLoggedin=='true' || livedetails.providerByName=='You Tube'" class="courses-details-image text-center">
                    <h3 style="color: var(--mainColor);">Please buy the Video, to watch !</h3>
                    <img [src]="livedetails.thumbnail !='' ? livedetails.thumbnail : 'assets/img/courses/courses1.jpg'" alt="image">
                </div>-->
                <!-- <div  *ngIf="(isLoggedin=='false' && livedetails.providerByName !='You Tube') || (purchase == false && livedetails.teacherID._id != teacherID)" class="courses-details-image text-center">
                    <img [src]="livedetails.thumbnail !='' ? livedetails.thumbnail : 'assets/img/courses/courses1.jpg'" alt="image">
                </div>  -->
                <ng-template #thumbnail class="courses-details-image text-center"> 
                    <img [src]="livedetails.thumbnail ? livedetails.thumbnail : 'assets/img/courses/courses1.jpg'" alt="image">
                </ng-template>
                <!-- <div class="row" *ngIf="livedetails.providerByName!='You Tube'">
                    <div class="col-lg-10 pt-3">
                        {{viewCount}} Views
                    </div> -->
                     <!-- <div class="col-lg-2 pt-2" >                        
                        <i  *ngIf="isLoggedin=='true'" (click)="likeReview(reviewForm,'add')"  class="fa fa-thumbs-up ml-1 mr-1" style="font-size:20px" [class]="(userlike.length ==0) ? 'text-primary':''"><span  style="font-size:16px" [class]="(userlike.length ==0 && !isteacherRoute)? 'text-primary':''"></span></i>
                        <i  *ngIf="isLoggedin=='false'" class="fa fa-thumbs-up ml-1 mr-1" style="font-size:20px" ><span  style="font-size:16px" ></span></i>
                        <span class="mt-2" data-toggle="modal" data-target="#myModal" style="font-size:20px" [class]="userlike.length !=0 && !isteacherRoute ? 'text-primary':''">
                            <span  style="font-size:16px" [class]="userlike.length !=0 && !isteacherRoute ? 'text-primary':''"> 
                            {{reviewLike.length}}
                            </span>
                        </span>
                    </div>               -->
                <!-- </div>  -->

                <!-- <hr>
               
                     <div class="modal fade" id="myModal" role="dialog">
                    <div class="modal-dialog">
                        <div class="modal-content"> -->
                            <!-- <div class="modal-header">                      
                                <span class="bold">Likes {{reviewLike.length}} </span>
                            </div> -->
                            <!-- <span class="modal-body bold text-primary" >
                                <commaFor [forData]="reviewLike"></commaFor>
                                <<span *ngFor="let item of reviewLike">{{item.name}},</span> -->
                            <!-- </span>  -->
                            <!-- <div class="modal-footer">
                                <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                  
                    </div>
                </div> -->
                <div class="mt-5">
                    <label>
                        Description
                    </label>
                    <div class="col-lg-12 text-break">
                            {{livedetails.description =='' ? '-' : livedetails.description}}
                    </div>
                </div>
            </div>
          
            <div class="col-lg-4">
                <div class="courses-sidebar-information">
                    <ul>
                        <li style="overflow-wrap: break-word;">
                            <span><i class='bx bx-time'></i> Video Name:</span>
                            {{livedetails.videoName =='' ? '-' :livedetails.videoName}}
                        </li>
                        
                       <!--  <li>
                            <span><i class='bx bx-group'></i> Privacy Type:</span>
                            {{livedetails.securitytype =='Group' ? 'School':livedetails.securitytype}}
                        </li> -->
                        <li *ngIf="livedetails.securitytype =='Group'">
                            <span><i class='bx bx-time'></i> School Name:</span>
                            {{livedetails.groupname}}
                        </li>
                        <!--<li>
                            <span><i class='bx bx-tachometer'></i>Grade:</span>
                            {{livedetails.topicID.gradeID ? livedetails.topicID.gradeID.gradeName: ''}}

                        </li> -->
                        <!-- <li *ngIf="livedetails.boardID"> -->
                            <!-- <li>
                            <span><i class='bx bx-tachometer'></i>Board:</span>
                            {{livedetails.boardID ? livedetails.boardID.boardName: '-'}}
                        </li> -->
                        <!-- <li>
                            <span><i class='bx bx-tachometer'></i>Subject:</span>
                            {{livedetails.topicID.subjectID ? livedetails.subjectID.subjectName: ''}}

                        </li> -->
                        <li>
                            <span><i class='bx bx-tachometer'></i>Language:</span>
                            {{livedetails.languageID ? livedetails.languageID.languageName: ''}}
                        </li>
                        <li style="overflow-wrap: break-word;" *ngIf= "livedetails.moduleID">
                            <span><i class='bx bx-time'></i> Module Name:</span>
                            {{livedetails.moduleID.moduleName == '' ? '-' :livedetails.moduleID.moduleName}}
                        </li>
                        <!-- <li style="overflow-wrap: break-word;">
                            <span><i class='bx bx-time'></i> Chapter Name:</span>
                            {{livedetails.chapterName =='' ? '-' :livedetails.chapterName}}
                        </li> -->
                        
                        <li style="overflow-wrap: break-word;"  *ngIf= "livedetails.topicID">
                            <span><i class='bx bx-time'></i> Topic Name:</span>
                            <!-- {{livedetails.topicID.topicName}} -->
                            {{livedetails.topicID.topicName =='' ? '-' :livedetails.topicID.topicName}}

                        </li>
                       
                        <!-- <li>
                            <span><i class='bx bx-time'></i> Type(Optional):</span>
                            {{livedetails.type =='' ? '-' :livedetails.type }}
                        </li>
                       
                        
                      
                        <li class="hashtag">
                            <span><i class='bx bxs-badge-check'></i> Tag:</span>                            
                            <a  *ngFor="let tagObj of tagArray"> {{tagObj | removespace}}</a>                           
                        </li> -->
                        <!-- <li style="overflow-wrap: break-word;">
                            <span><i class='bx bxs-badge-check'></i> Author:</span>
                            {{livedetails.teacherID ? livedetails.teacherID.firstName + " " + livedetails.teacherID.lastName : '-'}}
                        </li> -->
                        
                        <!-- <li *ngIf="livedetails.boardID"> -->
                        <li >
                            <span><i class='bx bx-tachometer'></i>Level:</span>
                            {{livedetails.level ? livedetails.level: ''}}
                        </li>
                        <!-- <li *ngIf="livedetails.boardID"> -->
                            <li >
                            <span><i class='bx bx-tachometer'></i>Price:</span>
                            {{livedetails.price}}
                        </li>
                       <!--  <li>
                            <span><i class='bx bxs-badge-check'></i> Additional.info:</span>
                            {{livedetails.additional =='' ? '-' : livedetails.additional}}
                        </li> -->
                        <!-- <li *ngIf="livedetails.isPartner" style="overflow-wrap: break-word;"> -->
                            <!-- <li  style="overflow-wrap: break-word;">
                            <span><i class='bx bxs-badge-check'></i> Provider By:</span>
                            {{livedetails.partner ==null? livedetails.providerByName ==''? 'buddi Premium' : livedetails.providerByName : livedetails.partner.partnerName}}
                        </li> -->
                     
                       
                    </ul>
                </div>
                <div class="row">
                    <div  *ngIf="isTeacher" class=" mr-1">
                        <button  (click)="editvideo()" > 
                            <a  target=”_blank”><i class="bi bi-pencil-square kin"></i>Edit</a>
                        </button>
                    </div>
                    <div  (click)="editvideo()" class=" mr-1" *ngIf="!isTeacher && livedetails.securitytype =='Private'">
                        <button class=" mr-1"> 
                            <a  target=”_blank”><i class="bi bi-pencil-square kin"></i>Edit</a>
                        </button>
                    </div> 

                     <div class="float-right mr-1" (click)="deletevideo()" *ngIf="isTeacher">
                        <button class=" mr-1"> 
                            <a  target=”_blank”>Delete</a>
                        </button>
                    </div> 
                     <div class="float-right mr-1" (click)="deletevideo()" *ngIf="!isTeacher && livedetails.securitytype =='Private'">
                        <button class=" ml-1"> 
                            <a  target=”_blank”>Delete</a>
                        </button>
                    </div> 
                     <!-- <div class="ml-3" *ngIf="!isrequest && livedetails.securitytype !='Private' && isLoggedin=='true' && !isteacherRoute">
                        <a class="default-btn" (click)="removeFromList(livedetails)">    
                         <i class='bx bx-paper-plane icon-arrow before'></i> -->
                            <!-- <span class="label">Remove</span> -->
                            <!-- <i class="bx bx-paper-plane icon-arrow after"></i> -->
                        <!-- </a> 
                    </div> -->
                    
                </div>         
            </div>
        </div>
    </div>
</section>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <img src="assets/img/spinner-default.gif" alt="">
</ngx-spinner>