
<div *ngIf="!isStudent">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>
<div *ngIf="isStudent">
    <app-header-style-one></app-header-style-one>
</div>
<script src="https://checkout.razorpay.com/v1/checkout.js"></script>
<div class="wrap cf">
    <div class="heading cf">
        <h1>Wallet Recharge</h1>
    </div>
    <div class="amount">
        <br>
        <h3>Enter amount to recharge: </h3>
        <input type="text" id="rechargeAmount" name="rechargeAmount">
    </div>
    <a (click)="submit()" href="javascript:void(0)" class="btn continue">Recharge</a>

</div>