import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, Validators} from '@angular/forms';
import { ApiService } from '../../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { lastnamevalidator } from './../../../../shared/lastnamevalidator'

@Component({
  selector: 'app-student-edit-profile',
  templateUrl: './student-edit-profile.component.html',
  styleUrls: ['./student-edit-profile.component.scss']
})
export class StudentEditProfileComponent implements OnInit {

  registerForm: FormGroup;
  selectedFileName: string = 'Upload Profile Photo';
  public userPhoto: any;
  name: any;
  submitted = false;
  showModal: boolean;
  formData;
  stateInfo: any[] = [];
  countryInfo: any[] = [];
  cityInfo: any[] = [];
  id: any;
  facebook: any;
  twitter: any;
  instagram: any;
  linkedin: any;
  youtube: any;
  bio: any;
  listlanguages : any;
  listsubjects : any;
  listboard : any;
  Mobilenumber : any;
  listgrade : any;
  listcategories: any;
  listcountries : any;
  public islastname = false;
  public isOneonone:any = [];
  constructor(private activatedRoute: ActivatedRoute,private location:Location,private formBuilder: FormBuilder,private userApi: ApiService,public toastr: ToastrManager, private router: Router) { }

  ngOnInit() {
   
    const webToken = localStorage.getItem('webtoken');  
    this.isOneonone =  window.history.state
    console.log(window.history.state,'window.history.state');
    if(window.history.state.pageName=="oneOnone"){
       $('#myModal1').show();
       document.getElementById("editForm").scrollIntoView();
    }
    if(window.history.state.pageName == 'dashboard'){
      $('#myModalcheck').show();
      document.getElementById("editForm").scrollIntoView();

    }
    this.userApi.studentSubject(webToken).subscribe((result) =>{
      console.log('subject',result.data[0].subjectName)
      this.listsubjects = result.data;
    })

    this.userApi.board(webToken).subscribe((result) =>{
      console.log('boards',result.data)
      this.listboard = result.data;
    })

    this.userApi.gradefield().subscribe((result) =>{
      console.log('grades',result.data)
      this.listgrade = result.data;
    })

    this.userApi.languagefield().subscribe((result) =>{
      console.log('languages',result.data)
      this.listlanguages = result.data;
    })

    this.userApi.getCourseCategory().subscribe((result) =>{
      console.log('categories',result.data)
      this.listcategories = result.data;
    })

    this.userApi.getcountry().subscribe((result) =>{
      console.log('countries',result)
      this.listcountries = result;
    })

    this.userApi.board(webToken).subscribe((result) =>{
      console.log('board',result.data);
      this.listboard = result.data;
    })

    this.userdetails();  
    this.registerForm = this.formBuilder.group({
      id: null,
      upload_name: [null],
      fileupload: [''],
      firstname: ['', [Validators.required, Validators.pattern("^[a-zA-Z ,.'-]+$"), lastnamevalidator.cannotContainSpace]],
      lastname: ['', [Validators.required, Validators.pattern("^[a-zA-Z ,.'-]+$"), lastnamevalidator.cannotContainSpace]],
      email: [''],
      mobileNumber1: [''],
      dob: [''],
      gender: [''],
      street: [''],
      area: [''],
      city: [''],
      schoolName: [''],
      subjects: ['',Validators.required],
      boards:['',Validators.required],
      categories: ['',Validators.required],      
      grades: ['',Validators.required],
      languages:['',Validators.required],
      address1:[''],
      address2:[''],
      guardianName: [''],
      guardianPhone: [''],
      state: [''],
      countries: [''],
      postalCode: [''],
      currentCity: [''],
      qualification: [''],
      preferences: [''],
      currentEducation: [''],
      profession: [''],
      facebook: [''],
      twitter: [''],
      instagram: [''],
      linkedIn: [''],
      youtube: [''],
      bio:[''],
      Mobilenumber:['']
      
  });
  }

  get f() {return this.registerForm.controls; }

  getCountries(){
    this.userApi.allCountries().
    subscribe(
      data2 => {
        this.countryInfo=data2.Countries;
        console.log('Data:', this.countryInfo);
      },
      err => console.log(err),
      () => console.log('complete')
    )
  }

  onChangeCountry(countryValue) {
    this.stateInfo=this.countryInfo[countryValue].States;
    this.cityInfo=this.stateInfo[0].Cities;
    console.log(this.stateInfo);
  }

  onChangeState(stateValue) {
    this.cityInfo=this.stateInfo[stateValue].Cities;
    console.log(this.cityInfo);
  }

  fileChange(event) {
    let fileList: FileList = event.target.files;
    this.registerForm.patchValue({ upload_name: fileList[0] });
    this.selectedFileName = fileList[0].name;
    this.registerForm.patchValue({ fileupload: this.selectedFileName });
    console.log('image',this.selectedFileName)
    console.log(this.registerForm.value);
  }

  userdetails() {
    const webToken = localStorage.getItem('webtoken');
    if(webToken != null){
    this.userApi.userName_DisplayStudent(webToken).subscribe((result) => { 
      console.log('resultUpdate-Student',result);     
      var dataResult = result.data.studentDetails;  
      console.log(dataResult,'dataResultcheck');   
        this.registerForm.patchValue({
          id : dataResult._id,
          subjects : Object.keys(dataResult.subjects).length==0 || dataResult.subjects=='All'? '' : dataResult.subjects._id,
          grades :  Object.keys(dataResult.grade).length==0 || dataResult.grade=='All'? '' : dataResult.grade._id,
          boards : Object.keys(dataResult.board).length==0 || dataResult.board=='All'? '' : dataResult.board._id,
          categories : Object.keys(dataResult.categories).length==0 || dataResult.categories=='All'? '' : dataResult.categories._id,
          languages : Object.keys(dataResult.language).length==0 || dataResult.language=='All'? this.listlanguages[0]._id : dataResult.language._id,
          firstname : dataResult.firstName,
          lastname : dataResult.lastName,
          address1 : dataResult.address1,
          address2 : dataResult.address2,
          guardianName : dataResult.guardianName,
          guardianPhone : dataResult.guardianPhone,
          countries : dataResult.country ==undefined ? "" : dataResult.country,
          email : dataResult.email == undefined ? "" : dataResult.email,
          Mobilenumber : dataResult.phoneNumber == undefined ? "" : dataResult.phoneNumber,
          dob : dataResult.dob,
          gender : dataResult.gender ==undefined ? "" : dataResult.gender,
          street : dataResult.street,
          area : dataResult.area == undefined ? '' : dataResult.area,
          city : dataResult.city == undefined ? '' : dataResult.city,
          state : dataResult.state == undefined ? '' : dataResult.state,
          postalCode :  dataResult.postalCode == undefined ? '' : dataResult.postalCode,
          currentCity : dataResult.currentCity == undefined ? '' : dataResult.currentCity,
          preferences : dataResult.preferences == undefined ? '' : dataResult.preferences,
          qualification : dataResult.qualification == undefined ? '' : dataResult.qualification,
          currentEducation : dataResult.currentEducation == undefined ? '' : dataResult.currentEducation,
          profession : dataResult.profession == undefined ? '' : dataResult.profession,
        });       
        this.selectedFileName = dataResult.userPhoto.split('/')[dataResult.userPhoto.split('/').length - 1].length > 29 ? dataResult.userPhoto.split('/')[dataResult.userPhoto.split('/').length - 1].slice(0, 30) + '...' : dataResult.userPhoto.split('/')[dataResult.userPhoto.split('/').length - 1];
        this.userPhoto = dataResult.userPhoto
        this.name = dataResult.profileName;
        this.id = dataResult._id;
        const userId = { id: this.id }
      // this.userApi.userPricingData_Get(userId,webToken).subscribe((response) => {
      //   console.log(response)
      //   let assignAddData : any = {};
      //   assignAddData.facebook = response.data.facebookURL =='undefined' ? '' : response.data.facebookURL;
      //   assignAddData.twitter = response.data.twitterURL =='undefined' ? '' : response.data.twitterURL;
      //   assignAddData.instagram = response.data.instagramURL =='undefined' ? '' : response.data.instagramURL;
      //   assignAddData.linkedIn = response.data.linkedinURL =='undefined' ? '' : response.data.linkedinURL;
      //   assignAddData.youtube = response.data.youtubeURL =='undefined' ? '' : response.data.youtubeURL;
      //   assignAddData.bio = response.data.userBio =='undefined' ? '' :  response.data.userBio;
      //   this.registerForm.patchValue(assignAddData);     
      //   console.log(assignAddData); 
       
      // });
                
    }, (err) => {
    console.log(err);
    });
   }
  } 

  onSubmit(FormGroup) {
    this.submitted = true;
    if (this.registerForm.invalid && !this.registerForm.value.id) {
      return;      
    }
    if(this.registerForm.invalid) {
     
       //To check the invalid field
      const invalid = [];
      const controls = this.registerForm.controls;
      console.log('controls',controls);
      for (const name in controls) {

          if (controls[name].invalid) {
              invalid.push(name);
            if(name == "lastname") {
              this.toastr.errorToastr('please check Last Name');
            }
            else {
              this.toastr.errorToastr('Select '+name);
            }
          }
      }
      return;
    }
    this.showModal = false;
  console.log('profile update value',this.registerForm.value);
  const data = this.registerForm.value;
  console.log('Form Data',data)
  if(data.upload_name!=null){
  this.formData = new FormData();
  this.formData.append("id", data.id);
  this.formData.append("firstName",data.firstname);
  this.formData.append("lastName",data.lastname);
  this.formData.append("profileName",data.firstname);
  this.formData.append("mobileNumber1",data.mobileNumber1);
  this.formData.append("dob",data.dob);
  this.formData.append("gender",data.gender);
  this.formData.append("upload_name",data.upload_name,data.upload_name.name);
  this.formData.append("street", data.street);
  this.formData.append("area", data.area);
  this.formData.append("city", data.city);
  this.formData.append("grade", data.grades);
  this.formData.append("address1", data.address1);
  this.formData.append("address2", data.address2);
  this.formData.append("schoolName",data.schoolName);
  this.formData.append("subjects", data.subjects);
  this.formData.append("language", data.languages);
  this.formData.append("board", data.boards);
  this.formData.append("categories", data.categories);
  this.formData.append("guardianName",data.guardianName);
  this.formData.append("guardianPhone",data.guardianPhone)
  this.formData.append("state", data.state);
  this.formData.append("country", data.countries);
  this.formData.append("postalCode", data.postalCode);
  this.formData.append("currentCity", data.currentCity);
  this.formData.append("qualification", data.qualification);
  this.formData.append("preferences", data.preferences);
  this.formData.append("currentEducation", data.currentEducation);
  this.formData.append("profession", data.profession);
  this.formData.append("linkedinURL", data.linkedIn);
  this.formData.append("instagramURL", data.instagram);
  this.formData.append("facebookURL", data.facebook);
  this.formData.append("twitterURL", data.twitter);
  this.formData.append("userBio", data.bio);
  this.formData.append("youtubeURL", data.youtube);
  this.formData.append("email", data.email);
  }

  if(data.upload_name == null){
    this.formData = {
      firstName: data.firstname,
      lastName: data.lastname,
      profileName: data.firstname,
      mobileNumber1: data.mobileNumber1,
      dob: data.dob,
      gender: data.gender,
      street: data.street,
      area: data.area,
      city: data.city,
      address1: data.address1,
      address2: data.address2,
      schoolName: data.schoolName,
      language:data.languages,
      subjects: data.subjects,
      board: data.boards,
      categories:data.categories,
      grade:data.grades,
      guardianName: data.guardianName,
      guardianPhone: data.guardianPhone,
      state: data.state,
      country : data.countries,
      postalCode: data.postalCode,
      currentCity: data.currentCity,
      qualification: data.qualification,
      preferences: data.preferences,
      currentEducation: data.currentEducation,
      profession: data.profession,
      linkedinURL: data.linkedIn,
      instagramURL: data.instagram,
      facebookURL: data.facebook,
      twitterURL: data.twitter,
      userBio: data.bio,
      youtubeURL: data.youtube,
      email: data.email
    }
  }
  console.log(this.formData);
  const webToken = localStorage.getItem('webtoken'); 
  this.userApi.userData_UpdateStudent(this.formData, webToken).subscribe((response) => {

    console.log(response,'responseData');
    console.log(this.formData,'this.formData');

  if (response.status) {
   // alert(response.status);
      // alert('User Profile Update Successfully')      
       var localData={
          boardID :response.data.boardID,
          subjectID :response.data.subjectID,
          categoryID :response.data.categoryID,
          gradeID  :response.data.gradeID,
          languageID:response.data.languageID
        }
      localStorage.setItem('educationInfo',JSON.stringify(localData));
      this.toastr.successToastr('User Profile Update Successfully');
      this.router.navigate(['/sidenav']) 

      if(this.isOneonone.pageName!=undefined){
        if(this.isOneonone.isManual){
            this.router.navigate(['/student-onetoone-create/manual']) 
        }else{
          this.router.navigate(['/student-onetoone-create/automation'])   
        }             
      }
      this.location.back();
    }
  }, (err) => {
    //alert(err);
  console.log(err);
  });


}
backto(){
  this.location.back();
}
nextTo(event){
  $('a.active').parent().next().children()[0].click();
}
  closePopup(){
    $('#myModal1').hide()
  }
  closePopup1(){
    $('#myModalcheck').hide()

  }
}
