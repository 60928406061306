<!-- <a href="javascript:void(0)" (click)="liveClassFilter('today')" class="default-btn mr-1">
    <span class="label">Today</span>
</a>
<a href="javascript:void(0)" (click)="liveClassFilter('tomorrow')" class="default-btn mr-1">
    <span class="label">Tomorrow</span>
</a>
<a href="javascript:void(0)" (click)="liveClassFilter('week')" class="default-btn mr-1">
    <span class="label">This Week</span>
</a>
<a href="javascript:void(0)" (click)="liveClassFilter('month')" class="default-btn mr-1">
    <span class="label">This Month</span>
</a> -->
<div *ngIf="isStudent == true && isTeacher == false" class="container mt-3">
    <div class="flexButton">
    <h6 style="
            color: #000000;
            cursor: pointer;            
            border-bottom: 1px solid #f7f0f0 !important;"
        >
        Live Classes
        <!-- <span class="more-text" *ngIf="classList">more</span> -->
    </h6>
    <div class="btn-group ml-3">
        <a href="javascript:void(0)" (click)="liveClassFilter('today')"
        class="d-inline-block"
        style= "margin-bottom: 8px;
        text-decoration-line: underline;
        margin-right: 10px; color: blue;font-size:13px;">Today</a>
        <a href="javascript:void(0)" (click)="liveClassFilter('tomorrow')"
        class="d-inline-block"
        style= "margin-bottom: 8px;
        text-decoration-line: underline;
        margin-right: 10px;  color: blue;font-size:13px;">Tomorrow</a>
        <a href="javascript:void(0)" (click)="liveClassFilter('week')"
        class="d-inline-block"
        style= "margin-bottom: 8px;
        text-decoration-line: underline;
        margin-right: 10px; color: blue;font-size:13px;">This Week</a>
        <a href="javascript:void(0)" (click)="liveClassFilter('month')"
        class="d-inline-block"
        style= "margin-bottom: 8px;
        text-decoration-line: underline;
        margin-right: 10px; color: blue;font-size:13px;">This Month</a>
        <!-- <button (click)="liveClassFilter('today')" class="mr-1">Today</button>
        <button (click)="liveClassFilter('tomorrow')" class="mr-1">Tomorrow</button>
        <button (click)="liveClassFilter('week')" class="mr-1">This Week</button>
        <button (click)="liveClassFilter('month')" class="mr-1">This Month</button> -->
    </div>
</div>
    <div class="container-fluid row">
        <div class="orders-table table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Class Name</th>
                        <th>Topic Name</th>
                        <th>Date</th>
                        <th>Tutor Name</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor='let class of classList; let i = index'>
                        <td>{{i + 1}}</td>
                        <td>{{class.name}}</td>
                        <td *ngIf= "class.personalClassID">{{class.personalClassID.topicID.topicName}}</td>
                        <td>{{class.startDateTime}}</td>
                        <td>{{class.teacherID.profileName}}</td>
                        <td>
                            <div class="Action"><a *ngIf="class.isAccepted == true" href="javascript:void(0)" class="classAccepted" (click)="liveClassFunction(class, 'join')">        <i class="fa fa-sign-in" data-toggle="tooltip" data-placement="bottom" title="Join"></i></a><br  *ngIf="class.isAccepted == true">
                        <a *ngIf="class.isAccepted == false" href="javascript:void(0)" class="classPending" (click)="liveClassFunction(class, 'pending')"><i class='fas fa-info-circle'data-toggle="tooltip" data-placement="bottom" title="Pending"></i></a><br  *ngIf="class.isAccepted == false">
                        <a href="javascript:void(0)" [ngClass]="class.isAccepted == true ? 'classAccepted' : 'classPending'" (click)="liveClassFunction(class, 'reschedule')"><i class="far fa-calendar-alt" data-toggle="tooltip" data-placement="bottom" title="Reschedule"></i></a><br>
                        <a href="javascript:void(0)" [ngClass]="class.isAccepted == true ? 'classAccepted' : 'classPending'" (click)="liveClassFunction(class, 'cancel')"><i class="fas fa-ban" data-toggle="tooltip" data-placement="bottom" title="Cancel"></i></a><br>
                        <a href="javascript:void(0)" [ngClass]="class.isAccepted == true ? 'classAccepted' : 'classPending'" (click)="liveClassFunction(class, 'share')"><i class="fas fa-share-alt-square" data-toggle="tooltip" data-placement="bottom" title="Share"></i></a></div></td>
                    </tr>
                </tbody>
            </table>
            <div class="pagination-area text-center">
                    <a
                        href="javascript:void(0)"
                        (click)="clickLiveClassPageNumber(1)"
                        *ngIf="currentLiveClassPageNumber > 2 && totalLiveClassPageCount > 1"
                        class="page-numbers"
                        >1</a
                    >
                    <span
                        class="page-numbers"
                        *ngIf="currentLiveClassPageNumber > 2 && totalLiveClassPageCount > 1"
                        aria-current="page"
                        >...</span
                    >
                    <a
                        href="javascript:void(0)"
                        (click)="clickLiveClassPageNumber(currentLiveClassPageNumber - 1)"
                        *ngIf="currentLiveClassPageNumber > 1 && totalLiveClassPageCount > 1"
                        class="page-numbers"
                        >{{ currentLiveClassPageNumber - 1 }}</a
                    >
                    <span
                        class="page-numbers current"
                        *ngIf="totalLiveClassPageCount != 0"
                        aria-current="page"
                        >{{ currentLiveClassPageNumber }}</span
                    >
                    <a
                        href="javascript:void(0)"
                        (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 1)"
                        *ngIf="
                            totalLiveClassPageCount > 1 &&
                            currentLiveClassPageNumber + 1 <= totalLiveClassPageCount
                        " class="page-numbers">{{ currentLiveClassPageNumber + 1 }}</a>
                <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 2)" *ngIf="
                            totalLiveClassPageCount > 1 &&
                            currentLiveClassPageNumber + 2 <= totalLiveClassPageCount
                        " class="page-numbers">{{ currentLiveClassPageNumber + 2 }}</a>
                <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 3)" *ngIf="
                            totalLiveClassPageCount > 1 &&
                            currentLiveClassPageNumber + 3 <= totalLiveClassPageCount
                        " class="page-numbers">{{ currentLiveClassPageNumber + 3 }}</a>
                <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 4)" *ngIf="
                            totalLiveClassPageCount > 1 &&
                            currentLiveClassPageNumber + 4 <= totalLiveClassPageCount
                        " class="page-numbers">{{ currentLiveClassPageNumber + 4 }}</a>
                <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 1)" *ngIf="
                            totalLiveClassPageCount > 1 &&
                            currentLiveClassPageNumber + 1 <= totalLiveClassPageCount
                        " class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
            </div>
        </div>        
    </div>
    <!--<div style="color: #aba3a3" *ngIf="classList.length == 0">
        No Live Classes Available.
    </div>-->
</div>

<div *ngIf="isTeacher == true" class="container mt-3">
    <!-- <h3 style="color: var(--mainColor); cursor: pointer" class="col-lg-12">
        Live Classes
    </h3> --><br />
    <div class="flexButton">
        <h6 style="
                color: #000000;
                cursor: pointer;            
                border-bottom: 1px solid #f7f0f0 !important;"
            >
            Live Classes
            <!-- <span class="more-text" *ngIf="classList">more</span> -->
        </h6>
        <div class="btn-group ml-3">
            <a href="javascript:void(0)" (click)="liveClassFilter('today')"
        class="d-inline-block"
        style= "margin-bottom: 8px;
        text-decoration-line: underline;
        margin-right: 10px; color: blue;font-size:13px;">Today</a>
        <a href="javascript:void(0)" (click)="liveClassFilter('tomorrow')"
        class="d-inline-block"
        style= "margin-bottom: 8px;
        text-decoration-line: underline;
        margin-right: 10px;  color: blue;font-size:13px;">Tomorrow</a>
        <a href="javascript:void(0)" (click)="liveClassFilter('week')"
        class="d-inline-block"
        style= "margin-bottom: 8px;
        text-decoration-line: underline;
        margin-right: 10px; color: blue;font-size:13px;">This Week</a>
        <a href="javascript:void(0)" (click)="liveClassFilter('month')"
        class="d-inline-block"
        style= "margin-bottom: 8px;
        text-decoration-line: underline;
        margin-right: 10px; color: blue;font-size:13px;">This Month</a>
    </div>
    </div>
    <div class="container-fluid row">
        <div class="orders-table table-responsive" >


            <table class="table">
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Class Name</th>
                        <th>Topic Name</th>
                        <th>Date</th>
                        <th>Student Name</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor='let class of classList; let i = index'>
                        <td>{{i + 1}}</td>
                        <td>{{class.name}}</td>
                        <td *ngIf = "class.personalClassID">{{class.personalClassID.topicID.topicName}}</td>
                        <td>{{class.startDateTime}}</td>
                        <td>{{class.studentID.firstName}} {{class.studentID.lastName}}</td>
                        <td>
                            <div class="Action"><a href="javascript:void(0)"
                                *ngIf="class.isAccepted == false"
                                [ngClass]="class.isAccepted == true ? 'classAccepted' : 'classPending'"
                                (click)="liveClassFunction(class, 'accept')"> <i class="fa fa-check-square-o" data-toggle="tooltip" data-placement="bottom" title="Accept"></i></a><br *ngIf="class.isAccepted == false">
                            <a href="javascript:void(0)"
                                *ngIf="class.isAccepted == false"
                                [ngClass]="class.isAccepted == true ? 'classAccepted' : 'classPending'"
                                (click)="liveClassFunction(class, 'reject')"> <i class="fa fa-window-close" data-toggle="tooltip" data-placement="bottom" title="Reject"></i></a>
                                <br *ngIf="class.isAccepted == false">
                            <a href="javascript:void(0)"
                                *ngIf="class.isAccepted == true"
                                [ngClass]="class.isAccepted == true ? 'classAccepted' : 'classPending'"
                                (click)="liveClassFunction(class, 'start')"> <i class="fab fa-google-play" data-toggle="tooltip" data-placement="bottom" title="Start"></i></a>
                                <br *ngIf="class.isAccepted == true">
                            <a href="javascript:void(0)"
                                [ngClass]="class.isAccepted == true ? 'classAccepted' : 'classPending'"
                                (click)="liveClassFunction(class, 'reschedule')"> <i class="far fa-calendar-alt" data-toggle="tooltip" data-placement="bottom" title="Reschedule"></i></a><br>
                            <a href="javascript:void(0)"
                                [ngClass]="class.isAccepted == true ? 'classAccepted' : 'classPending'"
                                (click)="liveClassFunction(class, 'cancel')"><i class="fas fa-ban" data-toggle="tooltip" data-placement="bottom" title="Cancel"></i></a><br>
                            <a href="javascript:void(0)"
                                [ngClass]="class.isAccepted == true ? 'classAccepted' : 'classPending'"
                                (click)="liveClassFunction(class, 'share')"> <i class="fas fa-share-alt-square" data-toggle="tooltip" data-placement="bottom" title="Share"></i></a>
                            </div> 
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="pagination-area text-center">
                <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(1)"
                    *ngIf="currentLiveClassPageNumber > 2 && totalLiveClassPageCount > 1" class="page-numbers">1</a>
                <span class="page-numbers" *ngIf="currentLiveClassPageNumber > 2 && totalLiveClassPageCount > 1"
                    aria-current="page">...</span>
                <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber - 1)"
                    *ngIf="currentLiveClassPageNumber > 1 && totalLiveClassPageCount > 1" class="page-numbers">{{
                    currentLiveClassPageNumber - 1 }}</a>
                <span class="page-numbers current" *ngIf="totalLiveClassPageCount != 0" aria-current="page">{{
                    currentLiveClassPageNumber }}</span>
                <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 1)" *ngIf="
                            totalLiveClassPageCount > 1 &&
                            currentLiveClassPageNumber + 1 <= totalLiveClassPageCount
                        " class="page-numbers">{{ currentLiveClassPageNumber + 1 }}</a>
                <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 2)" *ngIf="
                            totalLiveClassPageCount > 1 &&
                            currentLiveClassPageNumber + 2 <= totalLiveClassPageCount
                        " class="page-numbers">{{ currentLiveClassPageNumber + 2 }}</a>
                <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 3)" *ngIf="
                            totalLiveClassPageCount > 1 &&
                            currentLiveClassPageNumber + 3 <= totalLiveClassPageCount
                        " class="page-numbers">{{ currentLiveClassPageNumber + 3 }}</a>
                <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 4)" *ngIf="
                            totalLiveClassPageCount > 1 &&
                            currentLiveClassPageNumber + 4 <= totalLiveClassPageCount
                        " class="page-numbers">{{ currentLiveClassPageNumber + 4 }}</a>
                <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 1)" *ngIf="
                            totalLiveClassPageCount > 1 &&
                            currentLiveClassPageNumber + 1 <= totalLiveClassPageCount
                        " class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
            </div>
        </div>
        <!--<div style="color: #aba3a3" class="col-lg-12"  *ngIf="classList.length == 0">
            No Live Classes Available.
        </div>-->
    </div>
</div>
<div *ngIf="isStudent == true && isTeacher == false" class="container mt-3">
    <h6 style="
        color: #000000;
        cursor: pointer;            
        border-bottom: 1px solid #f7f0f0 !important;"
    >
buddi Tests
</h6>
<div class="container-fluid row">
    <div class="orders-table table-responsive">
    <table class="table">
        <thead>
            <tr>
                <th>S.No</th>
                <th>Test Name</th>
                <th>Topic Name</th>
                <th>Total Marks</th>
                <th>Tutor Name</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor='let test of testList; let i = index'>
                <td>{{i + 1}}</td>
                <td>{{test.testName}}</td>
                <td>{{test.topicID.topicName}}</td>
                <td>{{test.totalMarks}}</td>
                <td>{{test.teacherID.firstName}} {{test.teacherID.lastName}}</td>
                <td>
                <div class="Action"><a href="javascript:void(0)" (click)="testFunction(test, 'start')"><i class="fab fa-google-play" data-toggle="tooltip" data-placement="bottom" title="Start"></i></a><br>
                <a href="javascript:void(0)" (click)="testFunction(test, 'share')"><i class="fas fa-share-alt-square" data-toggle="tooltip" data-placement="bottom" title="Share"></i></a></div></td>
            </tr>
        </tbody>
    </table>
</div>
    <!--<div
        style="color: #aba3a3"
        class="col-lg-12"
        *ngIf="testList.length == 0"
    >
        No Tests Available.
    </div>-->
</div>
</div>

<div *ngIf="isTeacher == true" class="container mt-3">
<h6
    style="
        color: #000000;
        cursor: pointer;
        border-bottom: 1px solid #f7f0f0 !important;
    "
>
buddi Tests
</h6>
<div class="container-fluid row">
    <div class="orders-table table-responsive">
    <table class="table">
        <thead>
            <tr>
                <th>S.No</th>
                <th>Test Name</th>
                <th>Topic Name</th>
                <th>Date</th>
                <th>Learner Name</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor='let test of testList; let i = index'>
                <td>{{i + 1}}</td>
                <td>{{test.testID.testName}}</td>
                <td>{{test.testID.topicID.topicName}}</td>
                <td>{{test.dateTaken}}</td>
                <td>{{test.studentID.firstName}} {{test.studentID.lastName}}</td>
                <td><div class="Action"><a href="javascript:void(0)" (click)="testFunction(test, 'start')">Start Correction</a><br>
                <a href="javascript:void(0)" (click)="testFunction(test, 'share')"><i class="fas fa-share-alt-square" data-toggle="tooltip" data-placement="bottom" title="Share"></i></a></div></td>
            </tr>
        </tbody>
    </table>
</div>
    <!--<div
        style="color: #aba3a3"
        *ngIf="testList.length == 0"
    >
        No Tests Available.
    </div>-->
</div>
</div>
<div *ngIf="isTeacher">
    <div class="container mt-3">
        <div class="flexButton">
            <h6 style="
                    color: #000000;
                    cursor: pointer;            
                    border-bottom: 1px solid #f7f0f0 !important;"
                >
                Study Hall Classes
                <!-- <span class="more-text" *ngIf="classList">more</span> -->
            </h6>
            <div class="btn-group ml-3">
                <a href="javascript:void(0)" (click)="studyClassFilter('today')"
                class="d-inline-block"
                style= "margin-bottom: 8px;
                text-decoration-line: underline;
                margin-right: 10px; color: blue;font-size:13px;">Today</a>
                <a href="javascript:void(0)" (click)="studyClassFilter('tomorrow')"
                class="d-inline-block"
                style= "margin-bottom: 8px;
                text-decoration-line: underline;
                margin-right: 10px;  color: blue;font-size:13px;">Tomorrow</a>
                <a href="javascript:void(0)" (click)="studyClassFilter('week')"
                class="d-inline-block"
                style= "margin-bottom: 8px;
                text-decoration-line: underline;
                margin-right: 10px; color: blue;font-size:13px;">This Week</a>
                <a href="javascript:void(0)" (click)="studyClassFilter('month')"
                class="d-inline-block"
                style= "margin-bottom: 8px;
                text-decoration-line: underline;
                margin-right: 10px; color: blue;font-size:13px;">This Month</a>

            <!-- <button (click)="studyClassFilter('today')" class="mr-1">Today</button>
            <button (click)="studyClassFilter('tomorrow')" class="mr-1">Tomorrow</button>
            <button (click)="studyClassFilter('week')" class="mr-1">This Week</button>
            <button (click)="studyClassFilter('month')" class="mr-1">This Month</button> -->
        </div>
        </div>
        <div class="orders-table table-responsive">
        
            <table class="table">
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Class Name</th>
                        <!-- <th>Teacher Name</th> -->
                        <th>Topic Name</th>
                        <th>Enrolled Learners</th>
                        <th>Date & Time</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor='let class of classroomList; let i = index' >
                        <td>{{i + 1}}</td>

                        <td> <a routerLink="javascript:void(0)" (click)="StudyhallClassDetails(class)"
                                class="d-inline-block"
                                style=" text-decoration-line: underline;">{{class.name}}</a></td>
                        <!-- <td *ngIf="class.teacherID">{{class.teacherID.firstName}} {{class.teacherID.lastName}}</td> -->
                        <td>
                            <div *ngIf="class.personalClassID.topicID != undefined">{{class.personalClassID.topicID.topicName}}
                            </div>
                        </td>
                        <td></td>

                        <td *ngIf="class">
                            {{class.startDateTime}}
                        </td>
                        <td>
                            <div class="Action">
                                <div>
                                    <a class="Action" href="javascript:void(0)"
                                        (click)="studyClassFunction(class,'start')">
                                        <i class="fab fa-google-play" data-toggle="tooltip" data-placement="bottom" title="Start"></i>
                                    </a>
                                </div>
                                <div>
                                    {{class.isCompleted ? 'Completed' : class.isRejected ? 'Rejected by Teacher' :
                                    'Cancelled'}}
                                </div>
                                <div>
                                    <a class="Action" href="javascript:void(0)"
                                        (click)="studyClassFunction(class,'share')">
                                        <i class="fas fa-share-alt-square" data-toggle="tooltip" data-placement="bottom" title="Share"></i>
                                    </a>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!--<div style="color: #aba3a3" *ngIf="classroomList.length == 0">
            No Studyhall Classes Available.
        </div>-->
    </div>

    <div class="container mt-3">
        <div class="flexButton">
            <h6 style="
                    color: #000000;
                    cursor: pointer;            
                    border-bottom: 1px solid #f7f0f0 !important;"
                >
                Round Table Classes
                <!-- <span class="more-text" *ngIf="classList">more</span> -->
            </h6>
            <div class="btn-group ml-3">
            
                <a href="javascript:void(0)" (click)="roundClassFilter('today')"
                class="d-inline-block"
                style= "margin-bottom: 8px;
                text-decoration-line: underline;
                margin-right: 10px; color: blue;font-size:13px;">Today</a>
                <a href="javascript:void(0)" (click)="roundClassFilter('tomorrow')"
                class="d-inline-block"
                style= "margin-bottom: 8px;
                text-decoration-line: underline;
                margin-right: 10px;  color: blue;font-size:13px;">Tomorrow</a>
                <a href="javascript:void(0)" (click)="roundClassFilter('week')"
                class="d-inline-block"
                style= "margin-bottom: 8px;
                text-decoration-line: underline;
                margin-right: 10px; color: blue;font-size:13px;">This Week</a>
                <a href="javascript:void(0)" (click)="roundClassFilter('month')"
                class="d-inline-block"
                style= "margin-bottom: 8px;
                text-decoration-line: underline;
                margin-right: 10px; color: blue;font-size:13px;">This Month</a>
        </div>
        </div>
        <div class="orders-table table-responsive">

            <table class="table">
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Class Name</th>
                        <!-- <th>Student Name</th> -->
                        <th>Topic Name </th>
                        <th>Enrolled learners</th>
                        <th>Date & Time</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor='let class of RoundList; let i = index'>
                        <td>{{i + 1}}</td>

                        <td>
                            <a routerLink="javascript:void(0)" (click)="RoundtableClassDetails(class)"
                                class="d-inline-block"
                                style="height:60px; text-decoration-line: underline;">{{class.name}}</a>
                        </td>

                        <td>
                            <div *ngIf="class">{{class.personalClassID.topicID.topicName}}
                            </div>
                        </td>
                        <td></td>
                        <td *ngIf="class">
                            {{class.startDateTime}}
                        </td>
                        <td>    
                            <div class="Action">
                                <div>
                                    <a class="Action" href="javascript:void(0)"
                                        (click)="roundClassFunction(class,'start')">
                                <i class="fab fa-google-play" data-toggle="tooltip" data-placement="bottom" title="Start"></i>
                                    </a>
                                </div>
                                <div>
                                    {{class.isCompleted ? 'Completed' : class.isRejected ? 'Rejected by Teacher' :
                                    'Cancelled'}}
                                </div>
                                <div>
                                    <a class="Action" href="javascript:void(0)"
                                        (click)="roundClassFunction(class,'share')">
                                        <i class="fas fa-share-alt-square" data-toggle="tooltip" data-placement="bottom" title="Share"></i>
                                    </a>
                                </div>
                            </div>


                        </td>

                    </tr>
                </tbody>
            </table>
            </div>
        </div>
    </div>


<div *ngIf="isStudent">


    <div class="container mt-3">
        <!-- <h3 style="color: var(--mainColor); cursor: pointer" class="col-lg-12">
            Studyhall Classes </h3> -->
            <div class="flexButton">
                <h6 style="
                        color: #000000;
                        cursor: pointer;            
                        border-bottom: 1px solid #f7f0f0 !important;"
                    >
                    Study Hall Classes
                    <!-- <span class="more-text" *ngIf="classList">more</span> -->
                </h6>
                <div class="btn-group ml-3">
                    <a href="javascript:void(0)" (click)="studyClassFilter('today')"
                    class="d-inline-block"
                    style= "margin-bottom: 8px;
                    text-decoration-line: underline;
                    margin-right: 10px; color: blue;font-size:13px;">Today</a>
                    <a href="javascript:void(0)" (click)="studyClassFilter('tomorrow')"
                    class="d-inline-block"
                    style= "margin-bottom: 8px;
                    text-decoration-line: underline;
                    margin-right: 10px;  color: blue;font-size:13px;">Tomorrow</a>
                    <a href="javascript:void(0)" (click)="studyClassFilter('week')"
                    class="d-inline-block"
                    style= "margin-bottom: 8px;
                    text-decoration-line: underline;
                    margin-right: 10px; color: blue;font-size:13px;">This Week</a>
                    <a href="javascript:void(0)" (click)="studyClassFilter('month')"
                    class="d-inline-block"
                    style= "margin-bottom: 8px;
                    text-decoration-line: underline;
                    margin-right: 10px; color: blue;font-size:13px;">This Month</a>
            </div>
            </div>

        <div class="orders-table table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Class Name</th>
                        <th>Learner Name</th>
                        <th>Topic Name</th>
                        <th>Date & Time</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor='let class of classroomList; let i = index'>
                        <td>{{i + 1}}</td>

                        <td *ngIf="class.personalClassID">
                            <a routerLink="javascript:void(0)" (click)="StudyhallClassDetails(class)"
                                class="d-inline-block"
                                style=" text-decoration-line: underline;">{{class.personalClassID.className}}</a>
                        </td>
                        <td *ngIf="class.personalClassID">{{class.personalClassID.teacherID.profileName}} </td>
                        <td>
                            <div *ngIf="class.personalClassID">{{class.personalClassID.topicID.topicName}}

                            </div>
                        </td>
                        <td *ngIf="class">
                            {{class.startDateTime}}
                        </td>
                        <td>
                            <!-- <div class="Action">
                            <div>
                                Join
                            </div>
                            <div>
                               Share
                            </div>Classes
                        </div> -->
                            <div class="Action">
                                <div>
                                    <a class="Action" href="javascript:void(0)"
                                        (click)="studyClassFunction(class,'join')">
                                        <i class="fa fa-sign-in" data-toggle="tooltip" data-placement="bottom" title="Join"></i>
                                    </a>
                                </div>

                                <div>
                                    <a class="Action" href="javascript:void(0)"
                                        (click)="studyClassFunction(class,'share')">
                                        <i class="fas fa-share-alt-square" data-toggle="tooltip" data-placement="bottom" title="Share"></i>
                                    </a>
                                </div>
                            </div>
                        </td>



                        <!-- <a routerLink="/single-courses" class="view-button"
                            >View</a
                        > -->
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="container mt-3">
        <div class="flexButton">
            <h6 style="
                    color: #000000;
                    cursor: pointer;            
                    border-bottom: 1px solid #f7f0f0 !important;"
                >
                Round Table Classes
                <!-- <span class="more-text" *ngIf="classList">more</span> -->
            </h6>
            <div class="btn-group ml-3">
                <a href="javascript:void(0)" (click)="roundClassFilter('today')"
                class="d-inline-block"
                style= "margin-bottom: 8px;
                text-decoration-line: underline;
                margin-right: 10px; color: blue;font-size:13px;">Today</a>
                <a href="javascript:void(0)" (click)="roundClassFilter('tomorrow')"
                class="d-inline-block"
                style= "margin-bottom: 8px;
                text-decoration-line: underline;
                margin-right: 10px;  color: blue;font-size:13px;">Tomorrow</a>
                <a href="javascript:void(0)" (click)="roundClassFilter('week')"
                class="d-inline-block"
                style= "margin-bottom: 8px;
                text-decoration-line: underline;
                margin-right: 10px; color: blue;font-size:13px;">This Week</a>
                <a href="javascript:void(0)" (click)="roundClassFilter('month')"
                class="d-inline-block"
                style= "margin-bottom: 8px;
                text-decoration-line: underline;
                margin-right: 10px; color: blue;font-size:13px;">This Month</a>
            <!-- <button (click)="roundClassFilter('today')" class="mr-1">Today</button>
            <button (click)="roundClassFilter('tomorrow')" class="mr-1">Tomorrow</button>
            <button (click)="roundClassFilter('week')" class="mr-1">This Week</button>
            <button (click)="roundClassFilter('month')" class="mr-1">This Month</button> -->
        </div>
        </div>
        <div class="orders-table table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Class Name</th>
                        <!-- <th>Student Name</th> -->
                        <th>Topic Name</th>
                        <th>Date & Time</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor='let class of RoundList; let i = index'>
                        <td>{{i + 1}}</td>

                        <td *ngIf="class.personalClassID">
                            <a routerLink="javascript:void(0)" (click)="RoundtableClassDetails(class)"
                                class="d-inline-block"
                                style=" text-decoration-line: underline;">{{class.personalClassID.className}}</a>
                        </td>
                        <!-- <td *ngIf="class.personalClassID">{{class.personalClassID.teacherID.profileName}}</td> -->
                        <td *ngIf="class.personalClassID">{{class.personalClassID.topicID.topicName}}</td>
                        <td *ngIf="class">{{class.startDateTime}}</td>
                        <td>
                            <!-- <div class="Action">
                                <div>
                                    Join
                                </div>
                                <div>
                                 Share
                                </div>
                            </div> -->
                            <div class="Action">
                                <div>
                                    <a class="Action" href="javascript:void(0)"
                                        (click)="roundClassFunction(class,'join')">
                                        <i class="fa fa-sign-in" data-toggle="tooltip" data-placement="bottom" title="Join"></i>
                                    </a>
                                </div>

                                <div>
                                    <a class="Action" href="javascript:void(0)"
                                        (click)="roundClassFunction(class,'share')">
                                        <i class="fas fa-share-alt-square" data-toggle="tooltip" data-placement="bottom" title="Share"></i>
                                    </a>
                                </div>
                            </div>
                        </td>

                        <!-- <a routerLink="/single-courses" class="view-button"
                            >View</a
                        > -->
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="container mt-3">
    <h6 style="
            color: #000000;
            cursor: pointer;            
            border-bottom: 1px solid #f7f0f0 !important;"
        >
        Video Lessons </h6>
    <div class="orders-table table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th>S.No</th>
                    <th>Video Name</th>
                    <th>Topic Name</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor='let class of videoList; let i = index'>
                    <td>{{i + 1}}</td>
                    <td>
                        <a routerLink="javascript:void(0)" (click)="detailsVideo(class)" class="d-inline-block"
                            style=" text-decoration-line: underline;">{{ class.videoName }}</a>
                    </td>
                    <!-- <td *ngIf="class.teacherID">{{class.teacherID.profileName}} {{class.teacherID.lastName}}</td> -->
                    <td *ngIf="class.topicID">{{class.topicID.topicName}}</td>
                    <td>
                        <div class="Action">
                            <div>
                                <a routerLink="javascript:void(0)" (click)="detailsVideo(class)" class="d-inline-block"
                                    style="text-decoration-line: underline;">
                                    <i class="far fa-play-circle" data-toggle="tooltip" data-placement="bottom" title="Play"></i></a>

                            </div>
                            <div>
                                <a class="Action" href="javascript:void(0)" (click)="videoClassFunc('share')">
                                    <i class="fas fa-share-alt-square" data-toggle="tooltip" data-placement="bottom" title="Share"></i>
                                </a>
                            </div>
                        </div>


                    </td>

                    <!-- <a routerLink="/single-courses" class="view-button"
                        >View</a
                    > -->
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="container mt-3">
    <h6 style="
            color: #000000;
            cursor: pointer;            
            border-bottom: 1px solid #f7f0f0 !important;"
        >
        Smart Material </h6>
    <div class="orders-table table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th>S.No</th>
                    <th>Material Name</th>
                    <th>Topic Name</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor='let class of smartMaterialList; let i = index'>
                    <td>{{i + 1}}</td>
                    <td>
                        <a routerLink="javascript:void(0)" (click)="detailsSmartMaterial(class)" class="d-inline-block"
                            style="text-decoration-line: underline;">{{ class.materialName }}</a>
                    </td>
                    <!-- <td *ngIf="class.teacherID">{{class.teacherID.profileName}} {{class.teacherID.lastName}}</td> -->
                    <td *ngIf="class.topicID">{{class.topicID.topicName}}</td>
                    <td>
                        <div class="Action">
                            <div>
                                <a class="Action"
                                    *ngIf="(isLoggedin =='true' && purchase == true) || (isLoggedin == 'true' && class.teacherID._id == teacherID)"
                                    (click)="addToCourseCompletion()" href="{{class.materialPath}}"><i class="far fa-eye" data-toggle="tooltip" data-placement="bottom" title="View"></i></a>
                            </div>
                            <div>
                                <a class="Action" href="javascript:void(0)" (click)="smartMaterialFunc('share')">
                                    <i class="fas fa-share-alt-square" data-toggle="tooltip" data-placement="bottom" title="Share"></i>
                                </a>
                            </div>
                        </div>


                    </td>

                    <!-- <a routerLink="/single-courses" class="view-button"
                        >View</a
                    > -->
                </tr>
            </tbody>
        </table>
    </div>
</div>