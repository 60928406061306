<div class="row">
    <div class="col-lg-4 col-md-4 col-6">
        <div class="single-funfact">
            <div class="icon">
                <i class="fas fa-user-graduate"></i>
            </div>
            <h3 class="odometer" data-count="1000">00</h3>
            <p>Paying Students</p>
        </div>
    </div>

    <div class="col-lg-4 col-md-4 col-6">
        <div class="single-funfact">
            <div class="icon">
                <i class="fas fa-chalkboard-teacher"></i>
            </div>
            <h3 class="odometer" data-count="100">00</h3>
            <p>Teachers & Growing</p>
        </div>
    </div>

    <div class="col-lg-4 col-md-4 col-6">
        <div class="single-funfact">
            <div class="icon">
                <i class="fas fa-tv"></i>
            </div>
            <h3 class="odometer" data-count="2000">00</h3>
            <p>Videos & Courses</p>
        </div>
    </div>

    <!-- <div class="col-lg-3 col-md-3 col-6">
        <div class="single-funfact">
            <div class="icon">
                <i class="fas fa-dollar-sign"></i>
            </div>
            <h3 class="odometer" data-count="14000">00</h3>
            <p>USD Revenue Last Year</p>
        </div>
    </div> -->
</div>