<div class="row">
    <div class="col-lg-3 col-sm-6 col-md-4">
        <div class="single-courses-category mb-30">
            <a routerLink="/K-12/5f12a2efbf9506355c8adb5c">
                <i class='bx bx-book-reader'></i>
                K-12
            </a>
        </div>
    </div>

    <div class="col-lg-3 col-sm-6 col-md-4">
        <div class="single-courses-category mb-30">
            <a routerLink="/NEET/5f12a351bf9506355c8adb5e">
                <i class='bx bx-first-aid'></i>
                NEET
            </a>
        </div>
    </div>

    <div class="col-lg-3 col-sm-6 col-md-4">
        <div class="single-courses-category mb-30">
            <a routerLink="/JEE/5f12a36ebf9506355c8adb5f">
                <i class='bx bx-shape-triangle'></i>
                JEE
            </a>
        </div>
    </div>

    <div class="col-lg-3 col-sm-6 col-md-4">
        <div class="single-courses-category mb-30">
            <a routerLink="/Coding/5f12a389bf9506355c8adb60">
                <i class='bx bx-font-family'></i>
                Coding
            </a>
        </div>
    </div>

  
</div>