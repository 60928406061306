<app-header-style-one *ngIf="!isTeacher"></app-header-style-one>
<app-header-style-one-teacher *ngIf="isTeacher"></app-header-style-one-teacher>

    <div class="container  mt-3" >
        <div class="page-title-content" >
            <ul>
                <li><a href="/student/home">Home</a></li>
                <li><a href="liveClassList">Live Classes</a></li>
                <li *ngIf="livedetails" >{{livedetails.className}}</li>
            </ul>
            <h4 style="color: #000000;padding-left: 0;padding-bottom:10px;" class="col-lg-12">
					Live Classes
				</h4>
        </div>
    </div>


<section class="courses-details-area pt-50 pb-70" *ngIf="livedetails">
    <div class="container">
        <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-lg-5">
                    <div class="courses-title">
                        <h2>{{livedetails.className}} 
                            <!-- - {{livedetails.ScheduleDate |  date :"MMM dd,yyyy, hh:mm aaa"}} -->
                        </h2>
                       
                    </div>

                    <div class="courses-meta">
                        <ul>
                            <li>
                                <i class='bx bx-folder-open'></i>
                                <span>Category</span>
                                <span *ngIf="livedetails.categoryID">{{livedetails.categoryID.categoryName}} / {{livedetails.moduleID.moduleName}} / {{livedetails.topicID.topicName}}</span>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-7">
                    <div class="courses-price">
                        <button *ngIf="isLoggedin == 'false'" href="javascript:void(0)" (click)="redirectToLoginPage()" class="default-btn space mr-1">
                            <i class='bx bx-paper-plane icon-arrow before size'></i>
                            <span class="label">Login</span>
                            <i class="bx bx-paper-plane icon-arrow after size"></i>
                        </button>
                        <span *ngIf="isLoggedin == 'true'">
                             <button  *ngIf = "isTeacher == true" class="default-btn mr-1 p-2" (click)="viewClassRequests(livedetails._id)"> <i class="fa-solid fa-code-pull-request"></i>
                                View Requests
                            </button>
                            
                            <span *ngIf="livedetails.isTemplate">
                                <button *ngIf = "isStudent == true && !isAlreadyJoined" class="default-btn mr-1 p-2 " (click)="requestLiveClass(livedetails._id)">
                                    Request a Class
                                </button>
                                <button *ngIf="isStudent && isAlreadyJoined"  href="javascript:void(0)" (click)="redirectToRequest(livedetails.personalClassID)" class="default-btn mr-1">
                                    
                                    <i class='bx bx-check icon-arrow before'></i>
                                    <span class="label">Already Joined</span>
                                    <i class="bx bx-check icon-arrow after"></i>
                                </button>
                            </span>
                            <span *ngIf="!livedetails.isTemplate">
                                <button *ngIf="isStudent && !isAlreadyJoined" (click)="joinLiveClass()"  href="javascript:void(0)" class="default-btn mr-1">
                                    
                                    <i class='bx bx-paper-plane icon-arrow before'></i>
                                    <span class="label">Enroll</span>
                                    <i class="bx bx-paper-plane icon-arrow after"></i>
                                </button>
                                <button *ngIf="isStudent && isAlreadyJoined"  href="javascript:void(0)" class="default-btn mr-1">
                                    
                                    <i class='bx bx-check icon-arrow before'></i>
                                    <span class="label">Already Joined</span>
                                    <i class="bx bx-check icon-arrow after"></i>
                                </button>
                            </span>
                        </span>
                        <button  class="default-btn mr-1" style="padding: 7px 20px 7px 20px;" (click)="backto()"><i class="fas fa-long-arrow-alt-left kin"></i>
                            Back
                        </button>
                        <!-- <a routerLink="/single-courses" class="default-btn"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Buy Course</span><i class="default-btn"></i></a> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-8">
                <div class="courses-details-image text-center">
                    <img [src]="livedetails.thumbnail ? livedetails.thumbnail : 'assets/img/courses/courses1.jpg'" alt="image">
                </div>
                <div class="mt-3 mb-3">
                    <strong class="mb-3">
                        Description
                    </strong>
                    <div class="col-lg-12 text-break">
                            {{livedetails.description =='' ? '-' : livedetails.description}}
                    </div>
                </div>
               
            </div>

            <div class="col-lg-4">
                <div class="courses-sidebar-information">
                    <ul>
                        <li>
                            <span><i class='bx bx-time'></i> Class Name:</span>
                            {{livedetails.className}}
                        </li>
                        <li *ngIf="livedetails.classType =='One-on-One'">
                            <span><i class='bx bx-time'></i> Class Type:</span>
                            Live
                        </li>
                        <li *ngIf="!livedetails.isTemplate">
                            <span><i class='bx bx-tachometer'></i> Class Datetime:</span>
                            {{livedetails.startDateTime ? livedetails.startDateTime : '' }}
                        </li>
                        <li *ngIf="livedetails.isTemplate">
                            <span><i class='bx bx-tachometer'></i> Class Template</span>
                            Yes
                        </li>
                        
                        <li>
                            <span><i class='bx bx-tachometer'></i>Duration:</span>
                            {{livedetails.totalClassTime ? livedetails.totalClassTime +' Min' : '-'}}
                        </li>

                        <li>
                            <span><i class='bx bx-support'></i> Frequency:</span>
                            {{livedetails.occurance ? livedetails.occurance : 'Single'}}
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i>Teaching Method:</span>
                            {{livedetails.teachingMethod ? livedetails.teachingMethod: 'Not Available'}}
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i>Category:</span>
                            {{livedetails.categoryID ? livedetails.categoryID.categoryName: ''}}
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i>Module:</span>
                            {{livedetails.moduleID ? livedetails.moduleID.moduleName: ''}}
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i>Topic:</span>
                            {{livedetails.topicID ? livedetails.topicID.topicName: ''}}
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i>Language:</span>
                            {{livedetails.languageID ? livedetails.languageID.languageName: ''}}
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i>Price:</span>
                            {{livedetails.price ? livedetails.price: 'Free'}}
                        </li>
                       <!-- <li>
                            <span><i class='bx bxs-badge-check'></i> Tag:</span>
                            {{livedetails.tag =='' ? '-' : tag}}
                        </li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <img src="assets/img/spinner-default.gif" alt="">
</ngx-spinner>