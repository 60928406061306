import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dataFilter'
})
export class DataFilterPipe implements PipeTransform {

  transform(teacherPayoutDetails: any[], myAccountSearch: string): any[] {
    if (!teacherPayoutDetails) {
      return [];
    }
    if (!myAccountSearch) {
      return teacherPayoutDetails;
    }
  
    myAccountSearch = myAccountSearch.toLocaleLowerCase();
    console.log('account serarch',myAccountSearch);

    return teacherPayoutDetails.filter(it => {
      console.log(it,'checking')
      return it.moduleName.toLocaleLowerCase().includes(myAccountSearch);
    });
  }

}


