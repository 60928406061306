
<br>
<br>
<form [formGroup]="newDashboardForm">
<div *ngIf="data.length!=0">
  <div *ngFor="let item of data;let i = index;">
    <button class="accordion" (click)="toggleAccordian($event, i)" [id]=item.id> {{item.chapterName}} </button>
    <div class="panel" hide="!item.isActive">
      <p *ngFor="let child of item.topic">  <input type="checkbox" (click)="PersonalizedClass($event, child)" id= "PersonalizedClass" formControlName="PersonalizedClass" name="checked" value="">{{child.topicName}} </p>
      <ul class="nav">
        <li class="nav-item pr-2  mt-2">
          <button class="default-btn" (click)="PushDataClass($event)" >
             Live Classes
          </button>
     </li>
        <li class="nav-item pr-2  mt-2">
          <a class="default-btn">
             Video Lessons
         </a>
     </li>
     <li class="nav-item pr-2  mt-2">
          <a class="default-btn">
             Smart Material
         </a>
     </li>
     <li class="nav-item pr-2  mt-2">
          <a class="default-btn">
             buddi Tests
         </a>
     </li>
     <li class="nav-item pr-2  mt-2">
          <a class="default-btn">
             Study Hall
         </a>
     </li>
      </ul>
    </div>
  </div>
</div>
<!--<div *ngIf="data.length==0">
  No Chapter Available
</div>-->
</form>
<!-- <div id="test" *ngFor="let item of dashboardData[0].data;index as i">
  <div class="card">
    <div class="card-header" id="heading-1">
      <div class="mb-0">
        <a role="button" data-toggle="collapse" href="#collapse-1" aria-expanded="true" aria-controls="collapse-1">
          {{item.chapters[0].chapterName}}
        </a>
      </div>
    </div>
    <div id="collapse-1" class="collapse show" data-parent="test" aria-labelledby="heading-1">
      <div class="card-body">
        <div>
          <ul class="nav flex-column">
            <li class="nav-item pr-2  mt-2">
              <input type="checkbox" >Topic 1
            </li>
            <li class="nav-item pr-2  mt-2">
              <input type="checkbox" >Topic 2
            </li>
            <li class="nav-item pr-2  mt-2">
              <input type="checkbox" >Topic 3
            </li>
          </ul>
        </div>
        <div>
          <ul class="nav">
            <li class="nav-item pr-2  mt-2">
              <a class="default-btn">
                 Personalized Classes
             </a>
         </li>
            <li class="nav-item pr-2  mt-2">
              <a class="default-btn">
                 Video Classes
             </a>
         </li>
         <li class="nav-item pr-2  mt-2">
              <a class="default-btn">
                 Study Material
             </a>
         </li>
         <li class="nav-item pr-2  mt-2">
              <a class="default-btn">
                 Test
             </a>
         </li>
         <li class="nav-item pr-2  mt-2">
              <a class="default-btn">
                 Group Classes
             </a>
         </li>
          </ul>
        </div>
         <div id="accordion-1">
          <div class="card">
            <div class="card-header" id="heading-1-1">
              <div class="mb-0">
                <a class="collapsed" role="button" data-toggle="collapse" href="#collapse-1-1" aria-expanded="false" aria-controls="collapse-1-1">
                  Title 1
                </a>
              </div>
            </div>
            <div id="collapse-1-1" class="collapse" data-parent="#accordion-1" aria-labelledby="heading-1-1">
              <div class="card-body">

                <ul class="nav">
                        <li class="nav-item pr-2 mt-2">
                          <input type="checkbox">
                            <a >
                                Personalized Classes
                            </a>
                        </li>
                        <li class="nav-item pr-2  mt-2">
                             <a class="default-btn">
                                Video Classes
                            </a>
                        </li>
                        <li class="nav-item pr-2  mt-2">
                             <a class="default-btn">
                                Study Material
                            </a>
                        </li>
                        <li class="nav-item pr-2  mt-2">
                             <a class="default-btn">
                                Test
                            </a>
                        </li>
                        <li class="nav-item pr-2  mt-2">
                             <a class="default-btn">
                                Group ClassessuccessToastr
                            </a>
                        </li>
                        
                      </ul>

              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="heading-1-2">
              <div class="mb-0">
                <a class="collapsed" role="button" data-toggle="collapse" href="#collapse-1-2" aria-expanded="false" aria-controls="collapse-1-2">
                  Title 2
                </a>
              </div>
            </div>
            <div id="collapse-1-2" class="collapse" data-parent="#accordion-1" aria-labelledby="heading-1-2">
              <div class="card-body">
                 Title 3
              </div>
            </div>
          </div>
        </div>       
      
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="heading-2">
      <div class="mb-0">
        <a class="collapsed" role="button" data-toggle="collapse" href="#collapse-2" aria-expanded="false" aria-controls="collapse-2">
          Chapter 2
        </a>
      </div>
    </div>
    <div id="collapse-2" class="collapse" data-parent="#accordion" aria-labelledby="heading-2">
      <div class="card-body">
        Title 1
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="heading-3">
      <div class="mb-0">
        <a class="collapsed" role="button" data-toggle="collapse" href="#collapse-3" aria-expanded="false" aria-controls="collapse-3">
          Chapter 3
        </a>
      </div>
    </div>
    <div id="collapse-3" class="collapse" data-parent="#accordion" aria-labelledby="heading-3">
      <div class="card-body">
        Text 3
      </div>
    </div>
  </div>
</div> -->