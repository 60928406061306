import { Component, OnInit,Input } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { fromEvent, Observable, Subscription } from 'rxjs';
@Component({
  selector: 'app-nointernet',
  templateUrl: './nointernet.component.html',
  styleUrls: ['./nointernet.component.scss']
})
export class NointernetComponent implements OnInit {
	  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  constructor(private location:Location) { }

  ngOnInit(): void {
  	// this.offlineEvent = fromEvent(window, 'offline');
   //  this.onlineEvent = fromEvent(window, 'online');
   //  this.subscriptions.push(this.onlineEvent.subscribe(e => {
   //      console.log('Online...');
   //      this.location.back()
   //  }))  

   //  this.subscriptions.push(this.offlineEvent.subscribe(e => {
   //          // console.log('Offline...');
   //          // this.isOffline = true;
   //          // this.router.navigate(['/nointernet']);
   //  }))
  	// alert(this.isOffline !=undefined )
  }

}
