import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { title } from 'process';
@Component({
  selector: 'app-teaching',
  templateUrl: './online-teaching-jobs.component.html',
  styleUrls: ['./online-teaching-jobs.component.scss']
})
export class TeachingComponent implements OnInit {
  public isstudent = false;
  public isteacher = false;
  constructor(private meta: Meta) {
    this.meta.addTags([
      { name: 'description', content: 'We offer online teaching jobs for teachers on our platform. This is one of the best online tutoring jobs since you will work part-time from your home! Join now' },
      { name:'title', content: 'Online Teaching Jobs | Work From Home | Part Time | Buddi Life' },
      { name:'keywords',content:'Online Teaching Jobs Work From Home,Online Tuition Jobs From Home,Online Teaching Jobs Part Time,Online Tutoring Jobs For Teachers,Best Online Tutoring Jobs' }
    ]);
  }
  ngOnInit(): void {
    const userType = localStorage.getItem('userType');      
    if(userType.toLowerCase()=="teacher") {
      this.isteacher = true;
    }
    else {
      this.isstudent = true;
    }
  }
  }


