<div *ngIf="!isStudent">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>
<div *ngIf="isStudent">
    <app-header-style-one></app-header-style-one>
</div>
<!-- <div class="container" routerLink="/sidenav"><button style="width: 10%;height:45px;margin-left:89%;margin-top:1%;padding: 9px;"><i class="fas fa-long-arrow-alt-left mr-1"></i>Back</button></div> -->
<div *ngIf="isStudent == true && isTeacher == false" class="container mt-3 mb-3">
    <div class="go">
        <h5 style="color: #000000; ">Upcoming Evalution Tests</h5>
        <div class="btn-group ml-3" style="margin-left:0rem !important">
        <button type="button" (click)="backto()"><i class="fas fa-long-arrow-alt-left mr-1"></i>Back</button>
    </div>
    </div>
    <div class="container-fluid row">
        <div class="orders-table table-responsive mb-3">
            <table class="table">
                <thead>
                    <tr>
                        <th>Sl.No</th>
                        <th>Test Name</th>
                        <th>Topic Name</th>
                        <th>Total Marks</th>
                        <th>Tutor Name</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let test of testList; let i = index">
                        <td>{{ i + 1 }}</td>
                        <td>{{ test.testName }}</td>
                        <td>{{ test.topicID.topicName }}</td>
                        <td>{{ test.totalMarks }}</td>
                        <td>
                            {{ test.teacherID.firstName }}
                            {{ test.teacherID.lastName }}
                        </td>
                        <td class="align">
                            <a
                                href="javascript:void(0)"
                                (click)="testFunction(test, 'start')"
                                ><i class="fab fa-google-play" data-toggle="tooltip" data-placement="bottom" title="Start"></i></a
                            ><br />
                            <a
                                href="javascript:void(0)"
                                (click)="testFunction(test, 'share')"
                                > <i class="fas fa-share-alt-square" data-toggle="tooltip" data-placement="bottom" title="Share"></i></a
                            >
                        </td>
                    </tr>
                </tbody>
            </table>
            <h4
            style="color: #000000; text-align: center"
            class="col-lg-12 mt-3"
            *ngIf="testList.length == 0"
        >
            No Evalution Test Available
        </h4>
            <div class="pagination-area text-center">
                <a
                    href="javascript:void(0)"
                    (click)="clickLiveClassPageNumber(1)"
                    *ngIf="currentLiveClassPageNumber > 2 && totalLiveClassPageCount > 1"
                    class="page-numbers"
                    >1</a
                >
                <span
                    class="page-numbers"
                    *ngIf="currentLiveClassPageNumber > 2 && totalLiveClassPageCount > 1"
                    aria-current="page"
                    >...</span
                >
                <a
                    href="javascript:void(0)"
                    (click)="clickLiveClassPageNumber(currentLiveClassPageNumber - 1)"
                    *ngIf="currentLiveClassPageNumber > 1 && totalLiveClassPageCount > 1"
                    class="page-numbers"
                    >{{ currentLiveClassPageNumber - 1 }}</a
                >
                <span
                    class="page-numbers current"
                    *ngIf="totalLiveClassPageCount != 0"
                    aria-current="page"
                    >{{ currentLiveClassPageNumber }}</span
                >
                <a
                    href="javascript:void(0)"
                    (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 1)"
                    *ngIf="
                        totalLiveClassPageCount > 1 &&
                        currentLiveClassPageNumber + 1 <= totalLiveClassPageCount
                    " class="page-numbers">{{ currentLiveClassPageNumber + 1 }}</a>
            <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 2)" *ngIf="
                        totalLiveClassPageCount > 1 &&
                        currentLiveClassPageNumber + 2 <= totalLiveClassPageCount
                    " class="page-numbers">{{ currentLiveClassPageNumber + 2 }}</a>
            <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 3)" *ngIf="
                        totalLiveClassPageCount > 1 &&
                        currentLiveClassPageNumber + 3 <= totalLiveClassPageCount
                    " class="page-numbers">{{ currentLiveClassPageNumber + 3 }}</a>
            <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 4)" *ngIf="
                        totalLiveClassPageCount > 1 &&
                        currentLiveClassPageNumber + 4 <= totalLiveClassPageCount
                    " class="page-numbers">{{ currentLiveClassPageNumber + 4 }}</a>
            <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 1)" *ngIf="
                        totalLiveClassPageCount > 1 &&
                        currentLiveClassPageNumber + 1 <= totalLiveClassPageCount
                    " class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
        </div>
        </div>
        <!--<div
        style="color: #aba3a3"
        class="col-lg-12"
        *ngIf="testList.length == 0"
    >
        No Tests Available.
    </div>-->
    </div>
</div>

<div *ngIf="isTeacher == true" class="container mt-3">
    <div class="go">
        <h5 style="color: #000000;">Upcoming Evaluation Tests</h5>
        <div class="btn-group ml-3" style="margin-left:0rem !important">
        <button type="button" routerLink="/sidenav"><i class="fas fa-long-arrow-alt-left mr-1"></i>Back</button>
    </div>
    </div>
    <div class="container" style="margin: 0;padding: 0;">
        <div class="orders-table table-responsive mb-3">
            <table class="table">
                <thead>
                    <tr>
                        <th>Sl.No</th>
                        <th>Test Name</th>
                        <th>Topic Name</th>
                        <th>Date & Time</th>
                        <th>Learner Name</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let test of testList; let i = index">
                        <td>{{ i + 1 }}</td>
                        <td>{{ test.testID.testName }}</td>
                        <td>{{ test.testID.topicID.topicName }}</td>
                        <td>{{ test.dateTaken }}</td>
                        <td>
                            {{ test.studentID.firstName }}
                            {{ test.studentID.lastName }}
                        </td>
                        <td class="align">
                            <a
                                href="javascript:void(0)"
                                (click)="testFunction(test, 'start')"
                                ><i class="far fa-check-square" data-toggle="tooltip" data-placement="bottom" title="Start"></i></a
                            ><br />
                            <a
                                href="javascript:void(0)"
                                (click)="testFunction(test, 'share')"
                                ><i class="fas fa-share-alt-square" data-toggle="tooltip" data-placement="bottom" title="Share"></i></a
                            >
                        </td>
                    </tr>
                </tbody>
            </table>
            <h4
            style="color: #000000; text-align: center"
            class="col-lg-12 mt-3"
            *ngIf="testList.length == 0"
        >
        No Evaluation Test Available
        </h4>
            <div class="pagination-area text-center">
                <a
                    href="javascript:void(0)"
                    (click)="clickLiveClassPageNumber(1)"
                    *ngIf="currentLiveClassPageNumber > 2 && totalLiveClassPageCount > 1"
                    class="page-numbers"
                    >1</a
                >
                <span
                    class="page-numbers"
                    *ngIf="currentLiveClassPageNumber > 2 && totalLiveClassPageCount > 1"
                    aria-current="page"
                    >...</span
                >
                <a
                    href="javascript:void(0)"
                    (click)="clickLiveClassPageNumber(currentLiveClassPageNumber - 1)"
                    *ngIf="currentLiveClassPageNumber > 1 && totalLiveClassPageCount > 1"
                    class="page-numbers"
                    >{{ currentLiveClassPageNumber - 1 }}</a
                >
                <span
                    class="page-numbers current"
                    *ngIf="totalLiveClassPageCount != 0"
                    aria-current="page"
                    >{{ currentLiveClassPageNumber }}</span
                >
                <a
                    href="javascript:void(0)"
                    (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 1)"
                    *ngIf="
                        totalLiveClassPageCount > 1 &&
                        currentLiveClassPageNumber + 1 <= totalLiveClassPageCount
                    " class="page-numbers">{{ currentLiveClassPageNumber + 1 }}</a>
            <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 2)" *ngIf="
                        totalLiveClassPageCount > 1 &&
                        currentLiveClassPageNumber + 2 <= totalLiveClassPageCount
                    " class="page-numbers">{{ currentLiveClassPageNumber + 2 }}</a>
            <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 3)" *ngIf="
                        totalLiveClassPageCount > 1 &&
                        currentLiveClassPageNumber + 3 <= totalLiveClassPageCount
                    " class="page-numbers">{{ currentLiveClassPageNumber + 3 }}</a>
            <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 4)" *ngIf="
                        totalLiveClassPageCount > 1 &&
                        currentLiveClassPageNumber + 4 <= totalLiveClassPageCount
                    " class="page-numbers">{{ currentLiveClassPageNumber + 4 }}</a>
            <a href="javascript:void(0)" (click)="clickLiveClassPageNumber(currentLiveClassPageNumber + 1)" *ngIf="
                        totalLiveClassPageCount > 1 &&
                        currentLiveClassPageNumber + 1 <= totalLiveClassPageCount
                    " class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
        </div>
        </div>
        <!--<div
        style="color: #aba3a3"
        *ngIf="testList.length == 0"
    >
        No Tests Available.
    </div>-->
    </div>
</div>
