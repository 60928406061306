
        <div class="container-fluid m-3 row" >
            <div class="col-lg-12  p-3 radius shadow" style="background-color: #FFFFFF; ">
                <div class="row">
                <div  class="col-lg-3 col-md-6 ng-star-inserted shadow">
                    <div  class="single-courses-box without-box-shadow mb-30">
                        <div  class="courses-image">
                            <a  class="d-block">
                                <img  alt="image" style="width: 250px; height: 175px;" src="assets/img/courses/courses1.jpg">
                            </a>
                        </div>
                        <div class="courses-content">
                            <div  class="course-author d-flex align-items-center">
                                <img  alt="image" class="rounded-circle mr-2" src="https://s3.ap-south-1.amazonaws.com/buddi.bucket/amaithiteacher5f4d336fa9b6472ce81cfe84/Photos/5f4d336fa9b6472ce81cfe84-1610969456046.jpeg">
                                    <span  class="ng-star-inserted">amaithiteacher</span>
                            </div>
                            <div  class="course-author d-flex align-items-center">
                                <img  src="assets/img/startdate.png" alt="image" class="rounded-circle mr-2"> 
                                    Oct 12,2020 | Oct 14,2020 
                            </div>
                            <h3>
                                <a  class="d-inline-block">
                                    <span  class="text-break">
                                        group buddi
                                    </span>
                                </a>
                            </h3>
                        </div>
                        <div > K-12, Grade-1, TN-Stateboard </div>
                        <div class="courses-box-footer"> SocialScience, Hindi </div>
                    </div>
                </div>
                <div  class="col-lg-3 col-md-6 ng-star-inserted shadow">
                    <div  class="single-courses-box without-box-shadow mb-30">
                        <div  class="courses-image">
                            <a  class="d-block">
                                <img  alt="image" style="width: 250px; height: 175px;" src="assets/img/courses/courses1.jpg">
                            </a>
                        </div>
                        <div class="courses-content">
                            <div  class="course-author d-flex align-items-center">
                                <img  alt="image" class="rounded-circle mr-2" src="https://s3.ap-south-1.amazonaws.com/buddi.bucket/amaithiteacher5f4d336fa9b6472ce81cfe84/Photos/5f4d336fa9b6472ce81cfe84-1610969456046.jpeg">
                                    <span  class="ng-star-inserted">amaithiteacher</span>
                            </div>
                            <div  class="course-author d-flex align-items-center">
                                <img  src="assets/img/startdate.png" alt="image" class="rounded-circle mr-2"> 
                                    Oct 12,2020 | Oct 14,2020 
                            </div>
                            <h3>
                                <a  class="d-inline-block">
                                    <span  class="text-break">
                                        group buddi
                                    </span>
                                </a>
                            </h3>
                        </div>
                        <div > K-12, Grade-1, TN-Stateboard </div>
                        <div class="courses-box-footer"> SocialScience, Hindi </div>
                    </div>
                </div>
                <div  class="col-lg-3 col-md-6 ng-star-inserted shadow">
                    <div  class="single-courses-box without-box-shadow mb-30">
                        <div  class="courses-image">
                            <a  class="d-block">
                                <img  alt="image" style="width: 250px; height: 175px;" src="assets/img/courses/courses1.jpg">
                            </a>
                        </div>
                        <div class="courses-content">
                            <div  class="course-author d-flex align-items-center">
                                <img  alt="image" class="rounded-circle mr-2" src="https://s3.ap-south-1.amazonaws.com/buddi.bucket/amaithiteacher5f4d336fa9b6472ce81cfe84/Photos/5f4d336fa9b6472ce81cfe84-1610969456046.jpeg">
                                    <span  class="ng-star-inserted">amaithiteacher</span>
                            </div>
                            <div  class="course-author d-flex align-items-center">
                                <img  src="assets/img/startdate.png" alt="image" class="rounded-circle mr-2"> 
                                    Oct 12,2020 | Oct 14,2020 
                            </div>
                            <h3>
                                <a  class="d-inline-block">
                                    <span  class="text-break">
                                        group buddi
                                    </span>
                                </a>
                            </h3>
                        </div>
                        <div > K-12, Grade-1, TN-Stateboard </div>
                        <div class="courses-box-footer"> SocialScience, Hindi </div>
                    </div>
                </div>
                <div  class="col-lg-3 col-md-6 ng-star-inserted shadow">
                    <div  class="single-courses-box without-box-shadow mb-30">
                        <div  class="courses-image">
                            <a  class="d-block">
                                <img  alt="image" style="width: 250px; height: 175px;" src="assets/img/courses/courses1.jpg">
                            </a>
                        </div>
                        <div class="courses-content">
                            <div  class="course-author d-flex align-items-center">
                                <img  alt="image" class="rounded-circle mr-2" src="https://s3.ap-south-1.amazonaws.com/buddi.bucket/amaithiteacher5f4d336fa9b6472ce81cfe84/Photos/5f4d336fa9b6472ce81cfe84-1610969456046.jpeg">
                                    <span  class="ng-star-inserted">amaithiteacher</span>
                            </div>
                            <div  class="course-author d-flex align-items-center">
                                <img  src="assets/img/startdate.png" alt="image" class="rounded-circle mr-2"> 
                                    Oct 12,2020 | Oct 14,2020 
                            </div>
                            <h3>
                                <a  class="d-inline-block">
                                    <span  class="text-break">
                                        group buddi
                                    </span>
                                </a>
                            </h3>
                        </div>
                        <div > K-12, Grade-1, TN-Stateboard </div>
                        <div class="courses-box-footer"> SocialScience, Hindi </div>
                    </div>
                </div>
            </div>
            </div>
        </div>



