<div *ngIf="!isStudent">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>
<div *ngIf="isStudent">
    <app-header-style-one></app-header-style-one>
</div>

<!-- <div class="container" routerLink="/sidenav"><button style="width: 10%;height:45px;margin-left: 89%;margin-top:1%;padding: 9px;"><i class="fas fa-long-arrow-alt-left mr-1"></i>Back</button></div> -->
<div class="container">
    <div class="page-title-content">

        <!-- <div class="row">
            <div class="col-lg-10 m-4">
                <h3 style="color: black;">My Dashboard</h3>
            </div>
        </div> -->
    </div>
</div>
<section class="row my-dashboard-area">
    <div class="col-lg-1">
    </div>
    <div class="col-lg-10">
        <!-- <div class="myDashboard-profile">
            <div class="row"></div>            
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-5">
                    <div class="profile-image">
                        <div class="mt-5">
                            <h3 class="titleProfile ml-5">
                                <img class="imageStyle"  [src]="userprofdata.userPhoto =='' ? 'assets/img/profilePhotoPlaceholder.png':userprofdata.userPhoto"
                                alt="image">  
                            </h3>
                        </div>                                         
                    </div>
                </div>
                <div class="col-lg-5 col-md-7">
                    <div>
                        <h3 class="titleProfile ml-5">
                            <span class=""><span>Profile Details&nbsp;</span></span>
                            <span id="title"></span>
                        </h3>
                    </div>
                    <div class="profile-content">
                        <table>
                            <tr>
                                <th>Profile Name</th>
                                <td> : </td>
                                <td><span>{{userprofdata.profileName}}</span></td>
                            </tr>
                            <tr>
                                <th>firstName</th>
                                <td> : </td>
                                <td><span>{{userprofdata.firstName}}</span></td>
                            </tr>
                            <tr>
                                <th>Last Name</th>
                                <td> : </td>
                                <td><span>{{userprofdata.lastName}}</span></td>
                            </tr>
                            <tr>
                                <th>Gender</th>
                                <td> : </td>
                                <td>
                                    <span>
                                    {{userprofdata.gender ==undefined || userprofdata.gender=="" || userprofdata.gender=="undefined"? '-':userprofdata.gender}}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <th>School Short Name</th>
                                <td> : </td>
                                <td><span>{{userprofdata.schoolName}}</span></td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="col-lg-2 col-md-7">
                    <a  (click)="backtoExplorePage()" style="float: right;" class="default-btn mr-1 font12size p-2">
                        <i class="fas fa-plus icon-arrow" style="font-size: 16px"></i>
                        <span class=""> Explore</span>                   
                    </a>
                </div>
            </div>
        </div> -->
        <!-- <div class="myDashboard-navigation">
            <ul class="nav nav-tabs">
                <li class="m-1" [ngClass]="{ 'active': tabName=='home'}">
                    <a data-toggle="tab" href="#home" [ngClass]="{ 'active': tabName=='home'}" class="p-2">
                        My Profile
                    </a>
                </li>
                <li class="m-1" (click)="accountRestart()" [ngClass]="{ 'active': tabName=='account'}">
                    <a [ngClass]="{ 'active': tabName=='account'}" data-toggle="tab" href="#account" class="p-2">
                        My Account
                    </a>
                </li>
                <li  class="m-1" [ngClass]="{ 'active': tabName=='menu1'}">
                    <a [ngClass]="{ 'active': tabName=='menu1'}" data-toggle="tab" href="#menu1" class="p-2">
                        My Classes
                    </a>
                </li>
                <li class="m-1" [ngClass]="{ 'active': tabName=='menu2'}">
                    <a [ngClass]="{ 'active': tabName=='menu2'}" data-toggle="tab" href="#menu2" class="p-2">
                        My Courses
                    </a>
                </li>
                <li class="m-1">
                    <a data-toggle="tab" href="#teststab" class="p-2">
                        My Tests
                    </a>
                </li>
                <li class="m-1" routerLink="/myCalendar">
                    <a data-toggle="tab" href="#menu3" class="p-2">
                        My Calendar
                    </a>
                </li>
                <li class="m-1" [ngClass]="{ 'active': tabName=='menu4'}">
                    <a data-toggle="tab" href="#menu4" class="p-2" [ngClass]="{ 'active': tabName=='menu4'}">
                        My Library
                    </a>
                </li>
                <li class="m-1" [ngClass]="{ 'active': tabName=='progress'}">
                    <a data-toggle="tab" href="#progress" class="p-2" [ngClass]="{ 'active': tabName=='progress'}">
                        My Progress
                    </a>
                </li>
                <li class="m-1 " [ngClass]="{ 'active': tabName=='studymaterial'}">
                    <a [ngClass]="{ 'active': tabName=='studymaterial'}" data-toggle="tab" href="#studymaterial" class="p-2">
                        Study Material
                    </a>
                </li>
            </ul>
        </div> -->
        <div class="tab-content myDashboard-content">
            <div id="home" [ngClass]="{ 'active': tabName=='home'}" class="tab-pane">
                <div class="row">
                    <div class="col-lg-12 col-md-2">
                        <button class="float-right" (click)="back()"><i class="fas fa-long-arrow-alt-left mr-1"></i>Back</button>
                        <button class="float-right mr-1" routerLink="/student/edit-profile"><i class="bi bi-pencil-square kin"></i>Edit Profile</button>
                    </div>
                    <div class="col-lg-12 col-md-2">
                        <h5>Profile Details (Referral ID: <a id="text" style="color:var(--mainColor);" >{{userprofdata._id}} - <i (click)="copyToClipboard('#text')" style="color:var(--mainColor);cursor: pointer;"  data-toggle="tooltip" data-placement="bottom" title="Copy" class="far fa-copy"></i></a>)</h5>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="tab-content">
                            <div class="tabs-item">
                                <div class="products-details-tab-content">
                                    <ul class="additional-information">
                                        <li><span>First Name:</span>
                                            {{userprofdata.firstName==undefined || userprofdata.firstName=="" || userprofdata.firstName=="undefined"? '-':userprofdata.firstName}}
                                        </li>
                                        <li><span>Last Name:</span>
                                            {{userprofdata.lastName==undefined || userprofdata.lastName=="" || userprofdata.lastName=="undefined"? '-':userprofdata.lastName}}
                                        </li>
                                        <li><span>Gender:</span>
                                            {{userprofdata.gender==undefined || userprofdata.gender==""|| userprofdata.gender=="undefined" ? '-':userprofdata.gender}}
                                        </li>
                                        <li><span>School Name:</span>
                                            {{userprofdata.groupName==undefined || userprofdata.groupName=="" || userprofdata.groupName=="undefined"? '-':userprofdata.groupName}}
                                        </li>
                                        <li *ngIf="isUserLoggedIn=='true'">
                                            <gbutton routerLink="/changepassword" class="has" [textLabel]="'Change Password'"></gbutton>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-2">
                        <h5>Contact Info</h5>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="tab-content">
                            <div class="tabs-item">
                                <div class="products-details-tab-content">
                                    <ul class="additional-information">
                                        <li><span>Email:</span>
                                            {{userprofdata.email==undefined || userprofdata.email==""|| userprofdata.email=="undefined" ? '-':userprofdata.email}}
                                        </li>
                                        <li><span>Mobile:</span>
                                            {{userprofdata.phoneNumber==undefined || userprofdata.phoneNumber==""|| userprofdata.phoneNumber=="undefined" ? '-':userprofdata.phoneNumber}}
                                        </li>
                                        <li><span>Address 1:</span>
                                            {{userprofdata.address1==undefined || userprofdata.address1=="" || userprofdata.address1=="undefined"? '-':userprofdata.address1}}
                                        </li>
                                        <li><span>Address 2:</span>
                                            {{userprofdata.address2==undefined || userprofdata.address2=="" || userprofdata.address2=="undefined"? '-':userprofdata.address2}}
                                        </li>
                                        <li><span>Area:</span>
                                            {{userprofdata.area==undefined || userprofdata.area=="" || userprofdata.area=="undefined"? '-':userprofdata.area}}
                                        </li>
                                        <li><span>City:</span>
                                            {{userprofdata.city==undefined || userprofdata.city=="" || userprofdata.city=="undefined"? '-':userprofdata.city}}
                                        </li>
                                        <li><span>State:</span>
                                            {{userprofdata.state==undefined || userprofdata.state=="" || userprofdata.state=="undefined"? '-':userprofdata.state}}
                                        </li>
                                        <li><span>Country:</span>
                                            {{userprofdata.country==undefined || userprofdata.country=="" || userprofdata.country=="undefined"? '-':userprofdata.country}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-2">
                        <h5>Guardian Info</h5>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="tab-content">
                            <div class="tabs-item">
                                <div class="products-details-tab-content">
                                    <ul class="additional-information">
                                        <li><span>Guardian Name:</span>
                                            {{userprofdata.guardianName==undefined || userprofdata.guardianName==""|| userprofdata.guardianName=="undefined" ? '-':userprofdata.guardianName}}
                                        </li>
                                        <li><span>Guardian Phone:</span>
                                            {{userprofdata.guardianPhone==undefined || userprofdata.guardianPhone=="" || userprofdata.guardianPhone=="undefined"? '-':userprofdata.guardianPhone}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                        <h5>Education Info</h5>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="tab-content">
                            <div class="tabs-item">
                                <div class="products-details-tab-content">
                                    <ul class="additional-information">
                                        <li><span>Category:</span>

                                            <!-- {{userprofdata.categories.categoryName==undefined || userprofdata.categories.categoryName==""|| userprofdata.categories.categoryName==undefined ? '-':userprofdata.categories.categoryName}} -->
                                            {{userprofdata.categories|emptyObject}}
                                            <!-- <ngfor-comma [keyName]="'categoryName'" [forData]="categorynamearray"></ngfor-comma> -->
                                        </li>
                                        <li><span>Grade:</span>
                                           <!--  {{userprofdata.grade.gradeName==undefined || userprofdata.grade.gradeName==""|| userprofdata.grade.gradeName==undefined? '-':userprofdata.grade.gradeName}} -->
                                             {{userprofdata.grade|emptyObject}}
                                             <!-- <ngfor-comma [keyName]="'gradeName'" [forData]="gradenamearray"></ngfor-comma> -->
                                        </li>
                                        <li><span>Board:</span>
                                         <!--    {{userprofdata.board.boardName==undefined || userprofdata.board.boardName=="" || userprofdata.board.boardName==undefined? '-':userprofdata.board.boardName}}
                                          -->
                                          {{userprofdata.board|emptyObject}}
                                          <!-- <ngfor-comma [keyName]="'boardName'" [forData]="boardnamearray"></ngfor-comma> -->
                                        </li>
                                        <li><span>Subject Name:</span>
                                            <!-- {{userprofdata.subjects.subjectName==undefined || userprofdata.subjects.subjectName==""|| userprofdata.subjects.subjectName==undefined ? '-':userprofdata.subjects.subjectName}} -->
                                            {{userprofdata.subjects|emptyObject}}
                                            <!-- <ngfor-comma [keyName]="'subjectName'" [forData]="subjectnamearray"></ngfor-comma> -->
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                </div>
            </div>
            <!-- <div id="menu1" [ngClass]="{ 'active': tabName=='menu1','fade':tabName!='menu1'}" class="tab-pane">
                <isManualPopup></isManualPopup>
                <br>
                <br> -->

                <!-- One-on-one Start -->
                <!-- <h3 style="color: var(--mainColor);">One-on-One Classes</h3>
                <listClass 
                    [typeClass]="'Public'" 
                    *ngIf="requestedDataList.length!=0" 
                    [itemNameClass]="'oneonone'" 
                    [configClass]="configOneonone"
                    [data]="requestedDataList" 
                    [redirectTextClass]="'details/solution/'"
                    (pageNumberClass)="receivePageNumberClass($event)"
                >                    
                </listClass>
                <div *ngIf="requestedDataList.length ==0">
                    No One-on-One Class(es) Available.
                </div> -->
                <!-- One-on-one End -->

                <!-- <br>
                <br>
                <hr>
                <div class="row">
                    <div class="col-lg-10"></div>
                    <div class="col-lg-2">
                        <form [formGroup]="classessForm">
                            <span *ngIf="studentIsSchool">                    
                                <input type="checkbox" (click)="changeMeetingList($event)" formControlName="classType">Only Schools
                            </span>
                        </form>
                    </div>
                </div> -->
                <!-- Group Public Class Start -->                                
                <!-- <h3 style="color: var(--mainColor);">
                    {{j.classType.value? 'School' : 'Public'}} Classes
                </h3>
                <teacherclass 
                    type="j.classType.value ? 'School' : 'Public'"
                    *ngIf="classData.length!=0" 
                    [itemName]="'class'" 
                    [config]="config.classData"
                    [data]="classData" 
                    [redirectText]="'details/solution/'"
                    (pageNumber)="receivePageNumber($event)"
                    >                    
                </teacherclass>
                <div *ngIf="classData.length ==0">
                    No {{j.classType.value? 'School' : 'Public'}} Class(es) Available.
                </div>                 -->
                <!-- Group Public Class End -->
                <!-- <br>
                <br>
                <hr>
                <div>
                    <h3 style="color: var(--mainColor);">buddi Group Classes</h3>
                    <teacherclass 
                        [type]="'buddiclass'" 
                        *ngIf="buddiClass.length!=0" 
                        [itemName]="'buddiclass'" 
                        [config]="config.buddiClass"
                        [data]="buddiClass" 
                        [redirectText]="'details/solution/'"
                        (pageNumber)="receivePageNumber($event)"
                    >                    
                    </teacherclass>
                    <div *ngIf="buddiClass.length ==0">
                        No buddi Group Class(es) Available.
                    </div>
                </div>
            </div> 
            <div id="menu2" [ngClass]="{ 'active': tabName=='menu2','fade':tabName!='menu2'}" class="row tab-pane">
                <div class="row">
                    <div class="col-lg-8"></div>
                    <div class="col-lg-2 mt-2">
                        <form [formGroup]="coursesForm">
                            <span *ngIf="studentIsSchool">                    
                                <input type="checkbox" formControlName="classType">Only Schools
                            </span>
                        </form>
                    </div>
                </div>
                <br>
                <div *ngIf="k.classType.value">
                    <h3 style="color: var(--mainColor);margin-top: 0px!important;">Upcoming courses</h3>
                    <div class="container-fluid row" style="cursor: pointer;border-bottom: 1px solid #d2d0d0">
                        <div class="col-lg-3 col-md-6"
                            *ngFor="let slidef of FuturecourseDatagroup | paginate: { id: 'upcomingID',itemsPerPage: count, currentPage: p }">
                            <div class="single-courses-box without-box-shadow mb-30">
                                <div class="courses-image">
                                    <a (click)="coursedetails(slidef)" class="d-block">
                                        <img
                                            style="width: 250px;height: 175px;"
                                            [src]="slidef.courseImage =='' ? 'assets/img/courses/courses1.jpg':slidef.courseImage"
                                            alt="image">
                                    </a>

                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img [src]="slidef.createdBy!=undefined && slidef.createdBy.userPhoto!='' ? slidef.createdBy.userPhoto :'assets/img/user1.jpg'"
                                            class="rounded-circle mr-2" alt="image">
                                        <span>{{slidef.createdBy ? slidef.createdBy.firstName : null }}</span>
                                    </div>
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image">
                                        <span>{{slidef.startDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</span>
                                    </div>
                                    <h3>
                                        <a (click)="coursedetails(slidef)"
                                            class="d-inline-block">{{slidef.fullName}}
                                        </a>
                                    </h3>

                                </div>
                                <div class="courses-box-footer">
                                    {{slidef.categoryID ? slidef.categoryID.categoryName : 'N/A' }} ,
                                    {{slidef.gradeID ? slidef.gradeID.gradeName : 'N/A' }} ,
                                    {{slidef.boardID ? slidef.boardID.boardName : 'N/A' }}
                                    <br>
                                    {{slidef.subjectID ? slidef.subjectID.subjectName : 'N/A' }} ,
                                    {{slidef.languageID ? slidef.languageID.languageName : 'N/A' }}
                                </div>
                            </div>
                        </div>
                        <div *ngIf="FuturecourseDatagroup?.length < 1">
                            No Upcoming Course(s) Available.
                        </div>
                        <div class="col-lg-12 col-md-12" *ngIf="FuturecourseDatagroup?.length > 0">
                            <div style="float: right;" *ngIf="FuturecourseDatagroup?.length > 0">
                                <pagination-controls (pageChange)="p = $event" id="upcomingID"></pagination-controls>
                            </div>
                        </div>                      
                    </div>
                    <h3 style="color: var(--mainColor);">Past courses</h3>
                    <div class="container-fluid row" style="cursor: pointer;border-bottom: 1px solid #d2d0d0">
                        <div class="col-lg-3 col-md-6"
                            *ngFor="let slide of PastcourseDatagroup | paginate: { id: 'pastID',itemsPerPage: count1, currentPage: p1 }">
                            <div class="single-courses-box without-box-shadow mb-30">
                                <div class="courses-image">
                                    <a (click)="coursedetails(slide)" class="d-block">
                                        <img
                                            style="width: 250px;height: 175px;"
                                            [src]="slide.courseImage =='' ? 'assets/img/courses/courses1.jpg':slide.courseImage"
                                            alt="image">
                                    </a>
                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img [src]="slide.createdBy!=undefined && slide.createdBy.userPhoto!='' ? slide.createdBy.userPhoto :'assets/img/user1.jpg'"
                                            class="rounded-circle mr-2" alt="image">
                                        <span>{{slide.createdBy ? slide.createdBy.firstName : null }}</span>
                                    </div>
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image">
                                        <span>{{slide.startDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</span>
                                    </div>
                                    <h3><a (click)="coursedetails(slide)" class="d-inline-block">{{slide.fullName}}</a>
                                    </h3>

                                </div>
                                <div class="courses-box-footer">
                                    {{slide.categoryID ? slide.categoryID.categoryName : 'N/A' }} ,
                                    {{slide.gradeID ? slide.gradeID.gradeName : 'N/A' }} ,
                                    {{slide.boardID ? slide.boardID.boardName : 'N/A' }}
                                    <br>
                                    {{slide.subjectID ? slide.subjectID.subjectName : 'N/A' }} ,
                                    {{slide.languageID ? slide.languageID.languageName : 'N/A' }}
                                </div>

                            </div>
                        </div>
                        <div *ngIf="PastcourseDatagroup?.length < 1">
                            No Past Course(s) Available.
                        </div>
                        <div class="col-lg-12 col-md-12" *ngIf="PastcourseDatagroup?.length > 0">
                            <div style="float: right;" *ngIf="PastcourseDatagroup?.length > 0">
                                <pagination-controls (pageChange)="p1 = $event" id="pastID"></pagination-controls>
                            </div>
                        </div>                      
                    </div>                  
                </div>
                <div *ngIf="!k.classType.value">
                    <h3 style="color: var(--mainColor);margin-top: 0px!important;">Upcoming courses</h3>
                    <div class="container-fluid row" style="cursor: pointer;border-bottom: 1px solid #d2d0d0">
                        <div class="col-lg-3 col-md-6"
                            *ngFor="let slidef of FuturecourseData | paginate: { id: 'coursegroup1',itemsPerPage: count3, currentPage: p3 }">
                            <div class="single-courses-box without-box-shadow mb-30">
                                <div class="courses-image">
                                    <a (click)="coursedetails(slidef)" class="d-block">
                                        <img
                                            style="width: 250px;height: 175px;"
                                            [src]="slidef.courseImage =='' ? 'assets/img/courses/courses1.jpg':slidef.courseImage"
                                            alt="image">
                                    </a>
                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img [src]="slidef.createdBy!=undefined && slidef.createdBy.userPhoto!='' ? slidef.createdBy.userPhoto :'assets/img/user1.jpg'"
                                            class="rounded-circle mr-2" alt="image">
                                        <span>{{slidef.createdBy ? slidef.createdBy.firstName : null }}</span>
                                    </div>
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image">
                                        <span>{{slidef.startDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</span>
                                    </div>
                                    <h3>
                                        <a (click)="coursedetails(slidef)"
                                            class="d-inline-block">{{slidef.fullName}}
                                        </a>
                                    </h3>

                                </div>
                                <div class="courses-box-footer">
                                    {{slidef.categoryID ? slidef.categoryID.categoryName : 'N/A' }} ,
                                    {{slidef.gradeID ? slidef.gradeID.gradeName : 'N/A' }} ,
                                    {{slidef.boardID ? slidef.boardID.boardName : 'N/A' }}
                                    <br>
                                    {{slidef.subjectID ? slidef.subjectID.subjectName : 'N/A' }} ,
                                    {{slidef.languageID ? slidef.languageID.languageName : 'N/A' }}
                                </div>
                            </div>
                        </div>
                        <div *ngIf="FuturecourseData?.length < 1">
                            No Upcoming Course(s) Available.
                        </div>
                        <div class="col-lg-12 col-md-12" *ngIf="FuturecourseData?.length > 0">
                            <div style="float: right;" *ngIf="FuturecourseData?.length > 0">
                                <pagination-controls (pageChange)="p3 = $event" id="coursegroup1"></pagination-controls>
                            </div>
                        </div>

                    </div>



                    <h3 style="color: var(--mainColor);">Past courses</h3>
                    <div class="container-fluid row" style="cursor: pointer;border-bottom: 1px solid #d2d0d0">
                        <div class="col-lg-3 col-md-6"
                            *ngFor="let slide of PastcourseData | paginate: { id: 'coursegroup2',itemsPerPage: count2, currentPage: p2 }">
                            <div class="single-courses-box without-box-shadow mb-30">
                                <div class="courses-image">
                                    <a (click)="coursedetails(slide)" class="d-block"><img
                                            style="width: 250px;height: 175px;"
                                            [src]="slide.courseImage =='' ? 'assets/img/courses/courses1.jpg':slide.courseImage"
                                            alt="image"></a>

                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img [src]="slide.createdBy!=undefined && slide.createdBy.userPhoto!='' ? slide.createdBy.userPhoto :'assets/img/user1.jpg'"
                                            class="rounded-circle mr-2" alt="image">
                                        <span>{{slide.createdBy ? slide.createdBy.firstName : null }}</span>
                                    </div>
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image">
                                        <span>{{slide.startDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</span>
                                    </div>
                                    <h3><a (click)="coursedetails(slide)" class="d-inline-block">{{slide.fullName}}</a>
                                    </h3>

                                </div>
                                <div class="courses-box-footer">
                                    {{slide.categoryID ? slide.categoryID.categoryName : 'N/A' }} ,
                                    {{slide.gradeID ? slide.gradeID.gradeName : 'N/A' }} ,
                                    {{slide.boardID ? slide.boardID.boardName : 'N/A' }}
                                    <br>
                                    {{slide.subjectID ? slide.subjectID.subjectName : 'N/A' }} ,
                                    {{slide.languageID ? slide.languageID.languageName : 'N/A' }}
                                </div>

                            </div>
                        </div>
                        <div *ngIf="PastcourseData?.length < 1">
                            No Past Course(s) Available.
                        </div>
                        <div class="col-lg-12 col-md-12" *ngIf="PastcourseData?.length > 0">
                            <div style="float: right;" *ngIf="PastcourseData?.length > 0">
                                <pagination-controls (pageChange)="p2 = $event" id="coursegroup2"></pagination-controls>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <div id="menu4" [ngClass]="{ 'active': tabName=='menu4','fade':tabName!='menu4'}" class="tab-pane">
                <div class="row">
                    <div class="col-lg-9"></div>
                    <div class="col-lg-3">
                        <form [formGroup]="LibraryForm">
                            <span *ngIf="studentIsSchool">                    
                                <input type="checkbox" (click)="publicOrGroup($event)" formControlName="securityType">Only Schools
                            </span>
                        </form>
                    </div>
                </div>    
                <h3 style="color: var(--mainColor);">Audio Classes</h3>
                <a routerLink="/student-audio-create" class="default-btn mb-2 ml-2 float-right p-2">Add Audio</a>
                <div class="container-fluid row p-0" style="cursor: pointer" *ngIf="audioMy.length !=0">
                    <div class="col-lg-3 col-md-6" *ngFor="let slide of audioMy | paginate: { id: 'lib4',itemsPerPage: countv4, currentPage: v4 }">
                        <div class="single-courses-box without-box-shadow mb-30">
                            <div class="courses-image">
                                <img style="width: 250px;height: 175px;" (click)="audiodetails(slide)" [src]="slide.thumbname ? slide.thumbname : 'assets/img/videocat-thumb-1.jpg'" alt=""  width="100%">
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center"
                                    *ngIf="slide.studentID !=undefined">
                                    <img [src]="slide.studentID!=undefined && slide.studentID.userPhoto!='' ? slide.studentID.userPhoto  :'assets/img/user1.jpg'"
                                        class="rounded-circle mr-2" alt="image">
                                    <span *ngIf="slide.studentID!=undefined">{{slide.studentID.profileName}}
                                    </span>
                                </div>
                                <div class="course-author d-flex align-items-center"
                                    *ngIf="slide.studentID ==undefined">
                                    <img [src]="slide.teacherID!=undefined && slide.teacherID.userPhoto!='' ? slide.teacherID.userPhoto  :'assets/img/user1.jpg'"
                                        class="rounded-circle mr-2" alt="image">
                                    <span *ngIf="slide.teacherID!=undefined">{{slide.teacherID.profileName}}
                                    </span>
                                </div>
                                <h3>
                                    <a (click)="audiodetails(slide)"
                                        class="d-inline-block"><span class="text-break">{{slide.flimname}}</span>
                                    </a>
                                </h3>
                            </div>
                            <div class="courses-box-footer">
                               hjkljkljk,
                                {{slide.gradeID ? slide.gradeID[0].gradeName : '' }},
                                {{slide.boardID ? slide.boardID.boardName : '' }}
                            </div>
                            <div class="courses-box-footer">
                                {{slide.subjectID ? slide.subjectID[0].subjectName : '' }},
                                {{slide.languageID ? slide.languageID.languageName : '' }}
                            </div>
                            <div *ngIf="slide.isPartner" class="courses-box-footer">
                                Provided By : {{slide.isbuddi_premium? 'buddi Premium' : slide.partner.partnerName}}
                            </div>
                        </div>
                    </div>
                </div>
                <div style="float: right;" *ngIf="audioMy.length!=0">
                    <pagination-controls (pageChange)="v4 = $event" id="lib4"></pagination-controls>
                </div>
                 <librarylist 
                    type="g.classType.value ? 'School' : 'Public'"
                    *ngIf="data.song!=undefined && data.song.length!=0" 
                    [config]="config.song"
                    [data]="data.song" 
                    (pageNumber)="receivePageNumber($event)"
                    >                    
                </librarylist>
                <div *ngIf="data.song==undefined || data.song.length==0">
                        No Audio(s) Available.
                </div>
                
                <br>
                <hr>
                 Audio End -->

                <!-- <h3 style="color: var(--mainColor);">Video Classes</h3>
                <a routerLink="/student-video-create" class="default-btn mb-2 float-right p-2">Add Video</a>
                <div class="container-fluid row p-0"  style="cursor: pointer" *ngIf="videoMy.length !=0">
                    <div class="col-lg-3 col-md-6"
                        *ngFor="let slide of videoMy | paginate: { id: 'lib7',itemsPerPage: countv7, currentPage: v7 }">
                        <div class="single-courses-box without-box-shadow mb-30">
                            <div class="courses-image">
                                <img style="width:100%" (click)="videodetails(slide)"
                                    style="width: 250px;height: 175px;"
                                    [src]="slide.thumbname ? slide.thumbname : 'assets/img/videocat-thumb-1.jpg'" alt=""
                                    width="100%">
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center"
                                    *ngIf="slide.studentID !=undefined">
                                    <img [src]="slide.studentID!=undefined && slide.studentID.userPhoto!='' ? slide.studentID.userPhoto  :'assets/img/user1.jpg'"
                                        class="rounded-circle mr-2" alt="image">
                                    <span *ngIf="slide.studentID!=undefined">{{slide.studentID.profileName}}
                                    </span>
                                </div>
                                <div class="course-author d-flex align-items-center"
                                    *ngIf="slide.studentID ==undefined">
                                    <img [src]="slide.teacherID!=undefined && slide.teacherID.userPhoto!='' ? slide.teacherID.userPhoto  :'assets/img/user1.jpg'"
                                        class="rounded-circle mr-2" alt="image">
                                    <span *ngIf="slide.teacherID!=undefined">{{slide.teacherID.profileName}}
                                    </span>
                                </div>
                                <h3>
                                    <a (click)="videodetails(slide)"
                                        class="d-inline-block">
                                        <span class="text-break">{{slide.flimname}}
                                        </span>
                                    </a>
                                </h3>
                            </div>
                            <div>
                                Type: {{slide.securitytype=='Group' ?'School' : slide.securitytype}}
                            </div>
                            <div class="courses-box-footer">
                                {{slide.categoryID ? slide.categoryID[0].categoryName : '' }},
                                {{slide.gradeID ? slide.gradeID[0].gradeName : '' }},
                                {{slide.boardID ? slide.boardID.boardName : '' }}
                            </div>
                            <div class="courses-box-footer">
                                {{slide.subjectID ? slide.subjectID[0].subjectName : '' }},
                                {{slide.languageID ? slide.languageID.languageName : '' }}
                            </div>
                            <div *ngIf="slide.isPartner" class="courses-box-footer">
                                Provided By : {{slide.partner ==null? slide.providerByName ==''? 'buddi Premium' :  slide.providerByName : slide.partner.partnerName}}
                            </div>                             
                        </div>
                    </div>
                </div>
                <div style="float: right;" *ngIf="videoMy.length !=0 ">
                    <pagination-controls (pageChange)="v7 = $event" id="lib7"></pagination-controls>
                </div>
                <librarylist 
                    type="g.classType.value ? 'School' : 'Public'"
                    *ngIf="data.video!=undefined && data.video.length!=0" 
                    [config]="config.video"
                    [data]="data.video" 
                    (pageNumber)="receivePageNumber($event)"
                    >                    
                </librarylist>
                <div *ngIf="data.video==undefined || data.video.length==0">
                        No Video(s) Available.
                </div> -->


          <!--       <div>
                    <div class="container-fluid row p-0" style="cursor: pointer"
                        *ngIf="videoGroup.length !=0 && g.securityType.value">
                        <div class="col-lg-3 col-md-6"
                            *ngFor="let slide of videoGroup | paginate: { id: 'lib8',itemsPerPage: countv8, currentPage: v8 }">
                            <div class="single-courses-box without-box-shadow mb-30">
                                <div class="courses-image">

                                    <img (click)="videodetails(slide['videoId'])" style="width: 250px;height: 175px;"
                                        [src]="slide['videoId'].thumbname ? slide['videoId'].thumbname : 'assets/img/videocat-thumb-1.jpg'"
                                        alt="" width="100%">
                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img [src]="slide['videoId'].teacherID!=undefined && slide['videoId'].teacherID.userPhoto!='' ?  slide['videoId'].teacherID.userPhoto :'assets/img/user1.jpg'"
                                            class="rounded-circle mr-2" alt="image">
                                        <span
                                            *ngIf="slide['videoId'].teacherID!=undefined">{{slide['videoId'].teacherID.profileName}}
                                        </span>
                                    </div>
                                    <h3>
                                        <a (click)="videodetails(slide['videoId'])"
                                            class="d-inline-block">
                                            <span class="text-break">{{slide['videoId'].flimname}}</span>
                                        </a>
                                    </h3>
                                </div>
                                <div>
                                    Type:
                                    {{slide['videoId'].securitytype=='Group' ?'School' : slide['videoId'].securitytype}}
                                </div>
                                <div class="courses-box-footer">
                                    {{slide['videoId'].categoryID ? slide['videoId'].categoryID.categoryName : '' }},
                                    {{slide['videoId'].gradeID ? slide['videoId'].gradeID.gradeName : '' }},
                                    {{slide['videoId'].boardID ? slide['videoId'].boardID.boardName : '' }}
                                </div>
                                <div class="courses-box-footer">
                                    {{slide['videoId'].subjectID ? slide['videoId'].subjectID.subjectName : '' }},
                                    {{slide['videoId'].languageID ? slide['videoId'].languageID.languageName : '' }}
                                </div>
                                <div *ngIf="slide['videoId'].isPartner" class="courses-box-footer">
                                    Provided By :{{slide['videoId'].partner ==null? slide['videoId'].providerByName ==''? 'buddi Premium' :  slide['videoId'].providerByName : slide['videoId'].partner.partnerName}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="videoGroup.length ==0 && g.securityType.value">
                        No Video(s) Available.
                    </div>
                    <div style="float: right;" *ngIf="videoGroup.length !=0 && g.securityType.value">
                        <pagination-controls (pageChange)="v8 = $event" id="lib8"></pagination-controls>
                    </div>
                </div> -->
               <!--  <div>
                    <div class="container-fluid row p-0" style="cursor: pointer"
                        *ngIf="videoPublic.length !=0 && !g.securityType.value">
                        <div class="col-lg-3 col-md-6"
                            *ngFor="let slide of videoPublic | paginate: { id: 'lib9',itemsPerPage: countv9, currentPage: v9 }">
                            <div class="single-courses-box without-box-shadow mb-30">
                                <div class="courses-image">
                                    <img *ngIf="!slide['meetingID']" (click)="videodetails(slide['videoId'])"
                                        style="width: 250px;height: 175px;"
                                        [src]="slide['videoId'].thumbname ? slide['videoId'].thumbname : 'assets/img/videocat-thumb-1.jpg'"
                                        alt="" width="100%">
                                    <img *ngIf="slide['meetingID'] && !slide.instructor" style="width:100%" (click)="detailsMeeting(slide['meetingID'],'Livesession')"
                                    style="width: 250px;height: 175px;"
                                    [src]="slide['meetingID'].image ? slide['meetingID'].image : 'assets/img/courses/courses1.jpg'" alt=""
                                    width="100%">
                                    <img *ngIf="slide.instructor" style="width:100%" (click)="buddiGroupRedirect(slide)"
                                    style="width: 250px;height: 175px;"
                                    [src]="slide['meetingID'].image ? slide['meetingID'].image : 'assets/img/courses/courses1.jpg'" alt=""
                                    width="100%">
                                </div>
                                <div class="courses-content">
                                    <div *ngIf="!slide['meetingID']" class="course-author d-flex align-items-center">
                                        <img [src]="slide['videoId'].teacherID!=undefined && slide['videoId'].teacherID.userPhoto!='' ? slide['videoId'].teacherID.userPhoto :'assets/img/user1.jpg'"
                                            class="rounded-circle mr-2" alt="image">
                                        <span
                                            *ngIf="slide['videoId'].teacherID!=undefined">{{slide['videoId'].teacherID.profileName}}
                                        </span>                                 
                                    </div>
                                    <div *ngIf="slide['meetingID']" class="course-author d-flex align-items-center">
                                        <div *ngIf="slide['meetingID'].teacherID">
                                            <img [src]="slide['meetingID'].teacherID!=undefined && slide['meetingID'].teacherID.userPhoto!='' ? slide['meetingID'].teacherID.userPhoto :'assets/img/user1.jpg'"
                                            class="rounded-circle mr-2" alt="image">
                                            <span
                                            *ngIf="slide['meetingID'].teacherID!=undefined">{{slide['meetingID'].teacherID.profileName}}
                                            </span>
                                            <img src="assets/img/recordingicon.png" *ngIf="slide['meetingID']" (click)="fetchrecordvideo(slide['meetingID']._id,'meetingId')" style="margin-left: 5%;background-color: red;border-radius: 5px;color: white;padding: 1%;font-weight: 600;">
                                        </div>                                        
                                        <div>
                                            <img [src]="slide.instructor!=undefined && slide.instructor.userPhoto!='' ? slide.instructor.userPhoto :'assets/img/user1.jpg'"
                                            class="rounded-circle mr-2" alt="image">
                                            <span
                                            *ngIf="slide.instructor!=undefined">    {{slide.instructor.profileName}}
                                            </span>
                                            <img src="assets/img/recordingicon.png" *ngIf="slide.instructor" (click)="fetchrecordvideo(slide._id,'meetingId')" style="margin-left: 5%;background-color: red;border-radius: 5px;color: white;padding: 1%;font-weight: 600;">
                                        </div>  
                                    </div>
                                    <h3>
                                        <a *ngIf="!slide['meetingID']" (click)="videodetails(slide['videoId'])"
                                            class="d-inline-block">
                                            <span class="text-break">{{slide['videoId'].flimname}}</span></a>
                                        <a *ngIf="slide['meetingID']" (click)="videodetails(slide['meetingID'])"
                                            class="d-inline-block">
                                            <span class="text-break">{{slide['meetingID'].fullName}}</span></a>
                                        <a *ngIf="slide.instructor" (click)="buddiGroupRedirect(slide)"
                                            class="d-inline-block">
                                            <span class="text-break">{{slide.name}}</span></a>
                                    </h3>
                                </div>
                                <div *ngIf="!slide['meetingID']">
                                    Type:
                                    {{slide['videoId'].securitytype=='Group' ?'School' : slide['videoId'].securitytype}}
                                </div>
                                <div *ngIf="!slide.instructor && slide['meetingID']">
                                    Type:
                                    {{slide['meetingID'].meetingType=='Group' ?'School' : slide['meetingID'].meetingType}}
                                </div>
                                <div *ngIf="slide.instructor">
                                    Type: {{slide.meetingType=='Group' ?'School' : slide.meetingType}}
                                </div>
                                <div *ngIf="!slide['meetingID']" class="courses-box-footer">
                                    {{slide['videoId'].categoryID ? slide['videoId'].categoryID.categoryName : '' }},
                                    {{slide['videoId'].gradeID ? slide['videoId'].gradeID.gradeName : '' }},
                                    {{slide['videoId'].boardID ? slide['videoId'].boardID.boardName : '' }}
                                </div>
                                <div *ngIf="!slide['meetingID']" class="courses-box-footer">
                                    {{slide['videoId'].subjectID ? slide['videoId'].subjectID.subjectName : '' }},
                                    {{slide['videoId'].languageID ? slide['videoId'].languageID.languageName : '' }}
                                </div>
                                <div *ngIf="!slide['meetingID'] && slide['videoId'].isPartner"
                                    class="courses-box-footer">
                                    Provided By :
                                   {{slide['videoId'].partner ==null? slide['videoId'].providerByName ==''? 'buddi Premium' :  slide['videoId'].providerByName : slide['videoId'].partner.partnerName}}
                                </div>

                                <div *ngIf="slide['meetingID'] && !slide.instructor" class="courses-box-footer">
                                    {{slide['meetingID'].categoryID ? slide['meetingID'].categoryID.categoryName : '' }},
                                    {{slide['meetingID'].gradeID ? slide['meetingID'].gradeID.gradeName : '' }},
                                    {{slide['meetingID'].boardID ? slide['meetingID'].boardID.boardName : '' }}
                                </div>
                                <div *ngIf="slide['meetingID'] && !slide.instructor" class="courses-box-footer">
                                    {{slide['meetingID'].subjectID ? slide['meetingID'].subjectID.subjectName : '' }},
                                    {{slide['meetingID'].languageID ? slide['meetingID'].languageID.languageName : '' }}
                                </div>
                                <div *ngIf="slide['meetingID'] && !slide.instructor" class="courses-box-footer">
                                    <div *ngIf="slide['meetingID'].isPartner">
                                        Provided By :
                                        {{slide['meetingID'].partner ==null? slide['meetingID'].providerByName ==''? 'buddi Premium' :  slide['meetingID'].providerByName : slide['meetingID'].partner.partnerName}}

                                       
                                    </div>
                                </div>
                                <div *ngIf="slide.instructor" class="courses-box-footer">
                                    {{slide.categoryID ? slide.categoryID.categoryName : '' }},
                                    {{slide.gradeID ? slide.gradeID.gradeName : '' }},
                                    {{slide.boardID ? slide.boardID.boardName : '' }}
                                </div>
                                <div *ngIf="slide.instructor" class="courses-box-footer">
                                    {{slide.subjectID ? slide.subjectID.subjectName : '' }},
                                    {{slide.languageID ? slide.languageID.languageName : '' }}
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div *ngIf="videoPublic.length ==0 && !g.securityType.value ">
                        No Video(s) Available.
                    </div>
                    <div style="float: right;" *ngIf="videoPublic.length !=0 && !g.securityType.value">
                        <pagination-controls (pageChange)="v9 = $event" id="lib9"></pagination-controls>
                    </div>
                </div> -->
                <!-- <br>
                <hr>
                <h3 style="color: var(--mainColor);" *ngIf="!g.securityType.value">Curated Content Videos</h3>
                <librarylist 
                    type="'Public'"
                    *ngIf="data.videoyoutube!=undefined && !g.securityType.value" 
                    [config]="config.videoyoutube"
                    [data]="data.videoyoutube" 
                    (pageNumber)="receivePageNumber($event)"
                    >                    
                </librarylist>
                <div *ngIf="(data.videoyoutube==undefined && !g.securityType.value) || (data.videoyoutube.length==0 && !g.securityType.value)">
                        No Video(s) Available.
                </div> -->
              <!--      <div class="container-fluid row p-0" style="cursor: pointer"
                        *ngIf="videoYoutubePublic.length !=0 && !g.securityType.value">
                        <div class="col-lg-3 col-md-6"
                            *ngFor="let slide of videoYoutubePublic | paginate: { id: 'lib88',itemsPerPage: countv88, currentPage: v88 }">
                            <div class="single-courses-box without-box-shadow mb-30">
                                <div class="courses-image">

                                    <img (click)="videodetails(slide['videoId'])" style="width: 250px;height: 175px;"
                                        [src]="slide['videoId'].thumbname ? slide['videoId'].thumbname : 'assets/img/videocat-thumb-1.jpg'"
                                        alt="" width="100%">
                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img [src]="slide['videoId'].teacherID!=undefined && slide['videoId'].teacherID.userPhoto!='' ?  slide['videoId'].teacherID.userPhoto :'assets/img/user1.jpg'"
                                            class="rounded-circle mr-2" alt="image">
                                        <span
                                            *ngIf="slide['videoId'].teacherID!=undefined">{{slide['videoId'].teacherID.profileName}}
                                        </span>
                                    </div>
                                    <h3>
                                        <a (click)="videodetails(slide['videoId'])"
                                            class="d-inline-block">
                                            <span class="text-break">{{slide['videoId'].flimname}}</span>
                                        </a>
                                    </h3>
                                </div>
                                <div>
                                    Type:
                                    {{slide['videoId'].securitytype=='Group' ?'School' : slide['videoId'].securitytype}}
                                </div>
                                <div class="courses-box-footer">
                                    {{slide['videoId'].categoryID ? slide['videoId'].categoryID.categoryName : '' }},
                                    {{slide['videoId'].gradeID ? slide['videoId'].gradeID.gradeName : '' }},
                                    {{slide['videoId'].boardID ? slide['videoId'].boardID.boardName : '' }}
                                </div>
                                <div class="courses-box-footer">
                                    {{slide['videoId'].subjectID ? slide['videoId'].subjectID.subjectName : '' }},
                                    {{slide['videoId'].languageID ? slide['videoId'].languageID.languageName : '' }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="videoYoutubePublic.length ==0 && !g.securityType.value">
                        No Video(s) Available.
                    </div>
                    <div style="float: right;" *ngIf="videoYoutubePublic.length !=0 && !g.securityType.value">
                        <pagination-controls (pageChange)="v88 = $event" id="lib88"></pagination-controls>
                    </div> 
            </div> -->
            <!-- <div id="teststab" class="tab-pane fade">
                <div style="margin-top: 2%;">
                    <h4 style="color: var(--mainColor);">Tests Details</h4>
                    <div class="container-fluid row" style="cursor: pointer;border-bottom: 1px solid #d2d0d0">
                        <div class="col-lg-3 col-md-6"
                            *ngFor="let slidete of testdata | paginate: { id: 'paginationte',itemsPerPage: countte, currentPage: te }">
                            <div class="single-courses-box without-box-shadow mb-30">
                                <div class="courses-image">
                                    <a class="d-block" (click)="testdetails(slidete._id)" >
                                        <img src='assets/img/courses/courses1.jpg'
                                        style="width: 250px;height: 175px;" alt="image">
                                    </a>                                  
                                </div>
                                <div class="courses-content"> 
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image">
                                        <span>
                                            {{slidete.dateTaken |  date :"MMM dd,yyyy, hh:mm aaa"}}
                                        </span>
                                    </div>
                                    <h3>
                                        <a
                                            class="d-inline-block">{{slidete.testID ? slidete.testID.testName : 'Not Available'}}</a>
                                    </h3>
                                </div>
                                <div class="courses-box-footer">
                                    {{slidete.category ? slidete.category.categoryName : 'N/A' }} , {{slidete.grade ? slidete.grade.gradeName : 'N/A' }} , {{slidete.board ? slidete.board.boardName : 'N/A' }} , {{slidete.subjectString}}
                                </div>
                            </div>
                        </div>
                        <div *ngIf="testdata?.length < 1">
                            No Test(s) Available.
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div style="float: right;">
                                <pagination-controls (pageChange)="te = $event" id="paginationte"></pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="margin-top: 2%;">
                    <h4 style="color: var(--mainColor);">Reviewed Tests</h4>
                    <div class="container-fluid row" style="cursor: pointer;border-bottom: 1px solid #d2d0d0">
                        <div class="col-lg-3 col-md-6"
                            *ngFor="let slidepre of reviewtestdata | paginate: { id: 'paginationrte',itemsPerPage: countrte, currentPage: rte }">
                            <div class="single-courses-box without-box-shadow mb-30">
                                <div class="courses-image">
                                    <a class="d-block" (click)="testdetails(slidepre._id)" >
                                        <img src='assets/img/courses/courses1.jpg'
                                        style="width: 250px;height: 175px;" alt="image">
                                    </a>                                  
                                </div>
                                <div class="courses-content"> 
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image">
                                        <span>
                                            {{slidepre.dateTaken |  date :"MMM dd,yyyy, hh:mm aaa"}}
                                        </span>
                                    </div>
                                    <h3>
                                        <a
                                            class="d-inline-block">{{slidepre.testID ? slidepre.testID.testName : 'Not Available'}}
                                        </a>
                                    </h3>
                                </div>
                                <div class="courses-box-footer">
                                    {{slidepre.category ? slidepre.category.categoryName : 'N/A' }} ,
                                    {{slidepre.grade ? slidepre.grade.gradeName : 'N/A' }} ,
                                    {{slidepre.board ? slidepre.board.boardName : 'N/A' }} , {{slidepre.subjectString}}

                                </div>
                            </div>
                        </div>
                        <div *ngIf="reviewtestdata?.length < 1">
                            No Test(s) Available.
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div style="float: right;">
                                <pagination-controls (pageChange)="rte = $event" id="paginationrte">
                                </pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="margin-top: 2%;">
                    <h4 style="color: var(--mainColor);">Pending Tests</h4>
                    <div class="container-fluid row" style="cursor: pointer;border-bottom: 1px solid #d2d0d0">
                        <div class="col-lg-3 col-md-6"
                            *ngFor="let slidepte of pendingtestdata | paginate: { id: 'paginationpte',itemsPerPage: countpte, currentPage: pte }">
                            <div class="single-courses-box without-box-shadow mb-30">
                                <div class="courses-image">
                                    <a class="d-block" (click)="pendingtestdetails(slidepte.testID._id)" >
                                        <img src='assets/img/courses/courses1.jpg'
                                        style="width: 250px;height: 175px;" alt="image">
                                    </a>                                  
                                </div>
                                <div class="courses-content">  
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image">
                                        <span>
                                            {{slidepte.dateTaken |  date :"MMM dd,yyyy, hh:mm aaa"}}
                                        </span>
                                    </div>
                                    <h3>
                                        <a
                                            class="d-inline-block">{{slidepte.testID ? slidepte.testID.testName : 'Not Available'}}
                                        </a>
                                    </h3>
                                </div>
                                <div class="courses-box-footer">
                                    {{slidepte.category ? slidepte.category.categoryName : 'N/A' }} , {{slidepte.grade ? slidepte.grade.gradeName : 'N/A' }} , {{slidepte.board ? slidepte.board.boardName : 'N/A' }} , {{slidepte.subjectString}}
                                </div>
                            </div>
                        </div>
                        <div *ngIf="pendingtestdata?.length < 1">
                            No Test(s) Available.
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div style="float: right;">
                                <pagination-controls (pageChange)="pte = $event" id="paginationpte">
                                </pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- <div id="menu5" class="tab-pane fade">
                <div class="row">
                    <div class="col-lg-8">

                    </div>
                    <div class="col-lg-2 mt-2">
                        <form [formGroup]="classessForm">
                            
                            <span *ngIf="studentIsSchool">                    
                                <input type="checkbox" formControlName="classTypeExam">Only Schools
                            </span>                            
                        </form>
                    </div>
                </div>
                <div *ngIf="j.classTypeExam.value">
                    <h3 style="color: var(--mainColor);margin-top: 0px!important;">Upcoming Exams</h3>
                    <div class="container-fluid row "   style="cursor: pointer;border-bottom: 1px solid #d2d0d0">
                        <div class="col-lg-3 col-md-6"
                            *ngFor="let slidefe of FuturecourseExamgroup | paginate: { id: 'pagination3',itemsPerPage: counte, currentPage: e }">
                            <div class="single-courses-box without-box-shadow mb-30">
                                <div class="courses-image">
                                    <a class="d-block" (click)="Examdetails(slidefe)"><img
                                            style="width: 250px;height: 175px;"
                                            [src]="slidefe.examImage =='' ? 'assets/img/courses/courses1.jpg':slidefe.examImage"
                                            alt="image"></a>
                                    <!-- <div class="courses-tag">
                                    <a  class="d-block">{{slidef.categoryID ? slidef.categoryID.categoryName : null }} ({{slidef.gradeID ? slidef.gradeID.gradeName : null }})</a>
                                </div> -->
                                <!-- </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img [src]="slidefe.createdBy!=undefined && slidefe.createdBy!='' ? slidefe.createdBy.userPhoto :'assets/img/user1.jpg'"
                                            class="rounded-circle mr-2" alt="image">
                                        <span>{{slidefe.createdBy ? slidefe.createdBy.firstName : null }}</span>
                                    </div>
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image">
                                        <span>{{slidefe.startDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</span>
                                    </div>
                                    <h3><a (click)="Examdetails(slidefe)"
                                            class="d-inline-block">{{slidefe.fullName}}</a></h3>

                                </div>

                                <div class="courses-box-footer">
                                    {{slidefe.categoryID ? slidefe.categoryID.categoryName : null }} ,
                                    {{slidefe.gradeID ? slidefe.gradeID.gradeName : null }} ,
                                    {{slidefe.boardID ? slidefe.boardID.boardName : null }}
                                    <br>
                                    {{slidefe.subjectID ? slidefe.subjectID.subjectName : null }} ,
                                    {{slidefe.languageID ? slidefe.languageID.languageName : null }}
                                </div>                                
                            </div>
                        </div>
                        <div *ngIf="FuturecourseExamgroup?.length < 1">
                            No Upcoming Exam(s) Available.
                        </div>
                        <div class="col-lg-12 col-md-12" *ngIf="FuturecourseExamgroup?.length > 0">
                            <div style="float: right;">
                                <pagination-controls (pageChange)="e = $event" id="pagination3"></pagination-controls>
                            </div>
                        </div>
                    </div>         
                    <h3 style="color: var(--mainColor);">Past Exams</h3>
                    <div class="container-fluid row"  style="cursor: pointer;border-bottom: 1px solid #d2d0d0">
                        <div class="col-lg-3 col-md-6"
                            *ngFor="let slidepe of PastcourseExamgroup | paginate: { id: 'pagination4',itemsPerPage: counte1, currentPage: e1 }">
                            <div class="single-courses-box without-box-shadow mb-30">
                                <div class="courses-image">
                                    <a class="d-block" (click)="Examdetails(slidepe)">
                                        <img
                                            style="width: 250px;height: 175px;"
                                            [src]="slidepe.examImage =='' ? 'assets/img/courses/courses1.jpg':slidepe.examImage"
                                            alt="image">
                                    </a>
                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img [src]="slidepe.createdBy!=undefined && slidepe.createdBy!='' ? slidepe.createdBy.userPhoto :'assets/img/user1.jpg'" class="rounded-circle mr-2" alt="image">
                                        <span>
                                            {{slidepe.createdBy ? slidepe.createdBy.firstName : null }}
                                        </span>
                                    </div>
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image">
                                        <span>
                                            {{slidepe.startDate |  date :"MMM dd,yyyy, hh:mm aaa"}}
                                        </span>
                                    </div>
                                    <h3>
                                        <a (click)="Examdetails(slidepe)"
                                            class="d-inline-block">
                                            {{slidepe.fullName}}
                                        </a>
                                    </h3>
                                </div>
                                <div class="courses-box-footer">
                                    {{slidepe.categoryID ? slidepe.categoryID.categoryName : null }} ,
                                    {{slidepe.gradeID ? slidepe.gradeID.gradeName : null }} ,
                                    {{slidepe.boardID ? slidepe.boardID.boardName : null }}
                                    <br>
                                    {{slidepe.subjectID ? slidepe.subjectID.subjectName : null }} ,
                                    {{slidepe.languageID ? slidepe.languageID.languageName : null }}
                                </div>
                            </div>
                        </div>
                        <div *ngIf="PastcourseExamgroup?.length < 1">
                            No Past Exam(s) Available.
                        </div>
                        <div class="col-lg-12 col-md-12" *ngIf="PastcourseExamgroup?.length > 0">
                            <div style="float: right;">
                                <pagination-controls (pageChange)="e1 = $event" id="pagination4"></pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>


                <div *ngIf="!j.classTypeExam.value">
                    <h3 style="color: var(--mainColor);margin-top: 0px!important;">Upcoming Exams</h3>
                    <div class="container-fluid row" style="cursor: pointer;border-bottom: 1px solid #d2d0d0">

                        <div class="col-lg-3 col-md-6"
                            *ngFor="let slidefe of FuturecourseExam | paginate: { id: 'groupexam1',itemsPerPage: counte2, currentPage: e2 }">
                            <div class="single-courses-box without-box-shadow mb-30">
                                <div class="courses-image">
                                    <a class="d-block" (click)="Examdetails(slidefe)">
                                        <img
                                            style="width: 250px;height: 175px;"
                                            [src]="slidefe.examImage =='' ? 'assets/img/courses/courses1.jpg':slidefe.examImage"
                                            alt="image">
                                    </a>
                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img [src]="slidefe.createdBy!=undefined && slidefe.createdBy!='' ? slidefe.createdBy.userPhoto :'assets/img/user1.jpg'" class="rounded-circle mr-2" alt="image">
                                        <span>
                                            {{slidefe.createdBy ? slidefe.createdBy.firstName : null }}
                                        </span>
                                    </div>
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image">
                                        <span>
                                            {{slidefe.startDate |  date :"MMM dd,yyyy, hh:mm aaa"}}
                                        </span>
                                    </div>
                                    <h3>
                                        <a (click)="Examdetails(slidefe)"
                                            class="d-inline-block">{{slidefe.fullName}}
                                        </a>
                                    </h3>
                                </div>
                                <div class="courses-box-footer">
                                    {{slidefe.categoryID ? slidefe.categoryID.categoryName : null }} ,
                                    {{slidefe.gradeID ? slidefe.gradeID.gradeName : null }} ,
                                    {{slidefe.boardID ? slidefe.boardID.boardName : null }}
                                    <br>
                                    {{slidefe.subjectID ? slidefe.subjectID.subjectName : null }} ,
                                    {{slidefe.languageID ? slidefe.languageID.languageName : null }}
                                </div>
                            </div>
                        </div>

                        <div *ngIf="FuturecourseExam?.length < 1">
                            No Upcoming Exam(s) Available.
                        </div>
                        <div class="col-lg-12 col-md-12" *ngIf="FuturecourseExam?.length > 0">
                            <div style="float: right;">
                                <pagination-controls (pageChange)="e2 = $event" id="groupexam1"></pagination-controls>
                            </div>
                        </div>
                    </div>                  

                    <h3 style="color: var(--mainColor);">Past Exams</h3>
                    <div class="container-fluid row"  style="cursor: pointer;border-bottom: 1px solid #d2d0d0">
                        <div class="col-lg-3 col-md-6"
                            *ngFor="let slidepe of PastcourseExam | paginate: { id: 'groupexam2',itemsPerPage: counte3, currentPage: e3 }">
                            <div class="single-courses-box without-box-shadow mb-30">
                                <div class="courses-image">
                                    <a class="d-block" (click)="Examdetails(slidepe)">
                                        <img
                                            style="width: 250px;height: 175px;"
                                            [src]="slidepe.examImage =='' ? 'assets/img/courses/courses1.jpg':slidepe.examImage"
                                            alt="image">
                                    </a>
                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img [src]="slidepe.createdBy!=undefined && slidepe.createdBy!='' ? slidepe.createdBy.userPhoto :'assets/img/user1.jpg'" class="rounded-circle mr-2" alt="image">
                                        <span>
                                            {{slidepe.createdBy ? slidepe.createdBy.firstName : null }}
                                        </span>
                                    </div>
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image">
                                        <span>
                                            {{slidepe.startDate |  date :"MMM dd,yyyy, hh:mm aaa"}}
                                        </span>
                                    </div>
                                    <h3>
                                        <a (click)="Examdetails(slidepe)"
                                            class="d-inline-block">{{slidepe.fullName}}
                                        </a>
                                    </h3>
                                </div>
                                <div class="courses-box-footer">
                                    {{slidepe.categoryID ? slidepe.categoryID.categoryName : null }} ,
                                    {{slidepe.gradeID ? slidepe.gradeID.gradeName : null }} ,
                                    {{slidepe.boardID ? slidepe.boardID.boardName : null }}
                                    <br>
                                    {{slidepe.subjectID ? slidepe.subjectID.subjectName : null }} ,
                                    {{slidepe.languageID ? slidepe.languageID.languageName : null }}
                                </div>
                            </div>
                        </div>
                        <div *ngIf="PastcourseExam?.length < 1">
                            No Past Exam(s) Available.
                        </div>
                        <div class="col-lg-12 col-md-12" *ngIf="PastcourseExam?.length > 0">
                            <div style="float: right;">
                                <pagination-controls (pageChange)="e3 = $event" id="groupexam2"></pagination-controls>
                            </div>
                        </div>
                    </div>

                </div>

            </div> 
            <div id="menu8" class="tab-pane fade">
                <form [formGroup]="registerForm" class="">
                    <div class="container">
                        <div class="myDashboard-profile">
                            <div class="row align-items-center">
                                <div class="col-lg-8 col-md-7">

                                    <div class="row borderpro">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <div class="custom-file">
                                                        <input type="hidden" formControlName="fileupload" name=""
                                                            value="">
                                                        <input type="file" class="custom-file-input"
                                                            (change)="fileChange($event)" id="inputGroupFile01"
                                                            aria-describedby="inputGroupFileAddon01" accept="image/*"
                                                            [ngClass]="{ 'is-invalid': submitted && f.fileupload.errors}">
                                                        <label class="custom-file-label"
                                                            for="inputGroupFile01">{{selectedFileName}}</label>
                                                    </div>
                                                    <div *ngIf="submitted && f.fileupload.errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.fileupload.errors.required">Profile Image is
                                                            required</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <input type="text" formControlName="firstname" class="form-control"
                                                    maxlength="25"
                                                    [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }" />
                                                <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                                                    <div *ngIf="f.firstname.errors.required">First Name is required
                                                    </div>
                                                    <div *ngIf="f.firstname.errors.maxlength">only 25 characters are
                                                        allowed</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <input placeholder="Last Name" type="text" formControlName="lastname"
                                                    maxlength="25" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <select class="browser-default custom-select" formControlName="gender">
                                                    <option disabled>Select Gender</option>
                                                    <option>Male</option>
                                                    <option>Female</option>
                                                    <option>TransGender</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-content myDashboard-content">
                            <div id="home" class="tab-pane active">
                                <div class="row borderpro">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <input type="text" formControlName="email" class="form-control" disabled />
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <input type="text" placeholder="Address 2 (Street)" formControlName="area"
                                                maxlength="50" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <input type="text" placeholder="State" formControlName="state"
                                                class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <input type="text" placeholder="City/District/Town" formControlName="city"
                                                class="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <button (click)="onSubmit()" class="col-lg-2 float-right">Update Profile &nbsp;<i
                                        class="fas fa-long-arrow-alt-right"></i></button>
                            </div>

                            <div id="menu1" class="tab-pane fade">
                                <div class="row borderpro">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <input type="text" placeholder="Qualification"
                                                formControlName="qualification" maxlength="25" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <input type="text" placeholder="Profession" formControlName="profession"
                                                maxlength="25" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <input type="text" placeholder="Current Education"
                                                formControlName="currentEducation" maxlength="25"
                                                class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <input type="text" placeholder="Preferences" formControlName="preferences"
                                                maxlength="40" class="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <button (click)="onSubmit()" class="col-lg-2 float-right">Update Profile &nbsp;<i
                                        class="fas fa-long-arrow-alt-right"></i></button>
                            </div>

                            <div id="menu3" [ngClass]="{ 'active': tabName=='menu3','fade':tabName!='menu3'}"
                                class="tab-pane">
                                <div class="row borderpro">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <input type="text" placeholder="Paste your Facebook URL here"
                                                maxlength="100" formControlName="facebook" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <input type="text" placeholder="Paste your Twitter URL here" maxlength="100"
                                                formControlName="twitter" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <input type="text" placeholder="Paste your Instagram URL here"
                                                maxlength="100" formControlName="instagram" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <input type="text" placeholder="Paste your LinkedIn URL here"
                                                maxlength="100" formControlName="linkedIn" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <input type="text" placeholder="Paste your You Tube URL here"
                                                maxlength="100" formControlName="youtube" class="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <button (click)="onSubmit()" class="col-lg-2 float-right">Update Profile &nbsp;<i
                                        class="fas fa-long-arrow-alt-right"></i></button>
                            </div>

                        </div>
                    </div>
                </form>
            </div>
            <div id="account" [ngClass]="{ 'active': tabName=='account','fade':tabName!='account'}" class="tab-pane fade">
                 <div class="form-group">
                    <input type="text" class="form-control" placeholder="Search Here" [(ngModel)]="term">
                </div>
                <div class="">                
                    <table datatable [dtOptions]="dtOptions" class="table">  
                    <tr>
                        <th>
                            Product Name
                        </th>
                        <th>
                            Monthly/
                            <br>
                            Annually
                        </th>
                        <th>
                            Description
                        </th>
                        <th (click)="sort('productPrice')">                           
                             <span *ngIf="!isDesc">
                                <i class="fa fa-arrow-up" aria-hidden="true"></i>
                            </span>
                            <span *ngIf="isDesc">
                                <i class="fa fa-arrow-down" aria-hidden="true"></i>
                            </span>
                            Price
                        </th>
                        <th>
                            Product Status
                        </th>
                        <th (click)="sort('productPrice')">
                            <span *ngIf="!isDesc">
                                <i class="fa fa-arrow-up" aria-hidden="true"></i>
                            </span>
                            <span *ngIf="isDesc">
                                <i class="fa fa-arrow-down" aria-hidden="true"></i>
                            </span>
                            Active Till Date
                        </th>
                        <th (click)="sort('status')">
                            <span *ngIf="!isDesc">
                                <i class="fa fa-arrow-up" aria-hidden="true"></i>
                            </span>
                            <span *ngIf="isDesc">
                                <i class="fa fa-arrow-down" aria-hidden="true"></i>
                            </span>
                            Payment Status
                        </th>
                        <th (click)="sort('remainingClass')">
                           <span *ngIf="!isDesc">
                                <i class="fa fa-arrow-up" aria-hidden="true"></i>
                            </span>
                            <span *ngIf="isDesc">
                                <i class="fa fa-arrow-down" aria-hidden="true"></i>
                            </span>
                            Remianing Class
                        </th>
                        <th >                            
                            Invoice
                        </th>
                        <th>
                            Payment
                        </th>
                    </tr>
                    <tr *ngFor="let slide of paymentDetails | filter:term  | paginate: { id: 'lib10',itemsPerPage: countv10, currentPage: v10 }">
                        <td class="p-3">
                            {{slide.productDetails.productName}}
                        </td>
                        <td class="p-3">
                            <span *ngIf="slide.type=='month_year_package';else notMonthYear">
                                 {{slide.productDetails.monthly_annully}}
                            </span>
                            <ng-template #notMonthYear>
                                -
                            </ng-template>
                             <span *ngIf="slide.productDetails.isOneonone || slide.productDetails.isFree || slide.productDetails.isCoder">
                               -                               
                            </span> -->
<!--                             
                        </td>
                        
                        <td class="p-3">
                           
                            <span *ngIf="slide.productDetails.coderDetails">
                                {{slide.productDetails.coderDetails.benefits}}
                            </span>
                             <span *ngIf="!slide.productDetails.coderDetails">
                                {{slide.productDetails.productDescription}}
                            </span>
                            
                        </td>
                        <td class="p-3">
                            {{slide.productDetails.currency}} {{slide.productPrice}}
                        </td>
                        <td class="p-3">
                            <span [ngSwitch]="slide.type">
                                <span *ngSwitchCase="'isFree'">
                                    <span *ngIf="slide.remainingClass !=0&& slide.status;else expiredClassisFree">
                                        Active
                                    </span>
                                    <ng-template #expiredClassisFree>
                                        Expired
                                    </ng-template>
                                    <span *ngIf="!slide.status">
                                        Expired
                                    </span>
                                </span>
                                <span *ngSwitchCase="'isCoder'">
                                    <span *ngIf="slide.remainingClass !=0 && slide.status;else expiredClassisCoder">
                                        Active
                                    </span>
                                    <ng-template #expiredClassisCoder>
                                        Expired
                                    </ng-template>
                                    <span *ngIf="!slide.status">
                                        Expired
                                    </span>
                                </span>
                                <span *ngSwitchCase="'isCrash'">
                                    <span *ngIf="slide.remainingClass !=0 && slide.status;else expiredClassisCrash">
                                        Active
                                    </span>
                                    <ng-template #expiredClassisCrash>
                                        Expired
                                    </ng-template>
                                    <span *ngIf="!slide.status">
                                        Expired
                                    </span>
                                </span>
                                <span *ngSwitchCase="'isOneonone'">
                                    <span *ngIf="slide.remainingClass !=0 && slide.status;else expiredClassisOneonone">
                                        Active
                                    </span>
                                    <ng-template #expiredClassisOneonone>
                                        Expired
                                    </ng-template>
                                    <span *ngIf="!slide.status">
                                        Expired
                                    </span>
                                </span>
                                <span *ngSwitchCase="'month_year_package'">
                                    <span *ngIf="slide.status">
                                        {{slide.validTill | productexpired}}
                                    </span>
                                   
                                    <span *ngIf="!slide.status">
                                        Expired
                                    </span>
                                </span>
                            </span> -->

                           <!--  <span *ngIf="slide.remainingClass && !slide.productDetails.isOneonone && slide.status && !slide.productDetails.isFree && !!slide.productDetails.isCoder">
                                  {{slide.validTill | productexpired}}
                            </span>
                            <span *ngIf="slide.remainingClass ==0 && !slide.productDetails.isFree || !slide.status && !slide.productDetails.isFree">
                                Expired
                            </span>
                            <span *ngIf="slide.remainingClass !=0 && slide.productDetails.isOneonone && slide.status && !slide.productDetails.isFree">
                                Active
                            </span>
                             <span *ngIf="slide.remainingClass !=0 && slide.productDetails.isFree">
                                Active
                            </span>
                            <span *ngIf="slide.remainingClass ==0 && slide.productDetails.isFree">
                                Expired
                            </span>       -->                   
                        <!-- </td>
                        <td class="p-3">
                            <span *ngIf="slide.type=='month_year_package';else notValid">
                               {{slide.validTill |  date :"MMM dd,yyyy, hh:mm aaa"}}
                            </span>
                            <ng-template #notValid>
                                -
                            </ng-template> -->

                           <!--  <span *ngIf="!slide.productDetails.isOneonone || !slide.productDetails.isCoder">
                                {{slide.validTill |  date :"MMM dd,yyyy, hh:mm aaa"}}
                            </span>
                            <span *ngIf="slide.productDetails.isOneonone || slide.productDetails.isFree || !slide.productDetails.isCoder">
                               -                               
                            </span> -->
<!--                             
                         </td>
                        <td class="p-3">
                            <span [class]="slide.status ? 'text-success' : 'text-danger'">
                                {{slide.status ? 'Success':'Failure'}}
                                
                            </span>
                        </td>
                         <td class="p-3">
                            {{slide.remainingClass}}
                        </td>
                        <td class="p-3">
                           <a class="text-danger" *ngIf="!slide.productDetails.isFree ;else notView" [href]="slide.invoiceURL" target="_blank">View</a>
                            <ng-template #notView>
                                -
                            </ng-template>
                        </td>
                        <td class="p-3" >
                            <span (click)="repayment(slide)" *ngIf="slide.remainingClass ==0 && slide.status && !slide.productDetails.isFree">
                                Repayment
                            </span>
                             <span (click)="repayment(slide)" *ngIf="!slide.status && !slide.productDetails.isFree">
                                Repayment
                            </span>
                             <span *ngIf="slide.productDetails.isFree">
                                -
                            </span>
                          
                        </td>

                    </tr> 
                    <tr>
                        <td colspan="11" *ngIf="paymentDetails.length==0">
                            No Payment Data Available
                        </td>
                    </tr>
                    </table>
                    <div style="float: right;" *ngIf="paymentDetails.length !=0">
                        <pagination-controls (pageChange)="v10 = $event" id="lib10"></pagination-controls>
                    </div>
                </div>
            </div>

            <div id="studymaterial" [ngClass]="{ 'active': tabName=='studymaterial','fade':tabName!='studymaterial'}"
                class="tab-pane">
                <div class="row">
                    <div class="col-lg-9">

                    </div>
                    <div class="col-lg-3">

                        <form [formGroup]="LibraryForm">
                            <span *ngIf="studentIsSchool">                    
                                <input type="checkbox" (click)="publicOrGroupEbook($event)" formControlName="securityTypeebook">Only Schools
                            </span>
                        </form>
                    </div>

                </div>    
                    <h3 style="color: var(--mainColor);">e-Books</h3>
                <a routerLink="/create/ebook" class="default-btn mb-2  ml-2 float-right p-2">Add e-Book</a>
                <div class="container-fluid row p-0" style="cursor: pointer" *ngIf="ebookMy.length !=0">
                    <div class="col-lg-3 col-md-6"
                        *ngFor="let slide of ebookMy | paginate: { id: 'lib1',itemsPerPage: countv1, currentPage: v1 }">
                        <div class="single-courses-box without-box-shadow mb-30">
                            <div class="courses-image">
                                <img style="width: 250px;height: 175px;" (click)="ebookdetails(slide)"
                                    [src]="slide.thumbname ? slide.thumbname : 'assets/img/videocat-thumb-1.jpg'" alt=""
                                    >
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center"
                                    *ngIf="slide.studentID !=undefined">
                                    <img [src]="slide.studentID!=undefined && slide.studentID.userPhoto!='' ? slide.studentID.userPhoto  :'assets/img/user1.jpg'"
                                        class="rounded-circle mr-2" alt="image">
                                    <span *ngIf="slide.studentID!=undefined">{{slide.studentID.profileName}}
                                    </span>
                                </div>
                                <div class="course-author d-flex align-items-center"
                                    *ngIf="slide.studentID ==undefined">
                                    <img [src]="slide.teacherID!=undefined && slide.teacherID.userPhoto!='' ? slide.teacherID.userPhoto :'assets/img/user1.jpg'"
                                        class="rounded-circle mr-2" alt="image">
                                    <span *ngIf="slide.teacherID!=undefined">{{slide.teacherID.profileName}}
                                    </span>
                                </div>
                                <h3>
                                    <a (click)="ebookdetails(slide)" class="d-inline-block">
                                        <span
                                            class="text-break">{{slide.flimname}}
                                        </span>
                                    </a>
                                </h3>
                            </div>
                            <div>
                                Type: {{slide.securitytype =='Group' ?'School' : slide.securitytype}}
                            </div>
                            <div class="courses-box-footer">
                                {{slide.categoryID ? slide.categoryID[0].categoryName : '' }},
                                {{slide.gradeID ? slide.gradeID[0].gradeName : '' }},
                                {{slide.boardID ? slide.boardID.boardName : '' }}
                            </div>
                            <div class="courses-box-footer">
                                {{slide.subjectID ? slide.subjectID[0].subjectName : '' }},
                                {{slide.languageID ? slide.languageID.languageName : '' }}
                            </div>
                            <div *ngIf="slide.isPartner" class="courses-box-footer">
                                Provided By : {{slide.isbuddi_premium? 'buddi Premium' : slide.partner.partnerName}}
                            </div>
                        </div>
                    </div>
                    <div *ngIf="ebookMy.length ==0">
                        No e-Book(s) Available.
                    </div>
                </div>
                <div style="float: right;" *ngIf="ebookMy.length!=0">
                    <pagination-controls (pageChange)="v1 = $event" id="lib1"></pagination-controls>
                </div>
                <librarylist 
                    type="g.classType.value ? 'School' : 'Public'"
                    *ngIf="data.ebook!=undefined && data.ebook.length!=0" 
                    [config]="config.ebook"
                    [data]="data.ebook" 
                    (pageNumber)="receivePageNumber($event)"
                    >                    
                </librarylist>
                <div *ngIf="data.ebook==undefined || data.ebook.length==0">
                        No Audio(s) Available.
                </div> -->
                <!-- <div>
                    <div class="container-fluid row p-0" style="cursor: pointer"
                        *ngIf="ebookGroup.length !=0 && g.securityTypeebook.value">

                        <div class="col-lg-3 col-md-6"
                            *ngFor="let slide of ebookGroup | paginate: { id: 'lib2',itemsPerPage: countv2, currentPage: v2 }">
                            <div class="single-courses-box without-box-shadow mb-30">
                                <div class="courses-image">

                                    <img style="width:100%" (click)="ebookdetails(slide['ebookId'])"
                                        style="width: 250px;height: 175px;"
                                        [src]="slide['ebookId'].thumbname ? slide['ebookId'].thumbname : 'assets/img/videocat-thumb-1.jpg'"
                                        alt="" width="100%">
                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img [src]="slide['ebookId'].teacherID!=undefined && slide['ebookId'].teacherID.userPhoto!='' ? slide['ebookId'].teacherID.userPhoto  :'assets/img/user1.jpg'"
                                            class="rounded-circle mr-2" alt="image">
                                        <span
                                            *ngIf="slide['ebookId'].teacherID!=undefined">{{slide['ebookId'].teacherID.profileName}}
                                        </span>
                                    </div>
                                    <h3>
                                        <a (click)="ebookdetails(slide['ebookId'])"
                                            class="d-inline-block">
                                            <span class="text-break">{{slide['ebookId'].flimname}}
                                            </span>
                                        </a>
                                    </h3>
                                </div>
                                <div>
                                    Type:
                                    {{slide['ebookId'].securitytype =='Group' ?'School' : slide['ebookId'].securitytype}}
                                </div>
                                <div class="courses-box-footer">
                                    {{slide['ebookId'].categoryID ? slide['ebookId'].categoryID.categoryName : '' }},
                                    {{slide['ebookId'].gradeID ? slide['ebookId'].gradeID.gradeName : '' }},
                                    {{slide['ebookId'].boardID ? slide['ebookId'].boardID.boardName : '' }}
                                </div>
                                <div class="courses-box-footer">
                                    {{slide['ebookId'].subjectID ? slide['ebookId'].subjectID.subjectName : '' }},
                                    {{slide['ebookId'].languageID ? slide['ebookId'].languageID.languageName : '' }}
                                </div>
                                <div *ngIf="slide['ebookId'].isPartner" class="courses-box-footer">
                                    Provided By :
                                    {{slide['ebookId'].isbuddi_premium? 'buddi Premium' : slide['ebookId'].partner.partnerName}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="ebookGroup.length ==0 && g.securityTypeebook.value">
                        No e-Book(s) Available.
                    </div>
                    <div style="float: right;" *ngIf="ebookGroup.length!=0 && g.securityTypeebook.value">
                        <pagination-controls (pageChange)="v2 = $event" id="lib2"></pagination-controls>
                    </div>
                </div>
                <div>
                    <div class="container-fluid row p-0" style="cursor: pointer"
                        *ngIf="ebookPublic.length !=0 && !g.securityTypeebook.value">
                        <div class="col-lg-3 col-md-6"
                            *ngFor="let slide of ebookPublic | paginate: { id: 'lib3',itemsPerPage: countv3, currentPage: v3 }">
                            <div class="single-courses-box without-box-shadow mb-30">
                                <div class="courses-image">
                                    <img style="width: 250px;height: 175px;" (click)="ebookdetails(slide['ebookId'])"
                                        style="width: 250px;height: 175px;"
                                        [src]="slide['ebookId'].thumbname ? slide['ebookId'].thumbname : 'assets/img/videocat-thumb-1.jpg'"
                                        alt="" width="100%">
                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img [src]="slide['ebookId'].teacherID!=undefined && slide['ebookId'].teacherID.userPhoto!='' ? slide['ebookId'].teacherID.userPhoto :'assets/img/user1.jpg'"
                                            class="rounded-circle mr-2" alt="image">
                                        <span
                                            *ngIf="slide['ebookId'].teacherID!=undefined">{{slide['ebookId'].teacherID.profileName}}
                                        </span>
                                    </div>
                                    <h3>
                                        <a (click)="ebookdetails(slide['ebookId'])"
                                            class="d-inline-block">
                                            <span class="text-break">{{slide['ebookId'].flimname}}
                                            </span>
                                        </a>
                                    </h3>
                                </div>
                                <div>
                                    Type:
                                    {{slide['ebookId'].securitytype=='Group' ?'School' : slide['ebookId'].securitytype}}
                                </div>
                                <div class="courses-box-footer">
                                    {{slide['ebookId'].categoryID ? slide['ebookId'].categoryID.categoryName : '' }},
                                    {{slide['ebookId'].gradeID ? slide['ebookId'].gradeID.gradeName : '' }},
                                    {{slide['ebookId'].boardID ? slide['ebookId'].boardID.boardName : '' }}
                                </div>
                                <div class="courses-box-footer">
                                    {{slide['ebookId'].subjectID ? slide['ebookId'].subjectID.subjectName : '' }},
                                    {{slide['ebookId'].languageID ? slide['ebookId'].languageID.languageName : '' }}
                                </div>
                                <div *ngIf="slide['ebookId'].isPartner" class="courses-box-footer">
                                    Provided By :
                                    {{slide['ebookId'].isbuddi_premium? 'buddi Premium' : slide['ebookId'].partner.partnerName}}
                                </div>
                            </div>
                        </div>          
                    </div>
                    <div *ngIf="ebookPublic.length ==0 &&!g.securityTypeebook.value">
                         No e-Book(s) Available.
                    </div>
                    <div style="float: right;" *ngIf="ebookPublic.length!=0 && !g.securityTypeebook.value">
                        <pagination-controls (pageChange)="v3 = $event" id="lib3"></pagination-controls>
                    </div>
                </div> -->
                <!-- <br>
                <hr>
                <h3 style="color: var(--mainColor);">Solutions</h3>
                <librarylist 
                    type="g.securityTypeebook.value ? 'School' : 'Public'"
                    *ngIf="data.solution!=undefined && data.solution.length!=0" 
                    [config]="config.solution"
                    [data]="data.solution" 
                    (pageNumber)="receivePageNumber($event)"
                    >                    
                </librarylist>
                <div *ngIf="data.solution==undefined || data.solution.length==0">
                        No Solution(s) Available.
                </div> -->
               <!--  <reuseList 
                    [type]="'Public'" 
                    *ngIf="solutionPublic.length!=0 && !g.securityTypeebook.value" 
                    [itemName]="'solution'" 
                    [config]="config.public"
                    [dataList]="solutionPublic" 
                    [redirectText]="'details/solution/'"
                    (pageNumber)="receivePageNumber($event)"
                >                    
                </reuseList>
                <div *ngIf="solutionPublic.length==0 && !g.securityTypeebook.value">
                    No Solution(s) Available.
                </div>
                <reuseList 
                    [type]="'Group'" 
                    *ngIf="solutionGroup.length!=0 && g.securityTypeebook.value" 
                    [itemName]="'solution'" 
                    [config]="config.group"
                    [dataList]="solutionGroup" 
                    [redirectText]="'details/solution/'"
                    (pageNumber)="receivePageNumber($event)"
                >                    
                </reuseList> 
                <div *ngIf="solutionGroup.length==0 && g.securityTypeebook.value">
                    No Solution(s) Available.
                </div>   -->
                <!-- <br>
                <hr>
                    <h3 style="color: var(--mainColor);">Question Papers</h3>
                <librarylist 
                    type="g.securityTypeebook.value ? 'School' : 'Public'"
                    *ngIf="data.question!=undefined && data.question.length!=0" 
                    [config]="config.question"
                    [data]="data.question" 
                    (pageNumber)="receivePageNumber($event)"
                    >                    
                </librarylist>
                <div *ngIf="data.question==undefined || data.question.length==0">
                    No Question(s) Available.
                </div>  
            </div>
            <div id="progress" [ngClass]="{ 'active': tabName=='progress','fade':tabName!='progress'}" class="tab-pane"
                (click)="quicksightembed()">
                <div class="row">
                    <div class="col-lg-3">
                        <label class="example-input-wrapper">
                             Date From :
                            <br>
                            <input placeholder="From" class="form-control" [(ngModel)]="dateTimeRange" [selectMode]="'rangeFrom'"
                                [owlDateTimeTrigger]="dtRange2" [owlDateTime]="dtRange2">
                            <owl-date-time #dtRange2 [pickerType]="'calendar'"></owl-date-time>
                        </label>
                    </div>

                    <div class="col-lg-3">
                        <label class="example-input-wrapper">
                             Date To :
                            <br>
                            <input placeholder="To" class="form-control" [(ngModel)]="dateTimeRangeTo" [selectMode]="'rangeTo'"
                                [owlDateTimeTrigger]="dtRange3" [owlDateTime]="dtRange3">
                            <owl-date-time #dtRange3 [pickerType]="'calendar'"></owl-date-time>
                        </label>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-lg-3">
                        <a href="https://charts.mongodb.com/charts-buddilife_testingserver-blkia/embed/charts?id=79585710-6399-453b-8fcb-373a1aeaa0e7&theme=light&filter={studentID:ObjectId('{{studentid}}'),$and:[{dateTaken:{$gte:ISODate('{{dateTimeRange | date: 'yyyy-MM-dd'}}')}},{dateTaken:{$lte:ISODate('{{dateTimeRangeTo | date: 'yyyy-MM-dd'}}')}}]}" title="Click here" class="default-btn mr-1 p-2" target="_blank">buddi Tests </a>
                    </div>
                    <div class="col-lg-3">
                        <a href="https://charts.mongodb.com/charts-buddilife_testingserver-blkia/embed/charts?id=9f061a7e-9c88-4053-9274-0eea3c47d9d2&theme=light&filter={studentID:ObjectId('{{studentid}}'),$and:[{ScheduleDate:{$gte:ISODate('{{dateTimeRange | date: 'yyyy-MM-dd'}}')}},{ScheduleDate:{$lte:ISODate('{{dateTimeRangeTo | date: 'yyyy-MM-dd'}}')}}]}" title="Click here" class="default-btn mr-1 p-2" target="_blank">One-on-One Classes</a>
                    </div>
                    <div class="col-lg-3">
                        <a href="https://charts.mongodb.com/charts-buddilife_testingserver-blkia/embed/charts?id=3e125417-409c-4ade-be8e-816f1e08f1c2&theme=light&filter={userId:ObjectId('{{studentid}}'),$and:[{createdDate:{$gte:ISODate('{{dateTimeRange | date: 'yyyy-MM-dd'}}')}},{createdDate:{$lte:ISODate('{{dateTimeRangeTo | date: 'yyyy-MM-dd'}}')}}]}" title="Click here" class="default-btn mr-1 p-2" target="_blank">Video Classes View</a>
                    </div>
                </div> -->
                
                
                
                <!-- <h4>StudentId: '{{studentid}}'</h4>
                <h4>Value From Date: '{{dateTimeRange | date: 'yyyy-MM-dd'}}'</h4>
                <h4>Value To Date: '{{dateTimeRangeTo | date: 'yyyy-MM-dd'}}'</h4> -->
                <!-- <div id="dashboardContainer1" style="height: 100% !important; width: 100% !important;"></div>
                <div id="dashboardContainerempty" style="height: 100% !important; width: 100% !important;"> -->
                    <!-- <h3> No data Available for Display.</h3>
                    <iframe
                        style="background: #FFFFFF;border: none;border-radius: 2px;box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);"
                        width="940" height="680"
                        src="https://charts.mongodb.com/charts-buddilife_testingserver-blkia/embed/charts?id=79585710-6399-453b-8fcb-373a1aeaa0e7&theme=light&filter={studentID:ObjectId('{{studentid}}'),$and:[{dateTaken:{$gte:ISODate('{{dateTimeRange | date: 'yyyy-MM-dd'}}')}},{dateTaken:{$lte:ISODate('{{dateTimeRangeTo | date: 'yyyy-MM-dd'}}')}}]}"></iframe>
                    <iframe
                        style="background: #FFFFFF;border: none;border-radius: 2px;box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);"
                        width="940" height="680"
                        
                        src="https://charts.mongodb.com/charts-buddilife_testingserver-blkia/embed/charts?id=9f061a7e-9c88-4053-9274-0eea3c47d9d2&theme=light&filter={studentID:ObjectId('{{studentid}}'),$and:[{ScheduleDate:{$gte:ISODate('{{dateTimeRange | date: 'yyyy-MM-dd'}}')}},{ScheduleDate:{$lte:ISODate('{{dateTimeRangeTo | date: 'yyyy-MM-dd'}}')}}]}"></iframe>
                    <img
                        style="background: #FFFFFF;border: none;border-radius: 2px;box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);"
                        width="940" height="680"
                        
                        src="https://charts.mongodb.com/charts-buddilife_testingserver-blkia/embed/charts?id=3e125417-409c-4ade-be8e-816f1e08f1c2&theme=light&filter={userId:ObjectId('{{studentid}}'),$and:[{createdDate:{$gte:ISODate('{{dateTimeRange | date: 'yyyy-MM-dd'}}')}},{createdDate:{$lte:ISODate('{{dateTimeRangeTo | date: 'yyyy-MM-dd'}}')}}]}"> -->
                <!-- </div> -->

            </div>
        </div>
    <!-- </div> -->
</section>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <img src="assets/img/spinner-default.gif" alt="">
</ngx-spinner>