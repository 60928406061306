<app-header-style-one *ngIf="!isTeacher"></app-header-style-one>
    <app-header-style-one-teacher
        *ngIf="isTeacher"
    ></app-header-style-one-teacher>
    <div class="containerBg">
        <div class="container">
            <div class="row mt-5">
                <div class="col-md-12">
                    <div class="serviceTitle">
                        <h3 class="col-lg-12" labelStyle>
                            <span>
                                <span class="ml-2"
                                    >{{
                                        this.editData._id ? "Update" : "Create"
                                    }}
                                    Institution</span
                                >
                            </span>
                            <button  type="button"  class="default-btn float-right"  (click)="goBack()"  ><i class="fas fa-long-arrow-alt-left"></i>&nbsp;Back</button>
                        </h3>
                    </div>
                    <hr />
                </div>
            </div>
            <div class="container">
                <form [formGroup]="createForm">
                    <div class="row">
                        <!-- <div class="col-lg-6 description">
                            <div class="form-group mb-3">
                    <label labelStyle>Video Name</label>
                               <input type="text" formControlName="videoname" placeholder="Video Name"  class="form-control" maxlength="50" [ngClass]="{ 'is-invalid': submitted && f.videoname.errors}" />
                                   <div *ngIf="submitted && f.videoname.errors" class="invalid-feedback">
                                    <div *ngIf="f.videoname.errors.required">
                                        Video Name is required
                                    </div>
                                 </div>
                            </div>
                        </div>					 -->
                        <div class="col-md-6 description">
                            <div class="form-group mb-3">
                                <label labelStyle>Institute Name *</label>
                                <input
                                    type="text"
                                    maxlength="25"
                                    formControlName="instituteName"
                                    class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.instituteName.errors }"
                                /> 
                                <div *ngIf="submitted && f.instituteName.errors" class="invalid-feedback">
                                    <div *ngIf="f.instituteName.errors.required">InstituteName is required</div>
                                    <div *ngIf="f.instituteName.errors.maxlength">
                                        Maximum 25 characters
                                       </div>
                                  </div>
                            </div>
                        </div>
                        <div class="col-md-6 description">
                            <div class="form-group mb-3">
                                <label labelStyle>Short Name *</label>
                                <input
                                    type="text"
                                    maxlength="25"
                                    formControlName="shortName"
                                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.shortName.errors }"
                                > 
                                <div *ngIf="submitted && f.shortName.errors" class="invalid-feedback">
                                    <div *ngIf="f.shortName.errors.required">ShortName is required</div>
                                    <div *ngIf="f.instituteName.errors.maxlength">
                                        Maximum 25 characters
                                       </div>
                                  </div>
                            </div>
                        </div>

                        <!-- <div class="col-md-6 description">
                            <div class="form-group mb-3">
                                <label labelStyle>Address</label>
                                <input
                                    type="text"
                                    maxlength="95"
                                    formControlName="address"
                                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.address.errors }"
                                /> 
                                <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                                    <div *ngIf="f.address.errors.required">Address is required</div>
                                  </div>
                            </div>
                        </div> -->
                        <div class="col-md-6 description">
                            <div class="form-group mb-3">
                                <label labelStyle>Locality</label>
                                <input
                                    type="text"
                                    maxlength="95"
                                    formControlName="Locality"
                                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.address.errors }"
                                /> 
                                <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                                    <div *ngIf="f.address.errors.required">Address is required</div>
                                  </div>
                            </div>
                        </div>
                        <div class="col-md-6 description">
                            <div class="form-group mb-3">
                                <label labelStyle>Phone number *</label>
                                <input
                                    type="text"
                                    maxlength="15"
                                    minlength="10"
                                    formControlName="phonenumber"
                                    class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }"
                                /> 
                                <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                                    <div *ngIf="f.phonenumber.errors.required">Phonenumber is required</div>
                                    <div *ngIf="f.phonenumber.errors.minlength">
                                        Minimum 10 digits number
                                      </div>
                                      <div *ngIf="f.phonenumber.errors.maxlength">
                                       Maximum 15 digits number
                                      </div>
                                  </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group mb-3">
                                <label labelStyle>About(description)</label>
                                <input
                                    type="text"
                                    maxlength="200"
                                    formControlName="description"
                                    class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.description.errors }"
                                /> 
                                <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                                    <div *ngIf="f.description.errors.required">Description is required</div>
                                  </div>
                            </div>
                        </div>
                        <!--<div class="col-md-6 description">
                            <div class="form-group mb-3">
                                <label labelStyle>Admin Name</label>
                                <input
                                    type="text"
                                    maxlength="25"
                                    formControlName="adminName"
                                    class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.adminName.errors }"
                                /> 
                                <div *ngIf="submitted && f.adminName.errors" class="invalid-feedback">
                                    <div *ngIf="f.adminName.errors.required">AdminName is required</div>
                                    <div *ngIf="f.instituteName.errors.maxlength">
                                        Maximum 25 characters
                                       </div>
                                  </div>
                            </div>
                        </div>
                        <div class="col-md-6 description">
                            <div class="form-group mb-3">
                                <label labelStyle>Admin Phone number</label>
                                <input
                                    type="text"
                                    maxlength="15"
                                    minlength="10"
                                    formControlName="adminPhonenumber"
                                    class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.adminPhonenumber.errors }"
                                /> 
                                <div *ngIf="submitted && f.adminPhonenumber.errors" class="invalid-feedback">
                                    <div *ngIf="f.adminPhonenumber.errors.required">AdminPhonenumber is required</div>
                                    <div *ngIf="f.phonenumber.errors.minlength">
                                    Minimum 10 digits number
                                      </div>
                                      <div *ngIf="f.phonenumber.errors.maxlength">
                                       Maximum 15 digits number
                                      </div>
                                  </div>
                            </div>
                        </div>
                        <div class="col-md-6 description">
                            <div class="form-group mb-3">
                                <label labelStyle>Admin Email id</label>
                                <input
                                    type="email"
                                    formControlName="adminEmailid"
                                    pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$"
                                    class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.adminEmailid.errors }"
                                /> 
                                <div *ngIf="submitted && f.adminEmailid.errors" class="invalid-feedback">
                                    <div *ngIf="f.adminEmailid.errors.required">AdminEmailid is required</div>
                                  </div>
                            </div>
                        </div>  
                    -->
                    <div class="col-md-6 description">
                        <div class="form-group mb-3">
                            <label labelStyle>Institution type *</label>
                            <select
                                class="form-control p-0 col-md-12"
                                required
                                formControlName="institutiontype" [ngClass]="{ 'is-invalid': submitted && f.classes.errors }"
                            >
                                <option value=""> 
                                    Select Institution Type
                                </option>
                                <option value="individual">Individual</option>
                                <option value="institutions">Institutions</option>
                                <option value="smallTC">Small Training Centre</option>
                                <option value="mediumTC">Medium Training Centre</option>
                                <option value="largeTC">Larger Training Centre</option>
                                <option value="school">Schools</option>
                                <option value="college">Colleges</option>
                                <option value="government">Govt.Institutions</option>
                                <option value="university">Universities</option>
                            </select>
                            <div *ngIf="submitted && f.institutiontype.errors" class="invalid-feedback">
                                <div *ngIf="f.institutiontype.errors.required">Institution Type is required</div>
                              </div>
                        </div>
                    </div>
    
                        <div class="col-md-6">
                            <div class="form-group mb-3">
                                <label labelStyle>Package Type *</label>
                                <select
                                    class="form-control p-0 col-md-12"
                                    (change)="Institutiontype($event)"
                                    required
                                    formControlName="packagetype"  [ngClass]="{ 'is-invalid': submitted && f.institutiontype.errors }"
                                >
                                    <option value="" disabled> 
                                        Select Package Type
                                    </option>
                                    <option
                                        *ngFor="let obj of institutionTypes"
                                        [ngValue]="obj._id"
                                    >
                                        {{ obj.institutionTypeName }}
                                    </option>   
                                </select>
                                <div *ngIf="submitted && f.institutiontype.errors" class="invalid-feedback">
                                    <div *ngIf="f.institutiontype.errors.required">Institutiontype is required</div>
                                  </div>
                            </div>
                        </div>
                    <!--
                        <div
                        class="col-md-6 description"
                        *ngIf="languageapi.length != 0"
                    >
                        <div class="form-group">
                            <label labelStyle>Language</label>
                            <select
                                class="form-control col-md-12"
                                (change)="selectlanguageChange($event)"
                                formControlName="courseLanguage" [ngClass]="{ 'is-invalid': submitted && f.courseLanguage.errors }"
                            >
                                <option value="" disabled>
                                    Select Language
                                </option>
                                <option
                                    *ngFor="let obj of languageapi"
                                    [ngValue]="obj._id"
                                >
                                    {{ obj.languageName }}
                                </option>
                            </select>
                            <div *ngIf="submitted && f.courseLanguage.errors" class="invalid-feedback">
                                <div *ngIf="f.courseLanguage.errors.required">CourseLanguage is required</div>
                              </div>
                        </div>
                    </div>
                    -->
                    <div class="col-md-6">
                        <div class="form-group mb-3">
                            <label labelStyle>Classes *</label>
                            <select
                                class="form-control p-0 col-md-12"
                                required
                                formControlName="classes" [ngClass]="{ 'is-invalid': submitted && f.classes.errors }"
                            >
                                <option value=""> 
                                    Select Class Type
                                </option>
                                <option value="online" >Online</option>
                                <option value="offline" >Offline</option>
                                <option value="bothOnlineAndOffline" >Both</option>
                            </select>
                            <div *ngIf="submitted && f.classes.errors" class="invalid-feedback">
                                <div *ngIf="f.classes.errors.required">Classes is required</div>
                              </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-6 description">
                        <div class="form-group mb-3">
                            <label labelStyle>Expertise</label>
                            <input
                                type="text"
                                formControlName="expertise"
                                class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.expertise.errors }"
                            /> 
                            <div *ngIf="submitted && f.expertise.errors" class="invalid-feedback">
                                <div *ngIf="f.expertise.errors.required">Expertise is required</div>
                              </div>
                        </div>
                    </div> -->
                    <!-- <div class="col-md-6 description">
                        <div class="form-group mb-3">
                            <label labelStyle>Reviews</label>
                            <input
                                type="text"
                                formControlName="reviews"
                                class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.reviews.errors }"
                            /> 
                            <div *ngIf="submitted && f.reviews.errors" class="invalid-feedback">
                                <div *ngIf="f.reviews.errors.required">Reviews is required</div>
                              </div>
                        </div>
                    </div> -->
            
                    <div class="col-md-6 description">
                        <div class="form-group mb-3">
                            <label labelStyle>Linkedin URL:</label>
                            <input
                                type="text"
                                formControlName="linkedin"
                                class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.linkedin.errors }"
                            /> 
                            <div *ngIf="submitted && f.linkedin.errors" class="invalid-feedback">
                                <div *ngIf="f.linkedin.errors.required">Linkedin is required</div>
                              </div>
                        </div>
                    </div>
                    <div class="col-md-6 description">
                        <div class="form-group mb-3">
                            <label labelStyle>Facebook URL: </label>
                            <input
                                type="text"
                                formControlName="facebook"
                                class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.whatsapp.errors }"
                            /> 
                            <div *ngIf="submitted && f.whatsapp.errors" class="invalid-feedback">
                                <div *ngIf="f.whatsapp.errors.required">Whatsapp is required</div>
                              </div>
                        </div>
                    </div>
           
                    <!-- <div class="col-md-6 description">
                        <div class="form-group mb-3">
                            <label labelStyle>Total tutors</label>
                            <input
                                type="number"
                                formControlName="totalteachers"
                                class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.totalteachers.errors }"
                            /> 
                            
                            <div *ngIf="submitted && f.totalteachers.errors" class="invalid-feedback">
                                <div *ngIf="f.totalteachers.errors.required">Totaltutors is required</div>
                              </div>
                        </div>
                    </div> -->
                    <!-- <div class="col-md-6 description">
                        <div class="form-group mb-3">
                            <label labelStyle>Total learners</label>
                            <input
                                type="number"
                                formControlName="totalstudents"
                                class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.totalstudents.errors }"
                            /> 
                            <div *ngIf="submitted && f.totalstudents.errors" class="invalid-feedback">
                                <div *ngIf="f.totalstudents.errors.required">Totallearners is required</div>
                              </div>
                        </div>
                    </div> -->
                    <!-- <div class="col-md-6 description">
                        <div class="form-group mb-3">
                            <label labelStyle>Total admins</label>
                            <input
                                type="number"
                                formControlName="totaladmins"
                                class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.totaladmins.errors }"
                            /> 
                            <div *ngIf="submitted && f.totaladmins.errors" class="invalid-feedback">
                                <div *ngIf="f.totaladmins.errors.required">Totaladmins is required</div>
                              </div>
                        </div>
                    </div> -->
                    </div>
                    <div class="row">
                        <div class="col-md-12 description">
                            <div class="text-center mt-3">
                                <button
                                    style="width: 20%"
                                    type="button"
                                    (click)="onSubmit()"
                                    class="default-btn p-2"
                                    id="addInstitutionButton"
                                >
                                    <i class="bx bx-save icon-arrow before"></i>
                                    <span class="label"
                                        >{{
                                            this.editData.length != 0
                                                ? "Update"
                                                : "Submit"
                                        }}
                                    </span>
                                    <i class="bx bx-save icon-arrow after"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
                <br />
                <div class="row"></div>
            </div>
        </div>
    </div>
    <ngx-spinner
        bdColor="rgba(51, 51, 51, 0.8)"
        size="default"
        type="ball-spin-clockwise"
    >
        <img src="assets/img/spinner-default.gif" alt="" />
    </ngx-spinner>