import { Component, OnInit,Inject, ElementRef, ViewChild, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';

// import { Options } from 'ng5-slider';
import { ApiService } from '../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {NgSelectModule, NgOption} from '@ng-select/ng-select';
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from 'moment';
import { Moment } from 'moment';
import { DataFilterPipe } from '../../custom-pipes/data-filter.pipe';
import { OwlDateTimeComponent, DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';

// import { Options } from 'select2';
import { Options } from 'select2';

import { NgSelect2Module } from 'ng-select2';

import {Observable, OperatorFunction} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators'

declare let $: any;

export const MY_MOMENT_DATE_TIME_FORMATS={
  parseInput: 'MM/YYYY',
  fullPickerInput: 'l LT',
  datePickerInput: 'MM/YYYY',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};
// export const MY_CUSTOM_FORMATS = {
//   parseInput: 'LL LT',
//   fullPickerInput: 'LL LT',
//   datePickerInput: 'LL',
//   timePickerInput: 'LT',
//   monthYearLabel: 'MMM YYYY',
//   dateA11yLabel: 'LL',
//   monthYearA11yLabel: 'MMMM YYYY',
//   };

@Component({
  selector: 'app-createmetting',
  templateUrl: './createmetting.component.html',
  styleUrls: ['./createmetting.component.scss'],
  providers: [DataFilterPipe,

    {provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_DATE_TIME_FORMATS},
]
})
export class CreatemettingComponent implements OnInit {
  public date = new FormControl(new Date());

  public model: any;
  public options: Options;

  
  // search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
  // text$.pipe(
  //   debounceTime(200),
  //   distinctUntilChanged(),
  //   map(term => term.length < 2 ? []
  //     : states.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
  // )


  @ViewChild('mapRef', {static: true }) mapElement: ElementRef;
  meetingForm: FormGroup;
  submitted = false;
  selectedValue = "";
  formData;
  categoryselectedValue = "";
  boardselectedValue = "";
  languageselectedValue = "";
  gradeselectedValue = "";
  subjectselectedValue = "";
  moduleselectedValue = "";
  templateselectedValue = "";
  topicselectedValue = "";
  public modulelist : any = [];
  public templatelist : any = [];
  public meetCategory : any = [];
  public coursecategorylist : any = [];
  public coursecategorylistWebinar : any = [];
  public courseboardlist : any = [];
  public coursesubjectlist : any = [];
  public languageapi : any = [];
  public gradeapi : any = [];
  public city : any = [];
  public topicapi : any = [];
  term: string;
  public dateValuesDayArray:any = [];

  public dateValuesDay: any= [
    {
      index:1,
      value:'Monday',
      selected:false
    },{
      index:2,
      value:'Tuesday',
      selected:false
    },{
      index:3,
      value:'Wednesday',
      selected:false
    },{
      index:4,
      value:'Thursday',
      selected:false
    },{
      index:5,
      value:'Friday',
      selected:false
    },
    {
      index:6,
      value:'Saturday',
      selected:false
    },
    {
      index:7,
      value:'Sunday',
      selected:false
    }
  ];

  // public meetingCategory = "Select category";
  // public meetingType = "Group";
  public meetingType = "Select Classes";
  public language = "Select Language";
  public occurance ="Select Re-occurance";
  public meetduration = "Select Duration of Session";
  public meetpricetype = "Free";
  public meetamount = 0;
  public isamount = false;
  public isClassRoom = false;
  public min: any = new Date();
  selectedFileNmae: string = 'Choose Thumbnail Image';
  public alltag:any = [];
  public userId:any = '';
  public groupname:any ='';
  public selected:any = [];
  public selected1: any = [];
  public editData:any=[];
  public paramData:any = [];
  public isEnterprise = '';
  public selecttag:any = [];
   public isEdit:Boolean = false;
   public chapterID: Boolean = true;
   public isfromcoursedetails: Boolean = false; 
     selectedcategorylist = [];
     selectedgradelist = [];
     selectedsubjectlist = [];
     dropdownSettings: any = {};
      dropdowngradeSettings: any = {};
      dropdownsubjectSettings: any = {};
      selectedtopiclist = [];


  constructor(private SpinnerService:NgxSpinnerService,private activatedRoute:ActivatedRoute,public location: Location, public toastr: ToastrManager, private formBuilder: FormBuilder, private rest: ApiService, private router: Router ) { }
  ngOnInit() {

    this.getTeacherAccount();
    console.log('getTeacherAccount',this.getTeacherAccount())

// this.modulelist();
// this.selectmoduleChange(event);
    this.options = {
      multiple: false,
      theme: 'classic',
      closeOnSelect: false,
      width: '500'
    };
    window.scrollTo(0, 0);
    // this.meetingCategory = "Select category";
    var profileName = localStorage.getItem('profileName');
    this.userId = localStorage.getItem('userId');
    const webToken = localStorage.getItem('webtoken');


   
    this.dropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'categoryName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3
  };
  
    this.dropdowngradeSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'gradeName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3
  };
  
  this.dropdownsubjectSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'subjectName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3
  };
 
    this.rest.userName_DisplayTeacher(webToken).subscribe((result) => {
      console.log(result);
      this.groupname =  result.data !=undefined && result.data.groupName != 'buddi' ? result.data.groupName : ''
      localStorage.setItem('groupname', result.data !=undefined && result.data.groupName != 'buddi' ? result.data.groupName : '');
    });
    profileName = profileName.replace(/ /gi, "+");
    var meetingId = profileName+"-"+Math.round(Math.random() * 1000000000)
    // this.meetingdetails();
    this.getcoursecategory();
    this.getcoursecategoryWebinar();
    this.getboardfield();
    this.getsubjectfield();
    this.getlanguage();
    this.getgrade();
    this.cities();
    this.tagfield();
    
    this.meetingForm = this.formBuilder.group({
      id:null,
      meetingName: ['', Validators.required],
      meetingId: [meetingId, Validators.required],
      ScheduleDate: ['', Validators.required],
      // endDateschedule: ['', Validators.required],
      // meetingCategory: [''],
      // meetingType: ['', Validators.required],
      tag: [''],
      fullName: ['', Validators.required],
      meetduration: [''],
      grouplevel:[''],
      meetdescription: [''],
      occurance: [''],
      moderatorpassword: [''],
      attendeespassword: [''],
      city: [''],
      meetpricetype: [''],
      meetamount: [''],
      upload_name: [null],
      fileupload: [''],

      // new Filed Added
      // courseGrade: ['', Validators.required],
      // courseSubject: ['', Validators.required],
      courseLanguage:['', Validators.required],
      courseCategory: ['', Validators.required],
      courseBoard: ['', Validators.required],
      // courseModule:['', Validators.required],
      // courseTemplate:['', Validators.required],
      // courseTopic: ['', Validators.required],

      // name:null
    });


    var urlArray = window.location.href.split('/');
      this.rest.getMeetingbyID(urlArray[urlArray.length-1]).subscribe((result) => {
      if(result.data.length!=0){
        this.editData =result.data[0];
         this.isEdit = this.editData._id ?true:false;
        this.meetingForm.patchValue({ courseCategory:  this.editData.categoryID._id});
        // this.meetingForm.patchValue({ courseGrade:  this.editData.gradeID._id});
        // this.meetingForm.patchValue({ courseSubject:  this.editData.subjectID._id});
        this.meetingForm.patchValue({ courseLanguage:  this.editData.languageID._id});
        this.meetingForm.patchValue({ courseBoard:  this.editData.boardID._id});

        this.meetingForm.patchValue({ meetingName:  this.editData.name});
        this.meetingForm.patchValue({ meetingId:  this.editData.meetingID});
        this.meetingForm.patchValue({ ScheduleDate:  this.editData.ScheduleDate});
        this.meetingForm.patchValue({ meetingType:  this.editData.meetingType});
        //this.meetingForm.patchValue({ tag: this.editData.tag == "" ? '' : JSON.stringify(this.editData.tag)});
        this.selecttag  = this.editData.tag == "" ? '' : JSON.parse(this.editData.tag);
        this.meetingForm.patchValue({ fullName:  this.editData.fullName});
        this.meetingForm.patchValue({ language:  this.editData.Languages});
        this.groupname  = this.editData[0].groupname
        this.meetingForm.patchValue({id:this.editData._id})
        this.meetingForm.patchValue({ meetduration:  this.editData.Duration});
        this.meetingForm.patchValue({ meetdescription:  this.editData.meetingdescription});
        this.meetingForm.patchValue({ occurance:  this.editData.occurance});
        this.meetingForm.patchValue({ city:  this.editData.city});
        this.selectedFileNmae = this.editData.originalname == '' ? '' : this.editData.originalname;
        this.meetingForm.patchValue({ meetpricetype:  this.editData.meetingPriceType});
        this.isamount = this.editData.meetingPriceType =="Premium" ? true : false;
        this.meetingForm.patchValue({ meetamount:  this.editData.PayableAmount});
        this.meetingForm.patchValue({ id:  this.editData._id});
        this.meetingForm.patchValue({ fileupload:  this.editData.fileupload});
        this.meetingForm.patchValue({ name:  this.editData.name});
        var image = this.editData.image == ''? 'default image':this.editData.image
        this.selectedFileNmae = this.editData.image.length >40 ? this.editData.image.slice(0,40) : image;
        }
        }, (err) => {
        console.log(err);
      });



  }
  tagfield(){
    this.rest.tagfield().subscribe((result) => {
      this.alltag = result;
      console.log(this.alltag);
    }, (err) => {
    console.log(err);
    });
  }
  backto(){
    this.location.back();
  }
  
  fileChange(event) {
    let fileList: FileList = event.target.files;
    this.meetingForm.patchValue({ upload_name: fileList[0] });
    this.selectedFileNmae = fileList[0].name;
    this.meetingForm.patchValue({ fileupload: this.selectedFileNmae });
    console.log(this.meetingForm.value);
  }
  goBack(){
    this.location.back();
  }


  getcoursecategoryWebinar() {
    this.rest.getCourseCategoryHeader().subscribe((result) => {
      this.coursecategorylistWebinar = result.data;
      if(Object.keys(this.paramData).length == 0 && !this.isEdit){
        this.meetingForm.patchValue({ courseCategory: this.coursecategorylistWebinar[0]._id })
      }
      console.log(this.coursecategorylistWebinar);
    }, (err) => {
    console.log(err);
    });
  }
getcoursecategory() {
  this.rest.getClassCourseCategory().subscribe((result) => {
    this.coursecategorylist = result.data;
    if(this.isfromcoursedetails == true) {
      if(Object.keys(this.paramData).length == 0 && !this.isEdit){
         this.meetingForm.patchValue({ courseCategory: this.categoryselectedValue })
       }
       this.isfromcoursedetailsgetmodule();
    }
    else {
      if(Object.keys(this.paramData).length == 0 && !this.isEdit){
        // this.createForm.patchValue({ courseCategory: "" })
       }
    }
  
  }, (err) => {
  console.log(err);
  });
}

  getboardfield(){
    this.rest.boardfield().subscribe((result) => {
      this.courseboardlist = result.data;
      if(Object.keys(this.paramData).length == 0 && !this.isEdit){
        this.meetingForm.patchValue({ courseBoard: this.courseboardlist[0]._id })
      }
      console.log(this.courseboardlist);
    }, (err) => {
    console.log(err);
    });
  }

  getsubjectfield(){
    this.rest.subjectfield().subscribe((result) => {
      this.coursesubjectlist = result.data;
      if(Object.keys(this.paramData).length == 0 && !this.isEdit) {
        this.meetingForm.patchValue({ courseSubject: this.coursesubjectlist[0]._id })
      }
      console.log(this.coursesubjectlist);
    }, (err) => {
    console.log(err);
    });
  }

  getlanguage(){
    this.rest.languagefield().subscribe((result) => {
      this.languageapi = result.data;
      if(Object.keys(this.paramData).length == 0 && !this.isEdit){
        this.meetingForm.patchValue({ courseLanguage: this.languageapi[0]._id })
      }
      console.log(this.languageapi);
    }, (err) => {
    console.log(err);
    });
  }

  getgrade(){
    this.rest.grade().subscribe((result) => {
      this.gradeapi = result.data;
      if(Object.keys(this.paramData).length == 0 && !this.isEdit){
        this.meetingForm.patchValue({ courseGrade: this.gradeapi[0]._id })
      }
    }, (err) => {
    console.log(err);
    });
  }

  cities(){
    this.rest.cities().subscribe((result) => {
      // this.city = result;
    }, (err) => {
    console.log(err);
    });
  }

  selectAmount(event: any) {
    var selected = event.target.options[event.target.selectedIndex].text;
    if(selected == 'Premium'){
      this.isamount = true;
    }else{
      this.isamount = false;
    }
  }


  // selectClassRoom(event: any) {
  //   var selected1 = event.target.options[event.target.selectedIndex].value;
  //   if(selected1 == 'Classroom'){
  //     this.isClassRoom = true;
  //   }else{
  //     this.isClassRoom = false;
  //   }
  // }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  selectChange (event: any) {
   this.selectedValue = event.target.options[event.target.selectedIndex].text;
  }
  getSelect(item) {
    item.selected = !item.selected;
  }
  chosenYearHandler( normalizedYear: Moment ) {
    const ctrlValue = this.date.value;
    //ctrlValue.year(normalizedYear.year());
     var dateSe = new Date().getFullYear()
    this.date.setValue(normalizedYear);
}

  getTeacherAccount(){
    const webToken = localStorage.getItem('webtoken');
      var req={
      month:this.date.value.getMonth()+1,
      year:this.date.value.getFullYear()
    }
  }
chosenMonthHandler( normalizedMonth: Moment, datepicker: OwlDateTimeComponent<Moment> ) {
    const ctrlValue = this.date.value;
   // ctrlValue.month(normalizedMonth.month());
    var dateSe = new Date().getMonth()
    var dataFormatWith = normalizedMonth;
    this.date.setValue(dataFormatWith);
    // this.getTeacherAccount();
    datepicker.close();
}

  selectcategoryChange (event: any) {
    this.categoryselectedValue = event.target.options[event.target.selectedIndex].value.substring(event.target.options[event.target.selectedIndex].value.indexOf(":") + 1);
    if(this.modulelist.length > 0) {
      this.modulelist = [];
      this.meetingForm.patchValue({ courseModule: "" })
    }
    if(this.templatelist.length > 0) {
      this.templatelist = [];
      this.meetingForm.patchValue({ courseTemplate: "" })
    }
    if(this.topicapi.length > 0) {
      this.topicapi = [];
      this.meetingForm.patchValue({ courseTopic: "" })
    }
    console.log(this.coursecategorylist);
    this.coursecategorylist.forEach(element => {
      if(element._id.replace(/\s/g, "") == this.categoryselectedValue.replace(/\s/g, "")) {
        this.modulelist = element.moduleList;
        console.log(this.modulelist,'dfdfdfdfdfdfdfchecking');
     for (let i = 0; i < this.modulelist.length; i++) {
      this.modulelist[i].text= this.modulelist[i].moduleName;
      this.modulelist[i].id= this.modulelist[i].moduleID;

     }
     console.log('jkjk',  this.modulelist)
         if(Object.keys(this.paramData).length == 0 && !this.isEdit){
        //  this.createForm.patchValue({ courseModule: element.moduleList[0]._id })
         }
      }     
    });   
  }


  isfromcoursedetailsgetmodule() {
    this.coursecategorylist.forEach(element => {
      if(element._id.replace(/\s/g, "") == this.categoryselectedValue.replace(/\s/g, "")) {
        this.modulelist = element.moduleList;
         if(Object.keys(this.paramData).length == 0){
          this.meetingForm.patchValue({ courseModule: this.moduleselectedValue })
         }
      }     
    });   

    this.isfromcoursedetailsgettemplate();
  }

  isfromcoursedetailsgettemplate() {
    var req= {
      moduleID:this.moduleselectedValue.replace(/\s/g, "")
    }
    this.rest.getClassCourseTemplatefromModule(req).subscribe((result) => {
      this.templatelist = result.data.templateList;
      if(Object.keys(this.paramData).length == 0){
        this.meetingForm.patchValue({ courseTemplate:  this.templateselectedValue})
      }
      this.isfromcoursedetailsgettopic();
    }, (err) => {
    console.log(err);
    });
   
  }

  isfromcoursedetailsgettopic() {
    console.log("templatelist", this.templatelist);
    this.templatelist.forEach(element => {
      if(element._id.replace(/\s/g, "") == this.templateselectedValue.replace(/\s/g, "")) {
        this.topicapi = element.topicList;
         if(Object.keys(this.paramData).length == 0 && !this.isEdit){
          this.meetingForm.patchValue({ courseTopic: this.topicselectedValue })
         }
      }     
    });
  }

  editmodulelist() {
    this.coursecategorylist.forEach(element => {
      if(element._id.replace(/\s/g, "") == this.categoryselectedValue.replace(/\s/g, "")) {
        this.modulelist = element.moduleList;
         if(Object.keys(this.paramData).length == 0 && !this.isEdit){
          this.meetingForm.patchValue({ courseModule: this.moduleselectedValue })
         }
      }     
    });  
  }

  selectmoduleChange (event: any) {

    // this.moduleselectedValue = event.target.value;
    // console.log('checkingselectmodulechange');
    // console.log(event,'fdfdfdf');
    this.moduleselectedValue = event;
    // console.log(event.value,'ff')
    // this.moduleselectedValue = event.target.options[event.target.selectedIndex].value.substring(event.target.options[event.target.selectedIndex].value.indexOf(":") + 1);
   if(this.templatelist.length > 0) {
      this.templatelist = [];
      this.meetingForm.patchValue({ courseTemplate: "" })
    }
    if(this.topicapi.length > 0) {
      this.topicapi = [];
      this.meetingForm.patchValue({ courseTopic: "" })
    }
    var req= {
      moduleID:this.moduleselectedValue.replace(/\s/g, "")
    }
    console.log("modulechange", req);
    this.rest.getClassCourseTemplatefromModule(req).subscribe((result) => {
      this.templatelist = result.data.templateList;
      console.log("template",this.templatelist);
      if(Object.keys(this.paramData).length == 0 && !this.isEdit){
       // this.createForm.patchValue({ courseCategory: "" })
      }
    }, (err) => {
    console.log(err);
    });
     
  }

  edittemplatelist() {  
    var req= {
      moduleID:this.moduleselectedValue.replace(/\s/g, "")
    }
    this.rest.getClassCourseTemplatefromModule(req).subscribe((result) => {
      this.templatelist = result.data.templateList;
      console.log(result.data.templateList);
      if(Object.keys(this.paramData).length == 0 && !this.isEdit){
        this.meetingForm.patchValue({ courseTemplate: this.templateselectedValue })
      }
      this.edittopiclist();
    }, (err) => {
    console.log(err);
    });
  }

  selecttemplateChange (event: any) {
    this.templateselectedValue = event.target.options[event.target.selectedIndex].value.substring(event.target.options[event.target.selectedIndex].value.indexOf(":") + 1);
   if(this.topicapi.length > 0) {
      this.topicapi = [];
      this.meetingForm.patchValue({ courseTopic: "" })
    }
    this.templatelist.forEach(element => {
      if(element._id.replace(/\s/g, "") == this.templateselectedValue.replace(/\s/g, "")) {
        this.topicapi = element.topicList;
        console.log("topic",this.topicapi);
         if(Object.keys(this.paramData).length == 0 && !this.isEdit){
        //  this.createForm.patchValue({ courseModule: element.moduleList[0]._id })
         }
      }     
    });   
  }
  
  selecttopicChange(event: any) {
    this.topicselectedValue = event.target.options[event.target.selectedIndex].value.substring(event.target.options[event.target.selectedIndex].value.indexOf(":") + 1);
  
  }
  edittopiclist() {
    // if(this.topicapi.length > 0) {
    //   this.topicapi = [];
    //   this.createForm.patchValue({ courseTopic: "" })
    // }
    this.templatelist.forEach(element => {
      if(element._id.replace(/\s/g, "") == this.templateselectedValue.replace(/\s/g, "")) {
        this.topicapi = element.topicList;
         if(Object.keys(this.paramData).length == 0 && !this.isEdit){
          this.meetingForm.patchValue({ courseTopic: this.topicselectedValue })
         }
      }     
    }); 
  }
  get f() { return this.meetingForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.meetingForm.invalid) {
      return;
    }
    const data = this.meetingForm.value;
    console.log(data);
    if(data.meetpricetype == "Premium"){
      if(data.meetamount == 0){
        this.toastr.errorToastr('Amount Should be greater than 0');
        return false;
      }else if(data.meetamount == ""){
        this.toastr.errorToastr('Enter Meeting amount');
        return false;
      }
    }else{
      data.meetamount = 0;
    }

    if(data.meetingType =="Select Session Type"){
      this.toastr.errorToastr('Please choose Type');
      return false;
    }
    if(data.language == 'Select Language'){
      data.language = '';
    }
    if(data.meetduration == 'Select Duration of Session'){

      data.meetduration = '';
    }
    if(data.meetdescription == undefined){
      data.meetdescription = '';
    }
    // if(data.occurance == "Select Re-occurance"){
    //   data.occurance = '';
    // }
    if(data.city == undefined || data.city.length == 0){
      data.city = '';
    }
    // var meetingCategoryName =this.selectedValue;

    var groupname = localStorage.getItem('groupname')
    if(data.upload_name!=null){

      this.formData = new FormData();
      this.formData.append("upload_name", data.upload_name, data.upload_name.name);
      this.formData.append("meetingName", data.meetingName);
      // New field Start
      this.formData.append("categoryID", data.courseCategory);
      this.formData.append("languageID", data.courseLanguage);
      // this.formData.append("subjectID", data.courseSubject);
      this.formData.append("boardID", data.courseBoard);
      // this.formData.append("gradeID", data.courseGrade);
      // New Field ENd

      this.formData.append("city", data.city);
      this.formData.append("occurance", data.occurance);
      this.formData.append("Duration", data.meetduration);
      this.formData.append("meetingdescription", data.meetdescription);
      this.formData.append("meetingId", data.meetingId);
      this.formData.append("ScheduleDate", data.ScheduleDate);

      
      this.formData.append("fullName", data.fullName);
      this.formData.append("meetingType", 'Classroom');
      this.formData.append("meetingPriceType", data.meetpricetype);
      this.formData.append("PayableAmount", data.meetamount);
      this.formData.append("name", data.meetingName);
      this.formData.append("tag",data.tag =="" || data.tag.length ==0  ? '' : JSON.stringify(data.tag));
      this.formData.append("originalname",this.selectedFileNmae);
      this.formData.append("groupname",'Classroom');

      // this.formData.append("groupname", data.meetingType == 'Group' ? groupname : '');
      console.log(this.formData);
    }else{
      this.formData = {

        categoryID:data.courseCategory,
        languageID:data.courseLanguage,
        // subjectID:data.courseSubject,
        boardID:data.courseBoard,
        // gradeID:data.courseGrade,
        meetingName     :  data.meetingName,
        originalname:this.selectedFileNmae,
        name : data.meetingName,
        meetingId     : data.meetingId,
        ScheduleDate : data.ScheduleDate,
        fullName        : data.fullName,
        // meetingCategory : data.meetingCategory,
        meetingPriceType:data.meetpricetype,
        PayableAmount:data.meetamount,
        meetingType     : 'Classroom',
        tag     :  data.tag =="" || data.tag.length ==0  ? '' : JSON.stringify(data.tag),
        // groupname       : data.meetingType == 'Group' ? groupname : '',
        groupname : data.meetingType,
        // meetingCategoryName     : meetingCategoryName,
        Languages : data.language,
        city  : data.city,
        occurance : data.occurance,
        Duration  : data.meetduration,
        meetingdescription   : data.meetdescription
      };
    }
    const webToken = localStorage.getItem('webtoken');
     this.SpinnerService.show()

      if(!this.editData._id){
        
      this.rest.createMeeting(this.formData, webToken).subscribe((result) => {

        console.log('resultCreateMeeting', result);

        this.SpinnerService.hide()
       if(result.status) {
         this.toastr.successToastr('Hooray! Your Class has been created');
        this.addToCalendar(result.data[0], webToken);



        this.backto()
        } else {
       }
      }, (err) => {
      console.log(err);
      });
    }else{
      this.rest.editMeeting(this.formData,webToken).subscribe((response) => {
        this.SpinnerService.hide();
        if(response.status){
          this.toastr.successToastr('Hooray......! Your Updated has been created');
          this.backto()
        }else {
          this.toastr.errorToastr(response.message);
        }
      });
    }





  }
 addToCalendar(data,webToken){
    var req={
      myLifeListDatepick:moment(data.ScheduleDate).format('YYYY-MM-DD'),
      myLifeListEventType: "Class",
      myLifeListTimepick:moment(data.ScheduleDate).format('HH:mm:ss'),
      setReminder: 0,
      todoEventType: "Class",
      meetingType:data.meetingType,
      todoSchedule: data.ScheduleDate,
      todoSetReminder: "",
      todosetReminderValue: "",
      sessionName:data.name,
      meetingId : data._id,
      myLifeListTitle:data.meetingType,
      sessionId:data.meetingID,
      profileType:'teacher'
    };
    this.SpinnerService.show()
    this.rest.add_myLife_NewData(req,webToken).subscribe((result) => {
      this.SpinnerService.hide()
      if(result.status){
        this.toastr.successToastr("Class Added to your Calender");
        if(!this.editData._id){
          this.router.navigate(['/classRoomList']);
        }else{
          this.location.back();
        }
      }else{
        this.toastr.errorToastr(result.message);
      }

    });
  }

}
