<div class="container">
    <div class="section-title">
        <h2 style="color: white; margin-top: 5%;">Why buddi?</h2>
        </div>
        <!-- <span class="sub-title">Make Connections</span> -->
<div class="row">
<div class="col-lg-4 col-md-4 col-6">
    <div class="single-funfact">
        <div class="icon">
            <i class="fas fa-tv"></i>
          
        </div>
        <h3>800+ </h3>
        <!-- <h3 class="odometer" data-count="800">00</h3> -->
        <p>Courses</p>
    </div>
</div>

<div class="col-lg-4 col-md-4 col-6">
    <div class="single-funfact">
        <div class="icon">
            <i class="fas fa-chalkboard-teacher"></i>
        </div>
        <!-- <h3 class="odometer" data-count="100">00</h3> -->
        <h3>20,000+</h3>
        <p>Tutors</p>
    </div>
</div>

<div class="col-lg-4 col-md-4 col-6">
    <div class="single-funfact">
        <div class="icon">
            <i class="fas fa-book-reader"></i>
        </div>
         <h3>1,00,000+</h3>
        <p>Learners</p>
    </div>
</div>

<!-- <div class="col-lg-3 col-md-3 col-6">
    <div class="single-funfact">
        <div class="icon">
            <i class="fas fa-dollar-sign"></i>
        </div>
        <h3 class="odometer" data-count="14000">00</h3>
        <p>USD Revenue Last Year</p>
    </div>
</div> -->
</div>
</div>