<div *ngIf="isstudent">
    <app-header-style-one></app-header-style-one>
</div>
<div *ngIf="isteacher">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>

<section class="my-dashboard-area ptb-100">
    <div class="container">
        <div class="myDashboard-content">
            <form class="edit-account" [formGroup]="examcreateform" (ngSubmit)="onSubmit(examcreateform)">
                <div class = "row">
                    <div class="col-lg-8 col-md-8 col-sm-8">
                        <h3 style="margin-bottom: 5%;">Create Exam</h3>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <a class="default-btn" routerLink="/teacher/mydashboard" style="float:right;border-radius: 5px;"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Back</span><i class="bx bx-paper-plane icon-arrow after"></i></a>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Exam Full Name <span class="required" style="color: red;">*</span></label>
                            <input type="text" maxlength="100" class="form-control" value="" formControlName="fullname"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && f.fullname.errors }">
                            <div *ngIf="submitted && f.fullname.errors" class="invalid-feedback">
                                <div *ngIf="f.fullname.errors?.required">Exam Full Name required</div>
                            </div>
                            <div *ngIf="isexammeexists==true" >
                                <p style="color: red;">Exam Full Name Already Exists</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Exam Short Name <span class="required" style="color: red;">*</span></label>
                            <input type="text" maxlength="100" class="form-control" value="" formControlName="shortname"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && f.shortname.errors }">
                            <div *ngIf="submitted && f.shortname.errors" class="invalid-feedback">
                                <div *ngIf="f.shortname.errors.required">Exam Short Name required</div>
                            </div>
                            <div *ngIf="isshortnameexists==true" >
                                <p style="color: red;">Exam Short Name Already Exists</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6" *ngIf="coursePrivacyapi.length !=0">
                        <div class="form-group">
                            <label>Privacy <span class="required" style="color: red;">*</span></label>
                            <select class="form-control col-md-12" formControlName="coursePrivacy"
                                (change)="selectedprivacy($event)">
                                <option value="" disabled>Select privacy Type</option>
                                <option *ngFor="let obj of coursePrivacyapi" [ngValue]="obj.name">{{obj.code}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-6 description">
                        <div class="form-group  col-md-12" style="padding-top: 7%;" *ngIf="isgroupname">

                            School Name ({{groupname}})

                            <span *ngIf="isEnterprise !=''"> School Name : {{isEnterprise}}</span>

                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6" *ngIf="coursecategorylist.length !=0">
                        <div class="form-group">
                            <label>Category <span class="required" style="color: red;">*</span></label>
                            <select class="form-control col-md-12" (change)="selectedcategory($event)"
                                class="form-control" formControlName="courseCategory">
                                <option value="" disabled>Select category</option>
                                <option *ngFor="let obj of coursecategorylist" [ngValue]="obj._id">{{obj.categoryName}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6" *ngIf="gradeapi.length !=0">
                        <div class="form-group" *ngIf="isselected">
                            <label>Grade <span class="required" style="color: red;">*</span></label>
                            <select class="form-control col-md-12" class="form-control" formControlName="courseGrade">
                                <option value="" disabled>Select Grade</option>
                                <option *ngFor="let obj of gradeapi" [value]="obj._id">{{obj.gradeName}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6" *ngIf="gradeapi.length !=0">
                        <div class="form-group">
                            <label>Board <span class="required" style="color: red;">*</span></label>
                            <select class="form-control col-md-12" formControlName="courseBoard"
                                [ngClass]="{ 'invalid-select': submitted && courseBoard =='Select board' }">
                                <option value="" disabled>Select board</option>
                                <option *ngFor="let obj of courseboardlist" [value]="obj._id">{{obj.boardName}}</option>
                            </select>
                            <div *ngIf="submitted && courseBoard =='Select board'" class="invalid-error-span"
                                style="color: #dc3545;font-size: 80%;">
                                Board is required
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6" *ngIf="coursesubjectlist.length !=0">
                        <div class="form-group">
                            <label>Subject <span class="required" style="color: red;">*</span></label>
                            <select class="form-control col-md-12" formControlName="courseSubject"
                                [ngClass]="{ 'invalid-select': submitted && courseSubject =='Select subject' }">
                                <option value="" disabled>Select subject</option>
                                <option *ngFor="let obj of coursesubjectlist" [value]="obj._id">{{obj.subjectName}}
                                </option>
                            </select>
                            <div *ngIf="submitted && courseSubject =='Select subject'" class="invalid-error-span"
                                style="color: #dc3545;font-size: 80%;">
                                Subject is required
                            </div>

                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Start Date <span class="required" style="color: red;">*</span></label>
                            <input type="text" class="form-control" formControlName="startdateschedule" [ngClass]="{ 'is-invalid': submitted && f.startdateschedule.errors }"
                                [owlDateTimeTrigger]="dtdp" [owlDateTime]="dtdp" />
                                <div *ngIf="submitted && f.startdateschedule.errors" class="invalid-feedback">
                                    <div *ngIf="f.startdateschedule.errors.required">Start Date Required</div>
                                </div>

                            <span>
                                <owl-date-time #dtdp [hour12Timer]="true"></owl-date-time>
                            </span>
                            <div *ngIf="isstartdate==true" >
                                <p style="color: red;">Exam Start Date time Must be Greater than current Date time</p>
                            </div>

                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>End Date </label>
                            <input type="text" class="form-control" formControlName="endDateschedule" [ngClass]="{ 'is-invalid': submitted && f.endDateschedule.errors }"
                            [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1" />
                            <div *ngIf="submitted && f.endDateschedule.errors" class="invalid-feedback">
                                <div *ngIf="f.endDateschedule.errors.required">End Date Required</div>
                            </div>
                            <span>
                                <owl-date-time #dt1 [hour12Timer]="true"></owl-date-time>
                            </span>

                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Image <span class="required" style="color: red;">*</span></label>
                            <div class="input-group">
                                <div class="custom-file">
                                    <input type="hidden" formControlName="fileupload" name="" value="">
                                    <input type="file" [ngClass]="{ 'is-invalid': submitted && f.fileupload.errors}"
                                        class="custom-file-input" accept="image/*" (change)="fileChange($event)"
                                        id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" accept="image/*">
                                    <label class="custom-file-label" for="inputGroupFile01">{{selectedFileNmae}}</label>
                                </div>
                                <div *ngIf="submitted && f.fileupload.errors" class="invalid-feedback">
                                    <div *ngIf="f.fileupload.errors.required">Image is required</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6" *ngIf="languageapi.length !=0">
                        <div class="form-group">
                            <label>Language <span class="required" style="color: red;">*</span></label>
                            <select class="form-control col-md-12" class="form-control"
                                formControlName="courseLanguage">
                                <!-- <option value="Select category">Select category</option> -->
                                <option *ngFor="let obj of languageapi" [ngValue]="obj._id">{{obj.languageName}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <label>Description </label>
                            <input type="text" maxlength="200" class="form-control" value="" formControlName="courseDescription"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.courseDescription.errors }">
                            <div *ngIf="submitted && f.courseDescription.errors" class="invalid-feedback">
                                <div *ngIf="f.courseDescription.errors.required">Exam Description required</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Exam Level <span class="required" style="color: red;">*</span></label>
                            <select class="form-control col-md-12" class="form-control" formControlName="examlevel">
                                <option value="Select category" disabled>Select Exam Level</option>
                                <option *ngFor="let obj of examlevelapi" [value]="obj.name">{{obj.name}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Certification <span class="required" style="color: red;">*</span></label>
                            <select class="form-control col-md-12" class="form-control" formControlName="Certification">
                                <option value="" disabled>Select Certification </option>
                                <option *ngFor="let obj of Certificationapi" [value]="obj.name">{{obj.name}}</option>                     
                            </select>
                        </div>                      
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Pricing Type <span class="required" style="color: red;">*</span></label>
                            <select class="form-control col-md-12" (change)="selectpricing($event)" class="form-control"
                                formControlName="exampricing">
                                <option value="Select Pricing Type" disabled>Select Pricing Type</option>
                                <option *ngFor="let obj of exampricingapi" [value]="obj.name">{{obj.name}}</option> 
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group" *ngIf="iscost">
                            <label>Exam Cost (in Rupees) <span class="required" style="color: red;">*</span></label>
                            <input type="number" class="form-control" value="" formControlName="Cost"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Cost.errors }">
                            <div *ngIf="submitted && f.Cost.errors" class="invalid-feedback">
                                <div *ngIf="f.Cost.errors?.required">Cost required</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Exam Duration (in Minutes) <span class="required" style="color: red;">*</span></label>
                            <input type="number" maxlength="3" class="form-control" value="" formControlName="examlength"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && f.examlength.errors }">
                            <div *ngIf="submitted && f.examlength.errors" class="invalid-feedback">
                                <div *ngIf="f.examlength.errors?.required">Exam Duration required</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Number of Questions <span class="required" style="color: red;">*</span></label>
                            <input type="number" maxlength="3" class="form-control" value="" formControlName="questions"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && f.questions.errors }">
                            <div *ngIf="submitted && f.questions.errors" class="invalid-feedback">
                                <div *ngIf="f.questions.errors?.required">Number of Questions Required</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Total Score <span class="required" style="color: red;">*</span></label>
                            <input type="number" maxlength="3" class="form-control" value="" formControlName="score"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && f.score.errors }">
                            <div *ngIf="submitted && f.score.errors" class="invalid-feedback">
                                <div *ngIf="f.score.errors?.required">Total Score Required</div>
                            </div>
                        </div>
                    </div>


                    <div class="col-lg-12 col-md-12">
                        <button type="submit" class="default-btn"><i class='bx bx-save icon-arrow before'></i><span
                                class="label">Create Exam</span><i class="bx bx-save icon-arrow after"></i></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>