
<div class="row p-2">
    <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12">
        <div class="card shadow borderR_10">
          <div class="body">
            <div class="row align-items-center p-3">
                <div class="col-md-4 mb-0">
                  <canvas baseChart style="display: block;width: 100%" class="chart" [data]="doughnutChartData"
                    [labels]="doughnutChartLabels" [options]="doughnutChartOptions" [chartType]="doughnutChartType"
                    [colors]="doughnutChartColors"></canvas>
                </div>
                <div class="col-md-8">
                <h6>
                  <div class="weight-600 col-pink pt-1">Good Job, Sarah. Keep Going!! </div>
                </h6>
                <p class="font-13 max-width-600">  task are 80% completed this week. Keep it up and improve your
                  result. Progress is very good!!!</p>
                <div class="row font-13">
                  <div class="col-lg-6">
                    <div><i class="fa fa-circle dot1 mr-2"></i> Development - 1 hour 50 Mins</div>
                  </div>
                  <div class="col-lg-6">
                    <div><i class="fa fa-circle dot2 mr-2"></i> Java Class - 50 Mins</div>
                  </div>
                  <div class="col-lg-6">
                    <div><i class="fa fa-circle dot3 mr-2"></i> painting - 1 hour 20 Mins</div>
                  </div>
                  <div class="col-lg-6">
                    <div><i class="fa fa-circle dot4 mr-2"></i> Geography Class - 30 Mins</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <div class="card l-bg-purple text-white p-4 mt-1 mb-3 shadow borderR_10">
          <div class="info d-flex justify-content-between align-items-center">
            <div class="d-flex flex-column text-white font-22"> <span>English Vocabulary Test</span>
              <small class="mt-2"><i class="far fa-clock mr-2"></i> 25, Feb, 2020. 04:00 PM</small> </div>
          </div>
        </div>
        <div class="card l-bg-green text-white p-3 shadow borderR_10" >
          <div class="info d-flex justify-content-between align-items-center">
            <div class="d-flex flex-column text-white font-22"> <span>Science Homework Submition</span>
              <small class="mt-2"><i class="far fa-clock mr-2"></i> 28, Feb, 2020. 10:00 AM</small> </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class= "row p-2">
        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
            <div class="card shadow borderR_10">
              <div class="header text-center pt-2">
                <h6>Your Progress</h6>
              </div>
              <div class="tableBody p-0 font-12">
                <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>Subject</th>
                        <th>Progress</th>
                        <th>Duration</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Chemistry</td>
                        <td>30%<mat-progress-bar mode="determinate" value="30" color="warn"></mat-progress-bar>
                        </td>
                        <td>2 Months</td>
                      </tr>
                      <tr>
                        <td>Chapter1</td>
                        <td>55%<mat-progress-bar mode="determinate" value="55"></mat-progress-bar>
                        </td>
                        <td>3 Months</td>
                      </tr>
                      <tr>
                        <td>Chapter2</td>
                        <td>67%<mat-progress-bar mode="determinate" value="67"></mat-progress-bar>
                        </td>
                        <td>1 Months</td>
                      </tr>
                      <tr>
                        <td>Chapter3</td>
                        <td>70%<mat-progress-bar mode="determinate" value="70"></mat-progress-bar>
                        </td>
                        <td>2 Months</td>
                      </tr>
                      <tr>
                        <td>Chapter4</td>
                        <td>24%<mat-progress-bar mode="determinate" value="24" color="warn"></mat-progress-bar>
                        </td>
                        <td>3 Months</td>
                      </tr>
                   
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
            <div class="card shadow borderR_10">
                <div class="header text-center pt-2">
                    <h6>Time Spent On Learning</h6>
                </div>
                <div class="body">
                    <apx-chart [series]="barChartOptions.series" [chart]="barChartOptions.chart"
                        [dataLabels]="barChartOptions.dataLabels" [plotOptions]="barChartOptions.plotOptions"
                        [responsive]="barChartOptions.responsive" [xaxis]="barChartOptions.xaxis"
                        [legend]="barChartOptions.legend" [fill]="barChartOptions.fill">
                    </apx-chart>
                </div>
            </div>
        </div>


        
        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
            <div class="card shadow borderR_10">
              <div class="header p-2 text-center">
                <h6>
                  Upcomming Class
                </h6>
              </div>
              <div class="card-body font-12">
                <ul class="list-unstyled user-progress list-unstyled-border list-unstyled-noborder"
                  style="position: relative; max-height: 330px;" [perfectScrollbar]>
                  <li class="lecture-list">
                    <img alt="image" class="mr-3 rounded-circle" width="40" src="assets/img/user/usrbig1.jpg">
                    <div class="media-body">
                      <div class="media-title font-16">Cara Stevens</div>
                      <div class="text-job text-muted mb-0">Mathematics</div>
                    </div>
                    <div class="media-body">
                      <div class="media-title font-16">12 June '20</div>
                      <div class="text-job text-muted mb-0">09:00-10:00</div>
                    </div>
                  </li>
                  <li class="lecture-list">
                    <img alt="image" class="mr-3 rounded-circle" width="40" src="assets/img/user/usrbig1.jpg">
                    <div class="media-body">
                      <div class="media-title font-16">Airi Satou</div>
                      <div class="text-job text-muted mb-0">Computer Studies</div>
                    </div>
                    <div class="media-body">
                      <div class="media-title font-16">13 June '20</div>
                      <div class="text-job text-muted mb-0">11:00-12:00</div>
                    </div>
                  </li>
                  <li class="lecture-list">
                    <img alt="image" class="mr-3 rounded-circle" width="40" src="assets/img/user/usrbig1.jpg">
                    <div class="media-body">
                      <div class="media-title font-16">Jens Brincker</div>
                      <div class="text-job text-muted mb-0">Geography</div>
                    </div>
                    <div class="media-body">
                      <div class="media-title font-16">15 June '20</div>
                      <div class="text-job text-muted mb-0">09:30-10:30</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>


          <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
            <div class="card shadow borderR_10">
              <div class="header text-center pt-2">
                <h6>Your Progress</h6>
              </div>
              <div class="tableBody p-0 font-12">
                <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>Subject</th>
                        <th>Progress</th>
                        <th>Duration</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Physics</td>
                        <td>30%<mat-progress-bar mode="determinate" value="30" color="warn"></mat-progress-bar>
                        </td>
                        <td>2 Months</td>
                      </tr>
                      <tr>
                        <td>Chapter1</td>
                        <td>55%<mat-progress-bar mode="determinate" value="55"></mat-progress-bar>
                        </td>
                        <td>3 Months</td>
                      </tr>
                      <tr>
                        <td>Chapter2</td>
                        <td>67%<mat-progress-bar mode="determinate" value="67"></mat-progress-bar>
                        </td>
                        <td>1 Months</td>
                      </tr>
                      <tr>
                        <td>Chapter3</td>
                        <td>70%<mat-progress-bar mode="determinate" value="70"></mat-progress-bar>
                        </td>
                        <td>2 Months</td>
                      </tr>
                     
                   
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
            <div class="card shadow borderR_10">
                <div class="header text-center pt-2">
                    <h6>Time Spent On Learning</h6>
                </div>
                <div class="body">
                    <apx-chart [series]="barChartOptions.series" [chart]="barChartOptions.chart"
                        [dataLabels]="barChartOptions.dataLabels" [plotOptions]="barChartOptions.plotOptions"
                        [responsive]="barChartOptions.responsive" [xaxis]="barChartOptions.xaxis"
                        [legend]="barChartOptions.legend" [fill]="barChartOptions.fill">
                    </apx-chart>
                </div>
            </div>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
        </div>

      



        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
          <div class="card shadow borderR_10">
            <div class="header text-center pt-2">
              <h6>Your Progress</h6>
            </div>
            <div class="tableBody p-0 font-12">
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Subject</th>
                      <th>Progress</th>
                      <th>Duration</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Maths</td>
                      <td>30%<mat-progress-bar mode="determinate" value="30" color="warn"></mat-progress-bar>
                      </td>
                      <td>2 Months</td>
                    </tr>
                    <tr>
                      <td>Chapter1</td>
                      <td>55%<mat-progress-bar mode="determinate" value="55"></mat-progress-bar>
                      </td>
                      <td>3 Months</td>
                    </tr>
                    <tr>
                      <td>Chapter2</td>
                      <td>67%<mat-progress-bar mode="determinate" value="67"></mat-progress-bar>
                      </td>
                      <td>1 Months</td>
                    </tr>
                    <tr>
                      <td>Chapter3</td>
                      <td>70%<mat-progress-bar mode="determinate" value="70"></mat-progress-bar>
                      </td>
                      <td>2 Months</td>
                    </tr>
                   
                 
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
          <div class="card shadow borderR_10">
              <div class="header text-center pt-2">
                  <h6>Time Spent On Learning</h6>
              </div>
              <div class="body">
                  <apx-chart [series]="barChartOptions.series" [chart]="barChartOptions.chart"
                      [dataLabels]="barChartOptions.dataLabels" [plotOptions]="barChartOptions.plotOptions"
                      [responsive]="barChartOptions.responsive" [xaxis]="barChartOptions.xaxis"
                      [legend]="barChartOptions.legend" [fill]="barChartOptions.fill">
                  </apx-chart>
              </div>
          </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
      </div>
      


      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
        <div class="card shadow borderR_10"><div class="progress">
          <div class="progress-bar bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
          <div class="tableBody p-0 font-12">
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>Subject</th>
                    <th>Progress</th>
                    <th>Duration</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Biology</td>
                    <td>30%<mat-progress-bar mode="determinate" value="30" color="warn"></mat-progress-bar>
                    </td>
                    <td>2 Months</td>
                  </tr>
                  <tr>
                    <td>Chapter1</td>
                    <td>55%<mat-progress-bar mode="determinate" value="55"></mat-progress-bar>
                    </td>
                    <td>3 Months</td>
                  </tr>
                  <tr>
                    <td>Chapter2</td>
                    <td>67%<mat-progress-bar mode="determinate" value="67"></mat-progress-bar>
                    </td>
                    <td>1 Months</td>
                  </tr>
                  <tr>
                    <td>Chapter3</td>
                    <td>70%<mat-progress-bar mode="determinate" value="70"></mat-progress-bar>
                    </td>
                    <td>2 Months</td>
                  </tr>
                 
               
                </tbody>
              </table>
            </div>
          </div>
        </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
        <div class="card shadow borderR_10">
            <div class="header text-center pt-2">
                <h6>Time Spent On Learning</h6>
            </div>
            <div class="body">
                <apx-chart [series]="barChartOptions.series" [chart]="barChartOptions.chart"
                    [dataLabels]="barChartOptions.dataLabels" [plotOptions]="barChartOptions.plotOptions"
                    [responsive]="barChartOptions.responsive" [xaxis]="barChartOptions.xaxis"
                    [legend]="barChartOptions.legend" [fill]="barChartOptions.fill">
                </apx-chart>
            </div>
        </div>
    </div>
   
    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
      <div class="card shadow borderR_10 setPadding">
        <div class="setPercentage">
        <span class="left">0% </span>
        
        <span class="right">50% </span>
      </div>
        <div class="progress">
         
          <div class="progress-bar bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div class="font-12 mb-1 fontsize">Physical World and Assignment <span> <b>(Physics)</b></span></div>
        <div class="font-12 mb-1 fontsize">Physical World and Assignment <span> <b>(Physics)</b></span></div>
        <div class="font-12 mb-1 fontsize">Physical World and Assignmen<span> <b>(Physics)</b></span></div>
        <div class="font-12 mb-1 fontsize">Some Baic Concepts Chemistry <span> <b>(Chemistry)</b></span></div>
        <div class="font-12 mb-1 fontsize">Diversity in world <span> <b>(Biology)</b></span></div>
      </div>

    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
      <div class="card shadow borderR_10 setPadding">
        <div class="setPercentage">
        <span class="left">0% </span>
        
        <span class="right">50% </span>
      </div>
        <div class="progress">
         
          <div class="progress-bar bg-info" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div class="font-12 mb-1 fontsize">Physical World and Assignment <span> <b>(Physics)</b></span></div>
        <div class="font-12 mb-1 fontsize">Physical World and Assignment <span> <b>(Physics)</b></span></div>
        <div class="font-12 mb-1 fontsize">Physical World and Assignmen<span> <b>(Physics)</b></span></div>
        <div class="font-12 mb-1 fontsize">Some Baic Concepts Chemistry <span> <b>(Chemistry)</b></span></div>
        <div class="font-12 mb-1 fontsize">Diversity in world <span> <b>(Biology)</b></span></div>
      </div>

    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
      <div class="card shadow borderR_10 setPadding">
        <div class="setPercentage">
        <span class="left">0% </span>
        
        <span class="right">50% </span>
      </div>
        <div class="progress">
         
          <div class="progress-bar bg-warning" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div class="font-12 mb-1 fontsize">Physical World and Assignment <span> <b>(Physics)</b></span></div>
        <div class="font-12 mb-1 fontsize">Physical World and Assignment <span> <b>(Physics)</b></span></div>
        <div class="font-12 mb-1 fontsize">Physical World and Assignmen<span> <b>(Physics)</b></span></div>
        <div class="font-12 mb-1 fontsize">Some Baic Concepts Chemistry <span> <b>(Chemistry)</b></span></div>
        <div class="font-12 mb-1 fontsize">Diversity in world <span> <b>(Biology)</b></span></div>
      </div>

    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
      <div class="card shadow borderR_10 setPadding">
        <div class="setPercentage">
        <span class="left">0% </span>
        
        <span class="right">50% </span>
      </div>
        <div class="progress">
         
          <div class="progress-bar bg-danger" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div class="font-12 mb-1 fontsize">Physical World and Assignment <span> <b>(Physics)</b></span></div>
        <div class="font-12 mb-1 fontsize">Physical World and Assignment <span> <b>(Physics)</b></span></div>
        <div class="font-12 mb-1 fontsize">Physical World and Assignmen<span> <b>(Physics)</b></span></div>
        <div class="font-12 mb-1 fontsize">Some Baic Concepts Chemistry <span> <b>(Chemistry)</b></span></div>
        <div class="font-12 mb-1 fontsize">Diversity in world <span> <b>(Biology)</b></span></div>
      </div>

    </div>
    </div>

    <div class="row clearfix p-2">
        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
          <div class="card">
            <div class="header">
              <h6>Test Results</h6>
            </div>
            <div class="body">
              <apx-chart [series]="areaChartOptions.series" [chart]="areaChartOptions.chart"
                [xaxis]="areaChartOptions.xaxis" [yaxis]="areaChartOptions.yaxis" [colors]="areaChartOptions.colors"
                [stroke]="areaChartOptions.stroke" [legend]="areaChartOptions.legend" [tooltip]="areaChartOptions.tooltip"
                [dataLabels]="areaChartOptions.dataLabels"></apx-chart>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card p-2">
            <div class="header">
              <h6>Assignments</h6>
            </div>
            <div class="body">
              <div class="doc-file-type">
                <ul class="list-unstyled" style="position: relative; max-height: 300px;" [perfectScrollbar]>
                  <li class="media mb-3">
                    <span class="mr-3 align-self-center img-icon primary-rgba text-primary"><i
                        class="far fa-file-word"></i></span>
                    <div class="media-body">
                      <div class="font-12 mb-1">Java Programming</div>
                      <p>.doc, 4.3 MB</p>
                    </div>
                    <div class="media-cta">
                      <td>
                        <i class="far fa-trash-alt pr-3"></i>
                        <i class="far fa-arrow-alt-circle-down"></i>
                      </td>
                    </div>
                  </li>
                  <li class="media mb-3">
                    <span class="mr-3 align-self-center img-icon success-rgba text-success"><i
                        class="far fa-file-excel"></i></span>
                    <div class="media-body">
                      <div class="font-12 mb-1">Angular Theory</div>
                      <p>.xls, 2.5 MB</p>
                    </div>
                    <div class="media-cta">
                      <td>
                        <i class="far fa-trash-alt pr-3"></i>
                        <i class="far fa-arrow-alt-circle-down"></i>
                      </td>
                    </div>
                  </li>
                  <!-- <li class="media mb-3">
                    <span class="mr-3 align-self-center img-icon danger-rgba text-danger"><i
                        class="far fa-file-pdf"></i></span>
                    <div class="media-body">
                      <h5 class="font-12 mb-1">Maths Sums Solution</h5>
                      <p>.pdf, 10.5 MB</p>
                    </div>
                    <div class="media-cta">
                      <td>
                        <i class="far fa-trash-alt pr-3"></i>
                        <i class="far fa-arrow-alt-circle-down"></i>
                      </td>
                    </div>
                  </li> -->
                  <li class="media">
                    <span class="mr-3 align-self-center img-icon info-rgba text-info"><i
                        class="far fa-file-archive"></i></span>
                    <div class="media-body">
                      <div class="font-12 mb-1">Submit Science Journal</div>
                      <p>.zip, 53.2 MB</p>
                    </div>
                    <div class="media-cta">
                      <td>
                        <i class="far fa-trash-alt pr-3"></i>
                        <i class="far fa-arrow-alt-circle-down"></i>
                      </td>
                    </div>
                  </li>
                  <li class="media mb-3">
                    <span class="mr-3 align-self-center img-icon primary-rgba text-primary"><i
                        class="far fa-file-word"></i></span>
                    <div class="media-body">
                      <div class="font-12 mb-1">Marketing Instructions</div>
                      <p>.doc, 5.3 MB</p>
                    </div>
                    <div class="media-cta">
                      <td>
                        <i class="far fa-trash-alt pr-3"></i>
                        <i class="far fa-arrow-alt-circle-down"></i>
                      </td>
                    </div>
                  </li>
                </ul>
                <div class="text-center p-t-20">
                  <button mat-stroked-button color="primary">Report Adverse Effect</button>
                </div>
                <br>
                <br>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row clearfix p-2">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card p-2">
            <div class="header">
              <h6>Notice Board</h6>
            </div>
            <div class="body font-12">
              <div class="recent-comment" style="position: relative; max-height: 370px;" [perfectScrollbar]>
                <div class="notice-board">
                  <div class="table-img">
                    <img class="notice-object" src="assets/img/user/usrbig1.jpg" alt="...">
                  </div>
                  <div class="notice-body">
                    <div class="notice-heading col-green">Airi Satou</div>
                    <p>Lorem ipsum dolor sit amet, id quo eruditi eloquentiam.</p>
                    <small class="text-muted">7 hours ago</small>
                  </div>
                </div>
                <div class="notice-board">
                  <div class="table-img">
                    <img class="notice-object" src="assets/img/user/usrbig1.jpg" alt="...">
                  </div>
                  <div class="notice-body">
                    <div class="notice-heading color-primary col-indigo">Sarah Smith</div>
                    <p>Lorem ipsum dolor sit amet, id quo eruditi eloquentiam.</p>
                    <p class="comment-date">1 hour ago</p>
                  </div>
                </div>
                <div class="notice-board">
                  <div class="table-img">
                    <img class="notice-object" src="assets/img/user/usrbig1.jpg" alt="...">
                  </div>
                  <div class="notice-body">
                    <div class="notice-heading color-danger col-cyan">Cara Stevens</div>
                    <p>Lorem ipsum dolor sit amet, id quo eruditi eloquentiam.</p>
                    <div class="comment-date">Yesterday</div>
                  </div>
                </div>
                <div class="notice-board no-border">
                  <div class="table-img">
                    <img class="notice-object" src="assets/img/user/usrbig1.jpg" alt="...">
                  </div>
                  <div class="notice-body">
                    <div class="notice-heading color-info col-orange">Ashton Cox</div>
                    <p>Lorem ipsum dolor sit amet, id quo eruditi eloquentiam.</p>
                    <div class="comment-date">Yesterday</div>
                  </div>
                </div>
                <!-- <div class="notice-board">
                  <div class="table-img">
                    <img class="notice-object" src="assets/img/user/usrbig1.jpg" alt="...">
                  </div>
                  <div class="notice-body">
                    <div class="notice-heading color-primary col-indigo">Jay Soni</div>
                    <p>Lorem ipsum dolor sit amet, id quo eruditi eloquentiam.</p>
                    <p class="comment-date">1 hour ago</p>
                  </div>
                </div> -->
                <!-- <div class="notice-board">
                  <div class="table-img">
                    <img class="notice-object" src="assets/img/user/usrbig1.jpg" alt="...">
                  </div>
                  <div class="notice-body">
                    <div class="notice-heading color-primary col-indigo">Veena Pandit</div>
                    <p>Lorem ipsum dolor sit amet, id quo eruditi eloquentiam.</p>
                    <p class="comment-date">1 hour ago</p>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
          <div class="card">
            <div class="header">
              <h6>Library Book Issue List</h6>
            </div>
            <div class="tableBody font-12">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Book ID</th>
                      <th>Book Title</th>
                      <th>Author</th>
                      <th>Issue Date</th>
                      <th>Status</th>
                      <th>Return Date</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>AI99876</td>
                      <td>Computer Programming</td>
                      <td>John Deo</td>
                      <td>10-03-2019</td>
                      <td><span class="label l-bg-green shadow-style">Issue</span></td>
                      <td>23-03-2019</td>
                      <td> <button mat-stroked-button color="primary">Details</button>
                      </td>
                    </tr>
                    <tr>
                      <td>BT67657</td>
                      <td>Design Pattern In java</td>
                      <td>Airi Satou</td>
                      <td>14-04-2019</td>
                      <td><span class="label l-bg-blue shadow-style">Return</span></td>
                      <td>28-04-2019</td>
                      <td> <button mat-stroked-button color="primary">Details</button>
                      </td>
                    </tr>
                    <tr>
                      <td>RT67013</td>
                      <td>The Mathematics Principles</td>
                      <td>Angelica Ramos</td>
                      <td>17-04-2019</td>
                      <td><span class="label l-bg-green shadow-style">Issue</span></td>
                      <td>24-04-2019</td>
                      <td> <button mat-stroked-button color="primary">Details</button>
                      </td>
                    </tr>
                    <tr>
                      <td>PS2398</td>
                      <td>Angular 10 Advance</td>
                      <td>Jens Brincker</td>
                      <td>21-04-2019</td>
                      <td><span class="label l-bg-green shadow-style">Issue</span></td>
                      <td>29-04-2019</td>
                      <td> <button mat-stroked-button color="primary">Details</button>
                      </td>
                    </tr>
                    <tr>
                      <td>MO4987</td>
                      <td>SEO Optimazation</td>
                      <td>Cara Stevens</td>
                      <td>11-05-2019</td>
                      <td><span class="label l-bg-blue shadow-style">Return</span></td>
                      <td>18-05-2019</td>
                      <td> <button mat-stroked-button color="primary">Details</button>
                      </td>
                    </tr>
                    <tr>
                      <td>BE2876</td>
                      <td>Android Basic Concept</td>
                      <td>Jacob Ryan</td>
                      <td>15-05-2019</td>
                      <td><span class="label l-bg-green shadow-style">Issue</span></td>
                      <td>21-05-2019</td>
                      <td> <button mat-stroked-button color="primary">Details</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>