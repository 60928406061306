import { Component, OnInit } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-student-how-it-works',
  templateUrl: './student-how-it-works.component.html',
  styleUrls: ['./student-how-it-works.component.scss']
})
export class StudentHowItWorksComponent implements OnInit {
  public isUserLoggedIn:string;

  constructor(public toastr: ToastrManager,private my_router: ActivatedRoute,private router: Router) { }

  ngOnInit(): void {
    this.isUserLoggedIn = localStorage.getItem('isLoggedin');
  }

  registerclick() {   
    if(this.isUserLoggedIn.toLowerCase()=="true") {
      this.toastr.errorToastr("Already you have Logged in");
    }
    else {
      this.router.navigate(['/teacher/login']);
    }
  }

  enrollattend() {   
    if(this.isUserLoggedIn.toLowerCase()=="true") {
      this.router.navigate(['/explore/5f12a2efbf9506355c8adb5c']);
    }
    else {
      this.router.navigate(['/liveClassList']);
    }

  }

}
