import { Component, OnInit,Inject, ElementRef, ViewChild, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Options } from 'ng5-slider';
import { ApiService } from '../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {NgSelectModule, NgOption} from '@ng-select/ng-select';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from '../../../../environments/environment';
import { MediaService } from '../../../shared/media.service';
import { DatePipe } from '@angular/common';
const datepipe: DatePipe = new DatePipe('en-US')
@Component({
  selector: 'app-listing-classrooms',
  templateUrl: './listing-classrooms.component.html',
  styleUrls: ['./listing-classrooms.component.scss']
})
export class ListingClassroomsComponent implements OnInit {

  public meetingsData: any = [];
  public meetingsDataRecord: any = [];
  public meetingsDataListen: any = [];
  public meetingsDataAll: any = [];
  public allmeeting: any = [];
  public inviteUrl = "";
  public frontwebsite = environment.frontUrl;
  public lessonData: any = [];
  public myId: any = ''
  public isLogin: any = 'false';
  public isCancel: any = 'false';
  // public isStudent : any 
  public videoList: any = [];
  public videoListPrivate: any = [];
  public videoListGroup: any = [];
  public teacherVideouploadlist: any = [];
  public videoYoutubePublic: any = [];
  public publicsessionData: any = [];
  public publicsessionDataRecord: any = [];
  public requestedDataList: any = [];
  public requestedDataAccpet: any = [];
  public requestedDataAccpetRecord: any = [];
  public teacherPayoutDetails:any = [];
  public mydashboardData: any = [];
  public isStudent: boolean = false;
  public isTeacher: boolean = false;
  public currentPageNumber : number = 1;
  public totalVideoCount: number;
  public totalPageCount: number;
  public currentVideoCount: number;
  term: string;
  // public webinarData: any = [];
  isDesc: any = 1;
  column: string = 'remainingClass';
  p: number = 1;
  count: number = 4;

  constructor(private api: MediaService,
    private SpinnerService:NgxSpinnerService,
    private activatedRoute:ActivatedRoute,
    public location: Location, public toastr: ToastrManager,
     private formBuilder: FormBuilder, private rest: ApiService, private router: Router )  
  { }

  ngOnInit() {
    this.isLogin = localStorage.getItem('isLoggedin');
    this.isStudent = localStorage.getItem('profileType').toLowerCase() == 'student'
    this.isTeacher = localStorage.getItem('profileType').toLowerCase() == 'teacher'
    if (this.isLogin == 'true' && this.isTeacher) {
      this.getClassRoomList();
    }
    else if (this.isLogin == 'true' && this.isStudent) {
      this.getClassRoomWithoutlogin();
    }
    else {
      this.getClassRoomWithoutlogin();
    }
  }


  getClassRoomList() {

    const webToken = localStorage.getItem('webtoken');

    this.SpinnerService.show();
    var request = {};
    request['limit'] = environment.listing__itemsPerPage;
    request['page'] = this.currentPageNumber;
    this.rest.getClassRoomTeacher(webToken, request).subscribe((response) => {
      console.log('response', response)
      
      if (response.status && response.data != null && response.data.length != 0) {
        this.videoList = response.data.classroomsrecord;
        this.totalVideoCount = response.data.totalCount;
        this.totalPageCount = Math.ceil(this.totalVideoCount / environment.listing__itemsPerPage)
        this.currentVideoCount = ((this.currentPageNumber - 1) * environment.listing__itemsPerPage)
        console.log(this.videoList, 'classroomsrecord')
        console.log(this.totalPageCount, this.totalVideoCount, environment.listing__itemsPerPage)
        for(var studyHall of this.videoList){
          studyHall.ScheduleDate = datepipe.transform( studyHall.ScheduleDate, 'MMM d, y, h:mm a')
          if(!studyHall.image){
            studyHall.image = 'assets/img/courses/courses1.jpg'
          }
        }
        // console.log(this.videoList)
      } else {
        this.mydashboardData = [];
      }
      this.SpinnerService.hide()
    }, (err) => {
      console.log(err);
    });
  }

  getClassRoomWithoutlogin() {
    
    this.isLogin = localStorage.getItem('isLoggedin');
    this.SpinnerService.show();
    var request = {};
    request['limit'] = environment.listing__itemsPerPage;
    request['page'] = this.currentPageNumber;
    this.rest.getClassRoomWithoutlogin({}, request).subscribe((response) => {
      console.log('response', response)
      
      if (response.status && response.data != null && response.data.length != 0) {
        this.videoList = response.data.data;
        this.totalVideoCount = response.data.totalCount;
        this.totalPageCount = Math.ceil(this.totalVideoCount / environment.listing__itemsPerPage)
        this.currentVideoCount = ((this.currentPageNumber - 1) * environment.listing__itemsPerPage)
        console.log(this.totalPageCount, this.totalVideoCount, environment.listing__itemsPerPage)
        for(var studyHall of this.videoList){
          studyHall.ScheduleDate = datepipe.transform(studyHall.ScheduleDate, 'MMM d, y, h:mm a')
          if(!studyHall.image){
            studyHall.image = 'assets/img/courses/courses1.jpg'
          }
        }
        console.log(this.videoList, 'classroomsrecord')
        // console.log(this.videoList)
      } else {
        this.mydashboardData = [];
      }
      this.SpinnerService.hide()
    }, (err) => {
      console.log(err);
    });
  }
  redirect() {
    this.router.navigateByUrl('/');
  }
  // videodetails(data) {
  //   this.router.navigateByUrl('/videoDetails/' + data._id);
  // }
  detailsMeeting(data, pagename) {
    data.pagename = pagename;
    var jsonStr = JSON.stringify(data)
    localStorage.setItem('livedetails', jsonStr);
    if(this.isTeacher){
      this.router.navigate(['/teacher/meetingDetails/' + "/" + data.meetingID], { state: data });
    }
    else{
      this.router.navigate(['/studyhall-details/' + data._id], { state: data });

      // this.router.navigate(['/student/meetingDetails/' + "/" + data.meetingID], { state: data });
    }
  }
  clickPageNumber(pageNumber) {
    this.currentPageNumber = pageNumber;
    console.log(this.currentPageNumber)
    if (this.isLogin == 'true' && this.isTeacher) {
      this.getClassRoomList();
    }
    else if (this.isLogin == 'true' && this.isStudent) {
      this.getClassRoomWithoutlogin();
    }

    else {
      this.getClassRoomWithoutlogin();
    }
  }

  redirectToCreate(){
    this.router.navigate(['/create-studyhall'])
  }
}
