import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstC'
})
export class FirstCapPipe implements PipeTransform {

  transform(value) {
  	if(value!=''){
  		var text = value
  		var data = value[0].toUpperCase()
  		return data+text.slice(1)+'s'
  	}else{
  		return '';
  	}
    
  }

}
