
<div *ngIf="isTeacher">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>
<div *ngIf="!this.isTeacher">
    <app-header-style-one></app-header-style-one>
</div>
<div  class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}' *ngIf="livedetails.length!=0">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a>Home</a></li>
                <!-- <li><a>Classes</a></li> -->
                <li>{{livedetails.name}} - {{livedetails.ScheduleDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</li>
            </ul>
            <h2>Classes</h2>
            <!-- <a *ngIf="isLogout && isMobile" href="intent:#Intent;action=com.appopenfromweb.androiddevicetest;category=android.intent.category.DEFAULT;category=android.intent.category.BROWSABLE;S.msg_from_browser=Launched%20from%20Browser;end">Click this link to launch you app from web</a> -->
        </div>
    </div>
</div>

<section class="courses-details-area pt-100 pb-70" *ngIf="livedetails.length!=0">
    <div class="container">
        <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-lg-7">
                    <div class="courses-title">
                        <h2 style="overflow-wrap: break-word;">{{livedetails.name}} 
                            <!-- - {{livedetails.ScheduleDate |  date :"MMM dd,yyyy, hh:mm aaa"}} -->
                        </h2>                      
                    </div>

                    <div class="courses-meta">
                        <ul>
                            <li>
                                <i class='bx bx-folder-open'></i>
                                <span>Category</span>
                                <!-- <a>{{livedetails.categoryID.categoryName}}</a> -->
                            </li>
                             <li *ngIf="!isrequest && !isaccept">
                                <i class='bx bx-group'></i>
                                <span>Teacher Name</span>
                                <a data-toggle="modal" data-target="#myModal1">
                                    {{livedetails.teacherID.profileName}}
                                <!-- {{courseDetails.teacherDetails ? courseDetails.teacherDetails.teacherFirstName : courseDetails.createdTeacherFirstName}}</a>
                                <a *ngIf="isteacher">{{courseDetails.createdTeacherFirstName}}-->
                                </a> 
                              
                            </li>    
                            <li *ngIf="!isrequest && !isaccept && livedetails.classType !='One-on-One'">
                                <i class='bx bx-group'></i>
                                <span>Enrolled Students</span>
                                <span> <studentList [listData]="studentCount"></studentList></span>
                            </li>
                            <!-- <li>
                                <i class='bx bx-calendar'></i>
                                <span>Last Updated</span>
                                <a routerLink="/single-courses">{{livedetails.ScheduleDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</a>
                            </li> -->
                        </ul>
                    </div>
                </div>
      <div  class="modal" id="myModal1">
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-body">
                            <h3 style="text-align: center;"> Teacher Details</h3>
                       
                            <div class="row marginTop">
                                <div class="col-md-4 col-lg-4 col-sm-4">
                                    <p style="color:var(--mainColor);">Teacher Name</p>
                                </div>
                                <div class="col-md-1 col-lg-1 col-sm-1">
                                    <p style="color:var(--mainColor)"> - </p>
                                </div>
                                <div class="col-md-7 col-lg-7 col-sm-7" *ngIf="livedetails.teacherID">
                                    
                                    <p>
                                        {{livedetails.teacherID.profileName ? livedetails.teacherID.profileName : ''}}
                                    </p>
                                </div>                              
                            </div>

                            <div class="row marginTop">
                                <div class="col-md-4 col-lg-4 col-sm-4">
                                    <p style="color:var(--mainColor);">School</p>
                                </div>
                                <div class="col-md-1 col-lg-1 col-sm-1">
                                    <p style="color:var(--mainColor)"> - </p>
                                </div>
                                <div class="col-md-7 col-lg-7 col-sm-7" *ngIf="livedetails.teacherID">
                                    <p>
                                        {{livedetails.teacherID.groupName ? livedetails.teacherID.groupName : ''}}
                                    </p>
                                </div>                              
                            </div>
                            <div class="row marginTop">
                                <div class="col-md-4 col-lg-4 col-sm-4">
                                    <p style="color:var(--mainColor);">State</p>
                                </div>
                                <div class="col-md-1 col-lg-1 col-sm-1">
                                    <p style="color:var(--mainColor)"> - </p>
                                </div>
                                <div class="col-md-7 col-lg-7 col-sm-7" *ngIf="livedetails.teacherID">
                                    <p>
                                        {{livedetails.teacherID.state !='undefined' ? livedetails.teacherID.state : ''}}
                                    </p>
                                </div>                              
                            </div>
                            <div class="row marginTop">
                                <div class="col-md-4 col-lg-4 col-sm-4">
                                    <p style="color:var(--mainColor);">Category</p>
                                </div>
                                <div class="col-md-1 col-lg-1 col-sm-1">
                                    <p style="color:var(--mainColor)"> - </p>
                                </div>
                                <div class="col-md-7 col-lg-7 col-sm-7" *ngIf="livedetails.teacherID!=null && livedetails.teacherID.categories !=null">
                                    <p>
                                        <ngfor-comma [keyName]="'categoryName'" [forData]="livedetails.teacherID.categories"></ngfor-comma>
                                    </p>                                  
                                </div>  
                            </div>
                            <div class="row marginTop">
                                <div class="col-md-4 col-lg-4 col-sm-4">
                                    <p style="color:var(--mainColor);">Grade</p>
                                </div>
                                <div class="col-md-1 col-lg-1 col-sm-1">
                                    <p style="color:var(--mainColor)"> - </p>
                                </div>
                                <div class="col-md-7 col-lg-7 col-sm-7" *ngIf="livedetails.teacherID!=null && livedetails.teacherID.grades !=null">
                                    <p>                                        
                                      <ngfor-comma [keyName]="'gradeName'" [forData]="livedetails.teacherID.grades"></ngfor-comma>
                                    </p>
                                </div>  
                            </div>
                            <div class="row marginTop">
                                <div class="col-md-4 col-lg-4 col-sm-4">
                                    <p style="color:var(--mainColor);">Board</p>
                                </div>
                                <div class="col-md-1 col-lg-1 col-sm-1">
                                    <p style="color:var(--mainColor)"> - </p>
                                </div>
                                <div class="col-md-7 col-lg-7 col-sm-7" *ngIf="livedetails.teacherID!=null && livedetails.teacherID.grades !=null">
                                    <p>
                                        <ngfor-comma [keyName]="'boardName'" [forData]="livedetails.teacherID.boards"></ngfor-comma>
                                    </p>                                    
                                </div>  
                            </div>
                            <div class="row marginTop">
                                <div class="col-md-4 col-lg-4 col-sm-4">
                                    <p style="color:var(--mainColor);">Subject Name</p>
                                </div>
                                <div class="col-md-1 col-lg-1 col-sm-1">
                                    <p style="color:var(--mainColor)"> - </p>
                                </div>
                                <div class="col-md-7 col-lg-7 col-sm-7" *ngIf="livedetails.teacherID!=null && livedetails.teacherID.grades !=null">
                                    <p>
                                        <ngfor-comma [keyName]="'subjectName'" [forData]="livedetails.teacherID.subjects"></ngfor-comma>
                                    </p>                                       
                                </div>  
                            </div>
                            <div class="row marginTop">
                                <div class="col-md-4 col-lg-4 col-sm-4">
                                    <p style="color:var(--mainColor);">Language</p>
                                </div>
                                <div class="col-md-1 col-lg-1 col-sm-1">
                                    <p style="color:var(--mainColor)"> - </p>
                                </div>
                                <div class="col-md-7 col-lg-7 col-sm-7" *ngIf="livedetails.teacherID!=null && livedetails.teacherID.preferredLanguage !=null">
                                    <p>
                                        <ngfor-comma [keyName]="'languageName'" [forData]="livedetails.teacherID.preferredLanguage"></ngfor-comma>
                                    </p>
                                </div>  
                            </div>      
                            <div class="row" style="margin-top: 5%;">
                                <div class="col-md-4 col-lg-4 col-sm-4">
                                </div>
                                <div class="col-md-3 col-lg-3 col-sm-3">
                                  
                                </div>
                               <!--  <div class="col-md-3 col-lg-3 col-sm-3">
                                    <a *ngIf="iscreatedby" class="default-btn" style="margin-left: 15%;" data-dismiss="modal1"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Cancel</span><i class="bx bx-paper-plane icon-arrow after"></i></a>
                                </div> -->
                            </div>
                          
                        </div>
                        
                        
                      </div>
                    </div>
                  </div>
                <div class="col-lg-5">
                    <div class="courses-price">
                        <div class="courses-review">
                        </div>
                        <div class="price"></div>
                        <a  (click)="backto()" class="default-btn mr-1 back-btn">
                            <!-- <i class='bx bx-paper-plane icon-arrow before'></i> -->
                            <span class="">Back</span>
                            <!-- <i class="bx bx-paper-plane icon-arrow after"></i> -->
                        </a>
                        <span *ngIf="!isrequest">
                            <!-- <a  *ngIf="meetingsactionText == 'Entroll' && this.isStudent" (click)="meetingsaction(meetingIDpopup,'Entroll',livedetails)" class="default-btn  mr-1" style=" margin-right: 10px;">
                                <i class='bx bx-paper-plane icon-arrow before'></i>
                                <span class="label">Enroll</span>
                                <i class="bx bx-paper-plane icon-arrow after"></i>
                            </a>
                            <a  *ngIf="meetingsactionText == 'Entroll' && this.isStudent" (click)="meetingsaction(meetingIDpopup,'Entroll',livedetails)" class="default-btn  mr-1" style=" margin-right: 10px;">
                                <i class='bx bx-paper-plane icon-arrow before'></i>
                                <span class="label">Enroll</span>
                                <i class="bx bx-paper-plane icon-arrow after"></i>
                            </a> -->


                            <a *ngIf="meetingsactionText == 'Entroll' && this.isStudent" href="javascript:void(0)" (click)="meetingsaction(meetingIDpopup,'Entroll',livedetails)" class="button mr-1">
                                 <!-- <i class='bx bx-paper-plane icon-arrow before'></i> -->
                                <span class="label">Enroll</span>
                                <!-- <i class="bx bx-paper-plane icon-arrow after"></i> -->
                            </a>
                            <a *ngIf="meetingsactionText == 'Start' && !livedetails.isrecorded" href="javascript:void(0)" (click)="meetingsaction(meetingIDpopup,'start',livedetails)" class="default-btn mr-1">
                               
                                <i class='bx bx-paper-plane icon-arrow before'></i>
                                <span class="label">Start Class</span>
                                <i class="bx bx-paper-plane icon-arrow after"></i>
                            </a>
                            <a *ngIf="livedetails.isrecorded && meetingsactionText != 'Entroll'" href="javascript:void(0)" (click)="fetchrecordvideo(livedetails._id,isone2one ? 'requestId':'meetingId')" class="default-btn mr-1">
                               
                                <i class='bx bx-paper-plane icon-arrow before'></i>
                                <span class="label">Playback</span>
                                <i class="bx bx-paper-plane icon-arrow after"></i>
                            </a>                            
                            <a *ngIf="meetingsactionText == 'Join' && !livedetails.isrecorded" href="javascript:void(0)" (click)="meetingsaction(meetingIDpopup,'join',livedetails)" class="button mr-1"><i class="fas fa-plus mr-1"></i>
                                <span class="label">Join</span>
                            </a>
                            <a *ngIf="meetingsactionText == 'Login'" href="javascript:void(0)" (click)="meetingsaction(meetingIDpopup,'login',livedetails)" class="button mr-1">
                                <span class="label">Login</span>
                            </a>
                            <a *ngIf="meetingsactionText == 'Start' && livedetails.classType !='One-on-One' && livedetails.meetingType=='Public' && !livedetails.isrecorded" title="Click to Copy the invite url"  (click)="copyMessage()" href="javascript:void(0)" class="button mr-1"><i class="far fa-copy mr-1"></i>
                                 
                                 <!-- <i class='bx bx-paper-plane icon-arrow before'></i> -->
                                <span class="label">Invite</span>
                                <!-- <i class="bx bx-paper-plane icon-arrow after"></i> -->
                            </a>
                            <!-- <a *ngIf="meetingsactionText == 'Start'" title="Delete Meeting"  (click)="removeMeeting(livedetails._id)" href="javascript:void(0)" class="default-btn mr-1"><i class="fa fa-trash mr-1"></i>
                                 
                                 <i class='bx bx-paper-plane icon-arrow before'></i>
                                <span class="label">Delete</span>
                                <i class="bx bx-paper-plane icon-arrow after"></i>
                            </a> -->
                        </span>
                        <span *ngIf="isrequest">
                            <a href="javascript:void(0)" (click)="requestAccept(livedetails)" class="button">
                                <!-- <i class='bx bx-paper-plane icon-arrow before'></i> -->
                                <span class="label">Accept</span>
                                <!-- <i class="bx bx-paper-plane icon-arrow after"></i> -->

                                </a>
                        </span>
                        <!-- <div class="row" style="margin-top: 5%;">
                            <div class="col-md-3 col-lg-3 col-sm-3">
                                
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3">
                              
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3">
                               
                                
                            </div>

                        </div> -->
                       
                           




                        <!-- <button  class="default-btn" (click)="backto()">Back</button> -->
                       
                       
                       
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-8">
                <div class="courses-details-image text-center">
                    <img [src]="livedetails.image !='' ? livedetails.image : 'assets/img/courses/courses1.jpg'" alt="image">
                </div>
                <div class="mt-5">
                    <label>
                        Description
                    </label>
                    <div class="col-lg-12 text-break">
                            {{livedetails.meetingdescription =='' ? '-' : livedetails.meetingdescription}}
                    </div>
                </div>
               
            </div>

            <div class="col-lg-4">
                <div class="courses-sidebar-information">
                    <ul>
                        <li>
                            <span><i class='bx bx-group'></i> Class ID:</span>
                            {{livedetails.meetingID}}
                        </li>
                        <li>
                            <span><i class='bx bx-time'></i> Class Name:</span>
                            {{livedetails.name}}
                        </li>
                        <li *ngIf="livedetails.classType !='One-on-One'">
                            <span><i class='bx bx-time'></i> Privacy Type:</span>
                            {{livedetails.meetingType =='Group' ? 'School':livedetails.meetingType}}
                        </li>
                        <li *ngIf="livedetails.meetingType =='Group'">
                            <span><i class='bx bx-time'></i> School Name:</span>
                            {{livedetails.groupname}}
                        </li>
                        <li *ngIf="livedetails.classType =='One-on-One'">
                            <span><i class='bx bx-time'></i> Class Type:</span>
                            One-on -One Class
                        </li>
                        <!-- <li>
                            <span><i class='bx bx-time'></i> Group Name:</span>
                            {{livedetails.groupname}}
                        </li> -->
                        <li>
                            <span><i class='bx bx-tachometer'></i> Class Datetime:</span>
                            
                            {{livedetails.ScheduleDate | date :"MMM dd,yyyy hh:mm aaa"}} 
                        </li>
                        <li>
                            <span><i class='bx bx-support'></i> Frequency</span>
                            {{livedetails.occurance =='' ? '-' : livedetails.occurance}}
                        </li>
                        
                        <li>
                            <span><i class='bx bx-tachometer'></i>Duration:</span>
                            {{livedetails.Duration ? livedetails.Duration +'Min' : '-'}}
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i>Grade:</span>
                            {{livedetails.gradeID ? livedetails.gradeID.gradeName: '-'}}
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i>Board:</span>
                            {{livedetails.boardID ? livedetails.boardID.boardName: '-'}}
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i>Subject:</span>
                            {{livedetails.subjectID ? livedetails.subjectID.subjectName: ''}}
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i>Language:</span>
                            {{livedetails.languageID ? livedetails.languageID.languageName: ''}}
                        </li>
                        <li>
                            <span><i class='bx bxs-badge-check'></i> City:</span>
                            {{livedetails.city =='' ? '-' : livedetails.city}}
                        </li>
                        <li>
                            <span><i class='bx bxs-badge-check'></i> Tag:</span>
                            {{livedetails.tag| removespace}}
                            
                        </li>
                        
                       
                        <!-- <li>
                            <span><i class='bx bxs-institution'></i> Name:</span>
                            <a href="#" target="_blank" class="d-inline-block">{{livedetails.fullName}}</a>
                        </li>
                        <li>
                            <span><i class='bx bx-certification'></i> Language:</span>
                            {{livedetails.Languages ==''? '-' : livedetails.Languages}}
                        </li> -->
                        <!-- <li>
                            <span><i class='bx bx-atom'></i> Class Type:</span>
                            {{livedetails.meetingType}}
                        </li> -->
                        
                       
                       
                        <!-- <li>
                            <span><i class='bx bx-certification'></i> Tag:</span>
                            {{tag =='' ? '-' : tag}}
                        </li>
                        <li>
                            <span><i class='bx bx-certification'></i> Class Price:</span>
                            {{livedetails.meetingPriceType  =='' ? '-' : livedetails.meetingPriceType}}
                        </li>
                        <li>
                            <span><i class='bx bx-certification'></i>  Amount(INR):</span>
                            {{livedetails.PayableAmount}}
                        </li> -->
                    </ul>
                   
                </div>
                 <span *ngIf="!isrequest">
                         <a (click)="editMeeting()" *ngIf="meetingsactionText == 'Start' && livedetails.classType !='One-on-One' && isTeacher && !livedetails.isrecorded"  title="Delete Meeting"   href="javascript:void(0)" class="default-btn mr-1">
                                                           
                            <i class='bx bx-paper-plane icon-arrow before'></i>
                            <span class="label">Edit</span>
                            <i class="bx bx-paper-plane icon-arrow after"></i>
                        </a>
                        <a *ngIf="meetingsactionText == 'Start' && livedetails.classType !='One-on-One' && isTeacher && !livedetails.isrecorded" title="Delete Meeting"  (click)="removeMeeting(livedetails._id)" href="javascript:void(0)" class="default-btn mr-1">
                                 
                                 <i class='bx bx-paper-plane icon-arrow before'></i>
                                <span class="label">Delete</span>
                                <i class="bx bx-paper-plane icon-arrow after"></i>
                        </a>                                    
                </span>
                <!-- <span>
                    <a *ngIf="meetingsactionText == 'Join'" href="javascript:void(0)" (click)="removeFromList(livedetails)" class="button mr-1"><i class="fas fa-plus mr-1"></i>
                        <span class="label">Remove</span>
                    </a>  
                </span> -->
            </div>
        </div>
    </div>
</section>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <img src="assets/img/spinner-default.gif" alt="">
</ngx-spinner>
