import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Options } from 'ng5-slider';
import { ApiService } from '../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { Location } from '@angular/common';
import * as moment from 'moment';
import { MediaService } from '../../../shared/media.service';
import { NgxSpinnerService } from "ngx-spinner";
import { RemovespacePipe } from '../../custom-pipes/removespace.pipe';
declare var $: any;
@Component({
  selector: 'app-audio-details',
  templateUrl: './audio-details.component.html',
  styleUrls: ['./audio-details.component.scss'],
  providers:[RemovespacePipe]
})
export class AudioDetailsComponent implements OnInit {

  isLoggedin = "false";
  public teacherID = "";
  public meetingimage = "";
  public meetingurl = "";
  public meetingsactionText = "";
  public meetingCategorypopup = "";
  public meetingScheduleDatepopup = "";
  public meetingrunningpopup = "";
  public meetingIDpopup = "";
  public meeting_ID = "";
  public meetingname = "";
  public meetingScheduleDate = "";
  public inviteUrl = "";
  public frontwebsite = environment.frontUrl;
  public redirectUrl = "";
  public meetingDuration = '';
  public meetingLanguage = '';
  public meetingCity = '';
  public meetingoccurance = '';
  public meetingdescription = '';
  public livedetails:any =[];
  public profileName = '';
  public meetingId='';
  public urlArray:any=[];
  public pagename:any = '';
  public tag:any='';
  public groupName:any;
  public isStudent:any;
  public userId:any = '';
  public isEntroll:any=false;
  public isteacherRoute:any =false;
  public isrequest:boolean = false;
  public isaccept:boolean = false;
  public submitted:boolean = false;
  public enrolledCount:Number = 0;
  reviewForm: FormGroup;
  reviewlist:any = [];
  reviewLike:any=[];
  userlike:any=[];
  public viewCount:Number = 0;
  public name:String = '';
  public tagArray :any = [];
  public isPartnerUser:any = [];
  public categoryarrayvalues;
  public subjectarrayvalues;
  public gradearrayvalues;
  constructor(private removespace:RemovespacePipe,private mediaAPI:MediaService,private location:Location,private my_router: ActivatedRoute,public toastr: ToastrManager, private formBuilder: FormBuilder, private rest: ApiService, private router: Router,private SpinnerService: NgxSpinnerService,private sanitizer: DomSanitizer,private datePipe: DatePipe) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.isLoggedin = localStorage.getItem('isLoggedin');
    this.teacherID = localStorage.getItem('teacherID');
    const webToken = localStorage.getItem('webtoken');
    var urlArray = window.location.href.split('/');
    this.userId=  localStorage.getItem('userId');
    this.name =  localStorage.getItem('profileName')
    this.isPartnerUser = localStorage.getItem('isPartner') =='true'?true:false;
    this.isrequest = window.location.href.match('enroll') !=null ? true : false;
    this.isteacherRoute = localStorage.getItem('profileType').toLowerCase() =='teacher'
      this.reviewForm = this.formBuilder.group({
        userType:'',
        itemId:null,
        ratingget:null,
        comment:['',Validators.required],
        rating: [5,Validators.required]

      });   
      var req={
        profileType:this.isteacherRoute ? 'teacher':'student',
        id:urlArray[urlArray.length-1],
      }
      this.mediaAPI.getAudiosByID(req,webToken).subscribe((result) => {
        if(result.status){
          this.livedetails = result.data[0];
          let categoryarray:any=[];
          let gradearray:any=[];
          let subjectarray:any=[];
          if(this.livedetails.categoryID.length > 0) {
            for(var i=0;i < this.livedetails.categoryID.length; i++) {
              categoryarray.push(this.livedetails.categoryID[i].categoryName);
            }
            this.categoryarrayvalues = categoryarray.toString();
          }

          if(this.livedetails.subjectID.length > 0) {
            for(var i=0;i < this.livedetails.subjectID.length; i++) {
              subjectarray.push(this.livedetails.subjectID[i].subjectName);
            }
            this.subjectarrayvalues = subjectarray.toString();
          }

          if(this.livedetails.gradeID.length > 0) {
            for(var i=0;i < this.livedetails.gradeID.length; i++) {
              gradearray.push(this.livedetails.gradeID[i].gradeName);
            }
            this.gradearrayvalues = gradearray.toString();
          }
         
          
         
          if(this.livedetails.length!=0 && this.livedetails.tag !=''){
            this.tagArray = this.livedetails.tag.split(',')
          }
           this.reviewForm.patchValue({'itemId':this.livedetails._id})
          this.reviewGet(this.livedetails._id);
          this.reviewLikeGet(this.livedetails._id);
          this.getLikereviewsByUser(this.livedetails._id);
          if(!this.isteacherRoute){
            var data={ 
                itemId : this.livedetails._id,
                itemType :'song'
              }
            this.rest.updateviewCount(data,localStorage.getItem('webtoken')).subscribe((response) => {
              if(response.status){
                this.reviewGet(this.livedetails._id);
              }
            });
          }
        }
      });
   

  }

  
countCal() {
     var data={ itemId : this.reviewForm.value.itemId}
      this.rest.updateviewCount(data,localStorage.getItem('webtoken')).subscribe((response) => {

        if(response.status){
          this.reviewGet(this.livedetails._id);
        }
      });
  }
   reviewLikeGet(id){
    var req={
      itemId : id
     }
      this.SpinnerService.show()
     this.rest.getLikereviews(req,localStorage.getItem('webtoken')).subscribe((response) => {
      this.SpinnerService.hide()
      if(response.status){
        this.reviewLike = response.data;
        // this.toastr.successToastr(response.message);
        // this.backto();
      }else{
        this.reviewLike =[];
        // this.toastr.errorToastr(response.message);
      }

    });
  }
  getLikereviewsByUser(id){
    var req={
      itemId : id
     }
     this.SpinnerService.show()
     this.rest.getLikereviewsByUser(req,localStorage.getItem('webtoken')).subscribe((response) => {
      this.SpinnerService.hide()
      if(response.status){
        this.userlike = response.data;
        // this.toastr.successToastr(response.message);
        // this.backto();
      }else{
        this.userlike = [];
        // this.toastr.errorToastr(response.message);
      }

    });
  }
  reviewGet(id){
    var req={
      itemId : id,
      itemtype : 'song'
     }
     this.rest.getreviews(req,localStorage.getItem('webtoken')).subscribe((response) => {
      console.log(response);
      if(response.status){
        this.reviewlist = response.data.data;
        this.enrolledCount = response.data.enrolledlCount.length!=0 ? response.data.enrolledlCount.length : 0;
        this.viewCount = response.data.viewCount.length!=0 ? response.data.viewCount.length : 0;

    //     if(this.viewCount ==0 && this.isLoggedin=='true'){
    //          var req={ videoid : this.reviewForm.value.userId,
    //           viewCount:Number(this.viewCount)+1
    //          }
    //       this.rest.saveviewcount(req,localStorage.getItem('webtoken')).subscribe((response) => {
    //         console.log(response)

    //       });
        
    //   }else{
    //     if(this.isLoggedin =='true'){
    //       var req1={ videoid : this.reviewForm.value.userId,
    //         viewCount:Number(this.viewCount)+1
    //       }
    //       this.rest.updateViewCount(req1,localStorage.getItem('webtoken')).subscribe((response) => {
    //         console.log(response);
    //       });
    //     }
        
    // }
        // this.toastr.successToastr(response.message);
        // this.backto();
      }else{
        // if(response.code =404){
        //      var req={ videoid : this.reviewForm.value.userId,
        // likecount : 0,
        // name : this.name,
        // isLike : false}
        // this.rest.reviewLikeSave(req,localStorage.getItem('webtoken')).subscribe((response) => {
        //     if(!response.status){
        //       this.toastr.errorToastr(response.message);
        //     }

        // });
        // }
        // this.toastr.errorToastr(response.message);
      }

    });
  }
  get f() {
    return this.reviewForm.controls;
  }
submitComment(form){
    this.submitted = true;
    if(form.invalid){
      return false;
    }else{
    var id = form.value.itemId
      var req={ itemId : form.value.itemId,
        likecount : 0,
        dislikecount : 0,
        starrating : form.value.rating,
        comment : form.value.comment,
        name : this.name}
      this.rest.Addreviews(req,localStorage.getItem('webtoken')).subscribe((response) => {
        console.log(response);
        if(response.status){
          // this.toastr.successToastr(response.message);
          this.reviewGet(id);
          this.submitted =false;
          this.reviewForm.patchValue({'comment':''})
          // this.reviewForm.reset();
          // this.backto();
        }else{
          this.toastr.errorToastr(response.message);
        }

      });
    }
    console.log(form)
  }
  likeDislike(data,type){
    if(type=='like'){
      data.likecount = data.likecount+1;
      this.rest.updatereviews(data,localStorage.getItem('webtoken')).subscribe((response) => {
        console.log(response);
        if(response.status){
          this.reviewGet(this.livedetails._id);
          this.submitted =false;
        }else{
          this.toastr.errorToastr(response.message);
        }

      });
    }else{      
      // data.dislikecount = data.dislikecount+1;
      // this.rest.updatereviews(data,localStorage.getItem('webtoken')).subscribe((response) => {
      //   console.log(response);
      //   if(response.status){
      //     this.reviewGet(this.livedetails._id);
      //     this.submitted =false;
      //   }else{
      //     this.toastr.errorToastr(response.message);
      //   }

      // });
    }
  }
  hideAddComment(){
    // if($('#demo')[0].classList[1] == 'show'){
    //   $('#demo').hide();
    // }else{
    //   $('#demo').show();
    // }
   
  }
  likeReview(form,text){
       if(this.userlike.length ==0 && !this.isteacherRoute){
      // this.userlike = [{"temp":"temp"}];
      var req={ itemId : form.value.itemId,
        likecount : 1,
        name : this.name,
        isLike : true}
        this.SpinnerService.show()
      this.rest.reviewLikeSave(req,localStorage.getItem('webtoken')).subscribe((response) => {
        this.SpinnerService.hide()
        if(response.status){
          this.reviewLikeGet(this.livedetails._id);
           this.getLikereviewsByUser(this.livedetails._id);
        }else{
          this.toastr.errorToastr(response.message);
        }
       
      });
    }else{
      return false
    }
    // else{
    //   var req1={ itemId : form.value.itemId,
    //     likecount : text == 'add' ? this.reviewLike.length+1 : this.reviewLike.length-1,
    //     name : this.name,
    //     isLike : !this.userlike[0].isLike}
    //   this.rest.updateLikeeviews(req1,localStorage.getItem('webtoken')).subscribe((response) => {
    //     console.log(response);
    //     if(response.status){
    //       this.reviewLikeGet(this.livedetails._id);
    //       this.getLikereviewsByUser(this.livedetails._id);
    //     }else{
    //       this.toastr.errorToastr(response.message);
    //     }
  
    //   });
    // }
   
  }
  hideShowComment(){
    // if($('#demo1')[0].classList[1] == 'show'){
    //   $('#demo1').hide();
    // }else{
    //   $('#demo1').show();
      
    //   $('#demo').hide();
    // }
   
  }
  
  backto(){
    this.location.back();
  }
  login(){
    var data={
      isredirect:true
    }
    this.router.navigate(['/student/login'],{state:data});
  }
  requestAccept(){
    const webToken = localStorage.getItem('webtoken');
    var req={
      id : this.livedetails._id,
      meetingType  :this.livedetails.securitytype,
      libraryType :'songId',
      
    }
    this.rest.libraryEnroll(req,webToken).subscribe((result) => {
      console.log(result)
      if(result.status){
      this.toastr.successToastr('Audio Added to your Library');           
        var data = {
          message:'Added'
        }  
        this.router.navigate(['/success/library'], { state: data });
      }else{
        this.toastr.errorToastr(result.message);          
      }
    });
  }
  deleteaudio(){
   var req={
      song_id : this.livedetails._id,
      }
      this.SpinnerService.show()
  this.mediaAPI.delBySong(req).subscribe((result) => {
        this.SpinnerService.hide()
      if(result.status){
        this.toastr.successToastr(result.message);          
        this.location.back();
      }else{
        this.toastr.errorToastr(result.message);          
      }
      }); 
  }
  editaudio(){
    this.router.navigate(['/audio-edit/'+this.livedetails._id]);
  }
  removeFromList(data){
    var req={
    "libraryType":"songId",
    "libraryId":data._id
    }
    const webToken = localStorage.getItem('webtoken');
    this.rest.removeList(req,webToken).subscribe((result) => {
      if(result){
        this.toastr.successToastr(result.message);          
        var data = {
          message:'Removed'
        }  
        this.router.navigate(['/success/library'], { state: data });  
      }else{
        this.toastr.errorToastr(result.message);          
      }
    })    
  }
}
