<div *ngIf="isstudent">
    <app-header-style-one></app-header-style-one>
</div>
<div *ngIf="isteacher">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>

<div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a>Home</a></li>
                <li>Exams</li>
                <li>{{examDetails.fullName}}</li>
            </ul>
            <h2>Exams</h2>
        </div>
    </div>
</div>

<section class="courses-details-area pt-100 pb-70">
    <div class="container">


        <div class="modal" id="myModal1">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-body">
                    <h3 style="text-align: center;"> Teacher Details</h3>
               
                    <div class="row" style="margin-top: 5%;">
                        <div class="col-md-4 col-lg-4 col-sm-4">
                            <p style="color:var(--mainColor);text-align: center;">Teacher Name</p>
                        </div>
                        <div class="col-md-1 col-lg-1 col-sm-1">
                            <p style="color:var(--mainColor)"> - </p>
                        </div>
                        <div class="col-md-7 col-lg-7 col-sm-7">
                            <p>{{examDetails.teacherDetails ? examDetails.teacherDetails.teacherFirstName : 'Not Available'}}</p>
                        </div>                              
                    </div>

                    <div class="row" style="margin-top: 5%;">
                        <div class="col-md-4 col-lg-4 col-sm-4">
                            <p style="color:var(--mainColor);text-align: center;">School</p>
                        </div>
                        <div class="col-md-1 col-lg-1 col-sm-1">
                            <p style="color:var(--mainColor)"> - </p>
                        </div>
                        <div class="col-md-7 col-lg-7 col-sm-7">
                            <p>{{examDetails.teacherDetails ? examDetails.teacherDetails.teacherSchoolName : 'Not Available'}}</p>
                        </div>                              
                    </div>

                    <!-- <div class="row" style="margin-top: 5%;">
                        <div class="col-md-4 col-lg-4 col-sm-4">
                            <p style="color:var(--mainColor);text-align: center;">Qualification</p>
                        </div>
                        <div class="col-md-1 col-lg-1 col-sm-1">
                            <p style="color:var(--mainColor)"> - </p>
                        </div>
                        <div class="col-md-7 col-lg-7 col-sm-7">
                            <p>{{examDetails.teacherDetails ? examDetails.teacherDetails.teacherQualification : 'Not Available'}}</p>
                        </div>                              
                    </div>                            -->

                    <div class="row" style="margin-top: 5%;">
                        <div class="col-md-4 col-lg-4 col-sm-4">
                            <p style="color:var(--mainColor);text-align: center;">State</p>
                        </div>
                        <div class="col-md-1 col-lg-1 col-sm-1">
                            <p style="color:var(--mainColor)"> - </p>
                        </div>
                        <div class="col-md-7 col-lg-7 col-sm-7">
                            <p>{{examDetails.teacherDetails ? examDetails.teacherDetails.teacherState : 'Not Available'}}</p>
                        </div>                              
                    </div>

                    <!-- <div class="row" style="margin-top: 5%;">
                        <div class="col-md-4 col-lg-4 col-sm-4">
                            <p style="color:var(--mainColor);text-align: center;">Country</p>
                        </div>
                        <div class="col-md-1 col-lg-1 col-sm-1">
                            <p style="color:var(--mainColor)"> - </p>
                        </div>
                        <div class="col-md-7 col-lg-7 col-sm-7">
                            <p>{{examDetails.teacherDetails ? examDetails.teacherDetails.TeacherCountry : 'Not Available'}}</p>
                        </div>                              
                    </div> -->

                    <div class="row" style="margin-top: 5%;">
                        <div class="col-md-4 col-lg-4 col-sm-4">
                        </div>
                        <div class="col-md-3 col-lg-3 col-sm-3">
                          
                        </div>
                        <div class="col-md-3 col-lg-3 col-sm-3">
                            <a  class="default-btn" style="margin-left: 15%;" data-dismiss="modal"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Cancel</span><i class="bx bx-paper-plane icon-arrow after"></i></a>
                        </div>
                    </div>
                  
                </div>
                
                <!-- Modal footer -->
                <!-- <div class="modal-footer">
                  <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                </div> -->
                
              </div>
            </div>
          </div>

            <!-- The Modal to delete a course -->
            <div class="modal" id="myModal">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-body">
                        <h3> Delete Course</h3>
                        <div style="margin-top: 5%;"> 
                            <p> Are you absolutely sure you want to completely delete this {{examDetails.fullName}}  course and all the data it contains? </p>     
                        </div>
                        <div class="row" style="margin-top: 5%;">
                            <div class="col-md-4 col-lg-4 col-sm-4">
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3">
                                <a *ngIf="iscreatedby" (click)="courseDelete(examDetails)" class="default-btn" style=" margin-right: 10px;" data-dismiss="modal"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Delete</span><i class="bx bx-paper-plane icon-arrow after"></i></a>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3">
                                <a *ngIf="iscreatedby" class="default-btn" style="margin-left: 15%;" data-dismiss="modal"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Cancel</span><i class="bx bx-paper-plane icon-arrow after"></i></a>
                            </div>
                        </div>
                      
                    </div>
                    
                    <!-- Modal footer -->
                    <!-- <div class="modal-footer">
                      <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                    </div> -->
                    
                  </div>
                </div>
              </div>

                        <!-- The Modal to publish the course-->
                        <div class="modal" id="myModal2">
                            <div class="modal-dialog">
                              <div class="modal-content">
                                <div class="modal-body">
                                    <h3> Publish Exam</h3>
                                    <div style="margin-top: 5%;"> 
                                        <p> Are you sure you want to Publish this {{examDetails.fullName}}  Exam? </p>     
                                    </div>
                                    <div class="row" style="margin-top: 5%;">
                                        <div class="col-md-4 col-lg-4 col-sm-4">
                                        </div>
                                        <div class="col-md-4 col-lg-4 col-sm-4">
                                            <a *ngIf="iscreatedby" (click)="publishcourse(examDetails._id,examDetails.categoryID.categoryName)" class="default-btn" style=" margin-right: 10px;" data-dismiss="modal"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Publish</span><i class="bx bx-paper-plane icon-arrow after"></i></a>
                                        </div>
                                        <div class="col-md-4 col-lg-4 col-sm-4">
                                            <a *ngIf="iscreatedby" class="default-btn"  data-dismiss="modal"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Cancel</span><i class="bx bx-paper-plane icon-arrow after"></i></a>
                                        </div>
                                    </div>
                                  
                                </div>
                                
                                <!-- Modal footer -->
                                <!-- <div class="modal-footer">
                                  <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                                </div> -->
                                
                              </div>
                            </div>
                          </div>

        <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-lg-8">
                    <div class="courses-title">
                        <h2>{{examDetails.fullName}}</h2>
                        <!-- <p>{{courseDetails.description}}</p> -->
                    </div>

                    <div class="courses-meta">
                        <ul>
                            <li>
                                <i class='bx bx-folder-open'></i>
                                <span>Category</span>
                                <a >{{examDetails.categoryID.categoryName}}</a>
                            </li>
                            <li>
                                <i class='bx bx-group'></i>
                                <span>Teacher Name</span>
                                <a *ngIf="isstudent" data-toggle="modal" data-target="#myModal1">{{examDetails.teacherDetails ? examDetails.teacherDetails.teacherFirstName : examDetails.createdTeacherFirstName}}</a>
                                <a *ngIf="isteacher">{{examDetails.teacherDetails ? examDetails.teacherDetails.CreatedTeacherFirstName : ''}}</a>                              
                            </li>     
                                 
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4" style="padding-left: 0px;padding-right:0px;">
                    <div class="courses-price">
                        <!-- <div class="courses-review">
                            <div class="review-stars">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>
                            <span class="reviews-total d-inline-block">(2 reviews)</span>
                        </div> -->
                        <!-- <div class="price">Free</div> -->
                        
                        <!-- <a *ngIf="ispublish" class="default-btn" style=" margin-right: 10px;" data-toggle="modal" data-target="#myModal2"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Publish</span><i class="bx bx-paper-plane icon-arrow after"></i></a>
                        <a *ngIf="iscreatedby"(click)="coursedetails(examDetails._id,examDetails.categoryID.categoryName)" class="default-btn" style=" margin-right: 10px;margin-top:5%"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Edit</span><i class="bx bx-paper-plane icon-arrow after"></i></a>
                        <a *ngIf="isUserLoggedIn =='true' && islogin == 'true'"(click)="courseJoin(examDetails._id)" class="default-btn"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Join</span><i class="bx bx-paper-plane icon-arrow after"></i></a>

                        <a *ngIf="isUserLoggedIn =='true' && isenroll == 'true'"(click)="courseEnroll(examDetails._id,examDetails.startDate)" class="default-btn"  style=" margin-left: 10px;"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Enroll</span><i class="bx bx-paper-plane icon-arrow after"></i></a>
                        
                        <a *ngIf="isUserLoggedIn =='false'" [routerLink]="['/student/login']" class="default-btn" style=" margin-left: 10px;"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">  Login</span><i class="bx bx-paper-plane icon-arrow after"></i></a> -->

                        <a *ngIf="ispublish" type="submit" class="button" style=" margin-right: 10px;" data-toggle="modal" data-target="#myModal2"><span class="label" style="font-weight: 600;">Publish</span></a>
                        <a *ngIf="iscreatedby" type="submit" (click)="coursedetails(examDetails._id,examDetails.categoryID.categoryName)" class="button" style=" margin-right: 10px;margin-top:5%"><span class="label" style="font-weight: 600;">Edit</span></a>
                        <a *ngIf="isUserLoggedIn =='true' && islogin == 'true'" type="submit" (click)="courseJoin(examDetails._id,examDetails.startDate)" class="button"><span class="label" style="font-weight: 600;">Join</span></a>

                        <a *ngIf="isUserLoggedIn =='true' && isenroll == 'true'" type="submit" (click)="courseEnroll(examDetails._id,examDetails.startDate)" class="button"  style=" margin-left: 10px;"><span class="label" style="font-weight: 600;">Enroll</span></a>
                        
                        <a *ngIf="isUserLoggedIn =='false'" type="submit" [routerLink]="['/student/login']" class="button" style=" margin-left: 10px;"><span class="label" style="font-weight: 600;">  Login</span></a>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-8">
                <div class="courses-details-image text-center">
                    <img [src]="examDetails.examImage =='' ? 'assets/img/courses/courses1.jpg':examDetails.examImage" alt="image">
                </div>

            </div>

            <div class="col-lg-4">
                <div class="courses-sidebar-information">
                    <ul>
                        <li>
                            <span><i class='bx bxs-graduation'></i> Short Name:</span>
                            {{examDetails.shortName}}
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> Privacy:</span>
                            {{examDetails.privacy}}
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> School Name:</span>
                            {{examDetails.groupID ? examDetails.groupID.groupName : 'Not Applicable'}}
                        </li>
                        <li>
                            <span><i class='bx bx-time'></i> Start Date:</span>
                            {{examDetails.startDate |  date :"MMM dd,yyyy, hh:mm aaa"}}                          
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i> End Date:</span>
                            {{examDetails.endDate |  date :"MMM dd,yyyy, hh:mm aaa"}}
                        </li>            
                              
                        <li>
                            <span><i class='bx bxs-badge-check'></i> Grade:</span>
                            {{examDetails.gradeID ? examDetails.gradeID.gradeName : 'Not Applicable' }}
                        </li>      
                        <li>
                            <span><i class='bx bxs-badge-check'></i> Board:</span>
                            {{examDetails.boardID ? examDetails.boardID.boardName : 'Not Applicable' }}
                        </li>   
                        <li>
                            <span><i class='bx bxs-badge-check'></i> Subject:</span>
                            {{examDetails.subjectID ? examDetails.subjectID.subjectName : 'Not Applicable' }}
                        </li>   
                        <li>
                            <span><i class='bx bxs-badge-check'></i> Language:</span>
                            {{examDetails.languageID ? examDetails.languageID.languageName : 'Not Applicable' }}
                        </li>    
                            
                        <li>
                            <span><i class='bx bxs-badge-check'></i> Exam Level:</span>
                            {{examDetails.level }}
                        </li>         
                        <li>
                            <span><i class='bx bxs-badge-check'></i> Exam Duration:</span>
                            {{examDetails.examLength }} minutes
                        </li>         
                        <li>
                            <span><i class='bx bxs-badge-check'></i> Exam Total Score:</span>
                            {{examDetails.totalScore }}
                        </li>  
                        <li>
                            <span><i class='bx bxs-badge-check'></i> Students Enrolled:</span>
                            {{examDetails.enrolledStudentsCount }}
                        </li>         
                    </ul>
                    <!-- <a *ngIf="iscreatedby"(click)="coursedetails(examDetails._id,examDetails.categoryID.categoryName)" class="default-btn" style=" margin-right: 10px;margin-top:5%"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Edit</span><i class="bx bx-paper-plane icon-arrow after"></i></a> -->
                    <a *ngIf="iscreatedby"  class="default-btn" style="float:right;margin-top:5%;border-radius: 5px;"  data-toggle="modal" data-target="#myModal"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Delete</span><i class="bx bx-paper-plane icon-arrow after"></i></a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8">
               

                <div class="courses-details-desc">                    
                    <h3>Description</h3>
                    <!-- <p><strong>Hi! Welcome to Certified Graphic Design with Free Project Course, the only course you need to become a BI Analyst.</strong></p> -->
                    <p>{{examDetails.description}}</p>
                    <!-- <p><strong>Our program is different than the rest of the materials available online.</strong></p>
                    <p>It is truly comprehensive. The Business Intelligence Analyst Course comprises of several modules:</p> -->
                    <!-- <ul class="description-features-list">
                        <li>Introduction to Data and Data Science</li>
                        <li>Statistics and Excel</li>
                        <li>Database theory</li>
                        <li>SQL</li>
                        <li>Tableau</li>
                    </ul>
                    <p>These are the precise technical skills recruiters are looking for when hiring BI Analysts. And today, you have the chance of acquiring an invaluable advantage to get ahead of other candidates. This course will be the secret to your success. And your success is our success, so let’s make it happen!</p>
                    <p>Here are some more details of what you get with The Business Intelligence Analyst Course:</p>
                    <ul class="description-features-list">
                        <li><strong>Introduction to Data and Data Science</strong> – Make sense of terms like business intelligence, traditional and big data, traditional statistical methods, machine learning, predictive analytics, supervised learning, unsupervised learning, reinforcement learning, and many more;</li>
                        <li><strong>Statistics and Excel</strong> – Understand statistical testing and build a solid foundation. Modern software packages and programming languages are automating most of these activities, but this part of the course gives you something more valuable – critical thinking abilities;</li>
                        <li><strong>Database theory</strong> – Before you start using SQL, it is highly beneficial to learn about the underlying database theory and acquire an understanding of why databases are created and how they can help us manage data;</li>
                        <li><strong>SQL</strong> – when you can work with SQL, it means you don’t have to rely on others sending you data and executing queries for you. You can do that on your own. This allows you to be independent and dig deeper into the data to obtain the answers to questions that might improve the way your company does its business;</li>
                        <li><strong>Tableau</strong> – one of the most powerful and intuitive data visualization tools available out there. Almost all large companies use such tools to enhance their BI capabilities. Tableau is the #1 best-in-class solution that helps you create powerful charts and dashboards;</li>
                        <li>Learning a programming language is meaningless without putting it to use. That’s why we integrate SQL and Tableau, and perform several real-life Business Intelligence tasks;</li>
                    </ul>
                    <p><strong>Sounds amazing, right?</strong></p>
                    <p>Our courses are unique because our team works hard to:</p>
                    <ul class="description-features-list">
                        <li>Pre-script the entire content</li>
                        <li>Work with real-life examples</li>
                        <li>Provide easy to understand and complete explanation</li>
                        <li>Create beautiful and engaging animations</li>
                        <li>Prepare exercises, course notes, quizzes, and other materials that will enhance your course taking experience</li>
                        <li>Be there for you and provide support whenever necessary</li>
                    </ul>
                    <p>We love teaching and we are really excited about this journey. It will get your foot in the door of an exciting and rising profession. Don’t hesitate and subscribe today. The only regret you will have is that you didn’t find this course sooner!</p>
                    <h3>Who this course is for:</h3>
                    <ul class="audience-list">
                        <li>Beginners to programming and data science</li>
                        <li>Students eager to learn about job opportunities in the field of data science</li>
                        <li>Candidates willing to boost their resume by learning how to combine the knowledge of Statistics, SQL, and Tableau in a real-world working environment</li>
                        <li>SQL Programmers who want to develop business reasoning and apply their knowledge to the solution of various business tasks</li>
                        <li>People interested in a Business Intelligence Analyst career</li>
                    </ul>
                    <h3>Meet your instructors</h3> -->
                  

                </div>

                
            </div>

        </div>
    </div>
</section>