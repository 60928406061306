<div *ngIf="isstudent">
    <app-header-style-one></app-header-style-one>
</div>
<div *ngIf="isteacher">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>

<section class="faq-area pb-70">
        <div class="container">
            <div class="col-lg-12 col-md-12" style="margin-bottom: 5%;">
                <div class="about-content">
                    <h3>Press release</h3>
                    <!-- <span class="sub-title" style="color: var(--mainColor);">Management Team</span> -->
                </div>
            </div>
                <!-- <div class="tab-content">
                    <div class="tabs-item"  id="classes">
                        <div class="row">
                                <div class="col-lg-12 col-md-6">
                                        <div class="faq-item">
                                         
                                            <a style="font-size:30px;"href="https://www.businesstalkz.com/2021/09/top-5-emerging-e-learning-companies-in.html?m=1"> - Top 5 Emerging E-Learning Companies in India 2021. BTALKZ- Sep 23, 2021.</a>
                                        </div>
                                    </div> <br>
                                    <div class="col-lg-12 col-md-6">
                                            <div class="faq-item">
                                             
                                                <a style="font-size:30px;"href="https://www.businesstalkz.com/2021/09/interview-with-uday-shankar-ceo-of.html?m=1"> - Interview With Uday Shankar - CEO of buddi.life. BTALKZ- Sep 09, 2021.</a>
                                            </div>
                                        </div>
                                         
                        </div>
                    </div>
                   
                </div> -->
                <!-- <div class="row">
                    <div class="col-md-3">
                            <div class="card" style="width: 18rem; height: 25rem;">
                            <img class="card-img-top" src="https://1.bp.blogspot.com/-K1uA75CDcMs/YUw9Q3-yZLI/AAAAAAAAAUQ/kV9FYpqYiOo7vRhNTN-pVydyAvor1--WACLcBGAsYHQ/s1152/IMG-20210923-WA0016.jpg" alt="Card image cap">
                            <div class="card-body">
                               <p class="card-text"> Top 5 Emerging E-Learning Companies in India 2021.</p>
                              <a href="https://www.businesstalkz.com/2021/09/top-5-emerging-e-learning-companies-in.html?m=1">View Details</a>
                              <p>posted date:23.09.2021</p>
                            </div>
                          </div>
                         </div>
                    
                         <div class="col-md-3">
                            <div class="card" style="width: 18rem; height: 25rem;">
                            <img class="card-img-top any" src="https://1.bp.blogspot.com/-NY831WmPtQ0/YTnhby3YxaI/AAAAAAAAA_M/RSBKU50o-qkWihoFoXuTfxHwy2umD0TxQCLcBGAsYHQ/s320/uday%2Bresized.jpg" alt="Card image cap">
                            <div class="card-body">
                               <p class="card-text">Interview With Uday Shankar - CEO of buddi.life</p>
                              <a href="https://www.businesstalkz.com/2021/09/interview-with-uday-shankar-ceo-of.html?m=1" >View Details</a>
                              <p>posted date:09-09-2021</p>
                            </div>
                          </div>
                         </div>
                    
                      </div> -->
                      <div class="card-deck">
                        <div class="col-lg-4 col-md-6">
                        <div class="card">
                          <img src="../../../../assets/img/E-Learning Companies.jpeg" class="card-img-top" alt="E-Learning Companies">
                          <div class="card-body">
                            <p> Top 5 Emerging E-Learning Companies in India 2021.</p>
                            <a href="https://www.businesstalkz.com/2021/09/top-5-emerging-e-learning-companies-in.html?m=1">View Details</a>
                            <p class="card-text"><small class="text-muted">posted date:23.09.2021</small></p>
                          </div>
                        </div>
                        </div>
                        
                        <div class="col-lg-4 col-md-6">
                        <div class="card">
                          <img src="../../../../assets/img/Interview.jpeg" class="card-img-top" alt="interview">
                          <div class="card-body">
                            <p>Interview With Uday Shankar - CEO of buddi.life</p>
                            <a href="https://www.businesstalkz.com/2021/09/interview-with-uday-shankar-ceo-of.html?m=1" >View Details</a>
                            <p class="card-text"><small class="text-muted">posted date:09-09-2021</small></p>
                          </div>
                        </div>
                    </div>
                      </div>
        </div>
    </section>