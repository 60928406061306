<app-header-style-one *ngIf="!isTeacher"></app-header-style-one>
<app-header-style-one-teacher *ngIf="isTeacher"></app-header-style-one-teacher>
<div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a>Home</a></li>
                <li><a>Classes</a></li>
                <li *ngIf="livedetails.length !=0">{{livedetails.name}} - {{livedetails.ScheduleDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</li>
            </ul>
            <h2>Classes</h2>
        </div>
    </div>
</div>
<div  class="container">
    <div *ngIf="livedetails.length!=0">
        <br>
        <h3>Currently No session Available </h3>
        <br>
    </div>
</div>
<section class="CourseMain"> 
    <div class="container">       
        <div class="courseMainCard">       
            <div class="courseImg">
                <img src="assets/img/jeee.jpg" class="img-fluid" />
            </div>
            <div class="CourseDetails">
                <h2>{{parentTable.name}}</h2>
                <h6>
                    Start Date: {{parentTable.startTime |  date :"MMM dd,yyyy"}}  |  End Date:  {{parentTable.endTime |  date :"MMM dd,yyyy"}}
                </h6>
                <div>
                    <span>
                        No of Session : {{parentTable.numberofsession}}
                    </span>
                </div>
                <div>
                    <span>
                        No of Exam : {{parentTable.numberofexam}}
                    </span>
                </div>
                <div>
                    {{parentTable.categoryID ? parentTable.categoryID.categoryName : '' }}, {{parentTable.gradeID ? parentTable.gradeID.gradeName : '' }}, {{parentTable.boardID ? parentTable.boardID.boardName : '' }}          
                </div>
                <div class="courses-box-footer">
                    {{parentTable.subjectID ? parentTable.subjectID.subjectName : '' }}, {{parentTable.languageID ? parentTable.languageID.languageName : '' }}
                </div>  
                <div class="courses-box-footer">
                    Enrolled Students : 
                    <!-- <b> -->
                        <span *ngIf="studentCount.length!=0"> <studentList [listData]="studentCount"></studentList></span>
            <!--         <studentList *ngIf="studentCount" [list]="studentCount"></studentList>
                    {{studentCount |json}}</b> -->
                </div>
            </div>
            <div class="CourseDetails" >            
                <a class="default-btn mr-1 ml-5" (click)="backto()">    
                    <span class="label">Back</span>
                </a>
                <a class="default-btn " (click)="enroll()" *ngIf="!isTeacher && !isJoin && isLoggedin =='true'">   
                    <i class='bx bx-paper-plane icon-arrow before'></i>
                    <span class="label">Enroll</span>
                    <i class="bx bx-paper-plane icon-arrow after"></i>
                </a>
            </div>
        </div>
        <div class="scheduleMainCard">
            <div class="row">
                <div class="col-md-4">
                    <div class="scheduleTitle">
                        <h3>Schedule</h3>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="scheduleDateMain">
                        <ul>
                            <li  *ngFor="let slide of childTable">
                                <div class="scheduleTitleCard">
                                    <h2>
                                        <small>
                                            {{slide.ScheduleDate |  date :"MMM"}} : {{slide.ScheduleDate |  date     :"dd"}}
                                        </small>
                                        {{slide.time}}
                                    </h2>
                                    <div class="scheduleDescription">
                                        <h5>{{slide.lession}}</h5>
                                       <h6>{{slide.topic}}</h6>
                                        <p>{{slide.description}}</p>
                                    </div>
                                </div>
                                <span class="btn btnLive" *ngIf="isLoggedin =='false'" (click)="login()">
                                    Login
                                </span>
                                <span class="btn btnLive" *ngIf="isLoggedin =='true' && slide.isEnable && !isTeacher && isJoin" (click)="meetingsaction(slide.meetingID,'join',parentTable)">
                                    Live
                                </span>
                                <span class="btn btnLive" *ngIf="isLoggedin =='true' && isTeacher && slide.isEnable" (click)="meetingsaction(slide.meetingID,'start',parentTable)">
                                    Start
                                </span>                            
                            </li>
                        </ul>
                    </div>
                </div>
            </div>            
        </div>
    </div>
</section>
<section class="courses-details-area pt-100 pb-70" *ngIf="false"> 
    <div class="container">
        <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-lg-5">
                    <div class="courses-title">
                        <h2>
                            {{livedetails.name}} 
                        </h2>                       
                    </div>
                    <div class="courses-meta">
                        <ul>
                            <li>
                                <i class='bx bx-folder-open'></i>
                                <span>Category</span>
                                <a>{{livedetails.categoryID.categoryName}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-7">
                    <div class="courses-price">
                        <div class="courses-review">
                        </div>
                        <div class="price"></div>                       
                        <a  class="default-btn mr-1" (click)="backto()"  class="default-btn mr-1 back-btn">
                            Back
                        </a>
                        <a href="javascript:void(0)" *ngIf="isLoggedin =='false'" (click)="login()" class="default-btn  mr-1">
                            <i class='bx bx-paper-plane icon-arrow before'></i>
                            <span class="label">Login</span>
                            <i class="bx bx-paper-plane icon-arrow after"></i>
                        </a>
                        <a href="javascript:void(0)" *ngIf="isLoggedin =='true' && !livedetails.alreadyUserEnrolled" (click)="enroll()" class="default-btn  mr-1">
                            <i class='bx bx-paper-plane icon-arrow before'></i>
                            <span class="label">Enroll</span>
                            <i class="bx bx-paper-plane icon-arrow after"></i>
                        </a>
                        <a href="javascript:void(0)" *ngIf="isLoggedin =='true' && livedetails.alreadyUserEnrolled" (click)="enroll()" class="default-btn  mr-1">
                            <i class='bx bx-paper-plane icon-arrow before'></i>
                            <span class="label">Start</span>
                            <i class="bx bx-paper-plane icon-arrow after"></i>
                        </a>
                    </div>                    
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8">
                <div class="courses-details-image text-center">
                    <img [src]="'assets/img/courses/courses1.jpg'" alt="image">                   
                </div>
                <div class="mt-5">
                    <label>
                        Description
                    </label>
                    <div class="col-lg-12">
                        {{livedetails.meetingdescription =='' ? '-' : livedetails.meetingdescription}}
                    </div>
                </div>               
            </div>
            <div class="col-lg-4">
                <div class="courses-sidebar-information">
                    <ul>
                        <li>
                            <span><i class='bx bx-group'></i> Class ID:</span>
                            {{livedetails.meetingID}}
                        </li>
                        <li>
                            <span><i class='bx bx-time'></i> Class Name:</span>
                            {{livedetails.name}}
                        </li>
                        <li *ngIf="livedetails.classType =='One-on-One'">
                            <span><i class='bx bx-time'></i> Class Type:</span>
                            One-on-One
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i> Class Start Date:</span>
                            {{livedetails.startdate}}
                        </li>
                         <li>
                            <span><i class='bx bx-tachometer'></i> Class End Date:</span>
                            {{livedetails.enddate}}
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i> Class Datetime:</span>
                            {{livedetails.ScheduleDate | date :"MMM dd,yyyy hh:mm aaa"}}
                        </li>
                        <li>
                            <span><i class='bx bx-support'></i> Student Count:</span>
                            {{livedetails.studentCount =='' ? '-' : livedetails.studentCount}}
                        </li>                        
                        <li>
                            <span><i class='bx bx-tachometer'></i>Number of Exam:</span>
                            {{livedetails.numberofexam ? livedetails.numberofexam : '-'}}
                        </li>
                         <li>
                            <span><i class='bx bx-tachometer'></i>Number of Exam:</span>
                            {{livedetails.numberofexam ? livedetails.numberofexam : '-'}}
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i>Grade:</span>
                            {{livedetails.gradeID ? livedetails.gradeID.gradeName: ''}}
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i>Board:</span>
                            {{livedetails.boardID ? livedetails.boardID.boardName: ''}}
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i>Subject:</span>
                            {{livedetails.subjectID ? livedetails.subjectID.subjectName: ''}}
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i>Language:</span>
                            {{livedetails.languageID ? livedetails.languageID.languageName: ''}}
                        </li>                                      
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <img src="assets/img/spinner-default.gif" alt="">
</ngx-spinner>
