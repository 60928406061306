
<div class="container-fluid row p-0" style="cursor: pointer" *ngIf="data.length !=0">
    <div class="col-lg-3 col-md-6" *ngFor="let slide of data | paginate: config">
        <div class="single-courses-box without-box-shadow mb-30">
            <div class="courses-image">
                <img style="width: 250px;height: 175px;" (click)="redirectDetails(slide)" [src]="slide.thumbname ? slide.thumbname : 'assets/img/courses/courses1.jpg'" alt=""  width="100%">
            </div>
            <div class="courses-content">
                <div class="course-author d-flex align-items-center" *ngIf="slide.studentID !=undefined">
                	<img [src]="slide.studentID!=undefined && slide.studentID.userPhoto!='' ? slide.studentID.userPhoto  :'assets/img/user1.jpg'" class="rounded-circle mr-2" alt="image">
                	<span *ngIf="slide.studentID!=undefined">{{slide.studentID.profileName}}
                    </span>
                </div>
                <div class="course-author d-flex align-items-center" *ngIf="slide.studentID ==undefined">
                	<img [src]="slide.teacherID!=undefined && slide.teacherID.userPhoto!='' ? slide.teacherID.userPhoto  :'assets/img/user1.jpg'"  class="rounded-circle mr-2" alt="image">
                    <span *ngIf="slide.teacherID!=undefined">{{slide.teacherID.profileName}}
                    </span>
                    <img  class="recordIcon" src="assets/img/recordingicon.png"  *ngIf="slide.isrecorded" (click)="fetchrecordvideo(slide._id,'meetingId')" style="">
                </div>
                <h3>
                    <a (click)="redirectDetails(slide)" class="d-inline-block">
                    	<span class="text-break">{{slide.flimname}}</span>
                    </a>
                </h3>
            </div>
            <list5 [slide]="slide" [hideData]="config.hideType"></list5>     
        </div>
    </div>
</div> 
<pagination-controls class="float-right" [id]="config.id" *ngIf="data.length!=0" (pageChange)="pageChanged($event)"></pagination-controls>