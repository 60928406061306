import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../shared/api.service';
@Component({
  selector: 'app-metaverse',
  templateUrl: './metaverse.component.html',
  styleUrls: ['./metaverse.component.scss']
})
export class MetaverseComponent implements OnInit {
public hide:boolean=false;
  public meta: boolean = true;
    public meta1: boolean = false;
  public isStudent: boolean = false;
  public isTeacher: boolean = false;
  constructor() { }

  ngOnInit(): void {
    this.isTeacher =
            localStorage.getItem("profileType").toLowerCase() == "teacher";
        this.isStudent =
            localStorage.getItem("profileType").toLowerCase() == "student";
  }

}