import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from "@angular/common";

@Component({
  selector: 'app-tutorials',
  templateUrl: './tutorials.component.html',
  styleUrls: ['./tutorials.component.scss']
})
export class TutorialsComponent implements OnInit {
  public isStudent: boolean = false;
  public isTeacher: boolean = false;
  tutvdo=true;
  leavdo=false;
  constructor( private router: Router, private location: Location,) { }

  ngOnInit(): void {
    this.isTeacher =
            localStorage.getItem("profileType").toLowerCase() == "teacher";
        this.isStudent =
            localStorage.getItem("profileType").toLowerCase() == "student";
  }
  tutor(){
    this.tutvdo=true;
    this.leavdo=false;
  }
  learn(){
    this.tutvdo=false;
    this.leavdo=true;
  }
  backto() {
    this.location.back()
  }
}
