import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Options } from 'ng5-slider';
import { ApiService } from '../../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-invitemeeting',
  templateUrl: './invitemeeting.component.html',
  styleUrls: ['./invitemeeting.component.scss']
})
export class InvitemeetingComponent implements OnInit {

  inviteForm: FormGroup;
  submitted = false;
  fullName = "";
  constructor(private my_router: ActivatedRoute,public toastr: ToastrManager, private formBuilder: FormBuilder, private rest: ApiService, private router: Router ) { }
  ngOnInit() {
  this.router.navigate(['student/login']);
    const passid = this.my_router.params['value'].id;
    var split_id = passid.split('-');
    this.inviteForm = this.formBuilder.group({
      fullName: ['', Validators.required],
      meetingId : [split_id[1]]
    });
     this.router.navigate(['/enroll/'+"/"+passid]);
    if(localStorage.getItem('isLoggedin') == 'true'){
      this.fullName = localStorage.getItem('profileName')
    }


  }
  get f() { return this.inviteForm.controls; }

  onSubmit(form) {
    this.submitted = true;
    if (this.inviteForm.invalid) {
      return;
    }
    const data = this.inviteForm.value;
    this.rest.inviteMeeting(data).subscribe((result) => {
     if(result.status) {
        window.open(result.data, "_blank");
      } else {
        this.toastr.errorToastr(result.message);
     }
    }, (err) => {
    console.log(err);
    });


  }

}
