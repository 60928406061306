import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../shared/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-menu-dropdown',
  templateUrl: './menu-dropdown.component.html',
  styleUrls: ['./menu-dropdown.component.scss']
})
export class MenuDropdownComponent implements OnInit {
  public isstudent = false;
  public isteacher = false;
  isUserLoggedIn: string;  
  public coursecategory: any = [];

  constructor(  private userApi: ApiService,
    private SpinnerService: NgxSpinnerService,
    public toastr: ToastrManager,
    private router: Router) { }

  ngOnInit(): void {
    const userType = localStorage.getItem('userType');      
    this.isUserLoggedIn = localStorage.getItem('isLoggedin');
    if(this.isUserLoggedIn=="true") {
      if(userType.toLowerCase()=="teacher") {
        this.isteacher = true;
      }
      else {
        this.isstudent = true;
      }
    }
    else {
      this.isstudent = true;
    }
    this.userApi.getCourseCategoryModuleHeader().subscribe((result) => {
      console.log('result',result);
      if (result.status) {
        this.coursecategory = result.data;
      } else {
      }
    }, (err) => {
      console.log(err);
    });

  
  }
  
  selectedmenu(categoryid, moduleid) {
    this.SpinnerService.show();
    var req = {
      moduleID : moduleid
    }
    this.userApi.getClassCourseTemplatefromModule(req).subscribe((result) => {
      this.SpinnerService.hide();
      if(result.status){
        if(result.data.templateList.length > 1){
          this.router.navigate(['/class-course-list/' + moduleid]);
        }
        else{
          var templateID = result.data.templateList[0]._id;
          if(this.isteacher){
            this.router.navigate(['/ClassCourseDetails/' + templateID]);
          }
          else{
            this.router.navigate(['/StudentCourseDetails/' + templateID]);
          }
          
        }
      }
      else{
        this.toastr.errorToastr("There was an issue. Please try again later.")
      }
    });
  }
}
