<app-header-style-one *ngIf="!isTeacher"></app-header-style-one>
    <app-header-style-one-teacher *ngIf="isTeacher"></app-header-style-one-teacher>

<div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content" >
            <ul>
                <li><a>Home</a></li>
                <li><a>Classes</a></li>
                <li> {{livedetails && livedetails.name}} - {{livedetails && livedetails.startDateTime ? livedetails.startDateTime : 'NA'}}</li>
            </ul>
            <h2>Classes</h2>
        </div>
    </div>
</div>

<section class="courses-details-area pt-70 pb-70">
    <div class="container">
        <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-lg-5">
                    <div class="courses-title">
                        <h2>Study hall - {{livedetails && livedetails.name}}
                        </h2>
                       
                    </div>

                    <div class="courses-meta">
                        <ul>
                            <li>
                                <i class='bx bx-folder-open'></i>
                                <span>Category</span>
                                <a>{{livedetails && livedetails.personalClassID.categoryID.categoryName}}</a>
                            </li>
                            <!-- <li>
                                <i class='bx bx-calendar'></i>
                                <span>Last Updated</span>
                                <a routerLink="/single-courses">{{livedetails.ScheduleDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</a>
                            </li> -->
                        </ul>
                    </div>
                </div>

                <div class="col-lg-7">
                    <div class="courses-price">
                        <div class="courses-review">
                        </div>
                        <div class="price"></div>
                        <span *ngIf="viewRequest" class="mt-0 ml-2 mr-1">
                            <button  class="default-btn" disabled>Request Pending</button>
                            <!-- <span class="color: var(--mainColor)">Your Request Still Pending</span> -->
                         </span>
                        <button  class="default-btn mr-1 p-2 " (click)="backto()"><i class="fas fa-long-arrow-alt-left kin"></i>
                            Back
                        </button>
                        
                        <span *ngIf="!viewRequest">
                           
                            <button *ngIf="meetingsactionText == 'Start' && !livedetails.isrecorded" href="javascript:void(0)" (click)="meetingsaction(meetingIDpopup,'start',livedetails)" class="default-btn mr-1">            
                                <i class='bx bx-paper-plane icon-arrow before'></i>
                                <span class="label">Start Class</span>
                                <i class="bx bx-paper-plane icon-arrow after"></i>
                            </button>
                            <button *ngIf="meetingsactionText == 'Join' && !livedetails.isrecorded" href="javascript:void(0)" (click)="meetingsaction(meetingIDpopup,'join',livedetails)" class="default-btn mr-1"> 
                                
                                <i class='bx bx-paper-plane icon-arrow before'></i>
                                <span class="label">Join</span>
                                <i class="bx bx-paper-plane icon-arrow after"></i>
                            </button>
                             <button *ngIf="livedetails.isrecorded" href="javascript:void(0)" (click)="fetchrecordvideo(livedetails._id,isone2one ? 'requestId':'meetingId')" class="default-btn mr-1">
                               
                                <i class='bx bx-paper-plane icon-arrow before'></i>
                                <span class="label">Playback</span>
                                <i class="bx bx-paper-plane icon-arrow after"></i>
                            </button>  
                            <button *ngIf="meetingsactionText == 'Login'" href="javascript:void(0)" (click)="meetingsaction(meetingIDpopup,'login',livedetails)" class="default-btn  mr-1">
                                <i class='bx bx-paper-plane icon-arrow before'></i>
                                <span class="label">Login</span>
                                <i class="bx bx-paper-plane icon-arrow after"></i>
                            </button>
                            <button *ngIf="meetingsactionText == 'Start' && !livedetails.isrecorded" title="Click to Copy the invite url"  (click)="copyMessage()" href="javascript:void(0)" class="default-btn mr-1">          
                                <i class="far fa-copy mr-1 icon-arrow before"></i>
                                <span class="label">Invite</span>
                                <i class="far fa-copy mr-1 icon-arrow after"></i>
                            </button>
                            
                        </span>
                      
                        <!-- <a routerLink="/single-courses" class="default-btn"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Buy Course</span><i class="default-btn"></i></a> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-8">
                <div class="courses-details-image text-center">
                    <img [src]="livedetails.thumbnail !='' ? livedetails.thumbnail : 'assets/img/courses/courses1.jpg'" alt="image">
                </div>
                <div class="mt-5">
                    <label>
                        Description
                    </label>
                    <div class="col-lg-12 text-break">
                            {{livedetails.personalClassID.description ? livedetails.personalClassID.description : ''}}
                    </div>
                </div>
               
            </div>

            <div class="col-lg-4">
                <div class="courses-sidebar-information">
                    <ul>
                        <li>
                            <span><i class='bx bx-group'></i> Class ID:</span>
                            {{livedetails.meetingID}}
                        </li>
                        <li>
                            <span><i class='bx bx-time'></i> Class Name:</span>
                            {{livedetails.name}}
                        </li>
                         <li *ngIf="livedetails.classType !='One-on-One'">
                            <span><i class='bx bx-time'></i> Meeting Type:</span>
                            {{livedetails.meetingType}}
                        </li>
                        <li>
                            <span><i class='bx bx-time'></i> Teacher:</span>
                            {{livedetails.teacherID.firstName ? livedetails.teacherID.firstName : ''}} {{livedetails.teacherID.lastName ? livedetails.teacherID.lastName : ''}} 
                        </li>
                        <li *ngIf="livedetails.classType =='One-on-One'">
                            <span><i class='bx bx-time'></i> Class Type:</span>
                            One-on-One
                        </li>
                        <!-- <li>
                            <span><i class='bx bx-time'></i> Group Name:</span>
                            {{livedetails.groupname}}
                        </li> -->
                        <li>
                            <span><i class='bx bx-tachometer'></i> Class Date and Time:</span>
                            {{livedetails.startDateTime}}
                        </li>
                        <li>
                            <span><i class='bx bx-support'></i> Frequency:</span>
                            {{livedetails.occurance =='' ? '-' : livedetails.occurance}}
                        </li>
                        
                        <li>
                            <span><i class='bx bx-tachometer'></i>Duration:</span>
                            {{livedetails.totalClassTime ? livedetails.totalClassTime +'Min' : '-'}}
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i>Module:</span>
                            {{livedetails.personalClassID.moduleID ? livedetails.personalClassID.moduleID.moduleName : ''}}
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i>Topic:</span>
                            {{livedetails.personalClassID.topicID ? livedetails.personalClassID.topicID.topicName : ''}}
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i>Language:</span>
                            {{livedetails.personalClassID.languageID ? livedetails.personalClassID.languageID.languageName: ''}}
                        </li>
                        
                       <li>
                            <span><i class='bx bxs-badge-check'></i> Tag:</span>
                            {{livedetails.tag =='' ? '-' : tag}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <img src="assets/img/spinner-default.gif" alt="">
</ngx-spinner>