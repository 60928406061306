import { Component, OnInit,ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Options } from 'ng5-slider';
import { ApiService } from '../../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { Location } from '@angular/common';
import * as moment from 'moment';
import { MediaService } from '../../../../shared/media.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ViewChild } from '@angular/core';
import { StarRatingComponent } from 'ng-starrating';

@Component({
  selector: 'app-student-video-details',
  templateUrl: './student-video-details.component.html',
  styleUrls: ['./student-video-details.component.scss']
})
export class StudentVideoDetailsComponent implements OnInit {
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  isLoggedin = "false";
  public teacherID = "";
  public meetingimage = "";
  public meetingurl = "";
  public meetingsactionText = "";
  public meetingCategorypopup = "";
  public meetingScheduleDatepopup = "";
  public meetingrunningpopup = "";
  public meetingIDpopup = "";
  public meeting_ID = "";
  public meetingname = "";
  public meetingScheduleDate = "";
  public inviteUrl = "";
  public frontwebsite = environment.frontUrl;
  public redirectUrl = "";
  public meetingDuration = '';
  public meetingLanguage = '';
  public meetingCity = '';
  public meetingoccurance = '';
  public meetingdescription = '';
  public livedetails:any = [];
  public profileName = '';
  public meetingId='';
  public urlArray:any=[];
  public pagename:any = '';
  public tag:any='';
  public groupName:any;
  public isStudent:any;
  public isEntroll:any=false;
  public isteacherRoute:any =false;
  public isrequest:boolean = false;
  public alreadywatched:boolean = false;
  public liked:boolean = true;
  public unliked:boolean = false;
  public userId:any='';
  public submitted:boolean = false;
  public enrolledCount:Number = 0;
  reviewForm: FormGroup;
  reviewlist:any = [];
  reviewLike:any=[];
  userlike:any=[];
  public viewCount:Number = 0;
  public upvoteCount:Number = 0;
  public downvoteCount:Number = 0;
  public name:String = '';
  public tagArray :any = [];
  public isPartnerUser:any = [];
  public categoryarrayvalues;
  public subjectarrayvalues;
  public is0p5star = false;
  public is1star = false;
  public is1p5star = false;
  public is2star = false;
  public is2p5star = false;
  public is3star = false;
  public is3p5star = false;
  public is4star = false;
  public is4p5star = false;
  public is5star = false;
  public gradearrayvalues;
  constructor(private mediaAPI:MediaService,private location:Location,private activatedRoute: ActivatedRoute,public toastr: ToastrManager, private formBuilder: FormBuilder, private rest: ApiService, private router: Router,private SpinnerService: NgxSpinnerService,private sanitizer: DomSanitizer,private datePipe: DatePipe) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.userId=  localStorage.getItem('userId');  
    this.isLoggedin = localStorage.getItem('isLoggedin');
    this.teacherID = localStorage.getItem('teacherID');
    const webToken = localStorage.getItem('webtoken');
    this.name =  localStorage.getItem('profileName')
    this.isrequest = window.location.href.match('enroll') !=null ? true : false;
    this.isPartnerUser = localStorage.getItem('isPartner') =='true'?true:false;
    this.isteacherRoute = localStorage.getItem('profileType').toLowerCase()=='teacher'
      this.reviewForm = this.formBuilder.group({
        userType:'',
        itemId:null,
        ratingget:null,
        comment:['',Validators.required],
        rating: [5,Validators.required]

      });   
      var req={
        videoID:this.activatedRoute.snapshot.params.id
      }
      this.mediaAPI.getUploadedVideosByID(req,webToken).subscribe((result) => {
        if(result.status){
          this.livedetails = result.data;
          let categoryarray:any=[];
          let gradearray:any=[];
          let subjectarray:any=[];
          this.viewCount = this.livedetails.viewCount;
          this.upvoteCount = this.livedetails.upvotes;
          this.downvoteCount = this.livedetails.downvotes;
          if(this.livedetails.rating != "" || this.livedetails.rating != null) {
            if(this.livedetails.rating == 0.5) {
              setTimeout(() => {
                this.is0p5star = true;
              },
                500);
            }
            else if(this.livedetails.rating == 1) {
              setTimeout(() => {
                this.is1star = true;
              },
                500);
            }
            else if(this.livedetails.rating == 1.5) {
              setTimeout(() => {
                this.is1p5star = true;
              },
                500);
            }
            else if(this.livedetails.rating == 2) {
              setTimeout(() => {
                this.is2star = true;
              },
                500);
            }
            else if(this.livedetails.rating == 2.5) {
              setTimeout(() => {
                this.is2p5star = true;
              },
                500);
            }
            else if(this.livedetails.rating == 3) {
              setTimeout(() => {
                this.is3star = true;
              },
                500);
            }
            else if(this.livedetails.rating == 3.5) {
              setTimeout(() => {
                this.is3p5star = true;
              },
                500);
            }
            else if(this.livedetails.rating == 4) {
              setTimeout(() => {
                this.is4star = true;
              },
                500);
            }
            else if(this.livedetails.rating == 4.5) {
              setTimeout(() => {
                this.is4p5star = true;
              },
                500);
            }
            else if(this.livedetails.rating == 5) {
              setTimeout(() => {
                this.is5star = true;
              },
                500);
            }           
          }
          if(this.livedetails.categoryID.length > 0) {
            for(var i=0;i < this.livedetails.categoryID.length; i++) {
              categoryarray.push(this.livedetails.categoryID[i].categoryName);
            }
            this.categoryarrayvalues = categoryarray.toString();
          }

          if(this.livedetails.subjectID.length > 0) {
            for(var i=0;i < this.livedetails.subjectID.length; i++) {
              subjectarray.push(this.livedetails.subjectID[i].subjectName);
            }
            this.subjectarrayvalues = subjectarray.toString();
          }

          if(this.livedetails.gradeID.length > 0) {
            for(var i=0;i < this.livedetails.gradeID.length; i++) {
              gradearray.push(this.livedetails.gradeID[i].gradeName);
            }
            this.gradearrayvalues = gradearray.toString();
          }
          if(this.livedetails.length!=0 && this.livedetails.tag !=''){
            this.tagArray = this.livedetails.tag.split(',')
          }
          this.reviewForm.patchValue({'itemId':this.livedetails._id})
          this.reviewGet(this.livedetails._id);
          this.reviewLikeGet(this.livedetails._id);
          this.getLikereviewsByUser(this.livedetails._id);
          if(!this.isteacherRoute){
            var data={ 
                itemId : this.livedetails._id,
                itemType :'video'
              }
            this.rest.updateviewCount(data,localStorage.getItem('webtoken')).subscribe((response) => {
              if(response.status){
                this.reviewGet(this.livedetails._id);
              }
            });
          }            
      
        }
      });
   

  }

  onRate($event:{oldValue:number, newValue:number, starRating:StarRatingComponent}) {
    alert(`Old Value:${$event.oldValue}, 
      New Value: ${$event.newValue}, 
      Checked Color: ${$event.starRating.checkedcolor}, 
      Unchecked Color: ${$event.starRating.uncheckedcolor}`);
  }

  backto(){
    this.location.back();
  }
  // toggleVideo(event: any) {
  //   alert("coming in");
  //   if(this.alreadywatched == false) {
  //     alert("false");
  //     var data={ videoID : this.livedetails._id}
  //     alert(this.livedetails._id);
  //      this.rest.uploadvideoupdateviewCount(data,localStorage.getItem('webtoken')).subscribe((response) => {
  //        if(response.status){
  //          this.viewCount = +this.viewCount + 1;
  //          this.alreadywatched = true;
  //        }
  //      });
  //   }   
  // }

  playvideo(event: any) {
    if(this.alreadywatched == false) {
      var data={ videoID : this.livedetails._id}
       this.rest.uploadvideoupdateviewCount(data,localStorage.getItem('webtoken')).subscribe((response) => {
         if(response.status){
           this.viewCount = +this.viewCount + 1;
           this.alreadywatched = true;
         }
       });
    }   
  }

  videoEnded() {
    this.alreadywatched = false;
  }

    reviewLikeGet(id){
    var req={
      itemId : id
     }
      this.SpinnerService.show()
     this.rest.getLikereviews(req,localStorage.getItem('webtoken')).subscribe((response) => {
      this.SpinnerService.hide()
      if(response.status){
        this.reviewLike = response.data;
        // this.toastr.successToastr(response.message);
        // this.backto();
      }else{
        this.reviewLike =[];
        // this.toastr.errorToastr(response.message);
      }

    });
  }
  getLikereviewsByUser(id){
    var req={
      itemId : id
     }
     this.SpinnerService.show()
     this.rest.getLikereviewsByUser(req,localStorage.getItem('webtoken')).subscribe((response) => {
      this.SpinnerService.hide()
      if(response.status){
        this.userlike = response.data;
        // this.toastr.successToastr(response.message);
        // this.backto();
      }else{
        this.userlike = [];
        // this.toastr.errorToastr(response.message);
      }

    });
  }
  reviewGet(id){
    var req={
      itemId : id,
      itemtype : 'video'
     }
     this.rest.getreviews(req,localStorage.getItem('webtoken')).subscribe((response) => {
      console.log(response);
      if(response.status){
        this.reviewlist = response.data.data;
        this.enrolledCount = response.data.enrolledlCount.length!=0 ? response.data.enrolledlCount.length : 0;
        this.viewCount = response.data.viewCount.length!=0 ? response.data.viewCount.length : 0;

    //     if(this.viewCount ==0 && this.isLoggedin=='true'){
    //          var req={ videoid : this.reviewForm.value.userId,
    //           viewCount:Number(this.viewCount)+1
    //          }
    //       this.rest.saveviewcount(req,localStorage.getItem('webtoken')).subscribe((response) => {
    //         console.log(response)

    //       });
        
    //   }else{
    //     if(this.isLoggedin =='true'){
    //       var req1={ videoid : this.reviewForm.value.userId,
    //         viewCount:Number(this.viewCount)+1
    //       }
    //       this.rest.updateViewCount(req1,localStorage.getItem('webtoken')).subscribe((response) => {
    //         console.log(response);
    //       });
    //     }
        
    // }
        // this.toastr.successToastr(response.message);
        // this.backto();
      }else{
        // if(response.code =404){
        //      var req={ videoid : this.reviewForm.value.userId,
        // likecount : 0,
        // name : this.name,
        // isLike : false}
        // this.rest.reviewLikeSave(req,localStorage.getItem('webtoken')).subscribe((response) => {
        //     if(!response.status){
        //       this.toastr.errorToastr(response.message);
        //     }

        // });
        // }
        // this.toastr.errorToastr(response.message);
      }

    });
  }
  get f() {
    return this.reviewForm.controls;
  }
submitComment(form){
    this.submitted = true;
    if(form.invalid){
      return false;
    }else{
    var id = form.value.itemId
      var req={ itemId : form.value.itemId,
        likecount : 0,
        dislikecount : 0,
        starrating : form.value.rating,
        comment : form.value.comment,
        name : this.name}
      this.rest.Addreviews(req,localStorage.getItem('webtoken')).subscribe((response) => {
        console.log(response);
        if(response.status){
          // this.toastr.successToastr(response.message);
          this.reviewGet(id);
          this.submitted =false;
          this.reviewForm.patchValue({'comment':''})
          // this.reviewForm.reset();
          // this.backto();
        }else{
          this.toastr.errorToastr(response.message);
        }

      });
    }
    console.log(form)
  }

  upvote() {
    var data={ videoID : this.livedetails._id}
    this.rest.videoupvote(data,localStorage.getItem('webtoken')).subscribe((response) => {
      if(response.status){
        this.liked = true;
        this.unliked = false;
      }
    });
  }

  downvote() {
    var data={ videoID : this.livedetails._id}
    this.rest.videodownvote(data,localStorage.getItem('webtoken')).subscribe((response) => {
      if(response.status){
        this.unliked = true;
        this.liked = false;
      }
    });
  }
  likeDislike(data,type){
    if(type=='like'){
      data.likecount = data.likecount+1;
      this.rest.updatereviews(data,localStorage.getItem('webtoken')).subscribe((response) => {
        console.log(response);
        if(response.status){
          this.reviewGet(this.livedetails._id);
          this.submitted =false;
        }else{
          this.toastr.errorToastr(response.message);
        }

      });
    }else{      
      // data.dislikecount = data.dislikecount+1;
      // this.rest.updatereviews(data,localStorage.getItem('webtoken')).subscribe((response) => {
      //   console.log(response);
      //   if(response.status){
      //     this.reviewGet(this.livedetails._id);
      //     this.submitted =false;
      //   }else{
      //     this.toastr.errorToastr(response.message);
      //   }

      // });
    }
  }
  hideAddComment(){
    if($('#demo')[0].classList[1] == 'show'){
      $('#demo').hide();
    }else{
      $('#demo').show();
    }
   
  }
  likeReview(form,text){
    if(this.userlike.length ==0 && !this.isteacherRoute){
      var req={ itemId : form.value.itemId,
        likecount : 1,
        name : this.name,
        isLike : true}
        this.SpinnerService.show()
      this.rest.reviewLikeSave(req,localStorage.getItem('webtoken')).subscribe((response) => {
        this.SpinnerService.hide()
        if(response.status){
          this.reviewLikeGet(this.livedetails._id);
           this.getLikereviewsByUser(this.livedetails._id);
        }else{
          this.toastr.errorToastr(response.message);
        }
       
      });
    }else{
      return false
    }   
  }
  hideShowComment(){
    if($('#demo1')[0].classList[1] == 'show'){
      $('#demo1').hide();
    }else{
      $('#demo1').show();
      
      $('#demo').hide();
    }
   
  }
  
  deletevideo(){
    this.SpinnerService.show()
    var req={
      video_id : this.livedetails._id,
      }
      
    this.mediaAPI.delByVideo(req).subscribe((result) => {
         this.SpinnerService.hide()
      if(result.status){
        this.toastr.successToastr(result.message);          
        this.location.back();
      }else{
        this.toastr.errorToastr(result.message);          
      }
    });
  }
 
  login(){
     var data={
      isredirect:true
    }
    this.router.navigate(['/student/login'],{state:data});
  }
  requestAccept(){
    const webToken = localStorage.getItem('webtoken');
    var req={
      id : this.livedetails._id,
      meetingType  :this.livedetails.securitytype,
      libraryType :'videoId',
      
    }
    this.rest.libraryEnroll(req,webToken).subscribe((result) => {
      console.log(result)
      if(result.status){
        this.toastr.successToastr('Video Added to your Library');          
        var data = {
          message:'Added'
        }  
        this.router.navigate(['/success/library'], { state: data });  
      }else{
        this.toastr.errorToastr(result.message);          
      }
    });
  }

  editvideo(){
    this.router.navigate(['/video-edit/'+this.livedetails._id]);
  }
  removeFromList(data){
    var req={
    "libraryType":"videoId",
    "libraryId":data._id
    }
    const webToken = localStorage.getItem('webtoken');
    this.rest.removeList(req,webToken).subscribe((result) => {
      if(result){
        this.toastr.successToastr(result.message);          
        var data = {
          message:'Removed'
        }  
        this.router.navigate(['/success/library'], { state: data });    
      }else{
        this.toastr.errorToastr(result.message);          
      }
    })    
  }

}

