import { BrowserModule  } from '@angular/platform-browser';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { RatingModule } from 'ng-starrating';


 //const config: SocketIoConfig = { url: 'http://3.7.151.172:4500', options: {} };
// const config: SocketIoConfig = { url: 'https://api.buddi.life:4500', options: {} };
const config: SocketIoConfig = { url: 'https://staging.buddi.life:4500', options: {} };
//const config: SocketIoConfig = { url: 'http://localhost:4500', options: {} };
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageOneComponent } from './components/pages/home-page-one/home-page-one.component';
import { HomePageTwoComponent } from './components/pages/home-page-two/home-page-two.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { HomeoneAboutComponent } from './components/pages/home-page-one/homeone-about/homeone-about.component';
import { HomeoneMainBannerComponent } from './components/pages/home-page-one/homeone-main-banner/homeone-main-banner.component';
import { HeaderStyleOneComponent } from './components/common/header-style-one/header-style-one.component';
import { FunfactsComponent } from './components/common/funfacts/funfacts.component';
import { HomeoneCoursesComponent } from './components/pages/home-page-one/homeone-courses/homeone-courses.component';
import { FeedbackStyleOneComponent } from './components/common/feedback-style-one/feedback-style-one.component';
import { HometwoMainBannerComponent } from './components/pages/home-page-two/hometwo-main-banner/hometwo-main-banner.component';
import { HometwoAboutComponent } from './components/pages/home-page-two/hometwo-about/hometwo-about.component';
import { HometwoCoursesComponent } from './components/pages/home-page-two/hometwo-courses/hometwo-courses.component';
import { StudentsFeedbackFormComponent } from './components/common/students-feedback-form/students-feedback-form.component';
// import { HomePageThreeComponent } from './components/pages/home-page-three/home-page-three.component';
import { K12FeedbackComponent } from './components/pages/k12/k12-feedback/k12-feedback.component';
import { HomePageFourComponent } from './components/pages/home-page-four/home-page-four.component';
import { HomePageFiveComponent } from './components/pages/home-page-five/home-page-five.component';
import { HomePageSevenComponent } from './components/pages/home-page-seven/home-page-seven.component';
import { OfferComponent } from './components/common/offer/offer.component';
import { CategoriesStyleTwoComponent } from './components/common/categories-style-two/categories-style-two.component';
// import { HomethreeCoursesComponent } from './components/pages/home-page-three/homethree-courses/homethree-courses.component';
// import { HomethreeAboutComponent } from './components/pages/home-page-three/homethree-about/homethree-about.component';
import { FeedbackFormComponent } from './components/common/feedback-form/feedback-form.component';
// import { HomethreeMainBannerComponent } from './components/pages/home-page-three/homethree-main-banner/homethree-main-banner.component';
import { HomefourMainBannerComponent } from './components/pages/home-page-four/homefour-main-banner/homefour-main-banner.component';
import { HomefourCoursesComponent } from './components/pages/home-page-four/homefour-courses/homefour-courses.component';
import { FeedbackStyleTwoComponent } from './components/common/feedback-style-two/feedback-style-two.component';
import { HomefiveCoursesComponent } from './components/pages/home-page-five/homefive-courses/homefive-courses.component';
import { HomefiveMainBannerComponent } from './components/pages/home-page-five/homefive-main-banner/homefive-main-banner.component';
import { FeaturesComponent } from './components/common/features/features.component';//duplicate
import { CategoriesStyleThreeComponent } from './components/common/categories-style-three/categories-style-three.component';
import { HomesevenMainBannerComponent } from './components/pages/home-page-seven/homeseven-main-banner/homeseven-main-banner.component';
import { HomesevenCoursesComponent } from './components/pages/home-page-seven/homeseven-courses/homeseven-courses.component';
import { LoginPageComponent } from './components/pages/Teachers/login-page/login-page.component';
import { RegisterPageComponent } from './components/pages/Teachers/register-page/register-page.component';
import { ErrorPageComponent } from './components/pages/error-page/error-page.component';
import { ComingSoonPageComponent } from './components/pages/coming-soon-page/coming-soon-page.component';
import { CoursesListPageComponent } from './components/pages/courses-list-page/courses-list-page.component';
import { CoursesDetailsPageComponent } from './components/pages/courses-details-page/courses-details-page.component';
import { MyDashboardPageComponent } from './components/pages/Teachers/my-dashboard-page/my-dashboard-page.component';
import { ToastrModule } from 'ng6-toastr-notifications';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { NgxPaginationModule } from 'ngx-pagination';
import {MathJaxModule} from '../app/math-jax/math-jax.module';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import {GoogleLoginProvider,FacebookLoginProvider,AmazonLoginProvider} from 'angularx-social-login';
import { ForgetpasswordComponent } from './components/pages//Teachers/forgetpassword/forgetpassword.component';
import {DataTablesModule} from 'angular-datatables';
import { StudentloginComponent } from './components/pages/Students/studentlogin/studentlogin.component';
import { StudentRegisterPageComponent } from './components/pages/Students/student-register-page/student-register-page.component';
import { StudentforgetpasswordComponent } from './components/pages/Students/studentforgetpassword/studentforgetpassword.component';
import { CreatemettingComponent } from './components/pages/createmetting/createmetting.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgSelectModule } from '@ng-select/ng-select';
import { NgSelect2Module } from 'ng-select2';
import { CommonModule,DatePipe } from  '@angular/common';
import { EditProfileComponent } from './components/pages/Teachers/edit-profile/edit-profile.component';
import { GroupCreateComponent } from './components/pages/Teachers/group-create/group-create.component';
import { HomesixCoursesComponent } from './components/pages/Students/home-page-six/homesix-courses/homesix-courses.component';
import { HomePageSixComponent } from './components/pages/Students/home-page-six/home-page-six.component';
import { StudentCategoriesComponent } from './components/pages/Students/student-categories/student-categories.component';
import { StudentFeaturesComponent } from './components/pages/Students/student-features/student-features.component';
import { StudentFeedbackComponent } from './components/pages/Students/student-feedback/student-feedback.component';
import { StudentFunfactsComponent } from './components/pages/Students/student-funfacts/student-funfacts.component';
import { StudentHowItWorksComponent } from './components/pages/Students/student-how-it-works/student-how-it-works.component';
import { StudentInstructorComponent } from './components/pages/Students/student-instructor/student-instructor.component';
import { StudentOfferComponent } from './components/pages/Students/student-offer/student-offer.component';
import { HomesixMainBannerComponent } from './components/pages/Students/home-page-six/homesix-main-banner/homesix-main-banner.component';
import { TeachersFeedbackComponent } from './components/pages/Teachers/teachers-feedback/teachers-feedback.component';
import { BoxesComponent } from './components/pages/Teachers/boxes/boxes.component';
import { HowItWorksComponent } from './components/pages/Teachers/how-it-works/how-it-works.component';
import { InstructorsStyleThreeComponent } from './components/pages/Teachers/instructors-style-three/instructors-style-three.component';
// import { Offer1Component } from './components/pages/Teachers/offer1/offer1.component';
// import { OffersComponent } from './components/pages/Teachers/offers/offers.component';
import { PartnerStyleTwoComponent } from './components/pages/Teachers/partner-style-two/partner-style-two.component';
import { StudentProfileComponent } from './components/pages/Students/student-profile/student-profile.component';
import { StudentEditProfileComponent } from './components/pages/Students/student-edit-profile/student-edit-profile.component';
import { GrpUserCreateStudentComponent } from './components/pages/Students/grp-user-create-student/grp-user-create-student.component';
import { MeetingDetailsComponent } from './components/pages/Students/meeting-details/meeting-details.component';
import { HeaderStyleOneTeacherComponent } from './components/common/header-style-one-teacher/header-style-one-teacher.component';
import { CreateCourseComponent } from './components/pages/create-course/create-course.component';
import { CoursesListComponent } from './components/pages/courses-list/courses-list.component';
import { CourseDetailsComponent } from './components/pages/course-details/course-details.component';
import { InvitemeetingComponent } from './components/pages/Teachers/invitemeeting/invitemeeting.component';
import { ExploreComponent } from './components/pages/explore/explore.component';
import { AboutComponent } from './components/pages/about/about.component';
import { FooterTeamComponent } from './components/pages/footer-team/footer-team.component';
import { FooterAdvisorsComponent } from './components/pages/footer-advisors/footer-advisors.component';
import { FooterPartnersComponent } from './components/pages/footer-partners/footer-partners.component';
import { FooterContactComponent } from './components/pages/footer-contact/footer-contact.component';
import { FaqPageComponent } from './components/pages/faq-page/faq-page.component';
import { FooterPrivacyComponent } from './components/pages/footer-privacy/footer-privacy.component';
import { FooterTermsandconditionComponent } from './components/pages/footer-termsandcondition/footer-termsandcondition.component';
import { VRComponent } from './components/pages/vr/vr.component';
// import { OneTOoneCreateComponent } from './components/pages/Students/one-toone-create/one-toone-create.component';
import { ChangepasswordComponent } from './components/pages/changepassword/changepassword.component';
import { BuddiAdvisorsComponent } from './components/pages/buddi-advisors/buddi-advisors.component';
import { CreateExamComponent } from './components/pages/create-exam/create-exam.component';
import { ExamDetailsComponent } from './components/pages/exam-details/exam-details.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
//import { CalendarComponent } from './components/pages/calendar/calendar.component';
import { TeacherEbookCreateComponent } from './components/pages/Teachers/teacher-ebook-create/teacher-ebook-create.component';
import { TeacherVideoCreateComponent } from './components/pages/Teachers/teacher-video-create/teacher-video-create.component';
import { TeacherAudioCreateComponent } from './components/pages/Teachers/teacher-audio-create/teacher-audio-create.component';
import { TeacherDetailsComponent } from './components/pages/Teachers/teacher-details/teacher-details.component';
import { EbookDetailsComponent } from './components/ebook-details/ebook-details.component';
import { TeacherRequestsComponent } from './components/pages/Teachers/teacher-requests/teacher-requests.component';
import { TechnologyRequiredComponent } from './components/pages/technology-required/technology-required.component';
import { VideoDetailsComponent } from './components/pages/video-details/video-details.component';
import { AudioDetailsComponent } from './components/pages/audio-details/audio-details.component';
import { AboutTeacherComponent } from './components/pages/about-teacher/about-teacher.component';
import { StudentFaqPageComponent } from './components/pages/student-faq-page/student-faq-page.component';
import { PressReleasesComponent } from './components/pages/press-releases/press-releases.component';
import { EditCourseComponent } from './components/pages/edit-course/edit-course.component';
import { EditExamComponent } from './components/pages/edit-exam/edit-exam.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BuddiTeamDetailsComponent } from './components/pages/buddi-team-details/buddi-team-details.component';
import { BuddiAdvisorsDetailsComponent } from './components/pages/buddi-advisors-details/buddi-advisors-details.component';
import { BuddigroupComponent } from './components/common/buddigroup/buddigroup.component';
import { SchoolRegistrationComponent } from './components/pages/school-registration/school-registration.component';
import { ThanksSchoolRegistrationComponent } from './components/pages/thanks-school-registration/thanks-school-registration.component';
import { BuddiLiveRedirectComponent } from './components/pages/buddi-live-redirect/buddi-live-redirect.component';
import { StudentsRegisterComponent } from './components/pages/students-register/students-register.component';
import { ThanksStudentRegistrationComponent } from './components/pages/thanks-student-registration/thanks-student-registration.component';
import { BuddiTestComponent } from './components/pages/buddi-test/buddi-test.component';
import { BuddiTestPageComponent } from './components/pages/buddi-test-page/buddi-test-page.component';
import { BuddiTestListComponent } from './components/pages/buddi-test-list/buddi-test-list.component';
import { ProductexpiredPipe } from './components/custom-pipes/productexpired.pipe';
import { RemovespacePipe } from './components/custom-pipes/removespace.pipe';
import { PaymentsuccessComponent } from './components/pages/paymentsuccess/paymentsuccess.component';
import { BuddioneOnoneComponent } from './components/pages/buddione-onone/buddione-onone.component';
import { BuddiTestCorrectionComponent } from './components/pages/buddi-test-correction/buddi-test-correction.component';
import { TestsRequestComponent } from './components/pages/tests-request/tests-request.component';
import { BuddiTestDetailsComponent } from './components/pages/buddi-test-details/buddi-test-details.component';
import { OneOnoneSuccessComponent } from './components/pages/one-onone-success/one-onone-success.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ChartsModule } from 'ng2-charts';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { StudentTestDetailsComponent } from './components/pages/student-test-details/student-test-details.component';
import { StudentTestResultComponent } from './components/pages/student-test-result/student-test-result.component';
import { BuddiTeacherDetailsComponent } from './components/pages/buddi-teacher-details/buddi-teacher-details.component';
import { KycComponent } from './components/pages/kyc/kyc.component';
import { BuddiTestReviewComponent } from './components/pages/buddi-test-review/buddi-test-review.component';
import { NointernetComponent } from './components/pages/nointernet/nointernet.component';
import { SearchByComponent } from './components/pages/search-by/search-by.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { FilterSearchPipe } from './components/custom-pipes/filter-search.pipe';
import { DiffTwoTimePipe } from './components/custom-pipes/diff-two-time.pipe';
import { TotalAmountPipe } from './components/custom-pipes/total-amount.pipe';
//import { NeetCrashComponent } from './components/pages/neet-crash/neet-crash.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { RupeeCommaPipe } from './components/custom-pipes/rupee-comma.pipe';
import { FeedbackTeacherComponent } from './components/pages/Teachers/feedback-teacher/feedback-teacher.component';
import { CommonTextColorDirective } from './components/custom-directive/common-text-color.directive';
import { NgForCommaDirective } from './components/custom-directive/ng-for-comma.directive';
import { commaForComponent } from './components/reusable-component/ng-for-comma/ng-for-comma.component';
import { NgForCommaComponent } from './components/reusable-component/ng-for-comma/ng-for-comma.component';
import { StudentListComponent } from './components/reusable-component/student-list/student-list.component';
import { PasswordEyeComponent } from './components/reusable-component/password-eye/password-eye.component';
import { GlobalButtonDirective } from './components/custom-directive/global-button.directive';
import { GlobalButtonComponent } from './components/reusable-component/global-button/global-button.component';
import { ManualAutomationComponent } from './components/reusable-component/manual-automation/manual-automation.component';
import { PaginationComponent } from './components/reusable-component/pagination/pagination.component';
import { ListingComponent } from './components/reusable-component/listing/listing.component';
import { AutomationComponent } from './components/pages/automation/automation.component';
// import { CalendarModule } from '@syncfusion/ej2-angular-calendars';
import {DemoMaterialModule} from './components/common/material-module';
import { List5Component } from './components/reusable-component/list5/list5.component';
import { List5StudentComponent } from './components/reusable-component/list5-student/list5-student.component';
//import { BuddiK12AdsComponent } from './components/pages/k12-ads/buddi-k12-ads/buddi-k12-ads.component';
import { ReuseListComponent } from './components/reusable-component/reuse-list/reuse-list.component';
import { ReuseDetailComponent } from './components/reusable-component/reuse-detail/reuse-detail.component';
// import { CommonDetailsPageComponent } from './components/pages/common-details-page/common-details-page.component';
import { ClassListComponent } from './components/reusable-component/class-list/class-list.component';
import { TeacherClasslistComponent } from './components/reusable-component/teacher-classlist/teacher-classlist.component';
import { LibrarylistComponent } from './components/reusable-component/librarylist/librarylist.component';
import { EvaluationTestDetailsComponent } from './components/pages/evaluation-test/evaluation-test-details/evaluation-test-details.component';
// import { BuddiUpscComponent } from './components/pages/UPSC/buddi-upsc/buddi-upsc.component';
// import { UpscFeedbackComponent } from './components/pages/UPSC/upsc-feedback/upsc-feedback.component';
// import { UpscFunfactsComponent } from './components/pages/UPSC/upsc-funfacts/upsc-funfacts.component';
// import { UpscInstructorComponent } from './components/pages/UPSC/upsc-instructor/upsc-instructor.component';
// import { UpscMissionComponent } from './components/pages/UPSC/upsc-mission/upsc-mission.component';
// import { UpscStoryComponent } from './components/pages/UPSC/upsc-story/upsc-story.component';
// import { UpscValuesComponent } from './components/pages/UPSC/upsc-values/upsc-values.component';
// import { BuddiCAComponent } from './components/pages/CA/buddi-ca/buddi-ca.component';
// import { BuddiNEETPGComponent } from './components/pages/neet-pg/buddi-neet-pg/buddi-neet-pg.component';
import { FirstCapPipe } from './components/custom-pipes/first-cap.pipe';
import { EmptyObjectPipe } from './components/custom-pipes/empty-object.pipe';
import { StudentReuselistComponent } from './components/reusable-component/student-reuselist/student-reuselist.component';
import { List5StudentSolutionComponent } from './components/reusable-component/list5-student-solution/list5-student-solution.component';
import { TeachersPricingComponent } from './components/pages/Teachers/teachers-pricing/teachers-pricing.component';
import { TeacherVideoUploadComponent } from './components/pages/teacher-video-upload/teacher-video-upload.component';
// import { OfferHeaderComponent } from './components/pages/offer-header/offer-header.component';
import { VideoListingComponent } from './components/pages/video-listing/video-listing.component';
import { FullListingComponent } from './components/pages/full-listing/full-listing.component';
import { VideodetailsComponent } from './components/pages/videodetails/videodetails.component';
import { UploadedVideoDetailsComponent } from './components/pages/uploaded-video-details/uploaded-video-details.component';
import { StudentVideoDetailsComponent } from './components/pages/Students/student-video-details/student-video-details.component';
import { UploadClassCourseComponent } from './components/pages/Teachers/upload-class-course/upload-class-course.component';
import { ClassCourseListComponent } from './components/pages/Teachers/class-course-list/class-course-list.component';


import { ShareModuleModule } from './components/share-module/share-module.module';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ChartComponent } from './components/pages/chart/chart.component';
// import { ChartsModule } from 'ng2-charts';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from 'ngx-perfect-scrollbar';
import { ChartdashboardComponent } from './components/pages/chartdashboard/chartdashboard.component';
import { ChapterTopicComponent } from './components/pages/chapter-topic/chapter-topic.component';
import { SidenavComponent } from './components/pages/sidenav/sidenav.component';
import { SubjectNavComponent } from './components/pages/subject-nav/subject-nav.component';
import { PaymentComponent } from './components/pages/payment/payment.component';
import { LiveclassListingComponent } from './components/pages/liveclass-listing/liveclass-listing.component';

import { ListingWebinarComponent } from './components/pages/listing-webinar/listing-webinar.component';
import { ClassCourseDetailsComponent } from './components/pages/Teachers/class-course-details/class-course-details.component';
import { StudentCourseDetailsComponent } from './components/pages/Students/student-course-details/student-course-details.component';
import { StudentsVideoListingComponent } from './components/pages/Students/students-video-listing/students-video-listing.component';
import { ListMylearningComponent } from './components/pages/Students/list-mylearning/list-mylearning.component';
import { StudyMaterialDetailsComponent } from './components/pages/study-material-details/study-material-details.component';
import { TeacherMyLearningComponent } from './components/pages/Teachers/teacher-my-learning/teacher-my-learning.component';
import { ListingClassroomsComponent } from './components/pages/listing-classrooms/listing-classrooms.component';
import { CreateWebinarComponent } from './components/pages/create-webinar/create-webinar.component';
import { DataFilterPipe } from './components/custom-pipes/data-filter.pipe';
import { UploadTeacherSmartmaterialComponent } from './components/pages/Teachers/upload-teacher-smartmaterial/upload-teacher-smartmaterial.component';
import { SmartmaterialsDetailsComponent } from './components/pages/Teachers/smartmaterials-details/smartmaterials-details.component';
import { TeacherTestUploadComponent } from './components/pages/Teachers/teacher-test-upload/teacher-test-upload.component';
import { TeacherTestDetailsComponent } from './components/pages/Teachers/teacher-test-details/teacher-test-details.component';
import { TestDetailsStudentComponent } from './components/pages/Students/test-details-student/test-details-student.component';
import { StudentStartTestComponent } from './components/pages/Students/student-start-test/student-start-test.component';
import { TeacherCorrectionPageComponent } from './components/pages/Teachers/teacher-correction-page/teacher-correction-page.component';
import { StudentCorrectionResultComponent } from './components/pages/Students/student-correction-result/student-correction-result.component';
import { PaymentHistoryComponent } from './components/pages/payment-history/payment-history.component';
import { CartCheckoutComponent } from './components/pages/cart-checkout/cart-checkout.component';
import { WalletComponent } from './components/pages/wallet/wallet.component';
import { WalletRechargeComponent } from './components/pages/wallet-recharge/wallet-recharge.component';
import { FullListSmartComponent } from './components/pages/full-list-smart/full-list-smart.component';
import { FullListTestComponent } from './components/pages/full-list-test/full-list-test.component';
import { MenuDropdownComponent } from './components/pages/menu-dropdown/menu-dropdown/menu-dropdown.component';
import { InstitutionPageComponent } from './components/pages/institution-page/institution-page.component';
import { CreateLiveclassComponent } from './components/pages/create-liveclass/create-liveclass.component';
import { LiveClassDetailsComponent } from './components/pages/live-class-details/live-class-details.component';
import { LiveClassRequestDetailsComponent } from './components/pages/live-class-request-details/live-class-request-details.component';
import { LiveClassRequestListingComponent } from './components/pages/live-class-request-listing/live-class-request-listing.component';
import { InstitutionMainbannerComponent } from './components/pages/institution-page/institution-mainbanner/institution-mainbanner.component';
import { LearningToolsComponent } from './components/pages/learning-tools/learning-tools.component';
import { CompletedClassesComponent } from './components/pages/completed-classes/completed-classes.component';
import { UpcomingclassComponent } from './components/pages/upcomingclass/upcomingclass.component';
import { ProfileAdvancedComponent } from './components/pages/profile-advanced/profile-advanced.component';
import { NotificationsComponent } from './components/pages/notifications/notifications.component';
import { CreateStudyHallComponent } from './components/pages/create-study-hall/create-study-hall.component';
import { CreateRoundtableComponent } from './components/pages/create-roundtable/create-roundtable.component';
import { StudyhallDetailsComponent } from './components/pages/studyhall-details/studyhall-details.component';
import { RoundtableDetailsComponent } from './components/pages/roundtable-details/roundtable-details.component';
import { CurrencyPaymentComponent } from './components/pages/currency-payment/currency-payment.component';
import { CoinPaymentComponent } from './components/pages/coin-payment/coin-payment.component';
import { StudyhallListComponent } from './components/pages/studyhall-list/studyhall-list.component';
import { RoundtableListComponent } from './components/pages/roundtable-list/roundtable-list.component';
import { StudyclassRequestDetailsComponent } from './components/pages/studyclass-request-details/studyclass-request-details.component';
import { RoundclassRequestDetailsComponent } from './components/pages/roundclass-request-details/roundclass-request-details.component';
import { StudyhallRequestListComponent } from './components/pages/studyhall-request-list/studyhall-request-list.component';
import { RoundtableRequestListComponent } from './components/pages/roundtable-request-list/roundtable-request-list.component';
import { StudentRequestLiveClassComponent } from './components/pages/Students/student-request-live-class/student-request-live-class.component';
import { AllStudyclassListingComponent } from './components/pages/all-studyclass-listing/all-studyclass-listing.component';
import { AllLiveclassListingComponent } from './components/pages/all-liveclass-listing/all-liveclass-listing.component';
import { AllRoundtableListingComponent } from './components/pages/all-roundtable-listing/all-roundtable-listing.component';
import { AddvideoDashboardListComponent } from './components/pages/addvideo-dashboard-list/addvideo-dashboard-list.component';
import { AddsmartmaterialListComponent } from './components/pages/addsmartmaterial-list/addsmartmaterial-list.component';
import { AddbudditestListComponent } from './components/pages/addbudditest-list/addbudditest-list.component';
import { TeacherRewardComponent } from './components/pages/teacher-reward/teacher-reward.component';
import { StudentRewardComponent } from './components/pages/student-reward/student-reward.component';
import { UpcomingStudyhallComponent } from './components/pages/upcoming-studyhall/upcoming-studyhall.component';
import { UpcomingroundtableComponent } from './components/pages/upcomingroundtable/upcomingroundtable.component';
import { UpcomingLiveClassComponent } from './components/pages/upcoming-live-classes/upcoming-live-class.component';
import { UpcomingTestsComponent } from './components/pages/upcoming-tests/upcoming-tests.component';
import { UpcomingVideosComponent } from './components/pages/upcoming-videos/upcoming-videos.component';
import { UpcomingSmartMaterialComponent } from './components/pages/upcoming-smart-material/upcoming-smart-material.component';
import { CompletedStudyhallTabComponent } from './components/pages/completed-studyhall-tab/completed-studyhall-tab.component';
import { CompletedLiveclassTabComponent } from './components/pages/completed-liveclass-tab/completed-liveclass-tab.component';
import { CompletedRoundtableTabComponent } from './components/pages/completed-roundtable-tab/completed-roundtable-tab.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { InvestorComponent } from './components/pages/investor/investor.component';
import { PricingPlansComponent } from './components/pages/pricing-plans/pricing-plans.component';
import { TutionClassPageComponent } from './components/pages/tution-class-page/tution-class-page.component';
import { CBSEComponent } from './components/pages/cbse/cbse-online-tution-content.component';
import { TeachingComponent } from './components/pages/teaching/online-teaching-jobs.component';
import { InstitutionCreationFormComponent } from './components/pages/institution-creation-form/institution-creation-form.component';
import { AddTutorLearnerComponent } from './components/pages/add-tutor-learner/add-tutor-learner.component';
import { InstitutionDetailsComponent } from './components/pages/institution-details/institution-details.component';

import { PartnerStyleOneComponent} from './components/common/partner-style-one/partner-style-one.component';
import { GetstartedComponent } from './components/pages/getstarted/getstarted.component';
import { LearningassetsComponent } from './components/pages/learningassets/learningassets.component';
import { NFTComponent } from './components/pages/nft/nft.component';
import { MetaverseComponent } from './components/pages/metaverse/metaverse.component';
import { MetaverseBannerComponent } from './components/pages/metaverse-banner/metaverse-banner.component';
import { MetaverseExploreComponent } from './components/pages/metaverse-explore/metaverse-explore.component';
import { BuddiUniqueModelComponent } from './components/pages/buddi-unique-model/buddi-unique-model.component';
import { TutorLearnerComponent } from './components/pages/tutor-learner/tutor-learner.component';
import { MetaverseHeaderComponent } from './components/pages/metaverse-header/metaverse-header.component';
import { MetaverseHeaderTeacherComponent } from './components/pages/metaverse-header-teacher/metaverse-header-teacher.component';
import { MetaverseStudentHowItWorksComponent } from './components/pages/metaverse-student-how-it-works/metaverse-student-how-it-works.component';
import { MetaverseStudentInstructorComponent } from './components/pages/metaverse-student-instructor/metaverse-student-instructor.component';
import { MetaverseStudentFunfactsComponent } from './components/pages/metaverse-student-funfacts/metaverse-student-funfacts.component';
import { NftExploreComponent } from './components/pages/nft-explore/nft-explore.component';
import { NftBannerComponent } from './components/pages/nft-banner/nft-banner.component';
import { NftEducationComponent } from './components/pages/nft-education/nft-education.component';
import { BuytokensComponent } from './components/pages/buytokens/buytokens.component';
import { MarketplaceComponent } from './components/pages/marketplace/marketplace.component';
import { LiveComponent } from './live/live.component';
import { CreateliveComponent } from './createlive/createlive.component';
import { LivelistComponent } from './livelist/livelist.component';
import { UpcommingComponent } from './upcomming/upcomming.component';
import { CompletedComponent } from './completed/completed.component';
import { ProfileComponent } from './profile/profile.component';
import { SettingsComponent } from './settings/settings.component';
import { AssetComponent } from './asset/asset.component';
import { BuddiCoinComponent } from './buddi-coin/buddi-coin.component';
import { TransactionHistoryComponent } from './transaction-history/transaction-history.component';
import { CoinTransactionHistoryComponent } from './coin-transaction-history/coin-transaction-history.component';
import { BuddiRewardsComponent } from './buddi-rewards/buddi-rewards.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { NftAssetTransactionComponent } from './nft-asset-transaction/nft-asset-transaction.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { UpgradePacakgeComponent } from './components/pages/upgarde-package/upgrade-package.component';
import { SummarySnapshotComponent } from './components/pages/summary-snapshot/summary-snapshot.component';
import { TutorialsComponent } from './components/pages/tutorials/tutorials.component';
import {DemoClassComponent} from './components/pages/demo-class/demo-class.component';
import { InstituteCreateListComponent } from './components/pages/institute-create-list/institute-create-list.component';
import { InstituteListDetailsComponent } from './components/pages/institute-list-details/institute-list-details.component';
import { BuddiTalkComponent } from './components/pages/buddi-talk/buddi-talk.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { LogpageComponent } from './logpage/logpage.component';
import { ClasscreationComponent } from './classcreation/classcreation.component';
import { EnrollClassesComponent } from './enroll-classes/enroll-classes.component';
import { LogpagetutorComponent } from './logpagetutor/logpagetutor.component';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false,
};
@NgModule({
  declarations: [
    GetstartedComponent,
    LearningassetsComponent,
    NFTComponent,
    MetaverseComponent,
    PartnerStyleOneComponent,
    AppComponent,
    BlogComponent,
    HomePageOneComponent,
    HomePageTwoComponent,
    FooterComponent,
    PreloaderComponent,
    HomeoneAboutComponent,
    HomeoneMainBannerComponent,
    
    FunfactsComponent,
    HomeoneCoursesComponent,
    FeedbackStyleOneComponent,
    HometwoMainBannerComponent,
    HometwoAboutComponent,
    HometwoCoursesComponent,
    StudentsFeedbackFormComponent,
    K12FeedbackComponent,
    // HomePageThreeComponent,
    HomePageFourComponent,
    HomePageFiveComponent,
    HomePageSixComponent,
    HomePageSevenComponent,
    PartnerStyleTwoComponent,
    OfferComponent,
    CategoriesStyleTwoComponent,
    // HomethreeCoursesComponent,
    //HomethreeAboutComponent,
    FeedbackFormComponent,
    //HomethreeMainBannerComponent,
    HomefourMainBannerComponent,
    HomefourCoursesComponent,
    BoxesComponent,
    FeedbackStyleTwoComponent,
    // OneTOoneCreateComponent,
    HomefiveCoursesComponent,
    HomefiveMainBannerComponent,
    InstructorsStyleThreeComponent,
    FeaturesComponent,
    HomePageSixComponent,
    CategoriesStyleThreeComponent,
    HomesevenMainBannerComponent,
    HomesevenCoursesComponent,
    HowItWorksComponent,
    LoginPageComponent,
    RegisterPageComponent,
    ErrorPageComponent,
    ComingSoonPageComponent,
    CoursesListPageComponent,
    CoursesDetailsPageComponent,
    MyDashboardPageComponent,
    ForgetpasswordComponent,
    StudentloginComponent,
    StudentRegisterPageComponent,
    StudentforgetpasswordComponent,
    CreatemettingComponent,
    EditProfileComponent,
    GroupCreateComponent,
    HomePageSixComponent,
    StudentCategoriesComponent,
    StudentFeaturesComponent,
    StudentFeedbackComponent,
    StudentFunfactsComponent,
    StudentHowItWorksComponent,
    StudentInstructorComponent,
    StudentOfferComponent,
    HomesixCoursesComponent,
    HomesixMainBannerComponent,
    BoxesComponent,
    // Offer1Component,
    TeachersFeedbackComponent,
    StudentProfileComponent,
    StudentEditProfileComponent,
    GrpUserCreateStudentComponent,
    MeetingDetailsComponent,
    HeaderStyleOneTeacherComponent,
    CreateCourseComponent,
    CoursesListComponent,
    CourseDetailsComponent,
    InvitemeetingComponent,
    ExploreComponent,
    AboutComponent,
    FooterTeamComponent,
    FooterAdvisorsComponent,
    FooterPartnersComponent,
    FooterContactComponent,
    FaqPageComponent,
    FooterPrivacyComponent,
    FooterTermsandconditionComponent,
    VRComponent,
    ChangepasswordComponent,
    BuddiAdvisorsComponent,
    CreateExamComponent,
    // OneTOoneCreateComponent,
    ExamDetailsComponent,
    //CalendarComponent,
    TeacherEbookCreateComponent,
    TeacherVideoCreateComponent,
    TeacherAudioCreateComponent,
    TeacherDetailsComponent,   
    AboutTeacherComponent,
    StudentFaqPageComponent,
    EbookDetailsComponent,
    TeacherRequestsComponent,
    TechnologyRequiredComponent,
    PressReleasesComponent,
    BlogComponent,
    EditCourseComponent,
    VideoDetailsComponent,
    AudioDetailsComponent,
    EditExamComponent,
    BuddiTeamDetailsComponent,
    BuddiAdvisorsDetailsComponent,
    BuddigroupComponent,
    SchoolRegistrationComponent,
    ThanksSchoolRegistrationComponent,
    BuddiLiveRedirectComponent,
    StudentsRegisterComponent,
    ThanksStudentRegistrationComponent,
    BuddiTestComponent,
    BuddiTestPageComponent,
    ProductexpiredPipe,
    RemovespacePipe,
    BuddiTestListComponent,
    ProductexpiredPipe,
    PaymentsuccessComponent,
    BuddioneOnoneComponent,
    BuddiTestCorrectionComponent,
    TestsRequestComponent,
    BuddiTestDetailsComponent,
    OneOnoneSuccessComponent,
    StudentTestDetailsComponent,
    StudentTestResultComponent,
    BuddiTeacherDetailsComponent,
    KycComponent,
    BuddiTestReviewComponent,
    NointernetComponent,
    SearchByComponent,
    FilterSearchPipe,
    DiffTwoTimePipe,
    TotalAmountPipe,
    //NeetCrashComponent,
    RupeeCommaPipe,
    FeedbackTeacherComponent,
    CommonTextColorDirective,
    NgForCommaDirective,
    NgForCommaComponent,
    StudentListComponent,
    PasswordEyeComponent,
    GlobalButtonDirective,
    GlobalButtonComponent,
    commaForComponent,
    ManualAutomationComponent,
    PaginationComponent,
    ListingComponent,
    AutomationComponent,
    List5Component,
    List5StudentComponent,
    List5StudentSolutionComponent,
    //BuddiK12AdsComponent,
    ReuseListComponent,
    StudentReuselistComponent,
    ReuseDetailComponent,
    // CommonDetailsPageComponent,
    ClassListComponent,
    TeacherClasslistComponent,
    LibrarylistComponent,
    EvaluationTestDetailsComponent,
    // BuddiUpscComponent,
    // UpscFeedbackComponent,
    // UpscFunfactsComponent,
    // UpscInstructorComponent,
    // UpscMissionComponent,
    // UpscStoryComponent,
    // UpscValuesComponent,
    // BuddiCAComponent,
    // BuddiNEETPGComponent,
    FirstCapPipe,
    EmptyObjectPipe,
    StudentReuselistComponent,
    List5StudentSolutionComponent,
    TeachersPricingComponent,
    TeacherVideoUploadComponent,
    // OfferHeaderComponent,
    VideoListingComponent,
    FullListingComponent,
    VideodetailsComponent,
    UploadedVideoDetailsComponent,
    StudentVideoDetailsComponent,
    UploadClassCourseComponent,
    ClassCourseListComponent,
    ChartComponent,
    ChartdashboardComponent,
    ChapterTopicComponent,
    SidenavComponent,
    SubjectNavComponent,
    HeaderStyleOneComponent,
    ClassCourseDetailsComponent,
    StudentCourseDetailsComponent,
    StudentsVideoListingComponent,
    ListMylearningComponent,
    StudyMaterialDetailsComponent,
    PaymentComponent,
    ListingWebinarComponent,
    ClassCourseDetailsComponent,
    TeacherMyLearningComponent,
    ListingClassroomsComponent,
    CreateWebinarComponent,
    DataFilterPipe,
    UploadTeacherSmartmaterialComponent,
    SmartmaterialsDetailsComponent,
    TeacherTestUploadComponent,
    TeacherTestDetailsComponent,
    TestDetailsStudentComponent,
    StudentStartTestComponent,
    TeacherCorrectionPageComponent,
    StudentCorrectionResultComponent,
    PaymentHistoryComponent,
    //TeacherTestDetailsComponent
    LiveclassListingComponent,
    CartCheckoutComponent,
    WalletComponent,
    WalletRechargeComponent,
    FullListSmartComponent,
    FullListTestComponent,
    MenuDropdownComponent,
    InstitutionPageComponent,
    CreateLiveclassComponent,
    LiveClassDetailsComponent,
    LiveClassRequestDetailsComponent,
    LiveClassRequestListingComponent,
    InstitutionMainbannerComponent,
    LearningToolsComponent,
    CompletedClassesComponent,
    UpcomingclassComponent,
    ProfileAdvancedComponent,
    NotificationsComponent,
    CreateStudyHallComponent,
    CreateRoundtableComponent,
    StudyhallDetailsComponent,
    RoundtableDetailsComponent,
    CurrencyPaymentComponent,
    CoinPaymentComponent,
    StudyhallListComponent,
    RoundtableListComponent,
    StudyclassRequestDetailsComponent,
    RoundclassRequestDetailsComponent,
    StudyhallRequestListComponent,
    RoundtableRequestListComponent,
    StudentRequestLiveClassComponent,
    AllStudyclassListingComponent,
    AllLiveclassListingComponent,
    AllRoundtableListingComponent,
    AddvideoDashboardListComponent,
    AddsmartmaterialListComponent,
    AddbudditestListComponent,
    TeacherRewardComponent,
    StudentRewardComponent,
    UpcomingStudyhallComponent,
    UpcomingroundtableComponent,
    UpcomingLiveClassComponent,
    UpcomingTestsComponent,
    UpcomingVideosComponent,
    UpcomingSmartMaterialComponent,
    CompletedStudyhallTabComponent,
    CompletedLiveclassTabComponent,
    CompletedRoundtableTabComponent,
    InvestorComponent,
    PricingPlansComponent,
    TutionClassPageComponent,
    CBSEComponent,
    TeachingComponent,
    InstitutionCreationFormComponent,
    AddTutorLearnerComponent,
    InstitutionDetailsComponent,
    MetaverseBannerComponent,
    MetaverseExploreComponent,
    BuddiUniqueModelComponent,
    TutorLearnerComponent,
    MetaverseHeaderComponent,
    MetaverseHeaderTeacherComponent,
    MetaverseStudentHowItWorksComponent,
    MetaverseStudentInstructorComponent,
    MetaverseStudentFunfactsComponent,
    NftExploreComponent,
    NftBannerComponent,
    NftEducationComponent,
    BuytokensComponent,
    MarketplaceComponent,
    LiveComponent,
    CreateliveComponent,
    LivelistComponent,
    UpcommingComponent,
    CompletedComponent,
    ProfileComponent,
    SettingsComponent,
    AssetComponent,
    BuddiCoinComponent,
    TransactionHistoryComponent,
    CoinTransactionHistoryComponent,
    BuddiRewardsComponent,
    InvoicesComponent,
    NftAssetTransactionComponent,
    SubscriptionComponent,
    UpgradePacakgeComponent,
    SummarySnapshotComponent,
    TutorialsComponent,
    DemoClassComponent,
    InstituteCreateListComponent,
    InstituteListDetailsComponent,
    BuddiTalkComponent,
    AboutUsComponent,
    LogpageComponent,
    ClasscreationComponent,
    EnrollClassesComponent,
    LogpagetutorComponent


      
  ],
  imports: [
    NgxDocViewerModule,
    MathJaxModule.forRoot({
      version: '2.7.5',
      config: 'TeX-AMS_HTML',
      hostname: 'cdnjs.cloudflare.com',
      mathjaxconfigobject : {
          'HTML-CSS': {
            styles: {
              '.MathJax_Display': {
                'background-color': 'yellow',
                'word-break': 'break-word',               
              },
              linebreaks: {
                automatic: true,
                width: "30em"
              }
            },
          },
        },
    }),
   
    BrowserModule,
    AppRoutingModule,
    NgxPaginationModule,
    NgImageSliderModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    NgSelect2Module,
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    ChartsModule,
    RatingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SocialLoginModule,
    DataTablesModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    NgxSpinnerModule,
    CommonModule,
    SocketIoModule.forRoot(config),
    NgMultiSelectDropDownModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    
    Ng2SearchPipeModule,  
    NgxDatatableModule,
    AutocompleteLibModule,
    DemoMaterialModule,
    NgxIntlTelInputModule,
    ShareModuleModule,
    NgApexchartsModule,
    ChartsModule,
    MatProgressBarModule,
    PerfectScrollbarModule,
    // Ng5SliderModule,
  ],
  providers: [
    // Meta, 
    DatePipe,
    {
    provide: 'SocialAuthServiceConfig',
    useValue: {
      autoLogin: false,
      providers: [
        {
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider(
            '913873231441-cl15p1e4cd9ip18r6e7008hv1ssigbdd.apps.googleusercontent.com'
          ),
        },
        {
          id: FacebookLoginProvider.PROVIDER_ID,
          provider: new FacebookLoginProvider('283039929661098'),
        }
      ],
    } as SocialAuthServiceConfig,
  },
],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
