
<div *ngIf="isteacherRoute">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>
<div *ngIf="!isteacherRoute">
    <app-header-style-one></app-header-style-one>
</div>

<section class="courses-details-area pt-100 pb-70" style="padding-top: 30px!important" *ngIf="isinstruction">
    <div class="container" style="box-shadow: 0px 0px 1px 0px;padding: 5px 30px 5px 30px;">
        <div class="row" style="display: block;">
            <h4 style="text-align: center;">{{livedetails.testName}}</h4>
        </div>

        <div class="row" style="margin-top: 3%;display: block;">
            <h6 style="text-align: center;">Instructions</h6>

        </div>
        <p style="text-align: center;">Read the instruction carefully</p>
        <div class="row" style="margin-top: 3%;display: block;">

            <p style="color: #444;font-size: 17px;font-weight: 400;">1. Total duration of the test is <span
                    style="font-size: 17px;color: black;font-weight: 700;">{{livedetails.totalTime}} Minutes</span>.</p>

            <p style="color: #444;font-size: 17px;font-weight: 400;">2. There will be only <span
                    style="font-size: 17px;color: black;font-weight: 700;">{{livedetails.numberOfQuestions}}
                    questions</span> in this test.</p>

            <p style="color: #444;font-size: 17px;font-weight: 400;">3. Total marks for this test is <span
                    style="font-size: 17px;color: black;font-weight: 700;">{{livedetails.totalMarks}} marks</span>.</p>

            <!-- <p *ngIf="subjectlength > 1" style="color: #444;font-size: 17px;font-weight: 400;">4. There are <span
                    style="font-size: 17px;color: black;font-weight: 700;">{{subjectlength}} sections</span> in the
                question paper consisting of {{subjectnameasstring}}.</p> -->

            <!-- <p *ngIf="subjectlength == 1" style="color: #444;font-size: 17px;font-weight: 400;">4. There is <span
                    style="font-size: 17px;color: black;font-weight: 700;">{{subjectlength}} section</span> in the
                question paper consisting of {{subjectnameasstring}}.</p> -->

            <!-- <p style="color: #444;font-size: 17px;font-weight: 400;">5. In all the sections, <span
                    style="font-size: 17px;color: black;font-weight: 700;">No deduction</span> from the total score will
                be made if a question is unanswered.</p> -->

            <!-- <p style="color: #444;font-size: 17px;font-weight: 400;">6. In all the sections,there will be a deduction
                from the total score for each wrong answers in some of the questions.</p> -->
                <p style="color: #444;font-size: 17px;font-weight: 400;">4. Read the questions carefully and write down the answers in an A4 sheet.Upload all the images at once at the end of the Test.</p>

                <p style="color: #444;font-size: 17px;font-weight: 400;">5. Important: Please mention the question number at the start of each page for easy reference.</p>

            <p style="color: #444;font-size: 17px;font-weight: 400;">6. Please use ONE Device,browser and tab while
                taking the test. As an anti-cheating measure,switching between tabs,browsers or devices will result in
                loss of your test data.</p>

            <label>
                <input type="checkbox" value="1" style="margin-right: 10px!important;" (change)="changed($event)"><span
                    style="color: #444;font-size: 17px;font-weight: 400;">I have read and understood the
                    instructions.</span>
            </label>
        </div>

        <div class="row" style="margin-top: 3%;display: block;text-align: center;">
            <button disabled *ngIf="!ischecked" type="submit" class="default-btn"
                style="background-color: grey;border-color: grey;"><i class='bx bx-send icon-arrow before'></i><span
                    class="label">Start Test</span></button>

            <button *ngIf="ischecked" type="submit" class="default-btn" (click)="getquestions()"><i
                    class='bx bx-send icon-arrow before'></i><span class="label">Start Test</span><i
                    class="bx bx-send icon-arrow after"></i></button>
        </div>


    </div>
</section>

<section class="courses-details-area pt-100 pb-70" style="padding-top: 30px!important" *ngIf="isquestion && uploadimage">
    <div class="container">

        <div class="modal" id="myimageModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">
                        <img src={{showimageinmodal}} id="imagepreview" style="width: 425px; height: 425px;" >
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" id="endtestmodal">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-body">
    
                            <div style="margin-top: 5%;">
                                <p> Are you sure you want to end this test?</p>
                            </div>
                           
                            <div class="row" style="margin-top: 5%;">
                                <div class="col-md-4 col-lg-4 col-sm-4">
                                </div>
                                <div class="col-md-3 col-lg-3 col-sm-3">
                                    <a class="default-btn" (click)="uploadimages()" style=" margin-right: 10px;"
                                        data-dismiss="modal"><i class='bx bx-paper-plane icon-arrow before'></i><span
                                            class="label">End</span><i class="bx bx-paper-plane icon-arrow after"></i></a>
                                </div>
                                <div class="col-md-3 col-lg-3 col-sm-3">
                                    <a class="default-btn" style="margin-left: 15%;" data-dismiss="modal"><i
                                            class='bx bx-paper-plane icon-arrow before'></i><span
                                            class="label">Cancel</span><i
                                            class="bx bx-paper-plane icon-arrow after"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="box-shadow: 0px 0px 1px 0px;">
                <div class="row" style="height: 75px;padding-top: 2%;">
                    <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                        <p style="word-break: break-word;font-size: 20px;color:black;font-weight: 700">
                           Upload Images for {{livedetails.testName}}</p>
                    </div>
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                            <a  class="default-btn" (click)="back()" style="padding-left: 24px;">
                                    Back
                                </a>
                    </div>
                    
                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                        <a  class="default-btn" data-toggle="modal" data-target="#endtestmodal"><i
                            class='bx bx-paper-plane icon-arrow before'></i><span
                            class="label">Upload</span><i
                            class="bx bx-paper-plane icon-arrow after"></i></a>
                    </div>
                </div>
            </div>

        </div>

        <div class="row" style="margin-top:3%">
            <form class="edit-account" [formGroup]="selectedimageform">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding: 5px 5px 5px 10px;border-bottom: 1px solid lightgrey;color: white;">

<!-- 
                <div class="input-group">
                    <div class="custom-file">
                        <input type="hidden" formControlName="fileupload" name="" value="">
                        <input type="file" [ngClass]="{ 'is-invalid': f.fileupload.errors}"
                            class="custom-file-input" accept="image/*" (change)="fileChange($event)"
                            id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" accept="image/*">
                        <label class="custom-file-label" for="inputGroupFile01">{{selectedFileNmae}}</label>
                    </div>
                </div>  -->


                <div class="form-group">

                    <label for="file">File</label>
            
                    <input 
            
                        formControlName="file"
            
                        id="file" 
            
                        type="file" 
                        accept="image/png, image/gif, image/jpeg"
            
                        class="form-control"
            
                        multiple="" 
            
                        (change)="onFileChange($event)">
            
                 
            
                </div>
            
               <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngFor='let url of images;let index = index'>                
              
                    <img   [src]="url" height="100" width="100%" (click)="imageselected(url)" style="margin: 3px;padding: 5px 5px 5px 10px;border-bottom: 1px solid lightgrey;color: white;"  data-toggle="modal" data-target="#myimageModal"> <br/>
                    <a class="button" (click)="removeSelectedFile(index)">Remove</a>
               
            </div>
        </div>
               

            </div>
            </form>

        </div>
    </div>
</section>

<section class="courses-details-area pt-100 pb-70" style="padding-top: 30px!important" *ngIf="isquestion && !uploadimage">
    <div class="container">
            <div class="modal" id="myimageModal1">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-body">
                                <img  src={{showimageinmodal}} id="imagepreview" style="width: 425px; height: 425px;" >
                            </div>
                        </div>
                    </div>
                </div>
            <!-- Exam Timer -->
            <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="box-shadow: 0px 0px 1px 0px;">
                        <div class="row" style="height: 75px;padding-top: 2%;">
                            <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                <p style="word-break: break-word;font-size: 20px;color:black;font-weight: 700">
                                    {{livedetails.testName}}</p>
                            </div>
                            <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                <p style="font-size: 18px;font-weight: 700;float: right;color: black;"> <i
                                        class='bx bx-time'></i> Time Left: {{display}} </p>
                            </div>
                        </div>
                    </div>
        
                </div>






<div class="row">

                      <!-- Question with options -->
            <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12" style="box-shadow: 0px 0px 1px 0px;margin-top:5%;padding-bottom: 2%">
                    <div class="row"
                        style="padding: 5px 5px 5px 10px;border-bottom: 1px solid lightgrey;background-color: #00adee;color: white;">
                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8" style="border-right: 1px solid white;">
                            <p style="color: white;font-weight: 600;"> Question {{questioncount}}
                            </p>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                           
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4" >
                       
                        </div>
                       
                    </div>   
                    <div class="row"
                    style="padding: 5px 5px 5px 10px;;margin-top: 2%;background-color: white">
                    <img class="img" [src]="url" height="100" width="100%"  (click)="imageselected(url)" style="margin: 3px;padding: 5px 5px 5px 10px;border-bottom: 1px solid lightgrey;color: white;" data-toggle="modal" data-target="#myimageModal1"  > <br/>
                  
                    </div>
    
    
                </div>
                <!-- End Of Question with options -->

            <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12">
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12"
                    style="box-shadow: 0px 0px 1px 0px;padding: 5px 5px 5px 10px;margin-top:5%;padding-bottom: 2%">
                    <div class="instructor-details" style="padding-top: 5%;">
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="textheader" style="color:darkred">
                                    <!-- {{livedetails.testName}} -->
                                    Note: Upload all the images at the end of the test.
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <a type="submit" class="button" (click)="uploadanswerimage()"
                                    style=" margin-left: 10px;font-size: 12px;"><span class="label" style="font-weight: 600;">Upload Images
                                        </span></a>
                            </div>
                        </div>                      
                        <hr>
                        <div class="row" style="margin-top: 3%;">
                            <div class="col-lg-10 col-md-10">
                                <h6 style="margin-left: 1%;margin-bottom: 2%;font-size: 20px;font-weight: 700;">
                                    <!-- {{livedetails.topicID.topicName}}-->Select Questions</h6>
                            </div>    
                            <div class="col-lg-2 col-md-2">

                            </div>
                            <hr>                       
                            <ng-container >
                                <div style="margin-top: 3%;" *ngFor="let subjects1 of subjectname1array; let i = index">                                  
                                    <a type="submit" class="buttontext"
                                        style=" margin-left: 10px;margin-top:2%"
                                        (click)="questionnumberbutton(subjects1.imageNumber)"><span class="label"
                                            style="font-weight: 600;color: black">{{subjects1.imageNumber}}</span></a>
                                   
                                </div>
                            </ng-container>
                        </div>
    
                     
                    </div>
                </div>


            </div>


            
            <!-- <div class="row" style="margin-top:5%;padding-bottom: 2%">
    
                    <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                        <div class="row">
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <a *ngIf="buttonprev" (click)="previousbutton()" type="submit" class="button"
                                    style=" margin-left: 10px;font-size: 13px;margin-top: 2%;background-color: var(--mainColor);color: white;"
                                    ><span class="label"
                                        style="font-weight: 600;">Previous</span></a>
                            </div>
                            <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1">

                            </div>
                          
                            <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                <a *ngIf="buttonnext" (click)="nextbutton()" type="submit" class="button"
                                    style=" margin-left: 10px;font-size: 13px;margin-top: 2%;background-color: var(--mainColor);color: white;"
                                    ><span class="label"
                                        style="font-weight: 600;">Next</span></a>
                            </div>
                        </div>
                    </div>

                </div> -->


            </div>
    </section>
        
