<!-- <pagination [totalLength]="totallength"></pagination> -->

<section class="my-dashboard-area">
    <div class="container-fluid row p-0" style="cursor: pointer" *ngIf="data.length !=0">
    <div class="col-lg-3 col-md-6"
        *ngFor="let slide of data |filter:searchText| paginate: { id: 'paginationv10',itemsPerPage: countv10, currentPage: v10 }">
          <div class="single-courses-box without-box-shadow mb-30">
                            <div class="courses-image">
                                <img style="width:100%" (click)="videodetails(slide)"
                                    style="width: 250px;height: 175px;"
                                    [src]="slide.thumbname ? slide.thumbname : 'assets/img/videocat-thumb-1.jpg'" alt=""
                                    width="100%">                   
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img [src]="slide.teacherID!=undefined && slide.teacherID.userPhoto!='' ? slide.teacherID.userPhoto  :'assets/img/user1.jpg'"
                                        class="rounded-circle mr-2" alt="image">
                                    <span *ngIf="slide.teacherID!=undefined">{{slide.teacherID.profileName}}
                                    </span>  
                                </div>
                                <h3>
                                    <a (click)="videodetails(slide)"
                                        class="d-inline-block">
                                        <span class="text-break">
                                            {{slide.flimname}}
                                        </span>
                                    </a>      
                                </h3>
                            </div>                                           
                            <app-list5-student-solution [slide]="slide"></app-list5-student-solution>
                        </div>
    </div>

</div>
<div *ngIf="data.length==0">
    No Video(s) Available.
</div>
<div style="float: right;" *ngIf="data.length!=0">
    <pagination-controls (pageChange)="v10= $event" id="paginationv10"></pagination-controls>
</div>
</section>

