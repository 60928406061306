import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../shared/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager, Toastr } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss']
})
export class ChangepasswordComponent implements OnInit {
  changepasswordform: FormGroup;
  submitted = false;
  public isstudent = false;
  public isteacher = false;

  constructor(private activatedRoute:ActivatedRoute,private router: Router ,private formBuilder: FormBuilder,private rest: ApiService, private toastr: ToastrManager) { }

  ngOnInit(): void {
    const userType = localStorage.getItem('userType');    
    const webToken = localStorage.getItem('webtoken');
    if(userType.toLowerCase()=="teacher") {
      this.isteacher = true;
    }
    else {
      this.isstudent = true;
    }
    this.changepasswordform = this.formBuilder.group({
      oldpassword: ['', Validators.required],
      newpassword: ['', [ Validators.required,Validators.minLength(8),Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$!%*?&])[A-Za-z0-9@$!%*#?&]{8,}$")]],
      confirmpassword: ['', Validators.required]
  });
  }
  get f() {return this.changepasswordform.controls; }

  onSubmit(FormGroup) {
    this.submitted = true; 
    // stop here if form is invalid
    if (this.changepasswordform.invalid) {
      this.toastr.errorToastr('Invalid Fields');
        return;
    }

    if(this.changepasswordform.value.newpassword == this.changepasswordform.value.confirmpassword) {
      const userType = localStorage.getItem('userType');    
      const webToken = localStorage.getItem('webtoken');
        var passwordchange = {};
        passwordchange["oldPassword"]=this.changepasswordform.value.oldpassword;
        passwordchange["newPassword"]=this.changepasswordform.value.newpassword;
        this.rest.ChangePasswordforboth(passwordchange,userType.toLowerCase(),webToken).subscribe(res => {
          if(res.status){
            console.log(res.status);
            this.toastr.successToastr(res.message);
            if(userType.toLowerCase()=="teacher") {
              this.router.navigate(['/teacher/mydashboard']);
            }
            else {
              this.router.navigate(['/student/mydashboard']);
            }
           
          }else{
            if(res.message.toLowerCase() == "incorrect password") {
              this.toastr.errorToastr("Old Password Incorrect");
            }
            else {
              this.toastr.errorToastr(res.message);
            }
           
          }
      
      });
    }
   
    else {
      this.toastr.errorToastr('New Password and Confirm Password Does not Match');
    }
  }

}
