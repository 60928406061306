

import { Component, OnInit } from "@angular/core";
import {
    FormBuilder,
    FormGroup,
    FormArray,
    FormControl,
    Validators,
    ValidatorFn,
} from "@angular/forms";
import { Options } from "ng5-slider";
import { ApiService } from "../../../shared/api.service";
import { ToastrManager } from "ng6-toastr-notifications";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { DatePipe } from "@angular/common";
const datepipe: DatePipe = new DatePipe('en-US')
import { Location } from "@angular/common";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { MediaService } from "../../../shared/media.service";
declare var $: any;
@Component({
  selector: 'app-roundclass-request-details',
  templateUrl: './roundclass-request-details.component.html',
  styleUrls: ['./roundclass-request-details.component.scss']
})
export class RoundclassRequestDetailsComponent implements OnInit {
    isLoggedin = "false";
    public teacherID = "";
    public studentID = "";
    public meetingimage = "";
    public meetingurl = "";
    public meetingsactionText = "";
    public meetingCategorypopup = "";
    public meetingScheduleDatepopup = "";
    public meetingrunningpopup = "";
    public meetingIDpopup = "";
    public meeting_ID = "";
    public meetingname = "";
    public meetingScheduleDate = "";
    public inviteUrl = "";
    public frontwebsite = environment.frontUrl;
    public redirectUrl = "";
    public meetingDuration = "";
    public meetingLanguage = "";
    public meetingCity = "";
    public meetingoccurance = "";
    public meetingdescription = "";
    public livedetails: any;
    public profileName = "";
    public meetingId = "";
    public urlArray: any = [];
    public pagename: any = "";
    public tag: any = "";
    public groupName: any;
    public isone2one: boolean = false;
    public isTeacher: boolean = false;
    public isStudent: boolean = false;
    public viewRequest: boolean = false;
    public isrecordPast: boolean = false;
    public packageActive: boolean = false;
    public paymentDetails: any = [];
    constructor(
        private api: MediaService,
        private location: Location,
        private my_router: ActivatedRoute,
        public toastr: ToastrManager,
        private formBuilder: FormBuilder,
        private rest: ApiService,
        private router: Router,
        private SpinnerService: NgxSpinnerService,
        private sanitizer: DomSanitizer,
        private datePipe: DatePipe
    ) {}

    ngOnInit() {
        window.scrollTo(0, 0);
        console.log('dddddddddddd',this.livedetails);
        this.isLoggedin = localStorage.getItem("isLoggedin");
        this.teacherID = localStorage.getItem("StudentID");
        const webToken = localStorage.getItem("webtoken");
        var urlArray = window.location.href.split("/");
        this.isone2one =
            window.location.href.match("one2oneAccept") != null ? true : false;
        this.viewRequest =
            window.location.href.match("viewRequest") != null ? true : false;
        this.isTeacher =
            localStorage.getItem("profileType").toLowerCase() == "teacher";
        this.isStudent =
            localStorage.getItem("profileType").toLowerCase() == "student";
        const requestID = urlArray[urlArray.length - 1]
        const req = {};
        req['requestID'] = requestID;
        this.rest.getTeacherRoundClassDetails(webToken, req).subscribe((result) => {
          console.log('result====',result)
          if(result.status){
            this.livedetails = result.data
            console.log(this.livedetails);
            if(this.livedetails.startDateTime){
                this.livedetails.startDateTime =  datepipe.transform(this.livedetails.startDateTime, 'MMM d, y, h:mm a')

            //   this.livedetails.startDateTime= new Date(this.livedetails.startDateTime).toLocaleString();
            }
            if(this.isTeacher && !this.isStudent){
              this.detailsMeeting(this.livedetails,'teacher');
            }
            if(this.isStudent && !this.isTeacher){
              this.detailsMeeting(this.livedetails,'student');
            }
            
          }
          else{
            this.toastr.errorToastr("Something went wrong. Please try again later");
            console.log(result.err)
          }
        })
    }
    fetchrecordvideo(id, type) {
        var req = {
            [type]: id,
        };
        this.SpinnerService.show();
        this.api.fetchrecordvideo(req).subscribe(
            (result) => {
                this.SpinnerService.hide();
                if (result.status) {
                    window.open(result.data[0].recordurl, "_blank");
                }
            },
            (err) => {
                this.toastr.errorToastr(err.message);
            }
        );
    }
    callPaymentDetails() {
        const webToken = localStorage.getItem("webtoken");
        this.rest.getCategoryProductReduce(webToken).subscribe((response) => {
            if (this.livedetails.categoryID.categoryName == "K-12") {
                this.packageActive = response.data.k12.length == 0;
            }
            if (this.livedetails.categoryID.categoryName == "NEET") {
                this.packageActive = response.data.neet.length == 0;
            }
            if (this.livedetails.categoryID.categoryName == "JEE") {
                this.packageActive = response.data.jee.length == 0;
            }
            if (this.livedetails.categoryID.categoryName == "Coding") {
                this.packageActive = response.data.coding.length == 0;
            }
            if (this.livedetails.categoryID.categoryName == "NEETPG") {
                this.packageActive = response.data.neetpg.length == 0;
            }
            if (this.livedetails.categoryID.categoryName == "CA") {
                this.packageActive = response.data.ca.length == 0;
            }
            if (this.livedetails.categoryID.categoryName == "UPSC") {
                this.packageActive = response.data.upsc.length == 0;
            }
            if (this.packageActive) {
                var data = {
                    isExpired: true,
                };
                this.packageActive =
                    response.data.upsc.length > 0 ||
                    response.data.neetpg.length > 0 ||
                    response.data.ca.length > 0 ||
                    response.data.jee.length > 0 ||
                    response.data.k12.length ||
                    response.data.neet.length;
                this.router.navigate(
                    [
                        "/" +
                            this.livedetails.categoryID.categoryName +
                            "/" +
                            this.livedetails.categoryID._id,
                    ],
                    { state: data }
                );
                // this.router.navigate(["/"+this.livedetails.categoryID.categoryName+'/'+this.livedetails.categoryID._id],{ state: data });
            }
            //   this.packageActive = response.data.jee.length>0 ||response.data.k12.length || response.data.neet.length
            //     if(!this.packageActive){
            //       // this.toastr.errorToastr("Your One-on-One free classes Credit has been Completed.! Please Subscribe and Continue for the buddi Online Classes.");
            //        // this.router.navigate([isTeacherText], { state: data })

            //     }
            // });
        });
    }
    backto() {
        var data = {
            pagename: "menu1",
        };
        var routerData = JSON.stringify(data);
        var isTeacherText = this.isTeacher ? "/sidenav" : "/sidenav";
        this.router.navigate([isTeacherText], { state: data });
    }
    editmeeting() {
        var text = this.livedetails.automation ? "automation" : "manual";
        this.router.navigate([
            "student-onetoone-create/" +
                text +
                "/" +
                this.livedetails.meetingID,
        ]);
    }
    detailsMeeting(data, text) {
        window.scroll(0, 0);
        if (text == "student") {
            this.studentID = localStorage.getItem("StudentID");
            this.meetingname = data.name;
            if (data.teacherID) {
                var profileName = data.studentID.profileName;
                profileName = profileName
                    .replace(/ /gi, "")
                    .replace(/-/gi, "_");
                this.inviteUrl =
                    this.frontwebsite +
                    "invite/" +
                    profileName +
                    "-" +
                    data.inviteurl;
            }
            this.meetingsactionText = 'Join';
        } else {
            this.teacherID = localStorage.getItem("teacherID");
            this.meetingname = data.name;
            if (data.teacherID) {
                var profileName = data.teacherID.profileName;
                profileName = profileName
                    .replace(/ /gi, "")
                    .replace(/-/gi, "_");
                this.inviteUrl =
                    this.frontwebsite +
                    "invite/" +
                    profileName +
                    "-" +
                    data.inviteurl;
            }
            this.meetingsactionText = "Start";
        }

        this.meetingIDpopup = data._id;
        this.meeting_ID = data._id;
        this.meetingrunningpopup = data.running;
        this.meetingScheduleDate = data.ScheduleDate;
        this.meetingDuration = data.Duration ? data.Duration + "Min" : "";
        this.meetingLanguage = data.Languages;
        this.meetingCity = data.city;
        this.meetingoccurance = data.occurance;
        this.meetingdescription = data.meetingdescription;
        var backimage = "";
        
    }
    copyMessage() {
        const selBox = document.createElement("textarea");
        selBox.style.position = "fixed";
        selBox.style.left = "0";
        selBox.style.top = "0";
        selBox.style.opacity = "0";
        selBox.value = this.inviteUrl;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand("copy");
        document.body.removeChild(selBox);
        this.toastr.successToastr("Copied Successfully");
    }
    removeMeeting(data) {
        const webToken = localStorage.getItem("webtoken");
        if (confirm("Are you sure to delete ")) {
            // this.SpinnerService.show();
            this.rest.removeMeeting(data, webToken).subscribe((result) => {
                // this.SpinnerService.hide();
                if (result.status) {
                    this.router.navigate(["/courses-4-columns-style-3"]);
                } else {
                    this.toastr.errorToastr(result.message);
                }
            });
        }
    }
    meetingsaction(id, action, data) {
        const webToken = localStorage.getItem("webtoken");
        if (action == "login") {
            this.router.navigate([
                "/" + localStorage.getItem("profileType") + "/login",
            ]);
        } else {
            if (action == "join" || action == 'start') {
                // if((action =='join' && !this.isone2one) ||  (action =='join' && this.isone2one && this.packageActive)){
                this.SpinnerService.show();
                this.rest
                    .meetingActionRoundClass(
                        webToken,
                        id,
                        action,
                        'roundTable'
                    )
                    .subscribe(
                        (result) => {
                            this.SpinnerService.hide();
                            if (result.status) {
                                this.SpinnerService.show();
                                setTimeout(function () {
                                    location.href = result.data[0];
                                }, 1000);
                                this.SpinnerService.hide();
                            } else {
                                if (result.message == "Meeting Not Started.") {
                                    var datajson = {
                                        date: this.meetingScheduleDate,
                                    };
                                    this.router.navigate(["/meetingnotstart"], {
                                        state: datajson,
                                    });
                                } else {
                                    this.toastr.errorToastr(result.message);
                                }
                            }
                        },
                        (err) => {
                            console.log(err);
                        }
                    );
            }

            // else{
            //   this.toastr.errorToastr("Please Subscribe the Product");
            // }
        }
    }
    deletemeeting() {
        console.log("IN ANGLUAR", this.livedetails);
        var req = {
            meetingID: this.livedetails.meetingID,
        };
        console.log(this.livedetails.meetingID);
        this.SpinnerService.show();
        this.rest.deloneononemeeting(req).subscribe((result) => {
            this.SpinnerService.hide();
            console.log(result);
            if (result.status) {
                this.toastr.successToastr(result.message);
                this.location.back();
            } else {
                this.toastr.errorToastr(result.message);
            }
        });
    }
    removeFromList(data) {
        var req = {
            libraryType: "meetingID",
            libraryId: data._id,
        };
        const webToken = localStorage.getItem("webtoken");
        this.rest.removeList(req, webToken).subscribe((result) => {
            if (result) {
                this.toastr.successToastr(result.message);
                var data = {
                    message: "Removed",
                };
                this.router.navigate(["/success/enrollclass"], { state: data });
            } else {
                this.toastr.errorToastr(result.message);
            }
        });
    }
}
