import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Options } from 'ng5-slider';
import { MediaService } from '../../../../shared/media.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Location } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";
import {NgSelectModule, NgOption} from '@ng-select/ng-select';
import { ApiService } from '../../../../shared/api.service';
@Component({
  selector: 'app-teacher-audio-create',
  templateUrl: './teacher-audio-create.component.html',
  styleUrls: ['./teacher-audio-create.component.scss']
})
export class TeacherAudioCreateComponent implements OnInit {
  selectedCityId: number;
  songs : any =[];
  formData;
  allfiles : any = [];
  songsList = [];
  createForm: FormGroup;
  submitted = false;
  nameList : any = [];
  allPoster : any = [];
  // loader:boolean = false;
   uploadSongName: string = 'Choose File';
   isMulAlbum:boolean = false;
   selectedRouteId:any;
   selectedRouteValue:any;
   public songsListget:any;
   category:any;
   formData1;
  selectedAlbumId: string;
  uploaPosterName: string = 'Choose File';
  meetCategory:any=[];
  selectedValue = "";
  public meetingCategory = "Select category";
  public meetingType = "Select Session Type";
  public tagtype ="Select Tag";
  data: any;
  public userId:any = '';  
  alltag:any=[];
  allcategory:any=[];
  securityArray:any=[];
  public groupName:any = [];
  public editData:any=[];
  public paramData:any = [];
  public selectedUserIds:any=[];
  public isEnterprise = '';
  public thumbnailFile : any = [];
  public thumbnailPlace:any = '';
  public teacherID:any='';
  public isTeacher:boolean = false;
 public studentID:any = '';
public selecttag:any = [];
 public coursecategorylist : any = [];
 public courseboardlist : any = [];
 public coursesubjectlist : any = [];
 public languageapi : any = [];
 public gradeapi : any = [];

public partnerList:any = [];
public isPartnerUser:any = [];
 public isEdit:Boolean = false;
 selectedcategorylist = [];
 selectedgradelist = [];
 selectedsubjectlist = [];
 dropdownSettings: any = {};
  dropdowngradeSettings: any = {};
  dropdownsubjectSettings: any = {};
  constructor(private activatedRoute :ActivatedRoute,private rest : ApiService,private SpinnerService: NgxSpinnerService,public toastr: ToastrManager,private location: Location,private api: MediaService,private formBuilder: FormBuilder,private router: Router) { }

  ngOnInit() {
    var btn = (document.getElementById('addAudioButton') as HTMLButtonElement);
    btn.disabled = false;
    window.scrollTo(0, 0);
    this.getSongList();
    // this.meetingcategory();
    this.tagfield();
    this.getSecurity();
    this.userId = localStorage.getItem('userId');
    const webToken = localStorage.getItem('webtoken');
    this.isTeacher =localStorage.getItem('profileType').toLowerCase() =='teacher' ;
    this.isPartnerUser = localStorage.getItem('isPartner') =='true'?true:false;
    this.createForm = this.formBuilder.group({
      id: null,
      
      upload_name: [null],
      fileupload: ['', Validators.required],

      chapterName: ['', Validators.required],
      caption: ['', Validators.required],
      flimname: ['', Validators.required],
      type: [''],
      benefit: ['', Validators.required],
      tag: [''],
      description: [''],
      securitytype: ['Public', Validators.required],
      additional: [''],
      autor: [''],
      thumbnail: ['', Validators.required],
      // courseGrade: [''],
      // courseSubject: [''],
      courseLanguage:['', Validators.required],
      // courseCategory: [''],
      courseBoard: ['', Validators.required],
      providerBy:['buddi Premium', Validators.required],
      partner:['', Validators.required]
    });

    this.dropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'categoryName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3
  };

    this.dropdowngradeSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'gradeName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3
  };
  
  this.dropdownsubjectSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'subjectName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3
  };

    this.getcoursecategory();
    this.getboardfield();
    this.getsubjectfield();
    this.getlanguage();
    this.getgrade();
    this.getPartner();

    if(this.isTeacher){
      this.rest.userName_DisplayTeacher(webToken).subscribe((result) => {
        console.log(result);
        this.groupName =  result.data !=undefined && result.data.groupName != 'buddi' ? result.data.groupName : ''
        localStorage.setItem('groupname', result.data !=undefined && result.data.groupName != 'buddi' ? result.data.groupName : '');
      });
     }else{
      this.rest.userName_DisplayStudent(webToken).subscribe((result) => {
        console.log(result);
        this.studentID = result.data._id     
      });
     }
   
    var urlArray = window.location.href.split('/');
    var req={
      id:urlArray[urlArray.length-1],
    }

    this.api.getAudiosByID(req,webToken).subscribe((result) => {
     if(result.data.length!=0){
      this.editData =result.data;
          if(this.isPartnerUser){
        this.createForm.patchValue({ providerBy:  this.editData[0].isbuddi_premium ? 'buddi Premium' :'Partner'})
        if(!this.editData[0].isbuddi_premium){
          this.createForm.patchValue({ partner:  this.editData[0].partner._id}); 
        }
       
      }
       this.isEdit = this.editData[0]._id ?true:false;
      this.createForm.patchValue({ caption:  this.editData[0].typename})
      this.createForm.patchValue({ flimname:  this.editData[0].flimname})
      this.createForm.patchValue({ type:  this.editData[0].type})
      this.createForm.patchValue({ benefit:  this.editData[0].benefit})
      this.createForm.patchValue({ name:  this.editData[0].name})
        this.selecttag  = this.editData[0].tag == "" ? '' :this.editData[0].tag.split(",");
      this.createForm.patchValue({ id:  this.editData[0]._id})
      this.createForm.patchValue({ description:  this.editData[0].description})
      this.createForm.patchValue({ securitytype:  this.editData[0].securitytype})
      this.createForm.patchValue({ additional:  this.editData[0].additional})
      this.createForm.patchValue({ autor:  this.editData[0].autor})
      this.uploadSongName = this.editData[0].originalname;
       this.groupName  = this.editData[0].groupname
      this.createForm.patchValue({ fileupload:  this.editData[0].path})
      this.createForm.patchValue({ thumbnail:  this.editData[0].thumbname})
      this.createForm.patchValue({ thumbname:  this.editData[0].thumbname})
      this.createForm.patchValue({ chapterName:  this.editData[0].chapterName})
      // this.createForm.patchValue({ courseCategory:this.editData[0].categoryID._id})
      // this.createForm.patchValue({ courseGrade:this.editData[0].gradeID._id})
      this.createForm.patchValue({ courseBoard:this.editData[0].boardID._id})
      // this.createForm.patchValue({ courseSubject:this.editData[0].subjectID._id})
      this.createForm.patchValue({ courseLanguage:this.editData[0].languageID._id})
      this.createForm.patchValue({ fileupload:this.editData[0].name})
      this.thumbnailPlace = this.editData[0].thumbfilename
      this.selectedcategorylist = this.editData[0].categoryID;
      this.selectedgradelist = this.editData[0].gradeID;
      this.selectedsubjectlist = this.editData[0].subjectID;
      }
      });    
    }
  get f() {
    return this.createForm.controls;
  }
  goBack() {
    this.location.back();
  }

getcoursecategory() {
  this.rest.getCourseCategoryHeader().subscribe((result) => {
    this.coursecategorylist = result.data;
    if(Object.keys(this.paramData).length == 0 && !this.isEdit){
      this.createForm.patchValue({ courseCategory: this.coursecategorylist[0]._id })
    }
    console.log("coursecategory",this.coursecategorylist);
  }, (err) => {
  console.log(err);
  });
}
getPartner(){
  this.rest.getAllPartner().subscribe((result) => {
    if(result.status){
      this.partnerList = result.data;  
      if(this.partnerList.length!=0){
        this.createForm.patchValue({ partner: this.partnerList[0]._id })
      }
    }    
    
  }, (err) => {

  });
}
getboardfield(){
  this.rest.boardfield().subscribe((result) => {
    this.courseboardlist = result.data;
    if(Object.keys(this.paramData).length == 0 && !this.isEdit){
      this.createForm.patchValue({ courseBoard: this.courseboardlist[0]._id })
    }
    console.log(this.courseboardlist);
  }, (err) => {
  console.log(err);
  });
}

getsubjectfield(){
  this.rest.subjectfield().subscribe((result) => {
    this.coursesubjectlist = result.data;
    if(Object.keys(this.paramData).length == 0 && !this.isEdit){
      this.createForm.patchValue({ courseSubject: this.coursesubjectlist[0]._id })
    }
    console.log(this.coursesubjectlist);
  }, (err) => {
  console.log(err);
  });
}

getlanguage(){
  this.rest.languagefield().subscribe((result) => {
    this.languageapi = result.data;
    if(Object.keys(this.paramData).length == 0 && !this.isEdit){
      this.createForm.patchValue({ courseLanguage: this.languageapi[0]._id })
    }
    console.log(this.languageapi);
  }, (err) => {
  console.log(err);
  });
}

getgrade(){
  this.rest.grade().subscribe((result) => {
    this.gradeapi = result.data;
    if(Object.keys(this.paramData).length == 0 && !this.isEdit){
      this.createForm.patchValue({ courseGrade: this.gradeapi[0]._id })
    }
  }, (err) => {
  console.log(err);
  });
}

  selectChange (event: any) {
    this.selectedValue = event.target.options[event.target.selectedIndex].text;
   }
   onSelectFile(event) {
    this.thumbnailFile=[];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url
      this.thumbnailFile.push(event.target.files[0]);
      this.createForm.patchValue({ thumbnail: this.thumbnailFile[0].name });
      this.thumbnailPlace = this.thumbnailFile[0].name;
      reader.onload = (event) => { // called once readAsDataURL is completed
        // this.url = event.target.result;
        
      }
    }
  }
   tagfield(){
    this.rest.tagfield().subscribe((result) => {
      this.alltag = result;
      // this.createForm.patchValue({ tag: this.alltag[0].name})
      console.log(this.alltag);
    }, (err) => {
    console.log(err);
    });
  }
  getSecurity(){
    this.rest.security().subscribe((result) => {
      this.securityArray = result;
      console.log(this.securityArray);
    }, (err) => {
    console.log(err);
    });
  }



  
  fileChangeposter(event){
    const files = event.target.files;
    if(files){
      for(let i=0;i< files.length; i++){
        const video = {
          name : "",
          type : "",
          size : "", 
          url : "",
        };
        this.allPoster.push(files[i]);
        const reader = new FileReader();
        reader.onload = (filedata) => {
          // video.url = reader.result + " ";
          // this.videos.push(video);
        }
        // this.nameList.push(files[i].name);
        // video.name = files[i].name;
        // video.type = files[i].type;
        // video.size = files[i].size;
        // const reader = new FileReader();
        // reader.onload = (filedata) => {
        //   video.url = reader.result + " ";
        //   this.videos.push(video);
        // }

        reader.readAsDataURL(files[i]);
      }
  }
  // if(files[0].name.join(',').length > 29){
  //   this.uploaPosterName =  files[0].name.join(',').substring(0,30)+'...';
  // }else{
  //   this.uploaPosterName =  files[0].name.join(',')
  // }
  this.uploaPosterName = files[0].name;
   this.createForm.patchValue({ fileposter: files[0].name });
  event.srcElement.value = null;
  }
  getSongList() {
    //this.loader = true;
    this.api.getSongList().subscribe((response) => {
    //  this.loader = false;
      this.selectedRouteId = this.router.url.split(';').length>1 ? this.router.url.split(';')[1].split('=')[1] : '';
      console.log('hashdhsad',this.selectedRouteId)
      this.selectedRouteValue = this.router.url.split(';').length>1 ? this.router.url.split(';')[2].split('=')[1] : '';
      this.selectedAlbumId = this.router.url.split(';').length>1 ? this.router.url.split(';')[3].split('=')[1] : '';
      console.log('ID',this.selectedAlbumId)
      if(response.status){
        this.songsListget = response.data.reverse();
          console.log(this.songsListget);
        this.api.getSongDetails(this.selectedAlbumId).subscribe((response) => {
          this.data = response.data;
          console.log(this.data);
        });
        
        //this.createForm.controls['category'].setValue(1, {onlySelf: true});
        
      }
    });
  }

getsong() {
  console.log('ID',this.selectedRouteId)
}

  deleteItem(item: any) {
    const index = this.songs.indexOf(item);
    this.nameList.splice(index,1);
    if(this.nameList.join(',').length > 29){
      this.uploadSongName =  this.nameList.join(',').substring(0,30)+'...';
    }else{
      this.uploadSongName =  this.nameList.join(',');
    }
    if(this.uploadSongName =='...'  || this.nameList.length ==0){
      this.uploadSongName = 'Choose File';
    }
    this.songs.splice(index,1);
    this.allfiles.splice(index,1);
  }
  resetData(){
    this.createForm.reset();
    this.songs =[];
    this.allfiles =[];
    this.songsList = [];
    this.submitted = false;
    this.nameList  = [];
    this.uploadSongName = 'Choose File';
  }
  songuploads(event) {
    if(this.createForm.value.id && event.target.files.length < 2 && this.allfiles.length <1){
      const files = event.target.files;
    console.log(files);
    if(files){
      for(let i=0;i< files.length; i++){
        const song = {
          name : "",
          type : "",
          size : "",
          url : "",
        };
        this.allfiles.push(files[i]);
        console.log(this.allfiles);
        this.nameList.push(files[i].name);
        console.log(this.nameList);
        song.name = files[i].name;
        song.type = files[i].type;
        song.size = files[i].size;
        const reader = new FileReader();
        reader.onload = (filedata) => {
          song.url = reader.result + " ";
          this.songs.push(song);
        }
        reader.readAsDataURL(files[i]);
      }
    }
    
    if(this.nameList.join(',').length > 29){
      this.uploadSongName =  this.nameList.join(',').substring(0,30)+'...';
    }else{
      this.uploadSongName =  this.nameList.join(',');
    }
    this.createForm.patchValue({ fileupload: this.uploadSongName });
	  event.srcElement.value = null;

    }else if(!this.createForm.value.id ){
      const files = event.target.files;
    console.log(files);
    if(files){
      for(let i=0;i< files.length; i++){
        const song = {
          name : "",
          type : "",
          size : "",
          url : "",
        };
        this.allfiles.push(files[i]);
        console.log(this.allfiles);
        this.nameList.push(files[i].name);
        console.log(this.nameList);
        song.name = files[i].name;
        song.type = files[i].type;
        song.size = files[i].size;
        const reader = new FileReader();
        reader.onload = (filedata) => {
          song.url = reader.result + " ";
          this.songs.push(song);
        }
        reader.readAsDataURL(files[i]);
      }
    }
    
    if(this.nameList.join(',').length > 29){
      this.uploadSongName =  this.nameList.join(',').substring(0,30)+'...';
    }else{
      this.uploadSongName =  this.nameList.join(',');
    }
    this.createForm.patchValue({ fileupload: this.uploadSongName });
	  event.srcElement.value = null;
    }else{

    }
    
  }
back(){
  this.location.back();
}
  onSubmit(status) {
    this.submitted = true;
    var btn = (document.getElementById('addVideoButton') as HTMLButtonElement);
    btn.disabled = true;
    if (!this.createForm.valid) {
      btn.disabled = false;
      return;
    }
    if(this.selectedcategorylist.length == 0) {
      this.toastr.errorToastr("Category Required");
      btn.disabled = false;
      return;
    }
    if(this.selectedgradelist.length == 0) {
      this.toastr.errorToastr("Grade Required");
      btn.disabled = false;
      return;
    }
    if(this.selectedsubjectlist.length == 0) {
      this.toastr.errorToastr("Subject Required");
      btn.disabled = false;
      return;
    }
   
    let data = this.createForm.value;
    let categoryresult = this.selectedcategorylist.map(a => a._id);
    let graderesult = this.selectedgradelist.map(a => a._id);
    let subjectresult = this.selectedsubjectlist.map(a => a._id);
    var formData = new FormData();

    for(let i =0; i < this.allfiles.length; i++){
      formData.append("upload_name", this.allfiles[i], this.allfiles[i]['name']);
    }
    if(this.thumbnailFile.length !=0){
      formData.append("thumbname", this.thumbnailFile[0],this.thumbnailFile[0]['name']);
    }
    // formData.append("poster_image", this.allPoster[0],this.allPoster[0]['name']);
    // formData.append("sharingType", data.sharingType == "Public" ? '1':'0');
    formData.append("flimname", data.flimname);
    formData.append("caption", data.caption);
    formData.append("userPlaylistId", this.selectedRouteId);

     // New Field Starttypename
     formData.append("typename", data.caption );
    //  formData.append("category", data.category);
     formData.append("type", data.type);
     formData.append("benefit", data.benefit);
     formData.append("chapterName", data.chapterName);
    //  if(data.tag=='' || data.tag ==null){
    //   formData.append("tag",'');
    // }else{
    //   var temp =[];
    // data.tag.forEach(function(val){temp.push(val.name)});
    // formData.append("tag",JSON.stringify(data.tag));
    // }
      formData.append("isPartner", this.isPartnerUser);
      if(this.isPartnerUser){
        formData.append("providerBy",data.providerBy);
        if(data.providerBy =='buddi Premium'){
           formData.append("isbuddi_premium",'true');
         }else{
           formData.append("partner",data.partner);
            formData.append("isbuddi_premium",'false');
         }
      } 
    formData.append("originalname",this.uploadSongName);
    formData.append("tag",data.tag);
     formData.append("description", data.description);
     formData.append("securitytype",  this.isTeacher ?data.securitytype :'Private');
     formData.append("additional", data.additional);
     formData.append("autor", data.autor);
     formData.append("profileType", this.isTeacher  ? 'teacher':'student');
     formData.append("groupname",data.securitytype =='Group' ? this.groupName : '');
     formData.append("categoryID", JSON.stringify(categoryresult).slice(1,-1).replace(/['"]+/g, ''));
     formData.append("languageID", data.courseLanguage);
     formData.append("subjectID", JSON.stringify(subjectresult).slice(1,-1).replace(/['"]+/g, ''));
     formData.append("boardID", data.courseBoard);
     formData.append("gradeID", JSON.stringify(graderesult).slice(1,-1).replace(/['"]+/g, ''));
     // New Field End
    // if( this.selectedRouteId !=''){
    //   formData.append("category", this.selectedRouteId);
    // }else{
    // formData.append("category", data.category);
    // }

    this.SpinnerService.show();

  
    
    if(!this.createForm.value.id){
      this.api.addSongList(formData).subscribe((result) => {
        this.SpinnerService.hide();
      //  this.resetData();
        if(result.status) {
          console.log(result);
          this.router.navigate(['/success/Audio']);
            this.toastr.successToastr(result.message);
            //this.router.navigate(['/add-multiple-song-list']);
        } else {
          btn.disabled = false;
          this.toastr.errorToastr(result.message);
        }
       });
    }else{
      formData.append("id", data.id);
      this.api.editSongList(formData).subscribe((result) => {
        this.SpinnerService.hide();
    //   this.resetData();
        if(result.status) {
            this.location.back();
            this.toastr.successToastr(result.message);
            btn.disabled = false;
            //this.router.navigate(['/add-multiple-song-list']);
        } else {
          btn.disabled = false;
          this.toastr.errorToastr(result.message);
        }
       });
    }
 
   }

   route() {
    this.router.navigate(['/song-album',{'songId':this.selectedAlbumId}]); 
  }

  onItemsubjectSelect(item: any) {
     this.selectedsubjectlist.push(item)
     this.selectedsubjectlist = Array.from(this.selectedsubjectlist.reduce((m, t) => m.set(t._id, t), new Map()).values());
    }
    
    onItemsubjectDeSelect(item1: any) {
      this.selectedsubjectlist = this.selectedsubjectlist.filter(item => !item1._id.includes(item))
    }
   
    onItemsubjectallDeSelect(item1: any) {
     for (let i = 0; i < item1.length; i++) {
       this.selectedsubjectlist = this.selectedsubjectlist.filter(item => !item1[i]._id.includes(item))
       };
   }
   onItemsubjectallSelect(item1: any) {
     this.selectedsubjectlist = [];
     for (let i = 0; i < item1.length; i++) {
       this.selectedsubjectlist.push(item1[i])
       };
   }

   onItemcategorySelect(item: any) {
    this.selectedcategorylist.push(item)
    this.selectedcategorylist = Array.from(this.selectedcategorylist.reduce((m, t) => m.set(t._id, t), new Map()).values());
   }
   
   onItemcategoryDeSelect(item1: any) {
     this.selectedcategorylist = this.selectedcategorylist.filter(item => !item1._id.includes(item))
   }
   
   onItemcategoryallDeSelect(item1: any) {
     for (let i = 0; i < item1.length; i++) {
       this.selectedcategorylist = this.selectedcategorylist.filter(item => !item1[i]._id.includes(item))
       };
   }
   onItemcategoryallSelect(item1: any) {
     this.selectedcategorylist = [];
     for (let i = 0; i < item1.length; i++) {
       this.selectedcategorylist.push(item1[i])
       };
   }
   
   onItemgradeSelect(item: any) {
     this.selectedgradelist.push(item)
     this.selectedgradelist = Array.from(this.selectedgradelist.reduce((m, t) => m.set(t._id, t), new Map()).values());
    }
    
    onItemgradeDeSelect(item1: any) {
      this.selectedgradelist = this.selectedgradelist.filter(item => !item1._id.includes(item))
    }
   
    onItemgradeallDeSelect(item1: any) {
     for (let i = 0; i < item1.length; i++) {
       this.selectedgradelist = this.selectedgradelist.filter(item => !item1[i]._id.includes(item))
       };
   }
   onItemgradeallSelect(item1: any) {
     this.selectedgradelist = [];
     for (let i = 0; i < item1.length; i++) {
       this.selectedgradelist.push(item1[i])
       };
   }

}
