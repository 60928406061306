import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild, NgZone } from '@angular/core';

import { ApiService } from '../../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { lastnamevalidator } from './../../../../shared/lastnamevalidator'
import * as $ from "jquery";
import { Location } from '@angular/common';

@Component({
  selector: 'app-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss']
})
export class RegisterPageComponent implements OnInit {
  // public password = 'password';
  public cpasswordEye = 'password';
  registerform: FormGroup;
  userType: any;

  otpForm: FormGroup;
  isRegisterInvisible: true;
  submitted = false;
  value: any;
  timeLeft: number = 25;
  interval;
  isOTPButtonDisabled = false;
  isChangeNumberButtonDisabled = false;
  phoneNumber;
  changeNumberStatus = false;
  otpSubmitted = false;

  constructor(
    public fb: FormBuilder,
    public toastr: ToastrManager,
    private router: Router,
    private ngZone: NgZone,
    private userApi: ApiService,
    private my_router: ActivatedRoute,


  ) { }



  ngOnInit() {
    let queryString = window.location.search;
    let params = new URLSearchParams(queryString);
    let referralId = params.get('referralId');
    document.getElementById('OTPFormIDt').hidden = true;
    document.getElementById('registerFormIDt').hidden = false;
    document.getElementById('successmessage').hidden = true;
    // document.getElementById('OTPFormIDt').hidden = true;
    // document.getElementById('otpResendButtont').hidden = true;
    // document.getElementById('otpSentSuccesst').hidden = true;
    this.changeNumberStatus = false;
    this.registerform = this.fb.group({
      Profile_name: ['', [Validators.required, Validators.pattern("^[a-zA-Z ,.'-]+$")]],
      email: ['', [Validators.required, Validators.email]],
      // userType : [''],
      // first_name: ['', [Validators.required, Validators.pattern("^[a-zA-Z ,.'-]+$"),lastnamevalidator.cannotContainSpace]],
      // last_name: ['', [Validators.required, Validators.pattern("^[a-zA-Z ,.'-]+$"),lastnamevalidator.cannotContainSpace]],
      // email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      mobileNumber1: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(20), Validators.pattern("^[0-9 (),.+'-]+$")]],
      passWord: ['', [Validators.required, Validators.minLength(6), Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@_#$!%*?&])[A-Za-z0-9@$!%*#_?&]{6,}$")]],
      referralId : [''],
      // checkpassWord: ['', [ Validators.required]]
      //acceptTerms: [false, Validators.requiredTrue],
    });
    if(referralId){
      this.registerform.patchValue({
        referralId:referralId
      })
    }
    this.otpForm = this.fb.group({
      otp: ['', [Validators.required, Validators.maxLength(6), Validators.minLength(6), Validators.pattern("^[0-9]*$")]]
    })
  }

  get f() { return this.registerform.controls; }

  get otpf() { return this.otpForm.controls; }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      }
      else {
        document.getElementById('otpResendTimer').hidden = true;
        document.getElementById('otpResendButtont').hidden = false;

        clearInterval(this.interval);
      }
    }, 1000)
  }

  studentLogin(){
    window.location.href = '/student/register';
  }
  teacherLogin(){
    window.location.href = '/teacher/register';
  }
  teacher(){
    window.location.href = '/teacher/login';
  }
  RegisterSubmit() {

    this.timeLeft = 25;
    this.startTimer();
    document.getElementById('registerFormIDt').hidden = true;
    document.getElementById('OTPFormIDt').hidden = false;
    console.log('checking register', this.registerform.value)
    this.submitted = true;
    console.log(this.registerform.invalid);
    this.phoneNumber = this.registerform.value.mobileNumber1;
    if (!this.changeNumberStatus) {
      if (!this.registerform.invalid) {
        // if (this.registerform.value.passWord === this.registerform.value.checkpassWord) {
        // const data = this.registerform.value;
        // console.log(data);
        // data.profileName = data.first_name;
        // data.cpassword = data.checkpassWord;

        //   this.userApi.AddUserWithoutOTP(data).subscribe(res => {
        //   console.log('result', res );
        //   if(res.status){
        //     this.toastr.successToastr(res.message);
        //     this.router.navigate(['/login']);
        //   }else{
        //     this.toastr.errorToastr(res.message);
        //   }
        // });
        var req = {
          "email": this.registerform.value.email,
          "phoneNumber": this.registerform.value.mobileNumber1,
          "profileName":this.registerform.value.Profile_name,
          "password": this.registerform.value.passWord,
          'referralId':this.registerform.value.referralId,
        }
        this.userApi.AddUserTeacher(req).subscribe(res => {
          document.getElementById('OTPFormIDt').hidden = false;
          document.getElementById('registerFormIDt').hidden = true;
          console.log('result', res);
          if (res.status) {
            document.getElementById('registerFormIDt').hidden = true;
            document.getElementById('OTPFormIDt').hidden = false;
            document.getElementById('header').hidden = true;
            document.getElementById('signedin').hidden = true;
            document.getElementById('successmessage').hidden = false;
            // document.getElementById('OTPFormIDt').hidden = false;
            // this.startTimer()
            //  this.toastr.successToastr(res.message);
          } else {
            document.getElementById('OTPFormIDt').hidden = true;

            // this.toastr.errorToastr(res.message);
            if(res.restype){
              console.log('checkelse')
              this.toastr.errorToastr(res.message);
            this.router.navigate(['teacher/login']);
            }
            // document.getElementById('registerFormIDt').hidden = true;
            // document.getElementById('OTPFormIDt').hidden = false;

          }
        });
        // }
        // else {
        //   this.toastr.errorToastr('Password Mismatch');
        //   document.getElementById('registerFormIDt').hidden = false;
        //   document.getElementById('OTPFormIDt').hidden = true;

        // }
      }
      else {
        this.toastr.errorToastr('Please check all fields');
        document.getElementById('registerFormIDt').hidden = false;
        document.getElementById('OTPFormIDt').hidden = true;

      }
    }
    // else{
    //   if(!this.registerform.invalid) {
    //     if (this.registerform.value.passWord === this.registerform.value.checkpassWord) {
    //       const data = this.registerform.value;
    //       data.profileName = data.first_name;
    //       data.cpassword = data.checkpassWord;
    //       this.userApi.changePhoneNumber(data).subscribe(res => {
    //       console.log('result', res );
    //       if(res.status){
    //         document.getElementById('registerFormIDt').hidden = true;
    //         // document.getElementById('OTPFormIDt').hidden = false;
    //         // document.getElementById('otpResendTimer').hidden = false;
    //         // this.startTimer()
    //         this.toastr.successToastr(res.message);
    //       }else{

    //         this.toastr.errorToastr(res.message);
    //       }
    //       //this.toastr.successToastr('Activation Mail Sent to your Registered email ID, Check your Mailbox');
    //       //this.router.navigate(['/login']);
    //     });
    //     }
    //     else {
    //       this.toastr.errorToastr('Password Mismatch');
    //       // alert("Insert Valid Password")
    //     }
    //   } 
    //   else {
    //       //this.toastr.errorToastr('You must fill all fields');
    //       this.toastr.errorToastr('Please check all fields');
    //   }        
    // }
  }

  OTPSubmit() {
    this.otpSubmitted = true;
    console.log('checking register', this.registerform.value)
    var data = this.registerform.value;
    var req = {
      "email": this.registerform.value.email,
      "phoneNumber": this.registerform.value.mobileNumber1,
      "otp": this.otpForm.value.otp
    }
    if (!this.otpForm.invalid) {
      data["otp"] = this.otpForm.value.otp;
      this.userApi.checkOTPTeacher(req).subscribe(result => {
        console.log(result, 'result==================checkOTPTeacherValid')
        if (result.status) {
          console.log(result.data, '-----------reasdfdfdfdfdfdfdfd---------')
          var data = result.data.teacher;
          localStorage.setItem('webtoken', data.token);
          localStorage.setItem('teacherID', data.user_id);
          localStorage.setItem('isPartner', (data.email == 'buddilife@gmail.com' ? 'true' : 'false'));
          localStorage.setItem('profileName', data.profileName);
          localStorage.setItem('userType', data.userType);
          localStorage.setItem('isLoggedin', 'true');
          localStorage.setItem('userId', data.user_id);
          localStorage.setItem('groupname', data.groupName);
          localStorage.setItem('profileType', 'Teacher');

          this.toastr.successToastr('Welcome , you are now logged in!  ');
          this.userType = data.userType;
          console.log(this.userType);
          var inviteid = this.my_router.snapshot.queryParamMap.get('invite');
          this.feedbackform(result.token);
          window.location.href = '/upgrade';
          // if (inviteid == null) {
          //   if (this.userType == 1) {
          //     window.location.href = '/kyc';
          //   }
          //   if (this.userType == 2) {
          //     window.location.href = '/kyc';
          //   }
          // } else {
          //   var split_id = inviteid.split('-');
          //   window.location.href = '/invite/' + split_id[1];
          // }
          this.toastr.successToastr(result.message);
          // this.router.navigate(['/teacher/mydashboard']);
        } else {
          this.toastr.errorToastr(result.message);
        }

      });
    }
    else {
      console.log(this.otpForm);
      this.toastr.errorToastr("OTP Cannot be empty");
    }
  }

  changePhoneNumber() {
    this.changeNumberStatus = true;
    // document.getElementById('registerFormIDt').hidden = false;
    // document.getElementById('OTPFormIDt').hidden = true;
    // document.getElementById('otpResendTimer').hidden = true;
    // document.getElementById('otpResendButtont').hidden = true;
    window.location.href = "/teacher/register";

    // clearInterval(this.interval);
    // this.timeLeft = 15;
  }

  otpResendClicked() {
    console.log("Clicked");
    document.getElementById('otpResendButtont').hidden = true;
    document.getElementById('otpResendTimer').hidden = false;
    this.timeLeft = 60;
    this.startTimer();
    console.log('checking register', this.registerform.value)
    // var data = this.registerform.value;
    var req = {

      "phoneNumber": this.registerform.value.mobileNumber1,
    }
    this.userApi.checkUserTeacher(req).subscribe(res => {
      if (res.status) {
        this.toastr.successToastr(res.message);
        //this.router.navigate(['/login']);
      }
      else {
        this.toastr.errorToastr(res.message);
      }
    });

    (<HTMLInputElement>document.getElementById("otpResendButtont")).disabled = true;
    this.isOTPButtonDisabled = true;
    document.getElementById('otpResendButtont').hidden = true;
    //document.getElementById('otpSentSuccesst').hidden = false;




  }
  feedbackform(webToken) {
    this.userApi.getfeedbPendingTeacher(webToken).subscribe((result) => {
      console.log(result, 'result');
      if (result.data.length != 0) {
        localStorage.setItem('isPendindTeacher', 'true');
        // this.toastr.successToastr(result.message);
        this.router.navigate(['feedbackTeacher']);
      }
      else {
        window.location.href = '/sidenav';
      }
    }, (err) => {
      console.log(err);
    });
  }
  receivedData(data) {
    this.cpasswordEye = data;
  }
  creceivedData(data) {
    this.cpasswordEye = data;
  }
}
