<section class="offer-area pt-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="section-title">
          
            <h2>How Tutor and Learner can benefit</h2>
        </div>
        <!-- <h4>Live  classes ( One-to-one, Group and Webinars)</h4>
        <h4>Metaverse Classes for Immersive experience</h4>
        <h4>NFT Learning Assets (MCQ, Video Class, Study Materials) </h4>
        <h4>buddi Rewards</h4> -->
        <br>
        <!-- <div class="row">
            <div class="col-md-6 benfit">
            <h5> <i class="bi bi-collection"></i> simple for collection</h5>
            </div>
            <div class="col-md-6 benfit">
            <h5> <i class="bi bi-bell-fill"></i> Class Reminders and Timetable</h5>
            </div>
        </div> -->
<br>
        <!-- <div class="row">
            <div class="col-md-6 benfit">
           <h5> <i class="bi bi-calendar3"></i> Automated Attendance</h5>
            </div>
            <div class="col-md-6 benfit">
            <h5> <i class="bi bi-share-fill"></i> Organize amd Share Study Material</h5>
            </div>
        </div> -->
        
<!-- <div class="boder"> -->
        <div class="row kin">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-offer-box">
                    <!-- <div class="icon">
                        <i class='bx bx-book-reader'></i>
                    </div>
                    <h3>Best in Class Tutors</h3> -->
                  
                <div class="logo1">
                 <img src="../../../../../assets/img/live class logo.png" alt="Live class"/>
                 </div>
                 <div class="cls1">
                  <p>Live  classes (One-to-one, Group and Webinars)</p>
                  </div>
            </div>
            </div>
    
           
            
            

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-offer-box">
                    <!-- <div class="icon">
                        <i class='bx bx-book-reader'></i>
                    </div>
                    <h3>Best Value For Money</h3> -->
                  
                    <div class="logo1">
            
                        <img src="../../../../../assets/img/nft.jpeg" alt="NFT Learning"/>
                 </div>
                 <div class="cls1">
                        <p> NFT Learning Assets (MCQ, Video Class, Study Materials)</p>
                </div>

               
                
                     
                  
                     
            </div>
            </div>
            <!-- </div> -->
<!-- <div class="row kin"> -->
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-offer-box">
                    <!-- <div class="icon">
                        <i class='bx bx-target-lock'></i>
                    </div>
                    <h3>Learn in Native Language too</h3> -->
                   
                
                <div class="logo1">
            
                    <img src="../../../../../assets/img/Metaverse Classes.png" alt="Metaverse Classes"/>
             </div>
             <div class="cls1">
                    <p> Metaverse Classes for Immersive experience</p>
            </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6 ">
                <div class="single-offer-box">
                    <!-- <div class="icon">
                        <i class='bx bxs-thermometer'></i>
                    </div>
                    <h3>Fully Integrated Digital Platform</h3>
                    -->
                    <div class="logo2">
                        <img src="../../../../../assets/img/buddi Rewards.jpeg" alt="buddi Rewards"/>
                        </div>
                        <div class="cls1">
                         <p>buddi Rewards</p>
                         </div>
                
                </div>
            </div>
            </div>

        <!-- </div>  -->
    </div>
</section>