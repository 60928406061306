
<div *ngIf="isTeacher">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>
<div *ngIf="!isTeacher">
    <app-header-style-one></app-header-style-one>
</div>
<div  class="">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="/student/home">Home</a></li>
                <li><a href="/all-study-listing">Study Material</a></li>
                <li *ngIf="livedetails" >{{livedetails.materialName}} </li>
            </ul>
            <h3 style="color: #000000; padding-top:25px;padding-bottom:25px; cursor: pointer" class="col-lg-12">
					Study Material
				</h3>
        </div>
    </div>
</div>

<section class="courses-details-area pt-50 pb-70" *ngIf="livedetails.length != 0">
    <div class="container">
        <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="courses-title">
                        <h2 style="overflow-wrap: break-word;">{{livedetails.flimname}} 
                        </h2>                      
                    </div>
                    <div class="courses-meta">
                        <ul>
                            <li>
                                <i class='bx bx-folder-open'></i>
                                <span>Category</span>
                                <!-- <a>{{categoryarrayvalues}}</a> -->
                                <a>{{livedetails.categoryID.categoryName}}</a>

                            </li>
                            <!-- <li class="nav-item pr-2  mt-2" style="cursor: pointer;" *ngIf="isLoggedin =='true'">
                                <a class="default-btn" href="{{livedetails.materialPath}}">Download Documents</a>
                            </li> -->
                        </ul>
                    </div>
                    <div class="courses-meta">                        
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="courses-price" style="display: flex;justify-content: flex-end;">
                        <!--<a class="default-btn mr-2" target="_blank" *ngIf="livedetails.providerByName=='You Tube' && isLoggedin =='true'" [href]="livedetails.name">                            
                            <i class='bx bx-paper-plane icon-arrow before'></i>
                                <span class="label">Watch</span>
                            <i class="bx bx-paper-plane icon-arrow after"></i>
                        </a>  -->
                        <button  class="default-btn mr-1 p-2" (click)="backto()"><i class="fas fa-long-arrow-alt-left kin"></i>
                            Back
                        </button>
                        <a  *ngIf="(isLoggedin =='true' && purchase == true) || (isLoggedin == 'true' && livedetails.teacherID._id == teacherID)" class="default-btn mr-1" style="padding: 12px 20px 8px 20px;" href="{{livedetails.materialPath}}">Click to View</a>
                        <button  *ngIf="isLoggedin =='true' && purchase == false && livedetails.teacherID._id != teacherID" class="default-btn mr-1"  (click)="addToDashboard(livedetails)">Add to Dashboard</button>
                        <button  *ngIf="isLoggedin == 'true' && livedetails.teacherID._id == teacherID" class="default-btn mr-1"  (click)="redirectToEdit(livedetails)"><i class="bi bi-pencil-square kin"></i>Edit</button>
                        <button  *ngIf="(isLoggedin =='true' && purchase == true) || (isLoggedin == 'true' && livedetails.teacherID._id == teacherID)"  class="default-btn mr-1" (click)="deleteSmartMaterial()">Delete</button>

                        <!--<a  class="default-btn mr-1" *ngIf="isLoggedin =='true'" (click)="payments()">                            
                            <i class="fas fa-plus icon-arrow before"></i>
                            <span class="label">Buy now</span>
                            <i class="fas fa-plus icon-arrow after"></i>
                        </a>
                        <a  class="default-btn mr-1" *ngIf="isLoggedin =='true' && purchase =='true'" (click)="payments()">                            
                            
                        </a>
                         <a *ngIf="!isItemAddedToCart && isLoggedin =='true'" class="default-btn mr-1" (click)="addcart()">                            
                            <i class="fas fa-plus icon-arrow before"></i>
                            <span class="label">Add Cart</span>
                            <i class="fas fa-plus icon-arrow after"></i>
                        </a>
                       <a *ngIf="isItemAddedToCart && isLoggedin =='true'" class="default-btn mr-1 inactiveLink">                            
                            <span class="label">Added To Cart</span>
                        </a> -->
                        <a class="default-btn " *ngIf="isLoggedin=='false'" (click)="login()">    
                            <i class='bx bx-paper-plane icon-arrow before'></i>
                            <span class="label">Login</span>
                            <i class="bx bx-paper-plane icon-arrow after"></i>
                        </a>                      
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-8">
                <div *ngIf="( isLoggedin=='true') && livedetails.providerByName!='You Tube'" class="ourses-details-image text-center">
                    <img [src]="livedetails.thumbnail !='' ? livedetails.thumbnail : 'assets/img/courses/courses1.jpg'" alt="image">
                </div>
                <div  *ngIf="isLoggedin=='false' || livedetails.providerByName=='You Tube'" class="courses-details-image text-center">
                    <img [src]="livedetails.thumbnail !='' ? livedetails.thumbnail : 'assets/img/courses/courses1.jpg'" alt="image">
                </div>
                <!-- <div class="row" *ngIf="livedetails.providerByName!='You Tube'">
                    <div class="col-lg-10 pt-3">
                        {{viewCount}} Views
                    </div> -->
                     <!-- <div class="col-lg-2 pt-2" >                        
                        <i  *ngIf="isLoggedin=='true'" (click)="likeReview(reviewForm,'add')"  class="fa fa-thumbs-up ml-1 mr-1" style="font-size:20px" [class]="(userlike.length ==0) ? 'text-primary':''"><span  style="font-size:16px" [class]="(userlike.length ==0 && !isteacherRoute)? 'text-primary':''"></span></i>
                        <i  *ngIf="isLoggedin=='false'" class="fa fa-thumbs-up ml-1 mr-1" style="font-size:20px" ><span  style="font-size:16px" ></span></i>
                        <span class="mt-2" data-toggle="modal" data-target="#myModal" style="font-size:20px" [class]="userlike.length !=0 && !isteacherRoute ? 'text-primary':''">
                            <span  style="font-size:16px" [class]="userlike.length !=0 && !isteacherRoute ? 'text-primary':''"> 
                            {{reviewLike.length}}
                            </span>
                        </span>
                    </div>               -->
                <!-- </div>  -->

                <hr>
               
                     <div class="modal fade" id="myModal" role="dialog">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <!-- <div class="modal-header">                      
                                <span class="bold">Likes {{reviewLike.length}} </span>
                            </div> -->
                            <!-- <span class="modal-body bold text-primary" >
                                <commaFor [forData]="reviewLike"></commaFor>
                                <<span *ngFor="let item of reviewLike">{{item.name}},</span> -->
                            <!-- </span>  -->
                            <div class="modal-footer">
                                <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                  
                    </div>
                </div>
                <div class="mt-5">
                    <label>
                        Description
                    </label>
                    <div class="col-lg-12 text-break">
                            {{livedetails.description =='' ? '-' : livedetails.description}}
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="courses-sidebar-information">
                    <ul>
                        <li style="overflow-wrap: break-word;">
                            <span><i class='bx bx-time'></i> Material Name:</span>
                            {{livedetails.materialName =='' ? '-' :livedetails.materialName}}
                        </li>
                       <!--  <li>
                            <span><i class='bx bx-group'></i> Privacy Type:</span>
                            {{livedetails.securitytype =='Group' ? 'School':livedetails.securitytype}}
                        </li> -->
                        <li *ngIf="livedetails.securitytype =='Group'">
                            <span><i class='bx bx-time'></i> School Name:</span>
                            {{livedetails.groupname}}
                        </li>
                        <!-- <li>
                            <span><i class='bx bx-tachometer'></i>Grade:</span>
                            {{livedetails.topicID.gradeID ? livedetails.topicID.gradeID.gradeName: '-'}}

                        </li> -->
                        <li *ngIf="livedetails.boardID">
                            <span><i class='bx bx-tachometer'></i>Board:</span>
                            {{livedetails.boardID ? livedetails.boardID.boardName: ''}}
                        </li>
                        <!-- <li>
                            <span><i class='bx bx-tachometer'></i>Subject:</span>
                            {{livedetails.topicID.subjectID ? livedetails.subjectID.subjectName: ''}}

                        </li> -->
                        <li>
                            <span><i class='bx bx-tachometer'></i>Language:</span>
                            {{livedetails.languageID ? livedetails.languageID.languageName: ''}}
                        </li>
                        <li style="overflow-wrap: break-word;">
                            <span><i class='bx bx-time'></i> Module Name:</span>
                            {{livedetails.moduleID.moduleName =='' ? '-' :livedetails.moduleID.moduleName}}
                        </li>
                        <!-- <li style="overflow-wrap: break-word;">
                            <span><i class='bx bx-time'></i> Chapter Name:</span>
                            {{livedetails.chapterName =='' ? '-' :livedetails.chapterName}}
                        </li> -->
                        
                        <li style="overflow-wrap: break-word;">
                            <span><i class='bx bx-time'></i> Topic Name:</span>
                            <!-- {{livedetails.topicID.topicName}} -->
                            {{livedetails.topicID.topicName =='' ? '-' :livedetails.topicID.topicName}}

                        </li>
                       
                        <!-- <li>
                            <span><i class='bx bx-time'></i> Type(Optional):</span>
                            {{livedetails.type =='' ? '-' :livedetails.type }}
                        </li>
                       
                        
                      
                        <li class="hashtag">
                            <span><i class='bx bxs-badge-check'></i> Tag:</span>                            
                            <a  *ngFor="let tagObj of tagArray"> {{tagObj | removespace}}</a>                           
                        </li> -->
                        <li style="overflow-wrap: break-word;">
                            <span><i class='bx bxs-badge-check'></i> Author:</span>
                            {{livedetails.teacherID ? livedetails.teacherID.firstName + " " + livedetails.teacherID.lastName : '-'}}
                        </li>
                        
                        <li *ngIf="livedetails.boardID">
                            <span><i class='bx bx-tachometer'></i>Level:</span>
                            {{livedetails.level ? livedetails.level: ''}}
                        </li>
                         <li *ngIf="livedetails.boardID">
                            <span><i class='bx bx-tachometer'></i>Price:</span>
                            {{livedetails.price}}
                        </li>
                       <!--  <li>
                            <span><i class='bx bxs-badge-check'></i> Additional.info:</span>
                            {{livedetails.additional =='' ? '-' : livedetails.additional}}
                        </li> -->
                        <li *ngIf="livedetails.isPartner" style="overflow-wrap: break-word;">
                            <span><i class='bx bxs-badge-check'></i> Provider By:</span>
                            {{livedetails.partner ==null? livedetails.providerByName ==''? 'buddi Premium' : livedetails.providerByName : livedetails.partner.partnerName}}
                        </li>
                       
                      
                        
                       
                    </ul>
                </div>
                <div class="row">
                    <!-- <div  *ngIf="isteacherRoute">
                        <button class="default-btn ml-1" (click)="editvideo()" *ngIf="livedetails.userId == userId && (isLoggedin =='true')" > 
                            <a  style="color:white" target=”_blank”>Edit</a>
                        </button>
                    </div>
                    <div  (click)="editvideo()"  *ngIf="!isteacherRoute && livedetails.securitytype =='Private'">
                        <button class="default-btn ml-1"> 
                            <a style="color:white" target=”_blank”>Edit</a>
                        </button>
                    </div> -->

                    <!-- <div class="float-right" (click)="deletevideo()" *ngIf="isteacherRoute">
                        <button class="default-btn ml-1" *ngIf="livedetails.userId == userId && (isLoggedin =='true')" > 
                            <a  style="color:white" target=”_blank”>Delete</a>
                        </button>
                    </div> -->
                    <!-- <div class="float-right" (click)="deletevideo()" *ngIf="!isteacherRoute && livedetails.securitytype =='Private'">
                        <button class="default-btn ml-1"> 
                            <a  style="color:white" target=”_blank”>Delete</a>
                        </button>
                    </div> -->
                    <!-- <div class="ml-3" *ngIf="!isrequest && livedetails.securitytype !='Private' && isLoggedin=='true' && !isteacherRoute">
                        <a class="default-btn" (click)="removeFromList(livedetails)">     -->
                            <!-- <i class='bx bx-paper-plane icon-arrow before'></i> -->
                            <!-- <span class="label">Remove</span> -->
                            <!-- <i class="bx bx-paper-plane icon-arrow after"></i> -->
                        <!-- </a> 
                    </div> -->
                    
                </div>            
            </div>
        </div>
    </div>
</section>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <img src="assets/img/spinner-default.gif" alt="">
</ngx-spinner>