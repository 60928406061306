<div *ngIf="isstudent">
              <app-header-style-one></app-header-style-one>
          </div>
          <div *ngIf="isteacher">
              <app-header-style-one-teacher></app-header-style-one-teacher>
          </div>
          
          <section class="faq-area pt-50">
                  <div class="container">
                      <div class="col-lg-12 col-md-12" style="margin-bottom: 3%;">
                          <div class="about-content">
                              <h2>Our Blogs</h2>
                              <!-- <span class="sub-title" style="color: var(--mainColor);">Management Team</span> -->
                          </div>
                      </div>
                         

                                <div class="card-deck">
                                  <div class="col-lg-4 col-md-6">
                                  <div class="card">
                                    <img src="../../../../assets/img/Blockchain_blog.jpeg" class="card-img-top" alt="Blockchain_blog">
                                    <div class="card-body">
                                      <p class="card-text">Blockchain in Education - An Idea whose time has come</p>
                                      <a href="https://bit.ly/2VmUgaq">View Details</a>
                                      <p>posted date:06.09.2021</p>
                                    </div>
                                  </div>
                                  </div>
                                  
                                  <div class="col-lg-4 col-md-6">
                                  <div class="card">
                                    <img src="../../../../assets/img/Micro Learning_blog.jpeg" class="card-img-top" alt="Micro Learning_blog">
                                    <div class="card-body">
                                      <p class="card-text">Micro Learning - a modular approach with better results</p>
                                     <a href="https://bit.ly/3EiWadG" >View Details</a>
                                      <p>posted date:15.09.2021</p>
                                    </div>
                                  </div>
                              </div>

                              <div class="col-lg-4 col-md-6">
                                <div class="card">
                                  <img src="../../../../assets/img/Customized Learning_blog.jpeg" class="card-img-top" alt="Customized Learning_blog">
                                  <div class="card-body">
                                    <p class="card-text">Customized Learning Path - One size DOES NOT fit all!</p>
                                    <a href="https://bit.ly/39t0oBq" >View Details</a>
                                    <p>posted date:22.09.2021</p>
                                  </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                              <div class="card">
                                <img src="../../../../assets/img/buddi's Knowledge_blog.jpeg" class="card-img-top" alt="buddi's Knowledge_blog">
                                <div class="card-body">
                                  <p class="card-text">buddi's Knowledge Space based Learning Index model</p>
                                  <a href="https://bit.ly/3kZ7zYE" >View Details</a>
                                  <p>posted date:01.10.2021</p>
                                </div>
                              </div>
                          </div>
                          <div class="col-lg-4 col-md-6">
                            <div class="card">
                              <img src="../../../../assets/img/Hybrid Learning_blog.jpeg" class="card-img-top" alt="Hybrid Learning_blog">
                              <div class="card-body">
                                <p class="card-text">Hybrid Learning - why both offline and online will coexist in the future</p>
                                <a href="https://bit.ly/3lnBr0X">View Details</a>
                                <p>posted date:09.10.2021</p>
                              </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                          <div class="card">
                            <img src="../../../../assets/img/Learning to Code _blog.jpeg" class="card-img-top" alt="Learning to Code _blog">
                            <div class="card-body">
                              <p class="card-text">Learning to Code - buddi prepares students for the knowledge world of tomorrow</p>
                              <a href="https://bit.ly/3DFuBu6" >View Details</a>
                              <p>posted date:14.10.2021</p>
                            </div>
                          </div>
                      </div>

                      <div class="col-lg-4 col-md-6">
                        <div class="card">
                          <img src="../../../../assets/img/Tying Academic_blog.jpeg" class="card-img-top" alt="Tying Academic_blog">
                          <div class="card-body">
                            <p class="card-text">Tying Academic Outcomes to Costs, Efforts & Time-Meeting Expectations</p>
                            <a href="https://bit.ly/3ARVw3R">View Details</a>
                             <p>posted date:18.10.2021</p>
                          </div>
                        </div>
                    </div>
                     
                    <div class="col-lg-4 col-md-6">
                      <div class="card">
                        <img src="../../../../assets/img/E-Learning with Buddi_blog.jpeg" class="card-img-top" alt="E-Learning with Buddi_blog">
                        <div class="card-body">
                          <p class="card-text">How Students from any Strata of Society can Benefit from E-Learning with Buddi?</p>
                          <a href="https://bit.ly/3C9spKN" >View Details</a>
                          <p>posted date:24.10.2021</p>
                        </div>
                      </div>
                  </div>

                  <div class="col-lg-4 col-md-6">
                    <div class="card">
                      <img src="../../../../assets/img/Empowering Teachers_blog.jpeg" class="card-img-top" alt="Empowering Teachers_blog">
                      <div class="card-body">
                        <p class="card-text">Empowering Teachers – How Buddi can Help Create Residual Wealth for Teachers</p>
                        <a href="https://bit.ly/2ZUrHmM" >View Details</a>
                        <p>posted date:04.11.2021</p>
                      </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                  <div class="card">
                    <img src="../../../../assets/img/buddi Tokenomics_blog.jpeg" class="card-img-top" alt="buddi Tokenomics_blog">
                    <div class="card-body">
                      <p class="card-text">buddi Tokenomics - how the online marketplace will benefit all stakeholders</p>
                      <a href="https://bit.ly/3CpRoZJ" >View Details</a>
                      <p>posted date:18.11.2021 </p>
                    </div>
                  </div>
              </div>

              <div class="col-lg-4 col-md-6">
                <div class="card">
                  <img src="../../../../assets/img/buddi coin_blog.jpeg" class="card-img-top" alt="buddi coin_blog">
                  <div class="card-body">
                    <p class="card-text">buddi coin can create incredible wealth to all stakeholders (What is the Teacher  Reward Program on the buddi network)</p>
                    <a href="https://bit.ly/310qNWC" >View Details</a>
                    <p>posted date:25.12.2021</p>
                  </div>
                </div>
            </div>


            <div class="col-lg-4 col-md-6">
              <div class="card">
                <img src="../../../../assets/img/Student Reward_blog.jpeg" class="card-img-top" alt="Student Reward_blog">
                <div class="card-body">
                  <p class="card-text">What's the Student Reward Program on the buddi Network?</p>
                  <a href="https://bit.ly/32DFvUc" >View Details</a>
                  <p>posted date:01.01.2022</p>
                </div>
              </div>
          </div>

          <div class="col-lg-4 col-md-6">
            <div class="card">
              <img src="../../../../assets/img/global online education_blog.jpeg" class="card-img-top" alt="global online education_blog">
              <div class="card-body">
                <p class="card-text">buddi coin will remove transactional barriers in value exchange in a global online education marketplace</p>
                <a href="https://bit.ly/3qjbfqV" >View Details</a>
                <p>posted date:08.01.2022</p>
              </div>
            </div>
        </div>


                                </div>
                  </div>
              </section>


   <!-- <div class="row">
<div class="col-md-4">
     

    

    
<div class="row">

 
    
    
      

        
<div class="row">

  <div class="col-md-4">
    <div class="card" style="width: 18rem; height: 25rem;">
    <img class="card-img-top" src="	https://miro.medium.com/max/1400/1*JZLhBXKVrcPqY0XWvOOBVQ.jpeg" alt="Card image cap">
    <div class="card-body">
      
    </div>
  </div>
 </div>

 <div class="col-md-4">
    <div class="card" style="width: 18rem; height: 25rem;">
    <img class="card-img-top" src="		https://miro.medium.com/max/1400/1*us8QmVIe-aK2h-UTvjdUcQ.jpeg" alt="Card image cap">
    <div class="card-body">
     
    </div>
  </div>
 </div>
    <div class="col-md-4">
            <div class="card" style="width: 18rem; height: 25rem;">
            <img class="card-img-top" src="https://miro.medium.com/max/1400/1*2jIIfdtS0ApdbsEkuq3zqQ.jpeg" alt="Card image cap">
            <div class="card-body">
               <p class="card-text">Empowering Teachers – How Buddi can Help Create Residual Wealth for Teachers</p>
              <a href="https://bit.ly/2ZUrHmM" >View Details</a>
              <p>posted date:04.11.2021</p>
            </div>
          </div>
         </div>
    

    
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="card" style="width: 18rem; height: 25rem;">
            <img class="card-img-top" src="	https://miro.medium.com/max/1400/1*MMeSLqAi-KpQjlAJu0JZkg.jpeg" alt="Card image cap">
            <div class="card-body">
              
            </div>
          </div>
         </div>
    
         <div class="col-md-4">
            <div class="card" style="width: 18rem; height: 25rem;">
            <img class="card-img-top" src="https://miro.medium.com/max/1400/1*MP4eStnucvc_3I5DU4k9Lw.jpeg" alt="Card image cap">
            <div class="card-body">
               
            </div>
          </div>
         </div>
    
         <div class="col-md-4">
            <div class="card" style="width: 18rem; height: 25rem;">
            <img class="card-img-top" src="https://miro.medium.com/max/1400/1*Oz7J8uitqF2-K1OVrfGH5A.jpeg" alt="Card image cap">
            <div class="card-body">
             
            </div>
          </div>
         </div>
     
        <div class="col-md-4">
          <div class="card" style="width: 18rem; ">
          <img class="card-img-top" src="https://miro.medium.com/max/1000/1*x2DQsxpuIF7n5AAYabUoJg.jpeg" alt="Card image cap">
          <div class="card-body">
           
          </div>
        </div>
       </div>
      </div> -->
      
                



      <div
                class="col-lg-12 col-md-12 col-sm-12"
                style="margin-bottom: 1cm"
            >
                <div class="pagination-area text-center">
                    <a
                        href="javascript:void(0)"
                        (click)="clickPageNumber(1)"
                        *ngIf="currentPageNumber > 2 && totalPageCount > 1"
                        class="page-numbers"
                        >1</a
                    >
                    <span
                        class="page-numbers"
                        *ngIf="currentPageNumber > 2 && totalPageCount > 1"
                        aria-current="page"
                        >...</span
                    >
                    <a
                        href="javascript:void(0)"
                        (click)="clickPageNumber(currentPageNumber - 1)"
                        *ngIf="currentPageNumber > 1 && totalPageCount > 1"
                        class="page-numbers"
                        >{{ currentPageNumber - 1 }}</a
                    >
                    <span
                        class="page-numbers current"
                        *ngIf="totalPageCount != 0"
                        aria-current="page"
                        >{{ currentPageNumber }}</span
                    >
                    <a
                        href="javascript:void(0)"
                        (click)="clickPageNumber(currentPageNumber + 1)"
                        *ngIf="
                            totalPageCount > 1 &&
                            currentPageNumber + 1 <= totalPageCount
                        "
                        class="page-numbers"
                        >{{ currentPageNumber + 1 }}</a
                    >
                    <a
                        href="javascript:void(0)"
                        (click)="clickPageNumber(currentPageNumber + 2)"
                        *ngIf="
                            totalPageCount > 1 &&
                            currentPageNumber + 2 <= totalPageCount
                        "
                        class="page-numbers"
                        >{{ currentPageNumber + 2 }}</a
                    >
                    <a
                        href="javascript:void(0)"
                        (click)="clickPageNumber(currentPageNumber + 3)"
                        *ngIf="
                            totalPageCount > 1 &&
                            currentPageNumber + 3 <= totalPageCount
                        "
                        class="page-numbers"
                        >{{ currentPageNumber + 3 }}</a
                    >
                    <a
                        href="javascript:void(0)"
                        (click)="clickPageNumber(currentPageNumber + 4)"
                        *ngIf="
                            totalPageCount > 1 &&
                            currentPageNumber + 4 <= totalPageCount
                        "
                        class="page-numbers"
                        >{{ currentPageNumber + 4 }}</a
                    >
                    <a
                        href="javascript:void(0)"
                        (click)="clickPageNumber(currentPageNumber + 1)"
                        *ngIf="
                            totalPageCount > 1 &&
                            currentPageNumber + 1 <= totalPageCount
                        "
                        class="next page-numbers"
                        ><i class="bx bx-chevron-right"></i
                    ></a>
                </div>
            </div>



            