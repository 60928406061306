import { Component, OnInit,Output,EventEmitter } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { Router,ActivatedRoute} from '@angular/router'
@Component({
  selector: 'isManualPopup',
  templateUrl: './manual-automation.component.html',
  styleUrls: ['./manual-automation.component.scss']
})
export class ManualAutomationComponent implements OnInit {

	@Output() isManual = new EventEmitter()
	manualAuto:FormGroup;
  	constructor(private formBuilder: FormBuilder,private router:Router) { }

  	ngOnInit(): void {
  		this.manualAuto = this.formBuilder.group({
      		manualRAutomation: ['manual']
    	});
  	}
  	get f() { return this.manualAuto.controls; }
  	isManualFn(istrue){
  		this.isManual = istrue;
      $('#myModal1').removeClass('show');
    //  $('.modal-backdrop').hide();
      var data={
        isManual:istrue
      }
      window.location.href="/student-onetoone-create/"+(istrue?'manual':'automation')
  	}
}
