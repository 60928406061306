<gbutton class="float-right"  data-toggle="modal" data-target="#myModal1"  [textLabel]="'Create Class'"></gbutton>
<div  class="modal" id="myModal1">
	<div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body"> 
            	
                  <div class="modal-header">
          <!-- <button type="button" class="close" data-dismiss="modal">&times;</button> -->
          <div class="modal-title" style="font-size: 14px" labelStyle>Do you want to Schedule a Class Type? Please Choose below,</div>
        </div>
               <!-- <h6> Do you want to Schedule a Class Type? Please Choose below,</h6> -->
                <div class="mt-4">
                    <gbutton class="manual mt-2" (click)="isManualFn(true)" [textLabel]="'For K-12,NEET,JEE'"></gbutton>
                    <gbutton (click)="isManualFn(false)" [textLabel]="'For Coding'"></gbutton>
                </div>
             	
				<br>  	
            </div>
        </div>
    </div>
</div>