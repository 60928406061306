import { Component, OnInit } from '@angular/core';
import { ProductexpiredPipe } from '../../../custom-pipes/productexpired.pipe';
import { EmptyObjectPipe } from '../../../custom-pipes/empty-object.pipe';
import { ApiService } from '../../../../shared/api.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-teacher-my-learning',
  templateUrl: './teacher-my-learning.component.html',
  styleUrls: ['./teacher-my-learning.component.scss'],
  providers:[ProductexpiredPipe,EmptyObjectPipe]
})
export class TeacherMyLearningComponent implements OnInit {

  public teacherVideouploadlist: any = [];
  public teachersmartmaterialuploadlist: any = [];
  public teachertestuploadlist: any = [];
  public teachertestcorrectionlist: any = [];

  pvideoupload: number = 1;
  videouploadcount: number = 4;

  psmartupload: number = 1;
  smartuploadcount: number = 4;

  ptestupload: number = 1;
  testuploadcount: number = 4;

  ptestcorrect: number = 1;
  testcorrectcount: number = 4;

  constructor( private userApi: ApiService,private router: Router) { }

  ngOnInit(): void {
    const webToken = localStorage.getItem('webtoken');
    this.userApi.VideoUploadTeacher(webToken).subscribe((result) => {
      if (result.status) {
        this.teacherVideouploadlist = result.data;
      }
    }); 

    this.userApi.SmartmaterialUploadTeacher(webToken).subscribe((result) => {
      if (result.status) {
        this.teachersmartmaterialuploadlist = result.data;
      }
    }); 

    this.userApi.testsUploadedbyTeacher(webToken, {}).subscribe((result) => {
      if (result.status) {
        this.teachertestuploadlist = result.data;
      }
    }); 

    this.userApi.testscorrectionbyTeacher(webToken).subscribe((result) => {
      if (result.code == 200) {
        this.teachertestcorrectionlist = result.data;
      }
    }); 
  }

  uploadedvideodetails(data) {
    this.router.navigate(['/uploaded-video-details/' + data._id]);
  }
  // videodetails(data) {
  //   this.router.navigate(['/video-details/' + data._id]);
  // }

  smartmaterialdetails(data) {
    this.router.navigate(['/teacher-smartmaterial-details/' + data._id]);
  }

  testdetails(data) {
    this.router.navigate(['/teacher-test-details/' + data._id]);
  }

  testcorrectiondetails(data) {
    this.router.navigate(['/teacher-correction-page/' + data._id]);
  }

}
