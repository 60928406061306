import { Component, OnInit, Inject, ElementRef, ViewChild, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Options } from 'ng5-slider';
import { ApiService } from '../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from '../../../../environments/environment';
import { MediaService } from '../../../shared/media.service';
import { DatePipe } from '@angular/common';
const datepipe: DatePipe = new DatePipe('en-US')

@Component({
  selector: 'app-institute-create-list',
  templateUrl: './institute-create-list.component.html',
  styleUrls: ['./institute-create-list.component.scss']
})
export class InstituteCreateListComponent implements OnInit {
  public meetingsData: any = [];
  public meetingsDataRecord: any = [];
  public meetingsDataListen: any = [];
  public meetingsDataAll: any = [];
  public allmeeting: any = [];
  public inviteUrl = "";
  public frontwebsite = environment.frontUrl;
  public lessonData: any = [];
  public myId: any = ''
  public isLogin: any = 'false';
  public isCancel: any = 'false';
  // public isStudent : any 
  public videoList: any = {};
  public videoListPrivate: any = [];
  public videoListGroup: any = [];
  public teacherVideouploadlist: any = [];
  public videoYoutubePublic: any = [];
  public publicsessionData: any = [];
  public publicsessionDataRecord: any = [];
  public requestedDataList: any = [];
  public requestedDataAccpet: any = [];
  public requestedDataAccpetRecord: any = [];
  public teacherPayoutDetails: any = [];
  public mydashboardData: any = [];
  public isStudent: boolean = false;
  public isTeacher: boolean = false;
  public currentPageNumber : number = 1;
  public totalVideoCount: number;
  public Price: any = [];
  public totalPageCount: number;
  public currentVideoCount: number;
  public categoryID = null;
  public moduleID = null;
  public courseID = null;
  public topicID = null;
  term: string;
  public webinarData: any = [];
  isDesc: any = 1;
  column: string = 'remainingClass';
  p: number = 1;
  count: number = 4;
  teacherDetails = {};
  marked=false;
  currentSearch = null;
  public walletBalance = 0;
  // public videoList:any=[];

  constructor(private api: MediaService, private SpinnerService: NgxSpinnerService, private activatedRoute: ActivatedRoute, public location: Location, public toastr: ToastrManager, private formBuilder: FormBuilder, private rest: ApiService, private router: Router) { }

  ngOnInit() {
    const webToken = localStorage.getItem("webtoken");
    this.rest.getWalletBalance(webToken).subscribe((response) => {
      console.log("material", response)
      this.walletBalance = response.data
    })
    this.isLogin = localStorage.getItem('isLoggedin');
    console.log(this.isLogin)
    this.isStudent = localStorage.getItem('profileType') ? localStorage.getItem('profileType').toLowerCase() == 'student' : false;
    this.isTeacher = localStorage.getItem('profileType') ? localStorage.getItem('profileType').toLowerCase() == 'teacher' : false;
    this.categoryID = this.activatedRoute.snapshot.params['categoryid'];
    this.moduleID = this.activatedRoute.snapshot.params['moduleid'];
    this.courseID = this.activatedRoute.snapshot.params['courseid'];
    this.topicID = this.activatedRoute.snapshot.params['topicid'];
    console.log(this.categoryID, this.moduleID, this.courseID, this.topicID)
    if (this.isLogin == 'true' && this.isTeacher) {
      this.getLiveclassList();
    }
    else if (this.isLogin == 'true' && this.isStudent) {
      this.getLiveclassWithoutlogin();
    }

    else {
      this.getLiveclassWithoutlogin();
    }
  }

  toggleVisibility(e){
    this.marked= e.target.checked; 
    if (this.isLogin == 'true' && this.isTeacher) {
      this.getLiveclassList(null, this.currentSearch, this.marked);
    }
    else {
      this.getLiveclassWithoutlogin(null, this.currentSearch, this.marked);
    }
  }


  getLiveclassList(filter = null, search = null, institution = null) {

    const webToken = localStorage.getItem('webtoken');

    this.SpinnerService.show();
    var request = {};
    if(this.topicID){
      request = {
        topicID: this.topicID,
        moduleID: this.moduleID, 
        courseID: this.courseID,
        categoryID: this.categoryID
      }
    }
    if(filter && filter != 'all'){
      request['filter'] = filter;
    }
    if(search){
      request['search'] = search;
    }
    if(institution)
      request["fetchInstitution"] = true
    request['limit'] = environment.listing__itemsPerPage;
    request['page'] = this.currentPageNumber;   
    this.rest.getInstitutionsWithoutLogin(webToken, request).subscribe((response) => {
      console.log('response', response)
      
      if (response.status && response.data != null && response.data.length != 0) {
        this.videoList = response.data.data;
        console.log(this.videoList, 'this.webinarData')
        this.Price = response.data.price;
        console.log(this.Price, 'this.Price')
        this.totalVideoCount = response.data.totalCount;
        this.totalPageCount = Math.ceil(this.totalVideoCount / environment.listing__itemsPerPage)
        this.currentVideoCount = ((this.currentPageNumber - 1) * environment.listing__itemsPerPage)
        this.teacherDetails = response.data.teacherDetails;
        for(var roundTable of this.videoList){
          roundTable.startDateTime = datepipe.transform(roundTable.startDateTime, 'MMM d, y, h:mm a')
          console.log('roundTable.startDateTime',roundTable.startDateTime);
          if(!roundTable.thumbnail){
            roundTable.thumbnail = 'assets/img/courses/courses1.jpg'
          }
        }
      } else {
        this.mydashboardData = [];
      }
      this.SpinnerService.hide()
    }, (err) => {
      console.log(err);
    });
  }

  getLiveclassWithoutlogin(filter = null, search = null, institution = null) {
    console.log("getLiveclassWithoutlogin")
    this.isLogin = localStorage.getItem('isLoggedin');
    this.SpinnerService.show();
    var request = {};
    if(this.topicID){
      request = {
        topicID: this.topicID,
        moduleID: this.moduleID, 
        courseID: this.courseID,
        categoryID: this.categoryID
      }
    }
    if(filter && filter != 'all'){
      request['filter'] = filter;
    }
    if(search){
      request['search'] = search;
    }
    if(institution)
      request["fetchInstitution"] = true
    request['limit'] = environment.listing__itemsPerPage;
    request['page'] = this.currentPageNumber;
    const webToken = localStorage.getItem('webtoken');
    console.log("Webtoken", webToken)
    console.log("isLogin", this.isLogin)
    this.rest.getInstitutionsWithoutLogin( this.isLogin ? webToken : null, request).subscribe((response) => {
      console.log('response', response)
      
      if (response.status && response.data != null) {
        this.videoList = response.data.data;
        console.log(this.videoList, 'this.webinarData')
        this.totalVideoCount = response.data.totalCount;
        this.totalPageCount = Math.ceil(this.totalVideoCount / environment.listing__itemsPerPage)
        this.currentVideoCount = ((this.currentPageNumber - 1) * environment.listing__itemsPerPage)
        // console.log(this.videoList)
        for(var roundTable of this.videoList){
          roundTable.startDateTime = datepipe.transform(roundTable.startDateTime, 'MMM d, y, h:mm a')
          if(!roundTable.thumbnail){
            roundTable.thumbnail = 'assets/img/courses/courses1.jpg'
          }
        }
      } else {
        this.mydashboardData = [];
      }
      this.SpinnerService.hide()
    }, (err) => {
      console.log(err);
    });
  }
  SortDataPast(data, isOneonone) {
    var sortDate = data.sort(function (a, b) { return new Date(a.ScheduleDate).getTime() - new Date(b.ScheduleDate).getTime() });
    const now = Date.now();
    if (isOneonone) {
      const futureDates = sortDate.filter(date => {
        return (date.ScheduleDate && new Date(date.ScheduleDate).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)) || date.isrecorded;
      });
      return futureDates;
    } else {
      const futureDates = sortDate.filter(date => {
        return (date.ScheduleDate && new Date(date.ScheduleDate).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0));
      });
      return futureDates;
    }

  }
  // indexFilter(filterData){
  //   this.modelData = filterData;
  // }
  sort(property1, property2) {
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property1;
    let direction = this.isDesc ? 1 : -1;
    if (property2) {
      this.teacherPayoutDetails.sort(function (a, b) {
        if (a[property1][property2] < b[property1][property2]) {
          return -1 * direction;
        }
        else if (a[property1][property2] > b[property1][property2]) {
          return 1 * direction;
        }
        else {
          return 0;
        }
      });
    } else {
      this.teacherPayoutDetails.sort(function (a, b) {
        if (a[property1] < b[property1]) {
          return -1 * direction;
        }
        else if (a[property1] > b[property1]) {
          return 1 * direction;
        }
        else {
          return 0;
        }
      });
    }

  };
  redirect() {
    this.router.navigateByUrl('/');
  }
  redirectshare() {
    // this.router.navigate(['/roundtable-details/' + data._id], { state: data });
  }
 
  videodetails(data) {
    this.router.navigateByUrl('/videoDetails/' + data._id);
  }
  detailsMeeting(data, pagename) {
    data.pagename = pagename;
    var jsonStr = JSON.stringify(data)
    localStorage.setItem('livedetails', jsonStr);
    console.log(data)
    if(this.isTeacher){
      this.router.navigate(['/institute-details/' + data._id], { state: data });
    }
    else{
      this.router.navigate(['/institute-details/' + data._id], { state: data });
    }
  }

  enrollClicked(data, pagename) {
    console.log(data,'data');
    console.log(pagename,'pagename')
    // if(data.price != 0){  
    //   data.pagename = pagename;
    //   this.router.navigate(['/live-class-details/' + data._id], { state: data });
    // }
    if(this.isStudent){
      this.SpinnerService.show();
      var profileName = localStorage.getItem('profileName');
      profileName = profileName.replace(/ /gi, "+");
      var meetingId = profileName+"-"+Math.round(Math.random() * 1000000000)
      const webToken = localStorage.getItem("webtoken");
      var request = {}
      request['meetingID'] = meetingId;
      request['classID'] = data._id;

      this.rest.getWalletBalance(webToken).subscribe((response) => {
        console.log("material", response)
        for(var i=0;i < this.videoList.length;i++){
          this.Price = this.videoList[i].price;
          console.log(this.Price,'price')
        }
        if(response.data < data.price){
          this.SpinnerService.hide();
          this.toastr.errorToastr("Insufficient balance. Please recharge your wallet.")
        }
        else{
          this.SpinnerService.hide();
          this.rest.createLiveClass(request, webToken).subscribe((result) => {
            console.log('liveclassResultDetails',result)
            if(result['status']){
              this.SpinnerService.hide();
              this.toastr.successToastr("You've Successfully Enrolled to the Class.")
              window.location.href='/sidenav/upcoming/live-class';
            }
            else{
              this.SpinnerService.hide();
              this.toastr.errorToastr("Please login to continue this page");
              console.log(result.error)
            }
          })
        }
      })
    }
    
  }
  clickPageNumber(pageNumber) {
    this.currentPageNumber = pageNumber;
    console.log(this.currentPageNumber)
    if (this.isLogin == 'true' && this.isTeacher) {
      this.getLiveclassList();
    }
    else if (this.isLogin == 'true' && this.isStudent) {
      this.getLiveclassWithoutlogin();
    }

    else {
      this.getLiveclassWithoutlogin();
    }
  }

  redirectToCreate(){
    this.router.navigate(['/create-liveclass'])
  }
  redirectToLoginPage(){
    this.router.navigateByUrl('/student/login');
  }
  dropdownChange(event){
    var filter = event.target.options[event.target.selectedIndex].value.substring(event.target.options[event.target.selectedIndex].value.indexOf(":") + 1);
    if (this.isLogin == 'true' && this.isTeacher) {
      this.getLiveclassList(filter);
    }
    else {
      this.getLiveclassWithoutlogin(filter);
    }
  }

  searchClicked(){
    console.log("Clicked")
    let searchBox = (document.getElementById('searchBar') as HTMLInputElement).value
    this.currentSearch = searchBox;
    if(searchBox != ""){ 
      if (this.isLogin == 'true' && this.isTeacher) {
        this.getLiveclassList(null, this.currentSearch);
      }
      else {
        this.getLiveclassWithoutlogin(null, this.currentSearch);
      }
    }
  }
}
