<div *ngIf="!isStudent">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>
<div *ngIf="isStudent">
    <app-header-style-one></app-header-style-one>
</div>
<div class="container" routerLink="/sidenav"><button style="width: 10%;height:45px;margin-left:89%;margin-top:1%;padding: 9px;"><i class="fas fa-long-arrow-alt-left mr-1"></i>Back</button></div>
<div *ngIf="isteacher">
<section class="row my-dashboard-area ">

<!-- <div *ngIf="isstudent"> -->
    <!-- <app-header-style-one></app-header-style-one> -->
<!-- </div> -->
    <!-- <app-header-style-one-teacher></app-header-style-one-teacher> -->

<!-- <div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Error</li>
            </ul>
            <h2>Error 404</h2>
        </div>
    </div>
</div> -->

<!-- <div class="error-404-area">
    <div class="container">
        <div class="notfound">
            <div class="notfound-bg">
                <div></div>
            </div>
            <h5>Advanced Profile will be Updated Shortly. </h5> -->
           

            <!-- <h3>Error 404 : Page Not Found</h3>
            <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
            <a routerLink="/" class="default-btn"><i class='bx bx-home-circle icon-arrow before'></i><span class="label">Back Home</span><i class="bx bx-home-circle icon-arrow after"></i></a> -->
        <!-- </div>
    </div>
</div> -->
    <div class="col-lg-1"></div>
    <div class="col-lg-10">
<div class="tab-content myDashboard-content" *ngIf="userprofdata">
    <!-- <div id="home" [ngClass]="{ 'active': tabName=='home'}"  class="tab-pane"> -->
        <div class="row">
<div class="col-lg-2 col-md-2">
    <h5 style="color: var(--mainColor);">KYC Info.</h5>
</div>
<div class="col-lg-12 col-md-12">
    <div class="tab-content">
        <div class="tabs-item">
            <div class="products-details-tab-content">
                <ul class="additional-information">
                    <li>
                        <span>KYC Status:</span>
                         <span 
                            style=" word-wrap:break-word;width: 70%;color: grey;font-weight: 400;">{{iskyc ? 'Verified' : 'Not Verified'}}
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>      
<div class="col-lg-3 col-md-3" *ngIf="bankDetails.bankName!=undefined && !this.bankCheck.isFullTime">
    <h5 style="color: var(--mainColor);">Bank Details.</h5>
</div>
<div class="col-lg-12 col-md-12" *ngIf="bankDetails.bankName!=undefined && !this.bankCheck.isFullTime">
    <div class="tab-content">
        <div class="tabs-item">
            <div class="products-details-tab-content">
                <ul class="additional-information">
                    <li>
                        <span>Account Name:</span>
                        <span style=" word-wrap:break-word;width: 70%;color: grey;font-weight: 400;">{{bankDetails.accountName}}
                        </span>
                    </li>
                    <li>
                        <span>Account Number:</span>
                        <span style=" word-wrap:break-word;width: 70%;color: grey;font-weight: 400;">{{bankDetails.accountNumber}}
                        </span>
                    </li>
                    <li>
                        <span>Account Type:</span>
                        <span style=" word-wrap:break-word;width: 70%;color: grey;font-weight: 400;">{{bankDetails.accountType}}
                        </span>
                    </li>
                    <li>
                        <span>Bank Name:</span>
                        <span style=" word-wrap:break-word;width: 70%;color: grey;font-weight: 400;">{{bankDetails.bankName}}
                        </span>
                    </li>
                    <li>
                        <span>Bank Branch:</span>
                        <span style=" word-wrap:break-word;width: 70%;color: grey;font-weight: 400;">{{bankDetails.bankBranch}}
                        </span>
                    </li>
                    <li>
                        <span>IFSC Code:</span>
                        <span style=" word-wrap:break-word;width: 70%;color: grey;font-weight: 400;">{{bankDetails.ifscCode}}
                        </span>
                    </li>
                </ul>
            </div>
        </div>
</div>
</div>
</div>
</div>
<!-- </div> -->
</div>
</section>
</div>
