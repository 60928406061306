import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of, observable, throwError, from } from 'rxjs';
import { map, catchError, tap, retry, timeout } from 'rxjs/operators';
import { ToastrManager } from 'ng6-toastr-notifications';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  constructor(private http: HttpClient,public toastr: ToastrManager,
    private router: Router) { }
    public BASE_URL = environment.BASE_URL;
    private token =  localStorage.getItem('webtoken');
    private httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
         'Authorization': "Bearer "+this.token
      })
    };
    getVideoList(): Observable<any> {
      return this.http.get<any>(this.BASE_URL + 'videos/playlistFindall', this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
    }
    getAllVideoCollection(): Observable<any> {
      return this.http.get<any>(this.BASE_URL + 'videos/allVideoCollection', this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
    }
    delVideoAlbum(id): Observable<any> {
      let passdata = {userVideolistId:id}
      return this.http.post<any>(this.BASE_URL + 'videos/delVideolist',passdata, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
    }

    getVideoDetails(id): Observable<any> {
      return this.http.get<any>(this.BASE_URL + 'videos/playlistFindall/'+id, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
    }
    
    uploadebook(data) {
      const headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token})};
      let post_url = this.BASE_URL + 'ebook/uploadebook';
      console.log(data);
      return this.http.post<any>(post_url, data, headers_object).pipe(
        catchError(this.handleError)
      );
     }
    uploadSolutionPaper(data) {
      const headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token})};
      let post_url = this.BASE_URL + 'solution/uploadSolution';
      console.log(data);
      return this.http.post<any>(post_url, data, headers_object).pipe(
        catchError(this.handleError)
      );
    }
    uploadQuestionPaper(data) {
      const headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token})};
      let post_url = this.BASE_URL + 'question/uploadeQuestion';
      console.log(data);
      return this.http.post<any>(post_url, data, headers_object).pipe(
        catchError(this.handleError)
      );
    }
    addVideoList(data) {
      const headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token})};
      let post_url = this.BASE_URL + 'videos/uploadvideos';
      console.log(data);
      return this.http.post<any>(post_url, data, headers_object).pipe(
        catchError(this.handleError)
      );
     }

     uploadVideo(data) {
      const headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token})};
      let post_url = this.BASE_URL + 'teacherVideos/uploadVideo';
      console.log(data);
      return this.http.post<any>(post_url, data, headers_object).pipe(
        catchError(this.handleError)
      );
     }

     uploadSmartMaterial(data) {
      const headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token, 
        })};
      let post_url = this.BASE_URL + 'teacherSmartMaterial/uploadSmartMaterial';
      console.log(data);
      return this.http.post<any>(post_url, data, headers_object).pipe(
        catchError(this.handleError)
      );
     }

     addLiveClass(data) {
      const headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token})};
      let post_url = this.BASE_URL + 'teacherPersonalClass/addPersonalClass';
      console.log(data);
      return this.http.post<any>(post_url, data, headers_object).pipe(
        catchError(this.handleError)
      );
     }
     addStudyhallClass(data) {
      const headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token})};
      let post_url = this.BASE_URL + 'teacherPersonalClass/addStudyClass';
      console.log(data);
      return this.http.post<any>(post_url, data, headers_object).pipe(
        catchError(this.handleError)
      );
     }
     addRoundTableClass(data) {
      const headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token})};
      let post_url = this.BASE_URL + 'teacherPersonalClass/addRoundTable';
      console.log(data);
      return this.http.post<any>(post_url, data, headers_object).pipe(
        catchError(this.handleError)
      );
     }
     
     teacherUploadTest(data,token) {
      const headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token})};
      let post_url = this.BASE_URL + 'teacherTests/addTest';
      return this.http.post<any>(post_url, data, headers_object).pipe(
        catchError(this.handleError)
      );
     }

     EdituploadedVideo(data) {
      const headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token})};
      let post_url = this.BASE_URL + 'teacherVideos/editVideo';
      console.log(data);
      return this.http.post<any>(post_url, data, headers_object).pipe(
        catchError(this.handleError)
      );
     }

     EditSmartMaterial(data) {
      const headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token})};
      let post_url = this.BASE_URL + 'teacherSmartMaterial/editSmartMaterial';
      console.log(data);
      return this.http.post<any>(post_url, data, headers_object).pipe(
        catchError(this.handleError)
      );
     }

     EditTeacheruploadedtest(data) {
      const headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token})};
      let post_url = this.BASE_URL + 'teacherTests/editTest';
      console.log(data);
      return this.http.post<any>(post_url, data, headers_object).pipe(
        catchError(this.handleError)
      );
     }

     uploadCourseTemplate(data,webtoken) {
      const headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + webtoken})};
      let post_url = this.BASE_URL + 'classCourses/uploadCourseTemplate';
      return this.http.post<any>(post_url, data, headers_object).pipe(
        catchError(this.handleError)
      );
     }

    addVideoColletion(data) {
      const headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token})};
      let post_url = this.BASE_URL + 'videos/videosalbum';
      console.log(data);
      return this.http.post<any>(post_url, data, headers_object).pipe(
        catchError(this.handleError)
      );
     }
    updateVideoColletion(data) {
      const headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token})};
      let post_url = this.BASE_URL + 'videos/updatealbum';
      return this.http.post<any>(post_url, data, headers_object).pipe(
        catchError(this.handleError)
      );
     }

    addSongColletion(data) {
      const headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token})};
      let post_url = this.BASE_URL + 'songs/songsalbum';
      console.log(data);
      return this.http.post<any>(post_url, data, headers_object).pipe(
        catchError(this.handleError)
      );
     }
    updateSongColletion(data) {
      const headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token})};
      let post_url = this.BASE_URL + 'songs/updatesongsalbum';
      console.log(data);
      return this.http.post<any>(post_url, data, headers_object).pipe(
        catchError(this.handleError)
      );
     }
     moveMultipleItem(data){
      const headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token})};
      let post_url = this.BASE_URL + 'userPlaylist/addSongToPlaylist';
      console.log(data);
      return this.http.post<any>(post_url, data, headers_object).pipe(
        catchError(this.handleError)
      );
     }
     delMultipleItem(data){
      const headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token})};
      let post_url = this.BASE_URL + 'songs/delSong';
      console.log(data);
      return this.http.post<any>(post_url, data, headers_object).pipe(
        catchError(this.handleError)
      );
     }
     delMultiplePhoto(data){
      const headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token})};
      let post_url = this.BASE_URL + 'user/delByMedia';
      console.log(data);
      return this.http.post<any>(post_url, data, headers_object).pipe(
        catchError(this.handleError)
      );
     }
     moveMultipleItemPhoto(data){
      const headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token})};
      let post_url = this.BASE_URL + 'user/movetoalbum';
      console.log(data);
      return this.http.post<any>(post_url, data, headers_object).pipe(
        catchError(this.handleError)
      );
     }

     delMultipleVideo(data){
      const headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token})};
      let post_url = this.BASE_URL + 'videos/delVideo';
      console.log(data);
      return this.http.post<any>(post_url, data, headers_object).pipe(
        catchError(this.handleError)
      );
     }
     fetchrecordvideo(req):Observable<any> {
      
      return this.http.post<any>(this.BASE_URL + 'user/getrecordedvideo',req, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
    }
     moveMultipleVideo(data){
      const headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token})};
      let post_url = this.BASE_URL + 'videos/addVideoToVideolist';
      console.log(data);
      return this.http.post<any>(post_url, data, headers_object).pipe(
        catchError(this.handleError)
      );
     }


    addPhotoColletion(data) {
      const headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token})};
      let post_url = this.BASE_URL + 'user/photosalbum';
      console.log(data);
      return this.http.post<any>(post_url, data, headers_object).pipe(
        catchError(this.handleError)
      );
     }
    updatePhotoColletion(data) {
      const headers_object = {headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token})};
      let post_url = this.BASE_URL + 'user/updatephotosalbum';
      console.log(data);
      return this.http.post<any>(post_url, data, headers_object).pipe(
        catchError(this.handleError)
      );
     }

     delPhotoAlbum(id): Observable<any> {
       let passdata = {album_id:id}
       return this.http.post<any>(this.BASE_URL + 'user/deleteAlbum',passdata, this.httpOptions).pipe(
       retry(1),
       catchError(this.handleError)
     );
     }
     deleteEbook(req): Observable<any> {
      return this.http.post<any>(this.BASE_URL + 'ebook/deleteEbook',req, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
    }

    deleteSolution(req): Observable<any> {
      return this.http.post<any>(this.BASE_URL + 'solution/deleteSolution',req, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
    }
    deleteQuestion(req): Observable<any> {
      return this.http.post<any>(this.BASE_URL + 'question/deleteQuestion',req, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
    }
     getSongList(): Observable<any> {
       return this.http.get<any>(this.BASE_URL + 'userPlaylist/playlistFindall', this.httpOptions).pipe(
       retry(1),
       catchError(this.handleError)
     );
     }
     getAllPlaylistSong(): Observable<any> {
      return this.http.get<any>(this.BASE_URL + 'userPlaylist/allSongCollection', this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
    }
     delSongList(id): Observable<any> {
       let passdata = {userPlaylistId:id}
       return this.http.post<any>(this.BASE_URL + 'userPlaylist/delPlaylist',passdata, this.httpOptions).pipe(
       retry(1),
       catchError(this.handleError)
     );
     }

     getSongDetails(id): Observable<any> {
       return this.http.get<any>(this.BASE_URL + 'userPlaylist/playlistFindall/'+id, this.httpOptions).pipe(
       retry(1),
       catchError(this.handleError)
     );
     }
     
     addSongList(data) {
       const headers_object = {headers: new HttpHeaders({
         'Authorization': 'Bearer ' + this.token})};
       let post_url = this.BASE_URL + 'songs/uploadsongs';
       console.log(data);
       return this.http.post<any>(post_url, data, headers_object).pipe(
         catchError(this.handleError)
       );
      }
        delBySong(data) {
       const headers_object = {headers: new HttpHeaders({
         'Authorization': 'Bearer ' + this.token})};
       let post_url = this.BASE_URL + 'songs/delBySong';
       console.log(data);
       return this.http.post<any>(post_url, data, headers_object).pipe(
         catchError(this.handleError)
       );
      }
      editSongList(data) {
        const headers_object = {headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.token})};
        let post_url = this.BASE_URL + 'songs/editSong';
        console.log(data);
        return this.http.post<any>(post_url, data, headers_object).pipe(
          catchError(this.handleError)
        );
       }
       editVideoList(data) {
        const headers_object = {headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.token})};
        let post_url = this.BASE_URL + 'videos/editVideo';
        console.log(data);
        return this.http.post<any>(post_url, data, headers_object).pipe(
          catchError(this.handleError)
        );
       }
        delByVideo(data) {
        const headers_object = {headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.token})};
        let post_url = this.BASE_URL + 'videos/delByVideo';
        console.log(data);
        return this.http.post<any>(post_url, data, headers_object).pipe(
          catchError(this.handleError)
        );
       }

       deleteuploadedVideo(data) {
        const headers_object = {headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.token})};
        let post_url = this.BASE_URL + 'teacherVideos/deleteVideo';
        console.log(data);
        return this.http.post<any>(post_url, data, headers_object).pipe(
          catchError(this.handleError)
        );
       }

       deleteuploadedTest(data) {
        const headers_object = {headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.token})};
        let post_url = this.BASE_URL + 'teacherTests/deleteTest';
        return this.http.post<any>(post_url, data, headers_object).pipe(
          catchError(this.handleError)
        );
       }

       deleteuploadedsmartmaterial(data) {
        const headers_object = {headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.token})};
        let post_url = this.BASE_URL + 'teacherSmartMaterial/deleteSmartMaterial';
        console.log(data);
        return this.http.post<any>(post_url, data, headers_object).pipe(
          catchError(this.handleError)
        );
       }
       editEbook(data) {
        const headers_object = {headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.token})};
        let post_url = this.BASE_URL + 'ebook/editEbook';
        console.log(data);
        return this.http.post<any>(post_url, data, headers_object).pipe(
          catchError(this.handleError)
        );
       }
      editSolutionPaper(data) {
        const headers_object = {headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.token})};
        let post_url = this.BASE_URL + 'solution/editSolution';
        console.log(data);
        return this.http.post<any>(post_url, data, headers_object).pipe(
          catchError(this.handleError)
        );
       }
      editQuestionPaper(data) {
        const headers_object = {headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.token})};
        let post_url = this.BASE_URL + 'question/editQuestion';
        console.log(data);
        return this.http.post<any>(post_url, data, headers_object).pipe(
          catchError(this.handleError)
        );
       }
       editPhotoList(data) {
        const headers_object = {headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.token})};
        let post_url = this.BASE_URL + 'user/editPhoto';
        console.log(data);
        return this.http.post<any>(post_url, data, headers_object).pipe(
          catchError(this.handleError)
        );
       }
      getPhotoList(): Observable<any> {
        return this.http.get<any>(this.BASE_URL + 'user/getAlbum', this.httpOptions).pipe(
        retry(1),
        catchError(this.handleError)
      );
      }

      getPhotoDetails(id): Observable<any> {
        return this.http.get<any>(this.BASE_URL + 'user/getAlbum/'+id, this.httpOptions).pipe(
        retry(1),
        catchError(this.handleError)
      );
      }

      addPhotoList(data) {
        const headers_object = {headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.token})};
        let post_url = this.BASE_URL + 'user/uploadphoto';
        console.log(data);
        return this.http.post<any>(post_url, data, headers_object).pipe(
          catchError(this.handleError)
        );
       }
      getMultipleSongList(): Observable<any> {
        return this.http.get<any>(this.BASE_URL + 'songs/allSongs', this.httpOptions).pipe(
        retry(1),
        catchError(this.handleError)
      );
      }
      getMultiplePhotoList(): Observable<any> {
        return this.http.get<any>(this.BASE_URL + 'user/allMedia/photo', this.httpOptions).pipe(
        retry(1),
        catchError(this.handleError)
      );
      }
      getMultiplePhotoListAll(): Observable<any> {
        return this.http.get<any>(this.BASE_URL + 'user/allMedialist/photo', this.httpOptions).pipe(
        retry(1),
        catchError(this.handleError)
      );
      }
      group_photo(data) {
        const headers_object = {headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.token})};
        let post_url = this.BASE_URL + 'user/getHomeGroupData/photos';
        console.log(data);
        var req={
          type:'photos',
          'groupname':data
        }
        return this.http.post<any>(this.BASE_URL + 'user/getHomeGroupData',req, this.httpOptions).pipe(
        // return this.http.post<any>(post_url, data, headers_object).pipe(
          catchError(this.handleError)
        );
       }
       group_video(data) {
        const headers_object = {headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.token})};
        // let post_url = this.BASE_URL + 'user/getHomeGroupData/videos';
        console.log(data);
        var req={
          type:'videos',
          'groupname':data
        }
        return this.http.post<any>(this.BASE_URL + 'user/getHomeGroupData',req, this.httpOptions).pipe(
        // return this.http.post<any>(post_url, data, headers_object).pipe(
          catchError(this.handleError)
        );
       }

       group_ebook(data) {
        const headers_object = {headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.token})};
        console.log(data);
        var req={
          type:'ebook',
          'groupname':data
        }
        return this.http.post<any>(this.BASE_URL + 'user/getHomeGroupData',req, this.httpOptions).pipe(
          catchError(this.handleError)
        );
       }
       
       getEbookByID(req,webtoken){
        // const headers_object = {headers: new HttpHeaders({
        //   'Authorization': 'Bearer ' + webtoken})};        
        return this.http.post<any>(this.BASE_URL + 'ebook/getEbookByID',req, this.httpOptions).pipe(
        catchError(this.handleError)
        );
       }
      getSolution(req,webtoken){     
        return this.http.post<any>(this.BASE_URL + 'solution/getSolutionByID',req, this.httpOptions).pipe(
        catchError(this.handleError)
        );
      }
      getQuestion(req,webtoken){       
        return this.http.post<any>(this.BASE_URL + 'question/getQuestionByID',req, this.httpOptions).pipe(
        catchError(this.handleError)
        );
      }
       getAudioByAllPrivacyType(req,webtoken){
        const headers_object = {headers: new HttpHeaders({
          'Authorization': 'Bearer ' + webtoken})};        
        return this.http.post<any>(this.BASE_URL + 'songs/getSongsByAllPrivacyType',req, this.httpOptions).pipe(
        catchError(this.handleError)
        );
       }
       getVideoByAllPrivacyType(req,webtoken){
        const headers_object = {headers: new HttpHeaders({
          'Authorization': 'Bearer ' + webtoken})};        
        return this.http.post<any>(this.BASE_URL + 'videos/getVideoByAllPrivacyType',req, this.httpOptions).pipe(
        catchError(this.handleError)
        );
       }

       getAudiosByID(req,webtoken){
        // const headers_object = {headers: new HttpHeaders({
        //   'Authorization': 'Bearer ' + webtoken})};        
        return this.http.post<any>(this.BASE_URL + 'songs/getSongsByID',req, this.httpOptions).pipe(
        catchError(this.handleError)
        );
       }
       getVideosByID(req,webtoken){
        // const headers_object = {headers: new HttpHeaders({
        //   'Authorization': 'Bearer ' + webtoken})};        
        return this.http.post<any>(this.BASE_URL + 'videos/getVideosByID',req, this.httpOptions).pipe(
        catchError(this.handleError)
        );
       }
       getUploadedVideosByID(req,webtoken){
        const headers_object = {headers: new HttpHeaders({
          'Authorization': 'Bearer ' + webtoken})};        
        return this.http.post<any>(this.BASE_URL + 'teacherVideos/getVideoDetails',req, this.httpOptions).pipe(
        catchError(this.handleError)
        );
       }

       getteacheruploadedtestbyid(req,webtoken){
        const headers_object = {headers: new HttpHeaders({
          'Authorization': 'Bearer ' + webtoken})};        
        return this.http.post<any>(this.BASE_URL + 'teacherTests/getTestDetails',req, this.httpOptions).pipe(
        catchError(this.handleError)
        );
       }

       getstudentsubmissiontest(req,webtoken){
        const headers_object = {headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Bearer ' + webtoken})};        
        return this.http.post<any>(this.BASE_URL + 'teacherTests/getSubmissionDetails',req, headers_object).pipe(
        catchError(this.handleError)
        );
       }

       getstudentcorrectedtests(req,webtoken){ 
        const headers_object = {headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Bearer ' + webtoken})};       
        return this.http.post<any>(this.BASE_URL + 'teacherTests/getSubmissionDetailsStudent', req,this.httpOptions).pipe(
        catchError(this.handleError)
        );
       }

       getStudentTestsForTestID(req,webtoken){ 
        const headers_object = {headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Bearer ' + webtoken})};       
        return this.http.post<any>(this.BASE_URL + 'teacherTests/getStudentTests', req,this.httpOptions).pipe(
        catchError(this.handleError)
        );
       }

       teacherfinishcorrection(req,webtoken){
        const headers_object = {headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Bearer ' + webtoken})};        
        return this.http.post<any>(this.BASE_URL + 'teacherTests/finishTestCorrection',req, headers_object).pipe(
        catchError(this.handleError)
        );
       }

       getUploadedSmartmaterialsByID(req,webtoken){
        const headers_object = {headers: new HttpHeaders({
          'Authorization': 'Bearer ' + webtoken})};        
        return this.http.post<any>(this.BASE_URL + 'teacherSmartMaterial/getSmartMaterialDetails',req, this.httpOptions).pipe(
        catchError(this.handleError)
        );
       }       

          getSmartMaterialByID(req,webtoken){
        const headers_object = {headers: new HttpHeaders({
          'Authorization': 'Bearer ' + webtoken})};        
        return this.http.post<any>(this.BASE_URL + 'teacherSmartMaterial/getSmartMaterialDetails',req, this.httpOptions).pipe(
        catchError(this.handleError)
        );
       }

       getEbookByAllPrivacyType(req,webtoken){
        const headers_object = {headers: new HttpHeaders({
          'Authorization': 'Bearer ' + webtoken})};        
        return this.http.post<any>(this.BASE_URL + 'ebook/getEbookByAllPrivacyType',req, this.httpOptions).pipe(
        catchError(this.handleError)
        );
       }
       group_song(data) {
        const headers_object = {headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.token})};
        // let post_url = this.BASE_URL + 'user/getHomeGroupData/videos';
        console.log(data);
        var req={
          type:'songs',
          'groupname':data
        }
        return this.http.post<any>(this.BASE_URL + 'user/getHomeGroupData',req, this.httpOptions).pipe(
        // return this.http.post<any>(post_url, data, headers_object).pipe(
          catchError(this.handleError)
        );
       }


      // group_photo(): Observable<any> {
      //   const headers_object = {headers: new HttpHeaders({
      //     'Content-Type':  'application/json',
      //     })};
      //    return this.http.get<any>(this.BASE_URL + 'user/getHomeGroupData/photos', headers_object).pipe(
      //    retry(1),
      //   catchError(this.handleError)
      // );
      // }
      
      home_song(): Observable<any> {
        const headers_object = {headers: new HttpHeaders({
          'Content-Type':  'application/json',
          })};
         return this.http.get<any>(this.BASE_URL + 'user/getHomeData/songs', headers_object).pipe(
         retry(1),
        catchError(this.handleError)
      );
      }
      home_ebook(): Observable<any> {
        const headers_object = {headers: new HttpHeaders({
          'Content-Type':  'application/json',
          })};
         return this.http.get<any>(this.BASE_URL + 'user/getHomeData/ebook', headers_object).pipe(
         retry(1),
        catchError(this.handleError)
      );
      }
      
      home_video(): Observable<any> {
        const headers_object = {headers: new HttpHeaders({
          'Content-Type':  'application/json',
          })};
         return this.http.get<any>(this.BASE_URL + 'user/getHomeData/videos', headers_object).pipe(
         retry(1),
        catchError(this.handleError)
      );
      }
      home_photo(): Observable<any> {
        const headers_object = {headers: new HttpHeaders({
          'Content-Type':  'application/json',
          })};
         return this.http.get<any>(this.BASE_URL + 'user/getHomeData/photos', headers_object).pipe(
         retry(1),
        catchError(this.handleError)
      );
      }


      getMultipleVideoListAll(): Observable<any> {
        return this.http.get<any>(this.BASE_URL + 'user/allMedialist/video', this.httpOptions).pipe(
        retry(1),
        catchError(this.handleError)
      );
      }
      getMultipleVideoList(): Observable<any> {
        return this.http.get<any>(this.BASE_URL + 'videos/allVideos', this.httpOptions).pipe(
        retry(1),
        catchError(this.handleError)
      );
      }
      
      allebook(): Observable<any> {
        return this.http.get<any>(this.BASE_URL + 'ebook/allebook', this.httpOptions).pipe(
        retry(1),
        catchError(this.handleError)
      );
      }
    handleError(error) {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
        // client-side error
        errorMessage = `Error: ${error.error.message}`;
      } else {
        // server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      //  window.alert(errorMessage);
      if (errorMessage) {
        this.router.navigate(['/login']);
      }

      return throwError(errorMessage);
    }



}
