
<div *ngIf="!isTeacher">
    <app-header-style-one></app-header-style-one>
</div>
<div *ngIf="isTeacher">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>
<div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <!-- <ul>
                <li><a >Home</a></li>
                <li>Dashboard</li>
                <li>Edit</li>
            </ul> -->
            <h2>Tutor profile</h2>
            <button class="col-lg-1"(click)="backto()"><i class="fas fa-long-arrow-alt-left mr-1"></i>Back</button>
        </div>
    </div>
</div>
<form [formGroup]="registerForm" (ngSubmit)="onSubmit(registerForm)" class="my-dashboard-area ptb-100">
    <div class="container">
        <div class="myDashboard-profile">
            <div class="row align-items-center">

                <div class="col-lg-12">
                    <div class="myDashboard-navigation">
                        <!-- <ul class="nav nav-tabs">
                            <li class="active"><a data-toggle="tab" href="#home" class="active">Contact Info</a></li>
                            
                        </ul> -->
                        <h5 >Profile Info</h5>

                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="row borderpro">
                        <div class="col-sm-6">
                            <label >Profile Image</label>
                            <div class="form-group">
                                <div class="input-group">
                                    <div class="custom-file">
                                        <input type="hidden" formControlName="fileupload" name="" value="">
                                        <input type="file" class="custom-file-input" (change)="fileChange($event)"
                                            id="inputGroupFile01" aria-describedby="inputGroupFileAddon01"
                                            accept="image/*"
                                            [ngClass]="{ 'is-invalid': submitted && f.fileupload.errors}">
                                        <label class="custom-file-label"
                                            for="inputGroupFile01">{{selectedFileName}}</label>
                                    </div>
                                    <div *ngIf="submitted && f.fileupload.errors" class="invalid-feedback">
                                        <div *ngIf="f.fileupload.errors.required">Profile Image is
                                            required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label >First Name</label>
                                <input type="text" formControlName="firstname" class="form-control" maxlength="25"
                                class="form-control"  [ngClass]="{'is-invalid': !f.firstname.pristine && f.firstname.errors}" />
                                  
                                <div *ngIf="f.firstname.touched && f.firstname.invalid" class="invalid-feedback">
                                    <div *ngIf="f.firstname.errors.required">First Name is required
                                    </div>
                                    <div *ngIf="f.firstname.errors.maxlength">only 25 characters are allowed</div>
                                    <div *ngIf="f.firstname.errors.cannotContainSpace">First Name cannot contain spaces.</div>
                                </div>
                             
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label >Last Name</label>
                            <div class="form-group">
                                
                                <input placeholder="Last Name" type="text" formControlName="lastname" maxlength="25"
                                    class="form-control"  [ngClass]="{'is-invalid': !f.lastname.pristine && f.lastname.errors}" />
                                   
                                    <div *ngIf="f.lastname.touched && f.lastname.invalid" class="invalid-feedback">
                                        <div *ngIf="f.lastname.errors?.required" >Last Name is Required</div>
                                        <div *ngIf="f.lastname.errors?.pattern"> Last name can only have letters</div>     
                                        <div  *ngIf="f.lastname.errors.cannotContainSpace">Last Name cannot contain spaces.</div>                                  
                                      </div>
                                </div>
                        </div>

                        <div class="col-sm-6">
                            <label >Gender</label>
                            <div class="form-group">
                                <select class="browser-default custom-select" formControlName="gender">
                                    <option value="" selected>Select Gender</option>
                                    <option>Male</option>
                                    <option>Female</option>
                                    <option>Rather not say</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <label >School Name</label>
                            <div class="form-group">
                                <input type="text" formControlName="schoolName" placeholder="School Name"
                                    class="form-control" />
                            </div>
                        </div>

                       
                    </div>
                </div>
            </div>
        </div>

        <div class="myDashboard-navigation">
            <!-- <ul class="nav nav-tabs">
                <li class="active"><a data-toggle="tab" href="#home" class="active">Contact Info</a></li>
                
            </ul> -->
            <h5>Contact Info</h5>
        </div>
        <div class="tab-content myDashboard-content">
            <div id="home" class="tab-pane active">
                <div class="row borderpro">
                    <div class="col-sm-6">
                        <label >Email-Id</label>
                        <div class="form-group">
                            <input type="text" formControlName="email" class="form-control" />
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label >Mobile Number</label>
                        <div class="form-group">
                            <input type="text" formControlName="Mobilenumber" class="form-control" />
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label >Address 1</label>
                        <div class="form-group">
                            <input type="text" formControlName="address1"  placeholder="Address 1" class="form-control"  maxlength="50"/>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label >Address 2</label>
                        <div class="form-group">
                            <input type="text" formControlName="address2" placeholder="Address 2" class="form-control"  maxlength="50"/>
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <label >City/District/Town</label>
                        <div class="form-group">
                            <input type="text" placeholder="City/District/Town" formControlName="city"
                                class="form-control"  maxlength="40"/>
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <label >State</label>
                        <div class="form-group">
                            <input type="text" placeholder="State" formControlName="state" class="form-control"  maxlength="40"/>
                        </div>
                    </div> 

                    <div class="col-sm-6">
                        <label >Country</label>
                        <div class="form-group">
                        <select formControlName="countries" class="browser-default custom-select">
                        <option value="" selected>Select Country</option>
                        <option *ngFor="let countries of listcountries">{{countries.name}}</option>
                        
                    </select>
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <label >Area Code</label>
                        <div class="form-group">
                            <input type="number" placeholder="Area" formControlName="area" maxlength="10"
                                class="form-control" />
                        </div>
                    </div>





                    <!-- <div class="col-lg-12">
                        <div class="myDashboard-navigation">
                            
                            <h5 style="color: #ff1949;">Guardian Info</h5>
                        </div>
                    </div> -->

                    <!-- <div class="col-sm-6">
                        <div class="form-group">

                            <input type="text" placeholder="Guardian Name" formControlName="guardianName"
                                class="form-control" />
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">

                            <input type="text" placeholder="Guardian Phone Number" formControlName="guardianPhone"
                                class="form-control" />
                        </div>
                    </div> -->
                    <br>
                    <div class="col-lg-12">
                        <div class="myDashboard-navigation">
                            <!-- <ul class="nav nav-tabs">
                                <li class="active"><a data-toggle="tab" href="#home" class="active">Education Info</a></li>
                            </ul> -->
                            <h5 labelStyle>Education Info</h5>
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <label >Categories</label>
                        <div class="form-group">
                            <ng-multiselect-dropdown  [multiple]="true"  [placeholder]="'Select category'" [settings]="dropdownSettings" [data]="listcategories"  [(ngModel)]="selectedcategorylist" [ngModelOptions]="{standalone: true}" [settings]="dropdownSettings" (onSelect)="onItemcategorySelect($event)" (onSelectAll)="onItemcategoryallSelect($event)" (onDeSelectAll)="onItemcategoryallDeSelect($event)"(onDeSelect)="onItemcategoryDeSelect($event)" >
                                <!-- <option disabled>Select Categories</option>
                                <option *ngFor="let categories of listcategories">{{categories.categoryName}}</option> -->

                            </ng-multiselect-dropdown>
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <label >Grades</label>
                        <div class="form-group">
                            <ng-multiselect-dropdown  [placeholder]="'Select Grades'"  [settings]="dropdowngradeSettings" [data]="listgrade"  [(ngModel)]="selectedgradelist" [ngModelOptions]="{standalone: true}" (onSelect)="onItemgradeSelect($event)" (onSelectAll)="onItemgradeallSelect($event)" (onDeSelectAll)="onItemgradeallDeSelect($event)" (onDeSelect)="onItemgradeDeSelect($event)" >
                                <!-- <option disabled>Select Categories</option>
                                <option *ngFor="let categories of listcategories">{{categories.categoryName}}</option> -->

                            </ng-multiselect-dropdown>
                            <!-- <select formControlName="grades" class="browser-default custom-select">
                                <option disabled>Select Grade</option>
                                <option *ngFor="let grade of listgrade">{{grade.gradeName}}</option>

                            </select> -->
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <label>Boards</label>
                        <div class="form-group">
                            <ng-multiselect-dropdown  [placeholder]="'Select Board'" [settings]="dropdownboardSettings"  [data]="listboard"  [(ngModel)]="selectedboardlist" [ngModelOptions]="{standalone: true}" (onSelect)="onItemboardSelect($event)" (onSelectAll)="onItemboardallSelect($event)" (onDeSelectAll)="onItemboardallDeSelect($event)" (onDeSelect)="onItemboardDeSelect($event)" >
                                <!-- <option disabled>Select Categories</option>
                                <option *ngFor="let categories of listcategories">{{categories.categoryName}}</option> -->

                            </ng-multiselect-dropdown>
                            <!-- <select formControlName="boards" class="browser-default custom-select">
                                <option disabled>Select Board</option>
                                <option *ngFor="let board of listboard">{{board.boardName}}</option>
                            </select> -->
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label >Subjects</label>
                        <div class="form-group">
                            <ng-multiselect-dropdown  [placeholder]="'Select Subject'" [data]="listsubjects"  [(ngModel)]="selectedsubjectlist" [ngModelOptions]="{standalone: true}" [settings]="dropdownsubjectSettings" (onSelect)="onItemsubjectSelect($event)" (onSelectAll)="onItemsubjectallSelect($event)" (onDeSelectAll)="onItemsubjectallDeSelect($event)" (onDeSelect)="onItemsubjectDeSelect($event)" >
                                <!-- <option disabled>Select Categories</option>
                                <option *ngFor="let categories of listcategories">{{categories.categoryName}}</option> -->

                            </ng-multiselect-dropdown>
                            <!-- <select formControlName="subjects" class="browser-default custom-select">
                                <option disabled>Select Subject</option>
                                <option *ngFor="let Subject of listsubjects">{{Subject.subjectName}}</option>

                            </select> -->
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <label>Languages</label>
                        <div class="form-group">
                            <ng-multiselect-dropdown  [placeholder]="'Select Language'" [data]="listlanguages"  [(ngModel)]="selectedlanguagelist" [ngModelOptions]="{standalone: true}" [settings]="dropdownlanguageSettings" (onSelect)="onItemlanguageSelect($event)" (onSelectAll)="onItemlanguageallSelect($event)" (onDeSelectAll)="onItemlanguageallDeSelect($event)" (onDeSelect)="onItemlanguageDeSelect($event)" >
                                <!-- <option disabled>Select Categories</option>
                                <option *ngFor="let categories of listcategories">{{categories.categoryName}}</option> -->

                            </ng-multiselect-dropdown>
                            <!-- <select formControlName="subjects" class="browser-default custom-select">
                                <option disabled>Select Subject</option>
                                <option *ngFor="let Subject of listsubjects">{{Subject.subjectName}}</option>

                            </select> -->
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="myDashboard-navigation">
                            <!-- <ul class="nav nav-tabs">
                                <li class="active"><a data-toggle="tab" href="#home" class="active">Education Info</a></li>
                            </ul> -->
                            <h5 labelStyle>Bank Details</h5>
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <label >Account Name</label>
                        <div class="form-group">
                            <input type="text" [ngClass]="{ 'is-invalid': submitted && f.accountName.errors }"  placeholder="Account Name" formControlName="accountName" maxlength="40" class="form-control" />
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label >Account Number</label>
                        <div class="form-group">
                            <input type="number" [ngClass]="{ 'is-invalid': submitted && f.accountNumber.errors }" placeholder="Account Number" formControlName="accountNumber" maxlength="40" class="form-control" />
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label >Account Type</label>
                        <div class="form-group">
                        <input type="text" [ngClass]="{ 'is-invalid': submitted && f.accountType.errors }" placeholder="Account Type" formControlName="accountType" maxlength="40" class="form-control" />
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label >Bank Name</label>
                        <div class="form-group">
                            <input type="text" [ngClass]="{ 'is-invalid': submitted && f.bankName.errors }" placeholder="Bank Name" formControlName="bankName" maxlength="40" class="form-control" />
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label >Bank Branch</label>
                        <div class="form-group">
                            <input type="text" [ngClass]="{ 'is-invalid': submitted && f.bankBranch.errors }" placeholder="Bank Branch" formControlName="bankBranch" maxlength="40" class="form-control" />
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label >IFSC Code</label>
                        <div class="form-group">
                            <input type="text" [ngClass]="{ 'is-invalid': submitted && f.ifscCode.errors }" placeholder="IFSC Code" formControlName="ifscCode" maxlength="40" class="form-control" />
                        </div>
                    </div>
                </div>
                <button  class="col-lg-2 float-right">Update Profile &nbsp;<i
                        class="fas fa-long-arrow-alt-right"></i></button>
            </div>




            <div id="menu1" class="tab-pane fade">
                <div class="row borderpro">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <!-- <label>Qualification</label> -->
                            <input type="text" placeholder="Qualification" formControlName="qualification"
                                maxlength="25" class="form-control" />
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <!-- <label>Profession</label> -->
                            <input type="text" placeholder="Profession" formControlName="profession" maxlength="25"
                                class="form-control" />
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <!-- <label>Current Education</label> -->
                            <input type="text" placeholder="Current Education" formControlName="currentEducation"
                                maxlength="25" class="form-control" />
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <!-- <label>Preferences</label> -->
                            <input type="text" placeholder="Preferences" formControlName="preferences" maxlength="40"
                                class="form-control" />
                        </div>
                    </div>
                </div>
                <button  class="col-lg-2 float-right">Update Profile &nbsp;<i
                        class="fas fa-long-arrow-alt-right"></i></button>
            </div>

            <div id="menu3" class="tab-pane fade">
                <div class="row borderpro">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <!-- <label>Facebook</label> -->
                            <input type="text" placeholder="Paste your Facebook URL here" maxlength="100"
                                formControlName="facebook" class="form-control" />
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <!-- <label>Twitter</label> -->
                            <input type="text" placeholder="Paste your Twitter URL here" maxlength="100"
                                formControlName="twitter" class="form-control" />
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <!-- <label>Instagram</label> -->
                            <input type="text" placeholder="Paste your Instagram URL here" maxlength="100"
                                formControlName="instagram" class="form-control" />
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <!-- <label>LinkedIn</label> -->
                            <input type="text" placeholder="Paste your LinkedIn URL here" maxlength="100"
                                formControlName="linkedIn" class="form-control" />
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <!-- <label>You Tube</label> -->
                            <input type="text" placeholder="Paste your You Tube URL here" maxlength="100"
                                formControlName="youtube" class="form-control" />
                        </div>
                    </div>
                </div>
                <button  class="col-lg-2 float-right">Update Profile &nbsp;<i
                        class="fas fa-long-arrow-alt-right"></i></button>
            </div>

        </div>
    </div>
</form>