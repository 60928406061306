import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../shared/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from 'src/environments/environment';
import { ToastrManager, Toastr } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-edit-exam',
  templateUrl: './edit-exam.component.html',
  styleUrls: ['./edit-exam.component.scss']
})
export class EditExamComponent implements OnInit {
  examcreateform: FormGroup;
  submitted = false;
  formData; 
  public isstudent = false;
  public isteacher = false;
  
  public coursecategorylist : any = [];
  public courseboardlist : any = [];
  public coursesubjectlist : any = [];
  public languageapi : any = [];
  public gradeapi : any = [];  
  public coursePrivacyapi:any=[];  
  public examlevelapi:any=[];
  public exampricingapi:any=[];
  public Certificationapi:any=[];
  public groupname:any ='';
  public isEnterprise = '';
  public paramData:any = [];
  public courseCategory = "Select Category";
  public courseBoard = "Select Board";
  public courseSubject = "Select Subject";
  public language = "Select Language";
  public grade = "Select Grade";  
  public coursePrivacy = "Select Privacy";  
  selectedFileNmae: string = 'Choose File';  
  public isselected = true;
  public isgroupname = false;  
  isexammeexists: boolean;
  isshortnameexists: boolean;
  isstartdate: boolean;  
  public iscost = false;
  examDetails: any;
  examSideID: any;  //course id of the exam on the course.buddiforlife.com side
  examID: any;
  typeuser: string;  
  public editcourseID: any;
  editcategoryname: any;
  selectedFileName: string = 'Upload Profile Photo';
  public statusofcourse: any = '';

  constructor(private activatedRoute:ActivatedRoute,private SpinnerService: NgxSpinnerService,private router: Router ,private formBuilder: FormBuilder,private rest: ApiService, private toastr: ToastrManager) { }

  ngOnInit(): void {
    this.editcourseID = this.activatedRoute.snapshot.params['courseid'];
    this.editcategoryname = this.activatedRoute.snapshot.params['categoryname'];
    const userType = localStorage.getItem('userType');      
    if(userType.toLowerCase()=="teacher") {
      this.isteacher = true;
    }
    else {
      this.isstudent = true;
    }
    const webToken = localStorage.getItem('webtoken');


    this.getcourseprivacy();
    this.getcoursecategory();
    this.getboardfield();
    this.getsubjectfield();
    this.getlanguage();
    this.getgrade();
    this.getexamlevelapi();
    this.getcertification();
    this.getpricing();

    this.examcreateform = this.formBuilder.group({
      fullname: ['', Validators.required],
      shortname: ['', Validators.required],
      startdateschedule: ['', Validators.required],
      endDateschedule: ['', Validators.required],
      coursePrivacy: ['', Validators.required],
      courseGrade: [''],
      courseSubject: ['', Validators.required],
      courseLanguage:['', Validators.required],
      courseDescription:['', Validators.required],
      upload_name: [null],
      fileupload: [''],
      courseCategory: ['', Validators.required],
      courseBoard: ['', Validators.required],
      examlevel : [''],
      Certification : [''],
      Cost : [''],
      examlength : ['', Validators.required],
      questions : ['', Validators.required],
      score : ['', Validators.required],
      exampricing :['']
    });

    this.getexamdetails();



  }
  get f() { return this.examcreateform.controls; }



 


  fileChange(event) {
    let fileList: FileList = event.target.files;
    this.examcreateform.patchValue({ upload_name: fileList[0] });
    this.selectedFileNmae = fileList[0].name;
    this.examcreateform.patchValue({ fileupload: this.selectedFileNmae });
    console.log(this.examcreateform.value);
  }

  getcoursecategory() {
    this.rest.getCourseCategory().subscribe((result) => {
      this.coursecategorylist = result.data;
      // if(Object.keys(this.paramData).length == 0){
      //   this.examcreateform.patchValue({ courseCategory: this.coursecategorylist[0]._id })
      // }
      console.log(this.coursecategorylist);
    }, (err) => {
    console.log(err);
    });
  }

  getboardfield(){
    this.rest.boardfield().subscribe((result) => {
      this.courseboardlist = result.data;
      // if(Object.keys(this.paramData).length == 0){
      //   this.examcreateform.patchValue({ courseBoard: this.courseboardlist[0]._id })
      // }
      console.log(this.courseboardlist);
    }, (err) => {
    console.log(err);
    });
  }

  getsubjectfield(){
    this.rest.subjectfield().subscribe((result) => {
      this.coursesubjectlist = result.data;
      // if(Object.keys(this.paramData).length == 0){
      //   this.examcreateform.patchValue({ courseSubject: this.coursesubjectlist[0]._id })
      // }
      console.log(this.coursesubjectlist);
    }, (err) => {
    console.log(err);
    });
  }

  getlanguage(){
    this.rest.languagefield().subscribe((result) => {
      this.languageapi = result.data;
      // if(Object.keys(this.paramData).length == 0){
      //   this.examcreateform.patchValue({ courseLanguage: this.languageapi[0]._id })
      // }
      console.log(this.languageapi);
    }, (err) => {
    console.log(err);
    });
  }

  getgrade(){
    this.rest.gradefield().subscribe((result) => {
      this.gradeapi = result.data;
      // if(Object.keys(this.paramData).length == 0){
      //   this.examcreateform.patchValue({ courseGrade: this.gradeapi[0]._id })
      // }
    }, (err) => {
    console.log(err);
    });
  }

  getcourseprivacy(){
    this.rest.privacy().subscribe((result) => {
      this.coursePrivacyapi = result;
      // if(Object.keys(this.paramData).length == 0){
      //   this.examcreateform.patchValue({ coursePrivacy: this.coursePrivacyapi[0].name })
      // }
    }, (err) => {
    console.log(err);
    });
  }

  getexamlevelapi(){
    this.rest.examlevel().subscribe((result) => {
      this.examlevelapi = result;
      // if(Object.keys(this.paramData).length == 0){
      //   this.examcreateform.patchValue({ examlevel: this.examlevelapi[0].name })
      // }
    }, (err) => {
    console.log(err);
    });
  }

  getcertification(){
    this.rest.certification().subscribe((result) => {
      this.Certificationapi = result;
      // if(Object.keys(this.paramData).length == 0){
      //   this.examcreateform.patchValue({ Certification: this.Certificationapi[0].name })
      // }
    }, (err) => {
    console.log(err);
    });
  }

  getpricing(){
    this.rest.pricing().subscribe((result) => {
      this.exampricingapi = result;
      if(Object.keys(this.paramData).length == 0){
        this.examcreateform.patchValue({ exampricing: this.exampricingapi[0].name })
      }
    }, (err) => {
    console.log(err);
    });
  }

  selectedcategory(event: any) {
    var selected = event.target.options[event.target.selectedIndex].text;
    if(selected == 'K-12'){         
      this.isselected = true;
    }else{
      this.isselected = false;
    }          
  }

  selectpricing(event: any) {
    var selected = event.target.options[event.target.selectedIndex].text;
    if(selected == 'Paid'){         
      this.iscost = true;
    }else{
      this.iscost = false;
    }          
  }

  selectedprivacy(event: any) {
    var selected = event.target.options[event.target.selectedIndex].text;
    if(selected == 'Group'){         
      this.isgroupname = true;
    }else{
      this.isgroupname = false;
    }          
  }

  getexamdetails() {
    const userType = localStorage.getItem('userType');  
    const webToken = localStorage.getItem('webtoken');    
    var examformData = {};
    examformData["id"] = this.editcourseID;
    examformData["userType"] = userType;
    this.rest.getExamDetails(webToken, examformData).subscribe((result) => {
      this.examDetails = result.data;
      this.examSideID = this.examDetails.examID;

      let assigndata: any = {};
      assigndata.fullname = result.data.fullName;
      assigndata.shortname = result.data.shortName;
      //assigndata.coursePrivacy = result.data.privacy;
      //assigndata.courseCategory = result.data.categoryID._id;
      // if (result.data.courseGrade != null) {
      //   assigndata.courseGrade = result.data.gradeID._id;
      // }     
      //assigndata.courseBoard = result.data.boardID._id;
      //assigndata.courseSubject = result.data.subjectID._id;
      assigndata.startdateschedule = result.data.startDate;
      assigndata.endDateschedule = result.data.endDate;
      this.selectedFileName = result.data.examImage.split('/')[result.data.examImage.split('/').length - 1].length > 29 ? result.data.examImage.split('/')[result.data.examImage.split('/').length - 1].slice(0, 30) + '...' : result.data.examImage.split('/')[result.data.examImage.split('/').length - 1];    
      //assigndata.courseLanguage = result.data.languageID._id;
      assigndata.courseDescription = result.data.description;
      // assigndata.examlevel = result.data.level;
      // assigndata.Certification = result.data.certification;
      // assigndata.exampricing = result.data.pricingType;
      assigndata.Cost = result.data.cost;
      assigndata.examlength = result.data.examLength;
      assigndata.questions = result.data.numberOfQuestions;      
      assigndata.score = result.data.totalScore;
      this.statusofcourse = result.data.status;


      this.examcreateform.patchValue(assigndata);
      this.examcreateform.patchValue({ Certification:  result.data.certification})
      this.examcreateform.patchValue({ exampricing:  result.data.pricingType})
      this.examcreateform.patchValue({ courseBoard:  result.data.boardID._id})
      this.examcreateform.patchValue({ courseCategory:  result.data.categoryID._id})
      if (result.data.courseGrade != null || result.data.courseGrade != "") {
        this.examcreateform.patchValue({ courseGrade:  result.data.gradeID._id})
      }   
      this.examcreateform.patchValue({ courseSubject:  result.data.subjectID._id})
      this.examcreateform.patchValue({ courseLanguage:  result.data.languageID._id})
      this.examcreateform.patchValue({ coursePrivacy:  result.data.privacy})
      this.examcreateform.patchValue({ examlevel:  result.data.level})
    });
  }


  onSubmit(FormGroup) {
    this.submitted = true;

    // stop here if form is invalid
    // if (this.coursecreateform.invalid) {
    //   console.log("Invalid Fields");
    //   this.toastr.errorToastr("Invalid Fields");
    //     return;
    // }         

    const data = FormGroup.value;
    const startDate1 = new Date(this.examcreateform.value.startdateschedule).getTime() / 1000
    const endDate1 = new Date(this.examcreateform.value.endDateschedule).getTime() / 1000
    const currentdate = new Date().getTime() / 1000



    this.formData = new FormData();
    if (data.upload_name != null) {
      this.formData.append("examImage", data.upload_name, data.upload_name.name);
    }
    this.formData.append("fullName", data.fullname);
    this.formData.append("shortName", data.shortname);
    this.formData.append("categoryID", data.courseCategory);
    this.formData.append("languageID", data.courseLanguage);
    this.formData.append("subjectID", data.courseSubject);
    this.formData.append("boardID", data.courseBoard);
    this.formData.append("gradeID", data.courseGrade);
    this.formData.append("privacy", data.coursePrivacy);
    this.formData.append("startDate", startDate1);
    this.formData.append("endDate", endDate1);
    this.formData.append("description", data.courseDescription);
    this.formData.append("level", data.examlevel);
    this.formData.append("pricingType", data.exampricing);    
    if(data.Cost == "null") {
      this.formData.append("cost", null);
    }    
    this.formData.append("certification", data.Certification);
    this.formData.append("examLength", data.examlength);
    this.formData.append("numberOfQuestions", data.questions);
    this.formData.append("totalScore", data.score);  
    this.formData.append("_id", this.editcourseID);
    this.formData.append("status",this.statusofcourse);
    // this.formData.append("_id", this.editcourseID)
    const webToken = localStorage.getItem('webtoken');
   
   

    for (var pair of this.formData.entries()) {
  //    console.log(pair[0]+ ', ' + pair[1]); 
      console.log(JSON.stringify(pair[0]+ ':' + pair[1] + ','));
  }
    
    this.rest.UpdateExamDetails(this.formData, webToken).subscribe((response) => {
      if (response.status) {
        this.router.navigateByUrl('exams/' + this.editcategoryname + "/" + this.editcourseID);
        this.toastr.successToastr('Exam Updated Successfull');
      }
      else {
        console.log(response);
      }
    }, (err) => {
      console.log(err);
    });

  }
}
