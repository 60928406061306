import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MediaService } from "../../../shared/media.service";
import { ToastrManager } from "ng6-toastr-notifications";
import { Location } from "@angular/common";
import { ApiService } from "../../../shared/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from "@angular/router";
import { ExternalLibraryService } from "../util";
declare let Razorpay: any;
import { environment } from "../../../../environments/environment";
@Component({
    selector: "app-institution-creation-form",
    templateUrl: "./institution-creation-form.component.html",
    styleUrls: ["./institution-creation-form.component.scss"],
})
export class InstitutionCreationFormComponent implements OnInit {
    createForm: FormGroup;
    submitted = false;
    selectedValue = "";
    RAZORPAY_OPTIONS: any;
    formData;
    languageselectedValue = "";
    public editData: any = [];
    public paramData: any = [];
    public isTeacher: boolean = false;
    public languageapi: any = [];
    public isEdit: Boolean = false;
    public institutionTypes = [];
    orderID = "";
    paymentID = ";";
    amount = 0;
    constructor(
        public toastr: ToastrManager,
        private rest: ApiService,
        private location: Location,
        private formBuilder: FormBuilder,
        private SpinnerService: NgxSpinnerService,
        private api: MediaService,
        private router: Router,
        private razorpayService: ExternalLibraryService
    ) {}

    ngOnInit() {
        this.isTeacher =
            localStorage.getItem("profileType").toLowerCase() == "teacher";

        this.createForm = this.formBuilder.group({
            id: null,
            instituteName: ["", Validators.required],
            shortName: ["", Validators.required],
            // address: ["", Validators.required],
            // Locality: ["", Validators.required], 
            phonenumber: ["", Validators.required],
            // description: ["", Validators.required],
            // adminName: ["", Validators.required],
            // adminPhonenumber: ["", Validators.required],
            // adminEmailid: ["", Validators.required],
            //courseLanguage: ["", Validators.required],
            // expertise: ["", Validators.required],
            // reviews: ["", Validators.required],
            institutiontype: ["", Validators.required],
            packagetype: ["", Validators.required],
            classes: ["", Validators.required],
            // linkedin: ["", Validators.required],
            // facebook: ["", Validators.required],
            // totalteachers: ["", Validators.required],
            // totalstudents: ["", Validators.required],
            // totaladmins: ["", Validators.required],
        });
        this.getInstitutionTypes();
        this.razorpayService
            .lazyLoadLibrary("https://checkout.razorpay.com/v1/checkout.js")
            .subscribe();
        this.RAZORPAY_OPTIONS = {
            key: environment.razorpayKey,
            amount: "",
            name: environment.paymentNameHead,
            order_id: "",
            description: environment.paymentNameSub,
            prefill: {
                name: "",
                contact: "",
                method: "",
            },
            modal: {},
            theme: {
                color: "#0096C5",
            },
        };
    }
    get f() {
        return this.createForm.controls;
    }

    getInstitutionTypes() {
        const webToken = localStorage.getItem("webtoken");
        this.rest.getinstitutionTypes(webToken).subscribe((response) => {
            console.log(response);
            this.institutionTypes = response.data;
            console.log(this.institutionTypes);
        });
    }
    selectlanguageChange(event: any) {
        this.languageselectedValue = event.target.options[
            event.target.selectedIndex
        ].value.substring(
            event.target.options[event.target.selectedIndex].value.indexOf(
                ":"
            ) + 1
        );
    }

    goBack() {
        this.location.back();
    }

    onSubmit() {
        let data = this.createForm.value;
        let institutionType = data.packagetype;
        console.log(institutionType);
        let institutionObject = this.institutionTypes.filter((item) => {
            return item._id.toString() === institutionType.toString();
        });
        // if (!institutionObject || institutionObject.length == 0) {
        //     this.toastr.errorToastr(
        //         "Something went wrong. Please try again later."
        //     );
        //     return;
        // }
        console.log(this.createForm);
        if (!this.createForm.valid) {
            const controls = this.createForm.controls;
            for (const name in controls) {
                if (controls[name].invalid) {
                    console.log(name);

                    if (name == "instituteName") {
                        this.toastr.errorToastr(
                            "Please enter a valid Institute Name"
                        );

                        return;
                    } else if (name == "shortName") {   
                        this.toastr.errorToastr(
                            "Please enter a valid ShortName"
                        );

                        return;
                    } else if (name == "Locality") {
                        this.toastr.errorToastr("Please enter a valid Address");

                        return;
                    } else if (name == "phonenumber") {
                        this.toastr.errorToastr(
                            "Please enter a valid Phonenumber"
                        );

                        return;
                    } else if (name == "description") {
                        this.toastr.errorToastr(
                            "Please enter a valid Description"
                        );

                        return;
                    } else if (name == "courseLanguage") {
                        this.toastr.errorToastr(
                            "Please enter a valid Language"
                        );

                        return;
                    } else if (name == "adminName") {
                        this.toastr.errorToastr(
                            "Please enter a valid AdminName"
                        );

                        return;
                    } else if (name == "adminPhonenumber") {
                        this.toastr.errorToastr(
                            "Please enter a valid Admin Phone number"
                        );

                        return;
                    } else if (name == "adminEmailid") {
                        this.toastr.errorToastr(
                            "Please enter a valid Admin Email id"
                        );

                        return;
                    } else if (name == "institutiontype") {
                        this.toastr.errorToastr(
                            "Please select a valid Institution type"
                        );

                        return;
                    } else if (name == "packagetype") {
                        this.toastr.errorToastr(
                            "Please enter a valid Package type"
                        );

                        return;
                    } else if (name == "classes") {
                        this.toastr.errorToastr(
                            "Please enter a valid class value"
                        );

                        return;
                    } else if (name == "facebook") {
                        this.toastr.errorToastr(
                            "Please enter a valid Whatsapp URL"
                        );

                        return;
                    } else if (name == "linkedin") {
                        this.toastr.errorToastr(
                            "Please enter a valid Linkedin URL"
                        );

                        return;
                    } else {
                        this.toastr.errorToastr("Invalid Fields");
                        return;
                    }
                }
            }
        }
        let value = institutionObject[0]["price"];
        console.log(value)
        if (value == 0) {
            this.createInstitution();
            return
        }
        this.amount = value;
        this.RAZORPAY_OPTIONS["amount"] = this.amount * 100;
        this.RAZORPAY_OPTIONS["handler"] =
            this.razorPaySuccessHandler.bind(this);
        this.initiateOrder();
    }

    Institutiontype(e) {
        this.createForm.patchValue({ privacy: e.target.value });
    }

    initiateOrder() {
        const webToken = localStorage.getItem("webtoken");
        var request = {
            amount: this.amount,
        };
        if (!webToken) {
            this.toastr.errorToastr("Please login to continue");
            this.router.navigate(["/teacher/login"]);
        } else {
            this.SpinnerService.show();
            this.rest
                .purchaseInstitution(webToken, request)
                .subscribe((response) => {
                    this.SpinnerService.hide();
                    console.log("material", response);
                    this.orderID = response.data.orderId;
                    this.paymentID = response.data._id;
                    this.RAZORPAY_OPTIONS["order_id"] = response.data.orderId;

                    let razorpay = new Razorpay(this.RAZORPAY_OPTIONS);
                    razorpay.open();
                });
        }
    }

    public razorPaySuccessHandler(response) {
        console.log(response);
        var jsonData = {};
        const webToken = localStorage.getItem("webtoken");
        jsonData["razorpay_order_id"] = this.orderID;
        jsonData["razorpay_payment_id"] = response.razorpay_payment_id;
        jsonData["razorpay_signature"] = response.razorpay_signature;
        jsonData["paymentID"] = this.paymentID;
        this.SpinnerService.show();
        this.rest
            .completePurchaseInstitution(webToken, jsonData)
            .subscribe((response) => {
                console.log(response)
                this.SpinnerService.hide();
                if(response['status']) {
                    this.toastr.successToastr("Purchase Successful");
                    this.createInstitution();
                }
                else{
                    this.toastr.errorToastr('Something went wrong. Please try again later.')
                }
                
            });
    }
    public createInstitution() {
        var btn = document.getElementById(
            "addInstitutionButton"
        ) as HTMLButtonElement;
        this.submitted = true;
        const webToken = localStorage.getItem("webtoken");
        let data = this.createForm.value;
        var reqObject = {};
        reqObject["shortName"] = data.shortName;
        reqObject["address"] = data.address;
        reqObject["phoneNumber"] = data.phonenumber;
        reqObject["description"] = data.description;
        reqObject["institutionName"] = data.instituteName;
        reqObject["subscriptionType"] = data.packagetype;
        reqObject["classes"] = data.classes;
        reqObject["expertise"] = data.expertise;
        reqObject["reviews"] = data.reviews;
        reqObject["whatsapp"] = data.whatsapp;
        reqObject["linkedin"] = data.linkedin;
        reqObject["institutionType"] = data.institutiontype;
        reqObject["totalTeachers"] = data.totalteachers;
        reqObject["totalStudents"] = data.totalstudents;
        reqObject["totalAdmins"] = data.totaladmins;
        reqObject["monthsPurchased"] = 12;
        console.log(reqObject);
        this.SpinnerService.show();
        this.rest
        .createInstitution(webToken, reqObject)
        .subscribe((response) => {
            this.SpinnerService.hide();

            console.log(response);
            if (response.status) {
                this.router.navigate(["/sidenav/payment/subscription"]);
                this.toastr.infoToastr(response.message);
            } else {
                this.toastr.errorToastr(response.message);
                btn.disabled = false;
            }
        });
    }
}
