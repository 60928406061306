import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-coin-transaction-history',
  templateUrl: './coin-transaction-history.component.html',
  styleUrls: ['./coin-transaction-history.component.scss']
})
export class CoinTransactionHistoryComponent implements OnInit {
  public isStudent = false;
  public isTeacher = false;
  constructor() { }

  ngOnInit(): void {
    this.isTeacher =
    localStorage.getItem("profileType").toLowerCase() == "teacher";
this.isStudent =
    localStorage.getItem("profileType").toLowerCase() == "student";
  }

}
