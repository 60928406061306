import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../shared/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from 'src/environments/environment';
import { ToastrManager, Toastr } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-edit-course',
  templateUrl: './edit-course.component.html',
  styleUrls: ['./edit-course.component.scss']
})
export class EditCourseComponent implements OnInit {
  coursecreateform: FormGroup;
  submitted = false;
  formData;
  public isstudent = false;
  public isteacher = false;
  courseSideID: any;
  courseName: any;
  public userId: any = '';
  public courseCategory = "Select Category";
  public courseBoard = "Select Board";
  public courseSubject = "Select Subject";
  public language = "Select Language";
  public grade = "Select Grade";
  selectedFileNmae: string = 'Choose File';
  public coursecategorylist: any = [];
  public paramData: any = [];
  public courseboardlist: any = [];
  public coursesubjectlist: any = [];
  public languageapi: any = [];
  public gradeapi: any = [];
  public coursePrivacyapi: any = [];
  public isselected = true;
  public isgroupname = false;
  iscoursenameexists: boolean;
  isshortnameexists: boolean;
  isstartdate: boolean;
  public isEnterprise = '';
  selectedFileName: string = 'Upload Profile Photo';
  courseDetails: any;
  startdate: any;
  editcourseID: any;
  groupname: any;
  editcategoryname: any;
  public statusofcourse: any = '';

  constructor(private activatedRoute: ActivatedRoute, private SpinnerService: NgxSpinnerService, private router: Router, private formBuilder: FormBuilder, private rest: ApiService, private toastr: ToastrManager) { }

  ngOnInit(): void {
    this.editcourseID = this.activatedRoute.snapshot.params['courseid'];
    this.editcategoryname = this.activatedRoute.snapshot.params['categoryname'];
    var profileName = localStorage.getItem('profileName');
    this.userId = localStorage.getItem('userId');
    const webToken = localStorage.getItem('webtoken');
    const userType = localStorage.getItem('userType');
    if (userType.toLowerCase() == "teacher") {
      this.isteacher = true;
    }
    else {
      this.isstudent = true;
    }
    this.rest.userName_DisplayTeacher(webToken).subscribe((result) => {
      //  console.log(result);
      this.groupname = result.data != undefined && result.data.groupName != 'buddi' ? result.data.groupName : ''
      localStorage.setItem('groupname', result.data != undefined && result.data.groupName != 'buddi' ? result.data.groupName : '');
    });

    this.getcourseprivacy();
    this.getcoursecategory();
    this.getboardfield();
    this.getsubjectfield();
    this.getlanguage();
    this.getgrade();

    this.coursecreateform = this.formBuilder.group({
      fullname: ['', Validators.required],
      shortname: ['', Validators.required],
      startdateschedule: ['', Validators.required],
      endDateschedule: ['', Validators.required],
      coursePrivacy: ['', Validators.required],
      courseGrade: [''],
      courseSubject: ['', Validators.required],
      courseLanguage: ['', Validators.required],
      courseDescription: ['', Validators.required],
      upload_name: [null],
      fileupload: [''],
      courseCategory: ['', Validators.required],
      courseBoard: ['', Validators.required]

    });

    this.getcoursedetails();

  }

  get f() { return this.coursecreateform.controls; }

  fileChange(event) {
    let fileList: FileList = event.target.files;
    this.coursecreateform.patchValue({ upload_name: fileList[0] });
    this.selectedFileNmae = fileList[0].name;
    this.coursecreateform.patchValue({ fileupload: this.selectedFileNmae });
    // console.log(this.coursecreateform.value);
  }

  getcoursecategory() {
    this.rest.getCourseCategory().subscribe((result) => {
      this.coursecategorylist = result.data;
      // if(Object.keys(this.paramData).length == 0){
      //   this.coursecreateform.patchValue({ courseCategory: this.coursecategorylist[0]._id })
      // }
      //  console.log(this.coursecategorylist);
    }, (err) => {
      console.log(err);
    });
  }

  getboardfield() {
    this.rest.boardfield().subscribe((result) => {
      this.courseboardlist = result.data;
      // if (Object.keys(this.paramData).length == 0) {
      //   this.coursecreateform.patchValue({ courseBoard: this.courseboardlist[0]._id })
      // }
    }, (err) => {
      console.log(err);
    });
  }

  getsubjectfield() {
    this.rest.subjectfield().subscribe((result) => {
       this.coursesubjectlist = result.data;
      // if (Object.keys(this.paramData).length == 0) {
      //   this.coursecreateform.patchValue({ courseSubject: this.coursesubjectlist[0]._id })
      // }
      //  console.log(this.coursesubjectlist);
    }, (err) => {
      console.log(err);
    });
  }

  getlanguage() {
    this.rest.languagefield().subscribe((result) => {
       this.languageapi = result.data;
      // if (Object.keys(this.paramData).length == 0) {
      //   this.coursecreateform.patchValue({ courseLanguage: this.languageapi[0]._id })
      // }
      // console.log(this.languageapi);
    }, (err) => {
      console.log(err);
    });
  }

  getgrade() {
    this.rest.gradefield().subscribe((result) => {
      this.gradeapi = result.data;
      // if (Object.keys(this.paramData).length == 0) {
      //   this.coursecreateform.patchValue({ courseGrade: this.gradeapi[0]._id })
      // }
      // console.log(this.languageapi);
    }, (err) => {
      console.log(err);
    });
  }

  getcourseprivacy() {
    this.rest.privacy().subscribe((result) => {
      this.coursePrivacyapi = result;
      // if (Object.keys(this.paramData).length == 0) {
      //   this.coursecreateform.patchValue({ coursePrivacy: this.coursePrivacyapi[0].name })
      // }
    }, (err) => {
      console.log(err);
    });
  }

  getcoursedetails() {
    var courseformData = {};
    const userType = localStorage.getItem('userType');
    courseformData["id"] = this.editcourseID;
    courseformData["userType"] = userType;
    const webToken = localStorage.getItem('webtoken');
    this.SpinnerService.show();
    this.rest.getCourseDetails(webToken, courseformData).subscribe((result) => {
      this.SpinnerService.hide();
      this.courseDetails = result.data;
      this.courseSideID = this.courseDetails.courseID;
      let assigndata: any = {};
      assigndata.fullname = result.data.fullName;
      assigndata.shortname = result.data.shortName;
      //assigndata.coursePrivacy = result.data.privacy;
      if (result.data.privacy == "Group") {
        this.isgroupname = true;
      }
      //assigndata.courseCategory = result.data.categoryID._id;
      if (result.data.gradeID != null) {
        assigndata.courseGrade = result.data.gradeID._id;
      }

      //assigndata.courseBoard = result.data.boardID._id;
      //assigndata.courseSubject = result.data.subjectID._id;
      assigndata.startdateschedule = result.data.startDate;
      assigndata.endDateschedule = result.data.endDate;
      this.selectedFileName = result.data.courseImage.split('/')[result.data.courseImage.split('/').length - 1].length > 29 ? result.data.courseImage.split('/')[result.data.courseImage.split('/').length - 1].slice(0, 30) + '...' : result.data.courseImage.split('/')[result.data.courseImage.split('/').length - 1];
      //  assigndata.fileupload = result.data.fullName;
      //assigndata.courseLanguage = result.data.languageID._id;
      assigndata.courseDescription = result.data.description;
      this.statusofcourse = result.data.status;

      this.coursecreateform.patchValue(assigndata);
      this.coursecreateform.patchValue({ courseBoard:  result.data.boardID._id})
      this.coursecreateform.patchValue({ courseCategory:  result.data.categoryID._id})
      this.coursecreateform.patchValue({ courseGrade:  result.data.gradeID._id})
      this.coursecreateform.patchValue({ courseSubject:  result.data.subjectID._id})
      this.coursecreateform.patchValue({ courseLanguage:  result.data.languageID._id})
      this.coursecreateform.patchValue({ coursePrivacy:  result.data.privacy})

    });
  }

  selectedcategory(event: any) {
    var selected = event.target.options[event.target.selectedIndex].text;
    if (selected == 'K-12') {
      this.isselected = true;
    } else {
      this.isselected = false;
    }
  }

  selectedprivacy(event: any) {
    var selected = event.target.options[event.target.selectedIndex].text;
    if (selected == 'Group') {
      this.isgroupname = true;
    } else {
      this.isgroupname = false;
    }
  }

  onSubmit(FormGroup) {
    this.submitted = true;

    // stop here if form is invalid
    // if (this.coursecreateform.invalid) {
    //   console.log("Invalid Fields");
    //   this.toastr.errorToastr("Invalid Fields");
    //     return;
    // }         

    const data = FormGroup.value;
    const startDate1 = new Date(this.coursecreateform.value.startdateschedule).getTime() / 1000
    const endDate1 = new Date(this.coursecreateform.value.endDateschedule).getTime() / 1000
    const currentdate = new Date().getTime() / 1000



    this.formData = new FormData();
    if (data.upload_name != null) {
      this.formData.append("course_image", data.upload_name, data.upload_name.name);
    }
    this.formData.append("fullName", data.fullname);
    this.formData.append("shortName", data.shortname);
    this.formData.append("categoryID", data.courseCategory);
    this.formData.append("languageID", data.courseLanguage);
    this.formData.append("subjectID", data.courseSubject);
    this.formData.append("boardID", data.courseBoard);
    this.formData.append("gradeID", data.courseGrade);
    this.formData.append("privacy", data.coursePrivacy);
    this.formData.append("startDate", startDate1);
    this.formData.append("endDate", endDate1);
    this.formData.append("description", data.courseDescription);
    this.formData.append("status",this.statusofcourse);
    this.formData.append("_id", this.editcourseID)
    const webToken = localStorage.getItem('webtoken');
    console.log(this.formData);
    this.rest.UpdateCourseDetails(this.formData, webToken).subscribe((response) => {
      if (response.status) {
        this.router.navigateByUrl('courses/' + this.editcategoryname + "/" + this.editcourseID);
        this.toastr.successToastr('Course Updated Successfully');
      }
      else {
        console.log(response);
      }
    }, (err) => {
      console.log(err);
    });

  }

}
