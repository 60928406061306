import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-feedback-style-one',
  templateUrl: './feedback-style-one.component.html',
  styleUrls: ['./feedback-style-one.component.scss']
})
export class FeedbackStyleOneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    
    $('.carousel-control-prev').click(function(e) {
      
      var stopAllYouTubeVideos = () => { 
        var iframes = document.querySelectorAll('iframe');
        Array.prototype.forEach.call(iframes, iframe => { 
          iframe.contentWindow.postMessage(JSON.stringify({ event: 'command', 
        func: 'stopVideo' }), '*');
       });
      }
      stopAllYouTubeVideos();

      // $(".iframe-carousel").each(function() { 
        
      //    //var src= $(this).attr('src');
      //    //$(this).attr('src',src);  
      //    $(this).contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
      //  });

    });
    
    $('.carousel-control-next').click(function(e) {
            
      // $(".iframe-carousel").each(function() { 
      //   var src= $(this).attr('src');       
      //   $(this).attr('src',src);  
      // });
      var stopAllYouTubeVideos = () => { 
        var iframes = document.querySelectorAll('iframe');
        Array.prototype.forEach.call(iframes, iframe => { 
          iframe.contentWindow.postMessage(JSON.stringify({ event: 'command', 
        func: 'stopVideo' }), '*');
       });
      }
      stopAllYouTubeVideos();

      // $(".iframe-carousel").each(function() { 
        
      //   //var src= $(this).attr('src');
      //   //$(this).attr('src',src);  
      //   $(this).contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
      // });

    });

  }

}
