<div class="container-fluid row p-0" style="cursor: pointer" *ngIf="data.length !=0">
    <div class="col-lg-3 col-md-6" *ngFor="let slide of data  | paginate: config">
        <div class="single-courses-box without-box-shadow mb-30">
            <div class="courses-image">
                <a (click)="redirectDetails(slide)" class="d-block">
                    <img style="width: 250px;height: 175px;"  [src]="slide.image =='' || slide.image==undefined? 'assets/img/courses/courses1.jpg':slide.image" alt="image">
                </a>
            </div>
            <div class="courses-content" *ngIf="slide.teacherID!=undefined else buddi">
                <div class="course-author d-flex align-items-center" >
                    <img [src]="slide.teacherID!=undefined && slide.teacherID.userPhoto!='' ? slide.teacherID.userPhoto:'assets/img/user1.jpg'"  class="rounded-circle mr-2" alt="image">
                    <span *ngIf="slide.teacherID!=undefined">
                    	{{slide.teacherID.firstName}} 
                    </span>
                    <img  class="recordIcon" src="assets/img/recordingicon.png"  *ngIf="slide.acceptStatus && slide.isrecorded" (click)="fetchrecordvideo(slide._id,'requestId')" style="">
                </div>                
                <div class="course-author d-flex align-items-center">
                    <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image">
                    {{slide.ScheduleDate |  date :"MMM dd,yyyy, hh:mm aaa"}}
                </div>
                <h3>
                    <a (click)="redirectDetails(slide)" class="d-inline-block">
                        <span class="text-break">{{slide.name}}</span>
                    </a>
                </h3>
            </div>
            <ng-template class="courses-content" #buddi>
                <div class="courses-content">
                    <div  class="course-author d-flex align-items-center">
                        <img [src]="slide.instructor!=undefined && slide.instructor.userPhoto!='' ? slide.instructor.userPhoto :'assets/img/user1.jpg'" class="rounded-circle mr-2" alt="image">
                        <span *ngIf="slide.instructor!=undefined">		{{slide.instructor.firstName}}
                        </span>
                        <img  class="recordIcon" src="assets/img/recordingicon.png"  *ngIf="slide.isrecorded" (click)="fetchrecordvideo(slide._id,'buddiGroupId')" style="">
                    </div>
                    <div class="course-author d-flex align-items-center">
                        <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image">
                            {{slide.startTime |  date :"MMM dd,yyyy"}} |
                            {{slide.endTime |  date :"MMM dd,yyyy"}}
                    </div>
                </div>
                <h3>
                    <a (click)="redirectDetails(slide)" class="nameFont d-inline-block">
                        <span class="text-break">{{slide.name}}</span>
                    </a>
                </h3>
            </ng-template >
            <list5 [slide]="slide" [hideData]=true></list5>                                
        </div>
    </div>
</div>
<pagination-controls class="float-right" [id]="config.id" *ngIf="data.length!=0" (pageChange)="pageChanged($event)"></pagination-controls>    
<div *ngIf="data.length ==0">
    No {{config.type}} Class(es) Available.
</div>