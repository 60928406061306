import { Component, OnInit } from '@angular/core';
import { ToastrManager } from "ng6-toastr-notifications";
import { Location } from "@angular/common";
import { ApiService } from "../../../shared/api.service";
import { Router, ActivatedRoute } from "@angular/router";
@Component({
  selector: 'app-summary-snapshot',
  templateUrl: './summary-snapshot.component.html',
  styleUrls: ['./summary-snapshot.component.scss']
})
export class SummarySnapshotComponent implements OnInit {
  tutortable=false;
  learnertable=false;
  selectedValue = "";
  formData;
  languageselectedValue = "";
  public classList: any = [];
  public editData: any = [];
  public paramData: any = [];
  public isTeacher: boolean = false;
  public languageapi: any = [];
  public isEdit: Boolean = false;
  public institutionDetails: any  = {};
  public studlist: any;
  public teachlist: any;
  public institutionIsAdmin = false;
  public isBasicInstitution = false;
  constructor(
      public toastr: ToastrManager,
      private rest: ApiService,
      private location: Location,
      private router: Router
  ) {}


  

  ngOnInit() {
      this.isTeacher =
          localStorage.getItem("profileType").toLowerCase() == "teacher";
      this.getInstitutionDetails()
  }
  getInstitutionDetails() {
      const webToken = localStorage.getItem("webtoken");
      this.rest.getInstitutionDetails(webToken).subscribe((response) => {
          console.log(response)
          if(response.redirectToCreate){
              this.router.navigate(["/Pricing-plans"]);
          }
          this.institutionDetails = response.data.institution;
          this.studlist=response.data.studentList;
          this.teachlist=response.data.teacherList;
          this.institutionIsAdmin = response.isAdmin;
          this.isBasicInstitution = response.data.institution.subscriptionType ? response.data.institution.subscriptionType.price == 0 : false;
          console.log(this.institutionDetails)
          console.log(this.studlist,'studentdetails')
          console.log(this.teachlist,'teacherdetails')
          console.log(this.institutionIsAdmin)
      });
  }

  tutor(){
       this.tutortable=true;
       this.learnertable=false
  }
  tutorclose(){
       this.tutortable=false;
  }
  learnerclose(){
       this.learnertable=false;
  }
  learner(){
       this.learnertable=true;
       this.tutortable=false;
  }
  su(){
      this.router.navigateByUrl('/summary-snapshot');
  }
}
