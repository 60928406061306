<div class="row">
    <div class="col-lg-3 d-flex mt-3 justify-content-center" style="border-right:1px solid rgb(242,244,249)">
       <ul>
           <li>
                <a  routerlink="/student/home" ng-reflect-router-link="/student/home" href="/student/home">
                    <img  class="logo" src="assets/img/black-logo.png" alt="logo">
                </a>
           </li>
       </ul>
        
    </div>
    <div class="col-lg-9 mt-3 boxb">
        <form [formGroup]="dashboard" >
            <div class="row">
                <div class="col-lg-2  mt-2">
                    Dashboard | <i class="fas fa-home"></i>
                </div>
                <div class="col-lg-8" *ngIf="data.length!=0">
                    <select (change)="changeCategory($event)" class="browser-default  custom-select  width20 mr-1" formControlName="categoryID">
                        <option value="" disabled>Select Category</option>
                        <option *ngFor="let obj of data.categoryList" [ngValue]="obj._id">{{obj.categoryName}}</option>
                    </select>
                    boardName : <span class="setcolor">{{data.studentDetails.board.boardName}} </span> gradeName : <span class="setcolor">{{data.studentDetails.grade.gradeName}}</span>  languageName :  <span class="setcolor">{{data.studentDetails.language.languageName}}</span> 
                    <!-- <select  class="browser-default  custom-select  width20 mr-1" formControlName="languageID" >
                        <option value="" disabled>Select Category</option>
                        <option *ngFor="let obj of data.languages" [ngValue]="obj._id">{{obj.languageName}}</option>
                    </select> -->
                </div>
                <div class="col-lg-1  mt-2">
                    <a class="nav-link" [routerLink]="" (click)="logout()" >Logout</a>
                </div>
                <div class="col-lg-1">
                    <a  href="javascript:void(0)" id="notificationDropLink"  class="nav-link dropdown-toggle">
                    <i class="bx bxs-bell bx-tada-hover"></i>
                    <sup >0</sup></a>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="row">
    <div class="col-3 p-0 text-center boxl">
      <div class="nav flex-column nav-pills" id="v-pills-tab"  role="tablist" aria-orientation="vertical">
        <a class="nav-link"  role="tab" >
            <img  alt="image" class ="imageStyle shadow" src="https://s3.ap-south-1.amazonaws.com/buddi.bucket/amaithi5f4dfaa35a0448402de5e102/Photos/5f4dfaa35a0448402de5e102-1610957531186.jpeg">
        </a>
        <a class="nav-link"  role="tab">
            <b>{{profilename}}</b>
        </a>
        <a class="nav-link active" id="dashboard-tab" data-toggle="pill" href="#dashboard" role="tab" aria-controls="dashboard" aria-selected="false">Dashboard</a>
        <a class="nav-link " id="mylearning-tab" data-toggle="pill" href="#mylearning" role="tab" aria-controls="mylearning" aria-selected="true">UpComing Classes</a>
        <a class="nav-link" id="profile-tab" data-toggle="pill" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Profile</a>
        <a class="nav-link" id="account-tab" data-toggle="pill" href="#account" role="tab" aria-controls="account" aria-selected="false">Account Settings</a>
        <a class="nav-link" id="payment-tab" data-toggle="pill" href="#payment" role="tab" aria-controls="payment" aria-selected="false">Payment History</a>
        <a class="nav-link " id="notification-tab" data-toggle="pill" href="#notification" role="tab" aria-controls="notification" aria-selected="false">Notification</a>
        <a class="nav-link" id="messages-tab" data-toggle="pill" href="#messages" role="tab" aria-controls="messages" aria-selected="false">Messages</a>
        <!-- <a class="nav-link" id="study-tab" data-toggle="pill" href="#study" role="tab" aria-controls="study" aria-selected="false">Study Material</a> -->
      </div>
    </div>
    <div class="col-9 p-0 dashboard_bg">
      <div class="tab-content no-border" id="v-pills-tabContent" >
        <div>
            <app-subject-nav [data]=data (dropdownData)="receivedData($event)" [emitData]=emitData></app-subject-nav>
        </div>
      </div>
    </div>
  </div>

