import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import * as moment from 'moment';
import { Location } from '@angular/common';
import { environment } from '../../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { MediaService } from '../../../../shared/media.service';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import { ProductexpiredPipe } from '../../../custom-pipes/productexpired.pipe';
import { EmptyObjectPipe } from '../../../custom-pipes/empty-object.pipe';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-student-profile',
  templateUrl: './student-profile.component.html',
  styleUrls: ['./student-profile.component.scss'],
  providers:[ProductexpiredPipe,EmptyObjectPipe]
})
export class StudentProfileComponent implements OnInit {
  
  userprofdata: any = [];
  id: any;
  userId: any;
  membership: string;
  enterpriseID: any;
  enterpriseName: any;
  privacy: any;
  groupName: any;
  adminID: any;
  enterprise: any;
  dashboard: any;
  licenseDate: any;
  uploadform: FormGroup;
  selectedFileName: string = 'Choose File';
  formdata: FormData;
  facebook: any;
  twitter: any;
  instagram: any;
  linkedIn: any;
  youtube: any;
  bio: any;
  classessForm: FormGroup;
  coursesForm: FormGroup;
  LibraryForm: FormGroup;
  teacherData: any;
  studentData: any;
  teacherTable: boolean = false;
  studentTable: boolean;
  dtOptions: DataTables.Settings = {};
  dupeEmailArray: any;
  dupePhoneArray: any;
  dupeArray: boolean;
  registerForm: FormGroup;
  public userPhoto: any;
  name: any;
  submitted = false;
  showModal: boolean;
  categorynamearray = '';
  boardnamearray = '';
  subjectnamearray = '';
  languagenamearray = '';
  gradenamearray = '';
  formData;
  stateInfo: any[] = [];
  countryInfo: any[] = [];
  cityInfo: any[] = [];
  public testdata: any = [];
  public pendingtestdata: any = [];
  public reviewtestdata: any = [];
  public meetingsData: any = [];
  public meetingsDataRecord: any = [];
  public meetingsDataListen: any = [];
  public meetingsDataAll: any = [];
  public allmeeting: any = [];
  isUserLoggedIn: string;
  public inviteUrl = "";
  public frontwebsite = environment.frontUrl;
  public meetingVisible = true;
  public myId: any = '';
  public isLogin: any = 'false'
  public courseData: any = [];
  public mydashboardData: any = [];
  public mydashboardDataRecord: any = [];
  public PastcourseData: any = [];
  public FuturecourseData: any = [];
  public PastcourseDatagroup: any = [];
  public FuturecourseDatagroup: any = [];
  coursestartDate: string;
  pastcoursestartDate: string;
  futurecoursestartDate: string;
  public requestedDataList: any = [];
  public requestedDataListRecord: any = [];
  public requestedDataAccepted: any = [];
  public PastcourseExam: any = [];
  public FuturecourseExam: any = [];
  public PastcourseExamgroup: any = [];
  public FuturecourseExamgroup: any = [];
  public songsList: any = [];
  public ebooklistbyId: any = []
  public videoList: any = [];
  public isTeacher: boolean = false;
  public isStudent: boolean = false;
  public isSchool: boolean = false;
  public videoGroup: any = [];
  public videoPublic: any = [];
  public videoMy: any = [];
  public audioGroup: any = [];
  public audioPublic: any = [];
  public audioMy: any = [];
  public ebookGroup: any = [];
  public ebookPublic: any = [];
  public ebookMy: any = [];
  public isSchoolcourse: boolean = false;
  public isSchoolexam: boolean = false;
  public buddiGroupData: any = [];
  public buddiGroupDataRecord: any = [];
  public paymentDetails:any = [];
  public coursecategorylist:any = [];
  public dateTimeRange: Date[];
  public dateTimeRangeTo: Date[];
  public videoYoutubePublic: any = [];
  public solutionPublic:any = [];
  public solutionGroup:any = [];
  public questionPublic:any = [];
  public questionGroup:any = [];
  isDesc: any = 1;
  column: string = 'remainingClass';
  public tabName ='home';
  public studentid : string;
  //For Pagination
  p: number = 1;
  count: number = 4;

  p1: number = 1;
  count1: number = 4;

  p2: number = 1;
  count2: number = 4;

  p3: number = 1;
  count3: number = 4;

  c1: number = 1;
  countc1: number = 4;

  c2: number = 1;
  countc2: number = 4;

  c3: number = 1;
  countc3: number = 4;

  c4: number = 1;
  countc4: number = 4;

  v1: number = 1;
  countv1: number = 4;

  v2: number = 1;
  countv2: number = 4;

  v3: number = 1;
  countv3: number = 4;

  v4: number = 1;
  countv4: number = 4;

  v5: number = 1;
  countv5: number = 4;

  v6: number = 1;
  countv6: number = 4;

  v7: number = 1;
  countv7: number = 4;

  v8: number = 1;
  countv8: number = 4;

  v88: number = 1;
  countv88: number = 4;

  v9: number = 1;
  countv9: number = 4;

  v10: number = 1;
  countv10: number = 4;



  e1: number = 1;
  counte1: number = 4;

  e: number = 1;
  counte: number = 4;

  e2: number = 1;
  counte2: number = 4;

  e3: number = 1;
  counte3: number = 4;

  te: number = 1;
  countte: number = 4;

  pte: number = 1;
  countpte: number = 4;

  rte: number = 1;
  countrte: number = 4;

  public invoiceUrl:any = '';  
  term: string;
  public publicDataRecord:any = [];
  public studentIsSchool:any = [];
  public requestedDataListLength:Number = 0;
  public pageLimit:Number = 0;
  public dataList:any = [];
  public config:any = [];
  public configOneonone:any = [];
  public buddiClass:any = [];
  public classData:any = [];
  public data:any = [];
  constructor(private activatedRoute:ActivatedRoute,private SpinnerService: NgxSpinnerService,private productexpired : ProductexpiredPipe,private api: MediaService, private router: Router, private userApi: ApiService, private location: Location, private formBuilder: FormBuilder, public toastr: ToastrManager) { }

  ngOnInit() {
    this.studentid = localStorage.getItem('StudentID');
    window.scrollTo(0, 0);
    this.tabName = window.history.state.pagename!=undefined ? window.history.state.pagename :'home';
    this.studentIsSchool = (localStorage.getItem('groupName') !='buddi')? true : false;
    const groupname = localStorage.getItem('groupname');
    var usertype = localStorage.getItem('profileType');
    this.isUserLoggedIn = localStorage.getItem('isLoggedin');
    var courseformData = {};
    this.isTeacher =
    localStorage.getItem("profileType").toLowerCase() == "teacher";
this.isStudent =
    localStorage.getItem("profileType").toLowerCase() == "student";
    courseformData["groupName"] = groupname;
    courseformData["categoryName"] = "";
    courseformData["userType"] = usertype.toLowerCase();
    this.invoiceUrl = environment.frontUrl;
    this.userdetails();
    this.getmyaudio();
    this.getmyEbook();
    this.gettestsdata();
    this.classessForm = this.formBuilder.group({
      classType: [false],
      classTypeExam: [false],
      classTypeRecord:  [false]
    });
    this.coursesForm = this.formBuilder.group({
      classType: [false]
    });
    this.LibraryForm = this.formBuilder.group({
      securityType: [false],
      securityTypeebook:[false]
    });
    this.registerForm = this.formBuilder.group({
      id: null,
      upload_name: [null],
      fileupload: ['', Validators.required],
      firstname: ['', [Validators.required, Validators.maxLength(25)]],
      lastname: ['', [Validators.required, Validators.maxLength(25)]],
      email: ['', [Validators.required, Validators.minLength(6)]],
      mobileNumber1: ['', [Validators.required, Validators.minLength(6)]],
      dob: ['', [Validators.required, Validators.minLength(6)]],
      gender: [''],
      street: [''],
      area: [''],
      city: [''],
      state: [''],
      postalCode: [''],
      currentCity: [''],
      qualification: [''],
      preferences: [''],
      currentEducation: [''],
      profession: [''],
      facebook: [''],
      twitter: [''],
      instagram: [''],
      linkedIn: [''],
      youtube: [''],
      bio: ['']
    });

    this.SpinnerService.show()
    this.userApi.getCourseCategoryHeader().subscribe((result) => {
      this.SpinnerService.hide()
      this.coursecategorylist = result.data;
    }, (err) => {
    console.log(err);
    });

    const webToken = localStorage.getItem('webtoken');
    // this.SpinnerService.show()
    // this.userApi.isFreeClass(webToken).subscribe((result) => {
    //   console.log(result);
    //   this.SpinnerService.hide()
    //   if(result.data.isfree.length==0){
    //     var req={
    //       _id:result.data.freeProduct._id,
    //       productPrice:result.data.freeProduct.productPrice,
    //       remainingClass:result.data.freeProduct.totalClass,
    //       remainingExam:result.data.freeProduct.totalExam,
    //       isFree :true
    //     }
    //     this.SpinnerService.show()
    //     this.userApi.addPaymentGetRes(webToken,req).subscribe((response) => {
    //       this.SpinnerService.hide()
    //       if(response.status){
    //         this.toastr.successToastr('Your One-on-one 10 free Class added Successfully');
    //       }
    //     });      
    //   }
    // }, (err) => {
    // console.log(err);
    // });
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true
    };

    this.uploadform = this.formBuilder.group({
      upload_name: [null],
      fileupload: ['']
    }); 

    if(webToken != null){
      this.SpinnerService.show()
      this.userApi.userName_DisplayStudent(webToken).subscribe((result) => {
       this.SpinnerService.hide()
        this.id = result.data.studentDetails._id;
        var localData={
          boardID :Object.keys(result.data.studentDetails.board).length!=0 ?  result.data.studentDetails.board._id : 'undefined',
          subjectID :Object.keys(result.data.studentDetails.subjects).length!=0 ?  result.data.studentDetails.subjects._id : 'undefined',
          categoryID :Object.keys(result.data.studentDetails.categories).length!=0 ?  result.data.studentDetails.categories._id : 'undefined',
          gradeID  :Object.keys(result.data.studentDetails.grade).length!=0 ? result.data.studentDetails.grade._id : 'undefined',
          
        }
        localStorage.setItem('educationInfo',JSON.stringify(localData));
        localStorage.setItem('groupName',result.data.studentDetails.groupName);
        this.userprofdata = result.data.studentDetails;
        console.log(this.userprofdata,'userDetails')
        const userId = { id: this.id };
      }, (err) => {
        console.log(err);
      });
    }
    // this.getSolutionData([]);
    // this.getQuestionData([]);
    this.getAddedClass([]);
    this.callBuddi([]);
    this.getClassData([]);
    // this.getAudioData([]);
    this.getStudyLibraryData([]);
    this.userApi.getPastCoursesbyGroup(webToken, courseformData).subscribe((result) => {
      this.PastcourseData = result.data.Public;
      this.PastcourseDatagroup = result.data.Group;

    });
    this.userApi.getFutureCoursesbyGroup(webToken, courseformData).subscribe((result) => {
      this.FuturecourseData = [];
      this.FuturecourseDatagroup = [];
      this.FuturecourseData = result.data.Public;
      this.FuturecourseDatagroup = result.data.Group;
      console.log('courses', result);
    });

    this.userApi.getPastExamsbyGroup(webToken, courseformData).subscribe((result) => {
      this.PastcourseExam = result.data.Public;
      this.PastcourseExamgroup = result.data.Group;
    });

    this.userApi.getFutureExamsbyGroup(webToken, courseformData).subscribe((result) => {
      this.FuturecourseExam = result.data.Public;
      this.FuturecourseExamgroup = result.data.Group;
    });
    this.userApi.enterpriseTeacherGet(webToken).subscribe((response) => {
      this.teacherData = response.data;
      console.log('teachers', this.teacherData);
    });

    this.userApi.enterpriseStudentGet(webToken).subscribe((response) => {
      this.studentData = response.data;
      console.log('students', this.studentData);
    });
    //   this.SpinnerService.show();
    //   this.userApi.getmyDashboadmeeting(webToken).subscribe((response) => {
    //   this.SpinnerService.hide();
    //   if (response.status) {
    //     if (response.data.groupData.length != 0) {
    //       var sortDate = response.data.groupData.sort(function (a, b) { return new Date(a.meetingID.ScheduleDate).getTime() - new Date(b.meetingID.ScheduleDate).getTime() });
    //       const now = Date.now();
    //       const futureDates = sortDate.filter(date => {
    //         return date.meetingID.ScheduleDate && new Date(date.meetingID.ScheduleDate).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0) || date.meetingID.isrecorded;
    //       });
    //       this.meetingsData = futureDates;
    //     }
    //     if(response.data.groupDataRecord.length!=0){
    //        var a = response.data.groupDataRecord.filter(function(val,key){if(val.meetingID.isrecorded){return val}})
    //       this.meetingsDataRecord = a;
    //     }
    //     if(response.data.publicDataRecord.length!=0){
    //       var a = response.data.publicDataRecord.filter(function(val,key){if(val.meetingID.isrecorded){return val}})
    //       this.publicDataRecord = a;
    //       this.publicDataRecord  = [...this.videoPublic,...this.publicDataRecord ,...this.buddiGroupDataRecord]
    //     }     
    //     if (response.data.buddiGroup.length != 0) {
    //       this.buddiGroupData = response.data.buddiGroup;
    //     }
    //   }
    // });
    // this.userApi.getMyRequestSuccess(webToken).subscribe((response) => {
    //   if (response.status && response.data != null && response.data.length != 0) {
    //     this.requestedDataAccepted = this.SortDataPast(response.data,false);
    //     // this.requestedDataAccepted = response.data;
    //   } else {
    //     this.requestedDataAccepted = [];
    //   }
    // });
    
    this.userApi.buddigroupRecordStudent(webToken).subscribe((result)=>{
      if(result.status){
        this.buddiGroupDataRecord = result.data
        this.videoPublic = [...this.videoPublic,...this.buddiGroupDataRecord,...this.publicDataRecord];
      }
    });
    this.userApi.getmyDashboadLibrary(webToken).subscribe((result) => {
      if (result.status) {
        this.videoMy = result.data.videoMy;
        this.videoGroup = result.data.videoGroup;
        this.videoPublic = [...result.data.videoPublic,...this.buddiGroupDataRecord,...this.publicDataRecord]; 
        this.audioGroup = result.data.audioGroup;
        this.audioPublic = result.data.audioPublic;
        this.videoYoutubePublic = result.data.youtubeVideo;
        this.audioMy = result.data.audioMy;
        this.ebookGroup = result.data.ebookGroup;
        this.ebookPublic = result.data.ebookPublic;
        this.ebookMy = result.data.ebookMy;

      }
    }, (err) => {
      console.log(err);
    }); 
    this.isLogin = localStorage.getItem('isLoggedin');
    this.myId = localStorage.getItem('userId');
    this.userApi.userName_DisplayStudent(webToken).subscribe((result) => {
      this.groupName = result.data.studentDetails != undefined ? result.data.studentDetails.groupName : '';
      var req = {
        groupname: this.groupName
      }
    });
  }
  back(){
    this.location.back();
  }
  copyToClipboard(element) {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val($(element).text()).select();
    document.execCommand("copy");
    $temp.remove();
    this.toastr.successToastr(' Referal Code Copied to Clipboard!');
  }
  getStudyLibraryData(data){    
    var req = {
      profileType : this.isTeacher ? 'teacher' : 'student',
      currentPage : data.currentPage ? data.currentPage : 1,
      groupName : this.groupName ? this.groupName : '',
      list : 'ebook',
      type :  data.type ? data.type : 'Public',
      itemsPerPage : data.pageNumber ? data.pageNumber : environment.listing__itemsPerPage,
    }
    var reqAudio = {
      profileType : this.isTeacher ? 'teacher' : 'student',
      currentPage : data.currentPage ? data.currentPage : 1,
      groupName : this.groupName ? this.groupName : '',
      list :  'song',
      type :  data.type ? data.type : 'Public',
      itemsPerPage : data.pageNumber ? data.pageNumber : environment.listing__itemsPerPage,
    }   
    var reqSolution = {
      profileType : this.isTeacher ? 'teacher' : 'student',
      currentPage : data.currentPage ? data.currentPage : 1,
      groupName : this.groupName ? this.groupName : '',
      list : 'solution',
      type :  data.type ? data.type : 'Public',
      itemsPerPage : data.pageNumber ? data.pageNumber : environment.listing__itemsPerPage,
    } 
    var reqQuestion = {
      profileType : this.isTeacher ? 'teacher' : 'student',
      currentPage : data.currentPage ? data.currentPage : 1,
      groupName : this.groupName ? this.groupName : '',
      list : 'question',
      type :  data.type ? data.type : 'Public',
      itemsPerPage : data.pageNumber ? data.pageNumber : environment.listing__itemsPerPage,
    }   
     var reqVideo = {
      profileType : this.isTeacher ? 'teacher' : 'student',
      currentPage : data.currentPage ? data.currentPage : 1,
      groupName : this.groupName ? this.groupName : '',
      list :  'video',
      type :  data.type ? data.type : 'Public',
      itemsPerPage : data.pageNumber ? data.pageNumber : environment.listing__itemsPerPage,
    }   
    var reqVideoYoutube = {
      profileType : this.isTeacher ? 'teacher' : 'student',
      currentPage : data.currentPage ? data.currentPage : 1,
      groupName : this.groupName ? this.groupName : '',
      list :  'videoyoutube',
      type :  data.type ? data.type : 'Public',
      itemsPerPage : data.pageNumber ? data.pageNumber : environment.listing__itemsPerPage,
    }   
    
    if(data.list==undefined){
      this.callAPI(req);
      this.callAPI(reqAudio);
      this.callAPI(reqSolution);
      this.callAPI(reqQuestion);
      this.callAPIVideo(reqVideo);
      this.callAPIVideoYoutube(reqVideo);      
    }else{
      switch(data.list){
        case 'ebook':{
          this.callAPI(req);
          break;
        }
        case 'song':{
          this.callAPI(reqAudio);
          break;
        }
        case 'video':{
          this.callAPIVideo(reqVideo);
          break;
        }
        case 'videoyoutube':{
          this.callAPIVideoYoutube(reqVideo);
          break;
        }
        case 'solution':{
          this.callAPI(reqSolution);
          break;
        }
        case 'question':{
          this.callAPI(reqQuestion);
          break;
        }
      }
    }
   
  }

  callAPIVideo(req){    
    const webToken = localStorage.getItem('webtoken');
    this.SpinnerService.show();    
    this.userApi.videoStudent(req, webToken).subscribe((result) => {
      this.SpinnerService.hide();
      var redirectTxt = 'video-details/';
      var hideType = false
     
      if(result.status){
        this.data[req.list] = result.data;
        this.config[req.list]  = {
          itemsPerPage: environment.listing__itemsPerPage,
          currentPage: result.currentPage ? result.currentPage : 1,
          totalItems: result.dataCount,
          id:req.list+req.type,
          type:req.type,
          list:req.list,
          redirect:redirectTxt,
          hideType:hideType
        };
      }else{
        this.data[req.list]  = [];
      }     
    });
  }

  callAPIVideoYoutube(req){    
    const webToken = localStorage.getItem('webtoken');
    this.SpinnerService.show();   
    req.list = "video" 
    this.userApi.youtubeStudent(req, webToken).subscribe((result) => {
      this.SpinnerService.hide();
      var redirectTxt = 'video-details/';
      var hideType = false;     
      if(result.status){
        this.data.videoyoutube = result.data;
        this.config.videoyoutube  = {
          itemsPerPage: environment.listing__itemsPerPage,
          currentPage: result.currentPage ? result.currentPage : 1,
          totalItems: result.dataCount,
          id:'videoyoutube'+req.type,
          type:req.type,
          list:'videoyoutube',
          redirect:redirectTxt,
          hideType:hideType
        };
      }else{
        this.data[req.list]  = [];
      }     
    });
  }
  callAPI(req){    
    const webToken = localStorage.getItem('webtoken');
    this.SpinnerService.show();    
    this.userApi.studyLibraryStudent(req, webToken).subscribe((result) => {
      this.SpinnerService.hide();
      var redirectTxt = '';
      var hideType = false
      switch(req.list){
        case 'song':{
          redirectTxt = 'audio-details/';
          hideType = true;
          break;
        }
        case 'video':{
          redirectTxt = 'video-details/';
          break;
        }       
        case 'ebook':{
          redirectTxt = 'ebook-details/';
          break;
        }
        case 'solution':{
          redirectTxt = 'details/solution/';
          break;
        }
        case 'question':{
          redirectTxt ='details/question/';
          break;
        }
      }
      
      if(result.status){
        this.data[req.list] = result.data;
        this.config[req.list]  = {
          itemsPerPage: environment.listing__itemsPerPage,
          currentPage: result.currentPage ? result.currentPage : 1,
          totalItems: result.dataCount,
          id:req.list+req.type,
          type:req.type,
          list:req.list,
          redirect:redirectTxt,
          hideType:hideType
        };
      }else{
        this.data[req.list]  = [];
      }     
    });
  }
  getAudioData(data){    
    const webToken = localStorage.getItem('webtoken');
    var req = {
      profileType : this.isTeacher ? 'teacher' : 'student',
      currentPage : data.currentPage ? data.currentPage : 1,
      groupName : this.groupName ? this.groupName : '',
      list : data.list ? data.list : 'audio',
      type :  data.type ? data.type : 'Public',
      itemsPerPage : data.pageNumber ? data.pageNumber : environment.listing__itemsPerPage,
    }
    this.SpinnerService.show();
    
    this.userApi.audioStudent(req, webToken).subscribe((result) => {
      this.SpinnerService.hide();
      if(result.status){
        this.data.audio = result.data;
        this.config.audio = {
          itemsPerPage: environment.listing__itemsPerPage,
          currentPage: result.currentPage ? result.currentPage : 1,
          totalItems: result.dataCount,
          id:req.list+req.type,
          type:req.type,
          list:req.list,
          redirect:'audio-details/'
        };
      }else{
        this.data.audio  = [];
      }     
    });
  }
  callBuddi(data){    
    const webToken = localStorage.getItem('webtoken');
    var req = {
      profileType : this.isTeacher ? 'teacher' : 'student',
      currentPage : data.currentPage ? data.currentPage : 1,
      groupName : this.groupName ? this.groupName : '',
      list : data.list ? data.list : 'buddiclass',
      type :  data.type ? data.type : 'Public',
      itemsPerPage : data.pageNumber ? data.pageNumber : environment.listing__itemsPerPage,
    }
    this.SpinnerService.show();
    this.userApi.buddiStudent(req, webToken).subscribe((result) => {
      this.SpinnerService.hide();
      if(result.status){
        this.buddiClass = result.data;
        this.config.buddiClass = {
          itemsPerPage: environment.listing__itemsPerPage,
          currentPage: result.currentPage ? result.currentPage : 1,
          totalItems: result.dataCount,
          id:'teacherbuddi',
          type:'buddiclass',
          list:'buddiclass',
        };
      }else{
        this.buddiClass  = [];
      }     
    });
  }
  getAddedClass(data){    
    const webToken = localStorage.getItem('webtoken');
    var req = {
      profileType : this.isTeacher ? 'teacher' : 'student',
      currentPage : data.currentPage ? data.currentPage : 1,
      groupName : this.groupName ? this.groupName : '',
      list : data.list ? data.list : 'class',
      type :  data.type ? data.type : 'Public',
      itemsPerPage : data.pageNumber ? data.pageNumber : environment.listing__itemsPerPage,
    }
    this.getClassStudent(req,webToken);
    // switch(data.type){
    //   case 'Public':{
        
    //     break;
    //   }
    //   case 'Group':{
    //     this.getGroupClass(req,webToken);
    //     break;
    //   }
    // }
    // if(data.type==undefined){
    //   this.getPublicClass(req,webToken);
    //   this.getGroupClass(req,webToken);
    // }
  }
  getClassStudent(req,webToken){
    this.SpinnerService.show();
    this.userApi.classStudent(req, webToken).subscribe((result) => {
      this.SpinnerService.hide();
      if(result.status){
        this.classData = result.data;
        this.config.classData = {
          itemsPerPage: environment.listing__itemsPerPage,
          currentPage: result.currentPage ? result.currentPage : 1,
          totalItems: result.dataCount,
          id:req.type+'class',
          type:req.type,
          list:'class'
        };
      }else{
        this.classData  = [];
      }     
  });
  }
  getClassData(data){
    const webToken = localStorage.getItem('webtoken');
    var req = {
      currentPage : data.currentPage ? data.currentPage :1,
      itemsPerPage : environment.listing__itemsPerPage,
    }
    this.userApi.studentOneonone(req,webToken).subscribe((response) => {
      if (response.status) {
        this.requestedDataList = response.data;
        this.configOneonone = {
          itemsPerPage: environment.listing__itemsPerPage,
          currentPage: response.currentPage,
          totalItems: response.dataCount,
          list:'buddiclass',
          id:'oneonone'
        };
      } else {
        this.requestedDataList = [];
      }
    });
  }

  receivePageNumber(data){
    switch(data.list){
      case 'solution':{
        this.getStudyLibraryData(data);
        break;
      }
      case 'question':{
        this.getStudyLibraryData(data);
        break;
      }
      case 'class':{
        this.getAddedClass(data);
        break;
      }
      case 'buddiclass':{
        this.callBuddi(data);
        break;
      }
      case 'song':{
        this.getStudyLibraryData(data);
        break;
      }
      case 'ebook':{
        this.getStudyLibraryData(data);
        break;
      }
      case 'video':{
        this.getStudyLibraryData(data);
        break;
      }
      case 'videoyoutube':{
        this.getStudyLibraryData(data);
        break;
      }
    }    
  }
  receivePageNumberClass(data){
    switch(data.list){     
      case 'oneonone':{
        this.getClassData(data);
        break;
      }
    }
    
  }
  sort(property) {
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.paymentDetails.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };

  SortDataPast(data,isOneonone) {
    var sortDate = data.sort(function (a, b) { return new Date(a.ScheduleDate).getTime() - new Date(b.ScheduleDate).getTime() });
    const now = Date.now();
    if(isOneonone){
       const futureDates = sortDate.filter(date => {
      return date.ScheduleDate && new Date(date.ScheduleDate).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0) || date.isrecorded;
    });
    return futureDates;
    }else{
     const futureDates = sortDate.filter(date => {
      return date.ScheduleDate && new Date(date.ScheduleDate).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0);
    });
    return futureDates;
    }
   
  }
  fileChange(event) {
    let fileList: FileList = event.target.files;
    console.log(fileList);
    this.uploadform.patchValue({ upload_name: fileList[0] });
    this.selectedFileName = fileList[0].name;
    console.log(this.selectedFileName);
    this.uploadform.patchValue({ fileupload: this.selectedFileName });
  }

  userDataUpload() {
    const data = this.uploadform.value;
    console.log(data);
    this.formdata = new FormData();
    this.formdata.append("excel_file", data.upload_name, data.upload_name.name);
    const webToken = localStorage.getItem('webtoken');
    this.userApi.userDataUpload(this.formdata, webToken).subscribe((response) => {
      console.log(response);
      this.dupeArray = true;
      this.dupeEmailArray = response.dupeEmailArray;
      this.dupePhoneArray = response.dupePhoneArray;
      this.toastr.successToastr('Users Created Susccessfully');
    })
  }

  viewTeachers() {
    this.teacherTable = true;
    this.studentTable = false;
  }

  viewStudents() {
    this.studentTable = true;
    this.teacherTable = false;

  }
  nextTo(event) {
    $('a.active').parent().next().children()[0].click();
  }
 
  get f() { return this.registerForm.controls; }
  get j() { return this.classessForm.controls; }
  get k() { return this.coursesForm.controls; }
  get g() { return this.LibraryForm.controls; }

  getCountries() {
    this.userApi.allCountries().
      subscribe(
        data2 => {
          this.countryInfo = data2.Countries;
          console.log('Data:', this.countryInfo);
        },
        err => console.log(err),
        () => console.log('complete')
      )
  }
  backto() {
    this.location.back();
  }
  onChangeCountry(countryValue) {
    this.stateInfo = this.countryInfo[countryValue].States;
    this.cityInfo = this.stateInfo[0].Cities;
    console.log(this.stateInfo);
  }

  onChangeState(stateValue) {
    this.cityInfo = this.stateInfo[stateValue].Cities;
    console.log(this.cityInfo);
  }
  userdetails() {
    const webToken = localStorage.getItem('webtoken');
    if (webToken != null) {
      this.userApi.userName_DisplayStudent(webToken).subscribe((result) => {
        console.log('resultofstudentProfile',result)
        var dataResult = result.data.studentDetails;
        this.registerForm.patchValue({
          id :dataResult._id,
          firstname : dataResult.firstName,
          lastname : dataResult.lastName,
          email : dataResult.email,
          mobileNumber1 : dataResult.mobileNumber1,
          dob : dataResult.dob,
          gender : dataResult.gender,
          street : dataResult.street,
          area : dataResult.area == undefined ? '' : dataResult.area,
          city : dataResult.city == undefined ? '' : dataResult.city,
          state : dataResult.state == undefined ? '' : dataResult.state,
          postalCode :  dataResult.postalCode == undefined ? '' : dataResult.postalCode,
          currentCity : dataResult.currentCity == undefined ? '' : dataResult.currentCity,
          preferences : dataResult.preferences == undefined ? '' : dataResult.preferences,
          qualification : dataResult.qualification == undefined ? '' : dataResult.qualification,
          currentEducation : dataResult.currentEducation == undefined ? '' : dataResult.currentEducation,
          profession : dataResult.profession == undefined ? '' : dataResult.profession,
        });       
        this.selectedFileName = dataResult.userPhoto.split('/')[dataResult.userPhoto.split('/').length - 1].length > 29 ? dataResult.userPhoto.split('/')[dataResult.userPhoto.split('/').length - 1].slice(0, 30) + '...' : dataResult.userPhoto.split('/')[dataResult.userPhoto.split('/').length - 1];
        this.userPhoto = dataResult.userPhoto
        this.name = dataResult.profileName;
        this.id = dataResult._id;
        const userId = { id: this.id }
        // this.userApi.userPricingData_Get(userId, webToken).subscribe((response) => {
        //   console.log(response)
        //   let assignAddData: any = {};
        //   assignAddData.facebook = response.data.facebookURL == 'undefined' ? '' : response.data.facebookURL;
        //   assignAddData.twitter = response.data.twitterURL == 'undefined' ? '' : response.data.twitterURL;
        //   assignAddData.instagram = response.data.instagramURL == 'undefined' ? '' : response.data.instagramURL;
        //   assignAddData.linkedIn = response.data.linkedinURL == 'undefined' ? '' : response.data.linkedinURL;
        //   assignAddData.youtube = response.data.youtubeURL == 'undefined' ? '' : response.data.youtubeURL;
        //   assignAddData.bio = response.data.userBio == 'undefined' ? '' : response.data.userBio;
        //   this.registerForm.patchValue(assignAddData);
        //   console.log(assignAddData);
        // });

      }, (err) => {
        console.log(err);
      });
    }
  }



  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid && !this.registerForm.value.id) {
      return;
    }
    this.showModal = false;
    console.log('profile update value', this.registerForm.value);
    const data = this.registerForm.value;
    console.log('Form Data', data)

    if (data.upload_name != null) {
      this.formData = new FormData();
      this.formData.append("id", data.id);
      this.formData.append("firstName", data.firstname);
      this.formData.append("lastName", data.lastname);
      this.formData.append("profileName", data.firstname);
      this.formData.append("mobileNumber1", data.mobileNumber1);
      this.formData.append("dob", data.dob);
      this.formData.append("gender", data.gender);
      this.formData.append("upload_name", data.upload_name, data.upload_name.name);
      this.formData.append("street", data.street);
      this.formData.append("area", data.area);
      this.formData.append("city", data.city);
      this.formData.append("state", data.state);
      this.formData.append("postalCode", data.postalCode);
      this.formData.append("currentCity", data.currentCity);
      this.formData.append("qualification", data.qualification);
      this.formData.append("preferences", data.preferences);
      this.formData.append("currentEducation", data.currentEducation);
      this.formData.append("profession", data.profession);
      this.formData.append("linkedinURL", data.linkedIn);
      this.formData.append("instagramURL", data.instagram);
      this.formData.append("facebookURL", data.facebook);
      this.formData.append("twitterURL", data.twitter);
      this.formData.append("userBio", data.bio);
      this.formData.append("youtubeURL", data.youtube);
    }

    if (data.upload_name == null) {
      this.formData = {
        firstName: data.firstname,
        lastName: data.lastname,
        profileName: data.firstname,
        mobileNumber1: data.mobileNumber1,
        dob: data.dob,
        gender: data.gender,
        street: data.street,
        area: data.area,
        city: data.city,
        state: data.state,
        postalCode: data.postalCode,
        currentCity: data.currentCity,
        qualification: data.qualification,
        preferences: data.preferences,
        currentEducation: data.currentEducation,
        profession: data.profession,
        linkedinURL: data.linkedIn,
        instagramURL: data.instagram,
        facebookURL: data.facebook,
        twitterURL: data.twitter,
        userBio: data.bio,
        youtubeURL: data.youtube
      }
    }
    console.log(this.formData);
    const webToken = localStorage.getItem('webtoken');
    this.userApi.userData_UpdateStudent(this.formData, webToken).subscribe((response) => {
      if (response.status) {
        // alert('User Profile Update Successfully')      
        this.location.back();
        this.toastr.successToastr('User Profile Update Successfully');
        this.ngOnInit();
      }
    }, (err) => {
      console.log(err);
    });


  }

  detailsMeeting(data, pagename) {
    if(data.classGroup){
     this.router.navigate(['/automation' + "/" + data.meetingID], { state: data });
    }else{
      data.pagename = pagename;
      this.router.navigate(['/student/meetingDetails/' + "/" + data.meetingID], { state: data });
    }   
  }
  detailsAccept(data, pagename) {
    if(data.classGroup){
       this.router.navigate(['/automation' + "/" + data.meetingID], { state: data });
    }else{
      data.pagename = pagename;
      this.router.navigate(['/student/one2oneAccept/' + "/" + data.meetingID], { state: data });
    }    
  }
  repayment(data){
   var a =this.coursecategorylist.find(function(val,key){
    if(data.productDetails.productName.split(' ')[1] == val.categoryName){
     return val;
    }
   })
   this.router.navigateByUrl('/' + a.categoryName+'/'+a._id);
  }
  detailsRequest(data, pagename) {
    if(data.classGroup){
      this.router.navigate(['/automation' + "/" + data.meetingID], { state: data });
    }else{
      data.pagename = pagename;
      this.router.navigate(['/student/viewRequest/' + "/" + data.meetingID], { state: data });
    }   
  }
  coursedetails(data) {
    this.router.navigateByUrl('courses/' + data.categoryID.categoryName + "/" + data._id);
  }

  Examdetails(data) {
    console.log(data);
    this.router.navigateByUrl('exams/' + data.categoryID.categoryName + "/" + data._id);
  }


  ebookdetails(data) {
    this.router.navigate(['/ebook-details/' + data._id]);
  }
  videodetails(data) {
    this.router.navigate(['/video-details/' + data._id]);
  }
  audiodetails(data) {
    this.router.navigate(['/audio-details/' + data._id]);
  }
  buddiGroupRedirect(data){
     this.router.navigateByUrl('buddigroup/'+data.parentMeetingID);
  } 
  buddiGroupRedirectParent(data){
     this.router.navigateByUrl('buddigroup/'+data._id);
  } 
  accountRestart(){
    const webToken = localStorage.getItem('webtoken');
     this.SpinnerService.show()
    this.userApi.getMyPaymentDetails(webToken).subscribe((result) => {
       this.SpinnerService.hide()
    if(result.status){
      this.paymentDetails = result.data;
      this.sort('status')
    }
    });
  }

  getmyEbook() {
    var req = {
      profileType: this.isTeacher ? 'teacher' : 'student',
      groupName: this.groupName
    }
    this.api.getEbookByAllPrivacyType(req, localStorage.getItem('webtoken')).subscribe((result) => {
      if (result.status) {
        this.ebooklistbyId = result.data
      }
    }, (err) => {
      console.log(err);
    });
  }
  getmyaudio() {
    var req = {
      profileType: this.isTeacher ? 'teacher' : 'student',
      groupName: this.groupName
    }
    this.api.getAudioByAllPrivacyType(req, localStorage.getItem('webtoken')).subscribe((result) => {
      if (result.status) {
        this.songsList = result.data
      }
    }, (err) => {
      console.log(err);
    });
  }

  albumList(data, header, pagename) {
    data.itemname = header;
    data.pagename = pagename;
    var jsonStr = JSON.stringify(data);
    localStorage.setItem('videoDetails', jsonStr);
    this.router.navigate(['/all-details/' + header + '/' + data.typename], { state: data });
  }
  removevideo(id) {
    var video_id = id;
    var passdata = { video_id: "" };
    passdata.video_id = video_id;
    this.api.delMultipleVideo(passdata).subscribe((response) => {
      console.log(response);
      if (response.status) {
        this.toastr.successToastr(response.message);
        this.ngOnInit();
      } else {
        this.toastr.errorToastr(response.message);
      }

    });
  }
  changeRole(type) {
    if (type == 'School') {
      this.isSchool = true
    } else {
      this.isSchool = false
    }
  }
  changeSecurityType(type) {
    if (type == 'School') {
      this.isSchool = true
    } else {
      this.isSchool = false
    }
  }

  changeRoleCourse(type) {
    if (type == 'School') {
      this.isSchoolcourse = true
    } else {
      this.isSchoolcourse = false
    }
  }

  changeRoleExam(type) {
    if (type == 'School') {
      this.isSchoolexam = true
    } else {
      this.isSchoolexam = false
    }
  }

  quicksightembed() {
    const webToken = localStorage.getItem('webtoken');
    this.userApi.getquicksightembed(webToken).subscribe((response) => {
      if (response.status) {
        document.getElementById('dashboardContainer1').hidden = false;
        document.getElementById('dashboardContainerempty').hidden = true;
        var containerDiv = document.getElementById("dashboardContainer1");
        var options = {
          url: response.data.EmbedUrl,
          container: containerDiv,
          scrolling: "yes",
          height: "700px",
          width: "100%"
        };
  
        this.dashboard = QuickSightEmbedding.embedDashboard(options);
      }
      else {
        document.getElementById('dashboardContainerempty').hidden = false;
        document.getElementById('dashboardContainer1').hidden = true;       
      }
    }, (err) => {
      console.log(err);
    });   
    }
fetchrecordvideo(id,type) {
    var req = {
      [type]: id
    }
    this.SpinnerService.show()
    this.api.fetchrecordvideo(req).subscribe((result) => {
      this.SpinnerService.hide()
      if (result.status) {
        window.open(result.data[0].recordurl, '_blank');
      }
    }, (err) => {
      this.toastr.errorToastr(err.message);
    });

  }

  gettestsdata() {
    const webToken = localStorage.getItem('webtoken');
    this.userApi.getallstudenttest(webToken).subscribe((result) => {
      if(result.status) {
        this.testdata = result.data.filter(item => item.testIsActive === false && item.status !== "Review");
        this.pendingtestdata = result.data.filter(item => item.testIsActive === true);
        this.reviewtestdata = result.data.filter(item => item.status === "Review" && item.reviewCompletedDate !== undefined);
      }
      else {
        this.toastr.errorToastr(result.message);
      }
     
      
    }, (err) => {
      console.log(err);
    });
  }
  testdetails(id) {    
    this.router.navigate(['/student-test-details/' + id]);
  }
  pendingtestdetails(id) {
    this.router.navigateByUrl('buddi-test/' + id);
  }
  backtoExplorePage(){
    this.router.navigate(['/explore/'+this.coursecategorylist[0]._id]);
  }
  changeMeetingList(event){
    var req={
      type:event.target.checked ? 'Group':'Public'
    }
    this.getAddedClass(req)
  }
  publicOrGroup(event){
    var req={
      type:event.target.checked ? 'Group':'Public',
      list:'song'
    }
    this.getStudyLibraryData(req)
    var reqVideo={
      type:event.target.checked ? 'Group':'Public',
      list:'video'
    }
    this.getStudyLibraryData(reqVideo)
  }
  publicOrGroupEbook(event){
    var req={
      type:event.target.checked ? 'Group':'Public',
      list:'ebook'
    }
    var reqSolution={
      type:event.target.checked ? 'Group':'Public',
      list:'solution'
    }
    var reqQuestion={
      type:event.target.checked ? 'Group':'Public',
      list:'question'
    }
    this.getStudyLibraryData(req);
    this.getStudyLibraryData(reqSolution);
    this.getStudyLibraryData(reqQuestion)
  }
}

