import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Options } from 'ng5-slider';
import { MediaService } from '../../../../shared/media.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ApiService } from '../../../../shared/api.service';
import { NgxSpinnerService } from "ngx-spinner";
import {NgSelectModule, NgOption} from '@ng-select/ng-select';

@Component({
  selector: 'app-upload-class-course',
  templateUrl: './upload-class-course.component.html',
  styleUrls: ['./upload-class-course.component.scss']
})
export class UploadClassCourseComponent implements OnInit {

  createForm: FormGroup;
  selectedFileName: string = 'Choose File';
  submitted = false;
  formData;  

  constructor(private activatedRoute :ActivatedRoute,public toastr: ToastrManager,private SpinnerService: NgxSpinnerService,private rest : ApiService,private location:Location,private api: MediaService,private formBuilder: FormBuilder,private router: Router) { }

  ngOnInit(): void {
    this.createForm = this.formBuilder.group({    
      fileupload: ['', Validators.required],
      upload_name: [null]

    });
  }
  get f() {
    return this.createForm.controls;
  }

  fileChange(event) {
    let fileList: FileList = event.target.files;
   // const file = event.target.files[0];
   // console.log(fileList);
   console.log(fileList[0]);
    this.createForm.patchValue({ upload_name: fileList[0] });
    this.selectedFileName = fileList[0].name;
    this.createForm.patchValue({ fileupload: this.selectedFileName });
   // this.testForm.get('fileupload').setValue(file);
  }

  onSubmit(status) {

    this.submitted = true;
    let data = this.createForm.value;
    if (!this.createForm.valid) {
      const invalid = [];
    const controls = this.createForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
       this.toastr.warningToastr("Excel file required")
       return;
      }
    }
  }


   this.formData = new FormData();
   this.formData.append("excel_file", data.upload_name);
   var token = localStorage.getItem('webtoken');
   for (var pair of this.formData.entries()) {
    console.log(pair[0]+ ', ' + pair[1]); 
}
   this.api.uploadCourseTemplate(this.formData,token).subscribe((response) => {
    if(response.status){
     this.toastr.infoToastr(response.message);
     setTimeout(() => {
       window.location.href = '/teacher/mydashboard'
   }, 500);
    
    }
    else {
     this.toastr.errorToastr(response.message);
    }
  });  
  }

}
