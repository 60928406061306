import { Component, OnInit } from "@angular/core";
import {
    FormBuilder,
    FormGroup,
    FormArray,
    FormControl,
    Validators,
    ValidatorFn,
} from "@angular/forms";
import { Options } from "ng5-slider";
import { MediaService } from "../../../../shared/media.service";
import { ToastrManager } from "ng6-toastr-notifications";
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { ApiService } from "../../../../shared/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { NgSelectModule, NgOption } from "@ng-select/ng-select";
import * as moment from 'moment';

@Component({
    selector: "app-student-request-live-class",
    templateUrl: "./student-request-live-class.component.html",
    styleUrls: ["./student-request-live-class.component.scss"],
})
export class StudentRequestLiveClassComponent implements OnInit {
    public liveDetails: any = {};
    public isTeacher: boolean = false;
    public isStudent: boolean = false;
    public dateTime: any = [];
    selectedDateTime: any = null;
    public min = moment(new Date()).add(30, 'm').toDate();
    constructor(
        private activatedRoute: ActivatedRoute,
        public toastr: ToastrManager,
        private SpinnerService: NgxSpinnerService,
        private rest: ApiService,
        private location: Location,
        private api: MediaService,
        private formBuilder: FormBuilder,
        private router: Router
    ) {}

    ngOnInit() {
        // this.getDatas();
        window.scrollTo(0, 0);
        const webToken = localStorage.getItem("webtoken");
        var liveClassID = this.activatedRoute.snapshot.params.id;
        this.isTeacher =
            localStorage.getItem("profileType").toLowerCase() == "teacher";
        this.isStudent =
            localStorage.getItem("profileType").toLowerCase() == "student";
        if(!liveClassID){
            this.router.navigate(['/sidenav'])
        }
        var request = {}
        request['classID'] = liveClassID;
        this.rest
        .getLiveClassDetails(request, webToken ? webToken : null)
        .subscribe((result) => {
          console.log(result)
            if (result['status']) {
              this.liveDetails = result['data']
            }
      });
    }
    goBack() {
        this.location.back();
    }
    dateChange(event){
        console.log(new Date(event))
        this.selectedDateTime = new Date(event);
    }
    onSubmit(){
        console.log(document.getElementById('classDateTime'))
    }

    joinLiveClass(fromLocation) {
        //this.SpinnerService.show();
        if(fromLocation == 'selectDateTime' && !this.selectedDateTime){
            this.toastr.errorToastr("Please Select a Date");
            return;
        }
        var checkBoxValue = document.getElementById("payInPersonCheckbox") as HTMLInputElement;
        var profileName = localStorage.getItem('profileName');
        profileName = profileName.replace(/ /gi, "+");
        var meetingId = profileName+"-"+Math.round(Math.random() * 1000000000)
        const webToken = localStorage.getItem("webtoken");
        var request = {}
        request['meetingID'] = meetingId;
        request['classID'] = this.liveDetails._id;
        if(this.selectedDateTime){
            request['startDateTime'] = this.selectedDateTime;
        }
        else{
            request['startDateTime'] = this.min;
        }
        console.log("Test")
        request['payInPerson'] = checkBoxValue.checked;
        this.rest.createLiveClass(request, webToken).subscribe((result) => {
          console.log(result)
          if(result['status']){
            this.SpinnerService.hide();
            this.toastr.successToastr("Class enrolled succesfully to upcoming classes")
            this.router.navigate(['/sidenav/upcoming/live-class'])
          }
          else{
            this.SpinnerService.hide();
            this.toastr.errorToastr("Something went wrong. Please try again later")
            console.log(result.error)
          }
        })
      }

      
}
