<div class="container">
    <div class="section-title">
        <h2>buddi Unique Model</h2>
        </div>
    <div class="row">
        <div class="col-md-12 unique">
    
    <img src="../../../../assets/img/buddi unique model.jpeg" alt="buddi unique model">
        </div>
    </div>
    </div>
