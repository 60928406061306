<app-header-style-one></app-header-style-one>


<section class="courses-area ptb-100">
    <!-- <div class="container">
        <div class="courses-topbar">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-4">
                    <div class="topbar-result-count">
                        <p>Showing 1 – 6 of 54</p>
                    </div>
                </div>

                <div class="col-lg-8 col-md-8">
                    <div class="topbar-ordering-and-search">
                        <div class="row align-items-center">
                            <div class="col-lg-3 col-md-5 offset-lg-4 offset-md-1 col-sm-6">
                                <div class="topbar-ordering">
                                    <select>
                                        <option>Sort by popularity</option>
                                        <option>Sort by latest</option>
                                        <option>Default sorting</option>
                                        <option>Sort by rating</option>
                                        <option>Sort by new</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-lg-5 col-md-6 col-sm-6">
                                <a   routerLink="/create-course" class="default-btn"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Create Course</span><i class="bx bx-paper-plane icon-arrow after"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    
    
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-3 col-md-6" >
            <!-- <div class="col-lg-3 col-md-6" *ngFor="let slide of courseData "> -->
                <div class="single-courses-box without-box-shadow mb-30">
                    <div class="courses-image">
                        <a   class="d-block"><img src='assets/img/courses/courses1.jpg' alt="image"></a>
                        <div class="courses-tag">
                            <a  class="d-block"></a>
                        </div>
                    </div>

                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image">
                            <!-- <span>{{slide.startDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</span> -->
                        </div>
                        <h3><a routerLink="/single-courses" class="d-inline-block"></a></h3>
                        <!-- <p style="color: black;font-weight: 600;"> Language : {{slide.languageID ? slide.languageID.languageName : null }}</p> -->
                    </div>                
                </div>
            </div>
          
          
           <!-- <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="pagination-area text-center">
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/courses-4-columns-style-3" class="page-numbers">2</a>
                    <a routerLink="/courses-4-columns-style-3" class="page-numbers">3</a>
                    <a routerLink="/courses-4-columns-style-3" class="page-numbers">4</a>
                    <a routerLink="/courses-4-columns-style-3" class="page-numbers">5</a>
                    <a routerLink="/courses-4-columns-style-3" class="next page-numbers"><i class='bx bx-chevron-right'></i></a>
                </div>
            </div> -->
        </div>
    </div>
</section>