<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a >Home</a></li>
                <li><a href="courses-2-columns-style-1.html">Classes</a></li>
                <li>{{livedetails.name}} - {{livedetails.ScheduleDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</li>
            </ul>
            <h2>Classes</h2>
        </div>
    </div>
</div>

<section class="courses-details-area pt-100 pb-70">
    <div class="container">
        <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-lg-7">
                    <div class="courses-title">
                        <h2>{{livedetails.name}} 
                            <!-- - {{livedetails.ScheduleDate |  date :"MMM dd,yyyy, hh:mm aaa"}} -->
                        </h2>
                       
                    </div>

                    <div class="courses-meta">
                        <ul>
                            <li>
                                <i class='bx bx-folder-open'></i>
                                <span>Category</span>
                                <a>{{livedetails.categoryID.categoryName}}</a>
                            </li>
                            <!-- <li>
                                <i class='bx bx-calendar'></i>
                                <span>Last Updated</span>
                                <a routerLink="/single-courses">{{livedetails.ScheduleDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</a>
                            </li> -->
                        </ul>
                    </div>
                </div>

                <div class="col-lg-5">
                    <div class="courses-price">
                        <div class="courses-review">
                        </div>
                        <div class="price"></div>
                        <span *ngIf="viewRequest" class="mt-0 ml-2">
                            <span class="color: var(--mainColor)">Your Request Still Pending</span>
                         </span>
                        <button  class="default-btn" (click)="backto()">Back</button>
                        <span *ngIf="!viewRequest">
                            <a *ngIf="meetingsactionText == 'Start'" href="javascript:void(0)" (click)="meetingsaction(meetingIDpopup,'start',livedetails)" class="default-btn">Start Meeting</a>
                            <a *ngIf="meetingsactionText == 'Join'" href="javascript:void(0)" (click)="meetingsaction(meetingIDpopup,'join',livedetails)" class="default-btn"><i class="fas fa-plus mr-1"></i> Join</a>
                            <a *ngIf="meetingsactionText == 'Login'" href="javascript:void(0)" (click)="meetingsaction(meetingIDpopup,'login',livedetails)" class="default-btn">Login</a>
                            <a *ngIf="meetingsactionText == 'Start'" title="Click to Copy the invite url"  (click)="copyMessage()" href="javascript:void(0)" class="default-btn"><i class="far fa-copy mr-1"></i> Invite</a>
                            <a *ngIf="meetingsactionText == 'Start'" title="Delete Meeting"  (click)="removeMeeting(livedetails._id)" href="javascript:void(0)" class="default-btn"><i class="fa fa-trash mr-1"></i> Delete</a>
                        </span>
                      
                        <!-- <a routerLink="/single-courses" class="default-btn"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Buy Course</span><i class="default-btn"></i></a> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-8">
                <div class="courses-details-image text-center">
                    <img [src]="livedetails.image !='' ? livedetails.image : 'assets/img/courses/courses1.jpg'" alt="image">
                </div>
                <div class="mt-5">
                    <label>
                        Description
                    </label>
                    <div class="col-lg-12">
                            {{livedetails.meetingdescription =='' ? '-' : livedetails.meetingdescription}}
                    </div>
                </div>
               
            </div>

            <div class="col-lg-4">
                <div class="courses-sidebar-information">
                    <ul>
                        <li>
                            <span><i class='bx bx-group'></i> Class ID:</span>
                            {{livedetails.meetingID}}
                        </li>
                        <li>
                            <span><i class='bx bx-time'></i> Class Name:</span>
                            {{livedetails.name}}
                        </li>
                        <li *ngIf="livedetails.classType !='One-on-One'">
                            <span><i class='bx bx-time'></i> Privacy Type:</span>
                            {{livedetails.meetingType =='Group' ? 'School':livedetails.meetingType}}
                        </li>
                        <li *ngIf="livedetails.meetingType =='Group'">
                            <span><i class='bx bx-time'></i> School Name:</span>
                            {{livedetails.groupname}}
                        </li>
                        <li *ngIf="livedetails.classType =='One-on-One'">
                            <span><i class='bx bx-time'></i> Class Type:</span>
                            One-on-One
                        </li>
                        <!-- <li>
                            <span><i class='bx bx-time'></i> Group Name:</span>
                            {{livedetails.groupname}}
                        </li> -->
                        <li>
                            <span><i class='bx bx-tachometer'></i> Class Datetime:</span>
                            {{livedetails.ScheduleDate | date :"MMM dd,yyyy hh:mm aaa"}}
                        </li>
                        <li>
                            <span><i class='bx bx-support'></i> Frequency:</span>
                            {{livedetails.occurance =='' ? '-' : livedetails.occurance}}
                        </li>
                        
                        <li>
                            <span><i class='bx bx-tachometer'></i>Duration:</span>
                            {{livedetails.Duration ? livedetails.Duration +'Min' : '-'}}
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i>Grade:</span>
                            {{livedetails.gradeID ? livedetails.gradeID.gradeName: ''}}
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i>Board:</span>
                            {{livedetails.boardID ? livedetails.boardID.boardName: ''}}
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i>Subject:</span>
                            {{livedetails.subjectID ? livedetails.subjectID.subjectName: ''}}
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i>Language:</span>
                            {{livedetails.languageID ? livedetails.languageID.languageName: ''}}
                        </li>
                        <!-- <li>
                            <span><i class='bx bxs-badge-check'></i> City:</span>
                            {{livedetails.city =='' ? '-' : livedetails.city}}
                        </li> -->
                        
                       
                        <!-- <li>
                            <span><i class='bx bxs-institution'></i> Name:</span>
                            <a href="#" target="_blank" class="d-inline-block">{{livedetails.fullName}}</a>
                        </li>
                        <li>
                            <span><i class='bx bx-certification'></i> Language:</span>
                            {{livedetails.Languages ==''? '-' : livedetails.Languages}}
                        </li> -->
                        <!-- <li>
                            <span><i class='bx bx-atom'></i> Class Type:</span>
                            {{livedetails.meetingType}}
                        </li> -->
                        
                       
                       
                        <!-- <li>
                            <span><i class='bx bx-certification'></i> Tag:</span>
                            {{tag =='' ? '-' : tag}}
                        </li>
                        <li>
                            <span><i class='bx bx-certification'></i> Class Price:</span>
                            {{livedetails.meetingPriceType  =='' ? '-' : livedetails.meetingPriceType}}
                        </li>
                        <li>
                            <span><i class='bx bx-certification'></i>  Amount(INR):</span>
                            {{livedetails.PayableAmount}}
                        </li> -->
                    </ul>
                </div>

            </div>
        </div>
    </div>
</section>