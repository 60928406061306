import { Component, OnInit ,Output,EventEmitter} from '@angular/core';
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../shared/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import * as moment from 'moment';
import { Location } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { MediaService } from '../../../shared/media.service';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import { ProductexpiredPipe } from '../../custom-pipes/productexpired.pipe';
import { EmptyObjectPipe } from '../../custom-pipes/empty-object.pipe';
import { NgxSpinnerService } from "ngx-spinner";
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  providers: [ProductexpiredPipe, EmptyObjectPipe]
})
export class SidenavComponent implements OnInit {
  public data: any = [];
public profilename : any;
  dashboard: FormGroup;
  public dashboardData = [];
  @Output() dropdownData = new EventEmitter();
  public emitData:any = [];
  constructor(private activatedRoute: ActivatedRoute, private SpinnerService: NgxSpinnerService, private productexpired: ProductexpiredPipe, private api: MediaService, private router: Router, private userApi: ApiService, private location: Location, private formBuilder: FormBuilder, public toastr: ToastrManager, private http: HttpClient) { }

  ngOnInit(): void {
    this.getStudentDetails();
    this.profilename = localStorage.getItem('profileName')
    this.dashboard = this.formBuilder.group({
      categoryID: [''],
      languageID: [''],
      boardID: [''],
      subjectID: [''],
      gradeID: [''],
    });
  }
  get f() { return this.dashboard.controls; }
  getStudentDetails() {
    const webToken = localStorage.getItem('webtoken');
    if (webToken != null) {
      this.userApi.userName_DisplayStudent(webToken).subscribe((result) => {
        if (result.status) {
          this.data = result.data;
          //  this.data.studentDetails.board = null;
          var stateParam = {
            pageName: 'dashboard'
          }
          // || Object.keys(this.data.studentDetails.language).length == 0
          // if (Object.keys(this.data.studentDetails.board).length == 0  || Object.keys(this.data.studentDetails.subjects).length == 0 || Object.keys(this.data.studentDetails.grade).length == 0) {
          //   this.router.navigate(['/student/edit-profile'], { state: stateParam });

          // }

          console.log(this.data, 'this.data');
          this.dashboard.patchValue({
            categoryID: this.data.categoryList[0]._id,
            languageID: this.data.languages[0],
            boardID: this.data.studentDetails.board._id,
            gradeID: this.data.studentDetails.grade._id,
            subjectID: this.data.studentDetails.subjects._id,
          })
           var req= {
              "categoryID": "5f12a2efbf9506355c8adb5c",
              "gradeID": "5f6864c40a19fd6e912fbf9f",
              "boardID": "5f43aa8c0c46160c254a05d3",
              "subjectID": "5f4d0b937f22ab6769601c97",
              "languageID": "5f43aa990c46160c254a05d4"
         }
         this.getChapterDetails(req)
        }

      });
    }
  }

  changeCategory(event) {
    this.dashboard.patchValue({
      categoryID: this.data.categoryList[event.target.selectedIndex]._id,     
    })
    console.log(this.dashboard.value);
    this.emitData = {
      categoryID: this.dashboard.value.categoryID,
      languageID: this.dashboard.value.languageID._id,
      boardID: this.dashboard.value.boardID,
      gradeID: this.dashboard.value.gradeID,
      subjectID: this.dashboard.value.subjectID,
    }
    // this.userApi.dropdownData =  this.emitData;

    this.getChapterDetails(this.emitData)
    // this.dropdownData.emit(this.emitData);
  }




  getChapterDetails(req){
  const webToken = localStorage.getItem('webtoken');
  if (webToken != null) {
     
        this.userApi.getChapterDetails(req,webToken).subscribe((response) => {
          // if(response.status){
            console.log('responseGetChapter',response)
            var responseData= response;
            this.userApi.customChangeDetector.next(responseData);
          // }
        });
     }
 }

 getAllDetails(){
  
  // this.userApi.getChapterDetails().subscribe( this.getChapterDetails = response)
              
 }

  receivedData(event){
    // this.dropdownData.emit(this.emitData);
  }
  logout(){
    localStorage.setItem('isLoggedin','false');
     window.location.href = localStorage.getItem('profileType').toLowerCase()+'/login';
  }
 
}
