

<div class="error-404-area">
    <div class="container">
        <div class="notfound">
            <div class="notfound-bg">
                <div>

                </div>
                <div>
                </div>
            </div>

            <div >
                 <h5 class="text-center pt-3">You're not connected to the internet</h5>
                 <p>
                 	The Website can't open because you're not connected to the internet. Check your internet connection and try again.
                 </p>
                <br>
               
            </div>

        </div>
    </div>
</div>
