<div *ngIf="!isStudent">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>
<div *ngIf="isStudent">
    <app-header-style-one></app-header-style-one>
</div>
<div class="container vty" >
	<h2>Buddi Coin Wallet</h2>
	<button ><i class="fas fa-long-arrow-alt-left mr-1"></i>Back</button></div>
<div class="container" style="margin-bottom: 1%;">
		<div class="card-deck">
			<div class="col-lg-4 col-md-6">
				<h4 class="text-center">Coming Soon!</h4>
			</div>
		</div>
</div>