<header class="header-area p-relative">
    <div class="top-header top-header-style-four">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-12 col-md-12">   
                    <div class="row">
                    <div class="col-lg-2">
                    </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul class="top-header-login-register">
                        
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="navbar-area navbar-style-three ">
        <div class="ednuv-responsive-nav">
            <div class="container">
                <div class="ednuv-responsive-menu">
                    <div class="logo">
                        <a routerLink="/Home">
                            <img src="assets/img/black-logo.png" class="logoImg" alt="logo">
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="ednuv-nav">
            <div class="container">
              
                <nav class="navbar navbar-expand-md navbar-light">
                    <a routerLink="/Home">
                        <img src="assets/img/black-logo.png" class="logo" alt="logo">
                    </a>
                   <div class="collapse navbar-collapse mean-menu">
                       
                        <ul class="navbar-nav col-md-12 customNavbar">
          
                            <li class=" nav-item col-xs-1">
                               <a routerLink="/Home" class="nav-link">About Us</a>
                            </li>
                            <li class=" nav-item col-xs-1" style="display: flex;align-items: center;" *ngIf="isLogin=='true'">
                                <a routerLink="/sidenav" class="nav-link">Dashboard</a><i class="bi bi-person-circle"></i>
                             </li>
                 
                            <li class=" nav-item col-xs-1" *ngIf="isLogin=='false' || isLogin==null">
                                <a routerLink="/student/login" class="nav-link">Login</a>
                            </li>
                            <li class=" nav-item col-xs-2"  (click)="logout()"  *ngIf="isLogin=='true'">
                                <div style="display: flex;align-items: center;">
                                <a class="nav-link">Logout</a>
                                <i class="fa fa-sign-out" style="cursor: pointer;"></i>
                            </div>
                            </li>
                        </ul>                  
                    </div>
                </nav>
            </div>
        </div>
    </div>
    
</header>

<div class="container">
    <div class="main-banner-content" >
    <div class="row">
        <div  style="padding-bottom: 10px;">
            <span class="sub-title" style="color: black;">Hi {{userprofdataTeacher.profileName}},</span>
            <h2 style="color: black;" class="buddititle">Welcome to buddi.life</h2>
        </div>
    </div>
</div>
</div>

<div class="container mb-4">
    <div class="row">
                    <div class="col-lg-7 col-md-7 col-sm-7" style="padding-bottom: 10px;">
                        <div *ngIf="profiletype=='teacher'">
                            <a class="nav-link" role="tab">
                                <img alt="image" class="imageStyle shadow"
                                    [src]="userprofdataTeacher.userPhoto =='' ? 'assets/img/blank_avatar.webp':userprofdataTeacher.userPhoto">
                            </a>
                        </div>

                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-5 hovervideo">
                        <h2 style="color: black; " class="buddisub mb-2">What are you looking to do on buddi.life?
                        </h2>
                       <div class="fir"> 
                           <div class="space">
                            <i class="fas fa-user-friends" style="color: rgb(44, 184, 202);"></i><p style="color: black;" routerLink="/create-liveclass" class="sub-title">Create Live Class (1:1)</p> 
                           </div>
                           <div class="space">
                            <i class="fas fa-users-cog" style="color: blue;"></i><p style="color: black;" routerLink="/create-studyhall" class="sub-title">Create Study Hall (Group Class)</p> 
                           </div>
                           <div class="space">
                            <i class="fas fa-users" style="color: rgb(0, 255, 221);"></i><p style="color: black;" routerLink="/create-roundtable" class="sub-title">Create Round Table (Webinar)</p> 
                           </div>
                           <div class="space">
                             <i class="far fa-file-video" style="color: palevioletred;"></i><p style="color: black;" routerLink="/teacher-video-upload" class="sub-title">Create Video Lessons</p> 
                           </div>
                           <div class="space">
                            <i class="fas fa-book-reader"  style="color: #a7a776;padding: 10px 11px;"></i><p style="color: black;" routerLink="/teacher-smartmaterial-upload" class="sub-title">Create Study Material</p> 
                           </div>
                           <div class="space">
                            <i class="fas fa-edit" style="color: green;padding: 10px 11px;"></i><p style="color: black;" routerLink="/teacher-test-upload"class="sub-title">Create Evaluation Tests</p> 
                           </div>
                         </div>
                    </div>
    </div>
</div>

