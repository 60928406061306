<div class="container">
    <app-header-style-one *ngIf="!isTeacher"></app-header-style-one>
    <app-header-style-one-teacher *ngIf="isTeacher"></app-header-style-one-teacher>
    <br />
    <br />  
<div *ngIf="isTeacher == true || isStudent == true" class="container mt-3">
    <h3 style="color: #000000; cursor: pointer" class="col-lg-12">
        Smart Material
    </h3>
    <!-- <h3 (click) = "redirectSmartmaterial()"style="color: var(--mainColor); cursor: pointer"  class="col-lg-12">
        Smart Material
        <span class="more-text" *ngIf="smartMaterialList.length!=0">more</span>
           </h3> -->
           <div class="container">  
            <div class="courses-topbar">
                <div class="row align-items-center">
                    <div class="col-lg-4 col-md-4">
                        <div class="topbar-result-count">
                            <p>
                                Showing {{ currentVideoCount + 1 }} –
                                {{ currentVideoCount + smartMaterialList.length }} of
                                {{ totalVideoCount }}
                            </p>
                        </div>
                    </div>
    
                    <div class="col-lg-8 col-md-8">
                        <div class="topbar-ordering-and-search">
                            <div class="row align-items-center">
                                <div
                                    class="
                                        col-lg-3 col-md-5
                                        offset-lg-4 offset-md-1
                                        col-sm-6
                                    "
                                >
                                    <div class="topbar-ordering">
                                        <select class="nice-select1">
                                            <option>Sort by Popularity</option>
                                            <option>Sort by Latest</option>
                                            <option>Sort By Views</option>
                                            <option>Sort by Rating</option>
                                        </select>
                                    </div>
                                </div>
    
                                <div class="col-lg-5 col-md-6 col-sm-6">
                                    <div class="topbar-search">
                                        <form>
                                            <label
                                                ><i class="bx bx-search"></i
                                            ></label>
                                            <input
                                                type="text"
                                                class="input-search"
                                                placeholder="Search here..."
                                            />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <div class="container-fluid row" style="cursor: pointer">
        <div class="col-lg-4 col-md-6" *ngFor="let slide of smartMaterialList">
            <div class="single-courses-item mb-30 p-4">
                <div class="courses-image">
                    <h5>
                        <a
                            routerLink="javascript:void(0)"
                            (click)="detailsSmartMaterial(slide)"
                            class="d-inline-block"
                            style="height: 60px"
                            >{{ slide.materialName }}</a
                        >
                    </h5>
                </div>

                <div class="courses-content px-0 my-3">
                    <div
                        class="
                            d-flex
                            justify-content-between
                            align-items-center
                        "
                    >
                        <div class="course-author d-flex align-items-center">
                            <img
                                [src]="
                                    slide.teacherID.userPhoto
                                        ? slide.teacherID.userPhoto
                                        : 'assets/img/profilePhotoPlaceholder1.png'
                                "
                                class="shadow"
                                alt="image"
                            />
                            <span>{{
                                slide.teacherID ? slide.teacherID.firstName : ""
                            }}</span>
                        </div>

                        <div *ngIf="slide.rating && slide.rating != 0" class="courses-rating">
                                <div class="review-stars-rated">
                                    <i *ngIf="slide.rating < 1" class='bx bxs-star-half'></i>
                                    <i *ngIf="slide.rating >= 1" class='bx bxs-star'></i>
                                    <i *ngIf="slide.rating >= 1.5 && slide.rating < 2" class='bx bxs-star-half'></i>
                                    <i *ngIf="slide.rating >= 2" class='bx bxs-star'></i>
                                    <i *ngIf="slide.rating >= 2 && slide.rating < 2.5" class='bx bxs-star-half'></i>
                                    <i *ngIf="slide.rating >= 3" class='bx bxs-star'></i>
                                    <i *ngIf="slide.rating >= 3 && slide.rating > 3.5" class='bx bxs-star-half'></i>
                                    <i *ngIf="slide.rating >= 4" class='bx bxs-star'></i>
                                    <i *ngIf="slide.rating >= 4 && slide.rating < 4.5" class='bx bxs-star-half'></i>
                                    <i *ngIf="slide.rating >= 5" class='bx bxs-star'></i>
                                </div>
                                <div class="rating-total">
                                    {{slide.rating}}
                                </div>
                        </div>
                    </div>
                 
                    <div style="overflow: hidden">
                        <p style="float: left">{{ slide.description }}</p>
                    </div>
                    <div style="overflow: hidden">
                        <p style="float: left">
                            {{ slide.languageID.languageName }}
                        </p>
                    </div>

                    <div class="gradeContent">
                        <h4>{{ slide.categoryID.categoryName}}</h4>
                        <!-- <p>{{slide.courseID.courseName}}</p> -->
                        <!-- <p class="text-overflow">{{slide.moduleID.moduleName}}</p> -->
                            <p class="text-overflow">{{slide.topicID.topicName}}</p>
                    </div>                  </div>

                    <div class="courses-box-footer mt-3">
                        <ul>
                            <li class="courses-lesson">
                                <i class='fa fa-rupee-sign'></i>
                                {{slide.price != 0 ? slide.price : 'Free'}}
        
                                <!-- <i class='bx bx-book-open'></i> {{slide.totalClassTime ? slide.totalClassTime : 30}} mins -->
                            </li>
                            <li class="courses-lesson _leeson_2">
                                <i class='bx bx-book-open'></i> Level {{slide.level ? slide.level : 1}}
                            </li>
                            <!-- <li class="courses-lesson"> 
                                {{slide.price != 0 ? slide.price : 'Free'}}
                            </li> -->
                            <!-- <li class="courses-lesson _leeson_2"> <i class='fa fa-rupee-sign'></i>
                                *******
                            </li>
                            <li class="courses-lesson"> <i class='fa fa-rupee-sign'></i>
                                INR 1999.00/buddi
                            </li>
                            <li class="courses-lesson _leeson_2">
                                Rating </li> -->
                        </ul>
                    </div>
            </div>
        </div>
        <div
            style="color: #aba3a3;"
            class="col-lg-12"
            *ngIf="smartMaterialList.length == 0"
        >
            No Smart Materials Available.
        </div>
        <!-- <div class="col-lg-12 col-md-12 col-sm-12" style="margin-bottom: 1cm">
            <div class="pagination-area text-center">
                <a
                    href="javascript:void(0)"
                    (click)="clickPageNumber(1)"
                    *ngIf="currentPageNumber > 2 && totalPageCount > 1"
                    class="page-numbers"
                    >1</a
                >
                <span
                    class="page-numbers"
                    *ngIf="currentPageNumber > 2 && totalPageCount > 1"
                    aria-current="page"
                    >...</span
                >
                <a
                    href="javascript:void(0)"
                    (click)="clickPageNumber(currentPageNumber - 1)"
                    *ngIf="currentPageNumber > 1 && totalPageCount > 1"
                    class="page-numbers"
                    >{{ currentPageNumber - 1 }}</a
                >
                <span
                    class="page-numbers current"
                    *ngIf="totalPageCount != 0"
                    aria-current="page"
                    >{{ currentPageNumber }}</span
                >
                <a
                    href="javascript:void(0)"
                    (click)="clickPageNumber(currentPageNumber + 1)"
                    *ngIf="
                        totalPageCount > 1 &&
                        currentPageNumber + 1 <= totalPageCount
                    "
                    class="page-numbers"
                    >{{ currentPageNumber + 1 }}</a
                >
                <a
                    href="javascript:void(0)"
                    (click)="clickPageNumber(currentPageNumber + 2)"
                    *ngIf="
                        totalPageCount > 1 &&
                        currentPageNumber + 2 <= totalPageCount
                    "
                    class="page-numbers"
                    >{{ currentPageNumber + 2 }}</a
                >
                <a
                    href="javascript:void(0)"
                    (click)="clickPageNumber(currentPageNumber + 3)"
                    *ngIf="
                        totalPageCount > 1 &&
                        currentPageNumber + 3 <= totalPageCount
                    "
                    class="page-numbers"
                    >{{ currentPageNumber + 3 }}</a
                >
                <a
                    href="javascript:void(0)"
                    (click)="clickPageNumber(currentPageNumber + 4)"
                    *ngIf="
                        totalPageCount > 1 &&
                        currentPageNumber + 4 <= totalPageCount
                    "
                    class="page-numbers"
                    >{{ currentPageNumber + 4 }}</a
                >
                <a
                    href="javascript:void(0)"
                    (click)="clickPageNumber(currentPageNumber + 1)"
                    *ngIf="
                        totalPageCount > 1 &&
                        currentPageNumber + 1 <= totalPageCount
                    "
                    class="next page-numbers"
                    ><i class="bx bx-chevron-right"></i
                ></a>
            </div>
        </div> -->
    </div>

    <br />
</div>
</div>