<section class="mission-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="mission-content">
            <a routerLink="/create-meeting" class="default-btn"><span class="label">Create Meeting</span></a>
            <div class="section-title text-left">
                <span class="sub-title">Discover Learning</span>
                <h2>Why our platform in better</h2>
            </div>

            <div class="mission-slides owl-carousel owl-theme">
                <div>
                    <h3>Quality can be better than quantity in this platform</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea commodo consequat.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea commodo consequat.</p>
                    <a routerLink="/" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-book-reader icon-arrow after"></i></a>
                </div>

                <div>
                    <h3>A place where you can achieve</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea commodo consequat.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea commodo consequat.</p>
                    <a routerLink="/" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-book-reader icon-arrow after"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>