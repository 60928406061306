<div class="testimonials-slides owl-carousel owl-theme">
    <div class="single-testimonials-item">
        <p>Joining buddi as an online tutor is one of the best experiences I had ever. It helped me  follow my passion and develop my interpersonal skills. Both teachers and students have a friendly platform. Iam very happy being a part of buddi.</p>
        <div class="info">
            <!-- <img src="assets/img/user1.jpg" class="shadow rounded-circle" alt="image"> -->
            <h3>Soniya</h3>
            <span>Computer Science</span>
        </div>
    </div>

    <div class="single-testimonials-item">
        <p>buddi is the best growing online platform for learning. Students find it easier and comfortable to approach the teachers and vice versa. buddi helped me in building my career towards teaching where i can learn many things along with the students. Very much excited to move forward.</p>
        <div class="info">
            <!-- <img src="assets/img/user2.jpg" class="shadow rounded-circle" alt="image"> -->
            <h3>Priyanka </h3>
            <span>Chemistry</span>
        </div>
    </div>

    <!-- <div class="single-testimonials-item">
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
        <div class="info">
            <img src="assets/img/user3.jpg" class="shadow rounded-circle" alt="image">
            <h3>David Warner</h3>
            <span>Student</span>
        </div>
    </div> -->

    <div class="single-testimonials-item">
        <p>buddi is very approachable and user friendly platform. Both teachers and students get to learn here. Looking forward to grow along with buddi.</p>
        <div class="info">
            <!-- <img src="assets/img/user4.jpg" class="shadow rounded-circle" alt="image"> -->
            <h3>Uma Kirthiga </h3>
            <span>Biology</span>
        </div>
    </div>

    
    <div class="single-testimonials-item">
        <p>buddi offers many interactive online classes for students in user friendly approach. Teachers can learn many multimedia tools in online teaching. In buddi students are also able to learn beyond their books. buddi helps to develop my career and I am much eager to grow along with buddi.</p>
        <div class="info">
            <!-- <img src="assets/img/user4.jpg" class="shadow rounded-circle" alt="image"> -->
            <h3>Nandhini </h3>
            <span>Physics</span>
        </div>
    </div>

    <div class="single-testimonials-item">
        <p>buddi is the right platform for ambitious teachers. Being a teacher here, helps me to acquire high proficiency in the subject. The technical team who works at the back of each teacher was very supportive and encouraging. It is a very much needed platform for both teachers and students to excel in the education system of the future.</p>
        <div class="info">
            <!-- <img src="assets/img/user4.jpg" class="shadow rounded-circle" alt="image"> -->
            <h3>Vidhyasri </h3>
            <span>Mathematics</span>
        </div>
    </div>
</div>