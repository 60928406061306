import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-buddi-test-list',
  templateUrl: './buddi-test-list.component.html',
  styleUrls: ['./buddi-test-list.component.scss']
})
export class BuddiTestListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    var userId = localStorage.getItem('userId');
  }

}
