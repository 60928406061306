import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../shared/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from 'src/environments/environment';
import { ToastrManager, Toastr } from 'ng6-toastr-notifications';
import * as moment from 'moment';
@Component({
  selector: 'app-create-exam',
  templateUrl: './create-exam.component.html',
  styleUrls: ['./create-exam.component.scss']
})
export class CreateExamComponent implements OnInit {
  examcreateform: FormGroup;
  submitted = false;
  formData;  
  public isstudent = false;
  public isteacher = false;
  public iscost = false;
  public coursecategorylist : any = [];
  public courseboardlist : any = [];
  public coursesubjectlist : any = [];
  public languageapi : any = [];
  public gradeapi : any = [];  
  public coursePrivacyapi:any=[];  
  public examlevelapi:any=[];
  public exampricingapi:any=[];
  public Certificationapi:any=[];
  public userId:any = '';
  public groupname:any ='';
  public isEnterprise = '';
  public paramData:any = [];
  public courseCategory = "Select Category";
  public courseBoard = "Select Board";
  public courseSubject = "Select Subject";
  public language = "Select Language";
  public grade = "Select Grade";  
  public coursePrivacy = "Select Privacy";  
  selectedFileNmae: string = 'Choose File';  
  public isselected = true;
  public isgroupname = false;
  public editData:any=[];
  public allboard:any = [];
  public allsubject:any = [];  
  public courseURL = environment.COURSE_URL;
  isexammeexists: boolean;
  isshortnameexists: boolean;
  isstartdate: boolean;
  constructor(private activatedRoute:ActivatedRoute,private SpinnerService: NgxSpinnerService,private router: Router ,private formBuilder: FormBuilder,private rest: ApiService, private toastr: ToastrManager) { }

  ngOnInit(): void {
    const userType = localStorage.getItem('userType');      
    if(userType.toLowerCase()=="teacher") {
      this.isteacher = true;
    }
    else {
      this.isstudent = true;
    }
    const webToken = localStorage.getItem('webtoken');
    this.rest.userName_DisplayTeacher(webToken).subscribe((result) => {
      console.log(result);
      this.groupname =  result.data !=undefined && result.data.groupName != 'buddi' ? result.data.groupName : ''
      localStorage.setItem('groupname', result.data !=undefined && result.data.groupName != 'buddi' ? result.data.groupName : '');
    });

    this.getcourseprivacy();
    this.getcoursecategory();
    this.getboardfield();
    this.getsubjectfield();
    this.getlanguage();
    this.getgrade();
    this.getexamlevelapi();
    this.getcertification();
    this.getpricing();
    
    this.examcreateform = this.formBuilder.group({
      fullname: ['', Validators.required],
      shortname: ['', Validators.required],
      startdateschedule: ['', Validators.required],
      endDateschedule: ['', Validators.required],
      coursePrivacy: ['', Validators.required],
      courseGrade: [''],
      courseSubject: ['', Validators.required],
      courseLanguage:['', Validators.required],
      courseDescription:['', Validators.required],
      upload_name: [null],
      fileupload: [''],
      courseCategory: ['', Validators.required],
      courseBoard: ['', Validators.required],
      examlevel : [''],
      Certification : [''],
      Cost : [''],
      examlength : ['', Validators.required],
      questions : ['', Validators.required],
      score : ['', Validators.required],
      exampricing :['']
    });

  }
  get f() { return this.examcreateform.controls; }

  onSubmit(FormGroup) {

    this.submitted = true;
 
    // stop here if form is invalid
    if (this.examcreateform.invalid) {
      this.toastr.errorToastr("Invalid Fields");
        return;
    }

    const data = this.examcreateform.value;     
    const startDate1 = new Date(this.examcreateform.value.startdateschedule).getTime() / 1000      
    const endDate1 = new Date(this.examcreateform.value.endDateschedule).getTime() / 1000
    const currentdate = new Date().getTime() / 1000

    if(startDate1 > currentdate) {
      this.isstartdate = false;  
      if(data.upload_name!=null) {
        this.formData = new FormData();
        this.formData.append("exam_image", data.upload_name, data.upload_name.name);
        this.formData.append("fullName", data.fullname);
        this.formData.append("shortName", data.shortname);
        this.formData.append("categoryID", data.courseCategory);
        this.formData.append("languageID", data.courseLanguage);
        this.formData.append("subjectID", data.courseSubject);
        this.formData.append("boardID", data.courseBoard);
        this.formData.append("gradeID", data.courseGrade);
        this.formData.append("privacy", data.coursePrivacy);
        this.formData.append("startDate", startDate1);
        this.formData.append("endDate", endDate1);
        this.formData.append("description", data.courseDescription);
        this.formData.append("level", data.examlevel);
        this.formData.append("certification", data.Certification);
        this.formData.append("pricingType", data.exampricing);      
        this.formData.append("cost", data.Cost);
        this.formData.append("examLength", data.examlength);
        this.formData.append("numberOfQuestions", data.questions);
        this.formData.append("totalScore", data.score);        
        this.formData.append("status", "Draft");
        const webToken = localStorage.getItem('webtoken');
        this.rest.createExam(this.formData, webToken).subscribe((result) => {
        var globalVar = result
          console.log(result);
          if(result.status) {
            this.toastr.successToastr("Exam created successfully");              
            var userId = localStorage.getItem('userId');
            if(!userId){
              this.toastr.errorToastr("please login to access courses");
            }
            else{
              var userData = {};
              userData["userId"]=userId;                
              const userType = localStorage.getItem('userType');
              userData["userType"]=userType.toLowerCase();
              console.log("usertype : " + userType);
              var redirectURL = this.courseURL + "course/view.php?id=" + result.data.examID;
              this.rest.getLoginURL(userData).subscribe((result) => {
                console.log(result);
                if(result){
                  if(!result["data"]) {
                    this.toastr.errorToastr("user has not registered for courses");
                  }
                  else {
                  //Calendar Start

                              this.addToCalendar(globalVar.data, webToken);

                              //Calendar End
                    if(!result["data"]["loginurl"] || result['exception'] == 'invalid_parameter_exception'){
                      this.toastr.errorToastr("user has not registered for courses");
                    }
                    else {
                      this.toastr.successToastr("You are now being redirected to the Add Activity Page of the course");                       
                      console.log(result);
                       

                      console.log(result["data"]["loginurl"]);
                      console.log(result["data"]["loginurl"] + "&wantsurl=" + redirectURL)
                      window.open(result["data"]["loginurl"] + "&wantsurl=" + redirectURL);
                      if(userType.toLowerCase()=="teacher") {
                        this.router.navigate(['/teacher/mydashboard']);
                      }
                      else {
                        this.router.navigate(['/teacher/mydashboard']);
                      }                       
                    }
                  }
                }
              });
            }
          } 
          else {
            if(result.message.toLowerCase().includes('exam full name')) {
              this.isexammeexists = true;
              this.isshortnameexists = false;
              this.toastr.errorToastr(result.message);
            }
            else if(result.message.toLowerCase().includes('short name')) {
              this.isshortnameexists = true;
              this.isexammeexists = false;
              this.toastr.errorToastr(result.message);
            }
            else {
              this.toastr.errorToastr(result.message);
              this.isexammeexists = false;
              this.isshortnameexists = false;
            }
           
            console.log(result.message);
         }
        }, (err) => {
        console.log(err);
        });  
      }

      else {
        this.toastr.errorToastr("Image Required");
      }
        
    }
    else {
      this.isstartdate = true;       
    }
    


  }

  fileChange(event) {
    let fileList: FileList = event.target.files;
    this.examcreateform.patchValue({ upload_name: fileList[0] });
    this.selectedFileNmae = fileList[0].name;
    this.examcreateform.patchValue({ fileupload: this.selectedFileNmae });
    console.log(this.examcreateform.value);
  }

  getcoursecategory() {
    this.rest.getCourseCategoryHeader().subscribe((result) => {
      this.coursecategorylist = result.data;
      if(Object.keys(this.paramData).length == 0){
        this.examcreateform.patchValue({ courseCategory: this.coursecategorylist[0]._id })
      }
      console.log(this.coursecategorylist);
    }, (err) => {
    console.log(err);
    });
  }

  getboardfield(){
    this.rest.boardfield().subscribe((result) => {
      this.courseboardlist = result.data;
      if(Object.keys(this.paramData).length == 0){
        this.examcreateform.patchValue({ courseBoard: this.courseboardlist[0]._id })
      }
      console.log(this.courseboardlist);
    }, (err) => {
    console.log(err);
    });
  }

  getsubjectfield(){
    this.rest.subjectfield().subscribe((result) => {
      this.coursesubjectlist = result.data;
      if(Object.keys(this.paramData).length == 0){
        this.examcreateform.patchValue({ courseSubject: this.coursesubjectlist[0]._id })
      }
      console.log(this.coursesubjectlist);
    }, (err) => {
    console.log(err);
    });
  }

  getlanguage(){
    this.rest.languagefield().subscribe((result) => {
      this.languageapi = result.data;
      if(Object.keys(this.paramData).length == 0){
        this.examcreateform.patchValue({ courseLanguage: this.languageapi[0]._id })
      }
      console.log(this.languageapi);
    }, (err) => {
    console.log(err);
    });
  }

  getgrade(){
    this.rest.gradefield().subscribe((result) => {
      this.gradeapi = result.data;
      if(Object.keys(this.paramData).length == 0){
        this.examcreateform.patchValue({ courseGrade: this.gradeapi[0]._id })
      }
    }, (err) => {
    console.log(err);
    });
  }

  getcourseprivacy(){
    this.rest.privacy().subscribe((result) => {
      this.coursePrivacyapi = result;
      if(Object.keys(this.paramData).length == 0){
        this.examcreateform.patchValue({ coursePrivacy: this.coursePrivacyapi[0].name })
      }
    }, (err) => {
    console.log(err);
    });
  }

  getexamlevelapi(){
    this.rest.examlevel().subscribe((result) => {
      this.examlevelapi = result;
      if(Object.keys(this.paramData).length == 0){
        this.examcreateform.patchValue({ examlevel: this.examlevelapi[0].name })
      }
    }, (err) => {
    console.log(err);
    });
  }

  getcertification(){
    this.rest.certification().subscribe((result) => {
      this.Certificationapi = result;
      if(Object.keys(this.paramData).length == 0){
        this.examcreateform.patchValue({ Certification: this.Certificationapi[0].name })
      }
    }, (err) => {
    console.log(err);
    });
  }

  getpricing(){
    this.rest.pricing().subscribe((result) => {
      this.exampricingapi = result;
      if(Object.keys(this.paramData).length == 0){
        this.examcreateform.patchValue({ exampricing: this.exampricingapi[0].name })
      }
    }, (err) => {
    console.log(err);
    });
  }

  

  selectedcategory(event: any) {
    var selected = event.target.options[event.target.selectedIndex].text;
    if(selected == 'K-12'){         
      this.isselected = true;
    }else{
      this.isselected = false;
    }          
  }

  selectpricing(event: any) {
    var selected = event.target.options[event.target.selectedIndex].text;
    if(selected == 'Paid'){         
      this.iscost = true;
    }else{
      this.iscost = false;
    }          
  }

  selectedprivacy(event: any) {
    var selected = event.target.options[event.target.selectedIndex].text;
    if(selected == 'School'){         
      this.isgroupname = true;
    }else{
      this.isgroupname = false;
    }          
  }

    addToCalendar(data,webToken){
    var req={
      myLifeListDatepick:moment(data.startDate).format('YYYY-MM-DD'),
      myLifeListEventType: "Exams",
      myLifeListTimepick:moment(data.startDate).format('HH:mm:ss'),
      setReminder: 0,
      todoEventType: "Exams",
      meetingType:data.privacy,
      todoSchedule: data.startDate,
      todoSetReminder: "",
      todosetReminderValue: "",
      sessionName:data.shortName,
      meetingId : data._id,
      categoryID :data.categoryID,
      myLifeListTitle:data.privacy,
      sessionId:data._id,
      profileType:'teacher'
    };
    this.SpinnerService.show()
    this.rest.add_myLife_NewData(req,webToken).subscribe((result) => {
    this.SpinnerService.hide()
      if(result.status){
        this.toastr.successToastr("Class Added to your Calender");
        //this.location.back();
      }else{
        this.toastr.errorToastr(result.message);
      }

    });
  }


}
