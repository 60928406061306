
<div class=" col-md-12 banner">
    <div class="container">
        <div class="row">
<div class="head">
<h1>Explore from 1000 of Educational .
    </h1>
    <p>Create and Sell your .
         </p>
         <!-- <a ><button class="btn">Get Started</button></a> -->

</div>


</div>


</div>
</div>
