<div *ngIf="isstudent">
    <app-header-style-one></app-header-style-one>
</div>
<div *ngIf="isteacher">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>

<!-- <div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Instructor Details</li>
            </ul>
            <h2>Instructor Details</h2>
        </div>
    </div>
</div> -->
  <!-- Below is for Uday Shankar-->
<section  *ngIf="isuma" class="instructor-details-area pt-100 pb-70">
    <div class="container">
        <div class="instructor-details-desc">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <div class="instructor-details-sidebar">
                        <img src="../../../../assets/img/UmaKirthiga_Biolog.JPG" 
                        onerror="this.src='../../../../assets/img/UmaKirthiga_Biolog.JPG';" class="img-thumbnail" alt="image">

                        <!-- <div class="instructor-details-meta">
                            <ul>
                                <li>
                                    <i class='bx bxs-group'></i>
                                    <span>Follower</span>
                                    <a routerLink="/">102</a>
                                </li>
                                <li>
                                    <i class='bx bx-group'></i>
                                    <span>Following</span>
                                    <a routerLink="/">81</a>
                                </li>
                            </ul>
                        </div> -->
                        <!-- <a  class="default-btn" style="margin-top: 5%;"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Follow</span><i class="bx bx-plus-circle icon-arrow after"></i></a> -->
                        <!-- <ul class="social-link" style="margin-top: 5%;">
                            <li><a href="#" class="d-block facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a class="d-block facebook" ><i class='bx bxl-facebook'></i></a></li>
                            <li><a class="d-block twitter" ><i class='bx bxl-twitter'></i></a></li>
                            <li><a class="d-block instagram" ><i class='bx bxl-instagram'></i></a></li>
                            <li><a class="d-block linkedin" ><i class='bx bxl-linkedin'></i></a></li>
                            <li><a class="d-block pinterest" ><i class='bx bxl-pinterest-alt'></i></a></li>
                        </ul> -->
                    </div>
                </div>

                <div class="col-lg-8 col-md-8">
                    <div class="instructor-details">
                        <h3>R.Uma Kirthiga</h3>
                        <span class="sub-title">Biology Expert</span>
                            <span class="sub-title">M.Sc., B.Ed.</span>
                            <div class="instructor-details-info">
                                <div class="row">
                                    <div class="col-lg-6 col-md-12">
                                        <!-- <h3>My Education</h3> -->
                                        <ul>
                                            <!-- <li>
                                                <i class='bx bxs-graduation'></i>
                                                <span>Teacher Grade</span>
                                                B
                                            </li> -->
                                            <li>
                                                <i class='bx bxs-graduation'></i>
                                                <span>Subject</span>
                                                Biology 
                                            </li>
                                            <li>
                                                <i class='bx bxs-graduation'></i>
                                                <span>Language</span>
                                                English, Tamil 
                                            </li>
                                        </ul>
                                    </div>
    
                                    <div class="col-lg-6 col-md-12">
                                        <!-- <h3>My Experience</h3> -->
                                        <ul>
                                            <li>
                                                <i class='bx bx-briefcase'></i>
                                                <span>Grade</span>
                                                11,12 NEET
                                            </li>
                                            <li>
                                                <i class='bx bx-briefcase'></i>
                                                <span>Time</span>
                                                Full Time
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <br>
                        <p>Uma Kiruthiga is a Biology Expert with 2 years of experience in teaching CBSE & NEET. Rank Holder in UG </p>
                      
                        


                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


  <!-- Below is for Mukundan-->
  <section  *ngIf="isvidhya" class="instructor-details-area pt-100 pb-70">
    <div class="container">
        <div class="instructor-details-desc">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <div class="instructor-details-sidebar">
                        <img src="../../../../../assets/img/vidhyasri_maths.jpg"  
                        onerror="this.src='../../../../../assets/img/vidhyasri_maths.JPG';" class="img-thumbnail" alt="image">

                        <!-- <div class="instructor-details-meta">
                            <ul>
                                <li>
                                    <i class='bx bxs-group'></i>
                                    <span>Follower</span>
                                    <a routerLink="/">102</a>
                                </li>
                                <li>
                                    <i class='bx bx-group'></i>
                                    <span>Following</span>
                                    <a routerLink="/">81</a>
                                </li>
                            </ul>
                        </div> -->
                        <!-- <a class="default-btn"  style="margin-top: 5%;"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Follow</span><i class="bx bx-plus-circle icon-arrow after"></i></a> -->
                        <!-- <ul class="social-link" style="margin-top: 5%;">
                            <li><a href="#" class="d-block facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a  class="d-block facebook" ><i class='bx bxl-facebook'></i></a></li>
                            <li><a  class="d-block twitter" ><i class='bx bxl-twitter'></i></a></li>
                            <li><a  class="d-block instagram" ><i class='bx bxl-instagram'></i></a></li>
                            <li><a  class="d-block linkedin" ><i class='bx bxl-linkedin'></i></a></li>
                            <li><a  class="d-block pinterest" ><i class='bx bxl-pinterest-alt'></i></a></li>
                        </ul> -->
                    </div>
                </div>

                <div class="col-lg-8 col-md-8">
                    <div class="instructor-details">
                        <h3>M.V. Vidhyashri</h3>
                        <span class="sub-title">Maths Expert</span>
                        <span class="sub-title">M.Sc., M.Ed.</span>
                        <div class="instructor-details-info">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <!-- <h3>My Education</h3> -->
                                    <ul>
                                        <!-- <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Teacher Grade</span>
                                            B
                                        </li> -->
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Subject</span>
                                            Mathematics 
                                        </li>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Language</span>
                                            English, Tamil 
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <!-- <h3>My Experience</h3> -->
                                    <ul>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Grade</span>
                                            class 6 to 12
                                        </li>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Time</span>
                                            Full Time
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <br>
                        <p>Vidhyashri is a Maths Expert with 7 years of experience in teaching CBSE & Matriculations.</p>
                      
                        <!-- <div class="instructor-details-info">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <h3>My Education</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Harvard University</span>
                                            Bachelor in Mathematics
                                        </li>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>University of Toronto</span>
                                            Bachelor in English
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <h3>My Experience</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Envato</span>
                                            2000-2010
                                        </li>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>EnvyTheme</span>
                                            2010-2020
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


  <!-- Below is for Arif-->
  <section  *ngIf="issoniya" class="instructor-details-area pt-100 pb-70">
    <div class="container">
        <div class="instructor-details-desc">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <div class="instructor-details-sidebar">
                        <img src="../../../../assets/img/sonia_computerscience.jpeg" 
                        onerror="this.src='../../../../assets/img/sonia_computerscience.JPEG';" class="img-thumbnail" alt="image">

                        <!-- <div class="instructor-details-meta">
                            <ul>
                                <li>
                                    <i class='bx bxs-group'></i>
                                    <span>Follower</span>
                                    <a routerLink="/">102</a>
                                </li>
                                <li>
                                    <i class='bx bx-group'></i>
                                    <span>Following</span>
                                    <a routerLink="/">81</a>
                                </li>
                            </ul>
                        </div> -->
                        <!-- <a class="default-btn"  style="margin-top: 5%;"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Follow</span><i class="bx bx-plus-circle icon-arrow after"></i></a> -->
                        <!-- <ul class="social-link" style="margin-top: 5%;">
                            <li><a href="#" class="d-block facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a  class="d-block facebook" ><i class='bx bxl-facebook'></i></a></li>
                            <li><a class="d-block twitter" ><i class='bx bxl-twitter'></i></a></li>
                            <li><a  class="d-block instagram" ><i class='bx bxl-instagram'></i></a></li>
                            <li><a  class="d-block linkedin" ><i class='bx bxl-linkedin'></i></a></li>
                            <li><a  class="d-block pinterest" ><i class='bx bxl-pinterest-alt'></i></a></li>
                        </ul> -->
                    </div>
                </div>

                <div class="col-lg-8 col-md-8">
                    <div class="instructor-details">
                        <h3>V. Soniya</h3>
                        <span class="sub-title">Coding Expert</span>
                        <span class="sub-title">M.E</span>
                        <div class="instructor-details-info">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <!-- <h3>My Education</h3> -->
                                    <ul>
                                        <!-- <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Teacher Grade</span>
                                            B
                                        </li> -->
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Subject</span>
                                            Coding 
                                        </li>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Language</span>
                                            English, Tamil, Telugu,Hindi(Basic)
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <!-- <h3>My Experience</h3> -->
                                    <ul>
                                        <!-- <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Grade</span>
                                            class 6 to 12
                                        </li> -->
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Time</span>
                                            Full Time
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <br>
                        <p>Soniya is a Coding Expert with 5 years of experience in developing & teaching coding Published 3 books (Cloud computing, Distributed System, Network Security).</p>
                      
                        <!-- <div class="instructor-details-info">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <h3>My Education</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Harvard University</span>
                                            Bachelor in Mathematics
                                        </li>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>University of Toronto</span>
                                            Bachelor in English
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <h3>My Experience</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Envato</span>
                                            2000-2010
                                        </li>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>EnvyTheme</span>
                                            2010-2020
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section  *ngIf="isnandhini" class="instructor-details-area pt-100 pb-70">
    <div class="container">
        <div class="instructor-details-desc">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <div class="instructor-details-sidebar">
                        <img src="../../../../../assets/img/nandhini_physics.jpg" 
                        onerror="this.src='../../../../../assets/img/nandhini_physics.JPG';" class="img-thumbnail" alt="image">

                        <!-- <div class="instructor-details-meta">
                            <ul>
                                <li>
                                    <i class='bx bxs-group'></i>
                                    <span>Follower</span>
                                    <a routerLink="/">102</a>
                                </li>
                                <li>
                                    <i class='bx bx-group'></i>
                                    <span>Following</span>
                                    <a routerLink="/">81</a>
                                </li>
                            </ul>
                        </div> -->
                        <!-- <a class="default-btn"  style="margin-top: 5%;"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Follow</span><i class="bx bx-plus-circle icon-arrow after"></i></a> -->
                        <!-- <ul class="social-link" style="margin-top: 5%;">
                            <li><a href="#" class="d-block facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a  class="d-block facebook" ><i class='bx bxl-facebook'></i></a></li>
                            <li><a class="d-block twitter" ><i class='bx bxl-twitter'></i></a></li>
                            <li><a  class="d-block instagram" ><i class='bx bxl-instagram'></i></a></li>
                            <li><a  class="d-block linkedin" ><i class='bx bxl-linkedin'></i></a></li>
                            <li><a  class="d-block pinterest" ><i class='bx bxl-pinterest-alt'></i></a></li>
                        </ul> -->
                    </div>
                </div>

                <div class="col-lg-8 col-md-8">
                    <div class="instructor-details">
                        <h3>M. Nandhini</h3>
                        <span class="sub-title">Physics Expert</span>
                        <span class="sub-title">M.E.</span>
                        <div class="instructor-details-info">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <!-- <h3>My Education</h3> -->
                                    <ul>
                                        <!-- <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Teacher Grade</span>
                                            B
                                        </li> -->
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Subject</span>
                                            Physics 
                                        </li>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Language</span>
                                            English, Tamil
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <!-- <h3>My Experience</h3> -->
                                    <ul>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Grade</span>
                                            11,12 NEET, JEE
                                        </li>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Time</span>
                                            Full Time
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <br>
                        <p>Nandhini is a Physics Expert with 2 years of experience in teaching NEET & JEE </p>
                      
                        <!-- <div class="instructor-details-info">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <h3>My Education</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Harvard University</span>
                                            Bachelor in Mathematics
                                        </li>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>University of Toronto</span>
                                            Bachelor in English
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <h3>My Experience</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Envato</span>
                                            2000-2010
                                        </li>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>EnvyTheme</span>
                                            2010-2020
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section  *ngIf="ispriyanka" class="instructor-details-area pt-100 pb-70">
    <div class="container">
        <div class="instructor-details-desc">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <div class="instructor-details-sidebar">
                        <img src="../../../../../assets/img/Priyanka-chemistry.jpg" 
                        onerror="this.src='../../../../../assets/img/Priyanka-chemistry.JPG';" class="img-thumbnail" alt="image">

                        <!-- <div class="instructor-details-meta">
                            <ul>
                                <li>
                                    <i class='bx bxs-group'></i>
                                    <span>Follower</span>
                                    <a routerLink="/">102</a>
                                </li>
                                <li>
                                    <i class='bx bx-group'></i>
                                    <span>Following</span>
                                    <a routerLink="/">81</a>
                                </li>
                            </ul>
                        </div> -->
                        <!-- <a class="default-btn"  style="margin-top: 5%;"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Follow</span><i class="bx bx-plus-circle icon-arrow after"></i></a> -->
                        <!-- <ul class="social-link" style="margin-top: 5%;">
                            <li><a href="#" class="d-block facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a  class="d-block facebook" ><i class='bx bxl-facebook'></i></a></li>
                            <li><a class="d-block twitter" ><i class='bx bxl-twitter'></i></a></li>
                            <li><a  class="d-block instagram" ><i class='bx bxl-instagram'></i></a></li>
                            <li><a  class="d-block linkedin" ><i class='bx bxl-linkedin'></i></a></li>
                            <li><a  class="d-block pinterest" ><i class='bx bxl-pinterest-alt'></i></a></li>
                        </ul> -->
                    </div>
                </div>

                <div class="col-lg-8 col-md-8">
                    <div class="instructor-details">
                        <h3>B. Priyanka</h3>
                        <span class="sub-title">Chemistry Expert</span>
                        <span class="sub-title">M.Sc.</span>
                        <div class="instructor-details-info">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <!-- <h3>My Education</h3> -->
                                    <ul>
                                        <!-- <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Teacher Grade</span>
                                            B
                                        </li> -->
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Subject</span>
                                            Chemistry 
                                        </li>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Language</span>
                                            English and Tamil 
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <!-- <h3>My Experience</h3> -->
                                    <ul>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Grade</span>
                                            6-12
                                        </li>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Time</span>
                                            Full Time
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <br>
                        <p>Priyanka is a new buddi teacher with all Perseverance & Dedication.  Rank Holder in UG </p>
                      
                        <!-- <div class="instructor-details-info">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <h3>My Education</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Harvard University</span>
                                            Bachelor in Mathematics
                                        </li>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>University of Toronto</span>
                                            Bachelor in English
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <h3>My Experience</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Envato</span>
                                            2000-2010
                                        </li>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>EnvyTheme</span>
                                            2010-2020
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section  *ngIf="issreeja" class="instructor-details-area pt-100 pb-70">
    <div class="container">
        <div class="instructor-details-desc">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <div class="instructor-details-sidebar">
                        <img src="../../../../../assets/img/Sreeja.jpg" 
                        onerror="this.src='../../../../../assets/img/Sreeja.JPG';" class="img-thumbnail" alt="image">

                        <!-- <div class="instructor-details-meta">
                            <ul>
                                <li>
                                    <i class='bx bxs-group'></i>
                                    <span>Follower</span>
                                    <a routerLink="/">102</a>
                                </li>
                                <li>
                                    <i class='bx bx-group'></i>
                                    <span>Following</span>
                                    <a routerLink="/">81</a>
                                </li>
                            </ul>
                        </div> -->
                        <!-- <a class="default-btn"  style="margin-top: 5%;"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Follow</span><i class="bx bx-plus-circle icon-arrow after"></i></a> -->
                        <!-- <ul class="social-link" style="margin-top: 5%;">
                            <li><a href="#" class="d-block facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a  class="d-block facebook" ><i class='bx bxl-facebook'></i></a></li>
                            <li><a class="d-block twitter" ><i class='bx bxl-twitter'></i></a></li>
                            <li><a  class="d-block instagram" ><i class='bx bxl-instagram'></i></a></li>
                            <li><a  class="d-block linkedin" ><i class='bx bxl-linkedin'></i></a></li>
                            <li><a  class="d-block pinterest" ><i class='bx bxl-pinterest-alt'></i></a></li>
                        </ul> -->
                    </div>
                </div>

                <div class="col-lg-8 col-md-8">
                    <div class="instructor-details">
                        <h3>V.S.Sreeja</h3>
                        <span class="sub-title">Chemistry Expert</span>
                        <span class="sub-title">M.Sc., B.Ed., (phD)</span>
                        <div class="instructor-details-info">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <!-- <h3>My Education</h3> -->
                                    <ul>
                                        <!-- <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Teacher Grade</span>
                                            A
                                        </li> -->
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Subject</span>
                                            Chemistry 
                                        </li>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Language</span>
                                            English & Malayalam
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <!-- <h3>My Experience</h3> -->
                                    <ul>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Grade</span>
                                            8 - 12
                                        </li>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Time</span>
                                            Full Time
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <br>
                        <p>Sreeja is a Chemistry expert with 6 years of experience in teaching CBSE, IGCSE </p>
                      
                        <!-- <div class="instructor-details-info">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <h3>My Education</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Harvard University</span>
                                            Bachelor in Mathematics
                                        </li>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>University of Toronto</span>
                                            Bachelor in English
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <h3>My Experience</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Envato</span>
                                            2000-2010
                                        </li>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>EnvyTheme</span>
                                            2010-2020
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section  *ngIf="isrithika" class="instructor-details-area pt-100 pb-70">
    <div class="container">
        <div class="instructor-details-desc">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <div class="instructor-details-sidebar">
                        <img src="../../../../../assets/img/Rethika.jpg" 
                        onerror="this.src='../../../../../assets/img/Rethika.JPG';" class="img-thumbnail" alt="image">

                        <!-- <div class="instructor-details-meta">
                            <ul>
                                <li>
                                    <i class='bx bxs-group'></i>
                                    <span>Follower</span>
                                    <a routerLink="/">102</a>
                                </li>
                                <li>
                                    <i class='bx bx-group'></i>
                                    <span>Following</span>
                                    <a routerLink="/">81</a>
                                </li>
                            </ul>
                        </div> -->
                        <!-- <a class="default-btn"  style="margin-top: 5%;"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Follow</span><i class="bx bx-plus-circle icon-arrow after"></i></a> -->
                        <!-- <ul class="social-link" style="margin-top: 5%;">
                            <li><a href="#" class="d-block facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a  class="d-block facebook" ><i class='bx bxl-facebook'></i></a></li>
                            <li><a class="d-block twitter" ><i class='bx bxl-twitter'></i></a></li>
                            <li><a  class="d-block instagram" ><i class='bx bxl-instagram'></i></a></li>
                            <li><a  class="d-block linkedin" ><i class='bx bxl-linkedin'></i></a></li>
                            <li><a  class="d-block pinterest" ><i class='bx bxl-pinterest-alt'></i></a></li>
                        </ul> -->
                    </div>
                </div>

                <div class="col-lg-8 col-md-8">
                    <div class="instructor-details">
                        <h3>Rethika Ramesh</h3>
                        <span class="sub-title">Biology Expert</span>
                        <span class="sub-title">MBBS</span>
                        <div class="instructor-details-info">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <!-- <h3>My Education</h3> -->
                                    <ul>
                                        <!-- <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Teacher Grade</span>
                                            B+
                                        </li> -->
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Subject</span>
                                            Biology   
                                        </li>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Language</span>
                                            English, Tamil, Mandarian(Basic)
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <!-- <h3>My Experience</h3> -->
                                    <ul>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Grade</span>
                                            K-12, NEET
                                        </li>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Time</span>
                                            Full Time
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <br>
                        <p>Rethika Ramesh is a biology expert with 2 years of Experience in teaching CBSE, NEET & TN Stateboard</p>
                      
                        <!-- <div class="instructor-details-info">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <h3>My Education</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Harvard University</span>
                                            Bachelor in Mathematics
                                        </li>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>University of Toronto</span>
                                            Bachelor in English
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <h3>My Experience</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Envato</span>
                                            2000-2010
                                        </li>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>EnvyTheme</span>
                                            2010-2020
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section  *ngIf="isvignesh" class="instructor-details-area pt-100 pb-70">
    <div class="container">
        <div class="instructor-details-desc">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <div class="instructor-details-sidebar">
                        <img src="../../../../assets/img/Vignesh.jpg" 
                        onerror="this.src='../../../../assets/img/Vignesh.JPG';" class="img-thumbnail" alt="image">

                        <!-- <div class="instructor-details-meta">
                            <ul>
                                <li>
                                    <i class='bx bxs-group'></i>
                                    <span>Follower</span>
                                    <a routerLink="/">102</a>
                                </li>
                                <li>
                                    <i class='bx bx-group'></i>
                                    <span>Following</span>
                                    <a routerLink="/">81</a>
                                </li>
                            </ul>
                        </div> -->
                        <!-- <a class="default-btn"  style="margin-top: 5%;"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Follow</span><i class="bx bx-plus-circle icon-arrow after"></i></a> -->
                        <!-- <ul class="social-link" style="margin-top: 5%;">
                            <li><a href="#" class="d-block facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a  class="d-block facebook" ><i class='bx bxl-facebook'></i></a></li>
                            <li><a class="d-block twitter" ><i class='bx bxl-twitter'></i></a></li>
                            <li><a  class="d-block instagram" ><i class='bx bxl-instagram'></i></a></li>
                            <li><a  class="d-block linkedin" ><i class='bx bxl-linkedin'></i></a></li>
                            <li><a  class="d-block pinterest" ><i class='bx bxl-pinterest-alt'></i></a></li>
                        </ul> -->
                    </div>
                </div>

                <div class="col-lg-8 col-md-8">
                    <div class="instructor-details">
                        <h3>V. Vignesh</h3>
                        <span class="sub-title">Computer Science Expert</span>
                        <span class="sub-title">B.Sc (Software Engineering)</span>
                        <div class="instructor-details-info">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <!-- <h3>My Education</h3> -->
                                    <ul>
                                        <!-- <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Teacher Grade</span>
                                            B+
                                        </li> -->
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Subject</span>
                                            Computer Science  
                                        </li>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Language</span>
                                            English, Tamil, Telugu
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <!-- <h3>My Experience</h3> -->
                                    <ul>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Grade</span>
                                            K-12 , Coding
                                        </li>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Time</span>
                                            Full Time
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <br>
                        <p> Vignesh is a Coding expert with 4 years of experience in teaching coding </p>
                      
                        <!-- <div class="instructor-details-info">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <h3>My Education</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Harvard University</span>
                                            Bachelor in Mathematics
                                        </li>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>University of Toronto</span>
                                            Bachelor in English
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <h3>My Experience</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Envato</span>
                                            2000-2010
                                        </li>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>EnvyTheme</span>
                                            2010-2020
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section  *ngIf="isnivetha" class="instructor-details-area pt-100 pb-70">
    <div class="container">
        <div class="instructor-details-desc">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <div class="instructor-details-sidebar">
                        <img src="../../../../assets/img/Nivetha.jpg" 
                        onerror="this.src='../../../../assets/img/Nivetha.JPG';" class="img-thumbnail" alt="image">

                        <!-- <div class="instructor-details-meta">
                            <ul>
                                <li>
                                    <i class='bx bxs-group'></i>
                                    <span>Follower</span>
                                    <a routerLink="/">102</a>
                                </li>
                                <li>
                                    <i class='bx bx-group'></i>
                                    <span>Following</span>
                                    <a routerLink="/">81</a>
                                </li>
                            </ul>
                        </div> -->
                        <!-- <a class="default-btn"  style="margin-top: 5%;"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Follow</span><i class="bx bx-plus-circle icon-arrow after"></i></a> -->
                        <!-- <ul class="social-link" style="margin-top: 5%;">
                            <li><a href="#" class="d-block facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a  class="d-block facebook" ><i class='bx bxl-facebook'></i></a></li>
                            <li><a class="d-block twitter" ><i class='bx bxl-twitter'></i></a></li>
                            <li><a  class="d-block instagram" ><i class='bx bxl-instagram'></i></a></li>
                            <li><a  class="d-block linkedin" ><i class='bx bxl-linkedin'></i></a></li>
                            <li><a  class="d-block pinterest" ><i class='bx bxl-pinterest-alt'></i></a></li>
                        </ul> -->
                    </div>
                </div>

                <div class="col-lg-8 col-md-8">
                    <div class="instructor-details">
                        <h3>S. Nivetha</h3>
                        <span class="sub-title">Maths Expert</span>
                        <span class="sub-title">M.Sc.(Maths)</span>
                        <div class="instructor-details-info">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <!-- <h3>My Education</h3> -->
                                    <ul>
                                        <!-- <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Teacher Grade</span>
                                            B+
                                        </li> -->
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Subject</span>
                                            Maths   
                                        </li>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Language</span>
                                            English, Tamil
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <!-- <h3>My Experience</h3> -->
                                    <ul>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Grade</span>
                                            K-12
                                        </li>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Time</span>
                                            Full Time
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <br>
                        <p>Nivetha is a Maths expert with 4 years of experience in CBSE & Stateboard </p>
                      
                        <!-- <div class="instructor-details-info">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <h3>My Education</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Harvard University</span>
                                            Bachelor in Mathematics
                                        </li>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>University of Toronto</span>
                                            Bachelor in English
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <h3>My Experience</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Envato</span>
                                            2000-2010
                                        </li>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>EnvyTheme</span>
                                            2010-2020
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section  *ngIf="isbalkeespraveen" class="instructor-details-area pt-100 pb-70">
    <div class="container">
        <div class="instructor-details-desc">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <div class="instructor-details-sidebar">
                        <img src="../../../../assets/img/Balkees-Parveen.jpg" 
                        onerror="this.src='../../../../assets/img/Balkees-Parveen.JPG';" class="img-thumbnail" alt="image">

                        <!-- <div class="instructor-details-meta">
                            <ul>
                                <li>
                                    <i class='bx bxs-group'></i>
                                    <span>Follower</span>
                                    <a routerLink="/">102</a>
                                </li>
                                <li>
                                    <i class='bx bx-group'></i>
                                    <span>Following</span>
                                    <a routerLink="/">81</a>
                                </li>
                            </ul>
                        </div> -->
                        <!-- <a class="default-btn"  style="margin-top: 5%;"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Follow</span><i class="bx bx-plus-circle icon-arrow after"></i></a> -->
                        <!-- <ul class="social-link" style="margin-top: 5%;">
                            <li><a href="#" class="d-block facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a  class="d-block facebook" ><i class='bx bxl-facebook'></i></a></li>
                            <li><a class="d-block twitter" ><i class='bx bxl-twitter'></i></a></li>
                            <li><a  class="d-block instagram" ><i class='bx bxl-instagram'></i></a></li>
                            <li><a  class="d-block linkedin" ><i class='bx bxl-linkedin'></i></a></li>
                            <li><a  class="d-block pinterest" ><i class='bx bxl-pinterest-alt'></i></a></li>
                        </ul> -->
                    </div>
                </div>

                <div class="col-lg-8 col-md-8">
                    <div class="instructor-details">
                        <h3>Balkees Parveen</h3>
                        <span class="sub-title">Physics Expert</span>
                        <span class="sub-title">B.Sc, B.Ed</span>
                        <div class="instructor-details-info">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <!-- <h3>My Education</h3> -->
                                    <ul>
                                        <!-- <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Teacher Grade</span>
                                            B+
                                        </li> -->
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Subject</span>
                                            Physics 
                                        </li>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Language</span>
                                            English, Tamil
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <!-- <h3>My Experience</h3> -->
                                    <ul>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Grade</span>
                                            1 - 12
                                        </li>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Time</span>
                                            Full Time
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <br>
                        <p>Balkees Parveen is a Physics expert with 3 years of experience in Teaching CBSE.</p>
                      
                        <!-- <div class="instructor-details-info">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <h3>My Education</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Harvard University</span>
                                            Bachelor in Mathematics
                                        </li>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>University of Toronto</span>
                                            Bachelor in English
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <h3>My Experience</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Envato</span>
                                            2000-2010
                                        </li>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>EnvyTheme</span>
                                            2010-2020
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section  *ngIf="isvineethaanil" class="instructor-details-area pt-100 pb-70">
    <div class="container">
        <div class="instructor-details-desc">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <div class="instructor-details-sidebar">
                        <img src="../../../../assets/img/Vineetha-Anil.jpg" 
                        onerror="this.src='../../../../assets/img/Vineetha-Anil.JPG';" class="img-thumbnail" alt="image">

                        <!-- <div class="instructor-details-meta">
                            <ul>
                                <li>
                                    <i class='bx bxs-group'></i>
                                    <span>Follower</span>
                                    <a routerLink="/">102</a>
                                </li>
                                <li>
                                    <i class='bx bx-group'></i>
                                    <span>Following</span>
                                    <a routerLink="/">81</a>
                                </li>
                            </ul>
                        </div> -->
                        <!-- <a class="default-btn"  style="margin-top: 5%;"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Follow</span><i class="bx bx-plus-circle icon-arrow after"></i></a> -->
                        <!-- <ul class="social-link" style="margin-top: 5%;">
                            <li><a href="#" class="d-block facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a  class="d-block facebook" ><i class='bx bxl-facebook'></i></a></li>
                            <li><a class="d-block twitter" ><i class='bx bxl-twitter'></i></a></li>
                            <li><a  class="d-block instagram" ><i class='bx bxl-instagram'></i></a></li>
                            <li><a  class="d-block linkedin" ><i class='bx bxl-linkedin'></i></a></li>
                            <li><a  class="d-block pinterest" ><i class='bx bxl-pinterest-alt'></i></a></li>
                        </ul> -->
                    </div>
                </div>

                <div class="col-lg-8 col-md-8">
                    <div class="instructor-details">
                        <h3>Vineetha Anil</h3>
                        <span class="sub-title">Soft Skills, Social Science</span>
                        <span class="sub-title">MA(Economics), MA(Public Administration), M.Ed, UGC-NET(Economics), M.Phil(Part-I)</span>
                        <div class="instructor-details-info">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <!-- <h3>My Education</h3> -->
                                    <ul>
                                        <!-- <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Teacher Grade</span>
                                            B+
                                        </li> -->
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Subject</span>
                                            Soft Skills, Social Science 
                                        </li>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Language</span>
                                            English, Tamil
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <!-- <h3>My Experience</h3> -->
                                    <ul>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Grade</span>
                                            8, 9, 10
                                        </li>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Time</span>
                                            Full Time
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <br>
                        <p>About Teacher:  Former Principal of CBSE school for 7 years, 24years of Teaching experience in colleges and High School as Economics & Social Science faculty.</p>
                      
                        <!-- <div class="instructor-details-info">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <h3>My Education</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Harvard University</span>
                                            Bachelor in Mathematics
                                        </li>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>University of Toronto</span>
                                            Bachelor in English
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <h3>My Experience</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Envato</span>
                                            2000-2010
                                        </li>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>EnvyTheme</span>
                                            2010-2020
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section  *ngIf="isjothi" class="instructor-details-area pt-100 pb-70">
    <div class="container">
        <div class="instructor-details-desc">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <div class="instructor-details-sidebar">
                        <img src="../../../../assets/img/jothi.jpg" 
                        onerror="this.src='../../../../assets/img/jothi.JPG';" class="img-thumbnail" alt="image">

                        <!-- <div class="instructor-details-meta">
                            <ul>
                                <li>
                                    <i class='bx bxs-group'></i>
                                    <span>Follower</span>
                                    <a routerLink="/">102</a>
                                </li>
                                <li>
                                    <i class='bx bx-group'></i>
                                    <span>Following</span>
                                    <a routerLink="/">81</a>
                                </li>
                            </ul>
                        </div> -->
                        <!-- <a class="default-btn"  style="margin-top: 5%;"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Follow</span><i class="bx bx-plus-circle icon-arrow after"></i></a> -->
                        <!-- <ul class="social-link" style="margin-top: 5%;">
                            <li><a href="#" class="d-block facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a  class="d-block facebook" ><i class='bx bxl-facebook'></i></a></li>
                            <li><a class="d-block twitter" ><i class='bx bxl-twitter'></i></a></li>
                            <li><a  class="d-block instagram" ><i class='bx bxl-instagram'></i></a></li>
                            <li><a  class="d-block linkedin" ><i class='bx bxl-linkedin'></i></a></li>
                            <li><a  class="d-block pinterest" ><i class='bx bxl-pinterest-alt'></i></a></li>
                        </ul> -->
                    </div>
                </div>

                <div class="col-lg-8 col-md-8">
                    <div class="instructor-details">
                        <h3>Jothipriya Udayashankar</h3>
                        <span class="sub-title">English</span>
                        <span class="sub-title">Bsc Physics</span>
                        <div class="instructor-details-info">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <!-- <h3>My Education</h3> -->
                                    <ul>
                                        <!-- <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Teacher Grade</span>
                                            B+
                                        </li> -->
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Subject</span>
                                            English
                                        </li>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Language</span>
                                            English, Tamil
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <!-- <h3>My Experience</h3> -->
                                    <ul>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Grade</span>
                                            1 - 12
                                        </li>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Time</span>
                                            Full Time
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <br>
                        <p>Jothi is a spoken english certified teacher who has vast knowledge and experience over the subject.</p>
                      
                        <!-- <div class="instructor-details-info">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <h3>My Education</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Harvard University</span>
                                            Bachelor in Mathematics
                                        </li>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>University of Toronto</span>
                                            Bachelor in English
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <h3>My Experience</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Envato</span>
                                            2000-2010
                                        </li>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>EnvyTheme</span>
                                            2010-2020
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>