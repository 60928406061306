<div *ngIf="isstudent">
    <app-header-style-one></app-header-style-one>
</div>
<div *ngIf="isteacher">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>


<section class="courses-details-area pt-100 pb-70" style="padding-top: 30px!important" *ngIf="isquestion">
    <div class="container">
        <!-- The Modal to end a test -->
        <!-- The Modal -->
        <!-- The Modal -->
        <div class="modal" id="myModal1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body" *ngIf="questioncountimage!==''">
                        <img src={{questioncountimage}} alt="image" style="width:auto;height:auto">

                        <div class="row" style="margin-top: 5%;">
                            <div class="col-md-4 col-lg-4 col-sm-4">
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3">
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3">
                                <a class="default-btn" style="margin-left: 15%;" data-dismiss="modal"><i
                                        class='bx bx-paper-plane icon-arrow before'></i><span
                                        class="label">Cancel</span><i
                                        class="bx bx-paper-plane icon-arrow after"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="modal" id="myModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">

                        <div style="margin-top: 5%;">
                            <p> Are you sure you want to end this test?</p>
                        </div>
                        <div class="row" style="display: block;">
                            <h6 style="text-align: center;">Question summary</h6>
                        </div>
                        <div class="row" style="margin-top: 3%;">
                            <div class="col-lg-4">
                                <p style="font-size: 12px;"><span class="greendot"></span> {{answeredcount}} Answered
                                </p>
                            </div>
                            <div class="col-lg-4">
                                <p style="font-size: 12px;"><span class="orangedot"></span> {{reviewedcount}} Marked</p>
                            </div>
                            <div class="col-lg-4">
                                <p style="font-size: 12px;"><span class="yellowdot"></span> {{unansweredcount}}
                                    Unanswered</p>
                            </div>
                        </div>
                        <div class="row" style="margin-top: 5%;">
                            <div class="col-md-4 col-lg-4 col-sm-4">
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3">
                                <a class="default-btn" (click)="endtest()" style=" margin-right: 10px;"
                                    data-dismiss="modal"><i class='bx bx-paper-plane icon-arrow before'></i><span
                                        class="label">End</span><i class="bx bx-paper-plane icon-arrow after"></i></a>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3">
                                <a class="default-btn" style="margin-left: 15%;" data-dismiss="modal"><i
                                        class='bx bx-paper-plane icon-arrow before'></i><span
                                        class="label">Cancel</span><i
                                        class="bx bx-paper-plane icon-arrow after"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" id="myModal2">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">

                        <div style="margin-top: 5%;">
                            <p> Are you sure you want to review this test?</p>
                            <br>
                            <p>This test will be again corrected by a teacher. Once done it will be published in your Dashboard.</p>
                        </div>
                       <br>
                        <div class="row" style="margin-top: 5%;">
                            <div class="col-md-4 col-lg-4 col-sm-4">
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3">
                                <a class="default-btn" (click)="reviewtest()" style=" margin-right: 10px;"
                                    data-dismiss="modal"><i class='bx bx-paper-plane icon-arrow before'></i><span
                                        class="label">End</span><i class="bx bx-paper-plane icon-arrow after"></i></a>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3">
                                <a class="default-btn" style="margin-left: 15%;" data-dismiss="modal"><i
                                        class='bx bx-paper-plane icon-arrow before'></i><span
                                        class="label">Cancel</span><i
                                        class="bx bx-paper-plane icon-arrow after"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" id="myModal3">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-body">
                            <img [src]="imageToShow"  alt="image" style="width:auto;height:auto">
                          
                            <div class="row" style="margin-top: 5%;">
                                <div class="col-md-4 col-lg-4 col-sm-4">
                                        <button class="button" style="margin-left: 15%;" (click)="previousimage()">Previous</button>
                                </div>
                                <div class="col-md-3 col-lg-3 col-sm-3">
                                        <button class="button" (click)="nextimage()">Next</button>
                                </div>
                                <div class="col-md-3 col-lg-3 col-sm-3">
                                        <button class="button" style="margin-left: 15%;" data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <!-- End of The Modal to end a test -->

        <!-- Exam Timer -->
        <div class="row">
            <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12" style="box-shadow: 0px 0px 1px 0px;">
                <div class="row" style="height: 75px;padding-top: 5%;padding-left: 5%;">
                    <p style="word-break: break-word;font-size: 20px;color:black;font-weight: 700">
                        {{testDetails.testID ? testDetails.testID.testName : ''}}</p>

                </div>
            </div>
            <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                <div class="circleformark">
                    {{testDetails.totalMarks}}/{{testDetails.testID ? testDetails.testID.totalMarks : ''}}</div>
            </div>
        </div>
        <!-- End of Exam Timer -->

        <div class="row" style="margin-top:3%">

            <!-- Question with options -->
            <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12"
                style="box-shadow: 0px 0px 1px 0px;padding: 5px 5px 5px 10px;">
                <div class="row" >
                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                <p style="padding:10px 0px; color: black"> Question {{questioncount + 1}}</p>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                            <button *ngIf="viewimages" class="button" style="margin-left:20%" data-toggle="modal" data-target="#myModal3">View Images</button>
                                <!-- <a class="default-btn" style="margin-left: 15%;" data-toggle="modal" data-target="#myModal2"><span
                                    class="label">View Images</span></a> -->
                            </div>
                </div>
                <div *ngIf="answerlist[questioncount].images == undefined" class="row" style="padding: 5px 5px 5px 10px;;margin-top: 2%">
                    <div id="mathdiv" mathjax >
                       
                        {{answerlist[questioncount].questionText}}
                    </div>
                </div>

                <div *ngIf="answerlist[questioncount].images != undefined">
                    <div *ngFor="let path of answerlist[questioncount].images">
                        <div *ngIf="path.includes('http')">
                            <img src= {{path}} alt="something" style="width:150px;height:100px;"/>
                        </div>
                        <div id="mathdiv" *ngIf="!path.includes('http')"  mathjax>
                            {{path}}
                        </div>
                    </div>
                    <!-- <div id="mathdiv" mathjax >
                        {{answerlist[questioncount].beforeImage}}
                    </div>
                    <img src=  {{answerlist[questioncount].imagePath}} alt="something" style="width:150px;height:100px;"/>
                    <div id="mathdiv" mathjax >
                        {{answerlist[questioncount].afterImage}}
                    </div> -->
                </div>
                <form class="edit-account" [formGroup]="selectedoptionform">

                    <!-- Dropdown options with multiselect-->
                    <div class="row" style="padding: 5px 5px 5px 10px;"
                        *ngIf="answerlist[questioncount].correctAnswer.length > 1 && answerlist[questioncount].questionType==1">

                        <div class="col-lg-5" id="option1id"
                            style="padding: 15px 15px 15px 15px;box-shadow: 0px 0px 1px 0px;margin-top: 5%;">
                            
                                <div *ngIf="answerlist[questioncount].option1Images == undefined" class="row" style="padding: 5px 5px 5px 10px;;margin-top: 2%">
                                    <input type="radio" disabled name="isoption1" (click)="option1clicked()"
                                [(ngModel)]="isoption1" formControlName="option1" [value]="true">
                                    <div id="mathone" mathjax style="margin-left: 5%;">
                                       
                                        {{answerlist[questioncount].option1}}
                                    </div>
                                </div>
                
                                <div *ngIf="answerlist[questioncount].option1Images != undefined">
                                    <div *ngFor="let path of answerlist[questioncount].option1Images">
                                        <div *ngIf="path.includes('http')">
                                            <img src= {{path}} alt="something" style="width:150px;height:100px;"/>
                                        </div>
                                        <div id="mathone" *ngIf="!path.includes('http')"  mathjax>
                                            {{path}}
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div class="col-lg-1">

                        </div>

                        <div class="col-lg-5" id="option2id"
                            style="padding: 15px 15px 15px 15px;margin-top: 5%;box-shadow: 0px 0px 1px 0px">
                           
                                <!-- <span
                                style="margin-left: 3%;font-size: 13px;word-break: break-word;" id="mathtwo" mathjax>{{answerlist[questioncount].option2}}</span> -->
                                <div *ngIf="answerlist[questioncount].option2Images == undefined" class="row" style="padding: 5px 5px 5px 10px;;margin-top: 2%">
                                    <input type="radio" disabled name="isoption2" (click)="option2clicked()"
                                    [(ngModel)]="isoption2" formControlName="option2" [value]="true">
                                    <div id="mathtwo" mathjax style="margin-left: 5%;">
                                       
                                        {{answerlist[questioncount].option2}}
                                    </div>
                                </div>
                
                                <div *ngIf="answerlist[questioncount].option2Images != undefined">
                                    <div *ngFor="let path of answerlist[questioncount].option2Images">
                                        <div *ngIf="path.includes('http')">
                                            <img src= {{path}} alt="something" style="width:150px;height:100px;"/>
                                        </div>
                                        <div id="mathtwo" *ngIf="!path.includes('http')"  mathjax>
                                            {{path}}
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div class="col-lg-1">

                        </div>

                        <div class="col-lg-5" id="option3id"
                            style="padding: 15px 15px 15px 15px;margin-top: 5%;box-shadow: 0px 0px 1px 0px">
                            <input type="radio" disabled (click)="option3clicked()" [(ngModel)]="isoption3"
                                formControlName="option3" [value]="true">
                                <!-- <span
                                style="margin-left: 3%;font-size: 13px;word-break: break-word;" id="maththree" mathjax>{{answerlist[questioncount].option3}}</span> -->
                                <div *ngIf="answerlist[questioncount].option3Images == undefined" class="row" style="padding: 5px 5px 5px 10px;;margin-top: 2%">
                                    <input type="radio" disabled (click)="option3clicked()" [(ngModel)]="isoption3"
                                    formControlName="option3" [value]="true">
                                    <div id="maththree" mathjax style="margin-left: 5%;">
                                       
                                        {{answerlist[questioncount].option3}}
                                    </div>
                                </div>
                
                                <div *ngIf="answerlist[questioncount].option3Images != undefined">
                                    <div *ngFor="let path of answerlist[questioncount].option3Images">
                                        <div *ngIf="path.includes('http')">
                                            <img src= {{path}} alt="something" style="width:150px;height:100px;"/>
                                        </div>
                                        <div id="maththree" *ngIf="!path.includes('http')"  mathjax>
                                            {{path}}
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div class="col-lg-1">

                        </div>

                        <div class="col-lg-5" id="option4id"
                            style="padding: 15px 15px 15px 15px;margin-top: 5%;box-shadow: 0px 0px 1px 0px">
                           
                                <!-- <span
                                style="margin-left: 3%;font-size: 13px;word-break: break-word;" id="mathfour" mathjax>{{answerlist[questioncount].option4}}</span> -->
                                <div *ngIf="answerlist[questioncount].option4Images == undefined" class="row" style="padding: 5px 5px 5px 10px;;margin-top: 2%">
                                    <input type="radio" disabled (click)="option4clicked()" name="isoption4"
                                    [(ngModel)]="isoption4" formControlName="option4" [value]="true">
                                    <div id="mathfour" mathjax style="margin-left: 5%;">
                                       
                                        {{answerlist[questioncount].option4}}
                                    </div>
                                </div>
                
                                <div *ngIf="answerlist[questioncount].option4Images != undefined">
                                    <div *ngFor="let path of answerlist[questioncount].option4Images">
                                        <div *ngIf="path.includes('http')">
                                            <img src= {{path}} alt="something" style="width:150px;height:100px;"/>
                                        </div>
                                        <div id="mathfour" *ngIf="!path.includes('http')"  mathjax>
                                            {{path}}
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <!-- End of Dropdown options with multiselect-->
                    <!-- Dropdown options with single select-->
                    <div class="row" style="padding: 5px 5px 5px 10px;"
                        *ngIf="answerlist[questioncount].correctAnswer.length == 1 && answerlist[questioncount].questionType==1">

                        <div class="col-lg-5" id="option1id"
                            style="padding: 15px 15px 15px 15px;margin-top: 5%;box-shadow: 0px 0px 1px 0px">
                           
                                <!-- <span
                                style="margin-left: 3%;font-size: 13px;word-break: break-word;" id="mathone" mathjax>{{answerlist[questioncount].option1}}</span> -->
                                <div *ngIf="answerlist[questioncount].option1Images == undefined" class="row" style="padding: 5px 5px 5px 10px;;margin-top: 2%">
                                    <input type="radio" disabled name="category" formControlName="option1"
                                    [(ngModel)]="isoption1" [value]="true">
                                    <div id="mathone" mathjax style="margin-left: 5%;">
                                       
                                        {{answerlist[questioncount].option1}}
                                    </div>
                                </div>
                
                                <div *ngIf="answerlist[questioncount].option1Images != undefined">
                                    <div *ngFor="let path of answerlist[questioncount].option1Images">
                                        <div *ngIf="path.includes('http')">
                                            <img src= {{path}} alt="something" style="width:150px;height:100px;"/>
                                        </div>
                                        <div id="mathone" *ngIf="!path.includes('http')"  mathjax>
                                            {{path}}
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div class="col-lg-1">

                        </div>

                        <div class="col-lg-5" id="option2id"
                            style="padding: 15px 15px 15px 15px;margin-top: 5%;box-shadow: 0px 0px 1px 0px">
                           
                                <!-- <span
                                style="margin-left: 3%;font-size: 13px;word-break: break-word;" id="mathtwo" mathjax>{{answerlist[questioncount].option2}}</span> -->
                                <div *ngIf="answerlist[questioncount].option2Images == undefined" class="row" style="padding: 5px 5px 5px 10px;;margin-top: 2%">
                                    <input type="radio" disabled name="category" formControlName="option2"
                                    [(ngModel)]="isoption2" [value]="true">
                                    <div id="mathtwo" mathjax style="margin-left: 5%;">
                                       
                                        {{answerlist[questioncount].option2}}
                                    </div>
                                </div>
                
                                <div *ngIf="answerlist[questioncount].option2Images != undefined">
                                    <div *ngFor="let path of answerlist[questioncount].option2Images">
                                        <div *ngIf="path.includes('http')">
                                            <img src= {{path}} alt="something" style="width:150px;height:100px;"/>
                                        </div>
                                        <div id="mathtwo" *ngIf="!path.includes('http')"  mathjax>
                                            {{path}}
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div class="col-lg-1">

                        </div>

                        <div class="col-lg-5" id="option3id"
                            style="padding: 15px 15px 15px 15px;margin-top: 5%;box-shadow: 0px 0px 1px 0px">
                           
                                <!-- <span
                                style="margin-left: 3%;font-size: 13px;word-break: break-word;" id="maththree" mathjax>{{answerlist[questioncount].option3}}</span> -->
                                <div *ngIf="answerlist[questioncount].option3Images == undefined" class="row" style="padding: 5px 5px 5px 10px;;margin-top: 2%">
                                    <input type="radio" disabled name="category" formControlName="option3"
                                    [(ngModel)]="isoption3" [value]="true">
                                    <div id="maththree" mathjax  style="margin-left: 5%;">
                                       
                                        {{answerlist[questioncount].option3}}
                                    </div>
                                </div>
                
                                <div *ngIf="answerlist[questioncount].option3Images != undefined">
                                    <div *ngFor="let path of answerlist[questioncount].option3Images">
                                        <div *ngIf="path.includes('http')">
                                            <img src= {{path}} alt="something" style="width:150px;height:100px;"/>
                                        </div>
                                        <div id="maththree" *ngIf="!path.includes('http')"  mathjax>
                                            {{path}}
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div class="col-lg-1">

                        </div>

                        <div class="col-lg-5" id="option4id"
                            style="padding: 15px 15px 15px 15px;margin-top: 5%;box-shadow: 0px 0px 5px 0px">
                           
                                <!-- <span
                                style="margin-left: 3%;font-size: 13px;word-break: break-word;" id="mathfour" mathjax>{{answerlist[questioncount].option4}}</span> -->
                                <div *ngIf="answerlist[questioncount].option4Images == undefined" class="row" style="padding: 5px 5px 5px 10px;;margin-top: 2%">
                                    <input type="radio" disabled name="category" formControlName="option4"
                                    [(ngModel)]="isoption4" [value]="true">
                                    <div id="mathfour" mathjax style="margin-left: 5%;">
                                       
                                        {{answerlist[questioncount].option4}}
                                    </div>
                                </div>
                
                                <div *ngIf="answerlist[questioncount].option4Images != undefined">
                                    <div *ngFor="let path of answerlist[questioncount].option2Images">
                                        <div *ngIf="path.includes('http')">
                                            <img src= {{path}} alt="something" style="width:150px;height:100px;"/>
                                        </div>
                                        <div id="mathfour" *ngIf="!path.includes('http')"  mathjax>
                                            {{path}}
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <!-- End of Dropdown options with single select-->
                    <!-- start of Questions with textbox and image-->
                    <div style="padding: 5px 5px 5px 10px;" *ngIf="answerlist[questioncount].questionType==3">
                        <div class="row" *ngIf="isquestiontext" style="padding: 5px 20px 5px 10px;">
                            <p style="font-weight: 700">Student Answer:</p>
                            <br>
                            <p style="word-break: break-all;">{{answerlist[questioncount].studentAnswer}}</p>
                        </div>

                        <div class="row" *ngIf="isquestiontextnull" style="padding: 5px 20px 5px 10px;">
                            <p style="font-weight: 700">Student Answer :</p>
                            <br>
                             <p style="word-break: break-all;">No answer provided</p>
                            </div>


                        <!-- start of Questions with textbox and image-->
                        <div class="row" *ngIf="isquestionimage" style="padding: 5px 20px 5px 10px;">
                            <p style="font-weight: 700">Student Answered in Image:</p>
                            <br>
                            <!-- <img [src]="answerlist[questioncount].studentAnswer" data-toggle="modal"
                                data-target="#myModal1" alt="Snow" style="width: 200px;height: 200px;max-width:300px"> -->
                        </div>


                    </div>
                    <!-- End of Questions with textbox and image-->
                </form>
                <div *ngIf="answerlist[questioncount].questionType==3">
                    <h6 style="margin-top: 2%;color: red;font-weight: 600;">Remarks:</h6>
                    <p *ngIf="answerlist[questioncount].remarks!=''" style="word-break: break-word;">
                        {{answerlist[questioncount].remarks}}</p>

                </div>
                <div style="margin-top: 1%;">
                    <h6 style="margin-top: 2%;color: #4CAF50;font-weight: 600;">Solution:</h6>
                    <!-- <p *ngIf="answerlist[questioncount].solution!=''" style="word-break: break-word;" id="mathsolution" mathjax>
                        {{answerlist[questioncount].solution}}</p>
                    <p *ngIf="answerlist[questioncount].solution == null" style="word-break: break-word;">No solutions
                        Available!</p> -->
                    <div *ngIf="answerlist[questioncount].solutionImages == undefined" class="row" style="padding: 5px 5px 5px 10px;;margin-top: 2%">
                        <div id="mathdiv" mathjax >
                            
                            <p *ngIf="answerlist[questioncount].solution!=''" style="word-break: break-word;" id="mathsolution" mathjax>
                                {{answerlist[questioncount].solution}}</p>
                            <p *ngIf="answerlist[questioncount].solution == null" style="word-break: break-word;">No solutions
                                Available!</p>
                        </div>
                    </div>
    
                    <div *ngIf="answerlist[questioncount].solutionImages != undefined">
                        <div *ngFor="let path of answerlist[questioncount].solutionImages">
                            <div *ngIf="path.includes('http')">
                                <img src= {{path}} alt="something" style="width:150px;height:100px;"/>
                            </div>
                            <div id="mathdiv" *ngIf="!path.includes('http')"  mathjax>
                                {{path}}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" style="margin-top:5%;padding-bottom: 2%">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="row">
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <a type="submit" (click)="previous()" class="button"
                                    style=" font-size: 13px; border: solid 1px #c7c1c1;background-color: #b7b5b7;color: black;"><span
                                        class="label" style="font-weight: 600;">Previous</span></a>
                            </div>
                            <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1">
        
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
        
                            </div>
                            <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1">
        
                            </div>
        
                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <a type="submit" class="button"
                                    style=" font-size: 13px;background-color: var(--mainColor);color: white;"
                                    (click)="onSubmit(selectedoptionform)"><span class="label"
                                        style="font-weight: 600;">Next</span></a>
                            </div>
                        </div>
                    </div>
                
        
        
        
        
                </div>
            </div>
            <!-- End Of Question with options -->

            <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12">
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12"
                style="box-shadow: 0px 0px 1px 0px;padding: 5px 5px 5px 10px;">
                <div class="instructor-details" style="padding-top: 5%;">
                    <div class="row">
                        <div class="col-lg-6">
                            <a type="submit" *ngIf="istobereviewed" class="button" data-toggle="modal" data-target="#myModal2"  style=" margin-left: 10px;"><span
                                class="label" style="font-weight: 600;">Review Test</span></a>
                        </div>
                        <div class="col-lg-6">
                            <a type="submit" class="button" (click)="back()" style=" margin-left: 10px;"><span
                                    class="label" style="font-weight: 600;">Back</span></a>
                        </div>
                    </div>
                    <div class="row" style="margin-top: 3%;">
                        <div class="col-lg-4">
                            <p style="font-size: 12px;"><span class="greendot"></span> {{answeredcount}} Answered</p>
                        </div>
                        <div class="col-lg-4">
                            <p style="font-size: 12px;"><span class="orangedot"></span> {{reviewedcount}} Marked</p>
                        </div>
                        <div class="col-lg-4">
                            <p style="font-size: 12px;"><span class="yellowdot"></span> {{unansweredcount}} Unanswered
                            </p>
                        </div>
                    </div>
                    <div class="row" style="margin-top: 3%;" *ngIf="subjectname1!=''">
                        <div class="col-lg-10 col-md-10">
                        <h6 style="margin-left: 1%;margin-bottom: 2%;font-size: 20px;font-weight: 700;">{{subjectname1}}</h6>
                    </div>
                     <div class="col-lg-2 col-md-2" *ngIf="subjectname1arrowdown">
                        <img src="../../../../assets/img/down-arrow.png" (click)="subjectname1arrowdownfunction()" style="height: 20px;width: 20px;" alt="image">
                    </div>
                    <div class="col-lg-2 col-md-2" *ngIf="subjectname1arrowup">
                        <img src="../../../../assets/img/up-arrow.png" (click)="subjectname1arrowupfunction()"style="height: 20px;width: 20px;" alt="image">
                    </div>
                    <ng-container *ngIf="subjectname1arrayquestion">
                        <div  style="margin-top: 3%;" *ngFor="let subjects1 of subjectname1array; let i = index" >
                            <a type="submit" (click)="questionnumberbutton(subjects1.questionNumber)" *ngIf="subjects1.status=='Answered'" class="buttontext"
                                style=" margin-left: 10px;margin-top:2%;background-color: #00adee;color: #b7b5b7;"><span
                                    class="label" style="font-weight: 600;"
                                    >{{subjects1.questionNumber}}</span></a>
                            <a type="submit" *ngIf="subjects1.status=='Unanswered'" class="buttontext"
                                style=" margin-left: 10px;margin-top:2%" (click)="questionnumberbutton(subjects1.questionNumber)"><span
                                    class="label" style="font-weight: 600;">{{subjects1.questionNumber}}</span></a>
                            <a type="submit" (click)="questionnumberbutton(subjects1.questionNumber)" *ngIf="subjects1.status=='Reviewed'" class="buttontext"
                                style=" margin-left: 10px;margin-top:2%;background-color: #fd7649;color: #b7b5b7;"><span
                                    class="label" style="font-weight: 600;"
                                    >{{subjects1.questionNumber}}</span></a>
                    </div>
                </ng-container>
                    </div>

                    <hr *ngIf="subjectname1!=''">

                    <div class="row" style="margin-top: 3%;" *ngIf="subjectname2!=''">
                        <div class="col-lg-10 col-md-10">
                        <h6 style="margin-left: 1%;margin-bottom: 2%;font-size: 20px;font-weight: 700;">{{subjectname2}}</h6>
                        </div>
                        <div class="col-lg-2 col-md-2" *ngIf="subjectname2arrowdown">
                            <img src="../../../../assets/img/down-arrow.png" (click)="subjectname2arrowdownfunction()" style="height: 20px;width: 20px;" alt="image">
                        </div>
                        <div class="col-lg-2 col-md-2" *ngIf="subjectname2arrowup">
                            <img src="../../../../assets/img/up-arrow.png" (click)="subjectname2arrowupfunction()"style="height: 20px;width: 20px;" alt="image">
                        </div>
                        <ng-container *ngIf="subjectname2arrayquestion">
                        <div  style="margin-top: 3%;" *ngFor="let subjects2 of subjectname2array; let i = index">
                            <a type="submit" (click)="questionnumberbutton(subjects2.questionNumber)" *ngIf="subjects2.status=='Answered'" class="buttontext"
                                style=" margin-left: 10px;margin-top:2%;background-color: #00adee;color: white;"><span
                                    class="label" style="font-weight: 600;"
                                    >{{subjects2.questionNumber}}</span></a>
                            <a type="submit" *ngIf="subjects2.status=='Unanswered'" class="buttontext"
                                style=" margin-left: 10px;margin-top:2%" (click)="questionnumberbutton(subjects2.questionNumber)"><span
                                    class="label" style="font-weight: 600;">{{subjects2.questionNumber}}</span></a>
                            <a type="submit" (click)="questionnumberbutton(subjects2.questionNumber)" *ngIf="subjects2.status=='Reviewed'" class="buttontext"
                                style=" margin-left: 10px;margin-top:2%;background-color: #fd7649;color: white;"><span
                                    class="label" style="font-weight: 600;"
                                    >{{subjects2.questionNumber}}</span></a>
                    </div>
                    </ng-container>
                    </div>

                    <hr *ngIf="subjectname2!=''">

                    <div class="row" style="margin-top: 3%;" *ngIf="subjectname3!=''">
                        <div class="col-lg-10 col-md-10">
                        <h6 style="margin-left: 1%;margin-bottom: 2%;font-size: 20px;font-weight: 700;">{{subjectname3}}</h6>
                        </div>
                        <div class="col-lg-2 col-md-2" *ngIf="subjectname3arrowdown">
                            <img src="../../../../assets/img/down-arrow.png" (click)="subjectname3arrowdownfunction()" style="height: 20px;width: 20px;" alt="image">
                        </div>
                        <div class="col-lg-2 col-md-2" *ngIf="subjectname3arrowup">
                            <img src="../../../../assets/img/up-arrow.png" (click)="subjectname3arrowupfunction()"style="height: 20px;width: 20px;" alt="image">
                        </div>
                        <ng-container *ngIf="subjectname3arrayquestion">
                        <div  style="margin-top: 3%;" *ngFor="let subjects3 of subjectname3array; let i = index">
                        
                            <a type="submit" (click)="questionnumberbutton(subjects3.questionNumber)" *ngIf="subjects3.status=='Answered'" class="buttontext"
                                style=" margin-left: 10px;margin-top:2%;background-color: #00adee;color: white;"><span
                                    class="label" style="font-weight: 600;"
                                    >{{subjects3.questionNumber}}</span></a>
                            <a type="submit" *ngIf="subjects3.status=='Unanswered'" class="buttontext"
                                style=" margin-left: 10px;margin-top:2%" (click)="questionnumberbutton(subjects3.questionNumber)"><span
                                    class="label" style="font-weight: 600;">{{subjects3.questionNumber}}</span></a>
                            <a type="submit" (click)="questionnumberbutton(subjects3.questionNumber)" *ngIf="subjects3.status=='Reviewed'" class="buttontext"
                                style=" margin-left: 10px;margin-top:2%;background-color: #fd7649;color: white;"><span
                                    class="label" style="font-weight: 600;"
                                    >{{subjects3.questionNumber}}</span></a>
                    </div>
                    </ng-container>
                    </div>

                    <hr *ngIf="subjectname3!=''">

                    <div class="row" style="margin-top: 3%;" *ngIf="subjectname4!=''">
                        <div class="col-lg-10 col-md-10">
                        <h6 style="margin-left: 1%;margin-bottom: 2%;font-size: 20px;font-weight: 700;">{{subjectname4}}</h6>
                        </div>
                        <div class="col-lg-2 col-md-2" *ngIf="subjectname4arrowdown">
                            <img src="../../../../assets/img/down-arrow.png" (click)="subjectname4arrowdownfunction()" style="height: 20px;width: 20px;" alt="image">
                        </div>
                        <div class="col-lg-2 col-md-2" *ngIf="subjectname4arrowup">
                            <img src="../../../../assets/img/up-arrow.png" (click)="subjectname4arrowupfunction()"style="height: 20px;width: 20px;" alt="image">
                        </div>
                        <ng-container *ngIf="subjectname4arrayquestion">
                        <div  style="margin-top: 3%;" *ngFor="let subjects4 of subjectname4array; let i = index">
                            <a type="submit" (click)="questionnumberbutton(subjects4.questionNumber)" *ngIf="subjects4.status=='Answered'" class="buttontext"
                                style=" margin-left: 10px;margin-top:2%;background-color: #00adee;color: white;"><span
                                    class="label" style="font-weight: 600;"
                                    >{{subjects4.questionNumber}}</span></a>
                            <a type="submit" *ngIf="subjects4.status=='Unanswered'" class="buttontext"
                                style=" margin-left: 10px;margin-top:2%" (click)="questionnumberbutton(subjects4.questionNumber)"><span
                                    class="label" style="font-weight: 600;">{{subjects4.questionNumber}}</span></a>
                            <a type="submit" (click)="questionnumberbutton(subjects4.questionNumber)" *ngIf="subjects4.status=='Reviewed'" class="buttontext"
                                style=" margin-left: 10px;margin-top:2%;background-color: #fd7649;color: white;"><span
                                    class="label" style="font-weight: 600;"
                                    >{{subjects4.questionNumber}}</span></a>
                    </div>
                    </ng-container>
                    </div>
                    <!-- <div class="row">
                        <div id="ck-button">
                            <label>
                                <input type="checkbox" value="1"><span>1</span>
                            </label>
                        </div>
                        <div id="ck-button">
                            <label>
                                <input type="checkbox" value="1"><span>2</span>
                            </label>
                        </div>
                        <div id="ck-button">
                            <label>
                                <input type="checkbox" value="1"><span>3</span>
                            </label>
                        </div>
                        <div id="ck-button">
                            <label>
                                <input type="checkbox" value="1"><span>4</span>
                            </label>
                        </div>
                        <div id="ck-button">
                            <label>
                                <input type="checkbox" value="1"><span>5</span>
                            </label>
                        </div>
                        <div id="ck-button">
                            <label>
                                <input type="checkbox" value="1"><span>6</span>
                            </label>
                        </div>
                        <div id="ck-button">
                            <label>
                                <input type="checkbox" value="1"><span>7</span>
                            </label>
                        </div>

                    </div> -->
                </div>
            </div>
        </div>

        <div class="row" style="margin-top:3%">
            <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                       
                    </div>
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1">

                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">

                    </div>
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1">

                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                      
                    </div>
                </div>
            </div>
            <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1">
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4"
                style="box-shadow: 0px 0px 1px 0px;padding: 5px 5px 5px 5px;">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <h6>Total Marks</h6>
                        <br>
                        <p style="text-align: center;font-weight: 600;">{{totalmarksforquestion}}</p>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">

                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <h6>Marks Obtained</h6>
                        <br>
                        <p *ngIf="totalmarksobtainedforquestion >= 0"
                            style="text-align: center;color: green;font-weight: 600;">{{totalmarksobtainedforquestion}}
                        </p>
                        <p *ngIf="totalmarksobtainedforquestion < 0"
                            style="text-align: center;color: red;font-weight: 600;">{{totalmarksobtainedforquestion}}
                        </p>
                    </div>
                </div>
            </div>




        </div>

    </div>
</section>