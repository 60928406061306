<div *ngIf="isstudent">
    <app-header-style-one></app-header-style-one>
</div>
<div *ngIf="isteacher">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>

<!-- <div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Contact</li>
            </ul>
            <h2>Contact Us</h2>
        </div>
    </div>
</div> -->

<section class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30" style="height: 100%;">
                    <div class="icon">
                        <i class='bx bx-envelope'></i>
                    </div>
                    <h3>Email Here</h3>
                    <p><a href="mailto:care@buddi.life">care@buddi.life</a></p>
                    <p><a href="mailto:partners@buddi.life">partners@buddi.life</a></p>
                    <p><a href="mailto:sales@buddi.life">sales@buddi.life</a></p>
                    <p><a href="mailto:invest@buddi.life">invest@buddi.life</a></p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30" style="height: 100%;">
                    <div class="icon">
                        <i class='bx bx-map'></i>
                    </div>
                    <h3>Location Here</h3>
                    <!-- <p><a href="https://goo.gl/maps/Mii9keyeqXeNH4347" target="_blank">Scentric Networks Pvt. Ltd.<br>
                            Sriram Centre, No,67, 25, 2nd St, <br> TNHB Colony,
                            Korattur.<br> Chennai- 600 080.Tamil Nadu.</a></p> -->
                            <p>Scentric Networks Private Limited<br>
                                 Chennai, Tamil Nadu.</p>

                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="contact-info-box mb-30" style="height: 100%;">
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>
                    <h3>Call Here</h3>
                    <p><a href="tel:+919884890048">+91 984-084-4987</a></p>

                </div>
            </div>
        </div>
    </div>

    <div id="particles-js-circle-bubble-2"></div>
</section>

<section class="contact-area pb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Contact Us</span>
            <!-- <h2>Drop us Message for any Query</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>-->
        </div>

        <div class="contact-form">
            <form id="contactForm" [formGroup]="contactform" (ngSubmit)="onSubmit(contactform)">
                <div class="row">
                    <!-- <div class="col-lg-12 mt-2" style="margin-bottom: 5%;">         -->
                        <!-- <label>User Type <span class="required" style="color: red;">*</span></label>
                        <br>              -->
                            <!-- <input type="radio" name="classType"  value="Teacher"
                                formControlName="classType"> Teacher
                            <input type="radio" name="classType" value="Student"
                                formControlName="classType"> Student                    
                    </div> -->
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input type="text" placeholder="Your Name" maxlength="100" class="form-control" value=""
                            formControlName="name" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                            <div *ngIf="f.name.errors?.required">Name required</div>
                        </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input type="text" placeholder="Your Email" formControlName="email" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                        </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input type="number" placeholder="Your Phone Number" maxlength="100" class="form-control" value=""
                            formControlName="phonenumber" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }">
                        <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                            <div *ngIf="f.phonenumber.errors?.required">Phone Number required</div>
                        </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input type="text" placeholder="Your Subject" maxlength="100" class="form-control" value=""
                            formControlName="subject" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.subject.errors }">
                        <div *ngIf="submitted && f.subject.errors" class="invalid-feedback">
                            <div *ngIf="f.subject.errors?.required">Subject required</div>
                        </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <input type="text" placeholder="Your Message" maxlength="100" class="form-control" value=""
                            formControlName="message" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.message.errors }">
                        <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                            <div *ngIf="f.message.errors?.required">Message required</div>
                        </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <button type="submit" class="default-btn"><i
                                class='bx bx-paper-plane icon-arrow before'></i><span class="label">Send
                                Message</span><i class="bx bx-paper-plane icon-arrow after"></i></button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div id="particles-js-circle-bubble-3"></div>
</section>

