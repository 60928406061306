import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { ApiService } from './shared/api.service'
declare let $: any;
import { fromEvent, Observable, Subscription } from 'rxjs';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;
    onlineEvent: Observable<Event>;
    offlineEvent: Observable<Event>;
    subscriptions: Subscription[] = [];
    public isOffline: Boolean = false;
    constructor(private router: Router, private locationBack: Location, private activatedRoute: ActivatedRoute, private apiService: ApiService) {
    }

    ngOnInit() {

        this.offlineEvent = fromEvent(window, 'offline');
        this.onlineEvent = fromEvent(window, 'online');
        this.recallJsFuntions();

        this.subscriptions.push(this.onlineEvent.subscribe(e => {
            console.log('Online...');
            this.isOffline = false;
            this.locationBack.back()
        }))

        this.subscriptions.push(this.offlineEvent.subscribe(e => {
            console.log('Offline...');
            this.isOffline = true;
            this.router.navigate(['/nointernet']);
        }))

        this.router.events.pipe(
            filter(e => e instanceof NavigationEnd),
            map(e => this.activatedRoute),
            map((route) => {
                while (route.firstChild) route = route.firstChild;
                return route;
            }),
            filter((route) => route.outlet === 'primary'),
            mergeMap((route) => route.data),
        ).subscribe(data => {
            let seoData = data['seo'];
            if (seoData) {
                this.apiService.updateTitle(seoData['title']);
                console.log('seoData', seoData['title']);

                this.apiService.updateMetaTags(seoData['metaTags']);

            }

        });

    }
    recallJsFuntions() {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationStart) {
                    $('.preloader').fadeIn('slow');
                }
            });
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                $.getScript('../assets/js/main.js');
                $('.preloader').fadeOut('slow');
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }
}