import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../shared/api.service';
@Component({
  selector: 'app-profile-advanced',
  templateUrl: './profile-advanced.component.html',
  styleUrls: ['./profile-advanced.component.scss']
})
export class ProfileAdvancedComponent implements OnInit {
  public isStudent = false;
  public isTeacher = false;
  public isstudent = false;
  public isteacher = false;
  iskyc: boolean = false;
  userprofdata: any;
  categorynamearray = '';
  boardnamearray = '';
  subjectnamearray = '';
  languagenamearray = '';
  gradenamearray = '';
  id : any ;
  public bankCheck:any = {};
  public bankDetails:any = {};

  constructor(private userApi: ApiService) { }

  ngOnInit(): void {
    this.iskyc = localStorage.getItem('kycStatus') == 'true' ? true : false;
    if(!this.iskyc){
      // this.router.navigateByUrl('/kyc');
    }
    this.getUserdetails();
    this.getKyclist();
    const userType = localStorage.getItem('userType');      
    if(userType.toLowerCase()=="teacher") {
      this.isteacher = true;
    }
    else {
      this.isstudent = true;
    }
  }
  getUserdetails() {
    const webToken = localStorage.getItem('webtoken');
    this.userApi.Getteacherallpopulate(webToken).subscribe((result) => {
      this.id = result.data._id;
      this.userprofdata = result.data;
      this.categorynamearray = result.data.categories;
      this.gradenamearray = result.data.grades;
      this.boardnamearray = result.data.boards;
      this.subjectnamearray = result.data.subjects;
      this.languagenamearray = result.data.preferredLanguage;      
      // this.bankCheck.isFullTime = result.data.modeofEngagement!=undefined && result.data.modeofEngagement.length!=0? result.data.modeofEngagement[0].employmentType =='Full -Time' ? true : false: false;
      this.bankCheck.isBank = result.data.bankName !=undefined &&  result.data.bankName !=""? true : false;
      if(this.bankCheck.isBank){
        this.bankDetails.accountName = result.data.accountName;
        this.bankDetails.accountNumber = result.data.accountNumber;
        this.bankDetails.bankName = result.data.bankName;
        this.bankDetails.bankBranch = result.data.bankBranch;
        this.bankDetails.ifscCode = result.data.ifscCode;
        this.bankDetails.accountType = result.data.accountType;
      }
    }, (err) => {
      console.log(err);
    });
  }
  getKyclist(){
    const webToken = localStorage.getItem('webtoken');

    this.userApi.Kyclist(webToken).subscribe((result) => {
    
   console.log('result',result);
      },
   (err) => {
      console.log(err);
    });
  }
}
