<section class="home-slides slider owl-carousel owl-theme">
    <div class="main-banner ">
        <div class="d-table">
            <div class="d-table-cell" style="background-color: #dcf5ff  ;">
                <div class="container">
                    <div class="main-banner-content" >
                        <div class="row">
                            <div class="col-lg-7 col-md-7 col-sm-7" style="padding-bottom: 10px;">
                                <span class="sub-title" style="color: black;">buddi.life</span>
                                <h2 style="color:var(--mainColor);" class="buddititle">Micro Learning Approach </h2>
                                <h2 style="color: black; " class="buddisub">Your learners can learn just what they need. One topic at a time
                                </h2>
                                
                               <!--  <a class="default-btn mr-2 p-3 " style="cursor: pointer;" title="Click here" (click)="neetcrash()">buddi-NEET 2021</a> -->
                               <!-- <span style="color:var(--mainColor) ;">NEET</span> -->
                            </div>
                            <div class="col-lg-5 col-md-5 col-sm-5 hovervideo" >
                                <img src="../../../../../assets/img/bannerslide1.png" alt="bannerslide1"  data-toggle="modal" data-target="#myModal1" data-backdrop="false" style="cursor: pointer; border-radius: 10px;">
                                <!-- <iframe class="iframe" src="https://www.youtube.com/embed/Wzm15soEfOE?enablejsapi=1&version=3&playerapiid=ytplayer" frameborder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                                <!-- <div class="overlay1" data-toggle="modal" data-target="#myModal1" data-backdrop="false">
                                     <a  style="color: #FF0000;" class="icon">
                                <i c class='bx bxs-right-arrow'></i>
                            </a> 
                            </div> -->
                            </div>
                            <!-- <div  class="modal" id="myModal1">
                                <div class="modal-dialog" >
                                  <div class="modal-content" style="border-radius: 13px;">
                                    <div class="modal-body">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true"><b>&times;</b></span>
                                          </button>
                                        <iframe class="iframe" src="https://www.youtube.com/embed/JrnREWj7nQw?enablejsapi=1&version=3&playerapiid=ytplayer" frameborder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>

                                  </div>
                                </div>
                              </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="main-banner ">
        <div class="d-table">
            <div class="d-table-cell" style="background-color: #dcf5ff  ;">
                <div class="container">
                    <div class="main-banner-content" >
                        
                        <div class="row " >
                            <div class="col-lg-7 col-md-7 col-sm-7" style="padding-bottom: 10px;">
                                <span class="sub-title" style="color: black;">buddi.life</span>
                                <h2 style="color:var(--mainColor);" class="buddititle">Learning Platform for all type of Institutions </h2>
                                <h2 style="color: black;" class="buddisub">Partner – Enroll – Scale.
                                </h2>
                                
                                <!-- <a class="default-btn mr-2 p-3 " style="cursor: pointer;" title="Click here" (click)="k12()">K-12 2021</a> -->
                            
                            </div>
                            <div class="col-lg-5 col-md-5 col-sm-5 hovervideo">
                               <img src="../../../../../assets/img/bannerslider2Teacher.png" alt="bannerslider2Teacher" style="border-radius: 10px;" data-toggle="modal" data-target="#myModal2" data-backdrop="false">
                                <!-- <iframe class="iframe" src="https://www.youtube.com/embed/w0Z_X8EBUfk?enablejsapi=1&version=3&playerapiid=ytplayer" frameborder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                                <!-- <div class="overlay" data-toggle="modal" data-target="#myModal2" data-backdrop="false">
                                     <a style="color: #FF0000;" class="icon">
                                <i  class='bx bxs-right-arrow'></i>
                            </a> 
                            </div> -->
                            </div>
                            <!-- <div  class="modal" id="myModal2">
                                <div class="modal-dialog">
                                  <div class="modal-content" style="border-radius: 13px;">
                                    <div class="modal-body">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true"><b>&times;</b></span>
                                          </button>
                                          <iframe class="iframe" src="https://www.youtube.com/embed/w0Z_X8EBUfk?enablejsapi=1&version=3&playerapiid=ytplayer" frameborder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>

                                  </div>
                                </div>
                              </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

      <div class="main-banner ">
        <div class="d-table">
            <div class="d-table-cell" style="background-color: #dcf5ff  ;">
                <div class="container">
                    <div class="main-banner-content" >
                        
                        <div class="row " >
                            <div class="col-lg-7 col-md-7 col-sm-7" style="padding-bottom: 10px;">
                                <span class="sub-title" style="color: black;">buddi.life</span>
                                <h2 style="color:var(--mainColor);" class="buddititle">Over 800+ Courses options </h2>
                                <h2 style="color: black;" class="buddisub">800+ courses. Tutor can offer any course.
                                </h2>
                                
                                <!-- <a class="default-btn mr-2 p-3 " style="cursor: pointer;" title="Click here" (click)="k12()">K-12 2021</a> -->
                            
                            </div>
                            <div class="col-lg-5 col-md-5 col-sm-5 hovervideo">
                               <img src="../../../../../assets/img/bannerslide4.png" alt="bannerslide4" style="border-radius: 10px;" data-toggle="modal" data-target="#myModal2" data-backdrop="false">
                                <!-- <iframe class="iframe" src="https://www.youtube.com/embed/w0Z_X8EBUfk?enablejsapi=1&version=3&playerapiid=ytplayer" frameborder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                                <!-- <div class="overlaycoding" data-toggle="modal" data-target="#myModal4" data-backdrop="false">
                                     <a style="color: #FF0000;" class="icon">
                                <i  class='bx bxs-right-arrow'></i>
                            </a> 
                            </div> -->
                            </div>
                            <!-- <div  class="modal" id="myModal4">
                                <div class="modal-dialog">
                                  <div class="modal-content" style="border-radius: 13px;">
                                    <div class="modal-body">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true"><b>&times;</b></span>
                                          </button>
                                          <iframe class="iframe" src="https://www.youtube.com/embed/j0cKS6KGgDU?enablejsapi=1&version=3&playerapiid=ytplayer" frameborder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>

                                  </div>
                                </div>
                              </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="main-banner ">
        <div class="d-table">
            <div class="d-table-cell" style="background-color: #dcf5ff  ;">
                <div class="container">
                    <div class="main-banner-content" >
                        
                        <div class="row " >
                            <div class="col-lg-7 col-md-7 col-sm-7" style="padding-bottom: 10px;">
                                <span class="sub-title" style="color: black;">buddi.life</span>
                                <h2 style="color:var(--mainColor);" class="buddititle">Certificates on Blockchain </h2>
                                <h2 style="color: black;" class="buddisub"> Safe - Secure - Verifiable. Store your learner's certificates on Blockchain
                                </h2>
                                
                                <!-- <a class="default-btn mr-2 p-3 " style="cursor: pointer;" title="Click here" (click)="k12()">K-12 2021</a> -->
                            
                            </div>
                            <div class="col-lg-5 col-md-5 col-sm-5 hovervideo">
                               <img src="../../../../../assets/img/bannerslide5.png" alt="bannerslide5" style="border-radius: 10px;" data-toggle="modal" data-target="#myModal5" data-backdrop="false">
                                <!-- <iframe class="iframe" src="https://www.youtube.com/embed/w0Z_X8EBUfk?enablejsapi=1&version=3&playerapiid=ytplayer" frameborder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                                <!-- <div class="overlay5" data-toggle="modal" data-target="#myModal5" data-backdrop="false">
                                     <a style="color: #FF0000;" class="icon">
                                <i  class='bx bxs-right-arrow'></i>
                            </a> 
                            </div> -->
                            </div>
                            <!-- <div  class="modal" id="myModal5">
                                <div class="modal-dialog">
                                  <div class="modal-content" style="border-radius: 13px;">
                                    <div class="modal-body">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true"><b>&times;</b></span>
                                          </button>
                                          <iframe class="iframe" src="https://www.youtube.com/embed/D8726i60q0A?enablejsapi=1&version=3&playerapiid=ytplayer" frameborder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>

                                  </div>
                                </div>
                              </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- <div class="main-banner ">
        <div class="d-table">
            <div class="d-table-cell" style="background-color: #dcf5ff  ;">
                <div class="container">
                    <div class="main-banner-content" >
                        
                        <div class="row " >
                            <div class="col-lg-7 col-md-7 col-sm-7" style="padding-bottom: 10px;">
                                <span class="sub-title" style="color: black;">buddi.life</span>
                                <h2 style="color:var(--mainColor);" class="buddititle">Micro Learning</h2>
                                <h2 style="color: black;" class="buddisub">Take one topic at a time. Build your learning gradually.
                                </h2>
                                
                             <a class="default-btn mr-2 p-3 " style="cursor: pointer;" title="Click here" (click)="k12()">K-12 2021</a> 
                            
                            </div>
                            <div class="col-lg-5 col-md-5 col-sm-5 hovervideo">
                               <img src="../../../../../assets/img/self-confidence.jpg" style="border-radius: 10px;" data-toggle="modal" data-target="#myModalself" data-backdrop="false">
                                <iframe class="iframe" src="https://www.youtube.com/embed/w0Z_X8EBUfk?enablejsapi=1&version=3&playerapiid=ytplayer" frameborder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> 
                                <div class="overlaySuccess" data-toggle="modal" data-target="#myModalself" data-backdrop="false">
                                    <a style="color: #FF0000;" class="iconSuccess">
                                <i  class='bx bxs-right-arrow'></i>
                            </a>
                            </div>
                            </div>
                            <div  class="modal" id="myModalself">
                                <div class="modal-dialog">
                                  <div class="modal-content" style="border-radius: 13px;">
                                    <div class="modal-body">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true"><b>&times;</b></span>
                                          </button>
                                          <iframe class="iframe" src="https://www.youtube.com/embed/YPvSPX8lwco?enablejsapi=1&version=3&playerapiid=ytplayer" frameborder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>

                                  </div>
                                </div>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <!-- <div class="main-banner item-bg3">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content text-center">
                        <span class="sub-title">Aim for Excellence</span>
                        <h1>Learn a new skill from online courses</h1>
                        <p>Ednuv supports students by introducing collaborators outside R, internships and research experience abroad.</p>
                        <div class="btn-box">
                            <a routerLink="/courses-2-columns-style-3" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                            <a routerLink="/contact" class="optional-btn">Get Started Free</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</section>
