//ng build --prod --aot --build-optimizer

  //var servername = 'http://staging.buddi.life'; //Statging server 
//var servername = 'https://api.buddi.life';
// var servername = 'http://3.7.151.172'; //Testing
//var servername = 'http://52.66.110.27'

//var servername = 'http://localhost';
    //var servername = 'https://testing.buddi.life';

//var servername = 'http://localhost';
   // var servername = 'https://testing.buddi.life';
     
 //var frontUrl = 'https://staging.buddi.life'; //Statging server 
//var frontUrl = 'https://www.buddi.life/';
var servername = 'https://api.buddi.life';
// var servername = 'http://3.7.151.172'; //Testingsmartcentric@#2020

//var servername = 'http://52.66.110.27'
//var servername = 'http://localhost';
  //var servername = 'https://testing.buddi.life';
//var frontUrl = 'http://staging.buddi.life'; //Statging server 
 var frontUrl = 'https://www.buddi.life/';
// var frontUrl = 'http://3.7.151.172'; //Testing
//var frontUrl = 'https://testing.buddi.life';
 
//var frontUrl = 'http://localhost';

 var payKey = 'rzp_live_hjnosFkDjt8ynh'; //Live Server;
// var payKey =  'rzp_test_a5yVUhWaTt7Nbn' ; //Test Server;
// var payKey =  'rzp_test_eXiebj8pB4BuLN' ; //testing.buddi.life Server;
//var payKey='rzp_test_jfwqP4ONsECD3L';
// 'rzp_test_M90zs0bTbXB32K' //old test server
 var courseurl = 'http://35.154.13.33 '
export const environment = {
  // test and staging
  
  production: false,
  searchBy_itemsPerPage : 40,
  listing__itemsPerPage : 15,
  BASE_URL: servername+':4500/iBuddyPlatformAPI/',
  frontUrl: frontUrl+'/',
  frontUrlWithPort: frontUrl+':4500/',
  IMAGE_PATH: 'http://s3.ap-south-1.amazonaws.com/buddi.bucket/Photos/',
  razorpayKey :payKey,   
  paymentNameHead :  'buddi.life',
  paymentNameSub :  '(Scentric Networks Private Limited)',
  COURSE_URL : courseurl,  
    socket: {
      baseUrl: servername +':4500',
      config: {}
    }  
  // user production build 
  // production: false,
  // sear  chBy_itemsPerPage : 40,
  // listing__itemsPerPage : 4,
  //  BASE_URL: 'https://api.buddi.life:4500/iBuddyPlatformAPI/',
  //  frontUrl: 'https://www.buddi.life/',
  //  frontUrlWithPort: 'https://api.buddi.life:4500/',
  //  IMAGE_PATH: 'https://s3.ap-south-1.amazonaws.com/buddi.bucket/Photos/',   
  //  razorpayKey :'rzp_live_1nqH7zsXzO0bix', 
  //  COURSE_URL: "https://course.buddi.life/",
  //   paymentNameHead :  'buddi.life',
  //   paymentNameSub :  '(Scentric Networks Private Limited)',
  //     socket: {
  //   baseUrl: 'https://api.buddi.life:4500',
  //   config: {}
  // }
}

