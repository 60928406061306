import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-createlive',
  templateUrl: './createlive.component.html',
  styleUrls: ['./createlive.component.scss']
})
export class CreateliveComponent implements OnInit {
  public isStudent: boolean = false;
  public isTeacher: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

}
