import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-student-categories',
  templateUrl: './student-categories.component.html',
  styleUrls: ['./student-categories.component.scss']
})
export class StudentCategoriesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
