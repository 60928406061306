
<div *ngIf="isTeacher"> 
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>
<div *ngIf="isStudent">
    <app-header-style-one></app-header-style-one>
</div>
<div class="wrap cf">
    <div class="heading cf">
        <h1>Wallet</h1>
    </div>
    <div class="cart">
        <h3>Balance: {{walletBalance}} </h3>
    </div>
    <a (click)="checkPromoCode()" href="javascript:void(0)" class="btn continue">Recharge</a>

</div>