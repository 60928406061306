<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <img src="assets/img/spinner-default.gif" alt="">
</ngx-spinner>
<app-header-style-one-teacher></app-header-style-one-teacher>
<div class="container">
	<form [formGroup]="OneonOneForm">
		<div class="row ml-2 mt-5" *ngIf="feedbackData.length!=0">
			<div class="col-sm-12 col-lg-12 col-md-12 form-group">
				<h3 class="title" style="color:#00adee">Welcome to buddi.life ! Student FeedBack Form</h3>
			</div>
    		<div class="col-sm-6 col-lg-6 col-md-6 form-group">
        		<label class="set">Student Name:</label> 
        		{{feedbackData.studentID.profileName}}  
    		</div>
    		<div class="col-sm-6 col-lg-6 col-md-6 form-group" >
        		<label class="set" >Teacher Name:</label>    
        		{{feedbackData.teacherID.profileName}} 
    		</div>
    		<div class="col-sm-6 col-lg-6 col-md-6 form-group" >
    		    <label class="set" >Date:</label>    
    		    {{feedbackData.startDateTime |  date :"MMM dd,yyyy"}}
    		</div>
    		<div class="col-sm-6 col-lg-6 col-md-6 form-group">
    		    <label class="set" >Class Name:</label>    
    		    {{feedbackData.fullName}}
    		</div>
    		<div class="col-lg-12">
    			<label class="heading">
    				Student Class Start on time (Punctuality in the class)
    			</label>
    			<div class="row">
        			<div class="col-lg-2 form-group">
        	    		<input type="radio" value="Poor" name="section" formControlName="section"> Poor
        			</div>
        			<div class="col-lg-2 form-group">
        	    		<input type="radio" value="Average" name="section"  formControlName="section"> Average
        			</div>
        			<div class="col-lg-2 form-group">
        	    		<input type="radio" value="Good" name="section"  formControlName="section">Good
        			</div>
        			<div class="col-lg-2 form-group">
        	    		<input type="radio" value="Very Good" name="section"  formControlName="section"> Very Good
        			</div>
        			<div class="col-lg-2 form-group">
        	    		<input type="radio" value="Excellent" name="section"  formControlName="section"> Excellent
        			</div>
    			</div>                                   
			</div>
			<div class="col-lg-12 margin-set">
		    	<label class="heading">Student attentiveness & Participation </label>    
		    	<div class="row">
		    	    <div class="col-lg-2 form-group">
		    	        <input type="radio" value="Poor"
		    	        name="section1" formControlName="section1"> Poor
		    	    </div>
		    	    <div class="col-lg-2 form-group">
		    	        <input type="radio" value="Average"
		    	        name="section1" formControlName="section1"> Average
		    	    </div>
		    	    <div class="col-lg-2 form-group">
		    	        <input type="radio" value="Good"
		    	        name="section1"  formControlName="section1"> Good
		    	    </div>
		    	    <div class="col-lg-2 form-group">
		    	        <input type="radio" value="Very Good"
		    	        name="section1" formControlName="section1"> Very Good
		    	    </div>
		    	    <div class="col-lg-2 form-group">
		    	        <input type="radio" value="Excellent"
		    	        name="section1" formControlName="section1"> Excellent
		    	    </div>
		    	</div>                                   
			</div>
			<div class="col-lg-12 margin-set">
    			<label class="heading">Student answer to the questioner</label>    
    			<div class="row">
        			<div class="col-lg-2 form-group">
        			    <input type="radio" value="Poor"
        			    name="section2" formControlName="section2"> Poor
        			</div>
        			<div class="col-lg-2 form-group">
        			    <input type="radio" value="Average"
        			    name="section2" formControlName="section2"> Average
        			</div>
        			<div class="col-lg-2 form-group">
        			    <input type="radio" value="Good"
        			    name="section2"formControlName="section2"> Good
        			</div>
        			<div class="col-lg-2 form-group">
        			    <input type="radio" value="Very Good"
        			    name="section2" formControlName="section2"> Very Good
        			</div>
        			<div class="col-lg-2 form-group">
        			    <input type="radio" value="Excellent"
        			    name="section2" formControlName="section2"> Excellent
        			</div>
    			</div>                                   
			</div>
			<div class="col-lg-12 margin-set">
		    	<label class="heading">How he /she observed the class</label>
		    	<div class="row">
		    	    <div class="col-lg-2 form-group">
		    	        <input type="radio" value="Poor"
		    	        name="section3" formControlName="section3"> Poor
		    	    </div>
		    	    <div class="col-lg-2 form-group">
		    	        <input type="radio" value="Average"
		    	        name="section3" formControlName="section3"> Average
		    	    </div>
		    	    <div class="col-lg-2 form-group">
		    	        <input type="radio" value="Good"
		    	        name="section3" formControlName="section3"> Good
		    	    </div>
		    	    <div class="col-lg-2 form-group">
		    	        <input type="radio" value="Very Good"
		    	        name="section3" formControlName="section3"> Very Good
		    	    </div>
		    	    <div class="col-lg-2 form-group">
		    	        <input type="radio" value="Excellent"
		    	        name="section3" formControlName="section3"> Excellent
		    	    </div>
		    	</div>                                  
			</div>
			<div class="col-lg-4 col-md-4">
		    	
			</div>
			<div class="col-lg-4 col-md-4 mb-1">
				<button type="submit"  (click)="onSubmit()"class="default-btn">
		        	<i class='bx bx-save icon-arrow before'></i>
		        	<span class="label">Submit</span>
		        	<i class="bx bx-save icon-arrow after"></i>
		    	</button>
			</div>
			<div class="col-lg-4 col-md-4">
			</div>
        </div>
	</form>
</div>