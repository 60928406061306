<div *ngIf="!isStudent">
    <app-header-style-one-teacher></app-header-style-one-teacher>
</div>
<div *ngIf="isStudent">
    <app-header-style-one></app-header-style-one>
</div>

<!-- <div class="container" routerLink="/sidenav"><button style="width: 10%;height:45px;margin-left: 89%;margin-top:1%;padding: 9px;"><i class="fas fa-long-arrow-alt-left mr-1"></i>Back</button></div> -->

<section class="row my-dashboard-area ">
    <div class="col-lg-1"></div>
    <div class="col-lg-10">
        <!-- <div class="myDashboard-profile">
            <div class="row align-items-center">
               <div class="col-lg-4 col-md-5">
                    <div class="profile-image">
                        <div class="mt-5">
                            <h3 class="titleProfile ml-5" *ngIf="userprofdata">
                                <img style="width: 200px;height: 200px;border-radius: 23%;" [src]="userprofdata.userPhoto =='' ? 'assets/img/profilePhotoPlaceholder.png':userprofdata.userPhoto"
                                alt="image">  
                            </h3>
                        </div>          
                    </div>
                </div>
                <div class="col-lg-5 col-md-7">
                    <div>
                        <h3 class="titleProfile ml-5">
                            <span class="">
                                <span>Profile Details&nbsp;</span>
                            </span>
                            <span id="title"></span>
                        </h3>
                        <div class="container"> -->
                            <!-- The Modal -->
                            <!-- <div class="modal" id="myModal">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-body">
                                            <form [formGroup]="form1">
                                                <table>
                                                    <tr>
                                                        <th>
                                                            <div class="form-group" *ngIf="isGroupnameenabled">
                                                                <label>School Short Name</label>
                                                                <input type="text" class="form-control"
                                                                    placeholder="School Short Name"
                                                                    formControlName="groupName"
                                                                    [ngClass]="{'is-invalid': submittedupdate && g.groupName.errors}">
                                                                <div *ngIf="submittedupdate && g.groupName.errors  "
                                                                    class="invalid-feedback">
                                                                    <div *ngIf="g.groupName.errors?.required">School
                                                                        Short Name is required</div>
                                                                </div>
                                                            </div>
                                                            <div class="form-group" *ngIf="!isGroupnameenabled">
                                                                <label>School Short Name</label>
                                                                <input type="text" disabled class="form-control"
                                                                    placeholder="School Short Name"
                                                                    formControlName="groupName"
                                                                    [ngClass]="{'is-invalid': submittedupdate && g.groupName.errors}">
                                                                <div *ngIf="submittedupdate && g.groupName.errors  "
                                                                    class="invalid-feedback">
                                                                    <div *ngIf="g.groupName.errors?.required">School
                                                                        Short Name is required</div>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div class="form-group" *ngIf="isEnterprisenameenabled">
                                                                <label>School Name</label>
                                                                <input type="text" class="form-control"
                                                                    placeholder="School Name"
                                                                    formControlName="enterpriseName"
                                                                    [ngClass]="{'is-invalid': submittedupdate && g.enterpriseName.errors}">
                                                                <div *ngIf="submittedupdate && g.enterpriseName.errors  "
                                                                    class="invalid-feedback">
                                                                    <div *ngIf="g.enterpriseName.errors?.required">
                                                                        School Name is required</div>
                                                                </div>
                                                            </div>
                                                            <div class="form-group" *ngIf="!isEnterprisenameenabled">
                                                                <label>School Name</label>
                                                                <input type="text" disabled class="form-control"
                                                                    placeholder="School Name"
                                                                    formControlName="enterpriseName"
                                                                    [ngClass]="{'is-invalid': submittedupdate && g.enterpriseName.errors}">
                                                                <div *ngIf="submittedupdate && g.enterpriseName.errors  "
                                                                    class="invalid-feedback">
                                                                    <div *ngIf="g.enterpriseName.errors?.required">
                                                                        School Name is required</div>
                                                                </div>
                                                            </div>
                                                        </th>

                                                    </tr>

                                                    <tr>
                                                        <th *ngIf="isGroupnameenabled || isEnterprisenameenabled"
                                                            style="padding-top: 10px;">
                                                            <button type="submit" (click)="onSubmit1()"
                                                                class="btn btnType1 py-2 px-4">Register</button>
                                                        </th>
                                                        <th *ngIf="!isGroupnameenabled && !isEnterprisenameenabled"
                                                            style="padding-top: 10px;">

                                                        </th>

                                                        <th style="padding-top: 10px;">
                                                            <button id="close" data-dismiss="modal"
                                                                class="btn btnType1 py-2 px-4">Close</button>
                                                        </th>
                                                    </tr>
                                                </table>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div class="profile-content" *ngIf="userprofdata">

                        <table>
                            <tr>
                                <th>First Name</th>
                                <td> : </td>
                                <td>{{userprofdata.firstName}}</td>
                            </tr>
                            <tr>
                                <th>Last Name</th>
                                <td> : </td>
                                <td>{{userprofdata.lastName}}</td>
                            </tr>
                            <tr>
                                <th>Gender</th>
                                <td> :
                                  
                                </td>
                                <td>  {{userprofdata.gender ==undefined || userprofdata.gender=="" || userprofdata.gender=="undefined"? '-':userprofdata.gender}}</td>
                            </tr>
                            <tr>
                                <th>School Name :</th>
                                <td> : </td>
                                <td>{{profileteacherenterprisename ==null ? '-':profileteacherenterprisename}} </td>
                            </tr>
                            <tr>
                                <th>School Short Name</th>
                                <td> : </td>
                                <td>{{userprofdata.groupName ==null ? '-':userprofdata.groupName}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <div class="myDashboard-navigation" *ngIf="userprofdata">
            <ul class="nav nav-tabs">
                <li  [ngClass]="{ 'active': tabName=='home'}" class="active m-1">
                    <a [ngClass]="{ 'active': tabName=='home'}" data-toggle="tab" href="#home" class="active p-2">
                        My Profile
                    </a>
                </li>
                <li class=" m-1" [ngClass]="{ 'active': tabName=='account'}">
                    <a [ngClass]="{ 'active': tabName=='account'}" data-toggle="tab" href="#account" class="p-2">
                        My Account
                    </a>
                </li>
                <li class="m-1" [ngClass]="{ 'active': tabName=='schooldetails'}" *ngIf="userprofdata.enterpriseIsAdmin">
                    <a [ngClass]="{ 'active': tabName=='schooldetails'}" data-toggle="tab" href="#schooldetails" class="p-2">
                        School Details
                    </a>
                </li>
                <li class="m-1" [ngClass]="{ 'active': tabName=='menu1'}" data-toggle="modal" data-target="#myModal2" >
                    <a [ngClass]="{ 'active': tabName=='menu1'}"  data-toggle="tab" href="#menu1" class="p-2">
                        My Classes
                    </a>
                </li>
                <li class="m-1" [ngClass]="{ 'active': tabName=='menu3'}">
                    <a [ngClass]="{ 'active': tabName=='menu3'}" data-toggle="tab" href="#menu3" class="p-2">
                        My Courses
                    </a>
                </li>
                <li class="m-1">
                    <a data-toggle="tab" href="#teststab" class="p-2">
                        My Tests
                    </a>
                </li>
                <li class="m-1" routerLink="/myCalendar">
                    <a data-toggle="tab" href="#menu5" class="p-2">
                        My Calendar
                    </a>
                </li>
                <li class="m-1" [ngClass]="{ 'active': tabName=='menu4'}">
                    <a [ngClass]="{ 'active': tabName=='menu4'}" data-toggle="tab" href="#menu4" class="p-2">
                        My Library
                    </a>
                </li>              
                <li class="m-1" [ngClass]="{ 'active': tabName=='progress'}" >
                    <a [ngClass]="{ 'active': tabName=='progress'}" data-toggle="tab" href="#progress" class="p-2">
                        My Progress
                    </a>
                </li>
                <li class="m-1 " [ngClass]="{ 'active': tabName=='studymaterial'}">
                    <a [ngClass]="{ 'active': tabName=='studymaterial'}" data-toggle="tab" href="#studymaterial" class="p-2">
                        Study Material
                    </a>
                </li>
            </ul>
        </div> -->
        <div class="tab-content myDashboard-content" *ngIf="userprofdata">
            <div id="home" [ngClass]="{ 'active': tabName=='home'}"  class="tab-pane">
                <div class="row">
                    <div class="col-lg-12 col-md-2">
                        <button class="float-right" routerLink="/sidenav"><i class="fas fa-long-arrow-alt-left mr-1"></i>Back</button>
                        <button class="float-right mr-1" routerLink="/teacher/edit-profile"><i class="bi bi-pencil-square kin mr-1"></i>Edit Profile</button>
                        <!-- <button class="col-lg-2 mb-3 p-2 default-btn float-right"
                            ></button> -->
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <h5>Profile Details (Referral ID: <a id="text" style="color:var(--mainColor);" >{{userprofdata._id}} - <i (click)="copyToClipboard('#text')" style="color:var(--mainColor);cursor: pointer;"  data-toggle="tooltip" data-placement="bottom" title="Copy" class="far fa-copy"></i></a>)</h5>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="tab-content">
                            <div class="tabs-item">
                                <div class="products-details-tab-content">
                                    <ul class="additional-information">
                                        <li>
                                            <span>First Name:</span>{{userprofdata.firstName==undefined || userprofdata.firstName=="" || userprofdata.firstName=="undefined" ? '-':userprofdata.firstName}}
                                        </li>
                                        <li>
                                            <span>Last Name:</span>
                                            {{userprofdata.lastName==undefined || userprofdata.lastName==""|| userprofdata.lastName=="undefined"? '-':userprofdata.lastName}}
                                        </li>
                                       <!--  <li>
                                            <span>TeacherID:</span> {{userprofdata.teacherID}}
                                        </li> -->
                                        <li>
                                            <span>Gender:</span>
                                            {{userprofdata.gender ==undefined || userprofdata.gender=="" || userprofdata.gender=="undefined" ? '-':userprofdata.gender}}
                                        </li>
                                        <li><span>School Name:</span>
                                            {{userprofdata.groupName==undefined || userprofdata.groupName=="" || userprofdata.groupName=="undefined"? '-':userprofdata.groupName}}
                                        </li>
                                        <!-- <li><span>School Name:</span>
                                            {{profileteacherenterprisename==undefined || profileteacherenterprisename==""|| profileteacherenterprisename=="undefined" ? '-':profileteacherenterprisename}}
                                        </li> -->
                                        <!-- <li>
                                            <span>School Short Name:</span>
                                            {{userprofdata.groupName==undefined || userprofdata.groupName=="" || userprofdata.groupName=="undefined"? '-':userprofdata.groupName}}
                                        </li> -->

                                        <li *ngIf="isUserLoggedIn=='true'">

                                             <gbutton routerLink="/changepassword"  [textLabel]="'Change Password'"></gbutton>
                                         <!--    <button
                                                class="col-sm-2 mb-2 p-2 default-btn"
                                                routerLink="/changepassword">Change Password
                                            </button> -->
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                        <h5>Contact Info</h5>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="tab-content">
                            <div class="tabs-item">
                                <div class="products-details-tab-content">
                                    <ul class="additional-information">
                                        <li><span>Email:</span>
                                            {{userprofdata.email==undefined  ? '-':userprofdata.email}}</li>
                                        <li><span>Mobile:</span> {{userprofdata.phoneNumber}}</li>
                                        <li><span>Address 1:</span>
                                            {{userprofdata.address1==undefined || userprofdata.address1=="" || userprofdata.address1=="undefined"? '-':userprofdata.address1}}
                                        </li>
                                        <li><span>Address 2:</span>
                                            {{userprofdata.address2==undefined || userprofdata.address2=="" || userprofdata.address2=="undefined"? '-':userprofdata.address2}}
                                        </li>
                                        <li><span>Area:</span>
                                            {{userprofdata.area==undefined || userprofdata.area=="" || userprofdata.area=="undefined" ? '-':userprofdata.area}}
                                        </li>
                                        <li><span>City:</span>
                                            {{userprofdata.city==undefined || userprofdata.city=="" || userprofdata.city=="undefined"? '-':userprofdata.city}}
                                        </li>
                                        <li><span>State:</span>
                                            {{userprofdata.state==undefined || userprofdata.state=="" || userprofdata.state=="undefined"? '-':userprofdata.state}}
                                        </li>
                                        <li><span>Country:</span>
                                            {{userprofdata.country==undefined || userprofdata.country=="" || userprofdata.country=="undefined"? '-':userprofdata.country}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                        <h5>Education Info</h5>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="tab-content">
                            <div class="tabs-item">
                                <div class="products-details-tab-content">
                                    <ul class="additional-information">
                                        <li>
                                            <span>Category:</span>
                                            <span
                                                style=" word-wrap:break-word;width: 70%;color: grey;font-weight: 400;">

                                            <ngfor-comma [keyName]="'categoryName'" [forData]="categorynamearray"></ngfor-comma>
                                              <!--   {{categorynamearray==undefined ? '-':categorynamearray}} -->
                                            </span>
                                        </li>
                                        <li>
                                            <span>Grade:</span> 
                                            <span
                                                style=" word-wrap:break-word;width: 70%;color: grey;font-weight: 400;">
                                                <ngfor-comma [keyName]="'gradeName'" [forData]="gradenamearray"></ngfor-comma>
                                                <!-- {{gradenamearray==undefined? '-':gradenamearray}} -->
                                            </span>
                                        </li>
                                        <li>
                                            <span>Board:</span> 
                                            <span
                                                style=" word-wrap:break-word;width: 70%;color: grey;font-weight: 400;">
                                                <ngfor-comma [keyName]="'boardName'" [forData]="boardnamearray"></ngfor-comma>
                                                <!-- {{boardnamearray}} -->
                                                <!-- {{boardnamearray==undefined ? '-':boardnamearray}} -->
                                            </span>
                                        </li>
                                        <li>
                                            <span>Subject Name:</span>
                                            <span
                                                style=" word-wrap:break-word;width: 70%;color: grey;font-weight: 400;">
                                                 <ngfor-comma [keyName]="'subjectName'" [forData]="subjectnamearray"></ngfor-comma>
                                                 <!-- {{subjectnamearray==undefined ? '-':subjectnamearray}} -->
                                            </span>
                                        </li>
                                        <li>
                                            <span>Language:</span>
                                            <span
                                                style=" word-wrap:break-word;width: 70%;color: grey;font-weight: 400;">
                                                <ngfor-comma [keyName]="'languageName'" [forData]="languagenamearray"></ngfor-comma>
                                                <!-- {{languagenamearray==undefined ? '-':languagenamearray}} -->
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-lg-2 col-md-2">
                        <h5 style="color: var(--mainColor);">KYC Info.</h5>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="tab-content">
                            <div class="tabs-item">
                                <div class="products-details-tab-content">
                                    <ul class="additional-information">
                                        <li>
                                            <span>KYC Status:</span>
                                             <span 
                                                style=" word-wrap:break-word;width: 70%;color: grey;font-weight: 400;">{{iskyc ? 'Verified' : 'Not Verified'}}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>      
                    <div class="col-lg-3 col-md-3" *ngIf="bankDetails.bankName!=undefined && !this.bankCheck.isFullTime">
                        <h5 style="color: var(--mainColor);">Bank Details.</h5>
                    </div>
                    <div class="col-lg-12 col-md-12" *ngIf="bankDetails.bankName!=undefined && !this.bankCheck.isFullTime">
                        <div class="tab-content">
                            <div class="tabs-item">
                                <div class="products-details-tab-content">
                                    <ul class="additional-information">
                                        <li>
                                            <span>Account Name:</span>
                                            <span style=" word-wrap:break-word;width: 70%;color: grey;font-weight: 400;">{{bankDetails.accountName}}
                                            </span>
                                        </li>
                                        <li>
                                            <span>Account Number:</span>
                                            <span style=" word-wrap:break-word;width: 70%;color: grey;font-weight: 400;">{{bankDetails.accountNumber}}
                                            </span>
                                        </li>
                                        <li>
                                            <span>Account Type:</span>
                                            <span style=" word-wrap:break-word;width: 70%;color: grey;font-weight: 400;">{{bankDetails.accountType}}
                                            </span>
                                        </li>
                                        <li>
                                            <span>Bank Name:</span>
                                            <span style=" word-wrap:break-word;width: 70%;color: grey;font-weight: 400;">{{bankDetails.bankName}}
                                            </span>
                                        </li>
                                        <li>
                                            <span>Bank Branch:</span>
                                            <span style=" word-wrap:break-word;width: 70%;color: grey;font-weight: 400;">{{bankDetails.bankBranch}}
                                            </span>
                                        </li>
                                        <li>
                                            <span>IFSC Code:</span>
                                            <span style=" word-wrap:break-word;width: 70%;color: grey;font-weight: 400;">{{bankDetails.ifscCode}}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div> 
                        </div>
                    </div>    -->                
                </div>
            </div>
            <div id="menu4"  [ngClass]="{ 'active': tabName=='menu4','fade':tabName!='menu4'}" class="tab-pane">
                <div class="row">
                    <div class="col-lg-9">
                    </div>
                    <div class="col-lg-3">
                         <form [formGroup]="libraryForm">
                            <span *ngIf="studentIsSchool">                    
                                <input type="checkbox" formControlName="libraryTypeSplit">Only Schools
                            </span>                           
                        </form>
                    </div>
                </div>  
                <h3 >
                    Audio Classes
                </h3>
                <a routerLink="/teacher-audio-create" class="default-btn mb-2 ml-2 float-right p-2">Add Audio</a>
                   <div class="container-fluid row p-0" style="cursor: pointer">
                    <div class="col-lg-3 col-md-6"
                        *ngFor="let slide of songsListPrivate | paginate: { id: 'paginationpr1',itemsPerPage: countpr1, currentPage: pr1 }">
                        <div class="single-courses-box without-box-shadow mb-30">
                            <div class="courses-image">
                                <img style="width:100%" (click)="audiodetails(slide)"
                                    style="width: 250px;height: 175px;"
                                    [src]="slide.thumbname ? slide.thumbname : 'assets/img/videocat-thumb-1.jpg'" alt=""
                                    width="100%">                   
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img [src]="slide.teacherID!=undefined && slide.teacherID.userPhoto!='' ? slide.teacherID.userPhoto  :'assets/img/user1.jpg'"
                                        class="rounded-circle mr-2" alt="image">
                                    <span *ngIf="slide.teacherID!=undefined">{{slide.teacherID.profileName}}
                                    </span>   
                                </div>
                                <h3>
                                    <a (click)="audiodetails(slide)"
                                        class="d-inline-block">
                                        <span class="text-break">{{slide.flimname}}</span>
                                    </a>      
                                </h3>
                            </div>     
                              <!-- <div class="courses-box-footer">
                                <div>
                                    Type: {{slide.securitytype =='Group' ? 'School' : slide.securitytype}}
                                </div>
                            </div>  -->             
                            <div class="courses-box-footer">
                                <!-- {{slide.categoryID ? slide.categoryID[0].categoryName : '' }}, -->
                                {{slide.gradeID ? slide.gradeID[0].gradeName : '' }},
                                {{slide.boardID ? slide.boardID.boardName : '' }}
                            </div>
                            <div class="courses-box-footer">
                                {{slide.subjectID ? slide.subjectID[0].subjectName : '' }},
                                {{slide.languageID ? slide.languageID.languageName : '' }}
                            </div>
                            <div *ngIf="slide.isPartner" class="courses-box-footer">
                                Provided By : {{slide.isbuddi_premium? 'buddi Premium' : slide.partner.partnerName}}
                            </div>
                        </div>
                    </div>
                </div>
                <div style="float: right;" *ngIf="songsListPrivate.length!=0">
                    <pagination-controls (pageChange)="pr1 = $event" id="paginationpr1"></pagination-controls>
                </div>
                <div *ngIf="songsListPrivate.length !=0">
                    <br>
                    <hr>
                </div>
                <div class="container-fluid row p-0" style="cursor: pointer" *ngIf="songsList.length !=0 && !k.libraryTypeSplit.value">
                     
                    <div class="col-lg-3 col-md-6"
                        *ngFor="let slide of songsList| paginate: { id: 'pagination10',itemsPerPage: count10, currentPage: p10 }">
                        <div class="single-courses-box without-box-shadow mb-30">
                            <div class="courses-image">
                                <img style="width: 250px;height: 175px;" (click)="audiodetails(slide)"
                                    [src]="slide.thumbname ? slide.thumbname : 'assets/img/videocat-thumb-1.jpg'" alt=""
                                    width="100%">
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img [src]="slide.teacherID!=undefined && slide.teacherID.userPhoto!='' ? slide.teacherID.userPhoto :'assets/img/user1.jpg'"
                                        class="rounded-circle mr-2" alt="image">
                                    <span *ngIf="slide.teacherID!=undefined">{{slide.teacherID.profileName}}
                                    </span>
                                </div>
                                <h3>
                                    <a (click)="audiodetails(slide)"
                                        class="d-inline-block">
                                        <span class="text-break">
                                        {{slide.flimname}}</span>
                                    </a>
                                </h3>
                            </div>
                           <!--  <div class="courses-box-footer">
                                <div>
                                    Type: {{slide.securitytype =='Group' ? 'School' : slide.securitytype}}
                                </div>
                            </div> -->
                            <div class="courses-box-footer">
                                <!-- {{slide.categoryID ? slide.categoryID[0].categoryName : '' }}, -->
                                {{slide.gradeID ? slide.gradeID[0].gradeName : '' }},
                                {{slide.boardID ? slide.boardID.boardName : '' }}
                            </div>
                            <div class="courses-box-footer">
                                {{slide.subjectID ? slide.subjectID[0].subjectName : '' }},
                                {{slide.languageID ? slide.languageID.languageName : '' }}
                            </div>
                            <div *ngIf="slide.isPartner" class="courses-box-footer">
                                Provided By : {{slide.isbuddi_premium? 'buddi Premium' : slide.partner.partnerName}}
                            </div>
                        </div>
                    </div>

                </div>
                <div style="float: right;" *ngIf="songsList.length!=0 && !k.libraryTypeSplit.value">
                    <pagination-controls (pageChange)="p10 = $event" id="pagination10"></pagination-controls>
                </div>
                <div *ngIf="songsList.length ==0 && !k.libraryTypeSplit.value">
                    No Audio(s) Available.
                </div>
                 <div *ngIf="(songsList.length!=0 && !k.libraryTypeSplit.value) || (songsList.length ==0 && !k.libraryTypeSplit.value)">
                    <br>
                    <hr>
                </div>
         <div class="container-fluid row p-0" style="cursor: pointer" *ngIf="songsListGroup.length !=0 && k.libraryTypeSplit.value">                    
                    <div class="col-lg-3 col-md-6"
                        *ngFor="let slide of songsListGroup | paginate: { id: 'pagination101',itemsPerPage: count101, currentPage: p101 }">
                        <div class="single-courses-box without-box-shadow mb-30">
                            <div class="courses-image">
                                <img style="width:100%" (click)="audiodetails(slide)"
                                    style="width: 250px;height: 175px;"
                                    [src]="slide.thumbname ? slide.thumbname : 'assets/img/videocat-thumb-1.jpg'" alt=""
                                    width="100%">                   
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img [src]="slide.teacherID!=undefined && slide.teacherID.userPhoto!='' ? slide.teacherID.userPhoto  :'assets/img/user1.jpg'"
                                        class="rounded-circle mr-2" alt="image">
                                    <span *ngIf="slide.teacherID!=undefined">{{slide.teacherID.profileName}}
                                    </span> 
                                </div>
                                <h3>
                                    <a (click)="audiodetails(slide)"
                                        class="d-inline-block">
                                        <span class="text-break">
                                        {{slide.flimname}}
                                        </span>
                                    </a>      
                                </h3>
                            </div>     
                           <!--    <div class="courses-box-footer">
                                <div>
                                    Type: {{slide.securitytype =='Group' ? 'School' : slide.securitytype}}
                                </div>
                            </div>   -->            
                            <div class="courses-box-footer">
                                {{slide.categoryID ? slide.categoryID[0].categoryName : '' }},
                                {{slide.gradeID ? slide.gradeID[0].gradeName : '' }},
                                {{slide.boardID ? slide.boardID.boardName : '' }}
                            </div>
                            <div class="courses-box-footer">
                                {{slide.subjectID ? slide.subjectID[0].subjectName : '' }},
                                {{slide.languageID ? slide.languageID.languageName : '' }}
                            </div>
                            <div *ngIf="slide.isPartner" class="courses-box-footer">
                                Provided By : {{slide.isbuddi_premium? 'buddi Premium' : slide.partner.partnerName}}
                            </div>
                        </div>
                    </div>
                </div>
                <div style="float: right;" *ngIf="songsListGroup.length!=0 && k.libraryTypeSplit.value">
                    <pagination-controls (pageChange)="p101 = $event" id="pagination101"></pagination-controls>
                </div>
                <div *ngIf="songsListGroup.length ==0 && k.libraryTypeSplit.value">
                    No Audio(s) Available.
                </div>

                <div *ngIf="(songsListGroup.length!=0 && k.libraryTypeSplit.value) || (songsListGroup.length ==0 && k.libraryTypeSplit.value) ">
                    <br>
                    <hr>
                </div>
                <h3 style="color: var(--mainColor);">Video Classes</h3>
                <a routerLink="/teacher-video-create" class="default-btn mb-2 float-right p-2">Add Video</a>
                <listing [data]="videoListPrivate"></listing>

<!-- 
                <div class="container-fluid row p-0" style="cursor: pointer" *ngIf="videoListPrivate.length !=0">                    
                    <div class="col-lg-3 col-md-6"
                        *ngFor="let slide of videoListPrivate | paginate: { id: 'paginationpr2',itemsPerPage: countpr2, currentPage: pr2 }">
                        <div class="single-courses-box without-box-shadow mb-30">
                            <div class="courses-image">
                                <img style="width:100%" (click)="videodetails(slide)"
                                    style="width: 250px;height: 175px;"
                                    [src]="slide.thumbname ? slide.thumbname : 'assets/img/videocat-thumb-1.jpg'" alt=""
                                    width="100%">                   
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img [src]="slide.teacherID!=undefined && slide.teacherID.userPhoto!='' ? slide.teacherID.userPhoto  :'assets/img/user1.jpg'"
                                        class="rounded-circle mr-2" alt="image">
                                    <span *ngIf="slide.teacherID!=undefined">{{slide.teacherID.profileName}}
                                    </span>  
                                </div>
                                <h3>
                                    <a (click)="videodetails(slide)"
                                        class="d-inline-block">
                                        <span class="text-break">
                                            {{slide.flimname}}
                                        </span>
                                    </a>      
                                </h3>
                            </div>       
                            <div class="courses-box-footer">
                                <div>
                                    Type: {{slide.securitytype =='Group' ? 'School' : slide.securitytype}}
                                </div>
                            </div>            


                            <div class="courses-box-footer">
                                {{slide.categoryID ? slide.categoryID.categoryName : '' }},
                                {{slide.gradeID ? slide.gradeID.gradeName : '' }},
                                {{slide.boardID ? slide.boardID.boardName : '' }}
                            </div>
                            <div class="courses-box-footer">
                                {{slide.subjectID ? slide.subjectID.subjectName : '' }},
                                {{slide.languageID ? slide.languageID.languageName : '' }}
                            </div>
                            <div *ngIf="slide.isPartner" class="courses-box-footer">
                                Provider By : {{slide.partner ==null? slide.providerByName ==''? 'buddi Premium' :  slide.providerByName : slide.partner.partnerName}}
                            </div>
                        </div>
                    </div>
                </div>
                <div style="float: right;" *ngIf="videoListPrivate.length!=0">
                    <pagination-controls (pageChange)="pr2 = $event" id="paginationpr2"></pagination-controls>
                </div>
                 <div *ngIf="videoListPrivate.length !=0">
                    <br>
                    <hr>
                </div> -->
                <div class="container-fluid row p-0" style="cursor: pointer" *ngIf="videoList.length !=0 && !k.libraryTypeSplit.value">
                    <div class="col-lg-3 col-md-6"
                        *ngFor="let slide of videoList | paginate: { id: 'pagination11',itemsPerPage: count11, currentPage: p11 }">
                        <div class="single-courses-box without-box-shadow mb-30">
                            <div class="courses-image">
                                <img *ngIf="!slide.isrecorded" style="width:100%" (click)="videodetails(slide)"
                                    style="width: 250px;height: 175px;"
                                    [src]="slide.thumbname ? slide.thumbname : 'assets/img/videocat-thumb-1.jpg'" alt=""
                                    width="100%">
                                 <img *ngIf="slide.isrecorded && !slide.instructor" style="width:100%" (click)="detailsMeeting(slide,'Livesession')"
                                    style="width: 250px;height: 175px;"
                                    [src]="slide.image ? slide.image : 'assets/img/courses/courses1.jpg'" alt=""
                                    width="100%">
                                <img *ngIf="slide.isrecorded && slide.instructor" style="width:100%" (click)="buddiGroupRedirect(slide)"
                                    style="width: 250px;height: 175px;"
                                    [src]="slide.image ? slide.image : 'assets/img/courses/courses1.jpg'" alt=""
                                    width="100%">
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img *ngIf="!slide.instructor" [src]="slide.teacherID!=undefined && slide.teacherID.userPhoto!='' ? slide.teacherID.userPhoto  :'assets/img/user1.jpg'"
                                        class="rounded-circle mr-2" alt="image">
                                    <span *ngIf="!slide.instructor && slide.teacherID!=undefined">{{slide.teacherID.profileName}}
                                    </span>
                                      <img *ngIf="slide.instructor" [src]="slide.instructor!=undefined && slide.instructor.userPhoto!='' ? slide.instructor.userPhoto  :'assets/img/user1.jpg'"
                                        class="rounded-circle mr-2" alt="image">
                                    <span *ngIf="slide.instructor">{{slide.instructor.profileName}}
                                    </span>
                                    <img src="assets/img/recordingicon.png" *ngIf="slide.isrecorded && !slide.instructor"
                                            (click)="fetchrecordvideo(slide._id,'meetingId')"
                                            style="margin-left: 5%;background-color: red;border-radius: 5px;color: white;padding: 1%;font-weight: 600;">
                                    <img src="assets/img/recordingicon.png" *ngIf="slide.isrecorded && slide.instructor"
                                            (click)="fetchrecordvideo(slide._id,'buddiGroupId')"
                                            style="margin-left: 5%;background-color: red;border-radius: 5px;color: white;padding: 1%;font-weight: 600;">
                                </div>
                                <h3>
                                    <a *ngIf="!slide.isrecorded && !slide.instructor" (click)="videodetails(slide)"
                                        class="d-inline-block">
                                        <span class="text-break">
                                            {{slide.flimname}}
                                        </span>
                                    </a>
                                    <a *ngIf="slide.isrecorded && !slide.instructor" (click)="detailsMeeting(slide,'Livesession')"
                                        class="d-inline-block">
                                        <span class="text-break">
                                            {{slide.fullName}}
                                        </span>
                                    </a>
                                    <a *ngIf="slide.isrecorded && slide.instructor" (click)="buddiGroupRedirect(slide)"
                                        class="d-inline-block">
                                        <span class="text-break">{{slide['parentMeetingID'].name}}
                                        </span>
                                    </a>
                                </h3>                                
                            </div>
                            <div class="courses-box-footer">
                                <div *ngIf="!slide.isrecorded">
                                    Type: {{slide.securitytype =='Group' ? 'School' : slide.securitytype}}
                                </div>
                                <div *ngIf="slide.isrecorded && !slide.instructor">
                                    Type: {{slide.meetingType =='Group' ? 'School' : slide.meetingType}}
                                </div>
                                <div *ngIf="slide.isrecorded && slide.instructor">
                                    Type: {{slide.meetingType =='Group' ? 'School' : slide.meetingType}}
                                </div>
                            </div>
                            <div class="courses-box-footer" *ngIf="slide.gradeID || slide.boardID">
                                <!-- {{slide.categoryID ? slide.categoryID[0].categoryName : '' }}, -->
                                {{slide.gradeID ? slide.gradeID[0].gradeName : '' }},
                                {{slide.boardID ? slide.boardID.boardName : '' }}
                            </div>            
                                             
                            <div class="courses-box-footer" *ngIf="slide.subjectID || slide.subjectID.length > 0">
                                {{slide.subjectID ? slide.subjectID[0].subjectName : '' }},
                                {{slide.languageID ? slide.languageID.languageName : '' }}
                            </div>
                            <div *ngIf="slide.isPartner" class="courses-box-footer">
                               Provided By : {{slide.partner ==null? slide.providerByName ==''? 'buddi Premium' :  slide.providerByName : slide.partner.partnerName}}
                            </div>
                        </div>
                    </div>
                </div>
                <div style="float: right;" *ngIf="videoList.length!=0 && !k.libraryTypeSplit.value">
                    <pagination-controls (pageChange)="p11 = $event" id="pagination11"></pagination-controls>
                </div>
                <div *ngIf="videoList.length ==0 && !k.libraryTypeSplit.value">
                    No Video(s)cvxcv Available.
                </div>
                <div *ngIf="(videoList.length!=0 && !k.libraryTypeSplit.value ) || (videoList.length ==0 && !k.libraryTypeSplit.value)">
                    <br>
                    <hr>
                </div>
                <div class="container-fluid row p-0" style="cursor: pointer" *ngIf="videoListGroup.length !=0 && k.libraryTypeSplit.value">
                    <div class="col-lg-3 col-md-6"
                        *ngFor="let slide of videoListGroup | paginate: { id: 'pagination11',itemsPerPage: count111, currentPage: p111 }">
                        <div class="single-courses-box without-box-shadow mb-30">
                            <div class="courses-image">
                                <img style="width:100%" (click)="videodetails(slide)"
                                    style="width: 250px;height: 175px;"
                                    [src]="slide.thumbname ? slide.thumbname : 'assets/img/videocat-thumb-1.jpg'" alt=""
                                    width="100%">                   
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img [src]="slide.teacherID!=undefined && slide.teacherID.userPhoto!='' ? slide.teacherID.userPhoto  :'assets/img/user1.jpg'"
                                        class="rounded-circle mr-2" alt="image">
                                    <span *ngIf="slide.teacherID!=undefined">{{slide.teacherID.profileName}}
                                    </span>   
                                </div>
                                <h3>
                                    <a (click)="videodetails(slide)"
                                        class="d-inline-block">
                                        <span class="text-break">
                                            {{slide.flimname}}
                                        </span>
                                    </a>      
                                </h3>
                            </div>       
                              <!-- <div class="courses-box-footer">
                                <div>
                                    Type: {{slide.securitytype =='Group' ? 'School' : slide.securitytype}}
                                </div>
                            </div>             -->
                            <!-- <list5 [slide]="slide"></list5> -->
                            <div class="courses-box-footer" *ngIf="slide.categoryID.length > 0">
                                {{slide.categoryID ? slide.categoryID[0].categoryName : '' }},
                                {{slide.gradeID ? slide.gradeID[0].gradeName : '' }},
                                {{slide.boardID ? slide.boardID.boardName : '' }}
                            </div>
                            <div class="courses-box-footer" *ngIf="slide.subjectID.length > 0">
                                {{slide.subjectID ? slide.subjectID[0].subjectName : '' }},
                                {{slide.languageID ? slide.languageID.languageName : '' }}
                            </div>
                            <div *ngIf="slide.isPartner" class="courses-box-footer">
                              Provider By : {{slide.partner ==null? slide.providerByName ==''? 'buddi Premium' : slide.providerByName : slide.partner.partnerName}}
                            </div>
                        </div>
                    </div>
                </div>
                <div style="float: right;" *ngIf="videoListGroup.length!=0 && k.libraryTypeSplit.value">
                    <pagination-controls (pageChange)="p111 = $event" id="pagination111"></pagination-controls>
                </div>
                <div *ngIf="videoListGroup.length ==0 && k.libraryTypeSplit.value">
                    No Video(s) Available.
                </div>
                <div *ngIf="(videoListGroup.length!=0 && k.libraryTypeSplit.value)||(videoListGroup.length ==0 && k.libraryTypeSplit.value)">
                    <br>
                    <hr>
                </div>
                <h3 style="color: var(--mainColor);" *ngIf="!k.libraryTypeSplit.value">Curated Content Videos</h3>


                        <div class="container-fluid row p-0" style="cursor: pointer" *ngIf="videoYoutubePublic.length !=0 && !k.libraryTypeSplit.value">
                    <div class="col-lg-3 col-md-6"
                        *ngFor="let slide of videoYoutubePublic | paginate: { id: 'pagination112',itemsPerPage: count112, currentPage: p112 }">
                        <div class="single-courses-box without-box-shadow mb-30">
                            <div class="courses-image">
                                <img style="width:100%" (click)="videodetails(slide)"
                                    style="width: 250px;height: 175px;"
                                    [src]="slide.thumbname ? slide.thumbname : 'assets/img/videocat-thumb-1.jpg'" alt=""
                                    width="100%">                   
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img [src]="slide.teacherID!=undefined && slide.teacherID.userPhoto!='' ? slide.teacherID.userPhoto  :'assets/img/user1.jpg'"
                                        class="rounded-circle mr-2" alt="image">
                                    <span *ngIf="slide.teacherID!=undefined">{{slide.teacherID.profileName}}
                                    </span>   
                                </div>
                                <h3>
                                    <a (click)="videodetails(slide)"
                                        class="d-inline-block">
                                        <span class="text-break">
                                            {{slide.flimname}}
                                        </span>
                                    </a>      
                                </h3>
                            </div>       
                              <!-- <div class="courses-box-footer">
                                <div>
                                    Type: {{slide.securitytype =='Group' ? 'School' : slide.securitytype}}
                                </div>
                            </div>             -->
                            <!-- <list5 [slide]="slide"></list5> -->
                            <div class="courses-box-footer" *ngIf="slide.categoryID.length > 0">
                                {{slide.categoryID ? slide.categoryID[0].categoryName : '' }},
                                {{slide.gradeID ? slide.gradeID[0].gradeName : '' }},
                                {{slide.boardID ? slide.boardID.boardName : '' }}
                            </div>
                            <div class="courses-box-footer" *ngIf="slide.subjectID.length > 0">
                                {{slide.subjectID ? slide.subjectID[0].subjectName : '' }},
                                {{slide.languageID ? slide.languageID.languageName : '' }}
                            </div>
                            <div *ngIf="slide.isPartner" class="courses-box-footer">
                              Provider By : {{slide.partner ==null? slide.providerByName ==''? 'buddi Premium' : slide.providerByName : slide.partner.partnerName}}
                            </div>
                        </div>
                    </div>
                </div>
                <div style="float: right;" *ngIf="videoYoutubePublic.length!=0 && !k.libraryTypeSplit.value">
                    <pagination-controls (pageChange)="p112 = $event" id="pagination112"></pagination-controls>
                </div>
                <div *ngIf="videoYoutubePublic.length ==0 && !k.libraryTypeSplit.value">
                    No Video(s) Available.
                </div>
                <div *ngIf="(videoYoutubePublic.length!=0 && !k.libraryTypeSplit.value)||(videoYoutubePublic.length ==0 && !k.libraryTypeSplit.value)">
                    <br>
                    <hr>
                </div>

<!-------------------------------------------- Teacher Video Upload List------------------------------------------------------ -->

                <h3 style="color: var(--mainColor);" *ngIf="!k.libraryTypeSplit.value">Videos Uploaded</h3>
                <a routerLink="/teacher-video-upload" class="default-btn mb-2 float-right p-2">Add Video</a>

                <div class="container-fluid row p-0" style="cursor: pointer" *ngIf="teacherVideouploadlist.length !=0">
            <div class="col-lg-3 col-md-6"
                *ngFor="let slide of teacherVideouploadlist | paginate: { id: 'paginationvideoupload',itemsPerPage: videouploadcount, currentPage: pvideoupload }">
                <div class="single-courses-box without-box-shadow mb-30">
                    <div class="courses-image">
                        <img style="width:100%" (click)="uploadedvideodetails(slide)"
                            style="width: 250px;height: 175px;"
                            [src]="slide.thumbnail ? slide.thumbnail : 'assets/img/videocat-thumb-1.jpg'" alt=""
                            width="100%">                   
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            
                            <span style="word-break: break-all">{{slide.videoName}}
                            </span>   
                        </div>
                        <h3>
                            <a (click)="videodetails(slide)"
                                class="d-inline-block">
                                <span class="text-break">
                                    {{slide.topicID.topicName}}
                                </span>
                            </a>      
                        </h3>
                        <span  *ngIf="slide.status=='Draft'" style="background-color: #e86f19;border-radius: 7px;padding: 0px 3px 0px 3px;color: white;">Draft</span>
                    </div>       
                      <!-- <div class="courses-box-footer">
                        <div>
                            Type: {{slide.securitytype =='Group' ? 'School' : slide.securitytype}}
                        </div>
                    </div>             -->
                    <!-- <list5 [slide]="slide"></list5> -->
                    <div class="courses-box-footer">
                        {{slide.categoryID ? slide.categoryID.categoryName : '' }},
                        <!-- {{slide.topicID ? slide.topicID.gradeID.gradeName : '' }} -->
                        {{slide.boardID ? slide.boardID.boardName : '' }}
                    </div>
                    <div class="courses-box-footer">
                            <!-- {{slide.topicID ? slide.topicID.subjectID.subjectName : '' }} -->
                        {{slide.languageID ? slide.languageID.languageName : '' }}
                    </div>
                  
                </div>
            </div>
        </div>
        <div style="float: right;" *ngIf="teacherVideouploadlist.length!=0">
            <pagination-controls (pageChange)="pvideoupload" id="paginationvideoupload"></pagination-controls>
        </div>
        <div *ngIf="teacherVideouploadlist.length ==0">
            No Uploaded Video(s) Available.
        </div>
        <div *ngIf="teacherVideouploadlist.length!=0">
            <br>
            <hr>
        </div>

<!-------------------------------------------- End Of Teacher Video Upload List------------------------------------------------------ -->

                <!-- <listing [data]="videoYoutubePublic" *ngIf="!k.libraryTypeSplit.value"></listing> -->

                
            </div>
            <div id="schooldetails"  [ngClass]="{ 'active': tabName=='schooldetails','fade':tabName!='schooldetails'}" class="tab-pane">
                <div class="recent-orders-table table-responsive">

                    <div class="loader-size" *ngIf="loaderupload">
                        <div class="loader"></div>
                        <h5>Loading...Please Wait</h5>
                    </div>
                    <table class="table">
                        <tr>
                            <th></th>
                            <td>
                                <span> Add School : </span> <a href="" style="text-decoration: underline;color: blue;"
                                    data-toggle="modal" data-target="#myModal">
                                    (click here )
                                </a>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>School Name</th>
                            <td>{{teacherenterprisename ==undefined ? '-':teacherenterprisename}}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>School Short Name</th>
                            <td>{{userprofdata.groupName}}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Create Teachers</th>
                            <td>
                                <a href="" routerLink="/teacher/grp-user-create" class="upgrad"
                                    style="text-decoration: underline;color: blue;">
                                    Click to Add
                                </a> &nbsp;&nbsp;&nbsp;
                                <a href="javascript:void(0)" (click)="viewTeachers()"
                                    style="color: orangered;text-decoration: underline;">
                                    View
                                </a>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Upload Students</th>
                            <td>
                                <form [formGroup]="uploadform">
                                    <div style="display: flex;">
                                        <div>
                                            <div class="custom-file">
                                                <input type="hidden" formControlName="fileupload" name="" value="">
                                                <input type="file" accept=".xlsx" (change)="fileChange1($event)"
                                                    id="inputGroupFile01" aria-describedby="inputGroupFileAddon01">
                                                <label style="font-size: 17px;" class="custom-file-label"
                                                    for="inputGroupFile01">{{selectedFileName}}
                                                </label>
                                            </div>
                                        </div>&nbsp;&nbsp;
                                        <button *ngIf="isclickable" style="height: 39px;align-items: center;"
                                            type="submit" (click)="userDataUpload()"
                                            class="default-btn col-lg-2 mr-1 p-1 ">
                                            Update
                                        </button>&nbsp;&nbsp;&nbsp;
                                        <a href="javascript:void(0)" (click)="viewStudents()" *ngIf="isclickable"
                                            style="color: orangered;text-decoration: underline;">
                                            View
                                        </a>
                                        <button *ngIf="isclickabledisable" style="height: 39px;align-items: center;"
                                            class="default-btn col-lg-2 mr-1 p-1 "
                                            style="border-color: #b5b0b1;background-color: #a69ea0;"
                                            disabled>
                                            Update
                                        </button>&nbsp;&nbsp;&nbsp;

                                        <a href="javascript:void(0)" (click)="viewStudents()" *ngIf="isclickabledisable"
                                            style="color: orangered;">
                                            View
                                        </a>
                                    </div>
                                </form>
                            </td>
                        </tr>
                    </table><br>
                    <div class="col-md-12 text-center">
                        <label style="font-size: 15px;">
                            [File format : Sample.xlsx file attached.(<a style="text-decoration: underline;color: blue;"
                                href="../../../../../assets/Sample.xlsx">Download</a>)] [ Please enter all the users
                            details in the Excel file and Upload it here]
                            <br>
                            [Please make sure 100 users to be uploaded at a time.]
                        </label>
                    </div>
                </div>
                <div class="container mt-3">
                    <div class="loader-size" *ngIf="loader">
                        <div class="loader"></div>
                        <h5>Loading...Please Wait</h5>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive">
                                <div *ngIf="teacherTable == true">
                                    <h3 style="text-align: center;">Teachers</h3>
                                    <table class="table table-striped table-bordered table-sm row-border hover"
                                        datatable [dtOptions]="dtOptions">
                                        <thead>
                                            <tr>
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th>Email</th>
                                                <th>Mobile No</th>
                                                <th>School Short Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let data of teacherData">
                                                <td>{{data.firstName}}</td>
                                                <td>{{data.lastName}}</td>
                                                <td>{{data.email}}</td>
                                                <td>{{data.phoneNumber}}</td>
                                                <td>{{data.groupName}}</td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                                <div *ngIf="studentTable == true">
                                    <h3 style="text-align: center;">Students</h3>
                                    <table *ngIf="studentTable == true"
                                        class="table table-striped table-bordered table-sm row-border hover" datatable
                                        [dtOptions]="dtOptions">
                                        <thead>
                                            <tr>
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th>Email</th>
                                                <th>Mobile No</th>
                                                <th>School Short Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let data of studentData">
                                                <td>{{data.firstName}}</td>
                                                <td>{{data.lastName}}</td>
                                                <td>{{data.email}}</td>
                                                <td>{{data.phoneNumber}}</td>
                                                <td>{{data.groupName}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div *ngIf="dupeArray==true" class="container mb-3">
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <label style="font-weight: 500;">User details you've uploaded which are all exists in this
                                system.Please check and make sure the details below and re-do it.</label>
                        </div>
                        <div class="col-md-6">
                            <table>
                                <tr>
                                    <th>Mobile Number Exists</th>
                                </tr>
                                <tr *ngFor="let data of dupePhoneArray">
                                    <td style="color: red;">{{data}}</td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-md-6">
                            <table>
                                <tr>
                                    <th>Email Id Exists</th>
                                </tr>
                                <tr *ngFor="let data of dupeEmailArray">
                                    <td style="color: red;">{{data}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>

                <div *ngIf="invalidArray==true" class="container mb-3">
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <label style="font-weight: 500;">User details you've uploaded has invalid Data.Please check
                                and make sure the details below and re-do it.</label>
                        </div>
                        <div class="col-md-6">
                            <table>
                                <tr>
                                    <th>{{invalidDatalable}}</th>
                                </tr>

                                <td style="color: red;">{{invalidDatastring}}</td>

                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div id="menu1"  [ngClass]="{ 'active': tabName=='menu1','fade':tabName!='menu1'}" class="tab-pane">
                <div class="row">
                    <div class="col-lg-12">
                        <h3 style="color: var(--mainColor);">
                            Accepted One-on-One Classes
                        </h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-10">
                    </div>
                    <div class="col-lg-2">
                        <a (click)="requestMeeting()" class="default-btn float-right p-2 mb-1">Accept</a>
                    </div>
                </div>
                <div class="container-fluid row p-0" style="cursor: pointer" *ngIf="requestedDataAccpet.length !=0">

                    <div class="col-lg-3 col-md-6"
                        *ngFor="let slide of requestedDataAccpet | paginate: { id: 'pagination8',itemsPerPage: count8, currentPage: p8 }">
                        <div class="single-courses-box without-box-shadow mb-30">
                            <div class="courses-image">
                                <a (click)="detailsAccept(slide,'Livesession')" class="d-block"><img
                                        style="width: 250px;height: 175px;"
                                        [src]="slide.image =='' ? 'assets/img/courses/courses1.jpg':slide.image"
                                        alt="image"></a>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <div class="course-author d-flex align-items-center">
                                        <img [src]="slide.studentID!=undefined && slide.studentID.userPhoto!='' ? slide.studentID.userPhoto  :'assets/img/user1.jpg'"
                                            class="rounded-circle mr-2" alt="image">

                                        <span class="" *ngIf="slide.studentID!=undefined">{{slide.studentID.firstName}}
                                        </span>
                                         <img src="assets/img/recordingicon.png" *ngIf="slide.isrecorded"  (click)="fetchrecordvideo(slide._id,'requestId')" style="margin-left: 5%;background-color: red;border-radius: 5px;color: white;padding: 1%;font-weight: 600;"> 

                                    </div>

                                </div>

                                <div class="course-author d-flex align-items-center">
                                    <span class="col-lg-1 p-0" style="color:green"><b>Accepted</b></span>
                                </div>

                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image">
                                    {{slide.ScheduleDate |  date :"MMM dd,yyyy, hh:mm aaa"}}
                                </div>
                                <h3>
                                    <a (click)="detailsAccept(slide,'Livesession')"
                                        class="d-inline-block"><span>{{slide.name}}</span></a>

                                </h3>

                            </div>
                            <div>
                                {{slide.categoryID ? slide.categoryID.categoryName : '' }},
                                {{slide.gradeID ? slide.gradeID.gradeName : '' }},
                                {{slide.boardID ? slide.boardID.boardName : '' }}
                            </div>
                            <div class="courses-box-footer">
                                {{slide.subjectID ? slide.subjectID.subjectName : '' }},
                                {{slide.languageID ? slide.languageID.languageName : '' }}
                            </div>
                        </div>
                    </div>

                </div>
                <div style="float: right;" class="col-lg-4" *ngIf="requestedDataAccpet.length!=0">
                    <pagination-controls (pageChange)="p8 = $event" id="pagination8"></pagination-controls>
                </div>
                <br>
                <br>
                <div *ngIf="requestedDataAccpet.length ==0">
                    No One-on-One Class(es) available.
                </div>
                <hr>

                <div class="row">
                    <div class="col-lg-8">

                    </div>
                    <div class="col-lg-2 mt-2">
                        <form [formGroup]="classessForm">
                            <span *ngIf="studentIsSchool">                    
                                <input type="checkbox" formControlName="classType">Only Schools
                            </span>
                           <!--  <input type="radio" name="classType" (click)="changeRole('School')" value="School"
                                formControlName="classType"> School
                            <input type="radio" name="classType" (click)="changeRole('Public')" value="Public"
                                formControlName="classType"> Public -->
                        </form>
                    </div>
                    <div class="col-lg-2">
                        <a routerLink="/create-meeting" class="default-btn float-right p-2">Create Class</a>
                    </div>

                </div>
                <br>
                <div *ngIf="j.classType.value">
                    <h3 style="color: var(--mainColor);">
                        School Classes
                    </h3>
                    <div class="container-fluid row p-0" style="cursor: pointer" *ngIf="meetingsData.length !=0">

                        <div class="col-lg-3 col-md-6"
                            *ngFor="let slide of meetingsData | paginate: { id: 'pagination7',itemsPerPage: count7, currentPage: p7 }">
                            <div class="single-courses-box without-box-shadow mb-30">
                                <div class="courses-image">
                                    <a (click)="detailsMeeting(slide,'Livesession')" class="d-block"><img
                                            style="width: 250px;height: 175px;"
                                            [src]="slide.image =='' ? 'assets/img/courses/courses1.jpg':slide.image"
                                            alt="image"></a>
                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img [src]="slide.teacherID!=undefined && slide.teacherID.userPhoto!='' ? slide.teacherID.userPhoto  :'assets/img/user1.jpg'"
                                            class="rounded-circle mr-2" alt="image">
                                        <span *ngIf="slide.teacherID!=undefined">{{slide.teacherID.firstName}}
                                        </span>
                                          <img src="assets/img/recordingicon.png" *ngIf="slide.isrecorded"  (click)="fetchrecordvideo(slide._id,'meetingId')" style="margin-left: 5%;background-color: red;border-radius: 5px;color: white;padding: 1%;font-weight: 600;"> 
                                    </div>


                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image">
                                        {{slide.ScheduleDate |  date :"MMM dd,yyyy, hh:mm aaa"}}
                                    </div>
                                    <h3>
                                        <a (click)="detailsMeeting(slide,'Livesession')"
                                            class="d-inline-block"><span>{{slide.name}}</span></a>
                                    </h3>
                                </div>
                                <div>
                                    {{slide.categoryID ? slide.categoryID.categoryName : '' }},
                                    {{slide.gradeID ? slide.gradeID.gradeName : '' }},
                                    {{slide.boardID ? slide.boardID.boardName : '' }}
                                </div>
                                <div class="courses-box-footer">
                                    {{slide.subjectID ? slide.subjectID.subjectName : '' }},
                                    {{slide.languageID ? slide.languageID.languageName : '' }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="float: right;" *ngIf="meetingsData.length!=0">
                        <pagination-controls (pageChange)="p7 = $event" id="pagination7"></pagination-controls>
                    </div>
                    <div *ngIf="meetingsData.length ==0">
                        No School Class(es) Available.
                    </div>
                </div>
                <div *ngIf="!j.classType.value">

                    <h3 style="color: var(--mainColor);">
                        Public Classes
                    </h3>
                    <div class="container-fluid row p-0" style="cursor: pointer" *ngIf="publicsessionData.length !=0">

                        <div class="col-lg-3 col-md-6"
                            *ngFor="let slide of publicsessionData | paginate: { id: 'pagination6',itemsPerPage: count6, currentPage: p6 }">
                            <div class="single-courses-box without-box-shadow mb-30">
                                <div class="courses-image">
                                    <a (click)="detailsMeeting(slide,'Livesession')" class="d-block"><img
                                            style="width: 250px;height: 175px;"
                                            [src]="slide.image =='' ? 'assets/img/courses/courses1.jpg':slide.image"
                                            alt="image"></a>
                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img [src]="slide.teacherID!=undefined && slide.teacherID.userPhoto!='' ? slide.teacherID.userPhoto  :'assets/img/user1.jpg'"
                                            class="rounded-circle mr-2" alt="image">
                                        <span *ngIf="slide.teacherID!=undefined">{{slide.teacherID.firstName}}
                                        </span>
                                    </div>
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image">
                                        {{slide.ScheduleDate |  date :"MMM dd,yyyy, hh:mm aaa"}}
                                    </div>
                                    <h3>
                                        <a (click)="detailsMeeting(slide,'Livesession')"
                                            class="d-inline-block"><span>{{slide.name}}</span></a>
                                    </h3>

                                </div>
                                <div>
                                    {{slide.categoryID ? slide.categoryID.categoryName : '' }},
                                    {{slide.gradeID ? slide.gradeID.gradeName : '' }},
                                    {{slide.boardID ? slide.boardID.boardName : '' }}
                                </div>
                                <div class="courses-box-footer">
                                    {{slide.subjectID ? slide.subjectID.subjectName : '' }},
                                    {{slide.languageID ? slide.languageID.languageName : '' }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="float: right;" *ngIf="publicsessionData.length!=0">
                        <pagination-controls (pageChange)="p6 = $event" id="pagination6"></pagination-controls>
                    </div>
                    <div *ngIf="publicsessionData.length ==0">
                        No Public Class(es) Available.
                    </div>
                </div>
                <br>
                <hr>

                <div>
                    <h3 style="color: var(--mainColor);">
                        buddi Group Classes
                    </h3>
                    <div class="container-fluid row p-0" style="cursor: pointer" *ngIf="buddigroupData.length !=0">

                        <div class="col-lg-3 col-md-6"
                            *ngFor="let slide of buddigroupData | paginate: { id: 'pagination12',itemsPerPage: count12, currentPage: p12 }">
                            <div class="single-courses-box without-box-shadow mb-30">
                                <div class="courses-image">
                                    <a (click)="buddiGroupRedirectParent(slide)" class="d-block">

                                        <img style="width: 250px;height: 175px;"
                                            [src]="slide.userPhoto!=undefined && slide.userPhoto!='' ? slide.userPhoto :'assets/img/courses/courses1.jpg'"></a>
                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img [src]="slide.instructor!=undefined && slide.instructor.userPhoto!='' ? slide.instructor.userPhoto  :'assets/img/user1.jpg'"
                                            class="rounded-circle mr-2" alt="image">
                                        <span *ngIf="slide.instructor!=undefined">{{slide.instructor.profileName}}
                                        </span>
                                    </div>
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image">
                                        {{slide.startTime |  date :"MMM dd,yyyya"}} |
                                        {{slide.endTime |  date :"MMM dd,yyyy"}}
                                    </div>
                                    <h3>
                                        <a (click)="buddiGroupRedirectParent(slide)"
                                            class="d-inline-block"><span>{{slide.name}} </span></a>

                                    </h3>
                                </div>
                                <div>
                                    {{slide.categoryID ? slide.categoryID.categoryName : '' }},
                                    {{slide.gradeID ? slide.gradeID.gradeName : '' }},
                                    {{slide.boardID ? slide.boardID.boardName : '' }}
                                </div>
                                <div class="courses-box-footer">
                                    {{slide.subjectID ? slide.subjectID.subjectName : '' }},
                                    {{slide.languageID ? slide.languageID.languageName : '' }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="float: right;" *ngIf="buddigroupData.length!=0">
                        <pagination-controls (pageChange)="p12 = $event" id="pagination12"></pagination-controls>
                    </div>
                    <div *ngIf="buddigroupData.length ==0">
                        No buddi Group Class(es) Available.
                    </div>
                </div>
            </div>
            <div id="menu3"  [ngClass]="{ 'active': tabName=='menu3','fade':tabName!='menu3'}" class="tab-pane">
                <div class="row">
                    <div class="col-lg-8">
                    </div>
                    <div class="col-lg-2 mt-2">
                        <form [formGroup]="coursesForm">
                             <span *ngIf="studentIsSchool">                    
                                <input type="checkbox" formControlName="classType">Only Schools
                            </span>   
                            <!-- <input type="radio" name="classType" (click)="changeRoleCourse('School')" value="School"
                                formControlName="classType"> School
                            <input type="radio" name="classType" (click)="changeRoleCourse('Public')" value="Public"
                                formControlName="classType"> Public -->
                        </form>
                    </div>
                    <div class="col-lg-2">
                        <a *ngIf="iscoursecreate" routerLink="/create-course" class="default-btn float-right p-2">Create
                            Course</a>
                    </div>
                </div>
                <div *ngIf="r.classType.value">
                    <h3 style="color: var(--mainColor);">Upcoming courses</h3>
                    <div class="container-fluid row" style="cursor: pointer;border-bottom: 1px solid #d2d0d0">
                        <div class="col-lg-3 col-md-6"
                            *ngFor="let slidef of FuturecourseDatagroup | paginate: { id: 'pagination1',itemsPerPage: count, currentPage: p }">
                            <div class="single-courses-box without-box-shadow mb-30">
                                <div class="courses-image">
                                    <a (click)="coursedetails(slidef)" class="d-block"><img
                                            style="width: 250px;height: 175px;"
                                            [src]="slidef.courseImage =='' ? 'assets/img/courses/courses1.jpg':slidef.courseImage"
                                            alt="image"></a>
                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img [src]="slidef.createdBy!=undefined && slidef.createdBy.userPhoto!='' ? slidef.createdBy.userPhoto  :'assets/img/user1.jpg'"
                                            class="rounded-circle mr-2" alt="image">
                                        <span>{{slidef.createdBy ? slidef.createdBy.firstName : null }}</span>
                                        <p *ngIf="slidef.status=='Draft'"
                                            style="margin-left: 5%;background-color: red;border-radius: 5px;color: white;padding: 1%;font-weight: 600;">
                                            {{slidef.status}}</p>
                                    </div>
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image">
                                        <span>{{slidef.startDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</span>
                                    </div>
                                    <h3><a (click)="coursedetails(slidef)"
                                            class="d-inline-block">{{slidef.fullName}}</a></h3>

                                </div>
                                <div class="courses-box-footer">
                                    {{slidef.categoryID ? slidef.categoryID.categoryName : 'N/A' }} ,
                                    {{slidef.gradeID ? slidef.gradeID.gradeName : 'N/A' }} ,
                                    {{slidef.boardID ? slidef.boardID.boardName : 'N/A' }}
                                    <br>
                                    {{slidef.subjectID ? slidef.subjectID.subjectName : 'N/A' }} ,
                                    {{slidef.languageID ? slidef.languageID.languageName : 'N/A' }}
                                </div>
                            </div>
                        </div>

                        <div *ngIf="FuturecourseDatagroup.length < 1">
                            No Upcoming Course(s) Available.
                        </div>
                        <div class="col-lg-12 col-md-12" *ngIf="FuturecourseDatagroup.length > 0">
                            <div style="float: right;">
                                <pagination-controls (pageChange)="p = $event" id="pagination1"></pagination-controls>
                            </div>
                        </div>
                    </div>
                    <h3 style="color: var(--mainColor);">Past courses</h3>
                    <div class="container-fluid row" style="cursor: pointer;border-bottom: 1px solid #d2d0d0">
                        <div class="col-lg-3 col-md-6"
                            *ngFor="let slidep of PastcourseDatagroup | paginate: { id: 'pagination2',itemsPerPage: count1, currentPage: p1 }">
                            <div class="single-courses-box without-box-shadow mb-30">
                                <div class="courses-image">
                                    <a (click)="coursedetails(slidep)" class="d-block"><img
                                            style="width: 250px;height: 175px;"
                                            [src]="slidep.courseImage =='' ? 'assets/img/courses/courses1.jpg':slidep.courseImage"
                                            alt="image"></a>
                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img [src]="slidep.createdBy!=undefined && slidep.createdBy.userPhoto!='' ? slidep.createdBy.userPhoto  :'assets/img/user1.jpg'"
                                            class="rounded-circle mr-2" alt="image">
                                        <span>{{slidep.createdBy ? slidep.createdBy.firstName : null }}</span>
                                        <p *ngIf="slidep.status=='Draft'"
                                            style="margin-left: 5%;background-color: red;border-radius: 5px;color: white;padding: 1%;font-weight: 600;">
                                            {{slidep.status}}</p>
                                    </div>
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image">
                                        <span>{{slidep.startDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</span>
                                    </div>
                                    <h3><a (click)="coursedetails(slidep)"
                                            class="d-inline-block">{{slidep.fullName}}</a></h3>
                                </div>
                                <div class="courses-box-footer">
                                    {{slidep.categoryID ? slidep.categoryID.categoryName : 'N/A' }} ,
                                    {{slidep.gradeID ? slidep.gradeID.gradeName : 'N/A' }} ,
                                    {{slidep.boardID ? slidep.boardID.boardName : 'N/A' }}
                                    <br>
                                    {{slidep.subjectID ? slidep.subjectID.subjectName : 'N/A' }} ,
                                    {{slidep.languageID ? slidep.languageID.languageName : 'N/A' }}
                                </div>
                            </div>
                        </div>
                        <div *ngIf="PastcourseDatagroup?.length < 1">
                            No Past Course(s) Available.
                        </div>
                        <div class="col-lg-12 col-md-12" *ngIf="PastcourseDatagroup.length > 0">
                            <div style="float: right;">
                                <pagination-controls (pageChange)="p1 = $event" id="pagination2"></pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!r.classType.value">
                    <h3 style="color: var(--mainColor);">Upcoming courses</h3>
                    <div class="container-fluid row" style="cursor: pointer;border-bottom: 1px solid #d2d0d0">
                        <div class="col-lg-3 col-md-6"
                            *ngFor="let slidef of FuturecourseData | paginate: { id: 'groupcourse1',itemsPerPage: count3, currentPage: p3 }">
                            <div class="single-courses-box without-box-shadow mb-30">
                                <div class="courses-image">
                                    <a (click)="coursedetails(slidef)" class="d-block"><img
                                            style="width: 250px;height: 175px;"
                                            [src]="slidef.courseImage =='' ? 'assets/img/courses/courses1.jpg':slidef.courseImage"
                                            alt="image"></a>
                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img [src]="slidef.createdBy!=undefined && slidef.createdBy.userPhoto!='' ? slidef.createdBy.userPhoto  :'assets/img/user1.jpg'"
                                            class="rounded-circle mr-2" alt="image">
                                        <span>{{slidef.createdBy ? slidef.createdBy.firstName : null }}</span>
                                        <p *ngIf="slidef.status=='Draft'"
                                            style="margin-left: 5%;background-color: red;border-radius: 5px;color: white;padding: 1%;font-weight: 600;">
                                            {{slidef.status}}</p>
                                    </div>
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image">
                                        <span>{{slidef.startDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</span>
                                    </div>
                                    <h3><a (click)="coursedetails(slidef)"
                                            class="d-inline-block">{{slidef.fullName}}</a></h3>

                                </div>
                                <div class="courses-box-footer">
                                    {{slidef.categoryID ? slidef.categoryID.categoryName : 'N/A' }} ,
                                    {{slidef.gradeID ? slidef.gradeID.gradeName : 'N/A' }} ,
                                    {{slidef.boardID ? slidef.boardID.boardName : 'N/A' }}
                                    <br>
                                    {{slidef.subjectID ? slidef.subjectID.subjectName : 'N/A' }} ,
                                    {{slidef.languageID ? slidef.languageID.languageName : 'N/A' }}

                                </div>
                            </div>
                        </div>

                        <div *ngIf="FuturecourseData.length < 1">
                            No Upcoming Course(s) Available.
                        </div>
                        <div class="col-lg-12 col-md-12" *ngIf="FuturecourseData.length > 0">
                            <div style="float: right;">
                                <pagination-controls (pageChange)="p3 = $event" id="groupcourse1"></pagination-controls>
                            </div>
                        </div>
                    </div>
                    <h3 style="color: var(--mainColor);">Past courses</h3>
                    <div class="container-fluid row" style="cursor: pointer;border-bottom: 1px solid #d2d0d0">
                        <div class="col-lg-3 col-md-6"
                            *ngFor="let slidep of PastcourseData | paginate: { id: 'groupcourse2',itemsPerPage: count4, currentPage: p4 }">
                            <div class="single-courses-box without-box-shadow mb-30">
                                <div class="courses-image">
                                    <a (click)="coursedetails(slidep)" class="d-block"><img
                                            style="width: 250px;height: 175px;"
                                            [src]="slidep.courseImage =='' ? 'assets/img/courses/courses1.jpg':slidep.courseImage"
                                            alt="image"></a>
                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img [src]="slidep.createdBy!=undefined && slidep.createdBy.userPhoto!='' ? slidep.createdBy.userPhoto  :'assets/img/user1.jpg'"
                                            class="rounded-circle mr-2" alt="image">
                                        <span>{{slidep.createdBy ? slidep.createdBy.firstName : null }}</span>
                                        <p *ngIf="slidep.status=='Draft'"
                                            style="margin-left: 5%;background-color: red;border-radius: 5px;color: white;padding: 1%;font-weight: 600;">
                                            {{slidep.status}}</p>
                                    </div>
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image">
                                        <span>{{slidep.startDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</span>
                                    </div>
                                    <h3><a (click)="coursedetails(slidep)"
                                            class="d-inline-block">{{slidep.fullName}}</a></h3>
                                </div>
                                <div class="courses-box-footer">
                                    {{slidep.categoryID ? slidep.categoryID.categoryName : 'N/A' }} ,
                                    {{slidep.gradeID ? slidep.gradeID.gradeName : 'N/A' }} ,
                                    {{slidep.boardID ? slidep.boardID.boardName : 'N/A' }}
                                    <br>
                                    {{slidep.subjectID ? slidep.subjectID.subjectName : 'N/A' }} ,
                                    {{slidep.languageID ? slidep.languageID.languageName : 'N/A' }}
                                </div>
                            </div>
                        </div>
                        <div *ngIf="PastcourseData?.length < 1">
                            No Past Course(s) Available.
                        </div>
                        <div class="col-lg-12 col-md-12" *ngIf="PastcourseData.length > 0">
                            <div style="float: right;">
                                <pagination-controls (pageChange)="p4 = $event" id="groupcourse2"></pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="menu9"  [ngClass]="{ 'active': tabName=='menu9','fade':tabName!='menu9'}" class="tab-pane">
                <form [formGroup]="registerForm" class="">
                    <div class="container">
                        <div class="myDashboard-profile">
                            <div class="row align-items-center">
                                <div class="col-lg-8 col-md-7">
                                    <div>
                                    </div>
                                    <div class="row borderpro">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <div class="custom-file">
                                                        <input type="hidden" formControlName="fileupload" name=""
                                                            value="">
                                                        <input type="file" class="custom-file-input"
                                                            (change)="fileChange($event)" id="inputGroupFile01"
                                                            aria-describedby="inputGroupFileAddon01" accept="image/*"
                                                            [ngClass]="{ 'is-invalid': submitted && f.fileupload.errors}">
                                                        <label class="custom-file-label"
                                                            for="inputGroupFile01">{{selectedFileName}}</label>
                                                    </div>
                                                    <div *ngIf="submitted && f.fileupload.errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.fileupload.errors.required">Profile Image is
                                                            required</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <input type="text" formControlName="firstname" class="form-control"
                                                    maxlength="25"
                                                    [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }" />
                                                <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                                                    <div *ngIf="f.firstname.errors.required">First Name is required
                                                    </div>
                                                    <div *ngIf="f.firstname.errors.maxlength">only 25 characters are
                                                        allowed</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <input placeholder="Last Name" type="text" formControlName="lastname"
                                                    maxlength="25" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <select class="browser-default custom-select" formControlName="gender">
                                                    <option disabled>Select Gender</option>
                                                    <option>Male</option>
                                                    <option>Female</option>
                                                    <option>TransGender</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-content myDashboard-content">
                            <div id="home" [ngClass]="{ 'active': tabName=='home'}"  class="tab-pane">
                                <div class="row borderpro">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <input type="text" formControlName="email" class="form-control" disabled />
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <input type="text" placeholder="Address 2 (Street)" formControlName="area"
                                                maxlength="50" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <input type="text" placeholder="State" formControlName="state"
                                                class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <input type="text" placeholder="City/District/Town" formControlName="city"
                                                class="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <button (click)="onSubmit()" class="col-lg-2 float-right">Update Profile &nbsp;<i
                                        class="fas fa-long-arrow-alt-right"></i></button>
                            </div>

                            <div id="schooldetails"  [ngClass]="{ 'active': tabName=='schooldetails','fade':tabName!='schooldetails'}" class="tab-pane">
                                <div class="row borderpro">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <input type="text" placeholder="Qualification"
                                                formControlName="qualification" maxlength="25" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <input type="text" placeholder="Profession" formControlName="profession"
                                                maxlength="25" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <input type="text" placeholder="Current Education"
                                                formControlName="currentEducation" maxlength="25"
                                                class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <input type="text" placeholder="Preferences" formControlName="preferences"
                                                maxlength="40" class="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <button (click)="onSubmit()" class="col-lg-2 float-right">Update Profile &nbsp;<i
                                        class="fas fa-long-arrow-alt-right"></i></button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div id="menu6"  [ngClass]="{ 'active': tabName=='menu6','fade':tabName!='menu6'}" class="tab-pane">
                <div class="row">
                    <div class="col-lg-8">
                    </div>
                    <div class="col-lg-2 mt-2">
                        <form [formGroup]="classessForm">
                                <span *ngIf="studentIsSchool">                    
                                    <input type="checkbox" formControlName="classType">Only Schools
                                </span> 
                            <!-- <input type="radio" name="classType" (click)="changeRoleExam('School')" value="School"
                                formControlName="classType"> School
                            <input type="radio" name="classType" (click)="changeRoleExam('Public')" value="Public"
                                formControlName="classType"> Public -->
                        </form>
                    </div>
                    <div class="col-lg-2">
                        <a *ngIf="iscoursecreate" routerLink="/create-exam" class="default-btn float-right p-2">Create
                            Exam</a>
                    </div>
                </div>
                <div *ngIf="j.classType.value">
                    <h3 style="color: var(--mainColor);">Upcoming Exams</h3>
                    <div class="container-fluid row" style="cursor: pointer;border-bottom: 1px solid #d2d0d0">
                        <div class="col-lg-3 col-md-6"
                            *ngFor="let slidefe of FuturecourseExamgroup | paginate: { id: 'pagination3',itemsPerPage: counte, currentPage: e }">
                            <div class="single-courses-box without-box-shadow mb-30">
                                <div class="courses-image">
                                    <a class="d-block" (click)="Examdetails(slidefe)"><img
                                            style="width: 250px;height: 175px;"
                                            [src]="slidefe.examImage =='' ? 'assets/img/courses/courses1.jpg':slidefe.examImage"
                                            alt="image"></a>
                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img [src]="slidefe.createdBy!=undefined && slidefe.createdBy.userPhoto!='' ? slidefe.createdBy.userPhoto  :'assets/img/user1.jpg'"
                                            class="rounded-circle mr-2" alt="image">
                                        <span>{{slidefe.createdBy ? slidefe.createdBy.firstName : null }}</span>
                                        <p *ngIf="slidefe.status=='Draft'"
                                            style="margin-left: 5%;background-color: red;border-radius: 5px;color: white;padding: 1%;font-weight: 600;">
                                            {{slidefe.status}}</p>
                                    </div>
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image">
                                        <span>{{slidefe.startDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</span>
                                    </div>
                                    <h3><a (click)="Examdetails(slidefe)"
                                            class="d-inline-block">{{slidefe.fullName}}</a>
                                    </h3>

                                </div>
                                <div class="courses-box-footer">
                                    {{slidefe.categoryID ? slidefe.categoryID.categoryName : null }} ,
                                    {{slidefe.gradeID ? slidefe.gradeID.gradeName : null }} ,
                                    {{slidefe.boardID ? slidefe.boardID.boardName : null }}
                                    <br>
                                    {{slidefe.subjectID ? slidefe.subjectID.subjectName : null }} ,
                                    {{slidefe.languageID ? slidefe.languageID.languageName : null }}
                                </div>
                            </div>
                        </div>
                        <div *ngIf="FuturecourseExam?.length < 1">
                            No Upcoming Exam(s) Available.
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div style="float: right;">
                                <pagination-controls (pageChange)="e = $event" id="pagination3"></pagination-controls>
                            </div>
                        </div>
                    </div>
                    <h3 style="color: var(--mainColor);">Past Exams</h3>
                    <div class="container-fluid row" style="cursor: pointer;border-bottom: 1px solid #d2d0d0">
                        <div class="col-lg-3 col-md-6"
                            *ngFor="let slidepe of PastcourseExamgroup | paginate: { id: 'pagination4',itemsPerPage: counte1, currentPage: e1 }">
                            <div class="single-courses-box without-box-shadow mb-30">
                                <div class="courses-image">
                                    <a class="d-block" (click)="Examdetails(slidepe)"><img
                                            style="width: 250px;height: 175px;"
                                            [src]="slidepe.examImage =='' ? 'assets/img/courses/courses1.jpg':slidepe.examImage"
                                            alt="image"></a>
                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img [src]="slidepe.createdBy!=undefined && slidepe.createdBy.userPhoto!='' ? slidepe.createdBy.userPhoto  :'assets/img/user1.jpg'"
                                            class="rounded-circle mr-2" alt="image">
                                        <span>{{slidepe.createdBy ? slidepe.createdBy.firstName : null }}</span>
                                        <p *ngIf="slidepe.status=='Draft'"
                                            style="margin-left: 5%;background-color: red;border-radius: 5px;color: white;padding: 1%;font-weight: 600;">
                                            {{slidepe.status}}</p>
                                    </div>
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image">
                                        <span>{{slidepe.startDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</span>
                                    </div>
                                    <h3><a (click)="Examdetails(slidepe)"
                                            class="d-inline-block">{{slidepe.fullName}}</a>
                                    </h3>
                                </div>
                                <div class="courses-box-footer">
                                    {{slidepe.categoryID ? slidepe.categoryID.categoryName : null }} ,
                                    {{slidepe.gradeID ? slidepe.gradeID.gradeName : null }} ,
                                    {{slidepe.boardID ? slidepe.boardID.boardName : null }}
                                    <br>
                                    {{slidepe.subjectID ? slidepe.subjectID.subjectName : null }} ,
                                    {{slidepe.languageID ? slidepe.languageID.languageName : null }}
                                </div>
                            </div>
                        </div>
                        <div *ngIf="PastcourseExam?.length < 1">
                            No Past Exam(s) Available.
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div style="float: right;">
                                <pagination-controls (pageChange)="e1 = $event" id="pagination4"></pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!j.classType.value">
                    <h3 style="color: var(--mainColor);">Upcoming Exams</h3>
                    <div class="container-fluid row" style="cursor: pointer;border-bottom: 1px solid #d2d0d0">
                        <div class="col-lg-3 col-md-6"
                            *ngFor="let slidefe of FuturecourseExam | paginate: { id: 'examgroup1',itemsPerPage: counte3, currentPage: e3 }">
                            <div class="single-courses-box without-box-shadow mb-30">
                                <div class="courses-image">
                                    <a class="d-block" (click)="Examdetails(slidefe)"><img
                                            style="width: 250px;height: 175px;"
                                            [src]="slidefe.examImage =='' ? 'assets/img/courses/courses1.jpg':slidefe.examImage"
                                            alt="image"></a>
                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img [src]="slidefe.createdBy!=undefined && slidefe.createdBy.userPhoto!='' ? slidefe.createdBy.userPhoto  :'assets/img/user1.jpg'"
                                            class="rounded-circle mr-2" alt="image">
                                        <span>{{slidefe.createdBy ? slidefe.createdBy.firstName : null }}</span>
                                        <p *ngIf="slidefe.status=='Draft'"
                                            style="margin-left: 5%;background-color: red;border-radius: 5px;color: white;padding: 1%;font-weight: 600;">
                                            {{slidefe.status}}</p>
                                    </div>
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image">
                                        <span>{{slidefe.startDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</span>
                                    </div>
                                    <h3><a (click)="Examdetails(slidefe)"
                                            class="d-inline-block">{{slidefe.fullName}}</a>
                                    </h3>

                                </div>
                                <div class="courses-box-footer">
                                    {{slidefe.categoryID ? slidefe.categoryID.categoryName : null }} ,
                                    {{slidefe.gradeID ? slidefe.gradeID.gradeName : null }} ,
                                    {{slidefe.boardID ? slidefe.boardID.boardName : null }}
                                    <br>
                                    {{slidefe.subjectID ? slidefe.subjectID.subjectName : null }} ,
                                    {{slidefe.languageID ? slidefe.languageID.languageName : null }}
                                </div>
                            </div>
                        </div>
                        <div *ngIf="FuturecourseExam?.length < 1">
                            No Upcoming Exam(s) Available.
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div style="float: right;">
                                <pagination-controls (pageChange)="e3 = $event" id="examgroup1"></pagination-controls>
                            </div>
                        </div>
                    </div>
                    <h3 style="color: var(--mainColor);">Past Exams</h3>
                    <div class="container-fluid row" style="cursor: pointer;border-bottom: 1px solid #d2d0d0">
                        <div class="col-lg-3 col-md-6"
                            *ngFor="let slidepe of PastcourseExam | paginate: { id: 'examgroup2',itemsPerPage: counte4, currentPage: e4 }">
                            <div class="single-courses-box without-box-shadow mb-30">
                                <div class="courses-image">
                                    <a class="d-block" (click)="Examdetails(slidepe)"><img
                                            style="width: 250px;height: 175px;"
                                            [src]="slidepe.examImage =='' ? 'assets/img/courses/courses1.jpg':slidepe.examImage"
                                            alt="image"></a>
                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img [src]="slidepe.createdBy!=undefined && slidepe.createdBy.userPhoto!='' ? slidepe.createdBy.userPhoto  :'assets/img/user1.jpg'"
                                            class="rounded-circle mr-2" alt="image">
                                        <span>{{slidepe.createdBy ? slidepe.createdBy.firstName : null }}</span>
                                    </div>
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image">
                                        <span>{{slidepe.startDate |  date :"MMM dd,yyyy, hh:mm aaa"}}</span>
                                    </div>
                                    <h3><a (click)="Examdetails(slidepe)"
                                            class="d-inline-block">{{slidepe.fullName}}</a>
                                    </h3>
                                </div>
                                <div class="courses-box-footer">
                                    {{slidepe.categoryID ? slidepe.categoryID.categoryName : null }} ,
                                    {{slidepe.gradeID ? slidepe.gradeID.gradeName : null }} ,
                                    {{slidepe.boardID ? slidepe.boardID.boardName : null }}
                                    <br>
                                    {{slidepe.subjectID ? slidepe.subjectID.subjectName : null }} ,
                                    {{slidepe.languageID ? slidepe.languageID.languageName : null }}
                                </div>
                            </div>
                        </div>
                        <div *ngIf="PastcourseExam?.length < 1">
                            No Past Exam(s) Available.
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div style="float: right;">
                                <pagination-controls (pageChange)="e4 = $event" id="examgroup2"></pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="teststab"  [ngClass]="{ 'active': tabName=='teststab','fade':tabName!='teststab'}" class="tab-pane">
                <div class="row">
                    <div class="col-lg-12">
                        <h3 style="color: var(--mainColor);">
                            Tests
                        </h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-10">
                    </div>
                    <div class="col-lg-1">
                        <a (click)="requestTests()" class="default-btn float-right p-2 mb-1">Accept</a>
                    </div>
                    <div class="col-lg-1">
                        <a (click)="requestTestsreview()" class="default-btn float-right p-2 mb-1">Review</a>
                    </div>
                </div>
                <div style="margin-top: 2%;">
                    <h4 style="color: var(--mainColor);">Accepted Tests</h4>
                    <div class="container-fluid row" style="cursor: pointer;border-bottom: 1px solid #d2d0d0">

                        <div class="col-lg-3 col-md-6"
                            *ngFor="let slideun of uncorrecteddata | paginate: { id: 'paginationun',itemsPerPage: countun, currentPage: un }">
                            <div class="single-courses-box without-box-shadow mb-30">
                                <div class="courses-image">
                                    <a class="d-block" (click)="testdetails(slideun._id)" ><img src='assets/img/courses/courses1.jpg'
                                        style="width: 250px;height: 175px;" alt="image"></a>                            
                                </div>
                                <div class="courses-content">                                  
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image">
                                        <span>{{slideun.dateTestCompleted |  date :"MMM dd,yyyy, hh:mm aaa"}}</span>
                                    </div>
                                    <h3><a
                                            class="d-inline-block">{{slideun.testID ? slideun.testID.testName : 'Not Available'}}</a>
                                    </h3>

                                </div>
                                <div class="courses-box-footer">
                                    {{slideun.category ? slideun.category.categoryName : 'N/A' }} , {{slideun.grade ? slideun.grade.gradeName : 'N/A' }} , {{slideun.board ? slideun.board.boardName : 'N/A' }} , {{slideun.subjectString}}
                                </div>
                            </div>
                        </div>
                        <div *ngIf="uncorrecteddata?.length < 1">
                            No Accepted Test(s) Available.
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div style="float: right;">
                                <pagination-controls (pageChange)="un = $event" id="paginationun"></pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="margin-top: 2%;">
                    <h4 style="color: var(--mainColor);">Review Tests</h4>
                    <div class="container-fluid row" style="cursor: pointer;border-bottom: 1px solid #d2d0d0">

                        <div class="col-lg-3 col-md-6"
                            *ngFor="let slidere of reviewdata | paginate: { id: 'paginationreview',itemsPerPage: countreview, currentPage: review }">
                            <div class="single-courses-box without-box-shadow mb-30">
                                <div class="courses-image">
                                    <a class="d-block" (click)="testdetailsreview(slidere._id)" ><img src='assets/img/courses/courses1.jpg'
                                        style="width: 250px;height: 175px;" alt="image"></a>                            
                                </div>
                                <div class="courses-content">                                  
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/startdate.png" class="rounded-circle mr-2" alt="image">
                                        <span>{{slidere.dateTestCompleted |  date :"MMM dd,yyyy, hh:mm aaa"}}</span>
                                    </div>
                                    <h3><a
                                            class="d-inline-block">{{slidere.testID ? slidere.testID.testName : 'Not Available'}}</a>
                                    </h3>

                                </div>
                                <div class="courses-box-footer">
                                    {{slidere.category ? slidere.category.categoryName : 'N/A' }} , {{slidere.grade ? slidere.grade.gradeName : 'N/A' }} , {{slidere.board ? slidere.board.boardName : 'N/A' }} , {{slidere.subjectString}}
                                </div>
                            </div>
                        </div>
                        <div *ngIf="reviewdata?.length < 1">
                            No Review Test(s) Available.
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div style="float: right;">
                                <pagination-controls (pageChange)="review = $event" id="paginationreview"></pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="account" [ngClass]="{ 'active': tabName=='account','fade':tabName!='account'}" class="tab-pane ">
            <div class="row">
                <div class="col-lg-7">
                </div>
                <div class="col-lg-2">
                    Month and Year:
                    <input style="display: none;" disabled="true" [owlDateTimeTrigger]="dt"
                        [owlDateTime]="dt"
                        [formControl]="date">
                        <span class="example-trigger" [owlDateTimeTrigger]="dt">
                            <i class="fas fa-calendar-alt"></i>
                        </span>
                        <b>{{date.value |  date :"MM-yyyy"}}</b>
                        <owl-date-time [pickerType]="'calendar'"
                        [startView]="'multi-years'"
                        (yearSelected)="chosenYearHandler($event)"
                        (monthSelected)="chosenMonthHandler($event, dt)"
                        #dt="owlDateTime"></owl-date-time>
                </div>
                <div class="col-lg-3">
                    <div class="form-group" >
                        <input type="text" class="form-control" placeholder="Search Product Name" [(ngModel)]="term">
                    </div>
                </div>
            </div>                  
            <div  class="row" style="margin-top: 5%;">                               
                <div class="col-lg-6 mb-2">
                    <b> No.of Classes Completed :    {{teacherPayoutDetails.length}}
                    </b>
                </div>
            </div>
            <div class=""  >                
                <table datatable [dtOptions]="dtOptions" class="table">  
                    <tr>
                        <th>
                            Product Name
                        </th>
                        <th>
                            Class Type
                        </th>
                         <th (click)="sort('requestDetails','fullName')">
                            <span *ngIf="!isDesc">
                                <i class="fa fa-arrow-up" aria-hidden="true"></i>
                            </span>
                            <span *ngIf="isDesc">
                                <i class="fa fa-arrow-down" aria-hidden="true"></i>
                            </span>
                            Class Name
                        </th>
                        <th >                           
                            Class Date
                        </th>
                        <th>                           
                            Class Start Time
                        </th>
                        <th>                           
                            Class End Time
                        </th>
                        <th>                           
                           Class Duration(Min)
                        </th>
                       
                        <th>
                            Amount(INR)
                        </th>
                         <th>
                            Student Name
                        </th>
                        <th>
                        </th>
                    </tr>
                    <tr *ngFor="let slide of teacherPayoutDetails | filterSearch: term  | paginate: { id: 'liba10',itemsPerPage: countva10, currentPage: va10 }">
                        <td class="p-3">
                            <span *ngIf="slide.requestDetails!=null">{{slide.paymentDetails.productName}}</span>
                            <span *ngIf="slide.buddiDetails!=null">buddi Group Class</span>
                        </td>
                        <td class="p-3">
                            <span *ngIf="slide.requestDetails!=null">{{slide.requestDetails.classType}}</span>
                            <span *ngIf="slide.buddiDetails!=null">{{slide.buddiDetails.meetingType}}</span>
                        </td>
                        <td >
                            <span *ngIf="slide.requestDetails!=null" [routerLink]="'/teacher/accept/'+slide.requestDetails.meetingID">{{slide.requestDetails.fullName}}</span>
                             <span *ngIf="slide.buddiDetails!=null" [routerLink]="'/teacher/accept/'+slide.buddiDetails.meetingID">{{slide.buddiDetails.name}} | {{slide.buddiDetails.topic}}</span>
                        </td>
                        <td class="p-3">
                            <span *ngIf="slide.requestDetails!=null">
                                {{slide.requestDetails.ScheduleDate  |  date :"MMM dd,yyyy, hh:mm aaa"}}
                            </span> 
                            <span *ngIf="slide.buddiDetails!=null">
                                {{slide.buddiDetails.ScheduleDate  |  date :"MMM dd,yyyy, hh:mm aaa"}}
                            </span>                           
                        </td>
                        <td class="p-3">
                            <span *ngIf="slide.requestDetails!=null">
                                {{slide.requestDetails.startMeeting  |  date :"MMM dd,yyyy, hh:mm aaa"}}
                            </span>  
                            <span *ngIf="slide.buddiDetails!=null">
                                {{slide.buddiDetails.startMeeting  |  date :"MMM dd,yyyy, hh:mm aaa"}}
                            </span>                            
                        </td>
                        <td class="p-3">
                            <span *ngIf="slide.requestDetails!=null">
                                {{slide.requestDetails.endMeeting  |  date :"MMM dd,yyyy, hh:mm aaa"}}
                            </span>   
                            <span *ngIf="slide.buddiDetails!=null">
                                {{slide.buddiDetails.endMeeting  |  date :"MMM dd,yyyy, hh:mm aaa"}}
                            </span>                            
                        </td>                           
                        <td class="p-3">
                            <span>
                                {{slide.meetingDuration}}
                            </span>                            
                        </td>
                    
                        <td>
                            <span>{{slide.paymentDetails.amount}}</span>
                        </td>
                        <td >
                            <span *ngIf="slide.requestDetails!=null">{{slide.studentID.profileName}}</span>
                            <span *ngIf="slide.buddiDetails!=null">-</span>
                        </td>
                        <td class="p-3">
                            <span>
                                <button (click)="indexFilter(slide)" data-toggle="modal" data-target="#myModal1"  class="btn btnType1 py-2 px-4">View</button>
                            </span>                  
                        </td>
                    </tr> 
                    <tr>
                        <td colspan="11" *ngIf="(teacherPayoutDetails| filterSearch: term ).length==0">
                            No Payment Data Available
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <b>Total</b>
                        </td>
                         <td >
                            <b>{{teacherPayoutDetails| diffTwoTime: term}}</b>
                        </td>
                        <td >
                           <b>{{teacherPayoutDetails| totalAmount
                           : term}}</b>
                        </td>
                       
                        <td>
                        </td> 
                        <td>
                        </td>
                    </tr>                   
                    </table>
                    <div style="float: right;" *ngIf="teacherPayoutDetails.length !=0">
                        <pagination-controls (pageChange)="va10 = $event" id="liba10"></pagination-controls>
                    </div>
                </div>
            </div>
            <div id="studymaterial" [ngClass]="{ 'active': tabName=='studymaterial','fade':tabName!='studymaterial'}"  class="tab-pane">
                <div class="row">
                    <div class="col-lg-9"></div>
                    <div class="col-lg-3">
                        <form [formGroup]="studyMaterialForm">
                            <span *ngIf="studentIsSchool">                    
                                <input type="checkbox" formControlName="studymaterialType">Only Schools
                            </span> 
                        </form>
                    </div>
                </div>    
                <h3 style="color: var(--mainColor);">e-Book(s)</h3>
                <a routerLink="/create/ebook" class="default-btn mb-2  ml-2 float-right p-2">Add e-Book</a>
                <!-- <div class="container-fluid row p-0" style="cursor: pointer" *ngIf="ebooklistbyIdPrivate.length !=0">   
                    <div class="col-lg-3 col-md-6" *ngFor="let slide of ebooklistbyIdPrivate | paginate: { id: 'paginationpr3',itemsPerPage: countpr3, currentPage: pr3 }">
                        <div class="single-courses-box without-box-shadow mb-30">
                            <div class="courses-image">
                                <img style="width:100%" (click)="ebookdetails(slide)" style="width: 250px;height: 175px;"
                                    [src]="slide.thumbname ? slide.thumbname : 'assets/img/videocat-thumb-1.jpg'" alt=""
                                    width="100%">                   
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img [src]="slide.teacherID!=undefined && slide.teacherID.userPhoto!='' ? slide.teacherID.userPhoto  :'assets/img/user1.jpg'"
                                        class="rounded-circle mr-2" alt="image">
                                    <span *ngIf="slide.teacherID!=undefined">{{slide.teacherID.profileName}}</span>
                                </div>
                                <h3>
                                    <a (click)="ebookdetails(slide)" class="d-inline-block"><span class="text-break">{{slide.flimname}}</span></a>      
                                </h3>
                            </div>   
                            <div class="courses-box-footer">
                                <div>
                                    Type: {{slide.securitytype =='Group' ? 'School' : slide.securitytype}}
                                </div>
                            </div>                
                            <div class="courses-box-footer" *ngIf="slide.categoryID.length > 0">
                                {{slide.categoryID ? slide.categoryID[0].categoryName : '' }},
                                {{slide.gradeID ? slide.gradeID[0].gradeName : '' }},
                                {{slide.boardID ? slide.boardID.boardName : '' }}
                            </div>
                            <div class="courses-box-footer" *ngIf="slide.subjectID.length > 0">
                                {{slide.subjectID ? slide.subjectID[0].subjectName : '' }},
                                {{slide.languageID ? slide.languageID.languageName : '' }}
                            </div>
                            <div class="courses-box-footer" *ngIf="slide.subjectID.length == 0">
                                {{''}},
                                {{slide.languageID ? slide.languageID.languageName : '' }}
                            </div>
                            <div *ngIf="slide.isPartner" class="courses-box-footer">
                                Provided By : {{slide.isbuddi_premium? 'buddi Premium' : slide.partner.partnerName}}
                            </div>
                        </div>
                    </div>
                </div>
                <div style="float: right;" *ngIf="ebooklistbyIdPrivate.length!=0">
                    <pagination-controls (pageChange)="pr3 = $event" id="paginationpr3"></pagination-controls>
                </div>
                <div *ngIf="ebooklistbyIdPrivate.length !=0">
                    <br>
                    <hr>
                </div> -->


                <div class="container-fluid row p-0" style="cursor: pointer" *ngIf="ebooklistbyId.length !=0 && !s.studymaterialType.value">
                    <div class="col-lg-3 col-md-6"
                        *ngFor="let slide of ebooklistbyId | paginate: { id: 'pagination9',itemsPerPage: count9, currentPage: p9 }">
                        <div class="single-courses-box without-box-shadow mb-30">
                            <div class="courses-image">
                                <img style="width: 250px;height: 175px;" (click)="ebookdetails(slide)"
                                    [src]="slide.thumbname ? slide.thumbname : 'assets/img/videocat-thumb-1.jpg'" alt=""
                                    width="100%">
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img [src]="slide.teacherID!=undefined && slide.teacherID.userPhoto!='' ? slide.teacherID.userPhoto  :'assets/img/user1.jpg'"
                                        class="rounded-circle mr-2" alt="image">
                                    <span *ngIf="slide.teacherID!=undefined">{{slide.teacherID.profileName}}
                                    </span>
                                </div>
                                <h3>
                                    <a (click)="ebookdetails(slide)"
                                        class="d-inline-block"><span class="text-break">{{slide.flimname}}</span></a>

                                </h3>
                            </div>
                            <div class="courses-box-footer">
                                <div>
                                    Type: {{slide.securitytype =='Group' ? 'School' : slide.securitytype}}
                                </div>
                            </div>
                            <div class="courses-box-footer" *ngIf="slide.categoryID.length > 0">
                                {{slide.categoryID ? slide.categoryID[0].categoryName : '' }},
                                {{slide.gradeID ? slide.gradeID[0].gradeName : '' }},
                                {{slide.boardID ? slide.boardID.boardName : '' }}
                            </div>
                            <div class="courses-box-footer" *ngIf="slide.subjectID.length > 0">
                                {{slide.subjectID ? slide.subjectID[0].subjectName : '' }},
                                {{slide.languageID ? slide.languageID.languageName : '' }}
                            </div>

                            <!-- <div class="courses-box-footer" *ngIf="slide.categoryID!=''">
                                {{slide.categoryID ? slide.categoryID.categoryName : '' }},
                                {{slide.gradeID ? slide.gradeID.gradeName : '' }},
                                {{slide.boardID ? slide.boardID.boardName : '' }}
                            </div>
                            <div class="courses-box-footer" *ngIf="slide.categoryID!=''">
                                {{slide.subjectID ? slide.subjectID.subjectName : '' }},
                                {{slide.languageID ? slide.languageID.languageName : '' }}
                            </div> -->
                            <div *ngIf="slide.isPartner" class="courses-box-footer">
                                Provided By : {{slide.isbuddi_premium? 'buddi Premium' : slide.partner.partnerName}}
                            </div>
                        </div>
                    </div>
                </div>
                <div style="float: right;" *ngIf="ebooklistbyId.length!=0 && !s.studymaterialType.value">
                    <pagination-controls (pageChange)="p9 = $event" id="pagination9"></pagination-controls>
                </div>
                <div *ngIf="ebooklistbyId.length ==0 && !s.studymaterialType.value">
                    No e-Book(s) Available.
                </div>
                <div *ngIf="(ebooklistbyId.length ==0 && !s.studymaterialType.value) || (ebooklistbyId.length ==0 && !s.studymaterialType.value)">
                    <br>
                    <hr>
                </div> 
            <div class="container-fluid row p-0" style="cursor: pointer" *ngIf="ebooklistbyIdGroup.length !=0 && s.studymaterialType.value">   
                <div class="col-lg-3 col-md-6"
                        *ngFor="let slide of ebooklistbyIdGroup | paginate: { id: 'pagination91',itemsPerPage: count91, currentPage: p91 }">
                        <div class="single-courses-box without-box-shadow mb-30">
                            <div class="courses-image">
                                <img style="width:100%" (click)="ebookdetails(slide)"
                                    style="width: 250px;height: 175px;"
                                    [src]="slide.thumbname ? slide.thumbname : 'assets/img/videocat-thumb-1.jpg'" alt=""
                                    width="100%">                   
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img [src]="slide.teacherID!=undefined && slide.teacherID.userPhoto!='' ? slide.teacherID.userPhoto  :'assets/img/user1.jpg'"
                                        class="rounded-circle mr-2" alt="image">
                                    <span *ngIf="slide.teacherID!=undefined">{{slide.teacherID.profileName}}
                                    </span>  
                                </div>
                                <h3>
                                    <a (click)="ebookdetails(slide)"
                                        class="d-inline-block">
                                        <span class="text-break">{{slide.flimname}}</span>
                                    </a>      
                                </h3>
                            </div>   
                             <div class="courses-box-footer">
                                <div>
                                    Type: {{slide.securitytype =='Group' ? 'School' : slide.securitytype}}
                                </div>
                            </div>                
                            <div class="courses-box-footer">
                                {{slide.categoryID ? slide.categoryID[0].categoryName : '' }},
                                {{slide.gradeID ? slide.gradeID[0].gradeName : '' }},
                                {{slide.boardID ? slide.boardID.boardName : '' }}
                            </div>
                            <div class="courses-box-footer">
                                {{slide.subjectID ? slide.subjectID[0].subjectName : '' }},
                                {{slide.languageID ? slide.languageID.languageName : '' }}
                            </div>
                            <div *ngIf="slide.isPartner" class="courses-box-footer">
                                Provided By : {{slide.isbuddi_premium? 'buddi Premium' : slide.partner.partnerName}}
                            </div>
                        </div>
                    </div>
                </div>
                <div style="float: right;" *ngIf="ebooklistbyIdGroup.length!=0 && s.studymaterialType.value">
                    <pagination-controls (pageChange)="p91 = $event" id="pagination91"></pagination-controls>
                </div>
                <div *ngIf="ebooklistbyIdGroup.length ==0 && s.studymaterialType.value ">
                    No e-Book(s) Available.
                </div>
                <div *ngIf="(ebooklistbyIdGroup.length!=0 && s.studymaterialType.value ) || (ebooklistbyIdGroup.length ==0 && s.studymaterialType.value)">
                    <br>
                    <hr>
                </div>
                
                <br>
                <!-- Solution Start -->
                

                <h3 style="color: var(--mainColor);">Solutions</h3>
                <a routerLink="/create/solution" class="default-btn mb-2  ml-2 float-right p-2">Add Solution</a>
                <app-student-reuselist 
                    [type]="'Public'" 
                    *ngIf="studyMaterial.solutionPublic.length!=0 && !s.studymaterialType.value" 
                    [itemName]="'solution'" 
                    [config]="config.public"
                    [redirectText]="'details/solution/'"
                    [dataList]="studyMaterial.solutionPublic" 
                    (pageNumber)="receivePageNumber($event)"
                >                    
                </app-student-reuselist>
                <div *ngIf="studyMaterial.solutionPublic.length==0 && !s.studymaterialType.value">
                    No Solution(s) Available.
                </div>
                <app-student-reuselist 
                    [type]="'Public'" 
                    *ngIf="studyMaterial.solutionGroup.length!=0 && s.studymaterialType.value " 
                    [itemName]="'solution'" 
                    [config]="config.group"
                    [redirectText]="'details/solution/'"
                    [dataList]="studyMaterial.solutionGroup" 
                    (pageNumber)="receivePageNumber($event)"
                >                    
                </app-student-reuselist> 
                <div *ngIf="studyMaterial.solutionGroup.length==0 && s.studymaterialType.value">
                    No Solution(s) Available.
                </div>
                <!-- Solution End -->
                <br>
                <hr>
                <!-- Question Start -->
                <h3 style="color: var(--mainColor);">Question Papers</h3>
                <a routerLink="/create/question" class="default-btn mb-2  ml-2 float-right p-2">Add Question</a>
                <app-student-reuselist 
                    [type]="'Public'" 
                    *ngIf="studyMaterial.questionPublic.length!=0 && !s.studymaterialType.value" 
                    [itemName]="'question'" 
                    [config]="config.publicQuestion"
                    [redirectText]="'details/question/'"
                    [dataList]="studyMaterial.questionPublic" 
                    (pageNumber)="receivePageNumber($event)"
                >                    
                </app-student-reuselist>
                <div *ngIf="studyMaterial.questionPublic.length==0 && !s.studymaterialType.value">
                    No Question(s) Available.
                </div>

                <app-student-reuselist 
                    [type]="'Public'" 
                    *ngIf="studyMaterial.questionGroup.length!=0 && s.studymaterialType.value " 
                    [itemName]="'question'" 
                    [config]="config.groupQuestion"
                    [redirectText]="'details/question/'"
                    [dataList]="studyMaterial.questionGroup" 
                    (pageNumber)="receivePageNumber($event)"
                >                    
                </app-student-reuselist> 
                <div *ngIf="studyMaterial.questionGroup.length==0 && s.studymaterialType.value">
                    No Question(s) Available.
                </div>





                <!-- Question End -->



            </div>
            <div id="progress" [ngClass]="{ 'active': tabName=='progress','fade':tabName!='progress'}"  class="tab-pane">
                <h3> No data Available for Display.</h3>
            </div>
        </div>
        <br><br>
    </div>
</section>
<div  class="modal" id="myModal1">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <h3 style="text-align: center;"> Payment Details</h3>
                <div *ngIf="modelData.length!=0" >
                    <div class="row" style="margin-top: 5%;">
                        <div class="col-lg-6">Product Name</div>
                        <div class="col-lg-6">                       {{modelData.paymentDetails.productName}}
                        </div>
                    </div>
                    <div  class="row" style="margin-top: 5%;">
                        <div class="col-lg-6">Class Name</div>
                        <div class="col-lg-6">
                            <span *ngIf="modelData.requestDetails!=null">{{modelData.requestDetails.fullName}}</span>
                            <span *ngIf="modelData.buddiDetails!=null">{{modelData.buddiDetails.name}} | {{modelData.buddiDetails.topic}}</span>
                        </div>
                    </div>
                    <div class="row" style="margin-top: 5%;">
                        <div class="col-lg-6">Junior or Senior</div>
                        <div class="col-lg-6">
                            {{modelData.paymentDetails.seniorOrJunior}}
                        </div>
                    </div>
                    <div  class="row" style="margin-top: 5%;">
                        <div class="col-lg-6">Payment Status</div>
                        <div class="col-lg-6">
                            {{modelData.paymentDetails.isPaid ?'Paid' : 'Not Paid'}}
                        </div>
                    </div>                          
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="myModal2" *ngIf="!this.bankCheck.isBank">
    <div class="modal-dialog">
        <div class="modal-content">   
            <div class="modal-header">   
                Please fill up your Bank Account details for Payment Related Process.
            </div>             
        <div class="modal-footer">
            <button (click)="confirmBank()" class="btn btn-default" data-dismiss="modal">Ok</button>
        </div>
      </div>      
    </div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <img src="assets/img/spinner-default.gif" alt="">
</ngx-spinner>
